import React, { Component } from 'react';
import styled from 'react-emotion/macro';
import {  withRouter } from 'react-router-dom';
import i18n from 'Utils/i18n.js';
import TrustPilot from 'assets/sub-header-trustpilot-new.png';

import { Form, Button } from 'antd';
import { Input } from 'antd';
import { Select } from 'antd';

const Option = Select.Option;
const FormItem = Form.Item;
const formInputSize = 'large';

const FormLabel = styled.div`
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 7px;
  color: #2e353b;
`;

const flymbleBlue = '#4860ff';
const searchPageBg = '#f9f9fa';

const ThankYouPageWrapper = styled.div`
  padding-top: 20px;
  min-height: 1200px;
  background-color: ${searchPageBg};

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 510px)) 1fr;
    padding-top: 40px;

    .gridThankYou {
      grid-column: 2/4;
    }
  }

  h2 {
    font-size: 16px;
    text-align: center;
    color: black;
  }
`;

const AlmostDoneWrapper = styled.div`
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;

  h2 {
    font-size: 24px;
  }

  h4 {
    font-weight: 500;
    margin-top: 25px;
    padding-bottom: 10px;
    font-size: 20px;
  }

  .timeBox {
    border: ${flymbleBlue} 2px solid;
    padding: 7px 16px;
    font-size: 22px;
    border-radius: 2px;

    span {
      margin-left: 7px;
    }
  }
`;

const NextStepsWrapper = styled.div`
  margin-top: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;

  background-color: white;

  .nextStepsDescribtion {
    padding-bottom: 30px;
    font-size: 15px;
    display: block;
  }

  h2 {
    font-size: 18px;
    text-align: left;
    margin-bottom: 5px;
  }

  .describtion {
    font-size: 15px;
    padding-bottom: 10px;
    display: block;
  }

  .singleStep {
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-gap: 5px;
    margin-bottom: 20px;

    @media (min-width: 1000px) {
      grid-template-columns: 1fr 17fr;
    }

    p {
      font-size: 15px;
      margin: 0px;
    }

    a {
      text-decoration: none;
      color: black;
      font-weight: bold;
    }

    .numberStep {
      grid-column: 1/2;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 2px solid ${flymbleBlue};
      text-align: center;
      vertical-align: middle;

      margin-top: 3px;

      p {
        margin-top: 2px;
        color: ${flymbleBlue};
        font-size: 24px;
        font-weight: 500;
      }
    }

    .stepBodyText {
      padding-top: 3px;
    }
  }

  .fnplLogo {
    max-height: 42px;
    padding-top: 10px;
  }
`;

const RateUsWrapper = styled.div`
  margin-top: 35px;
  padding-top: 35px;
  padding-bottom: 70px;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;

  h2 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 5px;
  }

  .describtion {
    font-size: 15px;
    padding-bottom: 10px;
    display: block;
  }

  img {
    max-width: 220px;
    margin-left: -6px;
    padding-bottom: 25px;
    padding-top: 10px;
  }

  input {
    width: 100%;
  }

  p {
    font-size: 12px;
  }

  .feedbackButton {
    display: block;
    width: 100%;

    margin-top: 15px;
    height: 51px;

    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    border-radius: 2px;
    background-color: ${flymbleBlue};
    color: white;
  }
`;



const NextSteps = () => (
  <NextStepsWrapper>
    <h2>{i18n.t('ThankYouPage..You will receive your tickets shortly.')}</h2>

    <span className="nextStepsDescribtion">
      {i18n.t(
        'ThankYouPage..You can expect your booking confirmation and e-tickets in your inbox within 24 hours. Have a good trip!'
      )}
    </span>
  </NextStepsWrapper>
);

class RateUs extends React.Component {
  handleOnSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, feedbackValues) => {
      console.log('Feedback', feedbackValues);
      if (!err) {
        window.Intercom('trackEvent', 'ticket-feedback', feedbackValues);
        this.props.history.push('/');
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <RateUsWrapper>
        <Form>
          <h2>Please rate us and receive £10 discount on your next booking!</h2>
          <span className="describtion">
            {i18n.t(
              'ThankYouPage..Your feedback is greatly appreciated and improves our service.'
            )}
          </span>
          <img src={TrustPilot} alt="trust pilot logo" />

          <FormLabel>
            {i18n.t('ThankYouPage..How did you find out about Flymble?')}
          </FormLabel>
          <FormItem>
            {getFieldDecorator('hearabout', {
              rules: [
                {
                  required: true,
                  message: i18n.t(
                    'ThankYouPage..Please tell us how you learned about us?'
                  ),
                },
              ],
            })(
              <Select
                size={formInputSize}
                placeholder="Select"
                style={{
                  width: '100%',
                }}
              >
                <Option value="friend">
                  {i18n.t('ThankYouPage..From a friend')}
                </Option>
                <Option value="facebook">Facebook</Option>
                <Option value="media">
                  {i18n.t('ThankYouPage..Newspaper / Online media')}
                </Option>
                <Option value="word of mouth">
                  {' '}
                  {i18n.t('ThankYouPage..Word of mouth')}
                </Option>
                <Option value="google">Google</Option>
                <Option value="other"> {i18n.t('ThankYouPage..Other')}</Option>
              </Select>
            )}
          </FormItem>

          <FormLabel>
            {i18n.t(
              'ThankYouPage..How likely are you to recommend Flymble to a friend?'
            )}
          </FormLabel>
          <FormItem>
            {getFieldDecorator('recommend', {
              rules: [
                {
                  required: true,
                  message: i18n.t(
                    'ThankYouPage..How likely are you to recommend Flymble to a friend?'
                  ),
                },
              ],
            })(
              <Select
                size={formInputSize}
                placeholder="Select"
                style={{
                  width: '100%',
                }}
              >
                <Option value="1">1/10</Option>
                <Option value="2">2/10</Option>
                <Option value="3">3/10</Option>
                <Option value="4">4/10</Option>
                <Option value="5">5/10</Option>
                <Option value="6">6/10</Option>
                <Option value="7">7/10</Option>
                <Option value="8">8/10</Option>
                <Option value="9">9/10</Option>
                <Option value="10">10/10</Option>
              </Select>
            )}
          </FormItem>

          <FormLabel>
            {i18n.t('ThankYouPage..Give your feedback or leave blank')}
          </FormLabel>
          <FormItem>
            {getFieldDecorator('feedback', {
              rules: [
                {
                  required: false,
                },
              ],
            })(
              <Input.TextArea
                rows={4}
                placeholder={i18n.t(
                  'ThankYouPage..What can we improve for you? What are we doing well?'
                )}
              />
            )}
          </FormItem>
         
          <Button
            className="feedbackButton"
            htmlType="submit"
            onClick={this.handleOnSubmit}
          >
            {i18n.t('ThankYouPage..Send Feedback')}
          </Button>
        </Form>
      </RateUsWrapper>
    );
  }
}

const AlmostDone = () => {
  return (
    <AlmostDoneWrapper>
      <h2>{i18n.t('ThankYouPage..Thank you for choosing Flymble')}</h2>
      <h4>{i18n.t('ThankYouPage..Status: Processing your booking')}</h4>
    </AlmostDoneWrapper>
  );
};

const RateUsForm = Form.create()(withRouter(RateUs));

export default class ThankYouPage extends Component {
  componentDidMount = () => {
    window.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };
  render() {
    return (
      <ThankYouPageWrapper>
        <div className="gridThankYou">
          <AlmostDone />
          <NextSteps />
          <RateUsForm />
        </div>
      </ThankYouPageWrapper>
    );
  }
}


