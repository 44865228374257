import React from 'react';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { PersonalItemAndCabin } from './components/PersonalItemAndCabin';
import { NoCabinBaggageOptionElement } from './components/NoCabinBaggageOptionElement';
import { BaggageElement } from './components/BaggageElement';
import { BAGGAGE_OPTIONS, PURCHASED_BAGGAGE_TYPES } from '../../../constants';
import { PersonalItemInterface, CabinBagInterface } from "../../../interfaces";

const {
    CASE_2_FREE_CABIN_AND_PERSONAL,
    CASE_5_PAID_CABIN_NO_PERSONAL,
    CASE_4_FREE_PRIORITY_BOARDING_ONLY,
    CASE_3_FREE_PERSONAL_PAID_PRIORITY,
    CASE_1_RYAN_AIR,
    CASE_6_CABIN_BAG_ONLY,
    CASE_10_PERSONAL_ITEM_ONLY
} = BAGGAGE_OPTIONS;

const {
    personal_Item_And_Cabin,
    cabin_Bag,
} = PURCHASED_BAGGAGE_TYPES;

export const DetermineViewModeHandBag = (
    {
        mode,
        personalItem: { dimensions: personalItemDimensions, price: personalItemPrice },
        cabinBag: { dimensions: cabinBagDimensions, price: cabinBagPrice },
        priorityBoarding,
        handBagIsActive1,
        handBagIsActive2,
        setPurchasedBaggage,
        removePurchasedBaggage,
        setHandBagIsActive1,
        setHandBagIsActive2,
        setHandBagOneOption,
        prefix,
    }: {
        mode: string,
        personalItem: PersonalItemInterface,
        cabinBag: CabinBagInterface,
        priorityBoarding: any, // What to do if priority boarding doesn't exist? 
        handBagIsActive1: boolean,
        handBagIsActive2: boolean,
        setPurchasedBaggage: Function,
        removePurchasedBaggage: Function,
        setHandBagIsActive1: Function,
        setHandBagIsActive2: Function,
        setHandBagOneOption: Function,
        prefix: string,

    }) => {
    switch (mode) {
        case CASE_1_RYAN_AIR:
            return (
                <>
                    <BaggageElement
                        dimensions={personalItemDimensions}
                        price={personalItemPrice}
                        title="Personal item"
                        icon={faShoppingBag}
                        handleOnClick={() => {
                            !handBagIsActive1 &&
                                removePurchasedBaggage(prefix, personal_Item_And_Cabin);
                            setHandBagIsActive1(true);
                            setHandBagIsActive2(false);
                        }}
                        isActive={handBagIsActive1}
                    />
                    <PersonalItemAndCabin
                        personalItemDimensions={personalItemDimensions}
                        cabinBagDimensions={cabinBagDimensions}
                        price={priorityBoarding}
                        handleOnClick={() => {
                            setPurchasedBaggage(
                                prefix,
                                personal_Item_And_Cabin,
                                priorityBoarding
                            );
                            setHandBagIsActive1(false);
                            setHandBagIsActive2(true);
                        }}
                        isActive={handBagIsActive2}
                    />
                </>
            );
        case CASE_2_FREE_CABIN_AND_PERSONAL:
            setHandBagOneOption(true);
            return (
                <PersonalItemAndCabin
                    personalItemDimensions={personalItemDimensions}
                    cabinBagDimensions={cabinBagDimensions}
                    price={0}
                    isActive={true}
                    handleOnClick={() => {
                        console.log('There is no handle click function.');
                    }}
                />
            );
        case CASE_3_FREE_PERSONAL_PAID_PRIORITY:
            setHandBagOneOption(false);
            return (
                <>
                    <BaggageElement
                        dimensions={personalItemDimensions}
                        price={personalItemPrice}
                        title="Personal item"
                        icon={faShoppingBag}
                        handleOnClick={() => {
                            !handBagIsActive1 &&
                                removePurchasedBaggage(prefix, personal_Item_And_Cabin);
                            setHandBagIsActive1(true);
                            setHandBagIsActive2(false);
                        }}
                        isActive={handBagIsActive1}
                    />
                    <PersonalItemAndCabin
                        personalItemDimensions={personalItemDimensions}
                        cabinBagDimensions={cabinBagDimensions}
                        price={priorityBoarding}
                        handleOnClick={() => {
                            setHandBagIsActive1(false);
                            setHandBagIsActive2(true);
                            setPurchasedBaggage(
                                prefix,
                                personal_Item_And_Cabin,
                                priorityBoarding
                            );
                        }}
                        isActive={handBagIsActive2}
                    />
                </>
            );
        case CASE_4_FREE_PRIORITY_BOARDING_ONLY:
            setHandBagOneOption(true);
            return (
                <>
                    <PersonalItemAndCabin
                        personalItemDimensions={personalItemDimensions}
                        cabinBagDimensions={cabinBagDimensions}
                        price={0}
                        isActive={true}
                        handleOnClick={() => {
                            console.log('There is no handle click function.');
                        }}
                    />
                </>
            );

        case CASE_5_PAID_CABIN_NO_PERSONAL:
            setHandBagOneOption(false);
            return (
                <>
                    <NoCabinBaggageOptionElement
                        handleOnClick={() => {
                            !handBagIsActive1 && removePurchasedBaggage(prefix, cabin_Bag);
                            setHandBagIsActive1(true);
                            setHandBagIsActive2(false);
                        }}
                        isActive={handBagIsActive1}
                    />
                    <BaggageElement
                        dimensions={cabinBagDimensions}
                        price={priorityBoarding}
                        title="Cabin bag"
                        icon={faShoppingBag}
                        handleOnClick={() => {
                            setHandBagIsActive1(false);
                            setHandBagIsActive2(true);
                            setPurchasedBaggage(prefix, cabin_Bag, priorityBoarding);
                        }}
                        isActive={handBagIsActive2}
                    />
                </>
            );

        case CASE_6_CABIN_BAG_ONLY:
            setHandBagOneOption(true);
            return (
                <BaggageElement
                    dimensions={cabinBagDimensions}
                    price={0}
                    title="Cabin bag"
                    icon={faShoppingBag}
                    isActive={true}
                    handleOnClick={() => {
                        console.log('There is no handle click function.');
                    }}
                />
            );

            case CASE_10_PERSONAL_ITEM_ONLY:
                setHandBagOneOption(true);
                return (
                    <BaggageElement
                        dimensions={personalItemDimensions}
                        price={0}
                        title="Cabin bag"
                        // title="Personal item"
                        icon={faShoppingBag}
                        isActive={true}
                        handleOnClick={() => {
                            console.log('There is no handle click function.');
                        }}
                    />
                );

        default:
            return <h3>There has been an error.</h3>;
    }
};
