import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { cx } from 'emotion';
import { trackTravelCategoryClick } from 'Utils';

export const ToggleElement = ({
  activeCategory,
  handleOnClick,
  cardId,
  text,
  isVisible
}) => {
  const toggleElement = () => {
    if (cardId === 'Packages') {
      // message.success('Package holidays will be available January 12th', 4);
      handleOnClick();

    } else {
      handleOnClick(cardId);
    }
    trackTravelCategoryClick(cardId);
  };
  return (
    isVisible && 
    <ToggleElementWrapper onClick={() => toggleElement()}>
      <div className="toggleCircle">
        <div
          className={cx(
            activeCategory === cardId ? 'innerCircle' : 'innerCircleActive'
          )}
        />
      </div>
      <div className="toggleLabel">{text}</div>
    </ToggleElementWrapper>
  );
};

ToggleElement.propTypes = {
  activeCategory: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

const ToggleElementWrapper = styled.div`
  display: inline-flex;
  margin-right: 12px;

  .toggleCircle {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: white;
    border: solid 2px #d1d1d1;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .innerCircle {
      /* background-color: #0015a3; */
      background-color: black;
    }

    .innerCircleActive {
      background-color: transparent;
    }

    .innerCircle,
    .innerCircleActive {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }

  .toggleLabel {
    color: black;
    font-size: 12px;
    display: inline-block;
    margin-top: -1px;
    font-weight: 600;
  }
`;
