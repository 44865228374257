import React, { useContext, useEffect, useState } from 'react';
import { HeroHeader } from './components/Headers/HeroHeader';
import { PackagesPageWrapper } from './pageWrappers';
import { ProceedButtonCheckout } from '../../Checkout/FormStepController/ProceedButtonCheckout';
import { message } from 'antd';
import { MappedPackages } from './MappedPackages';
import { PackagesContext } from '../../Context/PackagesContext';
import { ErrorBoundary } from '../../components/ErrorBoundary';

/*
@TODO LIST:
a) Connect checkout to the packages booking flow. [DONE]
b) Add form for the dates. [DONE]
c) Store booking data and send via email (firbase). [DONE]
d) Create segments / categories for the packages. 
e) The hotel details text is not customized for all packages. 
f) Create an images slider for about 20 images. Find a way to store these images. 
g) Create dashboard for these bookings. 
*/

export const PackagesPage = () => {
  const [packagesState, setPackagesState] = useState(false);
  const context = useContext(PackagesContext);
  const { packagesArray, setSelectedPackageContext } = context;

  useEffect(() => {
    if (packagesArray !== null && packagesArray.hasOwnProperty('length')) {
      setPackagesState(packagesArray);
    }
  }, [packagesArray]);

  const saveSelectPackage = selectedPackage => {
    console.log('[SAVE SELECT PACKAGE FUNCTION] The selected package is....', selectedPackage);
    setSelectedPackageContext(selectedPackage);
  };

  return (
    <ErrorBoundary>
      <PackagesPageWrapper>
        <div className="gridWrapper">
          <div style={{ gridColumn: '1/4' }}>
            <HeroHeader />
          </div>
          <MappedPackages
            packagesArray={packagesState}
            saveSelectedPackage={saveSelectPackage}
          />
          <br />
          <br />
          <ProceedButtonCheckout
            isProceedButtonVisible={true}
            buttonText="View more deals"
            handlerFunction={() => {
              message.warning(
                'Flymble is adding more cheap holidays & deals, and will be back shortly.',
                5
              );
              window.dataLayer.push({
                event: 'view-more-deals-button',
              });
            }}
          />
        </div>
      </PackagesPageWrapper>
    </ErrorBoundary>
  );
};
