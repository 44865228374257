import React from 'react';
import styled from 'react-emotion/macro';
import { useTranslation } from 'react-i18next';
import { howItWorksSections } from './howItWorksSections';
import { RegulatoryTextLineFNPL } from '../RegulatoryTextLineFNPL';

const flymbleBlue = '#4860ff';
const backgroundColor = '#F9F9FA';

const HowItWorksBg = styled('div')`
  background-color: ${backgroundColor} !important;
  width: 100%;
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
  }
`;

const HowItWorksWrapper = styled('div')`
  background-color: ${backgroundColor};
  padding-top: 25px;


  @media (max-width: 1000px) {
    border-top: 2px solid hsla(0,0%,39%,.1);
    border-bottom: 2px solid hsla(0,0%,39%,.1);
    padding-bottom: 50px;
  }

  
  @media (min-width: 1000px) {
    padding-top: 20px;
    padding-bottom: 40px;
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    h2 {
      grid-column-start: span 3;
    }
    border-top: none;
  }


  padding-top: 25px;
  width: 100%;
  border-top: 1px #ddd solid;

  h2 {
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 25px;
      font-size: 28px;
    @media (min-width: 1000px) {
      padding-bottom: 55px;
      font-size: 36px;
    }
  }

  h3 {
    margin-top: 0px;
    font-size: 18px;

    @media(min-width: 1000px) {
      font-size: 20px;
    }
  }
  
  > div {
    display: grid;
    margin-left: 15px;
    margin-right: 15px;
    grid-template-columns: 40px minmax(200px, auto);
    grid-column-gap: 10px;

    @media (min-width: 1000px) {
      grid-template-columns: 60px minmax(160px, auto); 
      grid-column-gap: 40px;
    }
    
    > div:nth-child(odd) {
      color: ${flymbleBlue};
      font-weight: 600;
      font-size: 1.6em;
      text-align: center;
      /* border-bottom: 2px solid ${flymbleBlue}; */
      max-height: 2em;
      max-width: 50px;

      @media(min-width: 1000px) {
        max-height: 1.9em;
        font-size: 36px;
        width: 100%;
        max-width: 78px;
      border-bottom: 2px solid ${flymbleBlue};
      }
    }

    > div:nth-child(even) {
      color: #1e1e1e;

      font-size: 14px;
      line-height: 1.5em;
    }

  }
  
  background-color: ${backgroundColor};

.sectionCard {
  margin-bottom: 15px;
  .sectionNumber {
    margin-top: 5px;
    }

}


`;

const HowItWorks = ({ sections = howItWorksSections, titleText }) => {
  const { t } = useTranslation();
  let title = titleText ? titleText : t('EasySection..How does it work?');
  return (
    <HowItWorksBg>
      <HowItWorksWrapper>
        <h2>{title}</h2>
        {sections.map(section => (
          <div className="sectionCard" key={section.nr}>
            <div className="sectionNumber">{section.nr}</div>
            <div>
              <h3>{section.title}</h3>
              <p>{section.text}</p>
            </div>
          </div>
        ))}
      </HowItWorksWrapper>
      <RegulatoryTextLineFNPL />
    </HowItWorksBg>
  );
};
export default HowItWorks;
export { HowItWorks };
