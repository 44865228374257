import set from 'ramda/src/set';
import flip from 'ramda/src/flip';
import pipe from 'ramda/src/pipe';
import join from 'ramda/src/join';
import isNil from 'ramda/src/isNil';
import ifElse from 'ramda/src/ifElse';
import pathOr from 'ramda/src/pathOr';
import pathEq from 'ramda/src/pathEq';
import hasPath from 'ramda/src/hasPath';

import { formatToHour } from 'Utils/formatToHour';

import * as L from './lenses';

import { IFilters } from './types';

// TODO: refactor this
const handleFilters = (filters: IFilters) => {
  const filterParams: any = { select_airlines_exclude: [] };

  const filtersHasPath = flip(hasPath)(filters);
  const filtersPathEq = flip(pathEq)(filters);

  // TODO: check if obj mutation make sense here
  switch (true) {
    case filtersHasPath(['stopovers', 'zero']):
      filterParams.max_stopovers = 0;
    case filtersHasPath(['stopovers', 'one']):
      filterParams.max_stopovers = 1;
    case filtersHasPath(['stopovers', 'twoPlus']):
      filterParams.max_stopovers = 2;
    case filtersPathEq(['rstopovers'], "zero"):
      filterParams.max_stopovers = 0;
    case filtersPathEq(['rstopovers'], "one"):
      filterParams.max_stopovers = 1;
    case filtersPathEq(['rstopovers'], "twoPlus"):
      filterParams.max_stopovers = 2;
    case filtersHasPath(['departureHours']):
      filterParams.dtimefrom = formatToHour(filters.departureHours.min);
      filterParams.dtimeto = formatToHour(filters.departureHours.max);
    case filtersHasPath(['returnHours']):
      filterParams.returndtimefrom = formatToHour(filters.returnHours.min);
      filterParams.returndtimeto = formatToHour(filters.returnHours.max);
  }

  // TODO: refactor this to an external handler
  // AIRLINES EXCLUSION - START
  // AA,UA excluded
  // to turn off - define an empty array below
  // const bannedAirlines = ['AA', 'UA'] and concat with excludeAirlines
  filterParams.select_airlines = pipe(
    pathOr([], ["excludeAirlines"]),
    join(',')
  )(filters)

  // TODO: do we really need to set select_airlines_exclude as an array first
  // and then change it to the boolean?
  filterParams.select_airlines_exclude =
    filterParams.select_airlines.length > 0 ? 'true' : 'false';
  // AIRLINES EXCLUSION - END

  return filterParams
};

const handleNoFilters = pipe(
  set(L.selectAirlinesLens, ''),
  set(L.selectAirlinesExcludeLens, true),
)

export const parseFilters = ifElse(
  isNil,
  handleNoFilters,
  handleFilters,
)