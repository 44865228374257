import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';

const flymbleBlue = '#4860ff';
const almostBlack = '#1a1a1a';
const blueyGrey = '#f2f2f2';
const inputBackgroundColor = '#f6f6f6';
const inputSize = 48;
const inputFontSize = 14;
const inputMarginLeft = 38;

export const styleTab = css`
  padding: 8px 5px;
  padding-left: 12px;
  font-size: 15px;
  margin: 0 5px;
  font-size: 15px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
  background-color: ${blueyGrey};
  & h3 {
    font-weight: 400;
    margin: 0 0 10px 0;
    font-size: 15px;
    @media (max-width: 380px) {
      font-size: 14px;
    }
  }
  .microScreen {
    display: none;
  }
  @media (max-width: 330px) {
    .microScreen {
      display: block;
    }
    .normalScreen {
      display: none;
    }
  }
  & > span {
    display: inline-block;
    color: ${almostBlack};
    font-size: 12px;
    float: right;
  }
  & > span.price,
  & > span.priceDesktop {
    display: none;
    font-weight: 400;
    min-width: 30%;
    float: left;
    font-size: 18px;
  }
  & > span.price {
    @media (max-width: 1000px) {
      display: inline;
    }
  }
  & > span.priceDesktop {
    @media (min-width: 1000px) {
      display: inline;
      min-width: 50%;
    }
  }
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &[aria-selected='true'] {
    h3 {
      color: white;
    }
    color: white;
    background-color: ${flymbleBlue};
    span {
      color: white;
    }
  }
  &:focus {
    outline: none;
  }
  span.tabDuration {
    text-align: right;
    width: 40%;
  }
  @media (max-width: 1000px) {
    span.tabDuration {
      display: none;
    }
  }
  @media (min-width: 1000px) {
    padding: 10px 14px 10px 15px;

    & h3 {
      font-size: 18px;
    }
    & > span {
      font-size: 16px;
      float: unset;
    }
  }
`;

export const WrapperResult = styled.div`
  background-color: #f9f9fa;

  @media (min-width: 600px) {
    padding: 110px 15px 0 15px;
    padding-top: 30px;
  }

  & #tickets2,
  & #tickets3 {
    display: none;
  }

  & #tickets2.show,
  & #tickets3.show {
    display: block;
  }
`;

export const FilterButton = styled.button`
  padding: 8px;
  border: solid 1px #4860ff;
  font-size: 13px;
  color: #4860ff;
  border-radius: 3px;
  white-space: nowrap;
  min-width: 70px;
  line-height: 14px;
  background-color: white;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.75);
  position: sticky;
  top: 10px;
  z-index: 50;
  width: 100%;

  & .chevron {
    display: flex;
    margin-left: 10px;
  }

  & .spinner {
    margin-right: 10px;
  }

  &:disabled {
    color: #888;
  }

  &:focus {
    outline: 0;
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f9f9facc;
`;

export const marginTop3x = css`
  margin-bottom: 0;
`;

export const fullWide = css`
  width: 100%;
  margin-bottom: 14px;
`;

export const dateRangeSpan = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  @media (min-width: 700px) {
    .ant-input {
      padding: 4px 175px;
    }
  }
`;

export const controlHeight = css`
  height: ${inputSize}px;
`;

// 
// This code styles the Searchform. It is exported to FrontSearch.js top keep
// the <form> as the outer element of the component.
export const SearchMobileStyle = css`
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  color: rgb(72, 96, 255);
  .passengersGrid {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    .searchButton {
      /* grid-column: 1/3;
      margin-top: 10px; */
    }
  }

  @media (min-width: 1000px) {
    padding-bottom: 8px;
    padding-top: 16px;
    padding-right: 18px;
    padding-left: 18px;
    .searchFormGrid {
      display: grid;
      grid-template-areas:
        'additionalOption additionalOption additionalOption additionalOption additionalOption additionalOption additionalOption'
        'flyingFrom flyingFrom flyingFrom flyingTo flyingTo flyingTo flyingTo'
        'dateFrom dateFrom  noPassengers noPassengers noPassengers noPassengers searchButton';
      grid-template-rows: auto;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;

      .passengersGrid {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  .datePickerGridMobile {
    grid-area: dateFrom;
  }
  .adultsNONE {
    grid-area: noAdults;
  }
  .childrenNONE {
    grid-area: noChildren;
  }
  .flyingFrom {
    grid-area: flyingFrom;
  }
  .flyingTo {
    grid-area: flyingTo;
  }
  .passengersGrid {
    grid-area: noPassengers / noPassengers / noPassengers / searchButton;
  }

  .datePickerGridMobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }
  button {
    color: rgb(72, 96, 255);
    border-color: rgb(72, 96, 255);
    font-family: 'Roboto', 'Source Sans Pro', sans-serif;
    font-weight: 500;
  }
  button.ant-btn-primary {
    color: white;
    border-color: ${flymbleBlue};
    background-color: ${flymbleBlue};

    @media (max-width: 1000px) {
      margin-top: 10px;
    }

    @media (min-width: 1000px) {
      background-color: rgb(72, 96, 255);
      border-color: rgb(72, 96, 255);
      border: none;
      background-color: #13c29b;
      box-shadow: none;
      padding-top: 2px;
    }
  }
  /* antd form validation message */
  & div.ant-form-explain {
    display: none;
  }
  /* antd adjust form labels */
  & div.ant-select {
    font-size: 10px;
  }
  /* adjust placeholder position */
  & div.ant-select-selection__placeholder {
    top: 66%;
    font-size: ${inputFontSize}px;
  }
  & .ant-select-auto-complete.ant-select .ant-input {
    padding-top: 18px;
    height: ${inputSize}px;
    font-size: ${inputFontSize}px;
  }
  & .ant-select-auto-complete.ant-select .ant-select-selection__rendered,
  & .ant-select-selection__rendered {
    line-height: ${inputSize}px;
  }
  /* datepicker customization */
  & .ant-input {
    padding-left: ${inputMarginLeft - 2}px;
    height: ${inputSize}px;
    padding-top: 14px;
  }
  & .ant-calendar-picker-input.ant-input {
    padding-top: 20px;
  }
  & .ant-select-selection--single {
    height: ${inputSize}px;
  }
  & .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: ${inputMarginLeft}px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
  }
  & input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
  & .ant-select-combobox .ant-select-search__field__wrap,
  & .ant-select-auto-complete.ant-select .ant-select-search--inline {
    height: ${inputSize}px;
  }
  & div.ant-select-selection__rendered {
    top: 12%;
    font-size: 14px;
    span {
      padding-left: 26px;
    }
  }
  /* passengers customization */
  & .ant-form-item-control {
    line-height: unset;
  }
  /* gray border removal */
  /* for validation it is possible to add separate selector
   .has-error .ant-select-selection with red color 
    cause of precedence */
  & .ant-select-selection {
    /* border-color: transparent; */
  }
  & .has-error .ant-input {
    border-color: #f5222d;
  }
  /* blue border on active/focus element */
  & .ant-select-auto-complete.ant-select .ant-input:focus,
  & .ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: ${flymbleBlue};
    border-width: 2px;
  }
  & .ant-select-selection:focus {
    border-color: ${flymbleBlue};
    border-width: 2px;
  }
  & .ant-calendar-picker-icon:after {
    display: none;
  }
  /* for antd 3.8.* */
  & .anticon-calendar:before,
  & .anticon-down:before,
  & i.anticon.anticon-calendar {
    display: none;
  }
  & i.anticon.anticon-down {
    display: flex;
  }
`;

export const SearchMobileLPWrapper = styled('div')`
  ${SearchMobileStyle}
`;

export const fake2 = css`
  /* & .ant-select-combobox .ant-select-search__field__wrap {
    background-color: ${inputBackgroundColor};
  } */
      /* & .ant-input {
      background-color: ${inputBackgroundColor};
    } */
`;

export const fake = css``;

export const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: Black;
  position: relative;
  top: 12px;
  margin-left: ${inputMarginLeft}px;
  z-index: 100;
  line-height: 0;
`;

export const FormIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  top: ${props => props.top}px;
  left: 12px;
`;

export const RadioWrapper = styled.div`
  display: ${props => (props.isOneWayEnabled ? 'block' : 'none')};
  grid-area: additionalOption;
  max-width: 260px;
  .ant-radio-group {
    max-width: 280px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-weight: 700;
    margin-bottom: 6px;

    label {
      color: #000;
    }

    @media (min-width: 1000px) {
      font-weight: normal;
      grid-template-columns: 1fr 1fr;

      label {
        color: ${props => (props.light ? '#000' : '#fff')};
      }
    }
  }
`;
