import React from 'react';
import { PageHeaderWrapper } from './styles';

export const HeroHeader = () => {
  return (
    <PageHeaderWrapper>
      <div className="title">All-inclusive holiday deals</div>
      <div className="subTitle">
        Spread the cost over 3-12 monthly instalments and lock-in up to 60%
        discounts.
      </div>
    </PageHeaderWrapper>
  );
};
