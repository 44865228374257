import React, { useContext } from 'react';
import i18n from 'Utils/i18n.js';
import { faCheckCircle as faCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Steps } from 'antd';
import { BookingSteps as BookingStepsWrapper } from '../styles';
import styled from 'react-emotion/macro';
import { MobileOnly, DesktopOnly } from 'routes/LandingPage/components';
import TrustPilotBrandText from 'assets/images/trustpilot-vector-logo.svg';
import TrustPilotStars from 'assets/images/trustpilot-stars.png';
import { FORMSTEPS } from 'Utils';
import AppContext from 'Context/AppContext';

const Step1BreadCrumb = () => (
  <>
    <span className="active">1. Passengers</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;2.</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;3.</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;4.</span>
  </>
);

const Step2BreadCrumb = () => (
  <>
    <span>1. {">"}</span>
    <span className="active">&nbsp;2. Contact details</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;3.</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;4.</span>
  </>
);

const Step3BreadCrumb = () => (
  <>
    <span>1. {">"}</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;2.</span>
    <span className="active">&nbsp;3. Living address</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;4.</span>
  </>
);

const Step4BreadCrumb = () => (
  <>
    <span>1. {">"}</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;2.</span>
    <span>{">"}&nbsp;&nbsp;&nbsp;3.</span>
    <span className="active">&nbsp;4. Employment</span>
  </>
);

const BreadCrumbDisplay = ({ currentFormStep }) => {
  const { STEP1, STEP2, STEP3, STEP4, STEP0 } = FORMSTEPS;
  switch (currentFormStep) {
    case STEP0:
      return null;
    case STEP1:
      return <Step1BreadCrumb />;
    case STEP2:
      return <Step2BreadCrumb />;
    case STEP3:
      return <Step3BreadCrumb />;
    case STEP4:
      return <Step4BreadCrumb />;
    default:
      console.log(
        'The handleProceedForm() function is called at an undefined place'
      );
  }
};

const ProgressBookingSteps = () => {
  const Step = Steps.Step;
  const context = useContext(AppContext);

  return (
    <>
      <NavigationStepsWrapper mobileOnly>
        <div className="trustPilotSection">
          <img
            className="trustPilotBrandText"
            src={TrustPilotBrandText}
            alt="/"
          />
          <img className="trustPilotStars" src={TrustPilotStars} alt="/" />
        </div>
        {window.location.pathname.indexOf('checkout') !== -1 && (
          <div className="breadCrumbs">
            <BreadCrumbDisplay
              currentFormStep={context.currentFormStepContext}
            />
          </div>
        )}
      </NavigationStepsWrapper>
      <BookingStepsWrapper desktopOnly>
        <h2>{i18n.t('Checkout..Next Steps')}</h2>
        <Steps direction="horizontal" current={1}>
          <Step
            title={i18n.t('Checkout..Select flight ticket')}
            icon={<FontAwesomeIcon icon={faCheck} className="arrowIcon" />}
          />
          <Step
            title={i18n.t('Checkout..Fill in your personal details')}
            description={i18n.t('Checkout..Or call us to book')}
          />
          <Step title={i18n.t('Checkout..We process your application')} />
          <Step title={i18n.t('Checkout..Flights booked!')} />
        </Steps>
      </BookingStepsWrapper>
    </>
  );
};

export { ProgressBookingSteps };

const NavigationStepsWrapper = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  text-align: left;

  .trustPilotSection {
    padding: 12px 12px;
    border-bottom: 1px solid #e8edf1;
    .trustPilotBrandText {
      max-height: 16px;
      display: block;
    }
    .trustPilotStars {
      max-height: 18px;
      display: block;
      margin-top: 7px;
    }
  }

  .breadCrumbs {
    padding: 12px 12px;
    span {
      margin-right: 10px;
      font-size: 15px;
      /* color: #171b1e; */
      color: black;
      font-weight: 600;

      :not(.active) {
        color: #adb9c5;
      }
    }
  }
`;
