import styled from 'react-emotion/macro';

export const TravelDisplayStyle = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1000px) {
    &.oneway {
      grid-column: 1/2;

      .travelDisplayHolder {
        grid-template-columns: 6fr 40fr 6fr;
      }
    }
  }

  @media (max-width: 1000px) {
    grid-column: 1/3;
  }

  .travelDisplayHolder {
    display: inline-block;
    padding-bottom: 12px;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    @media (min-width: 1000px) {
      grid-template-columns: 6fr 7fr 6fr;
    }

    text-align: left;
    font-weight: 300;
    font-size: 14px;

    .airportCodeFrom {
      padding-left: 17px;
      grid-row: 2/3;
      @media (min-width: 1000px) {
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 5px;
      }
    }

    .airportCodeTo {
      padding-left: 17px;
      grid-column: 3/4;
      grid-row: 2/3;
      @media (min-width: 1000px) {
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        padding-right: 30px;
      }
    }

    .departureTime {
      font-size: 16px;
      font-weight: bold;
      padding-left: 17px;
      grid-column: 1/2;
      @media (min-width: 1000px) {
        text-align: left;
        padding-bottom: 5px;
        font-size: 22px;
      }
    }

    .arrivalTime {
      font-size: 16px;
      font-weight: bold;
      padding-left: 17px;
      grid-column: 3/4;
      grid-row: 1/2;

      @media (min-width: 1000px) {
        text-align: right;
        padding-right: 30px;
        font-size: 22px;
      }
    }

    /* Information only for desktop */
    .departureRouteDay {
      grid-column: 1/2;
      padding-left: 17px;

      @media (min-width: 1000px) {
        font-size: 14px;
        color: #1b1b1b;
        font-weight: 200;
      }

      @media (max-width: 1000px) {
        display: none;
        padding-bottom: 5px;
      }
    }

    .departureRouteCity {
      grid-column: 1/2;
      padding-left: 17px;

      @media (min-width: 1000px) {
        font-size: 14px;
        color: #1b1b1b;
        font-weight: 200;
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .arrivalRouteDay {
      grid-column: 3/4;
      grid-row: 3/4;
      text-align: right;
      padding-right: 30px;
      color: #1b1b1b;
      font-weight: 200;
      @media (max-width: 1000px) {
        display: none;
      }
    }

    .arrivalRouteCity {
      grid-column: 3/4;
      text-align: right;
      padding-right: 30px;
      color: #1b1b1b;
      font-weight: 200;

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .routeStopsWrapper {
      text-align: center;
      color: black;
      font-size: 12px;
      .directFlight {
        color: #20e3b7;
        font-weight: 400;
        font-size: 11px;

        @media (min-width: 1000px) {
          font-size: 12px;
        }
      }
      @media (max-width: 1000px) {
        grid-column: 2/3;
        grid-row: 1/3;
      }
      @media (min-width: 1000px) {
        grid-row: 1/3;
        grid-column: 2/3;
        font-size: 12px;
      }
    }

    .totalFlightDuration {
      display: block;
      text-align: center;
      color: black;
      font-size: 12px;

      @media (min-width: 1000px) {
        font-size: 12px;
      }
    }
  }
`;

export const CircleStop = styled.div`
  width: 6px;
  text-align: left;
  height: 6px;
  border-radius: 100%;
  border: 1px solid #133c55;
  background-color: #fff;
  z-index: 10;
  vertical-align: middle;
  display: inline-block;
`;

export const LineStopHorizontal = styled.div`
  text-align: center;
  width: 70%;
  height: 1px;
  background-color: #9b9b9b;
  display: inline-block;
  vertical-align: middle;
`;

export const LineStopVertical = styled.div`
  text-align: center;
  height: 30px;
  width: 1px;
  background-color: #9b9b9b;
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
  margin-bottom: -6px;
  margin-left: -0.04vw;
`;
