import React, { useState, useContext, useEffect } from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import i18n from 'Utils/i18n.js';
import { FlightTicketFunction } from 'SearchResultsPage/MappedFlightTickets';
import { MobileOnly, DesktopOnly } from 'routes/LandingPage/components';
import { ViewDetailsToggleButton } from 'components/ViewDetailsToggleButton';
import AppContext from 'Context/AppContext';
import { FORMSTEPS } from 'Utils';
import { BOOKING_TYPES } from '../../constants';

const ViewTripDetails = ({ children }) => {
  const [isTripDetailVisible, setIsTripDetailVisible] = useState(false);

  const context = useContext(AppContext);
  const {
    currentFormStepContext,
    selectedFlightTicket,
    typeBookingContext,
  } = context;

  useEffect(() => {
    if (window.location.pathname.indexOf('confirmation') !== -1) {
      setIsTripDetailVisible(true);
    } else if (currentFormStepContext !== FORMSTEPS.STEP1) {
      setIsTripDetailVisible(false);
    } else if (window.location.pathname.indexOf('confirmation') !== -1) {
      setIsTripDetailVisible(true);
    } else if (window.location.pathname.indexOf('pay-booking') !== -1) {
      if (typeBookingContext === BOOKING_TYPES.STRIPE_Flights) {
        setIsTripDetailVisible(true);
        } else setIsTripDetailVisible(false);
      // } else setIsTripDetailVisible(true);
    }
  }, [currentFormStepContext, window.location.pathname]);

  return (
    <>
      <ViewDetailsWrapper
        isOneWay={selectedFlightTicket.duration.return === 0}
        mobileOnly
      >
        <ViewDetailsToggleButton
          isTurnedUp={isTripDetailVisible}
          handlerFunction={setIsTripDetailVisible}
          text={isTripDetailVisible ? 'View trip details' : 'View trip details'}
        />
        {isTripDetailVisible && (
          <div className="viewDetailsOpen">
            <h2>{i18n.t('FlightInformation..Your flight details')}</h2>
            {FlightTicketFunction(selectedFlightTicket, 10, 1)}
            {children}
          </div>
        )}
      </ViewDetailsWrapper>
      <ViewDetailsWrapper
        isOneWay={selectedFlightTicket.duration.return === 0}
        desktopOnly
      >
        <h2>{i18n.t('FlightInformation..Your flight details')}</h2>
        {FlightTicketFunction(selectedFlightTicket, 10, 1)}
      </ViewDetailsWrapper>
    </>
  );
};

export { ViewTripDetails };

ViewTripDetails.propTypes = {
  children: PropTypes.object.isRequired,
};

const ViewDetailsWrapper = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};

  h1 {
    font-size: 18px;
    font-weight: 300;
  }

  hr,
  .viewDetailsWrapper,
  .priceDisplay,
  .priceTag,
  .selectFlightTicket {
    display: none !important;
  }

  .showDetailsDesktop {
    display: inline-block !important;
    padding-bottom: 3px;
  }

  .viewDetailsOpen {
    /* padding-bottom: 25px; */
  }

  @media (min-width: 1000px) {
    text-align: left;

    h1 {
      font-size: 38px;
    }

    /* 
  This code is used to modify the Flight Ticket on the Desktop view, on the checkout page. 
  There needs to be a small adjustment depending on wether the ticket is oneway or not one way. 
  */
    .searchResultCheckoutGrid {
      > div {
        grid-template-columns: ${props =>
          props.isOneWay ? '1fr' : '1fr 1fr'} !important;
        max-width: 100%;
      }
    }

    .bottomRowWrapperDisplay {
      display: none !important;
    }

    .detailsPaymentPlanDisplay {
      display: none !important;
    }
  }

  .viewDetailsToggle {
    text-align: center;
    font-weight: 500;
    color: black;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .viewDetailsIcon {
    margin-left: 10px;
    padding: 4px;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    display: inline-block;
    cursor: pointer;
    border: 1px #80808091 solid;
    border-radius: 50%;
    vertical-align: middle;
  }

  @media (max-width: 1000px) {
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 3px solid #0015a3;

    .totalFlightDuration {
      display: inline-block !important;
    }
    h2 {
      display: none;
    }

    .searchResultCheckoutGrid {
      p,
      span,
      b {
        text-align: left !important;
      }
      .viewDetailsMobileButton {
        width: 100% !important;
        grid-column: 2/3;
        text-align: center !important;
        width: 30px;
        height: 30px;
      }
    }
  }

  .priceUnit {
    display: none;
  }
`;
