import 'antd/lib/progress/style/index.css'; // for css
import React, { Fragment } from 'react';
import { CancelationPolicy } from './CancelationPolicy';
import CustomerInformationSection from './CustomerInformationSection';
import moment from 'moment';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      progress: 0,
    };
  }

  static propTypes = {
    data: PropTypes.object,
    isVisible: PropTypes.bool,
    children: PropTypes.array,
    isPayInFull: PropTypes.bool.isRequired,
  };

  static defaults = {
    isShortVersion: false,
    isLoading: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalProgress);
  };

  render() {
    const { data, children, isVisible } = this.props;
    var idx = 0;
    var passengers = [];
    while (typeof data[`P${idx}_firstname`] !== 'undefined') {
      var dob = moment(),
        idExp = moment();
      // Warning, Moment indees months from 0, so December = 11
      dob
        .utc()
        .year(parseInt(data[`P${idx}_dobYear`], 10))
        .month(parseInt(data[`P${idx}_dobMonth`], 10) - 1)
        .date(parseInt(data[`P${idx}_dobDay`], 10));
      if (data[`P${idx}_idNoExp`] !== true) {
        idExp
          .utc()
          .year(parseInt(data[`P${idx}_idExpYear`], 10))
          .month(parseInt(data[`P${idx}_idExpMonth`], 10) - 1)
          .date(parseInt(data[`P${idx}_idExpDay`], 10));
      }
      passengers.push({
        firstname: data[`P${idx}_firstname`],
        lastname: data[`P${idx}_lastname`],
        nationality: data[`P${idx}_nationality`],
        gender: data[`P${idx}_gender`],
        dob: dob,
        idNumber: data[`P${idx}_idNumber`],
        idExp: idExp,
        idNoExp: data[`P${idx}_idNoExp`],
        additionalBaggage: data[`P${idx}_additionalBaggage`],
      });
      idx += 1;
    }

    return (
      isVisible && (
        <Fragment>
          <ConfirmationPageWrapper>
            <CustomerInformationSection
              data={data}
              passengers={passengers}
              isShortVersion={false}
              isPayInFull={this.props.isPayInFull}
            />
            {false && <CancelationPolicy />}
          </ConfirmationPageWrapper>
          {children}
        </Fragment>
      )
    );
  }
}

export default ConfirmationPage;

const ConfirmationPageWrapper = styled.div`
  padding-bottom: 0px;

  @media (min-width: 1000px) {
    padding-top: 28px;
    padding-bottom: 0px;
  }

  .overviewSubtitle {
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
  }

  .confirmationIcon {
    min-width: 25px;
    display: inline-block;
  }
`;

