/* eslint-disable max-len */
import React from 'react';
import { Switch } from 'react-router-dom';
import { css } from 'emotion/macro';
import STTRoute from './components/ScrollToTopRoute';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Loadable from 'react-loadable';

// Seperate pages are imported below here.
import LandingPage from './routes/LandingPage/index.js';
import MappedFlightTickets from './SearchResultsPage/MappedFlightTickets';
import SearchResult from './Page/SearchResult';
import ThankYouPage from './routes/ThankYouPage';
import pages from './pages.js';
import { KIND_OF_TRIP } from './constants';

import PackagesCheckoutPage from './Checkout/PackagesCheckoutPage/index.js';
import ConfirmationPage from './Checkout/ConfirmationPageContainer/index.js';
import CreditCheckPage from './Checkout/CreditCheckPageContainer/index.js';
import PackagesCreditCheckPage from './Checkout/CreditCheckPageContainer/packagesFNPL';
import PaymentPlanPage from './routes/PaymentPlanPage';
import { PackagesPage } from './routes/PackagesPage/index.js';
import { DealInformationContainer } from './routes/PackagesPage/DealInformationContainer';
import { FlightDetails } from './routes/PackagesPage/components/Details/FlightDetails';
import { HotelDetails } from './routes/PackagesPage/components/Details/HotelDetails';
import { MapDetails } from './routes/PackagesPage/components/Details/MapDetails';

/**
 * Loader - should be used only for big chunks of page, main views
 *
 * @param isLoading boolean indicates component is loading
 * @param pastDelay boolean indicates loading last longer than delay set
 *
 * Smaller elements do not require Loading placeholder at all.
 */
const FlymbleLoading = ({ isLoading, pastDelay, _error }) => {
  if (isLoading && pastDelay) {
    return (
      <div
        className={css`
          width: 100%;
          min-height: 400px;
          background-color: inherit;
          text-align: center;
          padding-top: 50%;
          padding-left: 45%;
        `}
      ></div>
    );
  } else return null;
};

const loadableDelay = 300;

const Search = Loadable({
  loader: () => import('./Page/Search'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const SearchResultMobile = Loadable({
  loader: () => import('./Page/Search'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const QuerySearchPage = Loadable({
  loader: () => import('./QuerySearch'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const CheckoutPage = Loadable({
  loader: () => import('Checkout/FirstCheckoutPageContainer'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const ThankYouPageRetail = Loadable({
  loader: () => import('./routes/ThankYouPage/Retail'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const ApplicationResult = Loadable({
  loader: () => import('routes/ApplicationResultPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const SearchFilters = Loadable({
  loader: () => import('./components/SearchFilters'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const DealSearchResult = Loadable({
  loader: () => import('./Page/Search/DealSearchResult'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const AboutUs = Loadable({
  loader: () => import('./routes/AboutUsPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const Help = Loadable({
  loader: () => import('./routes/HelpPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const Sitemap = Loadable({
  loader: () => import('./routes/SitemapPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const Transparency = Loadable({
  loader: () => import('./routes/TransparencyPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const ContactUs = Loadable({
  loader: () => import('./routes/HelpPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const Careers = Loadable({
  loader: () => import('./routes/CareersPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const ReviewPage = Loadable({
  loader: () => import('./routes/ReviewPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

// const Pricing = Loadable({
//   loader: () => import('./Pricing/Pricing'),
//   loading: props => FlymbleLoading(props),
//   delay: loadableDelay,
// });

const TermsConditions = Loadable({
  loader: () => import('./routes/LegalPages/TermsConditions'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const CookiesPolicy = Loadable({
  loader: () => import('./routes/LegalPages/CookiesPrivacy'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const RepresentativeExample = Loadable({
  loader: () => import('./routes/LegalPages/RepresentativeExample/index.js'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const SEODashboard = Loadable({
  loader: () => import('./routes/GuidesPage'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const BestFNPL2018 = Loadable({
  loader: () => import('./routes/Guides/articles/BestFNPL2018'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const ArticleHowToFNPL = Loadable({
  loader: () => import('./routes/Guides/articles/HowToFNPL'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const UltimateGuideFNPL = Loadable({
  loader: () => import('./routes/Guides/articles/UltimateGuideFNPL'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const HowToProtect = Loadable({
  loader: () => import('./routes/Guides/articles/HowToProtect'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const BestSpotsCanary = Loadable({
  loader: () => import('./routes/Guides/articles/BestSpotsCanary'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const HelpEnvironment = Loadable({
  loader: () => import('./routes/Guides/articles/HelpEnvironment'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const CookiesPrices = Loadable({
  loader: () => import('./routes/Guides/articles/CookiesPrices'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const BestFNPLFamily = Loadable({
  loader: () => import('./routes/Guides/articles/BestFNPLFamily'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const Ultimate20FNPL = Loadable({
  loader: () => import('./routes/Guides/articles/Ultimate20FNPL'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

const LinkGenerator = Loadable({
  loader: () => import('./components/LinkGenerator'),
  loading: props => FlymbleLoading(props),
  delay: loadableDelay,
});

/******************************/

const airportCityReg = '[acAC]_[a-zA-Z]{3}';
const dateReg = '\\d{8}';
const passengerReg = '[0-7]{1}';
const kinfOfTrip = `${KIND_OF_TRIP.ROUND}|${KIND_OF_TRIP.ONE_WAY}`;

const AppRouter = () => {
  const { t } = useTranslation();
  return (
    <main className={mainStyle}>
      <Switch>
        <STTRoute exact path="/" component={LandingPage} />
        <STTRoute
          exact
          path="/v1"
          component={LandingPage}
          compProps={{ variant: 'v1' }}
        />
        <STTRoute
          exact
          path="/v2"
          component={LandingPage}
          compProps={{ variant: 'v2' }}
        />
        <STTRoute
          exact
          path="/v3"
          component={LandingPage}
          compProps={{ variant: 'v3' }}
        />
        <STTRoute
          exact
          path="/v4"
          component={LandingPage}
          compProps={{ variant: 'v4' }}
        />
        <STTRoute
          exact
          path="/v5"
          component={LandingPage}
          compProps={{ variant: 'v5' }}
        />
        <STTRoute path={pages.aboutUs} component={AboutUs} />
        <STTRoute path="/help" component={Help} />
        <STTRoute
          path="/loading"
          component={() => FlymbleLoading({ isLoading: true })}
        />
        <STTRoute
          path={`${pages.querySearchPage}/:type(${kinfOfTrip})?/:to(${airportCityReg})/:from(${airportCityReg})?/:fromdate(${dateReg})?/:todate(${dateReg})?/:nadults(${passengerReg})?/:nchildren(${passengerReg})?/:ninfants(${passengerReg})?`}
          component={QuerySearchPage}
        />
        <STTRoute
          path={`${pages.querySearchPage}`}
          component={QuerySearchPage}
        />
        <STTRoute path="/search" component={MappedFlightTickets} />
        <STTRoute path="/sf" component={SearchFilters} />
        <STTRoute path="/transparency" component={Transparency} />
        <STTRoute path="/contact-us" component={ContactUs} />
        <STTRoute path="/terms-conditions" component={TermsConditions} />
        <STTRoute path="/cookies-policy" component={CookiesPolicy} />
        <STTRoute
          path="/representative-example"
          component={RepresentativeExample}
        />
        <STTRoute path="/sitemap" component={Sitemap} />
        <STTRoute path="/guides" component={SEODashboard} />
        <STTRoute path="/packages" component={PackagesPage} />
        <STTRoute
          path="/packages-deal-summary"
          component={DealInformationContainer}
        />
        <STTRoute
          path="/packages-deal-summary-flight-details"
          component={FlightDetails}
        />
        <STTRoute
          path="/packages-deal-summary-hotel-details"
          component={HotelDetails}
        />
        <STTRoute
          path="/packages-deal-summary-map-details"
          component={MapDetails}
        />
        <STTRoute path="/searchform" component={Search} />
        <STTRoute path="/searchresult" component={SearchResult} />
        <STTRoute path="/searchdeal" component={DealSearchResult} />
        <STTRoute path="/checkout" component={CheckoutPage} />
        <STTRoute path="/packages-checkout" component={PackagesCheckoutPage} />
        <STTRoute path="/payment-plan" component={PaymentPlanPage} />
        <STTRoute path="/thank-you" component={ThankYouPage} />
        <STTRoute path="/packages-thank-you" component={ThankYouPageRetail} />
        <STTRoute path="/application-result" component={ApplicationResult} />
        <STTRoute path="/linkgen" component={LinkGenerator} />
        <STTRoute path="/review-page" component={ReviewPage} />
        <STTRoute path="/join-our-team" component={Careers} />
        <STTRoute path={pages.flightResult} component={SearchResultMobile} />
        <STTRoute path={pages.guides.bestFNPL2018} component={BestFNPL2018} />
        <STTRoute
          path="/how-to-use-fly-now-pay-later-flights"
          component={ArticleHowToFNPL}
        >
          <Helmet>
            <title>
              {t('AppRouter..Helmet..How to Use Fly Now Pay Later Flights')}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/the-ultimate-fly-now-pay-later-guide"
          component={UltimateGuideFNPL}
        >
          <Helmet>
            <title>
              {t('AppRouter..Helmet..How to Use Fly Now Pay Later Flights')}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/how-to-protect-yourself-against-rising-airfares-with-fly-now-pay-later"
          component={HowToProtect}
        >
          <Helmet>
            <title>
              {t(
                'AppRouter..Helmet..How to protect yourself against rising airfares'
              )}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/best-hideout-spots-in-the-canary-islands"
          component={BestSpotsCanary}
        >
          <Helmet>
            <title>
              {t('AppRouter..Helmet..Best Hideout Spots in the Canary Islands')}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/how-we-help-the-environment"
          component={HelpEnvironment}
        >
          <Helmet>
            <title>
              {t(
                'AppRouter..Helmet..How We Help the Environment - Flights Are Bad for the Environment'
              )}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/do-cookies-increase-flight-prices"
          component={CookiesPrices}
        >
          <Helmet>
            <title>
              {t('AppRouter..Helmet..Do Cookies Increase Flight Prices?')}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/best-book-now-pay-later-flights-for-a-budget-family-trip"
          component={BestFNPLFamily}
        >
          <Helmet>
            <title>
              {t(
                'AppRouter..Helmet..Best Book Now Pay Later Flights for a Budget Family Trip'
              )}
            </title>
          </Helmet>
        </STTRoute>
        <STTRoute
          path="/the-ultimate-20-fly-now-pay-later-destinations"
          component={Ultimate20FNPL}
        >
          <Helmet>
            <title>
              {t(
                'AppRouter..Helmet..The Ultimate 20 Fly Now Pay Later Destinations'
              )}
            </title>
            <meta
              name="description"
              content={t(
                'AppRouter..Helmet..Most desirable flying locations in 2018. You will find all of them, with short description why and when you should fly there.'
              )}
            />
          </Helmet>
        </STTRoute>
        <STTRoute path="/confirmation" component={ConfirmationPage} />
        <STTRoute path="/pay-booking" component={CreditCheckPage} />
        <STTRoute
          path="/packages-pay-booking"
          component={PackagesCreditCheckPage}
        />
        <STTRoute component={LandingPage} />
      </Switch>
    </main>
  );
};

export { Loadable, FlymbleLoading, loadableDelay };
export default AppRouter;

const mainStyle = css`
  min-height: 400px;
  @media (min-width: 1000px) {
    min-height: 1000px;
  }
  /* TP widget */
  & > div > div > div.trustpilot-widget {
    @media (min-width: 600px) {
      display: none;
    }
    border-bottom: none;
    margin-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 5px;
  }
`;

// const Fallback = () => <div style={{ height: '600px' }}>LOADING</div>;
