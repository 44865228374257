import React, { Fragment } from 'react';

export const NotFound = () => (
  <Fragment>
    <div style={{padding: '20px'}}>
    <h4>404 page not found</h4>
    <h3>Oops! The page you are looking for does not exist.</h3>
    </div>
  </Fragment>
);
