import { PARTNERS, FORMSTEPS } from './';

// Search button on landing page.
const submitSearchButton = () => {
  window.fbq('track', 'Search');
  // equivalent for gtm
  window.dataLayer.push({ event: 'search-button-click' });
  window.dataLayer.push({ event: 'google-ads-search' });
};

// Select button on flight ticket element.
const trackSelectFlightTicket = () => {
  window.dataLayer.push({
    event: 'select-flight-button-click',
  });
};

// Track the partner and the amount of months that was selected on the payment plan page, and proceeds with to checkout.
const trackMonthsPartnerCombinationOnCheckout = (amountMonths, partner) => {
  let eventCombination;

  if (partner === PARTNERS.KLARNA) {
    eventCombination = 'checkout-klarna-button-click';
  } else if (partner === PARTNERS.FNPL) {
    eventCombination = `checkout-fnpl-${amountMonths}-months-button-click`;
  } else if (partner === PARTNERS.STRIPE) {
    eventCombination = 'checkout-stripe-button-click';
    console.log('pay in full payment plan @todo', eventCombination);
  }

  window.dataLayer.push({
    event: eventCombination,
  });
};

// Stripe events.
export const trackDirectPayment_VisitConfirmationPage = () =>
  window.dataLayer.push({ event: 'stripe-go-to-confirmation-btn' });
export const trackDirectPayment_VisitPaymentPage = () =>
  window.dataLayer.push({ event: 'stripe-go-to-payment-page-btn' });
export const trackDirectPayment_PayNowBtn = () =>
  window.dataLayer.push({ event: 'stripe-pay-now-btn' });

// Track service and protection.
export const trackAddServiceAndProtection = () => {
  window.dataLayer.push({ event: 'add-service-and-protection' });
};

// The all purpose function for the first checkout page.
const trackCheckoutPage = (ticketPrice, amountMonths, partner) => {
  // Facebook function
  window.fbq('track', 'InitiateCheckout', {
    value: ticketPrice,
    currency: process.env.REACT_APP_VARIANT_CURRENCY,
  });

  // window.mixpanel.track('InitiateCheckout');

  // Google Analytcis function
  trackMonthsPartnerCombinationOnCheckout(amountMonths, partner);

  window.dataLayer.push({
    event: 'track',
    event_custom_name: 'track-checkout-page',
    initiateCheckout: {
      value: ticketPrice,
      currency: process.env.REACT_APP_VARIANT_CURRENCY,
    },
  });
  window.dataLayer.push({
    event: 'google-ads-checkout',
  });
};

const trackCheckoutFormStep = formStep => {
  let step;
  const { STEP1, STEP2, STEP3, STEP4 } = FORMSTEPS;

  switch (formStep) {
    case STEP1:
      step = 'checkout-step-2-passenger-continue-button-click';
      break;
    case STEP2:
      step =
        'checkout-step-2-person-applying-contact-details-continue-button-click';
      break;
    case STEP3:
      step =
        'checkout-step-2-person-applying-living-address-continue-button-click';
      break;
    case STEP4:
      step = 'proceed-to-confirmation-button-click';
      break;
    default:
      step = 'checkout-step-2-default-or-undefined';
      console.log(
        'The track checkout form step function is called with an undefined step'
      );
  }

  if (window.location.pathname.indexOf('packages') !== -1) {
    step = `packages-${step}`;
  }

  window.dataLayer.push({
    event: step,
  });
};

// Press the "proceed to confirmation page button" on checkout page 1.
const trackProceedToConfirmation = bookingPrice => {
  window.fbq('track', 'CompleteRegistration', {
    value: bookingPrice,
    currency: process.env.REACT_APP_VARIANT_CURRENCY,
  });
};

// The confirmation button on the confirmation page.
const trackConfirmationPageButton = () => {
  let eventName = 'confirm-button-click';
  window.mixpanel.track('confirm-button-click');

  if (window.location.pathname.indexOf('packages') !== -1) {
    eventName = `packages-${eventName}`;
  }

  window.dataLayer.push({
    event: 'google-ads-confirmation',
  });
  window.dataLayer.push({
    event: eventName,
  });
};

const trackAuthorizeOrderButton = () => {
  window.dataLayer.push({
    event: 'authorize-card-button-click',
  });
};

// Proceed card details on checkout page.
const trackAddPaymentInfo = (ticketPrice = 0) => {
  window.fbq('track', 'AddPaymentInfo', {
    value: ticketPrice,
    currency: process.env.REACT_APP_VARIANT_CURRENCY,
  });
};

// Press the book now button on the confirmation page.
const trackBookNowButton = ticketPrice => {
  window.fbq('track', 'AddPaymentInfo', {
    value: ticketPrice,
    currency: process.env.REACT_APP_VARIANT_CURRENCY,
  });
};

// Old Passengers checkout form, Complete booking button.
const trackPassengersForm = () => {
  // window.fbq('track', 'Purchase', {
  window.fbq('track', 'PassengersFormComplete');
};

// Thank you page after FNPL process
const trackThankYouPage = value => {
  window.mixpanel.track('Purchase');
  window.fbq('track', 'Purchase', {
    value: value,
    currency: process.env.REACT_APP_VARIANT_CURRENCY,
  });
  window.dataLayer.push({
    event: 'google-ads-purchase',
  });
};

// Share flight ticket tracking.
const trackShareFlightTicket = () => {
  window.fbq('track', 'Donate');
};

// Add to Cart on Payment Plan Page.
const trackPaymentPlanPage = bookingPrice => {
  window.fbq('track', 'AddToCart', {
    content_name: 'Payment plan page',
    content_category: 'UK',
    content_ids: ['1234'],
    content_type: 'product',
    value: bookingPrice,
    currency: process.env.REACT_APP_VARIANT_CURRENCY,
  });

  window.dataLayer.push({
    event: 'google-ads-payment-plan',
  });
};

// Track if customer presses pay in full button.
const trackPayInFullButton = () => {
  trackMonthsPartnerCombinationOnCheckout(0, PARTNERS.STRIPE);
};

// Phone bookings / Retail functions
const trackRetailCallNow = () => {
  window.dataLayer.push({
    event: 'select-packages-call-now-button',
  });
};

const trackRetailCallMeBack = () => {
  window.dataLayer.push({
    event: 'select-packages-call-me-back-button',
  });
};

// Landing page search form travel category tests.
const trackTravelCategoryClick = id => {
  let eventName;
  switch (id) {
    case 'Round trip':
      eventName = 'round-trip-button-clik';
      break;

    case 'Multi-city':
      eventName = 'multicity-button-click';
      break;

    case 'Packages':
      eventName = 'packages-button-click';
      break;

    case 'Hotels':
      eventName = 'hotels-button-click';
      break;
    case 'Flights':
      eventName = 'flights-button-click';
      break;
    default:
      eventName = 'travel-category-click-undefined';
      break;
  }

  console.log('The button event is clicked:...', eventName);

  window.dataLayer.push({
    event: eventName,
  });
};

export const trackLowerPriceLimit = () => {
  window.dataLayer.push({
    event: 'lower-price-limit-modal'
  })
}

export const trackHigherPriceLimit = () => {
  window.dataLayer.push({
    event: 'higher-price-limit-modal'
  })
}


export {
  submitSearchButton,
  trackAddPaymentInfo,
  trackProceedToConfirmation,
  trackConfirmationPageButton,
  trackRetailCallNow,
  trackRetailCallMeBack,
  trackPassengersForm,
  trackThankYouPage,
  trackBookNowButton,
  trackShareFlightTicket,
  trackPaymentPlanPage,
  trackCheckoutPage,
  trackMonthsPartnerCombinationOnCheckout,
  trackSelectFlightTicket,
  trackCheckoutFormStep,
  trackAuthorizeOrderButton,
  trackTravelCategoryClick,
  trackPayInFullButton,
};
