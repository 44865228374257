import React from 'react';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FormIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  top: ${props => props.top}px;
  left: 12px;
`;

export const FormIcon = ({ icon, top = 0, focus = false }) => {
  const color = focus ? '#4860ff' : '#aaa';
  return (
    <FormIconWrapper top={top}>
      <FontAwesomeIcon icon={icon} size="1x" fixedWidth color={color} />
    </FormIconWrapper>
  );
};
