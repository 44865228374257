import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { calculatePrice } from './calculatePrice/index.js';

const contentAPI = 'https://content-management-flymble.herokuapp.com/deals';

export const PackagesContext = React.createContext();
export const PackagesConsumer = PackagesContext.Consumer;

export class PackagesProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutPackagesPriceContext: 777,
      packagesArray: null,
      selectedPackageContext: null,
      amountPassengersContext: 2,
      lastSearch: {
        noAdults: 2,
        noChildren: 0,
        noInfants: 0,
      },
      packagePriceObjectContext: {
        oldDealPriceAllPassengers: 777,
        dealPriceAllPassengers: 999,
      },
      amountRoomsContext: 1,
      amountNightsContext: 7,
      departureAirportContext: 'London Gatwick (LGW)',
      departureDateContext: '2020-04-06 5',
      amountNightsOfStayContext: 7,
      setAmountRoomsContext: this.setAmountRoomsContext,
      setAmountNightsContext: this.setAmountNightsContext,
      setSelectedPackageContext: this.setSelectedPackageContext,
      setDepartureAirportContext: this.setDepartureAirportContext,
      setDepartureDateContext: this.setDepartureDateContext,
      setCheckoutPackagesPriceContext: this.setCheckoutPackagesPriceContext,
      setLastSearch: this.setLastSearch,
      getPackagePriceObjectContext: this.getPackagePriceObjectContext,
    };
  }

  setDepartureAirportContext = airport => {
    this.setState({ departureAirportContext: airport });
  };

  setDepartureDateContext = date => {
    this.setState({ departureDateContext: date }, () =>
      this.getPackagePriceObjectContext()
    );
  };

  setAmountRoomsContext = amount => {
    this.setState({ amountRoomsContext: amount });
  };

  setAmountNightsContext = amount => {
    this.setState({ amountNightsContext: amount });
  };

  setSelectedPackageContext = selectedPackage => {
    this.setState({
      selectedPackageContext: selectedPackage,
    });
  };

  setCheckoutPackagesPriceContext = value => {
    this.setState({ checkoutPackagesPriceContext: value });
  };

  getPackagePriceObjectContext = selectedPackage_proxy => {
    const selectedPackage =
      selectedPackage_proxy || this.state.selectedPackageContext;
    const { noAdults, noChildren, noInfants } = this.state.lastSearch;
    const amountPassengers = noAdults + noChildren + noInfants;
    const priceObject = calculatePrice(
      selectedPackage,
      this.state.amountRoomsContext,
      this.state.amountNightsContext,
      this.state.departureDateContext,
      amountPassengers
    );

    console.log('[getPackagePriceObjectContext] the price object:', priceObject);


    this.setCheckoutPackagesPriceContext(priceObject.dealPriceAllPassengers);

    this.setState({ packagePriceObjectContext: priceObject });

    return priceObject;
  };

  setLastSearch = (value, afterCallback = () => {}) => {
    this.setState(
      prevState => ({
        lastSearch: {
          ...prevState.lastSearch,
          ...value,
        },
      }),
      () => {
        localStorage.setItem(
          'packagesAmountPassengers',
          JSON.stringify(this.state.lastSearch)
        );
        const { noAdults, noChildren, noInfants } = this.state.lastSearch;
        const amountPassengers = noAdults + noChildren + noInfants;
        this.setState({ amountPassengersContext: amountPassengers });

        afterCallback();
      }
    );
  };

  // This function makes sure that we don't have to call the initialize packages function multiple times. If not nescescarry.
  initializePackages = async () => {
    const packagesSession = JSON.parse(
      window.sessionStorage.getItem('Packages')
    );

    if (packagesSession) {
      this.setState({ packagesArray: packagesSession });
    } else if (!packagesSession) {
      return axios
        .get(contentAPI)
        .then(response => {
          window.sessionStorage.setItem(
            'Packages',
            JSON.stringify(response.data)
          );
          this.setState({ packagesArray: response.data });
          return response.data;
        })
        .catch(error => console.log('There has been an error', error));
    }
  };

  componentDidMount() {
    const { noAdults, noChildren, noInfants } = this.state.lastSearch;
    const amountPassengers = noAdults + noChildren + noInfants;
    this.setState({ amountPassengersContext: amountPassengers });

    if (!this.state.packagesArray) {
      console.log('[Context:componentDidMount] empty packages array in state]');
      try {
        this.initializePackages().then(packages_response => {
          if (
            !this.state.selectedPackageContext &&
            window.location.pathname.includes('packages-deal-summary/')
            // && this.state.packagesArray
          ) {
            console.log(
              'Selected package context is empty, setting a selected package based on URL deal id.'
            );
            const package_id = window.location.pathname.split('/').slice(-1)[0];
            const the_filtered_package = this.state.packagesArray.filter(
              item => item.id === Number.parseInt(package_id)
            )[0];
            console.log('The filtered package', the_filtered_package);
            this.setState(
              { selectedPackageContext: the_filtered_package },

              () => this.getPackagePriceObjectContext(the_filtered_package)
            );
          }
        });
      } catch {
        console.log('[try catch component did mount err');
      }
    } else
      console.log(
        'The context component was remounted, but the packages were already loaded in local session storage.'
      );
  }

  render() {
    return (
      <PackagesContext.Provider value={this.state}>
        {this.props.children}
      </PackagesContext.Provider>
    );
  }
}

PackagesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
