import React from 'react';
import PropTypes from 'prop-types';
import InformationCircle from './InformationCircle';

import {
  Details,
  FinalDetail,
  Installments,
  PaymentInformationWrapper,
  Position,
  Price,
} from './styles';

const DisplayPaymentInformation = ({
  monthlyPriceIncludingBaggage,
  totalFlymbleIncludingBaggage,
  discountValue,
  discountCode,
  noPassengers,
  noMonths,
  airfareAllPassengers,
  isVisibleInformationCircle,
  sumBaggagePrices,
  isPayInFull,
  isAddedTicketServiceContext,
  costAddedTicketService,
}) => {
  return (
    <PaymentInformationWrapper>
      <PaymentInformationHeader
        monthlyPriceIncludingBaggage={monthlyPriceIncludingBaggage}
        noMonths={noMonths}
        isDisplayInformationCircle={isVisibleInformationCircle}
        isPayInFull={isPayInFull}
        // isPayInFull={true}
      />
      <Details>
        <Position>{noPassengers}x Passenger</Position>
        <Price>£{airfareAllPassengers.toFixed(2)}</Price>

        {isAddedTicketServiceContext && (
          <>
            <Position>{noPassengers}x Ticket Protection</Position>
            <Price>
              <Price>£{costAddedTicketService.toFixed(2)}</Price>
            </Price>
          </>
        )}
        <>
          <Position>Baggage</Position>
          <Price>
            {sumBaggagePrices > 0 ? `£${sumBaggagePrices.toFixed(2)}` : 'Free'}
          </Price>
        </>

        {discountCode && (
          <>
            <Position>Discount {discountCode.toString()}</Position>
            <Price>
              <Price>- £{discountValue.toFixed(2)}</Price>
            </Price>
          </>
        )}

        <TotalPriceRow
          isPayInFull={isPayInFull}
          totalPrice={totalFlymbleIncludingBaggage}
        />
      </Details>
    </PaymentInformationWrapper>
  );
};

const TotalPriceRow = ({ totalPrice, isPayInFull }) => {
  if (isPayInFull) {
    return (
      <FinalDetail>
        <Position>Total (GBP) </Position>
        <Price>
          <div className="payInFullPrice">£{totalPrice.toFixed(2)}</div>
        </Price>
      </FinalDetail>
    );
  } else
    return (
      <>
        <FinalDetail>
          <Position>Sub-total</Position>
          <Price>£{totalPrice.toFixed(2)}</Price>
        </FinalDetail>
      </>
    );
};

TotalPriceRow.propTypes = {
  isPayInFull: PropTypes.bool.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

const PaymentInformationHeader = ({
  monthlyPriceIncludingBaggage,
  noMonths,
  isDisplayInformationCircle,
  isPayInFull,
}) => {
  return (
    <>
      <Installments isBorder={!isPayInFull}>
        {!isPayInFull && (
          <>
            {isDisplayInformationCircle ? 'From ' : 'Pay '}£
            {monthlyPriceIncludingBaggage.toFixed(2)} / mo
            {isDisplayInformationCircle && <InformationCircle />}
            <p className="upfrontPrice">
              Pay in {noMonths} monthly instalments
            </p>
          </>
        )}
      </Installments>
    </>
  );
};

PaymentInformationHeader.propTypes = {
  monthlyPriceIncludingBaggage: PropTypes.number.isRequired,
  noMonths: PropTypes.number.isRequired,
  isDisplayInformationCircle: PropTypes.bool.isRequired,
  isPayInFull: PropTypes.bool.isRequired,
};

DisplayPaymentInformation.propTypes = {
  monthlyPriceIncludingBaggage: PropTypes.number.isRequired,
  totalFlymbleIncludingBaggage: PropTypes.number.isRequired,
  discountValue: PropTypes.number.isRequired,
  discountCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noPassengers: PropTypes.number.isRequired,
  noMonths: PropTypes.number.isRequired,
  airfareAllPassengers: PropTypes.number.isRequired,
  displayInformationCircle: PropTypes.object,
  isVisibleInformationCircle: PropTypes.bool,
  sumBaggagePrices: PropTypes.number.isRequired,
  isPayInFull: PropTypes.bool.isRequired,
  isAddedTicketServiceContext: PropTypes.bool.isRequired,
  costAddedTicketService: PropTypes.number.isRequired,
};

export default DisplayPaymentInformation;
