import {
  faAt,
  faBriefcase,
  faHome,
  faPaste,
  faPhone,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'Utils/i18n.js';
import BaggagePassengersSection from './BaggagePassengersSection';
import PersonSection from './PersonSection';
import { ContrastStyle, CustomerInformationSectionWrapper } from './styles';

const dateText = 'MMM Do, YYYY';

class CustomerInformationSection extends React.Component {
  render() {
    const {
      passengers,
      data: {
        paying_title,
        paying_firstname,
        paying_lastname,
        paying_dobDay,
        paying_dobMonth,
        paying_dobYear,
        email,
        mobileNr,
        street,
        houseNr,
        postcode,
        residentialstatus,
        salary,
        employment,
        moreThanYear,
        city,
        county,
        apartmentNr,
        floorNr,
      },
      isShortVersion,
    } = this.props;

    const leadBookerDob = moment()
      .utc()
      .year(parseInt(paying_dobYear, 10))
      .month(parseInt(paying_dobMonth, 10) - 1)
      .date(parseInt(paying_dobDay, 10));

    const checkedBaggages = passengers.filter(
      p => p.additionalBaggage === true
    );

    const hideApplyingPersonAddress = typeof postcode == 'undefined';

    console.log(
      '[CustomerInformationSection] this.props.isPayInFull ',
      this.props.isPayInFull
    );

    if (!isShortVersion) {
      return (
        <CustomerInformationSectionWrapper>
          <h2 ref={this.myRef}>
            <FontAwesomeIcon icon={faPaste} className="faIconOverview" />{' '}
            {i18n.t('CustomerInformationSection..Overview')}
          </h2>
          {!this.props.isShortVersion && (
            <div>
              <p className="overviewSubtitle">
                {i18n.t('CustomerInformationSection..Passengers')}
              </p>
              {passengers.map((person, index) => (
                <PersonSection
                  key={`P${index}`}
                  passengerData={person}
                  icon={faUser}
                  title={`Passenger ${index + 1}`}
                />
              ))}
            </div>
          )}
          {checkedBaggages.length > 0 && (
            <div>
              <p className="overviewSubtitle">
                {i18n.t('CustomerInformationSection..Baggage')}
              </p>
              {checkedBaggages.map((person, index) => (
                <BaggagePassengersSection
                  key={`P${index}`}
                  passengerData={person}
                  icon={faUser}
                  title={`Passenger ${index + 1}`}
                />
              ))}
            </div>
          )}

          <p className="overviewSubtitle">
            {this.props.isPayInFull ? 'Contact details' : 'Person applying'}
          </p>
          {!this.props.isPayInFull && (
            <div>
              <span className="confirmationIcon">
                <FontAwesomeIcon icon={faUser} />
              </span>
              {`${paying_title} ${paying_firstname} ${paying_lastname}`}
              <ContrastStyle>
                {`| ${leadBookerDob.utc().format(dateText)}`}
              </ContrastStyle>
            </div>
          )}

          <div>
            {' '}
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faAt} />
            </span>
            {email}
          </div>

          <div>
            {' '}
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faPhone} />
            </span>
            {mobileNr}
          </div>

          {!hideApplyingPersonAddress && (
            <>
              <div>
                {' '}
                <span className="confirmationIcon">
                  <FontAwesomeIcon icon={faHome} />
                </span>
                {`${houseNr || ''} ${street}`}
                {apartmentNr && `, ${apartmentNr}`}
                {floorNr && `, floor ${floorNr}`}
              </div>
              <div>
                {' '}
                <span className="confirmationIcon">
                  <FontAwesomeIcon icon={faHome} />
                </span>
                {`${city}, ${postcode}, ${county} ${
                  moreThanYear !== undefined ? `| ${moreThanYear}` : ''
                }`}
              </div>
              {typeof residentialstatus !== 'undefined' && (
                <div>
                  {' '}
                  <span className="confirmationIcon">
                    <FontAwesomeIcon icon={faBriefcase} />
                  </span>
                  {`${residentialstatus} | ${employment} | £${salary}`}
                </div>
              )}
            </>
          )}
        </CustomerInformationSectionWrapper>
      );
    } else {
      return (
        <CustomerInformationSectionWrapper>
          <h2 ref={this.myRef}>
            <FontAwesomeIcon icon={faPaste} className="faIconOverview" />{' '}
            {i18n.t('CustomerInformationSection..Overview')}
          </h2>
          {checkedBaggages.length > 0 && (
            <div>
              <p className="overviewSubtitle">
                {i18n.t('CustomerInformationSection..Baggage')}
              </p>
              {checkedBaggages.map((person, index) => (
                <BaggagePassengersSection
                  key={`P${index}`}
                  passengerData={person}
                  icon={faUser}
                  title={`Passenger ${index + 1}`}
                />
              ))}
            </div>
          )}

          <p className="overviewSubtitle">
            {i18n.t('CustomerInformationSection..Person applying')}
          </p>
          <div>
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faUser} />
            </span>
            {`${paying_title} ${paying_firstname} ${paying_lastname}`}
            <ContrastStyle>
              {`| ${leadBookerDob.utc().format(dateText)}`}
            </ContrastStyle>
          </div>
          <div>
            {' '}
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faAt} />
            </span>
            {email}
          </div>
          <div>
            {' '}
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faPhone} />
            </span>
            {mobileNr}
          </div>
          <div>
            {' '}
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faHome} />
            </span>
            {`${houseNr} ${street}, ${postcode} | ${moreThanYear}`}
          </div>
          <div>
            <span className="confirmationIcon">
              <FontAwesomeIcon icon={faBriefcase} />
            </span>
            {`${residentialstatus} | ${employment} | £${salary}`}
          </div>
        </CustomerInformationSectionWrapper>
      );
    }
  }
}

CustomerInformationSection.propTypes = PropTypes.shape({
  data: PropTypes.object.isRequired,
  passengers: PropTypes.array,
  isShortVersion: PropTypes.bool.isRequired,
}).isRequired;

export default CustomerInformationSection;
