import React, { lazy, Suspense } from 'react';
import { i18n } from 'Utils';

// Import PaymentPlanPage Components.
import {
  // WhyFlymble,
  NeedHelp,
  // whyFlymbleUspsUK,
  HideDesktopWrapper,
} from './components';

// The sections can be loaded lazy as they are not visible in the top view on mobile.
// const BrandVideo = lazy(() => import('../../components/BrandVideo'));
const HowItWorks = lazy(() => import('../../components/HowItWorks'));
const FeedBackSection = lazy(() => import('../../components/FeedbackSection'));
// const FAQsLandingPage = lazy(() => import('../../components/FAQsLandingPage'));

const ContentBlock = () => {
  return (
    <Suspense fallback={<div />}>
      <HideDesktopWrapper>
        {/* <BrandVideo /> */}
      </HideDesktopWrapper>
      <HowItWorks />
      <FeedBackSection flymbleReviewPage={true} />
      {/* <FAQsLandingPage /> */}
      {/* <WhyFlymble
        titleText="Lock Prices"
        explenationText="Book when prices are low and protect yourself against rising airfares."
        whyFlymbleUsps={whyFlymbleUspsUK}
      /> */}
      <NeedHelp
        titleText="Need help?"
        callNow="Call now"
        phoneNumber={i18n.t('Main..Phone Number Text')}
        openingTimes="open 10am-5pm"
      />
    </Suspense>
  );
};

export default ContentBlock;
