import React from 'react';
import styled from 'react-emotion/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

//#region
const TitleRowWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  padding-top: 8px;
  padding-bottom: 30px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1000px) {
    padding-top: 30px;
    grid-template-columns: 7fr 9fr 4fr;
  }

  .travelLegTitle {
    grid-column: 1/3;
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 20px;

    @media (min-width: 1000px) {
      grid-column: 1/4;
    }

    span {
      font-size: 14px;
    }
  }

  .arrives {
    grid-column: 1/2;
  }

  .totalDuration {
    grid-column: 2/3;
    text-align: right;
    /* border-left: solid 2px black; */
    margin-left: 8px;

    @media (min-width: 1000px) {
      margin-left: 0px;
      text-align: left;
      padding-left: 24px;
      max-height: 18px;
    }
  }
`;
//#endregion

const TitleRow = ({
  typeLeg,
  textDateDeparture,
  textDateArrival,
  totalDuration,
}) => {
  const { t } = useTranslation();
  return (
    <TitleRowWrapper>
      <div className="travelLegTitle">
        <b>{typeLeg}</b>
        <span> {textDateDeparture}</span>
      </div>
      <div className="arrives">
        <b>{t('TitleRow..Arrives')}:</b>&nbsp;
        {textDateArrival}
      </div>
      <div className="totalDuration">
        <b>{t('TitleRow..Total duration')}:</b>&nbsp;
        {totalDuration}
      </div>
    </TitleRowWrapper>
  );
};

TitleRow.propTypes = {
  typeLeg: PropTypes.string.isRequired,
  textDateDeparture: PropTypes.string.isRequired,
  textDateArrival: PropTypes.string.isRequired,
  totalDuration: PropTypes.string.isRequired,
};

export { TitleRow };
