import React from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';

// Import Assets.
import KlarnaLogo from 'assets/images/klarna.svg';
import Pie1 from './CirclePie1.svg';
import Pie2 from './CirclePie2.svg';
import Pie3 from './CirclePie3.svg';

export default class KlarnaEducation extends React.Component {
  render() {
    const { totalBookingPrice, isVisible } = this.props;
    const monthlyPrice = totalBookingPrice / 3;

    const VisualElement = ({ children, text, price }) => (
      <div>
        {children}
        <div className="textLabel">{text}</div>
        <br />
        <div className="piePrice">£{price.toFixed(2)}</div>
      </div>
    );
    return (
      isVisible &&
      <KlarnaEducationWrapper>
        <div className="titleSection">
          Slice the cost with <img src={KlarnaLogo} alt="/" />
        </div>
        <div className="contentBlock">
          <div className="textLine">
            Pay later in 3 interest-free instalments.
          </div>
          <div className="visualsGrid">
            <VisualElement text="1st payment" price={monthlyPrice}>
              <img src={Pie1} alt="pie1" />
            </VisualElement>
            <VisualElement text="30 days later" price={monthlyPrice}>
              <img src={Pie2} alt="pie1" />
            </VisualElement>
            <VisualElement text="60 days later" price={monthlyPrice}>
              <img src={Pie3} alt="pie1" />
            </VisualElement>
          </div>
          <div className="bottomRowPrice">
            <div>Total</div>
            <div>£{totalBookingPrice.toFixed(2)}</div>
          </div>
        </div>
      </KlarnaEducationWrapper>
    );
  }
}

KlarnaEducation.propTypes = {
  totalBookingPrice: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired
};

const paymentPlanBorder = '2px solid #dbdbdb';
// const flymbleBlue = '#4860ff';
const grayBgColor = '#f9f9fa';
const textLabelColor = '#808080';
// const textLabelColor = '#B2B2B2';

const KlarnaEducationWrapper = styled.div`
  .titleSection {
    text-align: center;
    padding: 40px;
    border-bottom: 3px solid #0015a3;
    background-color: ${grayBgColor};
    font-weight: 600;
    font-size: 20px;

    img {
      max-height: 21px;
      margin-left: 3px;
    }
  }

  .contentBlock {
    .textLine {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      padding: 18px;
    }

    .visualsGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      text-align: center;
      padding-bottom: 35px;
      padding-top: 5px;
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: ${paymentPlanBorder};

      .textLabel {
        color: ${textLabelColor};
        margin-top: 12px;
        font-size: 12px;
      }

      .piePrice {
        font-weight: 600;
        color: black;
        font-size: 16px;
        line-height: 0;
      }
    }

    .bottomRowPrice {
      text-align: center;
      padding: 20px;
      text-align: left;
      color: black;
      font-size: 18px;
      font-weight: 600;

      div {
        display: inline-block;
      }
      div:first-child {
        text-align: left;
      }

      div:nth-child(2) {
        float: right;
      }
    }
  }

  @media (min-width: 1000px) {
    grid-column: 2/4;
    width: 66%;
    margin-left: auto;
    margin-right: auto;

    .titleSection {
      font-size: 27px;
    }

    .contentBlock {
      .textLine {
        font-size: 21px;
        padding-top: 35px;
        padding-bottom: 25px;
      }

      .visualsGrid {
        padding-top: 15px;

        .textLabel {
          font-size: 15px;
        }

        .piePrice {
          font-weight: 600;
          color: black;
          font-size: 19px;
        }
      }

      .bottomRowPrice {
        font-size: 20px;
        font-weight: 600;

        div {
          display: inline-block;
        }
        div:first-child {
          text-align: left;
        }

        div:nth-child(2) {
          float: right;
        }
      }
    }
  }
`;
