import React from 'react';
import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, Select, Checkbox } from 'antd';

import FormLabel from '../components/FormLabel';
import { PassengerBagsContainer } from '../components/PassengerBagsContainer';
import CountryInput from '../components/Countries/CountryInput';
// import { WarningBanner } from '../components/WarningBanner.js';
import i18n from 'Utils/i18n';
import { Trans } from 'react-i18next';
import { FirstCheckoutPageInstructions } from '../components/FirstCheckoutPageInstructions';

const Option = Select.Option;
const FormItem = Form.Item;
const formInputSize = 'large';

const flymbleBlue = '#4860ff';
const checkoutSectionBorder = '1px solid #E8E8E8';

const dobGrid = css`
  .dobGrid {
    display: grid;
    grid-template-columns: 35fr 20fr 20fr;
    grid-column-gap: 10px;
  }
`;
const PassengerSectionWrapper = styled.div`
  ${dobGrid};
  @media (min-width: 1000px) {
    margin-top: 50px;
  }
  .natGenderGrid {
    display: grid;
    grid-template-columns: 22fr 12fr;
    grid-column-gap: 10px;
  }

  .idExpiryDateGrid {
    display: grid;
    grid-template-columns: 25fr 16fr 18fr 18fr;
    grid-column-gap: 10px;
  }

  .idDateSelector {
    grid-column: 1/4;
  }

  .expirySelectorGrid {
    display: grid;
    grid-template-columns: 25fr 16fr 18fr;
    grid-column-gap: 10px;
  }

  .addPassenger {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    cursor: pointer;

    width: 100%;

    span {
      display: inline-block;
      text-align: center;
      font-size: 14px;
      color: white;
      font-weight: bold;

      width: 220px;
      padding-top: 14px;
      padding-bottom: 14px;
      background-color: ${flymbleBlue};
      border-radius: 3px;
    }

    .faIcon {
      padding-right: 3px;
      font-size: 16px;
    }
  }

  @media (min-width: 1000px) {
    background-color: white;
    border: ${checkoutSectionBorder};
    border-radius: 3px;
    padding: 38px;

    .coreForms {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 18px;
    }
  }

  .passportInformation {
    font-weight: 400;
    margin-top: 0px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    grid-column: 1/3;
  }

  @media (max-width: 1000px) {
    background-color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 15px;

    h2 {
      margin-top: 0px;
    }
  }
`;

class PassengerSection extends React.Component {
  state = {
    idNoExpRequired: false,
  };

  validateExpiry = (rule, value, callback) => {
    this.recheckExpiry();
    callback();
  };

  handleNoExpiryOnChange = event => {
    const {
      target: { checked },
    } = event;
    this.setState(
      {
        idNoExpRequired: checked,
      },
      this.recheckExpiry
    );
  };

  recheckExpiry = () => {
    const {
      form: { validateFields },
      prefix,
    } = this.props;
    validateFields(
      [`${prefix}idExpYear`, `${prefix}idExpMonth`, `${prefix}idExpDay`],
      { force: true }
    );
  };

  customExpiryValidator = (rule, value, callback) => {
    const {
      form: { getFieldValue },
      prefix,
    } = this.props;
    if (!getFieldValue(`${prefix}idNoExp`) && '' === value) {
      callback(true);
    } else {
      callback();
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      setDebugValues,
      noPassengers,
    } = this.props;

    return [...Array(noPassengers)].map((_, i) => {
      const prefix = `P${i}_`;
      const title = i18n.t(
        'CheckoutForms..{{value}}. Passenger - Choose baggage',
        { value: i + 1 }
      );
      return (
        <PassengerSectionWrapper key={prefix}>
          <FirstCheckoutPageInstructions
            isVisible={prefix === 'P0_'}
          />
          <h2 onClick={setDebugValues}>
            <FontAwesomeIcon icon={faUser} className="faIcon" />
            &ensp; {title}
          </h2>
          <div
            style={{
              color: 'black',
              marginBottom: '12px',
              marginTop: '7px',
              fontSize: '1tpx',
            }}
          >
            Use all given names and surnames exactly as per passport/ID.
          </div>
          <div className="coreForms">
            {/* <WarningBanner
              setDebugValues={setDebugValues}
              text="Use all given names and surnames exactly as they appear in your passport/ID to avoid boarding complications."
            /> */}
            {/* <div /> */}
            <div>
              <FormLabel>
                {i18n.t('PassengerSection..label..Given names')}
              </FormLabel>
              <FormItem>
                {getFieldDecorator(`${`P${i}_`}firstname`, {
                  rules: [
                    {
                      required: true,
                      message: i18n.t(
                        'PassengerSection..validator..Please enter a first name using letters only.'
                      ),
                      type: 'string',
                      pattern: /([^0-9]*)$/,
                      whitespace: true,
                      // Cannot be digits. pattern: /^(?=\s*\S).*$/, String cannot start, end with
                      // white-space and consist of few white-spaces in a row
                    },
                  ],
                })(
                  <Input
                    size={formInputSize}
                    placeholder={i18n.t(
                      'PassengerSection..placeholder..e.g. Oliver James'
                    )}
                  />
                )}
              </FormItem>
            </div>
            <div>
              <FormLabel>
                {i18n.t('PassengerSection..label..Surname(s)')}
              </FormLabel>
              <FormItem>
                {getFieldDecorator(`${prefix}lastname`, {
                  rules: [
                    {
                      required: true,
                      message: i18n.t(
                        'PassengerSection..validator..Please enter a surname using letters only.'
                      ),
                      type: 'string',
                      pattern: /([^0-9]*)$/,
                      whitespace: true,
                      // Cannot be digits. pattern: /^(?=\s*\S).*$/, String cannot start, end with
                      // white-space and consist of few white-spaces in a row
                    },
                  ],
                })(
                  <Input
                    size={formInputSize}
                    placeholder={i18n.t(
                      'PassengerSection..placeholder..e.g. Brown'
                    )}
                  />
                )}
              </FormItem>
            </div>
            <div className="natGenderGrid">
              <div>
                <FormLabel>
                  {i18n.t('PassengerSection..label..Nationality')}
                </FormLabel>
                <CountryInput
                  getFieldDecorator={getFieldDecorator}
                  prefix={prefix}
                  placeholder={i18n.t(
                    'PassengerSection..placeholder..e.g. United Kingdom'
                  )}
                />
              </div>

              <div>
                <FormLabel>
                  {i18n.t('PassengerSection..label..Gender')}
                </FormLabel>
                <FormItem>
                  {getFieldDecorator(`${prefix}gender`, {
                    rules: [
                      {
                        required: true,
                        message: i18n.t(
                          'PassengerSection..validator..Is required.'
                        ),
                      },
                    ],
                  })(
                    <Select
                      size={formInputSize}
                      placeholder={i18n.t(
                        'PassengerSection..placeholder..Select'
                      )}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Option value="male">
                        {i18n.t('PassengerSection..label..Male')}
                      </Option>
                      <Option value="female">
                        {i18n.t('PassengerSection..label..Female')}
                      </Option>
                    </Select>
                  )}
                </FormItem>
              </div>
            </div>
            <div>
              <FormLabel>
                {i18n.t('PassengerSection..label..Date of birth')}
              </FormLabel>
              <div className="dobGrid">
                <FormItem>
                  {getFieldDecorator(`${prefix}dobMonth`, {
                    rules: [
                      {
                        required: true,
                        message: i18n.t(
                          'PassengerSection..validator..Is required.'
                        ),
                      },
                    ],
                  })(
                    <Select
                      size={formInputSize}
                      placeholder={i18n.t(
                        'PassengerSection..placeholder..Month'
                      )}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Option value="01">
                        {i18n.t('PassengerSection..label..January')}
                      </Option>
                      <Option value="02">
                        {i18n.t('PassengerSection..label..February')}
                      </Option>
                      <Option value="03">
                        {i18n.t('PassengerSection..label..March')}
                      </Option>
                      <Option value="04">
                        {i18n.t('PassengerSection..label..April')}
                      </Option>
                      <Option value="05">
                        {i18n.t('PassengerSection..label..May')}
                      </Option>
                      <Option value="06">
                        {i18n.t('PassengerSection..label..June')}
                      </Option>
                      <Option value="07">
                        {i18n.t('PassengerSection..label..July')}
                      </Option>
                      <Option value="08">
                        {i18n.t('PassengerSection..label..August')}
                      </Option>
                      <Option value="09">
                        {i18n.t('PassengerSection..label..September')}
                      </Option>
                      <Option value="10">
                        {i18n.t('PassengerSection..label..October')}
                      </Option>
                      <Option value="11">
                        {i18n.t('PassengerSection..label..November')}
                      </Option>
                      <Option value="12">
                        {i18n.t('PassengerSection..label..December')}
                      </Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator(`${prefix}dobDay`, {
                    rules: [
                      {
                        required: true,
                        message: i18n.t(
                          'PassengerSection..validator..Please provide your day of birth in a 2 digit format.'
                        ),
                        type: 'string',
                        max: 2,
                        // specifies up untill 31 and only two digits.
                        pattern: /^(?:[0]?[0-9]|[12][0-9]|3[01])$/g,
                      },
                    ],
                  })(
                    <Input
                      placeholder={i18n.t('PassengerSection..placeholder..DD')}
                      type="number"
                      size={formInputSize}
                      style={{
                        width: '100%',
                      }}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator(`${prefix}dobYear`, {
                    rules: [
                      {
                        required: true,
                        message: i18n.t(
                          'PassengerSection..validator..Please provide your year of birth in 4 digits.'
                        ),
                        type: 'string',
                        max: 4,
                        // specifies up untill 2018 and only digits.
                        pattern: /^19[1-9]\d|20[0-1]\d|2018$/g,
                      },
                    ],
                  })(
                    <Input
                      placeholder={i18n.t(
                        'PassengerSection..placeholder..YYYY'
                      )}
                      type="number"
                      size={formInputSize}
                      style={{
                        width: '100%',
                      }}
                    />
                  )}
                </FormItem>
              </div>
            </div>

            {
              // window.location.pathname.indexOf('packages') === -1 &&
              <>
                <div>
                  <FormLabel>
                    {i18n.t('PassengerSection..label..Passport or ID number')}
                  </FormLabel>
                  <FormItem>
                    {getFieldDecorator(`${prefix}idNumber`, {
                      rules: [
                        {
                          required: true,
                          message: i18n.t(
                            'PassengerSection..validator..Is required.'
                          ),
                          type: 'string',
                          whitespace: true,
                        },
                      ],
                    })(
                      <Input
                        size={formInputSize}
                        placeholder={i18n.t(
                          'PassengerSection..placeholder..Passport or ID number'
                        )}
                      />
                    )}
                  </FormItem>
                </div>
                <div className="idExpiryDateGrid">
                  <div className="idDateSelector">
                    <FormLabel>
                      {i18n.t(
                        'PassengerSection..label..Passport or ID expiry date'
                      )}
                    </FormLabel>
                    <div className="expirySelectorGrid">
                      <FormItem>
                        {getFieldDecorator(`${prefix}idExpMonth`, {
                          rules: [
                            {
                              message: i18n.t(
                                'PassengerSection..validator..Is required.'
                              ),
                              type: 'string',
                              max: '2',
                              pattern: /^(?:[0]?[0-9]|10|11|12)$/g,
                            },
                            {
                              validator: this.customExpiryValidator,
                              message: i18n.t(
                                'PassengerSection..validator..ID Expiry conditions are required.'
                              ),
                            },
                          ],
                        })(
                          <Select
                            size={formInputSize}
                            placeholder={i18n.t(
                              'PassengerSection..placeholder..Month'
                            )}
                            style={{
                              width: '100%',
                            }}
                          >
                            <Option value="01">
                              {i18n.t('PassengerSection..label..January')}
                            </Option>
                            <Option value="02">
                              {i18n.t('PassengerSection..label..February')}
                            </Option>
                            <Option value="03">
                              {i18n.t('PassengerSection..label..March')}
                            </Option>
                            <Option value="04">
                              {i18n.t('PassengerSection..label..April')}
                            </Option>
                            <Option value="05">
                              {i18n.t('PassengerSection..label..May')}
                            </Option>
                            <Option value="06">
                              {i18n.t('PassengerSection..label..June')}
                            </Option>
                            <Option value="07">
                              {i18n.t('PassengerSection..label..July')}
                            </Option>
                            <Option value="08">
                              {i18n.t('PassengerSection..label..August')}
                            </Option>
                            <Option value="09">
                              {i18n.t('PassengerSection..label..September')}
                            </Option>
                            <Option value="10">
                              {i18n.t('PassengerSection..label..October')}
                            </Option>
                            <Option value="11">
                              {i18n.t('PassengerSection..label..November')}
                            </Option>
                            <Option value="12">
                              {i18n.t('PassengerSection..label..December')}
                            </Option>
                          </Select>
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator(`${prefix}idExpDay`, {
                          rules: [
                            {
                              message: i18n.t(
                                'PassengerSection..validator..Day of expiry is required in 2 digits.'
                              ),
                              type: 'string',
                              max: '2',
                              pattern: /^(?:[0]?[0-9]|[12][0-9]|3[01])$/g,
                              // specifies up untill 31 and only two digits.
                            },
                            {
                              validator: this.customExpiryValidator,
                              message: i18n.t(
                                'PassengerSection..validator..ID Expiry conditions are required.'
                              ),
                            },
                          ],
                        })(
                          <Input
                            placeholder={i18n.t(
                              'PassengerSection..placeholder..DD'
                            )}
                            size={formInputSize}
                            type="number"
                            style={{
                              width: '100%',
                            }}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator(`${prefix}idExpYear`, {
                          rules: [
                            {
                              // required: !this.state.idNoExpRequired,
                              message: i18n.t(
                                'PassengerSection..validator..Year of expiry is required in 4 digits.'
                              ),
                              type: 'string',
                              max: '4',
                              pattern: /^201[8-9]|20[2-3]\d|2019$/g,
                              // specifies up untill 2019 and only digits.
                            },
                            {
                              validator: this.customExpiryValidator,
                              message: i18n.t(
                                'PassengerSection..validator..ID Expiry conditions are required.'
                              ),
                            },
                          ],
                        })(
                          <Input
                            placeholder={i18n.t(
                              'PassengerSection..placeholder..YYYY'
                            )}
                            size={formInputSize}
                            type="number"
                            style={{
                              width: '100%',
                            }}
                          />
                        )}
                      </FormItem>
                    </div>
                  </div>
                  <div className="noExpiryCollumn">
                    <FormLabel>
                      {i18n.t('PassengerSection..label..No expiry')}
                    </FormLabel>
                    <FormItem>
                      {getFieldDecorator(`${prefix}idNoExp`, {
                        rules: [
                          {
                            validator: this.validateExpiry,
                          },
                        ],
                        // message: 'ID no expiration date checkbox is required if expiration date not
                        // provided',
                        valuePropName: 'checked',
                        onChange: this.handleNoExpiryOnChange,
                      })(<Checkbox />)}
                    </FormItem>
                  </div>
                </div>

                <Trans
                  i18nKey="PassengerSection..If your passport"
                  parent="p"
                  className="passportInformation"
                >
                  <i>
                    If your passport/ID is currently being renewed, please enter
                    your previous passport/ID details. You can amend them after
                    booking. This may, however incur an extra cost.
                  </i>
                </Trans>
              </>
            }
          </div>
            {window.location.pathname.indexOf('packages') === -1 && (
              <PassengerBagsContainer prefix={prefix} />
            )}
        </PassengerSectionWrapper>
      );
    });
  }
}

PassengerSection.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  setDebugValues: PropTypes.func,
  noPassengers: PropTypes.number.isRequired,
};

export { dobGrid };
export default PassengerSection;
