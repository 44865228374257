import { tryCatch } from "fp-ts/lib/TaskEither"
import axios, { AxiosResponse } from 'axios';

export interface IHTTPRequest<T = {}> {
  method: 'get';
  url: string;
  params: T;
}

export const configAxios = <T>(params: IHTTPRequest<T>) =>
  tryCatch<Error, AxiosResponse>(
    () => axios(params),
    reason => new Error(String(reason))
  );
