import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'emotion';
import styled from 'react-emotion/macro';
import { message } from 'antd';

const flymbleBlue = '#4860ff';

const ButtonCardPartners = ({
  option: {
    logo,
    hasNoInterestBanner,
    desktopText,
    firstPar,
    priceLine,
    subPriceLine,
    infoText,
    partnerName,
    hasSubPriceLine,
    cardNrMonths,
  },
  activeMonths,
  setContextAmountMonths,
  setContextPaymentPlanPartner,
  paymentPlanPartnerSelected,
}) => {
  const isActive =
    cardNrMonths === activeMonths && paymentPlanPartnerSelected === partnerName;

  const handleClickOnCard = (months, partnerName) => {
    setContextAmountMonths(months);
    setContextPaymentPlanPartner(partnerName);
  };

  const stripeStyleLogo =
    partnerName === 'stripe' ? { width: '155px', maxHeight: '30px' } : null;
  const partnerLogo =
    partnerName === 'stripe'
      ? 'https://mainimageservice.azureedge.net/00-tup-web//images/footernew/card.png'
      : logo;

  return (
    <ButtonCardWrapper>
      <div
        className={cx(
          { paymentPlanButton: true },
          { paymentPlanButtonActive: isActive }
        )}
        onClick={() => handleClickOnCard(cardNrMonths, partnerName)}
      >
        <div className="buttonContent">
          <div className="pricingContent">
            <div className="firstPar">
              {firstPar}
              &nbsp;
              <i
                className="fas fa-info-circle"
                onClick={() => message.info(infoText, 7)}
              />
            </div>
            <div className="priceLine">
              {priceLine}
              {/* {hasSubPriceLine && (
                <div className="subPriceLine">{subPriceLine}</div>
              )} */}
            </div>
            <div className="bottomText">{desktopText}</div>
          </div>
        </div>
        <div className="logoSection">
          <img src={partnerLogo} style={stripeStyleLogo} alt='the-specific-partner-logo'/>
          {hasNoInterestBanner && (
            <div className="interest">{hasNoInterestBanner}</div>
          )}
        </div>
      </div>
    </ButtonCardWrapper>
  );
};

ButtonCardPartners.propTypes = {
  option: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    hasNoInterestBanner: PropTypes.oneOfType(PropTypes.string, PropTypes.bool)
      .isRequired,
    hasSubPriceLine: PropTypes.bool.isRequired,
    desktopText: PropTypes.string,
    firstPar: PropTypes.string.isRequired,
    priceLine: PropTypes.string.isRequired,
    subPriceLine: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
    infoText: PropTypes.string,
    cardNrMonths: PropTypes.number.isRequired,
  }),
  activeMonths: PropTypes.number.isRequired,
  setContextPaymentPlanPartner: PropTypes.func.isRequired,
  setContextAmountMonths: PropTypes.func.isRequired,
  paymentPlanPartnerSelected: PropTypes.string,
  isPromo: PropTypes.bool,
};

export default ButtonCardPartners;

const ButtonCardWrapper = styled.div`
  background-color: white;
  .paymentPlanButton {
    outline: none;
    border: 1px solid #e8e8e8;
    display: flex;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.31);
    padding: 10px 15px;

    @media (max-width: 380px) {
      padding-left: 8px;
    }

    padding-right: 0px;
    font-size: 15px;
    cursor: pointer;
    display: grid;

    grid-template-columns: 11fr 5fr;
    @media (max-width: 380px) {
      grid-template-columns: 110fr 45fr;
    }

    .fas {
      color: ${flymbleBlue};
      font-size: 17px;
    }

    .logoSection {
      grid-column: 2/3;
      grid-row: 1/2;
      text-align: right;

      .interest {
        padding: 5px 12px;
        margin-top: 5px;
        background-color: #e8e8e8;
        text-align: center;
        max-width: 140px;
        float: right;
        color: black;
        font-weight: 400;
        border-radius: 3px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        font-size: 12px;
        @media (min-width: 1000px) {
          font-size: 14px;
        }
      }

      img {
        width: 69px;
        max-height: 23px;
        padding-top: 4px;
        margin-right: 10px;
      }
    }

    .buttonContent {
      width: 100%;
      text-align: center;

      .buttonContentTop {
        em {
          color: black;
          font-weight: 500;
        }
      }

      .pricingContent {
        padding-top: 0px;
        .labelPrice {
          font-size: 14px;
          color: black;
          letter-spacing: 0;
          text-align: left;
          line-height: 18px;
        }

        .firstPar {
          font-size: 18px;
          color: black;
          letter-spacing: 0;
          text-align: left;
          line-height: 21px;
          font-weight: 600;
          padding: 4px 0;
          padding-bottom: 8px;
        }
        .priceLine {
          font-size: 17px;
          color: ${flymbleBlue};
          letter-spacing: 0;
          text-align: left;
          line-height: 21px;
          font-weight: 500;
          margin-top: 0px;
          padding-bottom: 0px;

          @media (max-width: 380px) {
            font-size: 17px;
          }

          .subPriceLine {
            font-size: 15px;
            font-weight: 500;
            padding-bottom: 6px;
            padding-top: 2px;

            @media (max-width: 1000px) {
              display: none;
            }
          }
        }

        .bottomText {
          @media (max-width: 1000px) {
            display: none;
          }
          letter-spacing: 0;
          text-align: left;
          padding-top: 8px;
          font-size: 17px;
          color: #252930;
          font-weight: 400;
          max-width: 265px;
          line-height: 1.55;
        }
      }
    }
  }

  .paymentPlanButtonActive {
    border: solid 2px ${flymbleBlue} !important;
    border-radius: 3px;
  }
`;
