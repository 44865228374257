import pipe from 'ramda/src/pipe';
import __ from 'ramda/src/__';
import set from 'ramda/src/set';
import path from 'ramda/src/path';
import cond from 'ramda/src/cond';
import view from 'ramda/src/view';
import ifElse from 'ramda/src/ifElse';
import length from 'ramda/src/length';
import equals from 'ramda/src/equals';
import identity from 'ramda/src/identity';

import { array } from 'fp-ts/lib/Array'
import { task } from 'fp-ts/lib/Task'

import { prepareParams } from './prepareParams'
import { searchFlightApiCall } from './searchFlightApiCall'

import { responseDataDataLens } from './lenses';

export const searchFlight = async (search, callback, filters = {}, max_stops) => {
  const getResult = searchFlightApiCall(search, filters)
  const pre_sort = path(["params", "sort"], prepareParams(search, filters, max_stops))

  const handleCallback = (callbakInputResults) =>
    callback(callbakInputResults, pre_sort)

  const combinePriceResults = ([a_result, b_result]) => {
    const cheapFlight = b_result.data.data.slice(0, 1);

    const otherFlights = a_result.data.data.concat(
      b_result.data.data.slice(1)
    );

    const combined_result_data = cheapFlight.concat(otherFlights);

    const combined_result_data_sorted = combined_result_data.sort(
      (a, b) => a.price - b.price
    );

    return combined_result_data_sorted
  }

  const handleCombinedPriceResults = ifElse(
    pipe(length, equals(0)),
    () => getResult(2),
    set(responseDataDataLens, __, {}),
  )

  const handlePriceRequest = () =>
    array.sequence(task)([() => getResult(0), () => getResult(1)])()
      .then(
        pipe(
          combinePriceResults,
          handleCombinedPriceResults,
        )
      )
      .then(handleCallback)

  const handleSecondNoDurationData = () =>
    getResult(2)
      .then(
        ifElse(
          pipe(
            view(responseDataDataLens),
            length,
            equals(0)
          ),
          identity,
          identity
        )
      )

  const handleFirstNoDurationData = () =>
    getResult(1)
      .then(
        ifElse(
          pipe(
            view(responseDataDataLens),
            length,
            equals(0)
          ),
          handleSecondNoDurationData,
          identity
        )
      )

  const handleDurationRequest = () =>
    getResult(0)
      .then(
        ifElse(
          pipe(
            view(responseDataDataLens),
            length,
            equals(0)
          ),
          handleFirstNoDurationData,
          identity
        )
      )
      .then(handleCallback)

  const combineQualityResults = ([a_result, b_result]) => {
    const cheapFlight = b_result.data.data.slice(0, 1);
    const goodFlight = a_result.data.data.slice(0, 10);
    const good_flights = cheapFlight.concat(goodFlight);

    const otherFlights = a_result.data.data.slice(10).concat(
      b_result.data.data.slice(1)
    );

    const other_flights_sorted = otherFlights.sort(
      (a, b) => a.quality - b.quality
    );

    const combined_result_data = good_flights.concat(other_flights_sorted);

    return combined_result_data
  }

  const handleCombinedQualityResults = ifElse(
    pipe(length, equals(0)),
    () => getResult(2),
    set(responseDataDataLens, __, {}),
  )

  const handleQualityRequest = () =>
    array.sequence(task)([() => getResult(0), () => getResult(1)])()
      .then(
        pipe(
          combineQualityResults,
          handleCombinedQualityResults,
        )
      )
      .then(handleCallback)

  return cond([
    [equals('price'), handlePriceRequest],
    [equals('duration'), handleDurationRequest],
    [equals('quality'), handleQualityRequest],
  ])(pre_sort)
  // @todo error handling.
};

export { checkFlight } from './checkFlight';
