import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ViewDetailsToggleButton } from 'components/ViewDetailsToggleButton';

const CollapsedForms = ({ formStep, resetFormStep }) => {
  return formStep.map((item, index) => (
    <CollapsedFormsWrapper key={index}>
      <div>
        <div className="titleLine">
          <FontAwesomeIcon icon={faUser} className="userIcon" />
          &nbsp; {item}
        </div>
        <div className="subLine">
          Use all given names and surnames as per passport/ID.
        </div>
      </div>
      <div style={{ margin: 'auto' }}>
        <ViewDetailsToggleButton
          isTurnedUp={false}
          handlerFunction={() => resetFormStep(item)}
        />
      </div>
    </CollapsedFormsWrapper>
  ));
};

CollapsedForms.propTypes = {
  //   getFieldDecorator: PropTypes.func.isRequired,
};

export { CollapsedForms };

const CollapsedFormsWrapper = styled.div`
  padding: 10px 14px;
  @media (max-width: 1000px) {
    margin-left: 12px;
    margin-right: 12px;
  }
  margin-top: 15px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 10fr 1fr;
  background-color: #ececec;
  color: black;

  .titleLine {
    font-size: 17px;
    font-weight: 700;
    .userIcon {
      /* font-size: 15px; */
    }
  }

  .subLine {
    margin-top: 3px;
    font-size: 13px;
    color: black;
  }

  @media (min-width: 1000px) {
    padding: 12px 17px;
    margin-top: 26px;

    .titleLine {
      font-size: 20px;
    }
  }
`;
