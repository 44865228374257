import 'antd/lib/progress/style/index.css'; // for css
import { Button } from 'antd';
import React from 'react';
import i18n from 'Utils/i18n.js';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import { RedirectElement } from 'components/RedirectElement';

const flymbleBlue = '#4860ff';

class ConfirmationButtons extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      progress: 0,
      isVisible: true,
      redirectToCheckout: false,
      isLoadingConfirmButton: false,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ redirectToCheckout: false });
  };

  handleConfirmButtonClick = () => {
    this.setState({
      isLoadingConfirmButton: true,
    });
    setTimeout(() => {
      clearTimeout();
      this.setState({
        isLoadingConfirmButton: false,
      });
      this.props.onConfirmButtonClick();
    }, 2000);
  };

  render() {
    const {
      isLoading,
      handleEditFormDetails,
      paymentPlanPartnerSelected,
      isConfirmationButtonsVisible,
    } = this.props;

    return (
      isConfirmationButtonsVisible && (
        <ConfirmationButtonsWrapper>
          <Button
            onClick={this.handleConfirmButtonClick}
            className="proceedWithKlarna"
            id={`confirmButtonWith${paymentPlanPartnerSelected}`}
            loading={this.state.isLoadingConfirmButton}
          >
            {i18n.t('ConfirmationSection..Confirm')}
          </Button>

          {window.location.pathname.indexOf('confirmation/packages') === -1 && (
            <Button
              onClick={handleEditFormDetails}
              className="backButton"
              id="editDetailsButton"
              disabled={isLoading}
            >
              {i18n.t('ConfirmationSection..Back')}
            </Button>
          )}
          {this.state.redirectToCheckout && (
            <RedirectElement destination="/checkout" />
          )}
        </ConfirmationButtonsWrapper>
      )
    );
  }
}

ConfirmationButtons.propTypes = {
  handleEditFormDetails: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onConfirmButtonClick: PropTypes.func.isRequired,
  paymentPlanPartnerSelected: PropTypes.string,
  isConfirmationButtonsVisible: PropTypes.bool,
};

export default ConfirmationButtons;

const ConfirmationButtonsWrapper = styled.div`
  @media (max-width: 1000px) {
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 20px;
    margin-top: 30px;

  }
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    grid-column-gap: 18px;
    margin-top: 45px;
  }

  .confirmationButton,
  .proceedWithFNPL,
  .proceedWithKlarna,
  .backButton {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
    }

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${flymbleBlue};
    color: white;
    border-color: ${flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }

  .backButton {
    background-color: #e9eef2;
    border: none;
    color: black;
    grid-column: 1/2;
  }

  .proceedWithKlarna {
    grid-column: 2/3;
  }
`;
