import styled from 'react-emotion/macro';

const secretEscapesOrange = '#ff8c00';

export const DealCardWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 30px;
  position: relative;

  img {
    width: 100%;

    @media (min-width: 1000px) {
      height: 208px;
    }
  }
`;

export const DealLabelsWrapper = styled.div`
  position: ${props => (props.isAbsolute ? 'absolute' : 'relative')};
  left: ${props => (props.isAbsolute ? '15px' : null)};
  top: ${props => (props.isAbsolute ? '8px' : null)};
  z-index: 2;
  .dealLabel,
  .flightsLabel {
    padding: 8px 6px;
    margin: 8px 0;
    margin-right: 10px;
    background-color: ${secretEscapesOrange};
    display: inline-block;
    box-sizing: border-box;
    border-radius: 3px;
    color: #ffffff;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 400;
  }

  .flightsLabel {
    background-color: black;
    color: white;
  }
`;

export const DealCardDetailsWrapper = styled.div`
  padding: 8px;
  background: #ffffff;
  border: 1px solid #ccc;
  position: relative;
  margin-top: -40px;
  margin-top: -2.5rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  .detailsTitle {
    color: black;
    font-size: 17px;
    font-weight: 500;
    margin: 0 0 2px 0;
    margin: 0 0 0.125rem 0;
    line-height: 1.6;
  }

  .detailsSubTitle {
    color: #000000;
    font-size: 12px;
    font-size: 0.75rem;
    margin-bottom: 3px;
    margin-bottom: 0.1875rem;
    width: 100%;
  }
`;
