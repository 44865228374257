/**
 * Should contain definition of all website entries
 * can be multi-level by topic
 */

const pages = {
  aboutUs: '/about-us',
  flightResult: '/flight-result',
  searchForm: '/searchform',
  querySearchPage: '/search-flight',
  querySearchPageRound: '/search-flight/round',
  querySearchPageOneWay: '/search-flight/oneway',
  guides: {
    bestFNPL2018: '/the-best-fly-now-pay-later-service-to-use-in-2018',
  },
};

export default pages;
