import React from 'react';
import { DealCard } from './components/DealCard/index';
import { Placeholder } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';

export const MappedPackages = ({ packagesArray, saveSelectedPackage }) => {
  if (packagesArray) {
    return packagesArray
      .map((dealObject, index) => {
        let {
          destination: dealSubtitle,
          id,
          main_image_url: mainImage,
          old_price: oldDealPrice,
          price: dealPrice,
          price_condition: priceBasisDescription,
          title: dealTitle,
        } = dealObject;
        let dealPriceAllPassengers = dealPrice;
        let oldDealPriceAllPassengers = oldDealPrice;
        let monthlyPriceAllPassengers = dealPriceAllPassengers / 12;
        return (
          <DealCard
            mainImage={mainImage}
            dealTitle={dealTitle}
            dealSubtitle={dealSubtitle}
            dealPrice={dealPriceAllPassengers}
            oldDealPrice={oldDealPriceAllPassengers}
            monthlyPriceAllPassengers={monthlyPriceAllPassengers}
            key={index}
            priceBasisDescription={priceBasisDescription}
            name={id}
            saveSelectedPackage={saveSelectedPackage}
            dealObject={dealObject}
          />
        );
      });
  } else
    return (
      <>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
      </>
    );
};

MappedPackages.propTypes = {
  packagesArray: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
    .isRequired,
  saveSelectedPackage: PropTypes.func.isRequired,
};
