import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isMobileDevice } from '../../Utils';

const FirstCheckoutPageInstructions = ({ isVisible, inputItems }) => {
  const default_items = [
    'No account needed!',
    'Providing your details takes less than 2 minutes.',
    'Receive your booking confirmation within 12 hours.',
  ];

  const map_items = inputItems ? inputItems : default_items;

  const BodyContent = ({ isVisible }) =>
    isVisible && (
      <FirstCheckoutPageInstructionsWrapper>
        <div className="bodyContent">
          <div className="textContent">
            {map_items.map((item, index) => (
              <div className="lineElement" key={index}>
                <FontAwesomeIcon icon={faCheck} className="faIcon" />
                {item}
              </div>
            ))}
          </div>
        </div>
      </FirstCheckoutPageInstructionsWrapper>
    );
  return isVisible && window.location.pathname.indexOf('packages') === -1 ? (
    <BodyContent isVisible={isVisible} />
  ) : (
    isMobileDevice && <BodyContent isVisible={false} />
  );
};

FirstCheckoutPageInstructions.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  inputItems: PropTypes.array,
};

export default FirstCheckoutPageInstructions;
export { FirstCheckoutPageInstructions };

const FirstCheckoutPageInstructionsWrapper = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-weight: 400;
  color: black;
  border: #00c699 2px solid;
  background-color: #cdfff6;
  border-radius: 3px;
  margin-bottom: 35px;

  .faIcon {
    margin-right: 10px;
    color: #00c699;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .bodyContent {
    padding: 12px;
    color: black;

    .textContent {
      .lineElement {
        margin-bottom: 7px;
        display: grid;
        grid-template-columns: 25px 1fr;
      }
    }
  }

  @media (max-width: 1000px) {
    color: black;
    margin-top: 20px;

    p {
      margin-bottom: 6px;
      margin-top: 8px;
    }

    .bodyContent {
      text-align: left;
    }
  }

  @media (min-width: 1000px) {
    margin-bottom: 40px;
    text-align: left;
  }
`;
