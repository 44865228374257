import React from 'react';
import styled from 'react-emotion/macro';
import { Trans } from 'react-i18next';
import TrustPilot from './TrustPilot.png';
import StarsBar from './TrustPilotStars.png';

const backgroundColor = '#f7f7f7';
const TrustPilotUrl =
  'https://uk.trustpilot.com/review/flymble.com?utm_medium=Trustbox&utm_source=MicroCombo';

const FeedbackWrapper = styled.div`
  background-color: ${backgroundColor} !important;
  padding-top: 50px;
  padding-left: 1.3em;
  padding-right: 1.3em;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    padding-top: 130px;
    padding-bottom: 40px;

    h2 {
      font-size: 36px;
      margin-top: 0px;
    }

    .feedbackGrid {
      grid-column: 2/4;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 20px;

      .trustPilotMain {
        grid-column: 1/2;
        p {
          font-size: 14px;
        }
      }
    }
  }

  .trustPilotMain {
    p,
    a {
      font-size: 14px;
    }
    @media (max-width: 1000px) {
      h2 {
        margin-bottom: 0px;
        font-size: 28px;
      }

      p {
        margin-top: 5px;
        padding-bottom: 15px;
      }
      .trustBodyText {
        display: none;
      }
    }
  }

  .trustPilotLogo {
    img {
      width: 180px;
    }
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .reviewCta {
    text-decoration: none;
    font-size: 14px;
    grid-column: 2/6;
    text-align: center;
    color: black;

    @media (max-width: 599px) {
      font-size: 14px;
    }
  }

  .reviewCard {
    padding: 10px 10px;
    border: 0px solid #1d1d1d1c;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 10px 30px rgba(44, 80, 89, 0.09);
    height: 100%;
    height: 180px;

    @media (max-width: 1000px) {
      :not(first-child) {
        display: none;
      }
    }

    .reviewerName {
      color: #403f8d;
      font-size: 14px;
    }

    .reviewText {
      font-size: 12px;
    }

    a {
      text-decoration: none;
      font-size: 12px;
      color: #ccc;
    }

    text-align: center;
    img {
      height: 19px;
    }
  }

  .review1 {
    grid-column: 2/3;
  }
  .review2 {
    grid-column: 3/4;
  }
  .review3 {
    grid-column: 4/5;
  }
  .review4 {
    grid-column: 5/6;
  }

  .mobileReview {
    background-color: white;
    padding: 12px;
    margin-bottom: 15px;
    border-radius: 3px;
    box-shadow: 1px 2px 5px 0 rgba(147, 147, 147, 0.11);
    width: 90%;

    @media (max-width: 325px) {
      width: 100%;
    }

    @media (min-width: 1000px) {
      display: none;
    }

    img {
      height: 19px;
      vertical-align: middle;
    }

    .mobileName {
      margin-right: 15px;
      vertical-align: middle;
      font-size: 14px;
      color: #1e1c77;

      @media (min-width: 325px) {
        margin-right: 16px;
      }
    }
    .mobileSnippet {
      margin-left: 14px;
      vertical-align: middle;
      font-size: 14px;
    }
  }

  .mobileReview.odd {
    margin-left: 30px;

    @media (max-width: 325px) {
      margin-left: 0px;

      .mobileSnippet {
        margin-left: 5px;
      }

      .mobileName {
        margin-right: 5px;
      }
    }
  }

  .mobileReviews {
    padding-top: 50px;
    padding-bottom: 20px;
  }
`;

const Feedback = () => {
  return (
    <FeedbackWrapper>
      <div className="feedbackGrid">
        <Trans i18nKey="Feedback..main">
          <div className="trustPilotMain">
            <h2>We love feedback</h2>
            <div className="trustPilotLogo">
              <img src={TrustPilot} alt="Trust Pilot logo" />
            </div>
            <p>We're super proud of our 5* Trustpilot rating.</p>
            <p className="trustBodyText">
              We are constantly learning and evolving our product and process to
              reflect your needs. We take every comment, question and criticism
              as an opportunity to learn.
            </p>
          </div>
        </Trans>
        <Trans i18nKey="Feedback..review1">
          <div className="reviewCard review1">
            <p className="reviewerName">Lucy</p>
            <img src={StarsBar} alt="trust pilot stars bar" />
            <p className="reviewText">
              Great support on their chat and received my tickets.
            </p>
            <a
              href={TrustPilotUrl}
              alt="to trustpilot website"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Read more</u>
            </a>
          </div>
        </Trans>
        <Trans i18nKey="Feedback..review2">
          <div className="reviewCard review2">
            <p className="reviewerName">Viswanath</p>
            <img src={StarsBar} alt="trust pilot stars bar" />
            <p className="reviewText">
              Amazing idea. Awesome suport (Thank you Vincent).
            </p>
            <a
              href={TrustPilotUrl}
              alt="to trustpilot website"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Read more</u>
            </a>
          </div>
        </Trans>
        <Trans i18nKey="Feedback..review3">
          <div className="reviewCard review3">
            <p className="reviewerName">Louise</p>
            <img src={StarsBar} alt="trust pilot stars bar" />
            <p className="reviewText">
              Great, easy service!! Would recommend to all my friends.
            </p>
            <a
              href={TrustPilotUrl}
              alt="to trustpilot website"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Read more</u>
            </a>
          </div>
        </Trans>
        <Trans i18nKey="Feedback..review4">
          <div className="reviewCard review4">
            <p className="reviewerName">Emma</p>
            <img src={StarsBar} alt="trust pilot stars bar" />
            <p className="reviewText">Quick, easy and professional support.</p>
            <a
              href={TrustPilotUrl}
              alt="to trustpilot website"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Read more</u>
            </a>
          </div>
        </Trans>
        <Trans i18nKey="Feedback..Read all">
          <a
            className="reviewCta"
            href={TrustPilotUrl}
            alt="rate us on trustpilot"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>Read all our reviews and rate us!</u>
          </a>
        </Trans>
        <Trans i18nKey="Feedback..mobileReviews">
          <div className="mobileReviews">
            <div className="mobileReview">
              <span className="mobileName">Enrico J.</span>
              <img src={StarsBar} alt="/" />
              <span className="mobileSnippet">Perfect...</span>
            </div>
            <div>
              <div className="mobileReview odd">
                <span className="mobileName">Lucy H.</span>
                <img src={StarsBar} alt="/" />
                <span className="mobileSnippet">Great support...</span>
              </div>
            </div>
            <div className="mobileReview">
              <span className="mobileName">Edwin B.</span>
              <img src={StarsBar} alt="/" />
              <span className="mobileSnippet">Very good...</span>
            </div>
          </div>
        </Trans>
      </div>
    </FeedbackWrapper>
  );
};

export default Feedback;
