import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';

const OneWayErrorMessageWrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 20px;
  margin: auto;
  max-width: 700px;

  .warningShadow {
    background-color: #ffa5004d;
    padding: 10px;
    border-radius: 3px;
  }

  @media (min-width: 1000px) {
    padding-bottom: 50px;
    grid-column: 2/4;
  }
`;

const OneWayErrorMessage = ({ isVisible }) => {
  return (
    isVisible && (
      <OneWayErrorMessageWrapper>
        <div className="warningShadow">
          It seems your trip is a one-way flight. We currently only offer return
          flights. One-way flights will be available within the next 14 days.
        </div>
      </OneWayErrorMessageWrapper>
    )
  );
};

OneWayErrorMessage.propTypes = {
  isVisible: PropTypes.bool,
};

export default OneWayErrorMessage;
