import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { RedirectElement } from '../RedirectElement';

const flymbleBlue = '#4860ff';

export const LoadingScreen = () => {
  const [isAllowedRedirectState, setIsAllowedRedirectState] = useState(false);

  return (
    <div style={{ paddingTop: '40%' }}>
      <Loader active inline="centered" />
      <div style={{ margin: '30px' }}>
        <p style={{ color: flymbleBlue }} onClick={() => setIsAllowedRedirectState(true)}>Go back to search</p>
        <RedirectElement
          destination="/"
          isAllowedRedirect={isAllowedRedirectState}
        />
      </div>
    </div>
  );
};
