import set from 'ramda/src/set';
import ifElse from 'ramda/src/ifElse';
import propEq from 'ramda/src/propEq';
import compose from 'ramda/src/compose';
import multiply from 'ramda/src/multiply';

import { getFlymblePriceVariantMargin } from 'Utils';
import { VARIANT_CURRENCY } from 'constants/env';

import { VIRTUAL_INTERLINING_MARGIN } from '../../constants';

import { conversionByCurrencyLens, conversionOriginalLens } from './lenses';

import { IFlight } from './types';

const handleIsVirtualInterlining = (flight: IFlight) =>
  set(
    conversionByCurrencyLens,
    // tslint:disable-next-line: max-line-length
    multiply(flight.conversion[VARIANT_CURRENCY], (getFlymblePriceVariantMargin() + VIRTUAL_INTERLINING_MARGIN)),
    flight,
  );

const handleNoVirtualInterlining = (flight: IFlight) =>
  set(
    conversionByCurrencyLens,
    multiply(flight.conversion[VARIANT_CURRENCY], getFlymblePriceVariantMargin()),
    flight
  )

const handleVirtualInterlining = ifElse(
  propEq("virtual_interlining", true),
  handleNoVirtualInterlining,
  handleIsVirtualInterlining,
);

export const addAdditionalFees = (flight: IFlight) => compose(
  set(conversionOriginalLens, flight.conversion),
  handleVirtualInterlining,
)(flight);