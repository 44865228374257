import i18n from 'Utils/i18n';
import PartnerComponentUK from './PartnerComponentUK';
import PartnerComponentPL from './PartnerComponentPL';

const variantFaqList = {
  en: PartnerComponentUK,
  pl: PartnerComponentPL,
};
const PartnerComponent = variantFaqList[i18n.language];

export default PartnerComponent;
