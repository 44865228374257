import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DimensionsInterface } from "../../../../interfaces";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const BaggageTypeRow = (
  { dimensions, price, title, icon }: {
    dimensions: DimensionsInterface,
    price: number,
    icon: IconProp,
    title: string
  }) => {
  let priceDisplay;
  if (price === 0 || price === null) {
    priceDisplay = 'Free';
  } else {
    priceDisplay = `£${price.toFixed(2)}`;
  }

  const {
    length,
    width,
    weight,
    height } = dimensions;

  return (
    <div className="baggageTypeRow">
      <FontAwesomeIcon icon={icon} className="faIcon" />
      <div>
        <p className="typeBaggage">
          <b>{title}</b>
        </p>
        <p className="baggageSizes">
          {length} x {height} x {width} cm
          {weight ? `, ${weight}kg` : null}
        </p>
      </div>
      <p className="unitPrice">
        <b>{price !== null && priceDisplay}</b>
      </p>
    </div>
  );
};
