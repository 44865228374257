import React, { useContext } from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { PackagesContext } from '../../../../Context/PackagesContext';

export const RoomsForm = ({ selectRoomsAmount }) => {
  const context = useContext(PackagesContext);
  const { setAmountRoomsContext } = context;
  const handleSelectRooms = value => {
    setAmountRoomsContext(value);
    selectRoomsAmount(value);
  };
  return (
    <>
      <div className='title'>Rooms</div>
      <div className="roomsForm">
        <Form>
          <Select
            showArrow={true}
            placeholder="1 room"
            style={{ width: 120 }}
            size="large"
            defaultValue={1}
            onChange={value => handleSelectRooms(value)}
          >
            {[1, 2, 3].map(opt => (
              <Select.Option value={opt} key={opt}>
                <span>{opt > 1 ? `${opt} rooms` : `1 room`}</span>
              </Select.Option>
            ))}
          </Select>
        </Form>
      </div>
    </>
  );
};

RoomsForm.propTypes = {
  selectRoomsAmount: PropTypes.func.isRequired,
};
