import styled from 'react-emotion/macro';

export const PackageInputFieldsWrapper = styled.div`
  margin-right: 15px;
  margin-left: 15px;
  padding: 15px;
  margin-top: 40px;
  border: 1px solid #ccc;
  position: relative;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  .passengersGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }

  @media (min-width: 1000px) {
    margin-left: 0px;
    margin-right: 0px;

    .passengersGrid {
      grid-gap: 10px;
    }
  }
`;
