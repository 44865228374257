import styled from 'react-emotion/macro';

export const DetailsWrapper = styled.div`
  position: ${props => (props.isNoScroll ? 'fixed' : 'absolute')};
  overflow: ${props => (props.isNoScroll ? 'hidden' : null)};
  height: ${props => (props.isNoScroll ? '100%' : null)};
  top: 0px;
  width: 100%;
  a {
    text-decoration: none !important;
  }

  background-color: white;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 500px) minmax(auto, 500px) 1fr;
  }
`;

export const TextContentWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 40px;

  @media (min-width: 1000px) {
    grid-column: 2/4;
    padding-top: 70px;
  }
`;

export const ParagraphTitle = styled.p`
  font-weight: bold;
`;

export const MapDetailsWrapper = styled.div`
  padding: 16px;
  @media (min-width: 1000px) {
    grid-column: 2/4;
    padding-top: 75px;
  }
`;
