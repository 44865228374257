// Landing Page Section Components.
import { KeyPoints } from './KeyPoints';
import TrustPilotSection from './TrustPilotSection';
import Main from './Main';
import TravelDeals from './TravelDeals';
import PressArticles from './PressArticles';
import PopularSearches from './PopularSearches';
import PressBar from './PressBar';
import SupportSection from './SupportSection';
import VideoSection from './VideoSection';
import { UspDropdowns } from './UspDropdowns';
import { TravelDeals2020 } from './TravelDeals2020';


// StrylingComponents.
import MainWrapper from './MainWrapper';
import {
  MobileOnly,
  DesktopOnly,
  onlyDesktop,
  onlyMobile,
  MobilePressWrapper,
  Title,
  Spacer,
  HorizontalLine,
  Sphere,
} from './StylingComponents';

// Components that come from the shared components file. These components are in the shared component file because they are also used elsewhere in the application.
import HowItWorks from '../../../components/HowItWorks';
import FeedbackSection from '../../../components/FeedbackSection';
import FAQsLandingPage from '../../../components/FAQsLandingPage';

export {
  KeyPoints,
  MainWrapper,
  TrustPilotSection,
  MobileOnly,
  DesktopOnly,
  onlyDesktop,
  onlyMobile,
  MobilePressWrapper,
  Title,
  Spacer,
  HorizontalLine,
  Sphere,
  Main,
  HowItWorks,
  TravelDeals,
  FeedbackSection,
  FAQsLandingPage,
  PressArticles,
  PopularSearches,
  PressBar,
  SupportSection,
  VideoSection,
  UspDropdowns,
  TravelDeals2020
};
