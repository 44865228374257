import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styled from 'react-emotion/macro';
import { trackDirectPayment_PayNowBtn } from 'Utils';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonLoading: false,
    };
  }

  handleSubmit = async ev => {
    console.log('[stripePaymentForm] Calling handle submit');
    trackDirectPayment_PayNowBtn();
    this.setState({ isButtonLoading: true });
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // See our confirmCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
    await this.props.stripe
      .confirmCardPayment(this.props.clientSecret, {
        payment_method: {
          card: this.props.elements.getElement('card'),
          billing_details: {
            name: this.props.customerName,
          },
        },
      })
      .then(result => {
        console.log('[InjectedPaymentForm] The stripe result is', result);
        this.setState({ isButtonLoading: false });
        const payment_intent_status = result.paymentIntent.status;

        if (payment_intent_status === 'succeeded') {
          this.props.handleStripePaymentComplete();
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ isButtonLoading: false });
      });
  };

  //  payment_intent.succeeded

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <PaymentButtonWrapper>
            <Button
              className="paymentButton"
              htmlType="submit"
              loading={this.state.isButtonLoading}
            >
              Pay £{this.props.checkoutPrice}
            </Button>
          </PaymentButtonWrapper>
        </form>
      </>
    );
  }
}

PaymentForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  handleStripePaymentComplete: PropTypes.func.isRequired,
  checkoutPrice: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default injectStripe(PaymentForm);

const PaymentButtonWrapper = styled.div`
  padding: 0px 0px 0px 0px !important;

  .paymentButton {
    grid-column: 2/3;

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 44px;

    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 25px;

    font-size: 19px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: #4860ff;
    color: white;
    border-color: #4860ff !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;
    }

    @media (max-width: 400px) {
      .faIconProceed {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      grid-column: 1/3;

      span {
        margin-top: 0px;
      }
    }

    @media (min-width: 1000px) {
      height: 47px;
      font-size: 20px;
      max-width: 50%;
    }
  }

  @media (min-width: 1000px) {
    text-align: center;
    margin-top: 35px;
  }
`;
