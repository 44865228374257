import styled from 'react-emotion/macro';

export const DealDescriptionWrapper = styled.div`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;

  p {
    display: block;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 5px;
    font-size: 15px;
    line-height: 1.25;
    margin: 0;
    color: black;
  }
 
  @media(min-width: 1000px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

