import React, { Component } from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import i18n from 'Utils/i18n.js';

//#region
const DetailsPaymentPlanWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  grid-row: 3/7;
  grid-column: 3;
  border-left: dashed 1px #d0d0d0;
  border-top: dashed 1px #d0d0d0;

  @media (max-width: 1000px) {
    display: none;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    line-height: 27px;

    span:nth-child(2) {
      float: right;
    }

    :nth-child(even) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;
//#endregion

export default class DetailsPaymentPlan extends Component {
  render() {
    const { flymblePrice } = this.props.costs;
    return (
      <DetailsPaymentPlanWrapper className="detailsPaymentPlanDisplay">
        <p>
          <span>{i18n.t('DetailsPaymentPlan..Airfare')}</span>
          <span>
            {i18n.t('DetailsPaymentPlan..£')}
            {flymblePrice.toFixed(2)}
          </span>
        </p>
      </DetailsPaymentPlanWrapper>
    );
  }
}

DetailsPaymentPlan.propTypes = {
  costs: PropTypes.shape({
    flymblePrice: PropTypes.number.isRequired,
    serviceFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export { DetailsPaymentPlan };
