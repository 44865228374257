import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'react-emotion/macro';
import AppContext from 'Context/AppContext';

const CheckoutTitle = () => {
  const context = useContext(AppContext);
  const { selectedFlightTicket } = context;
  const { cityFrom: departureCity, cityTo: arrivalCity } = selectedFlightTicket;
  return (
    <CheckoutTitleWrapper>
      <h1>
        <b>
          {departureCity}{' '}
          <FontAwesomeIcon icon={faArrowRight} className="arrowIcon" />{' '}
          {arrivalCity}
        </b>{' '}
      </h1>
    </CheckoutTitleWrapper>
  );
};

CheckoutTitle.propTypes = {};

export { CheckoutTitle };

const CheckoutTitleWrapper = styled.div`
  text-align: center;
  grid-column: 1/2;

  h1 {
    font-size: 22px;
    font-weight: 300;
    margin-top: 10px;
  }

  hr,
  .viewDetailsWrapper,
  .priceDisplay,
  .selectFlightTicket {
    display: none !important;
  }

  .showDetailsDesktop {
    display: inline-block !important;
  }

  @media (min-width: 1000px) {
    grid-column: 1/3;
    text-align: left;
    padding-bottom: 30px;

    h1 {
      font-size: 38px;
    }

    .arrowIcon {
      font-weight: 400 !important;
      font-size: 30px;
    }
  }
`;
