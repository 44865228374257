import React, { useState } from 'react';
import styled from 'react-emotion/macro';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AirlineRowTop } from './AirlineRowTop';
import { TravelDisplay } from './TravelDisplay';
import { ShowDetailsDesktop } from './ShowDetailsDesktop';
import { DetailedView } from './DetailedView';
import { DetailsPaymentPlan } from './DetailsPaymentPlan';
import { BottomRow } from './BottomRow';
import { TotalPriceRow } from './TotalPriceRow';
import { KIND_OF_TRIP } from '../../../constants';

const FlightTicketWrapper = styled.div`
  padding: 10px 0;
  grid-column: 2/4;

  @media (min-width: 1000px) {
    .borderlineTravelDisplay {
      border-left: 1px #dfdfdf solid;
    }
  }
}
`;

//#region
const checkoutSectionBorder = '1px solid #E8E8E8';
const SearchResult = styled.div`
  min-height: 100px;
  padding: 17px;
  padding-bottom: 10px;
  width: 100%;
  background-color: white;
  border-radius: 3px;
  border: ${checkoutSectionBorder};
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (min-width: 1000px) {
    /* grid-template-columns: 24fr 24fr 15fr; */
    grid-template-columns: ${props =>
      props.isOneWay ? '24fr 1fr 12fr' : '24fr 24fr 15fr'};
    max-width: ${props => props.isOneWay && '70%'};
    margin: ${props => props.isOneWay && 'auto'};
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  hr {
    text-align: right;
    margin-right: -1px;
    width: 97%;
    grid-column: 1/3;
    opacity: 0.75;
    border-top: dashed 1px #f0f0f0;

    @media (min-width: 1000px) {
      display: none;
    }

    @media (max-width: 1000px) {
      width: 100%;
      margin-right: 0px;
    }
  }

  .v1 {
    border-left: 6px solid green;
    
    height: 500px;
    position: absolute;
    left: 50%;

    @media (min-width: 1000px) {
      width: 100%;
      margin-right: 20px;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
}
`;
//#endregion

const FlightTicket = ({
  typeFlight,
  airlineCodes,
  outboundCodes,
  returnCodes,
  airlineName,
  nrSeatsLeft,
  seatsText,
  departureCity,
  arrivalCity,

  costs,
  theFlightTicket,

  departureTimeOutbound,
  arrivalTimeOutbound,
  departureAirportOutbound,
  arrivalAirportOutbound,
  departureCityOutbound,
  arrivalCityOutbound,
  flightDurationOutbound,
  routeStopsOutbound,
  outboundTextDateDeparture,
  outboundTextDateArrival,

  departureTimeInbound,
  arrivalTimeInbound,
  departureAirportReturn,
  arrivalAirportReturn,
  departureCityReturn,
  arrivalCityReturn,
  returnTextDateDeparture,
  returnTextDateArrival,
  flightDurationInbound,
  routeStopsInbound,

  outboundTotalDuration,
  returnTotalDuration,
  departureAirport,
  arrivalAirport,

  route,
}) => {
  const [viewDetails, toggleDetails] = useState(false);
  const { t } = useTranslation();

  return (
    <FlightTicketWrapper>
      <div className="verticalSeperationLine" />
      <div className="searchResultCheckoutGrid">
        <SearchResult isOneWay={typeFlight === KIND_OF_TRIP.ONE_WAY}>
          <AirlineRowTop
            airlineCodes={airlineCodes}
            flightLegCodes={outboundCodes}
            airlineName={airlineName}
            nrSeatsLeft={nrSeatsLeft}
            seatsText={seatsText}
            legType={t('DetailedView..Outbound')}
            viewDetails={viewDetails}
            departureCity={departureCity}
            arrivalCity={arrivalCity}
            instalment={costs.instalment}
          />
          {/*OUTBOUND TRAVEL DISPLAY */}
          <TravelDisplay
            typeFlight={typeFlight}
            departureTime={departureTimeOutbound}
            arrivalTime={arrivalTimeOutbound}
            departureAirport={departureAirportOutbound}
            arrivalAirport={arrivalAirportOutbound}
            departureRouteCity={departureCityOutbound}
            arrivalRouteCity={arrivalCityOutbound}
            flightDuration={flightDurationOutbound}
            routeStops={routeStopsOutbound}
            departureRouteDay={outboundTextDateDeparture}
            arrivalRouteDay={outboundTextDateArrival}
          />
          {typeFlight !== KIND_OF_TRIP.ONE_WAY && (
            <AirlineRowTop
              airlineCodes={airlineCodes}
              flightLegCodes={returnCodes}
              airlineName={airlineName}
              nrSeatsLeft={nrSeatsLeft}
              seatsText={seatsText}
              legType={t('DetailedView..Return')}
              viewDetails={viewDetails}
              departureCity={departureCity}
              arrivalCity={arrivalCity}
              instalment={costs.instalment}
            />
          )}
          {/*RETURN TRAVEL DISPLAY */}
          {typeFlight !== KIND_OF_TRIP.ONE_WAY && (
            <TravelDisplay
              typeFlight={typeFlight}
              departureTime={departureTimeInbound}
              arrivalTime={arrivalTimeInbound}
              departureAirport={departureAirportReturn}
              arrivalAirport={arrivalAirportReturn}
              departureRouteCity={departureCityReturn}
              arrivalRouteCity={arrivalCityReturn}
              departureRouteDay={returnTextDateDeparture}
              arrivalRouteDay={returnTextDateArrival}
              flightDuration={flightDurationInbound}
              routeStops={routeStopsInbound}
            />
          )}
          <hr />
          <ShowDetailsDesktop
            viewDetails={viewDetails}
            toggleDetails={toggleDetails}
          />
          {viewDetails ? (
            <React.Fragment>
              <DetailedView
                typeLeg="Outbound"
                textDateDeparture={outboundTextDateDeparture}
                textDateArrival={outboundTextDateArrival}
                totalDuration={outboundTotalDuration}
                airlineCodes={airlineCodes}
                airlineName={airlineName}
                departureTime={departureTimeOutbound}
                departureAirport={departureAirport}
                arrivalTime={arrivalTimeOutbound}
                arrivalAirport={arrivalAirport}
                departureCity={departureCity}
                arrivalCity={arrivalCity}
                connection={true}
                route={route.filter(flightLeg => 0 === flightLeg.return)}
                isOneWay={typeFlight === KIND_OF_TRIP.ONE_WAY}
              />

              {typeFlight !== KIND_OF_TRIP.ONE_WAY && (
                <>
                  <hr />
                  <DetailedView
                    typeLeg="Return"
                    textDateDeparture={returnTextDateDeparture}
                    textDateArrival={returnTextDateArrival}
                    totalDuration={returnTotalDuration}
                    airlineCodes={airlineCodes}
                    airlineName={airlineName}
                    departureTime={departureTimeOutbound}
                    departureAirport={departureAirport}
                    arrivalTime={arrivalTimeOutbound}
                    arrivalAirport={arrivalAirport}
                    departureCity={departureCity}
                    arrivalCity={arrivalCity}
                    connection={true}
                    route={route.filter(flightLeg => 1 === flightLeg.return)}
                  />
                </>
              )}
              <DetailsPaymentPlan costs={costs} />
              <hr />
              <TotalPriceRow totalPrice={costs} />
              <hr />
            </React.Fragment>
          ) : (
            ''
          )}
          <BottomRow
            costs={costs}
            theFlightTicket={theFlightTicket}
            viewDetails={viewDetails}
            departureCity={departureCity}
            arrivalCity={arrivalCity}
            toggleDetails={() => toggleDetails(!viewDetails)}
          />
        </SearchResult>
      </div>
    </FlightTicketWrapper>
  );
};

const CostsPropType = {
  instalment: PropTypes.number.isRequired,
  upfront: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  noInstalments: PropTypes.number.isRequired,
  flymblePrice: PropTypes.number.isRequired,
};

FlightTicket.propTypes = {
  typeFlight: PropTypes.string.isRequired,
  airlineCodes: PropTypes.array.isRequired,
  outboundCodes: PropTypes.array.isRequired,
  returnCodes: PropTypes.array.isRequired,
  airlineName: PropTypes.string.isRequired,
  nrSeatsLeft: PropTypes.number.isRequired,
  seatsText: PropTypes.string.isRequired,
  departureCity: PropTypes.string,
  arrivalCity: PropTypes.string,

  theFlightTicket: PropTypes.object.isRequired,

  departureTimeOutbound: PropTypes.string.isRequired,
  arrivalTimeOutbound: PropTypes.string.isRequired,
  departureAirportOutbound: PropTypes.string.isRequired,
  arrivalAirportOutbound: PropTypes.string.isRequired,
  departureCityOutbound: PropTypes.string.isRequired,
  arrivalCityOutbound: PropTypes.string.isRequired,
  flightDurationOutbound: PropTypes.string.isRequired,
  routeStopsOutbound: PropTypes.number.isRequired,
  outboundTextDateDeparture: PropTypes.string.isRequired,
  outboundTextDateArrival: PropTypes.string.isRequired,

  departureTimeInbound: PropTypes.string.isRequired,
  arrivalTimeInbound: PropTypes.string.isRequired,
  departureAirportReturn: PropTypes.string.isRequired,
  arrivalAirportReturn: PropTypes.string.isRequired,
  departureCityReturn: PropTypes.string.isRequired,
  arrivalCityReturn: PropTypes.string.isRequired,
  returnTextDateDeparture: PropTypes.string.isRequired,
  returnTextDateArrival: PropTypes.string.isRequired,
  flightDurationInbound: PropTypes.string,
  routeStopsInbound: PropTypes.number.isRequired,

  outboundTotalDuration: PropTypes.string.isRequired,
  returnTotalDuration: PropTypes.string,
  departureAirport: PropTypes.string,
  arrivalAirport: PropTypes.string,

  route: PropTypes.array.isRequired,
  costs: PropTypes.shape(CostsPropType).isRequired,
};

FlightTicket.defaultProps = {
  route: [],
};

export default FlightTicket;
