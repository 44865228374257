import React, { useState } from 'react';
import styled from 'react-emotion/macro';
import { TypeHolidaySearch } from './TypeHolidaySearch';
import { CategoryCard } from './CategoryCard';

export const TravelCategories = () => {
  const [stateCategory, setStateCategory] = useState('Flights');

  return (
    <>
      <TravelCategoriesWrapper>
        {false && (
          <>
            <CategoryCard
              text="Flights"
              cardId="Flights"
              activeCategory={stateCategory}
              handleOnClick={setStateCategory}
              infoMessage={false}
            />
            <CategoryCard
              text="Hotel + Flight"
              cardId="Packages"
              activeCategory={stateCategory}
              handleOnClick={setStateCategory}
            />
            <CategoryCard
              text="Hotels"
              cardId="Hotels"
              activeCategory={stateCategory}
              handleOnClick={setStateCategory}
            />
          </>
        )}
      </TravelCategoriesWrapper>
      <TypeHolidaySearch />
    </>
  );
};

const TravelCategoriesWrapper = styled.div`
  /* border-bottom: solid 3px #0015a3; */
  margin-left: 20px;
  margin-right: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
  color: white;
`;
