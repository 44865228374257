import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';

class DatePickerPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: null,
      endValue: null,
      endOpen: false,
      minValue: moment().add(1, 'month'),
      maxValue: moment().add(12, 'month'),
      defaultValue: moment().add(4, 'month'),
    };
  }

  disabledStartDate = startValue => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = endValue => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    console.log('DPRange onChange ORYGINAL', 'field', field, 'value', value);
    this.setState({
      [field]: value,
    });
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  dateInRange = date => {
    return (
      date &&
      !date.isBetween(this.state.minValue, this.state.maxValue, null, '[]')
    );
  };

  dateInRangeTo = date => {
    if (!this.state.startDate) {
      return (
        date &&
        !date.isBetween(this.state.minValue, this.state.maxValue, null, '[]')
      );
    } else {
      return (
        date &&
        !date.isBetween(this.state.startDate, this.state.maxValue, null, '[]')
      );
    }
  };

  handleCalendarChange = ({ d1, d2, s1, s2 }) => {
    console.log('OnCalendarChange CALLED', d1, d2, s1, s2);
  };

  componentDidMount() {
    this.props.initializeDepartureDate(this.state.defaultValue);
  }

  render() {
    function disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().add(1, 'month');
    }
    return (
      <div className={this.props.className}>
        <DatePicker
          format="YYYY-MM-DD"
          placeholder="Departure"
          onChange={this.props.onChange ? this.props.onChange : null}
          onOpenChange={this.handleStartOpenChange}
          allowClear={true}
          disabledDate={disabledDate}
          showToday={false}
          mode="date"
          onCalendarChange={this.handleCalendarChange}
          size="large"
          defaultValue={this.state.defaultValue}
        />
      </div>
    );
  }
}

export { DatePickerPackages };

DatePickerPackages.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  initializeDepartureDate: PropTypes.func.isRequired,
};
