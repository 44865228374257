import styled from 'react-emotion/macro';

export const TripAdvisorWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;

  .contentBox {
    padding: 11px;
    padding-top: 8px;
    box-shadow: #ccc 0 0 4px;
    border-bottom: 2px solid #68A353;
    display: grid;
    grid-template-columns: 4fr 3fr;
    align-items: center;

    .stars {
      height: 16px;
      display: block;
    }

    .logo {
      height: 24px;
      display: block;
      margin-bottom: 5px;
    }

    .reviews {
      text-align: right;
    }
  }

  @media(min-width: 1000px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
