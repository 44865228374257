import { PARTNERS } from 'Utils';
// Partner Logo's.
// import LogoKlarna from 'assets/images/klarna.svg';
import LogoFNPL from 'assets/images/fnpl-logo.svg';
// import LogoVisa from 'assets/images/visa-master.svg';

const flyNowPayLaterInfoText =
  'Fly now, pay later. Flights up to £3000. Upfront credit fee may apply.';

// const klarnaInfoText =
//   'Flights must depart in 30+ days from day of booking. Flights up to £800. No additional fee.';

// const stripeInfoText =
//   'Pay in full using your debit or credit card and receive £15 discount.';

const paymentPlanOptionsPartnersWithKlarna = totalBookingPrice => {
  return [
    // {
    //   partnerName: PARTNERS.STRIPE,
    //   firstPar: 'Pay in full',
    //   desktopText: null,
    //   logo: LogoVisa,
    //   cardNrMonths: 3,
    //   monthlyPrice: (totalBookingPrice / 1).toFixed(2),
    //   priceLine: `pay £${(totalBookingPrice / 1).toFixed(0)} today`,
    //   subPriceLine: 'Receive £15 discount, no additional fee.',
    //   infoText: stripeInfoText,
    //   hasNoInterestBanner: '£15 discount',
    //   hasSubPriceLine: true,
    // },
    {
      partnerName: PARTNERS.FNPL,
      firstPar: '3 monthly instalments',
      desktopText: null,
      logo: LogoFNPL,
      cardNrMonths: 3,
      monthlyPrice: (totalBookingPrice / 1).toFixed(2),
      priceLine: `from £${(totalBookingPrice / 3).toFixed(0)}/mo`,
      subPriceLine: 'Pay 10% interest upfront',
      infoText: flyNowPayLaterInfoText,
      // hasNoInterestBanner: '10% interest',
      hasNoInterestBanner: false,
      hasSubPriceLine: true,
    },
    {
      partnerName: PARTNERS.FNPL,
      firstPar: '6 monthly instalments',
      desktopText: null,
      logo: LogoFNPL,
      cardNrMonths: 6,
      monthlyPrice: (totalBookingPrice / 6).toFixed(2),
      priceLine: `from £${(totalBookingPrice / 6).toFixed(0)}/mo`,
      subPriceLine: 'Pay 14% interest upfront',
      // hasNoInterestBanner: '14% interest',
      hasNoInterestBanner: false,
      hasSubPriceLine: true,
      infoText: flyNowPayLaterInfoText,
    },
    {
      partnerName: PARTNERS.FNPL,
      firstPar: '12 monthly instalments',
      desktopText: null,
      logo: LogoFNPL,
      cardNrMonths: 12,
      monthlyPrice: (totalBookingPrice / 12).toFixed(2),
      priceLine: `from £${(totalBookingPrice / 12).toFixed(0)}/mo`,
      subPriceLine: 'Pay 17% interest upfront',
      infoText: flyNowPayLaterInfoText,
      // hasNoInterestBanner: '17% interest',
      hasNoInterestBanner: false,
      hasSubPriceLine: true,
    },
  ];
};

export { paymentPlanOptionsPartnersWithKlarna };

// {
//   partnerName: PARTNERS.KLARNA,
//   firstPar: '3 interest-free payments',
//   desktopText: null,
//   logo: LogoKlarna,
//   cardNrMonths: 3,
//   monthlyPrice: (totalBookingPrice / 1).toFixed(2),
//   // priceLine: `pay 3 x £${(totalBookingPrice / 3).toFixed(0)}`,
//   priceLine: `pay £${(totalBookingPrice / 3).toFixed(0)} upfront + 2x £${(totalBookingPrice / 3).toFixed(0)}/mo`,
//   subPriceLine: 'Pay 0% interest upfront',
//   infoText: klarnaInfoText,
//   hasNoInterestBanner: '0% interest',
//   hasSubPriceLine: true,
// },

// const paymentPlanOptionsPartners = totalBookingPrice => {
//   return [
//     {
//       partnerName: PARTNERS.STRIPE,
//       firstPar: 'Pay in full',
//       desktopText: null,
//       logo: LogoVisa,
//       nrMonths: 1,
//       monthlyPrice: (totalBookingPrice / 1).toFixed(2),
//       priceLine: `£${totalBookingPrice.toFixed(2)} total`,
//       subPriceLine: 'Debit or credit card payment',
//       infoText:
//         'Pay in full via debit or credit card and get free premium customer support.',
//       hasNoInterestBanner: false,
//     },
//     {
//       partnerName: PARTNERS.KLARNA,
//       firstPar: '3 interest-free payments',
//       desktopText: null,
//       logo: LogoKlarna,
//       nrMonths: 2,
//       monthlyPrice: (totalBookingPrice / 3).toFixed(2),
//       priceLine: `3x £${(totalBookingPrice / 3).toFixed(2)}/mo`,
//       subPriceLine: `Interest-free and soft credit checks.`,
//       hasNoInterestBanner: true,
//       infoText:
//         'Flights must depart in 30+ days from day of booking. Flights up to £800. No additional fee.',
//     },
//     {
//       partnerName: PARTNERS.FNPL,
//       firstPar: '3-12 monthly payments',
//       desktopText: null,
//       logo: LogoFNPL,
//       nrMonths: 12,
//       monthlyPrice: (totalBookingPrice / 12).toFixed(2),
//       priceLine: `From £${(totalBookingPrice / 12).toFixed(2)}/mo`,
//       subPriceLine: 'Plus upfront transaction fee of 10-17%.',
//       infoText:
//         'Fly now, pay later. Flights up to £3000. Upfront credit fee may apply.',
//       hasNoInterestBanner: false,
//     },
//   ];
// };
