import React from 'react';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

const ShowDetailsDesktopWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 7;
  text-align: center;
  margin-top: 10px;

  @media (max-width: 1000px) {
    .showDetailsDesktop {
      display: none;
    }
  }

  span {
    margin: -10px;
    background-color: #f0f0f0;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 11px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;

    cursor: pointer;

    p {
      margin: 0px;
      display: inline-block;
      font-size: 9px;
      font-weight: 300;
      vertical-align: middle;
    }
  }

  .clickHandlerDetails {
    /* display: inline-block; */
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .faIcon {
    margin-left: 10px;
    margin-bottom: 1px;
    font-size: 13px;
  }
`;

const ShowDetailsDesktop = ({ viewDetails, toggleDetails }) => {
  return (
    <ShowDetailsDesktopWrapper>
      <span
        className="clickHandlerDetails"
        // onClick={this.handleClick}
      >
        <span
          className="showDetailsDesktop"
          onClick={() => toggleDetails(!viewDetails)}
        >
          {!viewDetails ? (
            <Trans i18nKey="ShowDetailsDesktop..Show details">
              Show details
            </Trans>
          ) : (
            <Trans i18nKey="ShowDetailsDesktop..Hide details">
              Hide details
            </Trans>
          )}
          <p className="faIcon">
            <FontAwesomeIcon icon={viewDetails ? faChevronUp : faChevronDown} />
          </p>
        </span>
      </span>
    </ShowDetailsDesktopWrapper>
  );
};

ShowDetailsDesktop.propTypes = {
  viewDetails: PropTypes.bool.isRequired,
  toggleDetails: PropTypes.func.isRequired,
};

export { ShowDetailsDesktop };
