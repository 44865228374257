import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import moment from 'moment';

export const CheckInOutDates = ({
  daysOfStay,
  departureAirport,
  packageDepartureDate,
}) => {
  let arrivalDate = moment(packageDepartureDate, 'YYYY-MM-DD').format(
    'dddd, DD MMM YYYY'
  );
  let returnDate = moment(packageDepartureDate, 'YYYY-MM-DD')
    .add(daysOfStay, 'day')
    .format('dddd, DD MMM YYYY');

  return (
    <CheckInOutDatesWrapper>
      <div className="row">
        <div>Flight departs:</div>
        <div>{arrivalDate}</div>
      </div>
      <div className="row">
        <div>Flight returns:</div>
        <div>{returnDate}</div>
      </div>
      <div className="row">
        <div>Departure airport</div>
        <div>{departureAirport}</div>
      </div>
      <div className="row" style={{ display: 'none' }}>
        <div>1 Room, 2 Adults</div>
        <br />
      </div>
    </CheckInOutDatesWrapper>
  );
};

CheckInOutDates.propTypes = {
  daysOfStay: PropTypes.number.isRequired,
  departureAirport: PropTypes.string.isRequired,
  packageDepartureDate: PropTypes.string.isRequired,
};

const CheckInOutDatesWrapper = styled.div`
  padding: 15px;
  padding-bottom: 15px;
  padding-top: 8px;

  .row {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
`;
