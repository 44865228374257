import React from 'react';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { trackShareFlightTicket } from 'Utils/facebookFunctions';
import { Trans } from 'react-i18next';

//#region
const ShareTicketWrapper = styled.div`
  cursor: pointer;
  .shareFunctions {
    text-align: center;
    padding-top: 10px;
    font-weight: 400;
    color: black;
    margin-bottom: -20px;
    .shareText {
      display: inline-block;
    }
  }

  @media (max-width: 1000px) {
    /* display: none; */
    display: inline-block;
    .shareFunctions {
      float: right !important;
      margin-bottom: 0px;
      padding-top: 0px;
      .shareText {
        display: none;
        /* display: inline-block; */
      }
    }
  }
`;
//#endregion

class ShareTicket extends React.Component {
  shareFacebook = event => {
    trackShareFlightTicket();
    window.FB.ui(
      {
        method: 'share',
        href: `https://flymble.com${window.location.pathname}`,
      }
      // ,
      // function(response) {
      //   return console.log('The facebook response is...', response);
      // }
    );

    return false;
  };

  render() {
    return (
      <React.Fragment>
        {!this.props.viewDetails && (
          <ShareTicketWrapper>
            <div className="shareFunctions" onClickCapture={this.shareFacebook}>
              <div className="shareText">
                <Trans i18nKey="ShareTicket..Share flight">Share flight</Trans>
              </div>{' '}
              &nbsp; &nbsp;
              <FontAwesomeIcon
                icon={faFacebookF}
                className="faStyle"
                id="shareTicketFacebook"
              />
              &nbsp; &nbsp;
              <FontAwesomeIcon
                icon={faShareAlt}
                className="faStyle"
                id="shareTicketFacebook"
              />
            </div>
          </ShareTicketWrapper>
        )}
      </React.Fragment>
    );
  }
}

export { ShareTicket };
