import React, { useContext } from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import { trackPayInFullButton } from 'Utils';
import AppContext from 'Context/AppContext';
import { BOOKING_TYPES } from '../../../../constants';

const flymbleBlue = '#4860ff';
//

export const TextHeading = ({  
  titleText,
  travelDates,
  travelRoutes,
  passengersDisplay,
  totalBookingPriceString,
  showPrice,
  showTravelInfo,
  isVisible,
  goToCheckout,
}) => {
  const context = useContext(AppContext);

  const { changeTypeBookingContext } = context;


  const handlePayInFullFunc = () => {
    trackPayInFullButton();
    changeTypeBookingContext(BOOKING_TYPES.STRIPE_Flights);

    goToCheckout();

    // message.warning('Pay in full will be available from February 6th', 2);
  };

  return (
    isVisible && (
      <TextHeadingWrapper>
        <div className="textHeading">
          <h2 className="buildYourPlan">{`${titleText}`}</h2>
          {showTravelInfo ? (
            <p className="travelInformation">
              {`${travelRoutes}`}
              <br />
              {travelDates && (
                <React.Fragment>
                  {travelDates}
                  <br />
                </React.Fragment>
              )}

              {`${passengersDisplay}`}
            </p>
          ) : (
            // <LearnMore isScrollSmoothOnClick={scrollSmoothOnClick} />
            <PayInFull handlePayInFullFunc={handlePayInFullFunc} />
          )}
          <div className="seperationLine" />
          {showPrice && (
            <div className="headingPriceDisplay">{totalBookingPriceString}</div>
          )}
        </div>
      </TextHeadingWrapper>
    )
  );
};

const PayInFull = ({ handlePayInFullFunc }) => {
  return (
    <PayInFullWrapper>
      <div onClick={() => handlePayInFullFunc()} className="buttonWrapper">
        or pay in full here
      </div>
    </PayInFullWrapper>
  );
};

const PayInFullWrapper = styled.div`
  background-color: rgb(72, 96, 255);
  border-radius: 16px;
  display: inline-block;
  padding: 4px 26px;
  cursor: pointer;
  margin-top: 0px;
  @media (min-width: 1000px) {
    margin-top: 10px;
  }

  .buttonWrapper {
    color: white;
    font-weight: 400;
  }
`;

PayInFull.propTypes = {
  handlePayInFullFunc: PropTypes.func.isRequired,
};

const LearnMore = ({ isScrollSmoothOnClick }) => {
  return (
    <b>
      or learn more{' '}
      <b
        style={{ color: '#82888a', cursor: 'pointer' }}
        onClick={() =>
          window.scroll({
            top: 920,
            left: 0,
            behavior: isScrollSmoothOnClick ? 'smooth' : 'auto',
          })
        }
      >
        here
      </b>
    </b>
  );
};

LearnMore.propTypes = {
  isScrollSmoothOnClick: PropTypes.bool.isRequired,
};

TextHeading.propTypes = {
  totalBookingPriceString: PropTypes.string.isRequired,
  titleText: PropTypes.string,
  travelDates: PropTypes.string,
  travelRoutes: PropTypes.string,
  passengersDisplay: PropTypes.string,
  showPrice: PropTypes.bool,
};

const TextHeadingWrapper = styled.div`
  text-align: center;
  padding-bottom: 27px;

  .bookingInformationBanner {
    grid-template-columns: 1fr 1fr;
    display: grid;
    align-items: center;
    border-bottom: #dbdbdb solid 2px;
    padding-bottom: 20px;
    margin-bottom: 30px;

    .bookingPrice {
      text-align: right;
      display: inline-block;
      font-size: 30px;
      color: ${flymbleBlue};
      font-weight: bold;
    }

    .routeInfo {
      display: inline-block;
    }
  }

  .headingPriceDisplay {
    margin-top: 20px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 15px;
    padding: 8px 17px;
    border-radius: 4px;
    background-color: #e8e8e8;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    color: black;
  }

  hr {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    border: solid 1px black;
  }

  .travelInformation {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
  }

  .seperationLine {
    width: 100%;
    border-top: 3px solid #0015a3;
    margin-top: 14px;
  }

  .buildYourPlan {
    padding-top: 25px;
    margin-top: 0px;
    margin-bottom: 10px;

    @media (max-width: 1000px) {
      font-size: 22px;
    }
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 1000px) {
    margin-bottom: 20px;
    grid-column: 2/4;

    .textHeading {
      grid-column: 2/4;
      max-width: 33%;
      margin-left: auto;
      margin-right: auto;

      .seperationLine {
        display: none;
      }

      .headingPriceDisplay {
        margin-top: 35px;
      }

      .routeDisplay {
        font-size: 17px;
      }
    }
  }
`;
