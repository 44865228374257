import React from 'react';
import styled from 'react-emotion/macro';
import AppContext from 'Context/AppContext';
import SearchMobileLP, {
  SearchMobileLPWrapper,
} from 'Page/Search/SearchMobileLP';

const FrontFormWrapper = styled.div`
  background-color: white;
  color: lightsteelblue;
  position: relative;
  top: 25px;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  @media (min-width: 999px) {
    background-color: #0000008c;
    grid-column: 2/4;
  }

  @media(max-width: 1000px) {
    top: 0px;
  }

`;

const FrontSearch = () => {
  return (
    <AppContext.Consumer>
      {context => {
        const { toggleShowResults } = context;
        return (
          <FrontFormWrapper>
            <SearchMobileLPWrapper>
              <SearchMobileLP
                searchContext={context}
                toggleShowResults={toggleShowResults}
                isOneWayEnabled={true}
              />
            </SearchMobileLPWrapper>
          </FrontFormWrapper>
        );
      }}
    </AppContext.Consumer>
  );
};

export default FrontSearch;
