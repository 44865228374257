import styled from 'react-emotion/macro';

const flymbleBlue = '#4860ff';

export const PhotoSliderWrapper = styled.div`
  margin-bottom: 50px;

  .heroImage {
    width: 100%;
    height: 250px;
  }

  @media (min-width: 1000px) {
    .imageHolder {
      background-color: #1a1a1a;
      .heroImage {
        width: 60%;
        height: 102%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

export const MorePhotosWrapper = styled.div`
  background-color: white;
  padding: 20px;


  .tripAdvisorLogo {
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    height: 80px;
  }

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    color: black;
  }

  .viewButton {
    background-color: ${flymbleBlue};
    height: 50px;
    
    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    color: white;
    border-color: ${flymbleBlue} !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  @media(min-width: 1000px) {
    padding-bottom: 50px;
  }
`;
