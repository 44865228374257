function createTripTextConstants(lastSearch, theFlightTicket) {
  const dateText = 'D MMMM';

  const { dateFrom, dateTo, noAdults, noChildren, noInfants } = lastSearch;
  const { cityFrom, cityTo } = theFlightTicket;

  // Display the travel dates: departure date - return date.
  let travelDates = dateFrom.format(dateText);
  travelDates +=
    dateTo && dateTo.isValid() ? ` - ${dateTo.format(dateText)}` : ``;

  // Display the departure and destination Cities.
  const travelCities = `${cityFrom} - ${cityTo}`;

  const adultsText = noAdults === 1 ? '1 adult' : `${noAdults} adults`;
  const childrenText =
    noChildren === 1 ? ', 1 child' : `, ${noChildren} children`;
  const infantsText = noInfants === 1 ? ', 1 infant' : `, ${noInfants} infants`;

  const noPassengersString = `${adultsText}${
    noChildren !== 0 ? childrenText : ''
  }${noInfants !== 0 ? infantsText : ''}`;

  return {
    travelDates,
    travelCities,
    noPassengersString,
  };
}

export { createTripTextConstants };
