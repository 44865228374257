import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const NeedHelpWrapper = styled('div')`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 50px;
  text-align: center;

  h2 {
    margin-left: auto;
    margin-right: auto;
    color: black;
    text-align: center;
    padding-top: 30px;

    font-weight: 600;
    font-size: 34px;
    margin-bottom: 25px;
  }

  h4 {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 6px;

    .boldClass {
      margin-right: 15px;
      padding-top: 7px;
      display: block;
      font-weight: bold;
    }

    .faIcon {
      margin-right: 15px;
    }
  }

  .openingTimes {
    font-size: 18px;
    font-weight: 500;
  }
`;

const NeedHelp = ({ titleText, phoneNumber, callNow, openingTimes }) => {
  return (
    <NeedHelpWrapper>
      <h2>{titleText}</h2>
      <h4>
        {callNow}: <br />
        <div className="boldClass">
          <FontAwesomeIcon icon={faPhone} className="faIcon" />
          {phoneNumber}
        </div>
      </h4>
      <div className="openingTimes">{openingTimes}</div>
    </NeedHelpWrapper>
  );
};

NeedHelp.displayName = 'NeedHelp';

NeedHelp.propTypes = {
  titleText: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  callNow: PropTypes.string.isRequired,
  openingTimes: PropTypes.string.isRequired,
};

export default NeedHelp;
