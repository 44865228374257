import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ViewDetailsToggleButton } from 'components/ViewDetailsToggleButton';
import styled from 'react-emotion/macro';

const UspArray = [
  {
    title: 'Spread the cost over 12 months',
    textContent:
      'Spread the cost of your holiday over 3-12 monthly instalments. Choose to pay off the balance before or after your departure.',
  },
  {
    title: 'Instant & free credit decision',
    textContent:
      'Our credit partner FlyNowPayLater performs an instant credit check with an on-screen decision in 5 seconds. There is no charge if you are not accepted.',
  },
  {
    title: 'Lock-in prices before they rise',
    textContent:
      'Flymble aims to make travel as affordable as possible. Our price match guarantee means you always get the best deal. Simply send us a flight, hotel or package deal with a better rate and we will match it.',
  },
  // {
  //   title: 'Book flights, hotels & packages',
  //   textContent:
  //     'Flymble offers flights, hotels and package deals. You can search via our website, or simply ask one of our travel experts to assist you. They will be able to help you with any request you might have.',
  // },
];

export const UspDropdowns = () => {
  const [openDropDown, setOpenDropDown] = useState('');
  return (
    <UspDropdownsWrapper>
      {UspArray.map((usp, index) => {
        const { title, textContent } = usp;
        return (
          <div
            key={index}
            onClick={() =>
              setOpenDropDown(openDropDown !== title ? title : null)
            }
          >
            <div className="uspBox">
              <FontAwesomeIcon icon={faCheck} className="faIcon" />
              <div className="title">{title}</div>
              <ViewDetailsToggleButton
                isTurnedUp={openDropDown === title}
                handlerFunction={() => null}
                text={null}
              />

              <DropDownContent
                textContent={textContent}
                isVisible={openDropDown === title}
              />
            </div>
          </div>
        );
      })}
    </UspDropdownsWrapper>
  );
};

const UspDropdownsWrapper = styled.div`
  margin: 20px;
  margin-top: 30px;
  margin-bottom: 0px;
  color: black;

  @media (min-width: 1000px) {
    display: none;
  }

  .uspBox {
    display: grid;
    grid-template-columns: 5fr 28fr 4fr;
    background-color: #f6f6f6;
    border-radius: 3px;
    padding: 18px;
    align-content: center;
    margin-bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 15px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .faIcon {
      color: #14c29b;
      font-size: 22px;
    }
  }
`;

export const DropDownContent = ({ textContent, isVisible }) => {
  return (
    isVisible && <DropDownContentWrapper>{textContent}</DropDownContentWrapper>
  );
};

const DropDownContentWrapper = styled.div`
  padding-top: 15px;
  grid-column: 1/4;
  font-size: 15px;
`;
