// import React from 'react';
import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';

const checkoutSectionBorder = '1px solid #E8E8E8';
const fontColorCheckout = 'black';

const iconSpacing = css`
  margin-right: 10px;
`;

// This const, styles the common styles of: Contact Details, Living Address and Employment status.
// Each do have a different css grid because of different forms.
// Therefore they also do have a component specific wrapper defined elsewhere.
// See components on the lines below (i.e. ContactDetailsWrapper etc.).

const SectionStyling = styled.div`
  padding-bottom: 25px;

  /* Check wether the color should be black, but we need to standardize. */
  color: black;

  .slideup {
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 440px;
  }

  .slidedown {
    overflow-y: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 460px;
  }

  @media (min-width: 1000px) {
    background-color: white;
    border: ${checkoutSectionBorder};
    border-radius: 3px;
    padding: 20px 20px;
    padding-bottom: 25px;

    margin-top: 40px;

    .coreForms {
      display: grid;
      grid-column-gap: 18px;
      grid-template-columns: 1fr 1fr;
    }

    .apartmentForms {
      display: grid;
      grid-column-gap: 18px;
      grid-template-columns:
        minmax(80px, 5fr) minmax(80px, 4fr) minmax(80px, 4fr)
        minmax(180px, 5fr);
    }

    h2 {
      font-size: 22px;
      margin-bottom: 36px;
      margin-top: 15px;
      color: ${fontColorCheckout};
    }

    .faIcon {
      font-size: 28px;
    }

    .slidedown {
      max-height: 190px;
    }
  }
`;

export {
  checkoutSectionBorder,
  fontColorCheckout,
  iconSpacing,
  SectionStyling,
};
