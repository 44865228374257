import React from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const orangeColor = '#ffdab9a3';
const blueColor = '#e0f6ff';

const WarningBannerWrapper = styled.div`
  padding: 10px 12px;
  padding-top: 8px;
  display: grid;
  align-items: center;
  color: black;

  background-color: rgb(224, 246, 255);
  background-color: ${props => (props.isOrange ? orangeColor: blueColor)};
  border-radius: 3px;

  margin-top: 12px;
  margin-bottom: 10px;
  font-weight: 400;

  .description {
    font-size: 12px;
  }

  @media (min-width: 1000px) {
    padding: 12px 12px;
    grid-column: 1/3;
    font-weight: 400;
    margin-bottom: 22px;
    margin-top: 20px;


    padding-left: 18px;

    .description {
      font-size: 13px;
      color: black;
    }

    .faIcon {
      font-size: 26px;
      text-align: center;
    }
  }
`;

const WarningBannerWrapperOrange = styled.div`
  padding: 16px 12px;

  display: grid;
  align-items: center;

  background-color: #ffdab9a3;
  border-radius: 3px;

  margin-bottom: 25px;

  span {
    font-size: 13px;
  }

  @media (min-width: 1000px) {
    grid-column: 1/3;
    font-weight: 400;
    grid-template-columns: 1fr 16fr;
    margin-bottom: 22px;
  margin-top: 25px;

    padding-left: 18px;

    span {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65);
      grid-column: 1/3;
    }

    .faIcon {
      font-size: 26px;
      text-align: center;
    }
  }
`;

const WarningBanner = ({ text, setDebugValues }) => (
  <WarningBannerWrapper>
    <FontAwesomeIcon className="faIcon" icon={faInfoCircle} />
    <span
      onClick={() => {
        process.env.NODE_ENV === 'development' && setDebugValues();
      }}
    >
      <b>
        {text}
        &nbsp;
      </b>
    </span>
  </WarningBannerWrapper>
);

WarningBanner.defaultProps = {
  text:
    'ContactDetails..Please fill in the contact details belonging to the person applying for installments. ',
  setDebugValues: () => {},
};

WarningBanner.propTypes = {
  text: PropTypes.string,
  setDebugValues: PropTypes.func,
};

// export { WarningBannerWrapper };
export { WarningBannerWrapper, WarningBannerWrapperOrange, WarningBanner };

export default WarningBanner;
