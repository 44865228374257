import React, { useContext, useState, useEffect } from 'react';
import ConfirmationPage from './SummarySectionConfirmation';
import AppContext from 'Context/AppContext';
import PropTypes from 'prop-types';
import { PackagesContext } from 'Context/PackagesContext';
import ConfirmationButtons from './SummarySectionConfirmation/ConfirmationButtons';
import { trackConfirmationPageButton } from 'Utils';
import FlyNowPayLaterBenefitsBanner from '../components/ConfirmationPartnerSection/ConfirmationPartnerSectionUK';
import { CheckoutFormsUKWrapper } from '../CheckoutForms/CheckoutFormsUK/styles';
import CoreView from '../CoreView';
import { Form } from 'antd'; // <----------------- Should be removed, currently only for the styling of the confirmation page.
import { RedirectElement } from 'components/RedirectElement';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import {
  packagesBookingMailchimp,
  trackDirectPayment_VisitConfirmationPage,
} from 'Utils';
import { BOOKING_TYPES } from '../../constants';

const ConfirmationPageContainerProxy = ({ isPaymentPage }) => {
  const context = useContext(AppContext);
  const packagesContext = useContext(PackagesContext);
  const { paymentPlanPartnerSelected, typeBookingContext } = context;

  useEffect(() => {
    typeBookingContext === BOOKING_TYPES.STRIPE_Flights &&
      trackDirectPayment_VisitConfirmationPage();
  }, []);

  const [isVisibleState, setIsVisibleState] = useState(true);
  const [
    redirectToConfirmationPageState,
    setRedirectToConfirmationPageState,
  ] = useState(false);
  const [
    redirectToCheckoutPageState,
    setRedirectToCheckoutPageState,
  ] = useState(false);
  const [
    redirectToThankYouPageState,
    setRedirectToThankYouPageState,
  ] = useState(false);

  // That means that this function needs to be called on the confirmation page
  function handleOnSubmitConfirmation() {
    trackConfirmationPageButton();
    if (window.location.pathname.indexOf('confirmation/packages') !== -1) {
      // Send to mail chimp the following information:
      const {
        departureAirportContext,
        departureDateContext,
        selectedPackageContext,
      } = packagesContext;

      // @todo this one can possibly be removed.
      false &&
        packagesBookingMailchimp(
          context.firstCheckoutPageFormValuesContext,
          departureAirportContext,
          departureDateContext,
          selectedPackageContext
        );

      setRedirectToThankYouPageState(true);
    } else {
      setIsVisibleState(!isVisibleState);
      setRedirectToConfirmationPageState(!redirectToConfirmationPageState);
    }
  }

  function handleEditFormDetails() {
    setRedirectToCheckoutPageState(!redirectToCheckoutPageState);
  }

  return (
    <>
      {!isPaymentPage ? (
        <CoreView>
          <CheckoutFormsUKWrapper>
            <Form>
              <ConfirmationPage
                isVisible={isVisibleState}
                data={context.firstCheckoutPageFormValuesContext}
                isPayInFull={
                  typeBookingContext === BOOKING_TYPES.STRIPE_Flights
                }
              >
                <FlyNowPayLaterBenefitsBanner
                  // isVisible={paymentPlanPartnerSelected === PARTNERS.FNPL}
                  isVisible={false}
                />
                <ConfirmationButtons
                  handleEditFormDetails={() => handleEditFormDetails()}
                  onConfirmButtonClick={() => handleOnSubmitConfirmation()}
                  isLoading={false}
                  paymentPlanPartnerSelected={paymentPlanPartnerSelected}
                  isConfirmationButtonsVisible={true}
                />
              </ConfirmationPage>
            </Form>
          </CheckoutFormsUKWrapper>
          <RedirectElement
            destination="/pay-booking"
            isAllowedRedirect={redirectToConfirmationPageState}
          />
          <RedirectElement
            destination="/checkout"
            isAllowedRedirect={redirectToCheckoutPageState}
          />
          <RedirectElement
            destination="/packages-pay-booking"
            isAllowedRedirect={redirectToThankYouPageState}
          />
        </CoreView>
      ) : (
        <ConfirmationPage
          isVisible={isVisibleState}
          data={context.firstCheckoutPageFormValuesContext}
          isPayInFull={typeBookingContext === BOOKING_TYPES.STRIPE_Flights}
        />
      )}
    </>
  );
};

ConfirmationPageContainerProxy.propTypes = {
  isPaymentPage: PropTypes.bool.isRequired,
};

const ConfirmationPageContainer = () => (
  <ErrorBoundary errorLocation="Confirmation" redirect={true}>
    <ConfirmationPageContainerProxy />
  </ErrorBoundary>
);

export { ConfirmationPageContainer };
export default ConfirmationPageContainer;
