import styled from 'react-emotion/macro';

const secretEscapesOrange = '#ff8c00';
const flymbleBlue = '#4860ff';

export const DealLabelsWrapper = styled.div`
  position: ${props => (props.isAbsolute ? 'absolute' : 'relative')};
  left: ${props => (props.isAbsolute ? '15px' : null)};
  top: ${props => (props.isAbsolute ? '8px' : null)};
  z-index: 2;
  .dealLabel,
  .discountLabel,
  .flightsLabel {
    padding: 8px 6px;
    margin: 8px 0;
    margin-right: 10px;
    background-color: ${secretEscapesOrange};
    /* background-color: ${flymbleBlue}; */
    display: inline-block;
    box-sizing: border-box;
    border-radius: 3px;
    color: #ffffff;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 400;
  }

  .flightsLabel {
    background-color: black;
    color: white;
  }

  .discountLabel {
    background-color: #00b679;
  }
`;
