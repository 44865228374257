import React from 'react';
import { DealLabelsWrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const InstalmentLabel = ({ dealPrice, isVisible }) =>
  isVisible && (
    <div className="dealLabel">
      {/* 12x  */}£{((2 * dealPrice) / 12).toFixed(0)} INSTALMENTS
    </div>
  );

const ExclusiveLabel = ({ isVisible }) =>
  isVisible && <div className="dealLabel">EXCLUSIVE</div>;

const DiscountLabel = ({ saleAmount, isVisible }) =>
  isVisible && (
    <div className="discountLabel">
      <FontAwesomeIcon icon={faArrowDown} />
      &nbsp;{saleAmount}% SALE
    </div>
  );

const FlightsLabel = ({ isVisible }) =>
  isVisible && (
    <div className="flightsLabel">
      <FontAwesomeIcon icon={faPlane} />
      &nbsp; FLIGHTS INCLUDED
      {/* &nbsp; FLIGHTS  */}
    </div>
  );

export const DealLabels = ({
  isAbsolute,
  dealPrice,
  oldDealPrice,
  isVisible = true,
}) => {
  let saleAmount = (100 - (dealPrice * 100) / oldDealPrice).toFixed(0);
  let isDiscount = Math.random() >= 0.35;
  let isExclusive = Math.random() >= 0.7;

  return (
    isVisible && (
      <DealLabelsWrapper isAbsolute={isAbsolute}>
        <InstalmentLabel isVisible={false} dealPrice={dealPrice} />
        <ExclusiveLabel isVisible={isExclusive} />
        <DiscountLabel isVisible={isDiscount} saleAmount={saleAmount} />
        <FlightsLabel isVisible={true} />
      </DealLabelsWrapper>
    )
  );
};

DealLabels.propTypes = {
  dealPrice: PropTypes.number.isRequired,
  oldDealPrice: PropTypes.number.isRequired,
  isAbsolute: PropTypes.bool.isRequired,
};
