import React, { Component } from 'react';
import { searchFlight } from 'Services/Kiwi';
import has from 'lodash/has';
import moment from 'moment';
import { KIND_OF_TRIP, BOOKING_TYPES } from '../../constants';
import { PropTypes } from 'prop-types';
import { PARTNERS } from 'Utils/partnerConstants';
import { FORMSTEPS, generateOrderSummaryObject } from 'Utils/index';

const AppContext = React.createContext();
const AppConsumer = AppContext.Consumer;

moment.locale(process.env.REACT_APP_VARIANT_LOCALE);

const action = {
  SET_PAYMENT_PROVIDER: 'SET_PAYMENT_PROVIDER',
};
/**
 * Main Context Provider for the App, at least for now (July 2018) 
 * 
 */
class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeBookingContext: BOOKING_TYPES.FNPL_Flights,
      lastSearch: {
        noMonths:
          parseInt(process.env.REACT_APP_VARIANT_OPTIMAL_MONTHS, 10) || 36,
        noAdults: 1,
        noChildren: 0,
        noInfants: 0,
        typeFlight: KIND_OF_TRIP.ROUND,
      },
      loading: {
        quality: false,
        duration: false,
        price: false,
      },
      allSearches: [],
      lastResult: null,
      lastResult_price: null,
      lastResult_duration: null,
      allResults: [],
      resultTabs: {
        t1: 5,
        t2: 5,
        t3: 5,
      },
      showResults: true,
      filtersNoResults: false,
      selectedFlightTicket:
        // process.env.NODE_ENV === 'development' ? testFlightTicket : null,
        process.env.NODE_ENV === 'development' ? null : null,
      discountCode: {
        code: null,
        value: 0,
      },
      paymentProvider: '',
      kiwiApiReturnsBadRequest: false,
      setKiwiApiError: this.setKiwiApiError,
      paymentPlanPartnerSelected: PARTNERS.FNPL,
      currentFormStepContext: FORMSTEPS.STEP1,
      setCurrentFormStepContext: this.setCurrentFormStepContext,
      setPaymentPlanPartner: this.setPaymentPlanPartner,
      get: this.getContext,
      setLastSearch: this.setLastSearch,
      setLastResult: this.setLastResult,
      refreshLastSearch: this.refreshLastSearch.bind(this),
      setFilters: this.setFilters,
      setAmount: this.setAmount,
      toggleShowResults: this.toggleShowResults,
      setFiltersNoResult: this.setFiltersNoResults,
      setSelectedFlightTicket: this.setSelectedFlightTicket,
      setDiscountCode: this.setDiscountCode,
      isOneWayFlight: this.isOneWayFlight,
      setPaymentProvider: this.setPaymentProvider,
      setOrderSummaryContext: this.setOrderSummaryContext,
      orderSummaryContext: null,
      orderSummaryObjectContext: {},
      changeTypeBookingContext: this.changeTypeBookingContext,
      // START --- THIS NEEDS TO BE AMMENDED WITH CHECKFLIGHTS.
      getBaggagePiecesAndPrices: this.getBaggagePiecesAndPrices,
      totalBaggagePriceContext: 0,
      addedBaggagePiecesContext: 0,
      purchasedBaggageTypes: null,
      // END --- THIS NEEDS TO BE AMMENDED WITH CHECKFLIGHTS.
      // START NEW CHECK FLIGHTS
      removePurchasedBaggage: this.removePurchasedBaggage,
      setPurchasedBaggage: this.setPurchasedBaggage,
      purchasedBaggage: null,
      setCheckFlightsBaggageContext: this.setCheckFlightsBaggageContext,
      checkFlightsBaggageContext: null,
      // END NEW CHECK FLIGHTS
      // START CHECKOUT VALUES
      firstCheckoutPageFormValuesContext:
        // process.env.NODE_ENV === 'development' ? testCheckoutFormValues : null,
        process.env.NODE_ENV === 'development' ? null : null,
      setFirstCheckoutPageFormValuesContext: this
        .setFirstCheckoutPageFormValuesContext,
      setPartnerBookingReferenceContext: this.setPartnerBookingReferenceContext,
      partnerBookingReferenceContext: false,
      // END CHECKOUT VALUES
      isAddedTicketServiceContext: false,
      addTicketServiceContext: this.addTicketServiceContext,
    };

    const lastSearch = JSON.parse(
      localStorage.getItem('AppContext.lastSearch')
    );
    if (lastSearch) {
      let tomorrow = moment()
        .add(1, 'days')
        .startOf('day');
      let daysDifference = moment(lastSearch.dateTo).diff(
        moment(lastSearch.dateFrom),
        'days'
      );
      let dateFrom = moment.max(moment(lastSearch.dateFrom), tomorrow);
      let dateTo = moment.max(
        moment(lastSearch.dateTo),
        tomorrow.clone().add(daysDifference, 'days')
      );
      this.state.lastSearch = {
        noInfants: 0,
        ...this.state.lastSearch,
        ...lastSearch,
        dateFrom: moment(dateFrom),
        dateTo: moment(dateTo),
      };
    }

    this.state.filters = JSON.parse(sessionStorage.getItem('filters'));
  }

  toggleShowResults = show => {
    this.setState({ showResults: show });
  };

  /**
   * Returns copy of state as main Context object
   *
   * To change the state of the Context, you must use {@link setLastSearch}
   */
  getContext = () => {
    return Object.assign({}, this.state);
  };

  /**
   * Calculate optimal payback time before today and date of departure
   * If lower than minimum, take 3 months as default
   */

  getOptimalPaybackTime = _dateFrom => {
    // let dateDiff = Math.floor(_dateFrom.diff(moment(), 'months'));
    // ******************* OPTIMAL MONTHS FUNCTIONALITY *******************
    // uncomment to turn the functionality ON
    // return Math.max(3, Math.min(12, dateDiff));
    // uncomment to turn the functionality OFF
    return parseInt(process.env.REACT_APP_VARIANT_OPTIMAL_MONTHS, 10);
  };

  /**
   * Set the main Context object - state
   *
   * @param {object} state
   * @param {function} callback function
   *
   * Also after succesfull async change localstore is updated
   * And call @param callback after state is changed
   */
  setLastSearch = (value, afterCallback = () => { }) => {
    this.setState(
      prevState => ({
        lastSearch: {
          ...prevState.lastSearch,
          ...value,
        },
      }),
      () => {
        localStorage.setItem(
          'AppContext.lastSearch',
          JSON.stringify(this.state.lastSearch)
        );
        afterCallback();
      }
    );
  };

  setLastResult = (value, sort = 'quality', callback = () => { }) => {
    // @todo use (prevState) callback to save previous result, someday
    if (sort === 'quality') {
      this.setState({ lastResult: value }, callback);
      localStorage.setItem('AppContext.state', JSON.stringify(value));
    } else {
      this.setState({ ['lastResult_' + sort]: value }, callback);
    }
  };

  setResultBrowser = values => {
    this.setState(({ resultTabs }) => ({
      resultTabs: {
        ...resultTabs,
        ...values,
      },
    }));
  };

  setFilters = values => {
    this.setState({ filters: values });
    localStorage.removeItem('filters');
    sessionStorage.setItem('filters', JSON.stringify(values, null, 2));
  };

  setAmount = value => {
    this.setLastSearch({ noMonths: value });
  };

  actionCreator = ({ type, payload }) => {
    switch (type) {
      case action.SET_PAYMENT_PROVIDER:
        this.setState(state => ({
          ...state,
          paymentProvider: payload.paymentProvider,
        }));
        break;
      default:
        break;
    }
  };

  setPaymentProvider = value => {
    this.setState({ paymentProvider: value });
  };

  getSearchResult = () => {
    return Object.assign({}, this.state.lastResult);
  };

  setLoading = (value, sort = 'quality') => {
    this.setState(prevState => ({
      ...prevState,
      loading: {
        ...prevState.loading,
        [sort]: value,
      },
    }));
  };

  setFiltersNoResults = value => {
    this.setState({ filtersNoResults: value });
  };

  setSelectedFlightTicket = ticket => {
    this.setState({
      selectedFlightTicket: ticket,
      // When a new ticket is selected we also reset the purchased and included baggage:
      purchasedBaggage: null,
    });
  };

  async refreshLastSearch() {
    this.setLoading(true, 'quality');
    this.setLoading(true, 'price');
    this.setLoading(true, 'duration');

    await searchFlight(
      {
        ...this.state.lastSearch,
      },
      r => {
        if (has(r, 'data.data') && r.data.data.length > 0) {
          this.setLastResult(r, 'quality');
        }
      },
      this.state.filters,
      2
    );

    this.setLoading(false, 'quality');

    await searchFlight(
      {
        ...this.state.lastSearch,
        sort: 'duration',
      },
      r => this.setLastResult(r, 'duration'),
      this.state.filters,
      0
    );

    this.setLoading(false, 'duration');

    await searchFlight(
      {
        ...this.state.lastSearch,
        sort: 'price',
      },
      r => this.setLastResult(r, 'price'),
      this.state.filters,
      2
    );

    this.setLoading(false, 'price');
  }

  setDiscountCode = discountCode => {
    this.setState({ discountCode });
  };

  setDiscountCode = discountCode => {
    this.setState({ discountCode });
  };

  isOneWayFlight = kindOfTrip =>
    kindOfTrip
      ? kindOfTrip === KIND_OF_TRIP.ONE_WAY
      : this.state.lastSearch.typeFlight === KIND_OF_TRIP.ONE_WAY;

  setPaymentPlanPartner = partnerName => {
    this.setState({
      paymentPlanPartnerSelected: partnerName,
    });
  };

  setKiwiApiError = type => {
    type === 'Bad Request' &&
      this.setState({
        kiwiApiReturnsBadRequest: true,
      });
  };

  setCurrentFormStepContext = step => {
    const { STEP1, STEP2, STEP3, STEP4, STEP0 } = FORMSTEPS;

    switch (step) {
      case STEP0:
        this.setState({ currentFormStepContext: STEP0 });
        break;
      case STEP1:
        this.setState({ currentFormStepContext: STEP1 });
        break;
      case STEP2:
        this.setState({ currentFormStepContext: STEP2 });
        break;
      case STEP3:
        this.setState({ currentFormStepContext: STEP3 });
        break;
      case STEP4:
        this.setState({ currentFormStepContext: STEP4 });
        break;
      default:
        this.setState({ currentFormStepContext: STEP1 });
        break;
    }
  };

  changeTypeBookingContext = booking_type => {
    this.setState({ typeBookingContext: booking_type });
  };

  setOrderSummaryContext = () => {
    let purchasedBaggageTypesObject = {};
    if (this.state.purchasedBaggageTypesContext) {
      this.state.purchasedBaggageTypesContext.forEach((item, index) => {
        let returnString = `Purchased_Bag_${index}_${Object.keys(item)[0]}`;
        purchasedBaggageTypesObject[returnString] = Object.values(item)[0];
      });
    }
    console.log(
      'The purchased baggage types object is....',
      purchasedBaggageTypesObject
    );
    const { orderSummaryObject, orderSummary } = generateOrderSummaryObject(
      this.state.lastSearch,
      this.state.selectedFlightTicket,
      this.state.addedBaggagePiecesContext,
      this.state.totalBaggagePriceContext,
      purchasedBaggageTypesObject,
      this.state.discountCode,
      this.state.isAddedTicketServiceContext
    );
    console.log('Order summary object', orderSummaryObject);
    console.log('The order summary', orderSummary);
    this.setState({
      orderSummaryContext: orderSummary,
      orderSummaryObjectContext: orderSummaryObject,
    });

    return { orderSummary, orderSummaryObject };
  };

  setFirstCheckoutPageFormValuesContext = checkoutValues => {
    this.setState({ firstCheckoutPageFormValuesContext: checkoutValues });
  };

  // -------> Credit check page
  setPartnerBookingReferenceContext = value => {
    this.setState({ partnerBookingReferenceContext: value });
  };

  // <-------------

  // --------------> New Check Flight Functions.
  setCheckFlightsBaggageContext = object => {
    this.setState({
      checkFlightsBaggageContext: object,
    });
  };

  // <--------------

  getBaggagePiecesAndPrices = () => {
    let passengers = Object.keys(this.state.purchasedBaggage).map(
      key => this.state.purchasedBaggage[key]
    );

    let purchasedBaggageTypes = [].concat.apply(
      [],
      passengers.map(passenger =>
        Object.keys(passenger).map(key => {
          let dummyObject = {};
          return Object.assign(dummyObject, {
            [key]: passenger[key],
          });
        })
      )
    );

    let pricesArray = [].concat.apply(
      [],
      passengers.map(passenger =>
        Object.keys(passenger).map(key => passenger[key])
      )
    );

    let addedBaggagePieces = pricesArray.length;
    let totalBaggagePrice = 0;
    if (pricesArray.length !== 0) {
      totalBaggagePrice = pricesArray.reduce((a, b) => a + b);
    }

    this.setState({
      totalBaggagePriceContext: totalBaggagePrice,
      addedBaggagePiecesContext: addedBaggagePieces,
      purchasedBaggageTypesContext: purchasedBaggageTypes,
    });

    return { addedBaggagePieces, totalBaggagePrice };
  };

  setPurchasedBaggage = (passengerId, baggageType, price) => {
    let isExistingPassenger = false;
    if (this.state.purchasedBaggage) {
      isExistingPassenger =
        Object.keys(this.state.purchasedBaggage).indexOf(passengerId) !== -1;
    }

    if (isExistingPassenger) {
      this.setState(prevState => ({
        purchasedBaggage: {
          ...prevState.purchasedBaggage,
          [passengerId]: {
            ...prevState.purchasedBaggage[passengerId],
            [baggageType]: price,
          },
        },
      }));
    } else {
      this.setState({
        purchasedBaggage: {
          ...this.state.purchasedBaggage,
          [passengerId]: {
            [baggageType]: price,
          },
        },
      });
    }
  };

  removePurchasedBaggage = (passengerId, baggageType) => {
    let isExistingPassenger = false;
    if (this.state.purchasedBaggage) {
      isExistingPassenger =
        Object.keys(this.state.purchasedBaggage).indexOf(passengerId) !== -1;
    }

    if (isExistingPassenger) {
      if (this.state.purchasedBaggage[passengerId][baggageType]) {
        let oldState = Object.assign({}, this.state.purchasedBaggage);
        delete oldState[passengerId][baggageType];
        this.setState(prevState => ({
          purchasedBaggage: {
            ...prevState.purchasedBaggage,
            [passengerId]: {
              ...oldState[passengerId],
            },
          },
        }));
      }
    }
  };

  addTicketServiceContext = initialize => {
    if (initialize === 1) {
      this.setState({ isAddedTicketServiceContext: false });
    } else {
      this.setState(prevState => ({
        isAddedTicketServiceContext: !prevState.isAddedTicketServiceContext,
      }));
    }
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AppContext;
export { AppProvider, AppConsumer };
