import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion/macro';
import AppContext from 'Context/AppContext';
import { PackagesContext } from 'Context/PackagesContext';
import {
  FORMSTEPS,
  isMobileDevice,
  trackCheckoutFormStep,
} from 'Utils';
import ContactDetails from './ContactDetails';
import LivingAddress from './LivingAddress';
import EmploymentStatus from './EmploymentStatus';
import DiscountCode from '../components/DiscountCode';
import { AcceptTermsCheckbox } from './AcceptTermsCheckbox';
import { ProceedButtonCheckout } from './ProceedButtonCheckout';
import { CollapsedForms } from './CollapsedForms';
import { SubmitButtonCheckoutForms } from './SubmitButtonCheckoutForms';
import PassengerSection from './PassengerDetailsForm';
import { setDebugValues } from '../components';
import { BOOKING_TYPES } from '../../constants';
import { ServiceAndProtection } from './ServiceAndProtection';

// @todo: start handling the formsteps with redux.
// Later on we will have to add in form validators as a condition to proceed to to the next step.

const { STEP1, STEP2, STEP3, STEP4, STEP5, STEP0 } = FORMSTEPS;

const FormStepController = ({
  form,
  form: { getFieldDecorator, getFieldValue, setFieldsValue, validateFields },
  isToConfirmationButtonLoading,
  typeCheckout,
}) => {
  const context = useContext(AppContext);
  const packages_context = useContext(PackagesContext);

  const {
    setCurrentFormStepContext,
    currentFormStepContext,
    lastSearch: { noAdults, noChildren, noInfants },
    firstCheckoutPageFormValuesContext,
    discountCode,
    typeBookingContext,
  } = context;

  const formStepRef2 = useRef(null);
  const formStepRef3 = useRef(null);
  const formStepRef4 = useRef(null);

  const stepArray = [STEP1, STEP2, STEP3, STEP4, STEP5];

  // Set current form steps, changes the function on the handle proceed button.
  const [currentI, setCurrentI] = useState(0);

  useEffect(() => {
    setCurrentI(stepArray.indexOf(currentFormStepContext));
  }, [currentFormStepContext]);

  useEffect(() => {
    if(isPayInFull) {
      resetFormStep(STEP0);
    } else {
      resetFormStep(STEP1);
    }
    window.scrollTo(0, 0);
    firstCheckoutPageFormValuesContext &&
      setFieldsValue(firstCheckoutPageFormValuesContext);
  }, []);

  function scrollIntoFormView(myRef) {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      setTimeout(() => {
        !isMobileDevice() &&
          window.scrollBy({ top: -65, left: 0, behavior: 'smooth' });
        clearTimeout();
      }, 300);
    }
  }

  function handleProceedForm() {
    trackCheckoutFormStep(currentFormStepContext);
    switch (currentFormStepContext) {
      case STEP1:
        setCurrentFormStepContext(STEP2);
        scrollIntoFormView(formStepRef2);
        break;
      case STEP2:
        if (isPayInFull) {
          setCurrentFormStepContext(STEP4);
          scrollIntoFormView(formStepRef4);
        } else {
          setCurrentFormStepContext(STEP3);
          scrollIntoFormView(formStepRef3);
        }
        break;
      case STEP3:
        setCurrentFormStepContext(STEP4);
        scrollIntoFormView(formStepRef4);
        break;
      default:
        console.log(
          'The handleProceedForm() function is called at an undefined place'
        );
    }
  }

  function resetFormStep(item) {
    setCurrentFormStepContext(item);
  }

  function handleCheckoutButtonSubmit() {
    trackCheckoutFormStep(currentFormStepContext);
  }

  const notActiveStep = css`
    display: none !important;
  `;

  const displayForm = css`
    display: block !important;
  `;

  function handleShortCut() {
    setCurrentFormStepContext(STEP4);
  }

  const isPayInFull = typeBookingContext === BOOKING_TYPES.STRIPE_Flights;

  const {
    noAdults: noAdults_p,
    noChildren: noChildren_p,
    noInfants: noInfants_p,
  } = packages_context.lastSearch;

  const noPassengers_app = noAdults + noChildren + noInfants;
  const noPassengers_packages = noAdults_p + noChildren_p + noInfants_p;

  const noPassengers =
    typeCheckout === 'packages' ? noPassengers_packages : noPassengers_app;

  return (
    <React.Fragment>
      {window.location.origin.includes('localhost') && (
        <button onClick={() => handleShortCut()}>
          Click here for shortcut
        </button>
      )}
      {currentFormStepContext !== STEP0 && (
        <CollapsedForms
          formStep={stepArray.slice(0, currentI)}
          resetFormStep={resetFormStep}
        />
      )}
      <div
        className={cx(
          currentFormStepContext === STEP0
            ? displayForm
            : currentFormStepContext !== STEP1 && notActiveStep
        )}
      >
        <PassengerSection
          noPassengers={noPassengers}
          form={form}
          getFieldDecorator={getFieldDecorator}
          setDebugValues={() => {
            if (process.env.NODE_ENV === 'development') {
              setDebugValues(form);
              console.log('The set debug values function is called');
            } else if (window.location.hostname.indexOf('staging') !== -1) {
              setDebugValues(form);
              console.log('The set debug values function is called');
            } else
              console.log(
                'it is not possible to set debug values in prod environment'
              );
          }}
        />
      </div>
      <div
        className={cx(
          currentFormStepContext === STEP0
            ? displayForm
            : (currentFormStepContext !== STEP2 && notActiveStep) ||
                (currentFormStepContext === STEP0 && '')
        )}
        ref={formStepRef2}
      >
        <ContactDetails
          getFieldDecorator={getFieldDecorator}
          isPayInFull={isPayInFull}
        />
      </div>

      {// ------------------------------> For pay in full we never ask for employment status and or living address
      !isPayInFull && (
        <div
          className={cx(
            currentFormStepContext === STEP0
              ? displayForm
              : currentFormStepContext !== STEP3 && notActiveStep
          )}
          ref={formStepRef3}
        >
          <LivingAddress
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            validateFields={validateFields}
          />
        </div>
      )}
      <div
        className={cx(
          currentFormStepContext === STEP0
            ? displayForm
            : currentFormStepContext !== STEP4 && notActiveStep
        )}
        ref={formStepRef4}
      >
        <EmploymentStatus
          getFieldDecorator={getFieldDecorator}
          isEmploymentHidden={isPayInFull}
        />

        <ServiceAndProtection /> 

        <DiscountCode value={discountCode.code} />
        <AcceptTermsCheckbox
          upfront={100}
          getFieldDecorator={getFieldDecorator}
          isLoading={false}
          getFieldValue={getFieldValue}
          setFieldsValue={setFieldsValue}
        />
      </div>

      <ProceedButtonCheckout
        buttonText="Continue"
        handlerFunction={handleProceedForm}
        isProceedButtonVisible={
          currentFormStepContext !== STEP4 && currentFormStepContext !== STEP0
        }
        subText={determineSubText(currentFormStepContext)}
      />

      <SubmitButtonCheckoutForms
        isVisible={
          currentFormStepContext === STEP4 || currentFormStepContext === STEP0
        }
        isLoading={isToConfirmationButtonLoading}
        handleButtonClick={() => handleCheckoutButtonSubmit()}
        subText={determineSubText(currentFormStepContext)}
      />
    </React.Fragment>
  );
};

FormStepController.propTypes = {
  isEmploymentHidden: PropTypes.bool,
  form: PropTypes.object.isRequired,
  isToConfirmationButtonLoading: PropTypes.bool.isRequired,
  subText: PropTypes.string,
  typeCheckout: PropTypes.string.isRequired,
};

export { FormStepController };

function determineSubText(formStep) {
  switch (formStep) {
    case STEP0:
      return '';
    case STEP1:
      return 'Fill in your contact details in the next step';
    case STEP2:
      return 'Fill in your address details in the next step';
    case STEP3:
      return 'Fill in your employment details in the next step';
    case STEP4:
      return 'Double check your details in the last step';
    default:
      console.log(
        'The handleProceedForm() function is called at an undefined place'
      );
  }
}
