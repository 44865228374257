import styled from 'react-emotion/macro';

const blueGray = '#f2f3f6';

export const ActiveWrapper = styled.div`
  margin-bottom: 15px;
  margin-top: 10px;
  cursor: pointer;

  .borderWrapper {
    border-radius: 3px;
    border: 2px solid ${blueGray};
  }

  .borderWrapperActive {
    border-radius: 3px;
    border: 2px solid #00a699;
  }
`;

export const BaggageSectionWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 10px;

  .baggageSectionTitle {
    margin-bottom: 0px;
    color: black;
    padding-top: 25px;
    margin-top: 10px;
  }

  .baggageSectionSubTitle {
    font-weight: 400;
    color: black;
    line-height: 1;
    margin-bottom: 12px;
    margin-top: 5px;
    font-size: 15px;
  }
`;

export const BaggageElementWrapper = styled.div`
  padding: 12px 15px;
  align-items: center;

  .baggageTypeRow {
    display: grid;
    grid-template-columns: 1fr 6fr 3fr;

    .faIcon {
      margin-left: auto;
      margin-right: auto;
      margin-top: 9px;
      margin-right: 12px;
    }
  }

  p {
    margin: 0px;
    font-size: 14px;
    line-height: 22px;
    color: #2e353b;
  }

  .unitPrice {
    text-align: right;
  }

  .noCheckedBaggageElement {
    display: grid;
    grid-template-columns: 1fr 6fr 3fr;

    .faIcon {
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;
