import React, { useContext, useState, useEffect } from 'react';
import AppContext from 'Context/AppContext';
import { PackagesContext } from 'Context/PackagesContext';
import { CheckoutFormsUKWrapper } from '../styles';
import CoreView from '../CoreView';
import { Form } from 'antd'; // <----------------- Shouldn't be used and be removed, currently only for the styling of the confirmation page.
import FnplV2Widget from 'components/FnplWidget/index';
import InstructionsFnpl from '../components/InstructionsFnpl';
import { RedirectElement } from 'components/RedirectElement';
import { extractFlightTicketData } from 'Utils/fnplCalc';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import {
  test_OrderSummaryObjectContext,
  test_selectedFlightTicket,
} from './testValues';
import { generate_itinerary } from './generate_itinerary';
import {  trackProceedToConfirmation } from 'Utils';
import {
  packagesApplicationBackend,
  packagesCompleteTransaction,
} from './packagesProcessBooking';

const CreditCheckPageContainerProxy = () => {
  const [userIdReferenceState, setUserIdReferenceState] = useState('');
  const [
    redirectToThankYouPageState,
    setRedirectToThankYouPageState,
  ] = useState(false);

  const context = useContext(AppContext);
  const packagesContext = useContext(PackagesContext);
  const {
    firstCheckoutPageFormValuesContext,
    setPartnerBookingReferenceContext,
    discountCode,
  } = context;
  const {
    departureDateContext,
    departureAirportContext,
    selectedPackageContext,
    checkoutPackagesPriceContext,
    amountRoomsContext: package_rooms,
    amountRoomsContext,
    amountNightsContext: days_of_stay,
    amountNightsContext,
    lastSearch: lastSearchPackagesContext,
  } = packagesContext;

  const extract = extractFlightTicketData(test_selectedFlightTicket);

  const test_orderDataFNPL = {
    ...firstCheckoutPageFormValuesContext,
    tripExtract: extract,
    orderSummaryObject: test_OrderSummaryObjectContext,
  };

  // Temporary constants.  ----------> @todo
  function getCheckoutPackagesPrice(discountCode) {
    let price = checkoutPackagesPriceContext;
    if (discountCode) {
      price = price - discountCode.value;
    }
    return price;
  }
  const checkoutPackagesPrice = getCheckoutPackagesPrice(discountCode);

  // Packages trip object is used inside FNPL.
  const packages_trip_object = generate_itinerary(
    selectedPackageContext,
    checkoutPackagesPrice,
    firstCheckoutPageFormValuesContext,
    departureDateContext,
    days_of_stay,
    package_rooms
  );

  useEffect(() => {
    const flymbleUserId = localStorage.getItem('flymbleUserId') || '';

    setUserIdReferenceState(flymbleUserId);

    // Add a new application to the back-end
    packagesApplicationBackend(
      firstCheckoutPageFormValuesContext,
      departureAirportContext,
      departureDateContext,
      selectedPackageContext,
      lastSearchPackagesContext,
      amountRoomsContext,
      amountNightsContext,
      checkoutPackagesPriceContext,
      discountCode,
      extract
    );
    trackProceedToConfirmation(checkoutPackagesPriceContext, 'GBP');
  }, []);

  const handleFNPLCallBackComplete = dispersalToken => {
    const callBack = () => setRedirectToThankYouPageState(true);
    console.log('FNPL has accepted');

    packagesCompleteTransaction(
      dispersalToken,
      selectedPackageContext,
      firstCheckoutPageFormValuesContext,
      extract,
      callBack,
      departureAirportContext,
      departureDateContext,
    );
  };

  return (
    <CoreView timeOutDelay={0}>
      <CheckoutFormsUKWrapper>
        <Form>
          <FnplV2Widget
            orderDataFNPL={test_orderDataFNPL}
            callbackComplete={handleFNPLCallBackComplete}
            callbackReference={setPartnerBookingReferenceContext}
            referenceUUID={userIdReferenceState}
            isPackagesCheckout={true}
            packagesTripObject={packages_trip_object}
          >
            <InstructionsFnpl isVisible={true} />
          </FnplV2Widget>
        </Form>
      </CheckoutFormsUKWrapper>
      <RedirectElement
        destination="/thank-you"
        isAllowedRedirect={redirectToThankYouPageState}
      />
    </CoreView>
  );
};

const CreditCheckPageContainer = () => (
  <ErrorBoundary errorLocation="Pay booking" redirect={true}>
    <CreditCheckPageContainerProxy />
  </ErrorBoundary>
);

export { CreditCheckPageContainer };
export default CreditCheckPageContainer;
