import React, { useEffect, useContext, useState } from 'react';
import { DealInformationPageWrapper } from './pageWrappers';
import { TripAdvisorRating } from './components/TripAdvisorRating';
import { DealDescription } from './components/DealDescription';
import { OfferHeader } from './components/Headers/OfferHeader';
import { OfferSummaryForm } from './components/OfferSummaryForm';
import { SummaryNavigationLinks } from './components/SummaryNavigationLinks';
import { PackagesContext } from '../../Context/PackagesContext';
import AppContext from '../../Context/AppContext';
import { Placeholder } from 'semantic-ui-react';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import 'semantic-ui-css/semantic.min.css';
import { PhotoSlider } from './components/PhotoSlider';
import { generateMonthlyPriceArray } from 'Utils';

const DealInformationRoute = () => {
  const [selectedPackageState, setSelectedPackageState] = useState(null);
  const context = useContext(PackagesContext);
  const app_context = useContext(AppContext);

  const {
    lastSearch: { noAdults, noChildren, noInfants },
  } = app_context;
  const amount_passengers = noAdults + noChildren + noInfants;

  const {
    packagesArray,
    amountRoomsContext,
    amountNightsContext,
    setAmountNightsContext,
    setAmountRoomsContext,
    setSelectedPackageContext,
    setDepartureAirportContext,
    setDepartureDateContext,
  } = context;

  useEffect(() => {
    if (packagesArray) {
      setSelectedPackageState(true);
    }

    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: 'visit-deal-info-page',
    });
  }, [packagesArray]);

  // Find the selected package from the url id, and set it in context.
  if (selectedPackageState) {
    const selectedPackage = packagesArray.find(
      ({ id }) => id === parseInt(window.location.pathname.split('/').pop())
    );

    let {
      destination: dealSubtitle,
      id,
      offerSummaryDescription,
      old_price: oldDealPrice,
      price: dealPrice,
      price_condition: priceBasisDescription,
      title: dealTitle,
      tripAdvisorReviews,
      tripAdvisorUrl,
      typeRoom,

      // Photo definitions
      main_image_url,
      photo_1,
      photo_2,
      photo_3,
      photo_4,
      Photo_5: photo_5,
    } = selectedPackage;

    const packagePhotos = {
      main_image_url,
      photo_1,
      photo_2,
      photo_3,
      photo_4,
      photo_5,
    };

    const monthlyPriceArray = generateMonthlyPriceArray(selectedPackage);

    return (
      <DealInformationPageWrapper>
        <div className="gridWrapper">
          <OfferHeader offerHeaderTitle={dealTitle} />
          <PhotoSlider
            packagePhotos={packagePhotos}
            dealTitle={dealTitle}
            tripAdvisorUrl={tripAdvisorUrl}
          />
          <DealDescription
            offerSummaryDescription={offerSummaryDescription}
            dealPrice={dealPrice}
            oldDealPrice={oldDealPrice}
          />
          <SummaryNavigationLinks name={id} />
          <TripAdvisorRating
            tripAdvisorAmountReviews={tripAdvisorReviews}
            tripAdvisorLink={tripAdvisorUrl}
          />
          <OfferSummaryForm
            hotelName={dealSubtitle}
            roomType={typeRoom}
            priceBasisDescription={priceBasisDescription}
            amountRoomsContext={amountRoomsContext}
            amountNightsContext={amountNightsContext}
            setAmountRoomsContext={setAmountRoomsContext}
            setAmountNightsContext={setAmountNightsContext}
            setSelectedPackageContext={setSelectedPackageContext}
            selectedPackage={selectedPackage}
            setDepartureAirportContext={setDepartureAirportContext}
            setDepartureDateContext={setDepartureDateContext}
            monthlyPriceArray={monthlyPriceArray}
            amountPassengersContext={amount_passengers}
          />
        </div>
      </DealInformationPageWrapper>
    );
  } else
    return (
      <Placeholder>
        <Placeholder.Image square />
      </Placeholder>
    );
};

const WrappedDealInformationRoute = () => (
  <ErrorBoundary>
    <DealInformationRoute />
  </ErrorBoundary>
);

DealInformationRoute.propTypes = {
  // dealTitle: PropTypes.string.isRequired,
};

export { WrappedDealInformationRoute as DealInformationContainer };
