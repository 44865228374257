import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { dobGrid } from '../PassengerDetailsForm';
import { WarningBannerWrapper } from '../../components/WarningBanner';
import { SectionStyling } from '../styles.js';
import { SectionTitle } from '../../components/SectionTitle';
import { GenderFirstName, SurnameField, DobFields } from './ContactNames';
import { EmailField } from './EmailField';
import { PhoneField } from './PhoneField';

const ContactDetailsWrapper = styled.div`
  ${dobGrid};
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 20px;

  @media (min-width: 1000px) {
    padding-top: 0px;
  }
`;

const ContactDetails = ({ getFieldDecorator, isPayInFull }) => {
  const default_email_value = localStorage.getItem('hasEmailSignedUp')
    ? localStorage.getItem('hasEmailSignedUp')
    : null;

  useEffect(() => {
    getFieldDecorator('email', { initialValue: default_email_value });
  }, []);


  const contact_details_title = isPayInFull ? "Contact details - Person paying" : "Contact details - Person applying";

  return (
    <SectionStyling>
      <ContactDetailsWrapper>
        <SectionTitle title={contact_details_title} />
        {!isPayInFull && (
          <>
            <WarningBannerWrapper isOrange={true}>
              <span className="description">
                The name and address belonging to the person applying for
                instalments must match the registered bank account used for
                payment.
              </span>
            </WarningBannerWrapper>
            <WarningBannerWrapper isOrange={false}>
              <span className="description">
                This person can be someone else than the person(s) traveling. If
                we are unable to validate your identity you will be contacted by
                our credit partner within the next business day.
              </span>
            </WarningBannerWrapper>
          </>
        )}

        <div className="coreForms" style={{ marginTop: '20px' }}>
          {
            !isPayInFull && <>
              <GenderFirstName getFieldDecorator={getFieldDecorator} isGenderVisible={!isPayInFull} />
              <SurnameField getFieldDecorator={getFieldDecorator} />
              <DobFields getFieldDecorator={getFieldDecorator} />
            </>
          }
          <EmailField getFieldDecorator={getFieldDecorator} />
          <PhoneField getFieldDecorator={getFieldDecorator} />
        </div>
      </ContactDetailsWrapper>
    </SectionStyling>
  );
};

ContactDetails.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  isPayInFull: PropTypes.bool.isRequired,
};

export default ContactDetails;
