import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  // PAYMENT_PLAN_GROUPS,
  PARTNERS,
  BOOKING_TYPES,
} from '../../constants';

// import { isMobileDevice } from 'Utils';
// Import Payment Plan Card Sections.
import CardsPartners from './CardsPartners';

// Import ContentBlock Section with Informative Content.
import ContentBlock from './ContentBlock';

// Import Klarna Sales Section.
import KlarnaSales from './KlarnaSales';

// Import PaymentPlanPage Components.
import {
  showKlarnaPriceLimitModal,
  TextHeading,
  OneWayErrorMessage,
  DesktopGrid,
  PaymentPlanSectionWrapper,
  ProceedButton,
  GoBackButton,
  SignUpForm,
  PriceLimitModal,
} from './components';

const ViewComponent = ({
  goToCheckout,
  goBackToSearch,
  totalBookingPrice,
  setContextAmountMonths,
  setContextPaymentPlanPartner,
  paymentPlanPartnerSelected,
  activeMonths,
  isOneWayFlight,
  tripTextConstants,
  lastSearch,
  changeTypeBookingContext,
}) => {
  // State logic used in functions to determine what kind of components to display.
  const [isKlarnaAvailable, setIsKlarnaAvailable] = useState(true);

  useEffect(() => {
    // Calculating the difference between departure date and today's date.
    let daysDifference = moment(lastSearch.dateFrom._i).diff(
      moment(new Date()),
      'days'
    );

    if (daysDifference < 31 || totalBookingPrice > 800) {
      setIsKlarnaAvailable(false);
      setContextAmountMonths(6);
      setContextPaymentPlanPartner(PARTNERS.FNPL);
    } else {
      // setContextAmountMonths(3);
    }

    const isKlarnaOn = true;
    if (!isKlarnaOn) {
      setIsKlarnaAvailable(false);
    }
  }, []);

  // Proceed Button allows user to proceed to specific partner-checkout.
  function handleProceedFurther() {
    changeTypeBookingContext(BOOKING_TYPES.FNPL_Flights);
    const isKlarnaOn = true;
    if (isKlarnaOn) {
      if (isKlarnaAvailable === true) {
        goToCheckout();
      } else if (
        paymentPlanPartnerSelected === PARTNERS.KLARNA &&
        isKlarnaAvailable === false
      ) {
        showKlarnaPriceLimitModal(() => {
          setContextPaymentPlanPartner(PARTNERS.FNPL);
          goToCheckout();
        });
      } else if (paymentPlanPartnerSelected === PARTNERS.FNPL) {
        goToCheckout();
      } else if (paymentPlanPartnerSelected === PARTNERS.KLARNA) {
        goToCheckout();
        setContextAmountMonths(3);
      } else {
        goToCheckout();
      }
    } else {
      if (activeMonths === null) {
        // if someone presses proceed without selection || Possibly needs to be deleted after Klarna launch.
        setContextAmountMonths(3);
        setContextPaymentPlanPartner(PARTNERS.FNPL);
        goToCheckout();
      } else {
        goToCheckout();
      }
    }
  }

  function generateButtonId() {
    let buttonIdString;
    let partialString;

    if (paymentPlanPartnerSelected !== null) {
      partialString = paymentPlanPartnerSelected;
    } else {
      partialString = 'no-partner-selected';
    }

    if (isKlarnaAvailable === true) {
      buttonIdString = `partner-klarna-sales-page-proceed-button`;
    } else {
      buttonIdString = `partner-${partialString}-proceed-button`;
    }

    return buttonIdString;
  }

  const isShowingSignUp = false;
  // !localStorage.getItem('hasEmailSignedUp');

  return (
    <React.Fragment>
      <PriceLimitModal isOpen={true} totalBookingPrice={totalBookingPrice} />
      <PaymentPlanSectionWrapper>
        <KlarnaSales totalBookingPrice={totalBookingPrice} isVisible={false} />
        <TextHeading
          isVisible={true}
          showPrice={false}
          showTravelInfo={false}
          scrollSmoothOnClick={true}
          titleText="Choose payment plan"
          totalBookingPriceString={`Booking price £${totalBookingPrice}`}
          {...tripTextConstants}
          goToCheckout={goToCheckout}
        />
        <DesktopGrid>
          <OneWayErrorMessage isVisible={isOneWayFlight} />
          <CardsPartners
            isVisible={true}
            totalBookingPrice={totalBookingPrice}
            setContextPaymentPlanPartner={setContextPaymentPlanPartner}
            paymentPlanPartnerSelected={paymentPlanPartnerSelected}
            setContextAmountMonths={setContextAmountMonths}
            activeMonths={activeMonths}
            isKlarnaAvailable={isKlarnaAvailable}
          />
          <div style={{ textAlign: 'center' }}>
            {isShowingSignUp ? (
              <SignUpForm
                isVisible={true}
                handleProceedFurther={handleProceedFurther}
                lastSearch={lastSearch}
              />
            ) : (
              <ProceedButton
                Green
                text="Proceed"
                displayProceedButton={!isOneWayFlight}
                handleProceedFurther={handleProceedFurther}
                buttonId={generateButtonId()}
              />
            )}

            <GoBackButton
              Gray
              oneWay={isOneWayFlight}
              text="Back to search"
              handleGoBackToSearch={goBackToSearch}
              displayGoBackButton={true}
              buttonId="go-back-to-search-button"
            />
          </div>
        </DesktopGrid>
      </PaymentPlanSectionWrapper>
      <ContentBlock />
    </React.Fragment>
  );
};
export default ViewComponent;

ViewComponent.propTypes = {
  goToCheckout: PropTypes.func.isRequired,
  totalBookingPrice: PropTypes.number.isRequired,
  setContextAmountMonths: PropTypes.func.isRequired,
  setContextPaymentPlanPartner: PropTypes.func.isRequired,
  paymentPlanPartnerSelected: PropTypes.string,
  activeMonths: PropTypes.number.isRequired,
  tripTextConstants: PropTypes.object.isRequired,
  lastSearch: PropTypes.object.isRequired,
  goBackToSearch: PropTypes.func.isRequired,
  isOneWayFlight: PropTypes.bool.isRequired,
  changeTypeBookingContext: PropTypes.func.isRequired,
};
