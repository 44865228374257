import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import FormLabel from '../../components/FormLabel';

const formInputSize = 'large';
const FormItem = Form.Item;

export const PhoneField = ({ getFieldDecorator }) => {
  return (
    <div>
      <FormLabel>UK mobile phone number</FormLabel>
      <FormItem>
        {getFieldDecorator('mobileNr', {
          rules: [
            {
              required: true,
              message: 'Please enter a valid UK mobile phone number.',
              pattern: /^(0){0,1}\s*(?:\d\s?){9,10}$/g,
              // Pattern allows spaces between numbers.
            },
          ],
        })(
          <Input
            placeholder=".e.g. 07123456789"
            addonBefore="+44"
            size={formInputSize}
            style={{ width: '100%' }}
          />
        )}
      </FormItem>
    </div>
  );
};

PhoneField.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
};
