import firebase from 'firebase/app';
import 'firebase/functions';

var config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABAE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
};

firebase.initializeApp(config);

export default firebase;
