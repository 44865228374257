import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';

export const HeroImageSection = ({ title, subTitle, imageUrl }) => {
  return (
    <HeroImageSectionWrapper imageUrl={imageUrl}>
      <div
        className="heroImage"
        onClick={() =>
          process.env.NODE_ENV === 'development' && window.history.back()
        }
      >
        <div className="heroText">
          <p className="heroTitle">{title}</p>
          <p className="subTitle">{subTitle}</p>
        </div>
      </div>
    </HeroImageSectionWrapper>
  );
};

HeroImageSection.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

const HeroImageSectionWrapper = styled.div`
  .heroImage {
    width: 100%;
    background-image: linear-gradient( rgba(0,0,0,0), 75%, black ),
    url('${props => props.imageUrl}');


    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 270px;

    .heroText {
      position: absolute;
      bottom: 0px;
      color: white;
      padding: 15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      font-weight: 400;
      font-size: 16px;

      p {
        margin-bottom: 0px;
        line-height: 1.3;
      }

      .subTitle {
        font-weight: 300;
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }

  @media(min-width: 1000px) {
    grid-column: 1/2;
    margin-bottom: 27px;

    /* .heroImage {
      margin-left: auto;
      margin-right: auto;
    } */
  }
`;
