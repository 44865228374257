import styled from 'react-emotion/macro';

const FormLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 7px;
  color: #2e353b;
`;

export default FormLabel;
