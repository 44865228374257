import React, { Component } from 'react';
import styled from 'react-emotion/macro';
import i18n from 'Utils/i18n.js';

import ES from './assets/ES-logo.png';
import MT from './assets/MT-logo.png';
import BI from './assets/BI-logo.png';
import YH from './assets/YH-logo.png';

const PressArticlesWrapper = styled.div`
  padding-left: 1.3em;
  padding-right: 1.3em;
  padding-bottom: 45px;
  padding-top: 50px;
  background-color: #4860ff;

  @media (max-width: 1000px) {
    display: none;
  }

  @media (max-width: 599px) {
    padding-top: 20px;
    margin-bottom: 0px;
  }

  .articleCard {
    background-color: white;
    margin: 10px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 0px;
    border-radius: 5px;
    text-align: left;

    :last-child {
      @media (max-width: 1000px) {
        display: none;
      }
    }

    img {
      max-height: 18px;
      @media (max-width: 1000px) {
        width: 96px;
      }
    }

    a {
      float: right;
      text-decoration: none;
      color: #0079ff;
      font-size: 14px;
      font-weight: 400;
    }

    p {
      padding-bottom: 14px;
      font-size: 14px;
    }
  }

  .pressArticlesGrid {
    text-align: center;
    line-height: 1.42857;

    @media (min-width: 1000px) {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    }

    h2 {
      color: white !important;
      text-align: center;
      padding-bottom: 56px;
      font-size: 36px;
      margin-top: 0px;
      margin-bottom: 0px;

      @media (min-width: 1000px) {
        grid-column: 2/4;
        grid-row: 1/2;
      }
    }
  }

  .viewMoreArticles {
    padding-top: 35px;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    color: white;
    line-height: 1.42857;
    @media (min-width: 1000px) {
      grid-row: 4/5;
      grid-column: 2/4;
    }
  }

  @media (min-width: 1000px) {
    .article1 {
      grid-row: 2/3;
      grid-column: 2/3;
    }

    .article2 {
      grid-row: 2/3;
      grid-column: 3/4;
    }

    .article3 {
      grid-row: 3/4;
      grid-column: 2/3;
    }

    .article4 {
      grid-row: 3/4;
      grid-column: 3/4;
    }
  }
`;

export default class PressArticles extends Component {
  render() {
    return (
      <PressArticlesWrapper>
        <div className="pressArticlesGrid">
          <h2>{i18n.t('PressArticles..Don`t just take our word for it')}</h2>
          <div className="articleCard article1">
            <img src={BI} alt="/" />
            <a
              href="http://www.businessinsider.com/you-can-now-book-a-flight-and-pay-for-it-later-2017-12?IR=T"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."You can now book a flight and pay for it later."'
                )}
              </i>
            </p>
          </div>

          <div className="articleCard article2">
            <img src={ES} alt="/" />
            <a
              href="https://www.standard.co.uk/lifestyle/travel/flymble-you-can-now-book-a-flight-and-pay-for-it-later-a3711821.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."This platfom allows you to spread the cost of your holiday."'
                )}
              </i>
            </p>
          </div>

          <div className="articleCard article3">
            <img src={MT} alt="/" />
            <a
              href="https://www.metro.news/reimagining-the-travel-agent/897574/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."Flymble is reimagining the travel agent."'
                )}
              </i>
            </p>
          </div>

          <div className="articleCard article4">
            <img src={YH} alt="/" />
            <a
              href="https://www.metro.news/reimagining-the-travel-agent/897574/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."The easiest way to fly now and pay later"'
                )}
              </i>
            </p>
          </div>
        </div>
      </PressArticlesWrapper>
    );
  }
}
