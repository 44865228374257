import {
  PRICE_VARIANT_MARGINS,
} from '../constants';

export const getFlymblePriceVariantMargin = () => {
  const priceVariant = localStorage.getItem('priceVariant');

  const priceMargin = PRICE_VARIANT_MARGINS[priceVariant] || 1.0; // 1.00 as a fall back.

  return priceMargin;
};
