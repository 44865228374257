import React from 'react';
import { Link } from 'react-router-dom';
import { TopHeaderWrapper, GoBackButtonWrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const TopHeaderDetails = ({ title, name }) => (
  <TopHeaderWrapper>
    <Link to={`/packages-deal-summary/${name}`}>
      <div className="topHeader">
        <GoBackButtonWrapper>
          <FontAwesomeIcon icon={faArrowLeft} className="faIcon" />
          Go Back
        </GoBackButtonWrapper>
        <div className="title">{title}</div>
      </div>
    </Link>
  </TopHeaderWrapper>
);
