import React from 'react';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { NoCheckedBaggageOptionElement } from './components/NoCheckedBaggageOptionElement';
import { NoCheckedBaggageAvailable } from './components/NoCheckedBaggageAvailable';
import { BaggageElement } from './components/BaggageElement';
import { BAGGAGE_OPTIONS, PURCHASED_BAGGAGE_TYPES } from '../../../constants';
import { CheckedLuggageInterface } from "../../../interfaces";

const {
    CASE_7_FREE_CHECKED,
    CASE_8_PAID_CHECKED,
    CASE_9_NO_CHECKED_AVAILABLE,
    UNITED_AIRLINES,
} = BAGGAGE_OPTIONS;


export const DetermineViewModeCheckedBag = (
    {
        mode,
        checkedLuggage: { dimensions: checkedLuggageDimensions, price: checkedLuggagePrice },
        holdBagIsActive1,
        holdBagIsActive2,
        setPurchasedBaggage,
        removePurchasedBaggage,
        setHoldBagIsActive1,
        setHoldBagIsActive2,
        setHoldBagOneOption,
        prefix
    }: {
        mode: string,
        checkedLuggage: CheckedLuggageInterface,
        holdBagIsActive1: boolean,
        holdBagIsActive2: boolean,
        setPurchasedBaggage: Function,
        removePurchasedBaggage: Function,
        setHoldBagIsActive1: Function,
        setHoldBagIsActive2: Function,
        setHoldBagOneOption: Function,
        prefix: string,

    }) => {
    switch (mode) {
        case CASE_7_FREE_CHECKED:
            setHoldBagOneOption(true);
            return (
                <BaggageElement
                    dimensions={checkedLuggageDimensions}
                    price={checkedLuggagePrice}
                    title={`${checkedLuggageDimensions.weight}kg Checked bag`}
                    icon={faSuitcase}
                    isActive={holdBagIsActive1}
                    handleOnClick={() => {
                        console.log('There is no handle click function.');
                    }}
                />
            );
        case CASE_8_PAID_CHECKED:
            setHoldBagOneOption(false);
            return (
                <>
                    <NoCheckedBaggageOptionElement
                        handleOnClick={() => {
                            !holdBagIsActive1 &&
                                removePurchasedBaggage(prefix, PURCHASED_BAGGAGE_TYPES.checked_Bag);
                            setHoldBagIsActive1(true);
                            setHoldBagIsActive2(false);
                        }}
                        isActive={holdBagIsActive1}
                    />
                    <BaggageElement
                        dimensions={checkedLuggageDimensions}
                        price={checkedLuggagePrice}
                        title={`${checkedLuggageDimensions.weight}kg Checked bag`}
                        icon={faSuitcase}
                        handleOnClick={() => {
                            setHoldBagIsActive1(false);
                            setHoldBagIsActive2(true);
                            setPurchasedBaggage(prefix, PURCHASED_BAGGAGE_TYPES.checked_Bag, checkedLuggagePrice);
                        }}
                        isActive={holdBagIsActive2}
                    />
                </>
            );
        case UNITED_AIRLINES:
            setHoldBagOneOption(true);
            return (
                <NoCheckedBaggageAvailable
                    isActive={holdBagIsActive1}
                    handleOnClick={() => {
                        console.log('There is no handle click function.');
                    }}
                />
            );
        case CASE_9_NO_CHECKED_AVAILABLE:
            setHoldBagOneOption(true);
            return (
                <NoCheckedBaggageAvailable
                    isActive={holdBagIsActive1}
                    handleOnClick={() => {
                        console.log('There is no handle click function.');
                    }}
                />
            );
        default:
            // return <NoCheckedBaggageAvailable />;
            return <h3>There has been an error.</h3>;
    }
};
