// Constants.
import { createTripTextConstants } from './createTripTextConstants';
import PARTNERS from './partnerConstants';
import { FORMSTEPS } from './formStepConstants';

// Functions
import i18n from './i18n';
import {
  inbankCostsCalcFunction,
  inbankCostsCalc,
} from './inbankPricing/inbankPricing.js';
import {
  submitSearchButton,
  trackAddPaymentInfo,
  trackProceedToConfirmation,
  trackPassengersForm,
  trackThankYouPage,
  trackBookNowButton,
  trackShareFlightTicket,
  trackPaymentPlanPage,
  trackCheckoutPage,
  trackMonthsPartnerCombinationOnCheckout,
  trackSelectFlightTicket,
  trackCheckoutFormStep,
  trackAuthorizeOrderButton,
  trackConfirmationPageButton,
  trackTravelCategoryClick,
  trackRetailCallNow,
  trackRetailCallMeBack,
  trackPayInFullButton,
  trackDirectPayment_VisitConfirmationPage,
  trackDirectPayment_VisitPaymentPage,
  trackDirectPayment_PayNowBtn,
  trackAddServiceAndProtection,
  trackLowerPriceLimit,
  trackHigherPriceLimit
} from './facebookFunctions';
import {
  fnplCalc,
  fnplTotalCalc,
  convertToHourMinutes,
  timeDuration,
  timeConvert,
  airlineName,
  parseLocation,
  extractFlightTicketData,
  parsedTicketData,
  fnplV2Calc,
} from './fnplCalc';
import { isOfficeHour } from './isOfficeHour';
import isMobileDevice from './isMobileDevice';
import { generateLuggageObject } from './generateLuggageObject';
import {
  subscribeUserToMailchimp,
  signUpPackageUserToMailchimp,
  packagesBookingMailchimp,
} from './subscribeMailChimpFunctions.js';
import { generateOrderSummaryObject } from './generateOrderSummaryObject';
import { signUpUserEmailCollect } from './signUpUserEmailCollect';
import { generateMonthlyPriceArray } from './generateMonthlyPriceArray';
import { getFlymblePriceVariantMargin } from './getFlymblePriceVariantMargin';


export {
  inbankCostsCalcFunction,
  inbankCostsCalc,
  trackCheckoutPage,
  submitSearchButton,
  trackAddPaymentInfo,
  trackProceedToConfirmation,
  trackPassengersForm,
  trackThankYouPage,
  trackBookNowButton,
  trackShareFlightTicket,
  trackMonthsPartnerCombinationOnCheckout,
  trackSelectFlightTicket,
  trackConfirmationPageButton,
  trackAuthorizeOrderButton,
  trackTravelCategoryClick,
  trackRetailCallNow,
  trackRetailCallMeBack,
  fnplCalc,
  fnplTotalCalc,
  convertToHourMinutes,
  timeDuration,
  timeConvert,
  airlineName,
  parseLocation,
  extractFlightTicketData,
  parsedTicketData,
  fnplV2Calc,
  trackPaymentPlanPage,
  createTripTextConstants,
  i18n,
  PARTNERS,
  FORMSTEPS,
  subscribeUserToMailchimp,
  packagesBookingMailchimp,
  signUpPackageUserToMailchimp,
  isOfficeHour,
  isMobileDevice,
  trackCheckoutFormStep,
  generateOrderSummaryObject,
  generateLuggageObject,
  signUpUserEmailCollect,
  generateMonthlyPriceArray,
  getFlymblePriceVariantMargin,
  trackPayInFullButton,
  trackDirectPayment_VisitConfirmationPage,
  trackDirectPayment_VisitPaymentPage,
  trackDirectPayment_PayNowBtn,
  trackAddServiceAndProtection,
  trackLowerPriceLimit,
  trackHigherPriceLimit
};
