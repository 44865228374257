import React from 'react';
import PropTypes from 'prop-types';
import { PhotoSliderWrapper } from './styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import tripAdvisorLogo from './tripadvisor-logo.png';
import { MorePhotosWrapper } from './styles';
import { Button } from 'antd';

export const PhotoSlider = ({ packagePhotos, tripAdvisorUrl }) => {
  const {
    photo_1,
    photo_2,
    photo_3,
    photo_4,
    photo_5,
  } = packagePhotos;

  const photoArray = [photo_1, photo_2, photo_3, photo_4, photo_5];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <PhotoSliderWrapper>
      <Slider {...settings}>
        {photoArray.concat(['']).map((item, index) => {
          if (index === photoArray.length) {
            return <MorePhotos tripAdvisorUrl={tripAdvisorUrl} key={index} />;
          } else
            return (
              <div className="imageHolder" key={index}>
                <img src={item} alt={`holder-${index}`} className="heroImage" />
              </div>
            );
        })}
      </Slider>
    </PhotoSliderWrapper>
  );
};

PhotoSlider.propTypes = {
  packagePhotos: PropTypes.object.isRequired,
  tripAdvisorUrl: PropTypes.string.isRequired,
};

const MorePhotos = ({ tripAdvisorUrl }) => {
  return (
    <MorePhotosWrapper>
      <img className="tripAdvisorLogo" src={tripAdvisorLogo} alt='more-travel'/>
      <p>
        View more photos of this property and browse hundreds of traveler
        reviews on TripAdvisor.
      </p>
      <br />
      <br />
      <a href={tripAdvisorUrl} target="_blank" rel="noopener noreferrer">
        <Button className="viewButton">View more photos</Button>
      </a>
    </MorePhotosWrapper>
  );
};

MorePhotos.propTypes = {
  tripAdvisorUrl: PropTypes.string.isRequired,
};
