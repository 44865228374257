import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { BaggageElementWrapper } from '../styles';

export const LoadingBaggageView = ({ isVisible }: { isVisible: Boolean }) =>
  isVisible && (
    <BaggageElementWrapper>
      <h3>Baggage</h3>
      <FontAwesomeIcon icon={faCircleNotch} spin />
      <span className="loadingText">
        We are fetching the latest baggage prices.
      </span>
    </BaggageElementWrapper>
  );
