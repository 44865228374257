import React from 'react';
import { css } from 'emotion/macro';

const PartnerComponentUK = () => {
  return (
    <React.Fragment>
      <div
        className={css`
          position: relative;
          top: -4px;
        `}
      >
        {null}
      </div>
    </React.Fragment>
  );
};

export default PartnerComponentUK;
