import React from 'react';
import styled from 'react-emotion/macro';
import { TravelCategories } from '../TravelCategories/index.js';
import backgroundImageBeach from './beach-midsize.jpeg';

const grayBackgroundColor = '#f6f6f6';
const titleColor = '#1d1d1d';
const subTitleColor = '#696969';

export const HeroSectionMobile = () => {
  return (
    <HeroSectionMobileWrapper>
      <div className="backgroundImage">
        <div className="heroTitles">
          <h1>Dream holidays made affordable</h1>
          {/* <h1>Spread the cost of your flights.</h1> */}
        </div>
      </div>
      <TravelCategories />
    </HeroSectionMobileWrapper>
  );
};

const HeroSectionMobileWrapper = styled.div`
  background-color: ${grayBackgroundColor};
  padding-top: 0px;

  .backgroundImage {
    /* background-image: url('assets/beachOptimized.jpg'); */
    background-image: url('${backgroundImageBeach}');
    /* min-height: 180px;
    background-size: 100% 123%; */

    min-height: 160px;
    background-size: 115% 150%;
    background-position-y: -10px;

  .heroTitles {
    text-align: left;
    padding-bottom: 25px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;

    @font-face {
      font-family: 'San Francisco';
      font-weight: 400;
      src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff');
    }

    h1 {
      font-size: 28px;
      font-weight: 600;
      /* color: ${titleColor}; */
      color: white;
      margin: 0px;
    }

    h2 {
      color: ${subTitleColor};
      font-size: 17px;
      font-weight: 400;
      margin-top: 17px;
      margin-bottom: 5px;
    }
  }

  }

  @media (min-width: 1000px) {
    display: none;
  }
`;
