import { getFlymblePriceVariantMargin } from 'Utils';
import { TICKET_SERVICE_PRICE } from '../constants';

export function generateOrderSummaryObject(
  lastSearch,
  selectedFlightTicket,
  addedBaggagePiecesContext,
  totalBaggagePriceContext,
  purchasedBaggageTypesObject,
  discountCode,
  isAddedTicketServiceContext
) {
  const { noAdults, noChildren, noInfants, noMonths } = lastSearch;
  const { conversion } = selectedFlightTicket;
  const { code: Discount_Code, value: Discount_Amount } = discountCode;

  // The values for the input are:
  console.log(
    'addedBaggagePiecesContext The generate order summary function:',
    addedBaggagePiecesContext
  );
  console.log(
    'total baggage PiecesContext The generate order summary function:',
    totalBaggagePriceContext
  );
  console.log(
    'purchasedBaggageTypesObject The generate order summary function:',
    purchasedBaggageTypesObject
  );
  // Number of passengers addition.
  const noPassengers = noAdults + noChildren + noInfants;

  // Ticket service cost.
  const costAddedTicketService = isAddedTicketServiceContext
    ? TICKET_SERVICE_PRICE * noPassengers
    : 0;

  // Calculate baggage fee.
  const baggageCosts = totalBaggagePriceContext;
  const costPerBaggage = baggageCosts / addedBaggagePiecesContext;

  // Defenition of the flymble price.
  const flymblePrice = conversion[process.env.REACT_APP_VARIANT_CURRENCY];
  const flymblePriceIncludingBaggageAndDiscount =
    flymblePrice + baggageCosts - Discount_Amount + costAddedTicketService;

  // const flymblePriceIncludingBaggageAndDiscount_Monthly = parseFloat(
  //   flymblePriceIncludingBaggageAndDiscount / noMonths
  // );

  // The standard checkout price.
  const standardizedCheckoutPrice = parseFloat(
    flymblePriceIncludingBaggageAndDiscount
  ).toFixed(2);

  // When was the first visit?
  const firstVisit = localStorage.getItem('firstVisit') || 'n.a.';

  // Is the price variant assigned?
  const priceVariant = localStorage.getItem('priceVariant')
    ? localStorage.getItem('priceVariant')
    : 'not assigned';

  // Get the price margin.
  const flymblePriceMargin = getFlymblePriceVariantMargin();

  // Is the payment plan group assigned?
  const paymentPlanGroup = localStorage.getItem('paymentPlanGroup')
    ? localStorage.getItem('paymentPlanGroup')
    : 'not assigned';

  // Is the user mobile or desktop?
  const isUserDesktop = localStorage.getItem('isDesktop')
    ? localStorage.getItem('isDesktop')
    : 'not assigned';

  // Is it a viratual interlining flight?
  const isVi =
    selectedFlightTicket.virtual_interlining === true ? 'TRUE' : 'FALSE';

  const orderSummaryObject = {
    Nr_Of_Passengers: noPassengers,
    Nr_Of_Infants: noInfants,
    Flymble_Price: standardizedCheckoutPrice,
    FNPL_Price: standardizedCheckoutPrice,
    Price_Variant: priceVariant,
    Payment_Plan_Group: paymentPlanGroup,
    Virtual_Interlining: isVi,
    isUserDesktop: isUserDesktop,
    firstVisit: firstVisit,
    // Flymble_Price_Including_Baggage_AND_Discount: standardizedCheckoutPrice,
    // Monthly_Price_All_Passengers: flymblePriceIncludingBaggageAndDiscount_Monthly.toFixed(
    //   2
    // ),
    Nr_Of_Instalments: noMonths,
    The_Discount_Amount: Discount_Amount,
    The_Discount_Code: Discount_Code,
    // Is the baggage fee per baggage piece or for all baggage?
    Purchased_Baggage_Total_Cost: baggageCosts,
    Purchased_Baggage_Total_Nr_Added: addedBaggagePiecesContext,
    // ^^^^^ --- < ------- Review the baggage.
    ...purchasedBaggageTypesObject,
    purchasedBaggageTypesObject,
    flymblePriceMargin: flymblePriceMargin,
    isAddedTicketService: isAddedTicketServiceContext,
    costAddedTicketService: costAddedTicketService,
  };

  const orderSummary = `
        FNPL price: ${flymblePriceIncludingBaggageAndDiscount.toFixed(2)},
        Kiwi Price: ${flymblePriceIncludingBaggageAndDiscount.toFixed(2)},
        Flymble Price: ${flymblePriceIncludingBaggageAndDiscount.toFixed(2)},
        Price per person no fees: ${(
          flymblePriceIncludingBaggageAndDiscount / noPassengers
        ).toFixed(2)}
        Baggage Fees: ${baggageCosts},
        ALL total price: ${flymblePriceIncludingBaggageAndDiscount},
        noPassengers: ${noPassengers},
        baggageFee per baggage piece: ${costPerBaggage.toFixed(2)},
        noInstalments: ${noMonths}
    `;

  console.log(
    'The generate order summary function yields orderSummary:',
    orderSummary
  );
  console.log(
    'The generate order summary function yields orderSummaryObject:',
    orderSummaryObject
  );
  return { orderSummaryObject, orderSummary };
}
