import React, { useState } from 'react';
import { BaggageSectionWrapper } from './styles';
import { BaggageObjectInterface, PersonalItemInterface, CabinBagInterface, CheckedLuggageInterface } from "../../../interfaces";
import { DetermineViewModeCheckedBag } from "./DetermineViewModeCheckedBag";
import { DetermineViewModeHandBag } from "./DetermineViewModeHandBag";

export const BaggageViewController = ({
  baggageObjectState,
  setPurchasedBaggage,
  removePurchasedBaggage,
  prefix,
}: {
  baggageObjectState: BaggageObjectInterface,
  setPurchasedBaggage: Function,
  removePurchasedBaggage: Function,
  prefix: string
}) => {

  const {
    cabinBag,
    personalItem,
    checkedLuggage,
    priorityBoarding,
    checkedBagMode,
    handBagMode,
  }: {
    cabinBag: CabinBagInterface,
    personalItem: PersonalItemInterface,
    checkedLuggage: CheckedLuggageInterface,
    priorityBoarding: object,
    checkedBagMode: string,
    handBagMode: string
  } = baggageObjectState;

  const [holdBagOneOption, setHoldBagOneOption] = useState(false);
  const [handBagOneOption, setHandBagOneOption] = useState(false);

  const [handBagIsActive1, setHandBagIsActive1] = useState(true);
  const [handBagIsActive2, setHandBagIsActive2] = useState(false);

  const [holdBagIsActive1, setHoldBagIsActive1] = useState(true);
  const [holdBagIsActive2, setHoldBagIsActive2] = useState(false);

  return (
    <BaggageSectionWrapper>
      <h3
        className="baggageSectionTitle"
        style={{ marginBottom: '0px', color: 'black' }}
      >
        Cabin luggage
      </h3>
      <div className="baggageSectionSubTitle">
        {!handBagOneOption && 'Select one option'}
      </div>
      <DetermineViewModeHandBag
        mode={handBagMode}
        personalItem={personalItem}
        cabinBag={cabinBag}
        priorityBoarding={priorityBoarding}
        handBagIsActive1={handBagIsActive1}
        handBagIsActive2={handBagIsActive2}
        setPurchasedBaggage={setPurchasedBaggage}
        removePurchasedBaggage={removePurchasedBaggage}
        setHandBagIsActive1={setHandBagIsActive1}
        setHandBagIsActive2={setHandBagIsActive2}
        setHandBagOneOption={setHandBagOneOption}
        prefix={prefix}
      />
      <h3
        className="baggageSectionTitle"
        style={{ marginBottom: '0px', color: 'black' }}
      >
        Checked luggage
      </h3>
      <div className="baggageSectionSubTitle">
        {!holdBagOneOption && 'Select one option'}
      </div>
      <DetermineViewModeCheckedBag
        mode={checkedBagMode}
        checkedLuggage={checkedLuggage}
        holdBagIsActive1={holdBagIsActive1}
        holdBagIsActive2={holdBagIsActive2}
        setPurchasedBaggage={setPurchasedBaggage}
        removePurchasedBaggage={removePurchasedBaggage}
        setHoldBagIsActive1={setHoldBagIsActive1}
        setHoldBagIsActive2={setHoldBagIsActive2}
        setHoldBagOneOption={setHoldBagOneOption}
        prefix={prefix}
      />
    </BaggageSectionWrapper>
  );
};
