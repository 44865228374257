import React from 'react';
import { PriceInfoWrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

export const PriceInfo = ({
  dealPrice,
  oldDealPrice,
  priceBasisDescription,
  monthlyPriceAllPassengers,
}) => {
  return (
    <PriceInfoWrapper>
      <div className="pricePromotion">
        <div className="priceMain">
          <span className="priceFrom">From</span>
          <span className="priceValue">
            <span className="priceCurrency">£</span>
            {monthlyPriceAllPassengers.toFixed(0)}
          </span>
          <span className="pricePerPerson">&nbsp;/&nbsp;month</span>
        </div>
        <div className="priceMain" style={{ marginTop: '5px' }}>
          <span className="priceFrom">Total&nbsp;</span>
          <span className="pricePerPerson" style={{ fontSize: '1.125rem' }}>
            £{dealPrice.toFixed(0)}
          </span>
          <span className="priceStrikeThrough" style={{ color: 'black' }}>
            £{oldDealPrice.toFixed(0)}
          </span>
          <span className="pricePerPerson">for all passengers</span>
        </div>
      </div>

      <div className="priceLabel">
        {priceBasisDescription}&nbsp;{' '}
        <FontAwesomeIcon icon={faInfoCircle} className="faInfoCircle" />
      </div>
    </PriceInfoWrapper>
  );
};

PriceInfo.propTypes = {
  dealPrice: PropTypes.number.isRequired,
  oldDealPrice: PropTypes.number.isRequired,
  priceBasisDescription: PropTypes.string.isRequired,
  monthlyPriceAllPassengers: PropTypes.number.isRequired,
};
