import React, { useContext, useState } from 'react';
import { Button, Form, Input, message } from 'antd';
// import PropTypes from 'prop-types';
import i18n from 'Utils/i18n.js';
import { Wrapper, FormLabel, InputWrapper } from './styles';
import { DISCOUNT_CODES } from '../../../constants';
import AppContext from 'Context/AppContext';

const DiscountCode = () => {
  let ref = React.createRef();
  const [isDiscountCodeVisible, setIsDiscountCodeVisible] = useState(true);

  let context = useContext(AppContext);
  const { setDiscountCode } = context;

  const discountCodeHandler = discountCode => {
    if (DISCOUNT_CODES[discountCode]) {
      message.success(
        `Congratulations! You've received £${DISCOUNT_CODES[
          discountCode
        ].toFixed(0)} discount!`,
        2
      );
      setDiscountCode({
        code: discountCode,
        value: DISCOUNT_CODES[discountCode],
      });
      setIsDiscountCodeVisible(false);
    } else {
      message.warning(
        `Your discount code has not been recognized. Have you spelled everything correctly?`,
        3
      );
      setDiscountCode({
        code: '',
        value: 0,
      });
    }
  };

  return (
    <Wrapper>
      {isDiscountCodeVisible && (
        <div>
          <FormLabel>
            {i18n.t('DiscountCode..Do you have a discount code?')}
          </FormLabel>
          <InputWrapper>
            <Form.Item>
              <Input
                ref={input => {
                  ref = input;
                }}
                placeholder={i18n.t('DiscountCode..discount code')}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="ant-btn-primary ant-btn-lg"
                onClick={() => discountCodeHandler(ref.input.value)}
              >
                {i18n.t('DiscountCode..Verify code')}
              </Button>
            </Form.Item>
          </InputWrapper>
        </div>
      )}
    </Wrapper>
  );
};

export default DiscountCode;
