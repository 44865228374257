import styled from 'react-emotion/macro';

export const CookiesPopupWrapper = styled.div`
  /* background: #1282cf; */
  background: #252a31e8;
  bottom: 0;
  /* color: #fff; */
  color: white;
  font-size: 12px;
  left: 0;
  padding: 12px 75px 8px 12px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  cursor: pointer;

  p {
    position: relative;
    font-size: 11px;
    margin-bottom: 7px;
  }

  .closeText {
    font-size: 14px;
    /* border-bottom: 1px white solid; */
    margin-bottom: 5px;
    line-height: 1.5;
    display: inline-block;
  }

  @media(min-width: 1000px) {
    p, .closeText {

    font-size: 16px;
    }
  }
`;
