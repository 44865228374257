import React from 'react';
import styled from 'react-emotion/macro';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const flymbleBlue = '#4860ff';
const flymbleGreen = '#13c29b';

const ProceedButtonWrapper = styled.div`
  /* padding-bottom: 40px; */
  grid-column: 1/5;
  margin-top: 35px;

  padding: 0px 0px 0px 0px !important;

  .proceedToBookingDetailsButton {
    grid-column: 2/3;

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 47px;

    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 25px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${props => (props.Green ? flymbleGreen : flymbleBlue)};
    color: white;
    border-color: ${props =>
      props.Green ? flymbleGreen : flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;
    }

    @media (max-width: 400px) {
      .faIconProceed {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      grid-column: 1/3;

      span {
        margin-top: 0px;
      }
    }

    @media (min-width: 1000px) {
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      display: inline;
    }
  }

  @media (min-width: 1000px) {
    text-align: center;
    /* margin-right: 20px; */
    margin-top: 40px;
  }
`;

export default class ProceedButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
    };
  }

  //
  handleOnClick = () => {
    this.setState({ loading: true });
    this.timeoutPlanSelection = setTimeout(() => {
      this.setState({ loading: false });
      this.props.handleProceedFurther();
    }, 1500);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutPlanSelection);
  };
  render() {
    const {
      text,
      displayProceedButton,
      Green,
      buttonId,
    } = this.props;
    return (
      displayProceedButton && (
        <ProceedButtonWrapper Green={Green}>
          <Button
            className="proceedToBookingDetailsButton"
            id={buttonId}
            onClick={this.handleOnClick}
            loading={this.state.loading}
          >
            {text}
          </Button>
        </ProceedButtonWrapper>
      )
    );
  }
}

ProceedButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleProceedFurther: PropTypes.func,
  displayProceedButton: PropTypes.bool,
  Green: PropTypes.bool,
  buttonId: PropTypes.string.isRequired,
};
