import React from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPen } from '@fortawesome/free-solid-svg-icons';
import AppContext from '../../Context/AppContext/index';
import i18n from 'Utils/i18n.js';
import SearchMobileLP from './../Search/SearchMobileLP';
import TopSearchPageBanner from '../../components/Banners/FlightResultsBanner';
import {
  PageMain,
  PageWrapper,
  SearchBarWrapper,
  searchInputSummary,
} from './styles';
import { SEARCH_MOBILE_MODE } from '../../constants';

const dateText = 'ddd MMM D';
const SearchResultContext = React.createContext();
const SearchResultConsumer = SearchResultContext.Consumer;

message.config({
  top: 100,
  duration: 2,
  maxCount: 1,
});

class SearchResult extends React.Component {
  state = { filtersVisible: false };

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  goBack = () => {
    this.props.history.push('/');
  };

  handleFilter = () => {
    this.setState({ filtersVisible: true });
  };

  handleOnClose = () => {
    console.log('SearchResult.handleOnClose');
    this.setState({ filtersVisible: false });
  };

  render() {
    const { children } = this.props;
    const {
      lastSearch: {
        flyingTo,
        flyingFrom,
        dateFrom,
        dateTo,
        noAdults,
        noChildren,
        noInfants,
      },
    } = this.context;
    const appContext = this.context;

    if (typeof flyingFrom === 'undefined') {
      return (
        <PageWrapper>
          <PageMain id="summary">
            <h5>no data</h5>
          </PageMain>
        </PageWrapper>
      );
    } else {
      return (
        <PageWrapper>
          <PageMain id="summary">
            <React.Fragment>
              <div className={searchInputSummary}>
                <div className="inputWrapper">
                  <div className="summaryRows">
                    <div className="fromToRow">
                      <span>{flyingFrom.substr(-3, 3)}</span>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="arrowIcon"
                      />
                      <span>{flyingTo.substr(-3, 3)}</span>
                    </div>
                    <div className="datesRow">
                      {dateFrom && dateTo && (
                        <span>
                          {`${dateFrom.format(dateText)} - ${dateTo.format(
                            dateText
                          )},`}
                          <br />
                        </span>
                      )}
                      <span className="nrPassengers">
                        {i18n.t('SearchResult..Passengers: ', {
                          count: noAdults + noChildren + noInfants,
                        })}{' '}
                        <span>
                          {noAdults > 0 &&
                            i18n.t('SearchResult..{{count}} adult', {
                              count: noAdults,
                            })}
                        </span>
                        <span>
                          {noChildren > 0 &&
                            `, ${i18n.t('SearchResult..{{count}} child', {
                              count: noChildren,
                            })}`}
                        </span>
                        <span>
                          {noInfants > 0 &&
                            `, ${i18n.t('SearchResult..{{count}} infant', {
                              count: noInfants,
                            })}`}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="editButton" onClick={this.goBack}>
                    <FontAwesomeIcon icon={faPen} className="editIcon" />
                    <p className="editText">
                      <Trans i18nKey="SearchResult..Edit">Edit</Trans>
                    </p>
                  </div>
                </div>
              </div>
              <SearchBarWrapper>
                <SearchMobileLP
                  mode={SEARCH_MOBILE_MODE.LIGHT}
                  lastSearch={appContext.lastSearch}
                  toggleShowResults={appContext.toggleShowResults}
                />
              </SearchBarWrapper>
              <div className="desktopOnly" style={{ paddingTop: '30px' }}>
                <TopSearchPageBanner isVisible={false} />
              </div>
            </React.Fragment>
            {children}
          </PageMain>
        </PageWrapper>
      );
    }
  }
}

SearchResult.contextType = AppContext;

const RoutedSearchResult = withRouter(SearchResult);
delete RoutedSearchResult.contextType;

export { SearchResultConsumer };
export default RoutedSearchResult;
