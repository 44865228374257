import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons';
import { iconSpacing } from '../FormStepController/styles.js';

export const SectionTitle = ({ title }) => {
  return (
    <h2>
      <FontAwesomeIcon
        icon={faPhoneSquare}
        className={cx('faIcon', iconSpacing)}
      />
      {title}
    </h2>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
