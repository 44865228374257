import React, { Suspense } from 'react';
import styled from 'react-emotion/macro';
import * as Sentry from '@sentry/browser';
import i18n from 'Utils/i18n.js';
import Navigation from 'components/Nav/index';
import NavMobile from 'components/Nav/NavMobile';
import AppRouter from './AppRouter';
import Footer from 'components/Footer';
import CookiesPopup from './components/CookiesPopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PRICE_VARIANTS } from './constants';
import uuid from 'uuid/v4';
import { isMobileDevice, getFlymblePriceVariantMargin } from 'Utils';

const NavWrapper = styled.div`
  height: 57px;
  position: relative;

  @media (min-width: 600px) {
    height: 50px;
  }
`;

const menuItemsUK = [
  { title: i18n.t('menuItems..About us'), link: '/about-us' },
  { title: i18n.t('menuItems..FAQs'), link: '/help' },
  { title: i18n.t('menuItems..Transparency'), link: '/transparency' },
  { title: i18n.t('menuItems..Reviews'), link: '/review-page' },
  { title: i18n.t('menuItems..Guides'), link: '/guides' },
];

const menuItemsPL = [
  { title: i18n.t('menuItems..About us'), link: '/about-us' },
  { title: i18n.t('menuItems..FAQs'), link: '/help' },
  { title: i18n.t('menuItems..Reviews'), link: '/review-page' },
];

const menuItems = i18n.language === 'pl' ? menuItemsPL : menuItemsUK;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://bafb0eb9495243c6824aef9faca40762@sentry.io/1247075',
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

const generatePriceVariant = () => {
  const { VARIANT_G, VARIANT_H, VARIANT_I } = PRICE_VARIANTS;
  var num = Math.random();
  console.log('The generated probabibility is', num);
  if (num < 0.6) return VARIANT_G;
  else if (num < 0.8) return VARIANT_H;
  else return VARIANT_I; //probability 0.2
};


class App extends React.Component {
  state = {
    cookiePopupVisible: this.isCookiesRead(),
  };

  componentDidMount = () => {
    console.log('ENV:', process.env.NODE_ENV);

  
    const saved_price_variant = localStorage.getItem('priceVariant');

    if (saved_price_variant) {
      console.log(
        `[App.js] P-variant = ${saved_price_variant} @ ${getFlymblePriceVariantMargin()}`
      );
    } else {
      const price_variant = generatePriceVariant();
      localStorage.setItem('priceVariant', price_variant);

      console.log(
        `[App.js] NEW GENERATED P-variant = ${price_variant} @ ${getFlymblePriceVariantMargin()}`
      );

    }

    if (!localStorage.getItem('firstVisit')) {
      const todayDate = new Date();
      const today_date_string = todayDate.toGMTString();
      localStorage.setItem('firstVisit', today_date_string);
    } else {
      console.log('FVST', localStorage.getItem('firstVisit'));
    }

    const isDesktop = !isMobileDevice();
    localStorage.setItem('isDesktop', isDesktop);

    if (localStorage.getItem('flymbleUserId')) {
      //
    } else {
      const flymbleUserId = uuid();
      localStorage.setItem('flymbleUserId', flymbleUserId);
      window.mixpanel.identify(flymbleUserId);
    }
  };

  closeHandler() {
    localStorage.setItem('cookiePopup', false);
    this.setState({ cookiePopupVisible: false });
  }

  isCookiesRead() {
    return localStorage.getItem('cookiePopup') === null;
  }

  render() {
    return (
      <div className="App">
        <NavWrapper>
          <Navigation menuItems={menuItems} />
          <NavMobile menuItems={menuItems} />
          {/* <SubHeader /> */}
        </NavWrapper>
        <Suspense fallback={<AppSuspense />}>
          <AppRouter />
        </Suspense>
        <Footer />
        <CookiesPopup
          closeHandler={() => this.closeHandler()}
          visible={this.state.cookiePopupVisible}
        />
        <ToastContainer />
      </div>
    );
  }
}

export default App;

const AppSuspense = () => <div style={{ minHeight: '400px' }} />;


