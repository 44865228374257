import React from 'react';
import PropTypes from 'prop-types';
import LogoFNPL from 'assets/images/fnpl-logo.svg';
import styled from 'react-emotion/macro';

const InstructionsFnpl = ({ isVisible }) => {
  return (
    isVisible && (
      <InstructionsFnplWrapper>
        <div className="bodyContent">
          <div className="banner">
            <img src={LogoFNPL} alt="partner-logo-fly-now-pay-later" />
          </div>
          <div className="textContent">
            <h3>Complete your booking!</h3>
            {/* <p>
              1. Double check if all your details are correct.
              <br />
              2. Instant credit check and review credit agreement.
              <br />
              3. Receive your booking confirmation within 12 hours. Enjoy!
            </p> */}
            <p>
              - Double check if all your details are correct.
              <br />
              - It can take up to 2 minutes to load.
              <br />
              {/* 3. Receive your booking confirmation within 12 hours. Enjoy! */}
            </p>
          </div>
        </div>
      </InstructionsFnplWrapper>
    )
  );
};

InstructionsFnpl.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default InstructionsFnpl;

const InstructionsFnplWrapper = styled.div`
  text-align: center;

  .bodyContent {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: white;
    color: black;

    .banner {
      text-align: center;
      img {
        max-height: 42px;
        max-width: 190px;
        margin-bottom: 10px;
      }
    }

    .textContent {
    }
  }

  @media (max-width: 1000px) {
    padding: 15px;
    color: black;
    h3 {
      margin-top: 0px;
      margin-bottom: 6px;
    }

    p {
      margin-bottom: 6px;
      margin-top: 8px;
    }

    .bodyContent {
      text-align: left;
      border: #00c699 2px solid;
      background-color: #cdfff6;
      border-radius: 3px;

      .banner {
        display: none;
      }
    }
  }

  @media (min-width: 1000px) {
    padding-top: 20px;
    margin-bottom: 40px;
    margin-top: 30px;
    text-align: left;
    border: 1px solid #e4eaf0;
    background-color: white;
    padding-bottom: 25px;
    .bodyContent {
      padding-bottom: 0px;
      padding-top: 30px;

      .banner {
        text-align: left;
      }

      .title {
        /* font-size: 20px; */
      }

      .textContent {
        font-size: 15px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
`;
