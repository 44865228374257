import React from 'react';
import PropTypes from 'prop-types';

export const InputLabelComp = ({ text }) => (
  <p
    style={{
      fontWeight: 400,
      marginBottom: '5px',
      color: 'black',
      fontSize: '15px',
      marginTop: '15px',
    }}
  >
    {text}
  </p>
);

InputLabelComp.propTypes = {
  text: PropTypes.string.isRequired,
};
