import styled from 'react-emotion/macro';
const flymbleBlue = '#4860ff';



export const SummaryNavigationWrapper = styled.div`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  color: ${flymbleBlue};

  .summaryLink {
    padding: 0.625rem 0;
    border-bottom: 1px solid #ccc;
    font-weight: 400;

    .faIcon {
      font-size: 18px;
      float: right;
    }
  }

  @media(min-width: 1000px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;


