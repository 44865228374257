/**
 * Use the CSS tab above to style your Element's container.
 */
import React from "react";
import { CardElement } from "react-stripe-elements";

const style = {
  base: {
    color: "#32325d",
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};

const CardSection = () => {
  return (
    <div style={{ maxWidth: "400px", fontWeigth: '400', color: 'black', marginTop: '8px' }}>
      <label>Use your debit or credit card to book.</label>
      <br />
      <div style={{ marginTop: "20px" }} />
      <CardElement className="MyCardElement" style={style} />
    </div>
  );
};

export default CardSection;
