import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tranlation_en from 'i18n/en/resource.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      fallbackLng: 'en',
      resources: {
        en: { translation: tranlation_en },
      },
      lng: process.env.REACT_APP_VARIANT_LOCALE,
      debug: false,

      nsSeparator: '::',
      keySeparator: '..', // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        backends: [],
        backendOptions: [
          {
            loadPath: '/i18n/{{lng}}/{{ns}}.json', // xhr load path for my own fallback
          },
        ],
      },
    },
    (error, t) => {
      if (error) return console.log('something went wrong loading', error);
      t('key'); // -> same as i18next.t
    }
  );

export default i18n;
