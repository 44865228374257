import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ViewDetailsToggleButton = ({ isTurnedUp, handlerFunction, text }) => {
  return (
    window.location.pathname.indexOf('confirmation') === -1 && (
      <ViewDetailsToggleButtonWrapper>
        {text ? (
          <p
            className="viewDetailsToggle"
            onClick={() => handlerFunction(!isTurnedUp)}
          >
            {text}
            <FontAwesomeIcon
              className="viewDetailsIcon"
              icon={isTurnedUp ? faChevronUp : faChevronDown}
            />
          </p>
        ) : (
          <FontAwesomeIcon
            className="viewDetailsIcon"
            icon={isTurnedUp ? faChevronUp : faChevronDown}
            onClick={() => handlerFunction(!isTurnedUp)}
          />
        )}
      </ViewDetailsToggleButtonWrapper>
    )
  );
};

ViewDetailsToggleButton.propTypes = {
  isTurnedUp: PropTypes.bool.isRequired,
  handlerFunction: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export { ViewDetailsToggleButton };

const ViewDetailsToggleButtonWrapper = styled.div`
  .viewDetailsToggle {
    text-align: center;
    font-weight: 500;
    color: black;
  }
  .viewDetailsIcon {
    margin-left: 10px;
    padding: 4px;
    width: 20px;
    height: 20px;
    /* background-color: #f0f0f0; */
    background-color: white;
    display: inline-block;
    cursor: pointer;
    border: 1px #80808091 solid;
    border-radius: 50%;
    vertical-align: middle;
  }
`;
