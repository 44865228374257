import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form } from 'antd';
import { RedirectElement } from '../../../../components/RedirectElement';
import styled from 'react-emotion/macro';
import { signUpUserEmailCollect } from 'Utils';

const flymbleBlue = '#4860ff';
const flymbleGreen = '#13c29b';

class EmailSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonLoadingState: false,
      showThankYouMessage: false,
    };
  }

  handleContinueSignUp = e => {
    e.preventDefault();
    this.setState({ isButtonLoadingState: true });
    this.props.form.validateFields((err, values) => {
      setTimeout(() => {
        clearTimeout();
        if (!err) {
          console.log('Received values of form: ', values);
          const lastSearch = this.props.lastSearch;
          const flymbleUserId = localStorage.getItem('flymbleUserId');
          const source = `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`;
          const email = values.email;
          signUpUserEmailCollect(lastSearch, flymbleUserId, email, source);

          // User has provided Email.
          localStorage.setItem('hasEmailSignedUp', email);

          this.props.handleProceedFurther();
        }
        this.setState({ isButtonLoadingState: false });
      }, 1500);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return this.state.showThankYouMessage ? (
      <RedirectElement destination="/checkout" isAllowedRedirect={true} />
    ) : (
      this.props.isVisible && (
        <>
          <br />
          <SignUpFormWrapper>
            <Form onSubmit={this.handleContinueSignUp}>
              <Form.Item>
                <p
                  style={{
                    fontWeight: 400,
                    marginBottom: '5px',
                    color: 'black',
                    fontSize: '15px',
                    marginTop: '5px',
                  }}
                >
                  Provide your email to continue
                </p>
                {getFieldDecorator('email', {
                  rules: [
                    { required: true, message: 'Please provide your Email!' },
                  ],
                })(
                  <Input
                    placeholder="your@email.com"
                    size="large"
                    type="email"
                  />
                )}
              </Form.Item>
              <ProceedButtonWrapper>
                <Button
                  size="large"
                  className="proceedToBookingDetailsButton"
                  loading={this.state.isButtonLoadingState}
                  htmlType="submit"
                >
                  Continue
                </Button>
              </ProceedButtonWrapper>
            </Form>
          </SignUpFormWrapper>
        </>
      )
    );
  }
}

EmailSignUp.propTypes = {
  handleProceedFurther: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  lastSearch: PropTypes.object.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldsError: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
    isFieldTouched: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
};

const WrappedEmailSignUpForm = Form.create({ name: 'horizontal_login' })(
  EmailSignUp
);

export { WrappedEmailSignUpForm as SignUpForm };

const ProceedButtonWrapper = styled.div`
  /* padding-bottom: 40px; */
  /* grid-column: 1/5; */
  margin-top: 35px;

  padding: 0px 0px 0px 0px !important;

  .proceedToBookingDetailsButton {
    grid-column: 2/3;

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 47px;

    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 25px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${props => (props.Green ? flymbleGreen : flymbleBlue)};
    color: white;
    border-color: ${props =>
      props.Green ? flymbleGreen : flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;
    }

    @media (max-width: 400px) {
      .faIconProceed {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      grid-column: 1/3;

      span {
        margin-top: 0px;
      }
    }

    @media (min-width: 1000px) {
      margin-left: auto;
      margin-right: auto;
      display: inline;
    }
  }

  @media (min-width: 1000px) {
    text-align: center;
    margin-top: 25px;
  }
`;

const SignUpFormWrapper = styled.div`
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  @media (min-width: 1000px) {
    margin-top: 35px;
  }
`;
