import React, { Component } from 'react';
import PropTypes from 'prop-types';

const FiltersContext = React.createContext();
const FiltersConsumer = FiltersContext.Consumer;

class FiltersProvider extends Component {
  state = {};

  setFilters = v => {
    this.setState(v);
  };

  render() {
    let { children } = this.props;
    return (
      <FiltersContext.Provider
        value={{ filters: this.state, setFilters: this.setFilters }}
      >
        {children}
      </FiltersContext.Provider>
    );
  }
}

FiltersProvider.propTypes = {
  children: PropTypes.any,
};

export default FiltersProvider;
export { FiltersConsumer, FiltersProvider };
