import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PriceInfo } from '../PriceInfo';
import { DisplayRoomsCapacity } from './DisplayRoomsCapacity';
import { RoomsForm } from './RoomsForm';
import { PackagesContext } from '../../../../Context/PackagesContext';

export const SelectOfferCard = ({
  selectedPackage,
  handleSelectOffer,
  isButtonLoadingState,
  isVisible,
}) => {
  if (selectedPackage) {
    const context = useContext(PackagesContext);

    const {
      amountRoomsContext,
      amountNightsContext,
      setAmountRoomsContext,
      amountPassengersContext,
      getPackagePriceObjectContext,
      packagePriceObjectContext: {
        oldDealPriceAllPassengers,
        dealPriceAllPassengers,
      },
    } = context;
    const { roomCapacity, typeRoom } = selectedPackage;

    useEffect(() => {
      getPackagePriceObjectContext(selectedPackage);
    }, [amountRoomsContext, amountNightsContext, amountPassengersContext]);

    const priceBasisDescription = `Based on ${amountPassengersContext} people for ${amountNightsContext} nights`;

    return (
      isVisible && (
        <>
          <div className="title">{typeRoom}</div>
          <DisplayRoomsCapacity roomCapacity={roomCapacity} />
          <RoomsForm selectRoomsAmount={setAmountRoomsContext} />
          <PriceInfo
            priceBasisDescription={priceBasisDescription}
            dealPrice={dealPriceAllPassengers}
            oldDealPrice={oldDealPriceAllPassengers}
            monthlyPriceAllPassengers={dealPriceAllPassengers / 12}
          />
          <br />
          <Button
            size="large"
            className="selectOfferButton"
            onClick={handleSelectOffer}
            loading={isButtonLoadingState}
          >
            Select offer
          </Button>
        </>
      )
    );
  } else return <div>Loading</div>;
};

SelectOfferCard.propTypes = {
  handleSelectOffer: PropTypes.func.isRequired,
  isButtonLoadingState: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  selectedPackage: PropTypes.object.isRequired,
};
