import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { Button } from 'antd';

const flymbleBlue = '#4860ff';

export const ContinueButton = ({
  handleContinue,
  isButtonLoading,
  isVisible,
}) => {
  return (
    isVisible && (
      <ContinueButtonWrapper>
        <Button
          size="large"
          className="continueButton"
          onClick={() => handleContinue()}
          loading={isButtonLoading}
        >
          Continue
        </Button>
      </ContinueButtonWrapper>
    )
  );
};

ContinueButton.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  isButtonLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export const ContinueButtonWrapper = styled.div`
  margin-top: 20px;

  .continueButton {
    background-color: ${flymbleBlue};
    width: 100%;
    color: white;

    @media (min-width: 1000px) {
      max-width: 50% !important;
    }
  }
`;
