import styled from 'react-emotion/macro';

const airbnbFontColor = '#484848';

export const PageHeaderWrapper = styled.div`
  color: ${airbnbFontColor};
  padding-bottom: 20px;

  .title {
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin: 0px;
    padding: 0px;
    color: black;
  }

  .subTitle {
    padding-top: 8px;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375em;
  }
`;

export const OfferHeaderWrapper = styled.div`
  color: black;
  padding: 20px 15px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 14px;

  i {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
  }
`;

export const TopHeaderWrapper = styled.div`
  background-color: black;
  color: white;
  padding: 15px 20px;
  text-align: center;

  a {
    text-decoration: none;
    color: inherit;
  }

  .title {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
  }

  @media(min-width: 1000px) {
    /* grid-column: 1/5; */
    display: none;
  }
`;

export const GoBackButtonWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 11px;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  padding: 8px 12px;
  background-color: #333 !important;
  color: #ffffff !important;

  .faIcon {
    margin-right: 5px;
  }
`;
