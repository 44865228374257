import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import LogoFNPL from 'assets/images/fnpl-logo.svg';
import BannerDesktop from './assets/WhyFlymbleBannerDesktop.jpg';

const whyFlymbleUspsUK = [
  { bold: 'Lock-in prices', light: ' months earlier when they are cheap' },
  {
    bold: 'You know what you pay',
    light: ' we provide transparent pricing',
  },
  { bold: 'Powered by our trusted partner', light: '' },
];

const WhyFlymbleWrapper = styled('div')`
  .imageCover {
    background-size: cover !important;
    min-height: 325px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    padding-left: 40px;
    padding-right: 40px;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.35) 100%
      ),
      url(${BannerDesktop});

    h2 {
      margin-left: auto;
      margin-right: auto;
      color: white;
      text-align: center;
      padding-top: 40px;
      margin-top: 0px;

      font-weight: 600;
      font-size: 28px;
      margin-bottom: 25px;
    }

    p {
      color: white;
      text-align: center;
      margin-bottom: 20px;
      font-size: 15px;
    }

    .lockPricesBody {
      color: white;
      text-align: center;
      margin: 40px 0px;
      font-size: 20px;
    }

    .partnerLogo {
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .imageCover {
      /* grid-template-columns:  */
      background: url(${BannerDesktop});
      min-height: 450px;
    }

    .bodyWrapper {
      padding: 20px;

      h2 {
        margin-top: 20px;
        font-size: 35px;
      }

      p {
        font-size: 18px;
      }
      .partnerLogo {
        height: 60px;
        display: block;
      }
    }

    .mobileOnly {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    .desktopOnly {
      display: none;
    }
  }
`;

const WhyFlymble = ({
  titleText,
  explenationText,
  whyFlymbleUsps,
  PartnerLogo,
}) => {
  const logo = PartnerLogo ? PartnerLogo : LogoFNPL;
  const BodyUsps = (
    <div className="bodyWrapper">
      <h2 style={{ paddingTop: '50px' }}>{titleText}</h2>
      {whyFlymbleUsps.map((element, index) => {
        return (
          <p key={index}>
            <b>{element.bold}</b> <span>{element.light}</span>
          </p>
        );
      })}
      <img src={logo} className="partnerLogo" alt="" />
    </div>
  );

  const BodyLockPrices = (
    <div className="bodyWrapper">
      <h2>{titleText}</h2>
      <p className="lockPricesBody">{explenationText}</p>
      <img src={logo} className="partnerLogo" alt="" />
    </div>
  );

  const Body = explenationText ? BodyLockPrices : BodyUsps;
  return (
    <WhyFlymbleWrapper>
      <div className="imageCover">
        <div className="mobileOnly">{Body}</div>
      </div>
      <div className="desktopOnly">{Body}</div>
    </WhyFlymbleWrapper>
  );
};

WhyFlymble.propTypes = {
  titleText: PropTypes.string.isRequired,
  explenationText: PropTypes.string,
  whyFlymbleUsps: PropTypes.arrayOf(
    PropTypes.shape({
      bold: PropTypes.string.isRequired,
      light: PropTypes.string.isRequired,
    })
  ).isRequired,
  PartnerLogo: PropTypes.string,
};

WhyFlymble.displayName = 'WhyFlymble';

export default memo(WhyFlymble);
export { whyFlymbleUspsUK };
