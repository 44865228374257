import React from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  timeDuration,
  timeConvert,
  airlineName,
} from './../../../Utils/fnplCalc';
import { ConnectionTime } from './ConnectionTime';
import { RouteSection } from './RouteSection';
import { TitleRow } from './TitleRow';

//#region
const DetailedViewWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;
  font-weight: 300;

  @media (max-width: 1000px) {
    grid-column: 1/3;

    .airlineInfo {
      grid-column: 1/3 !important;
    }
  }

  @media (min-width: 1000px) {
    padding-left: 37px;
    /* grid-column: 1/3; */
    border-top: dashed 1px #d0d0d0;
    align-content: start;
    grid-column: ${props => (props.isOneWay ? '1/2' : '')};

    .airlineName {
      padding: 0px !important;
      padding-left: 15px !important;
    }

    .airlineInfo {
      grid-column: 1/3 !important;
      text-align: left !important;
      padding-bottom: 15px !important;
    }

    .legType {
      display: none;
    }
  }
`;
//#endregion

function ConnectionsArray(route) {
  var arrayConnections = [];
  var arrivalTimes = [];
  var departureTimes = [];

  // split the departure and arrivals in seperate arrays, to create array with
  for (var j = 1; j < route.length; j++) {
    departureTimes.push(route[j].dTimeUTC);
  }

  for (var i = 0; i < route.length; i++) {
    arrivalTimes.push(route[i].aTimeUTC);
  }

  for (var f = 0; f < departureTimes.length; f++) {
    var singleConnection = timeDuration(arrivalTimes[f], departureTimes[f]);
    arrayConnections.push(singleConnection);
  }

  return arrayConnections;
}

//@Todo
//Connection duration needs to be calculated somehow between the previous arrival and the next arrival.
//Long wait [true / false] can be determined if (connectionDuration > 3)

// @todo fix the index used in key property in whole class

const DetailedView = ({
  route,
  typeLeg,
  textDateDeparture,
  textDateArrival,
  totalDuration,
  isOneWay,
}) => {
  const { t } = useTranslation();
  var sections = [];
  var connections = [];
  const typeLegText =
    typeLeg === 'Outbound'
      ? t('DetailedView..Outbound')
      : t('DetailedView..Return');

  connections = ConnectionsArray(route).map((duration, index) => (
    <ConnectionTime
      key={`c${index}`}
      connectionDuration={duration}
      isLongWait={parseInt(duration.split('')[0], 10) > 2}
    />
  ));

  sections = route.map((route, index) => (
    <RouteSection
      key={`r${index}`}
      airlineCodes={route.airline}
      airlineName={airlineName(route.airline)}
      departureTime={timeConvert(route.dTime)}
      arrivalTime={timeConvert(route.aTime)}
      departureTimeUTC={route.dTimeUTC}
      arrivalTimeUTC={route.aTimeUTC}
      departureAirport={route.flyFrom}
      arrivalAirport={route.flyTo}
      departureCity={route.cityFrom}
      arrivalCity={route.cityTo}
    />
  ));

  return (
    <DetailedViewWrapper isOneWay={isOneWay}>
      <TitleRow
        typeLeg={typeLegText}
        textDateDeparture={textDateDeparture}
        textDateArrival={textDateArrival}
        totalDuration={totalDuration}
      />
      {sections.map((section, index) => {
        return (
          <React.Fragment key={`f${index}`}>
            {section}
            {connections[index]}
          </React.Fragment>
        );
      })}
    </DetailedViewWrapper>
  );
};

DetailedView.propTypes = {
  route: PropTypes.array.isRequired,
  typeLeg: PropTypes.string.isRequired,
  textDateDeparture: PropTypes.string.isRequired,
  textDateArrival: PropTypes.string.isRequired,
  totalDuration: PropTypes.string.isRequired,
  isOneWay: PropTypes.bool,
};

export { DetailedView };
