import faker from 'faker/locale/en_GB';

const fDOB = new Date(faker.date.between('1950-01-01', '2000-01-01'));
const fExp = new Date(faker.date.between('2020-01-01', '2028-01-01'));

export const defValues = {
  P0_firstname: faker.name.firstName(), // 'Jonh',
  P0_lastname: 'TEST ' + faker.name.lastName(), // 'Smith',
  P0_nationality: 'United Kingdom',
  P0_gender: faker.random.arrayElement(['male', 'female']), // 'male'
  P0_dobMonth: '' + (1 + fDOB.getMonth()), // '02',
  P0_dobDay: '' + fDOB.getDate(), // '03',
  P0_dobYear: '' + fDOB.getFullYear(), // '1994',
  P0_idNumber: 'IDNUM000005',
  P0_idExpMonth: '' + (1 + fExp.getMonth()), // '05',
  P0_idExpDay: '' + fExp.getDay(), // '06',
  P0_idExpYear: '' + fExp.getFullYear(), // '2027',
  P0_idNoExp: faker.random.boolean(), // false,
  paying_title: 'Mr',
  paying_firstname: faker.name.firstName(), // 'John',
  paying_lastname: 'TEST ' + faker.name.lastName(), // 'Smith',
  paying_dobMonth: '' + (1 + fDOB.getMonth()), // '07',
  paying_dobDay: '' + fDOB.getDate(), // '08',
  paying_dobYear: '' + fDOB.getFullYear(), // '1999',
  paying_nationality: 'United Kingdom',
  email: 'NON' + faker.internet.exampleEmail(), // 'john.smith.007@gmail.com',
  mobileNr: '079' + faker.finance.amount(90000000, 99999999, 0), // '07987987987',

  postcode: 'W1T 1PZ',
  street: 'Newman Street', // faker.address.streetName(), // 'Sessame St',
  houseNr: '34', // faker.address.streetAddress('#').split(' ')[0],
  apartmentNr: '4', //apartment,
  floorNr: '1', //floor,
  moreThanYear: 'More than a year',
  city: 'London', //faker.address.city(),
  county: 'Greater London', //faker.address.county(),

  salary: faker.finance.amount(1e4, 1e5, 0), // '32000',
  residentialstatus: 'Owner',
  employment: 'Full time employed',
};
