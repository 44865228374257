import React from 'react';
import { AirlineRowWrapper } from './AirlineRow';
import { airlineName } from './../../../Utils/fnplCalc';
import { ShareTicket } from './ShareTicket';
import PropTypes from 'prop-types';

// This one is where we want to display multiple airlines as a summary.
// This is not the detailled view.
const AirlineRowTop = ({ airlineCodes, flightLegCodes, legType }) => {
  const nameAirline = airlineName(flightLegCodes[0]);
  return (
    <AirlineRowWrapper>
      <div className="legType">{legType}</div>

      <div className="airlineInfo">
        <div>
          {/* This mapping function is display for DESKTOP only...  */}
          <div className="desktopOnly">
            {flightLegCodes.map((airlineCode, i) => (
              <img
                src={`https://images.kiwi.com/airlines/64/${airlineCode}.png`}
                alt="airlinelogo"
                key={`${airlineCode}_${i}`}
              />
            ))}
          </div>
          {/* This mapping function is display for Mobile only...  */}
          <div className="mobileOnly">
            {airlineCodes.map((airlineCode, i) => (
              <img
                src={`https://images.kiwi.com/airlines/64/${airlineCode}.png`}
                alt="airlinelogo"
                key={`${airlineCode}_${i}`}
              />
            ))}
          </div>
          <span className="airlineName">{nameAirline}</span>
        </div>
        <div className="shareMobile">
          <ShareTicket />
        </div>
      </div>

      <div className="seatsLeft">
        {/* <span>
            {nrSeatsLeft} {seatsText}
            <div />
          </span> */}
      </div>
    </AirlineRowWrapper>
  );
};

AirlineRowTop.propTypes = {
  airlineCodes: PropTypes.array.isRequired,
  flightLegCodes: PropTypes.array.isRequired,
  legType: PropTypes.string.isRequired,
};

export { AirlineRowTop };
