import React from 'react';
import i18n from 'Utils/i18n';

const variantLocationPicker = {
  en: React.lazy(() => import('./../LocationPickerUK')),
  pl: React.lazy(() => import('./../LocationPickerPL')),
};

const LocationPicker = variantLocationPicker[i18n.language];

export default LocationPicker;
