import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import i18n from 'Utils/i18n.js';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const flymbleBlue = '#4860ff';

const SubmitButtonCheckoutForms = ({
  isLoading,
  isVisible,
  handleButtonClick,
  subText,
}) => {
  return (
    isVisible && (
      <SubmitButtonCheckoutFormsWrapper>
        <Button
          className="bookNowButton"
          htmlType="submit"
          loading={isLoading}
          id="firstCheckoutPageBookButton"
          onClick={handleButtonClick}
        >
          {i18n.t('BookPaymentSection..Proceed to confirmation')}
          <FontAwesomeIcon icon={faArrowRight} className="faIconProceed" />
        </Button>
        <div className="subText">{subText && subText}</div>
      </SubmitButtonCheckoutFormsWrapper>
    )
  );
};

SubmitButtonCheckoutForms.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};

const SubmitButtonCheckoutFormsWrapper = styled.div`
  @media (max-width: 1000px) {
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 70px;
  }
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 18px;
  }

  a {
    text-decoration: none;
  }

  .bookNowButton {
    display: block;
    width: 100%;
    height: 52px;
    grid-column: 2/3;

    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 15px;
    margin-bottom: 20px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    border-radius: 3px;
    border: none;
    outline: none;

    background-color: ${flymbleBlue};
    color: white;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (min-width: 1000px) {
      margin-top: 10px;
    }
    @media (max-width: 325px) {
      font-size: 16px;
    }
  }
  .reviewButton {
    color: black;
    background-color: #d4d7e08c;
  }

  @media (min-width: 1000px) {
    padding-bottom: 20px;
    padding-top: 0px;
  }

  .subText {
    grid-column: 1/3;
    text-align: center;
    margin-right: 10px;
    font-weight: 300;
    color: black;
    font-size: 15px;
    @media (min-width: 1000px) {
      grid-column: 2/3;
    }
  }
`;

export { SubmitButtonCheckoutForms };
