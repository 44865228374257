import React from 'react';
import { ActiveWrapper, BaggageElementWrapper } from "../styles";
import { BaggageTypeRow } from "./BaggageTypeRow";
import { cx } from 'emotion';
import {
    faSuitcaseRolling,
    faShoppingBag,
} from '@fortawesome/free-solid-svg-icons';
import { DimensionsInterface } from "../../../../interfaces";


export const PersonalItemAndCabin = (
    {
        isActive,
        personalItemDimensions,
        cabinBagDimensions,
        price,
        handleOnClick,
    }: {
        isActive: boolean,
        personalItemDimensions: DimensionsInterface,
        cabinBagDimensions: DimensionsInterface,
        price: number,
        handleOnClick: Function,
    }) => {
    return (
        <ActiveWrapper>
            <div
                className={cx(
                    { borderWrapper: true },
                    { borderWrapperActive: isActive }
                )}
                onClick={() => handleOnClick()}
            >
                <BaggageElementWrapper>
                    <BaggageTypeRow
                        dimensions={personalItemDimensions}
                        title="Personal item"
                        price={price}
                        icon={faShoppingBag}
                    />
                    <div style={{ marginBottom: '10px' }} />
                    <BaggageTypeRow
                        dimensions={cabinBagDimensions}
                        title="Cabin bag"
                        price={0}
                        icon={faSuitcaseRolling}
                    />
                </BaggageElementWrapper>
            </div>
        </ActiveWrapper>
    );
};