import moment, { Moment } from 'moment';

import set from 'ramda/src/set';
import pipe from 'ramda/src/pipe';
import over from 'ramda/src/over';
import prop from 'ramda/src/prop';
import merge from 'ramda/src/merge';
import pathOr from 'ramda/src/pathOr';
import propOr from 'ramda/src/propOr';
import identical from 'ramda/src/identical';

import { IHTTPRequest } from 'helpers/configAxios';

import { parseLocation } from '../../Utils/fnplCalc';
import { KIWI, KIND_OF_TRIP } from '../../constants';

import { parseFilters } from './parseFilters';
import { ISearch, IParams, IFilters } from './types';

import * as L from './lenses';

const getReturnValue = (search: ISearch) =>
  identical(pathOr(KIND_OF_TRIP.ROUND, ['typeFlight'], search), KIND_OF_TRIP.ONE_WAY)
    ? null
    : pathOr<Moment>(moment(), ['dateTo'], search).format(KIWI.DATE_FORMAT);

export const prepareParams = (search: ISearch, filters: IFilters, maxStops: number) => {
  const handleBasicParams = pipe(
    set(L.urlLens, KIWI.URL),
    set(L.methodLens, 'get'),
  );

  const handleDirectionParams = pipe(
    set(L.flyFromLens, parseLocation(propOr('', 'flyingFrom', search)).code),
    set(L.toLens, parseLocation(propOr('', 'flyingTo', search)).code),
  );

  const handleDateParams = pipe(
    set(
      L.dateFromLens,
      pathOr<Moment>(moment(), ['dateFrom'], search).format(KIWI.DATE_FORMAT),
    ),
    set(
      L.dateToLens,
      pathOr<Moment>(moment(), ['dateFrom'], search).format(KIWI.DATE_FORMAT),
    ),
  );

  const handleReturnParams = pipe(
    set(L.returnFromLens, getReturnValue(search)),
    set(L.returnToLens, getReturnValue(search)),
  );

  const handlePassengerParams = pipe(
    set(L.adultsLens, prop('noAdults', search)),
    set(L.infantsLens, prop('noInfants', search)),
    set(L.childrenLens, prop('noChildren', search)),
  )

  const handleFlightParams = pipe(
    set(L.partnerLens, 'flymble'),
    set(L.flightTypeLens, propOr(KIND_OF_TRIP.ROUND, 'typeFlight', search)),
  )

  const handleOtherParams = pipe(
    set(L.sortLens, propOr('quality', 'sort', search)),
    set(L.limitLens, propOr(30, 'limit', search)),
    set(L.currLens, process.env.REACT_APP_VARIANT_CURRENCY),
    set(L.localeLens, process.env.REACT_APP_VARIANT_LOCALE),
  )

  const handleParams = pipe(
    handleBasicParams,
    handleDirectionParams,
    handleDateParams,
    handleReturnParams,
    handlePassengerParams,
    handleFlightParams,
    handleOtherParams,
    over(L.paramsLens, merge(parseFilters(filters))),
    set(L.maxStopoversLens, maxStops),
  );

  return handleParams({}) as IHTTPRequest<IParams>
};
