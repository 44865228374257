import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OfferSummaryFormWrapper } from './styles';
import { SignUpForm } from './SignUpForm';
import { PackageInputFields } from './PackageInputFields';
import { RedirectElement } from '../../../../components/RedirectElement';
import moment from 'moment';
import { SelectOfferCard } from './SelectOfferCard';

export const OfferSummaryForm = ({
  hotelName,
  roomType,
  setAmountNightsContext,
  setSelectedPackageContext,
  setDepartureAirportContext,
  setDepartureDateContext,
  selectedPackage,
  monthlyPriceArray,
}) => {
  const [isButtonLoadingState, setIsButtonLoadingState] = useState(false);
  const [isShowingSignUp] = useState(false);
  const [isShowingOfferCardState, setIsShowingOfferCardState] = useState(false);
  const [isDirectToCheckout, setIsDirectToCheckout] = useState(false);

  const handleSelectOffer = () => {
    window.dataLayer.push({
      event: 'select-see-more-deal-info-button',
    });
    setIsButtonLoadingState(true);
    setTimeout(() => {
      clearTimeout();
      setIsButtonLoadingState(false);
      setSelectedPackageContext(selectedPackage);
      setIsDirectToCheckout(true);

    }, 1500);
  };

  const handleSelectNights = value => {
    setAmountNightsContext(value);
  };

  const handleSelectAirport = value => {
    setDepartureAirportContext(value);
  };

  const handleSelectDepartureDate = value => {
    let date = moment(value).format('YYYY-MM-DD');
    console.log('The new deal date is...', date);
    setDepartureDateContext(date);
  };

  const handleContinue = () => {
    setIsShowingOfferCardState(true);
  };

  return (
    <>
      <RedirectElement
        destination="/packages-checkout"
        isAllowedRedirect={false && isShowingSignUp}
      />
      <PackageInputFields
        handleSelectNights={handleSelectNights}
        handleSelectDepartureDate={handleSelectDepartureDate}
        handleSelectAirport={handleSelectAirport}
        monthlyPriceArray={monthlyPriceArray}
        handleContinue={() => handleContinue()}
      />
      <OfferSummaryFormWrapper>
        <div className="selectOfferCardWrapper">
          <SelectOfferCard
            handleSelectOffer={handleSelectOffer}
            isButtonLoadingState={isButtonLoadingState}
            isVisible={!isShowingSignUp}
            selectedPackage={selectedPackage}
          />
        </div>
      </OfferSummaryFormWrapper>
      {isShowingOfferCardState && selectedPackage && (
        <OfferSummaryFormWrapper>
          <div className="selectOfferCardWrapper">
            <SelectOfferCard
              handleSelectOffer={handleSelectOffer}
              isButtonLoadingState={isButtonLoadingState}
              isVisible={!isShowingSignUp}
              selectedPackage={selectedPackage}
            />
            <SignUpForm
              hotelName={hotelName}
              dealPriceAllPassengers={777}
              isVisible={isShowingSignUp}
              roomType={roomType}
            />
          </div>
        </OfferSummaryFormWrapper>
      )}{' '}
      {isDirectToCheckout && (
        <RedirectElement
          destination="/packages-checkout"
          isAllowedRedirect={true}
        />
      )}
    </>
  );
};

OfferSummaryForm.propTypes = {
  hotelName: PropTypes.string.isRequired,
  roomType: PropTypes.string.isRequired,
  amountRoomsContext: PropTypes.number.isRequired,
  amountNightsContext: PropTypes.number.isRequired,
  setAmountNightsContext: PropTypes.func.isRequired,
  setAmountRoomsContext: PropTypes.func.isRequired,
  setSelectedPackageContext: PropTypes.func.isRequired,
  setDepartureAirportContext: PropTypes.func.isRequired,
  setDepartureDateContext: PropTypes.func.isRequired,
  selectedPackage: PropTypes.object.isRequired,
  monthlyPriceArray: PropTypes.array.isRequired,
  amountPassengersContext: PropTypes.number.isRequired,
};
