import React, { useState } from 'react';
import styled from 'react-emotion/macro';
import { ToggleElement } from './ToggleElement';
import { PassengersDropDown } from 'components/PassengersDropDown';
import { RedirectElement } from '../../../../../components/RedirectElement';

export const TypeHolidaySearch = () => {
  const [typeFlightState, setTypeFlightState] = useState('Round trip');
  // const [isAllowedRedirectState, setIsAllowedRedirectState] = useState(false);
  const [isAllowedRedirectState ] = useState(false);


  // const handlePackagesClick = () => {
  //   setIsAllowedRedirectState(true);
  // };

  const showPackages =
    window.localStorage.getItem('priceVariant') === 'VARIANT_H'
      ? true
      : (window.location.origin.includes('localhost') || window.location.origin.includes('staging'));
  console.log('Is showing packages', showPackages);

  return (
    <>
      <TypeHolidaySearchWrapper>
        <ToggleElement
          text="Flights"
          cardId="Round trip"
          activeCategory={typeFlightState}
          handleOnClick={setTypeFlightState}
          isVisible={true}
        />
        {/* <ToggleElement
          text="Packages"
          cardId="Packages"
          activeCategory={typeFlightState}
          handleOnClick={handlePackagesClick}
          infoMessage="Packages will be available from January 15th"
          isVisible={showPackages}
        /> */}
        <PassengersDropDown travelCategory="Flights" />
        <br />
        <RedirectElement
          destination="/packages"
          isAllowedRedirect={isAllowedRedirectState}
          timeOutDuration={300}
        />
      </TypeHolidaySearchWrapper>
    </>
  );
};

const TypeHolidaySearchWrapper = styled.div`
  padding-top: 18px;
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 20px; */
  padding-bottom: 18px;
  background-color: white;
`;
