import React from 'react';
import { Link } from 'react-router-dom';
import { SummaryNavigationWrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


export const SummaryNavigationLinks = ({ name }) => (
  <SummaryNavigationWrapper>
    <Link to={`/packages-deal-summary-hotel-details/${name}`}>
      <div className="summaryLink">
        Hotel details <FontAwesomeIcon icon={faArrowRight} className="faIcon" />
      </div>
    </Link>
    <Link to={`/packages-deal-summary-flight-details/${name}`}>
      <div className="summaryLink">
        Flight details{' '}
        <FontAwesomeIcon icon={faArrowRight} className="faIcon" />
      </div>
    </Link>
    <Link to={`/packages-deal-summary-map-details/${name}`}>
      <div className="summaryLink">
        Map <FontAwesomeIcon icon={faArrowRight} className="faIcon" />
      </div>
    </Link>
  </SummaryNavigationWrapper>
);
