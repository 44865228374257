import React from 'react';
import { OfferHeaderWrapper } from './styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const OfferHeader = ({ offerHeaderTitle }) => (
  <OfferHeaderWrapper>
    <Link to="/packages">
      <i>Promotions / All-inclusive</i>
    </Link>
    <b>{offerHeaderTitle}</b>
  </OfferHeaderWrapper>
);

OfferHeader.propTypes = {
  offerHeaderTitle: PropTypes.string.isRequired,
};
