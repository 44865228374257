import axios from 'axios';

const apiUrl =
  'https://us-central1-project-423459023132745232.cloudfunctions.net/emailCapture';

export const signUpUserEmailCollect = async (
  lastSearch,
  flymbleUserId,
  email,
  source = 'PRODUCTION'
) => {
  console.log(
    'lastSearch, flymbleUserId, email, source',
    lastSearch,
    flymbleUserId,
    email,
    source
  );

  try {
    let response = await axios.post(apiUrl, {
      lastSearch: lastSearch,
      flymbleUserId: flymbleUserId,
      email: email,
      source: source,
    });
    let responseBody = JSON.parse(response.data.body);
    console.log(responseBody);
  } catch (error) {
    console.error(error);
  }
};
