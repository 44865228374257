const listAirports = [
  {
    icon: 'faBuilding',
    label: 'London (all airports), United Kingdom, LON',
    key: 'LON',
  },
  { icon: 'faBuilding', label: 'Birmingham, United Kingdom, BHX', key: 'BHX' },
  { icon: 'faBuilding', label: 'Manchester, United Kingdom, MAN', key: 'MAN' },
  { icon: 'faBuilding', label: 'Liverpool, United Kingdom, LPL', key: 'LPL' },
  { icon: 'faBuilding', label: 'Bristol, United Kingdom, BRS', key: 'BRS' },
  { icon: 'faBuilding', label: 'Leeds, United Kingdom, LBA', key: 'LBA' },
  { icon: 'faBuilding', label: 'Aberdeen, United Kingdom, ABZ', key: 'ABZ' },
  { icon: 'faBuilding', label: 'Edinburgh, United Kingdom, EDI', key: 'EDI' },
  { icon: 'faBuilding', label: 'Glasgow, United Kingdom, GLA', key: 'GLA' },
  { icon: 'faBuilding', label: 'Belfast, United Kingdom, BFS', key: 'BFS' },
  {
    icon: 'faBuilding',
    label: 'Newcastle upon Tyne, United Kingdom, NCL',
    key: 'NCL',
  },
  { icon: 'faBuilding', label: 'Cardiff, United Kingdom, CWL', key: 'CWL' },

  { icon: 'faPlaneDeparture', label: 'A Coruña, Spain, LCG', key: 'LCG' },
  { icon: 'faPlaneDeparture', label: 'Aalborg, Denmark, AAL', key: 'AAL' },
  { icon: 'faPlaneDeparture', label: 'Aarhus, Denmark, AAR', key: 'AAR' },
  { icon: 'faPlaneDeparture', label: 'Aasiaat, Greenland, JEG', key: 'JEG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aba Segud, Jimma, Ethiopia, JIM',
    key: 'JIM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aba Tenna Dejazmach Yilma International, Dire Dawa, Ethiopia, DIR',
    key: 'DIR',
  },
  { icon: 'faPlaneDeparture', label: 'Abadan, Iran, ABD', key: 'ABD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Abakan International, Russia, ABA',
    key: 'ABA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abane Ramdane, Béjaïa, Algeria, BJA',
    key: 'BJA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abbotsford International, Canada, YXX',
    key: 'YXX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abdul Rachman Saleh, Malang, Indonesia, MLG',
    key: 'MLG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abdullahi Yusuf, Galkayo, Somalia, GLK',
    key: 'GLK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abeid Amani Karume International, Zanzibar, Tanzania, ZNZ',
    key: 'ZNZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abel Santamaría, Santa Clara, Cuba, SNU',
    key: 'SNU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aberdeen, United Kingdom, ABZ',
    key: 'ABZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aberdeen Regional, United States, ABR',
    key: 'ABR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abha Regional, Saudi Arabia, AHB',
    key: 'AHB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abilene Regional, United States, ABI',
    key: 'ABI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abraham Lincoln Capital, Springfield, United States, SPI',
    key: 'SPI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abruzzo, Pescara, Italy, PSR',
    key: 'PSR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Abu Dhabi International, United Arab Emirates, AUH',
    key: 'AUH',
  },
  { icon: 'faPlaneDeparture', label: 'Abu Simbel, Egypt, ABS', key: 'ABS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Acapulco International, Mexico, ACA',
    key: 'ACA',
  },
  { icon: 'faPlaneDeparture', label: 'Achutupo, Panama, ACU', key: 'ACU' },
  { icon: 'faPlaneDeparture', label: 'Adado, Somalia, AAD', key: 'AAD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Adak, Adak Island, United States, ADK',
    key: 'ADK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Adana Şakirpaşa, Turkey, ADA',
    key: 'ADA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Addis Ababa Bole International, Ethiopia, ADD',
    key: 'ADD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Addison Airport, Dallas, United States, ADS',
    key: 'ADS',
  },
  { icon: 'faPlaneDeparture', label: 'Adelaide, Australia, ADL', key: 'ADL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aden Adde International, Mogadishu, Somalia, MGQ',
    key: 'MGQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aden International Airport, Yemen, ADE',
    key: 'ADE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Adirondack Regional, Saranac Lake, United States, SLK',
    key: 'SLK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Adisumarmo International, Surakarta, Indonesia, SOC',
    key: 'SOC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Adisutjipto International, Yogyakarta, Indonesia, JOG',
    key: 'JOG',
  },
  { icon: 'faPlaneDeparture', label: 'Adıyaman, Turkey, ADF', key: 'ADF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Adnan Menderes, İzmir, Turkey, ADB',
    key: 'ADB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Adolfo Suárez Madrid–Barajas, Spain, MAD',
    key: 'MAD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aek Godang, Padangsidempuan, Indonesia, AEG',
    key: 'AEG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aeroparque Jorge Newbery, Buenos Aires, Argentina, AEP',
    key: 'AEP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Afonso Pena International, Curitiba, Brazil, CWB',
    key: 'CWB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Agadir–Al Massira, Morocco, AGA',
    key: 'AGA',
  },
  { icon: 'faPlaneDeparture', label: 'Agartala, India, IXA', key: 'IXA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Agatti Aerodrome, Agatti Island, India, AGX',
    key: 'AGX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Agen La Garenne, France, AGF',
    key: 'AGF',
  },
  { icon: 'faPlaneDeparture', label: 'Agra, India, AGR', key: 'AGR' },
  { icon: 'faPlaneDeparture', label: 'Ağrı, Turkey, AJI', key: 'AJI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aguadilla Rafael Hernandez, United States, BQN',
    key: 'BQN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aguascalientes International, Mexico, AGU',
    key: 'AGU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aguenar – Hadj Bey Akhamok, Tamanrasset, Algeria, TMR',
    key: 'TMR',
  },
  { icon: 'faPlaneDeparture', label: 'Ahe, French Polynesia, AHE', key: 'AHE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ahmad Yani International, Semarang, Indonesia, SRG',
    key: 'SRG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ahvaz International, Iran, AWZ',
    key: 'AWZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ain Arnat, Sétif, Algeria, QSF',
    key: 'QSF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ain Beida, Ouargla, Algeria, OGX',
    key: 'OGX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Air Force Base Hoedspruit, Hoedspruit, Limpopo, South Africa, HDS',
    key: 'HDS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aitutaki, Cook Islands, AIT',
    key: 'AIT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ajaccio Napoleon Bonaparte, France, AJA',
    key: 'AJA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Akanu Ibiam International, Enugu, Nigeria, ENU',
    key: 'ENU',
  },
  { icon: 'faPlaneDeparture', label: 'Akhiok, United States, AKK', key: 'AKK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Akiachak, United States, KKI',
    key: 'KKI',
  },
  { icon: 'faPlaneDeparture', label: 'Akita, Japan, AXT', key: 'AXT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aklavik/Freddie Carmichael, Canada, LAK',
    key: 'LAK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Akron–Canton, United States, CAK',
    key: 'CAK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aksu, Aksu City, China, AKU',
    key: 'AKU',
  },
  { icon: 'faPlaneDeparture', label: 'Aktau, Kazakhstan, SCO', key: 'SCO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aktion National, Preveza, Greece, PVK',
    key: 'PVK',
  },
  { icon: 'faPlaneDeparture', label: 'Aktobe, Kazakhstan, AKX', key: 'AKX' },
  { icon: 'faPlaneDeparture', label: 'Akulivik, Canada, AKV', key: 'AKV' },
  { icon: 'faPlaneDeparture', label: 'Akure, Nigeria, AKR', key: 'AKR' },
  { icon: 'faPlaneDeparture', label: 'Akureyri, Iceland, AEY', key: 'AEY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Akutan Seaplane Base, United States, KQA',
    key: 'KQA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Akwa Ibom, Uyo, Nigeria, QUO',
    key: 'QUO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al Abraq International, Al Bayda , Libya, LAQ',
    key: 'LAQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al Ain International, United Arab Emirates, AAN',
    key: 'AAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al Maktoum International, Dubai, United Arab Emirates, DWC',
    key: 'DWC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al Najaf International, Iraq, NJF',
    key: 'NJF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al Qaisumah/Hafr Al Batin, Saudi Arabia, AQI',
    key: 'AQI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al Wajh Domestic, Saudi Arabia, EJH',
    key: 'EJH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al-Ahsa International, Hofuf, Saudi Arabia, HOF',
    key: 'HOF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al-Baha Domestic, Al Bahah, Saudi Arabia, ABT',
    key: 'ABT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Al-Jawf Domestic, Al Jawf Region, Saudi Arabia, AJF',
    key: 'AJF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alakanuk, United States, AUK',
    key: 'AUK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Albano Machado, Huambo, Angola, NOV',
    key: 'NOV',
  },
  { icon: 'faPlaneDeparture', label: 'Albany, Australia, ALH', key: 'ALH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Albany International, United States, ALB',
    key: 'ALB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Albert J. Ellis, Jacksonville, United States, OAJ',
    key: 'OAJ',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Albrook  Marcos A. Gelabert  International, Panama City, Panama, PAC',
    key: 'PAC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Albuquerque International Sunport, United States, ABQ',
    key: 'ABQ',
  },
  { icon: 'faPlaneDeparture', label: 'Albury, Australia, ABX', key: 'ABX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Alcantarí International Airport, Sucre, Bolivia, SRE',
    key: 'SRE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alcides Fernández, Acandí, Colombia, ACD',
    key: 'ACD',
  },
  { icon: 'faPlaneDeparture', label: 'Aldan, Russia, ADH', key: 'ADH' },
  { icon: 'faPlaneDeparture', label: 'Alderney, Guernsey, ACI', key: 'ACI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Alejandro Velasco Astete International, Cusco, Peru, CUZ',
    key: 'CUZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ålesund Airport, Vigra, Norway, AES',
    key: 'AES',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alexandria International, United States, AEX',
    key: 'AEX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alexandroupolis International, Greece, AXD',
    key: 'AXD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alférez FAP David Figueroa Fernandini, Huánuco, Peru, HUU',
    key: 'HUU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alfonso Bonilla Aragón International, Cali, Colombia, CLO',
    key: 'CLO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alfonso López Pumarejo, Valledupar, Colombia, VUP',
    key: 'VUP',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Alfredo Vásquez Cobo International, Leticia, Amazonas, Colombia, LET',
    key: 'LET',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alghero-Fertilia, Italy, AHO',
    key: 'AHO',
  },
  { icon: 'faPlaneDeparture', label: 'Alicante–Elche, Spain, ALC', key: 'ALC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Alice Springs, Australia, ASP',
    key: 'ASP',
  },
  { icon: 'faPlaneDeparture', label: 'Allahabad, India, IXD', key: 'IXD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Allama Iqbal International, Lahore, Pakistan, LHE',
    key: 'LHE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Allegheny County Airport, Pittsburgh, United States, AGC',
    key: 'AGC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Almaty International, Kazakhstan, ALA',
    key: 'ALA',
  },
  { icon: 'faPlaneDeparture', label: 'Almería, Spain, LEI', key: 'LEI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Almirante Marcos A. Zar, Trelew, Argentina, REL',
    key: 'REL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Almirante Padilla, Riohacha, Colombia, RCH',
    key: 'RCH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alor Island, Indonesia, ARD',
    key: 'ARD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alpena County Regional, United States, APN',
    key: 'APN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alpes–Isère, Grenoble, France, GNB',
    key: 'GNB',
  },
  { icon: 'faPlaneDeparture', label: 'Alta, Norway, ALF', key: 'ALF' },
  { icon: 'faPlaneDeparture', label: 'Alta Floresta, Brazil, AFL', key: 'AFL' },
  { icon: 'faPlaneDeparture', label: 'Altamira, Brazil, ATM', key: 'ATM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Altay, Altay Prefecture, China, AAT',
    key: 'AAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alula Aba Nega, Mek ele, Ethiopia, MQX',
    key: 'MQX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Alykel, Norilsk, Russia, NSK',
    key: 'NSK',
  },
  { icon: 'faPlaneDeparture', label: 'Amami, Japan, ASJ', key: 'ASJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Amasya Merzifon, Turkey, MZH',
    key: 'MZH',
  },
  { icon: 'faPlaneDeparture', label: 'Ambler, United States, ABL', key: 'ABL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Amborovy, Mahajanga, Madagascar, MJN',
    key: 'MJN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Amboseli, Amboseli National Park, Kenya, ASV',
    key: 'ASV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Amílcar Cabral International, Sal, Cape Verde, SID',
    key: 'SID',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Amsterdam Airport Schiphol, Netherlands, AMS',
    key: 'AMS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Anaa, French Polynesia, AAA',
    key: 'AAA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Anadolu, Eskişehir, Turkey, AOE',
    key: 'AOE',
  },
  { icon: 'faPlaneDeparture', label: 'Anahim Lake, Canada, YAA', key: 'YAA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Anaktuvuk Pass, United States, AKP',
    key: 'AKP',
  },
  { icon: 'faPlaneDeparture', label: 'Anapa, Russia, AAQ', key: 'AAQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Anatom, Aneityum, Vanuatu, AUY',
    key: 'AUY',
  },
  { icon: 'faPlaneDeparture', label: 'Andahuaylas, Peru, ANS', key: 'ANS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Andizhan, Andijan, Uzbekistan, AZN',
    key: 'AZN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Andøya, Andenes, Norway, ANX',
    key: 'ANX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Andrés Sabella Gálvez International, Antofagasta, Chile, ANF',
    key: 'ANF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Angads, Oujda, Morocco, OUD',
    key: 'OUD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ängelholm–Helsingborg, Sweden, AGH',
    key: 'AGH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Angers – Loire, France, ANE',
    key: 'ANE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Anglesey, Ynys Môn, United Kingdom, VLY',
    key: 'VLY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Angoon Seaplane Base, United States, AGN',
    key: 'AGN',
  },
  { icon: 'faPlaneDeparture', label: 'Aniak, United States, ANI', key: 'ANI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Animas Air Park, Durango, United States, AMK',
    key: 'AMK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aniwa, Aniwa Island, Vanuatu, AWD',
    key: 'AWD',
  },
  { icon: 'faPlaneDeparture', label: 'Ann, Myanmar (Burma), VBA', key: 'VBA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Anqing Tianzhushan, China, AQG',
    key: 'AQG',
  },
  { icon: 'faPlaneDeparture', label: 'Anshan Teng ao, China, AOG', key: 'AOG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Anshun Huangguoshu, China, AVA',
    key: 'AVA',
  },
  { icon: 'faPlaneDeparture', label: 'Antalya, Turkey, AYT', key: 'AYT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Antonio B. Won Pat International, Hagåtña, United States, GUM',
    key: 'GUM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Antonio José de Sucre, Cumaná, Venezuela, CUM',
    key: 'CUM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Antonio Maceo, Santiago de Cuba, Cuba, SCU',
    key: 'SCU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Antonio Nariño, Pasto, Colombia, PSO',
    key: 'PSO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Antonio Rivera Rodríguez, Vieques, United States, VQS',
    key: 'VQS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Antonio Roldán Betancourt, Apartadó, Colombia, APO',
    key: 'APO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Antwerp International, City of Brussels, Belgium, ANR',
    key: 'ANR',
  },
  { icon: 'faPlaneDeparture', label: 'Anvik, United States, ANV', key: 'ANV' },
  { icon: 'faPlaneDeparture', label: 'Aomori, Japan, AOJ', key: 'AOJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Apataki, French Polynesia, APK',
    key: 'APK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Appleton International, United States, ATW',
    key: 'ATW',
  },
  { icon: 'faPlaneDeparture', label: 'Araçatuba, Brazil, ARU', key: 'ARU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arad International, Romania, ARW',
    key: 'ARW',
  },
  { icon: 'faPlaneDeparture', label: 'Araguaína, Brazil, AUX', key: 'AUX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arar Domestic, Saudi Arabia, RAE',
    key: 'RAE',
  },
  { icon: 'faPlaneDeparture', label: 'Araracuara, Colombia, ACR', key: 'ACR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arathusa Safari Lodge, South Africa, ASS',
    key: 'ASS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aratika-Nord, French Polynesia, RKA',
    key: 'RKA',
  },
  { icon: 'faPlaneDeparture', label: 'Araxá, Brazil, AAX', key: 'AAX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Araxos, Patras, Greece, GPA',
    key: 'GPA',
  },
  { icon: 'faPlaneDeparture', label: 'Arba Minch, Ethiopia, AMH', key: 'AMH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arcata–Eureka, United States, ACV',
    key: 'ACV',
  },
  { icon: 'faPlaneDeparture', label: 'Arctic Bay, Canada, YAB', key: 'YAB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arctic Village, United States, ARC',
    key: 'ARC',
  },
  { icon: 'faPlaneDeparture', label: 'Ardabil, Iran, ADU', key: 'ADU' },
  { icon: 'faPlaneDeparture', label: 'Åre Östersund, Sweden, OSD', key: 'OSD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aristides Pereira International, Boa Vista, Cape Verde, BVC',
    key: 'BVC',
  },
  { icon: 'faPlaneDeparture', label: 'Armidale, Australia, ARM', key: 'ARM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arnold Palmer Regional, Latrobe, United States, LBE',
    key: 'LBE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aropa, Kieta, Papua New Guinea, KIE',
    key: 'KIE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Arrachart, Antsiranana, Madagascar, DIE',
    key: 'DIE',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Arthur Napoleon Raymond Robinson International, Tobago, Trinidad & Tobago, TAB',
    key: 'TAB',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Arthur's Town, Bahamas, ATC",
    key: 'ATC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Arturo Michelena International, Valencia, Venezuela, VLN',
    key: 'VLN',
  },
  { icon: 'faPlaneDeparture', label: 'Arusha, Tanzania, ARK', key: 'ARK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Arutua, French Polynesia, AXR',
    key: 'AXR',
  },
  { icon: 'faPlaneDeparture', label: 'Arviat, Canada, YEK', key: 'YEK' },
  { icon: 'faPlaneDeparture', label: 'Arvidsjaur, Sweden, AJR', key: 'AJR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Asaba International, Nigeria, ABB',
    key: 'ABB',
  },
  { icon: 'faPlaneDeparture', label: 'Asahikawa, Japan, AKJ', key: 'AKJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Asheville Regional, United States, AVL',
    key: 'AVL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ashgabat International, Turkmenistan, ASB',
    key: 'ASB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Asmara International, Eritrea, ASM',
    key: 'ASM',
  },
  { icon: 'faPlaneDeparture', label: 'Asosa, Ethiopia, ASO', key: 'ASO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aspen–Pitkin County, United States, ASE',
    key: 'ASE',
  },
  { icon: 'faPlaneDeparture', label: 'Assiut, Asyut, Egypt, ATZ', key: 'ATZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Astana International, Kazakhstan, TSE',
    key: 'TSE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Astor Piazzolla International, Mar del Plata, Argentina, MDQ',
    key: 'MDQ',
  },
  { icon: 'faPlaneDeparture', label: 'Asturias, Spain, OVD', key: 'OVD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Astypalaia Island National, Greece, JTY',
    key: 'JTY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aswan International, Egypt, ASW',
    key: 'ASW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Athens International, Greece, ATH',
    key: 'ATH',
  },
  { icon: 'faPlaneDeparture', label: 'Atka, United States, AKB', key: 'AKB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Atlantic City International, United States, ACY',
    key: 'ACY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Atmautluak, United States, ATT',
    key: 'ATT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Atqasuk Edward Burnell Sr. Memorial, United States, ATK',
    key: 'ATK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Attawapiskat, Attawapiskat First Nation, Canada, YAT',
    key: 'YAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Atuona, Hiva Oa, French Polynesia, AUQ',
    key: 'AUQ',
  },
  { icon: 'faPlaneDeparture', label: 'Atyrau, Kazakhstan, GUW', key: 'GUW' },
  { icon: 'faPlaneDeparture', label: 'Auckland, New Zealand, AKL', key: 'AKL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Augusta Regional, United States, AGS',
    key: 'AGS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Augusta State, United States, AUG',
    key: 'AUG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Auguste George, Anegada, British Virgin Islands, NGD',
    key: 'NGD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Augusto C. Sandino International, Managua, Nicaragua, MGA',
    key: 'MGA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Augusto Severo International, Natal, Brazil, NAT',
    key: 'NAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Auki Gwaunaru u, Solomon Islands, AKS',
    key: 'AKS',
  },
  { icon: 'faPlaneDeparture', label: 'Aupaluk, Canada, YPJ', key: 'YPJ' },
  { icon: 'faPlaneDeparture', label: 'Aurangabad, India, IXU', key: 'IXU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Aurillac – Tronquières, France, AUR',
    key: 'AUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Austin–Bergstrom International, United States, AUS',
    key: 'AUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Avalon, Melbourne, Australia, AVV',
    key: 'AVV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Aviador Carlos Campos, San Martín de los Andes, Argentina, CPC',
    key: 'CPC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Avignon – Provence, France, AVN',
    key: 'AVN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Awang, Cotabato City, Philippines, CBO',
    key: 'CBO',
  },
  { icon: 'faPlaneDeparture', label: 'Awasa, Ethiopia, AWA', key: 'AWA' },
  { icon: 'faPlaneDeparture', label: 'Axum, Ethiopia, AXU', key: 'AXU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ayers Rock, Uluru, Australia, AYQ',
    key: 'AYQ',
  },
  { icon: 'faPlaneDeparture', label: 'Ba kelalan, Malaysia, BKM', key: 'BKM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Babullah, Ternate City, Indonesia, TTE',
    key: 'TTE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bacha Khan International, Peshawar, Pakistan, PEW',
    key: 'PEW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bachigualato Federal International, Culiacán, Mexico, CUL',
    key: 'CUL',
  },
  { icon: 'faPlaneDeparture', label: 'Baco, Jinka, Ethiopia, BCO', key: 'BCO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bacolod–Silay, Philippines, BCD',
    key: 'BCD',
  },
  { icon: 'faPlaneDeparture', label: 'Badajoz, Spain, BJZ', key: 'BJZ' },
  { icon: 'faPlaneDeparture', label: 'Bafoussam, Cameroon, BFX', key: 'BFX' },
  { icon: 'faPlaneDeparture', label: 'Bagdogra, India, IXB', key: 'IXB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Baghdad International, Iraq, BGW',
    key: 'BGW',
  },
  { icon: 'faPlaneDeparture', label: 'Bahawalpur, Pakistan, BHV', key: 'BHV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bahía Piña, Bahia Pinas, Panama, BFQ',
    key: 'BFQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bahías de Huatulco International, Mexico, HUX',
    key: 'HUX',
  },
  { icon: 'faPlaneDeparture', label: 'Bahir Dar, Ethiopia, BJR', key: 'BJR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bahrain International, Bahrain Island, Bahrain, BAH',
    key: 'BAH',
  },
  { icon: 'faPlaneDeparture', label: 'Baie-Comeau, Canada, YBC', key: 'YBC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Baikal International, Ulan-Ude, Russia, UUD',
    key: 'UUD',
  },
  { icon: 'faPlaneDeparture', label: 'Baise Bama, China, AEB', key: 'AEB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Baker Lake, Baker Lake, Nunavut, Canada, YBK',
    key: 'YBK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Balalae, Balalae Island, Solomon Islands, BAS',
    key: 'BAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Balıkesir Koca Seyit, Edremit, Turkey, EDO',
    key: 'EDO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Balkanabat, Turkmenistan, BKN',
    key: 'BKN',
  },
  { icon: 'faPlaneDeparture', label: 'Balkhash, Kazakhstan, BXH', key: 'BXH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ballina Byron Gateway, Australia, BNK',
    key: 'BNK',
  },
  { icon: 'faPlaneDeparture', label: 'Balmaceda, Chile, BBA', key: 'BBA' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Baltimore–Washington International, Washington, D.C., United States, BWI',
    key: 'BWI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bamako–Sénou International, Mali, BKO',
    key: 'BKO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ban Huoeisay, Ban Houayxay, Laos, HOE',
    key: 'HOE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bancasi, Butuan, Philippines, BXU',
    key: 'BXU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bandar Abbas International, Iran, BND',
    key: 'BND',
  },
  { icon: 'faPlaneDeparture', label: 'Bandar Lengeh, Iran, BDH', key: 'BDH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bandaranaike International, Colombo, Sri Lanka, CMB',
    key: 'CMB',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Bangoka International, Kisangani, Democratic Republic of the Congo, FKI',
    key: 'FKI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bangor International, United States, BGR',
    key: 'BGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bangui M Poko International, Central African Republic, BGF',
    key: 'BGF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Banja Luka International, Bosnia & Herzegovina, BNX',
    key: 'BNX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Banjul International, Gambia, BJL',
    key: 'BJL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Baoshan Yunrui, Baoshan, Yunnan, China, BSD',
    key: 'BSD',
  },
  { icon: 'faPlaneDeparture', label: 'Baotou, China, BAV', key: 'BAV' },
  { icon: 'faPlaneDeparture', label: 'Barcaldine, Australia, BCI', key: 'BCI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Barcelona–El Prat, Spain, BCN',
    key: 'BCN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bardufoss, Andselv, Norway, BDU',
    key: 'BDU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bari Karol Wojtyła, Italy, BRI',
    key: 'BRI',
  },
  { icon: 'faPlaneDeparture', label: 'Barimunya, Australia, BYP', key: 'BYP' },
  { icon: 'faPlaneDeparture', label: 'Barinas, Venezuela, BNS', key: 'BNS' },
  { icon: 'faPlaneDeparture', label: 'Bario, Malaysia, BBN', key: 'BBN' },
  { icon: 'faPlaneDeparture', label: 'Barisal, Bangladesh, BZL', key: 'BZL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Barkley Regional, Paducah, United States, PAH',
    key: 'PAH',
  },
  { icon: 'faPlaneDeparture', label: 'Barnaul, Russia, BAX', key: 'BAX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Barnstable Municipal, Hyannis, United States, HYA',
    key: 'HYA',
  },
  { icon: 'faPlaneDeparture', label: 'Barra, United Kingdom, BRR', key: 'BRR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Barra do Garças, Brazil, BPG',
    key: 'BPG',
  },
  { icon: 'faPlaneDeparture', label: 'Barreiras, Brazil, BRA', key: 'BRA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Barter Island LRRS, United States, BTI',
    key: 'BTI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bartolomeu Lysandro, Campos dos Goytacazes, Brazil, CAW',
    key: 'CAW',
  },
  { icon: 'faPlaneDeparture', label: 'Basco, Philippines, BSO', key: 'BSO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Basra International, Iraq, BSR',
    key: 'BSR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bastia – Poretta, France, BIA',
    key: 'BIA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bata, Equatorial Guinea, BSG',
    key: 'BSG',
  },
  { icon: 'faPlaneDeparture', label: 'Batagay, Russia, BQJ', key: 'BQJ' },
  { icon: 'faPlaneDeparture', label: 'Bathurst, Australia, BHS', key: 'BHS' },
  { icon: 'faPlaneDeparture', label: 'Bathurst, Canada, ZBF', key: 'ZBF' },
  { icon: 'faPlaneDeparture', label: 'Batman, Turkey, BAL', key: 'BAL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Baton Rouge Metropolitan, United States, BTR',
    key: 'BTR',
  },
  { icon: 'faPlaneDeparture', label: 'Båtsfjord, Norway, BJF', key: 'BJF' },
  { icon: 'faPlaneDeparture', label: 'Batticaloa, Sri Lanka, BTC', key: 'BTC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Batu Licin, Batulicin, Indonesia, BTW',
    key: 'BTW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Batumi International, Georgia, BUS',
    key: 'BUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Baubau, Bau-Bau, Indonesia, BUW',
    key: 'BUW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bauerfield International, Port Vila, Vanuatu, VLI',
    key: 'VLI',
  },
  { icon: 'faPlaneDeparture', label: 'Bauru-Arealva, Brazil, JTC', key: 'JTC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bayannur Tianjitai, China, RLK',
    key: 'RLK',
  },
  { icon: 'faPlaneDeparture', label: 'Bearskin Lake, Canada, XBE', key: 'XBE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Beauvais–Tillé, Paris, France, BVA',
    key: 'BVA',
  },
  { icon: 'faPlaneDeparture', label: 'Beaver, United States, WBQ', key: 'WBQ' },
  { icon: 'faPlaneDeparture', label: 'Bedourie, Australia, BEU', key: 'BEU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Begishevo, Nizhnekamsk, Russia, NBC',
    key: 'NBC',
  },
  { icon: 'faPlaneDeparture', label: 'Beihai Fucheng, China, BHY', key: 'BHY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Beijing Capital International, China, PEK',
    key: 'PEK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Beijing Nanyuan, China, NAY',
    key: 'NAY',
  },
  { icon: 'faPlaneDeparture', label: 'Beira, Mozambique, BEW', key: 'BEW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Beirut–Rafic Hariri International, Lebanon, BEY',
    key: 'BEY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Belaya Gora, Belaya Gora, Sakha Republic, Russia, BGN',
    key: 'BGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Belfast International, United Kingdom, BFS',
    key: 'BFS',
  },
  { icon: 'faPlaneDeparture', label: 'Belgaum, India, IXG', key: 'IXG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Belgorod International, Russia, EGO',
    key: 'EGO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Belgrade Nikola Tesla, Serbia, BEG',
    key: 'BEG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Belize City Municipal, Belize, TZA',
    key: 'TZA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bella Bella (Campbell Island), Canada, ZEL',
    key: 'ZEL',
  },
  { icon: 'faPlaneDeparture', label: 'Bella Coola, Canada, QBC', key: 'QBC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bellingham International, United States, BLI',
    key: 'BLI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bellona/Anua, Bellona Island, Solomon Islands, BNY',
    key: 'BNY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Belo Horizonte/Pampulha – Carlos Drummond de Andrade, Brazil, PLU',
    key: 'PLU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Beloyarsk, Beloyarsky, Russia, EYK',
    key: 'EYK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bemidji Regional, United States, BJI',
    key: 'BJI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ben Gurion, Tel Aviv, Israel, TLV',
    key: 'TLV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Benbecula, United Kingdom, BEB',
    key: 'BEB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bengaluru International, India, BLR',
    key: 'BLR',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Beni Mavivi, Beni, Democratic Republic of the Congo, Democratic Republic of the Congo, BNC',
    key: 'BNC',
  },
  { icon: 'faPlaneDeparture', label: 'Beni Mellal, Morocco, BEM', key: 'BEM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Benin, Benin City, Nigeria, BNI',
    key: 'BNI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Benina International, Benghazi, Libya, BEN',
    key: 'BEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Benito Salas, Neiva, Huila, Colombia, NVA',
    key: 'NVA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Benjamín Rivera Noriega, Culebra, United States, CPX',
    key: 'CPX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bentota River SPB, Sri Lanka, BJT',
    key: 'BJT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Berbera Airport, Somalia, BBO',
    key: 'BBO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Berens River, Berens River, Manitoba, Canada, YBV',
    key: 'YBV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Berezovo, Beryozovo, Khanty-Mansi Autonomous Okrug, Russia, EZV',
    key: 'EZV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bergen Airport, Flesland, Norway, BGO',
    key: 'BGO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bergerac Dordogne Périgord, France, EGC',
    key: 'EGC',
  },
  { icon: 'faPlaneDeparture', label: 'Berlevåg, Norway, BVG', key: 'BVG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Berlin Brandenburg, Germany, BER',
    key: 'BER',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Berlin Schönefeld, Germany, SXF',
    key: 'SXF',
  },
  { icon: 'faPlaneDeparture', label: 'Berlin Tegel, Germany, TXL', key: 'TXL' },
  { icon: 'faPlaneDeparture', label: 'Bern, Switzerland, BRN', key: 'BRN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bert Mooney, Butte, United States, BTM',
    key: 'BTM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Beslan, Vladikavkaz, Russia, OGZ',
    key: 'OGZ',
  },
  { icon: 'faPlaneDeparture', label: 'Bethel, United States, BET', key: 'BET' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bettles, United States, BTT',
    key: 'BTT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Béziers Cap d Agde, France, BZR',
    key: 'BZR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bhadrapur, Bhadrapur, Mechi, Nepal, BDP',
    key: 'BDP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bhamo, Myanmar (Burma), BMO',
    key: 'BMO',
  },
  { icon: 'faPlaneDeparture', label: 'Bharatpur, Nepal, BHR', key: 'BHR' },
  { icon: 'faPlaneDeparture', label: 'Bhavnagar, India, BHU', key: 'BHU' },
  { icon: 'faPlaneDeparture', label: 'Bhuj, India, BHJ', key: 'BHJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Biarritz Pays Basque, France, BIQ',
    key: 'BIQ',
  },
  { icon: 'faPlaneDeparture', label: 'Bijie Feixiong, China, BFJ', key: 'BFJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Biju Patnaik International, Bhubaneswar, India, BBI',
    key: 'BBI',
  },
  { icon: 'faPlaneDeparture', label: 'Bilbao, Spain, BIO', key: 'BIO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Billings Logan International, United States, BIL',
    key: 'BIL',
  },
  { icon: 'faPlaneDeparture', label: 'Billund, Denmark, BLL', key: 'BLL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Billy Bishop Toronto City, Canada, YTZ',
    key: 'YTZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Binaka, Gunungsitoli, Indonesia, GNS',
    key: 'GNS',
  },
  { icon: 'faPlaneDeparture', label: 'Bingöl, Turkey, BGG', key: 'BGG' },
  { icon: 'faPlaneDeparture', label: 'Bintulu, Malaysia, BTU', key: 'BTU' },
  { icon: 'faPlaneDeparture', label: 'Biratnagar, Nepal, BIR', key: 'BIR' },
  { icon: 'faPlaneDeparture', label: 'Birdsville, Australia, BVI', key: 'BVI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Birjand International, Iran, XBJ',
    key: 'XBJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Birmingham, United Kingdom, BHX',
    key: 'BHX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Birmingham-Shuttlesworth International, United States, BHM',
    key: 'BHM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Birsa Munda, Ranchi, India, IXR',
    key: 'IXR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bisha Domestic, Saudi Arabia, BHH',
    key: 'BHH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bishop International, Flint, United States, FNT',
    key: 'FNT',
  },
  { icon: 'faPlaneDeparture', label: 'Biskra, Algeria, BSK', key: 'BSK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bismarck Municipal, United States, BIS',
    key: 'BIS',
  },
  { icon: 'faPlaneDeparture', label: 'Blackall, Australia, BKQ', key: 'BKQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Blackpool, United Kingdom, BLK',
    key: 'BLK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Blaise Diagne International Airport, Dakar, Senegal, DSS',
    key: 'DSS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Blimbingsari, Banyuwangi, Indonesia, BWX',
    key: 'BWX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bloemfontein, South Africa, BFN',
    key: 'BFN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Blue Grass, Lexington, United States, LEX',
    key: 'LEX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Boa Vista International, Brazil, BVB',
    key: 'BVB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bob Baker Memorial, Kiana, United States, IAN',
    key: 'IAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bobo Dioulasso, Bobo-Dioulasso, Burkina Faso, BOY',
    key: 'BOY',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Bocas del Toro  Isla Colón  International, Bocas Town, Bocas del Toro, Panama, BOC',
    key: 'BOC',
  },
  { icon: 'faPlaneDeparture', label: 'Bodaybo, Russia, ODO', key: 'ODO' },
  { icon: 'faPlaneDeparture', label: 'Bodø, Norway, BOO', key: 'BOO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Boeing Field, Seattle, United States, BFI',
    key: 'BFI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bogashevo, Tomsk, Russia, TOF',
    key: 'TOF',
  },
  { icon: 'faPlaneDeparture', label: 'Boise, United States, BOI', key: 'BOI' },
  { icon: 'faPlaneDeparture', label: 'Bojnord, Iran, BJB', key: 'BJB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bokpyin, Myanmar (Burma), VBP',
    key: 'VBP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bologna Guglielmo Marconi, Italy, BLQ',
    key: 'BLQ',
  },
  { icon: 'faPlaneDeparture', label: 'Bonito, Brazil, BYO', key: 'BYO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bonriki International, Bairiki, Kiribati, TRW',
    key: 'TRW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Boone County, Harrison, United States, HRO',
    key: 'HRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bora Bora, French Polynesia, BOB',
    key: 'BOB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bordeaux–Mérignac, France, BOD',
    key: 'BOD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bordj Mokhtar, Bordj Badji Mokhtar, Algeria, BMW',
    key: 'BMW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Borg El Arab, Alexandria, Egypt, HBE',
    key: 'HBE',
  },
  { icon: 'faPlaneDeparture', label: 'Bornholm, Denmark, RNN', key: 'RNN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Boryspil International, Kiev, Ukraine, KBP',
    key: 'KBP',
  },
  { icon: 'faPlaneDeparture', label: 'Bosaso, Somalia, BSA', key: 'BSA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bost, Lashkargah, Afghanistan, BST',
    key: 'BST',
  },
  { icon: 'faPlaneDeparture', label: 'Bouaké, Côte d’Ivoire, BYK', key: 'BYK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Boudghene Ben Ali Lotfi, Béchar, Algeria, CBH',
    key: 'CBH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Boulder City Municipal, Las Vegas, United States, BLD',
    key: 'BLD',
  },
  { icon: 'faPlaneDeparture', label: 'Boulia, Australia, BQL', key: 'BQL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bournemouth, United Kingdom, BOH',
    key: 'BOH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Boutilimit Airport, Mauritania, OTL',
    key: 'OTL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bozeman Yellowstone International, United States, BZN',
    key: 'BZN',
  },
  { icon: 'faPlaneDeparture', label: 'Brač, Croatia, BWK', key: 'BWK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bradley International, Hartford, United States, BDL',
    key: 'BDL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brainerd Lakes Regional, United States, BRD',
    key: 'BRD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brandon Municipal, Canada, YBR',
    key: 'YBR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Branson, United States, BKG',
    key: 'BKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brasília International, Brazil, BSB',
    key: 'BSB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bratislava Airport, Slovakia, BTS',
    key: 'BTS',
  },
  { icon: 'faPlaneDeparture', label: 'Bratsk, Russia, BTK', key: 'BTK' },
  { icon: 'faPlaneDeparture', label: 'Bremen, Germany, BRE', key: 'BRE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Brescia / Montichiari Airport, Italy, VBS',
    key: 'VBS',
  },
  { icon: 'faPlaneDeparture', label: 'Brest, Belarus, BQT', key: 'BQT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Brest Bretagne, France, BES',
    key: 'BES',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brevig Mission, United States, KTS',
    key: 'KTS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brigadier Mayor César Raúl Ojeda, San Luis, Argentina, LUQ',
    key: 'LUQ',
  },
  { icon: 'faPlaneDeparture', label: 'Brindisi, Italy, BDS', key: 'BDS' },
  { icon: 'faPlaneDeparture', label: 'Brisbane, Australia, BNE', key: 'BNE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Brisbane West Wellcamp, Toowoomba, Australia, WTB',
    key: 'WTB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bristol, United Kingdom, BRS',
    key: 'BRS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brive–Souillac Airport, Brive-la-Gaillarde, France, BVE',
    key: 'BVE',
  },
  { icon: 'faPlaneDeparture', label: 'Brno–Tuřany, Czechia, BRQ', key: 'BRQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Brochet, Brochet, Manitoba, Canada, YBT',
    key: 'YBT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Broken Hill, Australia, BHQ',
    key: 'BHQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brønnøysund Airport, Brønnøy, Norway, BNN',
    key: 'BNN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Broome International, Australia, BME',
    key: 'BME',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brownsville/South Padre Island International, United States, BRO',
    key: 'BRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brunei International, Bandar Seri Begawan, Brunei, BWN',
    key: 'BWN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brunswick Golden Isles, United States, BQK',
    key: 'BQK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brussels, City of Brussels, Belgium, BRU',
    key: 'BRU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Brussels South Charleroi, City of Brussels, Belgium, CRL',
    key: 'CRL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bryansk International, Russia, BZK',
    key: 'BZK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bubung, Luwuk, Indonesia, LUW',
    key: 'LUW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Buchanan Field, Concord, United States, CCR',
    key: 'CCR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bucholz Army Airfield, Kwajalein Atoll, Marshall Islands, KWA',
    key: 'KWA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Buckland, United States, BKC',
    key: 'BKC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Budapest Ferenc Liszt International, Hungary, BUD',
    key: 'BUD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Buffalo Niagara International, United States, BUF',
    key: 'BUF',
  },
  { icon: 'faPlaneDeparture', label: 'Bugulma, Russia, UUA', key: 'UUA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bujumbura International, Burundi, BJM',
    key: 'BJM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Buka, Buka Island, Papua New Guinea, BUA',
    key: 'BUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bukhara International, Uzbekistan, BHK',
    key: 'BHK',
  },
  { icon: 'faPlaneDeparture', label: 'Bukoba, Tanzania, BKZ', key: 'BKZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Bulolo Airport, Papua New Guinea, BUL',
    key: 'BUL',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Bumba, Bumba, Democratic Republic of the Congo, Democratic Republic of the Congo, BMB',
    key: 'BMB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bundaberg, Bundaberg Region, Australia, BDB',
    key: 'BDB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bunia, Democratic Republic of the Congo, BUX',
    key: 'BUX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Buon Ma Thuot, Vietnam, BMV',
    key: 'BMV',
  },
  { icon: 'faPlaneDeparture', label: 'Burgas, Bulgaria, BOJ', key: 'BOJ' },
  { icon: 'faPlaneDeparture', label: 'Burgos Airport, Spain, RGS', key: 'RGS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Buriram, Buriram Province, Thailand, BFV',
    key: 'BFV',
  },
  { icon: 'faPlaneDeparture', label: 'Burketown, Australia, BUC', key: 'BUC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Burlington International, United States, BTV',
    key: 'BTV',
  },
  { icon: 'faPlaneDeparture', label: 'Burnie, Australia, BWT', key: 'BWT' },
  { icon: 'faPlaneDeparture', label: 'Bushehr, Iran, BUZ', key: 'BUZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Busselton Regional, Australia, BQB',
    key: 'BQB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bydgoszcz Ignacy Jan Paderewski, Poland, BZG',
    key: 'BZG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Bykovo Airport, Moscow, Russia, BKA',
    key: 'BKA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cà Mau, Ca Mau Province, Vietnam, CAH',
    key: 'CAH',
  },
  { icon: 'faPlaneDeparture', label: 'Cabinda, Angola, CAB', key: 'CAB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cabo Frio International, Brazil, CFB',
    key: 'CFB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cacique Aramare, Puerto Ayacucho, Venezuela, PYH',
    key: 'PYH',
  },
  { icon: 'faPlaneDeparture', label: 'Cacoal, Brazil, OAL', key: 'OAL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cad. FAP Guillermo del Castillo Paredes, Tarapoto, Peru, TPP',
    key: 'TPP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cadjehoun, Cotonou, Benin, COO',
    key: 'COO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Caen – Carpiquet, France, CFR',
    key: 'CFR',
  },
  { icon: 'faPlaneDeparture', label: 'Cagliari Elmas, Italy, CAG', key: 'CAG' },
  { icon: 'faPlaneDeparture', label: 'Cairns, Australia, CNS', key: 'CNS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cairo International, Egypt, CAI',
    key: 'CAI',
  },
  { icon: 'faPlaneDeparture', label: 'Calbayog, Philippines, CYP', key: 'CYP' },
  { icon: 'faPlaneDeparture', label: 'Caldas Novas, Brazil, CLV', key: 'CLV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Calgary International, Canada, YYC',
    key: 'YYC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Calicut International, Kozhikode, India, CCJ',
    key: 'CCJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Calvi – Sainte-Catherine, Calvi, Haute-Corse, France, CLY',
    key: 'CLY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cam Ranh International, Nha Trang, Vietnam, CXR',
    key: 'CXR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cambridge, United Kingdom, CBG',
    key: 'CBG',
  },
  { icon: 'faPlaneDeparture', label: 'Cambridge Bay, Canada, YCB', key: 'YCB' },
  { icon: 'faPlaneDeparture', label: 'Camiguin, Philippines, CGM', key: 'CGM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Camilo Daza International, Cúcuta, Colombia, CUC',
    key: 'CUC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Campbell River, Canada, YBL',
    key: 'YBL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Campbeltown, United Kingdom, CAL',
    key: 'CAL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Campeche International, Mexico, CPE',
    key: 'CPE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Campina Grande, Brazil, CPV',
    key: 'CPV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Campo dos Bugres, Caxias do Sul, Brazil, CXJ',
    key: 'CXJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Campo Grande International, Brazil, CGR',
    key: 'CGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Can Tho International, Vietnam, VCA',
    key: 'VCA',
  },
  { icon: 'faPlaneDeparture', label: 'Çanakkale, Turkey, CKZ', key: 'CKZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cañal Bajo Carlos Hott Siebert, Osorno, Chile, ZOS',
    key: 'ZOS',
  },
  { icon: 'faPlaneDeparture', label: 'Canberra, Australia, CBR', key: 'CBR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cancún International, Mexico, CUN',
    key: 'CUN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Canefield, Saint Joseph Parish, Dominica, DCF',
    key: 'DCF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cangyuan Washan, Cangyuan Va Autonomous County, China, CWJ',
    key: 'CWJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Canyonlands Field, Moab, United States, CNY',
    key: 'CNY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cap Skirring, Cap Skiring, Senegal, CSK',
    key: 'CSK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cap-Haïtien International, Haiti, CAP',
    key: 'CAP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cap. FAP Pedro Canga Rodríguez, Tumbes, Peru, TBP',
    key: 'TBP',
  },
  { icon: 'faPlaneDeparture', label: 'Cape Dorset, Canada, YTE', key: 'YTE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cape Girardeau Regional, United States, CGI',
    key: 'CGI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cape Lisburne LRRS, Cape Lisburne, Alaska, United States, LUR',
    key: 'LUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cape Town International, South Africa, CPT',
    key: 'CPT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Capital Region International, Lansing, United States, LAN',
    key: 'LAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Capitan Av. Salvador Ogaya G. airport, Puerto Suarez, Bolivia, PSZ',
    key: 'PSZ',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Capitán de Corbeta Carlos A. Curbelo International, Punta del Este, Uruguay, PDP',
    key: 'PDP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Capitán FAP Renán Elías Olivera, Pisco, Peru, PIO',
    key: 'PIO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Capitán FAP Víctor Montes Arias International, Talara, Peru, TYL',
    key: 'TYL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Capitan Fuentes Martinez, Porvenir, Chile, WPR',
    key: 'WPR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Capitan Oriel Lea Plaza, Tarija, Bolivia, TJA',
    key: 'TJA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Captain Aníbal Arab, Cobija, Bolivia, CIJ',
    key: 'CIJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Captain Nicolas Rojas, Potosí, Bolivia, POI',
    key: 'POI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Carajás, Parauapebas, Brazil, CKS',
    key: 'CKS',
  },
  { icon: 'faPlaneDeparture', label: 'Carcassonne, France, CCF', key: 'CCF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cardiff, United Kingdom, CWL',
    key: 'CWL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Carlos Manuel de Céspedes, Bayamo, Cuba, BYM',
    key: 'BYM',
  },
  { icon: 'faPlaneDeparture', label: 'Carnarvon, Australia, CVQ', key: 'CVQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Carrasco International, Montevideo, Uruguay, MVD',
    key: 'MVD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Carriel Sur International, Concepción, Chile, CCP',
    key: 'CCP',
  },
  { icon: 'faPlaneDeparture', label: 'Cascavel, Brazil, CAC', key: 'CAC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Casper–Natrona County International, United States, CPR',
    key: 'CPR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cassidy International, Kiritimati, Kiribati, CXI',
    key: 'CXI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Castellón–Costa Azahar, Castellón de la Plana, Spain, CDT',
    key: 'CDT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Castres–Mazamet, France, DCM',
    key: 'DCM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cat Bi International, Haiphong, Vietnam, HPH',
    key: 'HPH',
  },
  { icon: 'faPlaneDeparture', label: 'Cat Lake, Canada, YAC', key: 'YAC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Catania–Fontanarossa, Italy, CTA',
    key: 'CTA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cataratas del Iguazú International, Puerto Iguazú, Argentina, IGR',
    key: 'IGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Catarman National, Catarman, Northern Samar, Philippines, CRM',
    key: 'CRM',
  },
  { icon: 'faPlaneDeparture', label: 'Catumbela, Angola, CBT', key: 'CBT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cauayan, Cauayan, Isabela, Philippines, CYZ',
    key: 'CYZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Caucayá, Puerto Leguízamo, Colombia, LQM',
    key: 'LQM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Caycuma, Zonguldak, Turkey, ONQ',
    key: 'ONQ',
  },
  { icon: 'faPlaneDeparture', label: 'Caye Caulker, Belize, CUK', key: 'CUK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cayenne – Félix Eboué, France, CAY',
    key: 'CAY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cedar City Regional, United States, CDC',
    key: 'CDC',
  },
  { icon: 'faPlaneDeparture', label: 'Ceduna, Australia, CED', key: 'CED' },
  {
    icon: 'faPlaneDeparture',
    label: 'Centennial Airport, Denver, United States, APA',
    key: 'APA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Central, United States, CEM',
    key: 'CEM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Central Illinois Regional, Bloomington, United States, BMI',
    key: 'BMI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Central Nebraska Regional, Grand Island, United States, GRI',
    key: 'GRI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Central Wisconsin, Wausau, United States, CWA',
    key: 'CWA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'César Gaviria Trujillo, Inírida, Guainía, Colombia, PDA',
    key: 'PDA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cesária Évora, São Vicente, Cape Verde, VXE',
    key: 'VXE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'CFB Bagotville, Saguenay, Canada, YBG',
    key: 'YBG',
  },
  { icon: 'faPlaneDeparture', label: 'CFB Comox, Canada, YQQ', key: 'YQQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'CFB Goose Bay, Happy Valley-Goose Bay, Canada, YYR',
    key: 'YYR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chacalluta International, Arica, Chile, ARI',
    key: 'ARI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chadron Municipal, United States, CDR',
    key: 'CDR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chalkyitsik, United States, CIK',
    key: 'CIK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Châlons Vatry, Paris, France, XCR',
    key: 'XCR',
  },
  { icon: 'faPlaneDeparture', label: 'Chambéry, France, CMF', key: 'CMF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chamonate, Copiapó, Chile, CPO',
    key: 'CPO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chandigarh International, India, IXC',
    key: 'IXC',
  },
  { icon: 'faPlaneDeparture', label: 'Changbaishan, China, NBS', key: 'NBS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Changchun Longjia International, China, CGQ',
    key: 'CGQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Changde Taohuayuan, China, CGD',
    key: 'CGD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Changsha Huanghua International, China, CSX',
    key: 'CSX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Changuinola  Capitán Manuel Niño  International, Panama, CHX',
    key: 'CHX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Changzhi Wangcun, China, CIH',
    key: 'CIH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Changzhou Benniu, China, CZX',
    key: 'CZX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chania International, Greece, CHQ',
    key: 'CHQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chaoyang, Chaoyang, Liaoning, China, CHG',
    key: 'CHG',
  },
  { icon: 'faPlaneDeparture', label: 'Chapecó, Brazil, XAP', key: 'XAP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Charles B. Wheeler Downtown, Kansas City, United States, MKC',
    key: 'MKC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Charles de Gaulle Airport, Paris, France, CDG',
    key: 'CDG',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Charles Kirkconnell International, Cayman Brac, Cayman Islands, CYB',
    key: 'CYB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Charles M. Schulz–Sonoma County, Santa Rosa, United States, STS',
    key: 'STS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Charleston International, United States, CHS',
    key: 'CHS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Charleville, Australia, CTL',
    key: 'CTL',
  },
  { icon: 'faPlaneDeparture', label: 'Charlo, Canada, YCL', key: 'YCL' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Charlotte Amalie Harbor Seaplane Base, Saint Thomas, United States, SPB',
    key: 'SPB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Charlotte Douglas International, United States, CLT',
    key: 'CLT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Charlottesville–Albemarle, United States, CHO',
    key: 'CHO',
  },
  { icon: 'faPlaneDeparture', label: 'Charlottetown, Canada, YYG', key: 'YYG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chattanooga Metropolitan, United States, CHA',
    key: 'CHA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chaudhary Charan Singh International, Lucknow, India, LKO',
    key: 'LKO',
  },
  { icon: 'faPlaneDeparture', label: 'Cheboksary, Russia, CSY', key: 'CSY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cheddi Jagan International, Georgetown, Guyana, GEO',
    key: 'GEO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chefornak, United States, CYF',
    key: 'CYF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cheikh Larbi Tebessi, Tébessa, Algeria, TEE',
    key: 'TEE',
  },
  { icon: 'faPlaneDeparture', label: 'Chelyabinsk, Russia, CEK', key: 'CEK' },
  { icon: 'faPlaneDeparture', label: 'Chengde Puning, China, CDE', key: 'CDE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chengdu Shuangliu International, China, CTU',
    key: 'CTU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chennai International, India, MAA',
    key: 'MAA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cheongju International, South Korea, CJJ',
    key: 'CJJ',
  },
  { icon: 'faPlaneDeparture', label: 'Cherepovets, Russia, CEE', key: 'CEE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cherif Al Idrissi, Al Hoceima, Morocco, AHU',
    key: 'AHU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chernivtsi International, Ukraine, CWC',
    key: 'CWC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cherry Capital, Traverse City, United States, TVC',
    key: 'TVC',
  },
  { icon: 'faPlaneDeparture', label: 'Chersky, Russia, CYX', key: 'CYX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chesterfield Inlet, Chesterfield Inlet, Nunavut, Canada, YCS',
    key: 'YCS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chetumal International, Mexico, CTM',
    key: 'CTM',
  },
  { icon: 'faPlaneDeparture', label: 'Chevak, United States, VAK', key: 'VAK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chevery, Chevery, Quebec, Canada, YHR',
    key: 'YHR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cheyenne Regional, United States, CYS',
    key: 'CYS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chhatrapati Shivaji International, Mumbai, India, BOM',
    key: 'BOM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chiang Mai International, Thailand, CNX',
    key: 'CNX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chiang Rai International, Chiang Rai Province, Thailand, CEI',
    key: 'CEI',
  },
  { icon: 'faPlaneDeparture', label: 'Chiayi, Taiwan, CYI', key: 'CYI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chibougamau/Chapais, Canada, YMT',
    key: 'YMT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chicago Midway International, United States, MDW',
    key: 'MDW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chicago Rockford International, United States, RFD',
    key: 'RFD',
  },
  { icon: 'faPlaneDeparture', label: 'Chifeng Yulong, China, CIF', key: 'CIF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chignik Fisheries, United States, KCG',
    key: 'KCG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chignik Lagoon, United States, KCL',
    key: 'KCL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chignik Lake, United States, KCQ',
    key: 'KCQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chihuahua International, Mexico, CUU',
    key: 'CUU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chileka International, Blantyre, Malawi, BLZ',
    key: 'BLZ',
  },
  { icon: 'faPlaneDeparture', label: 'Chimoio, Mozambique, VPY', key: 'VPY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chimore, Chimoré, Cochabamba, Bolivia, CCA',
    key: 'CCA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'China Bay, Trincomalee, Sri Lanka, TRR',
    key: 'TRR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chinggis Khaan International, Ulaanbaatar, Mongolia, ULN',
    key: 'ULN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chingozi, Tete, Mozambique, TET',
    key: 'TET',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chios Island National, Greece, JKH',
    key: 'JKH',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Chippewa County International, Sault Sainte Marie, United States, CIU',
    key: 'CIU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chippewa Valley Regional, Eau Claire, United States, EAU',
    key: 'EAU',
  },
  { icon: 'faPlaneDeparture', label: 'Chisasibi, Canada, YKU', key: 'YKU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chișinău International, Moldova, KIV',
    key: 'KIV',
  },
  { icon: 'faPlaneDeparture', label: 'Chitral, Pakistan, CJL', key: 'CJL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chitre Alonso Valderrama, Chitré, Panama, CTD',
    key: 'CTD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chizhou Jiuhuashan, China, JUH',
    key: 'JUH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chlef International, Algeria, CFK',
    key: 'CFK',
  },
  { icon: 'faPlaneDeparture', label: 'Choibalsan, Mongolia, COQ', key: 'COQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Choiseul Bay, Solomon Islands, CHY',
    key: 'CHY',
  },
  { icon: 'faPlaneDeparture', label: 'Chokurdakh, Russia, CKH', key: 'CKH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Chongqing Jiangbei International, China, CKG',
    key: 'CKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Christchurch International, New Zealand, CHC',
    key: 'CHC',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Christiansted Harbor Seaplane Base, Saint Croix, United States, SSB',
    key: 'SSB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Christmas Island, Shire of, Christmas Island, XCH',
    key: 'XCH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chu Lai International, Vietnam, VCL',
    key: 'VCL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chuathbaluk, United States, CHU',
    key: 'CHU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chubu Centrair International, Nagoya, Japan, NGO',
    key: 'NGO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chulman Neryungri Airport, Russia, NER',
    key: 'NER',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chumphon, Chumphon Province, Thailand, CJM',
    key: 'CJM',
  },
  { icon: 'faPlaneDeparture', label: 'Churchill, Canada, YYQ', key: 'YYQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Churchill Falls, Canada, ZUM',
    key: 'ZUM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Chuuk International, Chuuk State, Micronesia, TKK',
    key: 'TKK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ciampino–G. B. Pastine International, Rome, Italy, CIA',
    key: 'CIA',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Cibao International, Santiago de los Caballeros, Dominican Republic, STI',
    key: 'STI',
  },
  { icon: 'faPlaneDeparture', label: 'Cicia, Fiji, ICI', key: 'ICI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cincinnati Municipal Lunken, United States, LUK',
    key: 'LUK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cincinnati/Northern Kentucky International, United States, CVG',
    key: 'CVG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Circle City, United States, IRC',
    key: 'IRC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'City of Derry, United Kingdom, LDY',
    key: 'LDY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ciudad de Catamayo, Loja, Ecuador, LOH',
    key: 'LOH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ciudad del Carmen International, Mexico, CME',
    key: 'CME',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ciudad Juárez International, Mexico, CJS',
    key: 'CJS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ciudad Obregón International, Mexico, CEN',
    key: 'CEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ciudad Victoria International, Mexico, CVM',
    key: 'CVM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Clarence Valley Regional, Grafton, Australia, GFN',
    key: 'GFN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Clark International, Angeles, Philippines, CRK',
    key: 'CRK',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Clarks Point, Clark's Point, United States, CLP",
    key: 'CLP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Clayton J. Lloyd International, Anguilla, Anguilla, AXA',
    key: 'AXA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Clermont-Ferrand Auvergne, France, CFE',
    key: 'CFE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cleveland Burke Lakefront, United States, BKL',
    key: 'BKL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cleveland Hopkins International, United States, CLE',
    key: 'CLE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Clinton National, Little Rock, United States, LIT',
    key: 'LIT',
  },
  { icon: 'faPlaneDeparture', label: 'Cloncurry, Australia, CNJ', key: 'CNJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cluj International, Cluj-Napoca, Romania, CLJ',
    key: 'CLJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Clyde River, Clyde River, Nunavut, Canada, YCY',
    key: 'YCY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coastal Carolina Regional, New Bern, United States, EWN',
    key: 'EWN',
  },
  { icon: 'faPlaneDeparture', label: 'Cobar, Australia, CAZ', key: 'CAZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cochin International, Kochi, India, COK',
    key: 'COK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cocos (Keeling) Islands, Home Island, Cocos (Keeling) Islands, CCK',
    key: 'CCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coffman Cove Sea Plane Base, United States, KCC',
    key: 'KCC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coffs Harbour, Australia, CFS',
    key: 'CFS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coimbatore International, India, CJB',
    key: 'CJB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cold Bay, United States, CDB',
    key: 'CDB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coldfoot, United States, CXF',
    key: 'CXF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coleman A. Young Municipal Airport, Detroit, United States, DET',
    key: 'DET',
  },
  { icon: 'faPlaneDeparture', label: 'Colima, Mexico, CLQ', key: 'CLQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cologne Bonn Airport, Germany, CGN',
    key: 'CGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Colonel Carlos Concha Torres, Esmeraldas, Ecuador, ESM',
    key: 'ESM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Colonel Hill, Crooked Island, Bahamas, CRI',
    key: 'CRI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Colorado Springs, United States, COS',
    key: 'COS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Columbia Metropolitan, United States, CAE',
    key: 'CAE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Columbia Regional, United States, COU',
    key: 'COU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Columbus Metropolitan, United States, CSG',
    key: 'CSG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Colville Lake/Tommy Kochon Aerodrome, Canada, YCK',
    key: 'YCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Comandante Armando Tola International, El Calafate, Argentina, FTE',
    key: 'FTE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Comandante Espora, Bahía Blanca, Argentina, BHI',
    key: 'BHI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Comandante FAP Germán Arias Graziani, Huaraz, Peru, ATA',
    key: 'ATA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Combolcha, Dessie, Ethiopia, DSE',
    key: 'DSE',
  },
  { icon: 'faPlaneDeparture', label: 'Comiso, Italy, CIY', key: 'CIY' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Comodoro Arturo Merino Benítez International, Santiago de Chile, Chile, SCL',
    key: 'SCL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Con Dao, Côn Sơn Island, Vietnam, VCS',
    key: 'VCS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Conakry International, Guinea, CKY',
    key: 'CKY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Concord Regional, United States, USA',
    key: 'USA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Condoto Mandinga, Colombia, COG',
    key: 'COG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Contadora, Contadora Island, Panama, OTD',
    key: 'OTD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coober Pedy, Australia, CPD',
    key: 'CPD',
  },
  { icon: 'faPlaneDeparture', label: 'Cooktown, Australia, CTN', key: 'CTN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cooma–Snowy Mountains, Australia, OOM',
    key: 'OOM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coondewanna, Australia, CJF',
    key: 'CJF',
  },
  { icon: 'faPlaneDeparture', label: 'Copenhagen, Denmark, CPH', key: 'CPH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Copernicus Airport Wrocław, Poland, WRO',
    key: 'WRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coposa, Tarapacá, Colombia, TCD',
    key: 'TCD',
  },
  { icon: 'faPlaneDeparture', label: 'Coral Harbour, Canada, YZS', key: 'YZS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Corfu International, Greece, CFU',
    key: 'CFU',
  },
  { icon: 'faPlaneDeparture', label: 'Cork, Ireland, ORK', key: 'ORK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Cornwall Airport Newquay, United Kingdom, NQY',
    key: 'NQY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coronel FAP Alfredo Mendívil Duarte, Ayacucho, Peru, AYP',
    key: 'AYP',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Coronel FAP Carlos Ciriani Santa Rosa International, Tacna, Peru, TCQ',
    key: 'TCQ',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Coronel FAP Francisco Secada Vignetta International, Iquitos, Peru, IQT',
    key: 'IQT',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Coronel Felipe Varela International, San Fernando del Valle de Catamarca, Argentina, CTC',
    key: 'CTC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Corozal, Corozal Town, Belize, CZH',
    key: 'CZH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Corpus Christi International, United States, CRP',
    key: 'CRP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cortez Municipal, United States, CEZ',
    key: 'CEZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Corumbá International, Brazil, CMG',
    key: 'CMG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Corvo, Corvo Island, Portugal, CVU',
    key: 'CVU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Costa Esmeralda, Rivas, Nicaragua, ECI',
    key: 'ECI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cotopaxi International, Latacunga, Ecuador, LTX',
    key: 'LTX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Coventry, United Kingdom, CVT',
    key: 'CVT',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Cox's Bazar, Bangladesh, CXB",
    key: 'CXB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cozumel International, Mexico, CZM',
    key: 'CZM',
  },
  { icon: 'faPlaneDeparture', label: 'Craig Cove, Vanuatu, CCV', key: 'CCV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Craig Sea Plane Base, United States, CGA',
    key: 'CGA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Craiova International, Romania, CRA',
    key: 'CRA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cranbrook/Canadian Rockies International, Canada, YXC',
    key: 'YXC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Crater Lake–Klamath Regional, Klamath Falls, United States, LMT',
    key: 'LMT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cristiano Ronaldo International, Madeira, Portugal, FNC',
    key: 'FNC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Crooked Creek, United States, CKD',
    key: 'CKD',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Cross Lake (Charlie Sinclair Memorial), Cross Lake, Manitoba, Canada, YCR',
    key: 'YCR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Crotone Airport, Italy, CRV',
    key: 'CRV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cruz Jericoacoara, Cruz, Ceará, Brazil, JJD',
    key: 'JJD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cruzeiro do Sul International, Brazil, CZS',
    key: 'CZS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cuddapah, Kadapa, India, CDP',
    key: 'CDP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cuneo International, Italy, CUF',
    key: 'CUF',
  },
  { icon: 'faPlaneDeparture', label: 'Cunnamulla, Australia, CMA', key: 'CMA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Curaçao International, Willemstad, Curaçao, CUR',
    key: 'CUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cut Nyak Dhien, Meulaboh, Indonesia, MEQ',
    key: 'MEQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Cyril E. King, Saint Thomas, United States, STT',
    key: 'STT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Da Nang International, Vietnam, DAD',
    key: 'DAD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Daegu International, South Korea, TAE',
    key: 'TAE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dakhla, Unknown Region, VIL',
    key: 'VIL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dala, Borlänge, Sweden, BLE',
    key: 'BLE',
  },
  { icon: 'faPlaneDeparture', label: 'Dalaman, Turkey, DLM', key: 'DLM' },
  { icon: 'faPlaneDeparture', label: 'Dalanzadgad, Mongolia, DLZ', key: 'DLZ' },
  { icon: 'faPlaneDeparture', label: 'Dalbandin, Pakistan, DBA', key: 'DBA' },
  { icon: 'faPlaneDeparture', label: 'Dali, China, DLU', key: 'DLU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dalian Zhoushuizi International, China, DLC',
    key: 'DLC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dallas Love Field, United States, DAL',
    key: 'DAL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dallas/Fort Worth International, United States, DFW',
    key: 'DFW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Damascus International, Syria, DAM',
    key: 'DAM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Damazin, Roseires, Sudan, RSS',
    key: 'RSS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dandong Langtou, China, DDG',
    key: 'DDG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dane County Regional, Madison, United States, MSN',
    key: 'MSN',
  },
  { icon: 'faPlaneDeparture', label: 'Dangriga, Belize, DGA', key: 'DGA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Daniel K. Inouye International, Honolulu, United States, HNL',
    key: 'HNL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Daniel Oduber Quirós International, Liberia, Costa Rica, LIR',
    key: 'LIR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Daniel Z. Romualdez, Tacloban, Philippines, TAC',
    key: 'TAC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Daocheng Yading, Daocheng County, China, DCY',
    key: 'DCY',
  },
  { icon: 'faPlaneDeparture', label: 'Daqing Sartu, China, DQA', key: 'DQA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Daru, Papua New Guinea, DAU',
    key: 'DAU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Darwin International, Australia, DRW',
    key: 'DRW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dasht-e Naz, Sari, Iran, SRY',
    key: 'SRY',
  },
  { icon: 'faPlaneDeparture', label: 'Daşoguz, Turkmenistan, TAZ', key: 'TAZ' },
  { icon: 'faPlaneDeparture', label: 'Datong Yungang, China, DAT', key: 'DAT' },
  {
    icon: 'faPlaneDeparture',
    label: 'David the Builder Kutaisi International, Georgia, KUT',
    key: 'KUT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dawadmi Domestic, Saudi Arabia, DWD',
    key: 'DWD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dawei, Dawei Township, Myanmar (Burma), TVY',
    key: 'TVY',
  },
  { icon: 'faPlaneDeparture', label: 'Dawson City, Canada, YDA', key: 'YDA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dawson Community, Glendive, United States, GDV',
    key: 'GDV',
  },
  { icon: 'faPlaneDeparture', label: 'Dawson Creek, Canada, YDQ', key: 'YDQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dayrestan, Qeshm, Iran, GSM',
    key: 'GSM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dayton International, United States, DAY',
    key: 'DAY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Daytona Beach International, United States, DAB',
    key: 'DAB',
  },
  { icon: 'faPlaneDeparture', label: 'Dazhou Heshi, China, DAX', key: 'DAX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Deadhorse, Prudhoe Bay, United States, SCC',
    key: 'SCC',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Deadman's Cay, Deadman's Cay Settlement, Bahamas, LGI",
    key: 'LGI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Deauville – Normandie, France, DOL',
    key: 'DOL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Debrecen International, Hungary, DEB',
    key: 'DEB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Decatur, United States, DEC',
    key: 'DEC',
  },
  { icon: 'faPlaneDeparture', label: 'Deer Lake, Canada, YVZ', key: 'YVZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Deer Lake Regional, Canada, YDF',
    key: 'YDF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Deering, United States, DRG',
    key: 'DRG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dehong Mangshi, Mang City, China, LUM',
    key: 'LUM',
  },
  { icon: 'faPlaneDeparture', label: 'Dehradun, India, DED', key: 'DED' },
  {
    icon: 'faPlaneDeparture',
    label: 'DeKalb–Peachtree, Atlanta, United States, PDK',
    key: 'PDK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Del Bajío International, León, Mexico, BJX',
    key: 'BJX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Del Norte County, Crescent City, United States, CEC',
    key: 'CEC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Delta County, Escanaba, United States, ESC',
    key: 'ESC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dembi Dolo, Dembidolo, Ethiopia, DEM',
    key: 'DEM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Denizli Çardak, Turkey, DNZ',
    key: 'DNZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Denver International, United States, DEN',
    key: 'DEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Depati Amir, Pangkal Pinang, Indonesia, PGK',
    key: 'PGK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Depati Parbo, Kerinci, Indonesia, KRC',
    key: 'KRC',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Deputado Luís Eduardo Magalhães International, Salvador, Brazil, SSA',
    key: 'SSA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Deputatsky, Deputatsky, Sakha Republic, Russia, DPT',
    key: 'DPT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dera Ghazi Khan International, Pakistan, DEA',
    key: 'DEA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Des Moines International, United States, DSM',
    key: 'DSM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Destin–Fort Walton Beach, United States, VPS',
    key: 'VPS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Detroit Metropolitan, United States, DTW',
    key: 'DTW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Devi Ahilya Bai Holkar, Indore, India, IDR',
    key: 'IDR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Devils Lake Regional, United States, DVL',
    key: 'DVL',
  },
  { icon: 'faPlaneDeparture', label: 'Devonport, Australia, DPO', key: 'DPO' },
  { icon: 'faPlaneDeparture', label: 'Dhangadhi, Nepal, DHI', key: 'DHI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dharamsala airport, Dharamshala, India, DHM',
    key: 'DHM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dharavandhoo, Maldives, DRV',
    key: 'DRV',
  },
  { icon: 'faPlaneDeparture', label: 'Dibrugarh, India, DIB', key: 'DIB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dickinson Theodore Roosevelt Regional, United States, DIK',
    key: 'DIK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Diego Aracena International, Iquique, Chile, IQQ',
    key: 'IQQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dien Bien Phu, Điện Biên Phủ, Vietnam, DIN',
    key: 'DIN',
  },
  { icon: 'faPlaneDeparture', label: 'Dikson, Russia, DKS', key: 'DKS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dillingham, United States, DLG',
    key: 'DLG',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Dillon's Bay, Dillons Bay, Vanuatu, DLY",
    key: 'DLY',
  },
  { icon: 'faPlaneDeparture', label: 'Dimapur, India, DMU', key: 'DMU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dinard–Pleurtuit–Saint-Malo, France, DNR',
    key: 'DNR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Diomício Freitas, Criciúma, Brazil, CCM',
    key: 'CCM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Diori Hamani International, Niamey, Niger, NIM',
    key: 'NIM',
  },
  { icon: 'faPlaneDeparture', label: 'Dipolog, Philippines, DPL', key: 'DPL' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Diqing Shangri-La, Diqing Tibetan Autonomous Prefecture, China, DIG',
    key: 'DIG',
  },
  { icon: 'faPlaneDeparture', label: 'Diu, India, DIU', key: 'DIU' },
  { icon: 'faPlaneDeparture', label: 'Divinópolis, Brazil, DIQ', key: 'DIQ' },
  { icon: 'faPlaneDeparture', label: 'Diyarbakır, Turkey, DIY', key: 'DIY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Djerba–Zarzis International, Tunisia, DJE',
    key: 'DJE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Djibouti–Ambouli International, Balbala, Djibouti, JIB',
    key: 'JIB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dnipropetrovsk International, Ukraine, DNK',
    key: 'DNK',
  },
  { icon: 'faPlaneDeparture', label: 'Dobo, Indonesia, DOB', key: 'DOB' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Doctor Fernando Piragine Niveyro International, Corrientes, Argentina, CNQ',
    key: 'CNQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dodge City Regional, United States, DDC',
    key: 'DDC',
  },
  { icon: 'faPlaneDeparture', label: 'Dole–Jura, France, DLE', key: 'DLE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Domine Eduard Osok, Sorong, Indonesia, SOQ',
    key: 'SOQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Domingo Faustino Sarmiento, San Juan, Argentina, UAQ',
    key: 'UAQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Domodedovo International, Moscow, Russia, DME',
    key: 'DME',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Don Mueang International, Bangkok, Thailand, DMK',
    key: 'DMK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Doncaster Sheffield, United Kingdom, DSA',
    key: 'DSA',
  },
  { icon: 'faPlaneDeparture', label: 'Donegal, Ireland, CFN', key: 'CFN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dong Hoi, Đồng Hới, Vietnam, VDH',
    key: 'VDH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dong Tac, Tuy Hòa, Vietnam, TBB',
    key: 'TBB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dongying Shengli, China, DOY',
    key: 'DOY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Donoi, Uliastai, Mongolia, ULZ',
    key: 'ULZ',
  },
  { icon: 'faPlaneDeparture', label: 'Doomadgee, Australia, DMD', key: 'DMD' },
  { icon: 'faPlaneDeparture', label: 'Dortmund, Germany, DTM', key: 'DTM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dothan Regional, United States, DHN',
    key: 'DHN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Douala International, Cameroon, DLA',
    key: 'DLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Douglas–Charles, Saint Joseph Parish, Dominica, DOM',
    key: 'DOM',
  },
  { icon: 'faPlaneDeparture', label: 'Dourados, Brazil, DOU', key: 'DOU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dr. Babasaheb Ambedkar International, Nagpur, India, NAG',
    key: 'NAG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Drake Bay, Bahía Drake, Costa Rica, DRK',
    key: 'DRK',
  },
  { icon: 'faPlaneDeparture', label: 'Dresden, Germany, DRS', key: 'DRS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dryden Regional, Canada, YHD',
    key: 'YHD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dubai International, United Arab Emirates, DXB',
    key: 'DXB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dubbo City Regional, Australia, DBO',
    key: 'DBO',
  },
  { icon: 'faPlaneDeparture', label: 'Dublin, Ireland, DUB', key: 'DUB' },
  {
    icon: 'faPlaneDeparture',
    label: 'DuBois Regional, United States, DUJ',
    key: 'DUJ',
  },
  { icon: 'faPlaneDeparture', label: 'Dubrovnik, Croatia, DBV', key: 'DBV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Dubuque Regional, United States, DBQ',
    key: 'DBQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Duluth International, United States, DLH',
    key: 'DLH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dundee, United Kingdom, DND',
    key: 'DND',
  },
  { icon: 'faPlaneDeparture', label: 'Dundo, Angola, DUE', key: 'DUE' },
  { icon: 'faPlaneDeparture', label: 'Dunedin, New Zealand, DUD', key: 'DUD' },
  { icon: 'faPlaneDeparture', label: 'Dunhuang, China, DNH', key: 'DNH' },
  { icon: 'faPlaneDeparture', label: 'Duqm Jaaluni, Oman, DQM', key: 'DQM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Durango International, Mexico, DGO',
    key: 'DGO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Durango–La Plata County, United States, DRO',
    key: 'DRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Durban International, South Africa, DUR',
    key: 'DUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Durham Tees Valley, Durham, England, United Kingdom, MME',
    key: 'MME',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dushanbe International, Tajikistan, DYU',
    key: 'DYU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Düsseldorf International Airport, Germany, DUS',
    key: 'DUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Dzaoudzi–Pamandzi International, Mamoudzou, France, DZA',
    key: 'DZA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'E. T. Joshua, Saint Vincent, St. Vincent & Grenadines, SVD',
    key: 'SVD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eagle County Regional, Vail, United States, EGE',
    key: 'EGE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'East Kimberley Regional, Kununurra, Australia, KNX',
    key: 'KNX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'East London, South Africa, ELS',
    key: 'ELS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'East Midlands, Nottingham, United Kingdom, EMA',
    key: 'EMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'East Texas Regional, Longview, United States, GGG',
    key: 'GGG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eastern Oregon Regional, Pendleton, United States, PDT',
    key: 'PDT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Easterwood, College Station, United States, CLL',
    key: 'CLL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eastmain River, Eastmain, Quebec, Canada, ZEM',
    key: 'ZEM',
  },
  { icon: 'faPlaneDeparture', label: 'Eday, United Kingdom, EOI', key: 'EOI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Edinburgh, United Kingdom, EDI',
    key: 'EDI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Edmonton International, Canada, YEG',
    key: 'YEG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Edna Bay Sea Plane Base, United States, EDA',
    key: 'EDA',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Eduardo Falla Solano Airport, San Vicente Del Caguan, Colombia, SVI',
    key: 'SVI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eduardo Gomes International, Manaus, Brazil, MAO',
    key: 'MAO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Edward Bodden Airfield, Little Cayman, Cayman Islands, LYB',
    key: 'LYB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Edward G. Pitka Sr., Galena, United States, GAL',
    key: 'GAL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Edward River, Pormpuraaw, Australia, EDR',
    key: 'EDR',
  },
  { icon: 'faPlaneDeparture', label: 'Eek, United States, EEK', key: 'EEK' },
  { icon: 'faPlaneDeparture', label: 'Egegik, United States, EGX', key: 'EGX' },
  { icon: 'faPlaneDeparture', label: 'Egilsstaðir, Iceland, EGS', key: 'EGS' },
  { icon: 'faPlaneDeparture', label: 'Eilat, Israel, ETH', key: 'ETH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Eindhoven, Netherlands, EIN',
    key: 'EIN',
  },
  { icon: 'faPlaneDeparture', label: 'Ekwok, United States, KEK', key: 'KEK' },
  {
    icon: 'faPlaneDeparture',
    label: 'El Alcaraván, Yopal, Colombia, EYP',
    key: 'EYP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Alto International, La Paz, Bolivia, LPB',
    key: 'LPB',
  },
  { icon: 'faPlaneDeparture', label: 'El Bagre, Colombia, EBG', key: 'EBG' },
  {
    icon: 'faPlaneDeparture',
    label: 'El Caraño, Quibdó, Colombia, UIB',
    key: 'UIB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Dorado International, Bogotá, Colombia, BOG',
    key: 'BOG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Edén International, Armenia, Colombia, AXM',
    key: 'AXM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Embrujo, Providencia Island, Colombia, PVA',
    key: 'PVA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Fasher, Al-Fashir, Sudan, ELF',
    key: 'ELF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Golea, El Goléa, Algeria, ELG',
    key: 'ELG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Hierro, Valverde, Spain, VDE',
    key: 'VDE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Lencero, Xalapa, Mexico, JAL',
    key: 'JAL',
  },
  { icon: 'faPlaneDeparture', label: 'El Loa, Calama, Chile, CJC', key: 'CJC' },
  {
    icon: 'faPlaneDeparture',
    label: 'El Palomar, Buenos Aires, Argentina, EPA',
    key: 'EPA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Paso International, United States, ELP',
    key: 'ELP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Tajín National, Poza Rica, Mexico, PAZ',
    key: 'PAZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Tari International, Kupang, Indonesia, KOE',
    key: 'KOE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Tehuelche, Puerto Madryn, Argentina, PMY',
    key: 'PMY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'El Tepual International, Puerto Montt, Chile, PMC',
    key: 'PMC',
  },
  { icon: 'faPlaneDeparture', label: 'Elazığ, Turkey, EZS', key: 'EZS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Elcho Island, Australia, ELC',
    key: 'ELC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eldoret International, Kenya, EDL',
    key: 'EDL',
  },
  { icon: 'faPlaneDeparture', label: 'Elim, United States, ELI', key: 'ELI' },
  { icon: 'faPlaneDeparture', label: 'Elista, Russia, ESL', key: 'ESL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Elko Regional, United States, EKO',
    key: 'EKO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Elmira/Corning Regional, United States, ELM',
    key: 'ELM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eloy Alfaro International, Manta, Ecuador, MEC',
    key: 'MEC',
  },
  { icon: 'faPlaneDeparture', label: 'Emerald, Australia, EMD', key: 'EMD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Emmonak, United States, EMK',
    key: 'EMK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Enfidha–Hammamet International, Tunisia, NBE',
    key: 'NBE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Enontekiö, Enontekio, Finland, ENF',
    key: 'ENF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Enrique Malek International, David, Chiriquí, Panama, DAV',
    key: 'DAV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Enshi Xujiaping, Enshi City, China, ENH',
    key: 'ENH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Entebbe International, Uganda, EBB',
    key: 'EBB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Enua, Atiu, Cook Islands, AIU',
    key: 'AIU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eppley Airfield, Omaha, United States, OMA',
    key: 'OMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erbil International, Iraq, EBL',
    key: 'EBL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erbogachen, Yerbogachen, Russia, ERG',
    key: 'ERG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ercan International, Northern Cyprus, Unknown Region, ECN',
    key: 'ECN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erenhot Saiwusu International, China, ERL',
    key: 'ERL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erfurt–Weimar, Germany, ERF',
    key: 'ERF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erie International, United States, ERI',
    key: 'ERI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erik Nielsen Whitehorse International, Canada, YXY',
    key: 'YXY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Erkilet International, Kayseri, Turkey, ASR',
    key: 'ASR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ernest A. Love Field, Prescott, United States, PRC',
    key: 'PRC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ernesto Cortissoz International, Barranquilla, Colombia, BAQ',
    key: 'BAQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eros, Windhoek, Namibia, ERS',
    key: 'ERS',
  },
  { icon: 'faPlaneDeparture', label: 'Erzincan, Turkey, ERC', key: 'ERC' },
  { icon: 'faPlaneDeparture', label: 'Erzurum, Turkey, ERZ', key: 'ERZ' },
  { icon: 'faPlaneDeparture', label: 'Esbjerg, Denmark, EBJ', key: 'EBJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Esenboğa International, Ankara, Turkey, ESB',
    key: 'ESB',
  },
  { icon: 'faPlaneDeparture', label: 'Esperance, Australia, EPR', key: 'EPR' },
  { icon: 'faPlaneDeparture', label: 'Esquel, Argentina, EQS', key: 'EQS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Essaouira-Mogador, Morocco, ESU',
    key: 'ESU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Essendon, Melbourne, Australia, MEB',
    key: 'MEB',
  },
  { icon: 'faPlaneDeparture', label: 'Eugene, United States, EUG', key: 'EUG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Eugenio María de Hostos, Mayagüez, United States, MAZ',
    key: 'MAZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Eurico de Aguiar Salles, Vitória, Espírito Santo, Brazil, VIX',
    key: 'VIX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'EuroAirport Basel Mulhouse Freiburg, Switzerland, BSL',
    key: 'BSL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Evansville Regional, United States, EVV',
    key: 'EVV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Excursion Inlet Seaplane Base, United States, EXI',
    key: 'EXI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Exeter, United Kingdom, EXT',
    key: 'EXT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Exuma International, George Town, Bahamas, GGT',
    key: 'GGT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'F. D. Roosevelt, Sint Eustatius, Netherlands, EUX',
    key: 'EUX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fa a ā International, Tahiti, French Polynesia, PPT',
    key: 'PPT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Faaite, French Polynesia, FAC',
    key: 'FAC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fabio Alberto León Bentley, Mitú, Colombia, MVP',
    key: 'MVP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fairbanks International, United States, FAI',
    key: 'FAI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Faisalabad International, Faisalabad District, Pakistan, LYP',
    key: 'LYP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fakarava, French Polynesia, FAV',
    key: 'FAV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fakfak Torea, Indonesia, FKQ',
    key: 'FKQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Falcone–Borsellino, Palermo, Italy, PMO',
    key: 'PMO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Faleolo International, Apia, Samoa, APW',
    key: 'APW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Falls International, International Falls, United States, INL',
    key: 'INL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'False Pass, United States, KFP',
    key: 'KFP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fangatau, French Polynesia, FGU',
    key: 'FGU',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'FAP Captain Carlos Martínez de Pinillos International, Trujillo, Peru, TRU',
    key: 'TRU',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'FAP Captain David Abensur Rengifo International, Pucallpa, Peru, PCL',
    key: 'PCL',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'FAP Captain Guillermo Concha Iberico International, Piura, Peru, PIU',
    key: 'PIU',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'FAP Captain José Abelardo Quiñones Gonzáles International, Chiclayo, Peru, CIX',
    key: 'CIX',
  },
  { icon: 'faPlaneDeparture', label: 'Faro, Portugal, FAO', key: 'FAO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Fascene, Nosy Be, Madagascar, NOS',
    key: 'NOS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fatmawati Soekarno, Bengkulu, Indonesia, BKS',
    key: 'BKS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fayetteville Regional, United States, FAY',
    key: 'FAY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Federico Fellini International, Rimini, Italy, RMI',
    key: 'RMI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Federico García Lorca, Granada, Spain, GRX',
    key: 'GRX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Feira de Santana, Brazil, FEC',
    key: 'FEC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fera, Fera Island, Solomon Islands, FRE',
    key: 'FRE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ferdinand Lumban Tobing, Sibolga, Indonesia, FLZ',
    key: 'FLZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fergana International, Uzbekistan, FEG',
    key: 'FEG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fernando De Noronha, Brazil, FEN',
    key: 'FEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fernando Luis Ribas Dominicci, San Juan, United States, SIG',
    key: 'SIG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fès–Saïs, Fes, Morocco, FEZ',
    key: 'FEZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Figari–Sud Corse, France, FSC',
    key: 'FSC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Flagstaff Pulliam, Grand Canyon Village, United States, FLG',
    key: 'FLG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Flamingo International, Bonaire, Netherlands, BON',
    key: 'BON',
  },
  { icon: 'faPlaneDeparture', label: 'Flin Flon, Canada, YFO', key: 'YFO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Flinders Island, Australia, FLS',
    key: 'FLS',
  },
  { icon: 'faPlaneDeparture', label: 'Florence, Italy, FLR', key: 'FLR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Florence Regional, United States, FLO',
    key: 'FLO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Flores, Flores Island, Portugal, FLW',
    key: 'FLW',
  },
  { icon: 'faPlaneDeparture', label: 'Florø, Norway, FRO', key: 'FRO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ford, Iron Mountain, United States, IMT',
    key: 'IMT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Førde Airport, Bringeland, Norway, FDE',
    key: 'FDE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Formosa International, Argentina, FMA',
    key: 'FMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Albany, Fort Albany First Nation, Canada, YFA',
    key: 'YFA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Chipewyan, Canada, YPY',
    key: 'YPY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Dodge Regional, United States, FOD',
    key: 'FOD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Frances Municipal, Canada, YAG',
    key: 'YAG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Good Hope, Canada, YGH',
    key: 'YGH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Lauderdale Executive, United States, FXE',
    key: 'FXE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Lauderdale–Hollywood International, United States, FLL',
    key: 'FLL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort McMurray International, Canada, YMM',
    key: 'YMM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort McPherson, Canada, ZFM',
    key: 'ZFM',
  },
  { icon: 'faPlaneDeparture', label: 'Fort Nelson, Canada, YYE', key: 'YYE' },
  { icon: 'faPlaneDeparture', label: 'Fort Simpson, Canada, YFS', key: 'YFS' },
  { icon: 'faPlaneDeparture', label: 'Fort Smith, Canada, YSM', key: 'YSM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Smith Regional, United States, FSM',
    key: 'FSM',
  },
  { icon: 'faPlaneDeparture', label: 'Fort St. John, Canada, YXJ', key: 'YXJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Wayne International, United States, FWA',
    key: 'FWA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fort Yukon, United States, FYU',
    key: 'FYU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Four Corners Regional, Farmington, United States, FMN',
    key: 'FMN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Foz do Iguaçu International, Brazil, IGU',
    key: 'IGU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Francisco B. Reyes, Busuanga, Palawan, Philippines, USU',
    key: 'USU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Francisco Bangoy International, Davao, Philippines, DVO',
    key: 'DVO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Francisco Carle, Jauja, Peru, JAU',
    key: 'JAU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Francisco de Orellana, Puerto Francisco de Orellana, Ecuador, OCC',
    key: 'OCC',
  },
  { icon: 'faPlaneDeparture', label: 'Francistown, Botswana, FRW', key: 'FRW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Frank País, Holguín, Cuba, HOG',
    key: 'HOG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Frankfurt International Airport, Germany, FRA',
    key: 'FRA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Frankfurt–Hahn, Germany, HHN',
    key: 'HHN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Frans Kaisiepo International, Biak, Indonesia, BIK',
    key: 'BIK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Frans Sales Lega, Ruteng, Indonesia, RTG',
    key: 'RTG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Frans Xavier Seda, Maumere, Indonesia, MOF',
    key: 'MOF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fredericton International, Canada, YFC',
    key: 'YFC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fresno Yosemite International, United States, FAT',
    key: 'FAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Friedman Memorial, Sun Valley, United States, SUN',
    key: 'SUN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Friedrichshafen, Germany, FDH',
    key: 'FDH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fuaʻamotu International, Nukuʻalofa, Tonga, TBU',
    key: 'TBU',
  },
  { icon: 'faPlaneDeparture', label: 'Fuerteventura, Spain, FUE', key: 'FUE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Fukue, Fukue Island, Japan, FUJ',
    key: 'FUJ',
  },
  { icon: 'faPlaneDeparture', label: 'Fukuoka, Japan, FUK', key: 'FUK' },
  { icon: 'faPlaneDeparture', label: 'Fukushima, Japan, FKS', key: 'FKS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Funafuti International, Tuvalu, FUN',
    key: 'FUN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Futuna, Futuna Island, Vanuatu, FTA',
    key: 'FTA',
  },
  { icon: 'faPlaneDeparture', label: 'Fuyang Xiguan, China, FUG', key: 'FUG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Fuyuan Dongji, Fuyuan, Heilongjiang, China, FYJ',
    key: 'FYJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fuyun Koktokay, Fuyun County, China, FYN',
    key: 'FYN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Fuzhou Changle International, China, FOC',
    key: 'FOC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gabriel Vargas Santos, Tame, Colombia, TME',
    key: 'TME',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gainesville Regional, United States, GNV',
    key: 'GNV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Galela Gamarmalamo, Indonesia, GLX',
    key: 'GLX',
  },
  { icon: 'faPlaneDeparture', label: 'Gällivare, Sweden, GEV', key: 'GEV' },
  { icon: 'faPlaneDeparture', label: 'Gambela, Ethiopia, GMB', key: 'GMB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gambell, United States, GAM',
    key: 'GAM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gamètì/Rae Lakes, Gameti, Canada, YRA',
    key: 'YRA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gander International, Canada, YQX',
    key: 'YQX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gandhinagar Airport, Nashik, India, ISK',
    key: 'ISK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ganja International, Azerbaijan, KVD',
    key: 'KVD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gannan Xiahe, Xiahe County, China, GXH',
    key: 'GXH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ganzhou Huangjin, China, KOW',
    key: 'KOW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Garden City Regional, United States, GCK',
    key: 'GCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Garoua International, Cameroon, GOU',
    key: 'GOU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Garowe, Garoe, Somalia, GGR',
    key: 'GGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gatwick, London, United Kingdom, LGW',
    key: 'LGW',
  },
  { icon: 'faPlaneDeparture', label: 'Gaua, Vanuatu, ZGU', key: 'ZGU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gautam Buddha, Siddharthanagar, Nepal, BWA',
    key: 'BWA',
  },
  { icon: 'faPlaneDeparture', label: 'Gaya, India, GAY', key: 'GAY' },
  { icon: 'faPlaneDeparture', label: 'Gazipaşa, Turkey, GZP', key: 'GZP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gdańsk Lech Wałęsa, Poland, GDN',
    key: 'GDN',
  },
  { icon: 'faPlaneDeparture', label: 'Gelendzhik, Russia, GDZ', key: 'GDZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gemena, Democratic Republic of the Congo, GMA',
    key: 'GMA',
  },
  { icon: 'faPlaneDeparture', label: 'Geneina, Sudan, EGN', key: 'EGN' },
  {
    icon: 'faPlaneDeparture',
    label:
      'General Enrique Mosconi International, Comodoro Rivadavia, Argentina, CRD',
    key: 'CRD',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'General Ignacio Pesqueira García International, Hermosillo, Mexico, HMO',
    key: 'HMO',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'General José Antonio Anzoátegui International, Barcelona, Venezuela, BLA',
    key: 'BLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'General Justo José de Urquiza, Paraná, Argentina, PRA',
    key: 'PRA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'General Lucio Blanco International, Reynosa, Mexico, REX',
    key: 'REX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'General Mitchell International, Milwaukee, United States, MKE',
    key: 'MKE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'General Santos International, Philippines, GES',
    key: 'GES',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'General Ulpiano Paez, Salinas, Ecuador, SNC',
    key: 'SNC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'General Wayne A. Downing Peoria International, United States, PIA',
    key: 'PIA',
  },
  { icon: 'faPlaneDeparture', label: 'Geneva, Switzerland, GVA', key: 'GVA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Genoa Cristoforo Colombo, Italy, GOA',
    key: 'GOA',
  },
  { icon: 'faPlaneDeparture', label: 'George, South Africa, GRJ', key: 'GRJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'George Best Belfast City, United Kingdom, BHD',
    key: 'BHD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'George Bush Intercontinental, Houston, United States, IAH',
    key: 'IAH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'George Enescu International, Bacău, Romania, BCM',
    key: 'BCM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'George F. L. Charles, Saint Lucia, St. Lucia, SLU',
    key: 'SLU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gerald R. Ford International, Grand Rapids, United States, GRR',
    key: 'GRR',
  },
  { icon: 'faPlaneDeparture', label: 'Geraldton, Australia, GET', key: 'GET' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gerardo Tobar López, Buenaventura, Colombia, BUN',
    key: 'BUN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Germán Olano, Puerto Carreño, Colombia, PCR',
    key: 'PCR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gewayantana, Larantuka, Indonesia, LKA',
    key: 'LKA',
  },
  { icon: 'faPlaneDeparture', label: 'Ghat, Libya, GHT', key: 'GHT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gibraltar International, Gibraltar, GIB',
    key: 'GIB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gilgit, Unknown Region, GIL',
    key: 'GIL',
  },
  { icon: 'faPlaneDeparture', label: 'Gillam, Canada, YGX', key: 'YGX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gillette–Campbell County, United States, GCC',
    key: 'GCC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gimhae International, Busan, South Korea, PUS',
    key: 'PUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gimpo International, Seoul, South Korea, GMP',
    key: 'GMP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Girona–Costa Brava, Spain, GRO',
    key: 'GRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Girua, Popondetta, Papua New Guinea, PNP',
    key: 'PNP',
  },
  { icon: 'faPlaneDeparture', label: 'Gisborne, New Zealand, GIS', key: 'GIS' },
  { icon: 'faPlaneDeparture', label: 'Gjoa Haven, Canada, YHK', key: 'YHK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Glacier Park International, Kalispell, United States, FCA',
    key: 'FCA',
  },
  { icon: 'faPlaneDeparture', label: 'Gladstone, Australia, GLT', key: 'GLT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Glasgow, United States, GGW',
    key: 'GGW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Glasgow, United Kingdom, GLA',
    key: 'GLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Glasgow Prestwick, United Kingdom, PIK',
    key: 'PIK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Goa International, India, GOI',
    key: 'GOI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gobernador Edgardo Castello, Viedma, Argentina, VDM',
    key: 'VDM',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Gobernador Horacio Guzmán International, San Salvador de Jujuy, Argentina, JUJ',
    key: 'JUJ',
  },
  { icon: 'faPlaneDeparture', label: 'Gode, Ethiopia, GDE', key: 'GDE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Godofredo P. Ramos, Caticlan, Philippines, MPH',
    key: 'MPH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gods Lake Narrows, Gods Lake Narrows, Manitoba, Canada, YGO',
    key: 'YGO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gods River, Manto Sipi Cree Nation, Canada, ZGI',
    key: 'ZGI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gogebic–Iron County, Ironwood, United States, IWD',
    key: 'IWD',
  },
  { icon: 'faPlaneDeparture', label: 'Gold Coast, Australia, OOL', key: 'OOL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Golden Triangle Regional, Columbus, United States, GTR',
    key: 'GTR',
  },
  { icon: 'faPlaneDeparture', label: 'Golfito, Costa Rica, GLF', key: 'GLF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Golfo de Morrosquillo, Tolú, Colombia, TLU',
    key: 'TLU',
  },
  { icon: 'faPlaneDeparture', label: 'Golmud, China, GOQ', key: 'GOQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Golog Maqin, Golog Tibetan Autonomous Prefecture, China, GMQ',
    key: 'GMQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Golosón International, La Ceiba, Honduras, LCE',
    key: 'LCE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Golovin, United States, GLV',
    key: 'GLV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Goma International, Democratic Republic of the Congo, GOM',
    key: 'GOM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gombe Lawanti International, Nigeria, GMO',
    key: 'GMO',
  },
  { icon: 'faPlaneDeparture', label: 'Gomel, Belarus, GME', key: 'GME' },
  { icon: 'faPlaneDeparture', label: 'Gondar, Ethiopia, GDQ', key: 'GDQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Goodnews, Goodnews Bay, United States, GNU',
    key: 'GNU',
  },
  { icon: 'faPlaneDeparture', label: 'Gorgan, Iran, GBT', key: 'GBT' },
  { icon: 'faPlaneDeparture', label: 'Gorno-Altaysk, Russia, RGK', key: 'RGK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Goroka, Papua New Guinea, GKA',
    key: 'GKA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Göteborg Landvetter, Gothenburg, Sweden, GOT',
    key: 'GOT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gothenburg City Airport, Sweden, GSE',
    key: 'GSE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gove, Nhulunbuy, Australia, GOV',
    key: 'GOV',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Governador Jorge Teixeira de Oliveira International, Porto Velho, Brazil, PVH',
    key: 'PVH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Governador Valadares, Brazil, GVR',
    key: 'GVR',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Governor Francisco Gabrielli International, Mendoza, Argentina, MDZ',
    key: 'MDZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Governor's Harbour, Bahamas, GHB",
    key: 'GHB',
  },
  { icon: 'faPlaneDeparture', label: 'Graciosa, Portugal, GRW', key: 'GRW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gran Canaria, Las Palmas, Spain, LPA',
    key: 'LPA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grand Bahama International, Freeport, Bahamas, FPO',
    key: 'FPO',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Grand Canyon National Park, Grand Canyon Village, United States, GCN',
    key: 'GCN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grand Canyon West, Grand Canyon West, Arizona, United States, GCW',
    key: 'GCW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grand Case-Espérance, Saint Martin (Island), St. Martin, SFG',
    key: 'SFG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grand Forks International, United States, GFK',
    key: 'GFK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grand Junction Regional, United States, GJT',
    key: 'GJT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grande Prairie, Canada, YQU',
    key: 'YQU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grantley Adams International, Bridgetown, Barbados, BGI',
    key: 'BGI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grayling, United States, KGX',
    key: 'KGX',
  },
  { icon: 'faPlaneDeparture', label: 'Graz, Austria, GRZ', key: 'GRZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Great Barrier Reef, Hamilton Island, Australia, HTI',
    key: 'HTI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Great Falls International, United States, GTF',
    key: 'GTF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Greater Binghamton, United States, BGM',
    key: 'BGM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Greater Moncton Roméo LeBlanc International, Canada, YQM',
    key: 'YQM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Greater Rochester International, United States, ROC',
    key: 'ROC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Green Bay–Austin Straubel International, United States, GRB',
    key: 'GRB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Greenbrier Valley, Lewisburg, United States, LWB',
    key: 'LWB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Greenville–Spartanburg International, United States, GSP',
    key: 'GSP',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Gregorio Luperón International, Puerto Plata, Dominican Republic, POP',
    key: 'POP',
  },
  { icon: 'faPlaneDeparture', label: 'Griffith, Australia, GFF', key: 'GFF' },
  { icon: 'faPlaneDeparture', label: 'Grise Fiord, Canada, YGZ', key: 'YGZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Groningen Airport Eelde, Netherlands, GRQ',
    key: 'GRQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Groote Eylandt, Australia, GTE',
    key: 'GTE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Grosseto Air Base, Italy, GRS',
    key: 'GRS',
  },
  { icon: 'faPlaneDeparture', label: 'Grozny, Russia, GRV', key: 'GRV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Guadalajara International, Mexico, GDL',
    key: 'GDL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guangyuan Panlong, China, GYS',
    key: 'GYS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guangzhou Baiyun International, China, CAN',
    key: 'CAN',
  },
  { icon: 'faPlaneDeparture', label: 'Guapi, Colombia, GPI', key: 'GPI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Guaraní International, Ciudad del Este, Paraguay, AGT',
    key: 'AGT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guardiamarina Zañartu, Puerto Williams, Chile, WPU',
    key: 'WPU',
  },
  { icon: 'faPlaneDeparture', label: 'Guayaramerín, Bolivia, GYA', key: 'GYA' },
  { icon: 'faPlaneDeparture', label: 'Guelmim, Morocco, GLN', key: 'GLN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Guemar, El Oued, Algeria, ELU',
    key: 'ELU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guernsey, Saint Peter Port, Guernsey, GCI',
    key: 'GCI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guilin Liangjiang International, China, KWL',
    key: 'KWL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guillermo León Valencia, Popayán, Colombia, PPN',
    key: 'PPN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guiyang Longdongbao International, China, KWE',
    key: 'KWE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gulfport–Biloxi International, United States, GPT',
    key: 'GPT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gunnison–Crested Butte Regional, United States, GUC',
    key: 'GUC',
  },
  { icon: 'faPlaneDeparture', label: 'Gunsan, South Korea, KUV', key: 'KUV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Gurayat Domestic, Qurayyat, Saudi Arabia, URY',
    key: 'URY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guriel, Guri el, Somalia, GUO',
    key: 'GUO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gurney, Alotau, Papua New Guinea, GUR',
    key: 'GUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gustaf III, Saint Barthélemy, St. Barthélemy, SBH',
    key: 'SBH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gustavo Artunduaga Paredes, Florencia, Colombia, FLA',
    key: 'FLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gustavo Rizo, Baracoa, Cuba, BCA',
    key: 'BCA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gustavo Rojas Pinilla International, San Andrés, Colombia, ADZ',
    key: 'ADZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gustavus, United States, GST',
    key: 'GST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gusti Syamsir Alam, Kotabaru Regency, Indonesia, KBU',
    key: 'KBU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Guyuan Liupanshan, Guyuan County, China, GYU',
    key: 'GYU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Gwadar International, Pakistan, GWD',
    key: 'GWD',
  },
  { icon: 'faPlaneDeparture', label: 'Gwalior, India, GWL', key: 'GWL' },
  { icon: 'faPlaneDeparture', label: 'Gwangju, South Korea, KWJ', key: 'KWJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'H. Asan, Sampit, Indonesia, SMQ',
    key: 'SMQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'H. Hasan Aroeboesman, Ende, Indonesia, ENE',
    key: 'ENE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'H.A.S. Hanandjoeddin International, Tanjung Pandan, Indonesia, TJQ',
    key: 'TJQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ha il Regional, Saudi Arabia, HAS',
    key: 'HAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hachijojima, Hachijō-jima, Japan, HAC',
    key: 'HAC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hagerstown Regional, United States, HGR',
    key: 'HGR',
  },
  { icon: 'faPlaneDeparture', label: 'Haifa, Israel, HFA', key: 'HFA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Haikou Meilan International, China, HAK',
    key: 'HAK',
  },
  { icon: 'faPlaneDeparture', label: 'Haines, United States, HNS', key: 'HNS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hakkari–Yüksekova, Hakkâri, Turkey, YKO',
    key: 'YKO',
  },
  { icon: 'faPlaneDeparture', label: 'Hakodate, Japan, HKD', key: 'HKD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Halifax Stanfield International, Canada, YHZ',
    key: 'YHZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Halim Perdanakusuma International, Jakarta, Indonesia, HLP',
    key: 'HLP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Haliwen, Atambua, Indonesia, ABU',
    key: 'ABU',
  },
  { icon: 'faPlaneDeparture', label: 'Hall Beach, Canada, YUX', key: 'YUX' },
  { icon: 'faPlaneDeparture', label: 'Halmstad, Sweden, HAD', key: 'HAD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Haluoleo, Kendari, Indonesia, KDI',
    key: 'KDI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hamad International, Doha, Qatar, DOH',
    key: 'DOH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hamadan International, Iran, HDM',
    key: 'HDM',
  },
  { icon: 'faPlaneDeparture', label: 'Hamburg, Germany, HAM', key: 'HAM' },
  { icon: 'faPlaneDeparture', label: 'Hami, China, HMI', key: 'HMI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hamid Karzai International, Kabul, Afghanistan, KBL',
    key: 'KBL',
  },
  { icon: 'faPlaneDeparture', label: 'Hamilton, New Zealand, HLZ', key: 'HLZ' },
  { icon: 'faPlaneDeparture', label: 'Hammerfest, Norway, HFT', key: 'HFT' },
  { icon: 'faPlaneDeparture', label: 'Hana, United States, HNM', key: 'HNM' },
  { icon: 'faPlaneDeparture', label: 'Hanamaki, Japan, HNA', key: 'HNA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hancock County-Bar Harbor, United States, BHB',
    key: 'BHB',
  },
  { icon: 'faPlaneDeparture', label: 'Handan, China, HDG', key: 'HDG' },
  { icon: 'faPlaneDeparture', label: 'Haneda, Tokyo, Japan, HND', key: 'HND' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hang Nadim, Batam, Indonesia, BTH',
    key: 'BTH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hangzhou Xiaoshan International, China, HGH',
    key: 'HGH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hannover, Hanover, Germany, HAJ',
    key: 'HAJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hanzhong Xiguan, China, HZG',
    key: 'HZG',
  },
  { icon: 'faPlaneDeparture', label: 'Hao, French Polynesia, HOI', key: 'HOI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Harare International, Zimbabwe, HRE',
    key: 'HRE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Harbin Taiping International, China, HRB',
    key: 'HRB',
  },
  { icon: 'faPlaneDeparture', label: 'Hargeisa, Somalia, HGA', key: 'HGA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Harrisburg International, United States, MDT',
    key: 'MDT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Harry Mwanga Nkumbula International, Livingstone, Zambia, LVI',
    key: 'LVI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Harstad/Narvik Airport, Evenes, Norway, EVE',
    key: 'EVE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hartsfield–Jackson Atlanta International, United States, ATL',
    key: 'ATL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hassan I, Laayoune, Unknown Region, EUN',
    key: 'EUN',
  },
  { icon: 'faPlaneDeparture', label: 'Hasvik, Norway, HAA', key: 'HAA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hat Yai International, Thailand, HDY',
    key: 'HDY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hatay, Hatay Province, Turkey, HTY',
    key: 'HTY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hattiesburg–Laurel Regional, United States, PIB',
    key: 'PIB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hatton Castlereigh Resrvr SPB, Sri Lanka, NUF',
    key: 'NUF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Haugesund Airport, Karmøy, Norway, HAU',
    key: 'HAU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Havre City–County, United States, HVR',
    key: 'HVR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Havryshivka Vinnytsia International, Ukraine, VIN',
    key: 'VIN',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Hawke's Bay, Napier, New Zealand, NPE",
    key: 'NPE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hay River/Merlyn Carter, Canada, YHY',
    key: 'YHY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hayman Island Heliport, Australia, HIS',
    key: 'HIS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hays Regional, United States, HYS',
    key: 'HYS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Healy River, Healy Lake, United States, HKB',
    key: 'HKB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Heathrow, London, United Kingdom, LHR',
    key: 'LHR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hector International, Fargo, United States, FAR',
    key: 'FAR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hector Silva Airstrip, Belize City, Belize, BCV',
    key: 'BCV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hefei Xinqiao International, China, HFE',
    key: 'HFE',
  },
  { icon: 'faPlaneDeparture', label: 'Heho, Myanmar (Burma), HEH', key: 'HEH' },
  { icon: 'faPlaneDeparture', label: 'Heihe, China, HEK', key: 'HEK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Helena Regional, United States, HLN',
    key: 'HLN',
  },
  { icon: 'faPlaneDeparture', label: 'Helsinki, Finland, HEL', key: 'HEL' },
  { icon: 'faPlaneDeparture', label: 'Hemavan, Sweden, HMV', key: 'HMV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hendrik Van Eck, Phalaborwa, South Africa, PHW',
    key: 'PHW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hengyang Nanyue, China, HNY',
    key: 'HNY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Henri Coandă International, Bucharest, Romania, OTP',
    key: 'OTP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Henry E. Rohlsen, Saint Croix, United States, STX',
    key: 'STX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Heraklion International, Greece, HER',
    key: 'HER',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Herat International, Afghanistan, HEA',
    key: 'HEA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hercílio Luz International, Florianópolis, Brazil, FLN',
    key: 'FLN',
  },
  { icon: 'faPlaneDeparture', label: 'Heringsdorf, Germany, HDF', key: 'HDF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hermanos Ameijeiras, Las Tunas, Cuba, VTU',
    key: 'VTU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hermes Quijada International, Río Grande, Argentina, RGA',
    key: 'RGA',
  },
  { icon: 'faPlaneDeparture', label: 'Hervey Bay, Australia, HVB', key: 'HVB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hévíz–Balaton, Hungary, SOB',
    key: 'SOB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hewanorra International, Saint Lucia, St. Lucia, UVF',
    key: 'UVF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Heydar Aliyev International, Baku, Azerbaijan, GYD',
    key: 'GYD',
  },
  { icon: 'faPlaneDeparture', label: 'High Level, Canada, YOJ', key: 'YOJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hihifo, Wallis Island, Wallis & Futuna, WLS',
    key: 'WLS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hikueru, French Polynesia, HHZ',
    key: 'HHZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hilo International, United States, ITO',
    key: 'ITO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hilton Head, Hilton Head Island, United States, HHH',
    key: 'HHH',
  },
  { icon: 'faPlaneDeparture', label: 'Hiroshima, Japan, HIJ', key: 'HIJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hobart International, Australia, HBA',
    key: 'HBA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hodeidah International Airport, Yemen, HOD',
    key: 'HOD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Höga Kusten, Kramfors Municipality, Sweden, KRF',
    key: 'KRF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hohhot Baita International, China, HET',
    key: 'HET',
  },
  { icon: 'faPlaneDeparture', label: 'Hokitika, New Zealand, HKK', key: 'HKK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hollis Seaplane Base, United States, HYL',
    key: 'HYL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hollywood Burbank, United States, BUR',
    key: 'BUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Holy Cross, United States, HCR',
    key: 'HCR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Homalin, Myanmar (Burma), HOX',
    key: 'HOX',
  },
  { icon: 'faPlaneDeparture', label: 'Homer, United States, HOM', key: 'HOM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hong Kong International, China, HKG',
    key: 'HKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Honiara International, Solomon Islands, HIR',
    key: 'HIR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Honningsvåg Airport, Valan, Norway, HVG',
    key: 'HVG',
  },
  { icon: 'faPlaneDeparture', label: 'Hoonah, United States, HNH', key: 'HNH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hooper Bay, United States, HPB',
    key: 'HPB',
  },
  { icon: 'faPlaneDeparture', label: 'Hopedale, Canada, YHO', key: 'YHO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Horn Island, Australia, HID',
    key: 'HID',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Horta, Horta, Azores, Portugal, HOR',
    key: 'HOR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hosea Kutako International, Windhoek, Namibia, WDH',
    key: 'WDH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hoskins, Papua New Guinea, HKN',
    key: 'HKN',
  },
  { icon: 'faPlaneDeparture', label: 'Hotan, China, HTN', key: 'HTN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Houari Boumediene, Algiers, Algeria, ALG',
    key: 'ALG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Houghton County Memorial, Hancock, United States, CMX',
    key: 'CMX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Howard Air Force Base, Balboa, Panama, BLB',
    key: 'BLB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hrodna, Grodno, Belarus, GNA',
    key: 'GNA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hua Hin, Hua Hin District, Thailand, HHQ',
    key: 'HHQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Huahine – Fare, French Polynesia, HUH',
    key: 'HUH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Huai an Lianshui, China, HIA',
    key: 'HIA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Huaihua Zhijiang, China, HJJ',
    key: 'HJJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hualien, Hualien County, Taiwan, HUN',
    key: 'HUN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Huangshan Tunxi International, Huangshan City, China, TXN',
    key: 'TXN',
  },
  { icon: 'faPlaneDeparture', label: 'Hubli, India, HBX', key: 'HBX' },
  { icon: 'faPlaneDeparture', label: 'Hughenden, Australia, HGD', key: 'HGD' },
  { icon: 'faPlaneDeparture', label: 'Hughes, United States, HUS', key: 'HUS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Huizhou Pingtan, China, HUZ',
    key: 'HUZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hulunbuir Hailar, China, HLD',
    key: 'HLD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Humberside, Kirmington, United Kingdom, HUY',
    key: 'HUY',
  },
  { icon: 'faPlaneDeparture', label: 'Humera, Ethiopia, HUE', key: 'HUE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Huntsville International, United States, HSV',
    key: 'HSV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Hurghada International, Egypt, HRG',
    key: 'HRG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Husein Sastranegara International, Bandung, Indonesia, BDO',
    key: 'BDO',
  },
  { icon: 'faPlaneDeparture', label: 'Huslia, United States, HSL', key: 'HSL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Hydaburg Seaplane Base, United States, HYG',
    key: 'HYG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Iași International, Romania, IAS',
    key: 'IAS',
  },
  { icon: 'faPlaneDeparture', label: 'Ibadan, Nigeria, IBA', key: 'IBA' },
  { icon: 'faPlaneDeparture', label: 'Ibaraki, Japan, IBR', key: 'IBR' },
  { icon: 'faPlaneDeparture', label: 'Ibiza, Spain, IBZ', key: 'IBZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Idaho Falls Regional, United States, IDA',
    key: 'IDA',
  },
  { icon: 'faPlaneDeparture', label: 'Igarka, Russia, IAA', key: 'IAA' },
  { icon: 'faPlaneDeparture', label: 'Iğdır, Turkey, IGD', key: 'IGD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Igiugig, United States, IGG',
    key: 'IGG',
  },
  { icon: 'faPlaneDeparture', label: 'Igloolik, Canada, YGT', key: 'YGT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ignacio Agramonte International, Camagüey, Cuba, CMW',
    key: 'CMW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ignatyevo, Blagoveshchensk, Russia, BQS',
    key: 'BQS',
  },
  { icon: 'faPlaneDeparture', label: 'Igrim, Russia, IRM', key: 'IRM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ikaria Island National, Icaria, Greece, JIK',
    key: 'JIK',
  },
  { icon: 'faPlaneDeparture', label: 'Iki, Japan, IKI', key: 'IKI' },
  { icon: 'faPlaneDeparture', label: 'Ilam, Iran, IIL', key: 'IIL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Île Art – Waala, Art Island, New Caledonia, BMY',
    key: 'BMY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Île des Pins, Isle of Pines, New Caledonia, ILP',
    key: 'ILP',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Îles-de-la-Madeleine, Les Îles-de-la-Madeleine, Quebec, Canada, YGR',
    key: 'YGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ilhéus Jorge Amado, Brazil, IOS',
    key: 'IOS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Iliamna, United States, ILI',
    key: 'ILI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Illawarra Regional, City of Wollongong, Australia, WOL',
    key: 'WOL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Iloilo International, Iloilo City, Philippines, ILO',
    key: 'ILO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ilorin International, Nigeria, ILR',
    key: 'ILR',
  },
  { icon: 'faPlaneDeparture', label: 'Ilulissat, Greenland, JAV', key: 'JAV' },
  { icon: 'faPlaneDeparture', label: 'Imperatriz, Brazil, IMP', key: 'IMP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Imperial County, El Centro, United States, IPL',
    key: 'IPL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Imphal International, India, IMF',
    key: 'IMF',
  },
  { icon: 'faPlaneDeparture', label: 'In Amenas, Algeria, IAM', key: 'IAM' },
  { icon: 'faPlaneDeparture', label: 'In Salah, Algeria, INZ', key: 'INZ' },
  { icon: 'faPlaneDeparture', label: 'Inagua, Bahamas, IGA', key: 'IGA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Inca Manco Cápac International, Juliaca, Peru, JUL',
    key: 'JUL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Incheon International, Seoul, South Korea, ICN',
    key: 'ICN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Independence, Independence and Mango Creek, Belize, INB',
    key: 'INB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Indian Mountain Lrrs, Utopia Creek, United States, UTO',
    key: 'UTO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Indianapolis International, United States, IND',
    key: 'IND',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Indira Gandhi International, New Delhi, India, DEL',
    key: 'DEL',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Ingeniero Aeronáutico Ambrosio L.V. Taravella International, Córdoba, Argentina, COR',
    key: 'COR',
  },
  { icon: 'faPlaneDeparture', label: 'Inhambane, Mozambique, INH', key: 'INH' },
  { icon: 'faPlaneDeparture', label: 'Innsbruck, Austria, INN', key: 'INN' },
  { icon: 'faPlaneDeparture', label: 'Inta Airport, Russia, INA', key: 'INA' },
  {
    icon: 'faPlaneDeparture',
    label: 'International Airport Irkutsk, Russia, IKT',
    key: 'IKT',
  },
  { icon: 'faPlaneDeparture', label: 'Inukjuak, Canada, YPH', key: 'YPH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Inuvik (Mike Zubko), Canada, YEV',
    key: 'YEV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Invercargill, New Zealand, IVC',
    key: 'IVC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Inverness, United Kingdom, INV',
    key: 'INV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ioannina National, Greece, IOA',
    key: 'IOA',
  },
  { icon: 'faPlaneDeparture', label: 'Ipota, Vanuatu, IPA', key: 'IPA' },
  { icon: 'faPlaneDeparture', label: 'Iqaluit, Canada, YFB', key: 'YFB' },
  { icon: 'faPlaneDeparture', label: 'Iranshahr, Iran, IHR', key: 'IHR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Irarutu Babo, Indonesia, BXB',
    key: 'BXB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ireland West Airport Knock, Knock, County Mayo, Ireland, NOC',
    key: 'NOC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Iringa Airport, Nduli, Tanzania, IRI',
    key: 'IRI',
  },
  { icon: 'faPlaneDeparture', label: 'Ísafjörður, Iceland, IFJ', key: 'IFJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Isfahan International, Iran, IFN',
    key: 'IFN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ishigaki, Ishigaki, Okinawa, Japan, ISG',
    key: 'ISG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Iskandar, Pangkalan Bun, Indonesia, PKN',
    key: 'PKN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Islamabad International Airport, Pakistan, ISB',
    key: 'ISB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Island Lake, Island Lake, Manitoba, Canada, YIV',
    key: 'YIV',
  },
  { icon: 'faPlaneDeparture', label: 'Islay, United Kingdom, ILY', key: 'ILY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Isle of Man, Douglas, Isle of Man, IOM',
    key: 'IOM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Isparta Süleyman Demirel, Turkey, ISE',
    key: 'ISE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Issyk-Kul International, Tamchy, Kyrgyzstan, IKU',
    key: 'IKU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Istanbul Atatürk, Turkey, IST',
    key: 'IST',
  },
  { icon: 'faPlaneDeparture', label: 'Itami, Osaka, Japan, ITM', key: 'ITM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ithaca Tompkins Regional, United States, ITH',
    key: 'ITH',
  },
  { icon: 'faPlaneDeparture', label: 'Iturup, Russia, ITU', key: 'ITU' },
  { icon: 'faPlaneDeparture', label: 'Ivalo, Finland, IVL', key: 'IVL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ivano-Frankivsk International, Ukraine, IFO',
    key: 'IFO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ivanovo Yuzhny, Russia, IWA',
    key: 'IWA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ivato International, Antananarivo, Madagascar, TNR',
    key: 'TNR',
  },
  { icon: 'faPlaneDeparture', label: 'Ivujivik, Canada, YIK', key: 'YIK' },
  { icon: 'faPlaneDeparture', label: 'Iwami, Masuda, Japan, IWJ', key: 'IWJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ixtapa-Zihuatanejo International, Mexico, ZIH',
    key: 'ZIH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ixtepec, Ixtepec, Oaxaca, Mexico, IZT',
    key: 'IZT',
  },
  { icon: 'faPlaneDeparture', label: 'Izhevsk, Russia, IJK', key: 'IJK' },
  { icon: 'faPlaneDeparture', label: 'Izumo, Japan, IZO', key: 'IZO' },
  { icon: 'faPlaneDeparture', label: 'Jabalpur, India, JLR', key: 'JLR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Jacinto Lara International, Barquisimeto, Venezuela, BRM',
    key: 'BRM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jack Brooks Regional, Beaumont, United States, BPT',
    key: 'BPT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jackson Hole, United States, JAC',
    key: 'JAC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jackson–Evers International, United States, JAN',
    key: 'JAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jacksons International, Port Moresby, Papua New Guinea, POM',
    key: 'POM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jacksonville International, United States, JAX',
    key: 'JAX',
  },
  { icon: 'faPlaneDeparture', label: 'Jaén, Peru, JAE', key: 'JAE' },
  {
    icon: 'faPlaneDeparture',
    label:
      'JAGS McCartney International, Cockburn Town, Turks & Caicos Islands, GDT',
    key: 'GDT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jaguaruna Regional, Brazil, JJG',
    key: 'JJG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jaime González, Cienfuegos, Cuba, CFG',
    key: 'CFG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jaipur International, India, JAI',
    key: 'JAI',
  },
  { icon: 'faPlaneDeparture', label: 'Jaisalmer, India, JSA', key: 'JSA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Jalaluddin, Gorontalo, Indonesia, GTO',
    key: 'GTO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jamestown Regional, United States, JMS',
    key: 'JMS',
  },
  { icon: 'faPlaneDeparture', label: 'Jammu, Unknown Region, IXJ', key: 'IXJ' },
  { icon: 'faPlaneDeparture', label: 'Jamnagar, India, JGA', key: 'JGA' },
  { icon: 'faPlaneDeparture', label: 'Janakpur, Nepal, JKR', key: 'JKR' },
  { icon: 'faPlaneDeparture', label: 'Jaque, Jaqué, Panama, JQE', key: 'JQE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Jardines del Rey, Cayo Coco, Cuba, CCC',
    key: 'CCC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jay Prakash Narayan International, Patna, India, PAT',
    key: 'PAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jazan, Jizan, Saudi Arabia, GIZ',
    key: 'GIZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jeju International, Jeju City, South Korea, CJU',
    key: 'CJU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jerez, Jerez de la Frontera, Spain, XRY',
    key: 'XRY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jersey, Saint Helier, Jersey, JER',
    key: 'JER',
  },
  { icon: 'faPlaneDeparture', label: 'Jessore, Bangladesh, JSR', key: 'JSR' },
  { icon: 'faPlaneDeparture', label: 'Ji-Paraná, Brazil, JPR', key: 'JPR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Jiagedaqi, Jiagedaqi District, China, JGD',
    key: 'JGD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jiamusi Dongjiao, China, JMU',
    key: 'JMU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jiayuguan, Jiayuguan City, China, JGN',
    key: 'JGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jieyang Chaoshan International, Shantou, China, SWA',
    key: 'SWA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jijel Ferhat Abbas, Algeria, GJL',
    key: 'GJL',
  },
  { icon: 'faPlaneDeparture', label: 'Jijiga, Ethiopia, JIJ', key: 'JIJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Jinan Yaoqiang International, China, TNA',
    key: 'TNA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jinchang Jinchuan, China, JIC',
    key: 'JIC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jingdezhen Luojia, China, JDZ',
    key: 'JDZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jinggangshan, Ji an, China, JGS',
    key: 'JGS',
  },
  { icon: 'faPlaneDeparture', label: 'Jining Qufu, China, JNG', key: 'JNG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Jinnah International, Karachi, Pakistan, KHI',
    key: 'KHI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jinzhou Xiaolingzi, China, JNZ',
    key: 'JNZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jiuzhai Huanglong, Jiuzhaigou County, China, JZH',
    key: 'JZH',
  },
  { icon: 'faPlaneDeparture', label: 'Jixi Xingkaihu, China, JXA', key: 'JXA' },
  {
    icon: 'faPlaneDeparture',
    label: 'João Paulo II, Ponta Delgada, Portugal, PDL',
    key: 'PDL',
  },
  { icon: 'faPlaneDeparture', label: 'Jodhpur, India, JDH', key: 'JDH' },
  { icon: 'faPlaneDeparture', label: 'Joensuu, Finland, JOE', key: 'JOE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Johan Adolf Pengel International, Paramaribo, Suriname, PBM',
    key: 'PBM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'John A. Osborne, Brades, United Kingdom, MNI',
    key: 'MNI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'John C. Munro Hamilton International, Canada, YHM',
    key: 'YHM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'John F. Kennedy International, New York City, United States, JFK',
    key: 'JFK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'John Glenn Columbus International, United States, CMH',
    key: 'CMH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'John Paul II International Airport Kraków–Balice, Poland, KRK',
    key: 'KRK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'John Wayne, Santa Ana, United States, SNA',
    key: 'SNA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Johnstown–Cambria County, United States, JST',
    key: 'JST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Joinville-Lauro Carneiro de Loyola, Brazil, JOI',
    key: 'JOI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jomo Kenyatta International, Nairobi, Kenya, NBO',
    key: 'NBO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jonesboro Municipal, United States, JBR',
    key: 'JBR',
  },
  { icon: 'faPlaneDeparture', label: 'Jönköping, Sweden, JKG', key: 'JKG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Joplin Regional, United States, JLN',
    key: 'JLN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jorge Chávez International, Lima, Peru, LIM',
    key: 'LIM',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Jorge Enrique González Torres, San José del Guaviare, Colombia, SJE',
    key: 'SJE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jorge Wilstermann International, Cochabamba, Bolivia, CBB',
    key: 'CBB',
  },
  { icon: 'faPlaneDeparture', label: 'Jorhat, India, JRH', key: 'JRH' },
  {
    icon: 'faPlaneDeparture',
    label:
      'José Aponte de la Torre, Roosevelt Roads Naval Station, United States, NRR',
    key: 'NRR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jose Celestino Mutis, Bahía Solano, Colombia, BSC',
    key: 'BSC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'José Joaquín de Olmedo International, Guayaquil, Ecuador, GYE',
    key: 'GYE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'José Leonardo Chirino, Coro, Venezuela, CZE',
    key: 'CZE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'José María Córdova International, Medellín, Colombia, MDE',
    key: 'MDE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'José Martí International, Havana, Cuba, HAV',
    key: 'HAV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Josefa Camejo International, Las Piedras, Venezuela, LSP',
    key: 'LSP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Joshua Mqabuko Nkomo International, Bulawayo, Zimbabwe, BUQ',
    key: 'BUQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juan Gualberto Gómez, Varadero, Cuba, VRA',
    key: 'VRA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juan H. White, Caucasia, Colombia, CAQ',
    key: 'CAQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juan Manuel Gálvez International, Coxen Hole, Honduras, RTB',
    key: 'RTB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juan Mendoza, Oruro, Bolivia, ORU',
    key: 'ORU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juan Pablo Pérez Alfonzo, El Vigía, Venezuela, VIG',
    key: 'VIG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juan Santamaría International, San José, Costa Rica, SJO',
    key: 'SJO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juancho E. Yrausquin, Saba, Netherlands, SAB',
    key: 'SAB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juanda International, Surabaya, Indonesia, SUB',
    key: 'SUB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juazeiro do Norte, Brazil, JDO',
    key: 'JDO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juba International, South Sudan, JUB',
    key: 'JUB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Julia Creek, Australia, JCK',
    key: 'JCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Julius Nyerere International, Dar es Salaam, Tanzania, DAR',
    key: 'DAR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juneau International, United States, JNU',
    key: 'JNU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Juwata International, Tarakan, North Kalimantan, Indonesia, TRK',
    key: 'TRK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Jyvaskyla, Jyväskylä, Finland, JYV',
    key: 'JYV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kabri Dar, Kebri Dahar, Ethiopia, ABK',
    key: 'ABK',
  },
  { icon: 'faPlaneDeparture', label: 'Kadala, Chita, Russia, HTA', key: 'HTA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kaduna International, Nigeria, KAD',
    key: 'KAD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kaghau, Kaghau Island, Solomon Islands, KGE',
    key: 'KGE',
  },
  { icon: 'faPlaneDeparture', label: 'Kagoshima, Japan, KOJ', key: 'KOJ' },
  { icon: 'faPlaneDeparture', label: 'Kahramanmaraş, Turkey, KCM', key: 'KCM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kahului, United States, OGG',
    key: 'OGG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kaili Huangping, Huangping County, China, KJH',
    key: 'KJH',
  },
  { icon: 'faPlaneDeparture', label: 'Kaimana, Indonesia, KNG', key: 'KNG' },
  { icon: 'faPlaneDeparture', label: 'Kajaani, Finland, KAJ', key: 'KAJ' },
  { icon: 'faPlaneDeparture', label: 'Kakamega, Kenya, GGM', key: 'GGM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kake Sea Plane Base, United States, KAE',
    key: 'KAE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalamata International, Greece, KLX',
    key: 'KLX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalamazoo/Battle Creek International, United States, AZO',
    key: 'AZO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalaymyo, Myanmar (Burma), KMV',
    key: 'KMV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalemie, Democratic Republic of the Congo, FMI',
    key: 'FMI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalgoorlie-Boulder, Australia, KGI',
    key: 'KGI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalibo International, Philippines, KLO',
    key: 'KLO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalimarau, Tanjung Redeb, Indonesia, BEJ',
    key: 'BEJ',
  },
  { icon: 'faPlaneDeparture', label: 'Kalmar, Sweden, KLR', key: 'KLR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalskag, Upper Kalskag, United States, KLG',
    key: 'KLG',
  },
  { icon: 'faPlaneDeparture', label: 'Kaltag, United States, KAL', key: 'KAL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kaluga (Grabtsevo), Russia, KLF',
    key: 'KLF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kalymnos Island National, Greece, JKL',
    key: 'JKL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kamembe, Cyangugu, Rwanda, KME',
    key: 'KME',
  },
  { icon: 'faPlaneDeparture', label: 'Kamloops, Canada, YKA', key: 'YKA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kananga, Democratic Republic of the Congo, KGA',
    key: 'KGA',
  },
  { icon: 'faPlaneDeparture', label: 'Kanas, Burqin, China, KJI', key: 'KJI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kandahar International, Afghanistan, KDH',
    key: 'KDH',
  },
  { icon: 'faPlaneDeparture', label: 'Kandla, India, IXY', key: 'IXY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kandy Polgolla Reservoir SPB, Sri Lanka, KDZ',
    key: 'KDZ',
  },
  { icon: 'faPlaneDeparture', label: 'Kangding, China, KGT', key: 'KGT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kangerlussuaq, Greenland, SFJ',
    key: 'SFJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kangiqsualujjuaq (Georges River), Canada, XGR',
    key: 'XGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kangiqsujuaq (Wakeham Bay), Canada, YWB',
    key: 'YWB',
  },
  { icon: 'faPlaneDeparture', label: 'Kangirsuk, Canada, YKG', key: 'YKG' },
  { icon: 'faPlaneDeparture', label: 'Kanpur, India, KNU', key: 'KNU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kansai International, Osaka, Japan, KIX',
    key: 'KIX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kansas City International, United States, MCI',
    key: 'MCI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kaohsiung International, Taiwan, KHH',
    key: 'KHH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kapalua, United States, JHM',
    key: 'JHM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kapiti Coast, Paraparaumu, New Zealand, PPQ',
    key: 'PPQ',
  },
  { icon: 'faPlaneDeparture', label: 'Kapuskasing, Canada, YYU', key: 'YYU' },
  { icon: 'faPlaneDeparture', label: 'Karamay, China, KRY', key: 'KRY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Karel Sadsuitubun Airport, Langgur, Indonesia, LUV',
    key: 'LUV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kariba International Airport, Zimbabwe, KAB',
    key: 'KAB',
  },
  { icon: 'faPlaneDeparture', label: 'Karlovy Vary, Czechia, KLV', key: 'KLV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Karlsruhe/Baden-Baden, Germany, FKB',
    key: 'FKB',
  },
  { icon: 'faPlaneDeparture', label: 'Karlstad, Sweden, KSD', key: 'KSD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Karpathos Island National, Greece, AOK',
    key: 'AOK',
  },
  { icon: 'faPlaneDeparture', label: 'Karratha, Australia, KTA', key: 'KTA' },
  { icon: 'faPlaneDeparture', label: 'Kars Harakani, Turkey, KSY', key: 'KSY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Karshi, Qarshi, Uzbekistan, KSQ',
    key: 'KSQ',
  },
  { icon: 'faPlaneDeparture', label: 'Karumba, Australia, KRB', key: 'KRB' },
  { icon: 'faPlaneDeparture', label: 'Kasaan, United States, KXA', key: 'KXA' },
  { icon: 'faPlaneDeparture', label: 'Kasane, Botswana, BBK', key: 'BBK' },
  { icon: 'faPlaneDeparture', label: 'Kashechewan, Canada, ZKE', key: 'ZKE' },
  { icon: 'faPlaneDeparture', label: 'Kashi, Kashgar, China, KHG', key: 'KHG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kasigluk, United States, KUK',
    key: 'KUK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kasos Island Public, Greece, KSJ',
    key: 'KSJ',
  },
  { icon: 'faPlaneDeparture', label: 'Kassel, Germany, KSF', key: 'KSF' },
  { icon: 'faPlaneDeparture', label: 'Kastamonu, Turkey, KFS', key: 'KFS' },
  { icon: 'faPlaneDeparture', label: 'Kastellorizo, Greece, KZS', key: 'KZS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kastoria National, Greece, KSO',
    key: 'KSO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Katima Mulilo, Namibia, MPA',
    key: 'MPA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Katiu, French Polynesia, KXU',
    key: 'KXU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Katowice International, Poland, KTW',
    key: 'KTW',
  },
  { icon: 'faPlaneDeparture', label: 'Kaubang, Indonesia, KAZ', key: 'KAZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kauehi, French Polynesia, KHZ',
    key: 'KHZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kaukura, French Polynesia, KKR',
    key: 'KKR',
  },
  { icon: 'faPlaneDeparture', label: 'Kaunas, Lithuania, KUN', key: 'KUN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kavala International, Greece, KVA',
    key: 'KVA',
  },
  { icon: 'faPlaneDeparture', label: 'Kavalerovo, Russia, KVR', key: 'KVR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kavieng, Papua New Guinea, KVG',
    key: 'KVG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kavumu, Bukavu, Democratic Republic of the Congo, BKY',
    key: 'BKY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kawthaung, Kawthaung Township, Myanmar (Burma), KAW',
    key: 'KAW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kazan International, Russia, KZN',
    key: 'KZN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kazi Nazrul Islam, Durgapur, India, RDP',
    key: 'RDP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kearney Regional, United States, EAR',
    key: 'EAR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Keflavík International, Reykjavik, Iceland, KEF',
    key: 'KEF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kegaska, Kegashka, Quebec, Canada, ZKG',
    key: 'ZKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kelowna International, Canada, YLW',
    key: 'YLW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kemerovo International, Russia, KEJ',
    key: 'KEJ',
  },
  { icon: 'faPlaneDeparture', label: 'Kemi-Tornio, Finland, KEM', key: 'KEM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kenai Municipal, United States, ENA',
    key: 'ENA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kengtung, Myanmar (Burma), KET',
    key: 'KET',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kenmore Air Harbor, United States, KEH',
    key: 'KEH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kenmore Air Harbor Seaplane Base, Seattle, United States, LKE',
    key: 'LKE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kenneth Kaunda International, Lusaka, Zambia, LUN',
    key: 'LUN',
  },
  { icon: 'faPlaneDeparture', label: 'Kenora, Canada, YQK', key: 'YQK' },
  { icon: 'faPlaneDeparture', label: 'Keperveyem, Russia, KPW', key: 'KPW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kephalonia International, Cephalonia, Greece, EFL',
    key: 'EFL',
  },
  { icon: 'faPlaneDeparture', label: 'Kerikeri, New Zealand, KKE', key: 'KKE' },
  { icon: 'faPlaneDeparture', label: 'Kerman, Iran, KER', key: 'KER' },
  { icon: 'faPlaneDeparture', label: 'Kermanshah, Iran, KSH', key: 'KSH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kerry, County Kerry, Ireland, KIR',
    key: 'KIR',
  },
  { icon: 'faPlaneDeparture', label: 'Kerteh, Malaysia, KTE', key: 'KTE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ketapang Rahadi Osman, Indonesia, KTG',
    key: 'KTG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ketchikan International, United States, KTN',
    key: 'KTN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Key West International, United States, EYW',
    key: 'EYW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Khabarovsk Novy, Russia, KHV',
    key: 'KHV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Khajuraho, Khajuraho Group of Monuments, India, HJR',
    key: 'HJR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Khamti, Myanmar (Burma), KHM',
    key: 'KHM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Khandyga Teply Klyuch, Russia, KDY',
    key: 'KDY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Khanty-Mansiysk, Russia, HMA',
    key: 'HMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kharkiv International, Ukraine, HRK',
    key: 'HRK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Khartoum International, Sudan, KRT',
    key: 'KRT',
  },
  { icon: 'faPlaneDeparture', label: 'Khasab, Oman, KHS', key: 'KHS' },
  { icon: 'faPlaneDeparture', label: 'Khatanga, Russia, HTG', key: 'HTG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kherson International, Ukraine, KHE',
    key: 'KHE',
  },
  { icon: 'faPlaneDeparture', label: 'Khon Kaen, Thailand, KKC', key: 'KKC' },
  { icon: 'faPlaneDeparture', label: 'Khorramabad, Iran, KHD', key: 'KHD' },
  { icon: 'faPlaneDeparture', label: 'Khovd, Mongolia, HVD', key: 'HVD' },
  { icon: 'faPlaneDeparture', label: 'Khoy Airport, Iran, KHY', key: 'KHY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Khrabrovo, Kaliningrad, Russia, KGD',
    key: 'KGD',
  },
  { icon: 'faPlaneDeparture', label: 'Khujand, Tajikistan, LBD', key: 'LBD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kigali International, Rwanda, KGL',
    key: 'KGL',
  },
  { icon: 'faPlaneDeparture', label: 'Kigoma, Tanzania, TKQ', key: 'TKQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kilimanjaro International, Mount Kilimanjaro, Tanzania, JRO',
    key: 'JRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Killeen–Fort Hood Regional, United States, GRK',
    key: 'GRK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kilwa Masoko, Kilwa Kisiwani, Tanzania, KIY',
    key: 'KIY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kimberley, Kimberley, Northern Cape, South Africa, KIM',
    key: 'KIM',
  },
  { icon: 'faPlaneDeparture', label: 'Kimmirut, Canada, YLC', key: 'YLC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kindu, Democratic Republic of the Congo, KND',
    key: 'KND',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Abdulaziz International, Jeddah, Saudi Arabia, JED',
    key: 'JED',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Cove, United States, KVC',
    key: 'KVC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Fahd International, Dammam, Saudi Arabia, DMM',
    key: 'DMM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Hussein International, Aqaba, Jordan, AQJ',
    key: 'AQJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Island, Australia, KNS',
    key: 'KNS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Khalid International, Riyadh, Saudi Arabia, RUH',
    key: 'RUH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Khalid Military City, Saudi Arabia, KMC',
    key: 'KMC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Mswati III International, Manzini, Swaziland, SHO',
    key: 'SHO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'King Salmon, United States, AKN',
    key: 'AKN',
  },
  { icon: 'faPlaneDeparture', label: 'Kingscote, Australia, KGC', key: 'KGC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kingston/Norman Rogers, Canada, YGK',
    key: 'YGK',
  },
  { icon: 'faPlaneDeparture', label: 'Kinmen, Taiwan, KNH', key: 'KNH' },
  { icon: 'faPlaneDeparture', label: 'Kipnuk, United States, KPN', key: 'KPN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kirakira, Solomon Islands, IRA',
    key: 'IRA',
  },
  { icon: 'faPlaneDeparture', label: 'Kirensk, Russia, KCK', key: 'KCK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kirkenes Airport, Høybuktmoen, Norway, KKN',
    key: 'KKN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kirksville Regional, United States, IRK',
    key: 'IRK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kirkwall, United Kingdom, KOI',
    key: 'KOI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kirovsk-Apatity, Russia, KVK',
    key: 'KVK',
  },
  { icon: 'faPlaneDeparture', label: 'Kiruna, Sweden, KRN', key: 'KRN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kish International, Kish Island, Iran, KIH',
    key: 'KIH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kisimayu, Kismayo, Somalia, KMU',
    key: 'KMU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kisumu International, Kenya, KIS',
    key: 'KIS',
  },
  { icon: 'faPlaneDeparture', label: 'Kitakyushu, Japan, KKJ', key: 'KKJ' },
  { icon: 'faPlaneDeparture', label: 'Kitale, Kenya, KTL', key: 'KTL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kithira Island National, Kythira, Greece, KIT',
    key: 'KIT',
  },
  { icon: 'faPlaneDeparture', label: 'Kittilä, Finland, KTT', key: 'KTT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kiunga, Papua New Guinea, UNG',
    key: 'UNG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kivalina, United States, KVL',
    key: 'KVL',
  },
  { icon: 'faPlaneDeparture', label: 'Klagenfurt, Austria, KLU', key: 'KLU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Klawock, United States, KLW',
    key: 'KLW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Knox County Regional, Rockland, United States, RKD',
    key: 'RKD',
  },
  { icon: 'faPlaneDeparture', label: 'Kobe, Japan, UKB', key: 'UKB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Koblenz-Winningen Airport, Germany, ZNV',
    key: 'ZNV',
  },
  { icon: 'faPlaneDeparture', label: 'Kobuk, United States, OBU', key: 'OBU' },
  { icon: 'faPlaneDeparture', label: 'Kōchi, Kochi, Japan, KCZ', key: 'KCZ' },
  { icon: 'faPlaneDeparture', label: 'Kodiak, United States, ADQ', key: 'ADQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kogalym International, Russia, KGP',
    key: 'KGP',
  },
  { icon: 'faPlaneDeparture', label: 'Koggala, Sri Lanka, KCT', key: 'KCT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kokhanok, Kakhonak, United States, KNK',
    key: 'KNK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kokkola-Pietarsaari, Finland, KOK',
    key: 'KOK',
  },
  { icon: 'faPlaneDeparture', label: 'Kokshetau, Kazakhstan, KOV', key: 'KOV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kolaka Pomala, Pomalaa, Indonesia, PUM',
    key: 'PUM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Koliganek, United States, KGK',
    key: 'KGK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Koltsovo, Yekaterinburg, Russia, SVX',
    key: 'SVX',
  },
  { icon: 'faPlaneDeparture', label: 'Komatsu, Japan, KMQ', key: 'KMQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Komodo, Labuan Bajo, Indonesia, LBJ',
    key: 'LBJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Komsomolsk-on-Amur, Russia, KXK',
    key: 'KXK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kona International, Kailua, United States, KOA',
    key: 'KOA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Konarak, Chabahar, Iran, ZBR',
    key: 'ZBR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kone Airport, New Caledonia, KNQ',
    key: 'KNQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kongiganak, United States, KKH',
    key: 'KKH',
  },
  { icon: 'faPlaneDeparture', label: 'Konya, Turkey, KYA', key: 'KYA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Korhogo, Côte d’Ivoire, HGO',
    key: 'HGO',
  },
  { icon: 'faPlaneDeparture', label: 'Korla, China, KRL', key: 'KRL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Koro Island Airport, Fiji, KXF',
    key: 'KXF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kos Island International, Greece, KGS',
    key: 'KGS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Košice International, Slovakia, KSC',
    key: 'KSC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kosrae International, Micronesia, KSA',
    key: 'KSA',
  },
  { icon: 'faPlaneDeparture', label: 'Kostanay, Kazakhstan, KSN', key: 'KSN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kota Kinabalu International, Malaysia, BKI',
    key: 'BKI',
  },
  { icon: 'faPlaneDeparture', label: 'Kotlas, Russia, KSZ', key: 'KSZ' },
  { icon: 'faPlaneDeparture', label: 'Kotlik, United States, KOT', key: 'KOT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kotoka International, Accra, Ghana, ACC',
    key: 'ACC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kotte Diyawanna Oya SPB, Sri Jayawardenepura Kotte, Sri Lanka, DWO',
    key: 'DWO',
  },
  { icon: 'faPlaneDeparture', label: 'Koumac, New Caledonia, KOC', key: 'KOC' },
  { icon: 'faPlaneDeparture', label: 'Kowanyama, Australia, KWM', key: 'KWM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Koyuk Alfred Adams, United States, KKA',
    key: 'KKA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Koyukuk, United States, KYU',
    key: 'KYU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kozani National, Greece, KZI',
    key: 'KZI',
  },
  { icon: 'faPlaneDeparture', label: 'Krabi, Thailand, KBV', key: 'KBV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Krasnodar International, Russia, KRR',
    key: 'KRR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Krayniy, Baikonur, Kazakhstan, BXY',
    key: 'BXY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kristiansand Airport, Kjevik, Norway, KRS',
    key: 'KRS',
  },
  { icon: 'faPlaneDeparture', label: 'Kristianstad, Sweden, KID', key: 'KID' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kristiansund Airport, Kvernberget, Norway, KSU',
    key: 'KSU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kruger Mpumalanga International, Nelspruit, South Africa, MQP',
    key: 'MQP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kuala Lumpur International, Malaysia, KUL',
    key: 'KUL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kualanamu International, Medan, Indonesia, KNO',
    key: 'KNO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kuching International, Malaysia, KCH',
    key: 'KCH',
  },
  { icon: 'faPlaneDeparture', label: 'Kudat, Malaysia, KUD', key: 'KUD' },
  { icon: 'faPlaneDeparture', label: 'Kufra, Kufrah, Libya, AKF', key: 'AKF' },
  { icon: 'faPlaneDeparture', label: 'Kugaaruk, Canada, YBB', key: 'YBB' },
  { icon: 'faPlaneDeparture', label: 'Kugluktuk, Canada, YCO', key: 'YCO' },
  { icon: 'faPlaneDeparture', label: 'Kuito, Angola, SVP', key: 'SVP' },
  { icon: 'faPlaneDeparture', label: 'Kullu–Manali, India, KUU', key: 'KUU' },
  { icon: 'faPlaneDeparture', label: 'Kulob, Tajikistan, TJU', key: 'TJU' },
  { icon: 'faPlaneDeparture', label: 'Kulusuk, Greenland, KUS', key: 'KUS' },
  { icon: 'faPlaneDeparture', label: 'Kumamoto, Japan, KMJ', key: 'KMJ' },
  { icon: 'faPlaneDeparture', label: 'Kumasi, Ghana, KMS', key: 'KMS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kumejima, Kume Island, Japan, UEO',
    key: 'UEO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kunming Changshui International, China, KMG',
    key: 'KMG',
  },
  { icon: 'faPlaneDeparture', label: 'Kuopio, Finland, KUO', key: 'KUO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kuqa Qiuci, Kuqa County, China, KCA',
    key: 'KCA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kurgan, Kurgan, Kurgan Oblast, Russia, KRO',
    key: 'KRO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kursk Vostochny, Russia, URS',
    key: 'URS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kurumoch International, Samara, Russia, KUF',
    key: 'KUF',
  },
  { icon: 'faPlaneDeparture', label: 'Kushiro, Japan, KUH', key: 'KUH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kushok Bakula Rimpochee, Leh, Unknown Region, IXL',
    key: 'IXL',
  },
  { icon: 'faPlaneDeparture', label: 'Kuujjuaq, Canada, YVP', key: 'YVP' },
  { icon: 'faPlaneDeparture', label: 'Kuujjuarapik, Canada, YGW', key: 'YGW' },
  { icon: 'faPlaneDeparture', label: 'Kuusamo, Finland, KAO', key: 'KAO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Kuwait International, Kuwait City, Kuwait, KWI',
    key: 'KWI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kwethluk, United States, KWT',
    key: 'KWT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kwigillingok, United States, KWK',
    key: 'KWK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kyaukpyu, Myanmar (Burma), KYP',
    key: 'KYP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Kyiv International Airport (Zhuliany), Kiev, Ukraine, IEV',
    key: 'IEV',
  },
  { icon: 'faPlaneDeparture', label: 'Kyzyl, Russia, KYZ', key: 'KYZ' },
  { icon: 'faPlaneDeparture', label: 'Kyzylorda, Kazakhstan, KZO', key: 'KZO' },
  {
    icon: 'faPlaneDeparture',
    label: 'L Mekrareg, Laghouat, Algeria, LOO',
    key: 'LOO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'L. M. Clayton, Wolf Point, United States, OLF',
    key: 'OLF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'L.F. Wade International, Bermuda, United Kingdom, BDA',
    key: 'BDA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Araucanía International, Temuco, Chile, ZCO',
    key: 'ZCO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Aurora International, Guatemala City, Guatemala, GUA',
    key: 'GUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Chinita International, Maracaibo, Venezuela, MAR',
    key: 'MAR',
  },
  { icon: 'faPlaneDeparture', label: 'La Chorrera, Colombia, LCR', key: 'LCR' },
  {
    icon: 'faPlaneDeparture',
    label: 'La Crosse Regional, United States, LSE',
    key: 'LSE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Florida, La Serena, Chile, LSC',
    key: 'LSC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Florida, Tumaco, Colombia, TCO',
    key: 'TCO',
  },
  { icon: 'faPlaneDeparture', label: 'La Fría, Venezuela, LFR', key: 'LFR' },
  {
    icon: 'faPlaneDeparture',
    label: 'La Gomera, San Sebastián de La Gomera, Spain, GMZ',
    key: 'GMZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Grande Rivière, La Grande River, Canada, YGL',
    key: 'YGL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Isabela International, Santo Domingo, Dominican Republic, JBQ',
    key: 'JBQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Laguna Airport, Guanaja, Honduras, GJA',
    key: 'GJA',
  },
  { icon: 'faPlaneDeparture', label: 'La Macarena, Colombia, LMC', key: 'LMC' },
  {
    icon: 'faPlaneDeparture',
    label: 'La Nubia, Manizales, Colombia, MZL',
    key: 'MZL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Palma, Santa Cruz de La Palma, Spain, SPC',
    key: 'SPC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Paz International, Mexico, LAP',
    key: 'LAP',
  },
  { icon: 'faPlaneDeparture', label: 'La Pedrera, Colombia, LPD', key: 'LPD' },
  { icon: 'faPlaneDeparture', label: 'La Rioja, Argentina, IRJ', key: 'IRJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'La Rochelle – Île de Ré, France, LRH',
    key: 'LRH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Romaine, La Romaine, Quebec, Canada, ZGS',
    key: 'ZGS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Romana International, Dominican Republic, LRM',
    key: 'LRM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Tabatière, La Tabatière, Quebec, Canada, ZLT',
    key: 'ZLT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Tontouta International, Nouméa, New Caledonia, NOU',
    key: 'NOU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'La Vanguardia, Villavicencio, Colombia, VVC',
    key: 'VVC',
  },
  { icon: 'faPlaneDeparture', label: 'Labasa, Fiji, LBS', key: 'LBS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Labo, Ozamiz, Philippines, OZC',
    key: 'OZC',
  },
  { icon: 'faPlaneDeparture', label: 'Labuan, Malaysia, LBU', key: 'LBU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lac Brochet, Lac Brochet, Manitoba, Canada, XLB',
    key: 'XLB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lac Brochet Airport, Lac Brochet, Manitoba, Canada, ZWH',
    key: 'ZWH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lae Nadzab, Papua New Guinea, LAE',
    key: 'LAE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lafayette Regional, United States, LFT',
    key: 'LFT',
  },
  { icon: 'faPlaneDeparture', label: 'Lages, Brazil, LAJ', key: 'LAJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lago Agrio, Lago Agrio Canton, Ecuador, LGQ',
    key: 'LGQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'LaGuardia, New York City, United States, LGA',
    key: 'LGA',
  },
  { icon: 'faPlaneDeparture', label: 'Lahad Datu, Malaysia, LDU', key: 'LDU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lahat Pagar Alam, Lahat Regency, Indonesia, PXA',
    key: 'PXA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lajes Field, Terceira Island, Portugal, TER',
    key: 'TER',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lake Charles Regional, United States, LCH',
    key: 'LCH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lake Manyara, Tanzania, LKY',
    key: 'LKY',
  },
  { icon: 'faPlaneDeparture', label: 'Lakeba, Fiji, LKB', key: 'LKB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lakselv Airport, Banak, Norway, LKL',
    key: 'LKL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lal Bahadur Shastri International, Varanasi, India, VNS',
    key: 'VNS',
  },
  { icon: 'faPlaneDeparture', label: 'Lalibela, Ethiopia, LLI', key: 'LLI' },
  { icon: 'faPlaneDeparture', label: 'Lamen Bay, Vanuatu, LNB', key: 'LNB' },
  { icon: 'faPlaneDeparture', label: 'Lamerd, Iran, LFM', key: 'LFM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lamezia Terme International, Italy, SUF',
    key: 'SUF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lampang, Lampang Province, Thailand, LPT',
    key: 'LPT',
  },
  { icon: 'faPlaneDeparture', label: 'Lampedusa, Italy, LMP', key: 'LMP' },
  { icon: 'faPlaneDeparture', label: 'Lanai, United States, LNY', key: 'LNY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Langkawi International, Malaysia, LGK',
    key: 'LGK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lankaran International, Azerbaijan, LLK',
    key: 'LLK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lannion – Côte de Granit, France, LAI',
    key: 'LAI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lanseria International, Johannesburg, South Africa, HLA',
    key: 'HLA',
  },
  { icon: 'faPlaneDeparture', label: 'Lanzarote, Spain, ACE', key: 'ACE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lanzhou Zhongchuan International, China, LHW',
    key: 'LHW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Laoag International, Philippines, LAO',
    key: 'LAO',
  },
  { icon: 'faPlaneDeparture', label: 'Lappeenranta, Finland, LPP', key: 'LPP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Laramie Regional, United States, LAR',
    key: 'LAR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Laredo International, United States, LRD',
    key: 'LRD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Larestan International, Iran, LRR',
    key: 'LRR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Larnaca International, Cyprus, LCA',
    key: 'LCA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Larsen Bay, United States, KLN',
    key: 'KLN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Las Américas International, Santo Domingo, Dominican Republic, SDQ',
    key: 'SDQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Las Brujas, Corozal, Sucre, Colombia, CZU',
    key: 'CZU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Las Flecheras, San Fernando de Apure, Venezuela, SFD',
    key: 'SFD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Las Higueras, Río Cuarto, Argentina, RCU',
    key: 'RCU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Las Marías, Valdivia, Chile, ZAL',
    key: 'ZAL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Las Termas, Termas de Río Hondo, Argentina, RHD',
    key: 'RHD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lashio, Myanmar (Burma), LSH',
    key: 'LSH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Laughlin/Bullhead International, Bullhead City, United States, IFP',
    key: 'IFP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Launceston, City of Launceston, Australia, LST',
    key: 'LST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lauro Kurtz, Passo Fundo, Brazil, PFB',
    key: 'PFB',
  },
  { icon: 'faPlaneDeparture', label: 'Lawas, Malaysia, LWY', key: 'LWY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lawton–Fort Sill Regional, United States, LAW',
    key: 'LAW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lázaro Cárdenas, Mexico, LZC',
    key: 'LZC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Le Puy – Loudes, Le Puy-en-Velay, France, LPY',
    key: 'LPY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lea County Regional, Hobbs, United States, HOB',
    key: 'HOB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lebanon Municipal, United States, LEB',
    key: 'LEB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Leeds Bradford, United Kingdom, LBA',
    key: 'LBA',
  },
  { icon: 'faPlaneDeparture', label: 'Legazpi, Philippines, LGP', key: 'LGP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lehigh Valley International, Allentown, United States, ABE',
    key: 'ABE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Leipzig/Halle Airport, Germany, LEJ',
    key: 'LEJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Leite Lopes, Ribeirão Preto, Brazil, RAO',
    key: 'RAO',
  },
  { icon: 'faPlaneDeparture', label: 'Leknes, Norway, LKN', key: 'LKN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lemnos International, Greece, LXS',
    key: 'LXS',
  },
  { icon: 'faPlaneDeparture', label: 'Lençóis, Brazil, LEC', key: 'LEC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lengpui, Aizawl, India, AJL',
    key: 'AJL',
  },
  { icon: 'faPlaneDeparture', label: 'Lensk, Russia, ULK', key: 'ULK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Leo Wattimena, Morotai, Indonesia, OTI',
    key: 'OTI',
  },
  { icon: 'faPlaneDeparture', label: 'León, Spain, LEN', key: 'LEN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Leonardo da Vinci–Fiumicino Airport, Rome, Italy, FCO',
    key: 'FCO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Léopold Sédar Senghor International, Dakar, Senegal, DKR',
    key: 'DKR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Leoš Janáček Airport Ostrava, Czechia, OSR',
    key: 'OSR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Leros Municipal, Greece, LRS',
    key: 'LRS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Les Bases Airport, Grand Bourg, France, GBJ',
    key: 'GBJ',
  },
  { icon: 'faPlaneDeparture', label: 'Lethbridge, Canada, YQL', key: 'YQL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Levelock, United States, KLL',
    key: 'KLL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lewiston–Nez Perce County, United States, LWS',
    key: 'LWS',
  },
  { icon: 'faPlaneDeparture', label: 'Lhasa Gonggar, China, LXA', key: 'LXA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lianyungang Baitabu, China, LYG',
    key: 'LYG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Liberal Mid-America Regional, United States, LBL',
    key: 'LBL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Libertador General José de San Martín, Posadas, Argentina, PSS',
    key: 'PSS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Libo, Libo County, China, LLB',
    key: 'LLB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Libreville International, Gabon, LBV',
    key: 'LBV',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Licenciado Gustavo Díaz Ordaz International, Puerto Vallarta, Mexico, PVR',
    key: 'PVR',
  },
  { icon: 'faPlaneDeparture', label: 'Lichinga, Mozambique, VXC', key: 'VXC' },
  { icon: 'faPlaneDeparture', label: 'Liège, Belgium, LGG', key: 'LGG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lien Khuong, Da Lat, Vietnam, DLI',
    key: 'DLI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Liepāja International, Latvia, LPX',
    key: 'LPX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lihir Island, Lihir Group, Papua New Guinea, LNV',
    key: 'LNV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lihue, Kauai, United States, LIH',
    key: 'LIH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lijiang Sanyi International, China, LJG',
    key: 'LJG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lilabari, Lakhimpur district, India, IXI',
    key: 'IXI',
  },
  { icon: 'faPlaneDeparture', label: 'Lille, France, LIL', key: 'LIL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lilongwe International, Malawi, LLW',
    key: 'LLW',
  },
  { icon: 'faPlaneDeparture', label: 'Limbang, Malaysia, LMN', key: 'LMN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Limoges – Bellegarde, France, LIG',
    key: 'LIG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Limón International, Puerto Limón, Costa Rica, LIO',
    key: 'LIO',
  },
  { icon: 'faPlaneDeparture', label: 'Linate, Milan, Italy, LIN', key: 'LIN' },
  { icon: 'faPlaneDeparture', label: 'Lincang, China, LNJ', key: 'LNJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lincoln, United States, LNK',
    key: 'LNK',
  },
  { icon: 'faPlaneDeparture', label: 'Linfen Qiaoli, China, LFQ', key: 'LFQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Linköping City, Sweden, LPI',
    key: 'LPI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Linyi Shubuling, China, LYI',
    key: 'LYI',
  },
  { icon: 'faPlaneDeparture', label: 'Linz, Austria, LNZ', key: 'LNZ' },
  { icon: 'faPlaneDeparture', label: 'Lipetsk, Russia, LPK', key: 'LPK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Liping, Liping County, China, HZH',
    key: 'HZH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lisala, Democratic Republic of the Congo, LIQ',
    key: 'LIQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lisbon Portela, Portugal, LIS',
    key: 'LIS',
  },
  { icon: 'faPlaneDeparture', label: 'Lismore, Australia, LSY', key: 'LSY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Liupanshui Yuezhao, China, LPF',
    key: 'LPF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Liuzhou Bailian, China, LZH',
    key: 'LZH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Liverpool John Lennon, United Kingdom, LPL',
    key: 'LPL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ljubljana Jože Pučnik, Slovenia, LJU',
    key: 'LJU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lleida–Alguaire, Spain, ILD',
    key: 'ILD',
  },
  { icon: 'faPlaneDeparture', label: 'Lloydminster, Canada, YLL', key: 'YLL' },
  { icon: 'faPlaneDeparture', label: 'Lodwar, Kenya, LOK', key: 'LOK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Łódź Władysław Reymont, Poland, LCJ',
    key: 'LCJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Loei, Loei Province, Thailand, LOE',
    key: 'LOE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Logan International, Boston, United States, BOS',
    key: 'BOS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Logroño–Agoncillo, Spain, RJL',
    key: 'RJL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Loikaw, Myanmar (Burma), LIW',
    key: 'LIW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lokpriya Gopinath Bordoloi International, Guwahati, India, GAU',
    key: 'GAU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lombok International, Praya, Lombok, Indonesia, LOP',
    key: 'LOP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lomé–Tokoin International, Togo, LFW',
    key: 'LFW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Londolozi, Londolozi Private Game Reserve, South Africa, LDZ',
    key: 'LDZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'London City, United Kingdom, LCY',
    key: 'LCY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'London International, Canada, YXU',
    key: 'YXU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'London Oxford Airport, United Kingdom, OXF',
    key: 'OXF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'London Southend, United Kingdom, SEN',
    key: 'SEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'London Stansted, United Kingdom, STN',
    key: 'STN',
  },
  { icon: 'faPlaneDeparture', label: 'Londrina, Brazil, LDB', key: 'LDB' },
  { icon: 'faPlaneDeparture', label: 'Long Akah, Malaysia, LKH', key: 'LKH' },
  { icon: 'faPlaneDeparture', label: 'Long Banga, Malaysia, LBP', key: 'LBP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Long Beach, United States, LGB',
    key: 'LGB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Long Island MacArthur, Islip, United States, ISP',
    key: 'ISP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Long Lellang, Malaysia, LGL',
    key: 'LGL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Long Seridan, Malaysia, ODN',
    key: 'ODN',
  },
  { icon: 'faPlaneDeparture', label: 'Longana, Vanuatu, LOD', key: 'LOD' },
  { icon: 'faPlaneDeparture', label: 'Longreach, Australia, LRE', key: 'LRE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Longyan Guanzhishan, China, LCX',
    key: 'LCX',
  },
  { icon: 'faPlaneDeparture', label: 'Lonorore, Vanuatu, LNE', key: 'LNE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lopez Island, United States, LPS',
    key: 'LPS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lord Howe Island, Australia, LDH',
    key: 'LDH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Loreto International, Mexico, LTO',
    key: 'LTO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lorient South Brittany, France, LRT',
    key: 'LRT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Los Angeles International, United States, LAX',
    key: 'LAX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Los Cabos International, San José del Cabo, Mexico, SJD',
    key: 'SJD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Los Colonizadores, Saravena, Colombia, RVE',
    key: 'RVE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Los Garzones, Montería, Colombia, MTR',
    key: 'MTR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Los Mochis International, Mexico, LMM',
    key: 'LMM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Losuia, Kiriwina, Papua New Guinea, LSA',
    key: 'LSA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Louang Namtha, Luang Namtha, Laos, LXG',
    key: 'LXG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Louis Armstrong New Orleans International, United States, MSY',
    key: 'MSY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Louisville International, United States, SDF',
    key: 'SDF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lourdes-de-Blanc-Sablon, Canada, YBX',
    key: 'YBX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Luang Prabang International, Laos, LPQ',
    key: 'LPQ',
  },
  { icon: 'faPlaneDeparture', label: 'Lubango, Angola, SDD', key: 'SDD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lubbock Preston Smith International, United States, LBB',
    key: 'LBB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lubeck Blankensee Airport, Germany, LBC',
    key: 'LBC',
  },
  { icon: 'faPlaneDeparture', label: 'Lublin, Poland, LUZ', key: 'LUZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lubumbashi International, Democratic Republic of the Congo, FBM',
    key: 'FBM',
  },
  { icon: 'faPlaneDeparture', label: 'Lüderitz, Namibia, LUD', key: 'LUD' },
  { icon: 'faPlaneDeparture', label: 'Luena, Angola, LUO', key: 'LUO' },
  { icon: 'faPlaneDeparture', label: 'Lugano, Switzerland, LUG', key: 'LUG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Luis Muñoz Marín International, San Juan, United States, SJU',
    key: 'SJU',
  },
  { icon: 'faPlaneDeparture', label: 'Luleå, Sweden, LLA', key: 'LLA' },
  { icon: 'faPlaneDeparture', label: 'Lüliang, China, LLV', key: 'LLV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lumbia Air Base, Cagayan de Oro, Philippines, CGY',
    key: 'CGY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lungi International, Freetown, Sierra Leone, FNA',
    key: 'FNA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Luoyang Beijiao, China, LYA',
    key: 'LYA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Luton, London, United Kingdom, LTN',
    key: 'LTN',
  },
  { icon: 'faPlaneDeparture', label: "Lutselk'e, Canada, YSG", key: 'YSG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Luxembourg, Luxembourg City, Luxembourg, LUX',
    key: 'LUX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Luxor International, Egypt, LXR',
    key: 'LXR',
  },
  { icon: 'faPlaneDeparture', label: 'Luzhou Yunlong, China, LZO', key: 'LZO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lviv Danylo Halytskyi International, Ukraine, LWO',
    key: 'LWO',
  },
  { icon: 'faPlaneDeparture', label: 'Lycksele, Sweden, LYC', key: 'LYC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Lynchburg Regional, United States, LYH',
    key: 'LYH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lynden Pindling International, Nassau, Bahamas, NAS',
    key: 'NAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Lyon–Saint-Exupéry, France, LYS',
    key: 'LYS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maastricht Aachen, Netherlands, MST',
    key: 'MST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Macapá International, Brazil, MCP',
    key: 'MCP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Macau International, China, MFM',
    key: 'MFM',
  },
  { icon: 'faPlaneDeparture', label: 'Mackay, Australia, MKY', key: 'MKY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mactan–Cebu International, Philippines, CEB',
    key: 'CEB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Madang, Papua New Guinea, MAG',
    key: 'MAG',
  },
  { icon: 'faPlaneDeparture', label: 'Madurai, India, IXM', key: 'IXM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mae Hong Son, Mae Hong Son Province, Thailand, HGN',
    key: 'HGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mae Sot, Mae Sot District, Thailand, MAQ',
    key: 'MAQ',
  },
  { icon: 'faPlaneDeparture', label: 'Maewo-Naone, Vanuatu, MWF', key: 'MWF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mafia, Mafia Island, Tanzania, MFA',
    key: 'MFA',
  },
  { icon: 'faPlaneDeparture', label: 'Magan, Russia, GYG', key: 'GYG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Magic Valley Regional, Twin Falls, United States, TWF',
    key: 'TWF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Magnitogorsk International, Russia, MQF',
    key: 'MQF',
  },
  { icon: 'faPlaneDeparture', label: 'Magong, Taiwan, MZG', key: 'MZG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Maharana Pratap, Udaipur, India, UDR',
    key: 'UDR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mahayogi Gorakhnath, Gorakhpur, India, GOP',
    key: 'GOP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mahikeng, Mmabatho, South Africa, MBD',
    key: 'MBD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maiduguri International Airport, Nigeria, MIU',
    key: 'MIU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maimun Saleh, Sabang, Indonesia, SBG',
    key: 'SBG',
  },
  { icon: 'faPlaneDeparture', label: 'Maio, Cape Verde, MMO', key: 'MMO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Major Brigadeiro Trompowsky, Varginha, Brazil, VAG',
    key: 'VAG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Makemo, French Polynesia, MKP',
    key: 'MKP',
  },
  { icon: 'faPlaneDeparture', label: 'Makkovik, Canada, YMN', key: 'YMN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Malabo International, Equatorial Guinea, SSG',
    key: 'SSG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Malacca International, Malacca City, Malaysia, MKZ',
    key: 'MKZ',
  },
  { icon: 'faPlaneDeparture', label: 'Málaga, Spain, AGP', key: 'AGP' },
  { icon: 'faPlaneDeparture', label: 'Malatya Erhaç, Turkey, MLX', key: 'MLX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Malekoula, Lamap, Vanuatu, LPM',
    key: 'LPM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Malikus Saleh, Lhokseumawe, Indonesia, LSW',
    key: 'LSW',
  },
  { icon: 'faPlaneDeparture', label: 'Malindi, Kenya, MYD', key: 'MYD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mallam Aminu Kano International, Nigeria, KAN',
    key: 'KAN',
  },
  { icon: 'faPlaneDeparture', label: 'Malmö, Sweden, MMX', key: 'MMX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Malta International, Malta, MLA',
    key: 'MLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mammoth Yosemite, Mammoth Lakes, United States, MMH',
    key: 'MMH',
  },
  { icon: 'faPlaneDeparture', label: 'Man, Côte d’Ivoire, MJC', key: 'MJC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Manas International, Bishkek, Kyrgyzstan, FRU',
    key: 'FRU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manchester, United Kingdom, MAN',
    key: 'MAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manchester–Boston Regional, United States, MHT',
    key: 'MHT',
  },
  { icon: 'faPlaneDeparture', label: 'Manda, Lamu, Kenya, LAU', key: 'LAU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mandalay International, Myanmar (Burma), MDL',
    key: 'MDL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mangaia Island Airport, Cook Islands, MGS',
    key: 'MGS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mangaluru, Mangalore, India, IXE',
    key: 'IXE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manhattan Regional, United States, MHK',
    key: 'MHK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manihi, French Polynesia, XMH',
    key: 'XMH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manihiki Island Airport, Cook Islands, MHX',
    key: 'MHX',
  },
  { icon: 'faPlaneDeparture', label: 'Maniitsoq, Greenland, JSU', key: 'JSU' },
  { icon: 'faPlaneDeparture', label: 'Maningrida, Australia, MNG', key: 'MNG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Manistee County Blacker, United States, MBL',
    key: 'MBL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manley Hot Springs, United States, MLY',
    key: 'MLY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mano Dayak International, Agadez, Niger, AJY',
    key: 'AJY',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Manuel Carlos Piar Guayana, Puerto Ordaz and San Felix, Venezuela, PZO',
    key: 'PZO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Manzhouli Xijiao, China, NZH',
    key: 'NZH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maputo International, Mozambique, MPM',
    key: 'MPM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mara Serena, Maasai Mara, Kenya, MRE',
    key: 'MRE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marabá, Marabá, Pará, Brazil, MAB',
    key: 'MAB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marana Regional Airport, Tucson, United States, AVW',
    key: 'AVW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marau, Marau Sound, Solomon Islands, RUS',
    key: 'RUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marche Airport, Ancona, Italy, AOI',
    key: 'AOI',
  },
  { icon: 'faPlaneDeparture', label: 'Mardin, Turkey, MQM', key: 'MQM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mare Airport, New Caledonia, MEE',
    key: 'MEE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marechal Cunha Machado International, São Luís, Brazil, SLZ',
    key: 'SLZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marechal Rondon International, Cuiabá, Brazil, CGB',
    key: 'CGB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Margaret Ekpo International, Calabar, Nigeria, CBQ',
    key: 'CBQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Margate, Margate, KwaZulu-Natal, South Africa, MGH',
    key: 'MGH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mariana Grajales, Guantánamo, Cuba, GAO',
    key: 'GAO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maribor Edvard Rusjan, Slovenia, MBX',
    key: 'MBX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mariehamn, Åland Islands, MHQ',
    key: 'MHQ',
  },
  { icon: 'faPlaneDeparture', label: 'Marília, Brazil, MII', key: 'MII' },
  {
    icon: 'faPlaneDeparture',
    label: 'Marina di Campo, Campo nell Elba, Italy, EBA',
    key: 'EBA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maringá Regional, Brazil, MGF',
    key: 'MGF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mariscal Lamar International, Cuenca, Ecuador, CUE',
    key: 'CUE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mariscal Sucre International, Quito, Ecuador, UIO',
    key: 'UIO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maroantsetra, Madagascar, WMN',
    key: 'WMN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marrakesh Menara, Morocco, RAK',
    key: 'RAK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marsa Alam International, Egypt, RMF',
    key: 'RMF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marseille Provence, France, MRS',
    key: 'MRS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marsh Harbour, Bahamas, MHH',
    key: 'MHH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marshall Don Hunter Sr., United States, MLL',
    key: 'MLL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Marshall Islands International, Majuro, Marshall Islands, MAJ',
    key: 'MAJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Martha's Vineyard, United States, MVY",
    key: 'MVY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Martín Miguel de Güemes International, Salta, Argentina, SLA',
    key: 'SLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Martinique Aimé Césaire International, Fort-de-France, France, FDF',
    key: 'FDF',
  },
  { icon: 'faPlaneDeparture', label: 'Marudi, Malaysia, MUR', key: 'MUR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mary International, Turkmenistan, MYP',
    key: 'MYP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mashhad International, Iran, MHD',
    key: 'MHD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mason City Municipal, United States, MCW',
    key: 'MCW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Massena International, United States, MSS',
    key: 'MSS',
  },
  { icon: 'faPlaneDeparture', label: 'Masset, Canada, ZMT', key: 'ZMT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mataiva, French Polynesia, MVT',
    key: 'MVT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Matak Tarempa, Indonesia, MWK',
    key: 'MWK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Matamoros International, Mexico, MAM',
    key: 'MAM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Matari, Isiro, Democratic Republic of the Congo, IRP',
    key: 'IRP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mataveri International, Easter Island, Chile, IPC',
    key: 'IPC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Matecaña International, Pereira, Colombia, PEI',
    key: 'PEI',
  },
  { icon: 'faPlaneDeparture', label: 'Matei, Taveuni, Fiji, TVU', key: 'TVU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Matsu Beigan, Matsu Islands, Taiwan, MFK',
    key: 'MFK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Matsu Nangan, Nangan, Lienchiang, Taiwan, LZN',
    key: 'LZN',
  },
  { icon: 'faPlaneDeparture', label: 'Matsumoto, Japan, MMJ', key: 'MMJ' },
  { icon: 'faPlaneDeparture', label: 'Matsuyama, Japan, MYJ', key: 'MYJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mattala Rajapaksa International, Hambantota, Sri Lanka, HRI',
    key: 'HRI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mauke Airport, Mauke Island, Cook Islands, MUK',
    key: 'MUK',
  },
  { icon: 'faPlaneDeparture', label: 'Maun, Botswana, MUB', key: 'MUB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Maupiti, French Polynesia, MAU',
    key: 'MAU',
  },
  {
    icon: 'faPlaneDeparture',
    label: "Maurice Bishop International, St George's, Grenada, GND",
    key: 'GND',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mawella Lagoon, Dickwella, Sri Lanka, DIW',
    key: 'DIW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mawlamyine, Myanmar (Burma), MNU',
    key: 'MNU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Maya-Maya, Brazzaville, Republic of the Congo, BZV',
    key: 'BZV',
  },
  { icon: 'faPlaneDeparture', label: 'Mayaguana, Bahamas, MYG', key: 'MYG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mayor Buenaventura Vivas, Santo Domingo, Venezuela, STD',
    key: 'STD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mayor General FAP Armando Revoredo Iglesias, Cajamarca, Peru, CJA',
    key: 'CJA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mazar-i-Sharif International, Afghanistan, MZR',
    key: 'MZR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mazatlán International, Mexico, MZT',
    key: 'MZT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mbandaka, Democratic Republic of the Congo, MDK',
    key: 'MDK',
  },
  { icon: 'faPlaneDeparture', label: 'Mbeya, Tanzania, MBI', key: 'MBI' },
  {
    icon: 'faPlaneDeparture',
    label: 'MBS International, Saginaw, United States, MBS',
    key: 'MBS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mbuji Mayi, Mbuji-Mayi, Democratic Republic of the Congo, MJM',
    key: 'MJM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McAllen Miller International, United States, MFE',
    key: 'MFE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McArthur River Mine, McArthur River zinc mine, Australia, MCV',
    key: 'MCV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McCarran International, Las Vegas, United States, LAS',
    key: 'LAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McClellan–Palomar, San Diego, United States, CLD',
    key: 'CLD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McCook Ben Nelson Regional, United States, MCK',
    key: 'MCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McGhee Tyson, Knoxville, United States, TYS',
    key: 'TYS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McGrath, United States, MCG',
    key: 'MCG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'McKellar–Sipes Regional, Jackson, United States, MKL',
    key: 'MKL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Meadows Field, Bakersfield, United States, BFL',
    key: 'BFL',
  },
  { icon: 'faPlaneDeparture', label: 'Medicine Hat, Canada, YXH', key: 'YXH' },
  { icon: 'faPlaneDeparture', label: 'Mehamn, Norway, MEH', key: 'MEH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mehrabad International, Tehran, Iran, THR',
    key: 'THR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Meixian, Meizhou, China, MXZ',
    key: 'MXZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mejametalana, Maseru, Lesotho, MSU',
    key: 'MSU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mekoryuk, United States, MYU',
    key: 'MYU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Melangguane, Melonguane, Indonesia, MNA',
    key: 'MNA',
  },
  { icon: 'faPlaneDeparture', label: 'Melbourne, Australia, MEL', key: 'MEL' },
  { icon: 'faPlaneDeparture', label: 'Melilla, Spain, MLN', key: 'MLN' },
  { icon: 'faPlaneDeparture', label: 'Memanbetsu, Japan, MMB', key: 'MMB' },
  { icon: 'faPlaneDeparture', label: 'Memmingen, Germany, FMM', key: 'FMM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Memorial Field, Hot Springs, United States, HOT',
    key: 'HOT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Memphis International, United States, MEM',
    key: 'MEM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mendi, Papua New Guinea, MDU',
    key: 'MDU',
  },
  { icon: 'faPlaneDeparture', label: 'Menongue, Angola, SPP', key: 'SPP' },
  { icon: 'faPlaneDeparture', label: 'Menorca, Spain, MAH', key: 'MAH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mercedita, Ponce, United States, PSE',
    key: 'PSE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mérida International, Mexico, MID',
    key: 'MID',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Meridian Regional, United States, MEI',
    key: 'MEI',
  },
  { icon: 'faPlaneDeparture', label: 'Merimbula, Australia, MIM', key: 'MIM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Merle K. (Mudhole) Smith, Cordova, United States, CDV',
    key: 'CDV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mersa Matruh Airport, Egypt, MUH',
    key: 'MUH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Metlakatla Sea Plane Base, United States, MTM',
    key: 'MTM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Metz–Nancy–Lorraine, France, ETZ',
    key: 'ETZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mexicali International, Mexico, MXL',
    key: 'MXL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mexico City International, Mexico, MEX',
    key: 'MEX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Meyers Chuck Sea Plane Base, United States, WMK',
    key: 'WMK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Miami International, United States, MIA',
    key: 'MIA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mianyang Nanjiao, China, MIG',
    key: 'MIG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Michel-Pouliot Gaspé, Canada, YGP',
    key: 'YGP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mid-Ohio Valley Regional, Parkersburg, United States, PKB',
    key: 'PKB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'MidAmerica St. Louis, Belleville, United States, BLV',
    key: 'BLV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Middle Georgia Regional, Macon, United States, MCN',
    key: 'MCN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Middlemount, Australia, MMM',
    key: 'MMM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Midland International Air and Space Port, United States, MAF',
    key: 'MAF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Midtjyllands, Karup, Denmark, KRP',
    key: 'KRP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mihail Kogălniceanu International, Constanța, Romania, CND',
    key: 'CND',
  },
  { icon: 'faPlaneDeparture', label: 'Miho-Yonago, Japan, YGJ', key: 'YGJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Milan Bergamo International Airport, Italy, BGY',
    key: 'BGY',
  },
  { icon: 'faPlaneDeparture', label: 'Milan–Malpensa, Italy, MXP', key: 'MXP' },
  { icon: 'faPlaneDeparture', label: 'Milas–Bodrum, Turkey, BJV', key: 'BJV' },
  { icon: 'faPlaneDeparture', label: 'Mildura, Australia, MQL', key: 'MQL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Milingimbi, Milingimbi Island, Australia, MGT',
    key: 'MGT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Milos Island National, Plaka, Milos, Greece, MLO',
    key: 'MLO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Minangkabau International, Padang, Indonesia, PDG',
    key: 'PDG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Minatitlán/Coatzacoalcos International, Mexico, MTT',
    key: 'MTT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Minchumina, Lake Minchumina, United States, LMA',
    key: 'LMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mineralnye Vody, Russia, MRV',
    key: 'MRV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ministro Pistarini International, Buenos Aires, Argentina, EZE',
    key: 'EZE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Minneapolis–Saint Paul International, United States, MSP',
    key: 'MSP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Minot International, United States, MOT',
    key: 'MOT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Minsk National, Belarus, MSQ',
    key: 'MSQ',
  },
  { icon: 'faPlaneDeparture', label: 'Minsk-1, Belarus, MHP', key: 'MHP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Minto Al Wright, United States, MNT',
    key: 'MNT',
  },
  { icon: 'faPlaneDeparture', label: 'Miri, Malaysia, MYY', key: 'MYY' },
  { icon: 'faPlaneDeparture', label: 'Mirny, Russia, MJZ', key: 'MJZ' },
  { icon: 'faPlaneDeparture', label: 'Misawa, Japan, MSJ', key: 'MSJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Misima Island, Papua New Guinea, MIS',
    key: 'MIS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Misrata, Misurata, Libya, MRA',
    key: 'MRA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Missoula International, United States, MSO',
    key: 'MSO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mitiaro Island Airport, Cook Islands, MOI',
    key: 'MOI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mitiga International, Tripoli, Libya, MJI',
    key: 'MJI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Miyako, Miyakojima, Japan, MMY',
    key: 'MMY',
  },
  { icon: 'faPlaneDeparture', label: 'Miyazaki, Japan, KMI', key: 'KMI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mo i Rana Airport, Røssvoll, Norway, MQN',
    key: 'MQN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mobile Regional, United States, MOB',
    key: 'MOB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mocopulli, Castro, Chile, MHC',
    key: 'MHC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Moenjodaro, Mohenjodaro, Pakistan, MJD',
    key: 'MJD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mohamed Boudiaf International, Constantine, Algeria, CZL',
    key: 'CZL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mohammed V International, Casablanca, Morocco, CMN',
    key: 'CMN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mohe Gulian, Mohe County, China, OHE',
    key: 'OHE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Moi International, Mombasa, Kenya, MBA',
    key: 'MBA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Moises R. Espinosa, Masbate City, Philippines, MBT',
    key: 'MBT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Molde Airport, Årø, Norway, MOL',
    key: 'MOL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Molokai, Hoʻolehua, United States, MKK',
    key: 'MKK',
  },
  { icon: 'faPlaneDeparture', label: 'Moma, Khonuu, Russia, MQJ', key: 'MQJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Momote, Manus Island, Papua New Guinea, MAS',
    key: 'MAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Monaco Heliport, Monte Carlo, Monaco, MCM',
    key: 'MCM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Monastir Habib Bourguiba International, Tunisia, MIR',
    key: 'MIR',
  },
  { icon: 'faPlaneDeparture', label: 'Monbetsu, Japan, MBE', key: 'MBE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Monclova International, Mexico, LOV',
    key: 'LOV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Monghsat, Mong Hsat Township, Myanmar (Burma), MOG',
    key: 'MOG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mongomo Mongomeyen, Equatorial Guinea, GEM',
    key: 'GEM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Monroe Regional, United States, MLU',
    key: 'MLU',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Monseñor Óscar Arnulfo Romero International, San Salvador, El Salvador, SAL',
    key: 'SAL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mont Tremblant International, Mont-Tremblant, Canada, YTM',
    key: 'YTM',
  },
  { icon: 'faPlaneDeparture', label: 'Mont-Joli, Canada, YYY', key: 'YYY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Monterey Regional, United States, MRY',
    key: 'MRY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Monterrey International, Mexico, MTY',
    key: 'MTY',
  },
  { icon: 'faPlaneDeparture', label: 'Montes Claros, Brazil, MOC', key: 'MOC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Montgomery Regional, United States, MGM',
    key: 'MGM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Montpellier–Méditerranée, France, MPL',
    key: 'MPL',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Montréal–Pierre Elliott Trudeau International, Montreal, Canada, YUL',
    key: 'YUL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Montrose Regional, United States, MTJ',
    key: 'MTJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Monywa, Myanmar (Burma), NYW',
    key: 'NYW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Moorea, Mo orea, French Polynesia, MOZ',
    key: 'MOZ',
  },
  { icon: 'faPlaneDeparture', label: 'Moosonee, Canada, YMO', key: 'YMO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mopah International, Merauke, Indonesia, MKQ',
    key: 'MKQ',
  },
  { icon: 'faPlaneDeparture', label: 'Mora–Siljan, Sweden, MXX', key: 'MXX' },
  { icon: 'faPlaneDeparture', label: 'Moranbah, Australia, MOV', key: 'MOV' },
  { icon: 'faPlaneDeparture', label: 'Moree, Australia, MRZ', key: 'MRZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Morelia International, Mexico, MLM',
    key: 'MLM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Morgantown Municipal, United States, MGW',
    key: 'MGW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mornington Island, Australia, ONG',
    key: 'ONG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Moro, Iagifu Ridge Camp, Papua New Guinea, MXH',
    key: 'MXH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mörön, Moron, Mongolia, MXV',
    key: 'MXV',
  },
  { icon: 'faPlaneDeparture', label: 'Morondava, Madagascar, MOQ', key: 'MOQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Morristown Municipal, United States, MMU',
    key: 'MMU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Morrisville–Stowe State, United States, MVL',
    key: 'MVL',
  },
  { icon: 'faPlaneDeparture', label: 'Moruya, Australia, MYA', key: 'MYA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mosjøen Airport, Kjærstad, Norway, MJF',
    key: 'MJF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Moss Airport, Rygge, Oslo, Norway, RYG',
    key: 'RYG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mostar, Bosnia & Herzegovina, OMO',
    key: 'OMO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mostépha Ben Boulaid, Batna, Algeria, BLJ',
    key: 'BLJ',
  },
  { icon: 'faPlaneDeparture', label: 'Mota Lava, Vanuatu, MTV', key: 'MTV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Moulay Ali Cherif, Errachidia, Morocco, ERH',
    key: 'ERH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mount Gambier, Australia, MGB',
    key: 'MGB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mount Hagen, Papua New Guinea, HGU',
    key: 'HGU',
  },
  { icon: 'faPlaneDeparture', label: 'Mount Isa, Australia, ISA', key: 'ISA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mountain Village, United States, MOU',
    key: 'MOU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mozes Kilangin International, Timika, Indonesia, TIM',
    key: 'TIM',
  },
  { icon: 'faPlaneDeparture', label: 'Mthatha, South Africa, UTT', key: 'UTT' },
  { icon: 'faPlaneDeparture', label: 'Mtwara, Tanzania, MYW', key: 'MYW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Muan International, Muan County, South Korea, MWX',
    key: 'MWX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mudanjiang Hailang International, China, MDG',
    key: 'MDG',
  },
  { icon: 'faPlaneDeparture', label: 'Mudgee, Australia, DGE', key: 'DGE' },
  { icon: 'faPlaneDeparture', label: 'Mukah, Malaysia, MKM', key: 'MKM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mukalla International Airport, Riyan, Yemen, RIY',
    key: 'RIY',
  },
  { icon: 'faPlaneDeparture', label: 'Mulatupo, Panama, MPP', key: 'MPP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mulhouse/Basel EuroAirport French  FR, Switzerland, MLH',
    key: 'MLH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Multan International, Pakistan, MUX',
    key: 'MUX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mulu, Gunung Mulu National Park, Malaysia, MZV',
    key: 'MZV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Munaung, Manaung, Myanmar (Burma), MGU',
    key: 'MGU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Munda, Solomon Islands, MUA',
    key: 'MUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mundo Maya International, Flores, Guatemala, FRS',
    key: 'FRS',
  },
  { icon: 'faPlaneDeparture', label: 'Munich, Germany, MUC', key: 'MUC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Münster Osnabrück International, Germany, FMO',
    key: 'FMO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Murcia–San Javier, Spain, MJV',
    key: 'MJV',
  },
  { icon: 'faPlaneDeparture', label: 'Murmansk, Russia, MMK', key: 'MMK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Murtala Muhammed International, Lagos, Nigeria, LOS',
    key: 'LOS',
  },
  { icon: 'faPlaneDeparture', label: 'Muş, Turkey, MSR', key: 'MSR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Muscat International, Oman, MCT',
    key: 'MCT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Muskegon County, United States, MKG',
    key: 'MKG',
  },
  { icon: 'faPlaneDeparture', label: 'Musoma, Tanzania, MUZ', key: 'MUZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mutiara SIS Al-Jufrie, Palu, Indonesia, PLW',
    key: 'PLW',
  },
  { icon: 'faPlaneDeparture', label: 'Mwanza, Tanzania, MWZ', key: 'MWZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Myeik, Myeik, Myanmar, Myanmar (Burma), MGZ',
    key: 'MGZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Myitkyina, Myanmar (Burma), MYT',
    key: 'MYT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Mykonos Island National, Greece, JMK',
    key: 'JMK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Myrtle Beach International, United States, MYR',
    key: 'MYR',
  },
  { icon: 'faPlaneDeparture', label: 'Mysore, India, MYQ', key: 'MYQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Mytilene International, Greece, MJT',
    key: 'MJT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'N Djamena International, Chad, NDJ',
    key: 'NDJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'N djili, Kinshasa, Democratic Republic of the Congo, FIH',
    key: 'FIH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nabire, Nabire Regency, Indonesia, NBX',
    key: 'NBX',
  },
  { icon: 'faPlaneDeparture', label: 'Nacala, Mozambique, MNC', key: 'MNC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nadi International, Fiji, NAN',
    key: 'NAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nador International, Morocco, NDR',
    key: 'NDR',
  },
  { icon: 'faPlaneDeparture', label: 'Nadym, Russia, NYM', key: 'NYM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Naga, Naga, Camarines Sur, Philippines, WNP',
    key: 'WNP',
  },
  { icon: 'faPlaneDeparture', label: 'Nagasaki, Japan, NGS', key: 'NGS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nagoya Airfield, Japan, NKM',
    key: 'NKM',
  },
  { icon: 'faPlaneDeparture', label: 'Naha, Indonesia, NAH', key: 'NAH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Naha, Okinawa Island, Japan, OKA',
    key: 'OKA',
  },
  { icon: 'faPlaneDeparture', label: 'Nain, Canada, YDP', key: 'YDP' },
  { icon: 'faPlaneDeparture', label: 'Nakashibetsu, Japan, SHB', key: 'SHB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nakhchivan International, Azerbaijan, NAJ',
    key: 'NAJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nakhon Phanom, Nakhon Phanom Province, Thailand, KOP',
    key: 'KOP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nakhon Si Thammarat, Nakhon Si Thammarat Province, Thailand, NST',
    key: 'NST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nal Airport, Bikaner, India, BKB',
    key: 'BKB',
  },
  { icon: 'faPlaneDeparture', label: 'Nalchik, Russia, NAL', key: 'NAL' },
  { icon: 'faPlaneDeparture', label: 'Namangan, Uzbekistan, NMA', key: 'NMA' },
  { icon: 'faPlaneDeparture', label: 'Namibe, Angola, MSZ', key: 'MSZ' },
  { icon: 'faPlaneDeparture', label: 'Nampula, Mozambique, APL', key: 'APL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Namsos Airport, Høknesøra, Norway, OSY',
    key: 'OSY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nan Nakhon, Nan Province, Thailand, NNT',
    key: 'NNT',
  },
  { icon: 'faPlaneDeparture', label: 'Nanaimo, Canada, YCD', key: 'YCD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanchang Changbei International, China, KHN',
    key: 'KHN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanchong Gaoping, China, NAO',
    key: 'NAO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanjing Lukou International, China, NKG',
    key: 'NKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanki–Shirahama, Japan, SHM',
    key: 'SHM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanning Wuxu International, China, NNG',
    key: 'NNG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nantes Atlantique, France, NTE',
    key: 'NTE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nantong Xingdong, China, NTG',
    key: 'NTG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nantucket Memorial, United States, ACK',
    key: 'ACK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanwalek, United States, KEB',
    key: 'KEB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nanyang Jiangying, China, NNY',
    key: 'NNY',
  },
  { icon: 'faPlaneDeparture', label: 'Nanyuki, Kenya, NYK', key: 'NYK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Napakiak, United States, WNA',
    key: 'WNA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Napaskiak, United States, PKA',
    key: 'PKA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Naples International, Italy, NAP',
    key: 'NAP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Naples Municipal, United States, APF',
    key: 'APF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Napuka, French Polynesia, NAU',
    key: 'NAU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Narathiwat, Narathiwat Province, Thailand, NAW',
    key: 'NAW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Narimanovo, Astrakhan, Russia, ASF',
    key: 'ASF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Narita International, Tokyo, Japan, NRT',
    key: 'NRT',
  },
  { icon: 'faPlaneDeparture', label: 'Narrandera, Australia, NRA', key: 'NRA' },
  { icon: 'faPlaneDeparture', label: 'Narsarsuaq, Greenland, UAK', key: 'UAK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Narvik Airport, Framnes, Norway, NVK',
    key: 'NVK',
  },
  { icon: 'faPlaneDeparture', label: 'Naryan-Mar, Russia, NNM', key: 'NNM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nashville International, United States, BNA',
    key: 'BNA',
  },
  { icon: 'faPlaneDeparture', label: 'Natashquan, Canada, YNA', key: 'YNA' },
  { icon: 'faPlaneDeparture', label: 'Natuashish, Canada, YNP', key: 'YNP' },
  { icon: 'faPlaneDeparture', label: 'Naujaat, Canada, YUT', key: 'YUT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nausori International, Suva, Fiji, SUV',
    key: 'SUV',
  },
  { icon: 'faPlaneDeparture', label: 'Navegantes, Brazil, NVT', key: 'NVT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Navoi International, Navoiy, Uzbekistan, NVI',
    key: 'NVI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Naxos Island National, Greece, JNX',
    key: 'JNX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Naypyidaw, Myanmar (Burma), NYT',
    key: 'NYT',
  },
  { icon: 'faPlaneDeparture', label: 'Nazran, Russia, IGT', key: 'IGT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nea Anchialos National, Volos, Greece, VOL',
    key: 'VOL',
  },
  { icon: 'faPlaneDeparture', label: 'Nelson, New Zealand, NSN', key: 'NSN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nelson Lagoon, United States, NLG',
    key: 'NLG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nelson Mandela International, Praia, Cape Verde, RAI',
    key: 'RAI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nemiscau, Nemaska, Canada, YNS',
    key: 'YNS',
  },
  { icon: 'faPlaneDeparture', label: 'Nepalgunj, Nepal, KEP', key: 'KEP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Netaji Subhas Chandra Bose International, Kolkata, India, CCU',
    key: 'CCU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nevşehir Kapadokya, Turkey, NAV',
    key: 'NAV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'New Bedford Regional, United States, EWB',
    key: 'EWB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'New Bight, The Bight, Bahamas, TBI',
    key: 'TBI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'New Chitose, Sapporo, Japan, CTS',
    key: 'CTS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'New Plymouth, New Zealand, NPL',
    key: 'NPL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'New Stuyahok, United States, KNW',
    key: 'KNW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'New Tanegashima, Japan, TNE',
    key: 'TNE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Newark Liberty International, New York City, United States, EWR',
    key: 'EWR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Newcastle, Newcastle upon Tyne, United Kingdom, NCL',
    key: 'NCL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Newcastle, City of Newcastle, Australia, NTL',
    key: 'NTL',
  },
  { icon: 'faPlaneDeparture', label: 'Newman, Australia, ZNE', key: 'ZNE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Newport News/Williamsburg International, United States, PHF',
    key: 'PHF',
  },
  { icon: 'faPlaneDeparture', label: 'Newtok, United States, WWT', key: 'WWT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ngala Airport, South Africa, NGL',
    key: 'NGL',
  },
  { icon: 'faPlaneDeparture', label: 'Ngaoundéré, Cameroon, NGE', key: 'NGE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ngari Gunsa, Ngari Prefecture, China, NGQ',
    key: 'NGQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ngurah Rai International, Denpasar, Indonesia, DPS',
    key: 'DPS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Niagara Falls International, United States, IAG',
    key: 'IAG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Niau, French Polynesia, NIU',
    key: 'NIU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nice Côte d Azur International, France, NCE',
    key: 'NCE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nightmute, United States, NME',
    key: 'NME',
  },
  { icon: 'faPlaneDeparture', label: 'Niigata, Japan, KIJ', key: 'KIJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nikolski Air Station, United States, IKO',
    key: 'IKO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nîmes–Alès–Camargue–Cévennes, France, FNI',
    key: 'FNI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ningbo Lishe International, China, NGB',
    key: 'NGB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ninglang Luguhu, China, NLH',
    key: 'NLH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ninoy Aquino International, Manila, Philippines, MNL',
    key: 'MNL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Niš Constantine the Great, Serbia, INI',
    key: 'INI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Niue International, Alofi, Niue, IUE',
    key: 'IUE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nizhnevartovsk, Russia, NJC',
    key: 'NJC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Njombe Airport, Tanzania, JOM',
    key: 'JOM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nnamdi Azikiwe International, Abuja, Nigeria, ABV',
    key: 'ABV',
  },
  { icon: 'faPlaneDeparture', label: 'Noatak, United States, WTK', key: 'WTK' },
  { icon: 'faPlaneDeparture', label: 'Nogliki, Russia, NGK', key: 'NGK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Noi Bai International, Hanoi, Vietnam, HAN',
    key: 'HAN',
  },
  { icon: 'faPlaneDeparture', label: 'Nome, United States, OME', key: 'OME' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nondalton, United States, NNL',
    key: 'NNL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nop Goliat Dekai, Yahukimo Regency, Indonesia, DEX',
    key: 'DEX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Norfolk International, United States, ORF',
    key: 'ORF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Norfolk Island, Burnt Pine, Norfolk Island, NLK',
    key: 'NLK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Norman Manley International, Kingston, Jamaica, KIN',
    key: 'KIN',
  },
  { icon: 'faPlaneDeparture', label: 'Norman Wells, Canada, YVQ', key: 'YVQ' },
  { icon: 'faPlaneDeparture', label: 'Normanton, Australia, NTN', key: 'NTN' },
  { icon: 'faPlaneDeparture', label: 'Norrköping, Sweden, NRK', key: 'NRK' },
  { icon: 'faPlaneDeparture', label: 'Norsup, Vanuatu, NUS', key: 'NUS' },
  {
    icon: 'faPlaneDeparture',
    label: 'North Bay/Jack Garland, Canada, YYB',
    key: 'YYB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'North Central West Virginia, Clarksburg, United States, CKB',
    key: 'CKB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'North Eleuthera, Bahamas, ELH',
    key: 'ELH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'North Platte Regional, United States, LBF',
    key: 'LBF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'North Ronaldsay, United Kingdom, NRL',
    key: 'NRL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'North Spirit Lake, Canada, YNO',
    key: 'YNO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'North Whale Seaplane Base, Whale Pass, United States, WWP',
    key: 'WWP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Northeast Florida Regional, St. Augustine, United States, UST',
    key: 'UST',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Northern Maine Regional Airport at Presque Isle, United States, PQI',
    key: 'PQI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Northern Peninsula, Bamaga, Australia, ABM',
    key: 'ABM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Northwest Alabama Regional, Muscle Shoals, United States, MSL',
    key: 'MSL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Northwest Arkansas Regional, Fayetteville, United States, XNA',
    key: 'XNA',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Northwest Florida Beaches International, Panama City, United States, ECP',
    key: 'ECP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Northwest Regional Airport Terrace-Kitimat, Canada, YXT',
    key: 'YXT',
  },
  { icon: 'faPlaneDeparture', label: 'Norway House, Canada, YNE', key: 'YNE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Norwich, United Kingdom, NWI',
    key: 'NWI',
  },
  { icon: 'faPlaneDeparture', label: 'Nosara, Costa Rica, NOB', key: 'NOB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Noshahr, Nowshahr, Iran, NSH',
    key: 'NSH',
  },
  { icon: 'faPlaneDeparture', label: 'Noto, Wajima, Japan, NTQ', key: 'NTQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Notohadinegoro, Jember Regency, Indonesia, JBB',
    key: 'JBB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nouadhibou International, Mauritania, NDB',
    key: 'NDB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nouakchott International, Mauritania, NKC',
    key: 'NKC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nouméa Magenta, New Caledonia, GEA',
    key: 'GEA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Noumérat – Moufdi Zakaria, Ghardaïa, Algeria, GHA',
    key: 'GHA',
  },
  { icon: 'faPlaneDeparture', label: 'Novy Urengoy, Russia, NUX', key: 'NUX' },
  { icon: 'faPlaneDeparture', label: 'Noyabrsk, Russia, NOJ', key: 'NOJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nuevo Laredo International, Mexico, NLD',
    key: 'NLD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nuiqsut, United States, NUI',
    key: 'NUI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nuku Hiva, French Polynesia, NHV',
    key: 'NHV',
  },
  { icon: 'faPlaneDeparture', label: 'Nukus, Uzbekistan, NCU', key: 'NCU' },
  { icon: 'faPlaneDeparture', label: 'Nulato, United States, NUL', key: 'NUL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nunapitchuk, United States, NUP',
    key: 'NUP',
  },
  { icon: 'faPlaneDeparture', label: 'Nunukan, Indonesia, NNX', key: 'NNX' },
  { icon: 'faPlaneDeparture', label: 'Nuremberg, Germany, NUE', key: 'NUE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nusatupe, Gizo, Solomon Islands, GZO',
    key: 'GZO',
  },
  { icon: 'faPlaneDeparture', label: 'Nuuk, Greenland, GOH', key: 'GOH' },
  { icon: 'faPlaneDeparture', label: 'Nyagan, Russia, NYA', key: 'NYA' },
  { icon: 'faPlaneDeparture', label: 'Nyala, Sudan, UYL', key: 'UYL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Nyaung U, Bagan, Myanmar (Burma), NYU',
    key: 'NYU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Nyingchi Mainling, China, LZY',
    key: 'LZY',
  },
  { icon: 'faPlaneDeparture', label: 'Nyurba, Russia, NYR', key: 'NYR' },
  {
    icon: 'faPlaneDeparture',
    label: 'O Hare International, Chicago, United States, ORD',
    key: 'ORD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'O. R. Tambo International, Johannesburg, South Africa, JNB',
    key: 'JNB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oakland International, United States, OAK',
    key: 'OAK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oaxaca International, Mexico, OAX',
    key: 'OAX',
  },
  { icon: 'faPlaneDeparture', label: 'Odate–Noshiro, Japan, ONJ', key: 'ONJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Odessa International, Ukraine, ODS',
    key: 'ODS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Odienne, Odienné, Côte d’Ivoire, KEO',
    key: 'KEO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oesman Sadik, Labuha, Indonesia, LAH',
    key: 'LAH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ogden-Hinckley, United States, OGD',
    key: 'OGD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ogdensburg International, United States, OGS',
    key: 'OGS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ogle, Georgetown, Guyana, OGL',
    key: 'OGL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oğuzeli, Gaziantep, Turkey, GZT',
    key: 'GZT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ohrid  St. Paul the Apostle , Macedonia (FYROM), OHD',
    key: 'OHD',
  },
  { icon: 'faPlaneDeparture', label: 'Oita, Japan, OIT', key: 'OIT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Okadama, Sapporo, Japan, OKD',
    key: 'OKD',
  },
  { icon: 'faPlaneDeparture', label: 'Okayama, Japan, OKJ', key: 'OKJ' },
  { icon: 'faPlaneDeparture', label: 'Okha, Russia, OHH', key: 'OHH' },
  { icon: 'faPlaneDeparture', label: 'Okhotsk, Russia, OHO', key: 'OHO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Oki, Oki Islands, Japan, OKI',
    key: 'OKI',
  },
  { icon: 'faPlaneDeparture', label: 'Oksibil, Indonesia, OKL', key: 'OKL' },
  { icon: 'faPlaneDeparture', label: 'Okushiri, Japan, OIR', key: 'OIR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Olaya Herrera, Medellín, Colombia, EOH',
    key: 'EOH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Olbia Costa Smeralda, Italy, OLB',
    key: 'OLB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Old Crow, Old Crow, Yukon, Canada, YOC',
    key: 'YOC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Old Harbor, United States, OLH',
    key: 'OLH',
  },
  { icon: 'faPlaneDeparture', label: 'Olenyok, Russia, ONK', key: 'ONK' },
  { icon: 'faPlaneDeparture', label: 'Ölgii, Mongolia, ULG', key: 'ULG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Olsztyn-Mazury, Szymany, Szczytno County, Poland, SZY',
    key: 'SZY',
  },
  { icon: 'faPlaneDeparture', label: 'Olyokminsk, Russia, OLZ', key: 'OLZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Omsk Tsentralny, Russia, OMS',
    key: 'OMS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ondjiva Pereira, Angola, VPE',
    key: 'VPE',
  },
  { icon: 'faPlaneDeparture', label: 'Onslow, Australia, ONS', key: 'ONS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ontario International, United States, ONT',
    key: 'ONT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oradea International, Romania, OMR',
    key: 'OMR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oral Ak Zhol, Kazakhstan, URA',
    key: 'URA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oran Es Sénia, Algeria, ORN',
    key: 'ORN',
  },
  { icon: 'faPlaneDeparture', label: 'Orange, Australia, OAG', key: 'OAG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Orange Walk, Orange Walk District, Belize, ORZ',
    key: 'ORZ',
  },
  { icon: 'faPlaneDeparture', label: 'Oranjemund, Namibia, OMD', key: 'OMD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ordos Ejin Horo, Ordos City, China, DSN',
    key: 'DSN',
  },
  { icon: 'faPlaneDeparture', label: 'Ordu–Giresun, Turkey, OGU', key: 'OGU' },
  { icon: 'faPlaneDeparture', label: 'Örebro, Sweden, ORB', key: 'ORB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Orenburg Tsentralny, Russia, REN',
    key: 'REN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Orestes Acosta, Moa, Cuba, MOA',
    key: 'MOA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ørland Main Air Station, Norway, OLA',
    key: 'OLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Orlando International, United States, MCO',
    key: 'MCO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Orlando Melbourne International, United States, MLB',
    key: 'MLB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Orlando Sanford International, United States, SFB',
    key: 'SFB',
  },
  { icon: 'faPlaneDeparture', label: 'Ormoc, Philippines, OMC', key: 'OMC' },
  { icon: 'faPlaneDeparture', label: 'Örnsköldsvik, Sweden, OER', key: 'OER' },
  { icon: 'faPlaneDeparture', label: 'Orsk, Russia, OSW', key: 'OSW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ørsta–Volda Airport, Hovden, Norway, HOV',
    key: 'HOV',
  },
  { icon: 'faPlaneDeparture', label: 'Osh, Kyrgyzstan, OSS', key: 'OSS' },
  { icon: 'faPlaneDeparture', label: 'Osijek, Croatia, OSI', key: 'OSI' },
  { icon: 'faPlaneDeparture', label: 'Oskemen, Kazakhstan, UKK', key: 'UKK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Oslo Airport, Gardermoen, Norway, OSL',
    key: 'OSL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Osmani International, Sylhet, Bangladesh, ZYL',
    key: 'ZYL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ostend-Bruges International, Belgium, OST',
    key: 'OST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Osvaldo Vieira International, Bissau, Guinea-Bissau, OXB',
    key: 'OXB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ottawa Macdonald–Cartier International, Canada, YOW',
    key: 'YOW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ouagadougou, Burkina Faso, OUA',
    key: 'OUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ouanaham, Lifou Island, New Caledonia, LIF',
    key: 'LIF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ouango Fitini, Ouango-Fitini, Côte d’Ivoire, OFI',
    key: 'OFI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ouani, Anjouan, Comoros, AJN',
    key: 'AJN',
  },
  { icon: 'faPlaneDeparture', label: 'Ouarzazate, Morocco, OZZ', key: 'OZZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Oudomsay, Muang Xay, Laos, ODY',
    key: 'ODY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oued Irara–Krim Belkacem, Hassi Messaoud, Algeria, HME',
    key: 'HME',
  },
  { icon: 'faPlaneDeparture', label: 'Oulu, Finland, OUL', key: 'OUL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ouvéa, Ouvéa Island, New Caledonia, UVE',
    key: 'UVE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ouzinkie, United States, KOZ',
    key: 'KOZ',
  },
  { icon: 'faPlaneDeparture', label: 'Ovda, Israel, VDA', key: 'VDA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Owen Roberts International, Grand Cayman, Cayman Islands, GCM',
    key: 'GCM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Owensboro–Daviess County Regional, United States, OWB',
    key: 'OWB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Oxford House, Oxford House, Manitoba, Canada, YOH',
    key: 'YOH',
  },
  { icon: 'faPlaneDeparture', label: 'Paama, Vanuatu, PBJ', key: 'PBJ' },
  { icon: 'faPlaneDeparture', label: 'Paamiut, Greenland, JFR', key: 'JFR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Paderborn Lippstadt, Germany, PAD',
    key: 'PAD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Padre Aldamiz International, Puerto Maldonado, Peru, PEM',
    key: 'PEM',
  },
  { icon: 'faPlaneDeparture', label: 'Pagadian, Philippines, PAG', key: 'PAG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Page Municipal, United States, PGA',
    key: 'PGA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pago Pago International, United States, PPG',
    key: 'PPG',
  },
  { icon: 'faPlaneDeparture', label: 'Pai, Thailand, PYY', key: 'PYY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pakse International, Laos, PKZ',
    key: 'PKZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palanga International, Lithuania, PLQ',
    key: 'PLQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palenque International, Mexico, PQM',
    key: 'PQM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palm Beach International, West Palm Beach, United States, PBI',
    key: 'PBI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palm Springs International, United States, PSP',
    key: 'PSP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palma de Mallorca, Palma, Majorca, Spain, PMI',
    key: 'PMI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palmar Sur, Costa Rica, PMZ',
    key: 'PMZ',
  },
  { icon: 'faPlaneDeparture', label: 'Palmas, Brazil, PMW', key: 'PMW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Palmerston North, New Zealand, PMR',
    key: 'PMR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palonegro International, Bucaramanga, Colombia, BGA',
    key: 'BGA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Palopo Lagaligo, Indonesia, LLO',
    key: 'LLO',
  },
  { icon: 'faPlaneDeparture', label: 'Pamplona, Spain, PNA', key: 'PNA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pangborn Memorial, Wenatchee, United States, EAT',
    key: 'EAT',
  },
  { icon: 'faPlaneDeparture', label: 'Pangnirtung, Canada, YXP', key: 'YXP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pangsuma, Putussibau, Indonesia, PSU',
    key: 'PSU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Panjgur Airport, Pakistan, PJG',
    key: 'PJG',
  },
  { icon: 'faPlaneDeparture', label: 'Pantelleria, Italy, PNL', key: 'PNL' },
  { icon: 'faPlaneDeparture', label: 'Pantnagar, India, PGH', key: 'PGH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Panzhihua Bao anying, China, PZI',
    key: 'PZI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Papa Westray, United Kingdom, PPW',
    key: 'PPW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Paphos International, Cyprus, PFO',
    key: 'PFO',
  },
  { icon: 'faPlaneDeparture', label: 'Paraburdoo, Australia, PBO', key: 'PBO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Parasi Airport, Solomon Islands, PRS',
    key: 'PRS',
  },
  { icon: 'faPlaneDeparture', label: 'Pardubice, Czechia, PED', key: 'PED' },
  { icon: 'faPlaneDeparture', label: 'Parintins, Brazil, PIN', key: 'PIN' },
  { icon: 'faPlaneDeparture', label: 'Paris Orly, France, ORY', key: 'ORY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Paris–Le Bourget, France, LBG',
    key: 'LBG',
  },
  { icon: 'faPlaneDeparture', label: 'Parkes, Australia, PKE', key: 'PKE' },
  { icon: 'faPlaneDeparture', label: 'Parma, Italy, PMF', key: 'PMF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Parnaíba-Prefeito Dr. João Silva Filho International, Brazil, PHB',
    key: 'PHB',
  },
  { icon: 'faPlaneDeparture', label: 'Paro, Bhutan, PBH', key: 'PBH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Paros National, Parikia, Greece, PAS',
    key: 'PAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Parsabade Moghan Airport, Iran, PFQ',
    key: 'PFQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pasighat Airport, India, IXT',
    key: 'IXT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pattimura, Ambon, Maluku, Indonesia, AMQ',
    key: 'AMQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pau Pyrénées, Pau, Pyrénées-Atlantiques, France, PUF',
    key: 'PUF',
  },
  { icon: 'faPlaneDeparture', label: 'Paulo Afonso, Brazil, PAV', key: 'PAV' },
  { icon: 'faPlaneDeparture', label: 'Pavlodar, Kazakhstan, PWQ', key: 'PWQ' },
  { icon: 'faPlaneDeparture', label: 'Peawanuck, Canada, YPO', key: 'YPO' },
  { icon: 'faPlaneDeparture', label: 'Pechora, Russia, PEX', key: 'PEX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pedasí, Pedasi, Panama, PDM',
    key: 'PDM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pedro Bay, United States, PDB',
    key: 'PDB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pelican Sea Plane Base, United States, PEC',
    key: 'PEC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pellston Regional, United States, PLN',
    key: 'PLN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pelotas International, Brazil, PET',
    key: 'PET',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pemba, Pemba Island, Tanzania, PMA',
    key: 'PMA',
  },
  { icon: 'faPlaneDeparture', label: 'Pemba, Mozambique, POL', key: 'POL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Penang International, Malaysia, PEN',
    key: 'PEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pensacola International, United States, PNS',
    key: 'PNS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Penticton Regional, Canada, YYF',
    key: 'YYF',
  },
  { icon: 'faPlaneDeparture', label: 'Penza, Russia, PEZ', key: 'PEZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Perales, Ibagué, Colombia, IBE',
    key: 'IBE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Périgueux Bassillac, France, PGX',
    key: 'PGX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Perm International, Russia, PEE',
    key: 'PEE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Perpignan–Rivesaltes, France, PGF',
    key: 'PGF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Perryville, United States, KPV',
    key: 'KPV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Persian Gulf, Asaluyeh, Iran, PGU',
    key: 'PGU',
  },
  { icon: 'faPlaneDeparture', label: 'Perth, Australia, PER', key: 'PER' },
  {
    icon: 'faPlaneDeparture',
    label: 'Petersburg James A. Johnson, United States, PSG',
    key: 'PSG',
  },
  { icon: 'faPlaneDeparture', label: 'Petrolina, Brazil, PNZ', key: 'PNZ' },
  { icon: 'faPlaneDeparture', label: 'Petropavl, Kazakhstan, PPK', key: 'PPK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Petropavlovsk-Kamchatsky, Russia, PKC',
    key: 'PKC',
  },
  { icon: 'faPlaneDeparture', label: 'Petrozavodsk, Russia, PES', key: 'PES' },
  { icon: 'faPlaneDeparture', label: 'Phaplu Airport, Nepal, PPL', key: 'PPL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Philadelphia International, United States, PHL',
    key: 'PHL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Philip S. W. Goldson International, Belize City, Belize, BZE',
    key: 'BZE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phinda Zulu Inyala, Phinda Private Game Reserve, South Africa, PZL',
    key: 'PZL',
  },
  { icon: 'faPlaneDeparture', label: 'Phitsanulok, Thailand, PHS', key: 'PHS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Phnom Penh International, Cambodia, PNH',
    key: 'PNH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phoenix Deer Valley Airport, United States, DVT',
    key: 'DVT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phoenix Sky Harbor International, United States, PHX',
    key: 'PHX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phoenix–Mesa Gateway, United States, AZA',
    key: 'AZA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phrae, Phrae Province, Thailand, PRH',
    key: 'PRH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phu Bai International, Hue, Vietnam, HUI',
    key: 'HUI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phu Cat, Qui Nhơn, Vietnam, UIH',
    key: 'UIH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phu Quoc International Airport, Phú Quốc, Vietnam, PQC',
    key: 'PQC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Phuket International, Phuket City, Thailand, HKT',
    key: 'HKT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Piarco International, Port of Spain, Trinidad & Tobago, POS',
    key: 'POS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pico, Pico Island, Portugal, PIX',
    key: 'PIX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Picton Aerodrome, New Zealand, PCN',
    key: 'PCN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Piedmont Triad International, Greensboro, United States, GSO',
    key: 'GSO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Piedras Negras International, Mexico, PDS',
    key: 'PDS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pierre Regional, United States, PIR',
    key: 'PIR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pierrefonds, Saint-Pierre, France, ZSE',
    key: 'ZSE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pietermaritzburg, South Africa, PZB',
    key: 'PZB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pikangikum, Pikangikum First Nation, Canada, YPM',
    key: 'YPM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pilanesberg International, Sun City, South Africa, NTY',
    key: 'NTY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pilot Point, United States, PIP',
    key: 'PIP',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Piloto Civil Norberto Fernández International, Río Gallegos, Argentina, RGL',
    key: 'RGL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pinang Kampai, Dumai, Indonesia, DUM',
    key: 'DUM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pinto Martins – Fortaleza International, Brazil, FOR',
    key: 'FOR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pisa International, Italy, PSA',
    key: 'PSA',
  },
  { icon: 'faPlaneDeparture', label: 'Pitalito, Colombia, PTX', key: 'PTX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pitt–Greenville, United States, PGV',
    key: 'PGV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pittsburgh International, United States, PIT',
    key: 'PIT',
  },
  { icon: 'faPlaneDeparture', label: 'Placencia, Belize, PLJ', key: 'PLJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Plan de Guadalupe International, Saltillo, Mexico, SLW',
    key: 'SLW',
  },
  { icon: 'faPlaneDeparture', label: 'Plastun, Russia, TLY', key: 'TLY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Platinum, United States, PTU',
    key: 'PTU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Platov International Airport, Rostov-on-Don, Russia, ROV',
    key: 'ROV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Plattsburgh International, United States, PBG',
    key: 'PBG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Playa de Oro International, Manzanillo, Mexico, ZLO',
    key: 'ZLO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Playón Chico, Ukupseni, Panama, PYC',
    key: 'PYC',
  },
  { icon: 'faPlaneDeparture', label: 'Pleiku, Vietnam, PXU', key: 'PXU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Plettenberg Bay, South Africa, PBZ',
    key: 'PBZ',
  },
  { icon: 'faPlaneDeparture', label: 'Plovdiv, Bulgaria, PDV', key: 'PDV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Plymouth City, United Kingdom, PLH',
    key: 'PLH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pobedilovo, Kirov, Russia, KVX',
    key: 'KVX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pocatello Regional, United States, PIH',
    key: 'PIH',
  },
  { icon: 'faPlaneDeparture', label: 'Podgorica, Montenegro, TGD', key: 'TGD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Podkamennaya Tunguska, Russia, TGP',
    key: 'TGP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pogogul, Buol, Indonesia, UOL',
    key: 'UOL',
  },
  { icon: 'faPlaneDeparture', label: 'Pohang, South Korea, KPO', key: 'KPO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pohnpei International, Micronesia, PNI',
    key: 'PNI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Point Baker Sea Plane Base, United States, KPB',
    key: 'KPB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Point Hope, United States, PHO',
    key: 'PHO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Point Lay LRRS, United States, PIZ',
    key: 'PIZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pointe Noire, Pointe-Noire, Republic of the Congo, PNR',
    key: 'PNR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pointe Vele, Futuna, Wallis & Futuna, FUT',
    key: 'FUT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pointe-à-Pitre International, France, PTP',
    key: 'PTP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Poitiers–Biard, France, PIS',
    key: 'PIS',
  },
  { icon: 'faPlaneDeparture', label: 'Pokhara, Nepal, PKR', key: 'PKR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Polokwane International, Polokwane, Limpopo, South Africa, PTG',
    key: 'PTG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Polyarny, Udachny, Russia, PYJ',
    key: 'PYJ',
  },
  { icon: 'faPlaneDeparture', label: 'Pond Inlet, Canada, YIO', key: 'YIO' },
  { icon: 'faPlaneDeparture', label: 'Pondicherry, India, PNY', key: 'PNY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ponta Grossa A.Amilton Beraldo, Brazil, PGZ',
    key: 'PGZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Poprad–Tatry, Slovakia, TAT',
    key: 'TAT',
  },
  { icon: 'faPlaneDeparture', label: 'Porbandar, India, PBD', key: 'PBD' },
  { icon: 'faPlaneDeparture', label: 'Pori, Finland, POR', key: 'POR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Alsworth, United States, PTA',
    key: 'PTA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Augusta, Australia, PUG',
    key: 'PUG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Bouet, Abidjan, Côte d’Ivoire, ABJ',
    key: 'ABJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Elizabeth, South Africa, PLZ',
    key: 'PLZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Graham, United States, PGM',
    key: 'PGM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Harcourt International, Nigeria, PHC',
    key: 'PHC',
  },
  { icon: 'faPlaneDeparture', label: 'Port Hardy, Canada, YZT', key: 'YZT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Hedland International, Australia, PHE',
    key: 'PHE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Heiden, United States, PTH',
    key: 'PTH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Lincoln, Australia, PLO',
    key: 'PLO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Lions, United States, ORI',
    key: 'ORI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Macquarie, Australia, PQQ',
    key: 'PQQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Moller, United States, PML',
    key: 'PML',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Nelson, Rum Cay District, Bahamas, RCY',
    key: 'RCY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Protection Seaplane Base, United States, PPV',
    key: 'PPV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port Sudan New International, Sudan, PZU',
    key: 'PZU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Port-Gentil International, Gabon, POG',
    key: 'POG',
  },
  { icon: 'faPlaneDeparture', label: 'Portland, Australia, PTJ', key: 'PTJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Portland International, United States, PDX',
    key: 'PDX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Portland International Jetport, United States, PWM',
    key: 'PWM',
  },
  { icon: 'faPlaneDeparture', label: 'Porto, Portugal, OPO', key: 'OPO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Porto Santo, Vila Baleira, Portugal, PXO',
    key: 'PXO',
  },
  { icon: 'faPlaneDeparture', label: 'Porto Seguro, Brazil, BPS', key: 'BPS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Portsmouth International Airport at Pease, United States, PSM',
    key: 'PSM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Poso Kasinguncu, Indonesia, PSJ',
    key: 'PSJ',
  },
  { icon: 'faPlaneDeparture', label: 'Postville, Canada, YSO', key: 'YSO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Powell River, Powell River, British Columbia, Canada, YPW',
    key: 'YPW',
  },
  { icon: 'faPlaneDeparture', label: 'Poznań–Ławica, Poland, POZ', key: 'POZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Praslin Island, Seychelles, PRI',
    key: 'PRI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Preguiça, São Nicolau, Cape Verde, SNE',
    key: 'SNE',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Presidente Carlos Ibáñez del Campo International, Punta Arenas, Chile, PUQ',
    key: 'PUQ',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Presidente Castro Pinto International, João Pessoa, Paraíba, Brazil, JPA',
    key: 'JPA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Presidente Nicolau Lobato International, Dili, Timor-Leste, DIL',
    key: 'DIL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Presidente Perón International, Neuquén, Argentina, NQN',
    key: 'NQN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Presidente Prudente, Presidente Prudente, São Paulo, Brazil, PPB',
    key: 'PPB',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Prince Abdul Majeed bin Abdulaziz Domestic, Al-`Ula, Saudi Arabia, ULH',
    key: 'ULH',
  },
  { icon: 'faPlaneDeparture', label: 'Prince George, Canada, YXS', key: 'YXS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Prince Mangosuthu Buthelezi Airport, Ulundi, South Africa, ULD',
    key: 'ULD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Prince Mohammad bin Abdulaziz, Medina, Saudi Arabia, MED',
    key: 'MED',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Prince Nayef bin Abdulaziz Regional, Al-Qassim Region, Saudi Arabia, ELQ',
    key: 'ELQ',
  },
  { icon: 'faPlaneDeparture', label: 'Prince Rupert, Canada, YPR', key: 'YPR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Prince Said Ibrahim International, Moroni, Comoros, HAH',
    key: 'HAH',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      "Princess Juliana International, Lower Prince's Quarter, Sint Maarten, SXM",
    key: 'SXM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pristina International, Kosovo, PRN',
    key: 'PRN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Providenciales International, Turks & Caicos Islands, PLS',
    key: 'PLS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Provincetown Municipal, United States, PVC',
    key: 'PVC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Provo Municipal, United States, PVU',
    key: 'PVU',
  },
  { icon: 'faPlaneDeparture', label: 'Pskov, Russia, PKV', key: 'PKV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pu er Simao, Simao District, China, SYM',
    key: 'SYM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Puebla International, Mexico, PBC',
    key: 'PBC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pueblo Memorial, United States, PUB',
    key: 'PUB',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Puerto Escondido International, Puerto Escondido, Oaxaca, Mexico, PXM',
    key: 'PXM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Puerto Jiménez, Costa Rica, PJM',
    key: 'PJM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Puerto Lempira, Honduras, PEU',
    key: 'PEU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Puerto Obaldia, Puerto Obaldía, Panama, PUE',
    key: 'PUE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Puerto Princesa International, Philippines, PPS',
    key: 'PPS',
  },
  { icon: 'faPlaneDeparture', label: 'Pula, Croatia, PUY', key: 'PUY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pulkovo International, Saint Petersburg, Russia, LED',
    key: 'LED',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Pullman–Moscow Regional, United States, PUW',
    key: 'PUW',
  },
  { icon: 'faPlaneDeparture', label: 'Pune, India, PNQ', key: 'PNQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Punta Cana International, Dominican Republic, PUJ',
    key: 'PUJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Punta Gorda, United States, PGD',
    key: 'PGD',
  },
  { icon: 'faPlaneDeparture', label: 'Punta Gorda, Belize, PND', key: 'PND' },
  {
    icon: 'faPlaneDeparture',
    label: 'Putao, Myanmar (Burma), PBU',
    key: 'PBU',
  },
  { icon: 'faPlaneDeparture', label: 'Puvirnituq, Canada, YPX', key: 'YPX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Pyongyang International, North Korea, FNJ',
    key: 'FNJ',
  },
  { icon: 'faPlaneDeparture', label: 'Qaarsut, Greenland, JQA', key: 'JQA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Qabala International, Azerbaijan, GBB',
    key: 'GBB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Qamdo Bamda, Chengguan, Chamdo, China, BPX',
    key: 'BPX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Qianjiang Wulingshan, Qianjiang District, China, JIQ',
    key: 'JIQ',
  },
  { icon: 'faPlaneDeparture', label: 'Qiemo, China, IQM', key: 'IQM' },
  { icon: 'faPlaneDeparture', label: 'Qikiqtarjuaq, Canada, YVM', key: 'YVM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Qingdao Liuting International, China, TAO',
    key: 'TAO',
  },
  { icon: 'faPlaneDeparture', label: 'Qingyang, China, IQN', key: 'IQN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Qinhuangdao Beidaihe, China, BPE',
    key: 'BPE',
  },
  { icon: 'faPlaneDeparture', label: "Qionghai Bo'ao, China, BAR", key: 'BAR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Qiqihar Sanjiazi, China, NDG',
    key: 'NDG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Quad City International, Moline, United States, MLI',
    key: 'MLI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Quanzhou Jinjiang International, China, JJN',
    key: 'JJN',
  },
  { icon: 'faPlaneDeparture', label: 'Quaqtaq, Canada, YQC', key: 'YQC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Quatro de Fevereiro, Luanda, Angola, LAD',
    key: 'LAD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Québec City Jean Lesage International, Quebec City, Canada, YQB',
    key: 'YQB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Queen Alia International, Amman, Jordan, AMM',
    key: 'AMM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Queen Beatrix International, Aruba, Aruba, AUA',
    key: 'AUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Queenstown, New Zealand, ZQN',
    key: 'ZQN',
  },
  { icon: 'faPlaneDeparture', label: 'Quelimane, Mozambique, UEL', key: 'UEL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Quepos La Managua, Costa Rica, XQP',
    key: 'XQP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Querétaro International, Santiago de Querétaro, Mexico, QRO',
    key: 'QRO',
  },
  { icon: 'faPlaneDeparture', label: 'Quesnel, Canada, YQZ', key: 'YQZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Quetta International, Pakistan, UET',
    key: 'UET',
  },
  { icon: 'faPlaneDeparture', label: 'Quilpie, Australia, ULP', key: 'ULP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Quimper–Cornouaille, France, UIP',
    key: 'UIP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Quincy Regional, United States, UIN',
    key: 'UIN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Quinhagak, United States, KWN',
    key: 'KWN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Qurghonteppa International, Tajikistan, KQT',
    key: 'KQT',
  },
  { icon: 'faPlaneDeparture', label: 'Quzhou, China, JUZ', key: 'JUZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'RAAF Base Learmonth, Exmouth, Australia, LEA',
    key: 'LEA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'RAAF Base Tindal, Katherine, Australia, KTR',
    key: 'KTR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rabah Bitat, Annaba, Algeria, AAE',
    key: 'AAE',
  },
  { icon: 'faPlaneDeparture', label: 'Rabat–Salé, Morocco, RBA', key: 'RBA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rabaul, Papua New Guinea, RAB',
    key: 'RAB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rach Gia, Rạch Giá, Vietnam, VKG',
    key: 'VKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Radin Inten II International, Bandar Lampung, Indonesia, TKG',
    key: 'TKG',
  },
  { icon: 'faPlaneDeparture', label: 'RAF Gan, Maldives, GAN', key: 'GAN' },
  {
    icon: 'faPlaneDeparture',
    label: 'RAF Mount Pleasant, Falkland Islands (Islas Malvinas), MPN',
    key: 'MPN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rafael Cabrera Mustelier, Nueva Gerona, Cuba, GER',
    key: 'GER',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rafael Núñez International, Cartagena, Colombia, CTG',
    key: 'CTG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rafha Domestic, Saudi Arabia, RAH',
    key: 'RAH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Raiatea, French Polynesia, RFP',
    key: 'RFP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Raivavae, French Polynesia, RVV',
    key: 'RVV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Raja Bhoj, Bhopal, India, BHO',
    key: 'BHO',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Raja Haji Fisabilillah International, Tanjung Pinang, Indonesia, TNJ',
    key: 'TNJ',
  },
  { icon: 'faPlaneDeparture', label: 'Rajahmundry, India, RJA', key: 'RJA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rajiv Gandhi International, Hyderabad, India, HYD',
    key: 'HYD',
  },
  { icon: 'faPlaneDeparture', label: 'Rajkot, India, RAJ', key: 'RAJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Raleigh County Memorial, Beckley, United States, BKW',
    key: 'BKW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Raleigh–Durham International, United States, RDU',
    key: 'RDU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ralph M. Calhoun Memorial, Tanana, United States, TAL',
    key: 'TAL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ralph Wien Memorial, Kotzebue, United States, OTZ',
    key: 'OTZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ramata, Ramata Island, Solomon Islands, RBV',
    key: 'RBV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ramón Villeda Morales International, San Pedro Sula, Honduras, SAP',
    key: 'SAP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rampart, United States, RMP',
    key: 'RMP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ramsar, Ramsar, Mazandaran, Iran, RZR',
    key: 'RZR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ranai, Natuna, Indonesia, NTX',
    key: 'NTX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Range Regional, Hibbing, United States, HIB',
    key: 'HIB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rangiroa, French Polynesia, RGI',
    key: 'RGI',
  },
  { icon: 'faPlaneDeparture', label: 'Rankin Inlet, Canada, YRT', key: 'YRT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ranong, Ranong Province, Thailand, UNN',
    key: 'UNN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rapid City Regional, United States, RAP',
    key: 'RAP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rarotonga International, Cook Islands, RAR',
    key: 'RAR',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Ras Al Khaimah International, Ras al-Khaimah, United Arab Emirates, RKT',
    key: 'RKT',
  },
  { icon: 'faPlaneDeparture', label: 'Rasht, Iran, RAS', key: 'RAS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ravensthorpe, Australia, RVT',
    key: 'RVT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Recife/Guararapes–Gilberto Freyre International, Brazil, REC',
    key: 'REC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Reconquista, Argentina, RCQ',
    key: 'RCQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Red Deer Regional, Canada, YQF',
    key: 'YQF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Red Dog, United States, RDB',
    key: 'RDB',
  },
  { icon: 'faPlaneDeparture', label: 'Red Lake, Canada, YRL', key: 'YRL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Red Sucker Lake, Canada, YRS',
    key: 'YRS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Redding Municipal, United States, RDD',
    key: 'RDD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Reggio Calabria, Italy, REG',
    key: 'REG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Regina International, Canada, YQR',
    key: 'YQR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Region of Waterloo International, Canada, YKF',
    key: 'YKF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rendani, Manokwari, Indonesia, MKW',
    key: 'MKW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rennell/Tingoa, Rennell Island, Solomon Islands, RNL',
    key: 'RNL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rennes–Saint-Jacques, France, RNS',
    key: 'RNS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Reno–Tahoe International, United States, RNO',
    key: 'RNO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Resistencia International, Argentina, RES',
    key: 'RES',
  },
  { icon: 'faPlaneDeparture', label: 'Resolute Bay, Canada, YRB', key: 'YRB' },
  { icon: 'faPlaneDeparture', label: 'Reus, Spain, REU', key: 'REU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Reyes Murillo, Nuquí, Colombia, NQU',
    key: 'NQU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Reykjavík, Reykjavik, Iceland, RKV',
    key: 'RKV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rhinelander–Oneida County, United States, RHI',
    key: 'RHI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rhodes International, Greece, RHO',
    key: 'RHO',
  },
  { icon: 'faPlaneDeparture', label: 'Riberalta, Bolivia, RIB', key: 'RIB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Richards Bay, South Africa, RCB',
    key: 'RCB',
  },
  { icon: 'faPlaneDeparture', label: 'Richmond, Australia, RCM', key: 'RCM' },
  {
    icon: 'faPlaneDeparture',
    label: 'Richmond International, United States, RIC',
    key: 'RIC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rick Husband Amarillo International, United States, AMA',
    key: 'AMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rickenbacker International, Columbus, United States, LCK',
    key: 'LCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Riga International, Latvia, RIX',
    key: 'RIX',
  },
  { icon: 'faPlaneDeparture', label: 'Rigolet, Canada, YRG', key: 'YRG' },
  { icon: 'faPlaneDeparture', label: 'Rijeka, Croatia, RJK', key: 'RJK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rimatara, French Polynesia, RMT',
    key: 'RMT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rio Branco International, Rio Branco, Acre, Brazil, RBR',
    key: 'RBR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rio de Janeiro–Galeão International, Brazil, GIG',
    key: 'GIG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rio Verde, Rio Verde, Goiás, Brazil, RVD',
    key: 'RVD',
  },
  { icon: 'faPlaneDeparture', label: 'Rishiri, Japan, RIS', key: 'RIS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Riverton Regional, United States, RIW',
    key: 'RIW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rizhao Shanzihe, China, RIZ',
    key: 'RIZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roanoke–Blacksburg Regional, United States, ROA',
    key: 'ROA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Robe Airport, Goba, Ethiopia, GOB',
    key: 'GOB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Robert (Bob) Curtis Memorial, Noorvik, United States, ORV',
    key: 'ORV',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Robert L. Bradshaw International, Saint Kitts, St. Kitts & Nevis, SKB',
    key: 'SKB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roberts Field, Redmond, United States, RDM',
    key: 'RDM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roberts International, Monrovia, Liberia, ROB',
    key: 'ROB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roche Harbor, Roche Harbor Grocery Store, United States, RCE',
    key: 'RCE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rochester International, United States, RST',
    key: 'RST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rock Sound International, Bahamas, RSD',
    key: 'RSD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rock Springs–Sweetwater County, United States, RKS',
    key: 'RKS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rockhampton, Australia, ROK',
    key: 'ROK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rocky Mountain Metropolitan Airport, Denver, United States, BJC',
    key: 'BJC',
  },
  { icon: 'faPlaneDeparture', label: 'Rodez–Aveyron, France, RDZ', key: 'RDZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rodríguez Ballón International, Arequipa, Peru, AQP',
    key: 'AQP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rogue Valley International–Medford, United States, MFR',
    key: 'MFR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roi Et, Roi Et Province, Thailand, ROI',
    key: 'ROI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roland Garros, Saint-Denis, France, RUN',
    key: 'RUN',
  },
  { icon: 'faPlaneDeparture', label: 'Roma, Australia, RMA', key: 'RMA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Roman Tmetuchl International, Koror, Palau, ROR',
    key: 'ROR',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Ronald Reagan Washington National, Washington, D.C., United States, DCA',
    key: 'DCA',
  },
  { icon: 'faPlaneDeparture', label: 'Rondonópolis, Brazil, ROO', key: 'ROO' },
  { icon: 'faPlaneDeparture', label: 'Ronneby, Sweden, RNB', key: 'RNB' },
  { icon: 'faPlaneDeparture', label: 'Røros, Norway, RRS', key: 'RRS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rørvik Airport, Ryum, Norway, RVK',
    key: 'RVK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rosario – Islas Malvinas International, Argentina, ROS',
    key: 'ROS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roshchino International, Tyumen, Russia, TJM',
    key: 'TJM',
  },
  { icon: 'faPlaneDeparture', label: 'Røst, Norway, RET', key: 'RET' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rostock–Laage, Germany, RLG',
    key: 'RLG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roswell International Air Center, United States, ROW',
    key: 'ROW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rota International, United States, ROP',
    key: 'ROP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Roti David C. Saudale, Rote Island, Indonesia, RTI',
    key: 'RTI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rotorua Regional, New Zealand, ROT',
    key: 'ROT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rotterdam The Hague, Netherlands, RTM',
    key: 'RTM',
  },
  { icon: 'faPlaneDeparture', label: 'Rotuma, Fiji, RTA', key: 'RTA' },
  { icon: 'faPlaneDeparture', label: 'Rouen, France, URO', key: 'URO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Round Lake (Weagamow Lake), Canada, ZRJ',
    key: 'ZRJ',
  },
  { icon: 'faPlaneDeparture', label: 'Rouyn-Noranda, Canada, YUY', key: 'YUY' },
  { icon: 'faPlaneDeparture', label: 'Rovaniemi, Finland, RVN', key: 'RVN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Roxas, Roxas, Capiz, Philippines, RXS',
    key: 'RXS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ruben Berta International, Uruguaiana, Brazil, URG',
    key: 'URG',
  },
  { icon: 'faPlaneDeparture', label: 'Ruby, United States, RBY', key: 'RBY' },
  { icon: 'faPlaneDeparture', label: 'Rundu, Namibia, NDU', key: 'NDU' },
  { icon: 'faPlaneDeparture', label: 'Rurrenabaque, Bolivia, RBQ', key: 'RBQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Rurutu, French Polynesia, RUR',
    key: 'RUR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Russian Mission, United States, RSH',
    key: 'RSH',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Rutland–Southern Vermont Regional, Rutland City, United States, RUT',
    key: 'RUT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rzeszów–Jasionka, Poland, RZE',
    key: 'RZE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Rzhevka, Saint Petersburg, Russia, RVH',
    key: 'RVH',
  },
  { icon: 'faPlaneDeparture', label: 'Saarbrücken, Germany, SCN', key: 'SCN' },
  { icon: 'faPlaneDeparture', label: 'Sabha, Libya, SEB', key: 'SEB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sabiha Gökçen International, Istanbul, Turkey, SAW',
    key: 'SAW',
  },
  { icon: 'faPlaneDeparture', label: 'Sabzevar, Iran, AFZ', key: 'AFZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sacheon, Jinju, South Korea, HIN',
    key: 'HIN',
  },
  { icon: 'faPlaneDeparture', label: 'Sachigo Lake, Canada, ZPB', key: 'ZPB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sachs Harbour (David Nasogaluak Jr. Saaryuaq), Canada, YSY',
    key: 'YSY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sacramento International, United States, SMF',
    key: 'SMF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sadiq Abubakar III International, Sokoto, Nigeria, SKO',
    key: 'SKO',
  },
  { icon: 'faPlaneDeparture', label: 'Saga, Japan, HSG', key: 'HSG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sahnewal, Ludhiana, India, LUH',
    key: 'LUH',
  },
  { icon: 'faPlaneDeparture', label: 'Saidpur, Bangladesh, SPD', key: 'SPD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Saint Helena, Jamestown, St. Helena, HLE',
    key: 'HLE',
  },
  { icon: 'faPlaneDeparture', label: 'Saint John, Canada, YSJ', key: 'YSJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Saint-Augustin, Saint-Augustin, Côte-Nord, Quebec, Canada, YIF',
    key: 'YIF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Saint-Étienne–Bouthéon, France, EBU',
    key: 'EBU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Saint-Pierre, St. Pierre & Miquelon, FSP',
    key: 'FSP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sainte Marie, Île Sainte-Marie, Madagascar, SMS',
    key: 'SMS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Saipan International, United States, SPN',
    key: 'SPN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sakon Nakhon, Thailand, SNO',
    key: 'SNO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Salak, Maroua, Cameroon, MVR',
    key: 'MVR',
  },
  { icon: 'faPlaneDeparture', label: 'Salalah, Oman, SLL', key: 'SLL' },
  { icon: 'faPlaneDeparture', label: 'Salamanca, Spain, SLM', key: 'SLM' },
  { icon: 'faPlaneDeparture', label: 'Salekhard, Russia, SLY', key: 'SLY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Salgado Filho International, Porto Alegre, Brazil, POA',
    key: 'POA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Salina Regional, United States, SLN',
    key: 'SLN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Salisbury–Ocean City–Wicomico Regional, United States, SBY',
    key: 'SBY',
  },
  { icon: 'faPlaneDeparture', label: 'Salluit, Canada, YZG', key: 'YZG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Salt Cay, Salt Cay, Turks Islands, Turks & Caicos Islands, SLX',
    key: 'SLX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Salt Lake City International, United States, SLC',
    key: 'SLC',
  },
  { icon: 'faPlaneDeparture', label: 'Salzburg, Austria, SZG', key: 'SZG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sam Mbakwe, Owerri, Nigeria, QOW',
    key: 'QOW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sam Ratulangi International, Manado, Indonesia, MDC',
    key: 'MDC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Samaná El Catey International, Dominican Republic, AZS',
    key: 'AZS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Samarinda Temindung, Indonesia, SRI',
    key: 'SRI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Samarkand International, Uzbekistan, SKD',
    key: 'SKD',
  },
  { icon: 'faPlaneDeparture', label: 'Sambava, Madagascar, SVB', key: 'SVB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Samburu, Samburu County, Kenya, UAS',
    key: 'UAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Samos International, Greece, SMI',
    key: 'SMI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Samsun-Çarşamba, Turkey, SZF',
    key: 'SZF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Samui, Ko Samui, Thailand, USM',
    key: 'USM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Angelo Regional, United States, SJT',
    key: 'SJT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Antonio International, United States, SAT',
    key: 'SAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Carlos de Bariloche, Argentina, BRC',
    key: 'BRC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Cristóbal, San Cristóbal Island, Ecuador, SCY',
    key: 'SCY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Diego International, United States, SAN',
    key: 'SAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Francesco d Assisi, Perugia, Italy, PEG',
    key: 'PEG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Francisco International, United States, SFO',
    key: 'SFO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Jose, San Jose, Occidental Mindoro, Philippines, SJI',
    key: 'SJI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Jose International, United States, SJC',
    key: 'SJC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Jose Island, Isla San José, Panama, SIC',
    key: 'SIC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Luis, Ipiales, Colombia, IPI',
    key: 'IPI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Luis Obispo County Regional, United States, SBP',
    key: 'SBP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Luis Potosí International, Mexico, SLP',
    key: 'SLP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Luis Valley Regional, Alamosa, United States, ALS',
    key: 'ALS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Pedro, San Pedro Town, Belize, SPR',
    key: 'SPR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Pédro, San-Pédro, Ivory Coast, Côte d’Ivoire, SPY',
    key: 'SPY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Salvador, San Salvador Island, Bahamas, ZSA',
    key: 'ZSA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'San Sebastián, Donostia / San Sebastián, Spain, EAS',
    key: 'EAS',
  },
  { icon: 'faPlaneDeparture', label: 'San Tomé, Venezuela, SOM', key: 'SOM' },
  {
    icon: 'faPlaneDeparture',
    label: "Sana'a International Airport, Yemen, SAH",
    key: 'SAH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sanandaj, Sanandij, Iran, SDG',
    key: 'SDG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sand Point, United States, SDP',
    key: 'SDP',
  },
  { icon: 'faPlaneDeparture', label: 'Sandakan, Malaysia, SDK', key: 'SDK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sandane Airport, Anda, Norway, SDN',
    key: 'SDN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sanday, Sanday, Orkney, United Kingdom, NDY',
    key: 'NDY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sandefjord Airport, Torp, Oslo, Norway, TRF',
    key: 'TRF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sandnessjøen Airport, Stokka, Norway, SSJ',
    key: 'SSJ',
  },
  { icon: 'faPlaneDeparture', label: 'Sandspit, Canada, YZP', key: 'YZP' },
  { icon: 'faPlaneDeparture', label: 'Sandy Lake, Canada, ZSJ', key: 'ZSJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sanga-Sanga, Tawi-Tawi, Philippines, TWT',
    key: 'TWT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sangster International, Montego Bay, Jamaica, MBJ',
    key: 'MBJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sania Ramel, Tétouan, Morocco, TTU',
    key: 'TTU',
  },
  { icon: 'faPlaneDeparture', label: 'Sanikiluaq, Canada, YSK', key: 'YSK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sanliurfa, Şanlıurfa, Turkey, GNY',
    key: 'GNY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sanming Shaxian, China, SQJ',
    key: 'SQJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Ana, Santa Ana Island, Solomon Islands, NNB',
    key: 'NNB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Barbara Municipal, United States, SBA',
    key: 'SBA',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Santa Cruz/Graciosa Bay/Luova, Santa Cruz Islands, Solomon Islands, SCZ',
    key: 'SCZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Fe Municipal, United States, SAF',
    key: 'SAF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Genoveva, Goiânia, Brazil, GYN',
    key: 'GYN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Maria, Aracaju, Brazil, AJU',
    key: 'AJU',
  },
  { icon: 'faPlaneDeparture', label: 'Santa Maria, Brazil, RIA', key: 'RIA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Maria, Santa Maria Island, Portugal, SMA',
    key: 'SMA',
  },
  { icon: 'faPlaneDeparture', label: 'Santa Maria, Peru, SMG', key: 'SMG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Maria Public, United States, SMX',
    key: 'SMX',
  },
  { icon: 'faPlaneDeparture', label: 'Santa Rosa, Argentina, RSA', key: 'RSA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Santa Rosa International, Santa Rosa Canton, Ecuador, ETR',
    key: 'ETR',
  },
  { icon: 'faPlaneDeparture', label: 'Santander, Spain, SDR', key: 'SDR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Santarém-Maestro Wilson Fonseca, Brazil, STM',
    key: 'STM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santiago de Compostela, Spain, SCQ',
    key: 'SCQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santiago Mariño Caribbean International, Porlamar, Venezuela, PMV',
    key: 'PMV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santiago Pérez Quiroz, Arauca, Colombia, AUC',
    key: 'AUC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santo-Pekoa International, Luganville, Vanuatu, SON',
    key: 'SON',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santorini (Thira) National, Greece, JTR',
    key: 'JTR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Santos Dumont, Rio de Janeiro, Brazil, SDU',
    key: 'SDU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sanya Phoenix International, China, SYX',
    key: 'SYX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'São Filipe, Cape Verde, SFL',
    key: 'SFL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sao Jorge, São Jorge Island, Portugal, SJZ',
    key: 'SJZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'São José do Rio Preto, Brazil, SJP',
    key: 'SJP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'São Paulo–Congonhas, Brazil, CGH',
    key: 'CGH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'São Paulo–Guarulhos International, Brazil, GRU',
    key: 'GRU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'São Tomé International, São Tomé & Príncipe, TMS',
    key: 'TMS',
  },
  { icon: 'faPlaneDeparture', label: 'Sara, Vanuatu, SSR', key: 'SSR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sarajevo International, Bosnia & Herzegovina, SJJ',
    key: 'SJJ',
  },
  { icon: 'faPlaneDeparture', label: 'Saransk, Russia, SKX', key: 'SKX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sarasota–Bradenton International, United States, SRQ',
    key: 'SRQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Saratov Tsentralny, Russia, RTW',
    key: 'RTW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sardar Vallabhbhai Patel International, Ahmedabad, India, AMD',
    key: 'AMD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sarnia Chris Hadfield, Canada, YZR',
    key: 'YZR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sary-Arka, Karaganda, Kazakhstan, KGF',
    key: 'KGF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Saskatoon John G. Diefenbaker International, Canada, YXE',
    key: 'YXE',
  },
  { icon: 'faPlaneDeparture', label: 'Saskylakh, Russia, SYS', key: 'SYS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Satu Mare International, Romania, SUJ',
    key: 'SUJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sauce Viejo, Santa Fe, Argentina, SFN',
    key: 'SFN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sault Ste. Marie, Canada, YAM',
    key: 'YAM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Saumlaki Olilit, Indonesia, SXK',
    key: 'SXK',
  },
  { icon: 'faPlaneDeparture', label: 'Saurimo, Angola, VHC', key: 'VHC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Savannah/Hilton Head International, United States, SAV',
    key: 'SAV',
  },
  { icon: 'faPlaneDeparture', label: 'Savannakhet, Laos, ZVK', key: 'ZVK' },
  { icon: 'faPlaneDeparture', label: 'Savonlinna, Finland, SVL', key: 'SVL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Savoonga, United States, SVA',
    key: 'SVA',
  },
  { icon: 'faPlaneDeparture', label: 'Savusavu, Fiji, SVU', key: 'SVU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sawyer International, Marquette, United States, MQT',
    key: 'MQT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sayak, Del Carmen, Philippines, IAO',
    key: 'IAO',
  },
  { icon: 'faPlaneDeparture', label: 'Sayun, Seiyun, Yemen, GXF', key: 'GXF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Scammon Bay, United States, SCM',
    key: 'SCM',
  },
  { icon: 'faPlaneDeparture', label: 'Schefferville, Canada, YKL', key: 'YKL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sde Dov, Tel Aviv, Israel, SDV',
    key: 'SDV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Seattle–Tacoma International, United States, SEA',
    key: 'SEA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Seghe, Solomon Islands, EGM',
    key: 'EGM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Selawik, United States, WLK',
    key: 'WLK',
  },
  { icon: 'faPlaneDeparture', label: 'Semera, Ethiopia, SZE', key: 'SZE' },
  { icon: 'faPlaneDeparture', label: 'Semey, Kazakhstan, PLX', key: 'PLX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Senador Petronio Portella, Teresina, Brazil, THE',
    key: 'THE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Senai International, Johor Bahru, Malaysia, JHB',
    key: 'JHB',
  },
  { icon: 'faPlaneDeparture', label: 'Sendai, Japan, SDJ', key: 'SDJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sentani International, Jayapura, Indonesia, DJJ',
    key: 'DJJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sepé Tiaraju, Santo Ângelo, Brazil, GEL',
    key: 'GEL',
  },
  { icon: 'faPlaneDeparture', label: 'Sept-Îles, Canada, YZV', key: 'YZV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Seronera Airstrip, Tanzania, SEU',
    key: 'SEU',
  },
  { icon: 'faPlaneDeparture', label: 'Severo-Evensk, Russia, SWV', key: 'SWV' },
  { icon: 'faPlaneDeparture', label: 'Seville, Spain, SVQ', key: 'SVQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Seychelles International, Mahé, Seychelles, SEZ',
    key: 'SEZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Seymour, Baltra Island, Ecuador, GPS',
    key: 'GPS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sfax–Thyna International, Tunisia, SFA',
    key: 'SFA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shageluk, United States, SHX',
    key: 'SHX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shah Amanat International, Chittagong, Bangladesh, CGP',
    key: 'CGP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shah Makhdum, Rajshahi, Bangladesh, RJH',
    key: 'RJH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shahid Sadooghi, Yazd, Iran, AZD',
    key: 'AZD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shahjalal International, Dhaka, Bangladesh, DAC',
    key: 'DAC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shahrekord Airport, Iran, CQD',
    key: 'CQD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shaikh Zayed International, Rahim Yar Khan, Pakistan, RYK',
    key: 'RYK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shakhtersk, Shakhtyorsk, Russia, EKS',
    key: 'EKS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shaktoolik, United States, SKK',
    key: 'SKK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shamattawa, Shamattawa First Nation, Canada, ZTM',
    key: 'ZTM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shanghai Hongqiao International, China, SHA',
    key: 'SHA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shanghai Pudong International, China, PVG',
    key: 'PVG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shangrao Sanqingshan, China, SQD',
    key: 'SQD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shannon, Shannon, County Clare, Ireland, SNN',
    key: 'SNN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shaoyang Wugang, China, WGN',
    key: 'WGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sharjah International, Dubai, United Arab Emirates, SHJ',
    key: 'SHJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shark Bay, Monkey Mia, Australia, MJK',
    key: 'MJK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sharm el-Sheikh International, Sharm El Sheikh, Egypt, SSH',
    key: 'SSH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sharurah Domestic, Saudi Arabia, SHW',
    key: 'SHW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sheldon Point, Nunam Iqua, United States, SXP',
    key: 'SXP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shenandoah Valley Regional, Staunton, Virginia, United States, SHD',
    key: 'SHD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shennongjia Hongping, China, HPG',
    key: 'HPG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shenyang Taoxian International, China, SHE',
    key: 'SHE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shenzhen Bao an International, China, SZX',
    key: 'SZX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sheremetyevo International, Moscow, Russia, SVO',
    key: 'SVO',
  },
  { icon: 'faPlaneDeparture', label: 'Shigatse Peace, China, RKZ', key: 'RKZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Shihezi Huayuan, China, SHF',
    key: 'SHF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shijiazhuang Zhengding International, China, SJW',
    key: 'SJW',
  },
  { icon: 'faPlaneDeparture', label: 'Shillong, India, SHL', key: 'SHL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Shirak, Gyumri, Armenia, LWN',
    key: 'LWN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shiraz International, Iran, SYZ',
    key: 'SYZ',
  },
  { icon: 'faPlaneDeparture', label: 'Shire, Ethiopia, SHC', key: 'SHC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Shishmaref, United States, SHH',
    key: 'SHH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shiyan Wudangshan, China, WDS',
    key: 'WDS',
  },
  { icon: 'faPlaneDeparture', label: 'Shizuoka, Japan, FSZ', key: 'FSZ' },
  { icon: 'faPlaneDeparture', label: 'Shonai, Japan, SYO', key: 'SYO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Shreveport Regional, United States, SHV',
    key: 'SHV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shri Guru Gobind Singh Ji, Nanded, India, NDC',
    key: 'NDC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shungnak, United States, SHG',
    key: 'SHG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Shymkent International, Kazakhstan, CIT',
    key: 'CIT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sialkot International, Pakistan, SKT',
    key: 'SKT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sibiu International, Romania, SBZ',
    key: 'SBZ',
  },
  { icon: 'faPlaneDeparture', label: 'Sibu, Malaysia, SBW', key: 'SBW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sibulan, Dumaguete, Philippines, DGT',
    key: 'DGT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sidi Mahdi, Touggourt, Algeria, TGR',
    key: 'TGR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sidney–Richland Municipal, United States, SDY',
    key: 'SDY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Siem Reap International, Cambodia, REP',
    key: 'REP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sierra Maestra, Manzanillo, Cuba, MZO',
    key: 'MZO',
  },
  { icon: 'faPlaneDeparture', label: 'Sigiriya, Sri Lanka, GIU', key: 'GIU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sihanoukville International, Sihanoukville Province, Cambodia, KOS',
    key: 'KOS',
  },
  { icon: 'faPlaneDeparture', label: 'Siirt, Turkey, SXZ', key: 'SXZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Silangit International, Siborong-Borong, Indonesia, DTB',
    key: 'DTB',
  },
  { icon: 'faPlaneDeparture', label: 'Silchar, India, IXS', key: 'IXS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Silvio Pettirossi International, Asunción, Paraguay, ASU',
    key: 'ASU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Simara, Simara, Bara, Nepal, SIF',
    key: 'SIF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Simferopol International, Unknown Region, SIP',
    key: 'SIP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Simón Bolívar International, Caracas, Venezuela, CCS',
    key: 'CCS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Simón Bolívar International, Santa Marta, Colombia, SMR',
    key: 'SMR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Simon Mwansa Kapwepwe International, Ndola, Zambia, NLA',
    key: 'NLA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Singapore Changi, Singapore, SIN',
    key: 'SIN',
  },
  { icon: 'faPlaneDeparture', label: 'Sinop, Turkey, NOP', key: 'NOP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sinop, Sinop, Mato Grosso, Brazil, OPS',
    key: 'OPS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sintang, Sintang Regency, Indonesia, SQG',
    key: 'SQG',
  },
  { icon: 'faPlaneDeparture', label: 'Sion, Switzerland, SIR', key: 'SIR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sioux Falls Regional, United States, FSD',
    key: 'FSD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sioux Gateway, Sioux City, United States, SUX',
    key: 'SUX',
  },
  { icon: 'faPlaneDeparture', label: 'Sioux Lookout, Canada, YXL', key: 'YXL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sir Gaëtan Duval, Rodrigues, Mauritius, RRG',
    key: 'RRG',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Sir Seewoosagur Ramgoolam International, Mauritius Island, Mauritius, MRU',
    key: 'MRU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sir Seretse Khama International, Gaborone, Botswana, GBE',
    key: 'GBE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sishen, Dingleton, South Africa, SIS',
    key: 'SIS',
  },
  { icon: 'faPlaneDeparture', label: 'Sisimiut, Greenland, JHS', key: 'JHS' },
  { icon: 'faPlaneDeparture', label: 'Sitia Public, Greece, JSH', key: 'JSH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sitka Rocky Gutierrez, United States, SIT',
    key: 'SIT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sittwe, Myanmar (Burma), AKY',
    key: 'AKY',
  },
  { icon: 'faPlaneDeparture', label: 'Sivas, Turkey, VAS', key: 'VAS' },
  { icon: 'faPlaneDeparture', label: 'Siwo, Emae, Vanuatu, EAE', key: 'EAE' },
  { icon: 'faPlaneDeparture', label: 'Şırnak, Turkey, NKT', key: 'NKT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Skagway, United States, SGY',
    key: 'SGY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Skardu, Unknown Region, KDU',
    key: 'KDU',
  },
  { icon: 'faPlaneDeparture', label: 'Skellefteå, Sweden, SFT', key: 'SFT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Skiathos Island National, Greece, JSI',
    key: 'JSI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Skopje  Alexander the Great , Macedonia (FYROM), SKP',
    key: 'SKP',
  },
  { icon: 'faPlaneDeparture', label: 'Skukuza, South Africa, SZK', key: 'SZK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Skyros Island National, Greece, SKU',
    key: 'SKU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sleetmute, United States, SLQ',
    key: 'SLQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sloulin Field International, Williston, United States, ISN',
    key: 'ISN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Smith Reynolds, Winston-Salem, United States, INT',
    key: 'INT',
  },
  { icon: 'faPlaneDeparture', label: 'Smithers, Canada, YYD', key: 'YYD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sochi International, Russia, AER',
    key: 'AER',
  },
  { icon: 'faPlaneDeparture', label: 'Socotra, Yemen, SCT', key: 'SCT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Soekarno–Hatta International, Jakarta, Indonesia, CGK',
    key: 'CGK',
  },
  { icon: 'faPlaneDeparture', label: 'Sofia, Bulgaria, SOF', key: 'SOF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sogndal Airport, Haukåsen, Norway, SOG',
    key: 'SOG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sohag International, Egypt, HMB',
    key: 'HMB',
  },
  { icon: 'faPlaneDeparture', label: 'Sohar, Oman, OHS', key: 'OHS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sokol, Magadan, Russia, GDX',
    key: 'GDX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Solidarity Szczecin–Goleniów, Poland, SZZ',
    key: 'SZZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Solovki, Solovetsky, Arkhangelsk Oblast, Russia, CSH',
    key: 'CSH',
  },
  { icon: 'faPlaneDeparture', label: 'Solwezi, Zambia, SLI', key: 'SLI' },
  { icon: 'faPlaneDeparture', label: 'Sønderborg, Denmark, SGD', key: 'SGD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Songyuan Chaganhu, China, YSQ',
    key: 'YSQ',
  },
  { icon: 'faPlaneDeparture', label: 'Sørkjosen, Norway, SOJ', key: 'SOJ' },
  { icon: 'faPlaneDeparture', label: 'Sorriso, Brazil, SMT', key: 'SMT' },
  {
    icon: 'faPlaneDeparture',
    label:
      'South Arkansas Regional Airport at Goodwin Field, El Dorado, United States, ELD',
    key: 'ELD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'South Bend International, United States, SBN',
    key: 'SBN',
  },
  { icon: 'faPlaneDeparture', label: 'South Bimini, Bahamas, BIM', key: 'BIM' },
  {
    icon: 'faPlaneDeparture',
    label: 'South Caicos, Turks & Caicos Islands, XSC',
    key: 'XSC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'South Indian Lake, Canada, XSI',
    key: 'XSI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'South Naknek, United States, WSN',
    key: 'WSN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'South West Bay, Vanuatu, SWJ',
    key: 'SWJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Southampton, United Kingdom, SOU',
    key: 'SOU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Southeast Iowa Regional, Burlington, United States, BRL',
    key: 'BRL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Southwest Florida International, Fort Myers, United States, RSW',
    key: 'RSW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Southwest Georgia Regional, Albany, United States, ABY',
    key: 'ABY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Southwest Oregon Regional, North Bend, United States, OTH',
    key: 'OTH',
  },
  { icon: 'faPlaneDeparture', label: 'Sovetsky, Russia, OVS', key: 'OVS' },
  { icon: 'faPlaneDeparture', label: 'Soyo, Angola, SZA', key: 'SZA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Spichenkovo, Novokuznetsk, Russia, NOZ',
    key: 'NOZ',
  },
  { icon: 'faPlaneDeparture', label: 'Split, Croatia, SPU', key: 'SPU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Spokane International, United States, GEG',
    key: 'GEG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Spriggs Payne Airport, Monrovia, Liberia, MLW',
    key: 'MLW',
  },
  { icon: 'faPlaneDeparture', label: 'Spring Point, Bahamas, AXP', key: 'AXP' },
  {
    icon: 'faPlaneDeparture',
    label: 'Springfield-Branson National, United States, SGF',
    key: 'SGF',
  },
  { icon: 'faPlaneDeparture', label: 'Srednekolymsk, Russia, SEK', key: 'SEK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sri Guru Ram Dass Jee International, Amritsar, India, ATQ',
    key: 'ATQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Srinagar International, Unknown Region, SXR',
    key: 'SXR',
  },
  { icon: 'faPlaneDeparture', label: 'St George, Australia, SGO', key: 'SGO' },
  {
    icon: 'faPlaneDeparture',
    label: 'St-Nazaire Montoir, Saint Nazaire, France, SNR',
    key: 'SNR',
  },
  { icon: 'faPlaneDeparture', label: 'St. Anthony, Canada, YAY', key: 'YAY' },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Cloud Regional, United States, STC',
    key: 'STC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Gallen–Altenrhein, Thal, Switzerland, ACH',
    key: 'ACH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. George, United States, STG',
    key: 'STG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. George Regional, United States, SGU',
    key: 'SGU',
  },
  {
    icon: 'faPlaneDeparture',
    label: "St. John's International, St. John s, Canada, YYT",
    key: 'YYT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Louis Lambert International, United States, STL',
    key: 'STL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Mary s, United States, KSM',
    key: 'KSM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Michael, United States, SMK',
    key: 'SMK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Paul Island, United States, SNP',
    key: 'SNP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Pete–Clearwater International, Tampa, United States, PIE',
    key: 'PIE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'St. Theresa Point, St. Theresa Point First Nation, Canada, YST',
    key: 'YST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stavanger Airport, Sola, Norway, SVG',
    key: 'SVG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stavropol Shpakovskoye, Russia, STW',
    key: 'STW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stebbins, United States, WBB',
    key: 'WBB',
  },
  { icon: 'faPlaneDeparture', label: 'Stella Maris, Bahamas, SML', key: 'SML' },
  {
    icon: 'faPlaneDeparture',
    label: 'Stephenville International, Canada, YJT',
    key: 'YJT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stewart International, New York City, United States, SWF',
    key: 'SWF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stillwater Regional, United States, SWO',
    key: 'SWO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stockholm Arlanda, Sweden, ARN',
    key: 'ARN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stockholm Bromma, Sweden, BMA',
    key: 'BMA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stockholm Skavsta, Sweden, NYO',
    key: 'NYO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stockholm Västerås, Sweden, VST',
    key: 'VST',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stockton Metropolitan, United States, SCK',
    key: 'SCK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stokmarknes Airport, Skagen, Norway, SKN',
    key: 'SKN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stony River, United States, SRV',
    key: 'SRV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stord Airport, Sørstokken, Norway, SRP',
    key: 'SRP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stornoway, United Kingdom, SYY',
    key: 'SYY',
  },
  { icon: 'faPlaneDeparture', label: 'Strasbourg, France, SXB', key: 'SXB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Strezhevoy, Strzhewoi, Russia, SWT',
    key: 'SWT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Strigino International, Nizhny Novgorod, Russia, GOJ',
    key: 'GOJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Stronsay, United Kingdom, SOY',
    key: 'SOY',
  },
  { icon: 'faPlaneDeparture', label: 'Stuttgart, Germany, STR', key: 'STR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Suavanao, Solomon Islands, VAO',
    key: 'VAO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Suceava International, Romania, SCV',
    key: 'SCV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sudbury, Greater Sudbury, Canada, YSB',
    key: 'YSB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sugimanuru, Raha, Indonesia, RAQ',
    key: 'RAQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sukhothai, Sukhothai Province, Thailand, THS',
    key: 'THS',
  },
  { icon: 'faPlaneDeparture', label: 'Sukkur, Pakistan, SKZ', key: 'SKZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sulaimaniyah International, Sulaymaniyah, Iraq, ISU',
    key: 'ISU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Abdul Aziz Shah, Kuala Lumpur, Malaysia, SZB',
    key: 'SZB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Abdul Halim, Alor Setar, Malaysia, AOR',
    key: 'AOR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Aji Muhammad Sulaiman, Balikpapan, Indonesia, BPN',
    key: 'BPN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Azlan Shah, Ipoh, Malaysia, IPH',
    key: 'IPH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Bantilan, Tolitoli, Indonesia, TLI',
    key: 'TLI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Haji Ahmad Shah, Kuantan, Malaysia, KUA',
    key: 'KUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Hasanuddin International, Makassar, Indonesia, UPG',
    key: 'UPG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Iskandar Muda International, Banda Aceh, Indonesia, BTJ',
    key: 'BTJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Ismail Petra, Kota Bharu, Malaysia, KBR',
    key: 'KBR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Mahmud, Kuala Terengganu, Malaysia, TGG',
    key: 'TGG',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Sultan Mahmud Badaruddin II International, Palembang, Indonesia, PLM',
    key: 'PLM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Muhammad Kaharuddin III, Sumbawa Besar, Indonesia, SWQ',
    key: 'SWQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Muhammad Salahudin, Bima, Indonesia, BMU',
    key: 'BMU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Syarif Kasim II International, Pekanbaru, Indonesia, PKU',
    key: 'PKU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sultan Thaha, Jambi City, Indonesia, DJB',
    key: 'DJB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sumburgh, Shetland Islands, United Kingdom, LSI',
    key: 'LSI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sunan Shuofang International, Wuxi, China, WUX',
    key: 'WUX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sundsvall–Timrå, Sweden, SDL',
    key: 'SDL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Sunshine Coast, Sunshine Coast Region, Australia, MCY',
    key: 'MCY',
  },
  { icon: 'faPlaneDeparture', label: 'Suntar, Russia, SUY', key: 'SUY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Supadio International, Pontianak, Indonesia, PNK',
    key: 'PNK',
  },
  { icon: 'faPlaneDeparture', label: 'Surat, India, STV', key: 'STV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Surat Thani International, Surat Thani Province, Thailand, URT',
    key: 'URT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Surgut International, Russia, SGC',
    key: 'SGC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Surigao, Surigao City, Philippines, SUG',
    key: 'SUG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Surkhet Airport, Nepal, SKH',
    key: 'SKH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Suvarnabhumi, Bangkok, Thailand, BKK',
    key: 'BKK',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Svalbard Airport, Longyear, Longyearbyen, Svalbard & Jan Mayen, LYR',
    key: 'LYR',
  },
  { icon: 'faPlaneDeparture', label: 'Sveg, Sweden, EVG', key: 'EVG' },
  {
    icon: 'faPlaneDeparture',
    label: 'Svolvær Airport, Helle, Norway, SVJ',
    key: 'SVJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Swami Vivekananda, Raipur, India, RPR',
    key: 'RPR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Syamsudin Noor International, Banjarmasin, Indonesia, BDJ',
    key: 'BDJ',
  },
  { icon: 'faPlaneDeparture', label: 'Sydney, Australia, SYD', key: 'SYD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sydney/J.A. Douglas McCurdy, Canada, YQY',
    key: 'YQY',
  },
  { icon: 'faPlaneDeparture', label: 'Syktyvkar, Russia, SCW', key: 'SCW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Sylt, Westerland, Germany, GWT',
    key: 'GWT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Syracuse Hancock International, United States, SYR',
    key: 'SYR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Syros Island National, Greece, JSY',
    key: 'JSY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'T. F. Green, Providence, United States, PVD',
    key: 'PVD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ta if Regional, Saudi Arabia, TIF',
    key: 'TIF',
  },
  { icon: 'faPlaneDeparture', label: 'Tabas Airport, Iran, TCX', key: 'TCX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tabatinga International, Brazil, TBT',
    key: 'TBT',
  },
  { icon: 'faPlaneDeparture', label: 'Tabora, Tanzania, TBO', key: 'TBO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tabriz International, Iran, TBZ',
    key: 'TBZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tabubil, Papua New Guinea, TBG',
    key: 'TBG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tabuk Regional, Saudi Arabia, TUU',
    key: 'TUU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tacheng, Tacheng Prefecture, China, TCG',
    key: 'TCG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tachilek, Tachileik, Myanmar (Burma), THL',
    key: 'THL',
  },
  { icon: 'faPlaneDeparture', label: 'Tadoule Lake, Canada, XTL', key: 'XTL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tagbilaran, Philippines, TAG',
    key: 'TAG',
  },
  { icon: 'faPlaneDeparture', label: 'Taichung, Taiwan, RMQ', key: 'RMQ' },
  { icon: 'faPlaneDeparture', label: 'Tainan, Taiwan, TNN', key: 'TNN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Taipei Songshan, Taiwan, TSA',
    key: 'TSA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Taitung, Taitung County, Taiwan, TTT',
    key: 'TTT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Taiwan Taoyuan International, Taipei, Taiwan, TPE',
    key: 'TPE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Taiyuan Wusu International, China, TYN',
    key: 'TYN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Taiz International, Taiz Governorate, Yemen, TAI',
    key: 'TAI',
  },
  { icon: 'faPlaneDeparture', label: 'Taizhou Luqiao, China, HYN', key: 'HYN' },
  { icon: 'faPlaneDeparture', label: 'Takamatsu, Japan, TAK', key: 'TAK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Takapoto, French Polynesia, TKP',
    key: 'TKP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Takaroa, French Polynesia, TKX',
    key: 'TKX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Takengon Rembele, Indonesia, TXE',
    key: 'TXE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Takhamalt, Illizi, Algeria, VVZ',
    key: 'VVZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Takoradi, Sekondi-Takoradi, Ghana, TKD',
    key: 'TKD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Talagi, Arkhangelsk, Russia, ARH',
    key: 'ARH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Talakan, Vitim, Sakha Republic, Russia, TLK',
    key: 'TLK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Taldykorgan, Kazakhstan, TDK',
    key: 'TDK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tallahassee International, United States, TLH',
    key: 'TLH',
  },
  { icon: 'faPlaneDeparture', label: 'Tallinn, Estonia, TLL', key: 'TLL' },
  { icon: 'faPlaneDeparture', label: 'Taloyoak, Canada, YYH', key: 'YYH' },
  { icon: 'faPlaneDeparture', label: 'Tamale, Ghana, TML', key: 'TML' },
  { icon: 'faPlaneDeparture', label: 'Tamarindo, Costa Rica, TNO', key: 'TNO' },
  { icon: 'faPlaneDeparture', label: 'Tambolaka, Indonesia, TMC', key: 'TMC' },
  { icon: 'faPlaneDeparture', label: 'Tambor, Costa Rica, TMU', key: 'TMU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tambov Donskoye, Russia, TBW',
    key: 'TBW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tampa International, United States, TPA',
    key: 'TPA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tampa Padang, Mamuju Regency, Indonesia, MJU',
    key: 'MJU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tampere–Pirkkala, Finland, TMP',
    key: 'TMP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tampico International, Mexico, TAM',
    key: 'TAM',
  },
  { icon: 'faPlaneDeparture', label: 'Tamworth, Australia, TMW', key: 'TMW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tan Son Nhat International, Ho Chi Minh City, Vietnam, SGN',
    key: 'SGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tan Tan, Tan-Tan, Morocco, TTA',
    key: 'TTA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tancredo Neves International, Belo Horizonte, Brazil, CNF',
    key: 'CNF',
  },
  { icon: 'faPlaneDeparture', label: 'Tandag, Philippines, TDG', key: 'TDG' },
  { icon: 'faPlaneDeparture', label: 'Tanga, Tanzania, TGT', key: 'TGT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tangier Ibn Battouta, Morocco, TNG',
    key: 'TNG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tangshan Sannühe, China, TVS',
    key: 'TVS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tanjung Harapan, Tanjung Selor, Indonesia, TJS',
    key: 'TJS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tanjung Manis, Malaysia, TGC',
    key: 'TGC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tapachula International, Mexico, TAP',
    key: 'TAP',
  },
  { icon: 'faPlaneDeparture', label: 'Taraz, Kazakhstan, DMB', key: 'DMB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tarbes–Lourdes–Pyrénées, France, LDE',
    key: 'LDE',
  },
  { icon: 'faPlaneDeparture', label: 'Taree, Australia, TRO', key: 'TRO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Târgu Mureș International, Romania, TGM',
    key: 'TGM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tari, Papua New Guinea, TIZ',
    key: 'TIZ',
  },
  { icon: 'faPlaneDeparture', label: 'Tartu, Estonia, TAY', key: 'TAY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tashkent International, Uzbekistan, TAS',
    key: 'TAS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tasikmalaya Wiriadinata, Indonesia, TSY',
    key: 'TSY',
  },
  { icon: 'faPlaneDeparture', label: 'Tasiujaq, Canada, YTQ', key: 'YTQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tatakoto, French Polynesia, TKV',
    key: 'TKV',
  },
  { icon: 'faPlaneDeparture', label: 'Taupo, New Zealand, TUO', key: 'TUO' },
  { icon: 'faPlaneDeparture', label: 'Tauranga, New Zealand, TRG', key: 'TRG' },
  { icon: 'faPlaneDeparture', label: 'Tawau, Malaysia, TWU', key: 'TWU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tazadit, Zouérat, Mauritania, OUZ',
    key: 'OUZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tbilisi International, Georgia, TBS',
    key: 'TBS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ted Stevens Anchorage International, United States, ANC',
    key: 'ANC',
  },
  { icon: 'faPlaneDeparture', label: 'Tefé, Brazil, TFF', key: 'TFF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tehran Imam Khomeini International, Iran, IKA',
    key: 'IKA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Teixeira de Freitas, Brazil, TXF',
    key: 'TXF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tekirdağ Çorlu, Turkey, TEQ',
    key: 'TEQ',
  },
  { icon: 'faPlaneDeparture', label: 'Teller, United States, TLA', key: 'TLA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Telluride Regional, United States, TEX',
    key: 'TEX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tenakee Seaplane Base, Tenakee Springs, United States, TKE',
    key: 'TKE',
  },
  { icon: 'faPlaneDeparture', label: 'Tenerife-North, Spain, TFN', key: 'TFN' },
  { icon: 'faPlaneDeparture', label: 'Tenerife–South, Spain, TFS', key: 'TFS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tengchong Tuofeng, China, TCZ',
    key: 'TCZ',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Teniente General Benjamín Matienzo International, San Miguel de Tucumán, Argentina, TUC',
    key: 'TUC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Teniente Jorge Henrich Arauz, Trinidad, Bolivia, TDD',
    key: 'TDD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Teniente Julio Gallardo, Puerto Natales, Chile, PNT',
    key: 'PNT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tennant Creek, Australia, TCA',
    key: 'TCA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tenzing–Hillary, Lukla, Nepal, LUA',
    key: 'LUA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tepic International, Mexico, TPQ',
    key: 'TPQ',
  },
  { icon: 'faPlaneDeparture', label: 'Termez, Uzbekistan, TMJ', key: 'TMJ' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Terrance B. Lettsome International, Tortola, British Virgin Islands, EIS',
    key: 'EIS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Terre-de-Haut Airport, Les Saintes, France, LSS',
    key: 'LSS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tête-à-la-Baleine, Tête-à-la-Baleine, Quebec, Canada, ZTB',
    key: 'ZTB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Texarkana Regional, United States, TXK',
    key: 'TXK',
  },
  { icon: 'faPlaneDeparture', label: 'Tezpur, India, TEZ', key: 'TEZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Thandwe, Myanmar (Burma), SNW',
    key: 'SNW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Thangool, Biloela, Australia, ZBL',
    key: 'ZBL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Thanh Hoa Tho Xuan, Thanh Hoa Province, Vietnam, THD',
    key: 'THD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Thargomindah, Australia, XTG',
    key: 'XTG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'The Eastern Iowa, Cedar Rapids, United States, CID',
    key: 'CID',
  },
  { icon: 'faPlaneDeparture', label: 'The Pas, Canada, YQD', key: 'YQD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Thessaloniki International Airport  Macedonia , Greece, SKG',
    key: 'SKG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Thief River Falls Regional, United States, TVF',
    key: 'TVF',
  },
  { icon: 'faPlaneDeparture', label: 'Thompson, Canada, YTH', key: 'YTH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Thorne Bay Seaplane Base, United States, KTB',
    key: 'KTB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Thunder Bay International, Canada, YQT',
    key: 'YQT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tianjin Binhai International, China, TSN',
    key: 'TSN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tiga, Tiga Island, New Caledonia, TGJ',
    key: 'TGJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tijuana International, Mexico, TIJ',
    key: 'TIJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tikehau, French Polynesia, TIH',
    key: 'TIH',
  },
  { icon: 'faPlaneDeparture', label: 'Tiksi, Russia, IKS', key: 'IKS' },
  { icon: 'faPlaneDeparture', label: 'Timaru, New Zealand, TIU', key: 'TIU' },
  { icon: 'faPlaneDeparture', label: 'Timimoun, Algeria, TMX', key: 'TMX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Timișoara Traian Vuia International, Romania, TSR',
    key: 'TSR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Timmins/Victor M. Power, Canada, YTS',
    key: 'YTS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tin City LRRS, United States, TNC',
    key: 'TNC',
  },
  { icon: 'faPlaneDeparture', label: 'Tindouf, Algeria, TIN', key: 'TIN' },
  { icon: 'faPlaneDeparture', label: 'Tingo María, Peru, TGI', key: 'TGI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tirana International Airport Nënë Tereza, Albania, TIA',
    key: 'TIA',
  },
  { icon: 'faPlaneDeparture', label: 'Tiree, United Kingdom, TRE', key: 'TRE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tiruchirappalli International, India, TRZ',
    key: 'TRZ',
  },
  { icon: 'faPlaneDeparture', label: 'Tirupati, India, TIR', key: 'TIR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tiska, Djanet, Algeria, DJG',
    key: 'DJG',
  },
  { icon: 'faPlaneDeparture', label: 'Tivat, Montenegro, TIV', key: 'TIV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tjilik Riwut, Palangka Raya, Indonesia, PKY',
    key: 'PKY',
  },
  { icon: 'faPlaneDeparture', label: 'Toamasina, Madagascar, TMM', key: 'TMM' },
  { icon: 'faPlaneDeparture', label: 'Tobruk, Libya, TOB', key: 'TOB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tocumen International, Panama City, Panama, PTY',
    key: 'PTY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tofino/Long Beach, Canada, YAZ',
    key: 'YAZ',
  },
  { icon: 'faPlaneDeparture', label: 'Togiak, United States, TOG', key: 'TOG' },
  { icon: 'faPlaneDeparture', label: 'Tok, United States, TKJ', key: 'TKJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tokachi–Obihiro, Japan, OBO',
    key: 'OBO',
  },
  { icon: 'faPlaneDeparture', label: 'Tokat, Turkey, TJK', key: 'TJK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Toksook Bay, United States, OOK',
    key: 'OOK',
  },
  { icon: 'faPlaneDeparture', label: 'Tokunoshima, Japan, TKN', key: 'TKN' },
  { icon: 'faPlaneDeparture', label: 'Tokushima, Japan, TKS', key: 'TKS' },
  { icon: 'faPlaneDeparture', label: 'Tôlanaro, Madagascar, FTU', key: 'FTU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Toledo Express, United States, TOL',
    key: 'TOL',
  },
  { icon: 'faPlaneDeparture', label: 'Toliara, Madagascar, TLE', key: 'TLE' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tolmachevo, Novosibirsk, Russia, OVB',
    key: 'OVB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Toluca International, Mexico, TLC',
    key: 'TLC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Toncontín International, Tegucigalpa, Honduras, TGU',
    key: 'TGU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tonghua Sanyuanpu, China, TNH',
    key: 'TNH',
  },
  { icon: 'faPlaneDeparture', label: 'Tongliao, China, TGO', key: 'TGO' },
  { icon: 'faPlaneDeparture', label: 'Tongoa, Vanuatu, TGH', key: 'TGH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tongren Fenghuang, China, TEN',
    key: 'TEN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Toronto Pearson International, Canada, YYZ',
    key: 'YYZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Torreón International, Mexico, TRC',
    key: 'TRC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Torres, Torres Islands, Vanuatu, TOH',
    key: 'TOH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tortuguero, Costa Rica, TTQ',
    key: 'TTQ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Totegegie, French Polynesia, GMR',
    key: 'GMR',
  },
  { icon: 'faPlaneDeparture', label: 'Tottori, Japan, TTJ', key: 'TTJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Touat-Cheikh Sidi Mohamed Belkebir, Adrar, Algeria, AZR',
    key: 'AZR',
  },
  { icon: 'faPlaneDeparture', label: 'Touho, New Caledonia, TOU', key: 'TOU' },
  { icon: 'faPlaneDeparture', label: 'Toulon–Hyères, France, TLN', key: 'TLN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Toulouse–Blagnac, France, TLS',
    key: 'TLS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tours Val de Loire, France, TUF',
    key: 'TUF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Toussaint Louverture International, Port-au-Prince, Haiti, PAP',
    key: 'PAP',
  },
  { icon: 'faPlaneDeparture', label: 'Townsville, Australia, TSV', key: 'TSV' },
  { icon: 'faPlaneDeparture', label: 'Toyama, Japan, TOY', key: 'TOY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tozeur–Nefta International, Tunisia, TOE',
    key: 'TOE',
  },
  { icon: 'faPlaneDeparture', label: 'Trabzon, Turkey, TZX', key: 'TZX' },
  { icon: 'faPlaneDeparture', label: 'Trail, Canada, YZZ', key: 'YZZ' },
  { icon: 'faPlaneDeparture', label: 'Trang, Thailand, TST', key: 'TST' },
  {
    icon: 'faPlaneDeparture',
    label: 'Trat, Trat Province, Thailand, TDX',
    key: 'TDX',
  },
  { icon: 'faPlaneDeparture', label: 'Treasure Cay, Bahamas, TCB', key: 'TCB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Trenton–Mercer, United States, TTN',
    key: 'TTN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tres de Mayo, Puerto Asís, Colombia, PUU',
    key: 'PUU',
  },
  { icon: 'faPlaneDeparture', label: 'Três Lagoas, Brazil, TJL', key: 'TJL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Treviso, Venice, Italy, TSF',
    key: 'TSF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tri-Cities, Pasco, United States, PSC',
    key: 'PSC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tri-Cities Regional, Bristol, United States, TRI',
    key: 'TRI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tri-State, Huntington, United States, HTS',
    key: 'HTS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tribhuvan International, Kathmandu, Nepal, KTM',
    key: 'KTM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Trieste – Friuli Venezia Giulia, Italy, TRS',
    key: 'TRS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tripoli International, Libya, TIP',
    key: 'TIP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Trivandrum International, Thiruvananthapuram, India, TRV',
    key: 'TRV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Trollhättan–Vänersborg, Sweden, THN',
    key: 'THN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tromsø Airport, Langnes, Norway, TOS',
    key: 'TOS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Trondheim Airport, Værnes, Norway, TRD',
    key: 'TRD',
  },
  { icon: 'faPlaneDeparture', label: 'Tsushima, Japan, TSJ', key: 'TSJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tubuai – Mataura, French Polynesia, TUB',
    key: 'TUB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tucson International, United States, TUS',
    key: 'TUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tufi, Papua New Guinea, TFI',
    key: 'TFI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tugdan, Tablas Island, Philippines, TBH',
    key: 'TBH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuguegarao, Philippines, TUG',
    key: 'TUG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuktoyaktuk/James Gruben, Canada, YUB',
    key: 'YUB',
  },
  { icon: 'faPlaneDeparture', label: 'Tulita, Canada, ZFN', key: 'ZFN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tulsa International, United States, TUL',
    key: 'TUL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuluksak, United States, TLT',
    key: 'TLT',
  },
  { icon: 'faPlaneDeparture', label: 'Tumlingtar, Nepal, TMI', key: 'TMI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tunis–Carthage International, Tunisia, TUN',
    key: 'TUN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tunoshna, Yaroslavl, Russia, IAR',
    key: 'IAR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuntutuliak, United States, WTL',
    key: 'WTL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tununak, United States, TNK',
    key: 'TNK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tupelo Regional, United States, TUP',
    key: 'TUP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Turaif Domestic, Saudi Arabia, TUI',
    key: 'TUI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Turbat International, Pakistan, TUK',
    key: 'TUK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Turelelo Soa, Bajawa, Indonesia, BJW',
    key: 'BJW',
  },
  { icon: 'faPlaneDeparture', label: 'Turin, Italy, TRN', key: 'TRN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Turkmenabat, Türkmenabat, Turkmenistan, CRZ',
    key: 'CRZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Turkmenbashi, Türkmenbaşy, Turkmenistan, KRW',
    key: 'KRW',
  },
  { icon: 'faPlaneDeparture', label: 'Turku, Finland, TKU', key: 'TKU' },
  { icon: 'faPlaneDeparture', label: 'Turpan Jiaohe, China, TLQ', key: 'TLQ' },
  { icon: 'faPlaneDeparture', label: 'Turukhansk, Russia, THX', key: 'THX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuticorin, Thoothukudi, India, TCR',
    key: 'TCR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuxtla Gutiérrez International, Mexico, TGZ',
    key: 'TGZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tuzla International, Bosnia & Herzegovina, TZL',
    key: 'TZL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tweed New Haven, United States, HVN',
    key: 'HVN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Tyler Pounds Regional, United States, TYR',
    key: 'TYR',
  },
  { icon: 'faPlaneDeparture', label: 'Tynda, Russia, TYD', key: 'TYD' },
  {
    icon: 'faPlaneDeparture',
    label: 'U-Tapao International, Pattaya, Thailand, UTP',
    key: 'UTP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ua Huka, French Polynesia, UAH',
    key: 'UAH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ua Pou, French Polynesia, UAP',
    key: 'UAP',
  },
  { icon: 'faPlaneDeparture', label: 'Uberaba, Brazil, UBA', key: 'UBA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Uberlandia, Uberlândia, Brazil, UDI',
    key: 'UDI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ubon Ratchathani, Ubon Ratchathani Province, Thailand, UBP',
    key: 'UBP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Udon Thani International, Thailand, UTH',
    key: 'UTH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ufa International, Russia, UFA',
    key: 'UFA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ugolny, Anadyr, Russia, DYR',
    key: 'DYR',
  },
  { icon: 'faPlaneDeparture', label: 'Ukhta, Russia, UCT', key: 'UCT' },
  { icon: 'faPlaneDeparture', label: 'Ukunda, Kenya, UKA', key: 'UKA' },
  { icon: 'faPlaneDeparture', label: 'Ulaangom, Mongolia, ULO', key: 'ULO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ulanhot, Ulan Hot, China, HLH',
    key: 'HLH',
  },
  { icon: 'faPlaneDeparture', label: 'Ulanqab Jining, China, UCB', key: 'UCB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ulawa, Arona, Solomon Islands, RNA',
    key: 'RNA',
  },
  { icon: 'faPlaneDeparture', label: 'Ulei, Toak, Vanuatu, ULB', key: 'ULB' },
  { icon: 'faPlaneDeparture', label: 'Ulsan, South Korea, USN', key: 'USN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ulukhaktok/Holman, Canada, YHI',
    key: 'YHI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ulusaba, Sabi Sand Game Reserve, South Africa, ULX',
    key: 'ULX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ulyanovsk Baratayevka, Russia, ULV',
    key: 'ULV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ulyanovsk Vostochny, Russia, ULY',
    key: 'ULY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Umbu Mehang Kunda, Waingapu, Indonesia, WGP',
    key: 'WGP',
  },
  { icon: 'faPlaneDeparture', label: 'Umeå, Sweden, UME', key: 'UME' },
  { icon: 'faPlaneDeparture', label: 'Umiujaq, Canada, YUD', key: 'YUD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Unalakleet, United States, UNK',
    key: 'UNK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Unalaska, Dutch Harbor, United States, DUT',
    key: 'DUT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'University of Illinois Willard, Champaign, United States, CMI',
    key: 'CMI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'University Park, State College, United States, SCE',
    key: 'SCE',
  },
  { icon: 'faPlaneDeparture', label: 'Upernavik, Greenland, JUV', key: 'JUV' },
  {
    icon: 'faPlaneDeparture',
    label: 'Upington, South Africa, UTN',
    key: 'UTN',
  },
  { icon: 'faPlaneDeparture', label: 'Uray, Russia, URJ', key: 'URJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Urdzhar, Urzhar, Kazakhstan, UZR',
    key: 'UZR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Urgench International, Uzbekistan, UGC',
    key: 'UGC',
  },
  { icon: 'faPlaneDeparture', label: 'Urmia, Iran, OMH', key: 'OMH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Uru Harbour, Atoifi, Solomon Islands, ATD',
    key: 'ATD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Uruapan International, Mexico, UPN',
    key: 'UPN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Ürümqi Diwopu International, China, URC',
    key: 'URC',
  },
  { icon: 'faPlaneDeparture', label: 'Uşak, Usak, Turkey, USQ', key: 'USQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ushuaia – Malvinas Argentinas International, Argentina, USH',
    key: 'USH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Usiminas, Ipatinga, Brazil, IPN',
    key: 'IPN',
  },
  { icon: 'faPlaneDeparture', label: 'Usinsk, Russia, USK', key: 'USK' },
  { icon: 'faPlaneDeparture', label: 'Ust-Kut, Russia, UKX', key: 'UKX' },
  { icon: 'faPlaneDeparture', label: 'Ust-Kuyga, Russia, UKG', key: 'UKG' },
  { icon: 'faPlaneDeparture', label: 'Ust-Maya, Russia, UMS', key: 'UMS' },
  { icon: 'faPlaneDeparture', label: 'Ust-Nera, Russia, USR', key: 'USR' },
  { icon: 'faPlaneDeparture', label: 'Ust-Tsilma, Russia, UTS', key: 'UTS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Ustupu-Ogobsucum, Ustupo, Panama, OGM',
    key: 'OGM',
  },
  { icon: 'faPlaneDeparture', label: 'Útila, Honduras, UII', key: 'UII' },
  {
    icon: 'faPlaneDeparture',
    label: 'Uytash, Makhachkala, Russia, MCX',
    key: 'MCX',
  },
  { icon: 'faPlaneDeparture', label: 'Uyuni, Bolivia, UYU', key: 'UYU' },
  {
    icon: 'faPlaneDeparture',
    label: 'V. C. Bird International, Antigua, Antigua & Barbuda, ANU',
    key: 'ANU',
  },
  { icon: 'faPlaneDeparture', label: 'Vaasa, Finland, VAA', key: 'VAA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Václav Havel Airport Prague, Czechia, PRG',
    key: 'PRG',
  },
  { icon: 'faPlaneDeparture', label: 'Vadodara, India, BDQ', key: 'BDQ' },
  { icon: 'faPlaneDeparture', label: 'Vadsø, Norway, VDS', key: 'VDS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Vágar, Sørvágur, Faroe Islands, FAE',
    key: 'FAE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Val de Cans International, Belém, Brazil, BEL',
    key: 'BEL',
  },
  { icon: 'faPlaneDeparture', label: 'Val-d Or, Canada, YVO', key: 'YVO' },
  { icon: 'faPlaneDeparture', label: 'Valdez, United States, VDZ', key: 'VDZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Valdosta Regional, United States, VLD',
    key: 'VLD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Valença, Valença, Bahia, Brazil, VAL',
    key: 'VAL',
  },
  { icon: 'faPlaneDeparture', label: 'Valencia, Spain, VLC', key: 'VLC' },
  { icon: 'faPlaneDeparture', label: 'Valesdir, Vanuatu, VLS', key: 'VLS' },
  { icon: 'faPlaneDeparture', label: 'Valladolid, Spain, VLL', key: 'VLL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Valley International, Harlingen, United States, HRL',
    key: 'HRL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Van Ferit Melen, Turkey, VAN',
    key: 'VAN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vance W. Amory International, Nevis, St. Kitts & Nevis, NEV',
    key: 'NEV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vancouver Harbour Flight Centre, Canada, CXH',
    key: 'CXH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vancouver International, Canada, YVR',
    key: 'YVR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vanimo, Papua New Guinea, VAI',
    key: 'VAI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vanua Lava, Sola, Vanuatu, SLH',
    key: 'SLH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vanuabalavu, Vanua Balavu, Fiji, VBV',
    key: 'VBV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vardø Airport, Svartnes, Norway, VAW',
    key: 'VAW',
  },
  { icon: 'faPlaneDeparture', label: 'Varna, Bulgaria, VAR', key: 'VAR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Vavaʻu International, Tonga, VAV',
    key: 'VAV',
  },
  { icon: 'faPlaneDeparture', label: 'Växjö Småland, Sweden, VXO', key: 'VXO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Veer Savarkar International, Port Blair, India, IXZ',
    key: 'IXZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Velana International, Malé, Maldives, MLE',
    key: 'MLE',
  },
  { icon: 'faPlaneDeparture', label: 'Veliky Ustyug, Russia, VUS', key: 'VUS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Venetie, United States, VEE',
    key: 'VEE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Venice Marco Polo, Italy, VCE',
    key: 'VCE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Veracruz International, Mexico, VER',
    key: 'VER',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Verkhnevilyuysk, Russia, VHV',
    key: 'VHV',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vernal Regional, United States, VEL',
    key: 'VEL',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vero Beach Regional, United States, VRB',
    key: 'VRB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Verona Villafranca, Italy, VRN',
    key: 'VRN',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Vicecomodoro Ángel de la Paz Aragonés, Santiago del Estero, Argentina, SDE',
    key: 'SDE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Victoria Falls International, Zimbabwe, VFA',
    key: 'VFA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Victoria Inner Harbour SPB, Canada, YWH',
    key: 'YWH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Victoria International, Canada, YYJ',
    key: 'YYJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Victoria Regional, United States, VCT',
    key: 'VCT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vienna International, Austria, VIE',
    key: 'VIE',
  },
  { icon: 'faPlaneDeparture', label: 'Vigo–Peinador, Spain, VGO', key: 'VGO' },
  { icon: 'faPlaneDeparture', label: 'Vijayawada, India, VGA', key: 'VGA' },
  { icon: 'faPlaneDeparture', label: 'Vilankulo, Mozambique, VNX', key: 'VNX' },
  { icon: 'faPlaneDeparture', label: 'Vilhelmina, Sweden, VHM', key: 'VHM' },
  { icon: 'faPlaneDeparture', label: 'Vilhena, Brazil, BVH', key: 'BVH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Villa Garzón, Villagarzón, Colombia, VGZ',
    key: 'VGZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Villa International, Maamigili, Maldives, VAM',
    key: 'VAM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Villahermosa International, Mexico, VSA',
    key: 'VSA',
  },
  { icon: 'faPlaneDeparture', label: 'Vilnius, Lithuania, VNO', key: 'VNO' },
  {
    icon: 'faPlaneDeparture',
    label: 'Vilo Acuña, Cayo Largo del Sur, Cuba, CYO',
    key: 'CYO',
  },
  { icon: 'faPlaneDeparture', label: 'Vilyuysk, Russia, VYI', key: 'VYI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Vincenzo Florio Airport Trapani–Birgi, Italy, TPS',
    key: 'TPS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vinh International, Vietnam, VII',
    key: 'VII',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Virac, Virac, Catanduanes, Philippines, VRC',
    key: 'VRC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Viracopos International, São Paulo, Brazil, VCP',
    key: 'VCP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Virgin Gorda, British Virgin Islands, VIJ',
    key: 'VIJ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Virginia Airport, Durban, South Africa, VIR',
    key: 'VIR',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Viru Viru International, Santa Cruz de la Sierra, Bolivia, VVI',
    key: 'VVI',
  },
  { icon: 'faPlaneDeparture', label: 'Visakhapatnam, India, VTZ', key: 'VTZ' },
  { icon: 'faPlaneDeparture', label: 'Visby, Sweden, VBY', key: 'VBY' },
  {
    icon: 'faPlaneDeparture',
    label: 'Vitebsk Vostochny, Belarus, VTB',
    key: 'VTB',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vitoria, Vitoria-Gasteiz, Spain, VIT',
    key: 'VIT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vitória da Conquista, Brazil, VDC',
    key: 'VDC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vladivostok International, Russia, VVO',
    key: 'VVO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vnukovo International, Moscow, Russia, VKO',
    key: 'VKO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Volgograd International, Russia, VOG',
    key: 'VOG',
  },
  { icon: 'faPlaneDeparture', label: 'Vorkuta, Russia, VKT', key: 'VKT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Voronezh International, Russia, VOZ',
    key: 'VOZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Vunisea, Kadavu Island, Fiji, KDV',
    key: 'KDV',
  },
  { icon: 'faPlaneDeparture', label: 'Wabush, Canada, YWK', key: 'YWK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Waco Regional, United States, ACT',
    key: 'ACT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wadi al-Dawasir Domestic, Wadi ad-Dawasir, Saudi Arabia, WAE',
    key: 'WAE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wagga Wagga, Australia, WGA',
    key: 'WGA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Waimea-Kohala, United States, MUE',
    key: 'MUE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wainwright, United States, AIN',
    key: 'AIN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Waisai Marinda, Indonesia, RJM',
    key: 'RJM',
  },
  { icon: 'faPlaneDeparture', label: 'Wajir, Kenya, WJR', key: 'WJR' },
  { icon: 'faPlaneDeparture', label: 'Wakkanai, Japan, WKJ', key: 'WKJ' },
  { icon: 'faPlaneDeparture', label: 'Walaha, Vanuatu, WLH', key: 'WLH' },
  { icon: 'faPlaneDeparture', label: 'Wales, United States, WAA', key: 'WAA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Walla Walla Regional, United States, ALW',
    key: 'ALW',
  },
  { icon: 'faPlaneDeparture', label: 'Walvis Bay, Namibia, WVB', key: 'WVB' },
  { icon: 'faPlaneDeparture', label: 'Wamena, Indonesia, WMX', key: 'WMX' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wanzhou Wuqiao, Wanzhou District, China, WXN',
    key: 'WXN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wapenamanda, Wapenamanda District, Papua New Guinea, WBM',
    key: 'WBM',
  },
  { icon: 'faPlaneDeparture', label: 'Warri, Nigeria, QRW', key: 'QRW' },
  {
    icon: 'faPlaneDeparture',
    label: 'Warrnambool, Australia, WMB',
    key: 'WMB',
  },
  { icon: 'faPlaneDeparture', label: 'Warsaw Chopin, Poland, WAW', key: 'WAW' },
  { icon: 'faPlaneDeparture', label: 'Warsaw Modlin, Poland, WMI', key: 'WMI' },
  {
    icon: 'faPlaneDeparture',
    label:
      'Washington Dulles International, Washington, D.C., United States, IAD',
    key: 'IAD',
  },
  { icon: 'faPlaneDeparture', label: 'Waskaganish, Canada, YKQ', key: 'YKQ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Waterfall Seaplane Base, Waterfall, Alaska, United States, KWF',
    key: 'KWF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Waterford Airport, Ireland, WAT',
    key: 'WAT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Waterloo Regional, United States, ALO',
    key: 'ALO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Watertown International, United States, ART',
    key: 'ART',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Watertown Regional, United States, ATY',
    key: 'ATY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wattay International, Vientiane, Laos, VTE',
    key: 'VTE',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Waynesville-St. Robert Regional, Fort Leonard Wood, United States, TBN',
    key: 'TBN',
  },
  { icon: 'faPlaneDeparture', label: 'Weerawila, Sri Lanka, WRZ', key: 'WRZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Weeze, Düsseldorf, Germany, NRN',
    key: 'NRN',
  },
  { icon: 'faPlaneDeparture', label: 'Weifang, China, WEF', key: 'WEF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Weihai Dashuibo, China, WEH',
    key: 'WEH',
  },
  { icon: 'faPlaneDeparture', label: 'Weipa, Australia, WEI', key: 'WEI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wellington International, New Zealand, WLG',
    key: 'WLG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wemindji, Wemindji, Quebec, Canada, YNC',
    key: 'YNC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wenshan Puzhehei, Wenshan City, China, WNH',
    key: 'WNH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wenzhou Longwan International, China, WNZ',
    key: 'WNZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'West Kootenay Regional, Castlegar, Canada, YCG',
    key: 'YCG',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Westchester County, Westchester County, New York, United States, HPN',
    key: 'HPN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Western Nebraska Regional, Scottsbluff, United States, BFF',
    key: 'BFF',
  },
  { icon: 'faPlaneDeparture', label: 'Westport, New Zealand, WSZ', key: 'WSZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Westray, United Kingdom, WRY',
    key: 'WRY',
  },
  {
    icon: 'faPlaneDeparture',
    label:
      'Westsound/WSX Seaplane Base, West Sound, Washington, United States, WSX',
    key: 'WSX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wewak, Papua New Guinea, WWK',
    key: 'WWK',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Whale Cove, Whale Cove, Nunavut, Canada, YXN',
    key: 'YXN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Whanganui Airport, New Zealand, WAG',
    key: 'WAG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Whangarei, New Zealand, WRE',
    key: 'WRE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'White Mountain, United States, WMO',
    key: 'WMO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Whitegrass, Tanna, Vanuatu, TAH',
    key: 'TAH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Whitsunday Coast, Proserpine, Australia, PPP',
    key: 'PPP',
  },
  { icon: 'faPlaneDeparture', label: 'Whyalla, Australia, WYA', key: 'WYA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wichita Dwight D. Eisenhower National, United States, ICT',
    key: 'ICT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wichita Falls Municipal, United States, SPS',
    key: 'SPS',
  },
  { icon: 'faPlaneDeparture', label: 'Wick, United Kingdom, WIC', key: 'WIC' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wiley Post–Will Rogers Memorial, Barrow, United States, BRW',
    key: 'BRW',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wilkes-Barre/Scranton International, United States, AVP',
    key: 'AVP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Will Rogers World, Oklahoma City, United States, OKC',
    key: 'OKC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'William P. Hobby, Houston, United States, HOU',
    key: 'HOU',
  },
  { icon: 'faPlaneDeparture', label: 'Williams Lake, Canada, YWL', key: 'YWL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Williamson County Regional, Marion, United States, MWA',
    key: 'MWA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Williamsport Regional, United States, IPT',
    key: 'IPT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Willow Run Airport, Detroit, United States, YIP',
    key: 'YIP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wilmington International, United States, ILM',
    key: 'ILM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wilson, Nairobi, Kenya, WIL',
    key: 'WIL',
  },
  { icon: 'faPlaneDeparture', label: 'Windorah, Australia, WNR', key: 'WNR' },
  {
    icon: 'faPlaneDeparture',
    label: 'Windsor International, Canada, YQG',
    key: 'YQG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wings Field, Philadelphia, United States, BBX',
    key: 'BBX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Winnipeg James Armstrong Richardson International, Canada, YWG',
    key: 'YWG',
  },
  { icon: 'faPlaneDeparture', label: 'Winton, Australia, WIN', key: 'WIN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wonderboom, Pretoria, South Africa, PRY',
    key: 'PRY',
  },
  { icon: 'faPlaneDeparture', label: 'Wonju, South Korea, WJU', key: 'WJU' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wonopito, Lewoleba, Indonesia, LWE',
    key: 'LWE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Woodbourne, Blenheim, New Zealand, BHE',
    key: 'BHE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Worcester Regional, United States, ORH',
    key: 'ORH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wrangell, United States, WRG',
    key: 'WRG',
  },
  { icon: 'faPlaneDeparture', label: 'Wuhai, China, WUA', key: 'WUA' },
  {
    icon: 'faPlaneDeparture',
    label: 'Wuhan Tianhe International, China, WUH',
    key: 'WUH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Wuyishan, Wuyishan, Fujian, China, WUS',
    key: 'WUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xi an Xianyang International, China, XIY',
    key: 'XIY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xiamen Gaoqi International, China, XMN',
    key: 'XMN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xiangyang Liuji, China, XFN',
    key: 'XFN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xichang Qingshan, China, XIC',
    key: 'XIC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xieng Khouang, Phonsavan, Laos, XKH',
    key: 'XKH',
  },
  { icon: 'faPlaneDeparture', label: 'Xilinhot, China, XIL', key: 'XIL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Xingyi Wanfenglin, Xingyi, Guizhou, China, ACX',
    key: 'ACX',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xining Caojiabao International, China, XNN',
    key: 'XNN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xinyuan Nalati, Xinyuan County, China, NLT',
    key: 'NLT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xinzhou Wutaishan, China, WUT',
    key: 'WUT',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xishuangbanna Gasa, Jinghong, China, JHG',
    key: 'JHG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Xuzhou Guanyin International, China, XUZ',
    key: 'XUZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yacuiba, Yacuíba, Bolivia, BYC',
    key: 'BYC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yakima Air Terminal, United States, YKM',
    key: 'YKM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yakubu Gowon, Jos, Nigeria, JOS',
    key: 'JOS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yakutat, United States, YAK',
    key: 'YAK',
  },
  { icon: 'faPlaneDeparture', label: 'Yakutsk, Russia, YKS', key: 'YKS' },
  { icon: 'faPlaneDeparture', label: 'Yamagata, Japan, GAJ', key: 'GAJ' },
  { icon: 'faPlaneDeparture', label: 'Yamaguchi Ube, Japan, UBJ', key: 'UBJ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yampa Valley, Hayden, United States, HDN',
    key: 'HDN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yan an Ershilipu, China, ENY',
    key: 'ENY',
  },
  { icon: 'faPlaneDeparture', label: 'Yanbu, Saudi Arabia, YNB', key: 'YNB' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yancheng Nanyang International, China, YNZ',
    key: 'YNZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yangon International, Myanmar (Burma), RGN',
    key: 'RGN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yangyang International, Yangyang County, South Korea, YNY',
    key: 'YNY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yangzhou Taizhou International, China, YTY',
    key: 'YTY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yanji Chaoyangchuan International, China, YNJ',
    key: 'YNJ',
  },
  { icon: 'faPlaneDeparture', label: 'Yantai Laishan, China, YNT', key: 'YNT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yaoundé Nsimalen International, Cameroon, NSI',
    key: 'NSI',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yap International, Micronesia, YAP',
    key: 'YAP',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yariguíes, Barrancabermeja, Colombia, EJA',
    key: 'EJA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yeager, Charleston, United States, CRW',
    key: 'CRW',
  },
  { icon: 'faPlaneDeparture', label: 'Yellowknife, Canada, YZF', key: 'YZF' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yellowstone, West Yellowstone, United States, WYS',
    key: 'WYS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yellowstone Regional, Cody, United States, COD',
    key: 'COD',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yemelyanovo International, Krasnoyarsk, Russia, KJA',
    key: 'KJA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yenişehir, Bursa, Turkey, YEI',
    key: 'YEI',
  },
  { icon: 'faPlaneDeparture', label: 'Yeosu, South Korea, RSU', key: 'RSU' },
  { icon: 'faPlaneDeparture', label: 'Yibin Caiba, China, YBP', key: 'YBP' },
  { icon: 'faPlaneDeparture', label: 'Yichang Sanxia, China, YIH', key: 'YIH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yichun Lindu, Yichun, Heilongjiang, China, LDS',
    key: 'LDS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yichun Mingyueshan, Yichun, Jiangxi, China, YIC',
    key: 'YIC',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yinchuan Hedong International, China, INC',
    key: 'INC',
  },
  { icon: 'faPlaneDeparture', label: 'Yingkou Lanqi, China, YKH', key: 'YKH' },
  { icon: 'faPlaneDeparture', label: 'Yining, Ghulja, China, YIN', key: 'YIN' },
  { icon: 'faPlaneDeparture', label: 'Yiwu, China, YIW', key: 'YIW' },
  { icon: 'faPlaneDeparture', label: 'Yola, Nigeria, YOL', key: 'YOL' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yongzhou Lingling, China, LLF',
    key: 'LLF',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'York Landing, York Factory First Nation, Canada, ZAC',
    key: 'ZAC',
  },
  { icon: 'faPlaneDeparture', label: 'Yoshkar-Ola, Russia, JOK', key: 'JOK' },
  {
    icon: 'faPlaneDeparture',
    label: 'Youngstown–Warren Regional, United States, YNG',
    key: 'YNG',
  },
  { icon: 'faPlaneDeparture', label: 'Yulin Yuyang, China, UYN', key: 'UYN' },
  {
    icon: 'faPlaneDeparture',
    label: 'Yuma International, United States, YUM',
    key: 'YUM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yuncheng Guangong, China, YCU',
    key: 'YCU',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yushu Batang, Yushu City, Qinghai, China, YUS',
    key: 'YUS',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yuzhno-Kurilsk Mendeleyevo, Unknown Region, DEE',
    key: 'DEE',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Yuzhno-Sakhalinsk, Russia, UUS',
    key: 'UUS',
  },
  { icon: 'faPlaneDeparture', label: 'Zabol, Iran, ACZ', key: 'ACZ' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zacatecas International, Mexico, ZCL',
    key: 'ZCL',
  },
  { icon: 'faPlaneDeparture', label: 'Zadar, Croatia, ZAD', key: 'ZAD' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zafer, Kütahya, Turkey, KZR',
    key: 'KZR',
  },
  { icon: 'faPlaneDeparture', label: 'Zagora, Morocco, OZG', key: 'OZG' },
  { icon: 'faPlaneDeparture', label: 'Zagreb, Croatia, ZAG', key: 'ZAG' },
  { icon: 'faPlaneDeparture', label: 'Zahedan, Iran, ZAH', key: 'ZAH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zakynthos International, Zakynthos Island, Greece, ZTH',
    key: 'ZTH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zamboanga International, Zamboanga City, Philippines, ZAM',
    key: 'ZAM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zaporizhia International, Ukraine, OZH',
    key: 'OZH',
  },
  { icon: 'faPlaneDeparture', label: 'Zaragoza, Spain, ZAZ', key: 'ZAZ' },
  { icon: 'faPlaneDeparture', label: 'Zaranj, Afghanistan, ZAJ', key: 'ZAJ' },
  { icon: 'faPlaneDeparture', label: 'Zaysan, Kazakhstan, SZI', key: 'SZI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zenata – Messali El Hadj, Tlemcen, Algeria, TLM',
    key: 'TLM',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhangjiajie Hehua International, China, DYG',
    key: 'DYG',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhangjiakou Ningyuan, China, ZQZ',
    key: 'ZQZ',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhangye Ganzhou, China, YZY',
    key: 'YZY',
  },
  { icon: 'faPlaneDeparture', label: 'Zhanjiang, China, ZHA', key: 'ZHA' },
  { icon: 'faPlaneDeparture', label: 'Zhaotong, China, ZAT', key: 'ZAT' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhengzhou Xinzheng International, China, CGO',
    key: 'CGO',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhezkazgan, Jezkazgan, Kazakhstan, DZN',
    key: 'DZN',
  },
  { icon: 'faPlaneDeparture', label: 'Zhigansk, Russia, ZIX', key: 'ZIX' },
  { icon: 'faPlaneDeparture', label: 'Zhob, Pakistan, PZH', key: 'PZH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhongwei Shapotou, China, ZHY',
    key: 'ZHY',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhoushan Putuoshan, China, HSN',
    key: 'HSN',
  },
  { icon: 'faPlaneDeparture', label: 'Zhuhai Jinwan, China, ZUH', key: 'ZUH' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zhukovsky International, Moscow, Russia, ZIA',
    key: 'ZIA',
  },
  { icon: 'faPlaneDeparture', label: 'Zielona Góra, Poland, IEG', key: 'IEG' },
  { icon: 'faPlaneDeparture', label: 'Ziguinchor, Senegal, ZIG', key: 'ZIG' },
  { icon: 'faPlaneDeparture', label: 'Zinder, Niger, ZND', key: 'ZND' },
  { icon: 'faPlaneDeparture', label: 'Zintan, Libya, ZIS', key: 'ZIS' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zona da Mata Regional, Juiz de Fora, Brazil, IZA',
    key: 'IZA',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zumbi dos Palmares International, Maceió, Brazil, MCZ',
    key: 'MCZ',
  },
  { icon: 'faPlaneDeparture', label: 'Zunyi Xinzhou, China, ZYI', key: 'ZYI' },
  {
    icon: 'faPlaneDeparture',
    label: 'Zürich Airport, Switzerland, ZRH',
    key: 'ZRH',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zvartnots International, Yerevan, Armenia, EVN',
    key: 'EVN',
  },
  {
    icon: 'faPlaneDeparture',
    label: 'Zyryanka West, Zyryanka, Sakha Republic, Russia, ZKP',
    key: 'ZKP',
  },
];
export { listAirports };
