import React, { useContext, useState } from 'react';
import AppContext from 'Context/AppContext';
import { PackagesContext } from 'Context/PackagesContext';

import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { PassengersPopup } from './PassengersPopup';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

const DownIcon = ({ isVisible }) =>
  isVisible && <Icon type="down" className="packagesIcon" />;

export const PassengersDropDown = ({ travelCategory }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const app_context = useContext(AppContext);
  const packages_context = useContext(PackagesContext);
  const context =
    travelCategory === 'Packages' ? packages_context : app_context;
  const {
    lastSearch: { noAdults, noInfants, noChildren },
  } = context;

  let passenger_amount = noAdults + noInfants + noChildren;
  const passenger_amount_string = `${passenger_amount} Passenger${
    passenger_amount === 1 ? '' : 's'
  }`;

  const PassengersDropDownWrapper =
    travelCategory === 'Packages'
      ? PassengersDropDownWrapperPackages
      : PassengersDropDownWrapperFlights;

  return (
    <>
      <PassengersDropDownWrapper
        onClick={() => setIsPopupVisible(!isPopupVisible)}
      >
        <FontAwesomeIcon icon={faUser} className="faIcon" />
        <div className="passengerText">&nbsp; {passenger_amount_string}</div>
        <DownIcon isVisible={travelCategory === 'Packages'} />
      </PassengersDropDownWrapper>
      <PassengersPopup
        isVisible={isPopupVisible}
        handleClose={() => setIsPopupVisible(false)}
        isFlights={travelCategory !== 'Packages'}
      />
    </>
  );
};

PassengersDropDown.propTypes = {
  travelCategory: PropTypes.string.isRequired,
};

const PassengersDropDownWrapperFlights = styled.div`
  margin-top: -7px;
  background-color: #efefef;
  padding: 8px 16px;
  font-size: 12px;
  color: black;
  border-radius: 3px;
  display: inline-block;

  position: absolute;
  right: 20px;

  .passengerText {
    font-weight: bold;
    display: inline-block;
    margin-right: 7px;
  }

  .faIcon {
    color: black;
  }
`;

const PassengersDropDownWrapperPackages = styled.div`
  margin-top: 0px;
  background-color: white;
  padding: 6px 2px;
  font-size: 12px;
  color: black;
  border-radius: 4px;
  display: block;
  border: 1px solid #d9d9d9;
  position: relative;

  .passengerText {
    font-weight: 300;
    display: inline-block;
    margin-right: 7px;
    color: rgba(0, 0, 0, 0.67);
    font-size: 16px;
    font-family: 'Monospaced Number', 'Chinese Quote', -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'PingFang SC', 'Hiragino Sans GB',
      'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .faIcon {
    display: none;
  }

  .packagesIcon {
    right: 0px;
    position: absolute;
    margin-top: 7px;
    margin-right: 11px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
`;
