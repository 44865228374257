import React from 'react';
import styled from 'react-emotion/macro';

const AirlineRowWrapper = styled.div`
  @media (max-width: 1000px) {
    :nth-child(3) {
      display: none;
    }
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 2fr 1fr;

    img {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      width: 35px !important;
      border-radius: 5px;
    }

    .airlineInfo {
      padding-bottom: 20px;
      grid-column: 1/3;
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
    }

    .airlineName {
      font-size: 12px;
      font-weight: 300;
      color: #9b9b9b;
    }

    .seatsLeft {
      text-align: right;
      font-size: 12px;
      font-weight: 500;
      color: #ca4152;
      padding-top: 4px;
    }
  }

  img {
    width: 30px;
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    /* grid-column: 1/2; */
    grid-row: 1/2;

    .airlineInfo {
      text-align: left;

      img {
        width: 20px;
        vertical-align: middle;
        text-align: right;
        padding-right: 5px;
      }

      .airlineName {
        padding: 0px;
        padding-left: 10px;
        padding-right: 45px;
        font-size: 12px;
        color: #9b9b9b;
        display: inline-block;
      }
    }
    .seatsLeft {
      /* display: none; */
    }

    .legType {
      padding-left: 37px;
      padding-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .legType {
    vertical-align: middle;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobileOnly {
    display: inline-block;
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .desktopOnly {
    display: inline-block;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .shareMobile {
    text-align: right;
    @media (min-width: 1000px) {
      display: none;
    }
  }
`;

// This component displays the airline logo's for the detailled view.
const AirlineRow = ({ legType, airlineName, airlineCodes }) => (
  <AirlineRowWrapper>
    <div className="legType">{legType}</div>

    <div className="airlineInfo">
      <img
        src={`https://images.kiwi.com/airlines/64/${airlineCodes}.png`}
        alt="airlinelogo"
      />
      <span className="airlineName">{airlineName}</span>
    </div>

    <div className="seatsLeft">
      <span>
        <div />
      </span>
    </div>
  </AirlineRowWrapper>
);

export { AirlineRow, AirlineRowWrapper };
