import React from 'react';
import styled from 'react-emotion/macro';
import { AirlineRow } from './AirlineRow';
import { DetailedRoute } from './DetailedRoute';
import { timeDuration } from './../../../Utils/fnplCalc';

//#region
const RouteSectionWrapper = styled.div`
  @media (max-width: 1000px) {
    grid-column: 1/3;
  }
`;
//#endregion

class RouteSection extends React.Component {
  render() {
    return (
      <RouteSectionWrapper>
        <AirlineRow
          airlineCodes={this.props.airlineCodes}
          airlineName={this.props.airlineName}
        />

        <DetailedRoute
          departureTime={this.props.departureTime}
          arrivalTime={this.props.arrivalTime}
          departureAirport={this.props.departureAirport}
          arrivalAirport={this.props.arrivalAirport}
          departureCity={this.props.departureCity}
          arrivalCity={this.props.arrivalCity}
          totalRouteDuration={timeDuration(
            this.props.departureTimeUTC,
            this.props.arrivalTimeUTC
          )}
        />
      </RouteSectionWrapper>
    );
  }
}

export { RouteSection };
