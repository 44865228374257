import React from 'react';
import i18n from 'Utils/i18n';

import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import { fnplCalc } from 'Utils/fnplCalc';
import dayjs from 'dayjs';
import TravelDealsCard from './TravelDealsCard/index.js';

import imgBali from './assets/Bali.png';
import imgCanaryIslands from './assets/CanaryIslands.png';
import imgLosAngeles from './assets/LosAngeles.png';
import imgSantorini from './assets/santorini.jpg';
import imgZakynthos from './assets/zakynthos.jpg';
import imgCuba from './assets/cuba.jpg';
// import imgBali from './assets/bali.jpg';

import Man1 from './assets/man1.jpg';
import Lady1 from './assets/lady1.jpg';

import Man2 from './assets/man2.jpg';
import Lady2 from './assets/lady2.jpg';

import Man3 from './assets/man3.jpg';
import Lady3 from './assets/lady3.jpg';

import Man4 from './assets/man4.jpg';
import Lady4 from './assets/lady4.jpg';

import Man5 from './assets/man5.jpg';
import Lady5 from './assets/lady5.jpg';

import Man6 from './assets/man6.jpg';
import Lady6 from './assets/lady6.jpg';

// All units are vw/vh
const CARD_WIDTH = 75;
const MARGIN_WIDTH = 10;
const CARD_NUM = 6;
const CARD_HEIGHT = 65;

const SlideWrapper = styled('div')`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #f7f7f7;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 1000px) {
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    padding-bottom: 70px;
  }

  /* Temporary solution to hide slider travels deals on higher than 500px so that no gray areas are visible on ipad */
  @media (min-width: 500px) {
    padding-top: 40px;
  }

  /* Don't display travel deals for Ipad*/
  @media (max-width: 1000px) {
    @media (min-width: 700px) {
      display: none;
    }
  }
`;
const Slider = styled('div')`
  width: ${(CARD_WIDTH + MARGIN_WIDTH) * CARD_NUM}vw;
  max-height: ${CARD_HEIGHT}vh;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 1000px) {
    grid-column: 2/4;
    width: 1140px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const departureDate = dayjs()
  .add(150, 'day')
  .format('YYYYMMDD');
const returnDate = dayjs()
  .add(150 + 14, 'day')
  .format('YYYYMMDD');
const startAirport = i18n.t('Discover..C_LON');

// THIS ARRAY CONTAINS PL PRICES FOR THE TRAVEL DEALS.
const travelDealsPL = [
  {
    destination: i18n.t('TravelDealsCardX..Canary Islands'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(878, 12),
    familyName: i18n.t('TravelDealsCardX..Tomson Family'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgCanaryIslands}');
      }
    `,
    imgMan: Man1,
    imgLady: Lady1,
    linkDeal: `/search-flight/C_ACE/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 4 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Los Angeles'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(2044, 12),
    familyName: i18n.t('TravelDealsCardX..Theresa and James'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgLosAngeles}');
      }
    `,
    imgMan: Man2,
    imgLady: Lady2,
    linkDeal: `/search-flight/C_LAX/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 2 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Bali'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(3356, 12),
    familyName: i18n.t('TravelDealsCardX..Lisa and Scott'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgBali}');
      }
    `,
    imgMan: Man3,
    imgLady: Lady3,
    linkDeal: `/search-flight/C_DPS/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 5 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Zanzibar'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(3556, 12),
    familyName: i18n.t('TravelDealsCardX..Sara and George'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgCuba}');
      }
    `,
    imgMan: Man4,
    imgLady: Lady4,
    linkDeal: `/search-flight/C_DAR/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 3 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Zakynthos'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(901, 12),
    familyName: i18n.t('TravelDealsCardX..Teresa and William'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgZakynthos}');
      }
    `,
    imgMan: Man5,
    imgLady: Lady5,
    linkDeal: `/search-flight/C_ZTH/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 3 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Santorini'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(1107, 12),
    familyName: i18n.t('TravelDealsCardX..Maya and Greg'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgSantorini}');
      }
    `,
    imgMan: Man6,
    imgLady: Lady6,
    linkDeal: `/search-flight/C_JTR/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 3 days ago'),
  },
];

// THIS ARRAY CONTAINS UK PRICES FOR THE TRAVEL DEALS.
const travelDealsUK = [
  {
    destination: i18n.t('TravelDealsCardX..Canary Islands'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(233, 12),
    familyName: i18n.t('TravelDealsCardX..Tomson Family'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgCanaryIslands}');
      }
    `,
    imgMan: Man1,
    imgLady: Lady1,
    linkDeal: `/search-flight/C_ACE/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 4 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Los Angeles'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(516, 12),
    familyName: i18n.t('TravelDealsCardX..Theresa and James'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgLosAngeles}');
      }
    `,
    imgMan: Man2,
    imgLady: Lady2,
    linkDeal: `/search-flight/C_LAX/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 2 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Bali'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(1035, 12),
    familyName: i18n.t('TravelDealsCardX..Lisa and Scott'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgBali}');
      }
    `,
    imgMan: Man3,
    imgLady: Lady3,
    linkDeal: `/search-flight/C_DPS/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 5 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Zanzibar'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(910, 12),
    familyName: i18n.t('TravelDealsCardX..Sara and George'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgCuba}');
      }
    `,
    imgMan: Man4,
    imgLady: Lady4,
    linkDeal: `/search-flight/C_DAR/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 3 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Zakynthos'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(332, 12),
    familyName: i18n.t('TravelDealsCardX..Teresa and William'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgZakynthos}');
      }
    `,
    imgMan: Man5,
    imgLady: Lady5,
    linkDeal: `/search-flight/C_ZTH/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 3 days ago'),
  },
  {
    destination: i18n.t('TravelDealsCardX..Santorini'),
    noInstalments: 12,
    noInstalmentsText: i18n.t('TravelDealsCardX..Twelve'),
    costs: fnplCalc(294, 12),
    familyName: i18n.t('TravelDealsCardX..Maya and Greg'),
    dealImageBg: css`
      .destinationImage {
        background-image: linear-gradient(
            rgba(20, 20, 20, 0.2),
            rgba(20, 20, 20, 0.3)
          ),
          url('${imgSantorini}');
      }
    `,
    imgMan: Man6,
    imgLady: Lady6,
    linkDeal: `/search-flight/C_JTR/${startAirport}/${departureDate}/${returnDate}/1/0`,
    daysAgo: i18n.t('TravelDealsCardX..Booked 3 days ago'),
  },
];

// POLISH OR UK PRICES FOR TRAVEL DEALS.
const travelDeals = i18n.language === 'pl' ? travelDealsPL : travelDealsUK;

const TravelDeals = () => {
  return (
    <SlideWrapper>
      <Slider>
        {travelDeals
          // randomize order each time
          .sort(function() {
            return 0.5 - Math.random();
          })
          .map(deal => (
            <TravelDealsCard key={deal.destination} {...deal} />
          ))}
      </Slider>
    </SlideWrapper>
  );
};

export default TravelDeals;
