import React, { useContext, useState, useEffect } from 'react';
import AppContext from 'Context/AppContext';
import { CheckoutFormsUKWrapper } from '../styles';
import CoreView from '../CoreView';
import { Form } from 'antd'; // <----------------- Shouldn't be used and be removed, currently only for the styling of the confirmation page.
import FnplV2Widget from 'components/FnplWidget/index';
import InstructionsFnpl from '../components/InstructionsFnpl';
import { RedirectElement } from 'components/RedirectElement';
import { extractFlightTicketData } from 'Utils/fnplCalc';
import { processBooking, tryProcessBooking } from '../processBooking';
import uuid from 'uuid/v4';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PaymentContainer } from './StripePaymentForm/PaymentContainer';
import { StripeProvider } from 'react-stripe-elements';
import { BOOKING_TYPES } from '../../constants';

const CreditCheckPageContainerProxy = () => {
  const [userIdReferenceState, setUserIdReferenceState] = useState('');
  const [
    redirectToThankYouPageState,
    setRedirectToThankYouPageState,
  ] = useState(false);

  const context = useContext(AppContext);
  const {
    selectedFlightTicket,
    firstCheckoutPageFormValuesContext,
    setPartnerBookingReferenceContext,
    partnerBookingReferenceContext,
    orderSummaryObjectContext,
    orderSummaryContext,
    typeBookingContext,
  } = context;

  useEffect(() => {
    const referenceUUID = uuid();
    console.log('the reference uuid is...', referenceUUID);
    setUserIdReferenceState(referenceUUID);
    const try_callback = () => console.log('the try process booking callback');
    tryProcessBooking(
      selectedFlightTicket,
      firstCheckoutPageFormValuesContext,
      partnerBookingReferenceContext,
      extract,
      try_callback,
      orderSummaryObjectContext,
      typeBookingContext
    );
  }, []);

  const extract = extractFlightTicketData(selectedFlightTicket);

  const orderDataFNPL = {
    ...firstCheckoutPageFormValuesContext,
    tripExtract: extract,
    // flymbleCheckoutPrice: orderSummaryObjectContext.FNPL_Price,
    orderSummaryObject: orderSummaryObjectContext,
  };
  const goThankYouPage = () => setRedirectToThankYouPageState(true);

  const handleFNPLCallBackComplete = dispersalToken => {
    processBooking(
      dispersalToken,
      selectedFlightTicket,
      firstCheckoutPageFormValuesContext,
      partnerBookingReferenceContext,
      extract,
      goThankYouPage,
      orderSummaryObjectContext,
      orderSummaryContext,
      typeBookingContext
    );
  };

  const handleStripePaymentComplete = () => {
    const token_string = 'STRIPE-XXXXXXXXX';
    const type_booking = BOOKING_TYPES.STRIPE_Flights;
    processBooking(
      token_string,
      selectedFlightTicket,
      firstCheckoutPageFormValuesContext,
      partnerBookingReferenceContext,
      extract,
      goThankYouPage,
      orderSummaryObjectContext,
      orderSummaryContext,
      type_booking
    );
  };

  const is_pay_in_full = typeBookingContext === BOOKING_TYPES.STRIPE_Flights;

  const getPaymentCustomerName = () => {
    const { P0_firstname, P0_lastname } = firstCheckoutPageFormValuesContext;
    const answer = `${P0_firstname} ${P0_lastname}`;
    console.log(
      '[getPaymentCustomerName]',
      firstCheckoutPageFormValuesContext,
      answer
    );
    return answer;
  };

  const stripe_customer_name = getPaymentCustomerName();

  return (
    <StripeProvider apiKey="pk_live_tCRgPq6IMsXx2gaATiaW9ByF">
      <CoreView timeOutDelay={0}>
        <CheckoutFormsUKWrapper>
          {is_pay_in_full ? (
            <PaymentContainer
              handleStripePaymentComplete={handleStripePaymentComplete}
              checkoutPrice={orderSummaryObjectContext.FNPL_Price}
              customerName={stripe_customer_name}
            />
          ) : (
            <Form>
              <FnplV2Widget
                orderDataFNPL={orderDataFNPL}
                callbackComplete={handleFNPLCallBackComplete}
                callbackReference={setPartnerBookingReferenceContext}
                referenceUUID={userIdReferenceState}
                flymbleCheckoutPrice={orderSummaryObjectContext.FNPL_Price}
              >
                <InstructionsFnpl isVisible={true} />
              </FnplV2Widget>
            </Form>
          )}
        </CheckoutFormsUKWrapper>
        <RedirectElement
          destination="/thank-you"
          isAllowedRedirect={redirectToThankYouPageState}
        />
      </CoreView>
   </StripeProvider>
  );
};

const CreditCheckPageContainer = () => (
  <ErrorBoundary errorLocation="Pay booking" redirect={true}>
    <CreditCheckPageContainerProxy />
  </ErrorBoundary>
);

export { CreditCheckPageContainer };
export default CreditCheckPageContainer;
