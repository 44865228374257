import React, { useContext } from 'react';
import styled from 'react-emotion/macro';
import { HeroImageSection } from './HeroImageSection';
import { CheckInOutDates } from './CheckInOutDates';
import { ShowOfferDetails } from './ShowOfferDetails';
import { ShowFlightTimes } from './ShowFlightTimes';
import { PackagesContext } from '../../../Context/PackagesContext';
// import { SideCollumnDesktop } from '../../components';

export const PackageInformationCheckout = () => {
  const context = useContext(PackagesContext);
  const {
    selectedPackageContext: selectedPackage,
    amountNightsContext,
    amountRoomsContext,
    departureAirportContext,
    departureDateContext,
    amountPassengersContext,
  } = context;
  const { destination, id, main_image_url, title } = selectedPackage;

  return (
    window.location.pathname.indexOf('packages') !== -1 &&
    window.location.pathname.indexOf('packages-pay-booking') === -1 && (
      <PackageInformationCheckoutWrapper>
        <HeroImageSection
          title={title}
          subTitle={destination}
          imageUrl={main_image_url}
        />
        <ShowOfferDetails
          amountNights={amountNightsContext}
          amountRooms={amountRoomsContext}
          amountPassengers={amountPassengersContext}
          isVisible={false}
        />
        <CheckInOutDates
          daysOfStay={amountNightsContext}
          departureAirport={departureAirportContext}
          packageDepartureDate={departureDateContext}
        />
        <ShowFlightTimes dealId={id} />
      </PackageInformationCheckoutWrapper>
    )
  );
};

const PackageInformationCheckoutWrapper = styled.div`
  color: black !important;
  padding-bottom: 15px;

  @media (min-width: 1000px) {
    /* grid-column: 1/3;
    display: grid;
    grid-template-columns: 28fr 13fr;
    grid-column-gap: 80px; */
  }
`;
