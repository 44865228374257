import React, { useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { dobGrid } from '../PassengerDetailsForm';
import { SectionStyling } from '../styles.js';
import { SectionTitle } from '../../components/SectionTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Radio } from 'semantic-ui-react';
import { trackAddServiceAndProtection } from 'Utils';
import AppContext from 'Context/AppContext';
import { TICKET_SERVICE_PRICE } from '../../../constants';

const servicePhoto = require('./assets/servicePhoto.svg');

export const ServiceAndProtection = () => {
  const context = useContext(AppContext);
  // const [isAddedState, setIsAddedState ] = useState(false);

  const { addTicketServiceContext } = context;

  useEffect(() => {
    addTicketServiceContext(1);
  }, []);

  const handleServiceProtectionToggle = () => {
    trackAddServiceAndProtection();
    addTicketServiceContext();
  };

  return (
    <SectionStyling>
      <ServiceAndProtectionWrapper>
        <SectionTitle title="Ticket service and protection" />
        <img src={servicePhoto} className="serviceImage" alt='bankruptcy-protection'/>
        <div className="keyPoints">
          <div className="lineElement">
            <FontAwesomeIcon icon={faCheck} className="faIcon" />
            Protect your flight against airline bankruptcy.
          </div>
          <div className="lineElement">
            <FontAwesomeIcon icon={faCheck} className="faIcon" />
            <div>
              Receive up to <b>£500</b> for flight delays and cancellations.
              {/* and airline overbooking. */}
            </div>
          </div>
          <div className="lineElement">
            <div></div>
            <b>
              <a
                className="learnMoreLink"
                href="https://s1.travix.com/budgetair/UK/buauk-ftg-en.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </b>
          </div>
          <br />
          <div className="lineElement">
            <div />
            <Radio
              toggle
              label={`Add insurance + £${TICKET_SERVICE_PRICE.toFixed(
                2
              )} per person`}
              onChange={handleServiceProtectionToggle}
            />
          </div>
        </div>
      </ServiceAndProtectionWrapper>
    </SectionStyling>
  );
};

const ServiceAndProtectionWrapper = styled.div`
  ${dobGrid};
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 20px;

  .serviceImage {
    width: 100%;
    min-width: 50px;
    padding: 30px;
    padding-left: 45px;
  }

  .keyPoints {
    margin-left: 5px;
    /* margin-right: 20px; */

    .lineElement {
      margin-bottom: 5px;
      display: grid;
      grid-template-columns: 25px 1fr;
      .faIcon {
        /* font-size: 15px; */
        font-size: 14px;
        /* margin-right: 12px; */
        color: #00c699;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  @media (min-width: 1000px) {
    padding-top: 0px;

    .serviceImage {
      width: auto;
      min-width: 50px;
      padding: 30px;
      padding-left: 35px;
      padding-top: 10px;
      max-height: 300px;
    }
  }
`;

export const SelectionBoxWrapper = styled.div`
  padding: 12px 15px;
  align-items: center;

  .baggageTypeRow {
    display: grid;
    grid-template-columns: 1fr 6fr 3fr;

    .faIcon {
      margin-left: auto;
      margin-right: auto;
      margin-top: 9px;
      margin-right: 12px;
    }
  }

  p {
    margin: 0px;
    font-size: 14px;
    line-height: 22px;
    color: #2e353b;
  }

  .unitPrice {
    text-align: right;
  }

  .noCheckedBaggageElement {
    display: grid;
    grid-template-columns: 1fr 6fr 3fr;

    .faIcon {
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;

// const InsuranceSelectionBox = () => {
//   return (
//     <SelectionBoxWrapper>
//       <div>Circle</div>
//       <div>Add insurance</div>
//       <div>Price</div>
//     </SelectionBoxWrapper>
//   );
// };

// ServiceAndProtection.propTypes = {
//   isAdded: PropTypes.bool.isRequired,
// };
