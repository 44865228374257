import React, { Component } from 'react';
import i18n from 'Utils/i18n.js';
import dayjs from 'dayjs';

// Larger landing page sections.
import {
  Main,
  HowItWorks,
  TravelDeals,
  FeedbackSection,
  FAQsLandingPage,
  PressArticles,
  PopularSearches,
  PressBar,
  SupportSection,
  VideoSection,
  UspDropdowns,
} from './components';

// Small landing page components and styling components.
import {
  Spacer,
  TrustPilotSection,
  MobilePressWrapper,
  MainWrapper,
} from './components';

// constants.
import { howItWorksSections } from './constants';

class LandingPage extends Component {
  state = {
    modalVisible: false,
    modalShowed: null,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
    window.Intercom('trackEvent', 'landing-check', {
      time: dayjs().format(),
    });
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    clearTimeout(this.emailPopup);
  }

  handleScroll = () => {
    let triggerY = document.querySelector('.mainGrid.footer').offsetTop - 800;
    if (window.scrollY > triggerY && !this.state.modalShowed) {
      this.setState({
        modalShowed: Date.now(),
      });
      this.emailPopup = setTimeout(() => {
        this.setState({ modalVisible: true });
      }, 3000);
    }
  };

  handleModalClose = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    return (
      <div>
        <Main className="mainHolder" />
        <TrustPilotSection />
        <UspDropdowns />
        <PressBar title="AS SEEN IN" bgWhite />
        <Spacer height={35} bgWhite mobileOnly />
        <React.Suspense fallback={<div style={{ height: '400px' }} />}>
          <TravelDeals />
          <div className="wrapper">
            <HowItWorks sections={howItWorksSections} />
          </div>
          <PopularSearches />
          <MainWrapper>{false && <VideoSection />}</MainWrapper>
          <FeedbackSection />
          {false && <FAQsLandingPage />}
          <MobilePressWrapper>
            <PressBar title={i18n.t('TrustSection..AS SEEN IN')} />
          </MobilePressWrapper>
          <PressArticles />
          <SupportSection />
        </React.Suspense>
      </div>
    );
  }
}

export default LandingPage;
