import React, { Suspense, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { cx } from 'react-emotion';
import moment from 'moment';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  AutoComplete,
  Button as AntButton,
  DatePicker,
  Form,
  Select,
} from 'antd';
import isEqual from 'lodash/isEqual';
import { Trans } from 'react-i18next';
import i18n from 'Utils/i18n.js';
import { topAirports, topDestinations } from './constants';
import { listAirports } from './../components/AirportInput/airportsNew';
import {
  SearchMobileLPWrapper,
  SearchMobileStyle,
  fullWide,
  FormLabel,
  dateRangeSpan,
  marginTop3x,
  controlHeight,
} from './styles';
import { KIND_OF_TRIP, SEARCH_MOBILE_MODE } from '../../constants';
import { FormIcon } from '../../components/FormIcon/index.js';
import pages from 'pages';
import './datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faPlaneDeparture,
  faPlaneArrival,
  faGlobe,
  faBuilding,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { submitSearchButton as submitSearchButtonFbFunc } from '../../Utils/facebookFunctions';
import AppContext from '../../Context/AppContext';
import { css } from 'emotion/macro';
import { isMobileDevice } from '../../Utils';

import LocationPicker from 'components/LocationPicker';

const FormItem = Form.Item;
const months_ahead = 12;
const SelectOption = Select.Option;
const Option = AutoComplete.Option;

const Button = ({ className, children, isButtonLoading, ...props }) => (
  <AntButton
    className={cx(fullWide, className)}
    {...props}
    loading={isButtonLoading}
  >
    {children}
  </AntButton>
);

class SearchMobileLP extends Component {
  static contextType = AppContext;

  icons = {
    faPlaneDeparture: faPlaneDeparture,
    faBuilding: faBuilding,
    faGlobe: faGlobe,
  };

  constructor(props) {
    super(props);

    this.state = {
      flyingFrom: null,
      flyingTo: null,
      dateFrom: null,
      dateTo: null,
      noAdults: 1,
      noChildren: 0,
      noInfants: 0,
      searchValues: null,
      values: null,
      loaded: false,
      loading: false,
      fromOptions: this.getFromOptions(topAirports),
      toOptions: this.getFromOptions(topDestinations),
      endOpen: false,
      focus: [],
      errors: {},
      typeFlight: KIND_OF_TRIP.ROUND,
    };
    this.r = {};
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.initializeForm();
  };

  initializeForm = () => {
    const { lastSearch } = this.context;
    const {
      form: { setFieldsValue },
    } = this.props;
    let values = {};

    this.setState(
      prevState => {
        values = {
          flyingFrom: lastSearch.flyingFrom,
          flyingTo: lastSearch.flyingTo,
          dateFrom: lastSearch.dateFrom,
          dateTo: lastSearch.dateTo,
          noAdults: lastSearch.noAdults || prevState.noAdults,
          noChildren: lastSearch.noChildren || prevState.noChildren,
          noInfants: lastSearch.noInfants || prevState.noInfants,
          typeFlight: lastSearch.typeFlight || prevState.typeFlight,
        };

        return Object.assign({}, prevState, values);
      },
      () => {
        setFieldsValue(values);
      }
    );
  };

  componentDidUpdate = (prevProps, _prevState) => {
    if (!isEqual(prevProps.lastSearch, this.props.lastSearch)) {
      this.initializeForm();
    }
  };

  handleFocus = (field, hasFocus) => {
    return () => {
      this.setState({
        focus: {
          [field]: hasFocus,
        },
      });
    };
  };

  redirectToSearchResult = ({
    dateFrom,
    dateTo,
    flyingFrom,
    flyingTo,
    noAdults,
    noChildren,
    noInfants,
  }) => {
    const urlDateFormat = 'YYYYMMDD';
    let resultUrl;

    if (dateTo) {
      resultUrl = `/round/${flyingTo}/${flyingFrom}/${dateFrom.format(
        urlDateFormat
      )}/${dateTo.format(
        urlDateFormat
      )}/${noAdults}/${noChildren}/${noInfants}`;
    } else {
      resultUrl = `/oneway/${flyingTo}/${flyingFrom}/${dateFrom.format(
        urlDateFormat
      )}/${dateFrom.format(
        urlDateFormat
      )}/${noAdults}/${noChildren}/${noInfants}`;
    }

    this.props.history.push({
      pathname: pages.querySearchPage + resultUrl,
    });
  };

  getFromOptions = list => {
    console.log('[getFromOptions], is called');

    return list.map(airport => {
      return (
        <Option key={airport.key} label={airport.label} value={airport.key}>
          <FontAwesomeIcon
            icon={this.icons[airport.icon]}
            size="sm"
            fixedWidth
          />{' '}
          {` ${airport.label}`}
        </Option>
      );
    });
  };

  handleFlyingFromOnSearch = value => {
    // console.log('handle flying from airports, is called');
    if (value.length >= 3) {
      const list = listAirports.filter(
        a => a.label.toUpperCase().indexOf(value.toUpperCase()) >= 0
      );
      this.setState({
        fromOptions: this.getFromOptions(list.slice(0, 30)),
      });
    }
  };

  handleFlyingToOnSearch = value => {
    if (value.length >= 3) {
      const list = listAirports.filter(a => {
        return a => a.label.toUpperCase().indexOf(value.toUpperCase()) >= 0;
      });
      this.setState({
        toOptions: this.getFromOptions(list.slice(0, 30)),
      });
    }
  };

  handleRestoreLastSearch = event => {
    event.preventDefault();
    this.props.form.setFieldsValue({ noAdults: 1 });
    this.props.form.setFieldsValue({ noChildren: 0 });
    this.props.form.setFieldsValue({ noInfants: 0 });
    this.props.form.setFieldsValue({
      dateFrom: dayjs()
        .add(14, 'day')
        .toDate(),
    });
    this.props.form.setFieldsValue({
      dateTo: dayjs()
        .add(90 + 14, 'day')
        .toDate(),
    });
    this.props.form.setFieldsValue({ flyingTo: 'C_DBX' });
    this.props.form.setFieldsValue({ flyingFrom: 'C_LON' });
  };

  onDateToChange2 = () => {
    this.setState({ endOpen: false });
  };

  dateFromDisabledDate = d => {
    const minDate = moment();
    const maxDate = moment().add(months_ahead, 'month');

    return d && !d.isBetween(minDate, maxDate, 'day', '[]');
  };

  dateToDisabledDate = d => {
    const minDate = moment();
    const maxDate = moment().add(months_ahead, 'month');
    let { dateFrom: startDate } = this.props.form.getFieldsValue();

    if (startDate) {
      return d && !d.isBetween(startDate, maxDate, 'day', '(]');
    }

    return d && !d.isBetween(minDate, maxDate, 'day', '[]');
  };

  handleDateFromOpenChange = stateOpen => {
    const { dateFrom: me, } = this.props.form.getFieldsValue();
    if (!me) {
      this.props.form.setFieldsValue({
        dateFrom: moment().add(15, 'day'),
      });
    }
    // panel is closing and date is selected and no end date, set end date to next day
    if (!stateOpen) {
      setTimeout(() => {
        this.setState({ endOpen: true });
        const { dateFrom, dateTo } = this.props.form.getFieldsValue();
        if (dateFrom && (!dateTo || dateTo.diff(dateFrom) < 0)) {
          this.props.form.setFieldsValue({
            dateTo: moment(dateFrom).add(2, 'day'),
          });
        }
      }, 400);
    }
  };

  handleDateToOpenChange = endOpen => {
    const { dateTo: me, dateFrom: other } = this.props.form.getFieldsValue();
    if (!me) {
      if (other) {
        this.props.form.setFieldsValue({
          dateTo: moment(other).add(3, 'day'),
        });
      } else {
        this.props.form.setFieldsValue({
          dateTo: moment().add(15, 'day'),
        });
      }
    }
    this.setState({ endOpen });
  };

  dateFromFooterRender = () => 'Book flights at least 7 days in advance';

  dateToFooterRender = () =>
    `Book flights up to ${months_ahead} months in advance`;

  handleNewSearch = e => {
    e.preventDefault();

    const {
      form: { validateFields, getFieldsValue },
    } = this.props;
    const { lastSearch, setLastSearch, refreshLastSearch } = this.context;
    const formValues = getFieldsValue();

    const { flyingFrom, flyingTo, dateFrom, dateTo } = formValues;

    let tempValues = isMobileDevice()
      ? {
          ...formValues,
          ...lastSearch,
        }
      : {
          ...lastSearch,
          ...formValues,
        };

    console.log('Is this a mobile device?', isMobileDevice());

    let defaults = {
      // ...formValues,
      // ...formValues,
      // ...lastSearch,
      ...tempValues,

      flyingFrom: flyingFrom || topAirports[1].key,
      flyingTo: flyingTo || topDestinations[0].key,
      dateFrom: dateFrom || moment().add(60, 'day'),
      dateTo:
        lastSearch.typeFlight === KIND_OF_TRIP.ONE_WAY
          ? null
          : dateTo || moment().add(74, 'day'),
      typeFlight: lastSearch.typeFlight,
    };

    // @todo validation of inputs should be done here
    validateFields((err, fieldValues) => {
      if (!err) {
        // if (fieldValues.noInfants > fieldValues.noAdults) {
        //   this.setState({
        //     errors: {
        //       infants: true,
        //     },
        //   });
        // } else if (
        //   fieldValues.noAdults +
        //     fieldValues.noChildren +
        //     fieldValues.noInfants >
        //   9
        // ) {
        //   this.setState({
        //     errors: {
        //       maxPassengers: true,
        //     },
        //   });
        // } else {
        //   this.setState({
        //     errors: {
        //       infants: false,
        //       maxPassengers: false,
        //     },
        //   });
        // We first set the last search.
        setLastSearch(defaults, refreshLastSearch);
        // Trak FB func
        submitSearchButtonFbFunc();
        // Then redirect.
        this.setState({ loading: true });
        this.timeoutSearchButton = setTimeout(() => {
          this.setState({ loading: false });
          this.redirectToSearchResult(defaults);
        }, 1500);
      }
    });
    // });
  };

  handleInfants = value => {
    if (value) {
      window.dataLayer.push({
        event: 'selectionInfants',
        selectedInfants: value,
      });
    }
  };

  /**
   * Display all the search form
   *
   * @var getFieldDecorator is used to handle input controls, validation, user interaction
   */
  render() {
    const {
      form: { getFieldDecorator, setFieldsValue },
    } = this.props;
    const { endOpen } = this.state;
    const { isOneWayFlight } = this.context;

    return (
      <Form
        id="search-form"
        className="login-form"
        hideRequiredMark={true}
        onSubmit={this.handleNewSearch}
      >
        <div className="searchFormGrid">
          <Suspense fallback={<div />}>
            <div className="flyingFrom">
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Flying from">Flying from</Trans>
              </FormLabel>
              <Suspense fallback={<div />}>
                <FormItem>
                  <FormIcon
                    icon={faMapMarkerAlt}
                    focus={this.state.focus['flyingFrom']}
                  />{' '}
                  {getFieldDecorator('flyingFrom', {
                    rules: [
                      {
                        required: false,
                        message: "Tell us where you're flying from",
                      },
                    ],
                  })(
                    <LocationPicker
                      airportIcon={faPlaneDeparture}
                      setFieldsValue={setFieldsValue}
                      // isOnlyUK={true}
                      isOnlyUK={false}
                    />
                  )}
                </FormItem>
              </Suspense>
            </div>
            <div className="flyingTo">
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Flying to">Flying to</Trans>
              </FormLabel>
              <FormItem>
                <FormIcon
                  icon={faMapMarkerAlt}
                  focus={this.state.focus['flyingTo']}
                />{' '}
                {getFieldDecorator('flyingTo', {
                  rules: [
                    {
                      required: false,
                      message: "Tell us where you're flying to",
                    },
                  ],
                })(
                  <LocationPicker
                    airportIcon={faPlaneArrival}
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </FormItem>
            </div>
          </Suspense>
          <div className="datePickerGridMobile">
            <div>
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Departing">Departing</Trans>
              </FormLabel>
              <FormItem>
                <FormIcon icon={faCalendarAlt} top={-10} />{' '}
                {getFieldDecorator('dateFrom', {
                  rules: [
                    {
                      required: false,
                      message: "Tell us when you're flying there",
                    },
                  ],
                })(
                  <DatePicker
                    showToday={false}
                    disabledDate={this.dateFromDisabledDate}
                    renderExtraFooter={() =>
                      i18n.t(
                        'SearchMobileLP..Book flights at least 14 days in advance'
                      )
                    }
                    tempValue={moment('2018-08-21')}
                    mode="date"
                    onOpenChange={this.handleDateFromOpenChange}
                    allowClear={false}
                    dropdownClassName="date-from-style"
                    placeholder={i18n.t('SearchBar..Departure')}
                    style={{
                      width: '100%',
                    }}
                  />
                )}
              </FormItem>
              <div className={dateRangeSpan} />
            </div>
            <div>
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Returning">Returning</Trans>
              </FormLabel>
              <FormItem>
                <FormIcon icon={faCalendarAlt} top={-10} />{' '}
                {getFieldDecorator('dateTo', {
                  rules: [
                    {
                      required: false, //!isOneWayFlight(this.state.typeFlight),
                      message: "Tell us when you're flying back",
                    },
                  ],
                })(
                  <DatePicker
                    onChange={this.onDateToChange2}
                    showToday={false}
                    disabled={isOneWayFlight()}
                    disabledDate={this.dateToDisabledDate}
                    renderExtraFooter={() =>
                      i18n.t(
                        'SearchMobileLP..Book flights up to 10 months in advance'
                      )
                    }
                    mode="date"
                    open={endOpen}
                    onOpenChange={this.handleDateToOpenChange}
                    allowClear={false}
                    dropdownClassName="date-to-style"
                    placeholder={i18n.t('SearchBar..Return')}
                    style={{
                      width: '100%',
                    }}
                  />
                )}
              </FormItem>
              <div className={dateRangeSpan} />
            </div>
          </div>
          <div className="passengersGrid">
            <div className="adults">
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Adults">Adults</Trans>
              </FormLabel>
              <FormItem
                validateStatus={
                  this.state.errors.maxPassengers ? 'error' : null
                }
                help={i18n.t(
                  'SearchMobileLP..Maximum number of adults and children is nine'
                )}
              >
                <FormIcon
                  icon={faUsers}
                  top={-2}
                  focus={this.state.focus['noAdults']}
                />{' '}
                {getFieldDecorator('noAdults', {
                  rules: [
                    {
                      required: true,
                      message: null,
                    },
                  ],
                  initialValue: 1,
                })(
                  <Select
                    showArrow={true}
                    onFocus={this.handleFocus('noAdults', true)}
                    onBlur={this.handleFocus('noAdults', false)}
                  >
                    {[1, 2, 3, 4, 5, 6, 7].map(opt => (
                      <SelectOption value={opt} key={opt}>
                        <span>
                          {opt > 1
                            ? `${opt} ${i18n.t('SearchBar..adults')}`
                            : `1 ${i18n.t('SearchBar..adult')}`}
                        </span>
                      </SelectOption>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
            <div className="children">
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Children">Children</Trans>
              </FormLabel>
              <FormItem
                validateStatus={
                  this.state.errors.maxPassengers ? 'error' : null
                }
                help={i18n.t(
                  'SearchMobileLP..Maximum number of adults and children is nine'
                )}
              >
                <FormIcon
                  icon={faUsers}
                  top={-2}
                  focus={this.state.focus['noChildren']}
                />{' '}
                {getFieldDecorator('noChildren', {
                  rules: [
                    {
                      required: true,
                      message: 'err4',
                    },
                  ],
                  initialValue: 0,
                })(
                  <Select
                    showArrow={true}
                    onFocus={this.handleFocus('noChildren', true)}
                    onBlur={this.handleFocus('noChildren', false)}
                  >
                    {[0, 1, 2, 3, 4, 5].map(opt => (
                      <SelectOption value={opt} key={opt}>
                        <span>
                          {opt === 0
                            ? `${i18n.t('SearchBar..no children')}`
                            : opt > 1
                            ? `${opt} ${i18n.t('SearchBar..children')}`
                            : `1 ${i18n.t('SearchBar..child')}`}
                        </span>
                      </SelectOption>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
            <div className="infants">
              <FormLabel>
                <Trans i18nKey="SearchMobileLP..Infants">Infants</Trans>
              </FormLabel>
              <FormItem
                validateStatus={
                  this.state.errors.infants || this.state.errors.maxPassengers
                    ? 'error'
                    : null
                }
                help={i18n.t(
                  'SearchMobileLP..One adult for every travelling infant is required by airlines.'
                )}
              >
                <FormIcon
                  icon={faUsers}
                  top={-2}
                  focus={this.state.focus['noInfants']}
                />{' '}
                {getFieldDecorator('noInfants', {
                  rules: [
                    {
                      required: true,
                      message: 'err4',
                    },
                  ],
                  initialValue: 0,
                })(
                  <Select
                    showArrow={true}
                    onFocus={this.handleFocus('noInfants', true)}
                    onBlur={this.handleFocus('noInfants', false)}
                    onChange={this.handleInfants}
                  >
                    {[0, 1, 2, 3, 4].map(opt => (
                      <SelectOption value={opt} key={opt}>
                        <span>
                          {opt === 0
                            ? `${i18n.t('SearchBar..no infants')}`
                            : i18n.t('SearchBar.. {{count}} infants', {
                                count: opt,
                              })}
                        </span>
                      </SelectOption>
                    ))}
                  </Select>
                )}
              </FormItem>
            </div>
            <div className={cx('searchButton', desktopOnly)}>
              <Button
                type="primary"
                className={cx(marginTop3x, controlHeight)}
                isButtonLoading={this.state.loading}
                size="large"
                htmlType="submit"
              >
                <Trans i18nKey="SearchMobileLP..Search flights">
                  &nbsp;Search flights
                </Trans>
              </Button>
            </div>
          </div>
          <div className={cx('searchButton', mobileOnly)}>
            <Button
              type="primary"
              className={cx(marginTop3x, controlHeight)}
              isButtonLoading={this.state.loading}
              size="large"
              htmlType="submit"
            >
              Search flights
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

SearchMobileLP.propTypes = {
  mode: PropTypes.string,
  isOneWayEnabled: PropTypes.bool,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
};

SearchMobileLP.defaultProps = {
  mode: SEARCH_MOBILE_MODE.DARK,
  isOneWayEnabled: false,
};

// this is workaround
// until https://github.com/ReactTraining/react-router/issues/6508 will be released

SearchMobileLP.contextType = AppContext;

const RouteredSearchMobileLP = withRouter(SearchMobileLP);
delete RouteredSearchMobileLP.contextType;
const WrappedSearchMobileLP = Form.create()(RouteredSearchMobileLP);

export default WrappedSearchMobileLP;
export { SearchMobileLPWrapper, SearchMobileStyle };

const mobileOnly = css`
  @media (min-width: 1000px) {
    display: none;
  }
`;

const desktopOnly = css`
  @media (max-width: 1000px) {
    display: none;
  }
`;

// 786 Lines of Code @29 December 2019.
