import React, { useEffect, useContext, useState } from 'react';
import { DetailsWrapper, MapDetailsWrapper } from './styles';
import { TopHeaderDetails } from '../Headers/TopHeaderDetails';
import { PackagesContext } from '../../../../Context/PackagesContext';
import { Placeholder } from 'semantic-ui-react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';

const MapInformationRoute = () => {
  const [selectedPackageState, setSelectedPackageState] = useState(null);
  const context = useContext(PackagesContext);
  const { packagesArray } = context;

  useEffect(() => {
    if (packagesArray) {
      setSelectedPackageState(true);
    }

    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: 'visit-deal-info-page',
    });
  }, [packagesArray]);
  if (selectedPackageState) {
    const selectedPackage = packagesArray.find(
      ({ id }) => id === parseInt(window.location.pathname.split('/').pop())
    );
    console.log(' the selected package is....', selectedPackage);

    let { googleMapsUrl, id } = selectedPackage;

    return <MapDetailsRoute googleMapsUrl={googleMapsUrl} id={id} />;
  } else
    return (
      <Placeholder>
        <Placeholder.Image square />
      </Placeholder>
    );
};

const WrappedDealInformationRoute = () => (
  <ErrorBoundary>
    <MapInformationRoute />
  </ErrorBoundary>
);

export { WrappedDealInformationRoute as MapDetails };

const MapDetailsRoute = ({ googleMapsUrl, id }) => {
  useEffect(() => {
    document.body.style.position = 'fixed';

    window.scrollTo(0, 0);

    return () => {
      document.body.style.position = '';
    };
  }, []);
  return (
    <DetailsWrapper isNoScroll={true}>
      <TopHeaderDetails title="Location" name={id} />
      <MapDetailsWrapper>
        <iframe
          title="google-maps-widget-hotel-location"
          src={googleMapsUrl}
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
        />
        {/* <p>
          Paramount Map New York, 235 W 46th St, New York, NY 10036, United
          States (your first destination)
        </p> */}
      </MapDetailsWrapper>
    </DetailsWrapper>
  );
};
