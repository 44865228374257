const counties = [
  'Aberdeen City',
  'Aberdeenshire',
  'Anglesey',
  'Angus',
  'Antrim',
  'Argyll and Bute',
  'Armagh',
  'Bedfordshire',
  'Breconshire',
  'Buckinghamshire',
  'Caernarvonshire',
  'Cambridgeshire',
  'Cardiganshire',
  'Carmarthenshire',
  'Cheshire',
  'City of Edinburgh',
  'Clackmannanshire',
  'Cleveland',
  'Cornwall',
  'Cumbria',
  'Denbighshire',
  'Derbyshire',
  'Derry and Londonderry',
  'Devon',
  'Dorset',
  'Down',
  'Dumfries and Galloway',
  'Dundee City',
  'Durham',
  'East Ayrshire',
  'East Dunbartonshire',
  'East Lothian',
  'East Renfrewshire',
  'East Sussex',
  'Eilean Siar',
  'Essex',
  'Falkirk',
  'Fermanagh',
  'Fife',
  'Flintshire',
  'Glamorgan',
  'Glasgow City',
  'Gloucestershire',
  'Greater London',
  'Greater Manchester',
  'Hampshire',
  'Hertfordshire',
  'Highland',
  'Inverclyde',
  'Kent',
  'Lancashire',
  'Leicestershire',
  'Lincolnshire',
  'Merionethshire',
  'Merseyside',
  'Midlothian',
  'Monmouthshire',
  'Montgomeryshire',
  'Moray',
  'Norfolk',
  'North Ayrshire',
  'North Lanarkshire',
  'North Yorkshire',
  'Northamptonshire',
  'Northumberland',
  'Nottinghamshire',
  'Orkney Islands',
  'Oxfordshire',
  'Pembrokeshire',
  'Perth and Kinross',
  'Radnorshire',
  'Renfrewshire',
  'Scottish Borders',
  'Shetland Islands',
  'Shropshire',
  'Somerset',
  'South Ayrshire',
  'South Lanarkshire',
  'South Yorkshire',
  'Staffordshire',
  'Stirling',
  'Suffolk',
  'Surrey',
  'Tyne and Wear',
  'Tyrone',
  'Warwickshire',
  'West Berkshire',
  'West Dunbartonshire',
  'West Lothian',
  'West Midlands',
  'West Sussex',
  'West Yorkshire',
  'Wiltshire',
  'Worcestershire',
];

export { counties };
