import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DealLabels } from '../DealLabels';
import { PriceInfo } from '../PriceInfo';
import { DealCardWrapper, DealCardDetailsWrapper } from './styles';
import { Placeholder } from 'semantic-ui-react';
import { RedirectElement } from '../../../../components/RedirectElement';

export const DealCard = ({
  mainImage,
  dealTitle,
  dealSubtitle,
  dealPrice,
  oldDealPrice,
  priceBasisDescription,
  monthlyPriceAllPassengers,
  name,
  saveSelectedPackage,
  dealObject,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAllowedRedirectState, setIsAllowedRedirectState] = useState(false);

  const handleClickPackage = () => {
    saveSelectedPackage(dealObject);
    setIsAllowedRedirectState(!isAllowedRedirectState);
  };

  return (
    <div onClick={() => handleClickPackage()}>
      <RedirectElement
        destination={`/packages-deal-summary/${name}`}
        timeOutDuration={100}
        isAllowedRedirect={isAllowedRedirectState}
      />
      <DealCardWrapper>
        {!isLoaded && (
          <Placeholder>
            <Placeholder.Image square />
          </Placeholder>
        )}

        <DealLabels
          isAbsolute={true}
          isVisible={isLoaded}
          dealPrice={dealPrice}
          oldDealPrice={oldDealPrice}
        />
        <img
          src={mainImage}
          alt="/"
          onLoad={() => setIsLoaded(true)}
          style={isLoaded ? {} : { display: 'none' }}
        />
        <DealCardDetails
          isVisible={isLoaded}
          dealTitle={dealTitle}
          dealSubtitle={dealSubtitle}
          dealPrice={dealPrice}
          oldDealPrice={oldDealPrice}
          monthlyPriceAllPassengers={monthlyPriceAllPassengers}
          priceBasisDescription={priceBasisDescription}
        />
      </DealCardWrapper>
    </div>
  );
};

DealCard.propTypes = {
  mainImage: PropTypes.string.isRequired,
  dealTitle: PropTypes.string.isRequired,
  dealSubtitle: PropTypes.string.isRequired,
  dealPrice: PropTypes.number.isRequired,
  oldDealPrice: PropTypes.number.isRequired,
  monthlyPriceAllPassengers: PropTypes.number.isRequired,
  priceBasisDescription: PropTypes.string.isRequired,
  saveSelectedPackage: PropTypes.func.isRequired,
  dealObject: PropTypes.object.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const DealCardDetails = ({
  dealTitle,
  dealSubtitle,
  dealPrice,
  oldDealPrice,
  monthlyPriceAllPassengers,
  priceBasisDescription,
  isVisible = true,
}) => {
  return (
    isVisible && (
      <DealCardDetailsWrapper>
        <h3 className="detailsTitle">{dealTitle}</h3>
        <p className="detailsSubTitle">{dealSubtitle}</p>
        <PriceInfo
          dealPrice={dealPrice}
          oldDealPrice={oldDealPrice}
          monthlyPriceAllPassengers={monthlyPriceAllPassengers}
          priceBasisDescription={priceBasisDescription}
        />
      </DealCardDetailsWrapper>
    )
  );
};

DealCardDetails.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  dealTitle: PropTypes.string.isRequired,
  dealSubtitle: PropTypes.string.isRequired,
  dealPrice: PropTypes.number.isRequired,
  oldDealPrice: PropTypes.number.isRequired,
  monthlyPriceAllPassengers: PropTypes.number.isRequired,
  priceBasisDescription: PropTypes.string.isRequired,
};
