import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import i18n from 'Utils/i18n';
import { Trans } from 'react-i18next';
import { cx } from 'emotion/macro';
import { Form, Select, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { WarningBannerWrapper } from '../../components/WarningBanner';
import FormLabel from '../../components/FormLabel';
import { SectionStyling, iconSpacing } from '../styles.js';
import { Popup } from 'semantic-ui-react';

const Option = Select.Option;
const formInputSize = 'large';
const FormItem = Form.Item;

const EmploymentStatusWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  .residentialGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  @media (min-width: 1000px) {
    .coreForms {
      grid-template-columns: 2fr 5fr 2fr;
    }
  }
`;

const EmploymentStatus = ({ getFieldDecorator, isEmploymentHidden }) =>
  !isEmploymentHidden && (
    <SectionStyling>
      <EmploymentStatusWrapper>
        <h2>
          <FontAwesomeIcon
            icon={faBriefcase}
            className={cx('faIcon', iconSpacing)}
          />
          Employment status - Person applying
          {/* for credit */}
        </h2>
        <p parent="span" className="description mobileOnly">
          Our credit check measures the accuracy of the information provided.
          Please make sure the information is <b>up to date and accurate.</b>
        </p>

        <WarningBannerWrapper className="desktopOnly">
          <Trans parent="span" className="description">
            Our credit check measures the accuracy of the information provided.
            Please make sure the information is <b>up to date and accurate.</b>
          </Trans>
        </WarningBannerWrapper>

        <FormLabel>
          {i18n.t('EmploymentStatus..label..Annual salary in GBP')}{' '}
          <Popup
            content="We only check if your annual salary is up to date and accurate. Not if the amount is high enough. For questions, please reach out via web chat to one of our support agents."
            trigger={
              <i
                style={{ marginLeft: '0px' }}
                className="question circle icon"
              />
            }
          />
        </FormLabel>
        <FormItem>
          {getFieldDecorator('salary', {
            rules: [
              {
                required: true,
                message: i18n.t(
                  'EmploymentStatus..Enter your annual gross salary in GBP.'
                ),
                pattern: /£?(?:\d{1,3})?(?:\d{3},)*\d{1,3}(?:\. ?\d+)*$/,
              },
            ],
          })(
            <Input
              placeholder={i18n.t('EmploymentStatus..placeholder..e.g. 20.000')}
              size={formInputSize}
              style={{ width: '100%' }}
            />
          )}
        </FormItem>

        <div className="residentialGrid">
          <div>
            <FormLabel>
              {i18n.t('EmploymentStatus..label..Residential status')}
            </FormLabel>
            <FormItem>
              {getFieldDecorator('residentialstatus', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'EmploymentStatus..validator..Enter your current residential status.'
                    ),
                  },
                ],
              })(
                <Select
                  size={formInputSize}
                  placeholder={i18n.t(
                    'EmploymentStatus..label..Residential status'
                  )}
                  style={{ width: '100%' }}
                >
                  <Option value="Owner">
                    {i18n.t('EmploymentStatus..label..Owner')}
                  </Option>
                  <Option value="Living with parents">
                    {i18n.t('EmploymentStatus..label..Living with parents')}
                  </Option>
                  <Option value="Tenant furnished">
                    {i18n.t('EmploymentStatus..label..Tenant furnished')}
                  </Option>
                  <Option value="Tenant unfurnished">
                    {i18n.t('EmploymentStatus..label..Tenant unfurnished')}
                  </Option>
                  <Option value="Other tenant">
                    {i18n.t('EmploymentStatus..label..Other tenant')}
                  </Option>
                  <Option value="House share">
                    {i18n.t('EmploymentStatus..label..House share')}
                  </Option>
                </Select>
              )}
            </FormItem>
          </div>
          <div>
            <FormLabel>
              {i18n.t('EmploymentStatus..label..Employment status')}
            </FormLabel>
            <FormItem>
              {getFieldDecorator('employment', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'EmploymentStatus..validator..Please enter your current employment status to continue.'
                    ),
                  },
                ],
              })(
                <Select
                  size={formInputSize}
                  placeholder={i18n.t(
                    'EmploymentStatus..placeholder..Employment status'
                  )}
                  style={{ width: '100%' }}
                >
                  <Option value="Full time employed">
                    {i18n.t('EmploymentStatus..label..Full time employed')}
                  </Option>
                  <Option value="Part time employed">
                    {i18n.t('EmploymentStatus..label..Part time employed')}
                  </Option>
                  <Option value="Self employed">
                    {i18n.t('EmploymentStatus..label..Self employed')}
                  </Option>
                  <Option value="Not employed">
                    {i18n.t('EmploymentStatus..label..Not employed')}
                  </Option>
                  <Option value="Student">
                    {i18n.t('EmploymentStatus..label..Student')}
                  </Option>
                  {/* <Option value="Homemaker">
                    {i18n.t('EmploymentStatus..label..Homemaker')}
                  </Option> */}
                  <Option value="Retired">
                    {i18n.t('EmploymentStatus..label..Retired')}
                  </Option>
                  <Option value="Other">
                    {i18n.t('EmploymentStatus..label..Other')}
                  </Option>
                </Select>
              )}
            </FormItem>
          </div>
        </div>
      </EmploymentStatusWrapper>
    </SectionStyling>
  );

EmploymentStatus.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  isEmploymentHidden: PropTypes.bool.isRequired,
};

export default EmploymentStatus;
