import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import i18n from 'Utils/i18n';
import { cx } from 'emotion/macro';
import { Form, Input, AutoComplete } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome,  } from '@fortawesome/free-solid-svg-icons';
import { counties } from 'Utils/counties';
import FormLabel from '../../components/FormLabel';

import {
  WarningBannerWrapper,

} from '../../components/WarningBanner';
import { SectionStyling, iconSpacing } from '../styles.js';

const formInputSize = 'large';
const FormItem = Form.Item;

const LivingAddressWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  @media (min-width: 1000px) {
    .coreForms {
      grid-template-columns: 2fr 5fr 2fr;
    }

    .moreThanYear {
      grid-column: 1/4;
    }
  }
`;
 
const LivingAddress = ({ getFieldDecorator, getFieldValue }) => {
  const moreThanYear = getFieldValue('moreThanYear');
  const livingLess = 'Less than a year';
  const [isPreviousAddressVisible, setPreviousAddressVisible] = useState(false);
  useEffect(() => setPreviousAddressVisible(moreThanYear === livingLess));

  return (
    <SectionStyling>
      <LivingAddressWrapper>
        <h2>
          <FontAwesomeIcon
            icon={faHome}
            className={cx('faIcon', iconSpacing)}
          />
          {i18n.t('LivingAddress..label..Living address - Person applying')}
        </h2>
        <WarningBannerWrapper isOrange={true}>
          <span className="description">
            The name and address belonging to the person applying for
            instalments must match the registered bank account used for payment.
          </span>
        </WarningBannerWrapper>
        <WarningBannerWrapper isOrange={false}>
          <span className="description">
            This person can be someone else than the person(s) traveling. If we
            are unable to validate your identity you will be contacted by our
            credit partner within the next business day.
          </span>
        </WarningBannerWrapper>

        <div className="coreForms">
          <div>
            <FormLabel>{i18n.t('LivingAddress..label..UK Postcode')}</FormLabel>
            <FormItem>
              {getFieldDecorator('postcode', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'LivingAddress..validator..The postcode given is invalid or incomplete. Please review.'
                    ),
                    pattern:
                      i18n.language === 'pl'
                        ? /^\d{2}-\d{3}/
                        : /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
                  },
                ],
              })(
                <Input
                  placeholder={i18n.t(
                    'LivingAddress..placeholder..e.g. B4 7DA'
                  )}
                  size={formInputSize}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </div>
          <div>
            <FormLabel>{i18n.t('LivingAddress..label..Street name')}</FormLabel>
            <FormItem>
              {getFieldDecorator('street', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'LivingAddress..validator..The street address given is invalid or incomplete.'
                    ),
                    whitespace: true,
                    type: 'string',
                  },
                ],
              })(
                <Input
                  placeholder={i18n.t(
                    'LivingAddress..placeholder..e.g. Pentonville road'
                  )}
                  size={formInputSize}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </div>
          <div>
            <FormLabel>
              {i18n.t('LivingAddress..label..House number/building')}
            </FormLabel>
            <FormItem>
              {getFieldDecorator('houseNr', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'LivingAddress..validator..The house number given is invalid or incomplete.'
                    ),
                    whitespace: true,
                    type: 'string',
                  },
                ],
              })(
                <Input
                  placeholder={i18n.t(
                    'LivingAddress..placeholder..e.g. Flat E, 27'
                  )}
                  size={formInputSize}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </div>
        </div>

        <div className="apartmentForms">
          <div>
            <FormLabel>
              {i18n.t('LivingAddress..label..Apartment (if applicable)')}
            </FormLabel>
            <FormItem>
              {getFieldDecorator('apartmentNr', {
                rules: [
                  {
                    required: false,
                    message: i18n.t(
                      'LivingAddress..validator..The apartment number given is invalid or incomplete.'
                    ),
                    whitespace: true,
                    type: 'string',
                  },
                ],
              })(
                <Input
                  placeholder="e.g. 205"
                  size={formInputSize}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </div>
          <div>
            <FormLabel>{i18n.t('LivingAddress..label..Floor Nr')}</FormLabel>
            <FormItem>
              {getFieldDecorator('floorNr', {
                rules: [
                  {
                    required: false,
                    message: i18n.t(
                      'LivingAddress..validator..The floor number given is invalid or incomplete.'
                    ),
                    whitespace: true,
                    type: 'string',
                  },
                ],
              })(
                <Input
                  placeholder="2"
                  size={formInputSize}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </div>
          <div>
            <FormLabel>{i18n.t('LivingAddress..label..City')}</FormLabel>
            <FormItem>
              {getFieldDecorator('city', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'LivingAddress..validator..The city given is invalid or incomplete.'
                    ),
                    whitespace: true,
                    type: 'string',
                  },
                ],
              })(
                <Input
                  placeholder="London"
                  size={formInputSize}
                  style={{ width: '100%' }}
                />
              )}
            </FormItem>
          </div>
          <div>
            <FormLabel>{i18n.t('LivingAddress..label..County')}</FormLabel>
            <FormItem>
              {getFieldDecorator('county', {
                rules: [
                  {
                    required: true,
                    message: i18n.t(
                      'LivingAddress..validator..The County given is invalid or incomplete.'
                    ),
                    whitespace: true,
                    type: 'string',
                  },
                ],
              })(
                <AutoComplete
                  size={formInputSize}
                  placeholder={i18n.t('LivingAddress..placeholder..Choose one')}
                  dataSource={counties}
                  style={{ width: '100%' }}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              )}
            </FormItem>
          </div>
        </div>
        <div
          className={cx({
            previousAddress: true,
            slideup: !isPreviousAddressVisible,
            slidedown: isPreviousAddressVisible,
          })}
        >
          {isPreviousAddressVisible && (
            <div>
              <span className="description mobileOnly">
                <b>
                  {i18n.t(
                    'LivingAddress..label..Please fill in the details of your previous address.'
                  )}
                </b>
              </span>
              <WarningBannerWrapper className="desktopOnly">
                <span className="description">
                  <b>
                    {i18n.t(
                      'LivingAddress..label..Please fill in the details of your previous address.'
                    )}
                  </b>
                </span>
              </WarningBannerWrapper>

              <div className="coreForms">
                <div>
                  <FormLabel>
                    {i18n.t('LivingAddress..label..UK Postcode')}
                  </FormLabel>
                  <FormItem>
                    {getFieldDecorator('previous_postcode', {
                      rules: [
                        {
                          required: true,
                          message: i18n.t(
                            'LivingAddress..validator..The postcode given is invalid or incomplete. Please review.'
                          ),
                          pattern: /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
                          // validator: customPreviousAddressValidator,
                        },
                      ],
                    })(
                      <Input
                        placeholder={i18n.t(
                          'LivingAddress..placeholder..e.g. B4 7DA'
                        )}
                        size={formInputSize}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </div>
                <div>
                  <FormLabel>
                    {i18n.t('LivingAddress..label..Street name')}
                  </FormLabel>
                  <FormItem>
                    {getFieldDecorator('previous_street', {
                      rules: [
                        {
                          required: true,
                          message: i18n.t(
                            'LivingAddress..validator..The street address given is invalid or incomplete.'
                          ),
                          whitespace: true,
                          type: 'string',
                          // validator: customPreviousAddressValidator,
                        },
                      ],
                    })(
                      <Input
                        placeholder={i18n.t(
                          'LivingAddress..placeholder..e.g. Pentonville road'
                        )}
                        size={formInputSize}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </div>
                <div>
                  <FormLabel>
                    {i18n.t('LivingAddress..label..House number/building')}
                  </FormLabel>
                  <FormItem>
                    {getFieldDecorator('previous_houseNr', {
                      rules: [
                        {
                          required: true,
                          message: i18n.t(
                            'LivingAddress..validator..The house number given is invalid or incomplete.'
                          ),
                          whitespace: true,
                          type: 'string',
                          // validator: customPreviousAddressValidator,
                        },
                      ],
                    })(
                      <Input
                        placeholder={i18n.t(
                          'LivingAddress..placeholder..e.g. Flat E, 27'
                        )}
                        size={formInputSize}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          )}
        </div>
      </LivingAddressWrapper>
    </SectionStyling>
  );
};

LivingAddress.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
};

export default LivingAddress;
