import React, { useContext, useEffect, useState } from 'react';
import { PARTNERS } from 'Utils';
import DisplayPaymentInformation from './Display';
import AppContext from 'Context/AppContext';
import { BOOKING_TYPES } from '../../../constants';
import { TICKET_SERVICE_PRICE } from '../../../constants';

export const PaymentInformation = () => {
  const context = useContext(AppContext);
  const {
    paymentPlanPartnerSelected,
    lastSearch: { noMonths, noAdults, noChildren, noInfants },
    selectedFlightTicket: { conversion },
    discountCode: { code, value },
    getBaggagePiecesAndPrices,
    purchasedBaggage,
    typeBookingContext,
    isAddedTicketServiceContext
  } = context;
  // Unpack added baggage pieces.

  // The flymble price what we received from KIWI and then with our adjustment.
  // This adjustment is done elsewhere in the application.
  const flymblePrice = conversion[process.env.REACT_APP_VARIANT_CURRENCY];

  //
  const [totalBaggagePrice, setTotalBaggagePrice] = useState(0);

  // Calculating the total number of passengers
  const noPassengers = noAdults + noChildren + noInfants;

  // Apply discount code to the final price ().
  const discountValue = value || 0;

  /*
  The total booking price charged by flymble. This means: 
  (1) Price for all passengers, 
  (2) Including margin [not calculated here], 
  (3) Optional baggage prices, 
  (4) Optional discount code.
  */
  const costAddedTicketService = isAddedTicketServiceContext ? (TICKET_SERVICE_PRICE * noPassengers) : 0;
  const totalFlymbleIncludingBaggage =
    flymblePrice.toFixed(2) - discountValue + totalBaggagePrice + costAddedTicketService;

  // The Monthly price, for the whole booking.
  // August 5th 2019: We currently display the price per month as "from".
  let monthlyPriceIncludingBaggage = totalFlymbleIncludingBaggage / noMonths;

  useEffect(() => {
    if (purchasedBaggage) {
      setTimeout(() => {
        clearTimeout();
        setTotalBaggagePrice(getBaggagePiecesAndPrices().totalBaggagePrice);
      }, 700);
    }
  }, [purchasedBaggage]);

  const isPayInFull = typeBookingContext === BOOKING_TYPES.STRIPE_Flights;
  // const isPayInFull = true;
  // const isVisibleDisplayPaymentInformation = window.location.pathname.indexOf('pay-booking') === -1 ? true : isPayInFull;
  const isVisibleDisplayPaymentInformation = true;



  return (
    isVisibleDisplayPaymentInformation && (
      <>
        <DisplayPaymentInformation
          isAddedTicketServiceContext={isAddedTicketServiceContext}
          costAddedTicketService={costAddedTicketService}
          isPayInFull={isPayInFull}
          sumBaggagePrices={totalBaggagePrice}
          monthlyPriceIncludingBaggage={monthlyPriceIncludingBaggage}
          totalFlymbleIncludingBaggage={totalFlymbleIncludingBaggage}
          discountValue={discountValue}
          discountCode={code}
          noPassengers={noPassengers}
          noMonths={noMonths}
          airfareAllPassengers={flymblePrice}
          isVisibleInformationCircle={
            paymentPlanPartnerSelected !== PARTNERS.KLARNA
          }
        />
      </>
    )
  );
};

