import React from 'react';
import { DealDescriptionWrapper } from './styles';
import { DealLabels } from '../DealLabels';
import PropTypes from 'prop-types';

export const DealDescription = ({
  offerSummaryDescription,
  dealPrice,
  oldDealPrice,
}) => (
  <DealDescriptionWrapper>
    <div style={{ position: 'relative' }}></div>
    <DealLabels
      isAbsolute={false}
      isExclusive={false}
      dealPrice={dealPrice}
      oldDealPrice={oldDealPrice}
    />
    <p>{offerSummaryDescription}</p>
  </DealDescriptionWrapper>
);

DealDescription.propTypes = {
  offerSummaryDescription: PropTypes.string.isRequired,
  dealPrice: PropTypes.number.isRequired,
  oldDealPrice: PropTypes.number.isRequired,
};
