import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { PropTypes } from 'prop-types';
import moment from 'moment';

export const DateDropDowns = ({
  monthlyPriceArray,
  handleSelectDepartureDate,
}) => {
  const [monthIdState, setMonthIdState] = useState(7);
  const [dayIdState, setDayIdState] = useState(1);

  const handleDaySelection = value => {
    setDayIdState(value);
    let dateString = `${value}-${monthIdState}-2020`;
    let momentObject = moment(dateString, 'D-M-YYYY');
    handleSelectDepartureDate(momentObject);
  };

  const handleMonthsSelection = value => {
    setMonthIdState(value);
    handleDaySelection(dayIdState);
    let dateString = `${dayIdState}-${value}-2020`;
    let momentObject = moment(dateString, 'D-M-YYYY');
    handleSelectDepartureDate(momentObject);
  };

  useEffect(() => {}, [monthIdState, dayIdState]);

  const nonZeroMonths = [];
  monthlyPriceArray.map(item => item.price !== 0 && nonZeroMonths.push(item));

  const nonZeroMonths_adjustment = nonZeroMonths.length > 4 ? 5 : 4;


  return (
    <>
      <p
        style={{
          marginBottom: '5px',
          color: 'black',
          fontSize: '15px',
          fontWeight: '400',
        }}
      >
        Departure Month
      </p>
      <Select
        showArrow={true}
        onChange={value => {
          handleMonthsSelection(value);
        }}
        size="large"
        defaultValue={`i.e. ${nonZeroMonths[nonZeroMonths.length - nonZeroMonths_adjustment].month}`}
      >
        {monthlyPriceArray.map((item, index) => {
          let monthString =
            parseInt(item.price) !== 0 && `${item.month} £${item.price}`;

          if (parseInt(item.price) !== 0) {
            return (
              <Select.Option value={item.monthId} key={index}>
                <div
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                >
                  <div>{monthString}</div>
                </div>
              </Select.Option>
            );
          } else return true;
        })}
      </Select>
      <br />
      <br />
      <p
        style={{
          marginBottom: '5px',
          color: 'black',
          fontSize: '15px',
          fontWeight: '400',
        }}
      >
        Departure Day
      </p>
      <Select
        showArrow={true}
        onChange={value => {
          handleDaySelection(value);
        }}
        size="large"
        defaultValue={moment(`1-${monthIdState}-2020`, 'D-M-YYYY').format(
          'DD dddd'
        )}
      >
        {Array.from(Array(monthlyPriceArray[monthIdState - 1].days).keys()).map(
          index => {
            let dateString = `${index + 1}-${monthIdState}-2020`;
            let momentObject = moment(dateString, 'D-M-YYYY');
            let dateDisplayString = momentObject.format('DD dddd');

            return (
              <Select.Option value={index + 1} key={index}>
                <div
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                >
                  <div>{dateDisplayString}</div>
                </div>
              </Select.Option>
            );
          }
        )}
      </Select>
    </>
  );
};

DateDropDowns.propTypes = {
  monthlyPriceArray: PropTypes.array.isRequired,
  handleSelectDepartureDate: PropTypes.func.isRequired,
};
