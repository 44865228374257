import React from 'react';
import PropTypes from 'prop-types';
import CoreView from '../CoreView';
import { Form } from 'antd';
import { FormStepController } from '../FormStepController';
import AppContext from 'Context/AppContext';
import { CheckoutFormsUKWrapper } from '../styles';
import { RedirectElement } from 'components/RedirectElement';
import { FORMSTEPS, trackCheckoutPage } from 'Utils';



class FirstCheckoutPageProxy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToConfirmationPage: false,
      isToConfirmationButtonLoading: false,
    };
  }

  handleFirstCheckoutPageSubmit = event => {
    event.persist();
    event.preventDefault();
    this.setState({ isToConfirmationButtonLoading: true });
    this.props.form.validateFieldsAndScroll(
      { scroll: { offsetTop: 100 } },
      (err, checkoutValues) => {
        if (!err) {
          const {
            setFirstCheckoutPageFormValuesContext,
          } = this.context;

          setFirstCheckoutPageFormValuesContext(checkoutValues);

          setTimeout(() => {
            clearTimeout();
            this.setState({
              isToConfirmationButtonLoading: false,
              redirectToConfirmationPage: true,
            });
          }, 2000);
        } else if (err) {
          this.context.setCurrentFormStepContext(FORMSTEPS.STEP0);
          this.setState({
            isToConfirmationButtonLoading: false,
          });
          console.log(
            'There has been an error in the first checkout page container. Cannot proceed to next page',
            'The error is....',
            err
          );
        }
      }
    );
  };

  componentDidMount = () => {
    this.context.setCurrentFormStepContext(FORMSTEPS.STEP0)


    trackCheckoutPage(
      777, // ticket price
      12, // months
      FORMSTEPS.FNPL // partner selected.
    );
  };

  render() {
    const { form } = this.props;

    return (
      <>
        <Form id="checkout-form" onSubmit={this.handleFirstCheckoutPageSubmit}>
          <CheckoutFormsUKWrapper>
            <FormStepController
              form={form}
              isToConfirmationButtonLoading={
                this.state.isToConfirmationButtonLoading
              }
              typeCheckout='packages'
            />
          </CheckoutFormsUKWrapper>
        </Form>
        <RedirectElement
          destination={`/confirmation${window.location.pathname.indexOf(
            'packages'
          ) !== -1 && '/packages'}`}
          isAllowedRedirect={this.state.redirectToConfirmationPage}
        />
      </>
    );
  }
}

FirstCheckoutPageProxy.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

FirstCheckoutPageProxy.contextType = AppContext;

const WrappedFirstCheckoutPageForm = Form.create({})(FirstCheckoutPageProxy);

class PackagesCheckoutPage extends React.Component {
  render() {
    return (
      <CoreView timeOutDelay={100}>
        <WrappedFirstCheckoutPageForm />
      </CoreView>
    );
  }
}

export default PackagesCheckoutPage;
