import React from 'react';
import styled from 'react-emotion/macro';
import { useTranslation } from 'react-i18next';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import background from './HeroSectionMobile/beachDesktop.jpeg';
import { HeroSectionMobile } from './HeroSectionMobile/index.js';
import FrontSearch from './FrontSearch';

const flymbleBlue = '#4860ff';

// Desktop
const heroHeight = '570';

const Main = () => {
  const { t } = useTranslation();
  return (
    <MainStyled>
      <span className="phone-number">
        <a href={t('Main..Phone Number Link')}>
          <FontAwesomeIcon icon={faPhone} className="faIconPhone" />
          &nbsp;&nbsp;
          {t('Main..Phone Number Text')}
        </a>
        &nbsp; (open 10am-5pm)
      </span>
      <div className="desktopTitles">
        <h1>Dream holidays made affordable</h1>
        <h2>
          {/* Spread the cost of your flight over 3-12 monthly installments */}
          {/* Book your perfect holiday now and take advantage of pay monthly
          installments */}
        </h2>
      </div>
      <HeroSectionMobile />
      <div className="mainGrid">
        <FrontSearch />
      </div>
    </MainStyled>
  );
};

export default Main;

const MainStyled = styled.div`
  color: #fff;
  background-color: white;
  background-size: 1920px 1000px;
  background-attachment: scroll;
  background-position-x: center;
  background-position-y: -145px;

  @media (min-width: 1000px) {
    min-height: 470px;
    background-position-y: -220px;
    min-height: ${heroHeight}px;
    background-image: url(${background});
    padding-top: 50px;
    .wrapper {
      margin-left: 1.3em;
      max-height: ${heroHeight}px;
    }
  }

  span.phone-number {
    @media (max-width: 1000px) {
      display: none;
    }
    float: right;
    position: absolute;
    right: 90px;
    top: 90px;

    a {
      font-size: 40px;
      font-weight: 400px;
      color: #fffd;
      text-shadow: 2px 2px 20px rgb(255, 255, 255);

      .faIconPhone {
        font-size: 35px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .titles {
    @media (min-width: 1000px) {
      float: right;
      display: none;
    }
  }

  h1 {
    font-family: 'Montserrat Alternates', sans-serif;
    margin: 0px;
    padding-top: 0px;
    padding-right: 20px;
    font-size: 37px;
    font-weight: 600;
    color: ${flymbleBlue};

    @media (min-width: 1000px) {
      font-size: 45px;
      padding-top: 0px;
      margin-top: 30px;
    }
  }

  h3 {
    padding: 30px 0px 30px 0px;
  }

  .desktopOnly {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .iataLogo {
    position: absolute;
    right: 30px;
    top: 85px;
    height: 50px;
    padding-right: 20px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  /* This changes are to transfer mobile searchform to desktop */
  @media (min-width: 1000px) {
    .mainGrid {
      grid-template-columns: 1fr repeat(2, minmax(auto, 550px)) 1fr;
      padding-top: 60px;
    }
  }

  .desktopTitles {
    text-align: center;
    font-family: 'Montserrat Alternates', sans-serif;
    /* text-shadow: 1px 1px 1px rgba(62, 62, 62, 0.27); */

    h1,
    h2 {
      color: white;
    }

    h2 {
      font-size: 26px;
      margin-block-start: -5px;
      font-weight: 400;
      margin-top: 10px;
    }

    h1 {
      font-size: 68px;
      padding-top: 70px;
      margin-bottom: 5px;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;
