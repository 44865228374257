import React from 'react';
import { useTranslation } from 'react-i18next';
import { KIND_OF_TRIP } from '../../../../constants';
import {
  TravelDisplayStyle,
  CircleStop,
  LineStopHorizontal,
  LineStopVertical,
} from './styles';

const Stops = ({ amountStops }) => {
  const { t } = useTranslation();
  // pluralization for PL language is for 1, 2-4 and 5, for GB is 1, many
  // Here we also need to service 0 in a different way
  var textStops;
  if (amountStops === 0) {
    textStops = <span className="directFlight">{t('Stops..Direct')}</span>;
  } else {
    textStops = (
      <span>{t('Stops..{{count}} stop', { count: amountStops })}</span>
    );
  }
  return textStops;
};

const TravelDisplay = ({
  departureTime,
  departureAirport,
  departureRouteDay,
  departureRouteCity,
  flightDuration,
  routeStops,
  arrivalTime,
  arrivalAirport,
  arrivalRouteDay,
  arrivalRouteCity,
  typeFlight,
}) => (
  <TravelDisplayStyle
    className={typeFlight === KIND_OF_TRIP.ONE_WAY ? KIND_OF_TRIP.ONE_WAY : ''}
  >
    <div className="travelDisplayHolder">
      <div className="departureTime">{departureTime}</div>
      <span className="airportCodeFrom">{departureAirport}</span>
      <span className="departureRouteDay">{departureRouteDay}</span>
      <span className="departureRouteCity">{departureRouteCity}</span>

      <div className="routeStopsWrapper">
        <span className="totalFlightDuration">{flightDuration}</span>

        <div>
          <CircleStop />
          <LineStopHorizontal />
          <CircleStop />
        </div>

        <span className="routeStops">
          <Stops amountStops={routeStops} />
        </span>
      </div>

      <div className="arrivalTime">{arrivalTime}</div>
      <span className="airportCodeTo">{arrivalAirport}</span>

      <span className="arrivalRouteDay">{arrivalRouteDay}</span>
      <span className="arrivalRouteCity">{arrivalRouteCity}</span>
    </div>
  </TravelDisplayStyle>
);

export { TravelDisplay, CircleStop, LineStopHorizontal, LineStopVertical };
