import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import i18n from 'Utils/i18n';
import styled from 'react-emotion/macro';
import { colors } from 'themeFlymble';

const ProceedButtonCheckout = ({
  buttonText,
  handlerFunction,
  isProceedButtonVisible,
  subText,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleButtonClick() {
    setIsButtonLoading(true);
    setTimeout(() => {
      handlerFunction(true);
      setIsButtonLoading(false);
      clearTimeout();
    }, 2500);
  }

  return (
    isProceedButtonVisible && (
      <ProceedButtonCheckoutWrapper>
        <Button
          onClick={handleButtonClick}
          className="proceedButton"
          id="proceedContactDetailsButton"
          loading={isButtonLoading}
        >
          {buttonText ? buttonText : i18n.t('ProceedContactDetails..Next')}
          <FontAwesomeIcon icon={faArrowRight} className="faIconProceed" />
        </Button>
        <div className="subText">{subText && subText}</div>
      </ProceedButtonCheckoutWrapper>
    )
  );
};

ProceedButtonCheckout.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handlerFunction: PropTypes.func.isRequired,
  isProceedButtonVisible: PropTypes.bool.isRequired,
  subText: PropTypes.string,
};

export { ProceedButtonCheckout };

const ProceedButtonCheckoutWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 80px;
  margin-top: 20px;

  .proceedButton {
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
    }

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;
    }

    @media (max-width: 1000px) {
      margin-bottom: 20px !important;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }

      .faIconProceed {
        margin-left: 10px;
        font-size: 19px;
      }
    }
  }
  .subText {
    grid-column: 1/3;
    text-align: center;
    margin-right: 10px;
    font-weight: 300;
    color: black;
    font-size: 15px;
    @media (min-width: 1000px) {
      grid-column: 2/3;
    }
  }
`;
