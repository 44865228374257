import styled from 'react-emotion/macro';

export const PackagesPageWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 50px;

  a {
    text-decoration: none !important;
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 480px)) 1fr;

    .gridWrapper {
      grid-column: 2/4;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
    }
  }
`;

export const DealInformationPageWrapper = styled.div`
  padding-bottom: 100px;

  a {
    text-decoration: none !important;
    color: inherit !important;
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 400px)) 1fr;

    .gridWrapper {
      grid-column: 2/4;
    }
  }
`;
