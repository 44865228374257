const listCities = new Map([
 ['C_LCG', { icon: 'faBuilding', label: 'A Coruña (all airports), Spain', key: 'C_LCG', type: 'city', code: 'a-coruna_es' } ],
 ['C_XNR', { icon: 'faBuilding', label: 'Aabenraa (all airports), Denmark', key: 'C_XNR', type: 'city', code: 'aabenraa_dk' } ],
 ['C_AAH', { icon: 'faBuilding', label: 'Aachen (all airports), Germany', key: 'C_AAH', type: 'city', code: 'aachen_de' } ],
 ['C_XOA', { icon: 'faBuilding', label: 'Aalbaek (all airports), Denmark', key: 'C_XOA', type: 'city', code: 'aalbaek_dk' } ],
 ['C_AAL', { icon: 'faBuilding', label: 'Aalborg (all airports), Denmark', key: 'C_AAL', type: 'city', code: 'aalborg_dk' } ],
 ['C_AAR', { icon: 'faBuilding', label: 'Aarhus (all airports), Denmark', key: 'C_AAR', type: 'city', code: 'aarhus_dk' } ],
 ['C_JEG', { icon: 'faBuilding', label: 'Aasiaat (all airports), Greenland', key: 'C_JEG', type: 'city', code: 'aasiaat_gl' } ],
 ['C_ABD', { icon: 'faBuilding', label: 'Abadan (all airports), Iran', key: 'C_ABD', type: 'city', code: 'abadan_ir' } ],
 ['C_ABA', { icon: 'faBuilding', label: 'Abakan (all airports), Russia', key: 'C_ABA', type: 'city', code: 'abakan_ru' } ],
 ['C_YXX', { icon: 'faBuilding', label: 'Abbotsford (all airports), Canada', key: 'C_YXX', type: 'city', code: 'abbotsford_bc_ca' } ],
 ['C_ABZ', { icon: 'faBuilding', label: 'Aberdeen (all airports), United Kingdom', key: 'C_ABZ', type: 'city', code: 'aberdeen_gb' } ],
 ['C_ABR', { icon: 'faBuilding', label: 'Aberdeen (all airports), United States', key: 'C_ABR', type: 'city', code: 'aberdeen_sd_us' } ],
 ['C_AHB', { icon: 'faBuilding', label: 'Abha (all airports), Saudi Arabia', key: 'C_AHB', type: 'city', code: 'abha_sa' } ],
 ['C_ABJ', { icon: 'faBuilding', label: 'Abidjan (all airports), Côte d’Ivoire', key: 'C_ABJ', type: 'city', code: 'abidjan_ci' } ],
 ['C_ABI', { icon: 'faBuilding', label: 'Abilene (all airports), United States', key: 'C_ABI', type: 'city', code: 'abilene_tx_us' } ],
 ['C_AUH', { icon: 'faBuilding', label: 'Abu Dhabi (all airports), United Arab Emirates', key: 'C_AUH', type: 'city', code: 'abu-dhabi_ae' } ],
 ['C_ABS', { icon: 'faBuilding', label: 'Abu Simbel (all airports), Egypt', key: 'C_ABS', type: 'city', code: 'abu-simbel_eg' } ],
 ['C_ABV', { icon: 'faBuilding', label: 'Abuja (all airports), Nigeria', key: 'C_ABV', type: 'city', code: 'abuja_ng' } ],
 ['C_ACD', { icon: 'faBuilding', label: 'Acandí (all airports), Colombia', key: 'C_ACD', type: 'city', code: 'acandi_co' } ],
 ['C_ACA', { icon: 'faBuilding', label: 'Acapulco (all airports), Mexico', key: 'C_ACA', type: 'city', code: 'acapulco_mx' } ],
 ['C_ACC', { icon: 'faBuilding', label: 'Accra (all airports), Ghana', key: 'C_ACC', type: 'city', code: 'accra_gh' } ],
 ['C_ACU', { icon: 'faBuilding', label: 'Achutupo (all airports), Panama', key: 'C_ACU', type: 'city', code: 'achutupo_pa' } ],
 ['C_AAD', { icon: 'faBuilding', label: 'Adado (all airports), Somalia', key: 'C_AAD', type: 'city', code: 'adado_so' } ],
 ['C_ADK', { icon: 'faBuilding', label: 'Adak Island (all airports), United States', key: 'C_ADK', type: 'city', code: 'adak-island_ak_us' } ],
 ['C_ADA', { icon: 'faBuilding', label: 'Adana (all airports), Turkey', key: 'C_ADA', type: 'city', code: 'adana_tr' } ],
 ['C_ADD', { icon: 'faBuilding', label: 'Addis Ababa (all airports), Ethiopia', key: 'C_ADD', type: 'city', code: 'addis-ababa_et' } ],
 ['C_ADL', { icon: 'faBuilding', label: 'Adelaide (all airports), Australia', key: 'C_ADL', type: 'city', code: 'adelaide_sa_au' } ],
 ['C_ZDB', { icon: 'faBuilding', label: 'Adelboden (all airports), Switzerland', key: 'C_ZDB', type: 'city', code: 'adelboden_ch' } ],
 ['C_ADE', { icon: 'faBuilding', label: 'Aden (all airports), Yemen', key: 'C_ADE', type: 'city', code: 'aden_ye' } ],
 ['C_ADF', { icon: 'faBuilding', label: 'Adıyaman (all airports), Turkey', key: 'C_ADF', type: 'city', code: 'adiyaman_tr' } ],
 ['C_AZR', { icon: 'faBuilding', label: 'Adrar (all airports), Algeria', key: 'C_AZR', type: 'city', code: 'adrar_dz' } ],
 ['C_AJY', { icon: 'faBuilding', label: 'Agadez (all airports), Niger', key: 'C_AJY', type: 'city', code: 'agadez_ne' } ],
 ['C_AGA', { icon: 'faBuilding', label: 'Agadir (all airports), Morocco', key: 'C_AGA', type: 'city', code: 'agadir_ma' } ],
 ['C_IXA', { icon: 'faBuilding', label: 'Agartala (all airports), India', key: 'C_IXA', type: 'city', code: 'agartala_in' } ],
 ['C_AGX', { icon: 'faBuilding', label: 'Agatti Island (all airports), India', key: 'C_AGX', type: 'city', code: 'agatti-island_in' } ],
 ['C_AGF', { icon: 'faBuilding', label: 'Agen (all airports), France', key: 'C_AGF', type: 'city', code: 'agen_fr' } ],
 ['C_AGR', { icon: 'faBuilding', label: 'Agra (all airports), India', key: 'C_AGR', type: 'city', code: 'agra_in' } ],
 ['C_AJI', { icon: 'faBuilding', label: 'Ağrı (all airports), Turkey', key: 'C_AJI', type: 'city', code: 'agri_tr' } ],
 ['C_BQN', { icon: 'faBuilding', label: 'Aguadilla (all airports), United States', key: 'C_BQN', type: 'city', code: 'aguadilla_pr_pr' } ],
 ['C_AGU', { icon: 'faBuilding', label: 'Aguascalientes (all airports), Mexico', key: 'C_AGU', type: 'city', code: 'aguascalientes_mx' } ],
 ['C_AHE', { icon: 'faBuilding', label: 'Ahe (all airports), French Polynesia', key: 'C_AHE', type: 'city', code: 'ahe_pf' } ],
 ['C_AMD', { icon: 'faBuilding', label: 'Ahmedabad (all airports), India', key: 'C_AMD', type: 'city', code: 'ahmedabad_in' } ],
 ['C_AWZ', { icon: 'faBuilding', label: 'Ahvaz (all airports), Iran', key: 'C_AWZ', type: 'city', code: 'ahvaz_ir' } ],
 ['C_AIT', { icon: 'faBuilding', label: 'Aitutaki (all airports), Cook Islands', key: 'C_AIT', type: 'city', code: 'aitutaki_ck' } ],
 ['C_AJL', { icon: 'faBuilding', label: 'Aizawl (all airports), India', key: 'C_AJL', type: 'city', code: 'aizawl_in' } ],
 ['C_AJA', { icon: 'faBuilding', label: 'Ajaccio (all airports), France', key: 'C_AJA', type: 'city', code: 'ajaccio_fr' } ],
 ['C_AKK', { icon: 'faBuilding', label: 'Akhiok (all airports), United States', key: 'C_AKK', type: 'city', code: 'akhiok_ak_us' } ],
 ['C_KKI', { icon: 'faBuilding', label: 'Akiachak (all airports), United States', key: 'C_KKI', type: 'city', code: 'akiachak_ak_us' } ],
 ['C_AXT', { icon: 'faBuilding', label: 'Akita (all airports), Japan', key: 'C_AXT', type: 'city', code: 'akita_jp' } ],
 ['C_LAK', { icon: 'faBuilding', label: 'Aklavik (all airports), Canada', key: 'C_LAK', type: 'city', code: 'aklavik_nt_ca' } ],
 ['C_CAK', { icon: 'faBuilding', label: 'Akron (all airports), United States', key: 'C_CAK', type: 'city', code: 'akron_oh_us' } ],
 ['C_AKU', { icon: 'faBuilding', label: 'Aksu City (all airports), China', key: 'C_AKU', type: 'city', code: 'aksu_cn' } ],
 ['C_SCO', { icon: 'faBuilding', label: 'Aktau (all airports), Kazakhstan', key: 'C_SCO', type: 'city', code: 'aktau_kz' } ],
 ['C_AKX', { icon: 'faBuilding', label: 'Aktobe (all airports), Kazakhstan', key: 'C_AKX', type: 'city', code: 'aktobe_kz' } ],
 ['C_AKV', { icon: 'faBuilding', label: 'Akulivik (all airports), Canada', key: 'C_AKV', type: 'city', code: 'akulivik_nt_ca' } ],
 ['C_AKR', { icon: 'faBuilding', label: 'Akure (all airports), Nigeria', key: 'C_AKR', type: 'city', code: 'akure_ng' } ],
 ['C_AEY', { icon: 'faBuilding', label: 'Akureyri (all airports), Iceland', key: 'C_AEY', type: 'city', code: 'akureyri_is' } ],
 ['C_KQA', { icon: 'faBuilding', label: 'Akutan (all airports), United States', key: 'C_KQA', type: 'city', code: 'akutan_ak_us' } ],
 ['C_AAN', { icon: 'faBuilding', label: 'Al Ain (all airports), United Arab Emirates', key: 'C_AAN', type: 'city', code: 'al-ain_ae' } ],
 ['C_ABT', { icon: 'faBuilding', label: 'Al Bahah (all airports), Saudi Arabia', key: 'C_ABT', type: 'city', code: 'al-bahah_sa' } ],
 ['C_LAQ', { icon: 'faBuilding', label: 'Al Bayda  (all airports), Libya', key: 'C_LAQ', type: 'city', code: 'al-bayda_ly' } ],
 ['C_AHU', { icon: 'faBuilding', label: 'Al Hoceima (all airports), Morocco', key: 'C_AHU', type: 'city', code: 'al-hoceima_ma' } ],
 ['C_AJF', { icon: 'faBuilding', label: 'Al Jawf Region (all airports), Saudi Arabia', key: 'C_AJF', type: 'city', code: 'al-jawf-region_sa' } ],
 ['C_EJH', { icon: 'faBuilding', label: 'Al Wajh (all airports), Saudi Arabia', key: 'C_EJH', type: 'city', code: 'al-wajh_sa' } ],
 ['C_ULH', { icon: 'faBuilding', label: 'Al-`Ula (all airports), Saudi Arabia', key: 'C_ULH', type: 'city', code: 'al-ula_sa' } ],
 ['C_ELF', { icon: 'faBuilding', label: 'Al-Fashir (all airports), Sudan', key: 'C_ELF', type: 'city', code: 'al-fashir_sd' } ],
 ['C_ELQ', { icon: 'faBuilding', label: 'Al-Qassim Region (all airports), Saudi Arabia', key: 'C_ELQ', type: 'city', code: 'gassim_sa' } ],
 ['C_AUK', { icon: 'faBuilding', label: 'Alakanuk (all airports), United States', key: 'C_AUK', type: 'city', code: 'alakanuk_ak_us' } ],
 ['C_ALS', { icon: 'faBuilding', label: 'Alamosa (all airports), United States', key: 'C_ALS', type: 'city', code: 'alamosa_co_us' } ],
 ['C_ABC', { icon: 'faBuilding', label: 'Albacete (all airports), Spain', key: 'C_ABC', type: 'city', code: 'albacete_es' } ],
 ['C_ALB', { icon: 'faBuilding', label: 'Albany (all airports), United States', key: 'C_ALB', type: 'city', code: 'albany_ny_us' } ],
 ['C_ABY', { icon: 'faBuilding', label: 'Albany (all airports), United States', key: 'C_ABY', type: 'city', code: 'albany_ga_us' } ],
 ['C_ALH', { icon: 'faBuilding', label: 'Albany (all airports), Australia', key: 'C_ALH', type: 'city', code: 'albany_wa_au' } ],
 ['C_ABQ', { icon: 'faBuilding', label: 'Albuquerque (all airports), United States', key: 'C_ABQ', type: 'city', code: 'albuquerque_nm_us' } ],
 ['C_ABX', { icon: 'faBuilding', label: 'Albury (all airports), Australia', key: 'C_ABX', type: 'city', code: 'albury_ns_au' } ],
 ['C_ADH', { icon: 'faBuilding', label: 'Aldan (all airports), Russia', key: 'C_ADH', type: 'city', code: 'aldan_ru' } ],
 ['C_ACI', { icon: 'faBuilding', label: 'Alderney (all airports), Guernsey', key: 'C_ACI', type: 'city', code: 'alderney_gg' } ],
 ['C_AES', { icon: 'faBuilding', label: 'Ålesund (all airports), Norway', key: 'C_AES', type: 'city', code: 'alesund_no' } ],
 ['C_AEX', { icon: 'faBuilding', label: 'Alexandria (all airports), United States', key: 'C_AEX', type: 'city', code: 'alexandria_la_us' } ],
 ['C_ALY', { icon: 'faBuilding', label: 'Alexandria (all airports), Egypt', key: 'C_ALY', type: 'city', code: 'alexandria_eg' } ],
 ['C_AXD', { icon: 'faBuilding', label: 'Alexandroupoli (all airports), Greece', key: 'C_AXD', type: 'city', code: 'alexandroupoli_gr' } ],
 ['C_AHO', { icon: 'faBuilding', label: 'Alghero (all airports), Italy', key: 'C_AHO', type: 'city', code: 'alghero_it' } ],
 ['C_ALG', { icon: 'faBuilding', label: 'Algiers (all airports), Algeria', key: 'C_ALG', type: 'city', code: 'algiers_dz' } ],
 ['C_ALC', { icon: 'faBuilding', label: 'Alicante (all airports), Spain', key: 'C_ALC', type: 'city', code: 'alicante_es' } ],
 ['C_ASP', { icon: 'faBuilding', label: 'Alice Springs (all airports), Australia', key: 'C_ASP', type: 'city', code: 'alice-springs_nt_au' } ],
 ['C_IXD', { icon: 'faBuilding', label: 'Allahabad (all airports), India', key: 'C_IXD', type: 'city', code: 'allahabad_in' } ],
 ['C_ABE', { icon: 'faBuilding', label: 'Allentown (all airports), United States', key: 'C_ABE', type: 'city', code: 'allentown_pa_us' } ],
 ['C_ALA', { icon: 'faBuilding', label: 'Almaty (all airports), Kazakhstan', key: 'C_ALA', type: 'city', code: 'almaty_kz' } ],
 ['C_LEI', { icon: 'faBuilding', label: 'Almería (all airports), Spain', key: 'C_LEI', type: 'city', code: 'almeria_es' } ],
 ['C_IUE', { icon: 'faBuilding', label: 'Alofi (all airports), Niue', key: 'C_IUE', type: 'city', code: 'alofi_nu' } ],
 ['C_ARD', { icon: 'faBuilding', label: 'Alor Island (all airports), Indonesia', key: 'C_ARD', type: 'city', code: 'alor-island_id' } ],
 ['C_AOR', { icon: 'faBuilding', label: 'Alor Setar (all airports), Malaysia', key: 'C_AOR', type: 'city', code: 'alor-setar_my' } ],
 ['C_GUR', { icon: 'faBuilding', label: 'Alotau (all airports), Papua New Guinea', key: 'C_GUR', type: 'city', code: 'alotau_pg' } ],
 ['C_APN', { icon: 'faBuilding', label: 'Alpena (all airports), United States', key: 'C_APN', type: 'city', code: 'alpena_mi_us' } ],
 ['C_ALF', { icon: 'faBuilding', label: 'Alta (all airports), Norway', key: 'C_ALF', type: 'city', code: 'alta_no' } ],
 ['C_AFL', { icon: 'faBuilding', label: 'Alta Floresta (all airports), Brazil', key: 'C_AFL', type: 'city', code: 'alta-floresta_mt_br' } ],
 ['C_ATM', { icon: 'faBuilding', label: 'Altamira (all airports), Brazil', key: 'C_ATM', type: 'city', code: 'altamira_pa_br' } ],
 ['C_AAT', { icon: 'faBuilding', label: 'Altay Prefecture (all airports), China', key: 'C_AAT', type: 'city', code: 'altay-prefecture_cn' } ],
 ['C_ASJ', { icon: 'faBuilding', label: 'Amami (all airports), Japan', key: 'C_ASJ', type: 'city', code: 'amami_jp' } ],
 ['C_AMA', { icon: 'faBuilding', label: 'Amarillo (all airports), United States', key: 'C_AMA', type: 'city', code: 'amarillo_tx_us' } ],
 ['C_MZH', { icon: 'faBuilding', label: 'Amasya (all airports), Turkey', key: 'C_MZH', type: 'city', code: 'amasya_tr' } ],
 ['C_ABL', { icon: 'faBuilding', label: 'Ambler (all airports), United States', key: 'C_ABL', type: 'city', code: 'ambler_ak_us' } ],
 ['C_AMQ', { icon: 'faBuilding', label: 'Ambon, Maluku (all airports), Indonesia', key: 'C_AMQ', type: 'city', code: 'ambon_id' } ],
 ['C_ASV', { icon: 'faBuilding', label: 'Amboseli National Park (all airports), Kenya', key: 'C_ASV', type: 'city', code: 'amboseli-national-park_ke' } ],
 ['C_AMM', { icon: 'faBuilding', label: 'Amman (all airports), Jordan', key: 'C_AMM', type: 'city', code: 'amman_jo' } ],
 ['C_ATQ', { icon: 'faBuilding', label: 'Amritsar (all airports), India', key: 'C_ATQ', type: 'city', code: 'amritsar_in' } ],
 ['C_AMS', { icon: 'faBuilding', label: 'Amsterdam (all airports), Netherlands', key: 'C_AMS', type: 'city', code: 'amsterdam_nl' } ],
 ['C_AAA', { icon: 'faBuilding', label: 'Anaa (all airports), French Polynesia', key: 'C_AAA', type: 'city', code: 'anaa_pf' } ],
 ['C_DYR', { icon: 'faBuilding', label: 'Anadyr (all airports), Russia', key: 'C_DYR', type: 'city', code: 'anadyr_ru' } ],
 ['C_YAA', { icon: 'faBuilding', label: 'Anahim Lake (all airports), Canada', key: 'C_YAA', type: 'city', code: 'anahim-lake_bc_ca' } ],
 ['C_AKP', { icon: 'faBuilding', label: 'Anaktuvuk Pass (all airports), United States', key: 'C_AKP', type: 'city', code: 'anaktuvuk-pass_ak_us' } ],
 ['C_AAQ', { icon: 'faBuilding', label: 'Anapa (all airports), Russia', key: 'C_AAQ', type: 'city', code: 'anapa_ru' } ],
 ['C_ANC', { icon: 'faBuilding', label: 'Anchorage (all airports), United States', key: 'C_ANC', type: 'city', code: 'anchorage_ak_us' } ],
 ['C_AOI', { icon: 'faBuilding', label: 'Ancona (all airports), Italy', key: 'C_AOI', type: 'city', code: 'ancona_it' } ],
 ['C_ANS', { icon: 'faBuilding', label: 'Andahuaylas (all airports), Peru', key: 'C_ANS', type: 'city', code: 'andahuaylas_pe' } ],
 ['C_ANX', { icon: 'faBuilding', label: 'Andenes (all airports), Norway', key: 'C_ANX', type: 'city', code: 'andenes_no' } ],
 ['C_AZN', { icon: 'faBuilding', label: 'Andijan (all airports), Uzbekistan', key: 'C_AZN', type: 'city', code: 'andijan_uz' } ],
 ['C_BDU', { icon: 'faBuilding', label: 'Andselv (all airports), Norway', key: 'C_BDU', type: 'city', code: 'andselv_no' } ],
 ['C_NGD', { icon: 'faBuilding', label: 'Anegada (all airports), British Virgin Islands', key: 'C_NGD', type: 'city', code: 'anegada_vg' } ],
 ['C_AUY', { icon: 'faBuilding', label: 'Aneityum (all airports), Vanuatu', key: 'C_AUY', type: 'city', code: 'aneityum_vu' } ],
 ['C_CRK', { icon: 'faBuilding', label: 'Angeles (all airports), Philippines', key: 'C_CRK', type: 'city', code: 'angeles_ph' } ],
 ['C_AGH', { icon: 'faBuilding', label: 'Ängelholm (all airports), Sweden', key: 'C_AGH', type: 'city', code: 'angelholm_se' } ],
 ['C_ANE', { icon: 'faBuilding', label: 'Angers (all airports), France', key: 'C_ANE', type: 'city', code: 'angers_fr' } ],
 ['C_AGN', { icon: 'faBuilding', label: 'Angoon (all airports), United States', key: 'C_AGN', type: 'city', code: 'angoon_ak_us' } ],
 ['C_AXA', { icon: 'faBuilding', label: 'Anguilla (all airports), Anguilla', key: 'C_AXA', type: 'city', code: 'anguilla_ai' } ],
 ['C_ANI', { icon: 'faBuilding', label: 'Aniak (all airports), United States', key: 'C_ANI', type: 'city', code: 'aniak_ak_us' } ],
 ['C_AWD', { icon: 'faBuilding', label: 'Aniwa Island (all airports), Vanuatu', key: 'C_AWD', type: 'city', code: 'aniwa-island_vu' } ],
 ['C_AJN', { icon: 'faBuilding', label: 'Anjouan (all airports), Comoros', key: 'C_AJN', type: 'city', code: 'anjouan_km' } ],
 ['C_ANK', { icon: 'faBuilding', label: 'Ankara (all airports), Turkey', key: 'C_ANK', type: 'city', code: 'ankara_tr' } ],
 ['C_VBA', { icon: 'faBuilding', label: 'Ann (all airports), Myanmar (Burma)', key: 'C_VBA', type: 'city', code: 'ann_mm' } ],
 ['C_AAE', { icon: 'faBuilding', label: 'Annaba (all airports), Algeria', key: 'C_AAE', type: 'city', code: 'annaba_dz' } ],
 ['C_AQG', { icon: 'faBuilding', label: 'Anqing (all airports), China', key: 'C_AQG', type: 'city', code: 'anqing_cn' } ],
 ['C_AOG', { icon: 'faBuilding', label: 'Anshan (all airports), China', key: 'C_AOG', type: 'city', code: 'anshan_cn' } ],
 ['C_AVA', { icon: 'faBuilding', label: 'Anshun (all airports), China', key: 'C_AVA', type: 'city', code: 'anshun_cn' } ],
 ['C_AYT', { icon: 'faBuilding', label: 'Antalya (all airports), Turkey', key: 'C_AYT', type: 'city', code: 'antalya_tr' } ],
 ['C_TNR', { icon: 'faBuilding', label: 'Antananarivo (all airports), Madagascar', key: 'C_TNR', type: 'city', code: 'antananarivo_mg' } ],
 ['C_ANU', { icon: 'faBuilding', label: 'Antigua (all airports), Antigua & Barbuda', key: 'C_ANU', type: 'city', code: 'antigua_ag' } ],
 ['C_ANF', { icon: 'faBuilding', label: 'Antofagasta (all airports), Chile', key: 'C_ANF', type: 'city', code: 'antofagasta_cl' } ],
 ['C_DIE', { icon: 'faBuilding', label: 'Antsiranana (all airports), Madagascar', key: 'C_DIE', type: 'city', code: 'antsiranana_mg' } ],
 ['C_ANR', { icon: 'faBuilding', label: 'Antwerp (all airports), Belgium', key: 'C_ANR', type: 'city', code: 'antwerp_be' } ],
 ['C_ANV', { icon: 'faBuilding', label: 'Anvik (all airports), United States', key: 'C_ANV', type: 'city', code: 'anvik_ak_us' } ],
 ['C_AOJ', { icon: 'faBuilding', label: 'Aomori (all airports), Japan', key: 'C_AOJ', type: 'city', code: 'aomori_jp' } ],
 ['C_APO', { icon: 'faBuilding', label: 'Apartadó (all airports), Colombia', key: 'C_APO', type: 'city', code: 'apartado_co' } ],
 ['C_APK', { icon: 'faBuilding', label: 'Apataki (all airports), French Polynesia', key: 'C_APK', type: 'city', code: 'apataki_pf' } ],
 ['C_APW', { icon: 'faBuilding', label: 'Apia (all airports), Samoa', key: 'C_APW', type: 'city', code: 'apia_ws' } ],
 ['C_ATW', { icon: 'faBuilding', label: 'Appleton (all airports), United States', key: 'C_ATW', type: 'city', code: 'appleton_wi_us' } ],
 ['C_AQJ', { icon: 'faBuilding', label: 'Aqaba (all airports), Jordan', key: 'C_AQJ', type: 'city', code: 'aqaba_jo' } ],
 ['C_AJU', { icon: 'faBuilding', label: 'Aracaju (all airports), Brazil', key: 'C_AJU', type: 'city', code: 'aracaju_se_br' } ],
 ['C_ARU', { icon: 'faBuilding', label: 'Araçatuba (all airports), Brazil', key: 'C_ARU', type: 'city', code: 'aracatuba_sp_br' } ],
 ['C_ARW', { icon: 'faBuilding', label: 'Arad (all airports), Romania', key: 'C_ARW', type: 'city', code: 'arad_ro' } ],
 ['C_AUX', { icon: 'faBuilding', label: 'Araguaína (all airports), Brazil', key: 'C_AUX', type: 'city', code: 'araguaina_to_br' } ],
 ['C_RAE', { icon: 'faBuilding', label: 'Arar (all airports), Saudi Arabia', key: 'C_RAE', type: 'city', code: 'arar_sa' } ],
 ['C_ACR', { icon: 'faBuilding', label: 'Araracuara (all airports), Colombia', key: 'C_ACR', type: 'city', code: 'araracuara_co' } ],
 ['C_ASS', { icon: 'faBuilding', label: 'Arathusa Safari Lodge (all airports), South Africa', key: 'C_ASS', type: 'city', code: 'arathusa-safari-lodge_za' } ],
 ['C_RKA', { icon: 'faBuilding', label: 'Aratika (all airports), French Polynesia', key: 'C_RKA', type: 'city', code: 'aratika_pf' } ],
 ['C_AUC', { icon: 'faBuilding', label: 'Arauca (all airports), Colombia', key: 'C_AUC', type: 'city', code: 'arauca_co' } ],
 ['C_AAX', { icon: 'faBuilding', label: 'Araxá (all airports), Brazil', key: 'C_AAX', type: 'city', code: 'araxa_mg_br' } ],
 ['C_AMH', { icon: 'faBuilding', label: 'Arba Minch (all airports), Ethiopia', key: 'C_AMH', type: 'city', code: 'arba-minch_et' } ],
 ['C_ACV', { icon: 'faBuilding', label: 'Arcata (all airports), United States', key: 'C_ACV', type: 'city', code: 'arcata_ca_us' } ],
 ['C_YAB', { icon: 'faBuilding', label: 'Arctic Bay (all airports), Canada', key: 'C_YAB', type: 'city', code: 'arctic-bay_nu_ca' } ],
 ['C_ARC', { icon: 'faBuilding', label: 'Arctic Village (all airports), United States', key: 'C_ARC', type: 'city', code: 'arctic-village_ak_us' } ],
 ['C_ADU', { icon: 'faBuilding', label: 'Ardabil (all airports), Iran', key: 'C_ADU', type: 'city', code: 'ardabil_ir' } ],
 ['C_AQP', { icon: 'faBuilding', label: 'Arequipa (all airports), Peru', key: 'C_AQP', type: 'city', code: 'arequipa_pe' } ],
 ['C_ARI', { icon: 'faBuilding', label: 'Arica (all airports), Chile', key: 'C_ARI', type: 'city', code: 'arica_cl' } ],
 ['C_ARH', { icon: 'faBuilding', label: 'Arkhangelsk (all airports), Russia', key: 'C_ARH', type: 'city', code: 'arkhangelsk_ru' } ],
 ['C_AXM', { icon: 'faBuilding', label: 'Armenia (all airports), Colombia', key: 'C_AXM', type: 'city', code: 'armenia_co' } ],
 ['C_ARM', { icon: 'faBuilding', label: 'Armidale (all airports), Australia', key: 'C_ARM', type: 'city', code: 'armidale_ns_au' } ],
 ['C_ZYM', { icon: 'faBuilding', label: 'Arnhem (all airports), Netherlands', key: 'C_ZYM', type: 'city', code: 'arnhem_nl' } ],
 ['C_RNA', { icon: 'faBuilding', label: 'Arona (all airports), Solomon Islands', key: 'C_RNA', type: 'city', code: 'arona_sb' } ],
 ['C_BMY', { icon: 'faBuilding', label: 'Art Island (all airports), New Caledonia', key: 'C_BMY', type: 'city', code: 'art-island_nc' } ],
 ['C_ATC', { icon: 'faBuilding', label: 'Arthur\'s Town (all airports), Bahamas', key: 'C_ATC', type: 'city', code: 'arthurs-town_bs' } ],
 ['C_AUA', { icon: 'faBuilding', label: 'Aruba (all airports), Aruba', key: 'C_AUA', type: 'city', code: 'aruba_aw' } ],
 ['C_ARK', { icon: 'faBuilding', label: 'Arusha (all airports), Tanzania', key: 'C_ARK', type: 'city', code: 'arusha_tz' } ],
 ['C_AXR', { icon: 'faBuilding', label: 'Arutua (all airports), French Polynesia', key: 'C_AXR', type: 'city', code: 'arutua_pf' } ],
 ['C_YEK', { icon: 'faBuilding', label: 'Arviat (all airports), Canada', key: 'C_YEK', type: 'city', code: 'arviat_nu_ca' } ],
 ['C_AJR', { icon: 'faBuilding', label: 'Arvidsjaur (all airports), Sweden', key: 'C_AJR', type: 'city', code: 'arvidsjaur_se' } ],
 ['C_ABB', { icon: 'faBuilding', label: 'Asaba (all airports), Nigeria', key: 'C_ABB', type: 'city', code: 'asaba_ng' } ],
 ['C_AKJ', { icon: 'faBuilding', label: 'Asahikawa (all airports), Japan', key: 'C_AKJ', type: 'city', code: 'asahikawa_jp' } ],
 ['C_PGU', { icon: 'faBuilding', label: 'Asaluyeh (all airports), Iran', key: 'C_PGU', type: 'city', code: 'asaluyeh_ir' } ],
 ['C_AVL', { icon: 'faBuilding', label: 'Asheville (all airports), United States', key: 'C_AVL', type: 'city', code: 'asheville_nc_us' } ],
 ['C_QDH', { icon: 'faBuilding', label: 'Ashford (all airports), United Kingdom', key: 'C_QDH', type: 'city', code: 'ashford_gb' } ],
 ['C_ASB', { icon: 'faBuilding', label: 'Ashgabat (all airports), Turkmenistan', key: 'C_ASB', type: 'city', code: 'ashgabat_tm' } ],
 ['C_ASM', { icon: 'faBuilding', label: 'Asmara (all airports), Eritrea', key: 'C_ASM', type: 'city', code: 'asmara_er' } ],
 ['C_ASO', { icon: 'faBuilding', label: 'Asosa (all airports), Ethiopia', key: 'C_ASO', type: 'city', code: 'asosa_et' } ],
 ['C_ASE', { icon: 'faBuilding', label: 'Aspen (all airports), United States', key: 'C_ASE', type: 'city', code: 'aspen_co_us' } ],
 ['C_TSE', { icon: 'faBuilding', label: 'Astana (all airports), Kazakhstan', key: 'C_TSE', type: 'city', code: 'astana_kz' } ],
 ['C_ASF', { icon: 'faBuilding', label: 'Astrakhan (all airports), Russia', key: 'C_ASF', type: 'city', code: 'astrakhan_ru' } ],
 ['C_OVD', { icon: 'faBuilding', label: 'Asturias (all airports), Spain', key: 'C_OVD', type: 'city', code: 'asturias_es' } ],
 ['C_JTY', { icon: 'faBuilding', label: 'Astypalaia (all airports), Greece', key: 'C_JTY', type: 'city', code: 'astypalaia_gr' } ],
 ['C_ASU', { icon: 'faBuilding', label: 'Asunción (all airports), Paraguay', key: 'C_ASU', type: 'city', code: 'asuncion_py' } ],
 ['C_ASW', { icon: 'faBuilding', label: 'Aswan (all airports), Egypt', key: 'C_ASW', type: 'city', code: 'aswan_eg' } ],
 ['C_ATZ', { icon: 'faBuilding', label: 'Asyut (all airports), Egypt', key: 'C_ATZ', type: 'city', code: 'asyut_eg' } ],
 ['C_ABU', { icon: 'faBuilding', label: 'Atambua (all airports), Indonesia', key: 'C_ABU', type: 'city', code: 'atambua_id' } ],
 ['C_ATH', { icon: 'faBuilding', label: 'Athens (all airports), Greece', key: 'C_ATH', type: 'city', code: 'athens_gr' } ],
 ['C_AIU', { icon: 'faBuilding', label: 'Atiu (all airports), Cook Islands', key: 'C_AIU', type: 'city', code: 'atiu_ck' } ],
 ['C_AKB', { icon: 'faBuilding', label: 'Atka (all airports), United States', key: 'C_AKB', type: 'city', code: 'atka_ak_us' } ],
 ['C_ATL', { icon: 'faBuilding', label: 'Atlanta (all airports), United States', key: 'C_ATL', type: 'city', code: 'atlanta_ga_us' } ],
 ['C_AIY', { icon: 'faBuilding', label: 'Atlantic City (all airports), United States', key: 'C_AIY', type: 'city', code: 'atlantic-city_nj_us' } ],
 ['C_ATT', { icon: 'faBuilding', label: 'Atmautluak (all airports), United States', key: 'C_ATT', type: 'city', code: 'atmautluak_ak_us' } ],
 ['C_ATD', { icon: 'faBuilding', label: 'Atoifi (all airports), Solomon Islands', key: 'C_ATD', type: 'city', code: 'atoifi_sb' } ],
 ['C_ATK', { icon: 'faBuilding', label: 'Atqasuk (all airports), United States', key: 'C_ATK', type: 'city', code: 'atqasuk_ak_us' } ],
 ['C_YAT', { icon: 'faBuilding', label: 'Attawapiskat First Nation (all airports), Canada', key: 'C_YAT', type: 'city', code: 'attawapiskat-first-nation_on_ca' } ],
 ['C_GUW', { icon: 'faBuilding', label: 'Atyrau (all airports), Kazakhstan', key: 'C_GUW', type: 'city', code: 'atyrau_kz' } ],
 ['C_AKL', { icon: 'faBuilding', label: 'Auckland (all airports), New Zealand', key: 'C_AKL', type: 'city', code: 'auckland_nz' } ],
 ['C_AGS', { icon: 'faBuilding', label: 'Augusta (all airports), United States', key: 'C_AGS', type: 'city', code: 'augusta_ga_us' } ],
 ['C_AUG', { icon: 'faBuilding', label: 'Augusta (all airports), United States', key: 'C_AUG', type: 'city', code: 'augusta_me_us' } ],
 ['C_AKS', { icon: 'faBuilding', label: 'Auki (all airports), Solomon Islands', key: 'C_AKS', type: 'city', code: 'auki_sb' } ],
 ['C_YPJ', { icon: 'faBuilding', label: 'Aupaluk (all airports), Canada', key: 'C_YPJ', type: 'city', code: 'aupaluk_qc_ca' } ],
 ['C_IXU', { icon: 'faBuilding', label: 'Aurangabad (all airports), India', key: 'C_IXU', type: 'city', code: 'aurangabad_in' } ],
 ['C_AUR', { icon: 'faBuilding', label: 'Aurillac (all airports), France', key: 'C_AUR', type: 'city', code: 'aurillac_fr' } ],
 ['C_AUS', { icon: 'faBuilding', label: 'Austin (all airports), United States', key: 'C_AUS', type: 'city', code: 'austin_tx_us' } ],
 ['C_AVN', { icon: 'faBuilding', label: 'Avignon (all airports), France', key: 'C_AVN', type: 'city', code: 'avignon_fr' } ],
 ['C_AWA', { icon: 'faBuilding', label: 'Awasa (all airports), Ethiopia', key: 'C_AWA', type: 'city', code: 'awasa_et' } ],
 ['C_AXU', { icon: 'faBuilding', label: 'Axum (all airports), Ethiopia', key: 'C_AXU', type: 'city', code: 'axum_et' } ],
 ['C_AYP', { icon: 'faBuilding', label: 'Ayacucho (all airports), Peru', key: 'C_AYP', type: 'city', code: 'ayacucho_pe' } ],
 ['C_BKM', { icon: 'faBuilding', label: 'Ba kelalan (all airports), Malaysia', key: 'C_BKM', type: 'city', code: 'bakelalan_my' } ],
 ['C_BXB', { icon: 'faBuilding', label: 'Babo (all airports), Indonesia', key: 'C_BXB', type: 'city', code: 'babo_id' } ],
 ['C_BCM', { icon: 'faBuilding', label: 'Bacău (all airports), Romania', key: 'C_BCM', type: 'city', code: 'bacau_ro' } ],
 ['C_BCD', { icon: 'faBuilding', label: 'Bacolod (all airports), Philippines', key: 'C_BCD', type: 'city', code: 'bacolod_ph' } ],
 ['C_BJZ', { icon: 'faBuilding', label: 'Badajoz (all airports), Spain', key: 'C_BJZ', type: 'city', code: 'badajoz_es' } ],
 ['C_BFX', { icon: 'faBuilding', label: 'Bafoussam (all airports), Cameroon', key: 'C_BFX', type: 'city', code: 'bafoussam_cm' } ],
 ['C_NYU', { icon: 'faBuilding', label: 'Bagan (all airports), Myanmar (Burma)', key: 'C_NYU', type: 'city', code: 'bagan_mm' } ],
 ['C_IXB', { icon: 'faBuilding', label: 'Bagdogra (all airports), India', key: 'C_IXB', type: 'city', code: 'bagdogra_in' } ],
 ['C_BGW', { icon: 'faBuilding', label: 'Baghdad (all airports), Iraq', key: 'C_BGW', type: 'city', code: 'baghdad_iq' } ],
 ['C_BHV', { icon: 'faBuilding', label: 'Bahawalpur (all airports), Pakistan', key: 'C_BHV', type: 'city', code: 'bahawalpur_pk' } ],
 ['C_BHI', { icon: 'faBuilding', label: 'Bahía Blanca (all airports), Argentina', key: 'C_BHI', type: 'city', code: 'bahia-blanca_ba_ar' } ],
 ['C_DRK', { icon: 'faBuilding', label: 'Bahía Drake (all airports), Costa Rica', key: 'C_DRK', type: 'city', code: 'bahia-drake_cr' } ],
 ['C_BFQ', { icon: 'faBuilding', label: 'Bahia Pinas (all airports), Panama', key: 'C_BFQ', type: 'city', code: 'bahia-pinas_pa' } ],
 ['C_BSC', { icon: 'faBuilding', label: 'Bahía Solano (all airports), Colombia', key: 'C_BSC', type: 'city', code: 'bahia-solano_co' } ],
 ['C_BJR', { icon: 'faBuilding', label: 'Bahir Dar (all airports), Ethiopia', key: 'C_BJR', type: 'city', code: 'bahir-dar_et' } ],
 ['C_BAH', { icon: 'faBuilding', label: 'Bahrain Island (all airports), Bahrain', key: 'C_BAH', type: 'city', code: 'bahrain-island_bh' } ],
 ['C_YBC', { icon: 'faBuilding', label: 'Baie-Comeau (all airports), Canada', key: 'C_YBC', type: 'city', code: 'baie-comeau_qc_ca' } ],
 ['C_BXY', { icon: 'faBuilding', label: 'Baikonur (all airports), Kazakhstan', key: 'C_BXY', type: 'city', code: 'baikonur_kz' } ],
 ['C_TRW', { icon: 'faBuilding', label: 'Bairiki (all airports), Kiribati', key: 'C_TRW', type: 'city', code: 'bairiki_ki' } ],
 ['C_AEB', { icon: 'faBuilding', label: 'Baise (all airports), China', key: 'C_AEB', type: 'city', code: 'baise_cn' } ],
 ['C_NBS', { icon: 'faBuilding', label: 'Baishan (all airports), China', key: 'C_NBS', type: 'city', code: 'baishan_cn' } ],
 ['C_BJW', { icon: 'faBuilding', label: 'Bajawa (all airports), Indonesia', key: 'C_BJW', type: 'city', code: 'bajawa_id' } ],
 ['C_YBK', { icon: 'faBuilding', label: 'Baker Lake, Nunavut (all airports), Canada', key: 'C_YBK', type: 'city', code: 'baker-lake_nu_ca' } ],
 ['C_BFL', { icon: 'faBuilding', label: 'Bakersfield (all airports), United States', key: 'C_BFL', type: 'city', code: 'bakersfield_ca_us' } ],
 ['C_BAK', { icon: 'faBuilding', label: 'Baku (all airports), Azerbaijan', key: 'C_BAK', type: 'city', code: 'baku_az' } ],
 ['C_BAS', { icon: 'faBuilding', label: 'Balalae Island (all airports), Solomon Islands', key: 'C_BAS', type: 'city', code: 'balalae-island_sb' } ],
 ['C_JIB', { icon: 'faBuilding', label: 'Balbala (all airports), Djibouti', key: 'C_JIB', type: 'city', code: 'balbala_dj' } ],
 ['C_BLB', { icon: 'faBuilding', label: 'Balboa (all airports), Panama', key: 'C_BLB', type: 'city', code: 'balboa_pa' } ],
 ['C_BPN', { icon: 'faBuilding', label: 'Balikpapan (all airports), Indonesia', key: 'C_BPN', type: 'city', code: 'balikpapan_id' } ],
 ['C_BKN', { icon: 'faBuilding', label: 'Balkanabat (all airports), Turkmenistan', key: 'C_BKN', type: 'city', code: 'balkanabat_tm' } ],
 ['C_BXH', { icon: 'faBuilding', label: 'Balkhash (all airports), Kazakhstan', key: 'C_BXH', type: 'city', code: 'balhash_kz' } ],
 ['C_BNK', { icon: 'faBuilding', label: 'Ballina (all airports), Australia', key: 'C_BNK', type: 'city', code: 'ballina_ns_au' } ],
 ['C_BBA', { icon: 'faBuilding', label: 'Balmaceda (all airports), Chile', key: 'C_BBA', type: 'city', code: 'balmaceda_cl' } ],
 ['C_GPS', { icon: 'faBuilding', label: 'Baltra Island (all airports), Ecuador', key: 'C_GPS', type: 'city', code: 'baltra-island_ec' } ],
 ['C_ABM', { icon: 'faBuilding', label: 'Bamaga (all airports), Australia', key: 'C_ABM', type: 'city', code: 'bamaga_ql_au' } ],
 ['C_BKO', { icon: 'faBuilding', label: 'Bamako (all airports), Mali', key: 'C_BKO', type: 'city', code: 'bamako_ml' } ],
 ['C_HOE', { icon: 'faBuilding', label: 'Ban Houayxay (all airports), Laos', key: 'C_HOE', type: 'city', code: 'houeisay_la' } ],
 ['C_BTJ', { icon: 'faBuilding', label: 'Banda Aceh (all airports), Indonesia', key: 'C_BTJ', type: 'city', code: 'banda-aceh_id' } ],
 ['C_BND', { icon: 'faBuilding', label: 'Bandar Abbas (all airports), Iran', key: 'C_BND', type: 'city', code: 'bandar-abbas_ir' } ],
 ['C_TKG', { icon: 'faBuilding', label: 'Bandar Lampung (all airports), Indonesia', key: 'C_TKG', type: 'city', code: 'bandar-lampung_id' } ],
 ['C_BDH', { icon: 'faBuilding', label: 'Bandar Lengeh (all airports), Iran', key: 'C_BDH', type: 'city', code: 'bandar-lengeh_ir' } ],
 ['C_BWN', { icon: 'faBuilding', label: 'Bandar Seri Begawan (all airports), Brunei', key: 'C_BWN', type: 'city', code: 'bandar-seri-begawan_bn' } ],
 ['C_BDO', { icon: 'faBuilding', label: 'Bandung (all airports), Indonesia', key: 'C_BDO', type: 'city', code: 'bandung_id' } ],
 ['C_BKK', { icon: 'faBuilding', label: 'Bangkok (all airports), Thailand', key: 'C_BKK', type: 'city', code: 'bangkok_th' } ],
 ['C_BGR', { icon: 'faBuilding', label: 'Bangor (all airports), United States', key: 'C_BGR', type: 'city', code: 'bangor_me_us' } ],
 ['C_BGF', { icon: 'faBuilding', label: 'Bangui (all airports), Central African Republic', key: 'C_BGF', type: 'city', code: 'bangui_cf' } ],
 ['C_BNX', { icon: 'faBuilding', label: 'Banja Luka (all airports), Bosnia & Herzegovina', key: 'C_BNX', type: 'city', code: 'banja-luka_ba' } ],
 ['C_BDJ', { icon: 'faBuilding', label: 'Banjarmasin (all airports), Indonesia', key: 'C_BDJ', type: 'city', code: 'banjarmasin_id' } ],
 ['C_BJL', { icon: 'faBuilding', label: 'Banjul (all airports), Gambia', key: 'C_BJL', type: 'city', code: 'banjul_gm' } ],
 ['C_BWX', { icon: 'faBuilding', label: 'Banyuwangi (all airports), Indonesia', key: 'C_BWX', type: 'city', code: 'banyuwangi_id' } ],
 ['C_BSD', { icon: 'faBuilding', label: 'Baoshan, Yunnan (all airports), China', key: 'C_BSD', type: 'city', code: 'baoshan_cn' } ],
 ['C_BAV', { icon: 'faBuilding', label: 'Baotou (all airports), China', key: 'C_BAV', type: 'city', code: 'baotou_cn' } ],
 ['C_BHB', { icon: 'faBuilding', label: 'Bar Harbor (all airports), United States', key: 'C_BHB', type: 'city', code: 'bar-harbor_me_us' } ],
 ['C_BCA', { icon: 'faBuilding', label: 'Baracoa (all airports), Cuba', key: 'C_BCA', type: 'city', code: 'baracoa_cu' } ],
 ['C_BCI', { icon: 'faBuilding', label: 'Barcaldine (all airports), Australia', key: 'C_BCI', type: 'city', code: 'barcaldine_ql_au' } ],
 ['C_BCN', { icon: 'faBuilding', label: 'Barcelona (all airports), Spain', key: 'C_BCN', type: 'city', code: 'barcelona_es' } ],
 ['C_BLA', { icon: 'faBuilding', label: 'Barcelona (all airports), Venezuela', key: 'C_BLA', type: 'city', code: 'barcelona_ve' } ],
 ['C_BRI', { icon: 'faBuilding', label: 'Bari (all airports), Italy', key: 'C_BRI', type: 'city', code: 'bari_it' } ],
 ['C_BRC', { icon: 'faBuilding', label: 'Bariloche (all airports), Argentina', key: 'C_BRC', type: 'city', code: 'bariloche_rn_ar' } ],
 ['C_BYP', { icon: 'faBuilding', label: 'Barimunya (all airports), Australia', key: 'C_BYP', type: 'city', code: 'barimunya_wa_au' } ],
 ['C_BNS', { icon: 'faBuilding', label: 'Barinas (all airports), Venezuela', key: 'C_BNS', type: 'city', code: 'barinas_ve' } ],
 ['C_BBN', { icon: 'faBuilding', label: 'Bario (all airports), Malaysia', key: 'C_BBN', type: 'city', code: 'bario_my' } ],
 ['C_BZL', { icon: 'faBuilding', label: 'Barisal (all airports), Bangladesh', key: 'C_BZL', type: 'city', code: 'barisal_bd' } ],
 ['C_BAX', { icon: 'faBuilding', label: 'Barnaul (all airports), Russia', key: 'C_BAX', type: 'city', code: 'barnaul_ru' } ],
 ['C_BRM', { icon: 'faBuilding', label: 'Barquisimeto (all airports), Venezuela', key: 'C_BRM', type: 'city', code: 'barquisimeto_ve' } ],
 ['C_BRR', { icon: 'faBuilding', label: 'Barra (all airports), United Kingdom', key: 'C_BRR', type: 'city', code: 'barra_gb' } ],
 ['C_BPG', { icon: 'faBuilding', label: 'Barra do Garças (all airports), Brazil', key: 'C_BPG', type: 'city', code: 'barra-do-garcas_mt_br' } ],
 ['C_EJA', { icon: 'faBuilding', label: 'Barrancabermeja (all airports), Colombia', key: 'C_EJA', type: 'city', code: 'barrancabermeja_co' } ],
 ['C_BAQ', { icon: 'faBuilding', label: 'Barranquilla (all airports), Colombia', key: 'C_BAQ', type: 'city', code: 'barranquilla_co' } ],
 ['C_BRA', { icon: 'faBuilding', label: 'Barreiras (all airports), Brazil', key: 'C_BRA', type: 'city', code: 'barreiras_ba_br' } ],
 ['C_BRW', { icon: 'faBuilding', label: 'Barrow (all airports), United States', key: 'C_BRW', type: 'city', code: 'barrow_ak_us' } ],
 ['C_BTI', { icon: 'faBuilding', label: 'Barter Island (all airports), United States', key: 'C_BTI', type: 'city', code: 'barter-island_ak_us' } ],
 ['C_BBH', { icon: 'faBuilding', label: 'Barth (all airports), Germany', key: 'C_BBH', type: 'city', code: 'barth_de' } ],
 ['C_BSO', { icon: 'faBuilding', label: 'Basco (all airports), Philippines', key: 'C_BSO', type: 'city', code: 'basco_ph' } ],
 ['C_EAP', { icon: 'faBuilding', label: 'Basel (all airports), Switzerland', key: 'C_EAP', type: 'city', code: 'basel_ch' } ],
 ['C_BSR', { icon: 'faBuilding', label: 'Basra (all airports), Iraq', key: 'C_BSR', type: 'city', code: 'basra_iq' } ],
 ['C_BIA', { icon: 'faBuilding', label: 'Bastia (all airports), France', key: 'C_BIA', type: 'city', code: 'bastia_fr' } ],
 ['C_BSG', { icon: 'faBuilding', label: 'Bata (all airports), Equatorial Guinea', key: 'C_BSG', type: 'city', code: 'bata_gq' } ],
 ['C_BQJ', { icon: 'faBuilding', label: 'Batagay (all airports), Russia', key: 'C_BQJ', type: 'city', code: 'batagay_ru' } ],
 ['C_BTH', { icon: 'faBuilding', label: 'Batam (all airports), Indonesia', key: 'C_BTH', type: 'city', code: 'batam_id' } ],
 ['C_BHS', { icon: 'faBuilding', label: 'Bathurst (all airports), Australia', key: 'C_BHS', type: 'city', code: 'bathurst_ns_au' } ],
 ['C_ZBF', { icon: 'faBuilding', label: 'Bathurst (all airports), Canada', key: 'C_ZBF', type: 'city', code: 'bathurst_nb_ca' } ],
 ['C_BAL', { icon: 'faBuilding', label: 'Batman (all airports), Turkey', key: 'C_BAL', type: 'city', code: 'batman_tr' } ],
 ['C_BLJ', { icon: 'faBuilding', label: 'Batna (all airports), Algeria', key: 'C_BLJ', type: 'city', code: 'batna_dz' } ],
 ['C_BTR', { icon: 'faBuilding', label: 'Baton Rouge (all airports), United States', key: 'C_BTR', type: 'city', code: 'baton-rouge_la_us' } ],
 ['C_BJF', { icon: 'faBuilding', label: 'Båtsfjord (all airports), Norway', key: 'C_BJF', type: 'city', code: 'batsfjord_no' } ],
 ['C_BTC', { icon: 'faBuilding', label: 'Batticaloa (all airports), Sri Lanka', key: 'C_BTC', type: 'city', code: 'batticaloa_lk' } ],
 ['C_BTW', { icon: 'faBuilding', label: 'Batulicin (all airports), Indonesia', key: 'C_BTW', type: 'city', code: 'batulicin_id' } ],
 ['C_BUS', { icon: 'faBuilding', label: 'Batumi (all airports), Georgia', key: 'C_BUS', type: 'city', code: 'batumi_ge' } ],
 ['C_BUW', { icon: 'faBuilding', label: 'Bau-Bau (all airports), Indonesia', key: 'C_BUW', type: 'city', code: 'bau-bau_id' } ],
 ['C_JTC', { icon: 'faBuilding', label: 'Bauru (all airports), Brazil', key: 'C_JTC', type: 'city', code: 'bauru_sp_br' } ],
 ['C_BYM', { icon: 'faBuilding', label: 'Bayamo (all airports), Cuba', key: 'C_BYM', type: 'city', code: 'bayamo_cu' } ],
 ['C_RLK', { icon: 'faBuilding', label: 'Bayannur (all airports), China', key: 'C_RLK', type: 'city', code: 'bayannur_cn' } ],
 ['C_XBE', { icon: 'faBuilding', label: 'Bearskin Lake (all airports), Canada', key: 'C_XBE', type: 'city', code: 'bearskin-lake_on_ca' } ],
 ['C_BPT', { icon: 'faBuilding', label: 'Beaumont (all airports), United States', key: 'C_BPT', type: 'city', code: 'beaumont_tx_us' } ],
 ['C_WBQ', { icon: 'faBuilding', label: 'Beaver (all airports), United States', key: 'C_WBQ', type: 'city', code: 'beaver_ak_us' } ],
 ['C_CBH', { icon: 'faBuilding', label: 'Béchar (all airports), Algeria', key: 'C_CBH', type: 'city', code: 'bechar_dz' } ],
 ['C_BKW', { icon: 'faBuilding', label: 'Beckley (all airports), United States', key: 'C_BKW', type: 'city', code: 'beckley_wv_us' } ],
 ['C_BEU', { icon: 'faBuilding', label: 'Bedourie (all airports), Australia', key: 'C_BEU', type: 'city', code: 'bedourie_ql_au' } ],
 ['C_BHY', { icon: 'faBuilding', label: 'Beihai (all airports), China', key: 'C_BHY', type: 'city', code: 'beihai_cn' } ],
 ['C_BJS', { icon: 'faBuilding', label: 'Beijing (all airports), China', key: 'C_BJS', type: 'city', code: 'beijing_cn' } ],
 ['C_BEW', { icon: 'faBuilding', label: 'Beira (all airports), Mozambique', key: 'C_BEW', type: 'city', code: 'beira_mz' } ],
 ['C_BEY', { icon: 'faBuilding', label: 'Beirut (all airports), Lebanon', key: 'C_BEY', type: 'city', code: 'beirut_lb' } ],
 ['C_BJA', { icon: 'faBuilding', label: 'Béjaïa (all airports), Algeria', key: 'C_BJA', type: 'city', code: 'bejaia_dz' } ],
 ['C_BGN', { icon: 'faBuilding', label: 'Belaya Gora, Sakha Republic (all airports), Russia', key: 'C_BGN', type: 'city', code: 'belaya-gora_ru' } ],
 ['C_BEL', { icon: 'faBuilding', label: 'Belém (all airports), Brazil', key: 'C_BEL', type: 'city', code: 'belem_pa_br' } ],
 ['C_BFS', { icon: 'faBuilding', label: 'Belfast (all airports), United Kingdom', key: 'C_BFS', type: 'city', code: 'belfast_gb' } ],
 ['C_IXG', { icon: 'faBuilding', label: 'Belgaum (all airports), India', key: 'C_IXG', type: 'city', code: 'belgaum_in' } ],
 ['C_EGO', { icon: 'faBuilding', label: 'Belgorod (all airports), Russia', key: 'C_EGO', type: 'city', code: 'belgorod_ru' } ],
 ['C_BEG', { icon: 'faBuilding', label: 'Belgrade (all airports), Serbia', key: 'C_BEG', type: 'city', code: 'belgrade_rs' } ],
 ['C_BZE', { icon: 'faBuilding', label: 'Belize City (all airports), Belize', key: 'C_BZE', type: 'city', code: 'belize-city_bz' } ],
 ['C_ZEL', { icon: 'faBuilding', label: 'Bella Bella (all airports), Canada', key: 'C_ZEL', type: 'city', code: 'bella-bella_bc_ca' } ],
 ['C_QBC', { icon: 'faBuilding', label: 'Bella Coola (all airports), Canada', key: 'C_QBC', type: 'city', code: 'bella-coola_bc_ca' } ],
 ['C_BLV', { icon: 'faBuilding', label: 'Belleville (all airports), United States', key: 'C_BLV', type: 'city', code: 'belleville_il_us' } ],
 ['C_BLI', { icon: 'faBuilding', label: 'Bellingham (all airports), United States', key: 'C_BLI', type: 'city', code: 'bellingham_wa_us' } ],
 ['C_BNY', { icon: 'faBuilding', label: 'Bellona Island (all airports), Solomon Islands', key: 'C_BNY', type: 'city', code: 'bellona-island_sb' } ],
 ['C_BHZ', { icon: 'faBuilding', label: 'Belo Horizonte (all airports), Brazil', key: 'C_BHZ', type: 'city', code: 'belo-horizonte_mg_br' } ],
 ['C_EYK', { icon: 'faBuilding', label: 'Beloyarsky (all airports), Russia', key: 'C_EYK', type: 'city', code: 'beloyarsky_ru' } ],
 ['C_BJI', { icon: 'faBuilding', label: 'Bemidji (all airports), United States', key: 'C_BJI', type: 'city', code: 'bemidji_mn_us' } ],
 ['C_BEB', { icon: 'faBuilding', label: 'Benbecula (all airports), United Kingdom', key: 'C_BEB', type: 'city', code: 'benbecula_gb' } ],
 ['C_BLR', { icon: 'faBuilding', label: 'Bengaluru (all airports), India', key: 'C_BLR', type: 'city', code: 'bengaluru_in' } ],
 ['C_BEN', { icon: 'faBuilding', label: 'Benghazi (all airports), Libya', key: 'C_BEN', type: 'city', code: 'benghazi_ly' } ],
 ['C_BKS', { icon: 'faBuilding', label: 'Bengkulu (all airports), Indonesia', key: 'C_BKS', type: 'city', code: 'bengkulu_id' } ],
 ['C_BEM', { icon: 'faBuilding', label: 'Beni Mellal (all airports), Morocco', key: 'C_BEM', type: 'city', code: 'beni-mellal_ma' } ],
 ['C_BNC', { icon: 'faBuilding', label: 'Beni, Democratic Republic of the Congo (all airports), Democratic Republic of the Congo', key: 'C_BNC', type: 'city', code: 'beni_cd' } ],
 ['C_BNI', { icon: 'faBuilding', label: 'Benin City (all airports), Nigeria', key: 'C_BNI', type: 'city', code: 'benin-city_ng' } ],
 ['C_BJT', { icon: 'faBuilding', label: 'Bentota (all airports), Sri Lanka', key: 'C_BJT', type: 'city', code: 'bentota_lk' } ],
 ['C_BBO', { icon: 'faBuilding', label: 'Berbera (all airports), Somalia', key: 'C_BBO', type: 'city', code: 'berbera_so' } ],
 ['C_YBV', { icon: 'faBuilding', label: 'Berens River, Manitoba (all airports), Canada', key: 'C_YBV', type: 'city', code: 'berens-river--manitoba_mb_ca' } ],
 ['C_BGO', { icon: 'faBuilding', label: 'Bergen (all airports), Norway', key: 'C_BGO', type: 'city', code: 'bergen_no' } ],
 ['C_EGC', { icon: 'faBuilding', label: 'Bergerac (all airports), France', key: 'C_EGC', type: 'city', code: 'bergerac_fr' } ],
 ['C_BVG', { icon: 'faBuilding', label: 'Berlevåg (all airports), Norway', key: 'C_BVG', type: 'city', code: 'berlevag_no' } ],
 ['C_BER', { icon: 'faBuilding', label: 'Berlin (all airports), Germany', key: 'C_BER', type: 'city', code: 'berlin_de' } ],
 ['C_BDA', { icon: 'faBuilding', label: 'Bermuda (all airports), United Kingdom', key: 'C_BDA', type: 'city', code: 'bermuda_bm' } ],
 ['C_BRN', { icon: 'faBuilding', label: 'Bern (all airports), Switzerland', key: 'C_BRN', type: 'city', code: 'bern_ch' } ],
 ['C_EZV', { icon: 'faBuilding', label: 'Beryozovo, Khanty-Mansi Autonomous Okrug (all airports), Russia', key: 'C_EZV', type: 'city', code: 'beryozovo_ru' } ],
 ['C_QWZ', { icon: 'faBuilding', label: 'Best (all airports), Netherlands', key: 'C_QWZ', type: 'city', code: 'best_nl' } ],
 ['C_BET', { icon: 'faBuilding', label: 'Bethel (all airports), United States', key: 'C_BET', type: 'city', code: 'bethel_ak_us' } ],
 ['C_BTT', { icon: 'faBuilding', label: 'Bettles (all airports), United States', key: 'C_BTT', type: 'city', code: 'bettles_ak_us' } ],
 ['C_BZR', { icon: 'faBuilding', label: 'Béziers (all airports), France', key: 'C_BZR', type: 'city', code: 'beziers_fr' } ],
 ['C_BDP', { icon: 'faBuilding', label: 'Bhadrapur, Mechi (all airports), Nepal', key: 'C_BDP', type: 'city', code: 'bhadrapur_np' } ],
 ['C_BMO', { icon: 'faBuilding', label: 'Bhamo (all airports), Myanmar (Burma)', key: 'C_BMO', type: 'city', code: 'bhamo_mm' } ],
 ['C_BHR', { icon: 'faBuilding', label: 'Bharatpur (all airports), Nepal', key: 'C_BHR', type: 'city', code: 'bharatpur_np' } ],
 ['C_BHU', { icon: 'faBuilding', label: 'Bhavnagar (all airports), India', key: 'C_BHU', type: 'city', code: 'bhavnagar_in' } ],
 ['C_BHO', { icon: 'faBuilding', label: 'Bhopal (all airports), India', key: 'C_BHO', type: 'city', code: 'bhopal_in' } ],
 ['C_BBI', { icon: 'faBuilding', label: 'Bhubaneswar (all airports), India', key: 'C_BBI', type: 'city', code: 'bhubaneswar_in' } ],
 ['C_BHJ', { icon: 'faBuilding', label: 'Bhuj (all airports), India', key: 'C_BHJ', type: 'city', code: 'bhuj_in' } ],
 ['C_BIK', { icon: 'faBuilding', label: 'Biak (all airports), Indonesia', key: 'C_BIK', type: 'city', code: 'biak_id' } ],
 ['C_BIQ', { icon: 'faBuilding', label: 'Biarritz (all airports), France', key: 'C_BIQ', type: 'city', code: 'biarritz_fr' } ],
 ['C_BFJ', { icon: 'faBuilding', label: 'Bijie (all airports), China', key: 'C_BFJ', type: 'city', code: 'bijie_cn' } ],
 ['C_BKB', { icon: 'faBuilding', label: 'Bikaner (all airports), India', key: 'C_BKB', type: 'city', code: 'bikaner_in' } ],
 ['C_BIO', { icon: 'faBuilding', label: 'Bilbao (all airports), Spain', key: 'C_BIO', type: 'city', code: 'bilbao_es' } ],
 ['C_BIL', { icon: 'faBuilding', label: 'Billings (all airports), United States', key: 'C_BIL', type: 'city', code: 'billings_mt_us' } ],
 ['C_BLL', { icon: 'faBuilding', label: 'Billund (all airports), Denmark', key: 'C_BLL', type: 'city', code: 'billund_dk' } ],
 ['C_ZBL', { icon: 'faBuilding', label: 'Biloela (all airports), Australia', key: 'C_ZBL', type: 'city', code: 'biloela_ql_au' } ],
 ['C_BMU', { icon: 'faBuilding', label: 'Bima (all airports), Indonesia', key: 'C_BMU', type: 'city', code: 'bima_id' } ],
 ['C_BIM', { icon: 'faBuilding', label: 'Bimini (all airports), Bahamas', key: 'C_BIM', type: 'city', code: 'bimini_bs' } ],
 ['C_BGM', { icon: 'faBuilding', label: 'Binghamton (all airports), United States', key: 'C_BGM', type: 'city', code: 'binghamton_ny_us' } ],
 ['C_BGG', { icon: 'faBuilding', label: 'Bingöl (all airports), Turkey', key: 'C_BGG', type: 'city', code: 'bingol_tr' } ],
 ['C_BTU', { icon: 'faBuilding', label: 'Bintulu (all airports), Malaysia', key: 'C_BTU', type: 'city', code: 'bintulu_my' } ],
 ['C_BIR', { icon: 'faBuilding', label: 'Biratnagar (all airports), Nepal', key: 'C_BIR', type: 'city', code: 'biratnagar_np' } ],
 ['C_BVI', { icon: 'faBuilding', label: 'Birdsville (all airports), Australia', key: 'C_BVI', type: 'city', code: 'birdsville_ql_au' } ],
 ['C_XBJ', { icon: 'faBuilding', label: 'Birjand (all airports), Iran', key: 'C_XBJ', type: 'city', code: 'birjand_ir' } ],
 ['C_BHM', { icon: 'faBuilding', label: 'Birmingham (all airports), United States', key: 'C_BHM', type: 'city', code: 'birmingham_al_us' } ],
 ['C_BHX', { icon: 'faBuilding', label: 'Birmingham (all airports), United Kingdom', key: 'C_BHX', type: 'city', code: 'birmingham_gb' } ],
 ['C_BHH', { icon: 'faBuilding', label: 'Bisha (all airports), Saudi Arabia', key: 'C_BHH', type: 'city', code: 'bisha_sa' } ],
 ['C_FRU', { icon: 'faBuilding', label: 'Bishkek (all airports), Kyrgyzstan', key: 'C_FRU', type: 'city', code: 'bishkek_kg' } ],
 ['C_BSK', { icon: 'faBuilding', label: 'Biskra (all airports), Algeria', key: 'C_BSK', type: 'city', code: 'biskra_dz' } ],
 ['C_BIS', { icon: 'faBuilding', label: 'Bismarck (all airports), United States', key: 'C_BIS', type: 'city', code: 'bismarck_nd_us' } ],
 ['C_OXB', { icon: 'faBuilding', label: 'Bissau (all airports), Guinea-Bissau', key: 'C_OXB', type: 'city', code: 'bissau_gw' } ],
 ['C_BKQ', { icon: 'faBuilding', label: 'Blackall (all airports), Australia', key: 'C_BKQ', type: 'city', code: 'blackall_ql_au' } ],
 ['C_BLK', { icon: 'faBuilding', label: 'Blackpool (all airports), United Kingdom', key: 'C_BLK', type: 'city', code: 'blackpool_gb' } ],
 ['C_BQS', { icon: 'faBuilding', label: 'Blagoveshchensk (all airports), Russia', key: 'C_BQS', type: 'city', code: 'blagoveshchensk_ru' } ],
 ['C_YBX', { icon: 'faBuilding', label: 'Blanc-Sablon (all airports), Canada', key: 'C_YBX', type: 'city', code: 'blanc-sablon_qc_ca' } ],
 ['C_BLZ', { icon: 'faBuilding', label: 'Blantyre (all airports), Malawi', key: 'C_BLZ', type: 'city', code: 'blantyre_mw' } ],
 ['C_BHE', { icon: 'faBuilding', label: 'Blenheim (all airports), New Zealand', key: 'C_BHE', type: 'city', code: 'blenheim_nz' } ],
 ['C_BFN', { icon: 'faBuilding', label: 'Bloemfontein (all airports), South Africa', key: 'C_BFN', type: 'city', code: 'bloemfontein_za' } ],
 ['C_BMI', { icon: 'faBuilding', label: 'Bloomington (all airports), United States', key: 'C_BMI', type: 'city', code: 'bloomington_il_us' } ],
 ['C_BVB', { icon: 'faBuilding', label: 'Boa Vista (all airports), Brazil', key: 'C_BVB', type: 'city', code: 'boa-vista_rr_br' } ],
 ['C_BVC', { icon: 'faBuilding', label: 'Boa Vista (all airports), Cape Verde', key: 'C_BVC', type: 'city', code: 'boa-vista_cv' } ],
 ['C_BOY', { icon: 'faBuilding', label: 'Bobo-Dioulasso (all airports), Burkina Faso', key: 'C_BOY', type: 'city', code: 'bobo-dioulasso_bf' } ],
 ['C_BOC', { icon: 'faBuilding', label: 'Bocas Town, Bocas del Toro (all airports), Panama', key: 'C_BOC', type: 'city', code: 'bocas-town_pa' } ],
 ['C_QBO', { icon: 'faBuilding', label: 'Bochum (all airports), Germany', key: 'C_QBO', type: 'city', code: 'bochum_de' } ],
 ['C_ODO', { icon: 'faBuilding', label: 'Bodaybo (all airports), Russia', key: 'C_ODO', type: 'city', code: 'bodaybo_ru' } ],
 ['C_BOO', { icon: 'faBuilding', label: 'Bodø (all airports), Norway', key: 'C_BOO', type: 'city', code: 'bodo_no' } ],
 ['C_BJV', { icon: 'faBuilding', label: 'Bodrum (all airports), Turkey', key: 'C_BJV', type: 'city', code: 'bodrum_tr' } ],
 ['C_BOG', { icon: 'faBuilding', label: 'Bogotá (all airports), Colombia', key: 'C_BOG', type: 'city', code: 'bogota_co' } ],
 ['C_BOI', { icon: 'faBuilding', label: 'Boise (all airports), United States', key: 'C_BOI', type: 'city', code: 'boise_id_us' } ],
 ['C_BJB', { icon: 'faBuilding', label: 'Bojnord (all airports), Iran', key: 'C_BJB', type: 'city', code: 'bojnord_ir' } ],
 ['C_VBP', { icon: 'faBuilding', label: 'Bokpyin (all airports), Myanmar (Burma)', key: 'C_VBP', type: 'city', code: 'bokpyin_mm' } ],
 ['C_BLQ', { icon: 'faBuilding', label: 'Bologna (all airports), Italy', key: 'C_BLQ', type: 'city', code: 'bologna_it' } ],
 ['C_null', { icon: 'faBuilding', label: 'Bolzano (all airports), Italy', key: 'C_null', type: 'city', code: 'bolzano_it' } ],
 ['C_BON', { icon: 'faBuilding', label: 'Bonaire (all airports), Netherlands', key: 'C_BON', type: 'city', code: 'bonaire_bq' } ],
 ['C_BYO', { icon: 'faBuilding', label: 'Bonito (all airports), Brazil', key: 'C_BYO', type: 'city', code: 'bonito_ms_br' } ],
 ['C_BOB', { icon: 'faBuilding', label: 'Bora Bora (all airports), French Polynesia', key: 'C_BOB', type: 'city', code: 'bora-bora_pf' } ],
 ['C_BOD', { icon: 'faBuilding', label: 'Bordeaux (all airports), France', key: 'C_BOD', type: 'city', code: 'bordeaux_fr' } ],
 ['C_BMW', { icon: 'faBuilding', label: 'Bordj Badji Mokhtar (all airports), Algeria', key: 'C_BMW', type: 'city', code: 'bordj-badji-mokhtar_dz' } ],
 ['C_BLE', { icon: 'faBuilding', label: 'Borlänge (all airports), Sweden', key: 'C_BLE', type: 'city', code: 'borlange_se' } ],
 ['C_RNN', { icon: 'faBuilding', label: 'Bornholm (all airports), Denmark', key: 'C_RNN', type: 'city', code: 'bornholm_dk' } ],
 ['C_BSA', { icon: 'faBuilding', label: 'Bosaso (all airports), Somalia', key: 'C_BSA', type: 'city', code: 'bosaso_so' } ],
 ['C_BOS', { icon: 'faBuilding', label: 'Boston (all airports), United States', key: 'C_BOS', type: 'city', code: 'boston_ma_us' } ],
 ['C_BYK', { icon: 'faBuilding', label: 'Bouaké (all airports), Côte d’Ivoire', key: 'C_BYK', type: 'city', code: 'bouake_ci' } ],
 ['C_BQL', { icon: 'faBuilding', label: 'Boulia (all airports), Australia', key: 'C_BQL', type: 'city', code: 'boulia_ql_au' } ],
 ['C_BOH', { icon: 'faBuilding', label: 'Bournemouth (all airports), United Kingdom', key: 'C_BOH', type: 'city', code: 'bournemouth_gb' } ],
 ['C_null', { icon: 'faBuilding', label: 'Boutilimit (all airports), Mauritania', key: 'C_null', type: 'city', code: 'boutilimit_mr' } ],
 ['C_BZN', { icon: 'faBuilding', label: 'Bozeman (all airports), United States', key: 'C_BZN', type: 'city', code: 'bozeman_mt_us' } ],
 ['C_BWK', { icon: 'faBuilding', label: 'Brač (all airports), Croatia', key: 'C_BWK', type: 'city', code: 'brac_hr' } ],
 ['C_MNI', { icon: 'faBuilding', label: 'Brades (all airports), United Kingdom', key: 'C_MNI', type: 'city', code: 'brades_ms' } ],
 ['C_BRD', { icon: 'faBuilding', label: 'Brainerd (all airports), United States', key: 'C_BRD', type: 'city', code: 'brainerd_mn_us' } ],
 ['C_ZMW', { icon: 'faBuilding', label: 'Brande (all airports), Denmark', key: 'C_ZMW', type: 'city', code: 'brande_dk' } ],
 ['C_YBR', { icon: 'faBuilding', label: 'Brandon (all airports), Canada', key: 'C_YBR', type: 'city', code: 'brandon_mb_ca' } ],
 ['C_BKG', { icon: 'faBuilding', label: 'Branson (all airports), United States', key: 'C_BKG', type: 'city', code: 'branson_mo_us' } ],
 ['C_BSB', { icon: 'faBuilding', label: 'Brasília (all airports), Brazil', key: 'C_BSB', type: 'city', code: 'brasilia_df_br' } ],
 ['C_BTS', { icon: 'faBuilding', label: 'Bratislava (all airports), Slovakia', key: 'C_BTS', type: 'city', code: 'bratislava_sk' } ],
 ['C_BTK', { icon: 'faBuilding', label: 'Bratsk (all airports), Russia', key: 'C_BTK', type: 'city', code: 'bratsk_ru' } ],
 ['C_BZV', { icon: 'faBuilding', label: 'Brazzaville (all airports), Republic of the Congo', key: 'C_BZV', type: 'city', code: 'brazzaville_cg' } ],
 ['C_XGZ', { icon: 'faBuilding', label: 'Bregenz (all airports), Austria', key: 'C_XGZ', type: 'city', code: 'bregenz_at' } ],
 ['C_BRE', { icon: 'faBuilding', label: 'Bremen (all airports), Germany', key: 'C_BRE', type: 'city', code: 'bremen_de' } ],
 ['C_BRZ', { icon: 'faBuilding', label: 'Brescia (all airports), Italy', key: 'C_BRZ', type: 'city', code: 'brescia_it' } ],
 ['C_BQT', { icon: 'faBuilding', label: 'Brest (all airports), Belarus', key: 'C_BQT', type: 'city', code: 'brest_by' } ],
 ['C_BES', { icon: 'faBuilding', label: 'Brest (all airports), France', key: 'C_BES', type: 'city', code: 'brest_fr' } ],
 ['C_KTS', { icon: 'faBuilding', label: 'Brevig Mission (all airports), United States', key: 'C_KTS', type: 'city', code: 'brevig-mission_ak_us' } ],
 ['C_BGI', { icon: 'faBuilding', label: 'Bridgetown (all airports), Barbados', key: 'C_BGI', type: 'city', code: 'bridgetown_bb' } ],
 ['C_BDS', { icon: 'faBuilding', label: 'Brindisi (all airports), Italy', key: 'C_BDS', type: 'city', code: 'brindisi_it' } ],
 ['C_BNE', { icon: 'faBuilding', label: 'Brisbane (all airports), Australia', key: 'C_BNE', type: 'city', code: 'brisbane_ql_au' } ],
 ['C_TRI', { icon: 'faBuilding', label: 'Bristol (all airports), United States', key: 'C_TRI', type: 'city', code: 'tri-cities_tn_us' } ],
 ['C_BRS', { icon: 'faBuilding', label: 'Bristol (all airports), United Kingdom', key: 'C_BRS', type: 'city', code: 'bristol_gb' } ],
 ['C_BVE', { icon: 'faBuilding', label: 'Brive-la-Gaillarde (all airports), France', key: 'C_BVE', type: 'city', code: 'brive-la-gaillarde_fr' } ],
 ['C_BRQ', { icon: 'faBuilding', label: 'Brno (all airports), Czechia', key: 'C_BRQ', type: 'city', code: 'brno_cz' } ],
 ['C_YBT', { icon: 'faBuilding', label: 'Brochet, Manitoba (all airports), Canada', key: 'C_YBT', type: 'city', code: 'brochet_mb_ca' } ],
 ['C_BHQ', { icon: 'faBuilding', label: 'Broken Hill (all airports), Australia', key: 'C_BHQ', type: 'city', code: 'broken-hill_ns_au' } ],
 ['C_XAQ', { icon: 'faBuilding', label: 'Bronderslev (all airports), Denmark', key: 'C_XAQ', type: 'city', code: 'bronderslev_dk' } ],
 ['C_BNN', { icon: 'faBuilding', label: 'Brønnøysund (all airports), Norway', key: 'C_BNN', type: 'city', code: 'bronnoysund_no' } ],
 ['C_BME', { icon: 'faBuilding', label: 'Broome (all airports), Australia', key: 'C_BME', type: 'city', code: 'broome_wa_au' } ],
 ['C_BRO', { icon: 'faBuilding', label: 'Brownsville (all airports), United States', key: 'C_BRO', type: 'city', code: 'brownsville_tx_us' } ],
 ['C_ZGJ', { icon: 'faBuilding', label: 'Bruges (all airports), Belgium', key: 'C_ZGJ', type: 'city', code: 'bruges_be' } ],
 ['C_SSI', { icon: 'faBuilding', label: 'Brunswick (all airports), United States', key: 'C_SSI', type: 'city', code: 'brunswick_ga_us' } ],
 ['C_BZK', { icon: 'faBuilding', label: 'Bryansk (all airports), Russia', key: 'C_BZK', type: 'city', code: 'bryansk_ru' } ],
 ['C_BGA', { icon: 'faBuilding', label: 'Bucaramanga (all airports), Colombia', key: 'C_BGA', type: 'city', code: 'bucaramanga_co' } ],
 ['C_BUH', { icon: 'faBuilding', label: 'Bucharest (all airports), Romania', key: 'C_BUH', type: 'city', code: 'bucharest_ro' } ],
 ['C_BKC', { icon: 'faBuilding', label: 'Buckland (all airports), United States', key: 'C_BKC', type: 'city', code: 'buckland_ak_us' } ],
 ['C_BUD', { icon: 'faBuilding', label: 'Budapest (all airports), Hungary', key: 'C_BUD', type: 'city', code: 'budapest_hu' } ],
 ['C_BUN', { icon: 'faBuilding', label: 'Buenaventura (all airports), Colombia', key: 'C_BUN', type: 'city', code: 'buenaventura_co' } ],
 ['C_BUE', { icon: 'faBuilding', label: 'Buenos Aires (all airports), Argentina', key: 'C_BUE', type: 'city', code: 'buenos-aires_ba_ar' } ],
 ['C_BUF', { icon: 'faBuilding', label: 'Buffalo (all airports), United States', key: 'C_BUF', type: 'city', code: 'buffalo_ny_us' } ],
 ['C_UUA', { icon: 'faBuilding', label: 'Bugulma (all airports), Russia', key: 'C_UUA', type: 'city', code: 'bugulma_ru' } ],
 ['C_BJM', { icon: 'faBuilding', label: 'Bujumbura (all airports), Burundi', key: 'C_BJM', type: 'city', code: 'bujumbura_bi' } ],
 ['C_BUA', { icon: 'faBuilding', label: 'Buka Island (all airports), Papua New Guinea', key: 'C_BUA', type: 'city', code: 'buka-island_pg' } ],
 ['C_BKY', { icon: 'faBuilding', label: 'Bukavu (all airports), Democratic Republic of the Congo', key: 'C_BKY', type: 'city', code: 'bukavu_cd' } ],
 ['C_BHK', { icon: 'faBuilding', label: 'Bukhara (all airports), Uzbekistan', key: 'C_BHK', type: 'city', code: 'bukhara_uz' } ],
 ['C_BKZ', { icon: 'faBuilding', label: 'Bukoba (all airports), Tanzania', key: 'C_BKZ', type: 'city', code: 'bukoba_tz' } ],
 ['C_BUQ', { icon: 'faBuilding', label: 'Bulawayo (all airports), Zimbabwe', key: 'C_BUQ', type: 'city', code: 'bulawayo_zw' } ],
 ['C_IFP', { icon: 'faBuilding', label: 'Bullhead City (all airports), United States', key: 'C_IFP', type: 'city', code: 'bullhead-city_az_us' } ],
 ['C_BUL', { icon: 'faBuilding', label: 'Bulolo (all airports), Papua New Guinea', key: 'C_BUL', type: 'city', code: 'bulolo_pg' } ],
 ['C_BMB', { icon: 'faBuilding', label: 'Bumba, Democratic Republic of the Congo (all airports), Democratic Republic of the Congo', key: 'C_BMB', type: 'city', code: 'bumba_cd' } ],
 ['C_BDB', { icon: 'faBuilding', label: 'Bundaberg Region (all airports), Australia', key: 'C_BDB', type: 'city', code: 'bundaberg-region_ql_au' } ],
 ['C_BUX', { icon: 'faBuilding', label: 'Bunia (all airports), Democratic Republic of the Congo', key: 'C_BUX', type: 'city', code: 'bunia_cd' } ],
 ['C_UOL', { icon: 'faBuilding', label: 'Buol (all airports), Indonesia', key: 'C_UOL', type: 'city', code: 'buol_id' } ],
 ['C_BMV', { icon: 'faBuilding', label: 'Buon Ma Thuot (all airports), Vietnam', key: 'C_BMV', type: 'city', code: 'buon-ma-thuot_vn' } ],
 ['C_BUR', { icon: 'faBuilding', label: 'Burbank (all airports), United States', key: 'C_BUR', type: 'city', code: 'burbank_ca_us' } ],
 ['C_BOJ', { icon: 'faBuilding', label: 'Burgas (all airports), Bulgaria', key: 'C_BOJ', type: 'city', code: 'burgas_bg' } ],
 ['C_RGS', { icon: 'faBuilding', label: 'Burgos (all airports), Spain', key: 'C_RGS', type: 'city', code: 'burgos_es' } ],
 ['C_BFV', { icon: 'faBuilding', label: 'Buriram Province (all airports), Thailand', key: 'C_BFV', type: 'city', code: 'buriram-province_th' } ],
 ['C_BUC', { icon: 'faBuilding', label: 'Burketown (all airports), Australia', key: 'C_BUC', type: 'city', code: 'burketown_ql_au' } ],
 ['C_BTV', { icon: 'faBuilding', label: 'Burlington (all airports), United States', key: 'C_BTV', type: 'city', code: 'burlington_vt_us' } ],
 ['C_BRL', { icon: 'faBuilding', label: 'Burlington (all airports), United States', key: 'C_BRL', type: 'city', code: 'burlington_ia_us' } ],
 ['C_BWT', { icon: 'faBuilding', label: 'Burnie (all airports), Australia', key: 'C_BWT', type: 'city', code: 'burnie_ts_au' } ],
 ['C_NLK', { icon: 'faBuilding', label: 'Burnt Pine (all airports), Norfolk Island', key: 'C_NLK', type: 'city', code: 'burnt-pine_nf' } ],
 ['C_KJI', { icon: 'faBuilding', label: 'Burqin (all airports), China', key: 'C_KJI', type: 'city', code: 'burqin_cn' } ],
 ['C_YEI', { icon: 'faBuilding', label: 'Bursa (all airports), Turkey', key: 'C_YEI', type: 'city', code: 'bursa_tr' } ],
 ['C_PUS', { icon: 'faBuilding', label: 'Busan (all airports), South Korea', key: 'C_PUS', type: 'city', code: 'busan_kr' } ],
 ['C_BUZ', { icon: 'faBuilding', label: 'Bushehr (all airports), Iran', key: 'C_BUZ', type: 'city', code: 'bushire_ir' } ],
 ['C_BQB', { icon: 'faBuilding', label: 'Busselton (all airports), Australia', key: 'C_BQB', type: 'city', code: 'busselton_wa_au' } ],
 ['C_USU', { icon: 'faBuilding', label: 'Busuanga, Palawan (all airports), Philippines', key: 'C_USU', type: 'city', code: 'busuanga_ph' } ],
 ['C_BTM', { icon: 'faBuilding', label: 'Butte (all airports), United States', key: 'C_BTM', type: 'city', code: 'butte_mt_us' } ],
 ['C_BXU', { icon: 'faBuilding', label: 'Butuan (all airports), Philippines', key: 'C_BXU', type: 'city', code: 'butuan_ph' } ],
 ['C_BZG', { icon: 'faBuilding', label: 'Bydgoszcz (all airports), Poland', key: 'C_BZG', type: 'city', code: 'bydgoszcz_pl' } ],
 ['C_CAH', { icon: 'faBuilding', label: 'Ca Mau Province (all airports), Vietnam', key: 'C_CAH', type: 'city', code: 'ca-mau-province_vn' } ],
 ['C_CAB', { icon: 'faBuilding', label: 'Cabinda (all airports), Angola', key: 'C_CAB', type: 'city', code: 'cabinda_ao' } ],
 ['C_CFB', { icon: 'faBuilding', label: 'Cabo Frio (all airports), Brazil', key: 'C_CFB', type: 'city', code: 'cabo-frio_rj_br' } ],
 ['C_OAL', { icon: 'faBuilding', label: 'Cacoal (all airports), Brazil', key: 'C_OAL', type: 'city', code: 'cacoal_ro_br' } ],
 ['C_CFR', { icon: 'faBuilding', label: 'Caen (all airports), France', key: 'C_CFR', type: 'city', code: 'caen_fr' } ],
 ['C_CGY', { icon: 'faBuilding', label: 'Cagayan de Oro (all airports), Philippines', key: 'C_CGY', type: 'city', code: 'cagayan-de-oro_ph' } ],
 ['C_CAG', { icon: 'faBuilding', label: 'Cagliari (all airports), Italy', key: 'C_CAG', type: 'city', code: 'cagliari_it' } ],
 ['C_CNS', { icon: 'faBuilding', label: 'Cairns (all airports), Australia', key: 'C_CNS', type: 'city', code: 'cairns_ql_au' } ],
 ['C_CAI', { icon: 'faBuilding', label: 'Cairo (all airports), Egypt', key: 'C_CAI', type: 'city', code: 'cairo_eg' } ],
 ['C_CJA', { icon: 'faBuilding', label: 'Cajamarca (all airports), Peru', key: 'C_CJA', type: 'city', code: 'cajamarca_pe' } ],
 ['C_CBQ', { icon: 'faBuilding', label: 'Calabar (all airports), Nigeria', key: 'C_CBQ', type: 'city', code: 'calabar_ng' } ],
 ['C_CJC', { icon: 'faBuilding', label: 'Calama (all airports), Chile', key: 'C_CJC', type: 'city', code: 'calama_cl' } ],
 ['C_CYP', { icon: 'faBuilding', label: 'Calbayog (all airports), Philippines', key: 'C_CYP', type: 'city', code: 'calbayog_ph' } ],
 ['C_CLV', { icon: 'faBuilding', label: 'Caldas Novas (all airports), Brazil', key: 'C_CLV', type: 'city', code: 'caldas-novas_go_br' } ],
 ['C_YYC', { icon: 'faBuilding', label: 'Calgary (all airports), Canada', key: 'C_YYC', type: 'city', code: 'calgary_ab_ca' } ],
 ['C_CLO', { icon: 'faBuilding', label: 'Cali (all airports), Colombia', key: 'C_CLO', type: 'city', code: 'cali_co' } ],
 ['C_CLY', { icon: 'faBuilding', label: 'Calvi, Haute-Corse (all airports), France', key: 'C_CLY', type: 'city', code: 'calvi_fr' } ],
 ['C_CMW', { icon: 'faBuilding', label: 'Camagüey (all airports), Cuba', key: 'C_CMW', type: 'city', code: 'camaguey_cu' } ],
 ['C_CBG', { icon: 'faBuilding', label: 'Cambridge (all airports), United Kingdom', key: 'C_CBG', type: 'city', code: 'cambridge_gb' } ],
 ['C_YCB', { icon: 'faBuilding', label: 'Cambridge Bay (all airports), Canada', key: 'C_YCB', type: 'city', code: 'cambridge-bay_nu_ca' } ],
 ['C_CGM', { icon: 'faBuilding', label: 'Camiguin (all airports), Philippines', key: 'C_CGM', type: 'city', code: 'camiguin_ph' } ],
 ['C_YBL', { icon: 'faBuilding', label: 'Campbell River (all airports), Canada', key: 'C_YBL', type: 'city', code: 'campbell-river_bc_ca' } ],
 ['C_CAL', { icon: 'faBuilding', label: 'Campbeltown (all airports), United Kingdom', key: 'C_CAL', type: 'city', code: 'campbeltown_gb' } ],
 ['C_CPE', { icon: 'faBuilding', label: 'Campeche (all airports), Mexico', key: 'C_CPE', type: 'city', code: 'campeche_mx' } ],
 ['C_CPV', { icon: 'faBuilding', label: 'Campina Grande (all airports), Brazil', key: 'C_CPV', type: 'city', code: 'campina-grande_pb_br' } ],
 ['C_CGR', { icon: 'faBuilding', label: 'Campo Grande (all airports), Brazil', key: 'C_CGR', type: 'city', code: 'campo-grande_ms_br' } ],
 ['C_EBA', { icon: 'faBuilding', label: 'Campo nell Elba (all airports), Italy', key: 'C_EBA', type: 'city', code: 'campo-nell-elba_it' } ],
 ['C_CAW', { icon: 'faBuilding', label: 'Campos dos Goytacazes (all airports), Brazil', key: 'C_CAW', type: 'city', code: 'campos-dos-goytacazes_rj_br' } ],
 ['C_VCA', { icon: 'faBuilding', label: 'Can Tho (all airports), Vietnam', key: 'C_VCA', type: 'city', code: 'can-tho_vn' } ],
 ['C_CKZ', { icon: 'faBuilding', label: 'Çanakkale (all airports), Turkey', key: 'C_CKZ', type: 'city', code: 'canakkale_tr' } ],
 ['C_CBR', { icon: 'faBuilding', label: 'Canberra (all airports), Australia', key: 'C_CBR', type: 'city', code: 'canberra_ac_au' } ],
 ['C_CUN', { icon: 'faBuilding', label: 'Cancún (all airports), Mexico', key: 'C_CUN', type: 'city', code: 'cancun_mx' } ],
 ['C_CWJ', { icon: 'faBuilding', label: 'Cangyuan Va Autonomous County (all airports), China', key: 'C_CWJ', type: 'city', code: 'cangyuan-va-autonomous-county_cn' } ],
 ['C_CSK', { icon: 'faBuilding', label: 'Cap Skiring (all airports), Senegal', key: 'C_CSK', type: 'city', code: 'cap-skiring_sn' } ],
 ['C_CAP', { icon: 'faBuilding', label: 'Cap-Haïtien (all airports), Haiti', key: 'C_CAP', type: 'city', code: 'cap-haitien_ht' } ],
 ['C_YTE', { icon: 'faBuilding', label: 'Cape Dorset (all airports), Canada', key: 'C_YTE', type: 'city', code: 'cape-dorset_nu_ca' } ],
 ['C_CGI', { icon: 'faBuilding', label: 'Cape Girardeau (all airports), United States', key: 'C_CGI', type: 'city', code: 'cape-girardeau_mo_us' } ],
 ['C_LUR', { icon: 'faBuilding', label: 'Cape Lisburne, Alaska (all airports), United States', key: 'C_LUR', type: 'city', code: 'cape-lisburne_ak_us' } ],
 ['C_CPT', { icon: 'faBuilding', label: 'Cape Town (all airports), South Africa', key: 'C_CPT', type: 'city', code: 'cape-town_za' } ],
 ['C_CCS', { icon: 'faBuilding', label: 'Caracas (all airports), Venezuela', key: 'C_CCS', type: 'city', code: 'caracas_ve' } ],
 ['C_CCF', { icon: 'faBuilding', label: 'Carcassonne (all airports), France', key: 'C_CCF', type: 'city', code: 'carcassonne_fr' } ],
 ['C_CWL', { icon: 'faBuilding', label: 'Cardiff (all airports), United Kingdom', key: 'C_CWL', type: 'city', code: 'cardiff_gb' } ],
 ['C_CVQ', { icon: 'faBuilding', label: 'Carnarvon (all airports), Australia', key: 'C_CVQ', type: 'city', code: 'carnarvon_wa_au' } ],
 ['C_CTG', { icon: 'faBuilding', label: 'Cartagena (all airports), Colombia', key: 'C_CTG', type: 'city', code: 'cartagena_co' } ],
 ['C_CAS', { icon: 'faBuilding', label: 'Casablanca (all airports), Morocco', key: 'C_CAS', type: 'city', code: 'casablanca_ma' } ],
 ['C_CAC', { icon: 'faBuilding', label: 'Cascavel (all airports), Brazil', key: 'C_CAC', type: 'city', code: 'cascavel_pr_br' } ],
 ['C_QTC', { icon: 'faBuilding', label: 'Caserta (all airports), Italy', key: 'C_QTC', type: 'city', code: 'caserta_it' } ],
 ['C_CPR', { icon: 'faBuilding', label: 'Casper (all airports), United States', key: 'C_CPR', type: 'city', code: 'casper_wy_us' } ],
 ['C_CDT', { icon: 'faBuilding', label: 'Castellón de la Plana (all airports), Spain', key: 'C_CDT', type: 'city', code: 'castellon-de-la-plana_es' } ],
 ['C_YCG', { icon: 'faBuilding', label: 'Castlegar (all airports), Canada', key: 'C_YCG', type: 'city', code: 'castlegar_bc_ca' } ],
 ['C_DCM', { icon: 'faBuilding', label: 'Castres (all airports), France', key: 'C_DCM', type: 'city', code: 'castres_fr' } ],
 ['C_WCA', { icon: 'faBuilding', label: 'Castro (all airports), Chile', key: 'C_WCA', type: 'city', code: 'castro_cl' } ],
 ['C_YAC', { icon: 'faBuilding', label: 'Cat Lake (all airports), Canada', key: 'C_YAC', type: 'city', code: 'cat-lake_on_ca' } ],
 ['C_CTA', { icon: 'faBuilding', label: 'Catania (all airports), Italy', key: 'C_CTA', type: 'city', code: 'catania_it' } ],
 ['C_CRM', { icon: 'faBuilding', label: 'Catarman, Northern Samar (all airports), Philippines', key: 'C_CRM', type: 'city', code: 'catarman_ph' } ],
 ['C_MPH', { icon: 'faBuilding', label: 'Caticlan (all airports), Philippines', key: 'C_MPH', type: 'city', code: 'caticlan_ph' } ],
 ['C_CBT', { icon: 'faBuilding', label: 'Catumbela (all airports), Angola', key: 'C_CBT', type: 'city', code: 'catumbela_ao' } ],
 ['C_CYZ', { icon: 'faBuilding', label: 'Cauayan, Isabela (all airports), Philippines', key: 'C_CYZ', type: 'city', code: 'cauayan_ph' } ],
 ['C_CAQ', { icon: 'faBuilding', label: 'Caucasia (all airports), Colombia', key: 'C_CAQ', type: 'city', code: 'caucasia_co' } ],
 ['C_CXJ', { icon: 'faBuilding', label: 'Caxias do Sul (all airports), Brazil', key: 'C_CXJ', type: 'city', code: 'caxias-do-sul_rs_br' } ],
 ['C_CUK', { icon: 'faBuilding', label: 'Caye Caulker (all airports), Belize', key: 'C_CUK', type: 'city', code: 'caye-caulker_bz' } ],
 ['C_CAY', { icon: 'faBuilding', label: 'Cayenne (all airports), France', key: 'C_CAY', type: 'city', code: 'cayenne_gf' } ],
 ['C_CYB', { icon: 'faBuilding', label: 'Cayman Brac (all airports), Cayman Islands', key: 'C_CYB', type: 'city', code: 'cayman-brac_ky' } ],
 ['C_CCC', { icon: 'faBuilding', label: 'Cayo Coco (all airports), Cuba', key: 'C_CCC', type: 'city', code: 'cayo-coco_cu' } ],
 ['C_CYO', { icon: 'faBuilding', label: 'Cayo Largo del Sur (all airports), Cuba', key: 'C_CYO', type: 'city', code: 'cayo-largo-del-sur_cu' } ],
 ['C_CEB', { icon: 'faBuilding', label: 'Cebu (all airports), Philippines', key: 'C_CEB', type: 'city', code: 'cebu_ph' } ],
 ['C_CDC', { icon: 'faBuilding', label: 'Cedar City (all airports), United States', key: 'C_CDC', type: 'city', code: 'cedar-city_ut_us' } ],
 ['C_CID', { icon: 'faBuilding', label: 'Cedar Rapids (all airports), United States', key: 'C_CID', type: 'city', code: 'cedar-rapids_ia_us' } ],
 ['C_CED', { icon: 'faBuilding', label: 'Ceduna (all airports), Australia', key: 'C_CED', type: 'city', code: 'ceduna_sa_au' } ],
 ['C_CEM', { icon: 'faBuilding', label: 'Central (all airports), United States', key: 'C_CEM', type: 'city', code: 'central_ak_us' } ],
 ['C_EFL', { icon: 'faBuilding', label: 'Cephalonia (all airports), Greece', key: 'C_EFL', type: 'city', code: 'cephalonia_gr' } ],
 ['C_ZBR', { icon: 'faBuilding', label: 'Chabahar (all airports), Iran', key: 'C_ZBR', type: 'city', code: 'chabahar_ir' } ],
 ['C_null', { icon: 'faBuilding', label: 'Chachapoyas (all airports), Peru', key: 'C_null', type: 'city', code: 'chachapoyas_pe' } ],
 ['C_CDR', { icon: 'faBuilding', label: 'Chadron (all airports), United States', key: 'C_CDR', type: 'city', code: 'chadron_ne_us' } ],
 ['C_CIK', { icon: 'faBuilding', label: 'Chalkyitsik (all airports), United States', key: 'C_CIK', type: 'city', code: 'chalkyitsik_ak_us' } ],
 ['C_CMF', { icon: 'faBuilding', label: 'Chambéry (all airports), France', key: 'C_CMF', type: 'city', code: 'chambery_fr' } ],
 ['C_CMI', { icon: 'faBuilding', label: 'Champaign (all airports), United States', key: 'C_CMI', type: 'city', code: 'champaign_il_us' } ],
 ['C_IXC', { icon: 'faBuilding', label: 'Chandigarh (all airports), India', key: 'C_IXC', type: 'city', code: 'chandigarh_in' } ],
 ['C_CGQ', { icon: 'faBuilding', label: 'Changchun (all airports), China', key: 'C_CGQ', type: 'city', code: 'changchun_cn' } ],
 ['C_CGD', { icon: 'faBuilding', label: 'Changde (all airports), China', key: 'C_CGD', type: 'city', code: 'changde_cn' } ],
 ['C_CSX', { icon: 'faBuilding', label: 'Changsha (all airports), China', key: 'C_CSX', type: 'city', code: 'changsha_cn' } ],
 ['C_CHX', { icon: 'faBuilding', label: 'Changuinola (all airports), Panama', key: 'C_CHX', type: 'city', code: 'changuinola_pa' } ],
 ['C_CIH', { icon: 'faBuilding', label: 'Changzhi (all airports), China', key: 'C_CIH', type: 'city', code: 'changzhi_cn' } ],
 ['C_CZX', { icon: 'faBuilding', label: 'Changzhou (all airports), China', key: 'C_CZX', type: 'city', code: 'changzhou_cn' } ],
 ['C_CHQ', { icon: 'faBuilding', label: 'Chania (all airports), Greece', key: 'C_CHQ', type: 'city', code: 'chania_gr' } ],
 ['C_CHG', { icon: 'faBuilding', label: 'Chaoyang, Liaoning (all airports), China', key: 'C_CHG', type: 'city', code: 'chaoyang_cn' } ],
 ['C_XAP', { icon: 'faBuilding', label: 'Chapecó (all airports), Brazil', key: 'C_XAP', type: 'city', code: 'chapeco_sc_br' } ],
 ['C_CHS', { icon: 'faBuilding', label: 'Charleston (all airports), United States', key: 'C_CHS', type: 'city', code: 'charleston_sc_us' } ],
 ['C_CRW', { icon: 'faBuilding', label: 'Charleston (all airports), United States', key: 'C_CRW', type: 'city', code: 'charleston_wv_us' } ],
 ['C_CTL', { icon: 'faBuilding', label: 'Charleville (all airports), Australia', key: 'C_CTL', type: 'city', code: 'charleville_ql_au' } ],
 ['C_YCL', { icon: 'faBuilding', label: 'Charlo (all airports), Canada', key: 'C_YCL', type: 'city', code: 'charlo_nb_ca' } ],
 ['C_CLT', { icon: 'faBuilding', label: 'Charlotte (all airports), United States', key: 'C_CLT', type: 'city', code: 'charlotte_nc_us' } ],
 ['C_CHO', { icon: 'faBuilding', label: 'Charlottesville (all airports), United States', key: 'C_CHO', type: 'city', code: 'charlottesville_va_us' } ],
 ['C_YYG', { icon: 'faBuilding', label: 'Charlottetown (all airports), Canada', key: 'C_YYG', type: 'city', code: 'charlottetown_pe_ca' } ],
 ['C_CHA', { icon: 'faBuilding', label: 'Chattanooga (all airports), United States', key: 'C_CHA', type: 'city', code: 'chattanooga_tn_us' } ],
 ['C_CSY', { icon: 'faBuilding', label: 'Cheboksary (all airports), Russia', key: 'C_CSY', type: 'city', code: 'cheboksary_ru' } ],
 ['C_CYF', { icon: 'faBuilding', label: 'Chefornak (all airports), United States', key: 'C_CYF', type: 'city', code: 'chefornak_ak_us' } ],
 ['C_CEK', { icon: 'faBuilding', label: 'Chelyabinsk (all airports), Russia', key: 'C_CEK', type: 'city', code: 'chelyabinsk_ru' } ],
 ['C_CDE', { icon: 'faBuilding', label: 'Chengde (all airports), China', key: 'C_CDE', type: 'city', code: 'chengde_cn' } ],
 ['C_CTU', { icon: 'faBuilding', label: 'Chengdu (all airports), China', key: 'C_CTU', type: 'city', code: 'chengdu_cn' } ],
 ['C_BPX', { icon: 'faBuilding', label: 'Chengguan, Chamdo (all airports), China', key: 'C_BPX', type: 'city', code: 'chengguan_cn' } ],
 ['C_MAA', { icon: 'faBuilding', label: 'Chennai (all airports), India', key: 'C_MAA', type: 'city', code: 'chennai_in' } ],
 ['C_CJJ', { icon: 'faBuilding', label: 'Cheongju (all airports), South Korea', key: 'C_CJJ', type: 'city', code: 'cheongju_kr' } ],
 ['C_CEE', { icon: 'faBuilding', label: 'Cherepovets (all airports), Russia', key: 'C_CEE', type: 'city', code: 'cherepovets_ru' } ],
 ['C_CWC', { icon: 'faBuilding', label: 'Chernivtsi (all airports), Ukraine', key: 'C_CWC', type: 'city', code: 'chernivtsi_ua' } ],
 ['C_CYX', { icon: 'faBuilding', label: 'Chersky (all airports), Russia', key: 'C_CYX', type: 'city', code: 'chersky_ru' } ],
 ['C_ZFI', { icon: 'faBuilding', label: 'Chesterfield (all airports), United Kingdom', key: 'C_ZFI', type: 'city', code: 'chesterfield_gb' } ],
 ['C_YCS', { icon: 'faBuilding', label: 'Chesterfield Inlet, Nunavut (all airports), Canada', key: 'C_YCS', type: 'city', code: 'chesterfield-inlet_nu_ca' } ],
 ['C_CTM', { icon: 'faBuilding', label: 'Chetumal (all airports), Mexico', key: 'C_CTM', type: 'city', code: 'chetumal_mx' } ],
 ['C_VAK', { icon: 'faBuilding', label: 'Chevak (all airports), United States', key: 'C_VAK', type: 'city', code: 'chevak_ak_us' } ],
 ['C_YHR', { icon: 'faBuilding', label: 'Chevery, Quebec (all airports), Canada', key: 'C_YHR', type: 'city', code: 'chevery_qc_ca' } ],
 ['C_CYS', { icon: 'faBuilding', label: 'Cheyenne (all airports), United States', key: 'C_CYS', type: 'city', code: 'cheyenne_wy_us' } ],
 ['C_CNX', { icon: 'faBuilding', label: 'Chiang Mai (all airports), Thailand', key: 'C_CNX', type: 'city', code: 'chiang-mai_th' } ],
 ['C_CEI', { icon: 'faBuilding', label: 'Chiang Rai Province (all airports), Thailand', key: 'C_CEI', type: 'city', code: 'chiang-rai-province_th' } ],
 ['C_CYI', { icon: 'faBuilding', label: 'Chiayi (all airports), Taiwan', key: 'C_CYI', type: 'city', code: 'chiayi-county_tw' } ],
 ['C_YMT', { icon: 'faBuilding', label: 'Chibougamau (all airports), Canada', key: 'C_YMT', type: 'city', code: 'chibougamau_qc_ca' } ],
 ['C_CHI', { icon: 'faBuilding', label: 'Chicago (all airports), United States', key: 'C_CHI', type: 'city', code: 'chicago_il_us' } ],
 ['C_CIX', { icon: 'faBuilding', label: 'Chiclayo (all airports), Peru', key: 'C_CIX', type: 'city', code: 'chiclayo_pe' } ],
 ['C_CIF', { icon: 'faBuilding', label: 'Chifeng (all airports), China', key: 'C_CIF', type: 'city', code: 'chifeng_cn' } ],
 ['C_KCL', { icon: 'faBuilding', label: 'Chignik (all airports), United States', key: 'C_KCL', type: 'city', code: 'chignik_ak_us' } ],
 ['C_CUU', { icon: 'faBuilding', label: 'Chihuahua (all airports), Mexico', key: 'C_CUU', type: 'city', code: 'chihuahua_mx' } ],
 ['C_VPY', { icon: 'faBuilding', label: 'Chimoio (all airports), Mozambique', key: 'C_VPY', type: 'city', code: 'chimoio_mz' } ],
 ['C_CCA', { icon: 'faBuilding', label: 'Chimoré, Cochabamba (all airports), Bolivia', key: 'C_CCA', type: 'city', code: 'chimoré,-cochabamba_bo' } ],
 ['C_JKH', { icon: 'faBuilding', label: 'Chios (all airports), Greece', key: 'C_JKH', type: 'city', code: 'chios_gr' } ],
 ['C_YKU', { icon: 'faBuilding', label: 'Chisasibi (all airports), Canada', key: 'C_YKU', type: 'city', code: 'chisasibi_qc_ca' } ],
 ['C_KIV', { icon: 'faBuilding', label: 'Chișinău (all airports), Moldova', key: 'C_KIV', type: 'city', code: 'chisinau_md' } ],
 ['C_HTA', { icon: 'faBuilding', label: 'Chita (all airports), Russia', key: 'C_HTA', type: 'city', code: 'chita_ru' } ],
 ['C_CJL', { icon: 'faBuilding', label: 'Chitral (all airports), Pakistan', key: 'C_CJL', type: 'city', code: 'chitral_pk' } ],
 ['C_CTD', { icon: 'faBuilding', label: 'Chitré (all airports), Panama', key: 'C_CTD', type: 'city', code: 'chitre_pa' } ],
 ['C_CGP', { icon: 'faBuilding', label: 'Chittagong (all airports), Bangladesh', key: 'C_CGP', type: 'city', code: 'chittagong_bd' } ],
 ['C_ZAK', { icon: 'faBuilding', label: 'Chiusa/Klausen (all airports), Italy', key: 'C_ZAK', type: 'city', code: 'chiusa-klausen_it' } ],
 ['C_JUH', { icon: 'faBuilding', label: 'Chizhou (all airports), China', key: 'C_JUH', type: 'city', code: 'chizhou_cn' } ],
 ['C_CFK', { icon: 'faBuilding', label: 'Chlef (all airports), Algeria', key: 'C_CFK', type: 'city', code: 'chlef_dz' } ],
 ['C_COQ', { icon: 'faBuilding', label: 'Choibalsan (all airports), Mongolia', key: 'C_COQ', type: 'city', code: 'choibalsan_mn' } ],
 ['C_CHY', { icon: 'faBuilding', label: 'Choiseul Bay (all airports), Solomon Islands', key: 'C_CHY', type: 'city', code: 'choiseul-bay_sb' } ],
 ['C_CKH', { icon: 'faBuilding', label: 'Chokurdakh (all airports), Russia', key: 'C_CKH', type: 'city', code: 'chokurdakh_ru' } ],
 ['C_CKG', { icon: 'faBuilding', label: 'Chongqing (all airports), China', key: 'C_CKG', type: 'city', code: 'chongqing_cn' } ],
 ['C_CHC', { icon: 'faBuilding', label: 'Christchurch (all airports), New Zealand', key: 'C_CHC', type: 'city', code: 'christchurch_nz' } ],
 ['C_VCL', { icon: 'faBuilding', label: 'Chu Lai (all airports), Vietnam', key: 'C_VCL', type: 'city', code: 'chu-lai_vn' } ],
 ['C_CHU', { icon: 'faBuilding', label: 'Chuathbaluk (all airports), United States', key: 'C_CHU', type: 'city', code: 'chuathbaluk_ak_us' } ],
 ['C_CJM', { icon: 'faBuilding', label: 'Chumphon Province (all airports), Thailand', key: 'C_CJM', type: 'city', code: 'chumphon-province_th' } ],
 ['C_YYQ', { icon: 'faBuilding', label: 'Churchill (all airports), Canada', key: 'C_YYQ', type: 'city', code: 'churchill_mb_ca' } ],
 ['C_ZUM', { icon: 'faBuilding', label: 'Churchill Falls (all airports), Canada', key: 'C_ZUM', type: 'city', code: 'churchill-falls_nl_ca' } ],
 ['C_TKK', { icon: 'faBuilding', label: 'Chuuk State (all airports), Micronesia', key: 'C_TKK', type: 'city', code: 'chuuk-state_fm' } ],
 ['C_ICI', { icon: 'faBuilding', label: 'Cicia (all airports), Fiji', key: 'C_ICI', type: 'city', code: 'cicia_fj' } ],
 ['C_CFG', { icon: 'faBuilding', label: 'Cienfuegos (all airports), Cuba', key: 'C_CFG', type: 'city', code: 'cienfuegos_cu' } ],
 ['C_CVG', { icon: 'faBuilding', label: 'Cincinnati (all airports), United States', key: 'C_CVG', type: 'city', code: 'cincinnati_oh_us' } ],
 ['C_IRC', { icon: 'faBuilding', label: 'Circle (all airports), United States', key: 'C_IRC', type: 'city', code: 'circle_ak_us' } ],
 ['C_BRU', { icon: 'faBuilding', label: 'City of Brussels (all airports), Belgium', key: 'C_BRU', type: 'city', code: 'brussels_be' } ],
 ['C_LST', { icon: 'faBuilding', label: 'City of Launceston (all airports), Australia', key: 'C_LST', type: 'city', code: 'city-of-launceston_ts_au' } ],
 ['C_NTL', { icon: 'faBuilding', label: 'City of Newcastle (all airports), Australia', key: 'C_NTL', type: 'city', code: 'city-of-newcastle_ns_au' } ],
 ['C_WOL', { icon: 'faBuilding', label: 'City of Wollongong (all airports), Australia', key: 'C_WOL', type: 'city', code: 'city-of-wollongong_ns_au' } ],
 ['C_CME', { icon: 'faBuilding', label: 'Ciudad del Carmen (all airports), Mexico', key: 'C_CME', type: 'city', code: 'ciudad-del-carmen_mx' } ],
 ['C_AGT', { icon: 'faBuilding', label: 'Ciudad del Este (all airports), Paraguay', key: 'C_AGT', type: 'city', code: 'ciudad-del-este_py' } ],
 ['C_CJS', { icon: 'faBuilding', label: 'Ciudad Juárez (all airports), Mexico', key: 'C_CJS', type: 'city', code: 'ciudad-juarez_mx' } ],
 ['C_CEN', { icon: 'faBuilding', label: 'Ciudad Obregón (all airports), Mexico', key: 'C_CEN', type: 'city', code: 'ciudad-obregon_mx' } ],
 ['C_CQM', { icon: 'faBuilding', label: 'Ciudad Real (all airports), Spain', key: 'C_CQM', type: 'city', code: 'ciudad-real_es' } ],
 ['C_CVM', { icon: 'faBuilding', label: 'Ciudad Victoria (all airports), Mexico', key: 'C_CVM', type: 'city', code: 'ciudad-victoria_mx' } ],
 ['C_CLP', { icon: 'faBuilding', label: 'Clark\'s Point (all airports), United States', key: 'C_CLP', type: 'city', code: 'clarks-point_ak_us' } ],
 ['C_CKB', { icon: 'faBuilding', label: 'Clarksburg (all airports), United States', key: 'C_CKB', type: 'city', code: 'clarksburg_wv_us' } ],
 ['C_CFE', { icon: 'faBuilding', label: 'Clermont-Ferrand (all airports), France', key: 'C_CFE', type: 'city', code: 'clermont-ferrand_fr' } ],
 ['C_CLE', { icon: 'faBuilding', label: 'Cleveland (all airports), United States', key: 'C_CLE', type: 'city', code: 'cleveland_oh_us' } ],
 ['C_CNJ', { icon: 'faBuilding', label: 'Cloncurry (all airports), Australia', key: 'C_CNJ', type: 'city', code: 'cloncurry_ql_au' } ],
 ['C_CLJ', { icon: 'faBuilding', label: 'Cluj-Napoca (all airports), Romania', key: 'C_CLJ', type: 'city', code: 'cluj-napoca_ro' } ],
 ['C_YCY', { icon: 'faBuilding', label: 'Clyde River, Nunavut (all airports), Canada', key: 'C_YCY', type: 'city', code: 'clyde-river_nu_ca' } ],
 ['C_CAZ', { icon: 'faBuilding', label: 'Cobar (all airports), Australia', key: 'C_CAZ', type: 'city', code: 'cobar_ns_au' } ],
 ['C_CIJ', { icon: 'faBuilding', label: 'Cobija (all airports), Bolivia', key: 'C_CIJ', type: 'city', code: 'cobija_bo' } ],
 ['C_CBB', { icon: 'faBuilding', label: 'Cochabamba (all airports), Bolivia', key: 'C_CBB', type: 'city', code: 'cochabamba_bo' } ],
 ['C_GDT', { icon: 'faBuilding', label: 'Cockburn Town (all airports), Turks & Caicos Islands', key: 'C_GDT', type: 'city', code: 'cockburn-town_tc' } ],
 ['C_COD', { icon: 'faBuilding', label: 'Cody (all airports), United States', key: 'C_COD', type: 'city', code: 'cody_wy_us' } ],
 ['C_KCC', { icon: 'faBuilding', label: 'Coffman Cove (all airports), United States', key: 'C_KCC', type: 'city', code: 'coffman-cove_ak_us' } ],
 ['C_CFS', { icon: 'faBuilding', label: 'Coffs Harbour (all airports), Australia', key: 'C_CFS', type: 'city', code: 'coffs-harbour_ns_au' } ],
 ['C_CJB', { icon: 'faBuilding', label: 'Coimbatore (all airports), India', key: 'C_CJB', type: 'city', code: 'coimbatore_in' } ],
 ['C_CDB', { icon: 'faBuilding', label: 'Cold Bay (all airports), United States', key: 'C_CDB', type: 'city', code: 'cold-bay_ak_us' } ],
 ['C_CXF', { icon: 'faBuilding', label: 'Coldfoot (all airports), United States', key: 'C_CXF', type: 'city', code: 'coldfoot_ak_us' } ],
 ['C_CLQ', { icon: 'faBuilding', label: 'Colima (all airports), Mexico', key: 'C_CLQ', type: 'city', code: 'colima_mx' } ],
 ['C_CLL', { icon: 'faBuilding', label: 'College Station (all airports), United States', key: 'C_CLL', type: 'city', code: 'college-station_tx_us' } ],
 ['C_CGN', { icon: 'faBuilding', label: 'Cologne (all airports), Germany', key: 'C_CGN', type: 'city', code: 'cologne_de' } ],
 ['C_CMB', { icon: 'faBuilding', label: 'Colombo (all airports), Sri Lanka', key: 'C_CMB', type: 'city', code: 'colombo_lk' } ],
 ['C_COS', { icon: 'faBuilding', label: 'Colorado Springs (all airports), United States', key: 'C_COS', type: 'city', code: 'colorado-springs_co_us' } ],
 ['C_CAE', { icon: 'faBuilding', label: 'Columbia (all airports), United States', key: 'C_CAE', type: 'city', code: 'columbia_sc_us' } ],
 ['C_COU', { icon: 'faBuilding', label: 'Columbia (all airports), United States', key: 'C_COU', type: 'city', code: 'columbia_mo_us' } ],
 ['C_CSG', { icon: 'faBuilding', label: 'Columbus (all airports), United States', key: 'C_CSG', type: 'city', code: 'columbus_ga_us' } ],
 ['C_UBS', { icon: 'faBuilding', label: 'Columbus (all airports), United States', key: 'C_UBS', type: 'city', code: 'columbus_ms_us' } ],
 ['C_CMH', { icon: 'faBuilding', label: 'Columbus (all airports), United States', key: 'C_CMH', type: 'city', code: 'columbus_oh_us' } ],
 ['C_YCK', { icon: 'faBuilding', label: 'Colville Lake (all airports), Canada', key: 'C_YCK', type: 'city', code: 'colville-lake_nt_ca' } ],
 ['C_CIY', { icon: 'faBuilding', label: 'Comiso (all airports), Italy', key: 'C_CIY', type: 'city', code: 'comiso_it' } ],
 ['C_CRD', { icon: 'faBuilding', label: 'Comodoro Rivadavia (all airports), Argentina', key: 'C_CRD', type: 'city', code: 'comodoro-rivadavia_cb_ar' } ],
 ['C_YQQ', { icon: 'faBuilding', label: 'Comox (all airports), Canada', key: 'C_YQQ', type: 'city', code: 'comox_bc_ca' } ],
 ['C_VCS', { icon: 'faBuilding', label: 'Côn Sơn Island (all airports), Vietnam', key: 'C_VCS', type: 'city', code: 'con-son-island_vn' } ],
 ['C_CKY', { icon: 'faBuilding', label: 'Conakry (all airports), Guinea', key: 'C_CKY', type: 'city', code: 'conakry_gn' } ],
 ['C_CCP', { icon: 'faBuilding', label: 'Concepción (all airports), Chile', key: 'C_CCP', type: 'city', code: 'concepcion_cl' } ],
 ['C_CCR', { icon: 'faBuilding', label: 'Concord (all airports), United States', key: 'C_CCR', type: 'city', code: 'concord_ca_us' } ],
 ['C_USA', { icon: 'faBuilding', label: 'Concord (all airports), United States', key: 'C_USA', type: 'city', code: 'concord_nc_us' } ],
 ['C_COG', { icon: 'faBuilding', label: 'Condoto (all airports), Colombia', key: 'C_COG', type: 'city', code: 'condoto_co' } ],
 ['C_CND', { icon: 'faBuilding', label: 'Constanța (all airports), Romania', key: 'C_CND', type: 'city', code: 'constanta_ro' } ],
 ['C_CZL', { icon: 'faBuilding', label: 'Constantine (all airports), Algeria', key: 'C_CZL', type: 'city', code: 'constantine_dz' } ],
 ['C_OTD', { icon: 'faBuilding', label: 'Contadora Island (all airports), Panama', key: 'C_OTD', type: 'city', code: 'contadora-island_pa' } ],
 ['C_CPD', { icon: 'faBuilding', label: 'Coober Pedy (all airports), Australia', key: 'C_CPD', type: 'city', code: 'coober-pedy_sa_au' } ],
 ['C_CTN', { icon: 'faBuilding', label: 'Cooktown (all airports), Australia', key: 'C_CTN', type: 'city', code: 'cooktown_ql_au' } ],
 ['C_OOM', { icon: 'faBuilding', label: 'Cooma (all airports), Australia', key: 'C_OOM', type: 'city', code: 'cooma_ns_au' } ],
 ['C_CJF', { icon: 'faBuilding', label: 'Coondewanna (all airports), Australia', key: 'C_CJF', type: 'city', code: 'coondewanna_wa_au' } ],
 ['C_CPH', { icon: 'faBuilding', label: 'Copenhagen (all airports), Denmark', key: 'C_CPH', type: 'city', code: 'copenhagen_dk' } ],
 ['C_CPO', { icon: 'faBuilding', label: 'Copiapó (all airports), Chile', key: 'C_CPO', type: 'city', code: 'copiapo_cl' } ],
 ['C_YZS', { icon: 'faBuilding', label: 'Coral Harbour (all airports), Canada', key: 'C_YZS', type: 'city', code: 'coral-harbour_nu_ca' } ],
 ['C_ODB', { icon: 'faBuilding', label: 'Cordoba (all airports), Spain', key: 'C_ODB', type: 'city', code: 'cordoba_es' } ],
 ['C_COR', { icon: 'faBuilding', label: 'Córdoba (all airports), Argentina', key: 'C_COR', type: 'city', code: 'cordoba_cd_ar' } ],
 ['C_CDV', { icon: 'faBuilding', label: 'Cordova (all airports), United States', key: 'C_CDV', type: 'city', code: 'cordova_ak_us' } ],
 ['C_CFU', { icon: 'faBuilding', label: 'Corfu (all airports), Greece', key: 'C_CFU', type: 'city', code: 'corfu_gr' } ],
 ['C_ORK', { icon: 'faBuilding', label: 'Cork (all airports), Ireland', key: 'C_ORK', type: 'city', code: 'cork_ie' } ],
 ['C_CZE', { icon: 'faBuilding', label: 'Coro (all airports), Venezuela', key: 'C_CZE', type: 'city', code: 'coro_ve' } ],
 ['C_CZH', { icon: 'faBuilding', label: 'Corozal Town (all airports), Belize', key: 'C_CZH', type: 'city', code: 'corozal-town_bz' } ],
 ['C_CZU', { icon: 'faBuilding', label: 'Corozal, Sucre (all airports), Colombia', key: 'C_CZU', type: 'city', code: 'corozal_co' } ],
 ['C_CRP', { icon: 'faBuilding', label: 'Corpus Christi (all airports), United States', key: 'C_CRP', type: 'city', code: 'corpus-christi_tx_us' } ],
 ['C_CNQ', { icon: 'faBuilding', label: 'Corrientes (all airports), Argentina', key: 'C_CNQ', type: 'city', code: 'corrientes_cr_ar' } ],
 ['C_CEZ', { icon: 'faBuilding', label: 'Cortez (all airports), United States', key: 'C_CEZ', type: 'city', code: 'cortez_co_us' } ],
 ['C_CMG', { icon: 'faBuilding', label: 'Corumbá (all airports), Brazil', key: 'C_CMG', type: 'city', code: 'corumba_ms_br' } ],
 ['C_CVU', { icon: 'faBuilding', label: 'Corvo Island (all airports), Portugal', key: 'C_CVU', type: 'city', code: 'corvo-island_pt' } ],
 ['C_CBO', { icon: 'faBuilding', label: 'Cotabato City (all airports), Philippines', key: 'C_CBO', type: 'city', code: 'cotabato-city_ph' } ],
 ['C_COO', { icon: 'faBuilding', label: 'Cotonou (all airports), Benin', key: 'C_COO', type: 'city', code: 'cotonou_bj' } ],
 ['C_KIR', { icon: 'faBuilding', label: 'County Kerry (all airports), Ireland', key: 'C_KIR', type: 'city', code: 'county-kerry_ie' } ],
 ['C_CVT', { icon: 'faBuilding', label: 'Coventry (all airports), United Kingdom', key: 'C_CVT', type: 'city', code: 'coventry_gb' } ],
 ['C_CXB', { icon: 'faBuilding', label: 'Cox\'s Bazar (all airports), Bangladesh', key: 'C_CXB', type: 'city', code: 'coxs-bazar_bd' } ],
 ['C_RTB', { icon: 'faBuilding', label: 'Coxen Hole (all airports), Honduras', key: 'C_RTB', type: 'city', code: 'coxen-hole_hn' } ],
 ['C_CZM', { icon: 'faBuilding', label: 'Cozumel (all airports), Mexico', key: 'C_CZM', type: 'city', code: 'cozumel_mx' } ],
 ['C_CGA', { icon: 'faBuilding', label: 'Craig (all airports), United States', key: 'C_CGA', type: 'city', code: 'craig_ak_us' } ],
 ['C_CCV', { icon: 'faBuilding', label: 'Craig Cove (all airports), Vanuatu', key: 'C_CCV', type: 'city', code: 'craig-cove_vu' } ],
 ['C_CRA', { icon: 'faBuilding', label: 'Craiova (all airports), Romania', key: 'C_CRA', type: 'city', code: 'craiova_ro' } ],
 ['C_YXC', { icon: 'faBuilding', label: 'Cranbrook (all airports), Canada', key: 'C_YXC', type: 'city', code: 'cranbrook_bc_ca' } ],
 ['C_CEC', { icon: 'faBuilding', label: 'Crescent City (all airports), United States', key: 'C_CEC', type: 'city', code: 'crescent-city_ca_us' } ],
 ['C_CCM', { icon: 'faBuilding', label: 'Criciúma (all airports), Brazil', key: 'C_CCM', type: 'city', code: 'criciuma_sc_br' } ],
 ['C_CKD', { icon: 'faBuilding', label: 'Crooked Creek (all airports), United States', key: 'C_CKD', type: 'city', code: 'crooked-creek_ak_us' } ],
 ['C_CRI', { icon: 'faBuilding', label: 'Crooked Island (all airports), Bahamas', key: 'C_CRI', type: 'city', code: 'crooked-island_bs' } ],
 ['C_YCR', { icon: 'faBuilding', label: 'Cross Lake, Manitoba (all airports), Canada', key: 'C_YCR', type: 'city', code: 'cross-lake_mb_ca' } ],
 ['C_CRV', { icon: 'faBuilding', label: 'Crotone (all airports), Italy', key: 'C_CRV', type: 'city', code: 'crotone_it' } ],
 ['C_JJD', { icon: 'faBuilding', label: 'Cruz, Ceará (all airports), Brazil', key: 'C_JJD', type: 'city', code: 'cruz--ceara_ce_br' } ],
 ['C_CZS', { icon: 'faBuilding', label: 'Cruzeiro do Sul (all airports), Brazil', key: 'C_CZS', type: 'city', code: 'cruzeiro-do-sul_ac_br' } ],
 ['C_CUC', { icon: 'faBuilding', label: 'Cúcuta (all airports), Colombia', key: 'C_CUC', type: 'city', code: 'cucuta_co' } ],
 ['C_CUE', { icon: 'faBuilding', label: 'Cuenca (all airports), Ecuador', key: 'C_CUE', type: 'city', code: 'cuenca_ec' } ],
 ['C_CGB', { icon: 'faBuilding', label: 'Cuiabá (all airports), Brazil', key: 'C_CGB', type: 'city', code: 'cuiaba_mt_br' } ],
 ['C_CPX', { icon: 'faBuilding', label: 'Culebra (all airports), United States', key: 'C_CPX', type: 'city', code: 'culebra_pr_pr' } ],
 ['C_CUL', { icon: 'faBuilding', label: 'Culiacán (all airports), Mexico', key: 'C_CUL', type: 'city', code: 'culiacan_mx' } ],
 ['C_CUM', { icon: 'faBuilding', label: 'Cumaná (all airports), Venezuela', key: 'C_CUM', type: 'city', code: 'cumana_ve' } ],
 ['C_CUF', { icon: 'faBuilding', label: 'Cuneo (all airports), Italy', key: 'C_CUF', type: 'city', code: 'cuneo_it' } ],
 ['C_CMA', { icon: 'faBuilding', label: 'Cunnamulla (all airports), Australia', key: 'C_CMA', type: 'city', code: 'cunnamulla_ql_au' } ],
 ['C_CWB', { icon: 'faBuilding', label: 'Curitiba (all airports), Brazil', key: 'C_CWB', type: 'city', code: 'curitiba_pr_br' } ],
 ['C_CUZ', { icon: 'faBuilding', label: 'Cusco (all airports), Peru', key: 'C_CUZ', type: 'city', code: 'cusco_pe' } ],
 ['C_KME', { icon: 'faBuilding', label: 'Cyangugu (all airports), Rwanda', key: 'C_KME', type: 'city', code: 'kamembe_rw' } ],
 ['C_DLI', { icon: 'faBuilding', label: 'Da Lat (all airports), Vietnam', key: 'C_DLI', type: 'city', code: 'da-lat_vn' } ],
 ['C_DAD', { icon: 'faBuilding', label: 'Da Nang (all airports), Vietnam', key: 'C_DAD', type: 'city', code: 'danang_vn' } ],
 ['C_TAE', { icon: 'faBuilding', label: 'Daegu (all airports), South Korea', key: 'C_TAE', type: 'city', code: 'daegu_kr' } ],
 ['C_DKR', { icon: 'faBuilding', label: 'Dakar (all airports), Senegal', key: 'C_DKR', type: 'city', code: 'dakar_sn' } ],
 ['C_VIL', { icon: 'faBuilding', label: 'Dakhla (all airports), Unknown Region', key: 'C_VIL', type: 'city', code: 'dakhla_zz' } ],
 ['C_DLM', { icon: 'faBuilding', label: 'Dalaman (all airports), Turkey', key: 'C_DLM', type: 'city', code: 'dalaman_tr' } ],
 ['C_DLZ', { icon: 'faBuilding', label: 'Dalanzadgad (all airports), Mongolia', key: 'C_DLZ', type: 'city', code: 'dalanzadgad_mn' } ],
 ['C_DBA', { icon: 'faBuilding', label: 'Dalbandin (all airports), Pakistan', key: 'C_DBA', type: 'city', code: 'dalbandin_pk' } ],
 ['C_DLU', { icon: 'faBuilding', label: 'Dali (all airports), China', key: 'C_DLU', type: 'city', code: 'dali_cn' } ],
 ['C_DLC', { icon: 'faBuilding', label: 'Dalian (all airports), China', key: 'C_DLC', type: 'city', code: 'dalian_cn' } ],
 ['C_DFW', { icon: 'faBuilding', label: 'Dallas (all airports), United States', key: 'C_DFW', type: 'city', code: 'dallas_tx_us' } ],
 ['C_DAM', { icon: 'faBuilding', label: 'Damascus (all airports), Syria', key: 'C_DAM', type: 'city', code: 'damascus_sy' } ],
 ['C_DMM', { icon: 'faBuilding', label: 'Dammam (all airports), Saudi Arabia', key: 'C_DMM', type: 'city', code: 'dammam_sa' } ],
 ['C_DDG', { icon: 'faBuilding', label: 'Dandong (all airports), China', key: 'C_DDG', type: 'city', code: 'dandong_cn' } ],
 ['C_DGA', { icon: 'faBuilding', label: 'Dangriga (all airports), Belize', key: 'C_DGA', type: 'city', code: 'dangriga_bz' } ],
 ['C_DCY', { icon: 'faBuilding', label: 'Daocheng County (all airports), China', key: 'C_DCY', type: 'city', code: 'daocheng-county_cn' } ],
 ['C_DQA', { icon: 'faBuilding', label: 'Daqing (all airports), China', key: 'C_DQA', type: 'city', code: 'daqing_cn' } ],
 ['C_DAR', { icon: 'faBuilding', label: 'Dar es Salaam (all airports), Tanzania', key: 'C_DAR', type: 'city', code: 'dar-es-salaam_tz' } ],
 ['C_DAU', { icon: 'faBuilding', label: 'Daru (all airports), Papua New Guinea', key: 'C_DAU', type: 'city', code: 'daru_pg' } ],
 ['C_DRW', { icon: 'faBuilding', label: 'Darwin (all airports), Australia', key: 'C_DRW', type: 'city', code: 'darwin_nt_au' } ],
 ['C_TAZ', { icon: 'faBuilding', label: 'Daşoguz (all airports), Turkmenistan', key: 'C_TAZ', type: 'city', code: 'dasoguz_tm' } ],
 ['C_DAT', { icon: 'faBuilding', label: 'Datong (all airports), China', key: 'C_DAT', type: 'city', code: 'datong_cn' } ],
 ['C_DVO', { icon: 'faBuilding', label: 'Davao (all airports), Philippines', key: 'C_DVO', type: 'city', code: 'davao_ph' } ],
 ['C_DAV', { icon: 'faBuilding', label: 'David, Chiriquí (all airports), Panama', key: 'C_DAV', type: 'city', code: 'david_pa' } ],
 ['C_DWD', { icon: 'faBuilding', label: 'Dawadmi (all airports), Saudi Arabia', key: 'C_DWD', type: 'city', code: 'dawadmi_sa' } ],
 ['C_TVY', { icon: 'faBuilding', label: 'Dawei Township (all airports), Myanmar (Burma)', key: 'C_TVY', type: 'city', code: 'dawei-township_mm' } ],
 ['C_YDA', { icon: 'faBuilding', label: 'Dawson City (all airports), Canada', key: 'C_YDA', type: 'city', code: 'dawson-city_yt_ca' } ],
 ['C_YDQ', { icon: 'faBuilding', label: 'Dawson Creek (all airports), Canada', key: 'C_YDQ', type: 'city', code: 'dawson-creek_bc_ca' } ],
 ['C_DAY', { icon: 'faBuilding', label: 'Dayton (all airports), United States', key: 'C_DAY', type: 'city', code: 'dayton_oh_us' } ],
 ['C_DAB', { icon: 'faBuilding', label: 'Daytona Beach (all airports), United States', key: 'C_DAB', type: 'city', code: 'daytona-beach_fl_us' } ],
 ['C_DAX', { icon: 'faBuilding', label: 'Dazhou (all airports), China', key: 'C_DAX', type: 'city', code: 'dazhou_cn' } ],
 ['C_LGI', { icon: 'faBuilding', label: 'Deadman\'s Cay Settlement (all airports), Bahamas', key: 'C_LGI', type: 'city', code: 'upper-channel-cay_bs' } ],
 ['C_DOL', { icon: 'faBuilding', label: 'Deauville (all airports), France', key: 'C_DOL', type: 'city', code: 'deauville_fr' } ],
 ['C_DEB', { icon: 'faBuilding', label: 'Debrecen (all airports), Hungary', key: 'C_DEB', type: 'city', code: 'debrecen_hu' } ],
 ['C_DEC', { icon: 'faBuilding', label: 'Decatur (all airports), United States', key: 'C_DEC', type: 'city', code: 'decatur_il_us' } ],
 ['C_YDF', { icon: 'faBuilding', label: 'Deer Lake (all airports), Canada', key: 'C_YDF', type: 'city', code: 'deer-lake_nl_ca' } ],
 ['C_YVZ', { icon: 'faBuilding', label: 'Deer Lake (all airports), Canada', key: 'C_YVZ', type: 'city', code: 'deer-lake_on_ca' } ],
 ['C_DRG', { icon: 'faBuilding', label: 'Deering (all airports), United States', key: 'C_DRG', type: 'city', code: 'deering_ak_us' } ],
 ['C_DED', { icon: 'faBuilding', label: 'Dehradun (all airports), India', key: 'C_DED', type: 'city', code: 'dehradun_in' } ],
 ['C_IAO', { icon: 'faBuilding', label: 'Del Carmen (all airports), Philippines', key: 'C_IAO', type: 'city', code: 'del-carmen_ph' } ],
 ['C_DEM', { icon: 'faBuilding', label: 'Dembidolo (all airports), Ethiopia', key: 'C_DEM', type: 'city', code: 'dembidolo_et' } ],
 ['C_DNZ', { icon: 'faBuilding', label: 'Denizli (all airports), Turkey', key: 'C_DNZ', type: 'city', code: 'denizli_tr' } ],
 ['C_DPS', { icon: 'faBuilding', label: 'Bali Denpasar (all airports), Indonesia', key: 'C_DPS', type: 'city', code: 'denpasar_id' } ],
 ['C_DEN', { icon: 'faBuilding', label: 'Denver (all airports), United States', key: 'C_DEN', type: 'city', code: 'denver_co_us' } ],
 ['C_DPT', { icon: 'faBuilding', label: 'Deputatsky, Sakha Republic (all airports), Russia', key: 'C_DPT', type: 'city', code: 'deputatsky_ru' } ],
 ['C_DEA', { icon: 'faBuilding', label: 'Dera Ghazi Khan (all airports), Pakistan', key: 'C_DEA', type: 'city', code: 'dera-ghazi-khan_pk' } ],
 ['C_LDY', { icon: 'faBuilding', label: 'Derry (all airports), United Kingdom', key: 'C_LDY', type: 'city', code: 'derry_gb' } ],
 ['C_DSM', { icon: 'faBuilding', label: 'Des Moines (all airports), United States', key: 'C_DSM', type: 'city', code: 'des-moines_ia_us' } ],
 ['C_DSE', { icon: 'faBuilding', label: 'Dessie (all airports), Ethiopia', key: 'C_DSE', type: 'city', code: 'dessie_et' } ],
 ['C_VPS', { icon: 'faBuilding', label: 'Destin (all airports), United States', key: 'C_VPS', type: 'city', code: 'valparaiso_fl_us' } ],
 ['C_DTT', { icon: 'faBuilding', label: 'Detroit (all airports), United States', key: 'C_DTT', type: 'city', code: 'detroit_mi_us' } ],
 ['C_DVL', { icon: 'faBuilding', label: 'Devils Lake (all airports), United States', key: 'C_DVL', type: 'city', code: 'devils-lake_nd_us' } ],
 ['C_DPO', { icon: 'faBuilding', label: 'Devonport (all airports), Australia', key: 'C_DPO', type: 'city', code: 'devonport_ts_au' } ],
 ['C_ZEQ', { icon: 'faBuilding', label: 'Dewsbury (all airports), United Kingdom', key: 'C_ZEQ', type: 'city', code: 'dewsbury_gb' } ],
 ['C_DAC', { icon: 'faBuilding', label: 'Dhaka (all airports), Bangladesh', key: 'C_DAC', type: 'city', code: 'dhaka_bd' } ],
 ['C_DHI', { icon: 'faBuilding', label: 'Dhangadhi (all airports), Nepal', key: 'C_DHI', type: 'city', code: 'dhangadhi_np' } ],
 ['C_DHM', { icon: 'faBuilding', label: 'Dharamshala (all airports), India', key: 'C_DHM', type: 'city', code: 'dharamsala_in' } ],
 ['C_DRV', { icon: 'faBuilding', label: 'Dharavandhoo (all airports), Maldives', key: 'C_DRV', type: 'city', code: 'dharavandhoo_mv' } ],
 ['C_DIB', { icon: 'faBuilding', label: 'Dibrugarh (all airports), India', key: 'C_DIB', type: 'city', code: 'dibrugarh_in' } ],
 ['C_DIK', { icon: 'faBuilding', label: 'Dickinson (all airports), United States', key: 'C_DIK', type: 'city', code: 'dickinson_nd_us' } ],
 ['C_DIW', { icon: 'faBuilding', label: 'Dickwella (all airports), Sri Lanka', key: 'C_DIW', type: 'city', code: 'dickwella_lk' } ],
 ['C_XPW', { icon: 'faBuilding', label: 'Didcot (all airports), United Kingdom', key: 'C_XPW', type: 'city', code: 'didcot_gb' } ],
 ['C_DIN', { icon: 'faBuilding', label: 'Điện Biên Phủ (all airports), Vietnam', key: 'C_DIN', type: 'city', code: 'dien-bien-phu_vn' } ],
 ['C_DKS', { icon: 'faBuilding', label: 'Dikson (all airports), Russia', key: 'C_DKS', type: 'city', code: 'dikson_ru' } ],
 ['C_DIL', { icon: 'faBuilding', label: 'Dili (all airports), Timor-Leste', key: 'C_DIL', type: 'city', code: 'dili_tl' } ],
 ['C_DLG', { icon: 'faBuilding', label: 'Dillingham (all airports), United States', key: 'C_DLG', type: 'city', code: 'dillingham_ak_us' } ],
 ['C_DLY', { icon: 'faBuilding', label: 'Dillons Bay (all airports), Vanuatu', key: 'C_DLY', type: 'city', code: 'dillons-bay_vu' } ],
 ['C_DMU', { icon: 'faBuilding', label: 'Dimapur (all airports), India', key: 'C_DMU', type: 'city', code: 'dimapur_in' } ],
 ['C_DNR', { icon: 'faBuilding', label: 'Dinard (all airports), France', key: 'C_DNR', type: 'city', code: 'dinard_fr' } ],
 ['C_SIS', { icon: 'faBuilding', label: 'Dingleton (all airports), South Africa', key: 'C_SIS', type: 'city', code: 'dingleton_za' } ],
 ['C_DPL', { icon: 'faBuilding', label: 'Dipolog (all airports), Philippines', key: 'C_DPL', type: 'city', code: 'dipolog_ph' } ],
 ['C_DIG', { icon: 'faBuilding', label: 'Diqing Tibetan Autonomous Prefecture (all airports), China', key: 'C_DIG', type: 'city', code: 'diqing-tibetan-autonomous-prefecture_cn' } ],
 ['C_DIR', { icon: 'faBuilding', label: 'Dire Dawa (all airports), Ethiopia', key: 'C_DIR', type: 'city', code: 'dire-dawa_et' } ],
 ['C_XED', { icon: 'faBuilding', label: 'Disneyland Paris (all airports), France', key: 'C_XED', type: 'city', code: 'disneyland-paris_fr' } ],
 ['C_DIU', { icon: 'faBuilding', label: 'Diu (all airports), India', key: 'C_DIU', type: 'city', code: 'diu_in' } ],
 ['C_DIQ', { icon: 'faBuilding', label: 'Divinópolis (all airports), Brazil', key: 'C_DIQ', type: 'city', code: 'divinopolis_mg_br' } ],
 ['C_DIY', { icon: 'faBuilding', label: 'Diyarbakır (all airports), Turkey', key: 'C_DIY', type: 'city', code: 'diyarbakir_tr' } ],
 ['C_DJG', { icon: 'faBuilding', label: 'Djanet (all airports), Algeria', key: 'C_DJG', type: 'city', code: 'djanet_dz' } ],
 ['C_DJE', { icon: 'faBuilding', label: 'Djerba (all airports), Tunisia', key: 'C_DJE', type: 'city', code: 'djerba_tn' } ],
 ['C_DNK', { icon: 'faBuilding', label: 'Dnipro (all airports), Ukraine', key: 'C_DNK', type: 'city', code: 'dnipropetrovsk_ua' } ],
 ['C_DOB', { icon: 'faBuilding', label: 'Dobo (all airports), Indonesia', key: 'C_DOB', type: 'city', code: 'dobo_id' } ],
 ['C_DDC', { icon: 'faBuilding', label: 'Dodge City (all airports), United States', key: 'C_DDC', type: 'city', code: 'dodge-city_ks_us' } ],
 ['C_DOH', { icon: 'faBuilding', label: 'Doha (all airports), Qatar', key: 'C_DOH', type: 'city', code: 'doha_qa' } ],
 ['C_DLE', { icon: 'faBuilding', label: 'Dole (all airports), France', key: 'C_DLE', type: 'city', code: 'dole_fr' } ],
 ['C_DSA', { icon: 'faBuilding', label: 'Doncaster (all airports), United Kingdom', key: 'C_DSA', type: 'city', code: 'doncaster_gb' } ],
 ['C_CFN', { icon: 'faBuilding', label: 'Donegal (all airports), Ireland', key: 'C_CFN', type: 'city', code: 'donegal_ie' } ],
 ['C_VDH', { icon: 'faBuilding', label: 'Đồng Hới (all airports), Vietnam', key: 'C_VDH', type: 'city', code: 'dong-hoi_vn' } ],
 ['C_DOY', { icon: 'faBuilding', label: 'Dongying (all airports), China', key: 'C_DOY', type: 'city', code: 'dongying_cn' } ],
 ['C_EAS', { icon: 'faBuilding', label: 'Donostia / San Sebastián (all airports), Spain', key: 'C_EAS', type: 'city', code: 'donostia-san-sebastian_es' } ],
 ['C_DMD', { icon: 'faBuilding', label: 'Doomadgee (all airports), Australia', key: 'C_DMD', type: 'city', code: 'doomadgee_ql_au' } ],
 ['C_DTM', { icon: 'faBuilding', label: 'Dortmund (all airports), Germany', key: 'C_DTM', type: 'city', code: 'dortmund_de' } ],
 ['C_DHN', { icon: 'faBuilding', label: 'Dothan (all airports), United States', key: 'C_DHN', type: 'city', code: 'dothan_al_us' } ],
 ['C_DLA', { icon: 'faBuilding', label: 'Douala (all airports), Cameroon', key: 'C_DLA', type: 'city', code: 'douala_cm' } ],
 ['C_IOM', { icon: 'faBuilding', label: 'Douglas (all airports), Isle of Man', key: 'C_IOM', type: 'city', code: 'douglas_im' } ],
 ['C_DOU', { icon: 'faBuilding', label: 'Dourados (all airports), Brazil', key: 'C_DOU', type: 'city', code: 'dourados_ms_br' } ],
 ['C_QQD', { icon: 'faBuilding', label: 'Dover (all airports), United Kingdom', key: 'C_QQD', type: 'city', code: 'dover_gb' } ],
 ['C_QYC', { icon: 'faBuilding', label: 'Drachten (all airports), Netherlands', key: 'C_QYC', type: 'city', code: 'drachten_nl' } ],
 ['C_DRS', { icon: 'faBuilding', label: 'Dresden (all airports), Germany', key: 'C_DRS', type: 'city', code: 'dresden_de' } ],
 ['C_YHD', { icon: 'faBuilding', label: 'Dryden (all airports), Canada', key: 'C_YHD', type: 'city', code: 'dryden_on_ca' } ],
 ['C_DXB', { icon: 'faBuilding', label: 'Dubai (all airports), United Arab Emirates', key: 'C_DXB', type: 'city', code: 'dubai_ae' } ],
 ['C_DBO', { icon: 'faBuilding', label: 'Dubbo (all airports), Australia', key: 'C_DBO', type: 'city', code: 'dubbo_ns_au' } ],
 ['C_DUB', { icon: 'faBuilding', label: 'Dublin (all airports), Ireland', key: 'C_DUB', type: 'city', code: 'dublin_ie' } ],
 ['C_DUJ', { icon: 'faBuilding', label: 'DuBois (all airports), United States', key: 'C_DUJ', type: 'city', code: 'dubois_pa_us' } ],
 ['C_DBV', { icon: 'faBuilding', label: 'Dubrovnik (all airports), Croatia', key: 'C_DBV', type: 'city', code: 'dubrovnik_hr' } ],
 ['C_DBQ', { icon: 'faBuilding', label: 'Dubuque (all airports), United States', key: 'C_DBQ', type: 'city', code: 'dubuque_ia_us' } ],
 ['C_DLH', { icon: 'faBuilding', label: 'Duluth (all airports), United States', key: 'C_DLH', type: 'city', code: 'duluth_mn_us' } ],
 ['C_DGT', { icon: 'faBuilding', label: 'Dumaguete (all airports), Philippines', key: 'C_DGT', type: 'city', code: 'dumaguete_ph' } ],
 ['C_DUM', { icon: 'faBuilding', label: 'Dumai (all airports), Indonesia', key: 'C_DUM', type: 'city', code: 'dumai_id' } ],
 ['C_DND', { icon: 'faBuilding', label: 'Dundee (all airports), United Kingdom', key: 'C_DND', type: 'city', code: 'dundee_gb' } ],
 ['C_DUE', { icon: 'faBuilding', label: 'Dundo (all airports), Angola', key: 'C_DUE', type: 'city', code: 'dundo_ao' } ],
 ['C_DUD', { icon: 'faBuilding', label: 'Dunedin (all airports), New Zealand', key: 'C_DUD', type: 'city', code: 'dunedin_nz' } ],
 ['C_DNH', { icon: 'faBuilding', label: 'Dunhuang (all airports), China', key: 'C_DNH', type: 'city', code: 'dunhuang_cn' } ],
 ['C_DQM', { icon: 'faBuilding', label: 'Duqm (all airports), Oman', key: 'C_DQM', type: 'city', code: 'duqm_om' } ],
 ['C_DRO', { icon: 'faBuilding', label: 'Durango (all airports), United States', key: 'C_DRO', type: 'city', code: 'durango_co_us' } ],
 ['C_DGO', { icon: 'faBuilding', label: 'Durango (all airports), Mexico', key: 'C_DGO', type: 'city', code: 'durango_mx' } ],
 ['C_DUR', { icon: 'faBuilding', label: 'Durban (all airports), South Africa', key: 'C_DUR', type: 'city', code: 'durban_za' } ],
 ['C_RDP', { icon: 'faBuilding', label: 'Durgapur (all airports), India', key: 'C_RDP', type: 'city', code: 'durgapur_in' } ],
 ['C_MME', { icon: 'faBuilding', label: 'Durham, England (all airports), United Kingdom', key: 'C_MME', type: 'city', code: 'durham_gb' } ],
 ['C_DYU', { icon: 'faBuilding', label: 'Dushanbe (all airports), Tajikistan', key: 'C_DYU', type: 'city', code: 'dushanbe_tj' } ],
 ['C_DUS', { icon: 'faBuilding', label: 'Düsseldorf (all airports), Germany', key: 'C_DUS', type: 'city', code: 'dusseldorf_de' } ],
 ['C_DUT', { icon: 'faBuilding', label: 'Dutch Harbor (all airports), United States', key: 'C_DUT', type: 'city', code: 'dutch-harbor_ak_us' } ],
 ['C_ELS', { icon: 'faBuilding', label: 'East London (all airports), South Africa', key: 'C_ELS', type: 'city', code: 'east-london_za' } ],
 ['C_IPC', { icon: 'faBuilding', label: 'Easter Island (all airports), Chile', key: 'C_IPC', type: 'city', code: 'easter-island_cl' } ],
 ['C_ZEM', { icon: 'faBuilding', label: 'Eastmain, Quebec (all airports), Canada', key: 'C_ZEM', type: 'city', code: 'eastmain_qc_ca' } ],
 ['C_EAU', { icon: 'faBuilding', label: 'Eau Claire (all airports), United States', key: 'C_EAU', type: 'city', code: 'eau-claire_wi_us' } ],
 ['C_EOI', { icon: 'faBuilding', label: 'Eday (all airports), United Kingdom', key: 'C_EOI', type: 'city', code: 'eday_gb' } ],
 ['C_EDI', { icon: 'faBuilding', label: 'Edinburgh (all airports), United Kingdom', key: 'C_EDI', type: 'city', code: 'edinburgh_gb' } ],
 ['C_YEA', { icon: 'faBuilding', label: 'Edmonton (all airports), Canada', key: 'C_YEA', type: 'city', code: 'edmonton_ab_ca' } ],
 ['C_EDA', { icon: 'faBuilding', label: 'Edna Bay (all airports), United States', key: 'C_EDA', type: 'city', code: 'edna-bay_ak_us' } ],
 ['C_EDO', { icon: 'faBuilding', label: 'Edremit (all airports), Turkey', key: 'C_EDO', type: 'city', code: 'edremit_tr' } ],
 ['C_EEK', { icon: 'faBuilding', label: 'Eek (all airports), United States', key: 'C_EEK', type: 'city', code: 'eek_ak_us' } ],
 ['C_EGX', { icon: 'faBuilding', label: 'Egegik (all airports), United States', key: 'C_EGX', type: 'city', code: 'egegik_ak_us' } ],
 ['C_EGS', { icon: 'faBuilding', label: 'Egilsstaðir (all airports), Iceland', key: 'C_EGS', type: 'city', code: 'egilsstadir_is' } ],
 ['C_ETH', { icon: 'faBuilding', label: 'Eilat (all airports), Israel', key: 'C_ETH', type: 'city', code: 'eilat_il' } ],
 ['C_EIN', { icon: 'faBuilding', label: 'Eindhoven (all airports), Netherlands', key: 'C_EIN', type: 'city', code: 'eindhoven_nl' } ],
 ['C_KEK', { icon: 'faBuilding', label: 'Ekwok (all airports), United States', key: 'C_KEK', type: 'city', code: 'ekwok_ak_us' } ],
 ['C_EBG', { icon: 'faBuilding', label: 'El Bagre (all airports), Colombia', key: 'C_EBG', type: 'city', code: 'el-bagre_co' } ],
 ['C_FTE', { icon: 'faBuilding', label: 'El Calafate (all airports), Argentina', key: 'C_FTE', type: 'city', code: 'el-calafate_sc_ar' } ],
 ['C_IPL', { icon: 'faBuilding', label: 'El Centro (all airports), United States', key: 'C_IPL', type: 'city', code: 'el-centro_ca_us' } ],
 ['C_ELD', { icon: 'faBuilding', label: 'El Dorado (all airports), United States', key: 'C_ELD', type: 'city', code: 'el-dorado_ar_us' } ],
 ['C_ELG', { icon: 'faBuilding', label: 'El Goléa (all airports), Algeria', key: 'C_ELG', type: 'city', code: 'el-golea_dz' } ],
 ['C_ELU', { icon: 'faBuilding', label: 'El Oued (all airports), Algeria', key: 'C_ELU', type: 'city', code: 'el-oued_dz' } ],
 ['C_ELP', { icon: 'faBuilding', label: 'El Paso (all airports), United States', key: 'C_ELP', type: 'city', code: 'el-paso_tx_us' } ],
 ['C_VIG', { icon: 'faBuilding', label: 'El Vigía (all airports), Venezuela', key: 'C_VIG', type: 'city', code: 'el-vigia_ve' } ],
 ['C_EZS', { icon: 'faBuilding', label: 'Elazığ (all airports), Turkey', key: 'C_EZS', type: 'city', code: 'elazig_tr' } ],
 ['C_ELC', { icon: 'faBuilding', label: 'Elcho Island (all airports), Australia', key: 'C_ELC', type: 'city', code: 'elcho-island_nt_au' } ],
 ['C_EDL', { icon: 'faBuilding', label: 'Eldoret (all airports), Kenya', key: 'C_EDL', type: 'city', code: 'eldoret_ke' } ],
 ['C_ELI', { icon: 'faBuilding', label: 'Elim (all airports), United States', key: 'C_ELI', type: 'city', code: 'elim_ak_us' } ],
 ['C_ESL', { icon: 'faBuilding', label: 'Elista (all airports), Russia', key: 'C_ESL', type: 'city', code: 'elista_ru' } ],
 ['C_EKO', { icon: 'faBuilding', label: 'Elko (all airports), United States', key: 'C_EKO', type: 'city', code: 'elko_nv_us' } ],
 ['C_ELM', { icon: 'faBuilding', label: 'Elmira (all airports), United States', key: 'C_ELM', type: 'city', code: 'elmira_ny_us' } ],
 ['C_EAE', { icon: 'faBuilding', label: 'Emae (all airports), Vanuatu', key: 'C_EAE', type: 'city', code: 'emae_vu' } ],
 ['C_EMD', { icon: 'faBuilding', label: 'Emerald (all airports), Australia', key: 'C_EMD', type: 'city', code: 'emerald_ql_au' } ],
 ['C_EMK', { icon: 'faBuilding', label: 'Emmonak (all airports), United States', key: 'C_EMK', type: 'city', code: 'emmonak_ak_us' } ],
 ['C_ENE', { icon: 'faBuilding', label: 'Ende (all airports), Indonesia', key: 'C_ENE', type: 'city', code: 'ende_id' } ],
 ['C_NBE', { icon: 'faBuilding', label: 'Enfidha (all airports), Tunisia', key: 'C_NBE', type: 'city', code: 'enfidha_tn' } ],
 ['C_ENF', { icon: 'faBuilding', label: 'Enontekio (all airports), Finland', key: 'C_ENF', type: 'city', code: 'enontekio_fi' } ],
 ['C_ENH', { icon: 'faBuilding', label: 'Enshi City (all airports), China', key: 'C_ENH', type: 'city', code: 'enshi-city_cn' } ],
 ['C_EBB', { icon: 'faBuilding', label: 'Entebbe (all airports), Uganda', key: 'C_EBB', type: 'city', code: 'entebbe_ug' } ],
 ['C_ENU', { icon: 'faBuilding', label: 'Enugu (all airports), Nigeria', key: 'C_ENU', type: 'city', code: 'enugu_ng' } ],
 ['C_EBL', { icon: 'faBuilding', label: 'Erbil (all airports), Iraq', key: 'C_EBL', type: 'city', code: 'erbil_iq' } ],
 ['C_ERL', { icon: 'faBuilding', label: 'Erenhot (all airports), China', key: 'C_ERL', type: 'city', code: 'erenhot_cn' } ],
 ['C_ERF', { icon: 'faBuilding', label: 'Erfurt (all airports), Germany', key: 'C_ERF', type: 'city', code: 'erfurt_de' } ],
 ['C_ERI', { icon: 'faBuilding', label: 'Erie (all airports), United States', key: 'C_ERI', type: 'city', code: 'erie_pa_us' } ],
 ['C_ERH', { icon: 'faBuilding', label: 'Errachidia (all airports), Morocco', key: 'C_ERH', type: 'city', code: 'errachidia_ma' } ],
 ['C_ERC', { icon: 'faBuilding', label: 'Erzincan (all airports), Turkey', key: 'C_ERC', type: 'city', code: 'erzincan_tr' } ],
 ['C_ERZ', { icon: 'faBuilding', label: 'Erzurum (all airports), Turkey', key: 'C_ERZ', type: 'city', code: 'erzurum_tr' } ],
 ['C_EBJ', { icon: 'faBuilding', label: 'Esbjerg (all airports), Denmark', key: 'C_EBJ', type: 'city', code: 'esbjerg_dk' } ],
 ['C_ESC', { icon: 'faBuilding', label: 'Escanaba (all airports), United States', key: 'C_ESC', type: 'city', code: 'escanaba_mi_us' } ],
 ['C_ESK', { icon: 'faBuilding', label: 'Eskişehir (all airports), Turkey', key: 'C_ESK', type: 'city', code: 'eskisehir_tr' } ],
 ['C_ESM', { icon: 'faBuilding', label: 'Esmeraldas (all airports), Ecuador', key: 'C_ESM', type: 'city', code: 'esmeraldas_ec' } ],
 ['C_EPR', { icon: 'faBuilding', label: 'Esperance (all airports), Australia', key: 'C_EPR', type: 'city', code: 'esperance_wa_au' } ],
 ['C_EQS', { icon: 'faBuilding', label: 'Esquel (all airports), Argentina', key: 'C_EQS', type: 'city', code: 'esquel_cb_ar' } ],
 ['C_ESU', { icon: 'faBuilding', label: 'Essaouira (all airports), Morocco', key: 'C_ESU', type: 'city', code: 'essaouira_ma' } ],
 ['C_EUG', { icon: 'faBuilding', label: 'Eugene (all airports), United States', key: 'C_EUG', type: 'city', code: 'eugene_or_us' } ],
 ['C_EVV', { icon: 'faBuilding', label: 'Evansville (all airports), United States', key: 'C_EVV', type: 'city', code: 'evansville_in_us' } ],
 ['C_SWV', { icon: 'faBuilding', label: 'Evensk (all airports), Russia', key: 'C_SWV', type: 'city', code: 'evensk_ru' } ],
 ['C_EXI', { icon: 'faBuilding', label: 'Excursion Inlet (all airports), United States', key: 'C_EXI', type: 'city', code: 'excursion-inlet_ak_us' } ],
 ['C_EXT', { icon: 'faBuilding', label: 'Exeter (all airports), United Kingdom', key: 'C_EXT', type: 'city', code: 'exeter_gb' } ],
 ['C_LEA', { icon: 'faBuilding', label: 'Exmouth (all airports), Australia', key: 'C_LEA', type: 'city', code: 'exmouth_wa_au' } ],
 ['C_FAC', { icon: 'faBuilding', label: 'Faaite (all airports), French Polynesia', key: 'C_FAC', type: 'city', code: 'faaite_pf' } ],
 ['C_FAI', { icon: 'faBuilding', label: 'Fairbanks (all airports), United States', key: 'C_FAI', type: 'city', code: 'fairbanks_ak_us' } ],
 ['C_LYP', { icon: 'faBuilding', label: 'Faisalabad District (all airports), Pakistan', key: 'C_LYP', type: 'city', code: 'faisalabad-district_pk' } ],
 ['C_FAV', { icon: 'faBuilding', label: 'Fakarava (all airports), French Polynesia', key: 'C_FAV', type: 'city', code: 'fakarava_pf' } ],
 ['C_FKQ', { icon: 'faBuilding', label: 'Fakfak (all airports), Indonesia', key: 'C_FKQ', type: 'city', code: 'fakfak_id' } ],
 ['C_KFP', { icon: 'faBuilding', label: 'False Pass (all airports), United States', key: 'C_KFP', type: 'city', code: 'false-pass_ak_us' } ],
 ['C_FGU', { icon: 'faBuilding', label: 'Fangatau (all airports), French Polynesia', key: 'C_FGU', type: 'city', code: 'fangatau_pf' } ],
 ['C_FAR', { icon: 'faBuilding', label: 'Fargo (all airports), United States', key: 'C_FAR', type: 'city', code: 'fargo_nd_us' } ],
 ['C_FMN', { icon: 'faBuilding', label: 'Farmington (all airports), United States', key: 'C_FMN', type: 'city', code: 'farmington_nm_us' } ],
 ['C_FAO', { icon: 'faBuilding', label: 'Faro (all airports), Portugal', key: 'C_FAO', type: 'city', code: 'faro_pt' } ],
 ['C_FAY', { icon: 'faBuilding', label: 'Fayetteville (all airports), United States', key: 'C_FAY', type: 'city', code: 'fayetteville_nc_us' } ],
 ['C_FYV', { icon: 'faBuilding', label: 'Fayetteville (all airports), United States', key: 'C_FYV', type: 'city', code: 'fayetteville_ar_us' } ],
 ['C_FEC', { icon: 'faBuilding', label: 'Feira de Santana (all airports), Brazil', key: 'C_FEC', type: 'city', code: 'feira-de-santana_ba_br' } ],
 ['C_FRE', { icon: 'faBuilding', label: 'Fera Island (all airports), Solomon Islands', key: 'C_FRE', type: 'city', code: 'fera-island_sb' } ],
 ['C_FEG', { icon: 'faBuilding', label: 'Fergana (all airports), Uzbekistan', key: 'C_FEG', type: 'city', code: 'fergana_uz' } ],
 ['C_FEN', { icon: 'faBuilding', label: 'Fernando de Noronha (all airports), Brazil', key: 'C_FEN', type: 'city', code: 'fernando-de-noronha_fn_br' } ],
 ['C_FEZ', { icon: 'faBuilding', label: 'Fes (all airports), Morocco', key: 'C_FEZ', type: 'city', code: 'fes_ma' } ],
 ['C_FSC', { icon: 'faBuilding', label: 'Figari (all airports), France', key: 'C_FSC', type: 'city', code: 'figari_fr' } ],
 ['C_YFO', { icon: 'faBuilding', label: 'Flin Flon (all airports), Canada', key: 'C_YFO', type: 'city', code: 'flin-flon_mb_ca' } ],
 ['C_FLS', { icon: 'faBuilding', label: 'Flinders Island (all airports), Australia', key: 'C_FLS', type: 'city', code: 'flinders-island_ts_au' } ],
 ['C_FNT', { icon: 'faBuilding', label: 'Flint (all airports), United States', key: 'C_FNT', type: 'city', code: 'flint_mi_us' } ],
 ['C_FLO', { icon: 'faBuilding', label: 'Florence (all airports), United States', key: 'C_FLO', type: 'city', code: 'florence_sc_us' } ],
 ['C_FLR', { icon: 'faBuilding', label: 'Florence (all airports), Italy', key: 'C_FLR', type: 'city', code: 'florence_it' } ],
 ['C_FLA', { icon: 'faBuilding', label: 'Florencia (all airports), Colombia', key: 'C_FLA', type: 'city', code: 'florencia_co' } ],
 ['C_FRS', { icon: 'faBuilding', label: 'Flores (all airports), Guatemala', key: 'C_FRS', type: 'city', code: 'flores_gt' } ],
 ['C_FLW', { icon: 'faBuilding', label: 'Flores Island (all airports), Portugal', key: 'C_FLW', type: 'city', code: 'flores-island_pt' } ],
 ['C_FLN', { icon: 'faBuilding', label: 'Florianópolis (all airports), Brazil', key: 'C_FLN', type: 'city', code: 'florianopolis_sc_br' } ],
 ['C_FRO', { icon: 'faBuilding', label: 'Florø (all airports), Norway', key: 'C_FRO', type: 'city', code: 'floro_no' } ],
 ['C_FOG', { icon: 'faBuilding', label: 'Foggia (all airports), Italy', key: 'C_FOG', type: 'city', code: 'foggia_it' } ],
 ['C_QZF', { icon: 'faBuilding', label: 'Font-Romeu (all airports), France', key: 'C_QZF', type: 'city', code: 'font-romeu_fr' } ],
 ['C_FDE', { icon: 'faBuilding', label: 'Førde (all airports), Norway', key: 'C_FDE', type: 'city', code: 'forde_no' } ],
 ['C_FMA', { icon: 'faBuilding', label: 'Formosa (all airports), Argentina', key: 'C_FMA', type: 'city', code: 'formosa_fo_ar' } ],
 ['C_YFA', { icon: 'faBuilding', label: 'Fort Albany First Nation (all airports), Canada', key: 'C_YFA', type: 'city', code: 'fort-albany-first-nation_on_ca' } ],
 ['C_YPY', { icon: 'faBuilding', label: 'Fort Chipewyan (all airports), Canada', key: 'C_YPY', type: 'city', code: 'fort-chipewyan_ab_ca' } ],
 ['C_FOD', { icon: 'faBuilding', label: 'Fort Dodge (all airports), United States', key: 'C_FOD', type: 'city', code: 'fort-dodge_ia_us' } ],
 ['C_YAG', { icon: 'faBuilding', label: 'Fort Frances (all airports), Canada', key: 'C_YAG', type: 'city', code: 'fort-frances_on_ca' } ],
 ['C_YGH', { icon: 'faBuilding', label: 'Fort Good Hope (all airports), Canada', key: 'C_YGH', type: 'city', code: 'fort-good-hope_nt_ca' } ],
 ['C_FLL', { icon: 'faBuilding', label: 'Fort Lauderdale (all airports), United States', key: 'C_FLL', type: 'city', code: 'fort-lauderdale_fl_us' } ],
 ['C_TBN', { icon: 'faBuilding', label: 'Fort Leonard Wood (all airports), United States', key: 'C_TBN', type: 'city', code: 'fort-leonard-wood_mo_us' } ],
 ['C_YMM', { icon: 'faBuilding', label: 'Fort McMurray (all airports), Canada', key: 'C_YMM', type: 'city', code: 'fort-mcmurray_ab_ca' } ],
 ['C_ZFM', { icon: 'faBuilding', label: 'Fort McPherson (all airports), Canada', key: 'C_ZFM', type: 'city', code: 'fort-mcpherson_nt_ca' } ],
 ['C_FMY', { icon: 'faBuilding', label: 'Fort Myers (all airports), United States', key: 'C_FMY', type: 'city', code: 'fort-myers_fl_us' } ],
 ['C_YYE', { icon: 'faBuilding', label: 'Fort Nelson (all airports), Canada', key: 'C_YYE', type: 'city', code: 'fort-nelson_bc_ca' } ],
 ['C_YFS', { icon: 'faBuilding', label: 'Fort Simpson (all airports), Canada', key: 'C_YFS', type: 'city', code: 'fort-simpson_nt_ca' } ],
 ['C_FSM', { icon: 'faBuilding', label: 'Fort Smith (all airports), United States', key: 'C_FSM', type: 'city', code: 'fort-smith_ar_us' } ],
 ['C_YSM', { icon: 'faBuilding', label: 'Fort Smith (all airports), Canada', key: 'C_YSM', type: 'city', code: 'fort-smith_nt_ca' } ],
 ['C_YXJ', { icon: 'faBuilding', label: 'Fort St. John (all airports), Canada', key: 'C_YXJ', type: 'city', code: 'fort-st-john_bc_ca' } ],
 ['C_FWA', { icon: 'faBuilding', label: 'Fort Wayne (all airports), United States', key: 'C_FWA', type: 'city', code: 'fort-wayne_in_us' } ],
 ['C_FYU', { icon: 'faBuilding', label: 'Fort Yukon (all airports), United States', key: 'C_FYU', type: 'city', code: 'fort-yukon_ak_us' } ],
 ['C_FDF', { icon: 'faBuilding', label: 'Fort-de-France (all airports), France', key: 'C_FDF', type: 'city', code: 'fort-de-france_mq' } ],
 ['C_FOR', { icon: 'faBuilding', label: 'Fortaleza (all airports), Brazil', key: 'C_FOR', type: 'city', code: 'fortaleza_ce_br' } ],
 ['C_IGU', { icon: 'faBuilding', label: 'Foz do Iguaçu (all airports), Brazil', key: 'C_IGU', type: 'city', code: 'foz-do-iguacu_pr_br' } ],
 ['C_FRW', { icon: 'faBuilding', label: 'Francistown (all airports), Botswana', key: 'C_FRW', type: 'city', code: 'francistown_bw' } ],
 ['C_FRA', { icon: 'faBuilding', label: 'Frankfurt (all airports), Germany', key: 'C_FRA', type: 'city', code: 'frankfurt_de' } ],
 ['C_YFC', { icon: 'faBuilding', label: 'Fredericton (all airports), Canada', key: 'C_YFC', type: 'city', code: 'fredericton_nb_ca' } ],
 ['C_QFH', { icon: 'faBuilding', label: 'Frederikshavn (all airports), Denmark', key: 'C_QFH', type: 'city', code: 'frederikshavn_dk' } ],
 ['C_FPO', { icon: 'faBuilding', label: 'Freeport (all airports), Bahamas', key: 'C_FPO', type: 'city', code: 'freeport_bs' } ],
 ['C_FNA', { icon: 'faBuilding', label: 'Freetown (all airports), Sierra Leone', key: 'C_FNA', type: 'city', code: 'freetown_sl' } ],
 ['C_QFB', { icon: 'faBuilding', label: 'Freiburg (all airports), Germany', key: 'C_QFB', type: 'city', code: 'freiburg_de' } ],
 ['C_FAT', { icon: 'faBuilding', label: 'Fresno (all airports), United States', key: 'C_FAT', type: 'city', code: 'fresno_ca_us' } ],
 ['C_FDH', { icon: 'faBuilding', label: 'Friedrichshafen (all airports), Germany', key: 'C_FDH', type: 'city', code: 'friedrichshafen_de' } ],
 ['C_FUE', { icon: 'faBuilding', label: 'Fuerteventura (all airports), Spain', key: 'C_FUE', type: 'city', code: 'fuerteventura_es' } ],
 ['C_FUJ', { icon: 'faBuilding', label: 'Fukue Island (all airports), Japan', key: 'C_FUJ', type: 'city', code: 'fukue-island_jp' } ],
 ['C_FUK', { icon: 'faBuilding', label: 'Fukuoka (all airports), Japan', key: 'C_FUK', type: 'city', code: 'fukuoka_jp' } ],
 ['C_FKS', { icon: 'faBuilding', label: 'Fukushima (all airports), Japan', key: 'C_FKS', type: 'city', code: 'fukushima_jp' } ],
 ['C_FUN', { icon: 'faBuilding', label: 'Funafuti (all airports), Tuvalu', key: 'C_FUN', type: 'city', code: 'funafuti_tv' } ],
 ['C_FUT', { icon: 'faBuilding', label: 'Futuna (all airports), Wallis & Futuna', key: 'C_FUT', type: 'city', code: 'futuna_wf' } ],
 ['C_FTA', { icon: 'faBuilding', label: 'Futuna Island (all airports), Vanuatu', key: 'C_FTA', type: 'city', code: 'futuna-island_vu' } ],
 ['C_FUG', { icon: 'faBuilding', label: 'Fuyang (all airports), China', key: 'C_FUG', type: 'city', code: 'fuyang_cn' } ],
 ['C_FYJ', { icon: 'faBuilding', label: 'Fuyuan, Heilongjiang (all airports), China', key: 'C_FYJ', type: 'city', code: 'fuyuan_cn' } ],
 ['C_FYN', { icon: 'faBuilding', label: 'Fuyun County (all airports), China', key: 'C_FYN', type: 'city', code: 'fuyun-county_cn' } ],
 ['C_FOC', { icon: 'faBuilding', label: 'Fuzhou (all airports), China', key: 'C_FOC', type: 'city', code: 'fuzhou_cn' } ],
 ['C_GBE', { icon: 'faBuilding', label: 'Gaborone (all airports), Botswana', key: 'C_GBE', type: 'city', code: 'gaborone_bw' } ],
 ['C_GNV', { icon: 'faBuilding', label: 'Gainesville (all airports), United States', key: 'C_GNV', type: 'city', code: 'gainesville_fl_us' } ],
 ['C_GLX', { icon: 'faBuilding', label: 'Galela (all airports), Indonesia', key: 'C_GLX', type: 'city', code: 'galela_id' } ],
 ['C_GAL', { icon: 'faBuilding', label: 'Galena (all airports), United States', key: 'C_GAL', type: 'city', code: 'galena_ak_us' } ],
 ['C_GLK', { icon: 'faBuilding', label: 'Galkayo (all airports), Somalia', key: 'C_GLK', type: 'city', code: 'galkayo_so' } ],
 ['C_GEV', { icon: 'faBuilding', label: 'Gällivare (all airports), Sweden', key: 'C_GEV', type: 'city', code: 'gallivare_se' } ],
 ['C_GMB', { icon: 'faBuilding', label: 'Gambela (all airports), Ethiopia', key: 'C_GMB', type: 'city', code: 'gambela_et' } ],
 ['C_GAM', { icon: 'faBuilding', label: 'Gambell (all airports), United States', key: 'C_GAM', type: 'city', code: 'gambell_ak_us' } ],
 ['C_YRA', { icon: 'faBuilding', label: 'Gameti (all airports), Canada', key: 'C_YRA', type: 'city', code: 'gameti_nt_ca' } ],
 ['C_GAN', { icon: 'faBuilding', label: 'Gan (all airports), Maldives', key: 'C_GAN', type: 'city', code: 'gan_mv' } ],
 ['C_YQX', { icon: 'faBuilding', label: 'Gander (all airports), Canada', key: 'C_YQX', type: 'city', code: 'gander_nl_ca' } ],
 ['C_KVD', { icon: 'faBuilding', label: 'Ganja (all airports), Azerbaijan', key: 'C_KVD', type: 'city', code: 'ganja_az' } ],
 ['C_KOW', { icon: 'faBuilding', label: 'Ganzhou (all airports), China', key: 'C_KOW', type: 'city', code: 'ganzhou_cn' } ],
 ['C_GCK', { icon: 'faBuilding', label: 'Garden City (all airports), United States', key: 'C_GCK', type: 'city', code: 'garden-city_ks_us' } ],
 ['C_ZEI', { icon: 'faBuilding', label: 'Garmisch-Partenkirchen (all airports), Germany', key: 'C_ZEI', type: 'city', code: 'garmisch-partenkirchen_de' } ],
 ['C_GGR', { icon: 'faBuilding', label: 'Garoe (all airports), Somalia', key: 'C_GGR', type: 'city', code: 'garoe_so' } ],
 ['C_GOU', { icon: 'faBuilding', label: 'Garoua (all airports), Cameroon', key: 'C_GOU', type: 'city', code: 'garoua_cm' } ],
 ['C_YGP', { icon: 'faBuilding', label: 'Gaspé (all airports), Canada', key: 'C_YGP', type: 'city', code: 'gaspe_qc_ca' } ],
 ['C_ZGU', { icon: 'faBuilding', label: 'Gaua (all airports), Vanuatu', key: 'C_ZGU', type: 'city', code: 'gaua_vu' } ],
 ['C_GAY', { icon: 'faBuilding', label: 'Gaya (all airports), India', key: 'C_GAY', type: 'city', code: 'gaya_in' } ],
 ['C_GZT', { icon: 'faBuilding', label: 'Gaziantep (all airports), Turkey', key: 'C_GZT', type: 'city', code: 'gaziantep_tr' } ],
 ['C_GZP', { icon: 'faBuilding', label: 'Gazipaşa (all airports), Turkey', key: 'C_GZP', type: 'city', code: 'gazipasa_tr' } ],
 ['C_GDN', { icon: 'faBuilding', label: 'Gdańsk (all airports), Poland', key: 'C_GDN', type: 'city', code: 'gdansk_pl' } ],
 ['C_GDZ', { icon: 'faBuilding', label: 'Gelendzhik (all airports), Russia', key: 'C_GDZ', type: 'city', code: 'gelendzhik_ru' } ],
 ['C_GMA', { icon: 'faBuilding', label: 'Gemena (all airports), Democratic Republic of the Congo', key: 'C_GMA', type: 'city', code: 'gemena_cd' } ],
 ['C_EGN', { icon: 'faBuilding', label: 'Geneina (all airports), Sudan', key: 'C_EGN', type: 'city', code: 'geneina_sd' } ],
 ['C_GES', { icon: 'faBuilding', label: 'General Santos (all airports), Philippines', key: 'C_GES', type: 'city', code: 'general-santos_ph' } ],
 ['C_GVA', { icon: 'faBuilding', label: 'Geneva (all airports), Switzerland', key: 'C_GVA', type: 'city', code: 'geneva_ch' } ],
 ['C_GOA', { icon: 'faBuilding', label: 'Genoa (all airports), Italy', key: 'C_GOA', type: 'city', code: 'genoa_it' } ],
 ['C_GRJ', { icon: 'faBuilding', label: 'George (all airports), South Africa', key: 'C_GRJ', type: 'city', code: 'george_za' } ],
 ['C_GGT', { icon: 'faBuilding', label: 'George Town (all airports), Bahamas', key: 'C_GGT', type: 'city', code: 'george-town_bs' } ],
 ['C_GEO', { icon: 'faBuilding', label: 'Georgetown (all airports), Guyana', key: 'C_GEO', type: 'city', code: 'georgetown_gy' } ],
 ['C_GET', { icon: 'faBuilding', label: 'Geraldton (all airports), Australia', key: 'C_GET', type: 'city', code: 'geraldton_wa_au' } ],
 ['C_GHA', { icon: 'faBuilding', label: 'Ghardaïa (all airports), Algeria', key: 'C_GHA', type: 'city', code: 'ghardaia_dz' } ],
 ['C_GHT', { icon: 'faBuilding', label: 'Ghat (all airports), Libya', key: 'C_GHT', type: 'city', code: 'ghat_ly' } ],
 ['C_YIN', { icon: 'faBuilding', label: 'Ghulja (all airports), China', key: 'C_YIN', type: 'city', code: 'ghulja_cn' } ],
 ['C_GIB', { icon: 'faBuilding', label: 'Gibraltar (all airports), Gibraltar', key: 'C_GIB', type: 'city', code: 'gibraltar_gi' } ],
 ['C_GIL', { icon: 'faBuilding', label: 'Gilgit (all airports), Unknown Region', key: 'C_GIL', type: 'city', code: 'gilgit_zz' } ],
 ['C_YGX', { icon: 'faBuilding', label: 'Gillam (all airports), Canada', key: 'C_YGX', type: 'city', code: 'gillam_mb_ca' } ],
 ['C_GCC', { icon: 'faBuilding', label: 'Gillette (all airports), United States', key: 'C_GCC', type: 'city', code: 'gillette_wy_us' } ],
 ['C_OGU', { icon: 'faBuilding', label: 'Giresun (all airports), Turkey', key: 'C_OGU', type: 'city', code: 'ordu_tr' } ],
 ['C_GRO', { icon: 'faBuilding', label: 'Girona (all airports), Spain', key: 'C_GRO', type: 'city', code: 'girona_es' } ],
 ['C_GIS', { icon: 'faBuilding', label: 'Gisborne (all airports), New Zealand', key: 'C_GIS', type: 'city', code: 'gisborne_nz' } ],
 ['C_GZO', { icon: 'faBuilding', label: 'Gizo (all airports), Solomon Islands', key: 'C_GZO', type: 'city', code: 'gizo_sb' } ],
 ['C_YHK', { icon: 'faBuilding', label: 'Gjoa Haven (all airports), Canada', key: 'C_YHK', type: 'city', code: 'gjoa-haven_nu_ca' } ],
 ['C_GLT', { icon: 'faBuilding', label: 'Gladstone (all airports), Australia', key: 'C_GLT', type: 'city', code: 'gladstone_ql_au' } ],
 ['C_GGW', { icon: 'faBuilding', label: 'Glasgow (all airports), United States', key: 'C_GGW', type: 'city', code: 'glasgow_mt_us' } ],
 ['C_GLA', { icon: 'faBuilding', label: 'Glasgow (all airports), United Kingdom', key: 'C_GLA', type: 'city', code: 'glasgow_gb' } ],
 ['C_GDV', { icon: 'faBuilding', label: 'Glendive (all airports), United States', key: 'C_GDV', type: 'city', code: 'glendive_mt_us' } ],
 ['C_GOI', { icon: 'faBuilding', label: 'Goa (all airports), India', key: 'C_GOI', type: 'city', code: 'goa_in' } ],
 ['C_null', { icon: 'faBuilding', label: 'Goba (all airports), Ethiopia', key: 'C_null', type: 'city', code: 'goba_et' } ],
 ['C_GDE', { icon: 'faBuilding', label: 'Gode (all airports), Ethiopia', key: 'C_GDE', type: 'city', code: 'gode_et' } ],
 ['C_YGO', { icon: 'faBuilding', label: 'Gods Lake Narrows, Manitoba (all airports), Canada', key: 'C_YGO', type: 'city', code: 'gods-lake-narrows--manitoba_mb_ca' } ],
 ['C_GYN', { icon: 'faBuilding', label: 'Goiânia (all airports), Brazil', key: 'C_GYN', type: 'city', code: 'goiania_go_br' } ],
 ['C_OOL', { icon: 'faBuilding', label: 'Gold Coast (all airports), Australia', key: 'C_OOL', type: 'city', code: 'gold-coast_ql_au' } ],
 ['C_GLF', { icon: 'faBuilding', label: 'Golfito (all airports), Costa Rica', key: 'C_GLF', type: 'city', code: 'golfito_cr' } ],
 ['C_GOQ', { icon: 'faBuilding', label: 'Golmud (all airports), China', key: 'C_GOQ', type: 'city', code: 'golmud_cn' } ],
 ['C_GMQ', { icon: 'faBuilding', label: 'Golog Tibetan Autonomous Prefecture (all airports), China', key: 'C_GMQ', type: 'city', code: 'golog-tibetan-autonomous-prefecture_cn' } ],
 ['C_GLV', { icon: 'faBuilding', label: 'Golovin (all airports), United States', key: 'C_GLV', type: 'city', code: 'golovin_ak_us' } ],
 ['C_GOM', { icon: 'faBuilding', label: 'Goma (all airports), Democratic Republic of the Congo', key: 'C_GOM', type: 'city', code: 'goma_cd' } ],
 ['C_GMO', { icon: 'faBuilding', label: 'Gombe (all airports), Nigeria', key: 'C_GMO', type: 'city', code: 'gombe_ng' } ],
 ['C_GME', { icon: 'faBuilding', label: 'Gomel (all airports), Belarus', key: 'C_GME', type: 'city', code: 'gomel_by' } ],
 ['C_GDQ', { icon: 'faBuilding', label: 'Gondar (all airports), Ethiopia', key: 'C_GDQ', type: 'city', code: 'gondar_et' } ],
 ['C_GNU', { icon: 'faBuilding', label: 'Goodnews Bay (all airports), United States', key: 'C_GNU', type: 'city', code: 'goodnews-bay_ak_us' } ],
 ['C_GOP', { icon: 'faBuilding', label: 'Gorakhpur (all airports), India', key: 'C_GOP', type: 'city', code: 'gorakhpur_in' } ],
 ['C_GBT', { icon: 'faBuilding', label: 'Gorgan (all airports), Iran', key: 'C_GBT', type: 'city', code: 'gorgan_ir' } ],
 ['C_RGK', { icon: 'faBuilding', label: 'Gorno-Altaysk (all airports), Russia', key: 'C_RGK', type: 'city', code: 'gorno-altaysk_ru' } ],
 ['C_GKA', { icon: 'faBuilding', label: 'Goroka (all airports), Papua New Guinea', key: 'C_GKA', type: 'city', code: 'goroka_pg' } ],
 ['C_GTO', { icon: 'faBuilding', label: 'Gorontalo (all airports), Indonesia', key: 'C_GTO', type: 'city', code: 'gorontalo_id' } ],
 ['C_GOT', { icon: 'faBuilding', label: 'Gothenburg (all airports), Sweden', key: 'C_GOT', type: 'city', code: 'gothenburg_se' } ],
 ['C_GVR', { icon: 'faBuilding', label: 'Governador Valadares (all airports), Brazil', key: 'C_GVR', type: 'city', code: 'governador-valadares_mg_br' } ],
 ['C_GHB', { icon: 'faBuilding', label: 'Governor\'s Harbour (all airports), Bahamas', key: 'C_GHB', type: 'city', code: 'governors-harbour_bs' } ],
 ['C_XRA', { icon: 'faBuilding', label: 'Graasten (all airports), Denmark', key: 'C_XRA', type: 'city', code: 'graasten_dk' } ],
 ['C_GRW', { icon: 'faBuilding', label: 'Graciosa (all airports), Portugal', key: 'C_GRW', type: 'city', code: 'graciosa_pt' } ],
 ['C_GFN', { icon: 'faBuilding', label: 'Grafton (all airports), Australia', key: 'C_GFN', type: 'city', code: 'grafton_ns_au' } ],
 ['C_GRX', { icon: 'faBuilding', label: 'Granada (all airports), Spain', key: 'C_GRX', type: 'city', code: 'granada_es' } ],
 ['C_GBJ', { icon: 'faBuilding', label: 'Grand Bourg (all airports), France', key: 'C_GBJ', type: 'city', code: 'grand-bourg_gp' } ],
 ['C_GCN', { icon: 'faBuilding', label: 'Grand Canyon Village (all airports), United States', key: 'C_GCN', type: 'city', code: 'grand-canyon-village_az_us' } ],
 ['C_GCW', { icon: 'faBuilding', label: 'Grand Canyon West, Arizona (all airports), United States', key: 'C_GCW', type: 'city', code: 'grand-canyon-west_az_us' } ],
 ['C_GCM', { icon: 'faBuilding', label: 'Grand Cayman (all airports), Cayman Islands', key: 'C_GCM', type: 'city', code: 'grand-cayman_ky' } ],
 ['C_GFK', { icon: 'faBuilding', label: 'Grand Forks (all airports), United States', key: 'C_GFK', type: 'city', code: 'grand-forks_nd_us' } ],
 ['C_GRI', { icon: 'faBuilding', label: 'Grand Island (all airports), United States', key: 'C_GRI', type: 'city', code: 'grand-island_ne_us' } ],
 ['C_GJT', { icon: 'faBuilding', label: 'Grand Junction (all airports), United States', key: 'C_GJT', type: 'city', code: 'grand-junction_co_us' } ],
 ['C_GRR', { icon: 'faBuilding', label: 'Grand Rapids (all airports), United States', key: 'C_GRR', type: 'city', code: 'grand-rapids_mi_us' } ],
 ['C_YQU', { icon: 'faBuilding', label: 'Grande Prairie (all airports), Canada', key: 'C_YQU', type: 'city', code: 'grande-prairie_ab_ca' } ],
 ['C_KGX', { icon: 'faBuilding', label: 'Grayling (all airports), United States', key: 'C_KGX', type: 'city', code: 'grayling_ak_us' } ],
 ['C_GRZ', { icon: 'faBuilding', label: 'Graz (all airports), Austria', key: 'C_GRZ', type: 'city', code: 'graz_at' } ],
 ['C_GTF', { icon: 'faBuilding', label: 'Great Falls (all airports), United States', key: 'C_GTF', type: 'city', code: 'great-falls_mt_us' } ],
 ['C_YSB', { icon: 'faBuilding', label: 'Greater Sudbury (all airports), Canada', key: 'C_YSB', type: 'city', code: 'greater-sudbury_on_ca' } ],
 ['C_GRB', { icon: 'faBuilding', label: 'Green Bay (all airports), United States', key: 'C_GRB', type: 'city', code: 'green-bay_wi_us' } ],
 ['C_GSO', { icon: 'faBuilding', label: 'Greensboro (all airports), United States', key: 'C_GSO', type: 'city', code: 'greensboro_nc_us' } ],
 ['C_PGV', { icon: 'faBuilding', label: 'Greenville (all airports), United States', key: 'C_PGV', type: 'city', code: 'greenville_nc_us' } ],
 ['C_GSP', { icon: 'faBuilding', label: 'Greenville (all airports), United States', key: 'C_GSP', type: 'city', code: 'greenville_sc_us' } ],
 ['C_GNB', { icon: 'faBuilding', label: 'Grenoble (all airports), France', key: 'C_GNB', type: 'city', code: 'grenoble_fr' } ],
 ['C_FMO', { icon: 'faBuilding', label: 'Greven (all airports), Germany', key: 'C_FMO', type: 'city', code: 'greven_de' } ],
 ['C_GFF', { icon: 'faBuilding', label: 'Griffith (all airports), Australia', key: 'C_GFF', type: 'city', code: 'griffith_ns_au' } ],
 ['C_YGZ', { icon: 'faBuilding', label: 'Grise Fiord (all airports), Canada', key: 'C_YGZ', type: 'city', code: 'grise-fiord_nu_ca' } ],
 ['C_GNA', { icon: 'faBuilding', label: 'Grodno (all airports), Belarus', key: 'C_GNA', type: 'city', code: 'grodno_by' } ],
 ['C_GRQ', { icon: 'faBuilding', label: 'Groningen (all airports), Netherlands', key: 'C_GRQ', type: 'city', code: 'groningen_nl' } ],
 ['C_GTE', { icon: 'faBuilding', label: 'Groote Eylandt (all airports), Australia', key: 'C_GTE', type: 'city', code: 'groote-eylandt_nt_au' } ],
 ['C_GRS', { icon: 'faBuilding', label: 'Grosseto (all airports), Italy', key: 'C_GRS', type: 'city', code: 'grosseto_it' } ],
 ['C_GRV', { icon: 'faBuilding', label: 'Grozny (all airports), Russia', key: 'C_GRV', type: 'city', code: 'grozny_ru' } ],
 ['C_GDL', { icon: 'faBuilding', label: 'Guadalajara (all airports), Mexico', key: 'C_GDL', type: 'city', code: 'guadalajara_mx' } ],
 ['C_GJA', { icon: 'faBuilding', label: 'Guanaja (all airports), Honduras', key: 'C_GJA', type: 'city', code: 'guanaja_hn' } ],
 ['C_GYS', { icon: 'faBuilding', label: 'Guangyuan (all airports), China', key: 'C_GYS', type: 'city', code: 'guangyuan_cn' } ],
 ['C_CAN', { icon: 'faBuilding', label: 'Guangzhou (all airports), China', key: 'C_CAN', type: 'city', code: 'guangzhou_cn' } ],
 ['C_GAO', { icon: 'faBuilding', label: 'Guantánamo (all airports), Cuba', key: 'C_GAO', type: 'city', code: 'guantanamo_cu' } ],
 ['C_GPI', { icon: 'faBuilding', label: 'Guapi (all airports), Colombia', key: 'C_GPI', type: 'city', code: 'guapi_co' } ],
 ['C_GUA', { icon: 'faBuilding', label: 'Guatemala City (all airports), Guatemala', key: 'C_GUA', type: 'city', code: 'guatemala-city_gt' } ],
 ['C_GYE', { icon: 'faBuilding', label: 'Guayaquil (all airports), Ecuador', key: 'C_GYE', type: 'city', code: 'guayaquil_ec' } ],
 ['C_GYA', { icon: 'faBuilding', label: 'Guayaramerín (all airports), Bolivia', key: 'C_GYA', type: 'city', code: 'guayaramerin_bo' } ],
 ['C_ZFK', { icon: 'faBuilding', label: 'Guderup (all airports), Denmark', key: 'C_ZFK', type: 'city', code: 'guderup_dk' } ],
 ['C_GLN', { icon: 'faBuilding', label: 'Guelmim (all airports), Morocco', key: 'C_GLN', type: 'city', code: 'guelmim_ma' } ],
 ['C_ZEX', { icon: 'faBuilding', label: 'Guetersloh (all airports), Germany', key: 'C_ZEX', type: 'city', code: 'guetersloh_de' } ],
 ['C_KWL', { icon: 'faBuilding', label: 'Guilin (all airports), China', key: 'C_KWL', type: 'city', code: 'guilin_cn' } ],
 ['C_KWE', { icon: 'faBuilding', label: 'Guiyang (all airports), China', key: 'C_KWE', type: 'city', code: 'guiyang_cn' } ],
 ['C_GPT', { icon: 'faBuilding', label: 'Gulfport (all airports), United States', key: 'C_GPT', type: 'city', code: 'gulfport_ms_us' } ],
 ['C_GUC', { icon: 'faBuilding', label: 'Gunnison (all airports), United States', key: 'C_GUC', type: 'city', code: 'gunnison_co_us' } ],
 ['C_KUV', { icon: 'faBuilding', label: 'Gunsan (all airports), South Korea', key: 'C_KUV', type: 'city', code: 'gunsan_kr' } ],
 ['C_MZV', { icon: 'faBuilding', label: 'Gunung Mulu National Park (all airports), Malaysia', key: 'C_MZV', type: 'city', code: 'mulu_my' } ],
 ['C_GNS', { icon: 'faBuilding', label: 'Gunungsitoli (all airports), Indonesia', key: 'C_GNS', type: 'city', code: 'gunungsitoli_id' } ],
 ['C_GUO', { icon: 'faBuilding', label: 'Guri el (all airports), Somalia', key: 'C_GUO', type: 'city', code: 'guri\'el_so' } ],
 ['C_GST', { icon: 'faBuilding', label: 'Gustavus (all airports), United States', key: 'C_GST', type: 'city', code: 'gustavus_ak_us' } ],
 ['C_GAU', { icon: 'faBuilding', label: 'Guwahati (all airports), India', key: 'C_GAU', type: 'city', code: 'guwahati_in' } ],
 ['C_GYU', { icon: 'faBuilding', label: 'Guyuan County (all airports), China', key: 'C_GYU', type: 'city', code: 'guyuan-county_cn' } ],
 ['C_GWD', { icon: 'faBuilding', label: 'Gwadar (all airports), Pakistan', key: 'C_GWD', type: 'city', code: 'gwadar_pk' } ],
 ['C_GWL', { icon: 'faBuilding', label: 'Gwalior (all airports), India', key: 'C_GWL', type: 'city', code: 'gwalior_in' } ],
 ['C_KWJ', { icon: 'faBuilding', label: 'Gwangju (all airports), South Korea', key: 'C_KWJ', type: 'city', code: 'gwangju_kr' } ],
 ['C_LWN', { icon: 'faBuilding', label: 'Gyumri (all airports), Armenia', key: 'C_LWN', type: 'city', code: 'gyumri_am' } ],
 ['C_HAS', { icon: 'faBuilding', label: 'Ha il (all airports), Saudi Arabia', key: 'C_HAS', type: 'city', code: 'hail_sa' } ],
 ['C_HAC', { icon: 'faBuilding', label: 'Hachijō-jima (all airports), Japan', key: 'C_HAC', type: 'city', code: 'hachijo-jima_jp' } ],
 ['C_GUM', { icon: 'faBuilding', label: 'Hagåtña (all airports), United States', key: 'C_GUM', type: 'city', code: 'hagatna_gu' } ],
 ['C_HGR', { icon: 'faBuilding', label: 'Hagerstown (all airports), United States', key: 'C_HGR', type: 'city', code: 'hagerstown_md_us' } ],
 ['C_HFA', { icon: 'faBuilding', label: 'Haifa (all airports), Israel', key: 'C_HFA', type: 'city', code: 'haifa_il' } ],
 ['C_HAK', { icon: 'faBuilding', label: 'Haikou (all airports), China', key: 'C_HAK', type: 'city', code: 'haikou_cn' } ],
 ['C_HLD', { icon: 'faBuilding', label: 'Hailar (all airports), China', key: 'C_HLD', type: 'city', code: 'hailar_cn' } ],
 ['C_HNS', { icon: 'faBuilding', label: 'Haines (all airports), United States', key: 'C_HNS', type: 'city', code: 'haines_ak_us' } ],
 ['C_HPH', { icon: 'faBuilding', label: 'Haiphong (all airports), Vietnam', key: 'C_HPH', type: 'city', code: 'haiphong_vn' } ],
 ['C_YKO', { icon: 'faBuilding', label: 'Hakkâri (all airports), Turkey', key: 'C_YKO', type: 'city', code: 'hakkari_tr' } ],
 ['C_HKD', { icon: 'faBuilding', label: 'Hakodate (all airports), Japan', key: 'C_HKD', type: 'city', code: 'hakodate_jp' } ],
 ['C_YHZ', { icon: 'faBuilding', label: 'Halifax (all airports), Canada', key: 'C_YHZ', type: 'city', code: 'halifax_ns_ca' } ],
 ['C_YUX', { icon: 'faBuilding', label: 'Hall Beach (all airports), Canada', key: 'C_YUX', type: 'city', code: 'hall-beach_nu_ca' } ],
 ['C_HAD', { icon: 'faBuilding', label: 'Halmstad (all airports), Sweden', key: 'C_HAD', type: 'city', code: 'halmstad_se' } ],
 ['C_HDM', { icon: 'faBuilding', label: 'Hamadan (all airports), Iran', key: 'C_HDM', type: 'city', code: 'hamadan_ir' } ],
 ['C_HRI', { icon: 'faBuilding', label: 'Hambantota (all airports), Sri Lanka', key: 'C_HRI', type: 'city', code: 'hambantota_lk' } ],
 ['C_HAM', { icon: 'faBuilding', label: 'Hamburg (all airports), Germany', key: 'C_HAM', type: 'city', code: 'hamburg_de' } ],
 ['C_HMI', { icon: 'faBuilding', label: 'Hami (all airports), China', key: 'C_HMI', type: 'city', code: 'hami-city_cn' } ],
 ['C_HLZ', { icon: 'faBuilding', label: 'Hamilton (all airports), New Zealand', key: 'C_HLZ', type: 'city', code: 'hamilton_nz' } ],
 ['C_YHM', { icon: 'faBuilding', label: 'Hamilton (all airports), Canada', key: 'C_YHM', type: 'city', code: 'mount-hope_on_ca' } ],
 ['C_HTI', { icon: 'faBuilding', label: 'Hamilton Island (all airports), Australia', key: 'C_HTI', type: 'city', code: 'hamilton-island_ql_au' } ],
 ['C_HFT', { icon: 'faBuilding', label: 'Hammerfest (all airports), Norway', key: 'C_HFT', type: 'city', code: 'hammerfest_no' } ],
 ['C_HNM', { icon: 'faBuilding', label: 'Hana (all airports), United States', key: 'C_HNM', type: 'city', code: 'hana_hi_us' } ],
 ['C_HNA', { icon: 'faBuilding', label: 'Hanamaki (all airports), Japan', key: 'C_HNA', type: 'city', code: 'hanamaki_jp' } ],
 ['C_CMX', { icon: 'faBuilding', label: 'Hancock (all airports), United States', key: 'C_CMX', type: 'city', code: 'hancock_mi_us' } ],
 ['C_HDG', { icon: 'faBuilding', label: 'Handan (all airports), China', key: 'C_HDG', type: 'city', code: 'handan_cn' } ],
 ['C_HGH', { icon: 'faBuilding', label: 'Hangzhou (all airports), China', key: 'C_HGH', type: 'city', code: 'hangzhou_cn' } ],
 ['C_HAN', { icon: 'faBuilding', label: 'Hanoi (all airports), Vietnam', key: 'C_HAN', type: 'city', code: 'hanoi_vn' } ],
 ['C_HAJ', { icon: 'faBuilding', label: 'Hanover (all airports), Germany', key: 'C_HAJ', type: 'city', code: 'hanover_de' } ],
 ['C_HZG', { icon: 'faBuilding', label: 'Hanzhong (all airports), China', key: 'C_HZG', type: 'city', code: 'hanzhong_cn' } ],
 ['C_HOI', { icon: 'faBuilding', label: 'Hao (all airports), French Polynesia', key: 'C_HOI', type: 'city', code: 'hao-island_pf' } ],
 ['C_YYR', { icon: 'faBuilding', label: 'Happy Valley-Goose Bay (all airports), Canada', key: 'C_YYR', type: 'city', code: 'happy-valley-goose-bay_nl_ca' } ],
 ['C_HRE', { icon: 'faBuilding', label: 'Harare (all airports), Zimbabwe', key: 'C_HRE', type: 'city', code: 'harare_zw' } ],
 ['C_HRB', { icon: 'faBuilding', label: 'Harbin (all airports), China', key: 'C_HRB', type: 'city', code: 'harbin_cn' } ],
 ['C_HGA', { icon: 'faBuilding', label: 'Hargeisa (all airports), Somalia', key: 'C_HGA', type: 'city', code: 'hargeisa_so' } ],
 ['C_HRL', { icon: 'faBuilding', label: 'Harlingen (all airports), United States', key: 'C_HRL', type: 'city', code: 'harlingen_tx_us' } ],
 ['C_HAR', { icon: 'faBuilding', label: 'Harrisburg (all airports), United States', key: 'C_HAR', type: 'city', code: 'harrisburg_pa_us' } ],
 ['C_HRO', { icon: 'faBuilding', label: 'Harrison (all airports), United States', key: 'C_HRO', type: 'city', code: 'harrison_ar_us' } ],
 ['C_HFD', { icon: 'faBuilding', label: 'Hartford (all airports), United States', key: 'C_HFD', type: 'city', code: 'hartford_ct_us' } ],
 ['C_HME', { icon: 'faBuilding', label: 'Hassi Messaoud (all airports), Algeria', key: 'C_HME', type: 'city', code: 'hassi-messaoud_dz' } ],
 ['C_HAA', { icon: 'faBuilding', label: 'Hasvik (all airports), Norway', key: 'C_HAA', type: 'city', code: 'hasvik_no' } ],
 ['C_HDY', { icon: 'faBuilding', label: 'Hat Yai (all airports), Thailand', key: 'C_HDY', type: 'city', code: 'hat-yai_th' } ],
 ['C_HTY', { icon: 'faBuilding', label: 'Hatay Province (all airports), Turkey', key: 'C_HTY', type: 'city', code: 'hatay_tr' } ],
 ['C_NUF', { icon: 'faBuilding', label: 'Hatton (all airports), Sri Lanka', key: 'C_NUF', type: 'city', code: 'hatton_lk' } ],
 ['C_HAU', { icon: 'faBuilding', label: 'Haugesund (all airports), Norway', key: 'C_HAU', type: 'city', code: 'haugesund_no' } ],
 ['C_HAV', { icon: 'faBuilding', label: 'Havana (all airports), Cuba', key: 'C_HAV', type: 'city', code: 'havana_cu' } ],
 ['C_HVR', { icon: 'faBuilding', label: 'Havre (all airports), United States', key: 'C_HVR', type: 'city', code: 'havre_mt_us' } ],
 ['C_YHY', { icon: 'faBuilding', label: 'Hay River (all airports), Canada', key: 'C_YHY', type: 'city', code: 'hay-river_nt_ca' } ],
 ['C_HDN', { icon: 'faBuilding', label: 'Hayden (all airports), United States', key: 'C_HDN', type: 'city', code: 'hayden_co_us' } ],
 ['C_HIS', { icon: 'faBuilding', label: 'Hayman Island (all airports), Australia', key: 'C_HIS', type: 'city', code: 'hayman-island_ql_au' } ],
 ['C_HYS', { icon: 'faBuilding', label: 'Hays (all airports), United States', key: 'C_HYS', type: 'city', code: 'hays_ks_us' } ],
 ['C_HKB', { icon: 'faBuilding', label: 'Healy Lake (all airports), United States', key: 'C_HKB', type: 'city', code: 'healy-lake_ak_us' } ],
 ['C_QYZ', { icon: 'faBuilding', label: 'Heerenveen (all airports), Netherlands', key: 'C_QYZ', type: 'city', code: 'heerenveen_nl' } ],
 ['C_HFE', { icon: 'faBuilding', label: 'Hefei (all airports), China', key: 'C_HFE', type: 'city', code: 'hefei_cn' } ],
 ['C_HEH', { icon: 'faBuilding', label: 'Heho (all airports), Myanmar (Burma)', key: 'C_HEH', type: 'city', code: 'heho_mm' } ],
 ['C_QHD', { icon: 'faBuilding', label: 'Heidelberg (all airports), Germany', key: 'C_QHD', type: 'city', code: 'heidelberg_de' } ],
 ['C_HEK', { icon: 'faBuilding', label: 'Heihe (all airports), China', key: 'C_HEK', type: 'city', code: 'heihe_cn' } ],
 ['C_HLN', { icon: 'faBuilding', label: 'Helena (all airports), United States', key: 'C_HLN', type: 'city', code: 'helena_mt_us' } ],
 ['C_HEL', { icon: 'faBuilding', label: 'Helsinki (all airports), Finland', key: 'C_HEL', type: 'city', code: 'helsinki_fi' } ],
 ['C_HMV', { icon: 'faBuilding', label: 'Hemavan (all airports), Sweden', key: 'C_HMV', type: 'city', code: 'hemavan_se' } ],
 ['C_HNY', { icon: 'faBuilding', label: 'Hengyang (all airports), China', key: 'C_HNY', type: 'city', code: 'hengyang_cn' } ],
 ['C_HER', { icon: 'faBuilding', label: 'Heraklion (all airports), Greece', key: 'C_HER', type: 'city', code: 'heraklion_gr' } ],
 ['C_HEA', { icon: 'faBuilding', label: 'Herat (all airports), Afghanistan', key: 'C_HEA', type: 'city', code: 'herat_af' } ],
 ['C_HDF', { icon: 'faBuilding', label: 'Heringsdorf (all airports), Germany', key: 'C_HDF', type: 'city', code: 'heringsdorf_de' } ],
 ['C_HMO', { icon: 'faBuilding', label: 'Hermosillo (all airports), Mexico', key: 'C_HMO', type: 'city', code: 'hermosillo_mx' } ],
 ['C_ZRY', { icon: 'faBuilding', label: 'Herning (all airports), Denmark', key: 'C_ZRY', type: 'city', code: 'herning_dk' } ],
 ['C_HVB', { icon: 'faBuilding', label: 'Hervey Bay (all airports), Australia', key: 'C_HVB', type: 'city', code: 'hervey-bay_ql_au' } ],
 ['C_XHI', { icon: 'faBuilding', label: 'Herzogenaurach (all airports), Germany', key: 'C_XHI', type: 'city', code: 'herzogenaurach_de' } ],
 ['C_SOB', { icon: 'faBuilding', label: 'Hévíz (all airports), Hungary', key: 'C_SOB', type: 'city', code: 'heviz_hu' } ],
 ['C_HIB', { icon: 'faBuilding', label: 'Hibbing (all airports), United States', key: 'C_HIB', type: 'city', code: 'hibbing_mn_us' } ],
 ['C_YOJ', { icon: 'faBuilding', label: 'High Level (all airports), Canada', key: 'C_YOJ', type: 'city', code: 'high-level_ab_ca' } ],
 ['C_HHZ', { icon: 'faBuilding', label: 'Hikueru (all airports), French Polynesia', key: 'C_HHZ', type: 'city', code: 'hikueru_pf' } ],
 ['C_ITO', { icon: 'faBuilding', label: 'Hilo (all airports), United States', key: 'C_ITO', type: 'city', code: 'hilo_hi_us' } ],
 ['C_HHH', { icon: 'faBuilding', label: 'Hilton Head Island (all airports), United States', key: 'C_HHH', type: 'city', code: 'hilton-head-island_sc_us' } ],
 ['C_HIJ', { icon: 'faBuilding', label: 'Hiroshima (all airports), Japan', key: 'C_HIJ', type: 'city', code: 'hiroshima_jp' } ],
 ['C_XAJ', { icon: 'faBuilding', label: 'Hirtshals (all airports), Denmark', key: 'C_XAJ', type: 'city', code: 'hirtshals_dk' } ],
 ['C_AUQ', { icon: 'faBuilding', label: 'Hiva Oa (all airports), French Polynesia', key: 'C_AUQ', type: 'city', code: 'hiva-oa_pf' } ],
 ['C_QHJ', { icon: 'faBuilding', label: 'Hjorring (all airports), Denmark', key: 'C_QHJ', type: 'city', code: 'hjorring_dk' } ],
 ['C_SGN', { icon: 'faBuilding', label: 'Ho Chi Minh City (all airports), Vietnam', key: 'C_SGN', type: 'city', code: 'ho-chi-minh-city_vn' } ],
 ['C_HBA', { icon: 'faBuilding', label: 'Hobart (all airports), Australia', key: 'C_HBA', type: 'city', code: 'hobart_ts_au' } ],
 ['C_HOB', { icon: 'faBuilding', label: 'Hobbs (all airports), United States', key: 'C_HOB', type: 'city', code: 'hobbs_nm_us' } ],
 ['C_HOD', { icon: 'faBuilding', label: 'Hodeida (all airports), Yemen', key: 'C_HOD', type: 'city', code: 'hodeida_ye' } ],
 ['C_HDS', { icon: 'faBuilding', label: 'Hoedspruit, Limpopo (all airports), South Africa', key: 'C_HDS', type: 'city', code: 'hoedspruit_za' } ],
 ['C_HOF', { icon: 'faBuilding', label: 'Hofuf (all airports), Saudi Arabia', key: 'C_HOF', type: 'city', code: 'hofuf_sa' } ],
 ['C_null', { icon: 'faBuilding', label: 'Hohenems / Dornbirn (all airports), Austria', key: 'C_null', type: 'city', code: 'hohenems-dornbirn_at' } ],
 ['C_HET', { icon: 'faBuilding', label: 'Hohhot (all airports), China', key: 'C_HET', type: 'city', code: 'hohhot_cn' } ],
 ['C_MKK', { icon: 'faBuilding', label: 'Hoʻolehua (all airports), United States', key: 'C_MKK', type: 'city', code: 'ho-olehua_hi_us' } ],
 ['C_HKK', { icon: 'faBuilding', label: 'Hokitika (all airports), New Zealand', key: 'C_HKK', type: 'city', code: 'hokitika_nz' } ],
 ['C_HOG', { icon: 'faBuilding', label: 'Holguín (all airports), Cuba', key: 'C_HOG', type: 'city', code: 'holguin_cu' } ],
 ['C_HYL', { icon: 'faBuilding', label: 'Hollis (all airports), United States', key: 'C_HYL', type: 'city', code: 'hollis_ak_us' } ],
 ['C_QWO', { icon: 'faBuilding', label: 'Holstebro (all airports), Denmark', key: 'C_QWO', type: 'city', code: 'holstebro_dk' } ],
 ['C_HCR', { icon: 'faBuilding', label: 'Holy Cross (all airports), United States', key: 'C_HCR', type: 'city', code: 'holy-cross_ak_us' } ],
 ['C_HOX', { icon: 'faBuilding', label: 'Homalin (all airports), Myanmar (Burma)', key: 'C_HOX', type: 'city', code: 'homalin_mm' } ],
 ['C_CCK', { icon: 'faBuilding', label: 'Home Island (all airports), Cocos (Keeling) Islands', key: 'C_CCK', type: 'city', code: 'home-island_cc' } ],
 ['C_HOM', { icon: 'faBuilding', label: 'Homer (all airports), United States', key: 'C_HOM', type: 'city', code: 'homer_ak_us' } ],
 ['C_HKG', { icon: 'faBuilding', label: 'Hong Kong (all airports), China', key: 'C_HKG', type: 'city', code: 'hong-kong_cn' } ],
 ['C_HIR', { icon: 'faBuilding', label: 'Honiara (all airports), Solomon Islands', key: 'C_HIR', type: 'city', code: 'honiara_sb' } ],
 ['C_HVG', { icon: 'faBuilding', label: 'Honningsvåg (all airports), Norway', key: 'C_HVG', type: 'city', code: 'honningsvag_no' } ],
 ['C_HNL', { icon: 'faBuilding', label: 'Honolulu (all airports), United States', key: 'C_HNL', type: 'city', code: 'honolulu_hi_us' } ],
 ['C_HNH', { icon: 'faBuilding', label: 'Hoonah (all airports), United States', key: 'C_HNH', type: 'city', code: 'hoonah_ak_us' } ],
 ['C_HPB', { icon: 'faBuilding', label: 'Hooper Bay (all airports), United States', key: 'C_HPB', type: 'city', code: 'hooper-bay_ak_us' } ],
 ['C_YHO', { icon: 'faBuilding', label: 'Hopedale (all airports), Canada', key: 'C_YHO', type: 'city', code: 'hopedale_nl_ca' } ],
 ['C_HID', { icon: 'faBuilding', label: 'Horn Island (all airports), Australia', key: 'C_HID', type: 'city', code: 'horn-island_ql_au' } ],
 ['C_ZIL', { icon: 'faBuilding', label: 'Horsens (all airports), Denmark', key: 'C_ZIL', type: 'city', code: 'horsens_dk' } ],
 ['C_HOR', { icon: 'faBuilding', label: 'Horta, Azores (all airports), Portugal', key: 'C_HOR', type: 'city', code: 'horta_pt' } ],
 ['C_HKN', { icon: 'faBuilding', label: 'Hoskins (all airports), Papua New Guinea', key: 'C_HKN', type: 'city', code: 'hoskins_pg' } ],
 ['C_HOT', { icon: 'faBuilding', label: 'Hot Springs (all airports), United States', key: 'C_HOT', type: 'city', code: 'hot-springs_ar_us' } ],
 ['C_HTN', { icon: 'faBuilding', label: 'Hotan (all airports), China', key: 'C_HTN', type: 'city', code: 'hotan_cn' } ],
 ['C_HOU', { icon: 'faBuilding', label: 'Houston (all airports), United States', key: 'C_HOU', type: 'city', code: 'houston_tx_us' } ],
 ['C_HVD', { icon: 'faBuilding', label: 'Hovd (all airports), Mongolia', key: 'C_HVD', type: 'city', code: 'hovd_mn' } ],
 ['C_HHQ', { icon: 'faBuilding', label: 'Hua Hin District (all airports), Thailand', key: 'C_HHQ', type: 'city', code: 'hua-hin-district_th' } ],
 ['C_HUH', { icon: 'faBuilding', label: 'Huahine (all airports), French Polynesia', key: 'C_HUH', type: 'city', code: 'huahine_pf' } ],
 ['C_HIA', { icon: 'faBuilding', label: 'Huai an (all airports), China', key: 'C_HIA', type: 'city', code: 'huaian_cn' } ],
 ['C_HJJ', { icon: 'faBuilding', label: 'Huaihua (all airports), China', key: 'C_HJJ', type: 'city', code: 'huaihua_cn' } ],
 ['C_HUN', { icon: 'faBuilding', label: 'Hualien County (all airports), Taiwan', key: 'C_HUN', type: 'city', code: 'hualien-county_tw' } ],
 ['C_NOV', { icon: 'faBuilding', label: 'Huambo (all airports), Angola', key: 'C_NOV', type: 'city', code: 'huambo_ao' } ],
 ['C_KJH', { icon: 'faBuilding', label: 'Huangping County (all airports), China', key: 'C_KJH', type: 'city', code: 'huangping-county_cn' } ],
 ['C_TXN', { icon: 'faBuilding', label: 'Huangshan City (all airports), China', key: 'C_TXN', type: 'city', code: 'huangshan-city_cn' } ],
 ['C_HUU', { icon: 'faBuilding', label: 'Huánuco (all airports), Peru', key: 'C_HUU', type: 'city', code: 'huanuco_pe' } ],
 ['C_ATA', { icon: 'faBuilding', label: 'Huaraz (all airports), Peru', key: 'C_ATA', type: 'city', code: 'huaraz_pe' } ],
 ['C_HUX', { icon: 'faBuilding', label: 'Huatulco (all airports), Mexico', key: 'C_HUX', type: 'city', code: 'huatulco_mx' } ],
 ['C_HBX', { icon: 'faBuilding', label: 'Hubli (all airports), India', key: 'C_HBX', type: 'city', code: 'hubli_in' } ],
 ['C_HUI', { icon: 'faBuilding', label: 'Hue (all airports), Vietnam', key: 'C_HUI', type: 'city', code: 'hue_vn' } ],
 ['C_HGD', { icon: 'faBuilding', label: 'Hughenden (all airports), Australia', key: 'C_HGD', type: 'city', code: 'hughenden_ql_au' } ],
 ['C_HUS', { icon: 'faBuilding', label: 'Hughes (all airports), United States', key: 'C_HUS', type: 'city', code: 'hughes_ak_us' } ],
 ['C_HUZ', { icon: 'faBuilding', label: 'Huizhou (all airports), China', key: 'C_HUZ', type: 'city', code: 'huizhou_cn' } ],
 ['C_HUE', { icon: 'faBuilding', label: 'Humera (all airports), Ethiopia', key: 'C_HUE', type: 'city', code: 'humera_et' } ],
 ['C_HTS', { icon: 'faBuilding', label: 'Huntington (all airports), United States', key: 'C_HTS', type: 'city', code: 'huntington_wv_us' } ],
 ['C_HSV', { icon: 'faBuilding', label: 'Huntsville (all airports), United States', key: 'C_HSV', type: 'city', code: 'huntsville_al_us' } ],
 ['C_HRG', { icon: 'faBuilding', label: 'Hurghada (all airports), Egypt', key: 'C_HRG', type: 'city', code: 'hurghada_eg' } ],
 ['C_HSL', { icon: 'faBuilding', label: 'Huslia (all airports), United States', key: 'C_HSL', type: 'city', code: 'huslia_ak_us' } ],
 ['C_HYA', { icon: 'faBuilding', label: 'Hyannis (all airports), United States', key: 'C_HYA', type: 'city', code: 'hyannis_ma_us' } ],
 ['C_HYG', { icon: 'faBuilding', label: 'Hydaburg (all airports), United States', key: 'C_HYG', type: 'city', code: 'hydaburg_ak_us' } ],
 ['C_HYD', { icon: 'faBuilding', label: 'Hyderabad (all airports), India', key: 'C_HYD', type: 'city', code: 'hyderabad_in' } ],
 ['C_TLN', { icon: 'faBuilding', label: 'Hyeres (all airports), France', key: 'C_TLN', type: 'city', code: 'hyeres_fr' } ],
 ['C_MXH', { icon: 'faBuilding', label: 'Iagifu Ridge Camp (all airports), Papua New Guinea', key: 'C_MXH', type: 'city', code: 'moro_pg' } ],
 ['C_IAS', { icon: 'faBuilding', label: 'Iași (all airports), Romania', key: 'C_IAS', type: 'city', code: 'iasi_ro' } ],
 ['C_IBA', { icon: 'faBuilding', label: 'Ibadan (all airports), Nigeria', key: 'C_IBA', type: 'city', code: 'ibadan_ng' } ],
 ['C_IBE', { icon: 'faBuilding', label: 'Ibagué (all airports), Colombia', key: 'C_IBE', type: 'city', code: 'ibague_co' } ],
 ['C_IBR', { icon: 'faBuilding', label: 'Ibaraki (all airports), Japan', key: 'C_IBR', type: 'city', code: 'ibaraki_jp' } ],
 ['C_IBZ', { icon: 'faBuilding', label: 'Ibiza (all airports), Spain', key: 'C_IBZ', type: 'city', code: 'ibiza_es' } ],
 ['C_JIK', { icon: 'faBuilding', label: 'Icaria (all airports), Greece', key: 'C_JIK', type: 'city', code: 'icaria_gr' } ],
 ['C_IDA', { icon: 'faBuilding', label: 'Idaho Falls (all airports), United States', key: 'C_IDA', type: 'city', code: 'idaho-falls_id_us' } ],
 ['C_IAA', { icon: 'faBuilding', label: 'Igarka (all airports), Russia', key: 'C_IAA', type: 'city', code: 'igarka_ru' } ],
 ['C_IGD', { icon: 'faBuilding', label: 'Iğdır (all airports), Turkey', key: 'C_IGD', type: 'city', code: 'igdir_tr' } ],
 ['C_IGG', { icon: 'faBuilding', label: 'Igiugig (all airports), United States', key: 'C_IGG', type: 'city', code: 'igiugig_ak_us' } ],
 ['C_YGT', { icon: 'faBuilding', label: 'Igloolik (all airports), Canada', key: 'C_YGT', type: 'city', code: 'igloolik_nu_ca' } ],
 ['C_IRM', { icon: 'faBuilding', label: 'Igrim (all airports), Russia', key: 'C_IRM', type: 'city', code: 'igrim_ru' } ],
 ['C_QLZ', { icon: 'faBuilding', label: 'Ikast (all airports), Denmark', key: 'C_QLZ', type: 'city', code: 'ikast_dk' } ],
 ['C_IKI', { icon: 'faBuilding', label: 'Iki (all airports), Japan', key: 'C_IKI', type: 'city', code: 'iki_jp' } ],
 ['C_IIL', { icon: 'faBuilding', label: 'Ilam (all airports), Iran', key: 'C_IIL', type: 'city', code: 'ilam_ir' } ],
 ['C_SMS', { icon: 'faBuilding', label: 'Île Sainte-Marie (all airports), Madagascar', key: 'C_SMS', type: 'city', code: 'ile-sainte-marie_mg' } ],
 ['C_IOS', { icon: 'faBuilding', label: 'Ilhéus (all airports), Brazil', key: 'C_IOS', type: 'city', code: 'ilheus_ba_br' } ],
 ['C_ILI', { icon: 'faBuilding', label: 'Iliamna (all airports), United States', key: 'C_ILI', type: 'city', code: 'iliamna_ak_us' } ],
 ['C_VVZ', { icon: 'faBuilding', label: 'Illizi (all airports), Algeria', key: 'C_VVZ', type: 'city', code: 'illizi_dz' } ],
 ['C_ILO', { icon: 'faBuilding', label: 'Iloilo City (all airports), Philippines', key: 'C_ILO', type: 'city', code: 'iloilo-city_ph' } ],
 ['C_ILR', { icon: 'faBuilding', label: 'Ilorin (all airports), Nigeria', key: 'C_ILR', type: 'city', code: 'ilorin_ng' } ],
 ['C_JAV', { icon: 'faBuilding', label: 'Ilulissat (all airports), Greenland', key: 'C_JAV', type: 'city', code: 'ilulissat_gl' } ],
 ['C_IMP', { icon: 'faBuilding', label: 'Imperatriz (all airports), Brazil', key: 'C_IMP', type: 'city', code: 'imperatriz_ma_br' } ],
 ['C_IMF', { icon: 'faBuilding', label: 'Imphal (all airports), India', key: 'C_IMF', type: 'city', code: 'imphal_in' } ],
 ['C_IAM', { icon: 'faBuilding', label: 'In Amenas (all airports), Algeria', key: 'C_IAM', type: 'city', code: 'in-amenas_dz' } ],
 ['C_INZ', { icon: 'faBuilding', label: 'In Salah (all airports), Algeria', key: 'C_INZ', type: 'city', code: 'in-salah_dz' } ],
 ['C_IGA', { icon: 'faBuilding', label: 'Inagua (all airports), Bahamas', key: 'C_IGA', type: 'city', code: 'inagua_bs' } ],
 ['C_INB', { icon: 'faBuilding', label: 'Independence and Mango Creek (all airports), Belize', key: 'C_INB', type: 'city', code: 'independence-and-mango-creek_bz' } ],
 ['C_IND', { icon: 'faBuilding', label: 'Indianapolis (all airports), United States', key: 'C_IND', type: 'city', code: 'indianapolis_in_us' } ],
 ['C_IDR', { icon: 'faBuilding', label: 'Indore (all airports), India', key: 'C_IDR', type: 'city', code: 'indore_in' } ],
 ['C_IGS', { icon: 'faBuilding', label: 'Ingolstadt (all airports), Germany', key: 'C_IGS', type: 'city', code: 'ingolstadt_de' } ],
 ['C_INH', { icon: 'faBuilding', label: 'Inhambane (all airports), Mozambique', key: 'C_INH', type: 'city', code: 'inhambane_mz' } ],
 ['C_PDA', { icon: 'faBuilding', label: 'Inírida, Guainía (all airports), Colombia', key: 'C_PDA', type: 'city', code: 'inirida_co' } ],
 ['C_INN', { icon: 'faBuilding', label: 'Innsbruck (all airports), Austria', key: 'C_INN', type: 'city', code: 'innsbruck_at' } ],
 ['C_INA', { icon: 'faBuilding', label: 'Inta (all airports), Russia', key: 'C_INA', type: 'city', code: 'inta_ru' } ],
 ['C_INL', { icon: 'faBuilding', label: 'International Falls (all airports), United States', key: 'C_INL', type: 'city', code: 'international-falls_mn_us' } ],
 ['C_YPH', { icon: 'faBuilding', label: 'Inukjuak (all airports), Canada', key: 'C_YPH', type: 'city', code: 'inukjuak_qc_ca' } ],
 ['C_YEV', { icon: 'faBuilding', label: 'Inuvik (all airports), Canada', key: 'C_YEV', type: 'city', code: 'inuvik_nt_ca' } ],
 ['C_IVC', { icon: 'faBuilding', label: 'Invercargill (all airports), New Zealand', key: 'C_IVC', type: 'city', code: 'invercargill_nz' } ],
 ['C_INV', { icon: 'faBuilding', label: 'Inverness (all airports), United Kingdom', key: 'C_INV', type: 'city', code: 'inverness_gb' } ],
 ['C_IOA', { icon: 'faBuilding', label: 'Ioannina (all airports), Greece', key: 'C_IOA', type: 'city', code: 'ioannina_gr' } ],
 ['C_IPN', { icon: 'faBuilding', label: 'Ipatinga (all airports), Brazil', key: 'C_IPN', type: 'city', code: 'ipatinga_mg_br' } ],
 ['C_IPI', { icon: 'faBuilding', label: 'Ipiales (all airports), Colombia', key: 'C_IPI', type: 'city', code: 'ipiales_co' } ],
 ['C_IPH', { icon: 'faBuilding', label: 'Ipoh (all airports), Malaysia', key: 'C_IPH', type: 'city', code: 'ipoh_my' } ],
 ['C_IPA', { icon: 'faBuilding', label: 'Ipota (all airports), Vanuatu', key: 'C_IPA', type: 'city', code: 'ipota_vu' } ],
 ['C_YFB', { icon: 'faBuilding', label: 'Iqaluit (all airports), Canada', key: 'C_YFB', type: 'city', code: 'iqaluit_nu_ca' } ],
 ['C_IQQ', { icon: 'faBuilding', label: 'Iquique (all airports), Chile', key: 'C_IQQ', type: 'city', code: 'iquique_cl' } ],
 ['C_IQT', { icon: 'faBuilding', label: 'Iquitos (all airports), Peru', key: 'C_IQT', type: 'city', code: 'iquitos_pe' } ],
 ['C_IHR', { icon: 'faBuilding', label: 'Iranshahr (all airports), Iran', key: 'C_IHR', type: 'city', code: 'iranshahr_ir' } ],
 ['C_IKT', { icon: 'faBuilding', label: 'Irkutsk (all airports), Russia', key: 'C_IKT', type: 'city', code: 'irkutsk_ru' } ],
 ['C_IMT', { icon: 'faBuilding', label: 'Iron Mountain (all airports), United States', key: 'C_IMT', type: 'city', code: 'iron-mountain_mi_us' } ],
 ['C_IWD', { icon: 'faBuilding', label: 'Ironwood (all airports), United States', key: 'C_IWD', type: 'city', code: 'ironwood_mi_us' } ],
 ['C_IFJ', { icon: 'faBuilding', label: 'Ísafjörður (all airports), Iceland', key: 'C_IFJ', type: 'city', code: 'isafjordur_is' } ],
 ['C_XOI', { icon: 'faBuilding', label: 'Ischgl (all airports), Austria', key: 'C_XOI', type: 'city', code: 'ischgl_at' } ],
 ['C_IFN', { icon: 'faBuilding', label: 'Isfahan (all airports), Iran', key: 'C_IFN', type: 'city', code: 'isfahan_ir' } ],
 ['C_ISG', { icon: 'faBuilding', label: 'Ishigaki, Okinawa (all airports), Japan', key: 'C_ISG', type: 'city', code: 'ishigaki_jp' } ],
 ['C_IRP', { icon: 'faBuilding', label: 'Isiro (all airports), Democratic Republic of the Congo', key: 'C_IRP', type: 'city', code: 'isiro_cd' } ],
 ['C_SIC', { icon: 'faBuilding', label: 'Isla San José (all airports), Panama', key: 'C_SIC', type: 'city', code: 'san-jose-island_pa' } ],
 ['C_ISB', { icon: 'faBuilding', label: 'Islamabad (all airports), Pakistan', key: 'C_ISB', type: 'city', code: 'islamabad_pk' } ],
 ['C_YIV', { icon: 'faBuilding', label: 'Island Lake, Manitoba (all airports), Canada', key: 'C_YIV', type: 'city', code: 'island-lake--manitoba_mb_ca' } ],
 ['C_ILY', { icon: 'faBuilding', label: 'Islay (all airports), United Kingdom', key: 'C_ILY', type: 'city', code: 'islay_gb' } ],
 ['C_ILP', { icon: 'faBuilding', label: 'Isle of Pines (all airports), New Caledonia', key: 'C_ILP', type: 'city', code: 'isle-of-pines_nc' } ],
 ['C_ISP', { icon: 'faBuilding', label: 'Islip (all airports), United States', key: 'C_ISP', type: 'city', code: 'islip_ny_us' } ],
 ['C_ISE', { icon: 'faBuilding', label: 'Isparta (all airports), Turkey', key: 'C_ISE', type: 'city', code: 'isparta_tr' } ],
 ['C_IST', { icon: 'faBuilding', label: 'Istanbul (all airports), Turkey', key: 'C_IST', type: 'city', code: 'istanbul_tr' } ],
 ['C_ITH', { icon: 'faBuilding', label: 'Ithaca (all airports), United States', key: 'C_ITH', type: 'city', code: 'ithaca_ny_us' } ],
 ['C_ITU', { icon: 'faBuilding', label: 'Iturup (all airports), Russia', key: 'C_ITU', type: 'city', code: 'iturup_ru' } ],
 ['C_IVL', { icon: 'faBuilding', label: 'Ivalo (all airports), Finland', key: 'C_IVL', type: 'city', code: 'ivalo_fi' } ],
 ['C_IFO', { icon: 'faBuilding', label: 'Ivano-Frankivsk (all airports), Ukraine', key: 'C_IFO', type: 'city', code: 'ivano-frankivsk_ua' } ],
 ['C_IWA', { icon: 'faBuilding', label: 'Ivanovo (all airports), Russia', key: 'C_IWA', type: 'city', code: 'ivanovo_ru' } ],
 ['C_YIK', { icon: 'faBuilding', label: 'Ivujivik (all airports), Canada', key: 'C_YIK', type: 'city', code: 'ivujivik_qc_ca' } ],
 ['C_ZIH', { icon: 'faBuilding', label: 'Ixtapa (all airports), Mexico', key: 'C_ZIH', type: 'city', code: 'ixtapa_mx' } ],
 ['C_IZT', { icon: 'faBuilding', label: 'Ixtepec, Oaxaca (all airports), Mexico', key: 'C_IZT', type: 'city', code: 'ixtepec,-oaxaca_mx' } ],
 ['C_IJK', { icon: 'faBuilding', label: 'Izhevsk (all airports), Russia', key: 'C_IJK', type: 'city', code: 'izhevsk_ru' } ],
 ['C_IZM', { icon: 'faBuilding', label: 'İzmir (all airports), Turkey', key: 'C_IZM', type: 'city', code: 'izmir_tr' } ],
 ['C_KCO', { icon: 'faBuilding', label: 'İzmit (all airports), Turkey', key: 'C_KCO', type: 'city', code: 'kocaeli-province_tr' } ],
 ['C_IZO', { icon: 'faBuilding', label: 'Izumo (all airports), Japan', key: 'C_IZO', type: 'city', code: 'izumo_jp' } ],
 ['C_JLR', { icon: 'faBuilding', label: 'Jabalpur (all airports), India', key: 'C_JLR', type: 'city', code: 'jabalpur_in' } ],
 ['C_JAN', { icon: 'faBuilding', label: 'Jackson (all airports), United States', key: 'C_JAN', type: 'city', code: 'jackson_ms_us' } ],
 ['C_MKL', { icon: 'faBuilding', label: 'Jackson (all airports), United States', key: 'C_MKL', type: 'city', code: 'jackson_tn_us' } ],
 ['C_JAC', { icon: 'faBuilding', label: 'Jackson (all airports), United States', key: 'C_JAC', type: 'city', code: 'jackson_wy_us' } ],
 ['C_OAJ', { icon: 'faBuilding', label: 'Jacksonville (all airports), United States', key: 'C_OAJ', type: 'city', code: 'jacksonville_nc_us' } ],
 ['C_JAX', { icon: 'faBuilding', label: 'Jacksonville (all airports), United States', key: 'C_JAX', type: 'city', code: 'jacksonville_fl_us' } ],
 ['C_JAE', { icon: 'faBuilding', label: 'Jaén (all airports), Peru', key: 'C_JAE', type: 'city', code: 'jaén_pe' } ],
 ['C_JJG', { icon: 'faBuilding', label: 'Jaguaruna (all airports), Brazil', key: 'C_JJG', type: 'city', code: 'jaguaruna_sc_br' } ],
 ['C_JAI', { icon: 'faBuilding', label: 'Jaipur (all airports), India', key: 'C_JAI', type: 'city', code: 'jaipur_in' } ],
 ['C_JSA', { icon: 'faBuilding', label: 'Jaisalmer (all airports), India', key: 'C_JSA', type: 'city', code: 'jaisalmer_in' } ],
 ['C_JKT', { icon: 'faBuilding', label: 'Jakarta (all airports), Indonesia', key: 'C_JKT', type: 'city', code: 'jakarta_id' } ],
 ['C_DJB', { icon: 'faBuilding', label: 'Jambi City (all airports), Indonesia', key: 'C_DJB', type: 'city', code: 'jambi-city_id' } ],
 ['C_JMS', { icon: 'faBuilding', label: 'Jamestown (all airports), United States', key: 'C_JMS', type: 'city', code: 'jamestown_nd_us' } ],
 ['C_HLE', { icon: 'faBuilding', label: 'Jamestown (all airports), St. Helena', key: 'C_HLE', type: 'city', code: 'jamestown_sh' } ],
 ['C_IXJ', { icon: 'faBuilding', label: 'Jammu (all airports), Unknown Region', key: 'C_IXJ', type: 'city', code: 'jammu_zz' } ],
 ['C_JGA', { icon: 'faBuilding', label: 'Jamnagar (all airports), India', key: 'C_JGA', type: 'city', code: 'jamnagar_in' } ],
 ['C_JKR', { icon: 'faBuilding', label: 'Janakpur (all airports), Nepal', key: 'C_JKR', type: 'city', code: 'janakpur_np' } ],
 ['C_JQE', { icon: 'faBuilding', label: 'Jaqué (all airports), Panama', key: 'C_JQE', type: 'city', code: 'jaque_pa' } ],
 ['C_JAU', { icon: 'faBuilding', label: 'Jauja (all airports), Peru', key: 'C_JAU', type: 'city', code: 'jauja_pe' } ],
 ['C_DJJ', { icon: 'faBuilding', label: 'Jayapura (all airports), Indonesia', key: 'C_DJJ', type: 'city', code: 'jayapura_id' } ],
 ['C_JED', { icon: 'faBuilding', label: 'Jeddah (all airports), Saudi Arabia', key: 'C_JED', type: 'city', code: 'jeddah_sa' } ],
 ['C_CJU', { icon: 'faBuilding', label: 'Jeju City (all airports), South Korea', key: 'C_CJU', type: 'city', code: 'jeju-city_kr' } ],
 ['C_JBB', { icon: 'faBuilding', label: 'Jember Regency (all airports), Indonesia', key: 'C_JBB', type: 'city', code: 'jember-regency_id' } ],
 ['C_XRY', { icon: 'faBuilding', label: 'Jerez de la Frontera (all airports), Spain', key: 'C_XRY', type: 'city', code: 'jerez-de-la-frontera_es' } ],
 ['C_JSR', { icon: 'faBuilding', label: 'Jessore (all airports), Bangladesh', key: 'C_JSR', type: 'city', code: 'jessore_bd' } ],
 ['C_DZN', { icon: 'faBuilding', label: 'Jezkazgan (all airports), Kazakhstan', key: 'C_DZN', type: 'city', code: 'jezkazgan_kz' } ],
 ['C_JGS', { icon: 'faBuilding', label: 'Ji an (all airports), China', key: 'C_JGS', type: 'city', code: 'jian_cn' } ],
 ['C_JPR', { icon: 'faBuilding', label: 'Ji-Paraná (all airports), Brazil', key: 'C_JPR', type: 'city', code: 'ji-parana_ro_br' } ],
 ['C_JGD', { icon: 'faBuilding', label: 'Jiagedaqi District (all airports), China', key: 'C_JGD', type: 'city', code: 'jiagedaqi-district_cn' } ],
 ['C_JMU', { icon: 'faBuilding', label: 'Jiamusi (all airports), China', key: 'C_JMU', type: 'city', code: 'jiamusi_cn' } ],
 ['C_JGN', { icon: 'faBuilding', label: 'Jiayuguan City (all airports), China', key: 'C_JGN', type: 'city', code: 'jiayuguan-city_cn' } ],
 ['C_GJL', { icon: 'faBuilding', label: 'Jijel (all airports), Algeria', key: 'C_GJL', type: 'city', code: 'jijel_dz' } ],
 ['C_JIJ', { icon: 'faBuilding', label: 'Jijiga (all airports), Ethiopia', key: 'C_JIJ', type: 'city', code: 'jijiga_et' } ],
 ['C_JIM', { icon: 'faBuilding', label: 'Jimma (all airports), Ethiopia', key: 'C_JIM', type: 'city', code: 'jimma_et' } ],
 ['C_TNA', { icon: 'faBuilding', label: 'Jinan (all airports), China', key: 'C_TNA', type: 'city', code: 'jinan_cn' } ],
 ['C_JIC', { icon: 'faBuilding', label: 'Jinchang (all airports), China', key: 'C_JIC', type: 'city', code: 'jinchang_cn' } ],
 ['C_JDZ', { icon: 'faBuilding', label: 'Jingdezhen (all airports), China', key: 'C_JDZ', type: 'city', code: 'jingdezhen_cn' } ],
 ['C_JHG', { icon: 'faBuilding', label: 'Jinghong (all airports), China', key: 'C_JHG', type: 'city', code: 'jinghong_cn' } ],
 ['C_JNG', { icon: 'faBuilding', label: 'Jining (all airports), China', key: 'C_JNG', type: 'city', code: 'jining_cn' } ],
 ['C_HIN', { icon: 'faBuilding', label: 'Jinju (all airports), South Korea', key: 'C_HIN', type: 'city', code: 'jinju_kr' } ],
 ['C_BCO', { icon: 'faBuilding', label: 'Jinka (all airports), Ethiopia', key: 'C_BCO', type: 'city', code: 'jinka_et' } ],
 ['C_JNZ', { icon: 'faBuilding', label: 'Jinzhou (all airports), China', key: 'C_JNZ', type: 'city', code: 'jinzhou_cn' } ],
 ['C_JZH', { icon: 'faBuilding', label: 'Jiuzhaigou County (all airports), China', key: 'C_JZH', type: 'city', code: 'jiuzhaigou-county_cn' } ],
 ['C_JXA', { icon: 'faBuilding', label: 'Jixi (all airports), China', key: 'C_JXA', type: 'city', code: 'jixi_cn' } ],
 ['C_GIZ', { icon: 'faBuilding', label: 'Jizan (all airports), Saudi Arabia', key: 'C_GIZ', type: 'city', code: 'jizan_sa' } ],
 ['C_JPA', { icon: 'faBuilding', label: 'João Pessoa, Paraíba (all airports), Brazil', key: 'C_JPA', type: 'city', code: 'joao-pessoa_pb_br' } ],
 ['C_JDH', { icon: 'faBuilding', label: 'Jodhpur (all airports), India', key: 'C_JDH', type: 'city', code: 'jodhpur_in' } ],
 ['C_JOE', { icon: 'faBuilding', label: 'Joensuu (all airports), Finland', key: 'C_JOE', type: 'city', code: 'joensuu_fi' } ],
 ['C_JNB', { icon: 'faBuilding', label: 'Johannesburg (all airports), South Africa', key: 'C_JNB', type: 'city', code: 'johannesburg_za' } ],
 ['C_JST', { icon: 'faBuilding', label: 'Johnstown (all airports), United States', key: 'C_JST', type: 'city', code: 'johnstown_pa_us' } ],
 ['C_JHB', { icon: 'faBuilding', label: 'Johor Bahru (all airports), Malaysia', key: 'C_JHB', type: 'city', code: 'johor-bahru_my' } ],
 ['C_JOI', { icon: 'faBuilding', label: 'Joinville (all airports), Brazil', key: 'C_JOI', type: 'city', code: 'joinville_sc_br' } ],
 ['C_JBR', { icon: 'faBuilding', label: 'Jonesboro (all airports), United States', key: 'C_JBR', type: 'city', code: 'jonesboro_ar_us' } ],
 ['C_JKG', { icon: 'faBuilding', label: 'Jönköping (all airports), Sweden', key: 'C_JKG', type: 'city', code: 'jonkoping_se' } ],
 ['C_JLN', { icon: 'faBuilding', label: 'Joplin (all airports), United States', key: 'C_JLN', type: 'city', code: 'joplin_mo_us' } ],
 ['C_JRH', { icon: 'faBuilding', label: 'Jorhat (all airports), India', key: 'C_JRH', type: 'city', code: 'jorhat_in' } ],
 ['C_JOS', { icon: 'faBuilding', label: 'Jos (all airports), Nigeria', key: 'C_JOS', type: 'city', code: 'jos_ng' } ],
 ['C_JDO', { icon: 'faBuilding', label: 'Juazeiro do Norte (all airports), Brazil', key: 'C_JDO', type: 'city', code: 'juazeiro-do-norte_ce_br' } ],
 ['C_JUB', { icon: 'faBuilding', label: 'Juba (all airports), South Sudan', key: 'C_JUB', type: 'city', code: 'juba_ss' } ],
 ['C_JDF', { icon: 'faBuilding', label: 'Juiz de Fora (all airports), Brazil', key: 'C_JDF', type: 'city', code: 'juiz-de-fora_mg_br' } ],
 ['C_JCK', { icon: 'faBuilding', label: 'Julia Creek (all airports), Australia', key: 'C_JCK', type: 'city', code: 'julia-creek_ql_au' } ],
 ['C_JUL', { icon: 'faBuilding', label: 'Juliaca (all airports), Peru', key: 'C_JUL', type: 'city', code: 'juliaca_pe' } ],
 ['C_JNU', { icon: 'faBuilding', label: 'Juneau (all airports), United States', key: 'C_JNU', type: 'city', code: 'juneau_ak_us' } ],
 ['C_JYV', { icon: 'faBuilding', label: 'Jyväskylä (all airports), Finland', key: 'C_JYV', type: 'city', code: 'jyvaskyla_fi' } ],
 ['C_KBL', { icon: 'faBuilding', label: 'Kabul (all airports), Afghanistan', key: 'C_KBL', type: 'city', code: 'kabul_af' } ],
 ['C_CDP', { icon: 'faBuilding', label: 'Kadapa (all airports), India', key: 'C_CDP', type: 'city', code: 'kadapa_in' } ],
 ['C_KDV', { icon: 'faBuilding', label: 'Kadavu Island (all airports), Fiji', key: 'C_KDV', type: 'city', code: 'kadavu-island_fj' } ],
 ['C_KAD', { icon: 'faBuilding', label: 'Kaduna (all airports), Nigeria', key: 'C_KAD', type: 'city', code: 'kaduna_ng' } ],
 ['C_KGE', { icon: 'faBuilding', label: 'Kaghau Island (all airports), Solomon Islands', key: 'C_KGE', type: 'city', code: 'kaghau-island_sb' } ],
 ['C_KOJ', { icon: 'faBuilding', label: 'Kagoshima (all airports), Japan', key: 'C_KOJ', type: 'city', code: 'kagoshima_jp' } ],
 ['C_KCM', { icon: 'faBuilding', label: 'Kahramanmaraş (all airports), Turkey', key: 'C_KCM', type: 'city', code: 'kahramanmaras_tr' } ],
 ['C_OGG', { icon: 'faBuilding', label: 'Kahului (all airports), United States', key: 'C_OGG', type: 'city', code: 'kahului_hi_us' } ],
 ['C_KOA', { icon: 'faBuilding', label: 'Kailua (all airports), United States', key: 'C_KOA', type: 'city', code: 'kailua_hi_us' } ],
 ['C_KNG', { icon: 'faBuilding', label: 'Kaimana (all airports), Indonesia', key: 'C_KNG', type: 'city', code: 'kaimana_id' } ],
 ['C_KAJ', { icon: 'faBuilding', label: 'Kajaani (all airports), Finland', key: 'C_KAJ', type: 'city', code: 'kajaani_fi' } ],
 ['C_GGM', { icon: 'faBuilding', label: 'Kakamega (all airports), Kenya', key: 'C_GGM', type: 'city', code: 'kakamega_ke' } ],
 ['C_KAE', { icon: 'faBuilding', label: 'Kake (all airports), United States', key: 'C_KAE', type: 'city', code: 'kake_ak_us' } ],
 ['C_KNK', { icon: 'faBuilding', label: 'Kakhonak (all airports), United States', key: 'C_KNK', type: 'city', code: 'kakhonak_ak_us' } ],
 ['C_KLX', { icon: 'faBuilding', label: 'Kalamata (all airports), Greece', key: 'C_KLX', type: 'city', code: 'kalamata_gr' } ],
 ['C_AZO', { icon: 'faBuilding', label: 'Kalamazoo (all airports), United States', key: 'C_AZO', type: 'city', code: 'kalamazoo_mi_us' } ],
 ['C_KMV', { icon: 'faBuilding', label: 'Kalay (all airports), Myanmar (Burma)', key: 'C_KMV', type: 'city', code: 'kalay_mm' } ],
 ['C_FMI', { icon: 'faBuilding', label: 'Kalemie (all airports), Democratic Republic of the Congo', key: 'C_FMI', type: 'city', code: 'kalemie_cd' } ],
 ['C_KGI', { icon: 'faBuilding', label: 'Kalgoorlie (all airports), Australia', key: 'C_KGI', type: 'city', code: 'kalgoorlie_wa_au' } ],
 ['C_KLO', { icon: 'faBuilding', label: 'Kalibo (all airports), Philippines', key: 'C_KLO', type: 'city', code: 'kalibo_ph' } ],
 ['C_KGD', { icon: 'faBuilding', label: 'Kaliningrad (all airports), Russia', key: 'C_KGD', type: 'city', code: 'kaliningrad_ru' } ],
 ['C_FCA', { icon: 'faBuilding', label: 'Kalispell (all airports), United States', key: 'C_FCA', type: 'city', code: 'kalispell_mt_us' } ],
 ['C_KLR', { icon: 'faBuilding', label: 'Kalmar (all airports), Sweden', key: 'C_KLR', type: 'city', code: 'kalmar_se' } ],
 ['C_KAL', { icon: 'faBuilding', label: 'Kaltag (all airports), United States', key: 'C_KAL', type: 'city', code: 'kaltag_ak_us' } ],
 ['C_KLF', { icon: 'faBuilding', label: 'Kaluga (all airports), Russia', key: 'C_KLF', type: 'city', code: 'kaluga_ru' } ],
 ['C_JKL', { icon: 'faBuilding', label: 'Kalymnos (all airports), Greece', key: 'C_JKL', type: 'city', code: 'kalymnos_gr' } ],
 ['C_YKA', { icon: 'faBuilding', label: 'Kamloops (all airports), Canada', key: 'C_YKA', type: 'city', code: 'kamloops_bc_ca' } ],
 ['C_KGA', { icon: 'faBuilding', label: 'Kananga (all airports), Democratic Republic of the Congo', key: 'C_KGA', type: 'city', code: 'kananga_cd' } ],
 ['C_KDH', { icon: 'faBuilding', label: 'Kandahar (all airports), Afghanistan', key: 'C_KDH', type: 'city', code: 'kandahar_af' } ],
 ['C_IXY', { icon: 'faBuilding', label: 'Kandla (all airports), India', key: 'C_IXY', type: 'city', code: 'kandla_in' } ],
 ['C_KDW', { icon: 'faBuilding', label: 'Kandy (all airports), Sri Lanka', key: 'C_KDW', type: 'city', code: 'kandy_lk' } ],
 ['C_KGT', { icon: 'faBuilding', label: 'Kangding (all airports), China', key: 'C_KGT', type: 'city', code: 'yongchang-county_cn' } ],
 ['C_SFJ', { icon: 'faBuilding', label: 'Kangerlussuaq (all airports), Greenland', key: 'C_SFJ', type: 'city', code: 'kangerlussuaq_gl' } ],
 ['C_XGR', { icon: 'faBuilding', label: 'Kangiqsualujjuaq (all airports), Canada', key: 'C_XGR', type: 'city', code: 'kangiqsualujjuaq_qc_ca' } ],
 ['C_YWB', { icon: 'faBuilding', label: 'Kangiqsujuaq (all airports), Canada', key: 'C_YWB', type: 'city', code: 'kangiqsujuaq_qc_ca' } ],
 ['C_YKG', { icon: 'faBuilding', label: 'Kangirsuk (all airports), Canada', key: 'C_YKG', type: 'city', code: 'kangirsuk_qc_ca' } ],
 ['C_KAN', { icon: 'faBuilding', label: 'Kano (all airports), Nigeria', key: 'C_KAN', type: 'city', code: 'kano_ng' } ],
 ['C_KNU', { icon: 'faBuilding', label: 'Kanpur (all airports), India', key: 'C_KNU', type: 'city', code: 'kanpur_in' } ],
 ['C_MKC', { icon: 'faBuilding', label: 'Kansas City (all airports), United States', key: 'C_MKC', type: 'city', code: 'kansas-city_mo_us' } ],
 ['C_KHH', { icon: 'faBuilding', label: 'Kaohsiung (all airports), Taiwan', key: 'C_KHH', type: 'city', code: 'kaohsiung_tw' } ],
 ['C_JHM', { icon: 'faBuilding', label: 'Kapalua (all airports), United States', key: 'C_JHM', type: 'city', code: 'kapalua_hi_us' } ],
 ['C_YYU', { icon: 'faBuilding', label: 'Kapuskasing (all airports), Canada', key: 'C_YYU', type: 'city', code: 'kapuskasing_on_ca' } ],
 ['C_KHI', { icon: 'faBuilding', label: 'Karachi (all airports), Pakistan', key: 'C_KHI', type: 'city', code: 'karachi_pk' } ],
 ['C_KGF', { icon: 'faBuilding', label: 'Karaganda (all airports), Kazakhstan', key: 'C_KGF', type: 'city', code: 'karaganda_kz' } ],
 ['C_KRY', { icon: 'faBuilding', label: 'Karamay (all airports), China', key: 'C_KRY', type: 'city', code: 'karamay_cn' } ],
 ['C_KAB', { icon: 'faBuilding', label: 'Kariba (all airports), Zimbabwe', key: 'C_KAB', type: 'city', code: 'kariba_zw' } ],
 ['C_KLV', { icon: 'faBuilding', label: 'Karlovy Vary (all airports), Czechia', key: 'C_KLV', type: 'city', code: 'karlovy-vary_cz' } ],
 ['C_FKB', { icon: 'faBuilding', label: 'Karlsruhe (all airports), Germany', key: 'C_FKB', type: 'city', code: 'karlsruhe_de' } ],
 ['C_KSD', { icon: 'faBuilding', label: 'Karlstad (all airports), Sweden', key: 'C_KSD', type: 'city', code: 'karlstad_se' } ],
 ['C_AOK', { icon: 'faBuilding', label: 'Karpathos (all airports), Greece', key: 'C_AOK', type: 'city', code: 'karpathos_gr' } ],
 ['C_KTA', { icon: 'faBuilding', label: 'Karratha (all airports), Australia', key: 'C_KTA', type: 'city', code: 'karratha_wa_au' } ],
 ['C_KSY', { icon: 'faBuilding', label: 'Kars (all airports), Turkey', key: 'C_KSY', type: 'city', code: 'kars_tr' } ],
 ['C_KRB', { icon: 'faBuilding', label: 'Karumba (all airports), Australia', key: 'C_KRB', type: 'city', code: 'karumba_ql_au' } ],
 ['C_KRP', { icon: 'faBuilding', label: 'Karup (all airports), Denmark', key: 'C_KRP', type: 'city', code: 'karup_dk' } ],
 ['C_KXA', { icon: 'faBuilding', label: 'Kasaan (all airports), United States', key: 'C_KXA', type: 'city', code: 'kasaan_ak_us' } ],
 ['C_BBK', { icon: 'faBuilding', label: 'Kasane (all airports), Botswana', key: 'C_BBK', type: 'city', code: 'kasane_bw' } ],
 ['C_ZKE', { icon: 'faBuilding', label: 'Kashechewan (all airports), Canada', key: 'C_ZKE', type: 'city', code: 'kashechewan_on_ca' } ],
 ['C_KHG', { icon: 'faBuilding', label: 'Kashgar (all airports), China', key: 'C_KHG', type: 'city', code: 'kashgar_cn' } ],
 ['C_KUK', { icon: 'faBuilding', label: 'Kasigluk (all airports), United States', key: 'C_KUK', type: 'city', code: 'kasigluk_ak_us' } ],
 ['C_KSJ', { icon: 'faBuilding', label: 'Kasos (all airports), Greece', key: 'C_KSJ', type: 'city', code: 'kasos_gr' } ],
 ['C_KSF', { icon: 'faBuilding', label: 'Kassel (all airports), Germany', key: 'C_KSF', type: 'city', code: 'kassel_de' } ],
 ['C_KFS', { icon: 'faBuilding', label: 'Kastamonu (all airports), Turkey', key: 'C_KFS', type: 'city', code: 'kastamonu_tr' } ],
 ['C_KZS', { icon: 'faBuilding', label: 'Kastellorizo (all airports), Greece', key: 'C_KZS', type: 'city', code: 'kastellorizo_gr' } ],
 ['C_KSO', { icon: 'faBuilding', label: 'Kastoria (all airports), Greece', key: 'C_KSO', type: 'city', code: 'kastoria_gr' } ],
 ['C_KTR', { icon: 'faBuilding', label: 'Katherine (all airports), Australia', key: 'C_KTR', type: 'city', code: 'katherine_nt_au' } ],
 ['C_KTM', { icon: 'faBuilding', label: 'Kathmandu (all airports), Nepal', key: 'C_KTM', type: 'city', code: 'kathmandu_np' } ],
 ['C_MPA', { icon: 'faBuilding', label: 'Katima Mulilo (all airports), Namibia', key: 'C_MPA', type: 'city', code: 'katima-mulilo_na' } ],
 ['C_KXU', { icon: 'faBuilding', label: 'Katiu (all airports), French Polynesia', key: 'C_KXU', type: 'city', code: 'katiu_pf' } ],
 ['C_KTW', { icon: 'faBuilding', label: 'Katowice (all airports), Poland', key: 'C_KTW', type: 'city', code: 'katowice_pl' } ],
 ['C_KAZ', { icon: 'faBuilding', label: 'Kau (all airports), Indonesia', key: 'C_KAZ', type: 'city', code: 'kau_id' } ],
 ['C_LIH', { icon: 'faBuilding', label: 'Kauai (all airports), United States', key: 'C_LIH', type: 'city', code: 'kauai_hi_us' } ],
 ['C_KHZ', { icon: 'faBuilding', label: 'Kauehi (all airports), French Polynesia', key: 'C_KHZ', type: 'city', code: 'kauehi_pf' } ],
 ['C_KKR', { icon: 'faBuilding', label: 'Kaukura (all airports), French Polynesia', key: 'C_KKR', type: 'city', code: 'kaukura_pf' } ],
 ['C_KUN', { icon: 'faBuilding', label: 'Kaunas (all airports), Lithuania', key: 'C_KUN', type: 'city', code: 'kaunas_lt' } ],
 ['C_KVA', { icon: 'faBuilding', label: 'Kavala (all airports), Greece', key: 'C_KVA', type: 'city', code: 'kavala_gr' } ],
 ['C_KVR', { icon: 'faBuilding', label: 'Kavalerovo (all airports), Russia', key: 'C_KVR', type: 'city', code: 'kavalerovo_ru' } ],
 ['C_KVG', { icon: 'faBuilding', label: 'Kavieng (all airports), Papua New Guinea', key: 'C_KVG', type: 'city', code: 'kavieng_pg' } ],
 ['C_KAW', { icon: 'faBuilding', label: 'Kawthaung Township (all airports), Myanmar (Burma)', key: 'C_KAW', type: 'city', code: 'kawthaung-township_mm' } ],
 ['C_ASR', { icon: 'faBuilding', label: 'Kayseri (all airports), Turkey', key: 'C_ASR', type: 'city', code: 'kayseri_tr' } ],
 ['C_KZN', { icon: 'faBuilding', label: 'Kazan (all airports), Russia', key: 'C_KZN', type: 'city', code: 'kazan_ru' } ],
 ['C_EAR', { icon: 'faBuilding', label: 'Kearney (all airports), United States', key: 'C_EAR', type: 'city', code: 'kearney_ne_us' } ],
 ['C_ABK', { icon: 'faBuilding', label: 'Kebri Dahar (all airports), Ethiopia', key: 'C_ABK', type: 'city', code: 'kebri-dahar_et' } ],
 ['C_ZKG', { icon: 'faBuilding', label: 'Kegashka, Quebec (all airports), Canada', key: 'C_ZKG', type: 'city', code: 'kegashka_qc_ca' } ],
 ['C_YLW', { icon: 'faBuilding', label: 'Kelowna (all airports), Canada', key: 'C_YLW', type: 'city', code: 'kelowna_bc_ca' } ],
 ['C_KEJ', { icon: 'faBuilding', label: 'Kemerovo (all airports), Russia', key: 'C_KEJ', type: 'city', code: 'kemerovo_ru' } ],
 ['C_KEM', { icon: 'faBuilding', label: 'Kemi (all airports), Finland', key: 'C_KEM', type: 'city', code: 'kemi_fi' } ],
 ['C_ENA', { icon: 'faBuilding', label: 'Kenai (all airports), United States', key: 'C_ENA', type: 'city', code: 'kenai_ak_us' } ],
 ['C_KDI', { icon: 'faBuilding', label: 'Kendari (all airports), Indonesia', key: 'C_KDI', type: 'city', code: 'kendari_id' } ],
 ['C_KET', { icon: 'faBuilding', label: 'Kengtung (all airports), Myanmar (Burma)', key: 'C_KET', type: 'city', code: 'kengtung_mm' } ],
 ['C_KEH', { icon: 'faBuilding', label: 'Kenmore (all airports), United States', key: 'C_KEH', type: 'city', code: 'kenmore_wa_us' } ],
 ['C_YQK', { icon: 'faBuilding', label: 'Kenora (all airports), Canada', key: 'C_YQK', type: 'city', code: 'kenora_on_ca' } ],
 ['C_KPW', { icon: 'faBuilding', label: 'Keperveyem (all airports), Russia', key: 'C_KPW', type: 'city', code: 'keperveyem_ru' } ],
 ['C_KKE', { icon: 'faBuilding', label: 'Kerikeri (all airports), New Zealand', key: 'C_KKE', type: 'city', code: 'kerikeri_nz' } ],
 ['C_KRC', { icon: 'faBuilding', label: 'Kerinci (all airports), Indonesia', key: 'C_KRC', type: 'city', code: 'kerinci_id' } ],
 ['C_KER', { icon: 'faBuilding', label: 'Kerman (all airports), Iran', key: 'C_KER', type: 'city', code: 'kerman_ir' } ],
 ['C_KSH', { icon: 'faBuilding', label: 'Kermanshah (all airports), Iran', key: 'C_KSH', type: 'city', code: 'kermanshah_ir' } ],
 ['C_KTE', { icon: 'faBuilding', label: 'Kerteh (all airports), Malaysia', key: 'C_KTE', type: 'city', code: 'kerteh_my' } ],
 ['C_KTG', { icon: 'faBuilding', label: 'Ketapang (all airports), Indonesia', key: 'C_KTG', type: 'city', code: 'roman-catholic-diocese-of-ketapang_id' } ],
 ['C_KTN', { icon: 'faBuilding', label: 'Ketchikan (all airports), United States', key: 'C_KTN', type: 'city', code: 'ketchikan_ak_us' } ],
 ['C_EYW', { icon: 'faBuilding', label: 'Key West (all airports), United States', key: 'C_EYW', type: 'city', code: 'key-west_fl_us' } ],
 ['C_KHV', { icon: 'faBuilding', label: 'Khabarovsk (all airports), Russia', key: 'C_KHV', type: 'city', code: 'khabarovsk_ru' } ],
 ['C_HJR', { icon: 'faBuilding', label: 'Khajuraho Group of Monuments (all airports), India', key: 'C_HJR', type: 'city', code: 'khajuraho-group-of-monuments_in' } ],
 ['C_KHM', { icon: 'faBuilding', label: 'Khamti (all airports), Myanmar (Burma)', key: 'C_KHM', type: 'city', code: 'khamti_mm' } ],
 ['C_KDY', { icon: 'faBuilding', label: 'Khandyga (all airports), Russia', key: 'C_KDY', type: 'city', code: 'khandyga_ru' } ],
 ['C_HMA', { icon: 'faBuilding', label: 'Khanty-Mansiysk (all airports), Russia', key: 'C_HMA', type: 'city', code: 'khanty-mansiysk_ru' } ],
 ['C_HRK', { icon: 'faBuilding', label: 'Kharkiv (all airports), Ukraine', key: 'C_HRK', type: 'city', code: 'kharkiv_ua' } ],
 ['C_KRT', { icon: 'faBuilding', label: 'Khartoum (all airports), Sudan', key: 'C_KRT', type: 'city', code: 'khartoum_sd' } ],
 ['C_KHS', { icon: 'faBuilding', label: 'Khasab (all airports), Oman', key: 'C_KHS', type: 'city', code: 'khasab_om' } ],
 ['C_HTG', { icon: 'faBuilding', label: 'Khatanga (all airports), Russia', key: 'C_HTG', type: 'city', code: 'khatanga-river_ru' } ],
 ['C_KHE', { icon: 'faBuilding', label: 'Kherson (all airports), Ukraine', key: 'C_KHE', type: 'city', code: 'kherson_ua' } ],
 ['C_KKC', { icon: 'faBuilding', label: 'Khon Kaen (all airports), Thailand', key: 'C_KKC', type: 'city', code: 'khon-kaen_th' } ],
 ['C_MQJ', { icon: 'faBuilding', label: 'Khonuu (all airports), Russia', key: 'C_MQJ', type: 'city', code: 'khonuu_ru' } ],
 ['C_KHD', { icon: 'faBuilding', label: 'Khorramabad (all airports), Iran', key: 'C_KHD', type: 'city', code: 'khorramabad_ir' } ],
 ['C_null', { icon: 'faBuilding', label: 'Khoy (all airports), Iran', key: 'C_null', type: 'city', code: 'khoy_ir' } ],
 ['C_LBD', { icon: 'faBuilding', label: 'Khujand (all airports), Tajikistan', key: 'C_LBD', type: 'city', code: 'khujand_tj' } ],
 ['C_IAN', { icon: 'faBuilding', label: 'Kiana (all airports), United States', key: 'C_IAN', type: 'city', code: 'kiana_ak_us' } ],
 ['C_KIE', { icon: 'faBuilding', label: 'Kieta (all airports), Papua New Guinea', key: 'C_KIE', type: 'city', code: 'kieta_pg' } ],
 ['C_IEV', { icon: 'faBuilding', label: 'Kiev (all airports), Ukraine', key: 'C_IEV', type: 'city', code: 'kiev_ua' } ],
 ['C_KGL', { icon: 'faBuilding', label: 'Kigali (all airports), Rwanda', key: 'C_KGL', type: 'city', code: 'kigali_rw' } ],
 ['C_TKQ', { icon: 'faBuilding', label: 'Kigoma (all airports), Tanzania', key: 'C_TKQ', type: 'city', code: 'kigoma_tz' } ],
 ['C_ILE', { icon: 'faBuilding', label: 'Killeen (all airports), United States', key: 'C_ILE', type: 'city', code: 'killeen_tx_us' } ],
 ['C_KIY', { icon: 'faBuilding', label: 'Kilwa Kisiwani (all airports), Tanzania', key: 'C_KIY', type: 'city', code: 'kilwa-kisiwani_tz' } ],
 ['C_KIM', { icon: 'faBuilding', label: 'Kimberley, Northern Cape (all airports), South Africa', key: 'C_KIM', type: 'city', code: 'kimberley_za' } ],
 ['C_YLC', { icon: 'faBuilding', label: 'Kimmirut (all airports), Canada', key: 'C_YLC', type: 'city', code: 'kimmirut_nu_ca' } ],
 ['C_KND', { icon: 'faBuilding', label: 'Kindu (all airports), Democratic Republic of the Congo', key: 'C_KND', type: 'city', code: 'kindu_cd' } ],
 ['C_KVC', { icon: 'faBuilding', label: 'King Cove (all airports), United States', key: 'C_KVC', type: 'city', code: 'king-cove_ak_us' } ],
 ['C_KNS', { icon: 'faBuilding', label: 'King Island (all airports), Australia', key: 'C_KNS', type: 'city', code: 'king-island_ts_au' } ],
 ['C_KMC', { icon: 'faBuilding', label: 'King Khalid Military City (all airports), Saudi Arabia', key: 'C_KMC', type: 'city', code: 'king-khalid-military-city_sa' } ],
 ['C_AKN', { icon: 'faBuilding', label: 'King Salmon (all airports), United States', key: 'C_AKN', type: 'city', code: 'king-salmon_ak_us' } ],
 ['C_KGC', { icon: 'faBuilding', label: 'Kingscote (all airports), Australia', key: 'C_KGC', type: 'city', code: 'kingscote_sa_au' } ],
 ['C_YGK', { icon: 'faBuilding', label: 'Kingston (all airports), Canada', key: 'C_YGK', type: 'city', code: 'kingston_on_ca' } ],
 ['C_KIN', { icon: 'faBuilding', label: 'Kingston (all airports), Jamaica', key: 'C_KIN', type: 'city', code: 'kingston_jm' } ],
 ['C_KNH', { icon: 'faBuilding', label: 'Kinmen (all airports), Taiwan', key: 'C_KNH', type: 'city', code: 'kinmen_tw' } ],
 ['C_FIH', { icon: 'faBuilding', label: 'Kinshasa (all airports), Democratic Republic of the Congo', key: 'C_FIH', type: 'city', code: 'kinshasa_cd' } ],
 ['C_KPN', { icon: 'faBuilding', label: 'Kipnuk (all airports), United States', key: 'C_KPN', type: 'city', code: 'kipnuk_ak_us' } ],
 ['C_IRA', { icon: 'faBuilding', label: 'Kirakira (all airports), Solomon Islands', key: 'C_IRA', type: 'city', code: 'kirakira_sb' } ],
 ['C_KCK', { icon: 'faBuilding', label: 'Kirensk (all airports), Russia', key: 'C_KCK', type: 'city', code: 'kirensk_ru' } ],
 ['C_CXI', { icon: 'faBuilding', label: 'Kiritimati (all airports), Kiribati', key: 'C_CXI', type: 'city', code: 'kiritimati_ki' } ],
 ['C_LSA', { icon: 'faBuilding', label: 'Kiriwina (all airports), Papua New Guinea', key: 'C_LSA', type: 'city', code: 'kiriwina_pg' } ],
 ['C_KKN', { icon: 'faBuilding', label: 'Kirkenes (all airports), Norway', key: 'C_KKN', type: 'city', code: 'kirkenes_no' } ],
 ['C_IRK', { icon: 'faBuilding', label: 'Kirksville (all airports), United States', key: 'C_IRK', type: 'city', code: 'kirksville_mo_us' } ],
 ['C_KOI', { icon: 'faBuilding', label: 'Kirkwall (all airports), United Kingdom', key: 'C_KOI', type: 'city', code: 'kirkwall_gb' } ],
 ['C_HUY', { icon: 'faBuilding', label: 'Kirmington (all airports), United Kingdom', key: 'C_HUY', type: 'city', code: 'humberside_gb' } ],
 ['C_KVX', { icon: 'faBuilding', label: 'Kirov (all airports), Russia', key: 'C_KVX', type: 'city', code: 'kirov_ru' } ],
 ['C_KVK', { icon: 'faBuilding', label: 'Kirovsk (all airports), Russia', key: 'C_KVK', type: 'city', code: 'kirovsk_ru' } ],
 ['C_KRN', { icon: 'faBuilding', label: 'Kiruna (all airports), Sweden', key: 'C_KRN', type: 'city', code: 'kiruna_se' } ],
 ['C_FKI', { icon: 'faBuilding', label: 'Kisangani (all airports), Democratic Republic of the Congo', key: 'C_FKI', type: 'city', code: 'kisangani_cd' } ],
 ['C_KIH', { icon: 'faBuilding', label: 'Kish Island (all airports), Iran', key: 'C_KIH', type: 'city', code: 'kish-island_ir' } ],
 ['C_KMU', { icon: 'faBuilding', label: 'Kismayo (all airports), Somalia', key: 'C_KMU', type: 'city', code: 'kismayo_so' } ],
 ['C_KIS', { icon: 'faBuilding', label: 'Kisumu (all airports), Kenya', key: 'C_KIS', type: 'city', code: 'kisumu_ke' } ],
 ['C_KKJ', { icon: 'faBuilding', label: 'Kitakyushu (all airports), Japan', key: 'C_KKJ', type: 'city', code: 'kitakyushu_jp' } ],
 ['C_KTL', { icon: 'faBuilding', label: 'Kitale (all airports), Kenya', key: 'C_KTL', type: 'city', code: 'kitale_ke' } ],
 ['C_KTT', { icon: 'faBuilding', label: 'Kittilä (all airports), Finland', key: 'C_KTT', type: 'city', code: 'kittila_fi' } ],
 ['C_XJS', { icon: 'faBuilding', label: 'Kitzbuehl (all airports), Austria', key: 'C_XJS', type: 'city', code: 'kitzbuehl_at' } ],
 ['C_UNG', { icon: 'faBuilding', label: 'Kiunga (all airports), Papua New Guinea', key: 'C_UNG', type: 'city', code: 'kiunga_pg' } ],
 ['C_KVL', { icon: 'faBuilding', label: 'Kivalina (all airports), United States', key: 'C_KVL', type: 'city', code: 'kivalina_ak_us' } ],
 ['C_QJW', { icon: 'faBuilding', label: 'Kjellerup (all airports), Denmark', key: 'C_QJW', type: 'city', code: 'kjellerup_dk' } ],
 ['C_KLU', { icon: 'faBuilding', label: 'Klagenfurt (all airports), Austria', key: 'C_KLU', type: 'city', code: 'klagenfurt_at' } ],
 ['C_null', { icon: 'faBuilding', label: 'Klaipeda (all airports), Lithuania', key: 'C_null', type: 'city', code: 'klaipeda_lt' } ],
 ['C_LMT', { icon: 'faBuilding', label: 'Klamath Falls (all airports), United States', key: 'C_LMT', type: 'city', code: 'klamath-falls_or_us' } ],
 ['C_KLW', { icon: 'faBuilding', label: 'Klawock (all airports), United States', key: 'C_KLW', type: 'city', code: 'klawock_ak_us' } ],
 ['C_NOC', { icon: 'faBuilding', label: 'Knock, County Mayo (all airports), Ireland', key: 'C_NOC', type: 'city', code: 'knock_ie' } ],
 ['C_TYS', { icon: 'faBuilding', label: 'Knoxville (all airports), United States', key: 'C_TYS', type: 'city', code: 'knoxville_tn_us' } ],
 ['C_USM', { icon: 'faBuilding', label: 'Ko Samui (all airports), Thailand', key: 'C_USM', type: 'city', code: 'ko-samui_th' } ],
 ['C_UKB', { icon: 'faBuilding', label: 'Kobe (all airports), Japan', key: 'C_UKB', type: 'city', code: 'kobe_jp' } ],
 ['C_ZNV', { icon: 'faBuilding', label: 'Koblenz (all airports), Germany', key: 'C_ZNV', type: 'city', code: 'koblenz_de' } ],
 ['C_OBU', { icon: 'faBuilding', label: 'Kobuk (all airports), United States', key: 'C_OBU', type: 'city', code: 'kobuk_ak_us' } ],
 ['C_COK', { icon: 'faBuilding', label: 'Kochi (all airports), India', key: 'C_COK', type: 'city', code: 'kochi_in' } ],
 ['C_KCZ', { icon: 'faBuilding', label: 'Kochi (all airports), Japan', key: 'C_KCZ', type: 'city', code: 'kochi_jp' } ],
 ['C_ADQ', { icon: 'faBuilding', label: 'Kodiak (all airports), United States', key: 'C_ADQ', type: 'city', code: 'kodiak_ak_us' } ],
 ['C_KGP', { icon: 'faBuilding', label: 'Kogalym (all airports), Russia', key: 'C_KGP', type: 'city', code: 'kogalym_ru' } ],
 ['C_KCT', { icon: 'faBuilding', label: 'Koggala (all airports), Sri Lanka', key: 'C_KCT', type: 'city', code: 'koggala_lk' } ],
 ['C_KOK', { icon: 'faBuilding', label: 'Kokkola (all airports), Finland', key: 'C_KOK', type: 'city', code: 'kokkola_fi' } ],
 ['C_KOV', { icon: 'faBuilding', label: 'Kokshetau (all airports), Kazakhstan', key: 'C_KOV', type: 'city', code: 'kokshetau_kz' } ],
 ['C_KGK', { icon: 'faBuilding', label: 'Koliganek (all airports), United States', key: 'C_KGK', type: 'city', code: 'koliganek_ak_us' } ],
 ['C_CCU', { icon: 'faBuilding', label: 'Kolkata (all airports), India', key: 'C_CCU', type: 'city', code: 'kolkata_in' } ],
 ['C_QJY', { icon: 'faBuilding', label: 'Kolobrzeg (all airports), Poland', key: 'C_QJY', type: 'city', code: 'kolobrzeg_pl' } ],
 ['C_KMQ', { icon: 'faBuilding', label: 'Komatsu (all airports), Japan', key: 'C_KMQ', type: 'city', code: 'komatsu_jp' } ],
 ['C_KXK', { icon: 'faBuilding', label: 'Komsomolsk-on-Amur (all airports), Russia', key: 'C_KXK', type: 'city', code: 'komsomolsk-on-amur_ru' } ],
 ['C_KNQ', { icon: 'faBuilding', label: 'Kone (all airports), New Caledonia', key: 'C_KNQ', type: 'city', code: 'kone_nc' } ],
 ['C_KKH', { icon: 'faBuilding', label: 'Kongiganak (all airports), United States', key: 'C_KKH', type: 'city', code: 'kongiganak_ak_us' } ],
 ['C_KYA', { icon: 'faBuilding', label: 'Konya (all airports), Turkey', key: 'C_KYA', type: 'city', code: 'konya_tr' } ],
 ['C_HGO', { icon: 'faBuilding', label: 'Korhogo (all airports), Côte d’Ivoire', key: 'C_HGO', type: 'city', code: 'korhogo_ci' } ],
 ['C_KRL', { icon: 'faBuilding', label: 'Korla (all airports), China', key: 'C_KRL', type: 'city', code: 'korla_cn' } ],
 ['C_KXF', { icon: 'faBuilding', label: 'Koro Island (all airports), Fiji', key: 'C_KXF', type: 'city', code: 'koro-island_fj' } ],
 ['C_ROR', { icon: 'faBuilding', label: 'Koror (all airports), Palau', key: 'C_ROR', type: 'city', code: 'koror_pw' } ],
 ['C_KGS', { icon: 'faBuilding', label: 'Kos (all airports), Greece', key: 'C_KGS', type: 'city', code: 'kos_gr' } ],
 ['C_KSC', { icon: 'faBuilding', label: 'Košice (all airports), Slovakia', key: 'C_KSC', type: 'city', code: 'kosice_sk' } ],
 ['C_KSA', { icon: 'faBuilding', label: 'Kosrae (all airports), Micronesia', key: 'C_KSA', type: 'city', code: 'kosrae_fm' } ],
 ['C_KSN', { icon: 'faBuilding', label: 'Kostanay (all airports), Kazakhstan', key: 'C_KSN', type: 'city', code: 'kostanay_kz' } ],
 ['C_KBR', { icon: 'faBuilding', label: 'Kota Bharu (all airports), Malaysia', key: 'C_KBR', type: 'city', code: 'kota-bharu_my' } ],
 ['C_BKI', { icon: 'faBuilding', label: 'Kota Kinabalu (all airports), Malaysia', key: 'C_BKI', type: 'city', code: 'kota-kinabalu_my' } ],
 ['C_KBU', { icon: 'faBuilding', label: 'Kotabaru Regency (all airports), Indonesia', key: 'C_KBU', type: 'city', code: 'kotabaru_id' } ],
 ['C_KSZ', { icon: 'faBuilding', label: 'Kotlas (all airports), Russia', key: 'C_KSZ', type: 'city', code: 'kotlas_ru' } ],
 ['C_KOT', { icon: 'faBuilding', label: 'Kotlik (all airports), United States', key: 'C_KOT', type: 'city', code: 'kotlik_ak_us' } ],
 ['C_OTZ', { icon: 'faBuilding', label: 'Kotzebue (all airports), United States', key: 'C_OTZ', type: 'city', code: 'kotzebue_ak_us' } ],
 ['C_KOC', { icon: 'faBuilding', label: 'Koumac (all airports), New Caledonia', key: 'C_KOC', type: 'city', code: 'koumac_nc' } ],
 ['C_KWM', { icon: 'faBuilding', label: 'Kowanyama (all airports), Australia', key: 'C_KWM', type: 'city', code: 'kowanyama_ql_au' } ],
 ['C_KKA', { icon: 'faBuilding', label: 'Koyuk (all airports), United States', key: 'C_KKA', type: 'city', code: 'koyuk_ak_us' } ],
 ['C_KYU', { icon: 'faBuilding', label: 'Koyukuk (all airports), United States', key: 'C_KYU', type: 'city', code: 'koyukuk_ak_us' } ],
 ['C_KZI', { icon: 'faBuilding', label: 'Kozani (all airports), Greece', key: 'C_KZI', type: 'city', code: 'kozani_gr' } ],
 ['C_CCJ', { icon: 'faBuilding', label: 'Kozhikode (all airports), India', key: 'C_CCJ', type: 'city', code: 'kozhikode_in' } ],
 ['C_KBV', { icon: 'faBuilding', label: 'Krabi (all airports), Thailand', key: 'C_KBV', type: 'city', code: 'krabi_th' } ],
 ['C_KRK', { icon: 'faBuilding', label: 'Kraków (all airports), Poland', key: 'C_KRK', type: 'city', code: 'krakow_pl' } ],
 ['C_KRF', { icon: 'faBuilding', label: 'Kramfors Municipality (all airports), Sweden', key: 'C_KRF', type: 'city', code: 'kramfors-municipality_se' } ],
 ['C_KRR', { icon: 'faBuilding', label: 'Krasnodar (all airports), Russia', key: 'C_KRR', type: 'city', code: 'krasnodar_ru' } ],
 ['C_KJA', { icon: 'faBuilding', label: 'Krasnoyarsk (all airports), Russia', key: 'C_KJA', type: 'city', code: 'krasnoyarsk_ru' } ],
 ['C_KRS', { icon: 'faBuilding', label: 'Kristiansand (all airports), Norway', key: 'C_KRS', type: 'city', code: 'kristiansand_no' } ],
 ['C_KID', { icon: 'faBuilding', label: 'Kristianstad (all airports), Sweden', key: 'C_KID', type: 'city', code: 'kristianstad_se' } ],
 ['C_KSU', { icon: 'faBuilding', label: 'Kristiansund (all airports), Norway', key: 'C_KSU', type: 'city', code: 'kristiansund_no' } ],
 ['C_KUL', { icon: 'faBuilding', label: 'Kuala Lumpur (all airports), Malaysia', key: 'C_KUL', type: 'city', code: 'kuala-lumpur_my' } ],
 ['C_TGG', { icon: 'faBuilding', label: 'Kuala Terengganu (all airports), Malaysia', key: 'C_TGG', type: 'city', code: 'kuala-terengganu_my' } ],
 ['C_KUA', { icon: 'faBuilding', label: 'Kuantan (all airports), Malaysia', key: 'C_KUA', type: 'city', code: 'kuantan_my' } ],
 ['C_KCH', { icon: 'faBuilding', label: 'Kuching (all airports), Malaysia', key: 'C_KCH', type: 'city', code: 'kuching_my' } ],
 ['C_KUD', { icon: 'faBuilding', label: 'Kudat (all airports), Malaysia', key: 'C_KUD', type: 'city', code: 'kudat_my' } ],
 ['C_AKF', { icon: 'faBuilding', label: 'Kufrah (all airports), Libya', key: 'C_AKF', type: 'city', code: 'kufra-district_ly' } ],
 ['C_YBB', { icon: 'faBuilding', label: 'Kugaaruk (all airports), Canada', key: 'C_YBB', type: 'city', code: 'kugaaruk_nu_ca' } ],
 ['C_YCO', { icon: 'faBuilding', label: 'Kugluktuk (all airports), Canada', key: 'C_YCO', type: 'city', code: 'kugluktuk_nu_ca' } ],
 ['C_SVP', { icon: 'faBuilding', label: 'Kuito (all airports), Angola', key: 'C_SVP', type: 'city', code: 'kuito_ao' } ],
 ['C_KUU', { icon: 'faBuilding', label: 'Kullu (all airports), India', key: 'C_KUU', type: 'city', code: 'kullu_in' } ],
 ['C_TJU', { icon: 'faBuilding', label: 'Kulob (all airports), Tajikistan', key: 'C_TJU', type: 'city', code: 'kulob_tj' } ],
 ['C_KUS', { icon: 'faBuilding', label: 'Kulusuk (all airports), Greenland', key: 'C_KUS', type: 'city', code: 'kulusuk_gl' } ],
 ['C_KMJ', { icon: 'faBuilding', label: 'Kumamoto (all airports), Japan', key: 'C_KMJ', type: 'city', code: 'kumamoto_jp' } ],
 ['C_KMS', { icon: 'faBuilding', label: 'Kumasi (all airports), Ghana', key: 'C_KMS', type: 'city', code: 'kumasi_gh' } ],
 ['C_UEO', { icon: 'faBuilding', label: 'Kume Island (all airports), Japan', key: 'C_UEO', type: 'city', code: 'kumejima_jp' } ],
 ['C_KMG', { icon: 'faBuilding', label: 'Kunming (all airports), China', key: 'C_KMG', type: 'city', code: 'kunming_cn' } ],
 ['C_KNX', { icon: 'faBuilding', label: 'Kununurra (all airports), Australia', key: 'C_KNX', type: 'city', code: 'kununurra_wa_au' } ],
 ['C_KUO', { icon: 'faBuilding', label: 'Kuopio (all airports), Finland', key: 'C_KUO', type: 'city', code: 'kuopio_fi' } ],
 ['C_KOE', { icon: 'faBuilding', label: 'Kupang (all airports), Indonesia', key: 'C_KOE', type: 'city', code: 'kupang_id' } ],
 ['C_KCA', { icon: 'faBuilding', label: 'Kuqa County (all airports), China', key: 'C_KCA', type: 'city', code: 'kuqa-county_cn' } ],
 ['C_KRO', { icon: 'faBuilding', label: 'Kurgan, Kurgan Oblast (all airports), Russia', key: 'C_KRO', type: 'city', code: 'kurgan_ru' } ],
 ['C_URS', { icon: 'faBuilding', label: 'Kursk (all airports), Russia', key: 'C_URS', type: 'city', code: 'kursk_ru' } ],
 ['C_KUH', { icon: 'faBuilding', label: 'Kushiro (all airports), Japan', key: 'C_KUH', type: 'city', code: 'kushiro_jp' } ],
 ['C_KZR', { icon: 'faBuilding', label: 'Kütahya (all airports), Turkey', key: 'C_KZR', type: 'city', code: 'kutahya_tr' } ],
 ['C_KUT', { icon: 'faBuilding', label: 'Kutaisi (all airports), Georgia', key: 'C_KUT', type: 'city', code: 'kutaisi_ge' } ],
 ['C_YVP', { icon: 'faBuilding', label: 'Kuujjuaq (all airports), Canada', key: 'C_YVP', type: 'city', code: 'kuujjuaq_qc_ca' } ],
 ['C_YGW', { icon: 'faBuilding', label: 'Kuujjuarapik (all airports), Canada', key: 'C_YGW', type: 'city', code: 'kuujjuarapik_qc_ca' } ],
 ['C_KAO', { icon: 'faBuilding', label: 'Kuusamo (all airports), Finland', key: 'C_KAO', type: 'city', code: 'kuusamo_fi' } ],
 ['C_KWI', { icon: 'faBuilding', label: 'Kuwait City (all airports), Kuwait', key: 'C_KWI', type: 'city', code: 'kuwait-city_kw' } ],
 ['C_KWA', { icon: 'faBuilding', label: 'Kwajalein Atoll (all airports), Marshall Islands', key: 'C_KWA', type: 'city', code: 'kwajalein-atoll_mh' } ],
 ['C_KWT', { icon: 'faBuilding', label: 'Kwethluk (all airports), United States', key: 'C_KWT', type: 'city', code: 'kwethluk_ak_us' } ],
 ['C_KWK', { icon: 'faBuilding', label: 'Kwigillingok (all airports), United States', key: 'C_KWK', type: 'city', code: 'kwigillingok_ak_us' } ],
 ['C_KYP', { icon: 'faBuilding', label: 'Kyaukpyu (all airports), Myanmar (Burma)', key: 'C_KYP', type: 'city', code: 'kyaukpyu_mm' } ],
 ['C_KIT', { icon: 'faBuilding', label: 'Kythira (all airports), Greece', key: 'C_KIT', type: 'city', code: 'kythira_gr' } ],
 ['C_KYZ', { icon: 'faBuilding', label: 'Kyzyl (all airports), Russia', key: 'C_KYZ', type: 'city', code: 'kyzyl_ru' } ],
 ['C_KZO', { icon: 'faBuilding', label: 'Kyzylorda (all airports), Kazakhstan', key: 'C_KZO', type: 'city', code: 'kyzylorda_kz' } ],
 ['C_LCE', { icon: 'faBuilding', label: 'La Ceiba (all airports), Honduras', key: 'C_LCE', type: 'city', code: 'la-ceiba_hn' } ],
 ['C_LCR', { icon: 'faBuilding', label: 'La Chorrera (all airports), Colombia', key: 'C_LCR', type: 'city', code: 'la-chorrera_co' } ],
 ['C_LSE', { icon: 'faBuilding', label: 'La Crosse (all airports), United States', key: 'C_LSE', type: 'city', code: 'la-crosse_wi_us' } ],
 ['C_LFR', { icon: 'faBuilding', label: 'La Fría (all airports), Venezuela', key: 'C_LFR', type: 'city', code: 'la-fria_ve' } ],
 ['C_YGL', { icon: 'faBuilding', label: 'La Grande River (all airports), Canada', key: 'C_YGL', type: 'city', code: 'la-grande-river_qc_ca' } ],
 ['C_LMC', { icon: 'faBuilding', label: 'La Macarena (all airports), Colombia', key: 'C_LMC', type: 'city', code: 'la-macarena_co' } ],
 ['C_LAP', { icon: 'faBuilding', label: 'La Paz (all airports), Mexico', key: 'C_LAP', type: 'city', code: 'la-paz_mx' } ],
 ['C_LPB', { icon: 'faBuilding', label: 'La Paz (all airports), Bolivia', key: 'C_LPB', type: 'city', code: 'la-paz_bo' } ],
 ['C_LPD', { icon: 'faBuilding', label: 'La Pedrera (all airports), Colombia', key: 'C_LPD', type: 'city', code: 'la-pedrera_co' } ],
 ['C_IRJ', { icon: 'faBuilding', label: 'La Rioja (all airports), Argentina', key: 'C_IRJ', type: 'city', code: 'la-rioja_lr_ar' } ],
 ['C_LRH', { icon: 'faBuilding', label: 'La Rochelle (all airports), France', key: 'C_LRH', type: 'city', code: 'la-rochelle_fr' } ],
 ['C_ZGS', { icon: 'faBuilding', label: 'La Romaine, Quebec (all airports), Canada', key: 'C_ZGS', type: 'city', code: 'la-romaine_qc_ca' } ],
 ['C_LRM', { icon: 'faBuilding', label: 'La Romana (all airports), Dominican Republic', key: 'C_LRM', type: 'city', code: 'la-romana_do' } ],
 ['C_LSC', { icon: 'faBuilding', label: 'La Serena (all airports), Chile', key: 'C_LSC', type: 'city', code: 'la-serena_cl' } ],
 ['C_ZLT', { icon: 'faBuilding', label: 'La Tabatière, Quebec (all airports), Canada', key: 'C_ZLT', type: 'city', code: 'la-tabatiere_qc_ca' } ],
 ['C_EUN', { icon: 'faBuilding', label: 'Laayoune (all airports), Unknown Region', key: 'C_EUN', type: 'city', code: 'laayoune_zz' } ],
 ['C_LBS', { icon: 'faBuilding', label: 'Labasa (all airports), Fiji', key: 'C_LBS', type: 'city', code: 'labasa_fj' } ],
 ['C_LBU', { icon: 'faBuilding', label: 'Labuan (all airports), Malaysia', key: 'C_LBU', type: 'city', code: 'labuan_my' } ],
 ['C_LBJ', { icon: 'faBuilding', label: 'Labuan Bajo (all airports), Indonesia', key: 'C_LBJ', type: 'city', code: 'labuan-bajo_id' } ],
 ['C_LAH', { icon: 'faBuilding', label: 'Labuha (all airports), Indonesia', key: 'C_LAH', type: 'city', code: 'labuha_id' } ],
 ['C_XLB', { icon: 'faBuilding', label: 'Lac Brochet, Manitoba (all airports), Canada', key: 'C_XLB', type: 'city', code: 'lac-brochet_mb_ca' } ],
 ['C_LAE', { icon: 'faBuilding', label: 'Lae (all airports), Papua New Guinea', key: 'C_LAE', type: 'city', code: 'lae_pg' } ],
 ['C_LFT', { icon: 'faBuilding', label: 'Lafayette (all airports), United States', key: 'C_LFT', type: 'city', code: 'lafayette_la_us' } ],
 ['C_LAJ', { icon: 'faBuilding', label: 'Lages (all airports), Brazil', key: 'C_LAJ', type: 'city', code: 'lages_sc_br' } ],
 ['C_LOO', { icon: 'faBuilding', label: 'Laghouat (all airports), Algeria', key: 'C_LOO', type: 'city', code: 'laghouat_dz' } ],
 ['C_LGQ', { icon: 'faBuilding', label: 'Lago Agrio Canton (all airports), Ecuador', key: 'C_LGQ', type: 'city', code: 'lago-agrio-canton_ec' } ],
 ['C_LOS', { icon: 'faBuilding', label: 'Lagos (all airports), Nigeria', key: 'C_LOS', type: 'city', code: 'lagos_ng' } ],
 ['C_LDU', { icon: 'faBuilding', label: 'Lahad Datu (all airports), Malaysia', key: 'C_LDU', type: 'city', code: 'lahad-datu_my' } ],
 ['C_PXA', { icon: 'faBuilding', label: 'Lahat Regency (all airports), Indonesia', key: 'C_PXA', type: 'city', code: 'lahat-regency_id' } ],
 ['C_LHE', { icon: 'faBuilding', label: 'Lahore (all airports), Pakistan', key: 'C_LHE', type: 'city', code: 'lahore_pk' } ],
 ['C_LCH', { icon: 'faBuilding', label: 'Lake Charles (all airports), United States', key: 'C_LCH', type: 'city', code: 'lake-charles_la_us' } ],
 ['C_LKY', { icon: 'faBuilding', label: 'Lake Manyara (all airports), Tanzania', key: 'C_LKY', type: 'city', code: 'lake-manyara_tz' } ],
 ['C_LMA', { icon: 'faBuilding', label: 'Lake Minchumina (all airports), United States', key: 'C_LMA', type: 'city', code: 'lake-minchumina_ak_us' } ],
 ['C_LKB', { icon: 'faBuilding', label: 'Lakeba (all airports), Fiji', key: 'C_LKB', type: 'city', code: 'lakeba_fj' } ],
 ['C_IXI', { icon: 'faBuilding', label: 'Lakhimpur district (all airports), India', key: 'C_IXI', type: 'city', code: 'lilabari_in' } ],
 ['C_LKL', { icon: 'faBuilding', label: 'Lakselv (all airports), Norway', key: 'C_LKL', type: 'city', code: 'lakselv_no' } ],
 ['C_LLI', { icon: 'faBuilding', label: 'Lalibela (all airports), Ethiopia', key: 'C_LLI', type: 'city', code: 'lalibela_et' } ],
 ['C_LPM', { icon: 'faBuilding', label: 'Lamap (all airports), Vanuatu', key: 'C_LPM', type: 'city', code: 'lamap_vu' } ],
 ['C_LNB', { icon: 'faBuilding', label: 'Lamen Bay (all airports), Vanuatu', key: 'C_LNB', type: 'city', code: 'lamen-bay_vu' } ],
 ['C_LFM', { icon: 'faBuilding', label: 'Lamerd (all airports), Iran', key: 'C_LFM', type: 'city', code: 'lamerd_ir' } ],
 ['C_SUF', { icon: 'faBuilding', label: 'Lamezia Terme (all airports), Italy', key: 'C_SUF', type: 'city', code: 'lamezia-terme_it' } ],
 ['C_LPT', { icon: 'faBuilding', label: 'Lampang Province (all airports), Thailand', key: 'C_LPT', type: 'city', code: 'lampang-province_th' } ],
 ['C_LMP', { icon: 'faBuilding', label: 'Lampedusa (all airports), Italy', key: 'C_LMP', type: 'city', code: 'lampedusa_it' } ],
 ['C_LAU', { icon: 'faBuilding', label: 'Lamu (all airports), Kenya', key: 'C_LAU', type: 'city', code: 'lamu_ke' } ],
 ['C_LNY', { icon: 'faBuilding', label: 'Lanai (all airports), United States', key: 'C_LNY', type: 'city', code: 'lanai_hi_us' } ],
 ['C_LUV', { icon: 'faBuilding', label: 'Langgur (all airports), Indonesia', key: 'C_LUV', type: 'city', code: 'langgur_id' } ],
 ['C_LGK', { icon: 'faBuilding', label: 'Langkawi (all airports), Malaysia', key: 'C_LGK', type: 'city', code: 'langkawi_my' } ],
 ['C_LLK', { icon: 'faBuilding', label: 'Lankaran (all airports), Azerbaijan', key: 'C_LLK', type: 'city', code: 'lankaran_az' } ],
 ['C_LAI', { icon: 'faBuilding', label: 'Lannion (all airports), France', key: 'C_LAI', type: 'city', code: 'lannion_fr' } ],
 ['C_LAN', { icon: 'faBuilding', label: 'Lansing (all airports), United States', key: 'C_LAN', type: 'city', code: 'lansing_mi_us' } ],
 ['C_ACE', { icon: 'faBuilding', label: 'Lanzarote (all airports), Spain', key: 'C_ACE', type: 'city', code: 'lanzarote_es' } ],
 ['C_LHW', { icon: 'faBuilding', label: 'Lanzhou (all airports), China', key: 'C_LHW', type: 'city', code: 'lanzhou_cn' } ],
 ['C_LAO', { icon: 'faBuilding', label: 'Laoag (all airports), Philippines', key: 'C_LAO', type: 'city', code: 'laoag_ph' } ],
 ['C_LPP', { icon: 'faBuilding', label: 'Lappeenranta (all airports), Finland', key: 'C_LPP', type: 'city', code: 'lappeenranta_fi' } ],
 ['C_LRR', { icon: 'faBuilding', label: 'Lar (all airports), Iran', key: 'C_LRR', type: 'city', code: 'lar_ir' } ],
 ['C_LAR', { icon: 'faBuilding', label: 'Laramie (all airports), United States', key: 'C_LAR', type: 'city', code: 'laramie_wy_us' } ],
 ['C_LKA', { icon: 'faBuilding', label: 'Larantuka (all airports), Indonesia', key: 'C_LKA', type: 'city', code: 'larantuka_id' } ],
 ['C_LRD', { icon: 'faBuilding', label: 'Laredo (all airports), United States', key: 'C_LRD', type: 'city', code: 'laredo_tx_us' } ],
 ['C_LCA', { icon: 'faBuilding', label: 'Larnaca (all airports), Cyprus', key: 'C_LCA', type: 'city', code: 'larnaca_cy' } ],
 ['C_KLN', { icon: 'faBuilding', label: 'Larsen Bay (all airports), United States', key: 'C_KLN', type: 'city', code: 'larsen-bay_ak_us' } ],
 ['C_LPA', { icon: 'faBuilding', label: 'Las Palmas (all airports), Spain', key: 'C_LPA', type: 'city', code: 'las-palmas_es' } ],
 ['C_LSP', { icon: 'faBuilding', label: 'Las Piedras (all airports), Venezuela', key: 'C_LSP', type: 'city', code: 'las-piedras_ve' } ],
 ['C_VTU', { icon: 'faBuilding', label: 'Las Tunas (all airports), Cuba', key: 'C_VTU', type: 'city', code: 'las-tunas_cu' } ],
 ['C_LAS', { icon: 'faBuilding', label: 'Las Vegas (all airports), United States', key: 'C_LAS', type: 'city', code: 'las-vegas_nv_us' } ],
 ['C_LSH', { icon: 'faBuilding', label: 'Lashio (all airports), Myanmar (Burma)', key: 'C_LSH', type: 'city', code: 'lashio_mm' } ],
 ['C_BST', { icon: 'faBuilding', label: 'Lashkargah (all airports), Afghanistan', key: 'C_BST', type: 'city', code: 'lashkargah_af' } ],
 ['C_LTX', { icon: 'faBuilding', label: 'Latacunga (all airports), Ecuador', key: 'C_LTX', type: 'city', code: 'latacunga_ec' } ],
 ['C_LBE', { icon: 'faBuilding', label: 'Latrobe (all airports), United States', key: 'C_LBE', type: 'city', code: 'latrobe_pa_us' } ],
 ['C_LUL', { icon: 'faBuilding', label: 'Laurel (all airports), United States', key: 'C_LUL', type: 'city', code: 'laurel_ms_us' } ],
 ['C_LWY', { icon: 'faBuilding', label: 'Lawas (all airports), Malaysia', key: 'C_LWY', type: 'city', code: 'lawas_my' } ],
 ['C_LAW', { icon: 'faBuilding', label: 'Lawton (all airports), United States', key: 'C_LAW', type: 'city', code: 'lawton_ok_us' } ],
 ['C_LZC', { icon: 'faBuilding', label: 'Lázaro Cárdenas (all airports), Mexico', key: 'C_LZC', type: 'city', code: 'lazaro-cardenas_mx' } ],
 ['C_XCC', { icon: 'faBuilding', label: 'Le Creusot/Montceau (all airports), France', key: 'C_XCC', type: 'city', code: 'le-creusot-montceau_fr' } ],
 ['C_LME', { icon: 'faBuilding', label: 'Le Mans (all airports), France', key: 'C_LME', type: 'city', code: 'le-mans_fr' } ],
 ['C_LPY', { icon: 'faBuilding', label: 'Le Puy-en-Velay (all airports), France', key: 'C_LPY', type: 'city', code: 'le-puy-en-velay_fr' } ],
 ['C_LEB', { icon: 'faBuilding', label: 'Lebanon (all airports), United States', key: 'C_LEB', type: 'city', code: 'lebanon_nh_us' } ],
 ['C_LBA', { icon: 'faBuilding', label: 'Leeds (all airports), United Kingdom', key: 'C_LBA', type: 'city', code: 'leeds_gb' } ],
 ['C_LGP', { icon: 'faBuilding', label: 'Legazpi (all airports), Philippines', key: 'C_LGP', type: 'city', code: 'legazpi_ph' } ],
 ['C_IXL', { icon: 'faBuilding', label: 'Leh (all airports), Unknown Region', key: 'C_IXL', type: 'city', code: 'leh_zz' } ],
 ['C_LEJ', { icon: 'faBuilding', label: 'Leipzig (all airports), Germany', key: 'C_LEJ', type: 'city', code: 'leipzig_de' } ],
 ['C_LKN', { icon: 'faBuilding', label: 'Leknes (all airports), Norway', key: 'C_LKN', type: 'city', code: 'leknes_no' } ],
 ['C_LXS', { icon: 'faBuilding', label: 'Lemnos (all airports), Greece', key: 'C_LXS', type: 'city', code: 'lemnos_gr' } ],
 ['C_LEC', { icon: 'faBuilding', label: 'Lençóis (all airports), Brazil', key: 'C_LEC', type: 'city', code: 'lencois_ba_br' } ],
 ['C_ULK', { icon: 'faBuilding', label: 'Lensk (all airports), Russia', key: 'C_ULK', type: 'city', code: 'lensk_ru' } ],
 ['C_ZJD', { icon: 'faBuilding', label: 'Lenzerheide/Lai (all airports), Switzerland', key: 'C_ZJD', type: 'city', code: 'lenzerheide-lai_ch' } ],
 ['C_LEN', { icon: 'faBuilding', label: 'León (all airports), Spain', key: 'C_LEN', type: 'city', code: 'leon_es' } ],
 ['C_BJX', { icon: 'faBuilding', label: 'León (all airports), Mexico', key: 'C_BJX', type: 'city', code: 'leon_mx' } ],
 ['C_LRS', { icon: 'faBuilding', label: 'Leros (all airports), Greece', key: 'C_LRS', type: 'city', code: 'leros_gr' } ],
 ['C_YGR', { icon: 'faBuilding', label: 'Les Îles-de-la-Madeleine, Quebec (all airports), Canada', key: 'C_YGR', type: 'city', code: 'les-iles-de-la-madeleine_qc_ca' } ],
 ['C_LSS', { icon: 'faBuilding', label: 'Les Saintes (all airports), France', key: 'C_LSS', type: 'city', code: 'les-saintes_gp' } ],
 ['C_YQL', { icon: 'faBuilding', label: 'Lethbridge (all airports), Canada', key: 'C_YQL', type: 'city', code: 'lethbridge_ab_ca' } ],
 ['C_LET', { icon: 'faBuilding', label: 'Leticia, Amazonas (all airports), Colombia', key: 'C_LET', type: 'city', code: 'leticia_co' } ],
 ['C_KLL', { icon: 'faBuilding', label: 'Levelock (all airports), United States', key: 'C_KLL', type: 'city', code: 'levelock_ak_us' } ],
 ['C_XGX', { icon: 'faBuilding', label: 'Levi (all airports), Finland', key: 'C_XGX', type: 'city', code: 'levi_fi' } ],
 ['C_LWB', { icon: 'faBuilding', label: 'Lewisburg (all airports), United States', key: 'C_LWB', type: 'city', code: 'lewisburg_wv_us' } ],
 ['C_LWS', { icon: 'faBuilding', label: 'Lewiston (all airports), United States', key: 'C_LWS', type: 'city', code: 'lewiston_id_us' } ],
 ['C_LWE', { icon: 'faBuilding', label: 'Lewoleba (all airports), Indonesia', key: 'C_LWE', type: 'city', code: 'lewoleba_id' } ],
 ['C_LEX', { icon: 'faBuilding', label: 'Lexington (all airports), United States', key: 'C_LEX', type: 'city', code: 'lexington_ky_us' } ],
 ['C_LXA', { icon: 'faBuilding', label: 'Lhasa (all airports), China', key: 'C_LXA', type: 'city', code: 'lhasa_cn' } ],
 ['C_LSW', { icon: 'faBuilding', label: 'Lhokseumawe (all airports), Indonesia', key: 'C_LSW', type: 'city', code: 'lhokseumawe_id' } ],
 ['C_LYG', { icon: 'faBuilding', label: 'Lianyungang (all airports), China', key: 'C_LYG', type: 'city', code: 'lianyungang_cn' } ],
 ['C_LBL', { icon: 'faBuilding', label: 'Liberal (all airports), United States', key: 'C_LBL', type: 'city', code: 'liberal_ks_us' } ],
 ['C_LIR', { icon: 'faBuilding', label: 'Liberia (all airports), Costa Rica', key: 'C_LIR', type: 'city', code: 'liberia_cr' } ],
 ['C_LLB', { icon: 'faBuilding', label: 'Libo County (all airports), China', key: 'C_LLB', type: 'city', code: 'libo-county_cn' } ],
 ['C_LBV', { icon: 'faBuilding', label: 'Libreville (all airports), Gabon', key: 'C_LBV', type: 'city', code: 'libreville_ga' } ],
 ['C_XQT', { icon: 'faBuilding', label: 'Lichfield (all airports), United Kingdom', key: 'C_XQT', type: 'city', code: 'lichfield_gb' } ],
 ['C_VXC', { icon: 'faBuilding', label: 'Lichinga (all airports), Mozambique', key: 'C_VXC', type: 'city', code: 'lichinga_mz' } ],
 ['C_LGG', { icon: 'faBuilding', label: 'Liège (all airports), Belgium', key: 'C_LGG', type: 'city', code: 'liege_be' } ],
 ['C_LPX', { icon: 'faBuilding', label: 'Liepāja (all airports), Latvia', key: 'C_LPX', type: 'city', code: 'liepaja_lv' } ],
 ['C_LIF', { icon: 'faBuilding', label: 'Lifou Island (all airports), New Caledonia', key: 'C_LIF', type: 'city', code: 'lifou-island_nc' } ],
 ['C_LNV', { icon: 'faBuilding', label: 'Lihir Group (all airports), Papua New Guinea', key: 'C_LNV', type: 'city', code: 'lihir-group_pg' } ],
 ['C_LJG', { icon: 'faBuilding', label: 'Lijiang (all airports), China', key: 'C_LJG', type: 'city', code: 'lijiang_cn' } ],
 ['C_LIL', { icon: 'faBuilding', label: 'Lille (all airports), France', key: 'C_LIL', type: 'city', code: 'lille_fr' } ],
 ['C_LLW', { icon: 'faBuilding', label: 'Lilongwe (all airports), Malawi', key: 'C_LLW', type: 'city', code: 'lilongwe_mw' } ],
 ['C_LIM', { icon: 'faBuilding', label: 'Lima (all airports), Peru', key: 'C_LIM', type: 'city', code: 'lima_pe' } ],
 ['C_LMN', { icon: 'faBuilding', label: 'Limbang (all airports), Malaysia', key: 'C_LMN', type: 'city', code: 'limbang_my' } ],
 ['C_LIG', { icon: 'faBuilding', label: 'Limoges (all airports), France', key: 'C_LIG', type: 'city', code: 'limoges_fr' } ],
 ['C_LNJ', { icon: 'faBuilding', label: 'Lincang (all airports), China', key: 'C_LNJ', type: 'city', code: 'lincang_cn' } ],
 ['C_LNK', { icon: 'faBuilding', label: 'Lincoln (all airports), United States', key: 'C_LNK', type: 'city', code: 'lincoln_ne_us' } ],
 ['C_LFQ', { icon: 'faBuilding', label: 'Linfen (all airports), China', key: 'C_LFQ', type: 'city', code: 'linfen_cn' } ],
 ['C_LPI', { icon: 'faBuilding', label: 'Linköping (all airports), Sweden', key: 'C_LPI', type: 'city', code: 'linkoping_se' } ],
 ['C_LYI', { icon: 'faBuilding', label: 'Linyi (all airports), China', key: 'C_LYI', type: 'city', code: 'linyi_cn' } ],
 ['C_LNZ', { icon: 'faBuilding', label: 'Linz (all airports), Austria', key: 'C_LNZ', type: 'city', code: 'linz_at' } ],
 ['C_LPK', { icon: 'faBuilding', label: 'Lipetsk (all airports), Russia', key: 'C_LPK', type: 'city', code: 'lipetsk_ru' } ],
 ['C_HZH', { icon: 'faBuilding', label: 'Liping County (all airports), China', key: 'C_HZH', type: 'city', code: 'liping-county_cn' } ],
 ['C_LIQ', { icon: 'faBuilding', label: 'Lisala (all airports), Democratic Republic of the Congo', key: 'C_LIQ', type: 'city', code: 'lisala_cd' } ],
 ['C_LIS', { icon: 'faBuilding', label: 'Lisbon (all airports), Portugal', key: 'C_LIS', type: 'city', code: 'lisbon_pt' } ],
 ['C_LSY', { icon: 'faBuilding', label: 'Lismore (all airports), Australia', key: 'C_LSY', type: 'city', code: 'lismore_ns_au' } ],
 ['C_LYB', { icon: 'faBuilding', label: 'Little Cayman (all airports), Cayman Islands', key: 'C_LYB', type: 'city', code: 'little-cayman_ky' } ],
 ['C_LIT', { icon: 'faBuilding', label: 'Little Rock (all airports), United States', key: 'C_LIT', type: 'city', code: 'little-rock_ar_us' } ],
 ['C_LPF', { icon: 'faBuilding', label: 'Liupanshui (all airports), China', key: 'C_LPF', type: 'city', code: 'liupanshui_cn' } ],
 ['C_LZH', { icon: 'faBuilding', label: 'Liuzhou (all airports), China', key: 'C_LZH', type: 'city', code: 'liuzhou_cn' } ],
 ['C_LPL', { icon: 'faBuilding', label: 'Liverpool (all airports), United Kingdom', key: 'C_LPL', type: 'city', code: 'liverpool_gb' } ],
 ['C_LVI', { icon: 'faBuilding', label: 'Livingstone (all airports), Zambia', key: 'C_LVI', type: 'city', code: 'livingstone_zm' } ],
 ['C_LJU', { icon: 'faBuilding', label: 'Ljubljana (all airports), Slovenia', key: 'C_LJU', type: 'city', code: 'ljubljana_si' } ],
 ['C_ILD', { icon: 'faBuilding', label: 'Lleida (all airports), Spain', key: 'C_ILD', type: 'city', code: 'lleida_es' } ],
 ['C_YLL', { icon: 'faBuilding', label: 'Lloydminster (all airports), Canada', key: 'C_YLL', type: 'city', code: 'lloydminster_sk_ca' } ],
 ['C_LOK', { icon: 'faBuilding', label: 'Lodwar (all airports), Kenya', key: 'C_LOK', type: 'city', code: 'lodwar_ke' } ],
 ['C_LCJ', { icon: 'faBuilding', label: 'Łódź (all airports), Poland', key: 'C_LCJ', type: 'city', code: 'lodz_pl' } ],
 ['C_LOE', { icon: 'faBuilding', label: 'Loei Province (all airports), Thailand', key: 'C_LOE', type: 'city', code: 'loei-province_th' } ],
 ['C_RJL', { icon: 'faBuilding', label: 'Logroño (all airports), Spain', key: 'C_RJL', type: 'city', code: 'logrono_es' } ],
 ['C_LIW', { icon: 'faBuilding', label: 'Loikaw (all airports), Myanmar (Burma)', key: 'C_LIW', type: 'city', code: 'loikaw_mm' } ],
 ['C_LOH', { icon: 'faBuilding', label: 'Loja (all airports), Ecuador', key: 'C_LOH', type: 'city', code: 'loja_ec' } ],
 ['C_LFW', { icon: 'faBuilding', label: 'Lomé (all airports), Togo', key: 'C_LFW', type: 'city', code: 'lome_tg' } ],
 ['C_LDZ', { icon: 'faBuilding', label: 'Londolozi Private Game Reserve (all airports), South Africa', key: 'C_LDZ', type: 'city', code: 'londolozi-private-game-reserve_za' } ],
 ['C_LON', { icon: 'faBuilding', label: 'London (all airports), United Kingdom', key: 'C_LON', type: 'city', code: 'london_gb' } ],
 ['C_YXU', { icon: 'faBuilding', label: 'London (all airports), Canada', key: 'C_YXU', type: 'city', code: 'london_on_ca' } ],
 ['C_LDB', { icon: 'faBuilding', label: 'Londrina (all airports), Brazil', key: 'C_LDB', type: 'city', code: 'londrina_pr_br' } ],
 ['C_LKH', { icon: 'faBuilding', label: 'Long Akah (all airports), Malaysia', key: 'C_LKH', type: 'city', code: 'long-akah_my' } ],
 ['C_LBP', { icon: 'faBuilding', label: 'Long Banga (all airports), Malaysia', key: 'C_LBP', type: 'city', code: 'long-banga_my' } ],
 ['C_LGB', { icon: 'faBuilding', label: 'Long Beach (all airports), United States', key: 'C_LGB', type: 'city', code: 'long-beach_ca_us' } ],
 ['C_LGL', { icon: 'faBuilding', label: 'Long Lellang (all airports), Malaysia', key: 'C_LGL', type: 'city', code: 'long-lellang_my' } ],
 ['C_ODN', { icon: 'faBuilding', label: 'Long Seridan (all airports), Malaysia', key: 'C_ODN', type: 'city', code: 'long-seridan_my' } ],
 ['C_LOD', { icon: 'faBuilding', label: 'Longana (all airports), Vanuatu', key: 'C_LOD', type: 'city', code: 'longana_vu' } ],
 ['C_LRE', { icon: 'faBuilding', label: 'Longreach (all airports), Australia', key: 'C_LRE', type: 'city', code: 'longreach_ql_au' } ],
 ['C_GGG', { icon: 'faBuilding', label: 'Longview (all airports), United States', key: 'C_GGG', type: 'city', code: 'longview_tx_us' } ],
 ['C_LCX', { icon: 'faBuilding', label: 'Longyan (all airports), China', key: 'C_LCX', type: 'city', code: 'longyan_cn' } ],
 ['C_LYR', { icon: 'faBuilding', label: 'Longyearbyen (all airports), Svalbard & Jan Mayen', key: 'C_LYR', type: 'city', code: 'longyearbyen_sj' } ],
 ['C_LNE', { icon: 'faBuilding', label: 'Lonorore (all airports), Vanuatu', key: 'C_LNE', type: 'city', code: 'lonorore_vu' } ],
 ['C_LPS', { icon: 'faBuilding', label: 'Lopez Island (all airports), United States', key: 'C_LPS', type: 'city', code: 'lopez-island_wa_us' } ],
 ['C_XJR', { icon: 'faBuilding', label: 'Lorca (all airports), Spain', key: 'C_XJR', type: 'city', code: 'lorca_es' } ],
 ['C_LDH', { icon: 'faBuilding', label: 'Lord Howe Island (all airports), Australia', key: 'C_LDH', type: 'city', code: 'lord-howe-island_ns_au' } ],
 ['C_LTO', { icon: 'faBuilding', label: 'Loreto (all airports), Mexico', key: 'C_LTO', type: 'city', code: 'loreto_mx' } ],
 ['C_LRT', { icon: 'faBuilding', label: 'Lorient (all airports), France', key: 'C_LRT', type: 'city', code: 'lorient_fr' } ],
 ['C_LAX', { icon: 'faBuilding', label: 'Los Angeles (all airports), United States', key: 'C_LAX', type: 'city', code: 'los-angeles_ca_us' } ],
 ['C_LMM', { icon: 'faBuilding', label: 'Los Mochis (all airports), Mexico', key: 'C_LMM', type: 'city', code: 'los-mochis_mx' } ],
 ['C_SDF', { icon: 'faBuilding', label: 'Louisville (all airports), United States', key: 'C_SDF', type: 'city', code: 'louisville_ky_us' } ],
 ['C_LDE', { icon: 'faBuilding', label: 'Lourdes (all airports), France', key: 'C_LDE', type: 'city', code: 'lourdes_fr' } ],
 ['C_SXM', { icon: 'faBuilding', label: 'Lower Prince\'s Quarter (all airports), Sint Maarten', key: 'C_SXM', type: 'city', code: 'lower-princes-quarter_sx' } ],
 ['C_LAD', { icon: 'faBuilding', label: 'Luanda (all airports), Angola', key: 'C_LAD', type: 'city', code: 'luanda_ao' } ],
 ['C_LXG', { icon: 'faBuilding', label: 'Luang Namtha (all airports), Laos', key: 'C_LXG', type: 'city', code: 'luang-namtha_la' } ],
 ['C_LPQ', { icon: 'faBuilding', label: 'Luang Prabang (all airports), Laos', key: 'C_LPQ', type: 'city', code: 'luang-prabang_la' } ],
 ['C_SDD', { icon: 'faBuilding', label: 'Lubango (all airports), Angola', key: 'C_SDD', type: 'city', code: 'lubango_ao' } ],
 ['C_LBB', { icon: 'faBuilding', label: 'Lubbock (all airports), United States', key: 'C_LBB', type: 'city', code: 'lubbock_tx_us' } ],
 ['C_LBC', { icon: 'faBuilding', label: 'Lubeck (all airports), Germany', key: 'C_LBC', type: 'city', code: 'lubeck_de' } ],
 ['C_LUZ', { icon: 'faBuilding', label: 'Lublin (all airports), Poland', key: 'C_LUZ', type: 'city', code: 'lublin_pl' } ],
 ['C_FBM', { icon: 'faBuilding', label: 'Lubumbashi (all airports), Democratic Republic of the Congo', key: 'C_FBM', type: 'city', code: 'lubumbashi_cd' } ],
 ['C_LKO', { icon: 'faBuilding', label: 'Lucknow (all airports), India', key: 'C_LKO', type: 'city', code: 'lucknow_in' } ],
 ['C_LUD', { icon: 'faBuilding', label: 'Lüderitz (all airports), Namibia', key: 'C_LUD', type: 'city', code: 'luderitz_na' } ],
 ['C_LUH', { icon: 'faBuilding', label: 'Ludhiana (all airports), India', key: 'C_LUH', type: 'city', code: 'ludhiana_in' } ],
 ['C_LUO', { icon: 'faBuilding', label: 'Luena (all airports), Angola', key: 'C_LUO', type: 'city', code: 'luena_ao' } ],
 ['C_LUG', { icon: 'faBuilding', label: 'Lugano (all airports), Switzerland', key: 'C_LUG', type: 'city', code: 'lugano_ch' } ],
 ['C_SON', { icon: 'faBuilding', label: 'Luganville (all airports), Vanuatu', key: 'C_SON', type: 'city', code: 'luganville_vu' } ],
 ['C_LGJ', { icon: 'faBuilding', label: 'Lugoj (all airports), Romania', key: 'C_LGJ', type: 'city', code: 'lugoj_ro' } ],
 ['C_LUA', { icon: 'faBuilding', label: 'Lukla (all airports), Nepal', key: 'C_LUA', type: 'city', code: 'lukla_np' } ],
 ['C_LLA', { icon: 'faBuilding', label: 'Luleå (all airports), Sweden', key: 'C_LLA', type: 'city', code: 'lulea_se' } ],
 ['C_LLV', { icon: 'faBuilding', label: 'Lüliang (all airports), China', key: 'C_LLV', type: 'city', code: 'luliang_cn' } ],
 ['C_LYA', { icon: 'faBuilding', label: 'Luoyang (all airports), China', key: 'C_LYA', type: 'city', code: 'luoyang_cn' } ],
 ['C_LUN', { icon: 'faBuilding', label: 'Lusaka (all airports), Zambia', key: 'C_LUN', type: 'city', code: 'lusaka_zm' } ],
 ['C_YSG', { icon: 'faBuilding', label: 'Lutselk\'e (all airports), Canada', key: 'C_YSG', type: 'city', code: 'lutselke_nt_ca' } ],
 ['C_LUW', { icon: 'faBuilding', label: 'Luwuk (all airports), Indonesia', key: 'C_LUW', type: 'city', code: 'luwuk_id' } ],
 ['C_LUX', { icon: 'faBuilding', label: 'Luxembourg City (all airports), Luxembourg', key: 'C_LUX', type: 'city', code: 'luxembourg-city_lu' } ],
 ['C_LXR', { icon: 'faBuilding', label: 'Luxor (all airports), Egypt', key: 'C_LXR', type: 'city', code: 'luxor_eg' } ],
 ['C_LZO', { icon: 'faBuilding', label: 'Luzhou (all airports), China', key: 'C_LZO', type: 'city', code: 'luzhou_cn' } ],
 ['C_LWO', { icon: 'faBuilding', label: 'Lviv (all airports), Ukraine', key: 'C_LWO', type: 'city', code: 'lviv_ua' } ],
 ['C_LYC', { icon: 'faBuilding', label: 'Lycksele (all airports), Sweden', key: 'C_LYC', type: 'city', code: 'lycksele_se' } ],
 ['C_LYH', { icon: 'faBuilding', label: 'Lynchburg (all airports), United States', key: 'C_LYH', type: 'city', code: 'lynchburg_va_us' } ],
 ['C_LYS', { icon: 'faBuilding', label: 'Lyon (all airports), France', key: 'C_LYS', type: 'city', code: 'lyon_fr' } ],
 ['C_VAM', { icon: 'faBuilding', label: 'Maamigili (all airports), Maldives', key: 'C_VAM', type: 'city', code: 'maamingili_mv' } ],
 ['C_MRE', { icon: 'faBuilding', label: 'Maasai Mara (all airports), Kenya', key: 'C_MRE', type: 'city', code: 'maasai-mara_ke' } ],
 ['C_MST', { icon: 'faBuilding', label: 'Maastricht (all airports), Netherlands', key: 'C_MST', type: 'city', code: 'maastricht_nl' } ],
 ['C_MCP', { icon: 'faBuilding', label: 'Macapá (all airports), Brazil', key: 'C_MCP', type: 'city', code: 'macapa_ap_br' } ],
 ['C_MFM', { icon: 'faBuilding', label: 'Macau (all airports), China', key: 'C_MFM', type: 'city', code: 'macau_cn' } ],
 ['C_MCZ', { icon: 'faBuilding', label: 'Maceió (all airports), Brazil', key: 'C_MCZ', type: 'city', code: 'maceio_al_br' } ],
 ['C_MKY', { icon: 'faBuilding', label: 'Mackay (all airports), Australia', key: 'C_MKY', type: 'city', code: 'mackay_ql_au' } ],
 ['C_MCN', { icon: 'faBuilding', label: 'Macon (all airports), United States', key: 'C_MCN', type: 'city', code: 'macon_ga_us' } ],
 ['C_MAG', { icon: 'faBuilding', label: 'Madang (all airports), Papua New Guinea', key: 'C_MAG', type: 'city', code: 'madang_pg' } ],
 ['C_FNC', { icon: 'faBuilding', label: 'Madeira (all airports), Portugal', key: 'C_FNC', type: 'city', code: 'madeira_pt' } ],
 ['C_MSN', { icon: 'faBuilding', label: 'Madison (all airports), United States', key: 'C_MSN', type: 'city', code: 'madison_wi_us' } ],
 ['C_MAD', { icon: 'faBuilding', label: 'Madrid (all airports), Spain', key: 'C_MAD', type: 'city', code: 'madrid_es' } ],
 ['C_IXM', { icon: 'faBuilding', label: 'Madurai (all airports), India', key: 'C_IXM', type: 'city', code: 'madurai_in' } ],
 ['C_HGN', { icon: 'faBuilding', label: 'Mae Hong Son Province (all airports), Thailand', key: 'C_HGN', type: 'city', code: 'mae-hong-son-province_th' } ],
 ['C_MAQ', { icon: 'faBuilding', label: 'Mae Sot District (all airports), Thailand', key: 'C_MAQ', type: 'city', code: 'mae-sot-district_th' } ],
 ['C_MWF', { icon: 'faBuilding', label: 'Maewo (all airports), Vanuatu', key: 'C_MWF', type: 'city', code: 'maewo_vu' } ],
 ['C_MFA', { icon: 'faBuilding', label: 'Mafia Island (all airports), Tanzania', key: 'C_MFA', type: 'city', code: 'mafia-island_tz' } ],
 ['C_GDX', { icon: 'faBuilding', label: 'Magadan (all airports), Russia', key: 'C_GDX', type: 'city', code: 'magadan_ru' } ],
 ['C_GYG', { icon: 'faBuilding', label: 'Magan (all airports), Russia', key: 'C_GYG', type: 'city', code: 'magan_ru' } ],
 ['C_MQF', { icon: 'faBuilding', label: 'Magnitogorsk (all airports), Russia', key: 'C_MQF', type: 'city', code: 'magnitogorsk_ru' } ],
 ['C_MZG', { icon: 'faBuilding', label: 'Magong (all airports), Taiwan', key: 'C_MZG', type: 'city', code: 'magong_tw' } ],
 ['C_MJN', { icon: 'faBuilding', label: 'Mahajanga (all airports), Madagascar', key: 'C_MJN', type: 'city', code: 'mahajanga_mg' } ],
 ['C_SEZ', { icon: 'faBuilding', label: 'Mahé (all airports), Seychelles', key: 'C_SEZ', type: 'city', code: 'mahe_sc' } ],
 ['C_null', { icon: 'faBuilding', label: 'Maiduguri (all airports), Nigeria', key: 'C_null', type: 'city', code: 'maiduguri_ng' } ],
 ['C_MMO', { icon: 'faBuilding', label: 'Maio (all airports), Cape Verde', key: 'C_MMO', type: 'city', code: 'maio_cv' } ],
 ['C_MAJ', { icon: 'faBuilding', label: 'Majuro (all airports), Marshall Islands', key: 'C_MAJ', type: 'city', code: 'majuro_mh' } ],
 ['C_UPG', { icon: 'faBuilding', label: 'Makassar (all airports), Indonesia', key: 'C_UPG', type: 'city', code: 'makassar_id' } ],
 ['C_MKP', { icon: 'faBuilding', label: 'Makemo (all airports), French Polynesia', key: 'C_MKP', type: 'city', code: 'makemo_pf' } ],
 ['C_MCX', { icon: 'faBuilding', label: 'Makhachkala (all airports), Russia', key: 'C_MCX', type: 'city', code: 'makhachkala_ru' } ],
 ['C_YMN', { icon: 'faBuilding', label: 'Makkovik (all airports), Canada', key: 'C_YMN', type: 'city', code: 'makkovik_nl_ca' } ],
 ['C_SSG', { icon: 'faBuilding', label: 'Malabo (all airports), Equatorial Guinea', key: 'C_SSG', type: 'city', code: 'malabo_gq' } ],
 ['C_MKZ', { icon: 'faBuilding', label: 'Malacca City (all airports), Malaysia', key: 'C_MKZ', type: 'city', code: 'malacca-city_my' } ],
 ['C_AGP', { icon: 'faBuilding', label: 'Málaga (all airports), Spain', key: 'C_AGP', type: 'city', code: 'malaga_es' } ],
 ['C_MLG', { icon: 'faBuilding', label: 'Malang (all airports), Indonesia', key: 'C_MLG', type: 'city', code: 'malang_id' } ],
 ['C_MLX', { icon: 'faBuilding', label: 'Malatya (all airports), Turkey', key: 'C_MLX', type: 'city', code: 'malatya_tr' } ],
 ['C_MLE', { icon: 'faBuilding', label: 'Malé (all airports), Maldives', key: 'C_MLE', type: 'city', code: 'male_mv' } ],
 ['C_MYD', { icon: 'faBuilding', label: 'Malindi (all airports), Kenya', key: 'C_MYD', type: 'city', code: 'malindi_ke' } ],
 ['C_MMA', { icon: 'faBuilding', label: 'Malmö (all airports), Sweden', key: 'C_MMA', type: 'city', code: 'malmo_se' } ],
 ['C_MLA', { icon: 'faBuilding', label: 'Malta (all airports), Malta', key: 'C_MLA', type: 'city', code: 'malta_mt' } ],
 ['C_MMH', { icon: 'faBuilding', label: 'Mammoth Lakes (all airports), United States', key: 'C_MMH', type: 'city', code: 'mammoth-lakes_ca_us' } ],
 ['C_DZA', { icon: 'faBuilding', label: 'Mamoudzou (all airports), France', key: 'C_DZA', type: 'city', code: 'mamoudzou_yt' } ],
 ['C_MJU', { icon: 'faBuilding', label: 'Mamuju Regency (all airports), Indonesia', key: 'C_MJU', type: 'city', code: 'mamuju-regency_id' } ],
 ['C_MJC', { icon: 'faBuilding', label: 'Man (all airports), Côte d’Ivoire', key: 'C_MJC', type: 'city', code: 'man_ci' } ],
 ['C_MDC', { icon: 'faBuilding', label: 'Manado (all airports), Indonesia', key: 'C_MDC', type: 'city', code: 'manado_id' } ],
 ['C_MGA', { icon: 'faBuilding', label: 'Managua (all airports), Nicaragua', key: 'C_MGA', type: 'city', code: 'managua_ni' } ],
 ['C_MGU', { icon: 'faBuilding', label: 'Manaung (all airports), Myanmar (Burma)', key: 'C_MGU', type: 'city', code: 'manaung_mm' } ],
 ['C_MAO', { icon: 'faBuilding', label: 'Manaus (all airports), Brazil', key: 'C_MAO', type: 'city', code: 'manaus_am_br' } ],
 ['C_MHT', { icon: 'faBuilding', label: 'Manchester (all airports), United States', key: 'C_MHT', type: 'city', code: 'manchester_nh_us' } ],
 ['C_MAN', { icon: 'faBuilding', label: 'Manchester (all airports), United Kingdom', key: 'C_MAN', type: 'city', code: 'manchester_gb' } ],
 ['C_MDL', { icon: 'faBuilding', label: 'Mandalay (all airports), Myanmar (Burma)', key: 'C_MDL', type: 'city', code: 'mandalay_mm' } ],
 ['C_LUM', { icon: 'faBuilding', label: 'Mang City (all airports), China', key: 'C_LUM', type: 'city', code: 'mang-city_cn' } ],
 ['C_MGS', { icon: 'faBuilding', label: 'Mangaia Island (all airports), Cook Islands', key: 'C_MGS', type: 'city', code: 'mangaia-island_ck' } ],
 ['C_IXE', { icon: 'faBuilding', label: 'Mangalore (all airports), India', key: 'C_IXE', type: 'city', code: 'mangalore_in' } ],
 ['C_MHK', { icon: 'faBuilding', label: 'Manhattan (all airports), United States', key: 'C_MHK', type: 'city', code: 'manhattan_ks_us' } ],
 ['C_XMH', { icon: 'faBuilding', label: 'Manihi (all airports), French Polynesia', key: 'C_XMH', type: 'city', code: 'manihi_pf' } ],
 ['C_null', { icon: 'faBuilding', label: 'Manihiki Island (all airports), Cook Islands', key: 'C_null', type: 'city', code: 'manihiki-island_ck' } ],
 ['C_JSU', { icon: 'faBuilding', label: 'Maniitsoq (all airports), Greenland', key: 'C_JSU', type: 'city', code: 'maniitsoq_gl' } ],
 ['C_MNL', { icon: 'faBuilding', label: 'Manila (all airports), Philippines', key: 'C_MNL', type: 'city', code: 'manila_ph' } ],
 ['C_MNG', { icon: 'faBuilding', label: 'Maningrida (all airports), Australia', key: 'C_MNG', type: 'city', code: 'maningrida_nt_au' } ],
 ['C_MBL', { icon: 'faBuilding', label: 'Manistee (all airports), United States', key: 'C_MBL', type: 'city', code: 'manistee_mi_us' } ],
 ['C_MZL', { icon: 'faBuilding', label: 'Manizales (all airports), Colombia', key: 'C_MZL', type: 'city', code: 'manizales_co' } ],
 ['C_MLY', { icon: 'faBuilding', label: 'Manley Hot Springs (all airports), United States', key: 'C_MLY', type: 'city', code: 'manley-hot-springs_ak_us' } ],
 ['C_MHG', { icon: 'faBuilding', label: 'Mannheim (all airports), Germany', key: 'C_MHG', type: 'city', code: 'mannheim_de' } ],
 ['C_MKW', { icon: 'faBuilding', label: 'Manokwari (all airports), Indonesia', key: 'C_MKW', type: 'city', code: 'manokwari_id' } ],
 ['C_ZMA', { icon: 'faBuilding', label: 'Mansfield (all airports), United Kingdom', key: 'C_ZMA', type: 'city', code: 'mansfield_gb' } ],
 ['C_MEC', { icon: 'faBuilding', label: 'Manta (all airports), Ecuador', key: 'C_MEC', type: 'city', code: 'manta_ec' } ],
 ['C_ZGI', { icon: 'faBuilding', label: 'Manto Sipi Cree Nation (all airports), Canada', key: 'C_ZGI', type: 'city', code: 'manto-sipi-cree-nation_mb_ca' } ],
 ['C_MAS', { icon: 'faBuilding', label: 'Manus Island (all airports), Papua New Guinea', key: 'C_MAS', type: 'city', code: 'manus-island_pg' } ],
 ['C_MZO', { icon: 'faBuilding', label: 'Manzanillo (all airports), Cuba', key: 'C_MZO', type: 'city', code: 'manzanillo_cu' } ],
 ['C_ZLO', { icon: 'faBuilding', label: 'Manzanillo (all airports), Mexico', key: 'C_ZLO', type: 'city', code: 'manzanillo_mx' } ],
 ['C_NZH', { icon: 'faBuilding', label: 'Manzhouli (all airports), China', key: 'C_NZH', type: 'city', code: 'manzhouli_cn' } ],
 ['C_MTS', { icon: 'faBuilding', label: 'Manzini (all airports), Swaziland', key: 'C_MTS', type: 'city', code: 'manzini_sz' } ],
 ['C_MPM', { icon: 'faBuilding', label: 'Maputo (all airports), Mozambique', key: 'C_MPM', type: 'city', code: 'maputo_mz' } ],
 ['C_MDQ', { icon: 'faBuilding', label: 'Mar del Plata (all airports), Argentina', key: 'C_MDQ', type: 'city', code: 'mar-del-plata_ba_ar' } ],
 ['C_MAB', { icon: 'faBuilding', label: 'Marabá, Pará (all airports), Brazil', key: 'C_MAB', type: 'city', code: 'maraba_pa_br' } ],
 ['C_MAR', { icon: 'faBuilding', label: 'Maracaibo (all airports), Venezuela', key: 'C_MAR', type: 'city', code: 'maracaibo_ve' } ],
 ['C_RUS', { icon: 'faBuilding', label: 'Marau Sound (all airports), Solomon Islands', key: 'C_RUS', type: 'city', code: 'marau_sb' } ],
 ['C_MQM', { icon: 'faBuilding', label: 'Mardin (all airports), Turkey', key: 'C_MQM', type: 'city', code: 'mardin_tr' } ],
 ['C_MEE', { icon: 'faBuilding', label: 'Mare (all airports), New Caledonia', key: 'C_MEE', type: 'city', code: 'mare_nc' } ],
 ['C_MGH', { icon: 'faBuilding', label: 'Margate, KwaZulu-Natal (all airports), South Africa', key: 'C_MGH', type: 'city', code: 'margate_za' } ],
 ['C_MBX', { icon: 'faBuilding', label: 'Maribor (all airports), Slovenia', key: 'C_MBX', type: 'city', code: 'maribor_si' } ],
 ['C_MHQ', { icon: 'faBuilding', label: 'Mariehamn (all airports), Åland Islands', key: 'C_MHQ', type: 'city', code: 'mariehamn_ax' } ],
 ['C_MII', { icon: 'faBuilding', label: 'Marília (all airports), Brazil', key: 'C_MII', type: 'city', code: 'marilia_sp_br' } ],
 ['C_MGF', { icon: 'faBuilding', label: 'Maringá (all airports), Brazil', key: 'C_MGF', type: 'city', code: 'maringa_pr_br' } ],
 ['C_MWA', { icon: 'faBuilding', label: 'Marion (all airports), United States', key: 'C_MWA', type: 'city', code: 'marion_il_us' } ],
 ['C_WMN', { icon: 'faBuilding', label: 'Maroantsetra (all airports), Madagascar', key: 'C_WMN', type: 'city', code: 'maroantsetra_mg' } ],
 ['C_MVR', { icon: 'faBuilding', label: 'Maroua (all airports), Cameroon', key: 'C_MVR', type: 'city', code: 'maroua_cm' } ],
 ['C_MQT', { icon: 'faBuilding', label: 'Marquette (all airports), United States', key: 'C_MQT', type: 'city', code: 'marquette_mi_us' } ],
 ['C_RAK', { icon: 'faBuilding', label: 'Marrakesh (all airports), Morocco', key: 'C_RAK', type: 'city', code: 'marrakesh_ma' } ],
 ['C_RMF', { icon: 'faBuilding', label: 'Marsa Alam (all airports), Egypt', key: 'C_RMF', type: 'city', code: 'marsa-alam_eg' } ],
 ['C_MRS', { icon: 'faBuilding', label: 'Marseille (all airports), France', key: 'C_MRS', type: 'city', code: 'marseille_fr' } ],
 ['C_MHH', { icon: 'faBuilding', label: 'Marsh Harbour (all airports), Bahamas', key: 'C_MHH', type: 'city', code: 'marsh-harbour_bs' } ],
 ['C_MLL', { icon: 'faBuilding', label: 'Marshall (all airports), United States', key: 'C_MLL', type: 'city', code: 'marshall_ak_us' } ],
 ['C_MVY', { icon: 'faBuilding', label: 'Martha\'s Vineyard (all airports), United States', key: 'C_MVY', type: 'city', code: 'marthas-vineyard_ma_us' } ],
 ['C_MUR', { icon: 'faBuilding', label: 'Marudi (all airports), Malaysia', key: 'C_MUR', type: 'city', code: 'marudi_my' } ],
 ['C_MYP', { icon: 'faBuilding', label: 'Mary (all airports), Turkmenistan', key: 'C_MYP', type: 'city', code: 'mary-region_tm' } ],
 ['C_MBT', { icon: 'faBuilding', label: 'Masbate City (all airports), Philippines', key: 'C_MBT', type: 'city', code: 'masbate-city_ph' } ],
 ['C_MSU', { icon: 'faBuilding', label: 'Maseru (all airports), Lesotho', key: 'C_MSU', type: 'city', code: 'maseru_ls' } ],
 ['C_MHD', { icon: 'faBuilding', label: 'Mashhad (all airports), Iran', key: 'C_MHD', type: 'city', code: 'mashhad_ir' } ],
 ['C_MCW', { icon: 'faBuilding', label: 'Mason City (all airports), United States', key: 'C_MCW', type: 'city', code: 'mason-city_ia_us' } ],
 ['C_MSS', { icon: 'faBuilding', label: 'Massena (all airports), United States', key: 'C_MSS', type: 'city', code: 'massena_ny_us' } ],
 ['C_ZMT', { icon: 'faBuilding', label: 'Masset (all airports), Canada', key: 'C_ZMT', type: 'city', code: 'masset_bc_ca' } ],
 ['C_IWJ', { icon: 'faBuilding', label: 'Masuda (all airports), Japan', key: 'C_IWJ', type: 'city', code: 'masuda_jp' } ],
 ['C_MVT', { icon: 'faBuilding', label: 'Mataiva (all airports), French Polynesia', key: 'C_MVT', type: 'city', code: 'mataiva_pf' } ],
 ['C_MWK', { icon: 'faBuilding', label: 'Matak (all airports), Indonesia', key: 'C_MWK', type: 'city', code: 'matak_id' } ],
 ['C_MAM', { icon: 'faBuilding', label: 'Matamoros (all airports), Mexico', key: 'C_MAM', type: 'city', code: 'matamoros_mx' } ],
 ['C_MFK', { icon: 'faBuilding', label: 'Matsu Islands (all airports), Taiwan', key: 'C_MFK', type: 'city', code: 'matsu-islands_tw' } ],
 ['C_MMJ', { icon: 'faBuilding', label: 'Matsumoto (all airports), Japan', key: 'C_MMJ', type: 'city', code: 'matsumoto_jp' } ],
 ['C_MYJ', { icon: 'faBuilding', label: 'Matsuyama (all airports), Japan', key: 'C_MYJ', type: 'city', code: 'matsuyama_jp' } ],
 ['C_MUK', { icon: 'faBuilding', label: 'Mauke Island (all airports), Cook Islands', key: 'C_MUK', type: 'city', code: 'mauke-island_ck' } ],
 ['C_MOF', { icon: 'faBuilding', label: 'Maumere (all airports), Indonesia', key: 'C_MOF', type: 'city', code: 'maumere_id' } ],
 ['C_MUB', { icon: 'faBuilding', label: 'Maun (all airports), Botswana', key: 'C_MUB', type: 'city', code: 'maun_bw' } ],
 ['C_MAU', { icon: 'faBuilding', label: 'Maupiti (all airports), French Polynesia', key: 'C_MAU', type: 'city', code: 'maupiti_pf' } ],
 ['C_MRU', { icon: 'faBuilding', label: 'Mauritius Island (all airports), Mauritius', key: 'C_MRU', type: 'city', code: 'mauritius-island_mu' } ],
 ['C_MNU', { icon: 'faBuilding', label: 'Mawlamyine (all airports), Myanmar (Burma)', key: 'C_MNU', type: 'city', code: 'mawlamyine_mm' } ],
 ['C_MYG', { icon: 'faBuilding', label: 'Mayaguana (all airports), Bahamas', key: 'C_MYG', type: 'city', code: 'mayaguana_bs' } ],
 ['C_MAZ', { icon: 'faBuilding', label: 'Mayagüez (all airports), United States', key: 'C_MAZ', type: 'city', code: 'mayaguez_pr_pr' } ],
 ['C_MZR', { icon: 'faBuilding', label: 'Mazar-i-Sharif (all airports), Afghanistan', key: 'C_MZR', type: 'city', code: 'mazar-i-sharif_af' } ],
 ['C_MZT', { icon: 'faBuilding', label: 'Mazatlán (all airports), Mexico', key: 'C_MZT', type: 'city', code: 'mazatlan_mx' } ],
 ['C_MDK', { icon: 'faBuilding', label: 'Mbandaka (all airports), Democratic Republic of the Congo', key: 'C_MDK', type: 'city', code: 'mbandaka_cd' } ],
 ['C_MBI', { icon: 'faBuilding', label: 'Mbeya (all airports), Tanzania', key: 'C_MBI', type: 'city', code: 'mbeya_tz' } ],
 ['C_MJM', { icon: 'faBuilding', label: 'Mbuji-Mayi (all airports), Democratic Republic of the Congo', key: 'C_MJM', type: 'city', code: 'mbuji-mayi_cd' } ],
 ['C_MFE', { icon: 'faBuilding', label: 'McAllen (all airports), United States', key: 'C_MFE', type: 'city', code: 'mcallen_tx_us' } ],
 ['C_MCV', { icon: 'faBuilding', label: 'McArthur River zinc mine (all airports), Australia', key: 'C_MCV', type: 'city', code: 'mcarthur-river_nt_au' } ],
 ['C_MCK', { icon: 'faBuilding', label: 'McCook (all airports), United States', key: 'C_MCK', type: 'city', code: 'mccook_ne_us' } ],
 ['C_MCG', { icon: 'faBuilding', label: 'McGrath (all airports), United States', key: 'C_MCG', type: 'city', code: 'mcgrath_ak_us' } ],
 ['C_MES', { icon: 'faBuilding', label: 'Medan (all airports), Indonesia', key: 'C_MES', type: 'city', code: 'medan_id' } ],
 ['C_MDE', { icon: 'faBuilding', label: 'Medellín (all airports), Colombia', key: 'C_MDE', type: 'city', code: 'medellin_co' } ],
 ['C_MFR', { icon: 'faBuilding', label: 'Medford (all airports), United States', key: 'C_MFR', type: 'city', code: 'medford_or_us' } ],
 ['C_YXH', { icon: 'faBuilding', label: 'Medicine Hat (all airports), Canada', key: 'C_YXH', type: 'city', code: 'medicine-hat_ab_ca' } ],
 ['C_MED', { icon: 'faBuilding', label: 'Medina (all airports), Saudi Arabia', key: 'C_MED', type: 'city', code: 'medina_sa' } ],
 ['C_MEH', { icon: 'faBuilding', label: 'Mehamn (all airports), Norway', key: 'C_MEH', type: 'city', code: 'mehamn_no' } ],
 ['C_MXZ', { icon: 'faBuilding', label: 'Meizhou (all airports), China', key: 'C_MXZ', type: 'city', code: 'meizhou_cn' } ],
 ['C_MQX', { icon: 'faBuilding', label: 'Mek ele (all airports), Ethiopia', key: 'C_MQX', type: 'city', code: 'mekele_et' } ],
 ['C_MYU', { icon: 'faBuilding', label: 'Mekoryuk (all airports), United States', key: 'C_MYU', type: 'city', code: 'mekoryuk_ak_us' } ],
 ['C_MLB', { icon: 'faBuilding', label: 'Melbourne (all airports), United States', key: 'C_MLB', type: 'city', code: 'melbourne_fl_us' } ],
 ['C_MEL', { icon: 'faBuilding', label: 'Melbourne (all airports), Australia', key: 'C_MEL', type: 'city', code: 'melbourne_vi_au' } ],
 ['C_MLN', { icon: 'faBuilding', label: 'Melilla (all airports), Spain', key: 'C_MLN', type: 'city', code: 'melilla_es' } ],
 ['C_MNA', { icon: 'faBuilding', label: 'Melonguane (all airports), Indonesia', key: 'C_MNA', type: 'city', code: 'melangguane_id' } ],
 ['C_MMB', { icon: 'faBuilding', label: 'Memanbetsu (all airports), Japan', key: 'C_MMB', type: 'city', code: 'memanbetsu_jp' } ],
 ['C_FMM', { icon: 'faBuilding', label: 'Memmingen (all airports), Germany', key: 'C_FMM', type: 'city', code: 'memmingen_de' } ],
 ['C_MEM', { icon: 'faBuilding', label: 'Memphis (all airports), United States', key: 'C_MEM', type: 'city', code: 'memphis_tn_us' } ],
 ['C_MDU', { icon: 'faBuilding', label: 'Mendi (all airports), Papua New Guinea', key: 'C_MDU', type: 'city', code: 'mendi_pg' } ],
 ['C_MDZ', { icon: 'faBuilding', label: 'Mendoza (all airports), Argentina', key: 'C_MDZ', type: 'city', code: 'mendoza_md_ar' } ],
 ['C_SPP', { icon: 'faBuilding', label: 'Menongue (all airports), Angola', key: 'C_SPP', type: 'city', code: 'menongue_ao' } ],
 ['C_MAH', { icon: 'faBuilding', label: 'Menorca (all airports), Spain', key: 'C_MAH', type: 'city', code: 'minorca_es' } ],
 ['C_ZMR', { icon: 'faBuilding', label: 'Merano/Meran (all airports), Italy', key: 'C_ZMR', type: 'city', code: 'merano-meran_it' } ],
 ['C_MKQ', { icon: 'faBuilding', label: 'Merauke (all airports), Indonesia', key: 'C_MKQ', type: 'city', code: 'merauke_id' } ],
 ['C_MID', { icon: 'faBuilding', label: 'Mérida (all airports), Mexico', key: 'C_MID', type: 'city', code: 'merida_mx' } ],
 ['C_MEI', { icon: 'faBuilding', label: 'Meridian (all airports), United States', key: 'C_MEI', type: 'city', code: 'meridian_ms_us' } ],
 ['C_MIM', { icon: 'faBuilding', label: 'Merimbula (all airports), Australia', key: 'C_MIM', type: 'city', code: 'merimbula_ns_au' } ],
 ['C_MUH', { icon: 'faBuilding', label: 'Mersa Matruh (all airports), Egypt', key: 'C_MUH', type: 'city', code: 'mersa-matruh_eg' } ],
 ['C_MSC', { icon: 'faBuilding', label: 'Mesa (all airports), United States', key: 'C_MSC', type: 'city', code: 'mesa_az_us' } ],
 ['C_MTM', { icon: 'faBuilding', label: 'Metlakatla (all airports), United States', key: 'C_MTM', type: 'city', code: 'metlakatla_ak_us' } ],
 ['C_MEQ', { icon: 'faBuilding', label: 'Meulaboh (all airports), Indonesia', key: 'C_MEQ', type: 'city', code: 'meulaboh_id' } ],
 ['C_MXL', { icon: 'faBuilding', label: 'Mexicali (all airports), Mexico', key: 'C_MXL', type: 'city', code: 'mexicali_mx' } ],
 ['C_MEX', { icon: 'faBuilding', label: 'Mexico City (all airports), Mexico', key: 'C_MEX', type: 'city', code: 'mexico-city_mx' } ],
 ['C_WMK', { icon: 'faBuilding', label: 'Meyers Chuck (all airports), United States', key: 'C_WMK', type: 'city', code: 'meyers-chuck_ak_us' } ],
 ['C_MIA', { icon: 'faBuilding', label: 'Miami (all airports), United States', key: 'C_MIA', type: 'city', code: 'miami_fl_us' } ],
 ['C_MIG', { icon: 'faBuilding', label: 'Mianyang (all airports), China', key: 'C_MIG', type: 'city', code: 'mianyang_cn' } ],
 ['C_MMM', { icon: 'faBuilding', label: 'Middlemount (all airports), Australia', key: 'C_MMM', type: 'city', code: 'middlemount_ql_au' } ],
 ['C_MAF', { icon: 'faBuilding', label: 'Midland (all airports), United States', key: 'C_MAF', type: 'city', code: 'midland_tx_us' } ],
 ['C_ZMC', { icon: 'faBuilding', label: 'Miedzyzdroje (all airports), Poland', key: 'C_ZMC', type: 'city', code: 'miedzyzdroje_pl' } ],
 ['C_MIL', { icon: 'faBuilding', label: 'Milan (all airports), Italy', key: 'C_MIL', type: 'city', code: 'milan_it' } ],
 ['C_MQL', { icon: 'faBuilding', label: 'Mildura (all airports), Australia', key: 'C_MQL', type: 'city', code: 'mildura_vi_au' } ],
 ['C_MGT', { icon: 'faBuilding', label: 'Milingimbi Island (all airports), Australia', key: 'C_MGT', type: 'city', code: 'milingimbi-island_nt_au' } ],
 ['C_KYN', { icon: 'faBuilding', label: 'Milton Keynes (all airports), United Kingdom', key: 'C_KYN', type: 'city', code: 'milton-keynes_gb' } ],
 ['C_MKE', { icon: 'faBuilding', label: 'Milwaukee (all airports), United States', key: 'C_MKE', type: 'city', code: 'milwaukee_wi_us' } ],
 ['C_MTT', { icon: 'faBuilding', label: 'Minatitlán (all airports), Mexico', key: 'C_MTT', type: 'city', code: 'minatitlan_mx' } ],
 ['C_MRV', { icon: 'faBuilding', label: 'Mineralnye Vody (all airports), Russia', key: 'C_MRV', type: 'city', code: 'mineralnye-vody_ru' } ],
 ['C_MSP', { icon: 'faBuilding', label: 'Minneapolis (all airports), United States', key: 'C_MSP', type: 'city', code: 'minneapolis_mn_us' } ],
 ['C_MOT', { icon: 'faBuilding', label: 'Minot (all airports), United States', key: 'C_MOT', type: 'city', code: 'minot_nd_us' } ],
 ['C_MSQ', { icon: 'faBuilding', label: 'Minsk (all airports), Belarus', key: 'C_MSQ', type: 'city', code: 'minsk_by' } ],
 ['C_MNT', { icon: 'faBuilding', label: 'Minto (all airports), United States', key: 'C_MNT', type: 'city', code: 'minto_ak_us' } ],
 ['C_MYY', { icon: 'faBuilding', label: 'Miri (all airports), Malaysia', key: 'C_MYY', type: 'city', code: 'miri_my' } ],
 ['C_MJZ', { icon: 'faBuilding', label: 'Mirny (all airports), Russia', key: 'C_MJZ', type: 'city', code: 'mirny_ru' } ],
 ['C_MSJ', { icon: 'faBuilding', label: 'Misawa (all airports), Japan', key: 'C_MSJ', type: 'city', code: 'misawa_jp' } ],
 ['C_MIS', { icon: 'faBuilding', label: 'Misima Island (all airports), Papua New Guinea', key: 'C_MIS', type: 'city', code: 'misima-island_pg' } ],
 ['C_MSO', { icon: 'faBuilding', label: 'Missoula (all airports), United States', key: 'C_MSO', type: 'city', code: 'missoula_mt_us' } ],
 ['C_MRA', { icon: 'faBuilding', label: 'Misurata (all airports), Libya', key: 'C_MRA', type: 'city', code: 'misurata_ly' } ],
 ['C_MOI', { icon: 'faBuilding', label: 'Mitiaro Island (all airports), Cook Islands', key: 'C_MOI', type: 'city', code: 'mitiaro-island_ck' } ],
 ['C_MVP', { icon: 'faBuilding', label: 'Mitú (all airports), Colombia', key: 'C_MVP', type: 'city', code: 'mitu_co' } ],
 ['C_MMY', { icon: 'faBuilding', label: 'Miyakojima (all airports), Japan', key: 'C_MMY', type: 'city', code: 'miyako-district_jp' } ],
 ['C_KMI', { icon: 'faBuilding', label: 'Miyazaki (all airports), Japan', key: 'C_KMI', type: 'city', code: 'miyazaki_jp' } ],
 ['C_MBD', { icon: 'faBuilding', label: 'Mmabatho (all airports), South Africa', key: 'C_MBD', type: 'city', code: 'mmabatho_za' } ],
 ['C_MQN', { icon: 'faBuilding', label: 'Mo i Rana (all airports), Norway', key: 'C_MQN', type: 'city', code: 'mo-i-rana_no' } ],
 ['C_MOZ', { icon: 'faBuilding', label: 'Mo orea (all airports), French Polynesia', key: 'C_MOZ', type: 'city', code: 'moorea_pf' } ],
 ['C_MOA', { icon: 'faBuilding', label: 'Moa (all airports), Cuba', key: 'C_MOA', type: 'city', code: 'moa_cu' } ],
 ['C_CNY', { icon: 'faBuilding', label: 'Moab (all airports), United States', key: 'C_CNY', type: 'city', code: 'moab_ut_us' } ],
 ['C_MOB', { icon: 'faBuilding', label: 'Mobile (all airports), United States', key: 'C_MOB', type: 'city', code: 'mobile_al_us' } ],
 ['C_ZMO', { icon: 'faBuilding', label: 'Modena (all airports), Italy', key: 'C_ZMO', type: 'city', code: 'modena_it' } ],
 ['C_MGQ', { icon: 'faBuilding', label: 'Mogadishu (all airports), Somalia', key: 'C_MGQ', type: 'city', code: 'mogadishu_so' } ],
 ['C_OHE', { icon: 'faBuilding', label: 'Mohe County (all airports), China', key: 'C_OHE', type: 'city', code: 'mohe-county_cn' } ],
 ['C_MJD', { icon: 'faBuilding', label: 'Mohenjodaro (all airports), Pakistan', key: 'C_MJD', type: 'city', code: 'mohenjodaro_pk' } ],
 ['C_MOL', { icon: 'faBuilding', label: 'Molde (all airports), Norway', key: 'C_MOL', type: 'city', code: 'molde_no' } ],
 ['C_MLI', { icon: 'faBuilding', label: 'Moline (all airports), United States', key: 'C_MLI', type: 'city', code: 'moline_il_us' } ],
 ['C_MBA', { icon: 'faBuilding', label: 'Mombasa (all airports), Kenya', key: 'C_MBA', type: 'city', code: 'mombasa_ke' } ],
 ['C_MIR', { icon: 'faBuilding', label: 'Monastir (all airports), Tunisia', key: 'C_MIR', type: 'city', code: 'monastir_tn' } ],
 ['C_MBE', { icon: 'faBuilding', label: 'Monbetsu (all airports), Japan', key: 'C_MBE', type: 'city', code: 'monbetsu_jp' } ],
 ['C_LOV', { icon: 'faBuilding', label: 'Monclova (all airports), Mexico', key: 'C_LOV', type: 'city', code: 'monclova_mx' } ],
 ['C_YQM', { icon: 'faBuilding', label: 'Moncton (all airports), Canada', key: 'C_YQM', type: 'city', code: 'moncton_nb_ca' } ],
 ['C_MOG', { icon: 'faBuilding', label: 'Mong Hsat Township (all airports), Myanmar (Burma)', key: 'C_MOG', type: 'city', code: 'mong-hsat-township_mm' } ],
 ['C_GEM', { icon: 'faBuilding', label: 'Mongomo (all airports), Equatorial Guinea', key: 'C_GEM', type: 'city', code: 'mongomo_gq' } ],
 ['C_MJK', { icon: 'faBuilding', label: 'Monkey Mia (all airports), Australia', key: 'C_MJK', type: 'city', code: 'monkey-mia_wa_au' } ],
 ['C_MLU', { icon: 'faBuilding', label: 'Monroe (all airports), United States', key: 'C_MLU', type: 'city', code: 'monroe_la_us' } ],
 ['C_MLW', { icon: 'faBuilding', label: 'Monrovia (all airports), Liberia', key: 'C_MLW', type: 'city', code: 'monrovia_lr' } ],
 ['C_YYY', { icon: 'faBuilding', label: 'Mont-Joli (all airports), Canada', key: 'C_YYY', type: 'city', code: 'mont-joli_qc_ca' } ],
 ['C_YTM', { icon: 'faBuilding', label: 'Mont-Tremblant (all airports), Canada', key: 'C_YTM', type: 'city', code: 'mont-tremblant_qc_ca' } ],
 ['C_MCM', { icon: 'faBuilding', label: 'Monte Carlo (all airports), Monaco', key: 'C_MCM', type: 'city', code: 'monte-carlo_mc' } ],
 ['C_MBJ', { icon: 'faBuilding', label: 'Montego Bay (all airports), Jamaica', key: 'C_MBJ', type: 'city', code: 'montego-bay_jm' } ],
 ['C_MRY', { icon: 'faBuilding', label: 'Monterey (all airports), United States', key: 'C_MRY', type: 'city', code: 'monterey_ca_us' } ],
 ['C_MTR', { icon: 'faBuilding', label: 'Montería (all airports), Colombia', key: 'C_MTR', type: 'city', code: 'monteria_co' } ],
 ['C_MTY', { icon: 'faBuilding', label: 'Monterrey (all airports), Mexico', key: 'C_MTY', type: 'city', code: 'monterrey_mx' } ],
 ['C_MOC', { icon: 'faBuilding', label: 'Montes Claros (all airports), Brazil', key: 'C_MOC', type: 'city', code: 'montes-claros_mg_br' } ],
 ['C_MVD', { icon: 'faBuilding', label: 'Montevideo (all airports), Uruguay', key: 'C_MVD', type: 'city', code: 'montevideo_uy' } ],
 ['C_MGM', { icon: 'faBuilding', label: 'Montgomery (all airports), United States', key: 'C_MGM', type: 'city', code: 'montgomery_al_us' } ],
 ['C_MPL', { icon: 'faBuilding', label: 'Montpellier (all airports), France', key: 'C_MPL', type: 'city', code: 'montpellier_fr' } ],
 ['C_YMQ', { icon: 'faBuilding', label: 'Montreal (all airports), Canada', key: 'C_YMQ', type: 'city', code: 'montreal_qc_ca' } ],
 ['C_MTJ', { icon: 'faBuilding', label: 'Montrose (all airports), United States', key: 'C_MTJ', type: 'city', code: 'montrose_co_us' } ],
 ['C_NYW', { icon: 'faBuilding', label: 'Monywa (all airports), Myanmar (Burma)', key: 'C_NYW', type: 'city', code: 'monywa_mm' } ],
 ['C_YMO', { icon: 'faBuilding', label: 'Moosonee (all airports), Canada', key: 'C_YMO', type: 'city', code: 'moosonee_on_ca' } ],
 ['C_MXX', { icon: 'faBuilding', label: 'Mora (all airports), Sweden', key: 'C_MXX', type: 'city', code: 'mora_se' } ],
 ['C_MOV', { icon: 'faBuilding', label: 'Moranbah (all airports), Australia', key: 'C_MOV', type: 'city', code: 'moranbah_ql_au' } ],
 ['C_MRZ', { icon: 'faBuilding', label: 'Moree (all airports), Australia', key: 'C_MRZ', type: 'city', code: 'moree_ns_au' } ],
 ['C_MLM', { icon: 'faBuilding', label: 'Morelia (all airports), Mexico', key: 'C_MLM', type: 'city', code: 'morelia_mx' } ],
 ['C_MGW', { icon: 'faBuilding', label: 'Morgantown (all airports), United States', key: 'C_MGW', type: 'city', code: 'morgantown_wv_us' } ],
 ['C_ONG', { icon: 'faBuilding', label: 'Mornington Island (all airports), Australia', key: 'C_ONG', type: 'city', code: 'mornington-island_ql_au' } ],
 ['C_MXV', { icon: 'faBuilding', label: 'Moron (all airports), Mongolia', key: 'C_MXV', type: 'city', code: 'moron_mn' } ],
 ['C_MOQ', { icon: 'faBuilding', label: 'Morondava (all airports), Madagascar', key: 'C_MOQ', type: 'city', code: 'morondava_mg' } ],
 ['C_YVA', { icon: 'faBuilding', label: 'Moroni (all airports), Comoros', key: 'C_YVA', type: 'city', code: 'moroni_km' } ],
 ['C_OTI', { icon: 'faBuilding', label: 'Morotai (all airports), Indonesia', key: 'C_OTI', type: 'city', code: 'morotai_id' } ],
 ['C_MMU', { icon: 'faBuilding', label: 'Morristown (all airports), United States', key: 'C_MMU', type: 'city', code: 'morristown_nj_us' } ],
 ['C_MYA', { icon: 'faBuilding', label: 'Moruya (all airports), Australia', key: 'C_MYA', type: 'city', code: 'moruya_ns_au' } ],
 ['C_MOW', { icon: 'faBuilding', label: 'Moscow (all airports), Russia', key: 'C_MOW', type: 'city', code: 'moscow_ru' } ],
 ['C_MJF', { icon: 'faBuilding', label: 'Mosjøen (all airports), Norway', key: 'C_MJF', type: 'city', code: 'mosjoen_no' } ],
 ['C_OMO', { icon: 'faBuilding', label: 'Mostar (all airports), Bosnia & Herzegovina', key: 'C_OMO', type: 'city', code: 'mostar_ba' } ],
 ['C_MTV', { icon: 'faBuilding', label: 'Mota Lava (all airports), Vanuatu', key: 'C_MTV', type: 'city', code: 'mota-lava_vu' } ],
 ['C_MGB', { icon: 'faBuilding', label: 'Mount Gambier (all airports), Australia', key: 'C_MGB', type: 'city', code: 'mount-gambier_sa_au' } ],
 ['C_HGU', { icon: 'faBuilding', label: 'Mount Hagen (all airports), Papua New Guinea', key: 'C_HGU', type: 'city', code: 'mount-hagen_pg' } ],
 ['C_ISA', { icon: 'faBuilding', label: 'Mount Isa (all airports), Australia', key: 'C_ISA', type: 'city', code: 'mount-isa_ql_au' } ],
 ['C_JRO', { icon: 'faBuilding', label: 'Mount Kilimanjaro (all airports), Tanzania', key: 'C_JRO', type: 'city', code: 'mount-kilimanjaro_tz' } ],
 ['C_MPN', { icon: 'faBuilding', label: 'Mount Pleasant (all airports), Falkland Islands (Islas Malvinas)', key: 'C_MPN', type: 'city', code: 'mount-pleasant_fk' } ],
 ['C_MOU', { icon: 'faBuilding', label: 'Mountain Village (all airports), United States', key: 'C_MOU', type: 'city', code: 'mountain-village_ak_us' } ],
 ['C_UTT', { icon: 'faBuilding', label: 'Mthatha (all airports), South Africa', key: 'C_UTT', type: 'city', code: 'mthatha_za' } ],
 ['C_MYW', { icon: 'faBuilding', label: 'Mtwara (all airports), Tanzania', key: 'C_MYW', type: 'city', code: 'mtwara_tz' } ],
 ['C_MWX', { icon: 'faBuilding', label: 'Muan County (all airports), South Korea', key: 'C_MWX', type: 'city', code: 'muan-county_kr' } ],
 ['C_ODY', { icon: 'faBuilding', label: 'Muang Xay (all airports), Laos', key: 'C_ODY', type: 'city', code: 'muang-xay_la' } ],
 ['C_MDG', { icon: 'faBuilding', label: 'Mudanjiang (all airports), China', key: 'C_MDG', type: 'city', code: 'mudanjiang_cn' } ],
 ['C_DGE', { icon: 'faBuilding', label: 'Mudgee (all airports), Australia', key: 'C_DGE', type: 'city', code: 'mudgee_ns_au' } ],
 ['C_MKM', { icon: 'faBuilding', label: 'Mukah (all airports), Malaysia', key: 'C_MKM', type: 'city', code: 'mukah_my' } ],
 ['C_MPP', { icon: 'faBuilding', label: 'Mulatupo (all airports), Panama', key: 'C_MPP', type: 'city', code: 'mulatupo_pa' } ],
 ['C_MUX', { icon: 'faBuilding', label: 'Multan (all airports), Pakistan', key: 'C_MUX', type: 'city', code: 'multan_pk' } ],
 ['C_BOM', { icon: 'faBuilding', label: 'Mumbai (all airports), India', key: 'C_BOM', type: 'city', code: 'mumbai_in' } ],
 ['C_MUA', { icon: 'faBuilding', label: 'Munda (all airports), Solomon Islands', key: 'C_MUA', type: 'city', code: 'munda_sb' } ],
 ['C_MUC', { icon: 'faBuilding', label: 'Munich (all airports), Germany', key: 'C_MUC', type: 'city', code: 'munich_de' } ],
 ['C_FMO', { icon: 'faBuilding', label: 'Münster (all airports), Germany', key: 'C_FMO', type: 'city', code: 'munster_de' } ],
 ['C_MJV', { icon: 'faBuilding', label: 'Murcia (all airports), Spain', key: 'C_MJV', type: 'city', code: 'murcia_es' } ],
 ['C_MMK', { icon: 'faBuilding', label: 'Murmansk (all airports), Russia', key: 'C_MMK', type: 'city', code: 'murmansk_ru' } ],
 ['C_MSR', { icon: 'faBuilding', label: 'Muş (all airports), Turkey', key: 'C_MSR', type: 'city', code: 'mus_tr' } ],
 ['C_MCT', { icon: 'faBuilding', label: 'Muscat (all airports), Oman', key: 'C_MCT', type: 'city', code: 'muscat_om' } ],
 ['C_MSL', { icon: 'faBuilding', label: 'Muscle Shoals (all airports), United States', key: 'C_MSL', type: 'city', code: 'muscle-shoals_al_us' } ],
 ['C_MKG', { icon: 'faBuilding', label: 'Muskegon (all airports), United States', key: 'C_MKG', type: 'city', code: 'muskegon_mi_us' } ],
 ['C_MUZ', { icon: 'faBuilding', label: 'Musoma (all airports), Tanzania', key: 'C_MUZ', type: 'city', code: 'musoma_tz' } ],
 ['C_MWZ', { icon: 'faBuilding', label: 'Mwanza (all airports), Tanzania', key: 'C_MWZ', type: 'city', code: 'mwanza_tz' } ],
 ['C_MGZ', { icon: 'faBuilding', label: 'Myeik, Myanmar (all airports), Myanmar (Burma)', key: 'C_MGZ', type: 'city', code: 'myeik_mm' } ],
 ['C_MYT', { icon: 'faBuilding', label: 'Myitkyina (all airports), Myanmar (Burma)', key: 'C_MYT', type: 'city', code: 'myitkyina_mm' } ],
 ['C_JMK', { icon: 'faBuilding', label: 'Mykonos (all airports), Greece', key: 'C_JMK', type: 'city', code: 'mykonos_gr' } ],
 ['C_MYR', { icon: 'faBuilding', label: 'Myrtle Beach (all airports), United States', key: 'C_MYR', type: 'city', code: 'myrtle-beach_sc_us' } ],
 ['C_MYQ', { icon: 'faBuilding', label: 'Mysore (all airports), India', key: 'C_MYQ', type: 'city', code: 'mysore_in' } ],
 ['C_MJT', { icon: 'faBuilding', label: 'Mytilene (all airports), Greece', key: 'C_MJT', type: 'city', code: 'mytilene_gr' } ],
 ['C_NDJ', { icon: 'faBuilding', label: 'N Djamena (all airports), Chad', key: 'C_NDJ', type: 'city', code: 'ndjamena_td' } ],
 ['C_NBX', { icon: 'faBuilding', label: 'Nabire Regency (all airports), Indonesia', key: 'C_NBX', type: 'city', code: 'nabire-regency_id' } ],
 ['C_MNC', { icon: 'faBuilding', label: 'Nacala (all airports), Mozambique', key: 'C_MNC', type: 'city', code: 'nacala_mz' } ],
 ['C_NAN', { icon: 'faBuilding', label: 'Nadi (all airports), Fiji', key: 'C_NAN', type: 'city', code: 'nadi_fj' } ],
 ['C_NDR', { icon: 'faBuilding', label: 'Nador (all airports), Morocco', key: 'C_NDR', type: 'city', code: 'nador_ma' } ],
 ['C_NYM', { icon: 'faBuilding', label: 'Nadym (all airports), Russia', key: 'C_NYM', type: 'city', code: 'nadym_ru' } ],
 ['C_WNP', { icon: 'faBuilding', label: 'Naga, Camarines Sur (all airports), Philippines', key: 'C_WNP', type: 'city', code: 'naga_ph' } ],
 ['C_NGS', { icon: 'faBuilding', label: 'Nagasaki (all airports), Japan', key: 'C_NGS', type: 'city', code: 'nagasaki_jp' } ],
 ['C_NGO', { icon: 'faBuilding', label: 'Nagoya (all airports), Japan', key: 'C_NGO', type: 'city', code: 'nagoya_jp' } ],
 ['C_NAG', { icon: 'faBuilding', label: 'Nagpur (all airports), India', key: 'C_NAG', type: 'city', code: 'nagpur_in' } ],
 ['C_NAH', { icon: 'faBuilding', label: 'Naha (all airports), Indonesia', key: 'C_NAH', type: 'city', code: 'naha_id' } ],
 ['C_YDP', { icon: 'faBuilding', label: 'Nain (all airports), Canada', key: 'C_YDP', type: 'city', code: 'nain_nl_ca' } ],
 ['C_NBO', { icon: 'faBuilding', label: 'Nairobi (all airports), Kenya', key: 'C_NBO', type: 'city', code: 'nairobi_ke' } ],
 ['C_NJF', { icon: 'faBuilding', label: 'Najaf (all airports), Iraq', key: 'C_NJF', type: 'city', code: 'najaf_iq' } ],
 ['C_SHB', { icon: 'faBuilding', label: 'Nakashibetsu (all airports), Japan', key: 'C_SHB', type: 'city', code: 'nakashibetsu_jp' } ],
 ['C_NAJ', { icon: 'faBuilding', label: 'Nakhchivan (all airports), Azerbaijan', key: 'C_NAJ', type: 'city', code: 'nakhchivan_az' } ],
 ['C_KOP', { icon: 'faBuilding', label: 'Nakhon Phanom Province (all airports), Thailand', key: 'C_KOP', type: 'city', code: 'nakhon-phanom-province_th' } ],
 ['C_NST', { icon: 'faBuilding', label: 'Nakhon Si Thammarat Province (all airports), Thailand', key: 'C_NST', type: 'city', code: 'nakhon-si-thammarat-province_th' } ],
 ['C_NAL', { icon: 'faBuilding', label: 'Nalchik (all airports), Russia', key: 'C_NAL', type: 'city', code: 'nalchik_ru' } ],
 ['C_NMA', { icon: 'faBuilding', label: 'Namangan (all airports), Uzbekistan', key: 'C_NMA', type: 'city', code: 'namangan_uz' } ],
 ['C_MSZ', { icon: 'faBuilding', label: 'Namibe (all airports), Angola', key: 'C_MSZ', type: 'city', code: 'namibe_ao' } ],
 ['C_APL', { icon: 'faBuilding', label: 'Nampula (all airports), Mozambique', key: 'C_APL', type: 'city', code: 'nampula_mz' } ],
 ['C_OSY', { icon: 'faBuilding', label: 'Namsos (all airports), Norway', key: 'C_OSY', type: 'city', code: 'namsos_no' } ],
 ['C_NNT', { icon: 'faBuilding', label: 'Nan Province (all airports), Thailand', key: 'C_NNT', type: 'city', code: 'nan-province_th' } ],
 ['C_YCD', { icon: 'faBuilding', label: 'Nanaimo (all airports), Canada', key: 'C_YCD', type: 'city', code: 'nanaimo_bc_ca' } ],
 ['C_KHN', { icon: 'faBuilding', label: 'Nanchang (all airports), China', key: 'C_KHN', type: 'city', code: 'nanchang_cn' } ],
 ['C_NAO', { icon: 'faBuilding', label: 'Nanchong (all airports), China', key: 'C_NAO', type: 'city', code: 'nanchong_cn' } ],
 ['C_ETZ', { icon: 'faBuilding', label: 'Nancy (all airports), France', key: 'C_ETZ', type: 'city', code: 'metz_fr' } ],
 ['C_NDC', { icon: 'faBuilding', label: 'Nanded (all airports), India', key: 'C_NDC', type: 'city', code: 'nanded_in' } ],
 ['C_LZN', { icon: 'faBuilding', label: 'Nangan, Lienchiang (all airports), Taiwan', key: 'C_LZN', type: 'city', code: 'nangan_tw' } ],
 ['C_NKG', { icon: 'faBuilding', label: 'Nanjing (all airports), China', key: 'C_NKG', type: 'city', code: 'nanjing_cn' } ],
 ['C_NNG', { icon: 'faBuilding', label: 'Nanning (all airports), China', key: 'C_NNG', type: 'city', code: 'nanning_cn' } ],
 ['C_NTE', { icon: 'faBuilding', label: 'Nantes (all airports), France', key: 'C_NTE', type: 'city', code: 'nantes_fr' } ],
 ['C_NTG', { icon: 'faBuilding', label: 'Nantong (all airports), China', key: 'C_NTG', type: 'city', code: 'nantong_cn' } ],
 ['C_ACK', { icon: 'faBuilding', label: 'Nantucket (all airports), United States', key: 'C_ACK', type: 'city', code: 'nantucket_ma_us' } ],
 ['C_KEB', { icon: 'faBuilding', label: 'Nanwalek (all airports), United States', key: 'C_KEB', type: 'city', code: 'nanwalek_ak_us' } ],
 ['C_NNY', { icon: 'faBuilding', label: 'Nanyang (all airports), China', key: 'C_NNY', type: 'city', code: 'nanyang_cn' } ],
 ['C_NYK', { icon: 'faBuilding', label: 'Nanyuki (all airports), Kenya', key: 'C_NYK', type: 'city', code: 'nanyuki_ke' } ],
 ['C_WNA', { icon: 'faBuilding', label: 'Napakiak (all airports), United States', key: 'C_WNA', type: 'city', code: 'napakiak_ak_us' } ],
 ['C_PKA', { icon: 'faBuilding', label: 'Napaskiak (all airports), United States', key: 'C_PKA', type: 'city', code: 'napaskiak_ak_us' } ],
 ['C_NPE', { icon: 'faBuilding', label: 'Napier (all airports), New Zealand', key: 'C_NPE', type: 'city', code: 'napier_nz' } ],
 ['C_APF', { icon: 'faBuilding', label: 'Naples (all airports), United States', key: 'C_APF', type: 'city', code: 'naples_fl_us' } ],
 ['C_NAP', { icon: 'faBuilding', label: 'Naples (all airports), Italy', key: 'C_NAP', type: 'city', code: 'naples_it' } ],
 ['C_NAU', { icon: 'faBuilding', label: 'Napuka (all airports), French Polynesia', key: 'C_NAU', type: 'city', code: 'napuka_pf' } ],
 ['C_NAW', { icon: 'faBuilding', label: 'Narathiwat Province (all airports), Thailand', key: 'C_NAW', type: 'city', code: 'narathiwat-province_th' } ],
 ['C_NRA', { icon: 'faBuilding', label: 'Narrandera (all airports), Australia', key: 'C_NRA', type: 'city', code: 'narrandera_ns_au' } ],
 ['C_UAK', { icon: 'faBuilding', label: 'Narsarsuaq (all airports), Greenland', key: 'C_UAK', type: 'city', code: 'narsarsuaq_gl' } ],
 ['C_EVE', { icon: 'faBuilding', label: 'Narvik (all airports), Norway', key: 'C_EVE', type: 'city', code: 'narvik_no' } ],
 ['C_NNM', { icon: 'faBuilding', label: 'Naryan-Mar (all airports), Russia', key: 'C_NNM', type: 'city', code: 'naryan-mar_ru' } ],
 ['C_null', { icon: 'faBuilding', label: 'Nashik (all airports), India', key: 'C_null', type: 'city', code: 'nashik_in' } ],
 ['C_BNA', { icon: 'faBuilding', label: 'Nashville (all airports), United States', key: 'C_BNA', type: 'city', code: 'nashville_tn_us' } ],
 ['C_NAS', { icon: 'faBuilding', label: 'Nassau (all airports), Bahamas', key: 'C_NAS', type: 'city', code: 'nassau_bs' } ],
 ['C_NAT', { icon: 'faBuilding', label: 'Natal (all airports), Brazil', key: 'C_NAT', type: 'city', code: 'natal_rn_br' } ],
 ['C_YNA', { icon: 'faBuilding', label: 'Natashquan (all airports), Canada', key: 'C_YNA', type: 'city', code: 'natashquan_qc_ca' } ],
 ['C_YNP', { icon: 'faBuilding', label: 'Natuashish (all airports), Canada', key: 'C_YNP', type: 'city', code: 'natuashish_nl_ca' } ],
 ['C_NTX', { icon: 'faBuilding', label: 'Natuna (all airports), Indonesia', key: 'C_NTX', type: 'city', code: 'natuna-regency_id' } ],
 ['C_YUT', { icon: 'faBuilding', label: 'Naujaat (all airports), Canada', key: 'C_YUT', type: 'city', code: 'naujaat_nu_ca' } ],
 ['C_NVT', { icon: 'faBuilding', label: 'Navegantes (all airports), Brazil', key: 'C_NVT', type: 'city', code: 'navegantes_sc_br' } ],
 ['C_NVI', { icon: 'faBuilding', label: 'Navoiy (all airports), Uzbekistan', key: 'C_NVI', type: 'city', code: 'navoiy_uz' } ],
 ['C_JNX', { icon: 'faBuilding', label: 'Naxos (all airports), Greece', key: 'C_JNX', type: 'city', code: 'naxos_gr' } ],
 ['C_NYT', { icon: 'faBuilding', label: 'Naypyidaw (all airports), Myanmar (Burma)', key: 'C_NYT', type: 'city', code: 'naypyidaw_mm' } ],
 ['C_IGT', { icon: 'faBuilding', label: 'Nazran (all airports), Russia', key: 'C_IGT', type: 'city', code: 'nazran_ru' } ],
 ['C_NLA', { icon: 'faBuilding', label: 'Ndola (all airports), Zambia', key: 'C_NLA', type: 'city', code: 'ndola_zm' } ],
 ['C_IRI', { icon: 'faBuilding', label: 'Nduli (all airports), Tanzania', key: 'C_IRI', type: 'city', code: 'nduli_tz' } ],
 ['C_NVA', { icon: 'faBuilding', label: 'Neiva, Huila (all airports), Colombia', key: 'C_NVA', type: 'city', code: 'neiva_co' } ],
 ['C_NSN', { icon: 'faBuilding', label: 'Nelson (all airports), New Zealand', key: 'C_NSN', type: 'city', code: 'nelson_nz' } ],
 ['C_NLG', { icon: 'faBuilding', label: 'Nelson Lagoon (all airports), United States', key: 'C_NLG', type: 'city', code: 'nelson-lagoon_ak_us' } ],
 ['C_NLP', { icon: 'faBuilding', label: 'Nelspruit (all airports), South Africa', key: 'C_NLP', type: 'city', code: 'nelspruit_za' } ],
 ['C_YNS', { icon: 'faBuilding', label: 'Nemaska (all airports), Canada', key: 'C_YNS', type: 'city', code: 'nemaska_qc_ca' } ],
 ['C_KEP', { icon: 'faBuilding', label: 'Nepalgunj (all airports), Nepal', key: 'C_KEP', type: 'city', code: 'nepalgunj_np' } ],
 ['C_NER', { icon: 'faBuilding', label: 'Neryungri (all airports), Russia', key: 'C_NER', type: 'city', code: 'neryungri_ru' } ],
 ['C_NQN', { icon: 'faBuilding', label: 'Neuquén (all airports), Argentina', key: 'C_NQN', type: 'city', code: 'neuquen_ne_ar' } ],
 ['C_NEV', { icon: 'faBuilding', label: 'Nevis (all airports), St. Kitts & Nevis', key: 'C_NEV', type: 'city', code: 'nevis_kn' } ],
 ['C_NAV', { icon: 'faBuilding', label: 'Nevşehir (all airports), Turkey', key: 'C_NAV', type: 'city', code: 'nevsehir_tr' } ],
 ['C_EWB', { icon: 'faBuilding', label: 'New Bedford (all airports), United States', key: 'C_EWB', type: 'city', code: 'new-bedford_ma_us' } ],
 ['C_EWN', { icon: 'faBuilding', label: 'New Bern (all airports), United States', key: 'C_EWN', type: 'city', code: 'new-bern_nc_us' } ],
 ['C_DEL', { icon: 'faBuilding', label: 'New Delhi (all airports), India', key: 'C_DEL', type: 'city', code: 'new-delhi_in' } ],
 ['C_HVN', { icon: 'faBuilding', label: 'New Haven (all airports), United States', key: 'C_HVN', type: 'city', code: 'new-haven_ct_us' } ],
 ['C_MSY', { icon: 'faBuilding', label: 'New Orleans (all airports), United States', key: 'C_MSY', type: 'city', code: 'new-orleans_la_us' } ],
 ['C_NPL', { icon: 'faBuilding', label: 'New Plymouth (all airports), New Zealand', key: 'C_NPL', type: 'city', code: 'new-plymouth_nz' } ],
 ['C_KNW', { icon: 'faBuilding', label: 'New Stuyahok (all airports), United States', key: 'C_KNW', type: 'city', code: 'new-stuyahok_ak_us' } ],
 ['C_NYC', { icon: 'faBuilding', label: 'New York City (all airports), United States', key: 'C_NYC', type: 'city', code: 'new-york-city_ny_us' } ],
 ['C_NCL', { icon: 'faBuilding', label: 'Newcastle upon Tyne (all airports), United Kingdom', key: 'C_NCL', type: 'city', code: 'newcastle-upon-tyne_gb' } ],
 ['C_ZNE', { icon: 'faBuilding', label: 'Newman (all airports), Australia', key: 'C_ZNE', type: 'city', code: 'newman_wa_au' } ],
 ['C_PHF', { icon: 'faBuilding', label: 'Newport News (all airports), United States', key: 'C_PHF', type: 'city', code: 'newport-news_va_us' } ],
 ['C_NQY', { icon: 'faBuilding', label: 'Newquay (all airports), United Kingdom', key: 'C_NQY', type: 'city', code: 'newquay_gb' } ],
 ['C_WWT', { icon: 'faBuilding', label: 'Newtok (all airports), United States', key: 'C_WWT', type: 'city', code: 'newtok_ak_us' } ],
 ['C_NGL', { icon: 'faBuilding', label: 'Ngala (all airports), South Africa', key: 'C_NGL', type: 'city', code: 'ngala_za' } ],
 ['C_NGE', { icon: 'faBuilding', label: 'Ngaoundéré (all airports), Cameroon', key: 'C_NGE', type: 'city', code: 'ngaoundere_cm' } ],
 ['C_NGQ', { icon: 'faBuilding', label: 'Ngari Prefecture (all airports), China', key: 'C_NGQ', type: 'city', code: 'ngari-prefecture_cn' } ],
 ['C_NHA', { icon: 'faBuilding', label: 'Nha Trang (all airports), Vietnam', key: 'C_NHA', type: 'city', code: 'nha-trang_vn' } ],
 ['C_GOV', { icon: 'faBuilding', label: 'Nhulunbuy (all airports), Australia', key: 'C_GOV', type: 'city', code: 'nhulunbuy_nt_au' } ],
 ['C_IAG', { icon: 'faBuilding', label: 'Niagara Falls (all airports), United States', key: 'C_IAG', type: 'city', code: 'niagara-falls_ny_us' } ],
 ['C_NIM', { icon: 'faBuilding', label: 'Niamey (all airports), Niger', key: 'C_NIM', type: 'city', code: 'niamey_ne' } ],
 ['C_NIU', { icon: 'faBuilding', label: 'Niau (all airports), French Polynesia', key: 'C_NIU', type: 'city', code: 'niau_pf' } ],
 ['C_NCE', { icon: 'faBuilding', label: 'Nice (all airports), France', key: 'C_NCE', type: 'city', code: 'nice_fr' } ],
 ['C_NME', { icon: 'faBuilding', label: 'Nightmute (all airports), United States', key: 'C_NME', type: 'city', code: 'nightmute_ak_us' } ],
 ['C_KIJ', { icon: 'faBuilding', label: 'Niigata (all airports), Japan', key: 'C_KIJ', type: 'city', code: 'niigata_jp' } ],
 ['C_IKO', { icon: 'faBuilding', label: 'Nikolski (all airports), United States', key: 'C_IKO', type: 'city', code: 'nikolski_ak_us' } ],
 ['C_FNI', { icon: 'faBuilding', label: 'Nîmes (all airports), France', key: 'C_FNI', type: 'city', code: 'nimes_fr' } ],
 ['C_NGB', { icon: 'faBuilding', label: 'Ningbo (all airports), China', key: 'C_NGB', type: 'city', code: 'ningbo_cn' } ],
 ['C_NLH', { icon: 'faBuilding', label: 'Ninglang (all airports), China', key: 'C_NLH', type: 'city', code: 'ninglang-yi-autonomous-county_cn' } ],
 ['C_INI', { icon: 'faBuilding', label: 'Niš (all airports), Serbia', key: 'C_INI', type: 'city', code: 'nis_rs' } ],
 ['C_NBC', { icon: 'faBuilding', label: 'Nizhnekamsk (all airports), Russia', key: 'C_NBC', type: 'city', code: 'nizhnekamsk_ru' } ],
 ['C_NJC', { icon: 'faBuilding', label: 'Nizhnevartovsk (all airports), Russia', key: 'C_NJC', type: 'city', code: 'nizhnevartovsk_ru' } ],
 ['C_GOJ', { icon: 'faBuilding', label: 'Nizhny Novgorod (all airports), Russia', key: 'C_GOJ', type: 'city', code: 'nizhny-novgorod_ru' } ],
 ['C_null', { icon: 'faBuilding', label: 'Njombe (all airports), Tanzania', key: 'C_null', type: 'city', code: 'njombe_tz' } ],
 ['C_WTK', { icon: 'faBuilding', label: 'Noatak (all airports), United States', key: 'C_WTK', type: 'city', code: 'noatak_ak_us' } ],
 ['C_NGK', { icon: 'faBuilding', label: 'Nogliki (all airports), Russia', key: 'C_NGK', type: 'city', code: 'nogliki_ru' } ],
 ['C_OME', { icon: 'faBuilding', label: 'Nome (all airports), United States', key: 'C_OME', type: 'city', code: 'nome_ak_us' } ],
 ['C_NNL', { icon: 'faBuilding', label: 'Nondalton (all airports), United States', key: 'C_NNL', type: 'city', code: 'nondalton_ak_us' } ],
 ['C_ORV', { icon: 'faBuilding', label: 'Noorvik (all airports), United States', key: 'C_ORV', type: 'city', code: 'noorvik_ak_us' } ],
 ['C_ORF', { icon: 'faBuilding', label: 'Norfolk (all airports), United States', key: 'C_ORF', type: 'city', code: 'norfolk_va_us' } ],
 ['C_NSK', { icon: 'faBuilding', label: 'Norilsk (all airports), Russia', key: 'C_NSK', type: 'city', code: 'norilsk_ru' } ],
 ['C_YVQ', { icon: 'faBuilding', label: 'Norman Wells (all airports), Canada', key: 'C_YVQ', type: 'city', code: 'norman-wells_nt_ca' } ],
 ['C_NTN', { icon: 'faBuilding', label: 'Normanton (all airports), Australia', key: 'C_NTN', type: 'city', code: 'normanton_ql_au' } ],
 ['C_NRK', { icon: 'faBuilding', label: 'Norrköping (all airports), Sweden', key: 'C_NRK', type: 'city', code: 'norrkoping_se' } ],
 ['C_NUS', { icon: 'faBuilding', label: 'Norsup (all airports), Vanuatu', key: 'C_NUS', type: 'city', code: 'norsup_vu' } ],
 ['C_YYB', { icon: 'faBuilding', label: 'North Bay (all airports), Canada', key: 'C_YYB', type: 'city', code: 'north-bay_on_ca' } ],
 ['C_OTH', { icon: 'faBuilding', label: 'North Bend (all airports), United States', key: 'C_OTH', type: 'city', code: 'north-bend_or_us' } ],
 ['C_ELH', { icon: 'faBuilding', label: 'North Eleuthera (all airports), Bahamas', key: 'C_ELH', type: 'city', code: 'north-eleuthera_bs' } ],
 ['C_LBF', { icon: 'faBuilding', label: 'North Platte (all airports), United States', key: 'C_LBF', type: 'city', code: 'north-platte_ne_us' } ],
 ['C_NRL', { icon: 'faBuilding', label: 'North Ronaldsay (all airports), United Kingdom', key: 'C_NRL', type: 'city', code: 'north-ronaldsay_gb' } ],
 ['C_YNO', { icon: 'faBuilding', label: 'North Spirit Lake (all airports), Canada', key: 'C_YNO', type: 'city', code: 'north-spirit-lake_on_ca' } ],
 ['C_ECN', { icon: 'faBuilding', label: 'Northern Cyprus (all airports), Unknown Region', key: 'C_ECN', type: 'city', code: 'ercan_zz' } ],
 ['C_YNE', { icon: 'faBuilding', label: 'Norway House (all airports), Canada', key: 'C_YNE', type: 'city', code: 'norway-house_mb_ca' } ],
 ['C_NWI', { icon: 'faBuilding', label: 'Norwich (all airports), United Kingdom', key: 'C_NWI', type: 'city', code: 'norwich_gb' } ],
 ['C_NOB', { icon: 'faBuilding', label: 'Nosara (all airports), Costa Rica', key: 'C_NOB', type: 'city', code: 'nosara_cr' } ],
 ['C_NOS', { icon: 'faBuilding', label: 'Nosy Be (all airports), Madagascar', key: 'C_NOS', type: 'city', code: 'nosy-be_mg' } ],
 ['C_NQT', { icon: 'faBuilding', label: 'Nottingham (all airports), United Kingdom', key: 'C_NQT', type: 'city', code: 'nottingham_gb' } ],
 ['C_NDB', { icon: 'faBuilding', label: 'Nouadhibou (all airports), Mauritania', key: 'C_NDB', type: 'city', code: 'nouadhibou_mr' } ],
 ['C_NKC', { icon: 'faBuilding', label: 'Nouakchott (all airports), Mauritania', key: 'C_NKC', type: 'city', code: 'nouakchott_mr' } ],
 ['C_NOU', { icon: 'faBuilding', label: 'Nouméa (all airports), New Caledonia', key: 'C_NOU', type: 'city', code: 'noumea_nc' } ],
 ['C_NOZ', { icon: 'faBuilding', label: 'Novokuznetsk (all airports), Russia', key: 'C_NOZ', type: 'city', code: 'novokuznetsk_ru' } ],
 ['C_OVB', { icon: 'faBuilding', label: 'Novosibirsk (all airports), Russia', key: 'C_OVB', type: 'city', code: 'novosibirsk_ru' } ],
 ['C_NUX', { icon: 'faBuilding', label: 'Novy Urengoy (all airports), Russia', key: 'C_NUX', type: 'city', code: 'novy-urengoy_ru' } ],
 ['C_NSH', { icon: 'faBuilding', label: 'Nowshahr (all airports), Iran', key: 'C_NSH', type: 'city', code: 'nowshahr_ir' } ],
 ['C_NOJ', { icon: 'faBuilding', label: 'Noyabrsk (all airports), Russia', key: 'C_NOJ', type: 'city', code: 'noyabrsk_ru' } ],
 ['C_GER', { icon: 'faBuilding', label: 'Nueva Gerona (all airports), Cuba', key: 'C_GER', type: 'city', code: 'nueva-gerona_cu' } ],
 ['C_NLD', { icon: 'faBuilding', label: 'Nuevo Laredo (all airports), Mexico', key: 'C_NLD', type: 'city', code: 'nuevo-laredo_mx' } ],
 ['C_NUI', { icon: 'faBuilding', label: 'Nuiqsut (all airports), United States', key: 'C_NUI', type: 'city', code: 'nuiqsut_ak_us' } ],
 ['C_NHV', { icon: 'faBuilding', label: 'Nuku Hiva (all airports), French Polynesia', key: 'C_NHV', type: 'city', code: 'nuku-hiva_pf' } ],
 ['C_TBU', { icon: 'faBuilding', label: 'Nukuʻalofa (all airports), Tonga', key: 'C_TBU', type: 'city', code: 'nuku-alofa_to' } ],
 ['C_NCU', { icon: 'faBuilding', label: 'Nukus (all airports), Uzbekistan', key: 'C_NCU', type: 'city', code: 'nukus_uz' } ],
 ['C_NUL', { icon: 'faBuilding', label: 'Nulato (all airports), United States', key: 'C_NUL', type: 'city', code: 'nulato_ak_us' } ],
 ['C_SXP', { icon: 'faBuilding', label: 'Nunam Iqua (all airports), United States', key: 'C_SXP', type: 'city', code: 'nunam-iqua_ak_us' } ],
 ['C_NUP', { icon: 'faBuilding', label: 'Nunapitchuk (all airports), United States', key: 'C_NUP', type: 'city', code: 'nunapitchuk_ak_us' } ],
 ['C_NNX', { icon: 'faBuilding', label: 'Nunukan (all airports), Indonesia', key: 'C_NNX', type: 'city', code: 'nunukan-island_id' } ],
 ['C_NQU', { icon: 'faBuilding', label: 'Nuquí (all airports), Colombia', key: 'C_NQU', type: 'city', code: 'nuqui_co' } ],
 ['C_NUE', { icon: 'faBuilding', label: 'Nuremberg (all airports), Germany', key: 'C_NUE', type: 'city', code: 'nuremberg_de' } ],
 ['C_GOH', { icon: 'faBuilding', label: 'Nuuk (all airports), Greenland', key: 'C_GOH', type: 'city', code: 'nuuk_gl' } ],
 ['C_NYA', { icon: 'faBuilding', label: 'Nyagan (all airports), Russia', key: 'C_NYA', type: 'city', code: 'nyagan_ru' } ],
 ['C_UYL', { icon: 'faBuilding', label: 'Nyala (all airports), Sudan', key: 'C_UYL', type: 'city', code: 'nyala_sd' } ],
 ['C_LZY', { icon: 'faBuilding', label: 'Nyingchi (all airports), China', key: 'C_LZY', type: 'city', code: 'nyingchi_cn' } ],
 ['C_ZAW', { icon: 'faBuilding', label: 'Nykobing Mors (all airports), Denmark', key: 'C_ZAW', type: 'city', code: 'nykobing-mors_dk' } ],
 ['C_NYR', { icon: 'faBuilding', label: 'Nyurba (all airports), Russia', key: 'C_NYR', type: 'city', code: 'nyurba_ru' } ],
 ['C_OAK', { icon: 'faBuilding', label: 'Oakland (all airports), United States', key: 'C_OAK', type: 'city', code: 'oakland_ca_us' } ],
 ['C_OAX', { icon: 'faBuilding', label: 'Oaxaca (all airports), Mexico', key: 'C_OAX', type: 'city', code: 'oaxaca_mx' } ],
 ['C_OBO', { icon: 'faBuilding', label: 'Obihiro (all airports), Japan', key: 'C_OBO', type: 'city', code: 'obihiro_jp' } ],
 ['C_SBY', { icon: 'faBuilding', label: 'Ocean City (all airports), United States', key: 'C_SBY', type: 'city', code: 'ocean-city_md_us' } ],
 ['C_ONJ', { icon: 'faBuilding', label: 'Odate (all airports), Japan', key: 'C_ONJ', type: 'city', code: 'odate_jp' } ],
 ['C_null', { icon: 'faBuilding', label: 'Odense (all airports), Denmark', key: 'C_null', type: 'city', code: 'odense_dk' } ],
 ['C_ODS', { icon: 'faBuilding', label: 'Odessa (all airports), Ukraine', key: 'C_ODS', type: 'city', code: 'odessa_ua' } ],
 ['C_KEO', { icon: 'faBuilding', label: 'Odienné (all airports), Côte d’Ivoire', key: 'C_KEO', type: 'city', code: 'odienne_ci' } ],
 ['C_OGD', { icon: 'faBuilding', label: 'Ogden (all airports), United States', key: 'C_OGD', type: 'city', code: 'ogden_ut_us' } ],
 ['C_OGS', { icon: 'faBuilding', label: 'Ogdensburg (all airports), United States', key: 'C_OGS', type: 'city', code: 'ogdensburg_ny_us' } ],
 ['C_OHD', { icon: 'faBuilding', label: 'Ohrid (all airports), Macedonia (FYROM)', key: 'C_OHD', type: 'city', code: 'ohrid_mk' } ],
 ['C_OIT', { icon: 'faBuilding', label: 'Oita (all airports), Japan', key: 'C_OIT', type: 'city', code: 'oita_jp' } ],
 ['C_OKJ', { icon: 'faBuilding', label: 'Okayama (all airports), Japan', key: 'C_OKJ', type: 'city', code: 'okayama_jp' } ],
 ['C_OHH', { icon: 'faBuilding', label: 'Okha (all airports), Russia', key: 'C_OHH', type: 'city', code: 'okha_ru' } ],
 ['C_OHO', { icon: 'faBuilding', label: 'Okhotsk (all airports), Russia', key: 'C_OHO', type: 'city', code: 'okhotsk_ru' } ],
 ['C_OKI', { icon: 'faBuilding', label: 'Oki Islands (all airports), Japan', key: 'C_OKI', type: 'city', code: 'oki-islands_jp' } ],
 ['C_OKA', { icon: 'faBuilding', label: 'Okinawa Island (all airports), Japan', key: 'C_OKA', type: 'city', code: 'okinawa-prefecture_jp' } ],
 ['C_OKC', { icon: 'faBuilding', label: 'Oklahoma City (all airports), United States', key: 'C_OKC', type: 'city', code: 'oklahoma-city_ok_us' } ],
 ['C_OKL', { icon: 'faBuilding', label: 'Oksibil (all airports), Indonesia', key: 'C_OKL', type: 'city', code: 'oksibil_id' } ],
 ['C_OIR', { icon: 'faBuilding', label: 'Okushiri (all airports), Japan', key: 'C_OIR', type: 'city', code: 'okushiri_jp' } ],
 ['C_OLB', { icon: 'faBuilding', label: 'Olbia (all airports), Italy', key: 'C_OLB', type: 'city', code: 'olbia_it' } ],
 ['C_YOC', { icon: 'faBuilding', label: 'Old Crow, Yukon (all airports), Canada', key: 'C_YOC', type: 'city', code: 'old-crow--yukon_yt_ca' } ],
 ['C_OLH', { icon: 'faBuilding', label: 'Old Harbor (all airports), United States', key: 'C_OLH', type: 'city', code: 'old-harbor_ak_us' } ],
 ['C_ONK', { icon: 'faBuilding', label: 'Olenyok (all airports), Russia', key: 'C_ONK', type: 'city', code: 'olenyok_ru' } ],
 ['C_ULG', { icon: 'faBuilding', label: 'Ölgii (all airports), Mongolia', key: 'C_ULG', type: 'city', code: 'olgii_mn' } ],
 ['C_OLO', { icon: 'faBuilding', label: 'Olomouc (all airports), Czechia', key: 'C_OLO', type: 'city', code: 'olomouc_cz' } ],
 ['C_OLZ', { icon: 'faBuilding', label: 'Olyokminsk (all airports), Russia', key: 'C_OLZ', type: 'city', code: 'olyokminsk_ru' } ],
 ['C_OMA', { icon: 'faBuilding', label: 'Omaha (all airports), United States', key: 'C_OMA', type: 'city', code: 'omaha_ne_us' } ],
 ['C_OMS', { icon: 'faBuilding', label: 'Omsk (all airports), Russia', key: 'C_OMS', type: 'city', code: 'omsk_ru' } ],
 ['C_OND', { icon: 'faBuilding', label: 'Ondangwa (all airports), Namibia', key: 'C_OND', type: 'city', code: 'ondangwa_na' } ],
 ['C_VPE', { icon: 'faBuilding', label: 'Ondjiva (all airports), Angola', key: 'C_VPE', type: 'city', code: 'ondjiva_ao' } ],
 ['C_ONS', { icon: 'faBuilding', label: 'Onslow (all airports), Australia', key: 'C_ONS', type: 'city', code: 'onslow_wa_au' } ],
 ['C_ONT', { icon: 'faBuilding', label: 'Ontario (all airports), United States', key: 'C_ONT', type: 'city', code: 'ontario_ca_us' } ],
 ['C_OMR', { icon: 'faBuilding', label: 'Oradea (all airports), Romania', key: 'C_OMR', type: 'city', code: 'oradea_ro' } ],
 ['C_URA', { icon: 'faBuilding', label: 'Oral (all airports), Kazakhstan', key: 'C_URA', type: 'city', code: 'oral_kz' } ],
 ['C_ORN', { icon: 'faBuilding', label: 'Oran (all airports), Algeria', key: 'C_ORN', type: 'city', code: 'oran_dz' } ],
 ['C_OAG', { icon: 'faBuilding', label: 'Orange (all airports), Australia', key: 'C_OAG', type: 'city', code: 'orange_ns_au' } ],
 ['C_ORZ', { icon: 'faBuilding', label: 'Orange Walk District (all airports), Belize', key: 'C_ORZ', type: 'city', code: 'orange-walk-district_bz' } ],
 ['C_OMD', { icon: 'faBuilding', label: 'Oranjemund (all airports), Namibia', key: 'C_OMD', type: 'city', code: 'oranjemund_na' } ],
 ['C_DSN', { icon: 'faBuilding', label: 'Ordos City (all airports), China', key: 'C_DSN', type: 'city', code: 'ordos_cn' } ],
 ['C_ORB', { icon: 'faBuilding', label: 'Örebro (all airports), Sweden', key: 'C_ORB', type: 'city', code: 'orebro_se' } ],
 ['C_REN', { icon: 'faBuilding', label: 'Orenburg (all airports), Russia', key: 'C_REN', type: 'city', code: 'orenburg_ru' } ],
 ['C_OLA', { icon: 'faBuilding', label: 'Ørland (all airports), Norway', key: 'C_OLA', type: 'city', code: 'orland_no' } ],
 ['C_ORL', { icon: 'faBuilding', label: 'Orlando (all airports), United States', key: 'C_ORL', type: 'city', code: 'orlando_fl_us' } ],
 ['C_OMC', { icon: 'faBuilding', label: 'Ormoc (all airports), Philippines', key: 'C_OMC', type: 'city', code: 'ormoc_ph' } ],
 ['C_OER', { icon: 'faBuilding', label: 'Örnsköldsvik (all airports), Sweden', key: 'C_OER', type: 'city', code: 'ornskoldsvik_se' } ],
 ['C_OSW', { icon: 'faBuilding', label: 'Orsk (all airports), Russia', key: 'C_OSW', type: 'city', code: 'orsk_ru' } ],
 ['C_ORU', { icon: 'faBuilding', label: 'Oruro (all airports), Bolivia', key: 'C_ORU', type: 'city', code: 'oruro_bo' } ],
 ['C_OSA', { icon: 'faBuilding', label: 'Osaka (all airports), Japan', key: 'C_OSA', type: 'city', code: 'osaka_jp' } ],
 ['C_OSS', { icon: 'faBuilding', label: 'Osh (all airports), Kyrgyzstan', key: 'C_OSS', type: 'city', code: 'osh_kg' } ],
 ['C_OSI', { icon: 'faBuilding', label: 'Osijek (all airports), Croatia', key: 'C_OSI', type: 'city', code: 'osijek_hr' } ],
 ['C_UKK', { icon: 'faBuilding', label: 'Oskemen (all airports), Kazakhstan', key: 'C_UKK', type: 'city', code: 'oskemen_kz' } ],
 ['C_OSL', { icon: 'faBuilding', label: 'Oslo (all airports), Norway', key: 'C_OSL', type: 'city', code: 'oslo_no' } ],
 ['C_ZOS', { icon: 'faBuilding', label: 'Osorno (all airports), Chile', key: 'C_ZOS', type: 'city', code: 'osorno_cl' } ],
 ['C_OST', { icon: 'faBuilding', label: 'Ostend (all airports), Belgium', key: 'C_OST', type: 'city', code: 'ostend_be' } ],
 ['C_OSD', { icon: 'faBuilding', label: 'Östersund (all airports), Sweden', key: 'C_OSD', type: 'city', code: 'ostersund_se' } ],
 ['C_OSR', { icon: 'faBuilding', label: 'Ostrava (all airports), Czechia', key: 'C_OSR', type: 'city', code: 'ostrava_cz' } ],
 ['C_YOW', { icon: 'faBuilding', label: 'Ottawa (all airports), Canada', key: 'C_YOW', type: 'city', code: 'ottawa_on_ca' } ],
 ['C_OUA', { icon: 'faBuilding', label: 'Ouagadougou (all airports), Burkina Faso', key: 'C_OUA', type: 'city', code: 'ouagadougou_bf' } ],
 ['C_OFI', { icon: 'faBuilding', label: 'Ouango-Fitini (all airports), Côte d’Ivoire', key: 'C_OFI', type: 'city', code: 'ouango-fitini_ci' } ],
 ['C_OGX', { icon: 'faBuilding', label: 'Ouargla (all airports), Algeria', key: 'C_OGX', type: 'city', code: 'ouargla_dz' } ],
 ['C_OZZ', { icon: 'faBuilding', label: 'Ouarzazate (all airports), Morocco', key: 'C_OZZ', type: 'city', code: 'ouarzazate_ma' } ],
 ['C_OUD', { icon: 'faBuilding', label: 'Oujda (all airports), Morocco', key: 'C_OUD', type: 'city', code: 'oujda_ma' } ],
 ['C_OUL', { icon: 'faBuilding', label: 'Oulu (all airports), Finland', key: 'C_OUL', type: 'city', code: 'oulu_fi' } ],
 ['C_UVE', { icon: 'faBuilding', label: 'Ouvéa Island (all airports), New Caledonia', key: 'C_UVE', type: 'city', code: 'ouvea-island_nc' } ],
 ['C_KOZ', { icon: 'faBuilding', label: 'Ouzinkie (all airports), United States', key: 'C_KOZ', type: 'city', code: 'ouzinkie_ak_us' } ],
 ['C_VDA', { icon: 'faBuilding', label: 'Ovda (all airports), Israel', key: 'C_VDA', type: 'city', code: 'ovda_il' } ],
 ['C_OWB', { icon: 'faBuilding', label: 'Owensboro (all airports), United States', key: 'C_OWB', type: 'city', code: 'owensboro_ky_us' } ],
 ['C_QOW', { icon: 'faBuilding', label: 'Owerri (all airports), Nigeria', key: 'C_QOW', type: 'city', code: 'owerri_ng' } ],
 ['C_OXF', { icon: 'faBuilding', label: 'Oxford (all airports), United Kingdom', key: 'C_OXF', type: 'city', code: 'oxford_gb' } ],
 ['C_YOH', { icon: 'faBuilding', label: 'Oxford House, Manitoba (all airports), Canada', key: 'C_YOH', type: 'city', code: 'oxford-house_mb_ca' } ],
 ['C_OZC', { icon: 'faBuilding', label: 'Ozamiz (all airports), Philippines', key: 'C_OZC', type: 'city', code: 'ozamiz_ph' } ],
 ['C_PBJ', { icon: 'faBuilding', label: 'Paama (all airports), Vanuatu', key: 'C_PBJ', type: 'city', code: 'paama_vu' } ],
 ['C_JFR', { icon: 'faBuilding', label: 'Paamiut (all airports), Greenland', key: 'C_JFR', type: 'city', code: 'paamiut_gl' } ],
 ['C_PDG', { icon: 'faBuilding', label: 'Padang (all airports), Indonesia', key: 'C_PDG', type: 'city', code: 'padang_id' } ],
 ['C_AEG', { icon: 'faBuilding', label: 'Padangsidempuan (all airports), Indonesia', key: 'C_AEG', type: 'city', code: 'padangsidempuan_id' } ],
 ['C_PAD', { icon: 'faBuilding', label: 'Paderborn (all airports), Germany', key: 'C_PAD', type: 'city', code: 'paderborn_de' } ],
 ['C_PAH', { icon: 'faBuilding', label: 'Paducah (all airports), United States', key: 'C_PAH', type: 'city', code: 'paducah_ky_us' } ],
 ['C_PAG', { icon: 'faBuilding', label: 'Pagadian (all airports), Philippines', key: 'C_PAG', type: 'city', code: 'pagadian_ph' } ],
 ['C_PGA', { icon: 'faBuilding', label: 'Page (all airports), United States', key: 'C_PGA', type: 'city', code: 'page_az_us' } ],
 ['C_PPG', { icon: 'faBuilding', label: 'Pago Pago (all airports), United States', key: 'C_PPG', type: 'city', code: 'pago-pago_as' } ],
 ['C_PYY', { icon: 'faBuilding', label: 'Pai (all airports), Thailand', key: 'C_PYY', type: 'city', code: 'pai_th' } ],
 ['C_PKZ', { icon: 'faBuilding', label: 'Pakse (all airports), Laos', key: 'C_PKZ', type: 'city', code: 'pakse_la' } ],
 ['C_PLQ', { icon: 'faBuilding', label: 'Palanga (all airports), Lithuania', key: 'C_PLQ', type: 'city', code: 'palanga_lt' } ],
 ['C_PKY', { icon: 'faBuilding', label: 'Palangka Raya (all airports), Indonesia', key: 'C_PKY', type: 'city', code: 'palangka-raya_id' } ],
 ['C_PLM', { icon: 'faBuilding', label: 'Palembang (all airports), Indonesia', key: 'C_PLM', type: 'city', code: 'palembang_id' } ],
 ['C_PQM', { icon: 'faBuilding', label: 'Palenque (all airports), Mexico', key: 'C_PQM', type: 'city', code: 'palenque_mx' } ],
 ['C_PMO', { icon: 'faBuilding', label: 'Palermo (all airports), Italy', key: 'C_PMO', type: 'city', code: 'palermo_it' } ],
 ['C_PSP', { icon: 'faBuilding', label: 'Palm Springs (all airports), United States', key: 'C_PSP', type: 'city', code: 'palm-springs_ca_us' } ],
 ['C_PMI', { icon: 'faBuilding', label: 'Palma, Majorca (all airports), Spain', key: 'C_PMI', type: 'city', code: 'palma_es' } ],
 ['C_PMZ', { icon: 'faBuilding', label: 'Palmar Sur (all airports), Costa Rica', key: 'C_PMZ', type: 'city', code: 'palmar-sur_cr' } ],
 ['C_PMW', { icon: 'faBuilding', label: 'Palmas (all airports), Brazil', key: 'C_PMW', type: 'city', code: 'palmas_to_br' } ],
 ['C_PMR', { icon: 'faBuilding', label: 'Palmerston North (all airports), New Zealand', key: 'C_PMR', type: 'city', code: 'palmerston-north_nz' } ],
 ['C_LLO', { icon: 'faBuilding', label: 'Palopo (all airports), Indonesia', key: 'C_LLO', type: 'city', code: 'palopo_id' } ],
 ['C_PLW', { icon: 'faBuilding', label: 'Palu (all airports), Indonesia', key: 'C_PLW', type: 'city', code: 'palu_id' } ],
 ['C_PNA', { icon: 'faBuilding', label: 'Pamplona (all airports), Spain', key: 'C_PNA', type: 'city', code: 'pamplona_es' } ],
 ['C_ECP', { icon: 'faBuilding', label: 'Panama City (all airports), United States', key: 'C_ECP', type: 'city', code: 'panama-city_fl_us' } ],
 ['C_PTY', { icon: 'faBuilding', label: 'Panama City (all airports), Panama', key: 'C_PTY', type: 'city', code: 'panama-city_pa' } ],
 ['C_PGK', { icon: 'faBuilding', label: 'Pangkal Pinang (all airports), Indonesia', key: 'C_PGK', type: 'city', code: 'pangkal-pinang_id' } ],
 ['C_PKN', { icon: 'faBuilding', label: 'Pangkalan Bun (all airports), Indonesia', key: 'C_PKN', type: 'city', code: 'pangkalan-bun_id' } ],
 ['C_YXP', { icon: 'faBuilding', label: 'Pangnirtung (all airports), Canada', key: 'C_YXP', type: 'city', code: 'pangnirtung_nu_ca' } ],
 ['C_PJG', { icon: 'faBuilding', label: 'Panjgur (all airports), Pakistan', key: 'C_PJG', type: 'city', code: 'panjgur_pk' } ],
 ['C_PNL', { icon: 'faBuilding', label: 'Pantelleria (all airports), Italy', key: 'C_PNL', type: 'city', code: 'pantelleria_it' } ],
 ['C_PGH', { icon: 'faBuilding', label: 'Pantnagar (all airports), India', key: 'C_PGH', type: 'city', code: 'pantnagar_in' } ],
 ['C_PZI', { icon: 'faBuilding', label: 'Panzhihua (all airports), China', key: 'C_PZI', type: 'city', code: 'panzhihua_cn' } ],
 ['C_PPW', { icon: 'faBuilding', label: 'Papa Westray (all airports), United Kingdom', key: 'C_PPW', type: 'city', code: 'papa-westray_gb' } ],
 ['C_PFO', { icon: 'faBuilding', label: 'Paphos (all airports), Cyprus', key: 'C_PFO', type: 'city', code: 'paphos_cy' } ],
 ['C_PBO', { icon: 'faBuilding', label: 'Paraburdoo (all airports), Australia', key: 'C_PBO', type: 'city', code: 'paraburdoo_wa_au' } ],
 ['C_PBM', { icon: 'faBuilding', label: 'Paramaribo (all airports), Suriname', key: 'C_PBM', type: 'city', code: 'paramaribo_sr' } ],
 ['C_PRA', { icon: 'faBuilding', label: 'Paraná (all airports), Argentina', key: 'C_PRA', type: 'city', code: 'parana_er_ar' } ],
 ['C_PPQ', { icon: 'faBuilding', label: 'Paraparaumu (all airports), New Zealand', key: 'C_PPQ', type: 'city', code: 'paraparaumu_nz' } ],
 ['C_null', { icon: 'faBuilding', label: 'Parasi (all airports), Solomon Islands', key: 'C_null', type: 'city', code: 'parasi_sb' } ],
 ['C_CKS', { icon: 'faBuilding', label: 'Parauapebas (all airports), Brazil', key: 'C_CKS', type: 'city', code: 'parauapebas_pa_br' } ],
 ['C_PED', { icon: 'faBuilding', label: 'Pardubice (all airports), Czechia', key: 'C_PED', type: 'city', code: 'pardubice_cz' } ],
 ['C_PAS', { icon: 'faBuilding', label: 'Parikia (all airports), Greece', key: 'C_PAS', type: 'city', code: 'parikia_gr' } ],
 ['C_PIN', { icon: 'faBuilding', label: 'Parintins (all airports), Brazil', key: 'C_PIN', type: 'city', code: 'parintins_am_br' } ],
 ['C_PAR', { icon: 'faBuilding', label: 'Paris (all airports), France', key: 'C_PAR', type: 'city', code: 'paris_fr' } ],
 ['C_PKB', { icon: 'faBuilding', label: 'Parkersburg (all airports), United States', key: 'C_PKB', type: 'city', code: 'parkersburg_wv_us' } ],
 ['C_PKE', { icon: 'faBuilding', label: 'Parkes (all airports), Australia', key: 'C_PKE', type: 'city', code: 'parkes_ns_au' } ],
 ['C_PMF', { icon: 'faBuilding', label: 'Parma (all airports), Italy', key: 'C_PMF', type: 'city', code: 'parma_it' } ],
 ['C_PHB', { icon: 'faBuilding', label: 'Parnaíba (all airports), Brazil', key: 'C_PHB', type: 'city', code: 'parnaíba_pi_br' } ],
 ['C_PBH', { icon: 'faBuilding', label: 'Paro (all airports), Bhutan', key: 'C_PBH', type: 'city', code: 'paro_bt' } ],
 ['C_null', { icon: 'faBuilding', label: 'Parsabad (all airports), Iran', key: 'C_null', type: 'city', code: 'parsabad_ir' } ],
 ['C_PSC', { icon: 'faBuilding', label: 'Pasco (all airports), United States', key: 'C_PSC', type: 'city', code: 'pasco_wa_us' } ],
 ['C_null', { icon: 'faBuilding', label: 'Pasighat (all airports), India', key: 'C_null', type: 'city', code: 'pasighat_in' } ],
 ['C_ZPF', { icon: 'faBuilding', label: 'Passau (all airports), Germany', key: 'C_ZPF', type: 'city', code: 'passau_de' } ],
 ['C_PFB', { icon: 'faBuilding', label: 'Passo Fundo (all airports), Brazil', key: 'C_PFB', type: 'city', code: 'passo-fundo_rs_br' } ],
 ['C_PSO', { icon: 'faBuilding', label: 'Pasto (all airports), Colombia', key: 'C_PSO', type: 'city', code: 'pasto_co' } ],
 ['C_QYT', { icon: 'faBuilding', label: 'Paterswolde (all airports), Netherlands', key: 'C_QYT', type: 'city', code: 'paterswolde_nl' } ],
 ['C_PAT', { icon: 'faBuilding', label: 'Patna (all airports), India', key: 'C_PAT', type: 'city', code: 'patna_in' } ],
 ['C_GPA', { icon: 'faBuilding', label: 'Patras (all airports), Greece', key: 'C_GPA', type: 'city', code: 'patras_gr' } ],
 ['C_UTP', { icon: 'faBuilding', label: 'Pattaya (all airports), Thailand', key: 'C_UTP', type: 'city', code: 'pattaya_th' } ],
 ['C_PUF', { icon: 'faBuilding', label: 'Pau, Pyrénées-Atlantiques (all airports), France', key: 'C_PUF', type: 'city', code: 'pau_fr' } ],
 ['C_PAV', { icon: 'faBuilding', label: 'Paulo Afonso (all airports), Brazil', key: 'C_PAV', type: 'city', code: 'paulo-afonso_ba_br' } ],
 ['C_PWQ', { icon: 'faBuilding', label: 'Pavlodar (all airports), Kazakhstan', key: 'C_PWQ', type: 'city', code: 'pavlodar_kz' } ],
 ['C_YPO', { icon: 'faBuilding', label: 'Peawanuck (all airports), Canada', key: 'C_YPO', type: 'city', code: 'peawanuck_on_ca' } ],
 ['C_PEX', { icon: 'faBuilding', label: 'Pechora (all airports), Russia', key: 'C_PEX', type: 'city', code: 'pechora_ru' } ],
 ['C_PDM', { icon: 'faBuilding', label: 'Pedasi (all airports), Panama', key: 'C_PDM', type: 'city', code: 'pedasi_pa' } ],
 ['C_PDB', { icon: 'faBuilding', label: 'Pedro Bay (all airports), United States', key: 'C_PDB', type: 'city', code: 'pedro-bay_ak_us' } ],
 ['C_PKU', { icon: 'faBuilding', label: 'Pekanbaru (all airports), Indonesia', key: 'C_PKU', type: 'city', code: 'pekanbaru_id' } ],
 ['C_PEC', { icon: 'faBuilding', label: 'Pelican (all airports), United States', key: 'C_PEC', type: 'city', code: 'pelican_ak_us' } ],
 ['C_PLN', { icon: 'faBuilding', label: 'Pellston (all airports), United States', key: 'C_PLN', type: 'city', code: 'pellston_mi_us' } ],
 ['C_PET', { icon: 'faBuilding', label: 'Pelotas (all airports), Brazil', key: 'C_PET', type: 'city', code: 'pelotas_rs_br' } ],
 ['C_POL', { icon: 'faBuilding', label: 'Pemba (all airports), Mozambique', key: 'C_POL', type: 'city', code: 'pemba_mz' } ],
 ['C_PMA', { icon: 'faBuilding', label: 'Pemba Island (all airports), Tanzania', key: 'C_PMA', type: 'city', code: 'pemba-island_tz' } ],
 ['C_PEN', { icon: 'faBuilding', label: 'Penang (all airports), Malaysia', key: 'C_PEN', type: 'city', code: 'penang_my' } ],
 ['C_PDT', { icon: 'faBuilding', label: 'Pendleton (all airports), United States', key: 'C_PDT', type: 'city', code: 'pendleton_or_us' } ],
 ['C_PNS', { icon: 'faBuilding', label: 'Pensacola (all airports), United States', key: 'C_PNS', type: 'city', code: 'pensacola_fl_us' } ],
 ['C_YYF', { icon: 'faBuilding', label: 'Penticton (all airports), Canada', key: 'C_YYF', type: 'city', code: 'penticton_bc_ca' } ],
 ['C_PEZ', { icon: 'faBuilding', label: 'Penza (all airports), Russia', key: 'C_PEZ', type: 'city', code: 'penza_ru' } ],
 ['C_PIA', { icon: 'faBuilding', label: 'Peoria (all airports), United States', key: 'C_PIA', type: 'city', code: 'peoria_il_us' } ],
 ['C_PEI', { icon: 'faBuilding', label: 'Pereira (all airports), Colombia', key: 'C_PEI', type: 'city', code: 'pereira_co' } ],
 ['C_PGX', { icon: 'faBuilding', label: 'Périgueux (all airports), France', key: 'C_PGX', type: 'city', code: 'perigueux_fr' } ],
 ['C_PEE', { icon: 'faBuilding', label: 'Perm (all airports), Russia', key: 'C_PEE', type: 'city', code: 'perm_ru' } ],
 ['C_PGF', { icon: 'faBuilding', label: 'Perpignan (all airports), France', key: 'C_PGF', type: 'city', code: 'perpignan_fr' } ],
 ['C_KPV', { icon: 'faBuilding', label: 'Perryville (all airports), United States', key: 'C_KPV', type: 'city', code: 'perryville_ak_us' } ],
 ['C_PER', { icon: 'faBuilding', label: 'Perth (all airports), Australia', key: 'C_PER', type: 'city', code: 'perth_wa_au' } ],
 ['C_PEG', { icon: 'faBuilding', label: 'Perugia (all airports), Italy', key: 'C_PEG', type: 'city', code: 'perugia_it' } ],
 ['C_PSR', { icon: 'faBuilding', label: 'Pescara (all airports), Italy', key: 'C_PSR', type: 'city', code: 'pescara_it' } ],
 ['C_PEW', { icon: 'faBuilding', label: 'Peshawar (all airports), Pakistan', key: 'C_PEW', type: 'city', code: 'peshawar_pk' } ],
 ['C_PSG', { icon: 'faBuilding', label: 'Petersburg (all airports), United States', key: 'C_PSG', type: 'city', code: 'petersburg_ak_us' } ],
 ['C_PNZ', { icon: 'faBuilding', label: 'Petrolina (all airports), Brazil', key: 'C_PNZ', type: 'city', code: 'petrolina_pe_br' } ],
 ['C_PPK', { icon: 'faBuilding', label: 'Petropavl (all airports), Kazakhstan', key: 'C_PPK', type: 'city', code: 'petropavl_kz' } ],
 ['C_PKC', { icon: 'faBuilding', label: 'Petropavlovsk-Kamchatsky (all airports), Russia', key: 'C_PKC', type: 'city', code: 'petropavlovsk-kamchatsky_ru' } ],
 ['C_PES', { icon: 'faBuilding', label: 'Petrozavodsk (all airports), Russia', key: 'C_PES', type: 'city', code: 'petrozavodsk_ru' } ],
 ['C_PHW', { icon: 'faBuilding', label: 'Phalaborwa (all airports), South Africa', key: 'C_PHW', type: 'city', code: 'phalaborwa_za' } ],
 ['C_null', { icon: 'faBuilding', label: 'Phaplu (all airports), Nepal', key: 'C_null', type: 'city', code: 'phaplu_np' } ],
 ['C_PHL', { icon: 'faBuilding', label: 'Philadelphia (all airports), United States', key: 'C_PHL', type: 'city', code: 'philadelphia_pa_us' } ],
 ['C_PZL', { icon: 'faBuilding', label: 'Phinda Private Game Reserve (all airports), South Africa', key: 'C_PZL', type: 'city', code: 'phinda-game-reserve_za' } ],
 ['C_PHS', { icon: 'faBuilding', label: 'Phitsanulok (all airports), Thailand', key: 'C_PHS', type: 'city', code: 'phitsanulok_th' } ],
 ['C_PNH', { icon: 'faBuilding', label: 'Phnom Penh (all airports), Cambodia', key: 'C_PNH', type: 'city', code: 'phnom-penh_kh' } ],
 ['C_PHX', { icon: 'faBuilding', label: 'Phoenix (all airports), United States', key: 'C_PHX', type: 'city', code: 'phoenix_az_us' } ],
 ['C_XKH', { icon: 'faBuilding', label: 'Phonsavan (all airports), Laos', key: 'C_XKH', type: 'city', code: 'phonsavan_la' } ],
 ['C_PRH', { icon: 'faBuilding', label: 'Phrae Province (all airports), Thailand', key: 'C_PRH', type: 'city', code: 'phrae-province_th' } ],
 ['C_PQC', { icon: 'faBuilding', label: 'Phú Quốc (all airports), Vietnam', key: 'C_PQC', type: 'city', code: 'phu-quoc_vn' } ],
 ['C_HKT', { icon: 'faBuilding', label: 'Phuket City (all airports), Thailand', key: 'C_HKT', type: 'city', code: 'phuket-city_th' } ],
 ['C_PIX', { icon: 'faBuilding', label: 'Pico Island (all airports), Portugal', key: 'C_PIX', type: 'city', code: 'pico-island_pt' } ],
 ['C_PCN', { icon: 'faBuilding', label: 'Picton (all airports), New Zealand', key: 'C_PCN', type: 'city', code: 'picton_nz' } ],
 ['C_PDS', { icon: 'faBuilding', label: 'Piedras Negras (all airports), Mexico', key: 'C_PDS', type: 'city', code: 'piedras-negras_mx' } ],
 ['C_PIR', { icon: 'faBuilding', label: 'Pierre (all airports), United States', key: 'C_PIR', type: 'city', code: 'pierre_sd_us' } ],
 ['C_PZB', { icon: 'faBuilding', label: 'Pietermaritzburg (all airports), South Africa', key: 'C_PZB', type: 'city', code: 'pietermaritzburg_za' } ],
 ['C_YPM', { icon: 'faBuilding', label: 'Pikangikum First Nation (all airports), Canada', key: 'C_YPM', type: 'city', code: 'pikangikum-first-nation_on_ca' } ],
 ['C_PIP', { icon: 'faBuilding', label: 'Pilot Point (all airports), United States', key: 'C_PIP', type: 'city', code: 'pilot-point_ak_us' } ],
 ['C_PSA', { icon: 'faBuilding', label: 'Pisa (all airports), Italy', key: 'C_PSA', type: 'city', code: 'pisa_it' } ],
 ['C_PIO', { icon: 'faBuilding', label: 'Pisco (all airports), Peru', key: 'C_PIO', type: 'city', code: 'pisco_pe' } ],
 ['C_PTX', { icon: 'faBuilding', label: 'Pitalito (all airports), Colombia', key: 'C_PTX', type: 'city', code: 'pitalito_co' } ],
 ['C_PIT', { icon: 'faBuilding', label: 'Pittsburgh (all airports), United States', key: 'C_PIT', type: 'city', code: 'pittsburgh_pa_us' } ],
 ['C_PIU', { icon: 'faBuilding', label: 'Piura (all airports), Peru', key: 'C_PIU', type: 'city', code: 'piura_pe' } ],
 ['C_PLJ', { icon: 'faBuilding', label: 'Placencia (all airports), Belize', key: 'C_PLJ', type: 'city', code: 'placencia_bz' } ],
 ['C_MLO', { icon: 'faBuilding', label: 'Plaka, Milos (all airports), Greece', key: 'C_MLO', type: 'city', code: 'plaka_gr' } ],
 ['C_TLY', { icon: 'faBuilding', label: 'Plastun (all airports), Russia', key: 'C_TLY', type: 'city', code: 'plastun_ru' } ],
 ['C_PTU', { icon: 'faBuilding', label: 'Platinum (all airports), United States', key: 'C_PTU', type: 'city', code: 'platinum_ak_us' } ],
 ['C_PBG', { icon: 'faBuilding', label: 'Plattsburgh (all airports), United States', key: 'C_PBG', type: 'city', code: 'plattsburgh_ny_us' } ],
 ['C_PXU', { icon: 'faBuilding', label: 'Pleiku (all airports), Vietnam', key: 'C_PXU', type: 'city', code: 'pleiku_vn' } ],
 ['C_PBZ', { icon: 'faBuilding', label: 'Plettenberg Bay (all airports), South Africa', key: 'C_PBZ', type: 'city', code: 'plettenberg-bay_za' } ],
 ['C_PDV', { icon: 'faBuilding', label: 'Plovdiv (all airports), Bulgaria', key: 'C_PDV', type: 'city', code: 'plovdiv_bg' } ],
 ['C_PLH', { icon: 'faBuilding', label: 'Plymouth (all airports), United Kingdom', key: 'C_PLH', type: 'city', code: 'plymouth_gb' } ],
 ['C_PIH', { icon: 'faBuilding', label: 'Pocatello (all airports), United States', key: 'C_PIH', type: 'city', code: 'pocatello_id_us' } ],
 ['C_TGD', { icon: 'faBuilding', label: 'Podgorica (all airports), Montenegro', key: 'C_TGD', type: 'city', code: 'podgorica_me' } ],
 ['C_TGP', { icon: 'faBuilding', label: 'Podkamennaya Tunguska (all airports), Russia', key: 'C_TGP', type: 'city', code: 'bor_ru' } ],
 ['C_KPO', { icon: 'faBuilding', label: 'Pohang (all airports), South Korea', key: 'C_KPO', type: 'city', code: 'pohang_kr' } ],
 ['C_PNI', { icon: 'faBuilding', label: 'Pohnpei (all airports), Micronesia', key: 'C_PNI', type: 'city', code: 'pohnpei_fm' } ],
 ['C_KPB', { icon: 'faBuilding', label: 'Point Baker (all airports), United States', key: 'C_KPB', type: 'city', code: 'point-baker_ak_us' } ],
 ['C_PHO', { icon: 'faBuilding', label: 'Point Hope (all airports), United States', key: 'C_PHO', type: 'city', code: 'point-hope_ak_us' } ],
 ['C_PIZ', { icon: 'faBuilding', label: 'Point Lay (all airports), United States', key: 'C_PIZ', type: 'city', code: 'point-lay_ak_us' } ],
 ['C_PTP', { icon: 'faBuilding', label: 'Pointe-à-Pitre (all airports), France', key: 'C_PTP', type: 'city', code: 'pointe-a-pitre_gp' } ],
 ['C_PNR', { icon: 'faBuilding', label: 'Pointe-Noire (all airports), Republic of the Congo', key: 'C_PNR', type: 'city', code: 'pointe-noire_cg' } ],
 ['C_PIS', { icon: 'faBuilding', label: 'Poitiers (all airports), France', key: 'C_PIS', type: 'city', code: 'poitiers_fr' } ],
 ['C_PKR', { icon: 'faBuilding', label: 'Pokhara (all airports), Nepal', key: 'C_PKR', type: 'city', code: 'pokhara_np' } ],
 ['C_PTG', { icon: 'faBuilding', label: 'Polokwane, Limpopo (all airports), South Africa', key: 'C_PTG', type: 'city', code: 'polokwane_za' } ],
 ['C_PUM', { icon: 'faBuilding', label: 'Pomalaa (all airports), Indonesia', key: 'C_PUM', type: 'city', code: 'pomala_id' } ],
 ['C_PSE', { icon: 'faBuilding', label: 'Ponce (all airports), United States', key: 'C_PSE', type: 'city', code: 'ponce_pr_pr' } ],
 ['C_YIO', { icon: 'faBuilding', label: 'Pond Inlet (all airports), Canada', key: 'C_YIO', type: 'city', code: 'pond-inlet_nu_ca' } ],
 ['C_PNY', { icon: 'faBuilding', label: 'Pondicherry (all airports), India', key: 'C_PNY', type: 'city', code: 'pondicherry_in' } ],
 ['C_PDL', { icon: 'faBuilding', label: 'Ponta Delgada (all airports), Portugal', key: 'C_PDL', type: 'city', code: 'ponta-delgada_pt' } ],
 ['C_PGZ', { icon: 'faBuilding', label: 'Ponta Grossa (all airports), Brazil', key: 'C_PGZ', type: 'city', code: 'ponta-grossa_pr_br' } ],
 ['C_PNK', { icon: 'faBuilding', label: 'Pontianak (all airports), Indonesia', key: 'C_PNK', type: 'city', code: 'pontianak_id' } ],
 ['C_PPN', { icon: 'faBuilding', label: 'Popayán (all airports), Colombia', key: 'C_PPN', type: 'city', code: 'popayan_co' } ],
 ['C_PNP', { icon: 'faBuilding', label: 'Popondetta (all airports), Papua New Guinea', key: 'C_PNP', type: 'city', code: 'popondetta_pg' } ],
 ['C_TAT', { icon: 'faBuilding', label: 'Poprad (all airports), Slovakia', key: 'C_TAT', type: 'city', code: 'poprad_sk' } ],
 ['C_PBD', { icon: 'faBuilding', label: 'Porbandar (all airports), India', key: 'C_PBD', type: 'city', code: 'porbandar_in' } ],
 ['C_POR', { icon: 'faBuilding', label: 'Pori (all airports), Finland', key: 'C_POR', type: 'city', code: 'pori_fi' } ],
 ['C_PMV', { icon: 'faBuilding', label: 'Porlamar (all airports), Venezuela', key: 'C_PMV', type: 'city', code: 'porlamar_ve' } ],
 ['C_EDR', { icon: 'faBuilding', label: 'Pormpuraaw (all airports), Australia', key: 'C_EDR', type: 'city', code: 'pormpuraaw_ql_au' } ],
 ['C_PZU', { icon: 'faBuilding', label: 'Port (all airports), Sudan', key: 'C_PZU', type: 'city', code: 'port_sd' } ],
 ['C_PTA', { icon: 'faBuilding', label: 'Port Alsworth (all airports), United States', key: 'C_PTA', type: 'city', code: 'port-alsworth_ak_us' } ],
 ['C_PUG', { icon: 'faBuilding', label: 'Port Augusta (all airports), Australia', key: 'C_PUG', type: 'city', code: 'port-augusta_sa_au' } ],
 ['C_IXZ', { icon: 'faBuilding', label: 'Port Blair (all airports), India', key: 'C_IXZ', type: 'city', code: 'port-blair_in' } ],
 ['C_PLZ', { icon: 'faBuilding', label: 'Port Elizabeth (all airports), South Africa', key: 'C_PLZ', type: 'city', code: 'port-elizabeth_za' } ],
 ['C_PGM', { icon: 'faBuilding', label: 'Port Graham (all airports), United States', key: 'C_PGM', type: 'city', code: 'port-graham_ak_us' } ],
 ['C_PHC', { icon: 'faBuilding', label: 'Port Harcourt (all airports), Nigeria', key: 'C_PHC', type: 'city', code: 'port-harcourt_ng' } ],
 ['C_YZT', { icon: 'faBuilding', label: 'Port Hardy (all airports), Canada', key: 'C_YZT', type: 'city', code: 'port-hardy_bc_ca' } ],
 ['C_PHE', { icon: 'faBuilding', label: 'Port Hedland (all airports), Australia', key: 'C_PHE', type: 'city', code: 'port-hedland_wa_au' } ],
 ['C_PTH', { icon: 'faBuilding', label: 'Port Heiden (all airports), United States', key: 'C_PTH', type: 'city', code: 'port-heiden_ak_us' } ],
 ['C_PLO', { icon: 'faBuilding', label: 'Port Lincoln (all airports), Australia', key: 'C_PLO', type: 'city', code: 'port-lincoln_sa_au' } ],
 ['C_ORI', { icon: 'faBuilding', label: 'Port Lions (all airports), United States', key: 'C_ORI', type: 'city', code: 'port-lions_ak_us' } ],
 ['C_PQQ', { icon: 'faBuilding', label: 'Port Macquarie (all airports), Australia', key: 'C_PQQ', type: 'city', code: 'port-macquarie_ns_au' } ],
 ['C_PML', { icon: 'faBuilding', label: 'Port Moller (all airports), United States', key: 'C_PML', type: 'city', code: 'port-moller_ak_us' } ],
 ['C_POM', { icon: 'faBuilding', label: 'Port Moresby (all airports), Papua New Guinea', key: 'C_POM', type: 'city', code: 'port-moresby_pg' } ],
 ['C_POS', { icon: 'faBuilding', label: 'Port of Spain (all airports), Trinidad & Tobago', key: 'C_POS', type: 'city', code: 'port-of-spain_tt' } ],
 ['C_PPV', { icon: 'faBuilding', label: 'Port Protection (all airports), United States', key: 'C_PPV', type: 'city', code: 'port-protection_ak_us' } ],
 ['C_VLI', { icon: 'faBuilding', label: 'Port Vila (all airports), Vanuatu', key: 'C_VLI', type: 'city', code: 'port-vila_vu' } ],
 ['C_PAP', { icon: 'faBuilding', label: 'Port-au-Prince (all airports), Haiti', key: 'C_PAP', type: 'city', code: 'port-au-prince_ht' } ],
 ['C_POG', { icon: 'faBuilding', label: 'Port-Gentil (all airports), Gabon', key: 'C_POG', type: 'city', code: 'port-gentil_ga' } ],
 ['C_PWM', { icon: 'faBuilding', label: 'Portland (all airports), United States', key: 'C_PWM', type: 'city', code: 'portland_me_us' } ],
 ['C_PTJ', { icon: 'faBuilding', label: 'Portland (all airports), Australia', key: 'C_PTJ', type: 'city', code: 'portland_vi_au' } ],
 ['C_PDX', { icon: 'faBuilding', label: 'Portland (all airports), United States', key: 'C_PDX', type: 'city', code: 'portland_or_us' } ],
 ['C_OPO', { icon: 'faBuilding', label: 'Porto (all airports), Portugal', key: 'C_OPO', type: 'city', code: 'porto_pt' } ],
 ['C_POA', { icon: 'faBuilding', label: 'Porto Alegre (all airports), Brazil', key: 'C_POA', type: 'city', code: 'porto-alegre_rs_br' } ],
 ['C_BPS', { icon: 'faBuilding', label: 'Porto Seguro (all airports), Brazil', key: 'C_BPS', type: 'city', code: 'porto-seguro_ba_br' } ],
 ['C_PVH', { icon: 'faBuilding', label: 'Porto Velho (all airports), Brazil', key: 'C_PVH', type: 'city', code: 'porto-velho_ro_br' } ],
 ['C_PSM', { icon: 'faBuilding', label: 'Portsmouth (all airports), United States', key: 'C_PSM', type: 'city', code: 'portsmouth_ma_us' } ],
 ['C_WPR', { icon: 'faBuilding', label: 'Porvenir (all airports), Chile', key: 'C_WPR', type: 'city', code: 'porvenir_cl' } ],
 ['C_PSS', { icon: 'faBuilding', label: 'Posadas (all airports), Argentina', key: 'C_PSS', type: 'city', code: 'posadas_mi_ar' } ],
 ['C_PSJ', { icon: 'faBuilding', label: 'Poso (all airports), Indonesia', key: 'C_PSJ', type: 'city', code: 'poso_id' } ],
 ['C_YSO', { icon: 'faBuilding', label: 'Postville (all airports), Canada', key: 'C_YSO', type: 'city', code: 'postville_nl_ca' } ],
 ['C_POI', { icon: 'faBuilding', label: 'Potosí (all airports), Bolivia', key: 'C_POI', type: 'city', code: 'potosi_bo' } ],
 ['C_YPW', { icon: 'faBuilding', label: 'Powell River, British Columbia (all airports), Canada', key: 'C_YPW', type: 'city', code: 'powell-river_bc_ca' } ],
 ['C_PAZ', { icon: 'faBuilding', label: 'Poza Rica (all airports), Mexico', key: 'C_PAZ', type: 'city', code: 'poza-rica_mx' } ],
 ['C_POZ', { icon: 'faBuilding', label: 'Poznań (all airports), Poland', key: 'C_POZ', type: 'city', code: 'poznan_pl' } ],
 ['C_PRG', { icon: 'faBuilding', label: 'Prague (all airports), Czechia', key: 'C_PRG', type: 'city', code: 'prague_cz' } ],
 ['C_RAI', { icon: 'faBuilding', label: 'Praia (all airports), Cape Verde', key: 'C_RAI', type: 'city', code: 'praia_cv' } ],
 ['C_PRI', { icon: 'faBuilding', label: 'Praslin (all airports), Seychelles', key: 'C_PRI', type: 'city', code: 'praslin_sc' } ],
 ['C_LOP', { icon: 'faBuilding', label: 'Praya, Lombok (all airports), Indonesia', key: 'C_LOP', type: 'city', code: 'praya_id' } ],
 ['C_PRC', { icon: 'faBuilding', label: 'Prescott (all airports), United States', key: 'C_PRC', type: 'city', code: 'prescott_az_us' } ],
 ['C_PPB', { icon: 'faBuilding', label: 'Presidente Prudente, São Paulo (all airports), Brazil', key: 'C_PPB', type: 'city', code: 'presidente-prudente_sp_br' } ],
 ['C_PQI', { icon: 'faBuilding', label: 'Presque Isle (all airports), United States', key: 'C_PQI', type: 'city', code: 'presque-isle_me_us' } ],
 ['C_PRY', { icon: 'faBuilding', label: 'Pretoria (all airports), South Africa', key: 'C_PRY', type: 'city', code: 'pretoria_za' } ],
 ['C_PVK', { icon: 'faBuilding', label: 'Preveza (all airports), Greece', key: 'C_PVK', type: 'city', code: 'preveza_gr' } ],
 ['C_YXS', { icon: 'faBuilding', label: 'Prince George (all airports), Canada', key: 'C_YXS', type: 'city', code: 'prince-george_bc_ca' } ],
 ['C_YPR', { icon: 'faBuilding', label: 'Prince Rupert (all airports), Canada', key: 'C_YPR', type: 'city', code: 'prince-rupert_bc_ca' } ],
 ['C_PRN', { icon: 'faBuilding', label: 'Pristina (all airports), Kosovo', key: 'C_PRN', type: 'city', code: 'pristina_xk' } ],
 ['C_PPP', { icon: 'faBuilding', label: 'Proserpine (all airports), Australia', key: 'C_PPP', type: 'city', code: 'proserpine_ql_au' } ],
 ['C_PVD', { icon: 'faBuilding', label: 'Providence (all airports), United States', key: 'C_PVD', type: 'city', code: 'providence_ri_us' } ],
 ['C_PVA', { icon: 'faBuilding', label: 'Providencia Island (all airports), Colombia', key: 'C_PVA', type: 'city', code: 'providencia-island_co' } ],
 ['C_PLS', { icon: 'faBuilding', label: 'Providenciales (all airports), Turks & Caicos Islands', key: 'C_PLS', type: 'city', code: 'providenciales_tc' } ],
 ['C_PVC', { icon: 'faBuilding', label: 'Provincetown (all airports), United States', key: 'C_PVC', type: 'city', code: 'provincetown_ma_us' } ],
 ['C_PVU', { icon: 'faBuilding', label: 'Provo (all airports), United States', key: 'C_PVU', type: 'city', code: 'provo_ut_us' } ],
 ['C_SCC', { icon: 'faBuilding', label: 'Prudhoe Bay (all airports), United States', key: 'C_SCC', type: 'city', code: 'prudhoe-bay_ak_us' } ],
 ['C_PKV', { icon: 'faBuilding', label: 'Pskov (all airports), Russia', key: 'C_PKV', type: 'city', code: 'pskov_ru' } ],
 ['C_PCL', { icon: 'faBuilding', label: 'Pucallpa (all airports), Peru', key: 'C_PCL', type: 'city', code: 'pucallpa_pe' } ],
 ['C_PBC', { icon: 'faBuilding', label: 'Puebla (all airports), Mexico', key: 'C_PBC', type: 'city', code: 'puebla_mx' } ],
 ['C_PUB', { icon: 'faBuilding', label: 'Pueblo (all airports), United States', key: 'C_PUB', type: 'city', code: 'pueblo_co_us' } ],
 ['C_PUU', { icon: 'faBuilding', label: 'Puerto Asís (all airports), Colombia', key: 'C_PUU', type: 'city', code: 'puerto-asis_co' } ],
 ['C_PYH', { icon: 'faBuilding', label: 'Puerto Ayacucho (all airports), Venezuela', key: 'C_PYH', type: 'city', code: 'puerto-ayacucho_ve' } ],
 ['C_PBR', { icon: 'faBuilding', label: 'Puerto Barrios (all airports), Guatemala', key: 'C_PBR', type: 'city', code: 'puerto-barrios_gt' } ],
 ['C_PCR', { icon: 'faBuilding', label: 'Puerto Carreño (all airports), Colombia', key: 'C_PCR', type: 'city', code: 'puerto-carreno_co' } ],
 ['C_PXM', { icon: 'faBuilding', label: 'Puerto Escondido, Oaxaca (all airports), Mexico', key: 'C_PXM', type: 'city', code: 'puerto-escondido_mx' } ],
 ['C_OCC', { icon: 'faBuilding', label: 'Puerto Francisco de Orellana (all airports), Ecuador', key: 'C_OCC', type: 'city', code: 'puerto-francisco-de-orellana_ec' } ],
 ['C_IGR', { icon: 'faBuilding', label: 'Puerto Iguazú (all airports), Argentina', key: 'C_IGR', type: 'city', code: 'puerto-iguazu_mi_ar' } ],
 ['C_PJM', { icon: 'faBuilding', label: 'Puerto Jiménez (all airports), Costa Rica', key: 'C_PJM', type: 'city', code: 'puerto-jimenez_cr' } ],
 ['C_LQM', { icon: 'faBuilding', label: 'Puerto Leguízamo (all airports), Colombia', key: 'C_LQM', type: 'city', code: 'puerto-leguizamo_co' } ],
 ['C_PEU', { icon: 'faBuilding', label: 'Puerto Lempira (all airports), Honduras', key: 'C_PEU', type: 'city', code: 'puerto-lempira_hn' } ],
 ['C_LIO', { icon: 'faBuilding', label: 'Puerto Limón (all airports), Costa Rica', key: 'C_LIO', type: 'city', code: 'puerto-limon_cr' } ],
 ['C_PMY', { icon: 'faBuilding', label: 'Puerto Madryn (all airports), Argentina', key: 'C_PMY', type: 'city', code: 'puerto-madryn_cb_ar' } ],
 ['C_PEM', { icon: 'faBuilding', label: 'Puerto Maldonado (all airports), Peru', key: 'C_PEM', type: 'city', code: 'puerto-maldonado_pe' } ],
 ['C_PMC', { icon: 'faBuilding', label: 'Puerto Montt (all airports), Chile', key: 'C_PMC', type: 'city', code: 'puerto-montt_cl' } ],
 ['C_PNT', { icon: 'faBuilding', label: 'Puerto Natales (all airports), Chile', key: 'C_PNT', type: 'city', code: 'puerto-natales_cl' } ],
 ['C_PUE', { icon: 'faBuilding', label: 'Puerto Obaldía (all airports), Panama', key: 'C_PUE', type: 'city', code: 'puerto-obaldía_pa' } ],
 ['C_PZO', { icon: 'faBuilding', label: 'Puerto Ordaz and San Felix (all airports), Venezuela', key: 'C_PZO', type: 'city', code: 'puerto-ordaz-and-san-felix_ve' } ],
 ['C_POP', { icon: 'faBuilding', label: 'Puerto Plata (all airports), Dominican Republic', key: 'C_POP', type: 'city', code: 'puerto-plata_do' } ],
 ['C_PPS', { icon: 'faBuilding', label: 'Puerto Princesa (all airports), Philippines', key: 'C_PPS', type: 'city', code: 'puerto-princesa_ph' } ],
 ['C_PSZ', { icon: 'faBuilding', label: 'Puerto Suarez (all airports), Bolivia', key: 'C_PSZ', type: 'city', code: 'puerto-suarez_bo' } ],
 ['C_PVR', { icon: 'faBuilding', label: 'Puerto Vallarta (all airports), Mexico', key: 'C_PVR', type: 'city', code: 'puerto-vallarta_mx' } ],
 ['C_WPU', { icon: 'faBuilding', label: 'Puerto Williams (all airports), Chile', key: 'C_WPU', type: 'city', code: 'puerto-williams_cl' } ],
 ['C_PUY', { icon: 'faBuilding', label: 'Pula (all airports), Croatia', key: 'C_PUY', type: 'city', code: 'pula_hr' } ],
 ['C_PUW', { icon: 'faBuilding', label: 'Pullman (all airports), United States', key: 'C_PUW', type: 'city', code: 'pullman_wa_us' } ],
 ['C_PNQ', { icon: 'faBuilding', label: 'Pune (all airports), India', key: 'C_PNQ', type: 'city', code: 'pune_in' } ],
 ['C_PUQ', { icon: 'faBuilding', label: 'Punta Arenas (all airports), Chile', key: 'C_PUQ', type: 'city', code: 'punta-arenas_cl' } ],
 ['C_PUJ', { icon: 'faBuilding', label: 'Punta Cana (all airports), Dominican Republic', key: 'C_PUJ', type: 'city', code: 'punta-cana_do' } ],
 ['C_PDP', { icon: 'faBuilding', label: 'Punta del Este (all airports), Uruguay', key: 'C_PDP', type: 'city', code: 'punta-del-este_uy' } ],
 ['C_PGD', { icon: 'faBuilding', label: 'Punta Gorda (all airports), United States', key: 'C_PGD', type: 'city', code: 'punta-gorda_fl_us' } ],
 ['C_PND', { icon: 'faBuilding', label: 'Punta Gorda (all airports), Belize', key: 'C_PND', type: 'city', code: 'punta-gorda_bz' } ],
 ['C_PBU', { icon: 'faBuilding', label: 'Putao (all airports), Myanmar (Burma)', key: 'C_PBU', type: 'city', code: 'putao_mm' } ],
 ['C_PSU', { icon: 'faBuilding', label: 'Putussibau (all airports), Indonesia', key: 'C_PSU', type: 'city', code: 'putussibau_id' } ],
 ['C_YPX', { icon: 'faBuilding', label: 'Puvirnituq (all airports), Canada', key: 'C_YPX', type: 'city', code: 'puvirnituq_qc_ca' } ],
 ['C_FNJ', { icon: 'faBuilding', label: 'Pyongyang (all airports), North Korea', key: 'C_FNJ', type: 'city', code: 'pyongyang_kp' } ],
 ['C_JQA', { icon: 'faBuilding', label: 'Qaarsut (all airports), Greenland', key: 'C_JQA', type: 'city', code: 'qaarsut_gl' } ],
 ['C_GBB', { icon: 'faBuilding', label: 'Qabala (all airports), Azerbaijan', key: 'C_GBB', type: 'city', code: 'qabala_az' } ],
 ['C_AQI', { icon: 'faBuilding', label: 'Qaisumah (all airports), Saudi Arabia', key: 'C_AQI', type: 'city', code: 'qaisumah_sa' } ],
 ['C_KSQ', { icon: 'faBuilding', label: 'Qarshi (all airports), Uzbekistan', key: 'C_KSQ', type: 'city', code: 'qarshi_uz' } ],
 ['C_GSM', { icon: 'faBuilding', label: 'Qeshm (all airports), Iran', key: 'C_GSM', type: 'city', code: 'qeshm_ir' } ],
 ['C_JIQ', { icon: 'faBuilding', label: 'Qianjiang District (all airports), China', key: 'C_JIQ', type: 'city', code: 'qianjiang-district_cn' } ],
 ['C_IQM', { icon: 'faBuilding', label: 'Qiemo (all airports), China', key: 'C_IQM', type: 'city', code: 'qiemo_cn' } ],
 ['C_YVM', { icon: 'faBuilding', label: 'Qikiqtarjuaq (all airports), Canada', key: 'C_YVM', type: 'city', code: 'qikiqtarjuaq_nu_ca' } ],
 ['C_TAO', { icon: 'faBuilding', label: 'Qingdao (all airports), China', key: 'C_TAO', type: 'city', code: 'qingdao_cn' } ],
 ['C_IQN', { icon: 'faBuilding', label: 'Qingyang (all airports), China', key: 'C_IQN', type: 'city', code: 'qingyang_cn' } ],
 ['C_BPE', { icon: 'faBuilding', label: 'Qinhuangdao (all airports), China', key: 'C_BPE', type: 'city', code: 'qinhuangdao_cn' } ],
 ['C_BAR', { icon: 'faBuilding', label: 'Qionghai (all airports), China', key: 'C_BAR', type: 'city', code: 'qionghai_cn' } ],
 ['C_NDG', { icon: 'faBuilding', label: 'Qiqihar (all airports), China', key: 'C_NDG', type: 'city', code: 'qiqihar_cn' } ],
 ['C_JJN', { icon: 'faBuilding', label: 'Quanzhou (all airports), China', key: 'C_JJN', type: 'city', code: 'quanzhou_cn' } ],
 ['C_YQC', { icon: 'faBuilding', label: 'Quaqtaq (all airports), Canada', key: 'C_YQC', type: 'city', code: 'quaqtaq_qc_ca' } ],
 ['C_YQB', { icon: 'faBuilding', label: 'Quebec City (all airports), Canada', key: 'C_YQB', type: 'city', code: 'quebec-city_qc_ca' } ],
 ['C_ZQN', { icon: 'faBuilding', label: 'Queenstown (all airports), New Zealand', key: 'C_ZQN', type: 'city', code: 'queenstown_nz' } ],
 ['C_UEL', { icon: 'faBuilding', label: 'Quelimane (all airports), Mozambique', key: 'C_UEL', type: 'city', code: 'quelimane_mz' } ],
 ['C_XQP', { icon: 'faBuilding', label: 'Quepos (all airports), Costa Rica', key: 'C_XQP', type: 'city', code: 'quepos_cr' } ],
 ['C_YQZ', { icon: 'faBuilding', label: 'Quesnel (all airports), Canada', key: 'C_YQZ', type: 'city', code: 'quesnel_bc_ca' } ],
 ['C_UET', { icon: 'faBuilding', label: 'Quetta (all airports), Pakistan', key: 'C_UET', type: 'city', code: 'quetta_pk' } ],
 ['C_UIH', { icon: 'faBuilding', label: 'Qui Nhơn (all airports), Vietnam', key: 'C_UIH', type: 'city', code: 'qui-nhon_vn' } ],
 ['C_UIB', { icon: 'faBuilding', label: 'Quibdó (all airports), Colombia', key: 'C_UIB', type: 'city', code: 'quibdo_co' } ],
 ['C_ULP', { icon: 'faBuilding', label: 'Quilpie (all airports), Australia', key: 'C_ULP', type: 'city', code: 'quilpie_ql_au' } ],
 ['C_UIP', { icon: 'faBuilding', label: 'Quimper (all airports), France', key: 'C_UIP', type: 'city', code: 'quimper_fr' } ],
 ['C_UIN', { icon: 'faBuilding', label: 'Quincy (all airports), United States', key: 'C_UIN', type: 'city', code: 'quincy_il_us' } ],
 ['C_KWN', { icon: 'faBuilding', label: 'Quinhagak (all airports), United States', key: 'C_KWN', type: 'city', code: 'quinhagak_ak_us' } ],
 ['C_UIO', { icon: 'faBuilding', label: 'Quito (all airports), Ecuador', key: 'C_UIO', type: 'city', code: 'quito_ec' } ],
 ['C_URY', { icon: 'faBuilding', label: 'Qurayyat (all airports), Saudi Arabia', key: 'C_URY', type: 'city', code: 'qurayyat_sa' } ],
 ['C_KQT', { icon: 'faBuilding', label: 'Qurghonteppa (all airports), Tajikistan', key: 'C_KQT', type: 'city', code: 'qurghonteppa_tj' } ],
 ['C_JUZ', { icon: 'faBuilding', label: 'Quzhou (all airports), China', key: 'C_JUZ', type: 'city', code: 'quzhou_cn' } ],
 ['C_RBA', { icon: 'faBuilding', label: 'Rabat (all airports), Morocco', key: 'C_RBA', type: 'city', code: 'rabat_ma' } ],
 ['C_RAB', { icon: 'faBuilding', label: 'Rabaul (all airports), Papua New Guinea', key: 'C_RAB', type: 'city', code: 'rabaul_pg' } ],
 ['C_VKG', { icon: 'faBuilding', label: 'Rạch Giá (all airports), Vietnam', key: 'C_VKG', type: 'city', code: 'rach-gia_vn' } ],
 ['C_RAH', { icon: 'faBuilding', label: 'Rafha (all airports), Saudi Arabia', key: 'C_RAH', type: 'city', code: 'rafha_sa' } ],
 ['C_RAQ', { icon: 'faBuilding', label: 'Raha (all airports), Indonesia', key: 'C_RAQ', type: 'city', code: 'raha_id' } ],
 ['C_RYK', { icon: 'faBuilding', label: 'Rahim Yar Khan (all airports), Pakistan', key: 'C_RYK', type: 'city', code: 'rahim-yar-khan_pk' } ],
 ['C_RFP', { icon: 'faBuilding', label: 'Raiatea (all airports), French Polynesia', key: 'C_RFP', type: 'city', code: 'raiatea_pf' } ],
 ['C_RPR', { icon: 'faBuilding', label: 'Raipur (all airports), India', key: 'C_RPR', type: 'city', code: 'raipur_in' } ],
 ['C_RVV', { icon: 'faBuilding', label: 'Raivavae (all airports), French Polynesia', key: 'C_RVV', type: 'city', code: 'raivavae_pf' } ],
 ['C_RJA', { icon: 'faBuilding', label: 'Rajahmundry (all airports), India', key: 'C_RJA', type: 'city', code: 'rajahmundry_in' } ],
 ['C_RAJ', { icon: 'faBuilding', label: 'Rajkot (all airports), India', key: 'C_RAJ', type: 'city', code: 'rajkot_in' } ],
 ['C_RJH', { icon: 'faBuilding', label: 'Rajshahi (all airports), Bangladesh', key: 'C_RJH', type: 'city', code: 'rajshahi_bd' } ],
 ['C_RDU', { icon: 'faBuilding', label: 'Raleigh (all airports), United States', key: 'C_RDU', type: 'city', code: 'raleigh_nc_us' } ],
 ['C_RBV', { icon: 'faBuilding', label: 'Ramata Island (all airports), Solomon Islands', key: 'C_RBV', type: 'city', code: 'ramata-island_sb' } ],
 ['C_RMP', { icon: 'faBuilding', label: 'Rampart (all airports), United States', key: 'C_RMP', type: 'city', code: 'rampart_ak_us' } ],
 ['C_RZR', { icon: 'faBuilding', label: 'Ramsar, Mazandaran (all airports), Iran', key: 'C_RZR', type: 'city', code: 'ramsar_ir' } ],
 ['C_IXR', { icon: 'faBuilding', label: 'Ranchi (all airports), India', key: 'C_IXR', type: 'city', code: 'ranchi_in' } ],
 ['C_RGI', { icon: 'faBuilding', label: 'Rangiroa (all airports), French Polynesia', key: 'C_RGI', type: 'city', code: 'rangiroa_pf' } ],
 ['C_YRT', { icon: 'faBuilding', label: 'Rankin Inlet (all airports), Canada', key: 'C_YRT', type: 'city', code: 'rankin-inlet_nu_ca' } ],
 ['C_UNN', { icon: 'faBuilding', label: 'Ranong Province (all airports), Thailand', key: 'C_UNN', type: 'city', code: 'ranong-province_th' } ],
 ['C_RAP', { icon: 'faBuilding', label: 'Rapid City (all airports), United States', key: 'C_RAP', type: 'city', code: 'rapid-city_sd_us' } ],
 ['C_RAR', { icon: 'faBuilding', label: 'Rarotonga (all airports), Cook Islands', key: 'C_RAR', type: 'city', code: 'rarotonga_ck' } ],
 ['C_RKT', { icon: 'faBuilding', label: 'Ras al-Khaimah (all airports), United Arab Emirates', key: 'C_RKT', type: 'city', code: 'ras-al-khaimah_ae' } ],
 ['C_RAS', { icon: 'faBuilding', label: 'Rasht (all airports), Iran', key: 'C_RAS', type: 'city', code: 'rasht_ir' } ],
 ['C_ZRW', { icon: 'faBuilding', label: 'Rastatt (all airports), Germany', key: 'C_ZRW', type: 'city', code: 'rastatt_de' } ],
 ['C_RVT', { icon: 'faBuilding', label: 'Ravensthorpe (all airports), Australia', key: 'C_RVT', type: 'city', code: 'ravensthorpe_wa_au' } ],
 ['C_XRE', { icon: 'faBuilding', label: 'Reading (all airports), United Kingdom', key: 'C_XRE', type: 'city', code: 'reading_gb' } ],
 ['C_REC', { icon: 'faBuilding', label: 'Recife (all airports), Brazil', key: 'C_REC', type: 'city', code: 'recife_pe_br' } ],
 ['C_RCQ', { icon: 'faBuilding', label: 'Reconquista (all airports), Argentina', key: 'C_RCQ', type: 'city', code: 'reconquista_sf_ar' } ],
 ['C_YQF', { icon: 'faBuilding', label: 'Red Deer (all airports), Canada', key: 'C_YQF', type: 'city', code: 'red-deer_ab_ca' } ],
 ['C_RDB', { icon: 'faBuilding', label: 'Red Dog (all airports), United States', key: 'C_RDB', type: 'city', code: 'red-dog_ak_us' } ],
 ['C_YRL', { icon: 'faBuilding', label: 'Red Lake (all airports), Canada', key: 'C_YRL', type: 'city', code: 'red-lake_on_ca' } ],
 ['C_YRS', { icon: 'faBuilding', label: 'Red Sucker Lake (all airports), Canada', key: 'C_YRS', type: 'city', code: 'red-sucker-lake_mb_ca' } ],
 ['C_RDD', { icon: 'faBuilding', label: 'Redding (all airports), United States', key: 'C_RDD', type: 'city', code: 'redding_ca_us' } ],
 ['C_RDM', { icon: 'faBuilding', label: 'Redmond (all airports), United States', key: 'C_RDM', type: 'city', code: 'redmond_or_us' } ],
 ['C_ZPM', { icon: 'faBuilding', label: 'Regensburg (all airports), Germany', key: 'C_ZPM', type: 'city', code: 'regensburg_de' } ],
 ['C_REG', { icon: 'faBuilding', label: 'Reggio Calabria (all airports), Italy', key: 'C_REG', type: 'city', code: 'reggio-calabria_it' } ],
 ['C_ZRO', { icon: 'faBuilding', label: 'Reggio nell\'Emilia (all airports), Italy', key: 'C_ZRO', type: 'city', code: 'reggio-nell-emilia_it' } ],
 ['C_YQR', { icon: 'faBuilding', label: 'Regina (all airports), Canada', key: 'C_YQR', type: 'city', code: 'regina_sk_ca' } ],
 ['C_XIZ', { icon: 'faBuilding', label: 'Reims (all airports), France', key: 'C_XIZ', type: 'city', code: 'reims_fr' } ],
 ['C_RNL', { icon: 'faBuilding', label: 'Rennell Island (all airports), Solomon Islands', key: 'C_RNL', type: 'city', code: 'rennell-island_sb' } ],
 ['C_RNS', { icon: 'faBuilding', label: 'Rennes (all airports), France', key: 'C_RNS', type: 'city', code: 'rennes_fr' } ],
 ['C_RNO', { icon: 'faBuilding', label: 'Reno (all airports), United States', key: 'C_RNO', type: 'city', code: 'reno_nv_us' } ],
 ['C_RES', { icon: 'faBuilding', label: 'Resistencia (all airports), Argentina', key: 'C_RES', type: 'city', code: 'resistencia_ch_ar' } ],
 ['C_YRB', { icon: 'faBuilding', label: 'Resolute (all airports), Canada', key: 'C_YRB', type: 'city', code: 'resolute_nu_ca' } ],
 ['C_REU', { icon: 'faBuilding', label: 'Reus (all airports), Spain', key: 'C_REU', type: 'city', code: 'reus_es' } ],
 ['C_REK', { icon: 'faBuilding', label: 'Reykjavik (all airports), Iceland', key: 'C_REK', type: 'city', code: 'reykjavik_is' } ],
 ['C_REX', { icon: 'faBuilding', label: 'Reynosa (all airports), Mexico', key: 'C_REX', type: 'city', code: 'reynosa_mx' } ],
 ['C_RHI', { icon: 'faBuilding', label: 'Rhinelander (all airports), United States', key: 'C_RHI', type: 'city', code: 'rhinelander_wi_us' } ],
 ['C_RHO', { icon: 'faBuilding', label: 'Rhodes (all airports), Greece', key: 'C_RHO', type: 'city', code: 'rhodes_gr' } ],
 ['C_RAO', { icon: 'faBuilding', label: 'Ribeirão Preto (all airports), Brazil', key: 'C_RAO', type: 'city', code: 'ribeirao-preto_sp_br' } ],
 ['C_RIB', { icon: 'faBuilding', label: 'Riberalta (all airports), Bolivia', key: 'C_RIB', type: 'city', code: 'riberalta_bo' } ],
 ['C_RCB', { icon: 'faBuilding', label: 'Richards Bay (all airports), South Africa', key: 'C_RCB', type: 'city', code: 'richards-bay_za' } ],
 ['C_RIC', { icon: 'faBuilding', label: 'Richmond (all airports), United States', key: 'C_RIC', type: 'city', code: 'richmond_va_us' } ],
 ['C_RCM', { icon: 'faBuilding', label: 'Richmond (all airports), Australia', key: 'C_RCM', type: 'city', code: 'richmond_ql_au' } ],
 ['C_RIX', { icon: 'faBuilding', label: 'Riga (all airports), Latvia', key: 'C_RIX', type: 'city', code: 'riga_lv' } ],
 ['C_YRG', { icon: 'faBuilding', label: 'Rigolet (all airports), Canada', key: 'C_YRG', type: 'city', code: 'rigolet_nl_ca' } ],
 ['C_RJK', { icon: 'faBuilding', label: 'Rijeka (all airports), Croatia', key: 'C_RJK', type: 'city', code: 'rijeka_hr' } ],
 ['C_RMT', { icon: 'faBuilding', label: 'Rimatara (all airports), French Polynesia', key: 'C_RMT', type: 'city', code: 'rimatara_pf' } ],
 ['C_RMI', { icon: 'faBuilding', label: 'Rimini (all airports), Italy', key: 'C_RMI', type: 'city', code: 'rimini_it' } ],
 ['C_RBR', { icon: 'faBuilding', label: 'Rio Branco, Acre (all airports), Brazil', key: 'C_RBR', type: 'city', code: 'rio-branco_ac_br' } ],
 ['C_RCU', { icon: 'faBuilding', label: 'Río Cuarto (all airports), Argentina', key: 'C_RCU', type: 'city', code: 'rio-cuarto_cd_ar' } ],
 ['C_RIO', { icon: 'faBuilding', label: 'Rio de Janeiro (all airports), Brazil', key: 'C_RIO', type: 'city', code: 'rio-de-janeiro_rj_br' } ],
 ['C_RGL', { icon: 'faBuilding', label: 'Río Gallegos (all airports), Argentina', key: 'C_RGL', type: 'city', code: 'rio-gallegos_sc_ar' } ],
 ['C_RGA', { icon: 'faBuilding', label: 'Río Grande (all airports), Argentina', key: 'C_RGA', type: 'city', code: 'rio-grande_tf_ar' } ],
 ['C_RVD', { icon: 'faBuilding', label: 'Rio Verde, Goiás (all airports), Brazil', key: 'C_RVD', type: 'city', code: 'rio-verde_go_br' } ],
 ['C_RCH', { icon: 'faBuilding', label: 'Riohacha (all airports), Colombia', key: 'C_RCH', type: 'city', code: 'riohacha_co' } ],
 ['C_RIS', { icon: 'faBuilding', label: 'Rishiri (all airports), Japan', key: 'C_RIS', type: 'city', code: 'rishiri_jp' } ],
 ['C_ECI', { icon: 'faBuilding', label: 'Rivas (all airports), Nicaragua', key: 'C_ECI', type: 'city', code: 'rivas-department_ni' } ],
 ['C_RIW', { icon: 'faBuilding', label: 'Riverton (all airports), United States', key: 'C_RIW', type: 'city', code: 'riverton_wy_us' } ],
 ['C_RUH', { icon: 'faBuilding', label: 'Riyadh (all airports), Saudi Arabia', key: 'C_RUH', type: 'city', code: 'riyadh_sa' } ],
 ['C_RIY', { icon: 'faBuilding', label: 'Riyan (all airports), Yemen', key: 'C_RIY', type: 'city', code: 'riyan_ye' } ],
 ['C_RIZ', { icon: 'faBuilding', label: 'Rizhao (all airports), China', key: 'C_RIZ', type: 'city', code: 'rizhao_cn' } ],
 ['C_ROA', { icon: 'faBuilding', label: 'Roanoke (all airports), United States', key: 'C_ROA', type: 'city', code: 'roanoke_va_us' } ],
 ['C_RCE', { icon: 'faBuilding', label: 'Roche Harbor Grocery Store (all airports), United States', key: 'C_RCE', type: 'city', code: 'roche-harbor-grocery-store_wa_us' } ],
 ['C_ROC', { icon: 'faBuilding', label: 'Rochester (all airports), United States', key: 'C_ROC', type: 'city', code: 'rochester_ny_us' } ],
 ['C_RST', { icon: 'faBuilding', label: 'Rochester (all airports), United States', key: 'C_RST', type: 'city', code: 'rochester_mn_us' } ],
 ['C_RSD', { icon: 'faBuilding', label: 'Rock Sound (all airports), Bahamas', key: 'C_RSD', type: 'city', code: 'rock-sound_bs' } ],
 ['C_RKS', { icon: 'faBuilding', label: 'Rock Springs (all airports), United States', key: 'C_RKS', type: 'city', code: 'rock-springs_wy_us' } ],
 ['C_ROK', { icon: 'faBuilding', label: 'Rockhampton (all airports), Australia', key: 'C_ROK', type: 'city', code: 'rockhampton_ql_au' } ],
 ['C_RKD', { icon: 'faBuilding', label: 'Rockland (all airports), United States', key: 'C_RKD', type: 'city', code: 'rockland_me_us' } ],
 ['C_RDZ', { icon: 'faBuilding', label: 'Rodez (all airports), France', key: 'C_RDZ', type: 'city', code: 'rodez_fr' } ],
 ['C_RRG', { icon: 'faBuilding', label: 'Rodrigues (all airports), Mauritius', key: 'C_RRG', type: 'city', code: 'rodrigues_mu' } ],
 ['C_ROI', { icon: 'faBuilding', label: 'Roi Et Province (all airports), Thailand', key: 'C_ROI', type: 'city', code: 'roi-et-province_th' } ],
 ['C_RMA', { icon: 'faBuilding', label: 'Roma (all airports), Australia', key: 'C_RMA', type: 'city', code: 'roma_ql_au' } ],
 ['C_ROM', { icon: 'faBuilding', label: 'Rome (all airports), Italy', key: 'C_ROM', type: 'city', code: 'rome_it' } ],
 ['C_ROO', { icon: 'faBuilding', label: 'Rondonópolis (all airports), Brazil', key: 'C_ROO', type: 'city', code: 'rondonopolis_mt_br' } ],
 ['C_RNB', { icon: 'faBuilding', label: 'Ronneby (all airports), Sweden', key: 'C_RNB', type: 'city', code: 'ronneby_se' } ],
 ['C_NRR', { icon: 'faBuilding', label: 'Roosevelt Roads Naval Station (all airports), United States', key: 'C_NRR', type: 'city', code: 'roosevelt-roads_pr_us' } ],
 ['C_RRS', { icon: 'faBuilding', label: 'Røros (all airports), Norway', key: 'C_RRS', type: 'city', code: 'roros_no' } ],
 ['C_RVK', { icon: 'faBuilding', label: 'Rørvik (all airports), Norway', key: 'C_RVK', type: 'city', code: 'rorvik_no' } ],
 ['C_ROS', { icon: 'faBuilding', label: 'Rosario (all airports), Argentina', key: 'C_ROS', type: 'city', code: 'rosario_sf_ar' } ],
 ['C_RSS', { icon: 'faBuilding', label: 'Roseires (all airports), Sudan', key: 'C_RSS', type: 'city', code: 'er-roseires_sd' } ],
 ['C_RET', { icon: 'faBuilding', label: 'Røst (all airports), Norway', key: 'C_RET', type: 'city', code: 'rost_no' } ],
 ['C_RLG', { icon: 'faBuilding', label: 'Rostock (all airports), Germany', key: 'C_RLG', type: 'city', code: 'rostock_de' } ],
 ['C_ROV', { icon: 'faBuilding', label: 'Rostov-on-Don (all airports), Russia', key: 'C_ROV', type: 'city', code: 'rostov-on-don_ru' } ],
 ['C_ROW', { icon: 'faBuilding', label: 'Roswell (all airports), United States', key: 'C_ROW', type: 'city', code: 'roswell_nm_us' } ],
 ['C_ROP', { icon: 'faBuilding', label: 'Rota (all airports), United States', key: 'C_ROP', type: 'city', code: 'rota_mp' } ],
 ['C_RTI', { icon: 'faBuilding', label: 'Rote Island (all airports), Indonesia', key: 'C_RTI', type: 'city', code: 'rote-island_id' } ],
 ['C_ROT', { icon: 'faBuilding', label: 'Rotorua (all airports), New Zealand', key: 'C_ROT', type: 'city', code: 'rotorua_nz' } ],
 ['C_RTM', { icon: 'faBuilding', label: 'Rotterdam (all airports), Netherlands', key: 'C_RTM', type: 'city', code: 'rotterdam_nl' } ],
 ['C_RTA', { icon: 'faBuilding', label: 'Rotuma (all airports), Fiji', key: 'C_RTA', type: 'city', code: 'rotuma_fj' } ],
 ['C_URO', { icon: 'faBuilding', label: 'Rouen (all airports), France', key: 'C_URO', type: 'city', code: 'rouen_fr' } ],
 ['C_YUY', { icon: 'faBuilding', label: 'Rouyn-Noranda (all airports), Canada', key: 'C_YUY', type: 'city', code: 'rouyn-noranda_qc_ca' } ],
 ['C_RVN', { icon: 'faBuilding', label: 'Rovaniemi (all airports), Finland', key: 'C_RVN', type: 'city', code: 'rovaniemi_fi' } ],
 ['C_RXS', { icon: 'faBuilding', label: 'Roxas, Capiz (all airports), Philippines', key: 'C_RXS', type: 'city', code: 'roxas_ph' } ],
 ['C_RBY', { icon: 'faBuilding', label: 'Ruby (all airports), United States', key: 'C_RBY', type: 'city', code: 'ruby_ak_us' } ],
 ['C_XRG', { icon: 'faBuilding', label: 'Rugeley (all airports), United Kingdom', key: 'C_XRG', type: 'city', code: 'rugeley_gb' } ],
 ['C_XHD', { icon: 'faBuilding', label: 'Ruka (all airports), Finland', key: 'C_XHD', type: 'city', code: 'ruka_fi' } ],
 ['C_RCY', { icon: 'faBuilding', label: 'Rum Cay District (all airports), Bahamas', key: 'C_RCY', type: 'city', code: 'rum-cay-district_bs' } ],
 ['C_NDU', { icon: 'faBuilding', label: 'Rundu (all airports), Namibia', key: 'C_NDU', type: 'city', code: 'rundu_na' } ],
 ['C_RBQ', { icon: 'faBuilding', label: 'Rurrenabaque (all airports), Bolivia', key: 'C_RBQ', type: 'city', code: 'rurrenabaque_bo' } ],
 ['C_RUR', { icon: 'faBuilding', label: 'Rurutu (all airports), French Polynesia', key: 'C_RUR', type: 'city', code: 'rurutu_pf' } ],
 ['C_RSH', { icon: 'faBuilding', label: 'Russian Mission (all airports), United States', key: 'C_RSH', type: 'city', code: 'russian-mission_ak_us' } ],
 ['C_RTG', { icon: 'faBuilding', label: 'Ruteng (all airports), Indonesia', key: 'C_RTG', type: 'city', code: 'ruteng_id' } ],
 ['C_RUT', { icon: 'faBuilding', label: 'Rutland City (all airports), United States', key: 'C_RUT', type: 'city', code: 'rutland-city_vt_us' } ],
 ['C_RZE', { icon: 'faBuilding', label: 'Rzeszów (all airports), Poland', key: 'C_RZE', type: 'city', code: 'rzeszow_pl' } ],
 ['C_SCN', { icon: 'faBuilding', label: 'Saarbrücken (all airports), Germany', key: 'C_SCN', type: 'city', code: 'saarbrucken_de' } ],
 ['C_XGQ', { icon: 'faBuilding', label: 'Saariselka (all airports), Finland', key: 'C_XGQ', type: 'city', code: 'saariselka_fi' } ],
 ['C_ZKI', { icon: 'faBuilding', label: 'Saas-Fee (all airports), Switzerland', key: 'C_ZKI', type: 'city', code: 'saas-fee_ch' } ],
 ['C_SAB', { icon: 'faBuilding', label: 'Saba (all airports), Netherlands', key: 'C_SAB', type: 'city', code: 'saba_bq' } ],
 ['C_SBG', { icon: 'faBuilding', label: 'Sabang (all airports), Indonesia', key: 'C_SBG', type: 'city', code: 'sabang_id' } ],
 ['C_SEB', { icon: 'faBuilding', label: 'Sabha (all airports), Libya', key: 'C_SEB', type: 'city', code: 'sabha_ly' } ],
 ['C_ULX', { icon: 'faBuilding', label: 'Sabi Sand Game Reserve (all airports), South Africa', key: 'C_ULX', type: 'city', code: 'sabi-sand-game-reserve_za' } ],
 ['C_AFZ', { icon: 'faBuilding', label: 'Sabzevar (all airports), Iran', key: 'C_AFZ', type: 'city', code: 'sabzevar_ir' } ],
 ['C_ZPB', { icon: 'faBuilding', label: 'Sachigo Lake (all airports), Canada', key: 'C_ZPB', type: 'city', code: 'sachigo-lake_on_ca' } ],
 ['C_YSY', { icon: 'faBuilding', label: 'Sachs Harbour (all airports), Canada', key: 'C_YSY', type: 'city', code: 'sachs-harbour_nt_ca' } ],
 ['C_SAC', { icon: 'faBuilding', label: 'Sacramento (all airports), United States', key: 'C_SAC', type: 'city', code: 'sacramento_ca_us' } ],
 ['C_HSG', { icon: 'faBuilding', label: 'Saga (all airports), Japan', key: 'C_HSG', type: 'city', code: 'saga_jp' } ],
 ['C_MBS', { icon: 'faBuilding', label: 'Saginaw (all airports), United States', key: 'C_MBS', type: 'city', code: 'saginaw_mi_us' } ],
 ['C_YBG', { icon: 'faBuilding', label: 'Saguenay (all airports), Canada', key: 'C_YBG', type: 'city', code: 'saguenay_qc_ca' } ],
 ['C_SPD', { icon: 'faBuilding', label: 'Saidpur (all airports), Bangladesh', key: 'C_SPD', type: 'city', code: 'saidpur_bd' } ],
 ['C_SBH', { icon: 'faBuilding', label: 'Saint Barthélemy (all airports), St. Barthélemy', key: 'C_SBH', type: 'city', code: 'saint-barthelemy_bl' } ],
 ['C_STX', { icon: 'faBuilding', label: 'Saint Croix (all airports), United States', key: 'C_STX', type: 'city', code: 'saint-croix_vi_vi' } ],
 ['C_JER', { icon: 'faBuilding', label: 'Saint Helier (all airports), Jersey', key: 'C_JER', type: 'city', code: 'saint-helier_je' } ],
 ['C_YSJ', { icon: 'faBuilding', label: 'Saint John (all airports), Canada', key: 'C_YSJ', type: 'city', code: 'saint-john_nb_ca' } ],
 ['C_DOM', { icon: 'faBuilding', label: 'Saint Joseph Parish (all airports), Dominica', key: 'C_DOM', type: 'city', code: 'saint-joseph-parish_dm' } ],
 ['C_SKB', { icon: 'faBuilding', label: 'Saint Kitts (all airports), St. Kitts & Nevis', key: 'C_SKB', type: 'city', code: 'saint-kitts_kn' } ],
 ['C_SLU', { icon: 'faBuilding', label: 'Saint Lucia (all airports), St. Lucia', key: 'C_SLU', type: 'city', code: 'saint-lucia_lc' } ],
 ['C_SFG', { icon: 'faBuilding', label: 'Saint Martin (Island) (all airports), St. Martin', key: 'C_SFG', type: 'city', code: 'saint-martin_mf' } ],
 ['C_SNR', { icon: 'faBuilding', label: 'Saint Nazaire (all airports), France', key: 'C_SNR', type: 'city', code: 'saint-nazaire_fr' } ],
 ['C_GCI', { icon: 'faBuilding', label: 'Saint Peter Port (all airports), Guernsey', key: 'C_GCI', type: 'city', code: 'saint-peter-port_gg' } ],
 ['C_LED', { icon: 'faBuilding', label: 'Saint Petersburg (all airports), Russia', key: 'C_LED', type: 'city', code: 'saint-petersburg_ru' } ],
 ['C_STT', { icon: 'faBuilding', label: 'Saint Thomas (all airports), United States', key: 'C_STT', type: 'city', code: 'saint-thomas_vi_vi' } ],
 ['C_SVD', { icon: 'faBuilding', label: 'Saint Vincent (all airports), St. Vincent & Grenadines', key: 'C_SVD', type: 'city', code: 'saint-vincent_vc' } ],
 ['C_YIF', { icon: 'faBuilding', label: 'Saint-Augustin, Côte-Nord, Quebec (all airports), Canada', key: 'C_YIF', type: 'city', code: 'mirabel_qc_ca' } ],
 ['C_RUN', { icon: 'faBuilding', label: 'Saint-Denis (all airports), France', key: 'C_RUN', type: 'city', code: 'saint-denis_re' } ],
 ['C_EBU', { icon: 'faBuilding', label: 'Saint-Étienne (all airports), France', key: 'C_EBU', type: 'city', code: 'saint-etienne_fr' } ],
 ['C_ZSE', { icon: 'faBuilding', label: 'Saint-Pierre (all airports), France', key: 'C_ZSE', type: 'city', code: 'saint-pierre_re' } ],
 ['C_FSP', { icon: 'faBuilding', label: 'Saint-Pierre (all airports), St. Pierre & Miquelon', key: 'C_FSP', type: 'city', code: 'saint-pierre_pm' } ],
 ['C_SPN', { icon: 'faBuilding', label: 'Saipan (all airports), United States', key: 'C_SPN', type: 'city', code: 'saipan_mp' } ],
 ['C_SNO', { icon: 'faBuilding', label: 'Sakon Nakhon (all airports), Thailand', key: 'C_SNO', type: 'city', code: 'sakon-nakhon_th' } ],
 ['C_SID', { icon: 'faBuilding', label: 'Sal (all airports), Cape Verde', key: 'C_SID', type: 'city', code: 'sal_cv' } ],
 ['C_SLL', { icon: 'faBuilding', label: 'Salalah (all airports), Oman', key: 'C_SLL', type: 'city', code: 'salalah_om' } ],
 ['C_SLM', { icon: 'faBuilding', label: 'Salamanca (all airports), Spain', key: 'C_SLM', type: 'city', code: 'salamanca_es' } ],
 ['C_SLY', { icon: 'faBuilding', label: 'Salekhard (all airports), Russia', key: 'C_SLY', type: 'city', code: 'salekhard_ru' } ],
 ['C_SLN', { icon: 'faBuilding', label: 'Salina (all airports), United States', key: 'C_SLN', type: 'city', code: 'salina_ks_us' } ],
 ['C_SNC', { icon: 'faBuilding', label: 'Salinas (all airports), Ecuador', key: 'C_SNC', type: 'city', code: 'salinas_ec' } ],
 ['C_YSW', { icon: 'faBuilding', label: 'Salluit (all airports), Canada', key: 'C_YSW', type: 'city', code: 'salluit_qc_ca' } ],
 ['C_SLX', { icon: 'faBuilding', label: 'Salt Cay, Turks Islands (all airports), Turks & Caicos Islands', key: 'C_SLX', type: 'city', code: 'salt-cay_tc' } ],
 ['C_SLC', { icon: 'faBuilding', label: 'Salt Lake City (all airports), United States', key: 'C_SLC', type: 'city', code: 'salt-lake-city_ut_us' } ],
 ['C_SLA', { icon: 'faBuilding', label: 'Salta (all airports), Argentina', key: 'C_SLA', type: 'city', code: 'salta_sa_ar' } ],
 ['C_SLW', { icon: 'faBuilding', label: 'Saltillo (all airports), Mexico', key: 'C_SLW', type: 'city', code: 'saltillo_mx' } ],
 ['C_SSA', { icon: 'faBuilding', label: 'Salvador (all airports), Brazil', key: 'C_SSA', type: 'city', code: 'salvador_ba_br' } ],
 ['C_SZG', { icon: 'faBuilding', label: 'Salzburg (all airports), Austria', key: 'C_SZG', type: 'city', code: 'salzburg_at' } ],
 ['C_AZS', { icon: 'faBuilding', label: 'Samaná (all airports), Dominican Republic', key: 'C_AZS', type: 'city', code: 'samana_do' } ],
 ['C_KUF', { icon: 'faBuilding', label: 'Samara (all airports), Russia', key: 'C_KUF', type: 'city', code: 'samara_ru' } ],
 ['C_SRI', { icon: 'faBuilding', label: 'Samarinda (all airports), Indonesia', key: 'C_SRI', type: 'city', code: 'samarinda_id' } ],
 ['C_SKD', { icon: 'faBuilding', label: 'Samarkand (all airports), Uzbekistan', key: 'C_SKD', type: 'city', code: 'samarkand_uz' } ],
 ['C_SVB', { icon: 'faBuilding', label: 'Sambava (all airports), Madagascar', key: 'C_SVB', type: 'city', code: 'sambava_mg' } ],
 ['C_UAS', { icon: 'faBuilding', label: 'Samburu County (all airports), Kenya', key: 'C_UAS', type: 'city', code: 'samburu-county_ke' } ],
 ['C_SMI', { icon: 'faBuilding', label: 'Samos (all airports), Greece', key: 'C_SMI', type: 'city', code: 'samos_gr' } ],
 ['C_SMQ', { icon: 'faBuilding', label: 'Sampit (all airports), Indonesia', key: 'C_SMQ', type: 'city', code: 'sampit_id' } ],
 ['C_SZF', { icon: 'faBuilding', label: 'Samsun (all airports), Turkey', key: 'C_SZF', type: 'city', code: 'samsun_tr' } ],
 ['C_ADZ', { icon: 'faBuilding', label: 'San Andrés (all airports), Colombia', key: 'C_ADZ', type: 'city', code: 'san-andres_co' } ],
 ['C_SJT', { icon: 'faBuilding', label: 'San Angelo (all airports), United States', key: 'C_SJT', type: 'city', code: 'san-angelo_tx_us' } ],
 ['C_SAT', { icon: 'faBuilding', label: 'San Antonio (all airports), United States', key: 'C_SAT', type: 'city', code: 'san-antonio_tx_us' } ],
 ['C_SCY', { icon: 'faBuilding', label: 'San Cristóbal Island (all airports), Ecuador', key: 'C_SCY', type: 'city', code: 'san-cristobal-island_ec' } ],
 ['C_SAN', { icon: 'faBuilding', label: 'San Diego (all airports), United States', key: 'C_SAN', type: 'city', code: 'san-diego_ca_us' } ],
 ['C_SFD', { icon: 'faBuilding', label: 'San Fernando de Apure (all airports), Venezuela', key: 'C_SFD', type: 'city', code: 'san-fernando-de-apure_ve' } ],
 ['C_CTC', { icon: 'faBuilding', label: 'San Fernando del Valle de Catamarca (all airports), Argentina', key: 'C_CTC', type: 'city', code: 'san-fernando-del-valle-de-catamarca_ca_ar' } ],
 ['C_SFO', { icon: 'faBuilding', label: 'San Francisco (all airports), United States', key: 'C_SFO', type: 'city', code: 'san-francisco_ca_us' } ],
 ['C_SJC', { icon: 'faBuilding', label: 'San Jose (all airports), United States', key: 'C_SJC', type: 'city', code: 'san-jose_ca_us' } ],
 ['C_SJO', { icon: 'faBuilding', label: 'San José (all airports), Costa Rica', key: 'C_SJO', type: 'city', code: 'san-jose_cr' } ],
 ['C_SJD', { icon: 'faBuilding', label: 'San José del Cabo (all airports), Mexico', key: 'C_SJD', type: 'city', code: 'san-jose-del-cabo_mx' } ],
 ['C_SJE', { icon: 'faBuilding', label: 'San José del Guaviare (all airports), Colombia', key: 'C_SJE', type: 'city', code: 'san-jose-del-guaviare_co' } ],
 ['C_SJI', { icon: 'faBuilding', label: 'San Jose, Occidental Mindoro (all airports), Philippines', key: 'C_SJI', type: 'city', code: 'san-jose_ph' } ],
 ['C_UAQ', { icon: 'faBuilding', label: 'San Juan (all airports), Argentina', key: 'C_UAQ', type: 'city', code: 'san-juan_sj_ar' } ],
 ['C_SJU', { icon: 'faBuilding', label: 'San Juan (all airports), United States', key: 'C_SJU', type: 'city', code: 'san-juan_pr_pr' } ],
 ['C_LUQ', { icon: 'faBuilding', label: 'San Luis (all airports), Argentina', key: 'C_LUQ', type: 'city', code: 'san-luis_sl_ar' } ],
 ['C_SBP', { icon: 'faBuilding', label: 'San Luis Obispo (all airports), United States', key: 'C_SBP', type: 'city', code: 'san-luis-obispo_ca_us' } ],
 ['C_SLP', { icon: 'faBuilding', label: 'San Luis Potosí (all airports), Mexico', key: 'C_SLP', type: 'city', code: 'san-luis-potosi_mx' } ],
 ['C_CPC', { icon: 'faBuilding', label: 'San Martín de los Andes (all airports), Argentina', key: 'C_CPC', type: 'city', code: 'san-martin-de-los-andes_ne_ar' } ],
 ['C_TUC', { icon: 'faBuilding', label: 'San Miguel de Tucumán (all airports), Argentina', key: 'C_TUC', type: 'city', code: 'san-miguel-de-tucuman_tu_ar' } ],
 ['C_ZRC', { icon: 'faBuilding', label: 'San Pedro de Alcantara (all airports), Spain', key: 'C_ZRC', type: 'city', code: 'san-pedro-de-alcantara_es' } ],
 ['C_SAP', { icon: 'faBuilding', label: 'San Pedro Sula (all airports), Honduras', key: 'C_SAP', type: 'city', code: 'san-pedro-sula_hn' } ],
 ['C_SPR', { icon: 'faBuilding', label: 'San Pedro Town (all airports), Belize', key: 'C_SPR', type: 'city', code: 'san-pedro-town_bz' } ],
 ['C_SAL', { icon: 'faBuilding', label: 'San Salvador (all airports), El Salvador', key: 'C_SAL', type: 'city', code: 'san-salvador_sv' } ],
 ['C_JUJ', { icon: 'faBuilding', label: 'San Salvador de Jujuy (all airports), Argentina', key: 'C_JUJ', type: 'city', code: 'san-salvador-de-jujuy_pj_ar' } ],
 ['C_ZSA', { icon: 'faBuilding', label: 'San Salvador Island (all airports), Bahamas', key: 'C_ZSA', type: 'city', code: 'san-salvador-island_bs' } ],
 ['C_GMZ', { icon: 'faBuilding', label: 'San Sebastián de La Gomera (all airports), Spain', key: 'C_GMZ', type: 'city', code: 'san-sebastian-de-la-gomera_es' } ],
 ['C_SOM', { icon: 'faBuilding', label: 'San Tomé (all airports), Venezuela', key: 'C_SOM', type: 'city', code: 'san-tome_ve' } ],
 ['C_SVI', { icon: 'faBuilding', label: 'San Vicente Del Caguan (all airports), Colombia', key: 'C_SVI', type: 'city', code: 'san-vicente-del-caguan_co' } ],
 ['C_SPY', { icon: 'faBuilding', label: 'San-Pédro, Ivory Coast (all airports), Côte d’Ivoire', key: 'C_SPY', type: 'city', code: 'san-pedro_ci' } ],
 ['C_SAH', { icon: 'faBuilding', label: 'Sana\'a (all airports), Yemen', key: 'C_SAH', type: 'city', code: 'sana-a_ye' } ],
 ['C_SDG', { icon: 'faBuilding', label: 'Sanandij (all airports), Iran', key: 'C_SDG', type: 'city', code: 'sanandij_ir' } ],
 ['C_SDP', { icon: 'faBuilding', label: 'Sand Point (all airports), United States', key: 'C_SDP', type: 'city', code: 'sand-point_ak_us' } ],
 ['C_SDK', { icon: 'faBuilding', label: 'Sandakan (all airports), Malaysia', key: 'C_SDK', type: 'city', code: 'sandakan_my' } ],
 ['C_SDN', { icon: 'faBuilding', label: 'Sandane (all airports), Norway', key: 'C_SDN', type: 'city', code: 'sandane_no' } ],
 ['C_NDY', { icon: 'faBuilding', label: 'Sanday, Orkney (all airports), United Kingdom', key: 'C_NDY', type: 'city', code: 'sanday_gb' } ],
 ['C_SSJ', { icon: 'faBuilding', label: 'Sandnessjøen (all airports), Norway', key: 'C_SSJ', type: 'city', code: 'sandnessjoen_no' } ],
 ['C_YZP', { icon: 'faBuilding', label: 'Sandspit (all airports), Canada', key: 'C_YZP', type: 'city', code: 'sandspit_bc_ca' } ],
 ['C_ZSJ', { icon: 'faBuilding', label: 'Sandy Lake (all airports), Canada', key: 'C_ZSJ', type: 'city', code: 'sandy-lake_on_ca' } ],
 ['C_YSK', { icon: 'faBuilding', label: 'Sanikiluaq (all airports), Canada', key: 'C_YSK', type: 'city', code: 'sanikiluaq_nu_ca' } ],
 ['C_SFQ', { icon: 'faBuilding', label: 'Şanlıurfa (all airports), Turkey', key: 'C_SFQ', type: 'city', code: 'sanliurfa_tr' } ],
 ['C_SQJ', { icon: 'faBuilding', label: 'Sanming (all airports), China', key: 'C_SQJ', type: 'city', code: 'sanming_cn' } ],
 ['C_SNA', { icon: 'faBuilding', label: 'Santa Ana (all airports), United States', key: 'C_SNA', type: 'city', code: 'santa-ana_ca_us' } ],
 ['C_NNB', { icon: 'faBuilding', label: 'Santa Ana Island (all airports), Solomon Islands', key: 'C_NNB', type: 'city', code: 'santa-ana-island_sb' } ],
 ['C_SBA', { icon: 'faBuilding', label: 'Santa Barbara (all airports), United States', key: 'C_SBA', type: 'city', code: 'santa-barbara_ca_us' } ],
 ['C_SNU', { icon: 'faBuilding', label: 'Santa Clara (all airports), Cuba', key: 'C_SNU', type: 'city', code: 'santa-clara_cu' } ],
 ['C_SPC', { icon: 'faBuilding', label: 'Santa Cruz de La Palma (all airports), Spain', key: 'C_SPC', type: 'city', code: 'santa-cruz-de-la-palma_es' } ],
 ['C_SRZ', { icon: 'faBuilding', label: 'Santa Cruz de la Sierra (all airports), Bolivia', key: 'C_SRZ', type: 'city', code: 'santa-cruz-de-la-sierra_bo' } ],
 ['C_SCZ', { icon: 'faBuilding', label: 'Santa Cruz Islands (all airports), Solomon Islands', key: 'C_SCZ', type: 'city', code: 'santa-cruz-island_sb' } ],
 ['C_SAF', { icon: 'faBuilding', label: 'Santa Fe (all airports), United States', key: 'C_SAF', type: 'city', code: 'santa-fe_nm_us' } ],
 ['C_SFN', { icon: 'faBuilding', label: 'Santa Fe (all airports), Argentina', key: 'C_SFN', type: 'city', code: 'santa-fe_sf_ar' } ],
 ['C_RIA', { icon: 'faBuilding', label: 'Santa Maria (all airports), Brazil', key: 'C_RIA', type: 'city', code: 'santa-maria_rs_br' } ],
 ['C_SMX', { icon: 'faBuilding', label: 'Santa Maria (all airports), United States', key: 'C_SMX', type: 'city', code: 'santa-maria_ca_us' } ],
 ['C_SMG', { icon: 'faBuilding', label: 'Santa Maria (all airports), Peru', key: 'C_SMG', type: 'city', code: 'santa-maria_pe' } ],
 ['C_SMA', { icon: 'faBuilding', label: 'Santa Maria Island (all airports), Portugal', key: 'C_SMA', type: 'city', code: 'santa-maria-island_pt' } ],
 ['C_SMR', { icon: 'faBuilding', label: 'Santa Marta (all airports), Colombia', key: 'C_SMR', type: 'city', code: 'santa-marta_co' } ],
 ['C_STS', { icon: 'faBuilding', label: 'Santa Rosa (all airports), United States', key: 'C_STS', type: 'city', code: 'santa-rosa_ca_us' } ],
 ['C_RSA', { icon: 'faBuilding', label: 'Santa Rosa (all airports), Argentina', key: 'C_RSA', type: 'city', code: 'santa-rosa_lp_ar' } ],
 ['C_ETR', { icon: 'faBuilding', label: 'Santa Rosa Canton (all airports), Ecuador', key: 'C_ETR', type: 'city', code: 'santa-rosa-canton_ec' } ],
 ['C_SDR', { icon: 'faBuilding', label: 'Santander (all airports), Spain', key: 'C_SDR', type: 'city', code: 'santander_es' } ],
 ['C_STM', { icon: 'faBuilding', label: 'Santarém (all airports), Brazil', key: 'C_STM', type: 'city', code: 'santarem_pa_br' } ],
 ['C_SCL', { icon: 'faBuilding', label: 'Santiago de Chile (all airports), Chile', key: 'C_SCL', type: 'city', code: 'santiago_cl' } ],
 ['C_SCQ', { icon: 'faBuilding', label: 'Santiago de Compostela (all airports), Spain', key: 'C_SCQ', type: 'city', code: 'santiago-de-compostela_es' } ],
 ['C_SCU', { icon: 'faBuilding', label: 'Santiago de Cuba (all airports), Cuba', key: 'C_SCU', type: 'city', code: 'santiago-de_cu' } ],
 ['C_STI', { icon: 'faBuilding', label: 'Santiago de los Caballeros (all airports), Dominican Republic', key: 'C_STI', type: 'city', code: 'santiago-de-los-caballeros_do' } ],
 ['C_QRO', { icon: 'faBuilding', label: 'Santiago de Querétaro (all airports), Mexico', key: 'C_QRO', type: 'city', code: 'santiago-de-queretaro_mx' } ],
 ['C_SDE', { icon: 'faBuilding', label: 'Santiago del Estero (all airports), Argentina', key: 'C_SDE', type: 'city', code: 'santiago-del-estero_se_ar' } ],
 ['C_GEL', { icon: 'faBuilding', label: 'Santo Ângelo (all airports), Brazil', key: 'C_GEL', type: 'city', code: 'santo-angelo_rs_br' } ],
 ['C_STD', { icon: 'faBuilding', label: 'Santo Domingo (all airports), Venezuela', key: 'C_STD', type: 'city', code: 'santo-domingo_ve' } ],
 ['C_SDQ', { icon: 'faBuilding', label: 'Santo Domingo (all airports), Dominican Republic', key: 'C_SDQ', type: 'city', code: 'santo-domingo_do' } ],
 ['C_JTR', { icon: 'faBuilding', label: 'Santorini (all airports), Greece', key: 'C_JTR', type: 'city', code: 'santorini_gr' } ],
 ['C_SYX', { icon: 'faBuilding', label: 'Sanya (all airports), China', key: 'C_SYX', type: 'city', code: 'sanya_cn' } ],
 ['C_SFL', { icon: 'faBuilding', label: 'São Filipe (all airports), Cape Verde', key: 'C_SFL', type: 'city', code: 'sao-filipe_cv' } ],
 ['C_SJZ', { icon: 'faBuilding', label: 'São Jorge Island (all airports), Portugal', key: 'C_SJZ', type: 'city', code: 'sao-jorge-island_pt' } ],
 ['C_SJP', { icon: 'faBuilding', label: 'São José do Rio Preto (all airports), Brazil', key: 'C_SJP', type: 'city', code: 'sao-jose-do-rio-preto_sp_br' } ],
 ['C_SLZ', { icon: 'faBuilding', label: 'São Luís (all airports), Brazil', key: 'C_SLZ', type: 'city', code: 'sao-luis_ma_br' } ],
 ['C_SNE', { icon: 'faBuilding', label: 'São Nicolau (all airports), Cape Verde', key: 'C_SNE', type: 'city', code: 'sao-nicolau_cv' } ],
 ['C_SAO', { icon: 'faBuilding', label: 'São Paulo (all airports), Brazil', key: 'C_SAO', type: 'city', code: 'sao-paulo_sp_br' } ],
 ['C_TMS', { icon: 'faBuilding', label: 'São Tomé (all airports), São Tomé & Príncipe', key: 'C_TMS', type: 'city', code: 'sao-tome_st' } ],
 ['C_VXE', { icon: 'faBuilding', label: 'São Vicente (all airports), Cape Verde', key: 'C_VXE', type: 'city', code: 'sao-vicente_cv' } ],
 ['C_SPK', { icon: 'faBuilding', label: 'Sapporo (all airports), Japan', key: 'C_SPK', type: 'city', code: 'sapporo_jp' } ],
 ['C_SSR', { icon: 'faBuilding', label: 'Sara (all airports), Vanuatu', key: 'C_SSR', type: 'city', code: 'sara_vu' } ],
 ['C_SJJ', { icon: 'faBuilding', label: 'Sarajevo (all airports), Bosnia & Herzegovina', key: 'C_SJJ', type: 'city', code: 'sarajevo_ba' } ],
 ['C_SLK', { icon: 'faBuilding', label: 'Saranac Lake (all airports), United States', key: 'C_SLK', type: 'city', code: 'saranac-lake_ny_us' } ],
 ['C_SKX', { icon: 'faBuilding', label: 'Saransk (all airports), Russia', key: 'C_SKX', type: 'city', code: 'saransk_ru' } ],
 ['C_SRQ', { icon: 'faBuilding', label: 'Sarasota (all airports), United States', key: 'C_SRQ', type: 'city', code: 'sarasota_fl_us' } ],
 ['C_RTW', { icon: 'faBuilding', label: 'Saratov (all airports), Russia', key: 'C_RTW', type: 'city', code: 'saratov_ru' } ],
 ['C_RVE', { icon: 'faBuilding', label: 'Saravena (all airports), Colombia', key: 'C_RVE', type: 'city', code: 'saravena_co' } ],
 ['C_SRY', { icon: 'faBuilding', label: 'Sari (all airports), Iran', key: 'C_SRY', type: 'city', code: 'sari_ir' } ],
 ['C_YZR', { icon: 'faBuilding', label: 'Sarnia (all airports), Canada', key: 'C_YZR', type: 'city', code: 'sarnia_on_ca' } ],
 ['C_YXE', { icon: 'faBuilding', label: 'Saskatoon (all airports), Canada', key: 'C_YXE', type: 'city', code: 'saskatoon_sk_ca' } ],
 ['C_SYS', { icon: 'faBuilding', label: 'Saskylakh (all airports), Russia', key: 'C_SYS', type: 'city', code: 'saskylakh_ru' } ],
 ['C_SUJ', { icon: 'faBuilding', label: 'Satu Mare (all airports), Romania', key: 'C_SUJ', type: 'city', code: 'satu-mare_ro' } ],
 ['C_SSM', { icon: 'faBuilding', label: 'Sault Sainte Marie (all airports), United States', key: 'C_SSM', type: 'city', code: 'sault-ste-marie_mi_us' } ],
 ['C_YAM', { icon: 'faBuilding', label: 'Sault Ste. Marie (all airports), Canada', key: 'C_YAM', type: 'city', code: 'sault-ste-marie_on_ca' } ],
 ['C_SXK', { icon: 'faBuilding', label: 'Saumlaki (all airports), Indonesia', key: 'C_SXK', type: 'city', code: 'saumlaki_id' } ],
 ['C_VHC', { icon: 'faBuilding', label: 'Saurimo (all airports), Angola', key: 'C_VHC', type: 'city', code: 'saurimo_ao' } ],
 ['C_SAV', { icon: 'faBuilding', label: 'Savannah (all airports), United States', key: 'C_SAV', type: 'city', code: 'savannah_ga_us' } ],
 ['C_ZVK', { icon: 'faBuilding', label: 'Savannakhet (all airports), Laos', key: 'C_ZVK', type: 'city', code: 'savannakhet_la' } ],
 ['C_SVL', { icon: 'faBuilding', label: 'Savonlinna (all airports), Finland', key: 'C_SVL', type: 'city', code: 'savonlinna_fi' } ],
 ['C_SVA', { icon: 'faBuilding', label: 'Savoonga (all airports), United States', key: 'C_SVA', type: 'city', code: 'savoonga_ak_us' } ],
 ['C_SVU', { icon: 'faBuilding', label: 'Savusavu (all airports), Fiji', key: 'C_SVU', type: 'city', code: 'savusavu_fj' } ],
 ['C_SCM', { icon: 'faBuilding', label: 'Scammon Bay (all airports), United States', key: 'C_SCM', type: 'city', code: 'scammon-bay_ak_us' } ],
 ['C_YKL', { icon: 'faBuilding', label: 'Schefferville (all airports), Canada', key: 'C_YKL', type: 'city', code: 'schefferville_qc_ca' } ],
 ['C_SZW', { icon: 'faBuilding', label: 'Schwerin (all airports), Germany', key: 'C_SZW', type: 'city', code: 'schwerin_de' } ],
 ['C_BFF', { icon: 'faBuilding', label: 'Scottsbluff (all airports), United States', key: 'C_BFF', type: 'city', code: 'scottsbluff_ne_us' } ],
 ['C_SEA', { icon: 'faBuilding', label: 'Seattle (all airports), United States', key: 'C_SEA', type: 'city', code: 'seattle_wa_us' } ],
 ['C_XOW', { icon: 'faBuilding', label: 'Seefeld (all airports), Austria', key: 'C_XOW', type: 'city', code: 'seefeld_at' } ],
 ['C_EGM', { icon: 'faBuilding', label: 'Seghe (all airports), Solomon Islands', key: 'C_EGM', type: 'city', code: 'seghe_sb' } ],
 ['C_XOU', { icon: 'faBuilding', label: 'Segovia (all airports), Spain', key: 'C_XOU', type: 'city', code: 'segovia_es' } ],
 ['C_GXF', { icon: 'faBuilding', label: 'Seiyun (all airports), Yemen', key: 'C_GXF', type: 'city', code: 'seiyun_ye' } ],
 ['C_TKD', { icon: 'faBuilding', label: 'Sekondi-Takoradi (all airports), Ghana', key: 'C_TKD', type: 'city', code: 'sekondi-takoradi_gh' } ],
 ['C_WLK', { icon: 'faBuilding', label: 'Selawik (all airports), United States', key: 'C_WLK', type: 'city', code: 'selawik_ak_us' } ],
 ['C_SRG', { icon: 'faBuilding', label: 'Semarang (all airports), Indonesia', key: 'C_SRG', type: 'city', code: 'semarang_id' } ],
 ['C_SZE', { icon: 'faBuilding', label: 'Semera (all airports), Ethiopia', key: 'C_SZE', type: 'city', code: 'semera_et' } ],
 ['C_PLX', { icon: 'faBuilding', label: 'Semey (all airports), Kazakhstan', key: 'C_PLX', type: 'city', code: 'semey_kz' } ],
 ['C_SDJ', { icon: 'faBuilding', label: 'Sendai (all airports), Japan', key: 'C_SDJ', type: 'city', code: 'sendai_jp' } ],
 ['C_SEL', { icon: 'faBuilding', label: 'Seoul (all airports), South Korea', key: 'C_SEL', type: 'city', code: 'seoul_kr' } ],
 ['C_YZV', { icon: 'faBuilding', label: 'Sept-Îles (all airports), Canada', key: 'C_YZV', type: 'city', code: 'sept-iles_qc_ca' } ],
 ['C_SEU', { icon: 'faBuilding', label: 'Seronera (all airports), Tanzania', key: 'C_SEU', type: 'city', code: 'seronera_tz' } ],
 ['C_QSF', { icon: 'faBuilding', label: 'Sétif (all airports), Algeria', key: 'C_QSF', type: 'city', code: 'setif_dz' } ],
 ['C_SVQ', { icon: 'faBuilding', label: 'Seville (all airports), Spain', key: 'C_SVQ', type: 'city', code: 'seville_es' } ],
 ['C_SFA', { icon: 'faBuilding', label: 'Sfax (all airports), Tunisia', key: 'C_SFA', type: 'city', code: 'sfax_tn' } ],
 ['C_SHX', { icon: 'faBuilding', label: 'Shageluk (all airports), United States', key: 'C_SHX', type: 'city', code: 'shageluk_ak_us' } ],
 ['C_CQD', { icon: 'faBuilding', label: 'Shahrekord (all airports), Iran', key: 'C_CQD', type: 'city', code: 'shahrekord_ir' } ],
 ['C_EKS', { icon: 'faBuilding', label: 'Shakhtyorsk (all airports), Russia', key: 'C_EKS', type: 'city', code: 'shakhtyorsk_ru' } ],
 ['C_SKK', { icon: 'faBuilding', label: 'Shaktoolik (all airports), United States', key: 'C_SKK', type: 'city', code: 'shaktoolik_ak_us' } ],
 ['C_ZTM', { icon: 'faBuilding', label: 'Shamattawa First Nation (all airports), Canada', key: 'C_ZTM', type: 'city', code: 'shamattawa_mb_ca' } ],
 ['C_SHA', { icon: 'faBuilding', label: 'Shanghai (all airports), China', key: 'C_SHA', type: 'city', code: 'shanghai_cn' } ],
 ['C_SQD', { icon: 'faBuilding', label: 'Shangrao (all airports), China', key: 'C_SQD', type: 'city', code: 'shangrao_cn' } ],
 ['C_SNN', { icon: 'faBuilding', label: 'Shannon, County Clare (all airports), Ireland', key: 'C_SNN', type: 'city', code: 'shannon_ie' } ],
 ['C_SWA', { icon: 'faBuilding', label: 'Shantou (all airports), China', key: 'C_SWA', type: 'city', code: 'shantou_cn' } ],
 ['C_WGN', { icon: 'faBuilding', label: 'Shaoyang (all airports), China', key: 'C_WGN', type: 'city', code: 'shaoyang_cn' } ],
 ['C_SSH', { icon: 'faBuilding', label: 'Sharm El Sheikh (all airports), Egypt', key: 'C_SSH', type: 'city', code: 'sharm-el-sheikh_eg' } ],
 ['C_SHW', { icon: 'faBuilding', label: 'Sharurah (all airports), Saudi Arabia', key: 'C_SHW', type: 'city', code: 'sharurah_sa' } ],
 ['C_SZD', { icon: 'faBuilding', label: 'Sheffield (all airports), United Kingdom', key: 'C_SZD', type: 'city', code: 'sheffield_gb' } ],
 ['C_HPG', { icon: 'faBuilding', label: 'Shennongjia (all airports), China', key: 'C_HPG', type: 'city', code: 'shennongjia_cn' } ],
 ['C_SHE', { icon: 'faBuilding', label: 'Shenyang (all airports), China', key: 'C_SHE', type: 'city', code: 'shenyang_cn' } ],
 ['C_SZX', { icon: 'faBuilding', label: 'Shenzhen (all airports), China', key: 'C_SZX', type: 'city', code: 'shenzhen_cn' } ],
 ['C_SDZ', { icon: 'faBuilding', label: 'Shetland Islands (all airports), United Kingdom', key: 'C_SDZ', type: 'city', code: 'shetland_gb' } ],
 ['C_RKZ', { icon: 'faBuilding', label: 'Shigatse (all airports), China', key: 'C_RKZ', type: 'city', code: 'shigatse_cn' } ],
 ['C_SHF', { icon: 'faBuilding', label: 'Shihezi (all airports), China', key: 'C_SHF', type: 'city', code: 'shihezi_cn' } ],
 ['C_SJW', { icon: 'faBuilding', label: 'Shijiazhuang (all airports), China', key: 'C_SJW', type: 'city', code: 'shijiazhuang_cn' } ],
 ['C_SHL', { icon: 'faBuilding', label: 'Shillong (all airports), India', key: 'C_SHL', type: 'city', code: 'shillong_in' } ],
 ['C_SHM', { icon: 'faBuilding', label: 'Shirahama (all airports), Japan', key: 'C_SHM', type: 'city', code: 'shirahama_jp' } ],
 ['C_SYZ', { icon: 'faBuilding', label: 'Shiraz (all airports), Iran', key: 'C_SYZ', type: 'city', code: 'shiraz_ir' } ],
 ['C_SHC', { icon: 'faBuilding', label: 'Shire (all airports), Ethiopia', key: 'C_SHC', type: 'city', code: 'shire_et' } ],
 ['C_XCH', { icon: 'faBuilding', label: 'Shire of (all airports), Christmas Island', key: 'C_XCH', type: 'city', code: 'shire-of_cx' } ],
 ['C_SHH', { icon: 'faBuilding', label: 'Shishmaref (all airports), United States', key: 'C_SHH', type: 'city', code: 'shishmaref_ak_us' } ],
 ['C_WDS', { icon: 'faBuilding', label: 'Shiyan (all airports), China', key: 'C_WDS', type: 'city', code: 'shiyan_cn' } ],
 ['C_FSZ', { icon: 'faBuilding', label: 'Shizuoka (all airports), Japan', key: 'C_FSZ', type: 'city', code: 'shizuoka_jp' } ],
 ['C_SYO', { icon: 'faBuilding', label: 'Shonai (all airports), Japan', key: 'C_SYO', type: 'city', code: 'shonai_jp' } ],
 ['C_SHV', { icon: 'faBuilding', label: 'Shreveport (all airports), United States', key: 'C_SHV', type: 'city', code: 'shreveport_la_us' } ],
 ['C_SHG', { icon: 'faBuilding', label: 'Shungnak (all airports), United States', key: 'C_SHG', type: 'city', code: 'shungnak_ak_us' } ],
 ['C_CIT', { icon: 'faBuilding', label: 'Shymkent (all airports), Kazakhstan', key: 'C_CIT', type: 'city', code: 'shymkent_kz' } ],
 ['C_SKT', { icon: 'faBuilding', label: 'Sialkot (all airports), Pakistan', key: 'C_SKT', type: 'city', code: 'sialkot_pk' } ],
 ['C_SBZ', { icon: 'faBuilding', label: 'Sibiu (all airports), Romania', key: 'C_SBZ', type: 'city', code: 'sibiu_ro' } ],
 ['C_FLZ', { icon: 'faBuilding', label: 'Sibolga (all airports), Indonesia', key: 'C_FLZ', type: 'city', code: 'sibolga_id' } ],
 ['C_DTB', { icon: 'faBuilding', label: 'Siborong-Borong (all airports), Indonesia', key: 'C_DTB', type: 'city', code: 'siborong-borong_id' } ],
 ['C_SBW', { icon: 'faBuilding', label: 'Sibu (all airports), Malaysia', key: 'C_SBW', type: 'city', code: 'sibu_my' } ],
 ['C_BWA', { icon: 'faBuilding', label: 'Siddharthanagar (all airports), Nepal', key: 'C_BWA', type: 'city', code: 'siddharthanagar_np' } ],
 ['C_SDY', { icon: 'faBuilding', label: 'Sidney (all airports), United States', key: 'C_SDY', type: 'city', code: 'sidney_mt_us' } ],
 ['C_ZPY', { icon: 'faBuilding', label: 'Siegburg (all airports), Germany', key: 'C_ZPY', type: 'city', code: 'siegburg_de' } ],
 ['C_REP', { icon: 'faBuilding', label: 'Siem Reap (all airports), Cambodia', key: 'C_REP', type: 'city', code: 'siem-reap_kh' } ],
 ['C_GIU', { icon: 'faBuilding', label: 'Sigiriya (all airports), Sri Lanka', key: 'C_GIU', type: 'city', code: 'sigiriya_lk' } ],
 ['C_KOS', { icon: 'faBuilding', label: 'Sihanoukville Province (all airports), Cambodia', key: 'C_KOS', type: 'city', code: 'sihanoukville-province_kh' } ],
 ['C_SXZ', { icon: 'faBuilding', label: 'Siirt (all airports), Turkey', key: 'C_SXZ', type: 'city', code: 'siirt_tr' } ],
 ['C_IXS', { icon: 'faBuilding', label: 'Silchar (all airports), India', key: 'C_IXS', type: 'city', code: 'silchar_in' } ],
 ['C_SYM', { icon: 'faBuilding', label: 'Simao District (all airports), China', key: 'C_SYM', type: 'city', code: 'simao-district_cn' } ],
 ['C_SIF', { icon: 'faBuilding', label: 'Simara, Bara (all airports), Nepal', key: 'C_SIF', type: 'city', code: 'simara--bara_np' } ],
 ['C_SIP', { icon: 'faBuilding', label: 'Simferopol (all airports), Unknown Region', key: 'C_SIP', type: 'city', code: 'simferopol_zz' } ],
 ['C_SIN', { icon: 'faBuilding', label: 'Singapore (all airports), Singapore', key: 'C_SIN', type: 'city', code: 'singapore_sg' } ],
 ['C_NOP', { icon: 'faBuilding', label: 'Sinop (all airports), Turkey', key: 'C_NOP', type: 'city', code: 'sinop_tr' } ],
 ['C_OPS', { icon: 'faBuilding', label: 'Sinop, Mato Grosso (all airports), Brazil', key: 'C_OPS', type: 'city', code: 'sinop_mt_br' } ],
 ['C_EUX', { icon: 'faBuilding', label: 'Sint Eustatius (all airports), Netherlands', key: 'C_EUX', type: 'city', code: 'sint-eustatius_bq' } ],
 ['C_SQG', { icon: 'faBuilding', label: 'Sintang Regency (all airports), Indonesia', key: 'C_SQG', type: 'city', code: 'sintang-regency_id' } ],
 ['C_SIR', { icon: 'faBuilding', label: 'Sion (all airports), Switzerland', key: 'C_SIR', type: 'city', code: 'sion_ch' } ],
 ['C_SUX', { icon: 'faBuilding', label: 'Sioux City (all airports), United States', key: 'C_SUX', type: 'city', code: 'sioux-city_ia_us' } ],
 ['C_FSD', { icon: 'faBuilding', label: 'Sioux Falls (all airports), United States', key: 'C_FSD', type: 'city', code: 'sioux-falls_sd_us' } ],
 ['C_YXL', { icon: 'faBuilding', label: 'Sioux Lookout (all airports), Canada', key: 'C_YXL', type: 'city', code: 'sioux-lookout_on_ca' } ],
 ['C_JHS', { icon: 'faBuilding', label: 'Sisimiut (all airports), Greenland', key: 'C_JHS', type: 'city', code: 'sisimiut_gl' } ],
 ['C_JSH', { icon: 'faBuilding', label: 'Sitia (all airports), Greece', key: 'C_JSH', type: 'city', code: 'sitia_gr' } ],
 ['C_SIT', { icon: 'faBuilding', label: 'Sitka (all airports), United States', key: 'C_SIT', type: 'city', code: 'sitka_ak_us' } ],
 ['C_AKY', { icon: 'faBuilding', label: 'Sittwe (all airports), Myanmar (Burma)', key: 'C_AKY', type: 'city', code: 'sittwe_mm' } ],
 ['C_VAS', { icon: 'faBuilding', label: 'Sivas (all airports), Turkey', key: 'C_VAS', type: 'city', code: 'sivas_tr' } ],
 ['C_NKT', { icon: 'faBuilding', label: 'Şırnak (all airports), Turkey', key: 'C_NKT', type: 'city', code: 'sirnak_tr' } ],
 ['C_SGY', { icon: 'faBuilding', label: 'Skagway (all airports), United States', key: 'C_SGY', type: 'city', code: 'skagway_ak_us' } ],
 ['C_KDU', { icon: 'faBuilding', label: 'Skardu (all airports), Unknown Region', key: 'C_KDU', type: 'city', code: 'skardu_zz' } ],
 ['C_SFT', { icon: 'faBuilding', label: 'Skellefteå (all airports), Sweden', key: 'C_SFT', type: 'city', code: 'skelleftea_se' } ],
 ['C_JSI', { icon: 'faBuilding', label: 'Skiathos (all airports), Greece', key: 'C_JSI', type: 'city', code: 'skiathos_gr' } ],
 ['C_SKP', { icon: 'faBuilding', label: 'Skopje (all airports), Macedonia (FYROM)', key: 'C_SKP', type: 'city', code: 'skopje_mk' } ],
 ['C_SZK', { icon: 'faBuilding', label: 'Skukuza (all airports), South Africa', key: 'C_SZK', type: 'city', code: 'skukuza_za' } ],
 ['C_SKU', { icon: 'faBuilding', label: 'Skyros (all airports), Greece', key: 'C_SKU', type: 'city', code: 'skyros_gr' } ],
 ['C_SLQ', { icon: 'faBuilding', label: 'Sleetmute (all airports), United States', key: 'C_SLQ', type: 'city', code: 'sleetmute_ak_us' } ],
 ['C_null', { icon: 'faBuilding', label: 'Sligo (all airports), Ireland', key: 'C_null', type: 'city', code: 'sligo_ie' } ],
 ['C_YYD', { icon: 'faBuilding', label: 'Smithers (all airports), Canada', key: 'C_YYD', type: 'city', code: 'smithers_bc_ca' } ],
 ['C_AER', { icon: 'faBuilding', label: 'Sochi (all airports), Russia', key: 'C_AER', type: 'city', code: 'sochi_ru' } ],
 ['C_SCT', { icon: 'faBuilding', label: 'Socotra (all airports), Yemen', key: 'C_SCT', type: 'city', code: 'socotra_ye' } ],
 ['C_XOO', { icon: 'faBuilding', label: 'Soelden (all airports), Austria', key: 'C_XOO', type: 'city', code: 'soelden_at' } ],
 ['C_SOF', { icon: 'faBuilding', label: 'Sofia (all airports), Bulgaria', key: 'C_SOF', type: 'city', code: 'sofia_bg' } ],
 ['C_SOG', { icon: 'faBuilding', label: 'Sogndal (all airports), Norway', key: 'C_SOG', type: 'city', code: 'sogndal_no' } ],
 ['C_HMB', { icon: 'faBuilding', label: 'Sohag (all airports), Egypt', key: 'C_HMB', type: 'city', code: 'sohag_eg' } ],
 ['C_OHS', { icon: 'faBuilding', label: 'Sohar (all airports), Oman', key: 'C_OHS', type: 'city', code: 'sohar_om' } ],
 ['C_SKO', { icon: 'faBuilding', label: 'Sokoto (all airports), Nigeria', key: 'C_SKO', type: 'city', code: 'sokoto_ng' } ],
 ['C_SLH', { icon: 'faBuilding', label: 'Sola (all airports), Vanuatu', key: 'C_SLH', type: 'city', code: 'sola_vu' } ],
 ['C_CSH', { icon: 'faBuilding', label: 'Solovetsky, Arkhangelsk Oblast (all airports), Russia', key: 'C_CSH', type: 'city', code: 'solovetsky_ru' } ],
 ['C_SLI', { icon: 'faBuilding', label: 'Solwezi (all airports), Zambia', key: 'C_SLI', type: 'city', code: 'solwezi_zm' } ],
 ['C_SGD', { icon: 'faBuilding', label: 'Sønderborg (all airports), Denmark', key: 'C_SGD', type: 'city', code: 'sonderborg_dk' } ],
 ['C_YSQ', { icon: 'faBuilding', label: 'Songyuan (all airports), China', key: 'C_YSQ', type: 'city', code: 'songyuan_cn' } ],
 ['C_XJO', { icon: 'faBuilding', label: 'Soria (all airports), Spain', key: 'C_XJO', type: 'city', code: 'soria_es' } ],
 ['C_SOJ', { icon: 'faBuilding', label: 'Sørkjosen (all airports), Norway', key: 'C_SOJ', type: 'city', code: 'sorkjosen_no' } ],
 ['C_SOQ', { icon: 'faBuilding', label: 'Sorong (all airports), Indonesia', key: 'C_SOQ', type: 'city', code: 'sorong_id' } ],
 ['C_SMT', { icon: 'faBuilding', label: 'Sorriso (all airports), Brazil', key: 'C_SMT', type: 'city', code: 'sorriso_br' } ],
 ['C_FAE', { icon: 'faBuilding', label: 'Sørvágur (all airports), Faroe Islands', key: 'C_FAE', type: 'city', code: 'sorvagur_fo' } ],
 ['C_XQS', { icon: 'faBuilding', label: 'Sotkamo (all airports), Finland', key: 'C_XQS', type: 'city', code: 'sotkamo_fi' } ],
 ['C_SBN', { icon: 'faBuilding', label: 'South Bend (all airports), United States', key: 'C_SBN', type: 'city', code: 'south-bend_in_us' } ],
 ['C_XSC', { icon: 'faBuilding', label: 'South Caicos (all airports), Turks & Caicos Islands', key: 'C_XSC', type: 'city', code: 'south-caicos_tc' } ],
 ['C_XSI', { icon: 'faBuilding', label: 'South Indian Lake (all airports), Canada', key: 'C_XSI', type: 'city', code: 'south-indian-lake_mb_ca' } ],
 ['C_WSN', { icon: 'faBuilding', label: 'South Naknek (all airports), United States', key: 'C_WSN', type: 'city', code: 'south-naknek_ak_us' } ],
 ['C_SWJ', { icon: 'faBuilding', label: 'South West Bay (all airports), Vanuatu', key: 'C_SWJ', type: 'city', code: 'south-west-bay_vu' } ],
 ['C_SOU', { icon: 'faBuilding', label: 'Southampton (all airports), United Kingdom', key: 'C_SOU', type: 'city', code: 'southampton_gb' } ],
 ['C_OVS', { icon: 'faBuilding', label: 'Sovetsky (all airports), Russia', key: 'C_OVS', type: 'city', code: 'sovetsky_ru' } ],
 ['C_SZA', { icon: 'faBuilding', label: 'Soyo (all airports), Angola', key: 'C_SZA', type: 'city', code: 'soyo_ao' } ],
 ['C_SPU', { icon: 'faBuilding', label: 'Split (all airports), Croatia', key: 'C_SPU', type: 'city', code: 'split_hr' } ],
 ['C_GEG', { icon: 'faBuilding', label: 'Spokane (all airports), United States', key: 'C_GEG', type: 'city', code: 'spokane_wa_us' } ],
 ['C_AXP', { icon: 'faBuilding', label: 'Spring Point (all airports), Bahamas', key: 'C_AXP', type: 'city', code: 'spring-point_bs' } ],
 ['C_SGF', { icon: 'faBuilding', label: 'Springfield (all airports), United States', key: 'C_SGF', type: 'city', code: 'springfield_mo_us' } ],
 ['C_SPI', { icon: 'faBuilding', label: 'Springfield (all airports), United States', key: 'C_SPI', type: 'city', code: 'springfield_il_us' } ],
 ['C_SEK', { icon: 'faBuilding', label: 'Srednekolymsk (all airports), Russia', key: 'C_SEK', type: 'city', code: 'srednekolymsk_ru' } ],
 ['C_DWO', { icon: 'faBuilding', label: 'Sri Jayawardenepura Kotte (all airports), Sri Lanka', key: 'C_DWO', type: 'city', code: 'sri-jayawardenepura-kotte_lk' } ],
 ['C_SXR', { icon: 'faBuilding', label: 'Srinagar (all airports), Unknown Region', key: 'C_SXR', type: 'city', code: 'srinagar_zz' } ],
 ['C_ANT', { icon: 'faBuilding', label: 'St Anton (all airports), Austria', key: 'C_ANT', type: 'city', code: 'st-anton_at' } ],
 ['C_SGO', { icon: 'faBuilding', label: 'St George (all airports), Australia', key: 'C_SGO', type: 'city', code: 'st-george_ql_au' } ],
 ['C_GND', { icon: 'faBuilding', label: 'St George\'s (all airports), Grenada', key: 'C_GND', type: 'city', code: 'st-georges_gd' } ],
 ['C_QXK', { icon: 'faBuilding', label: 'St-Genis (all airports), France', key: 'C_QXK', type: 'city', code: 'st-genis_fr' } ],
 ['C_YAY', { icon: 'faBuilding', label: 'St. Anthony (all airports), Canada', key: 'C_YAY', type: 'city', code: 'st-anthony_nl_ca' } ],
 ['C_UST', { icon: 'faBuilding', label: 'St. Augustine (all airports), United States', key: 'C_UST', type: 'city', code: 'st-augustine_fl_us' } ],
 ['C_STC', { icon: 'faBuilding', label: 'St. Cloud (all airports), United States', key: 'C_STC', type: 'city', code: 'st-cloud_mn_us' } ],
 ['C_SGU', { icon: 'faBuilding', label: 'St. George (all airports), United States', key: 'C_SGU', type: 'city', code: 'st-george_ut_us' } ],
 ['C_STG', { icon: 'faBuilding', label: 'St. George (all airports), United States', key: 'C_STG', type: 'city', code: 'st-george_ak_us' } ],
 ['C_YYT', { icon: 'faBuilding', label: 'St. John s (all airports), Canada', key: 'C_YYT', type: 'city', code: 'st-johns_nl_ca' } ],
 ['C_STL', { icon: 'faBuilding', label: 'St. Louis (all airports), United States', key: 'C_STL', type: 'city', code: 'st-louis_mo_us' } ],
 ['C_KSM', { icon: 'faBuilding', label: 'St. Mary s (all airports), United States', key: 'C_KSM', type: 'city', code: 'st-marys_ak_us' } ],
 ['C_SMK', { icon: 'faBuilding', label: 'St. Michael (all airports), United States', key: 'C_SMK', type: 'city', code: 'st-michael_ak_us' } ],
 ['C_SNP', { icon: 'faBuilding', label: 'St. Paul (all airports), United States', key: 'C_SNP', type: 'city', code: 'st-paul_ak_us' } ],
 ['C_YST', { icon: 'faBuilding', label: 'St. Theresa Point First Nation (all airports), Canada', key: 'C_YST', type: 'city', code: 'st-theresa-point_mb_ca' } ],
 ['C_SCE', { icon: 'faBuilding', label: 'State College (all airports), United States', key: 'C_SCE', type: 'city', code: 'state-college_pa_us' } ],
 ['C_SHD', { icon: 'faBuilding', label: 'Staunton, Virginia (all airports), United States', key: 'C_SHD', type: 'city', code: 'staunton_va_us' } ],
 ['C_SVG', { icon: 'faBuilding', label: 'Stavanger (all airports), Norway', key: 'C_SVG', type: 'city', code: 'stavanger_no' } ],
 ['C_STW', { icon: 'faBuilding', label: 'Stavropol (all airports), Russia', key: 'C_STW', type: 'city', code: 'stavropol_ru' } ],
 ['C_WBB', { icon: 'faBuilding', label: 'Stebbins (all airports), United States', key: 'C_WBB', type: 'city', code: 'stebbins_ak_us' } ],
 ['C_SML', { icon: 'faBuilding', label: 'Stella Maris (all airports), Bahamas', key: 'C_SML', type: 'city', code: 'stella-maris_bs' } ],
 ['C_YJT', { icon: 'faBuilding', label: 'Stephenville (all airports), Canada', key: 'C_YJT', type: 'city', code: 'stephenville_nl_ca' } ],
 ['C_SWO', { icon: 'faBuilding', label: 'Stillwater (all airports), United States', key: 'C_SWO', type: 'city', code: 'stillwater_ok_us' } ],
 ['C_STO', { icon: 'faBuilding', label: 'Stockholm (all airports), Sweden', key: 'C_STO', type: 'city', code: 'stockholm_se' } ],
 ['C_SCK', { icon: 'faBuilding', label: 'Stockton (all airports), United States', key: 'C_SCK', type: 'city', code: 'stockton_ca_us' } ],
 ['C_SKN', { icon: 'faBuilding', label: 'Stokmarknes (all airports), Norway', key: 'C_SKN', type: 'city', code: 'stokmarknes_no' } ],
 ['C_SRV', { icon: 'faBuilding', label: 'Stony River (all airports), United States', key: 'C_SRV', type: 'city', code: 'stony-river_ak_us' } ],
 ['C_SRP', { icon: 'faBuilding', label: 'Stord (all airports), Norway', key: 'C_SRP', type: 'city', code: 'stord_no' } ],
 ['C_SYY', { icon: 'faBuilding', label: 'Stornoway (all airports), United Kingdom', key: 'C_SYY', type: 'city', code: 'stornoway_gb' } ],
 ['C_MVL', { icon: 'faBuilding', label: 'Stowe (all airports), United States', key: 'C_MVL', type: 'city', code: 'stowe_vt_us' } ],
 ['C_SXB', { icon: 'faBuilding', label: 'Strasbourg (all airports), France', key: 'C_SXB', type: 'city', code: 'strasbourg_fr' } ],
 ['C_SOY', { icon: 'faBuilding', label: 'Stronsay (all airports), United Kingdom', key: 'C_SOY', type: 'city', code: 'stronsay_gb' } ],
 ['C_QWQ', { icon: 'faBuilding', label: 'Struer (all airports), Denmark', key: 'C_QWQ', type: 'city', code: 'struer_dk' } ],
 ['C_SWT', { icon: 'faBuilding', label: 'Strzhewoi (all airports), Russia', key: 'C_SWT', type: 'city', code: 'strezhevoy_ru' } ],
 ['C_STR', { icon: 'faBuilding', label: 'Stuttgart (all airports), Germany', key: 'C_STR', type: 'city', code: 'stuttgart_de' } ],
 ['C_VAO', { icon: 'faBuilding', label: 'Suavanao (all airports), Solomon Islands', key: 'C_VAO', type: 'city', code: 'suavanao_sb' } ],
 ['C_SCV', { icon: 'faBuilding', label: 'Suceava (all airports), Romania', key: 'C_SCV', type: 'city', code: 'suceava_ro' } ],
 ['C_SRE', { icon: 'faBuilding', label: 'Sucre (all airports), Bolivia', key: 'C_SRE', type: 'city', code: 'sucre_bo' } ],
 ['C_THS', { icon: 'faBuilding', label: 'Sukhothai Province (all airports), Thailand', key: 'C_THS', type: 'city', code: 'sukhothai-province_th' } ],
 ['C_SKZ', { icon: 'faBuilding', label: 'Sukkur (all airports), Pakistan', key: 'C_SKZ', type: 'city', code: 'sukkur_pk' } ],
 ['C_ISU', { icon: 'faBuilding', label: 'Sulaymaniyah (all airports), Iraq', key: 'C_ISU', type: 'city', code: 'sulaymaniyah_iq' } ],
 ['C_QYQ', { icon: 'faBuilding', label: 'Sulsted (all airports), Denmark', key: 'C_QYQ', type: 'city', code: 'sulsted_dk' } ],
 ['C_SWQ', { icon: 'faBuilding', label: 'Sumbawa Besar (all airports), Indonesia', key: 'C_SWQ', type: 'city', code: 'sumbawa-besar_id' } ],
 ['C_NTY', { icon: 'faBuilding', label: 'Sun City (all airports), South Africa', key: 'C_NTY', type: 'city', code: 'sun-city_za' } ],
 ['C_SUN', { icon: 'faBuilding', label: 'Sun Valley (all airports), United States', key: 'C_SUN', type: 'city', code: 'sun-valley_id_us' } ],
 ['C_SDL', { icon: 'faBuilding', label: 'Sundsvall (all airports), Sweden', key: 'C_SDL', type: 'city', code: 'sundsvall_se' } ],
 ['C_MCY', { icon: 'faBuilding', label: 'Sunshine Coast Region (all airports), Australia', key: 'C_MCY', type: 'city', code: 'sunshine-coast-region_ql_au' } ],
 ['C_SUY', { icon: 'faBuilding', label: 'Suntar (all airports), Russia', key: 'C_SUY', type: 'city', code: 'suntar_ru' } ],
 ['C_SUB', { icon: 'faBuilding', label: 'Surabaya (all airports), Indonesia', key: 'C_SUB', type: 'city', code: 'surabaya_id' } ],
 ['C_SOC', { icon: 'faBuilding', label: 'Surakarta (all airports), Indonesia', key: 'C_SOC', type: 'city', code: 'surakarta_id' } ],
 ['C_STV', { icon: 'faBuilding', label: 'Surat (all airports), India', key: 'C_STV', type: 'city', code: 'surat_in' } ],
 ['C_URT', { icon: 'faBuilding', label: 'Surat Thani Province (all airports), Thailand', key: 'C_URT', type: 'city', code: 'surat-thani-province_th' } ],
 ['C_SGC', { icon: 'faBuilding', label: 'Surgut (all airports), Russia', key: 'C_SGC', type: 'city', code: 'surgut_ru' } ],
 ['C_SUG', { icon: 'faBuilding', label: 'Surigao City (all airports), Philippines', key: 'C_SUG', type: 'city', code: 'surigao-city_ph' } ],
 ['C_SKH', { icon: 'faBuilding', label: 'Surkhet (all airports), Nepal', key: 'C_SKH', type: 'city', code: 'surkhet_np' } ],
 ['C_SUV', { icon: 'faBuilding', label: 'Suva (all airports), Fiji', key: 'C_SUV', type: 'city', code: 'suva_fj' } ],
 ['C_EVG', { icon: 'faBuilding', label: 'Sveg (all airports), Sweden', key: 'C_EVG', type: 'city', code: 'sveg_se' } ],
 ['C_SVJ', { icon: 'faBuilding', label: 'Svolvær (all airports), Norway', key: 'C_SVJ', type: 'city', code: 'svolvaer_no' } ],
 ['C_YQY', { icon: 'faBuilding', label: 'Sydney (all airports), Canada', key: 'C_YQY', type: 'city', code: 'sydney_ns_ca' } ],
 ['C_SYD', { icon: 'faBuilding', label: 'Sydney (all airports), Australia', key: 'C_SYD', type: 'city', code: 'sydney_ns_au' } ],
 ['C_SCW', { icon: 'faBuilding', label: 'Syktyvkar (all airports), Russia', key: 'C_SCW', type: 'city', code: 'syktyvkar_ru' } ],
 ['C_ZYL', { icon: 'faBuilding', label: 'Sylhet (all airports), Bangladesh', key: 'C_ZYL', type: 'city', code: 'sylhet_bd' } ],
 ['C_SYR', { icon: 'faBuilding', label: 'Syracuse (all airports), United States', key: 'C_SYR', type: 'city', code: 'syracuse_ny_us' } ],
 ['C_JSY', { icon: 'faBuilding', label: 'Syros (all airports), Greece', key: 'C_JSY', type: 'city', code: 'syros_gr' } ],
 ['C_SZZ', { icon: 'faBuilding', label: 'Szczecin (all airports), Poland', key: 'C_SZZ', type: 'city', code: 'szczecin_pl' } ],
 ['C_SZY', { icon: 'faBuilding', label: 'Szymany, Szczytno County (all airports), Poland', key: 'C_SZY', type: 'city', code: 'szymany_pl' } ],
 ['C_TIF', { icon: 'faBuilding', label: 'Ta if (all airports), Saudi Arabia', key: 'C_TIF', type: 'city', code: 'taif_sa' } ],
 ['C_TCX', { icon: 'faBuilding', label: 'Tabas (all airports), Iran', key: 'C_TCX', type: 'city', code: 'tabas_ir' } ],
 ['C_TBT', { icon: 'faBuilding', label: 'Tabatinga (all airports), Brazil', key: 'C_TBT', type: 'city', code: 'tabatinga_am_br' } ],
 ['C_TBH', { icon: 'faBuilding', label: 'Tablas Island (all airports), Philippines', key: 'C_TBH', type: 'city', code: 'tablas-island_ph' } ],
 ['C_TBO', { icon: 'faBuilding', label: 'Tabora (all airports), Tanzania', key: 'C_TBO', type: 'city', code: 'tabora_tz' } ],
 ['C_TBZ', { icon: 'faBuilding', label: 'Tabriz (all airports), Iran', key: 'C_TBZ', type: 'city', code: 'tabriz_ir' } ],
 ['C_TBG', { icon: 'faBuilding', label: 'Tabubil (all airports), Papua New Guinea', key: 'C_TBG', type: 'city', code: 'tabubil_pg' } ],
 ['C_TUU', { icon: 'faBuilding', label: 'Tabuk (all airports), Saudi Arabia', key: 'C_TUU', type: 'city', code: 'tabuk_sa' } ],
 ['C_TCG', { icon: 'faBuilding', label: 'Tacheng Prefecture (all airports), China', key: 'C_TCG', type: 'city', code: 'tacheng-prefecture_cn' } ],
 ['C_THL', { icon: 'faBuilding', label: 'Tachileik (all airports), Myanmar (Burma)', key: 'C_THL', type: 'city', code: 'tachileik_mm' } ],
 ['C_TAC', { icon: 'faBuilding', label: 'Tacloban (all airports), Philippines', key: 'C_TAC', type: 'city', code: 'tacloban_ph' } ],
 ['C_TCQ', { icon: 'faBuilding', label: 'Tacna (all airports), Peru', key: 'C_TCQ', type: 'city', code: 'tacna_pe' } ],
 ['C_XTL', { icon: 'faBuilding', label: 'Tadoule Lake (all airports), Canada', key: 'C_XTL', type: 'city', code: 'tadoule-lake_mb_ca' } ],
 ['C_TAG', { icon: 'faBuilding', label: 'Tagbilaran (all airports), Philippines', key: 'C_TAG', type: 'city', code: 'tagbilaran_ph' } ],
 ['C_PPT', { icon: 'faBuilding', label: 'Tahiti (all airports), French Polynesia', key: 'C_PPT', type: 'city', code: 'tahiti_pf' } ],
 ['C_RMQ', { icon: 'faBuilding', label: 'Taichung (all airports), Taiwan', key: 'C_RMQ', type: 'city', code: 'taichung_tw' } ],
 ['C_TNN', { icon: 'faBuilding', label: 'Tainan (all airports), Taiwan', key: 'C_TNN', type: 'city', code: 'tainan_tw' } ],
 ['C_TPE', { icon: 'faBuilding', label: 'Taipei (all airports), Taiwan', key: 'C_TPE', type: 'city', code: 'taipei_tw' } ],
 ['C_TTT', { icon: 'faBuilding', label: 'Taitung County (all airports), Taiwan', key: 'C_TTT', type: 'city', code: 'taitung-county_tw' } ],
 ['C_TYN', { icon: 'faBuilding', label: 'Taiyuan (all airports), China', key: 'C_TYN', type: 'city', code: 'taiyuan_cn' } ],
 ['C_TAI', { icon: 'faBuilding', label: 'Taiz Governorate (all airports), Yemen', key: 'C_TAI', type: 'city', code: 'taiz-governorate_ye' } ],
 ['C_HYN', { icon: 'faBuilding', label: 'Taizhou (all airports), China', key: 'C_HYN', type: 'city', code: 'taizhou_cn' } ],
 ['C_TAK', { icon: 'faBuilding', label: 'Takamatsu (all airports), Japan', key: 'C_TAK', type: 'city', code: 'takamatsu_jp' } ],
 ['C_TKP', { icon: 'faBuilding', label: 'Takapoto (all airports), French Polynesia', key: 'C_TKP', type: 'city', code: 'takapoto_pf' } ],
 ['C_TKX', { icon: 'faBuilding', label: 'Takaroa (all airports), French Polynesia', key: 'C_TKX', type: 'city', code: 'takaroa_pf' } ],
 ['C_TXE', { icon: 'faBuilding', label: 'Takengon (all airports), Indonesia', key: 'C_TXE', type: 'city', code: 'takengon_id' } ],
 ['C_TYL', { icon: 'faBuilding', label: 'Talara (all airports), Peru', key: 'C_TYL', type: 'city', code: 'talara_pe' } ],
 ['C_TDK', { icon: 'faBuilding', label: 'Taldykorgan (all airports), Kazakhstan', key: 'C_TDK', type: 'city', code: 'taldykorgan_kz' } ],
 ['C_TLH', { icon: 'faBuilding', label: 'Tallahassee (all airports), United States', key: 'C_TLH', type: 'city', code: 'tallahassee_fl_us' } ],
 ['C_TLL', { icon: 'faBuilding', label: 'Tallinn (all airports), Estonia', key: 'C_TLL', type: 'city', code: 'tallinn_ee' } ],
 ['C_YYH', { icon: 'faBuilding', label: 'Taloyoak (all airports), Canada', key: 'C_YYH', type: 'city', code: 'taloyoak_nu_ca' } ],
 ['C_TML', { icon: 'faBuilding', label: 'Tamale (all airports), Ghana', key: 'C_TML', type: 'city', code: 'tamale_gh' } ],
 ['C_TMR', { icon: 'faBuilding', label: 'Tamanrasset (all airports), Algeria', key: 'C_TMR', type: 'city', code: 'tamanrasset-province_dz' } ],
 ['C_TNO', { icon: 'faBuilding', label: 'Tamarindo (all airports), Costa Rica', key: 'C_TNO', type: 'city', code: 'tamarindo_cr' } ],
 ['C_TMC', { icon: 'faBuilding', label: 'Tambolaka (all airports), Indonesia', key: 'C_TMC', type: 'city', code: 'tambolaka_id' } ],
 ['C_TMU', { icon: 'faBuilding', label: 'Tambor (all airports), Costa Rica', key: 'C_TMU', type: 'city', code: 'tambor_cr' } ],
 ['C_TBW', { icon: 'faBuilding', label: 'Tambov (all airports), Russia', key: 'C_TBW', type: 'city', code: 'tambov_ru' } ],
 ['C_IKU', { icon: 'faBuilding', label: 'Tamchy (all airports), Kyrgyzstan', key: 'C_IKU', type: 'city', code: 'tamchy_kg' } ],
 ['C_TME', { icon: 'faBuilding', label: 'Tame (all airports), Colombia', key: 'C_TME', type: 'city', code: 'tame_co' } ],
 ['C_TPA', { icon: 'faBuilding', label: 'Tampa (all airports), United States', key: 'C_TPA', type: 'city', code: 'tampa_fl_us' } ],
 ['C_TMP', { icon: 'faBuilding', label: 'Tampere (all airports), Finland', key: 'C_TMP', type: 'city', code: 'tampere_fi' } ],
 ['C_TAM', { icon: 'faBuilding', label: 'Tampico (all airports), Mexico', key: 'C_TAM', type: 'city', code: 'tampico_mx' } ],
 ['C_TMW', { icon: 'faBuilding', label: 'Tamworth (all airports), Australia', key: 'C_TMW', type: 'city', code: 'tamworth_ns_au' } ],
 ['C_TTA', { icon: 'faBuilding', label: 'Tan-Tan (all airports), Morocco', key: 'C_TTA', type: 'city', code: 'tan-tan_ma' } ],
 ['C_TAL', { icon: 'faBuilding', label: 'Tanana (all airports), United States', key: 'C_TAL', type: 'city', code: 'tanana_ak_us' } ],
 ['C_TDG', { icon: 'faBuilding', label: 'Tandag (all airports), Philippines', key: 'C_TDG', type: 'city', code: 'tandag_ph' } ],
 ['C_TNE', { icon: 'faBuilding', label: 'Tanegashima (all airports), Japan', key: 'C_TNE', type: 'city', code: 'tanegashima_jp' } ],
 ['C_TGT', { icon: 'faBuilding', label: 'Tanga (all airports), Tanzania', key: 'C_TGT', type: 'city', code: 'tanga_tz' } ],
 ['C_TNG', { icon: 'faBuilding', label: 'Tangier (all airports), Morocco', key: 'C_TNG', type: 'city', code: 'tangier_ma' } ],
 ['C_TVS', { icon: 'faBuilding', label: 'Tangshan (all airports), China', key: 'C_TVS', type: 'city', code: 'tangshan_cn' } ],
 ['C_TGC', { icon: 'faBuilding', label: 'Tanjung Manis (all airports), Malaysia', key: 'C_TGC', type: 'city', code: 'tanjung-manis_my' } ],
 ['C_TJQ', { icon: 'faBuilding', label: 'Tanjung Pandan (all airports), Indonesia', key: 'C_TJQ', type: 'city', code: 'tanjung-pandan_id' } ],
 ['C_TNJ', { icon: 'faBuilding', label: 'Tanjung Pinang (all airports), Indonesia', key: 'C_TNJ', type: 'city', code: 'tanjung-pinang_id' } ],
 ['C_BEJ', { icon: 'faBuilding', label: 'Tanjung Redeb (all airports), Indonesia', key: 'C_BEJ', type: 'city', code: 'tanjung-redeb_id' } ],
 ['C_TJS', { icon: 'faBuilding', label: 'Tanjung Selor (all airports), Indonesia', key: 'C_TJS', type: 'city', code: 'tanjung-selor_id' } ],
 ['C_TAH', { icon: 'faBuilding', label: 'Tanna (all airports), Vanuatu', key: 'C_TAH', type: 'city', code: 'tanna_vu' } ],
 ['C_TAP', { icon: 'faBuilding', label: 'Tapachula (all airports), Mexico', key: 'C_TAP', type: 'city', code: 'tapachula_mx' } ],
 ['C_TRK', { icon: 'faBuilding', label: 'Tarakan, North Kalimantan (all airports), Indonesia', key: 'C_TRK', type: 'city', code: 'tarakan_id' } ],
 ['C_TCD', { icon: 'faBuilding', label: 'Tarapacá (all airports), Colombia', key: 'C_TCD', type: 'city', code: 'tarapaca_co' } ],
 ['C_TPP', { icon: 'faBuilding', label: 'Tarapoto (all airports), Peru', key: 'C_TPP', type: 'city', code: 'tarapoto_pe' } ],
 ['C_DMB', { icon: 'faBuilding', label: 'Taraz (all airports), Kazakhstan', key: 'C_DMB', type: 'city', code: 'taraz_kz' } ],
 ['C_TRO', { icon: 'faBuilding', label: 'Taree (all airports), Australia', key: 'C_TRO', type: 'city', code: 'taree_ns_au' } ],
 ['C_TGM', { icon: 'faBuilding', label: 'Târgu Mureș (all airports), Romania', key: 'C_TGM', type: 'city', code: 'targu-mures_ro' } ],
 ['C_TIZ', { icon: 'faBuilding', label: 'Tari (all airports), Papua New Guinea', key: 'C_TIZ', type: 'city', code: 'tari_pg' } ],
 ['C_TJA', { icon: 'faBuilding', label: 'Tarija (all airports), Bolivia', key: 'C_TJA', type: 'city', code: 'tarija_bo' } ],
 ['C_TAY', { icon: 'faBuilding', label: 'Tartu (all airports), Estonia', key: 'C_TAY', type: 'city', code: 'tartu_ee' } ],
 ['C_TAS', { icon: 'faBuilding', label: 'Tashkent (all airports), Uzbekistan', key: 'C_TAS', type: 'city', code: 'tashkent_uz' } ],
 ['C_TSY', { icon: 'faBuilding', label: 'Tasikmalaya (all airports), Indonesia', key: 'C_TSY', type: 'city', code: 'tasikmalaya_id' } ],
 ['C_YTQ', { icon: 'faBuilding', label: 'Tasiujaq (all airports), Canada', key: 'C_YTQ', type: 'city', code: 'tasiujaq_qc_ca' } ],
 ['C_TKV', { icon: 'faBuilding', label: 'Tatakoto (all airports), French Polynesia', key: 'C_TKV', type: 'city', code: 'tatakoto_pf' } ],
 ['C_TUO', { icon: 'faBuilding', label: 'Taupo (all airports), New Zealand', key: 'C_TUO', type: 'city', code: 'taupo_nz' } ],
 ['C_TRG', { icon: 'faBuilding', label: 'Tauranga (all airports), New Zealand', key: 'C_TRG', type: 'city', code: 'tauranga_nz' } ],
 ['C_TVU', { icon: 'faBuilding', label: 'Taveuni (all airports), Fiji', key: 'C_TVU', type: 'city', code: 'taveuni_fj' } ],
 ['C_TWU', { icon: 'faBuilding', label: 'Tawau (all airports), Malaysia', key: 'C_TWU', type: 'city', code: 'tawau_my' } ],
 ['C_TWT', { icon: 'faBuilding', label: 'Tawi-Tawi (all airports), Philippines', key: 'C_TWT', type: 'city', code: 'tawi-tawi_ph' } ],
 ['C_TBS', { icon: 'faBuilding', label: 'Tbilisi (all airports), Georgia', key: 'C_TBS', type: 'city', code: 'tbilisi_ge' } ],
 ['C_TEE', { icon: 'faBuilding', label: 'Tébessa (all airports), Algeria', key: 'C_TEE', type: 'city', code: 'tebessa_dz' } ],
 ['C_TFF', { icon: 'faBuilding', label: 'Tefé (all airports), Brazil', key: 'C_TFF', type: 'city', code: 'tefe_am_br' } ],
 ['C_TGU', { icon: 'faBuilding', label: 'Tegucigalpa (all airports), Honduras', key: 'C_TGU', type: 'city', code: 'tegucigalpa_hn' } ],
 ['C_THR', { icon: 'faBuilding', label: 'Tehran (all airports), Iran', key: 'C_THR', type: 'city', code: 'tehran_ir' } ],
 ['C_TXF', { icon: 'faBuilding', label: 'Teixeira de Freitas (all airports), Brazil', key: 'C_TXF', type: 'city', code: 'teixeira-de-freitas_ba_br' } ],
 ['C_TEQ', { icon: 'faBuilding', label: 'Tekirdağ (all airports), Turkey', key: 'C_TEQ', type: 'city', code: 'tekirdag_tr' } ],
 ['C_TLV', { icon: 'faBuilding', label: 'Tel Aviv (all airports), Israel', key: 'C_TLV', type: 'city', code: 'tel-aviv_il' } ],
 ['C_TLA', { icon: 'faBuilding', label: 'Teller (all airports), United States', key: 'C_TLA', type: 'city', code: 'teller_ak_us' } ],
 ['C_TEX', { icon: 'faBuilding', label: 'Telluride (all airports), United States', key: 'C_TEX', type: 'city', code: 'telluride_co_us' } ],
 ['C_ZCO', { icon: 'faBuilding', label: 'Temuco (all airports), Chile', key: 'C_ZCO', type: 'city', code: 'temuco_cl' } ],
 ['C_TKE', { icon: 'faBuilding', label: 'Tenakee Springs (all airports), United States', key: 'C_TKE', type: 'city', code: 'tenakee-springs_ak_us' } ],
 ['C_TCI', { icon: 'faBuilding', label: 'Tenerife (all airports), Spain', key: 'C_TCI', type: 'city', code: 'tenerife_es' } ],
 ['C_TCZ', { icon: 'faBuilding', label: 'Tengchong (all airports), China', key: 'C_TCZ', type: 'city', code: 'tengchong_cn' } ],
 ['C_TCA', { icon: 'faBuilding', label: 'Tennant Creek (all airports), Australia', key: 'C_TCA', type: 'city', code: 'tennant-creek_nt_au' } ],
 ['C_TPQ', { icon: 'faBuilding', label: 'Tepic (all airports), Mexico', key: 'C_TPQ', type: 'city', code: 'tepic_mx' } ],
 ['C_TER', { icon: 'faBuilding', label: 'Terceira Island (all airports), Portugal', key: 'C_TER', type: 'city', code: 'terceira-island_pt' } ],
 ['C_THE', { icon: 'faBuilding', label: 'Teresina (all airports), Brazil', key: 'C_THE', type: 'city', code: 'teresina_pi_br' } ],
 ['C_RHD', { icon: 'faBuilding', label: 'Termas de Río Hondo (all airports), Argentina', key: 'C_RHD', type: 'city', code: 'termas-de-rio-hondo_se_ar' } ],
 ['C_TMJ', { icon: 'faBuilding', label: 'Termez (all airports), Uzbekistan', key: 'C_TMJ', type: 'city', code: 'termez_uz' } ],
 ['C_TTE', { icon: 'faBuilding', label: 'Ternate City (all airports), Indonesia', key: 'C_TTE', type: 'city', code: 'ternate-city_id' } ],
 ['C_YXT', { icon: 'faBuilding', label: 'Terrace (all airports), Canada', key: 'C_YXT', type: 'city', code: 'terrace_bc_ca' } ],
 ['C_TET', { icon: 'faBuilding', label: 'Tete (all airports), Mozambique', key: 'C_TET', type: 'city', code: 'tete_mz' } ],
 ['C_ZTB', { icon: 'faBuilding', label: 'Tête-à-la-Baleine, Quebec (all airports), Canada', key: 'C_ZTB', type: 'city', code: 'tete-a-la-baleine_qc_ca' } ],
 ['C_TTU', { icon: 'faBuilding', label: 'Tétouan (all airports), Morocco', key: 'C_TTU', type: 'city', code: 'tetouan_ma' } ],
 ['C_TXK', { icon: 'faBuilding', label: 'Texarkana (all airports), United States', key: 'C_TXK', type: 'city', code: 'texarkana_ar_us' } ],
 ['C_TEZ', { icon: 'faBuilding', label: 'Tezpur (all airports), India', key: 'C_TEZ', type: 'city', code: 'tezpur_in' } ],
 ['C_ACH', { icon: 'faBuilding', label: 'Thal (all airports), Switzerland', key: 'C_ACH', type: 'city', code: 'thal_ch' } ],
 ['C_SNW', { icon: 'faBuilding', label: 'Thandwe (all airports), Myanmar (Burma)', key: 'C_SNW', type: 'city', code: 'thandwe_mm' } ],
 ['C_THD', { icon: 'faBuilding', label: 'Thanh Hoa Province (all airports), Vietnam', key: 'C_THD', type: 'city', code: 'thanh-hoa-province_vn' } ],
 ['C_XTG', { icon: 'faBuilding', label: 'Thargomindah (all airports), Australia', key: 'C_XTG', type: 'city', code: 'thargomindah_ql_au' } ],
 ['C_TBI', { icon: 'faBuilding', label: 'The Bight (all airports), Bahamas', key: 'C_TBI', type: 'city', code: 'the-bight_bs' } ],
 ['C_HAG', { icon: 'faBuilding', label: 'The Hague (all airports), Netherlands', key: 'C_HAG', type: 'city', code: 'the-hague_nl' } ],
 ['C_YQD', { icon: 'faBuilding', label: 'The Pas (all airports), Canada', key: 'C_YQD', type: 'city', code: 'the-pas_mb_ca' } ],
 ['C_SKG', { icon: 'faBuilding', label: 'Thessaloniki (all airports), Greece', key: 'C_SKG', type: 'city', code: 'thessaloniki_gr' } ],
 ['C_TVF', { icon: 'faBuilding', label: 'Thief River Falls (all airports), United States', key: 'C_TVF', type: 'city', code: 'thief-river-falls_mn_us' } ],
 ['C_TRV', { icon: 'faBuilding', label: 'Thiruvananthapuram (all airports), India', key: 'C_TRV', type: 'city', code: 'thiruvananthapuram_in' } ],
 ['C_YTH', { icon: 'faBuilding', label: 'Thompson (all airports), Canada', key: 'C_YTH', type: 'city', code: 'thompson_mb_ca' } ],
 ['C_TCR', { icon: 'faBuilding', label: 'Thoothukudi (all airports), India', key: 'C_TCR', type: 'city', code: 'thoothukudi_in' } ],
 ['C_KTB', { icon: 'faBuilding', label: 'Thorne Bay (all airports), United States', key: 'C_KTB', type: 'city', code: 'thorne-bay_ak_us' } ],
 ['C_YQT', { icon: 'faBuilding', label: 'Thunder Bay (all airports), Canada', key: 'C_YQT', type: 'city', code: 'thunder-bay_on_ca' } ],
 ['C_TSN', { icon: 'faBuilding', label: 'Tianjin (all airports), China', key: 'C_TSN', type: 'city', code: 'tianjin_cn' } ],
 ['C_TGJ', { icon: 'faBuilding', label: 'Tiga Island (all airports), New Caledonia', key: 'C_TGJ', type: 'city', code: 'tiga-island_nc' } ],
 ['C_TIJ', { icon: 'faBuilding', label: 'Tijuana (all airports), Mexico', key: 'C_TIJ', type: 'city', code: 'tijuana_mx' } ],
 ['C_TIH', { icon: 'faBuilding', label: 'Tikehau (all airports), French Polynesia', key: 'C_TIH', type: 'city', code: 'tikehau_pf' } ],
 ['C_IKS', { icon: 'faBuilding', label: 'Tiksi (all airports), Russia', key: 'C_IKS', type: 'city', code: 'tiksi_ru' } ],
 ['C_TIU', { icon: 'faBuilding', label: 'Timaru (all airports), New Zealand', key: 'C_TIU', type: 'city', code: 'timaru_nz' } ],
 ['C_TIM', { icon: 'faBuilding', label: 'Timika (all airports), Indonesia', key: 'C_TIM', type: 'city', code: 'timika_id' } ],
 ['C_TMX', { icon: 'faBuilding', label: 'Timimoun (all airports), Algeria', key: 'C_TMX', type: 'city', code: 'timimoun_dz' } ],
 ['C_TSR', { icon: 'faBuilding', label: 'Timișoara (all airports), Romania', key: 'C_TSR', type: 'city', code: 'timisoara_ro' } ],
 ['C_YTS', { icon: 'faBuilding', label: 'Timmins (all airports), Canada', key: 'C_YTS', type: 'city', code: 'timmins_on_ca' } ],
 ['C_TNC', { icon: 'faBuilding', label: 'Tin City (all airports), United States', key: 'C_TNC', type: 'city', code: 'tin-city_ak_us' } ],
 ['C_TIN', { icon: 'faBuilding', label: 'Tindouf (all airports), Algeria', key: 'C_TIN', type: 'city', code: 'tindouf_dz' } ],
 ['C_TGI', { icon: 'faBuilding', label: 'Tingo María (all airports), Peru', key: 'C_TGI', type: 'city', code: 'tingo-maria_pe' } ],
 ['C_TIA', { icon: 'faBuilding', label: 'Tirana (all airports), Albania', key: 'C_TIA', type: 'city', code: 'tirana_al' } ],
 ['C_TRE', { icon: 'faBuilding', label: 'Tiree (all airports), United Kingdom', key: 'C_TRE', type: 'city', code: 'tiree_gb' } ],
 ['C_TRZ', { icon: 'faBuilding', label: 'Tiruchirappalli (all airports), India', key: 'C_TRZ', type: 'city', code: 'tiruchirappalli_in' } ],
 ['C_TIR', { icon: 'faBuilding', label: 'Tirupati (all airports), India', key: 'C_TIR', type: 'city', code: 'tirupati_in' } ],
 ['C_TIV', { icon: 'faBuilding', label: 'Tivat (all airports), Montenegro', key: 'C_TIV', type: 'city', code: 'tivat_me' } ],
 ['C_TLM', { icon: 'faBuilding', label: 'Tlemcen (all airports), Algeria', key: 'C_TLM', type: 'city', code: 'tlemcen_dz' } ],
 ['C_ULB', { icon: 'faBuilding', label: 'Toak (all airports), Vanuatu', key: 'C_ULB', type: 'city', code: 'ulei_vu' } ],
 ['C_TMM', { icon: 'faBuilding', label: 'Toamasina (all airports), Madagascar', key: 'C_TMM', type: 'city', code: 'toamasina_mg' } ],
 ['C_TAB', { icon: 'faBuilding', label: 'Tobago (all airports), Trinidad & Tobago', key: 'C_TAB', type: 'city', code: 'tobago_tt' } ],
 ['C_TOB', { icon: 'faBuilding', label: 'Tobruk (all airports), Libya', key: 'C_TOB', type: 'city', code: 'tobruk_ly' } ],
 ['C_YAZ', { icon: 'faBuilding', label: 'Tofino (all airports), Canada', key: 'C_YAZ', type: 'city', code: 'tofino_bc_ca' } ],
 ['C_TOG', { icon: 'faBuilding', label: 'Togiak (all airports), United States', key: 'C_TOG', type: 'city', code: 'togiak_ak_us' } ],
 ['C_TKJ', { icon: 'faBuilding', label: 'Tok (all airports), United States', key: 'C_TKJ', type: 'city', code: 'tok_ak_us' } ],
 ['C_TJK', { icon: 'faBuilding', label: 'Tokat (all airports), Turkey', key: 'C_TJK', type: 'city', code: 'tokat_tr' } ],
 ['C_OOK', { icon: 'faBuilding', label: 'Toksook Bay (all airports), United States', key: 'C_OOK', type: 'city', code: 'toksook-bay_ak_us' } ],
 ['C_TKN', { icon: 'faBuilding', label: 'Tokunoshima (all airports), Japan', key: 'C_TKN', type: 'city', code: 'tokunoshima_jp' } ],
 ['C_TKS', { icon: 'faBuilding', label: 'Tokushima (all airports), Japan', key: 'C_TKS', type: 'city', code: 'tokushima_jp' } ],
 ['C_TYO', { icon: 'faBuilding', label: 'Tokyo (all airports), Japan', key: 'C_TYO', type: 'city', code: 'tokyo_jp' } ],
 ['C_FTU', { icon: 'faBuilding', label: 'Tôlanaro (all airports), Madagascar', key: 'C_FTU', type: 'city', code: 'tolanaro_mg' } ],
 ['C_TOL', { icon: 'faBuilding', label: 'Toledo (all airports), United States', key: 'C_TOL', type: 'city', code: 'toledo_oh_us' } ],
 ['C_XTJ', { icon: 'faBuilding', label: 'Toledo (all airports), Spain', key: 'C_XTJ', type: 'city', code: 'toledo_es' } ],
 ['C_TLE', { icon: 'faBuilding', label: 'Toliara (all airports), Madagascar', key: 'C_TLE', type: 'city', code: 'toliara_mg' } ],
 ['C_TLI', { icon: 'faBuilding', label: 'Tolitoli (all airports), Indonesia', key: 'C_TLI', type: 'city', code: 'tolitoli_id' } ],
 ['C_TLU', { icon: 'faBuilding', label: 'Tolú (all airports), Colombia', key: 'C_TLU', type: 'city', code: 'tolu_co' } ],
 ['C_TLC', { icon: 'faBuilding', label: 'Toluca (all airports), Mexico', key: 'C_TLC', type: 'city', code: 'toluca_mx' } ],
 ['C_TOF', { icon: 'faBuilding', label: 'Tomsk (all airports), Russia', key: 'C_TOF', type: 'city', code: 'tomsk_ru' } ],
 ['C_TNH', { icon: 'faBuilding', label: 'Tonghua (all airports), China', key: 'C_TNH', type: 'city', code: 'tonghua_cn' } ],
 ['C_TGO', { icon: 'faBuilding', label: 'Tongliao (all airports), China', key: 'C_TGO', type: 'city', code: 'tongliao_cn' } ],
 ['C_TGH', { icon: 'faBuilding', label: 'Tongoa (all airports), Vanuatu', key: 'C_TGH', type: 'city', code: 'tongoa_vu' } ],
 ['C_TEN', { icon: 'faBuilding', label: 'Tongren (all airports), China', key: 'C_TEN', type: 'city', code: 'tongren_cn' } ],
 ['C_TWB', { icon: 'faBuilding', label: 'Toowoomba (all airports), Australia', key: 'C_TWB', type: 'city', code: 'toowoomba_ql_au' } ],
 ['C_YTO', { icon: 'faBuilding', label: 'Toronto (all airports), Canada', key: 'C_YTO', type: 'city', code: 'toronto_on_ca' } ],
 ['C_TRC', { icon: 'faBuilding', label: 'Torreón (all airports), Mexico', key: 'C_TRC', type: 'city', code: 'torreon_mx' } ],
 ['C_TOH', { icon: 'faBuilding', label: 'Torres Islands (all airports), Vanuatu', key: 'C_TOH', type: 'city', code: 'torres-islands_vu' } ],
 ['C_TOV', { icon: 'faBuilding', label: 'Tortola (all airports), British Virgin Islands', key: 'C_TOV', type: 'city', code: 'tortola_vg' } ],
 ['C_TTQ', { icon: 'faBuilding', label: 'Tortuguero (all airports), Costa Rica', key: 'C_TTQ', type: 'city', code: 'tortuguero_cr' } ],
 ['C_GMR', { icon: 'faBuilding', label: 'Totegegie (all airports), French Polynesia', key: 'C_GMR', type: 'city', code: 'gambier-island_pf' } ],
 ['C_TTK', { icon: 'faBuilding', label: 'Tottenham (all airports), United Kingdom', key: 'C_TTK', type: 'city', code: 'tottenham_gb' } ],
 ['C_TTJ', { icon: 'faBuilding', label: 'Tottori (all airports), Japan', key: 'C_TTJ', type: 'city', code: 'tottori_jp' } ],
 ['C_TGR', { icon: 'faBuilding', label: 'Touggourt (all airports), Algeria', key: 'C_TGR', type: 'city', code: 'touggourt_dz' } ],
 ['C_TOU', { icon: 'faBuilding', label: 'Touho (all airports), New Caledonia', key: 'C_TOU', type: 'city', code: 'touho_nc' } ],
 ['C_TLN', { icon: 'faBuilding', label: 'Toulon (all airports), France', key: 'C_TLN', type: 'city', code: 'toulon_fr' } ],
 ['C_TLS', { icon: 'faBuilding', label: 'Toulouse (all airports), France', key: 'C_TLS', type: 'city', code: 'toulouse_fr' } ],
 ['C_TUF', { icon: 'faBuilding', label: 'Tours (all airports), France', key: 'C_TUF', type: 'city', code: 'tours_fr' } ],
 ['C_TSV', { icon: 'faBuilding', label: 'Townsville (all airports), Australia', key: 'C_TSV', type: 'city', code: 'townsville_ql_au' } ],
 ['C_TOY', { icon: 'faBuilding', label: 'Toyama (all airports), Japan', key: 'C_TOY', type: 'city', code: 'toyama_jp' } ],
 ['C_TOE', { icon: 'faBuilding', label: 'Tozeur (all airports), Tunisia', key: 'C_TOE', type: 'city', code: 'tozeur_tn' } ],
 ['C_TZX', { icon: 'faBuilding', label: 'Trabzon (all airports), Turkey', key: 'C_TZX', type: 'city', code: 'trabzon_tr' } ],
 ['C_YZZ', { icon: 'faBuilding', label: 'Trail (all airports), Canada', key: 'C_YZZ', type: 'city', code: 'trail_ca' } ],
 ['C_TST', { icon: 'faBuilding', label: 'Trang (all airports), Thailand', key: 'C_TST', type: 'city', code: 'trang_th' } ],
 ['C_TPS', { icon: 'faBuilding', label: 'Trapani (all airports), Italy', key: 'C_TPS', type: 'city', code: 'trapani_it' } ],
 ['C_TDX', { icon: 'faBuilding', label: 'Trat Province (all airports), Thailand', key: 'C_TDX', type: 'city', code: 'trat-province_th' } ],
 ['C_TVC', { icon: 'faBuilding', label: 'Traverse City (all airports), United States', key: 'C_TVC', type: 'city', code: 'traverse-city_mi_us' } ],
 ['C_TCB', { icon: 'faBuilding', label: 'Treasure Cay (all airports), Bahamas', key: 'C_TCB', type: 'city', code: 'treasure-cay_bs' } ],
 ['C_REL', { icon: 'faBuilding', label: 'Trelew (all airports), Argentina', key: 'C_REL', type: 'city', code: 'trelew_cb_ar' } ],
 ['C_TTN', { icon: 'faBuilding', label: 'Trenton (all airports), United States', key: 'C_TTN', type: 'city', code: 'trenton_nj_us' } ],
 ['C_TJL', { icon: 'faBuilding', label: 'Três Lagoas (all airports), Brazil', key: 'C_TJL', type: 'city', code: 'tres-lagoas_ms_br' } ],
 ['C_TRS', { icon: 'faBuilding', label: 'Trieste (all airports), Italy', key: 'C_TRS', type: 'city', code: 'trieste_it' } ],
 ['C_TRR', { icon: 'faBuilding', label: 'Trincomalee (all airports), Sri Lanka', key: 'C_TRR', type: 'city', code: 'trincomalee_lk' } ],
 ['C_TDD', { icon: 'faBuilding', label: 'Trinidad (all airports), Bolivia', key: 'C_TDD', type: 'city', code: 'trinidad_bo' } ],
 ['C_TIP', { icon: 'faBuilding', label: 'Tripoli (all airports), Libya', key: 'C_TIP', type: 'city', code: 'tripoli_ly' } ],
 ['C_THN', { icon: 'faBuilding', label: 'Trollhättan (all airports), Sweden', key: 'C_THN', type: 'city', code: 'trollhattan_se' } ],
 ['C_TOS', { icon: 'faBuilding', label: 'Tromsø (all airports), Norway', key: 'C_TOS', type: 'city', code: 'tromso_no' } ],
 ['C_TRD', { icon: 'faBuilding', label: 'Trondheim (all airports), Norway', key: 'C_TRD', type: 'city', code: 'trondheim_no' } ],
 ['C_TRU', { icon: 'faBuilding', label: 'Trujillo (all airports), Peru', key: 'C_TRU', type: 'city', code: 'trujillo_pe' } ],
 ['C_TSJ', { icon: 'faBuilding', label: 'Tsushima (all airports), Japan', key: 'C_TSJ', type: 'city', code: 'tsushima_jp' } ],
 ['C_TUB', { icon: 'faBuilding', label: 'Tubuai (all airports), French Polynesia', key: 'C_TUB', type: 'city', code: 'tubuai_pf' } ],
 ['C_TUS', { icon: 'faBuilding', label: 'Tucson (all airports), United States', key: 'C_TUS', type: 'city', code: 'tucson_az_us' } ],
 ['C_TFI', { icon: 'faBuilding', label: 'Tufi (all airports), Papua New Guinea', key: 'C_TFI', type: 'city', code: 'tufi_pg' } ],
 ['C_TUG', { icon: 'faBuilding', label: 'Tuguegarao (all airports), Philippines', key: 'C_TUG', type: 'city', code: 'tuguegarao_ph' } ],
 ['C_YUB', { icon: 'faBuilding', label: 'Tuktoyaktuk (all airports), Canada', key: 'C_YUB', type: 'city', code: 'tuktoyaktuk_nt_ca' } ],
 ['C_ZFN', { icon: 'faBuilding', label: 'Tulita (all airports), Canada', key: 'C_ZFN', type: 'city', code: 'tulita_nt_ca' } ],
 ['C_TUL', { icon: 'faBuilding', label: 'Tulsa (all airports), United States', key: 'C_TUL', type: 'city', code: 'tulsa_ok_us' } ],
 ['C_TLT', { icon: 'faBuilding', label: 'Tuluksak (all airports), United States', key: 'C_TLT', type: 'city', code: 'tuluksak_ak_us' } ],
 ['C_TCO', { icon: 'faBuilding', label: 'Tumaco (all airports), Colombia', key: 'C_TCO', type: 'city', code: 'tumaco_co' } ],
 ['C_TBP', { icon: 'faBuilding', label: 'Tumbes (all airports), Peru', key: 'C_TBP', type: 'city', code: 'tumbes_pe' } ],
 ['C_TMI', { icon: 'faBuilding', label: 'Tumlingtar (all airports), Nepal', key: 'C_TMI', type: 'city', code: 'tumlingtar_np' } ],
 ['C_TUN', { icon: 'faBuilding', label: 'Tunis (all airports), Tunisia', key: 'C_TUN', type: 'city', code: 'tunis_tn' } ],
 ['C_WTL', { icon: 'faBuilding', label: 'Tuntutuliak (all airports), United States', key: 'C_WTL', type: 'city', code: 'tuntutuliak_ak_us' } ],
 ['C_TNK', { icon: 'faBuilding', label: 'Tununak (all airports), United States', key: 'C_TNK', type: 'city', code: 'tununak_ak_us' } ],
 ['C_TUP', { icon: 'faBuilding', label: 'Tupelo (all airports), United States', key: 'C_TUP', type: 'city', code: 'tupelo_ms_us' } ],
 ['C_TUI', { icon: 'faBuilding', label: 'Turaif (all airports), Saudi Arabia', key: 'C_TUI', type: 'city', code: 'turaif_sa' } ],
 ['C_TUK', { icon: 'faBuilding', label: 'Turbat (all airports), Pakistan', key: 'C_TUK', type: 'city', code: 'turbat_pk' } ],
 ['C_TRN', { icon: 'faBuilding', label: 'Turin (all airports), Italy', key: 'C_TRN', type: 'city', code: 'turin_it' } ],
 ['C_CRZ', { icon: 'faBuilding', label: 'Türkmenabat (all airports), Turkmenistan', key: 'C_CRZ', type: 'city', code: 'turkmenabat_tm' } ],
 ['C_KRW', { icon: 'faBuilding', label: 'Türkmenbaşy (all airports), Turkmenistan', key: 'C_KRW', type: 'city', code: 'turkmenbasy_tm' } ],
 ['C_TKU', { icon: 'faBuilding', label: 'Turku (all airports), Finland', key: 'C_TKU', type: 'city', code: 'turku_fi' } ],
 ['C_TLQ', { icon: 'faBuilding', label: 'Turpan (all airports), China', key: 'C_TLQ', type: 'city', code: 'turpan_cn' } ],
 ['C_THX', { icon: 'faBuilding', label: 'Turukhansk (all airports), Russia', key: 'C_THX', type: 'city', code: 'turukhansk_ru' } ],
 ['C_TGZ', { icon: 'faBuilding', label: 'Tuxtla Gutiérrez (all airports), Mexico', key: 'C_TGZ', type: 'city', code: 'tuxtla-gutierrez_mx' } ],
 ['C_TBB', { icon: 'faBuilding', label: 'Tuy Hòa (all airports), Vietnam', key: 'C_TBB', type: 'city', code: 'tuy-hoa_vn' } ],
 ['C_TZL', { icon: 'faBuilding', label: 'Tuzla (all airports), Bosnia & Herzegovina', key: 'C_TZL', type: 'city', code: 'tuzla_ba' } ],
 ['C_TWF', { icon: 'faBuilding', label: 'Twin Falls (all airports), United States', key: 'C_TWF', type: 'city', code: 'twin-falls_id_us' } ],
 ['C_TYR', { icon: 'faBuilding', label: 'Tyler (all airports), United States', key: 'C_TYR', type: 'city', code: 'tyler_tx_us' } ],
 ['C_TYD', { icon: 'faBuilding', label: 'Tynda (all airports), Russia', key: 'C_TYD', type: 'city', code: 'tynda_ru' } ],
 ['C_TJM', { icon: 'faBuilding', label: 'Tyumen (all airports), Russia', key: 'C_TJM', type: 'city', code: 'tyumen_ru' } ],
 ['C_UAH', { icon: 'faBuilding', label: 'Ua Huka (all airports), French Polynesia', key: 'C_UAH', type: 'city', code: 'ua-huka_pf' } ],
 ['C_UAP', { icon: 'faBuilding', label: 'Ua Pou (all airports), French Polynesia', key: 'C_UAP', type: 'city', code: 'ua-pu_pf' } ],
 ['C_UBJ', { icon: 'faBuilding', label: 'Ube (all airports), Japan', key: 'C_UBJ', type: 'city', code: 'ube_jp' } ],
 ['C_UBA', { icon: 'faBuilding', label: 'Uberaba (all airports), Brazil', key: 'C_UBA', type: 'city', code: 'uberaba_mg_br' } ],
 ['C_UDI', { icon: 'faBuilding', label: 'Uberlândia (all airports), Brazil', key: 'C_UDI', type: 'city', code: 'uberlandia_mg_br' } ],
 ['C_UBP', { icon: 'faBuilding', label: 'Ubon Ratchathani Province (all airports), Thailand', key: 'C_UBP', type: 'city', code: 'ubon-ratchathani-province_th' } ],
 ['C_PYJ', { icon: 'faBuilding', label: 'Udachny (all airports), Russia', key: 'C_PYJ', type: 'city', code: 'polyarny_ru' } ],
 ['C_UDR', { icon: 'faBuilding', label: 'Udaipur (all airports), India', key: 'C_UDR', type: 'city', code: 'udaipur_in' } ],
 ['C_UTH', { icon: 'faBuilding', label: 'Udon Thani (all airports), Thailand', key: 'C_UTH', type: 'city', code: 'udon-thani_th' } ],
 ['C_UFA', { icon: 'faBuilding', label: 'Ufa (all airports), Russia', key: 'C_UFA', type: 'city', code: 'ufa_ru' } ],
 ['C_UCT', { icon: 'faBuilding', label: 'Ukhta (all airports), Russia', key: 'C_UCT', type: 'city', code: 'ukhta_ru' } ],
 ['C_UKA', { icon: 'faBuilding', label: 'Ukunda (all airports), Kenya', key: 'C_UKA', type: 'city', code: 'ukunda_ke' } ],
 ['C_PYC', { icon: 'faBuilding', label: 'Ukupseni (all airports), Panama', key: 'C_PYC', type: 'city', code: 'playon-chico_pa' } ],
 ['C_ULN', { icon: 'faBuilding', label: 'Ulaanbaatar (all airports), Mongolia', key: 'C_ULN', type: 'city', code: 'ulaanbaatar_mn' } ],
 ['C_ULO', { icon: 'faBuilding', label: 'Ulaangom (all airports), Mongolia', key: 'C_ULO', type: 'city', code: 'ulaangom_mn' } ],
 ['C_HLH', { icon: 'faBuilding', label: 'Ulan Hot (all airports), China', key: 'C_HLH', type: 'city', code: 'ulan-hot_cn' } ],
 ['C_UUD', { icon: 'faBuilding', label: 'Ulan-Ude (all airports), Russia', key: 'C_UUD', type: 'city', code: 'ulan-ude_ru' } ],
 ['C_UCB', { icon: 'faBuilding', label: 'Ulanqab (all airports), China', key: 'C_UCB', type: 'city', code: 'ulanqab_cn' } ],
 ['C_ULZ', { icon: 'faBuilding', label: 'Uliastai (all airports), Mongolia', key: 'C_ULZ', type: 'city', code: 'uliastai_mn' } ],
 ['C_USN', { icon: 'faBuilding', label: 'Ulsan (all airports), South Korea', key: 'C_USN', type: 'city', code: 'ulsan_kr' } ],
 ['C_YHI', { icon: 'faBuilding', label: 'Ulukhaktok (all airports), Canada', key: 'C_YHI', type: 'city', code: 'ulukhaktok_nt_ca' } ],
 ['C_ULD', { icon: 'faBuilding', label: 'Ulundi (all airports), South Africa', key: 'C_ULD', type: 'city', code: 'ulundi_za' } ],
 ['C_AYQ', { icon: 'faBuilding', label: 'Uluru (all airports), Australia', key: 'C_AYQ', type: 'city', code: 'uluru_nt_au' } ],
 ['C_ULY', { icon: 'faBuilding', label: 'Ulyanovsk (all airports), Russia', key: 'C_ULY', type: 'city', code: 'ulyanovsk_ru' } ],
 ['C_UME', { icon: 'faBuilding', label: 'Umeå (all airports), Sweden', key: 'C_UME', type: 'city', code: 'umea_se' } ],
 ['C_YUD', { icon: 'faBuilding', label: 'Umiujaq (all airports), Canada', key: 'C_YUD', type: 'city', code: 'umiujaq_qc_ca' } ],
 ['C_UNK', { icon: 'faBuilding', label: 'Unalakleet (all airports), United States', key: 'C_UNK', type: 'city', code: 'unalakleet_ak_us' } ],
 ['C_JUV', { icon: 'faBuilding', label: 'Upernavik (all airports), Greenland', key: 'C_JUV', type: 'city', code: 'upernavik_gl' } ],
 ['C_UTN', { icon: 'faBuilding', label: 'Upington (all airports), South Africa', key: 'C_UTN', type: 'city', code: 'upington_za' } ],
 ['C_KLG', { icon: 'faBuilding', label: 'Upper Kalskag (all airports), United States', key: 'C_KLG', type: 'city', code: 'upper-kalskag_ak_us' } ],
 ['C_URJ', { icon: 'faBuilding', label: 'Uray (all airports), Russia', key: 'C_URJ', type: 'city', code: 'uray_ru' } ],
 ['C_UGC', { icon: 'faBuilding', label: 'Urgench (all airports), Uzbekistan', key: 'C_UGC', type: 'city', code: 'urgench_uz' } ],
 ['C_OMH', { icon: 'faBuilding', label: 'Urmia (all airports), Iran', key: 'C_OMH', type: 'city', code: 'urmia_ir' } ],
 ['C_UPN', { icon: 'faBuilding', label: 'Uruapan (all airports), Mexico', key: 'C_UPN', type: 'city', code: 'uruapan_mx' } ],
 ['C_URG', { icon: 'faBuilding', label: 'Uruguaiana (all airports), Brazil', key: 'C_URG', type: 'city', code: 'uruguaiana_rs_br' } ],
 ['C_URC', { icon: 'faBuilding', label: 'Ürümqi (all airports), China', key: 'C_URC', type: 'city', code: 'urumqi_cn' } ],
 ['C_UZR', { icon: 'faBuilding', label: 'Urzhar (all airports), Kazakhstan', key: 'C_UZR', type: 'city', code: 'urzhar_kz' } ],
 ['C_USQ', { icon: 'faBuilding', label: 'Usak (all airports), Turkey', key: 'C_USQ', type: 'city', code: 'usak_tr' } ],
 ['C_USH', { icon: 'faBuilding', label: 'Ushuaia (all airports), Argentina', key: 'C_USH', type: 'city', code: 'ushuaia_tf_ar' } ],
 ['C_USK', { icon: 'faBuilding', label: 'Usinsk (all airports), Russia', key: 'C_USK', type: 'city', code: 'usinsk_ru' } ],
 ['C_UKX', { icon: 'faBuilding', label: 'Ust-Kut (all airports), Russia', key: 'C_UKX', type: 'city', code: 'ust-kut_ru' } ],
 ['C_UKG', { icon: 'faBuilding', label: 'Ust-Kuyga (all airports), Russia', key: 'C_UKG', type: 'city', code: 'ust-kuyga_ru' } ],
 ['C_UMS', { icon: 'faBuilding', label: 'Ust-Maya (all airports), Russia', key: 'C_UMS', type: 'city', code: 'ust-maya_ru' } ],
 ['C_USR', { icon: 'faBuilding', label: 'Ust-Nera (all airports), Russia', key: 'C_USR', type: 'city', code: 'ust-nera_ru' } ],
 ['C_UTS', { icon: 'faBuilding', label: 'Ust-Tsilma (all airports), Russia', key: 'C_UTS', type: 'city', code: 'ust-tsilma_ru' } ],
 ['C_OGM', { icon: 'faBuilding', label: 'Ustupo (all airports), Panama', key: 'C_OGM', type: 'city', code: 'ustupo_pa' } ],
 ['C_UII', { icon: 'faBuilding', label: 'Útila (all airports), Honduras', key: 'C_UII', type: 'city', code: 'utila_hn' } ],
 ['C_UTO', { icon: 'faBuilding', label: 'Utopia Creek (all airports), United States', key: 'C_UTO', type: 'city', code: 'utopia-creek_ak_us' } ],
 ['C_UTC', { icon: 'faBuilding', label: 'Utrecht (all airports), Netherlands', key: 'C_UTC', type: 'city', code: 'utrecht_nl' } ],
 ['C_QUO', { icon: 'faBuilding', label: 'Uyo (all airports), Nigeria', key: 'C_QUO', type: 'city', code: 'uyo_ng' } ],
 ['C_UYU', { icon: 'faBuilding', label: 'Uyuni (all airports), Bolivia', key: 'C_UYU', type: 'city', code: 'uyuni_bo' } ],
 ['C_VAA', { icon: 'faBuilding', label: 'Vaasa (all airports), Finland', key: 'C_VAA', type: 'city', code: 'vaasa_fi' } ],
 ['C_BDQ', { icon: 'faBuilding', label: 'Vadodara (all airports), India', key: 'C_BDQ', type: 'city', code: 'vadodara_in' } ],
 ['C_VDS', { icon: 'faBuilding', label: 'Vadsø (all airports), Norway', key: 'C_VDS', type: 'city', code: 'vadso_no' } ],
 ['C_EGE', { icon: 'faBuilding', label: 'Vail (all airports), United States', key: 'C_EGE', type: 'city', code: 'vail_co_us' } ],
 ['C_YVO', { icon: 'faBuilding', label: 'Val-d Or (all airports), Canada', key: 'C_YVO', type: 'city', code: 'val-dor_qc_ca' } ],
 ['C_VDZ', { icon: 'faBuilding', label: 'Valdez (all airports), United States', key: 'C_VDZ', type: 'city', code: 'valdez_ak_us' } ],
 ['C_ZAL', { icon: 'faBuilding', label: 'Valdivia (all airports), Chile', key: 'C_ZAL', type: 'city', code: 'valdivia_cl' } ],
 ['C_VLD', { icon: 'faBuilding', label: 'Valdosta (all airports), United States', key: 'C_VLD', type: 'city', code: 'valdosta_ga_us' } ],
 ['C_VAL', { icon: 'faBuilding', label: 'Valença, Bahia (all airports), Brazil', key: 'C_VAL', type: 'city', code: 'valenca_ba_br' } ],
 ['C_VLN', { icon: 'faBuilding', label: 'Valencia (all airports), Venezuela', key: 'C_VLN', type: 'city', code: 'valencia_ve' } ],
 ['C_VLC', { icon: 'faBuilding', label: 'Valencia (all airports), Spain', key: 'C_VLC', type: 'city', code: 'valencia_es' } ],
 ['C_VLS', { icon: 'faBuilding', label: 'Valesdir (all airports), Vanuatu', key: 'C_VLS', type: 'city', code: 'valesdir_vu' } ],
 ['C_VLL', { icon: 'faBuilding', label: 'Valladolid (all airports), Spain', key: 'C_VLL', type: 'city', code: 'valladolid_es' } ],
 ['C_VUP', { icon: 'faBuilding', label: 'Valledupar (all airports), Colombia', key: 'C_VUP', type: 'city', code: 'valledupar_co' } ],
 ['C_VDE', { icon: 'faBuilding', label: 'Valverde (all airports), Spain', key: 'C_VDE', type: 'city', code: 'valverde_es' } ],
 ['C_VAN', { icon: 'faBuilding', label: 'Van (all airports), Turkey', key: 'C_VAN', type: 'city', code: 'van_tr' } ],
 ['C_YVR', { icon: 'faBuilding', label: 'Vancouver (all airports), Canada', key: 'C_YVR', type: 'city', code: 'vancouver_bc_ca' } ],
 ['C_VAI', { icon: 'faBuilding', label: 'Vanimo (all airports), Papua New Guinea', key: 'C_VAI', type: 'city', code: 'vanimo_pg' } ],
 ['C_VBV', { icon: 'faBuilding', label: 'Vanua Balavu (all airports), Fiji', key: 'C_VBV', type: 'city', code: 'vanua-balavu_fj' } ],
 ['C_VRA', { icon: 'faBuilding', label: 'Varadero (all airports), Cuba', key: 'C_VRA', type: 'city', code: 'varadero_cu' } ],
 ['C_VNS', { icon: 'faBuilding', label: 'Varanasi (all airports), India', key: 'C_VNS', type: 'city', code: 'varanasi_in' } ],
 ['C_VAW', { icon: 'faBuilding', label: 'Vardø (all airports), Norway', key: 'C_VAW', type: 'city', code: 'vardo_no' } ],
 ['C_VAG', { icon: 'faBuilding', label: 'Varginha (all airports), Brazil', key: 'C_VAG', type: 'city', code: 'varginha_mg_br' } ],
 ['C_VAR', { icon: 'faBuilding', label: 'Varna (all airports), Bulgaria', key: 'C_VAR', type: 'city', code: 'varna_bg' } ],
 ['C_VAV', { icon: 'faBuilding', label: 'Vavaʻu (all airports), Tonga', key: 'C_VAV', type: 'city', code: 'vavau_to' } ],
 ['C_VXO', { icon: 'faBuilding', label: 'Växjö (all airports), Sweden', key: 'C_VXO', type: 'city', code: 'vaxjo_se' } ],
 ['C_VUS', { icon: 'faBuilding', label: 'Veliky Ustyug (all airports), Russia', key: 'C_VUS', type: 'city', code: 'veliky-ustyug_ru' } ],
 ['C_VEE', { icon: 'faBuilding', label: 'Venetie (all airports), United States', key: 'C_VEE', type: 'city', code: 'venetie_ak_us' } ],
 ['C_VCE', { icon: 'faBuilding', label: 'Venice (all airports), Italy', key: 'C_VCE', type: 'city', code: 'venice_it' } ],
 ['C_VER', { icon: 'faBuilding', label: 'Veracruz (all airports), Mexico', key: 'C_VER', type: 'city', code: 'veracruz_mx' } ],
 ['C_ZKY', { icon: 'faBuilding', label: 'Verbier (all airports), Switzerland', key: 'C_ZKY', type: 'city', code: 'verbier_ch' } ],
 ['C_VHV', { icon: 'faBuilding', label: 'Verkhnevilyuysk (all airports), Russia', key: 'C_VHV', type: 'city', code: 'verkhnevilyuysk_ru' } ],
 ['C_VEL', { icon: 'faBuilding', label: 'Vernal (all airports), United States', key: 'C_VEL', type: 'city', code: 'vernal_ut_us' } ],
 ['C_VRB', { icon: 'faBuilding', label: 'Vero Beach (all airports), United States', key: 'C_VRB', type: 'city', code: 'vero-beach_fl_us' } ],
 ['C_VRN', { icon: 'faBuilding', label: 'Verona (all airports), Italy', key: 'C_VRN', type: 'city', code: 'verona_it' } ],
 ['C_QXF', { icon: 'faBuilding', label: 'Vestbjerg (all airports), Denmark', key: 'C_QXF', type: 'city', code: 'vestbjerg_dk' } ],
 ['C_YYJ', { icon: 'faBuilding', label: 'Victoria (all airports), Canada', key: 'C_YYJ', type: 'city', code: 'victoria_bc_ca' } ],
 ['C_VCT', { icon: 'faBuilding', label: 'Victoria (all airports), United States', key: 'C_VCT', type: 'city', code: 'victoria_tx_us' } ],
 ['C_VFA', { icon: 'faBuilding', label: 'Victoria Falls (all airports), Zimbabwe', key: 'C_VFA', type: 'city', code: 'victoria-falls_zw' } ],
 ['C_VDM', { icon: 'faBuilding', label: 'Viedma (all airports), Argentina', key: 'C_VDM', type: 'city', code: 'viedma_rn_ar' } ],
 ['C_VIE', { icon: 'faBuilding', label: 'Vienna (all airports), Austria', key: 'C_VIE', type: 'city', code: 'vienna_at' } ],
 ['C_VTE', { icon: 'faBuilding', label: 'Vientiane (all airports), Laos', key: 'C_VTE', type: 'city', code: 'vientiane_la' } ],
 ['C_VQS', { icon: 'faBuilding', label: 'Vieques (all airports), United States', key: 'C_VQS', type: 'city', code: 'vieques_pr_pr' } ],
 ['C_VGO', { icon: 'faBuilding', label: 'Vigo (all airports), Spain', key: 'C_VGO', type: 'city', code: 'vigo_es' } ],
 ['C_VGA', { icon: 'faBuilding', label: 'Vijayawada (all airports), India', key: 'C_VGA', type: 'city', code: 'vijayawada_in' } ],
 ['C_PXO', { icon: 'faBuilding', label: 'Vila Baleira (all airports), Portugal', key: 'C_PXO', type: 'city', code: 'porto-santo-island_pt' } ],
 ['C_VNX', { icon: 'faBuilding', label: 'Vilankulo (all airports), Mozambique', key: 'C_VNX', type: 'city', code: 'vilankulo_mz' } ],
 ['C_VHM', { icon: 'faBuilding', label: 'Vilhelmina (all airports), Sweden', key: 'C_VHM', type: 'city', code: 'vilhelmina_se' } ],
 ['C_BVH', { icon: 'faBuilding', label: 'Vilhena (all airports), Brazil', key: 'C_BVH', type: 'city', code: 'vilhena_ro_br' } ],
 ['C_VGZ', { icon: 'faBuilding', label: 'Villagarzón (all airports), Colombia', key: 'C_VGZ', type: 'city', code: 'villagarzon_co' } ],
 ['C_VSA', { icon: 'faBuilding', label: 'Villahermosa (all airports), Mexico', key: 'C_VSA', type: 'city', code: 'villahermosa_mx' } ],
 ['C_VVC', { icon: 'faBuilding', label: 'Villavicencio (all airports), Colombia', key: 'C_VVC', type: 'city', code: 'villavicencio_co' } ],
 ['C_VNO', { icon: 'faBuilding', label: 'Vilnius (all airports), Lithuania', key: 'C_VNO', type: 'city', code: 'vilnius_lt' } ],
 ['C_VYI', { icon: 'faBuilding', label: 'Vilyuysk (all airports), Russia', key: 'C_VYI', type: 'city', code: 'vilyuysk_ru' } ],
 ['C_VII', { icon: 'faBuilding', label: 'Vinh (all airports), Vietnam', key: 'C_VII', type: 'city', code: 'vinh_vn' } ],
 ['C_VIN', { icon: 'faBuilding', label: 'Vinnytsia (all airports), Ukraine', key: 'C_VIN', type: 'city', code: 'vinnytsia_ua' } ],
 ['C_VRC', { icon: 'faBuilding', label: 'Virac, Catanduanes (all airports), Philippines', key: 'C_VRC', type: 'city', code: 'virac_ph' } ],
 ['C_VIJ', { icon: 'faBuilding', label: 'Virgin Gorda (all airports), British Virgin Islands', key: 'C_VIJ', type: 'city', code: 'virgin-gorda_vg' } ],
 ['C_VTZ', { icon: 'faBuilding', label: 'Visakhapatnam (all airports), India', key: 'C_VTZ', type: 'city', code: 'visakhapatnam_in' } ],
 ['C_VBY', { icon: 'faBuilding', label: 'Visby (all airports), Sweden', key: 'C_VBY', type: 'city', code: 'visby_se' } ],
 ['C_VTB', { icon: 'faBuilding', label: 'Vitebsk (all airports), Belarus', key: 'C_VTB', type: 'city', code: 'vitebsk_by' } ],
 ['C_TLK', { icon: 'faBuilding', label: 'Vitim, Sakha Republic (all airports), Russia', key: 'C_TLK', type: 'city', code: 'talakan_ru' } ],
 ['C_VDC', { icon: 'faBuilding', label: 'Vitória da Conquista (all airports), Brazil', key: 'C_VDC', type: 'city', code: 'vitoria-da-conquista_ba_br' } ],
 ['C_VIT', { icon: 'faBuilding', label: 'Vitoria-Gasteiz (all airports), Spain', key: 'C_VIT', type: 'city', code: 'vitoria-gasteiz_es' } ],
 ['C_VIX', { icon: 'faBuilding', label: 'Vitória, Espírito Santo (all airports), Brazil', key: 'C_VIX', type: 'city', code: 'vitoria_es_br' } ],
 ['C_OGZ', { icon: 'faBuilding', label: 'Vladikavkaz (all airports), Russia', key: 'C_OGZ', type: 'city', code: 'vladikavkaz_ru' } ],
 ['C_VVO', { icon: 'faBuilding', label: 'Vladivostok (all airports), Russia', key: 'C_VVO', type: 'city', code: 'vladivostok_ru' } ],
 ['C_HOV', { icon: 'faBuilding', label: 'Volda (all airports), Norway', key: 'C_HOV', type: 'city', code: 'volda_no' } ],
 ['C_VOG', { icon: 'faBuilding', label: 'Volgograd (all airports), Russia', key: 'C_VOG', type: 'city', code: 'volgograd_ru' } ],
 ['C_VOL', { icon: 'faBuilding', label: 'Volos (all airports), Greece', key: 'C_VOL', type: 'city', code: 'volos_gr' } ],
 ['C_VKT', { icon: 'faBuilding', label: 'Vorkuta (all airports), Russia', key: 'C_VKT', type: 'city', code: 'vorkuta_ru' } ],
 ['C_VOZ', { icon: 'faBuilding', label: 'Voronezh (all airports), Russia', key: 'C_VOZ', type: 'city', code: 'voronezh_ru' } ],
 ['C_XVM', { icon: 'faBuilding', label: 'Vuokatti (all airports), Finland', key: 'C_XVM', type: 'city', code: 'vuokatti_fi' } ],
 ['C_YWK', { icon: 'faBuilding', label: 'Wabush (all airports), Canada', key: 'C_YWK', type: 'city', code: 'wabush_nl_ca' } ],
 ['C_ACT', { icon: 'faBuilding', label: 'Waco (all airports), United States', key: 'C_ACT', type: 'city', code: 'waco_tx_us' } ],
 ['C_WAE', { icon: 'faBuilding', label: 'Wadi ad-Dawasir (all airports), Saudi Arabia', key: 'C_WAE', type: 'city', code: 'wadi-ad-dawasir_sa' } ],
 ['C_WGA', { icon: 'faBuilding', label: 'Wagga Wagga (all airports), Australia', key: 'C_WGA', type: 'city', code: 'wagga-wagga_ns_au' } ],
 ['C_MUE', { icon: 'faBuilding', label: 'Waimea (all airports), United States', key: 'C_MUE', type: 'city', code: 'waimea_hi_us' } ],
 ['C_WGP', { icon: 'faBuilding', label: 'Waingapu (all airports), Indonesia', key: 'C_WGP', type: 'city', code: 'waingapu_id' } ],
 ['C_AIN', { icon: 'faBuilding', label: 'Wainwright (all airports), United States', key: 'C_AIN', type: 'city', code: 'wainwright_ak_us' } ],
 ['C_RJM', { icon: 'faBuilding', label: 'Waisai (all airports), Indonesia', key: 'C_RJM', type: 'city', code: 'waisai_id' } ],
 ['C_NTQ', { icon: 'faBuilding', label: 'Wajima (all airports), Japan', key: 'C_NTQ', type: 'city', code: 'wajima_jp' } ],
 ['C_WJR', { icon: 'faBuilding', label: 'Wajir (all airports), Kenya', key: 'C_WJR', type: 'city', code: 'wajir_ke' } ],
 ['C_XWD', { icon: 'faBuilding', label: 'Wakefield (all airports), United Kingdom', key: 'C_XWD', type: 'city', code: 'wakefield_gb' } ],
 ['C_WKJ', { icon: 'faBuilding', label: 'Wakkanai (all airports), Japan', key: 'C_WKJ', type: 'city', code: 'wakkanai_jp' } ],
 ['C_WLH', { icon: 'faBuilding', label: 'Walaha (all airports), Vanuatu', key: 'C_WLH', type: 'city', code: 'walaha_vu' } ],
 ['C_WAA', { icon: 'faBuilding', label: 'Wales (all airports), United States', key: 'C_WAA', type: 'city', code: 'wales_ak_us' } ],
 ['C_ALW', { icon: 'faBuilding', label: 'Walla Walla (all airports), United States', key: 'C_ALW', type: 'city', code: 'walla-walla_wa_us' } ],
 ['C_WLS', { icon: 'faBuilding', label: 'Wallis Island (all airports), Wallis & Futuna', key: 'C_WLS', type: 'city', code: 'uvea_wf' } ],
 ['C_WVB', { icon: 'faBuilding', label: 'Walvis Bay (all airports), Namibia', key: 'C_WVB', type: 'city', code: 'walvis-bay_na' } ],
 ['C_WMX', { icon: 'faBuilding', label: 'Wamena (all airports), Indonesia', key: 'C_WMX', type: 'city', code: 'wamena_id' } ],
 ['C_WXN', { icon: 'faBuilding', label: 'Wanzhou District (all airports), China', key: 'C_WXN', type: 'city', code: 'wanzhou-district_cn' } ],
 ['C_WBM', { icon: 'faBuilding', label: 'Wapenamanda District (all airports), Papua New Guinea', key: 'C_WBM', type: 'city', code: 'wapenamanda-district_pg' } ],
 ['C_QRW', { icon: 'faBuilding', label: 'Warri (all airports), Nigeria', key: 'C_QRW', type: 'city', code: 'warri_ng' } ],
 ['C_XWN', { icon: 'faBuilding', label: 'Warrington (all airports), United Kingdom', key: 'C_XWN', type: 'city', code: 'warrington_gb' } ],
 ['C_WMB', { icon: 'faBuilding', label: 'Warrnambool (all airports), Australia', key: 'C_WMB', type: 'city', code: 'warrnambool_vi_au' } ],
 ['C_WAW', { icon: 'faBuilding', label: 'Warsaw (all airports), Poland', key: 'C_WAW', type: 'city', code: 'warsaw_pl' } ],
 ['C_WAS', { icon: 'faBuilding', label: 'Washington, D.C. (all airports), United States', key: 'C_WAS', type: 'city', code: 'washington_dc_us' } ],
 ['C_YKQ', { icon: 'faBuilding', label: 'Waskaganish (all airports), Canada', key: 'C_YKQ', type: 'city', code: 'waskaganish_qc_ca' } ],
 ['C_KWF', { icon: 'faBuilding', label: 'Waterfall, Alaska (all airports), United States', key: 'C_KWF', type: 'city', code: 'waterfall_ak_us' } ],
 ['C_WAT', { icon: 'faBuilding', label: 'Waterford (all airports), Ireland', key: 'C_WAT', type: 'city', code: 'waterford_ie' } ],
 ['C_YKF', { icon: 'faBuilding', label: 'Waterloo (all airports), Canada', key: 'C_YKF', type: 'city', code: 'waterloo_on_ca' } ],
 ['C_ALO', { icon: 'faBuilding', label: 'Waterloo (all airports), United States', key: 'C_ALO', type: 'city', code: 'waterloo_ia_us' } ],
 ['C_ART', { icon: 'faBuilding', label: 'Watertown (all airports), United States', key: 'C_ART', type: 'city', code: 'watertown_ny_us' } ],
 ['C_ATY', { icon: 'faBuilding', label: 'Watertown (all airports), United States', key: 'C_ATY', type: 'city', code: 'watertown_sd_us' } ],
 ['C_AUW', { icon: 'faBuilding', label: 'Wausau (all airports), United States', key: 'C_AUW', type: 'city', code: 'wausau_wi_us' } ],
 ['C_ZRJ', { icon: 'faBuilding', label: 'Weagamow Lake (all airports), Canada', key: 'C_ZRJ', type: 'city', code: 'weagamow-lake_on_ca' } ],
 ['C_WRZ', { icon: 'faBuilding', label: 'Weerawila (all airports), Sri Lanka', key: 'C_WRZ', type: 'city', code: 'weerawila_lk' } ],
 ['C_WEF', { icon: 'faBuilding', label: 'Weifang (all airports), China', key: 'C_WEF', type: 'city', code: 'weifang_cn' } ],
 ['C_WEH', { icon: 'faBuilding', label: 'Weihai (all airports), China', key: 'C_WEH', type: 'city', code: 'weihai_cn' } ],
 ['C_WEI', { icon: 'faBuilding', label: 'Weipa (all airports), Australia', key: 'C_WEI', type: 'city', code: 'weipa_ql_au' } ],
 ['C_WLG', { icon: 'faBuilding', label: 'Wellington (all airports), New Zealand', key: 'C_WLG', type: 'city', code: 'wellington_nz' } ],
 ['C_YNC', { icon: 'faBuilding', label: 'Wemindji, Quebec (all airports), Canada', key: 'C_YNC', type: 'city', code: 'wemindji--quebec_qc_ca' } ],
 ['C_EAT', { icon: 'faBuilding', label: 'Wenatchee (all airports), United States', key: 'C_EAT', type: 'city', code: 'wenatchee_wa_us' } ],
 ['C_WNH', { icon: 'faBuilding', label: 'Wenshan City (all airports), China', key: 'C_WNH', type: 'city', code: 'wenshan-city_cn' } ],
 ['C_WNZ', { icon: 'faBuilding', label: 'Wenzhou (all airports), China', key: 'C_WNZ', type: 'city', code: 'wenzhou_cn' } ],
 ['C_PBI', { icon: 'faBuilding', label: 'West Palm Beach (all airports), United States', key: 'C_PBI', type: 'city', code: 'west-palm-beach_fl_us' } ],
 ['C_WSX', { icon: 'faBuilding', label: 'West Sound, Washington (all airports), United States', key: 'C_WSX', type: 'city', code: 'west-sound_wa_us' } ],
 ['C_WYS', { icon: 'faBuilding', label: 'West Yellowstone (all airports), United States', key: 'C_WYS', type: 'city', code: 'west-yellowstone_mt_us' } ],
 ['C_HPN', { icon: 'faBuilding', label: 'Westchester County, New York (all airports), United States', key: 'C_HPN', type: 'city', code: 'westchester-county_ny_us' } ],
 ['C_GWT', { icon: 'faBuilding', label: 'Westerland (all airports), Germany', key: 'C_GWT', type: 'city', code: 'westerland_de' } ],
 ['C_WSZ', { icon: 'faBuilding', label: 'Westport (all airports), New Zealand', key: 'C_WSZ', type: 'city', code: 'westport_nz' } ],
 ['C_WRY', { icon: 'faBuilding', label: 'Westray (all airports), United Kingdom', key: 'C_WRY', type: 'city', code: 'westray_gb' } ],
 ['C_WWK', { icon: 'faBuilding', label: 'Wewak (all airports), Papua New Guinea', key: 'C_WWK', type: 'city', code: 'wewak_pg' } ],
 ['C_YXN', { icon: 'faBuilding', label: 'Whale Cove, Nunavut (all airports), Canada', key: 'C_YXN', type: 'city', code: 'whale-cove_nu_ca' } ],
 ['C_WWP', { icon: 'faBuilding', label: 'Whale Pass (all airports), United States', key: 'C_WWP', type: 'city', code: 'whale-pass_ak_us' } ],
 ['C_WAG', { icon: 'faBuilding', label: 'Whanganui (all airports), New Zealand', key: 'C_WAG', type: 'city', code: 'whanganui_nz' } ],
 ['C_WRE', { icon: 'faBuilding', label: 'Whangarei (all airports), New Zealand', key: 'C_WRE', type: 'city', code: 'whangarei_nz' } ],
 ['C_WMO', { icon: 'faBuilding', label: 'White Mountain (all airports), United States', key: 'C_WMO', type: 'city', code: 'white-mountain_ak_us' } ],
 ['C_YXY', { icon: 'faBuilding', label: 'Whitehorse (all airports), Canada', key: 'C_YXY', type: 'city', code: 'whitehorse_yt_ca' } ],
 ['C_WYA', { icon: 'faBuilding', label: 'Whyalla (all airports), Australia', key: 'C_WYA', type: 'city', code: 'whyalla_sa_au' } ],
 ['C_ICT', { icon: 'faBuilding', label: 'Wichita (all airports), United States', key: 'C_ICT', type: 'city', code: 'wichita_ks_us' } ],
 ['C_SPS', { icon: 'faBuilding', label: 'Wichita Falls (all airports), United States', key: 'C_SPS', type: 'city', code: 'wichita-falls_tx_us' } ],
 ['C_WIC', { icon: 'faBuilding', label: 'Wick (all airports), United Kingdom', key: 'C_WIC', type: 'city', code: 'wick_gb' } ],
 ['C_UWE', { icon: 'faBuilding', label: 'Wiesbaden (all airports), Germany', key: 'C_UWE', type: 'city', code: 'wiesbaden_de' } ],
 ['C_XWI', { icon: 'faBuilding', label: 'Wigan (all airports), United Kingdom', key: 'C_XWI', type: 'city', code: 'wigan_gb' } ],
 ['C_AVP', { icon: 'faBuilding', label: 'Wilkes-Barre (all airports), United States', key: 'C_AVP', type: 'city', code: 'wilkes-barre_pa_us' } ],
 ['C_CUR', { icon: 'faBuilding', label: 'Willemstad (all airports), Curaçao', key: 'C_CUR', type: 'city', code: 'willemstad_cw' } ],
 ['C_YWL', { icon: 'faBuilding', label: 'Williams Lake (all airports), Canada', key: 'C_YWL', type: 'city', code: 'williams-lake_bc_ca' } ],
 ['C_IPT', { icon: 'faBuilding', label: 'Williamsport (all airports), United States', key: 'C_IPT', type: 'city', code: 'williamsport_pa_us' } ],
 ['C_ISN', { icon: 'faBuilding', label: 'Williston (all airports), United States', key: 'C_ISN', type: 'city', code: 'williston_nd_us' } ],
 ['C_ILM', { icon: 'faBuilding', label: 'Wilmington (all airports), United States', key: 'C_ILM', type: 'city', code: 'wilmington_nc_us' } ],
 ['C_WDH', { icon: 'faBuilding', label: 'Windhoek (all airports), Namibia', key: 'C_WDH', type: 'city', code: 'windhoek_na' } ],
 ['C_WNR', { icon: 'faBuilding', label: 'Windorah (all airports), Australia', key: 'C_WNR', type: 'city', code: 'windorah_ql_au' } ],
 ['C_YQG', { icon: 'faBuilding', label: 'Windsor (all airports), Canada', key: 'C_YQG', type: 'city', code: 'windsor_on_ca' } ],
 ['C_YWG', { icon: 'faBuilding', label: 'Winnipeg (all airports), Canada', key: 'C_YWG', type: 'city', code: 'winnipeg_mb_ca' } ],
 ['C_INT', { icon: 'faBuilding', label: 'Winston-Salem (all airports), United States', key: 'C_INT', type: 'city', code: 'winston-salem_nc_us' } ],
 ['C_ZLI', { icon: 'faBuilding', label: 'Winterthur (all airports), Switzerland', key: 'C_ZLI', type: 'city', code: 'winterthur_ch' } ],
 ['C_WIN', { icon: 'faBuilding', label: 'Winton (all airports), Australia', key: 'C_WIN', type: 'city', code: 'winton_ql_au' } ],
 ['C_OLF', { icon: 'faBuilding', label: 'Wolf Point (all airports), United States', key: 'C_OLF', type: 'city', code: 'wolf-point_mt_us' } ],
 ['C_WJU', { icon: 'faBuilding', label: 'Wonju (all airports), South Korea', key: 'C_WJU', type: 'city', code: 'wonju_kr' } ],
 ['C_ORH', { icon: 'faBuilding', label: 'Worcester (all airports), United States', key: 'C_ORH', type: 'city', code: 'worcester_ma_us' } ],
 ['C_WRG', { icon: 'faBuilding', label: 'Wrangell (all airports), United States', key: 'C_WRG', type: 'city', code: 'wrangell_ak_us' } ],
 ['C_WRO', { icon: 'faBuilding', label: 'Wrocław (all airports), Poland', key: 'C_WRO', type: 'city', code: 'wroclaw_pl' } ],
 ['C_WUA', { icon: 'faBuilding', label: 'Wuhai (all airports), China', key: 'C_WUA', type: 'city', code: 'wuhai_cn' } ],
 ['C_WUH', { icon: 'faBuilding', label: 'Wuhan (all airports), China', key: 'C_WUH', type: 'city', code: 'wuhan_cn' } ],
 ['C_WUX', { icon: 'faBuilding', label: 'Wuxi (all airports), China', key: 'C_WUX', type: 'city', code: 'wuxi_cn' } ],
 ['C_WUS', { icon: 'faBuilding', label: 'Wuyishan, Fujian (all airports), China', key: 'C_WUS', type: 'city', code: 'wuyishan_cn' } ],
 ['C_JAL', { icon: 'faBuilding', label: 'Xalapa (all airports), Mexico', key: 'C_JAL', type: 'city', code: 'xalapa_mx' } ],
 ['C_SIA', { icon: 'faBuilding', label: 'Xi An (all airports), China', key: 'C_SIA', type: 'city', code: 'xian_cn' } ],
 ['C_GXH', { icon: 'faBuilding', label: 'Xiahe County (all airports), China', key: 'C_GXH', type: 'city', code: 'xiahe-county_cn' } ],
 ['C_XMN', { icon: 'faBuilding', label: 'Xiamen (all airports), China', key: 'C_XMN', type: 'city', code: 'xiamen_cn' } ],
 ['C_XFN', { icon: 'faBuilding', label: 'Xiangyang (all airports), China', key: 'C_XFN', type: 'city', code: 'xiangyang_cn' } ],
 ['C_XIC', { icon: 'faBuilding', label: 'Xichang (all airports), China', key: 'C_XIC', type: 'city', code: 'xichang_cn' } ],
 ['C_XIL', { icon: 'faBuilding', label: 'Xilinhot (all airports), China', key: 'C_XIL', type: 'city', code: 'xilinhot_cn' } ],
 ['C_ACX', { icon: 'faBuilding', label: 'Xingyi, Guizhou (all airports), China', key: 'C_ACX', type: 'city', code: 'xingyi_cn' } ],
 ['C_XNN', { icon: 'faBuilding', label: 'Xining (all airports), China', key: 'C_XNN', type: 'city', code: 'xining_cn' } ],
 ['C_NLT', { icon: 'faBuilding', label: 'Xinyuan County (all airports), China', key: 'C_NLT', type: 'city', code: 'xinyuan-county_cn' } ],
 ['C_WUT', { icon: 'faBuilding', label: 'Xinzhou (all airports), China', key: 'C_WUT', type: 'city', code: 'xinzhou_cn' } ],
 ['C_XUZ', { icon: 'faBuilding', label: 'Xuzhou (all airports), China', key: 'C_XUZ', type: 'city', code: 'xuzhou_cn' } ],
 ['C_BYC', { icon: 'faBuilding', label: 'Yacuíba (all airports), Bolivia', key: 'C_BYC', type: 'city', code: 'yacuiba_bo' } ],
 ['C_DEX', { icon: 'faBuilding', label: 'Yahukimo Regency (all airports), Indonesia', key: 'C_DEX', type: 'city', code: 'yahukimo-regency_id' } ],
 ['C_YKM', { icon: 'faBuilding', label: 'Yakima (all airports), United States', key: 'C_YKM', type: 'city', code: 'yakima_wa_us' } ],
 ['C_YAK', { icon: 'faBuilding', label: 'Yakutat (all airports), United States', key: 'C_YAK', type: 'city', code: 'yakutat_ak_us' } ],
 ['C_YKS', { icon: 'faBuilding', label: 'Yakutsk (all airports), Russia', key: 'C_YKS', type: 'city', code: 'yakutsk_ru' } ],
 ['C_GAJ', { icon: 'faBuilding', label: 'Yamagata (all airports), Japan', key: 'C_GAJ', type: 'city', code: 'yamagata_jp' } ],
 ['C_ENY', { icon: 'faBuilding', label: 'Yan an (all airports), China', key: 'C_ENY', type: 'city', code: 'yanan_cn' } ],
 ['C_YNB', { icon: 'faBuilding', label: 'Yanbu (all airports), Saudi Arabia', key: 'C_YNB', type: 'city', code: 'yanbu_sa' } ],
 ['C_YNZ', { icon: 'faBuilding', label: 'Yancheng (all airports), China', key: 'C_YNZ', type: 'city', code: 'yancheng_cn' } ],
 ['C_RGN', { icon: 'faBuilding', label: 'Yangon (all airports), Myanmar (Burma)', key: 'C_RGN', type: 'city', code: 'yangon_mm' } ],
 ['C_YNY', { icon: 'faBuilding', label: 'Yangyang County (all airports), South Korea', key: 'C_YNY', type: 'city', code: 'yangyang-county_kr' } ],
 ['C_YTY', { icon: 'faBuilding', label: 'Yangzhou (all airports), China', key: 'C_YTY', type: 'city', code: 'yangzhou_cn' } ],
 ['C_YNJ', { icon: 'faBuilding', label: 'Yanji (all airports), China', key: 'C_YNJ', type: 'city', code: 'yanji_cn' } ],
 ['C_YNT', { icon: 'faBuilding', label: 'Yantai (all airports), China', key: 'C_YNT', type: 'city', code: 'yantai_cn' } ],
 ['C_YAO', { icon: 'faBuilding', label: 'Yaoundé (all airports), Cameroon', key: 'C_YAO', type: 'city', code: 'yaounde_cm' } ],
 ['C_YAP', { icon: 'faBuilding', label: 'Yap (all airports), Micronesia', key: 'C_YAP', type: 'city', code: 'yap_fm' } ],
 ['C_IAR', { icon: 'faBuilding', label: 'Yaroslavl (all airports), Russia', key: 'C_IAR', type: 'city', code: 'yaroslavl_ru' } ],
 ['C_AZD', { icon: 'faBuilding', label: 'Yazd (all airports), Iran', key: 'C_AZD', type: 'city', code: 'yazd_ir' } ],
 ['C_SVX', { icon: 'faBuilding', label: 'Yekaterinburg (all airports), Russia', key: 'C_SVX', type: 'city', code: 'yekaterinburg_ru' } ],
 ['C_YZF', { icon: 'faBuilding', label: 'Yellowknife (all airports), Canada', key: 'C_YZF', type: 'city', code: 'yellowknife_nt_ca' } ],
 ['C_RSU', { icon: 'faBuilding', label: 'Yeosu (all airports), South Korea', key: 'C_RSU', type: 'city', code: 'yeosu_kr' } ],
 ['C_ERG', { icon: 'faBuilding', label: 'Yerbogachen (all airports), Russia', key: 'C_ERG', type: 'city', code: 'yerbogachen_ru' } ],
 ['C_EVN', { icon: 'faBuilding', label: 'Yerevan (all airports), Armenia', key: 'C_EVN', type: 'city', code: 'yerevan_am' } ],
 ['C_YBP', { icon: 'faBuilding', label: 'Yibin (all airports), China', key: 'C_YBP', type: 'city', code: 'yibin_cn' } ],
 ['C_YIH', { icon: 'faBuilding', label: 'Yichang (all airports), China', key: 'C_YIH', type: 'city', code: 'yichang_cn' } ],
 ['C_LDS', { icon: 'faBuilding', label: 'Yichun, Heilongjiang (all airports), China', key: 'C_LDS', type: 'city', code: 'yichun-heilongjiang_cn' } ],
 ['C_YIC', { icon: 'faBuilding', label: 'Yichun, Jiangxi (all airports), China', key: 'C_YIC', type: 'city', code: 'yichun-jiangxi_cn' } ],
 ['C_INC', { icon: 'faBuilding', label: 'Yinchuan (all airports), China', key: 'C_INC', type: 'city', code: 'yinchuan_cn' } ],
 ['C_YKH', { icon: 'faBuilding', label: 'Yingkou (all airports), China', key: 'C_YKH', type: 'city', code: 'yingkou_cn' } ],
 ['C_YIW', { icon: 'faBuilding', label: 'Yiwu (all airports), China', key: 'C_YIW', type: 'city', code: 'yiwu_cn' } ],
 ['C_VLY', { icon: 'faBuilding', label: 'Ynys Môn (all airports), United Kingdom', key: 'C_VLY', type: 'city', code: 'ynys-mon_gb' } ],
 ['C_JOG', { icon: 'faBuilding', label: 'Yogyakarta (all airports), Indonesia', key: 'C_JOG', type: 'city', code: 'yogyakarta_id' } ],
 ['C_YOL', { icon: 'faBuilding', label: 'Yola (all airports), Nigeria', key: 'C_YOL', type: 'city', code: 'yola_ng' } ],
 ['C_YGJ', { icon: 'faBuilding', label: 'Yonago (all airports), Japan', key: 'C_YGJ', type: 'city', code: 'yonago_jp' } ],
 ['C_LLF', { icon: 'faBuilding', label: 'Yongzhou (all airports), China', key: 'C_LLF', type: 'city', code: 'yongzhou_cn' } ],
 ['C_EYP', { icon: 'faBuilding', label: 'Yopal (all airports), Colombia', key: 'C_EYP', type: 'city', code: 'yopal_co' } ],
 ['C_ZAC', { icon: 'faBuilding', label: 'York Factory First Nation (all airports), Canada', key: 'C_ZAC', type: 'city', code: 'york-factory-first-nation_mb_ca' } ],
 ['C_JOK', { icon: 'faBuilding', label: 'Yoshkar-Ola (all airports), Russia', key: 'C_JOK', type: 'city', code: 'yoshkar-ola_ru' } ],
 ['C_YNG', { icon: 'faBuilding', label: 'Youngstown (all airports), United States', key: 'C_YNG', type: 'city', code: 'youngstown_oh_us' } ],
 ['C_UYN', { icon: 'faBuilding', label: 'Yulin (all airports), China', key: 'C_UYN', type: 'city', code: 'yulin_cn' } ],
 ['C_YUM', { icon: 'faBuilding', label: 'Yuma (all airports), United States', key: 'C_YUM', type: 'city', code: 'yuma_az_us' } ],
 ['C_YCU', { icon: 'faBuilding', label: 'Yuncheng (all airports), China', key: 'C_YCU', type: 'city', code: 'yuncheng_cn' } ],
 ['C_YUS', { icon: 'faBuilding', label: 'Yushu City, Qinghai (all airports), China', key: 'C_YUS', type: 'city', code: 'yushu-city_cn' } ],
 ['C_DEE', { icon: 'faBuilding', label: 'Yuzhno-Kurilsk (all airports), Unknown Region', key: 'C_DEE', type: 'city', code: 'yuzhno-kurilsk_zz' } ],
 ['C_UUS', { icon: 'faBuilding', label: 'Yuzhno-Sakhalinsk (all airports), Russia', key: 'C_UUS', type: 'city', code: 'yuzhno-sakhalinsk_ru' } ],
 ['C_ACZ', { icon: 'faBuilding', label: 'Zabol (all airports), Iran', key: 'C_ACZ', type: 'city', code: 'zabol_ir' } ],
 ['C_ZCL', { icon: 'faBuilding', label: 'Zacatecas (all airports), Mexico', key: 'C_ZCL', type: 'city', code: 'zacatecas_mx' } ],
 ['C_ZAD', { icon: 'faBuilding', label: 'Zadar (all airports), Croatia', key: 'C_ZAD', type: 'city', code: 'zadar_hr' } ],
 ['C_OZG', { icon: 'faBuilding', label: 'Zagora (all airports), Morocco', key: 'C_OZG', type: 'city', code: 'zagora_ma' } ],
 ['C_ZAG', { icon: 'faBuilding', label: 'Zagreb (all airports), Croatia', key: 'C_ZAG', type: 'city', code: 'zagreb_hr' } ],
 ['C_ZAH', { icon: 'faBuilding', label: 'Zahedan (all airports), Iran', key: 'C_ZAH', type: 'city', code: 'zahedan_ir' } ],
 ['C_ZTH', { icon: 'faBuilding', label: 'Zakynthos Island (all airports), Greece', key: 'C_ZTH', type: 'city', code: 'zakynthos-island_gr' } ],
 ['C_ZAM', { icon: 'faBuilding', label: 'Zamboanga City (all airports), Philippines', key: 'C_ZAM', type: 'city', code: 'zamboanga-city_ph' } ],
 ['C_ZNZ', { icon: 'faBuilding', label: 'Zanzibar (all airports), Tanzania', key: 'C_ZNZ', type: 'city', code: 'zanzibar_tz' } ],
 ['C_OZH', { icon: 'faBuilding', label: 'Zaporizhia (all airports), Ukraine', key: 'C_OZH', type: 'city', code: 'zaporizhia_ua' } ],
 ['C_ZAZ', { icon: 'faBuilding', label: 'Zaragoza (all airports), Spain', key: 'C_ZAZ', type: 'city', code: 'zaragoza_es' } ],
 ['C_ZAJ', { icon: 'faBuilding', label: 'Zaranj (all airports), Afghanistan', key: 'C_ZAJ', type: 'city', code: 'zaranj_af' } ],
 ['C_SZI', { icon: 'faBuilding', label: 'Zaysan (all airports), Kazakhstan', key: 'C_SZI', type: 'city', code: 'zaysan_kz' } ],
 ['C_DYG', { icon: 'faBuilding', label: 'Zhangjiajie (all airports), China', key: 'C_DYG', type: 'city', code: 'zhangjiajie_cn' } ],
 ['C_ZQZ', { icon: 'faBuilding', label: 'Zhangjiakou (all airports), China', key: 'C_ZQZ', type: 'city', code: 'zhangjiakou_cn' } ],
 ['C_YZY', { icon: 'faBuilding', label: 'Zhangye (all airports), China', key: 'C_YZY', type: 'city', code: 'zhangye_cn' } ],
 ['C_ZHA', { icon: 'faBuilding', label: 'Zhanjiang (all airports), China', key: 'C_ZHA', type: 'city', code: 'zhanjiang_cn' } ],
 ['C_ZAT', { icon: 'faBuilding', label: 'Zhaotong (all airports), China', key: 'C_ZAT', type: 'city', code: 'zhaotong_cn' } ],
 ['C_CGO', { icon: 'faBuilding', label: 'Zhengzhou (all airports), China', key: 'C_CGO', type: 'city', code: 'zhengzhou_cn' } ],
 ['C_ZIX', { icon: 'faBuilding', label: 'Zhigansk (all airports), Russia', key: 'C_ZIX', type: 'city', code: 'zhigansk_ru' } ],
 ['C_PZH', { icon: 'faBuilding', label: 'Zhob (all airports), Pakistan', key: 'C_PZH', type: 'city', code: 'zhob_pk' } ],
 ['C_ZHY', { icon: 'faBuilding', label: 'Zhongwei (all airports), China', key: 'C_ZHY', type: 'city', code: 'zhongwei_cn' } ],
 ['C_HSN', { icon: 'faBuilding', label: 'Zhoushan (all airports), China', key: 'C_HSN', type: 'city', code: 'zhoushan_cn' } ],
 ['C_ZUH', { icon: 'faBuilding', label: 'Zhuhai (all airports), China', key: 'C_ZUH', type: 'city', code: 'zhuhai_cn' } ],
 ['C_IEG', { icon: 'faBuilding', label: 'Zielona Góra (all airports), Poland', key: 'C_IEG', type: 'city', code: 'zielona-gora_pl' } ],
 ['C_ZIG', { icon: 'faBuilding', label: 'Ziguinchor (all airports), Senegal', key: 'C_ZIG', type: 'city', code: 'ziguinchor_sn' } ],
 ['C_ZND', { icon: 'faBuilding', label: 'Zinder (all airports), Niger', key: 'C_ZND', type: 'city', code: 'zinder_ne' } ],
 ['C_ZIS', { icon: 'faBuilding', label: 'Zintan (all airports), Libya', key: 'C_ZIS', type: 'city', code: 'zintan_ly' } ],
 ['C_ONQ', { icon: 'faBuilding', label: 'Zonguldak (all airports), Turkey', key: 'C_ONQ', type: 'city', code: 'zonguldak_tr' } ],
 ['C_OUZ', { icon: 'faBuilding', label: 'Zouérat (all airports), Mauritania', key: 'C_OUZ', type: 'city', code: 'zouerat_mr' } ],
 ['C_ZYI', { icon: 'faBuilding', label: 'Zunyi (all airports), China', key: 'C_ZYI', type: 'city', code: 'zunyi_cn' } ],
 ['C_ZRH', { icon: 'faBuilding', label: 'Zürich (all airports), Switzerland', key: 'C_ZRH', type: 'city', code: 'zurich_ch' } ],
 ['C_ZKP', { icon: 'faBuilding', label: 'Zyryanka, Sakha Republic (all airports), Russia', key: 'C_ZKP', type: 'city', code: 'zyryanka_ru' } ],
]);
const listAirports = [
  { icon: 'faPlaneDeparture', label: 'LCG A Coruña, Spain, A Coruña, LCG, a coruna a coruna spain 1', key:'A_LCG', cityBase: 17, city: 'LCG', country: 'ES', type: 'airport', nlabel: 'a coruna a coruna spain 1'},
  { icon: 'faPlaneDeparture', label: 'AAL Aalborg, Denmark, Aalborg, AAL, aalborg aalborg denmark', key:'A_AAL', cityBase: 64, city: 'AAL', country: 'DK', type: 'airport', nlabel: 'aalborg aalborg denmark'},
  { icon: 'faPlaneDeparture', label: 'AAR Aarhus, Denmark, Aarhus, AAR, aarhus aarhus denmark', key:'A_AAR', cityBase: 38, city: 'AAR', country: 'DK', type: 'airport', nlabel: 'aarhus aarhus denmark'},
  { icon: 'faPlaneDeparture', label: 'JEG Aasiaat, Greenland, Aasiaat, JEG, aasiaat aasiaat greenland', key:'A_JEG', cityBase: 127, city: 'JEG', country: 'GL', type: 'airport', nlabel: 'aasiaat aasiaat greenland'},
  { icon: 'faPlaneDeparture', label: 'JIM Jimma, Ethiopia, Aba Segud, JIM, aba segud jimma ethiopia', key:'A_JIM', cityBase: 127, city: 'JIM', country: 'ET', type: 'airport', nlabel: 'aba segud jimma ethiopia'},
  { icon: 'faPlaneDeparture', label: 'DIR Dire Dawa, Ethiopia, Aba Tenna Dejazmach Yilma International, DIR, aba tenna dejazmach yilma international dire dawa ethiopia', key:'A_DIR', cityBase: 127, city: 'DIR', country: 'ET', type: 'airport', nlabel: 'aba tenna dejazmach yilma international dire dawa ethiopia'},
  { icon: 'faPlaneDeparture', label: 'ABD Abadan, Iran, Abadan, ABD, abadan abadan iran', key:'A_ABD', cityBase: 127, city: 'ABD', country: 'IR', type: 'airport', nlabel: 'abadan abadan iran'},
  { icon: 'faPlaneDeparture', label: 'ABA Abakan, Russia, Abakan International, ABA, abakan international abakan russia', key:'A_ABA', cityBase: 47, city: 'ABA', country: 'RU', type: 'airport', nlabel: 'abakan international abakan russia'},
  { icon: 'faPlaneDeparture', label: 'BJA Béjaïa, Algeria, Abane Ramdane, BJA, abane ramdane bejaia algeria', key:'A_BJA', cityBase: 127, city: 'BJA', country: 'DZ', type: 'airport', nlabel: 'abane ramdane bejaia algeria'},
  { icon: 'faPlaneDeparture', label: 'YXX Abbotsford, Canada, Abbotsford International, YXX, abbotsford international abbotsford british columbia canada', key:'A_YXX', cityBase: 82, city: 'YXX', country: 'CA', type: 'airport', nlabel: 'abbotsford international abbotsford british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'MLG Malang, Indonesia, Abdul Rachman Saleh, MLG, abdul rachman saleh malang indonesia', key:'A_MLG', cityBase: 99, city: 'MLG', country: 'ID', type: 'airport', nlabel: 'abdul rachman saleh malang indonesia'},
  { icon: 'faPlaneDeparture', label: 'GLK Galkayo, Somalia, Abdullahi Yusuf, GLK, abdullahi yusuf galkayo somalia', key:'A_GLK', cityBase: 127, city: 'GLK', country: 'SO', type: 'airport', nlabel: 'abdullahi yusuf galkayo somalia'},
  { icon: 'faPlaneDeparture', label: 'ZNZ Zanzibar, Tanzania, Abeid Amani Karume International, ZNZ, abeid amani karume international zanzibar tanzania', key:'A_ZNZ', cityBase: 41, city: 'ZNZ', country: 'TZ', type: 'airport', nlabel: 'abeid amani karume international zanzibar tanzania'},
  { icon: 'faPlaneDeparture', label: 'SNU Santa Clara, Cuba, Abel Santamaría, SNU, abel santamaria santa clara cuba', key:'A_SNU', cityBase: 38, city: 'SNU', country: 'CU', type: 'airport', nlabel: 'abel santamaria santa clara cuba'},
  { icon: 'faPlaneDeparture', label: 'ABZ Aberdeen, United Kingdom, Aberdeen, ABZ, aberdeen aberdeen united kingdom', key:'A_ABZ', cityBase: -987, city: 'ABZ', country: 'GB', type: 'airport', nlabel: 'aberdeen aberdeen united kingdom'},
  { icon: 'faPlaneDeparture', label: 'ABR Aberdeen, United States, Aberdeen Regional, ABR, aberdeen regional aberdeen south dakota united states', key:'A_ABR', cityBase: 96, city: 'ABR', country: 'US', type: 'airport', nlabel: 'aberdeen regional aberdeen south dakota united states'},
  { icon: 'faPlaneDeparture', label: 'AHB Abha, Saudi Arabia, Abha Regional, AHB, abha regional abha saudi arabia', key:'A_AHB', cityBase: 84, city: 'AHB', country: 'SA', type: 'airport', nlabel: 'abha regional abha saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'ABI Abilene, United States, Abilene Regional, ABI, abilene regional abilene texas united states', key:'A_ABI', cityBase: 87, city: 'ABI', country: 'US', type: 'airport', nlabel: 'abilene regional abilene texas united states'},
  { icon: 'faPlaneDeparture', label: 'SPI Springfield, United States, Abraham Lincoln Capital, SPI, abraham lincoln capital springfield illinois united states', key:'A_SPI', cityBase: 76, city: 'SPI', country: 'US', type: 'airport', nlabel: 'abraham lincoln capital springfield illinois united states'},
  { icon: 'faPlaneDeparture', label: 'PSR Pescara, Italy, Abruzzo, PSR, abruzzo pescara italy', key:'A_PSR', cityBase: 18, city: 'PSR', country: 'IT', type: 'airport', nlabel: 'abruzzo pescara italy'},
  { icon: 'faPlaneDeparture', label: 'AUH Abu Dhabi, United Arab Emirates, Abu Dhabi International, AUH, abu dhabi international abu dhabi united arab emirates', key:'A_AUH', cityBase: 10, city: 'AUH', country: 'AE', type: 'airport', nlabel: 'abu dhabi international abu dhabi united arab emirates'},
  { icon: 'faPlaneDeparture', label: 'ABS Abu Simbel, Egypt, Abu Simbel, ABS, abu simbel abu simbel egypt', key:'A_ABS', cityBase: 100, city: 'ABS', country: 'EG', type: 'airport', nlabel: 'abu simbel abu simbel egypt'},
  { icon: 'faPlaneDeparture', label: 'ACA Acapulco, Mexico, Acapulco International, ACA, acapulco international acapulco mexico', key:'A_ACA', cityBase: 44, city: 'ACA', country: 'MX', type: 'airport', nlabel: 'acapulco international acapulco mexico'},
  { icon: 'faPlaneDeparture', label: 'ACU Achutupo, Panama, Achutupo, ACU, achutupo achutupo panama', key:'A_ACU', cityBase: 127, city: 'ACU', country: 'PA', type: 'airport', nlabel: 'achutupo achutupo panama'},
  { icon: 'faPlaneDeparture', label: 'AAD Adado, Somalia, Adado, AAD, adado adado somalia', key:'A_AAD', cityBase: 127, city: 'AAD', country: 'SO', type: 'airport', nlabel: 'adado adado somalia'},
  { icon: 'faPlaneDeparture', label: 'ADK Adak Island, United States, Adak, ADK, adak adak island alaska united states', key:'A_ADK', cityBase: 127, city: 'ADK', country: 'US', type: 'airport', nlabel: 'adak adak island alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ADA Adana, Turkey, Adana Şakirpaşa, ADA, adana sakirpasa adana turkey', key:'A_ADA', cityBase: 14, city: 'ADA', country: 'TR', type: 'airport', nlabel: 'adana sakirpasa adana turkey'},
  { icon: 'faPlaneDeparture', label: 'ADD Addis Ababa, Ethiopia, Addis Ababa Bole International, ADD, addis ababa bole international addis ababa ethiopia', key:'A_ADD', cityBase: 34, city: 'ADD', country: 'ET', type: 'airport', nlabel: 'addis ababa bole international addis ababa ethiopia'},
  { icon: 'faPlaneDeparture', label: 'ADS Dallas, United States, Addison Airport, ADS, addison airport dallas texas united states', key:'A_ADS', cityBase: 118, city: 'DFW', country: 'US', type: 'airport', nlabel: 'addison airport dallas texas united states'},
  { icon: 'faPlaneDeparture', label: 'ADL Adelaide, Australia, Adelaide, ADL, adelaide adelaide south australia australia', key:'A_ADL', cityBase: 42, city: 'ADL', country: 'AU', type: 'airport', nlabel: 'adelaide adelaide south australia australia'},
  { icon: 'faPlaneDeparture', label: 'MGQ Mogadishu, Somalia, Aden Adde International, MGQ, aden adde international mogadishu somalia', key:'A_MGQ', cityBase: 95, city: 'MGQ', country: 'SO', type: 'airport', nlabel: 'aden adde international mogadishu somalia'},
  { icon: 'faPlaneDeparture', label: 'ADE Aden, Yemen, Aden International Airport, ADE, aden international airport aden yemen', key:'A_ADE', cityBase: 127, city: 'ADE', country: 'YE', type: 'airport', nlabel: 'aden international airport aden yemen'},
  { icon: 'faPlaneDeparture', label: 'SLK Saranac Lake, United States, Adirondack Regional, SLK, adirondack regional saranac lake new york united states', key:'A_SLK', cityBase: 98, city: 'SLK', country: 'US', type: 'airport', nlabel: 'adirondack regional saranac lake new york united states'},
  { icon: 'faPlaneDeparture', label: 'SOC Surakarta, Indonesia, Adisumarmo International, SOC, adisumarmo international surakarta indonesia', key:'A_SOC', cityBase: 127, city: 'SOC', country: 'ID', type: 'airport', nlabel: 'adisumarmo international surakarta indonesia'},
  { icon: 'faPlaneDeparture', label: 'JOG Yogyakarta, Indonesia, Adisutjipto International, JOG, adisutjipto international yogyakarta indonesia', key:'A_JOG', cityBase: 54, city: 'JOG', country: 'ID', type: 'airport', nlabel: 'adisutjipto international yogyakarta indonesia'},
  { icon: 'faPlaneDeparture', label: 'ADF Adıyaman, Turkey, Adıyaman, ADF, adiyaman adiyaman turkey', key:'A_ADF', cityBase: 27, city: 'ADF', country: 'TR', type: 'airport', nlabel: 'adiyaman adiyaman turkey'},
  { icon: 'faPlaneDeparture', label: 'ADB İzmir, Turkey, Adnan Menderes, ADB, adnan menderes izmir turkey', key:'A_ADB', cityBase: 11, city: 'IZM', country: 'TR', type: 'airport', nlabel: 'adnan menderes izmir turkey'},
  { icon: 'faPlaneDeparture', label: 'MAD Madrid, Spain, Adolfo Suárez Madrid–Barajas, MAD, adolfo suarez madrid barajas madrid spain', key:'A_MAD', cityBase: 1, city: 'MAD', country: 'ES', type: 'airport', nlabel: 'adolfo suarez madrid barajas madrid spain'},
  { icon: 'faPlaneDeparture', label: 'AEG Padangsidempuan, Indonesia, Aek Godang, AEG, aek godang padangsidempuan indonesia', key:'A_AEG', cityBase: 127, city: 'AEG', country: 'ID', type: 'airport', nlabel: 'aek godang padangsidempuan indonesia'},
  { icon: 'faPlaneDeparture', label: 'AEP Buenos Aires, Argentina, Aeroparque Jorge Newbery, AEP, aeroparque jorge newbery buenos aires buenos aires argentina', key:'A_AEP', cityBase: 54, city: 'BUE', country: 'AR', type: 'airport', nlabel: 'aeroparque jorge newbery buenos aires buenos aires argentina'},
  { icon: 'faPlaneDeparture', label: 'CWB Curitiba, Brazil, Afonso Pena International, CWB, afonso pena international curitiba state of parana brazil', key:'A_CWB', cityBase: 63, city: 'CWB', country: 'BR', type: 'airport', nlabel: 'afonso pena international curitiba state of parana brazil'},
  { icon: 'faPlaneDeparture', label: 'AGA Agadir, Morocco, Agadir–Al Massira, AGA, agadir al massira agadir morocco', key:'A_AGA', cityBase: 23, city: 'AGA', country: 'MA', type: 'airport', nlabel: 'agadir al massira agadir morocco'},
  { icon: 'faPlaneDeparture', label: 'IXA Agartala, India, Agartala, IXA, agartala agartala india', key:'A_IXA', cityBase: 107, city: 'IXA', country: 'IN', type: 'airport', nlabel: 'agartala agartala india'},
  { icon: 'faPlaneDeparture', label: 'AGX Agatti Island, India, Agatti Aerodrome, AGX, agatti aerodrome agatti island india', key:'A_AGX', cityBase: 127, city: 'AGX', country: 'IN', type: 'airport', nlabel: 'agatti aerodrome agatti island india'},
  { icon: 'faPlaneDeparture', label: 'AGF Agen, France, Agen La Garenne, AGF, agen la garenne agen france', key:'A_AGF', cityBase: 124, city: 'AGF', country: 'FR', type: 'airport', nlabel: 'agen la garenne agen france'},
  { icon: 'faPlaneDeparture', label: 'AGR Agra, India, Agra, AGR, agra agra india', key:'A_AGR', cityBase: 98, city: 'AGR', country: 'IN', type: 'airport', nlabel: 'agra agra india'},
  { icon: 'faPlaneDeparture', label: 'AJI Ağrı, Turkey, Ağrı, AJI, agri agri turkey', key:'A_AJI', cityBase: 26, city: 'AJI', country: 'TR', type: 'airport', nlabel: 'agri agri turkey'},
  { icon: 'faPlaneDeparture', label: 'BQN Aguadilla, United States, Aguadilla Rafael Hernandez, BQN, aguadilla rafael hernandez aguadilla puerto rico united states', key:'A_BQN', cityBase: 34, city: 'BQN', country: 'US', type: 'airport', nlabel: 'aguadilla rafael hernandez aguadilla puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'AGU Aguascalientes, Mexico, Aguascalientes International, AGU, aguascalientes international aguascalientes mexico', key:'A_AGU', cityBase: 40, city: 'AGU', country: 'MX', type: 'airport', nlabel: 'aguascalientes international aguascalientes mexico'},
  { icon: 'faPlaneDeparture', label: 'TMR Tamanrasset, Algeria, Aguenar – Hadj Bey Akhamok, TMR, aguenar hadj bey akhamok tamanrasset algeria', key:'A_TMR', cityBase: 127, city: 'TMR', country: 'DZ', type: 'airport', nlabel: 'aguenar hadj bey akhamok tamanrasset algeria'},
  { icon: 'faPlaneDeparture', label: 'AHE Ahe, French Polynesia, Ahe, AHE, ahe ahe french polynesia', key:'A_AHE', cityBase: 127, city: 'AHE', country: 'PF', type: 'airport', nlabel: 'ahe ahe french polynesia'},
  { icon: 'faPlaneDeparture', label: 'SRG Semarang, Indonesia, Ahmad Yani International, SRG, ahmad yani international semarang indonesia', key:'A_SRG', cityBase: 81, city: 'SRG', country: 'ID', type: 'airport', nlabel: 'ahmad yani international semarang indonesia'},
  { icon: 'faPlaneDeparture', label: 'AWZ Ahvaz, Iran, Ahvaz International, AWZ, ahvaz international ahvaz iran', key:'A_AWZ', cityBase: 70, city: 'AWZ', country: 'IR', type: 'airport', nlabel: 'ahvaz international ahvaz iran'},
  { icon: 'faPlaneDeparture', label: 'QSF Sétif, Algeria, Ain Arnat, QSF, ain arnat setif algeria', key:'A_QSF', cityBase: 127, city: 'QSF', country: 'DZ', type: 'airport', nlabel: 'ain arnat setif algeria'},
  { icon: 'faPlaneDeparture', label: 'OGX Ouargla, Algeria, Ain Beida, OGX, ain beida ouargla algeria', key:'A_OGX', cityBase: 127, city: 'OGX', country: 'DZ', type: 'airport', nlabel: 'ain beida ouargla algeria'},
  { icon: 'faPlaneDeparture', label: 'HDS Hoedspruit, Limpopo, South Africa, Air Force Base Hoedspruit, HDS, air force base hoedspruit hoedspruit south africa', key:'A_HDS', cityBase: 58, city: 'HDS', country: 'ZA', type: 'airport', nlabel: 'air force base hoedspruit hoedspruit south africa'},
  { icon: 'faPlaneDeparture', label: 'AIT Aitutaki, Cook Islands, Aitutaki, AIT, aitutaki aitutaki cook islands', key:'A_AIT', cityBase: 109, city: 'AIT', country: 'CK', type: 'airport', nlabel: 'aitutaki aitutaki cook islands'},
  { icon: 'faPlaneDeparture', label: 'AJA Ajaccio, France, Ajaccio Napoleon Bonaparte, AJA, ajaccio napoleon bonaparte ajaccio france', key:'A_AJA', cityBase: 19, city: 'AJA', country: 'FR', type: 'airport', nlabel: 'ajaccio napoleon bonaparte ajaccio france'},
  { icon: 'faPlaneDeparture', label: 'ENU Enugu, Nigeria, Akanu Ibiam International, ENU, akanu ibiam international enugu nigeria', key:'A_ENU', cityBase: 105, city: 'ENU', country: 'NG', type: 'airport', nlabel: 'akanu ibiam international enugu nigeria'},
  { icon: 'faPlaneDeparture', label: 'AKK Akhiok, United States, Akhiok, AKK, akhiok akhiok alaska united states', key:'A_AKK', cityBase: 127, city: 'AKK', country: 'US', type: 'airport', nlabel: 'akhiok akhiok alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KKI Akiachak, United States, Akiachak, KKI, akiachak akiachak alaska united states', key:'A_KKI', cityBase: 127, city: 'KKI', country: 'US', type: 'airport', nlabel: 'akiachak akiachak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AXT Akita, Japan, Akita, AXT, akita akita japan', key:'A_AXT', cityBase: 126, city: 'AXT', country: 'JP', type: 'airport', nlabel: 'akita akita japan'},
  { icon: 'faPlaneDeparture', label: 'LAK Aklavik, Canada, Aklavik/Freddie Carmichael, LAK, aklavik freddie carmichael aklavik northwest territories canada', key:'A_LAK', cityBase: 127, city: 'LAK', country: 'CA', type: 'airport', nlabel: 'aklavik freddie carmichael aklavik northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'CAK Akron, United States, Akron–Canton, CAK, akron canton akron ohio united states', key:'A_CAK', cityBase: 22, city: 'CAK', country: 'US', type: 'airport', nlabel: 'akron canton akron ohio united states'},
  { icon: 'faPlaneDeparture', label: 'AKU Aksu City, China, Aksu, AKU, aksu aksu city china', key:'A_AKU', cityBase: 67, city: 'AKU', country: 'CN', type: 'airport', nlabel: 'aksu aksu city china'},
  { icon: 'faPlaneDeparture', label: 'SCO Aktau, Kazakhstan, Aktau, SCO, aktau aktau kazakhstan', key:'A_SCO', cityBase: 99, city: 'SCO', country: 'KZ', type: 'airport', nlabel: 'aktau aktau kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'PVK Preveza, Greece, Aktion National, PVK, aktion national preveza greece', key:'A_PVK', cityBase: 16, city: 'PVK', country: 'GR', type: 'airport', nlabel: 'aktion national preveza greece'},
  { icon: 'faPlaneDeparture', label: 'AKX Aktobe, Kazakhstan, Aktobe, AKX, aktobe aktobe kazakhstan', key:'A_AKX', cityBase: 109, city: 'AKX', country: 'KZ', type: 'airport', nlabel: 'aktobe aktobe kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'AKV Akulivik, Canada, Akulivik, AKV, akulivik akulivik northwest territories canada', key:'A_AKV', cityBase: 127, city: 'AKV', country: 'CA', type: 'airport', nlabel: 'akulivik akulivik northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'AKR Akure, Nigeria, Akure, AKR, akure akure nigeria', key:'A_AKR', cityBase: 127, city: 'AKR', country: 'NG', type: 'airport', nlabel: 'akure akure nigeria'},
  { icon: 'faPlaneDeparture', label: 'AEY Akureyri, Iceland, Akureyri, AEY, akureyri akureyri iceland', key:'A_AEY', cityBase: 66, city: 'AEY', country: 'IS', type: 'airport', nlabel: 'akureyri akureyri iceland'},
  { icon: 'faPlaneDeparture', label: 'KQA Akutan, United States, Akutan Seaplane Base, KQA, akutan seaplane base akutan alaska united states', key:'A_KQA', cityBase: 127, city: 'KQA', country: 'US', type: 'airport', nlabel: 'akutan seaplane base akutan alaska united states'},
  { icon: 'faPlaneDeparture', label: 'QUO Uyo, Nigeria, Akwa Ibom, QUO, akwa ibom uyo nigeria', key:'A_QUO', cityBase: 127, city: 'QUO', country: 'NG', type: 'airport', nlabel: 'akwa ibom uyo nigeria'},
  { icon: 'faPlaneDeparture', label: 'LAQ Al Bayda , Libya, Al Abraq International, LAQ, al abraq international al bayda libya', key:'A_LAQ', cityBase: 127, city: 'LAQ', country: 'LY', type: 'airport', nlabel: 'al abraq international al bayda libya'},
  { icon: 'faPlaneDeparture', label: 'AAN Al Ain, United Arab Emirates, Al Ain International, AAN, al ain international al ain united arab emirates', key:'A_AAN', cityBase: 105, city: 'AAN', country: 'AE', type: 'airport', nlabel: 'al ain international al ain united arab emirates'},
  { icon: 'faPlaneDeparture', label: 'DWC Dubai, United Arab Emirates, Al Maktoum International, DWC, al maktoum international dubai united arab emirates', key:'A_DWC', cityBase: 82, city: 'DXB', country: 'AE', type: 'airport', nlabel: 'al maktoum international dubai united arab emirates'},
  { icon: 'faPlaneDeparture', label: 'NJF Najaf, Iraq, Al Najaf International, NJF, al najaf international najaf iraq', key:'A_NJF', cityBase: 78, city: 'NJF', country: 'IQ', type: 'airport', nlabel: 'al najaf international najaf iraq'},
  { icon: 'faPlaneDeparture', label: 'AQI Qaisumah, Saudi Arabia, Al Qaisumah/Hafr Al Batin, AQI, al qaisumah hafr al batin qaisumah saudi arabia', key:'A_AQI', cityBase: 108, city: 'AQI', country: 'SA', type: 'airport', nlabel: 'al qaisumah hafr al batin qaisumah saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'EJH Al Wajh, Saudi Arabia, Al Wajh Domestic, EJH, al wajh domestic al wajh saudi arabia', key:'A_EJH', cityBase: 127, city: 'EJH', country: 'SA', type: 'airport', nlabel: 'al wajh domestic al wajh saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'HOF Hofuf, Saudi Arabia, Al-Ahsa International, HOF, al ahsa international hofuf saudi arabia', key:'A_HOF', cityBase: 111, city: 'HOF', country: 'SA', type: 'airport', nlabel: 'al ahsa international hofuf saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'ABT Al Bahah, Saudi Arabia, Al-Baha Domestic, ABT, al baha domestic al bahah saudi arabia', key:'A_ABT', cityBase: 119, city: 'ABT', country: 'SA', type: 'airport', nlabel: 'al baha domestic al bahah saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'AJF Al Jawf Region, Saudi Arabia, Al-Jawf Domestic, AJF, al jawf domestic al jawf region saudi arabia', key:'A_AJF', cityBase: 89, city: 'AJF', country: 'SA', type: 'airport', nlabel: 'al jawf domestic al jawf region saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'AUK Alakanuk, United States, Alakanuk, AUK, alakanuk alakanuk alaska united states', key:'A_AUK', cityBase: 117, city: 'AUK', country: 'US', type: 'airport', nlabel: 'alakanuk alakanuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NOV Huambo, Angola, Albano Machado, NOV, albano machado huambo angola', key:'A_NOV', cityBase: 127, city: 'NOV', country: 'AO', type: 'airport', nlabel: 'albano machado huambo angola'},
  { icon: 'faPlaneDeparture', label: 'ALH Albany, Australia, Albany, ALH, albany albany western australia australia', key:'A_ALH', cityBase: 127, city: 'ALH', country: 'AU', type: 'airport', nlabel: 'albany albany western australia australia'},
  { icon: 'faPlaneDeparture', label: 'ALB Albany, United States, Albany International, ALB, albany international albany new york united states', key:'A_ALB', cityBase: 13, city: 'ALB', country: 'US', type: 'airport', nlabel: 'albany international albany new york united states'},
  { icon: 'faPlaneDeparture', label: 'OAJ Jacksonville, United States, Albert J. Ellis, OAJ, albert j ellis jacksonville north carolina united states', key:'A_OAJ', cityBase: 56, city: 'OAJ', country: 'US', type: 'airport', nlabel: 'albert j ellis jacksonville north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'PAC Panama City, Panama, Albrook  Marcos A. Gelabert  International, PAC, albrook marcos a gelabert international panama city panama', key:'A_PAC', cityBase: 108, city: 'PTY', country: 'PA', type: 'airport', nlabel: 'albrook marcos a gelabert international panama city panama'},
  { icon: 'faPlaneDeparture', label: 'ABQ Albuquerque, United States, Albuquerque International Sunport, ABQ, albuquerque international sunport albuquerque new mexico united states', key:'A_ABQ', cityBase: 9, city: 'ABQ', country: 'US', type: 'airport', nlabel: 'albuquerque international sunport albuquerque new mexico united states'},
  { icon: 'faPlaneDeparture', label: 'ABX Albury, Australia, Albury, ABX, albury albury new south wales australia', key:'A_ABX', cityBase: 127, city: 'ABX', country: 'AU', type: 'airport', nlabel: 'albury albury new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'SRE Sucre, Bolivia, Alcantarí International Airport, SRE, juana azurduy de padilla international sucre bolivia', key:'A_SRE', cityBase: 87, city: 'SRE', country: 'BO', type: 'airport', nlabel: 'juana azurduy de padilla international sucre bolivia'},
  { icon: 'faPlaneDeparture', label: 'ACD Acandí, Colombia, Alcides Fernández, ACD, alcides fernandez acandi colombia', key:'A_ACD', cityBase: 127, city: 'ACD', country: 'CO', type: 'airport', nlabel: 'alcides fernandez acandi colombia'},
  { icon: 'faPlaneDeparture', label: 'ADH Aldan, Russia, Aldan, ADH, aldan aldan russia', key:'A_ADH', cityBase: 127, city: 'ADH', country: 'RU', type: 'airport', nlabel: 'aldan aldan russia'},
  { icon: 'faPlaneDeparture', label: 'ACI Alderney, Guernsey, Alderney, ACI, alderney alderney guernsey', key:'A_ACI', cityBase: 127, city: 'ACI', country: 'GG', type: 'airport', nlabel: 'alderney alderney guernsey'},
  { icon: 'faPlaneDeparture', label: 'CUZ Cusco, Peru, Alejandro Velasco Astete International, CUZ, alejandro velasco astete international cusco peru', key:'A_CUZ', cityBase: 27, city: 'CUZ', country: 'PE', type: 'airport', nlabel: 'alejandro velasco astete international cusco peru'},
  { icon: 'faPlaneDeparture', label: 'AES Ålesund, Norway, Ålesund Airport, Vigra, AES, alesund airport alesund norway', key:'A_AES', cityBase: 64, city: 'AES', country: 'NO', type: 'airport', nlabel: 'alesund airport alesund norway'},
  { icon: 'faPlaneDeparture', label: 'AEX Alexandria, United States, Alexandria International, AEX, alexandria international alexandria louisiana united states', key:'A_AEX', cityBase: 77, city: 'AEX', country: 'US', type: 'airport', nlabel: 'alexandria international alexandria louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'AXD Alexandroupoli, Greece, Alexandroupolis International, AXD, alexandroupolis international alexandroupoli greece', key:'A_AXD', cityBase: 23, city: 'AXD', country: 'GR', type: 'airport', nlabel: 'alexandroupolis international alexandroupoli greece'},
  { icon: 'faPlaneDeparture', label: 'HUU Huánuco, Peru, Alférez FAP David Figueroa Fernandini, HUU, alferez fap david figueroa fernandini huanuco peru', key:'A_HUU', cityBase: 127, city: 'HUU', country: 'PE', type: 'airport', nlabel: 'alferez fap david figueroa fernandini huanuco peru'},
  { icon: 'faPlaneDeparture', label: 'CLO Cali, Colombia, Alfonso Bonilla Aragón International, CLO, alfonso bonilla aragon international cali colombia', key:'A_CLO', cityBase: 29, city: 'CLO', country: 'CO', type: 'airport', nlabel: 'alfonso bonilla aragon international cali colombia'},
  { icon: 'faPlaneDeparture', label: 'VUP Valledupar, Colombia, Alfonso López Pumarejo, VUP, alfonso lopez pumarejo valledupar colombia', key:'A_VUP', cityBase: 100, city: 'VUP', country: 'CO', type: 'airport', nlabel: 'alfonso lopez pumarejo valledupar colombia'},
  { icon: 'faPlaneDeparture', label: 'LET Leticia, Amazonas, Colombia, Alfredo Vásquez Cobo International, LET, alfredo vasquez cobo international leticia colombia', key:'A_LET', cityBase: 88, city: 'LET', country: 'CO', type: 'airport', nlabel: 'alfredo vasquez cobo international leticia colombia'},
  { icon: 'faPlaneDeparture', label: 'AHO Alghero, Italy, Alghero-Fertilia, AHO, alghero fertilia alghero italy', key:'A_AHO', cityBase: 14, city: 'AHO', country: 'IT', type: 'airport', nlabel: 'alghero fertilia alghero italy'},
  { icon: 'faPlaneDeparture', label: 'ALC Alicante, Spain, Alicante–Elche, ALC, alicante elche alicante spain', key:'A_ALC', cityBase: 6, city: 'ALC', country: 'ES', type: 'airport', nlabel: 'alicante elche alicante spain'},
  { icon: 'faPlaneDeparture', label: 'ASP Alice Springs, Australia, Alice Springs, ASP, alice springs alice springs northern territory australia', key:'A_ASP', cityBase: 78, city: 'ASP', country: 'AU', type: 'airport', nlabel: 'alice springs alice springs northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'IXD Allahabad, India, Allahabad, IXD, allahabad allahabad india', key:'A_IXD', cityBase: 127, city: 'IXD', country: 'IN', type: 'airport', nlabel: 'allahabad allahabad india'},
  { icon: 'faPlaneDeparture', label: 'LHE Lahore, Pakistan, Allama Iqbal International, LHE, allama iqbal international lahore pakistan', key:'A_LHE', cityBase: 26, city: 'LHE', country: 'PK', type: 'airport', nlabel: 'allama iqbal international lahore pakistan'},
  { icon: 'faPlaneDeparture', label: 'AGC Pittsburgh, United States, Allegheny County Airport, AGC, allegheny county airport pittsburgh pennsylvania united states', key:'A_AGC', cityBase: 127, city: 'PIT', country: 'US', type: 'airport', nlabel: 'allegheny county airport pittsburgh pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'ALA Almaty, Kazakhstan, Almaty International, ALA, almaty international almaty kazakhstan', key:'A_ALA', cityBase: 45, city: 'ALA', country: 'KZ', type: 'airport', nlabel: 'almaty international almaty kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'LEI Almería, Spain, Almería, LEI, almeria almeria spain', key:'A_LEI', cityBase: 13, city: 'LEI', country: 'ES', type: 'airport', nlabel: 'almeria almeria spain'},
  { icon: 'faPlaneDeparture', label: 'REL Trelew, Argentina, Almirante Marcos A. Zar, REL, almirante marcos a zar trelew chubut argentina', key:'A_REL', cityBase: 79, city: 'REL', country: 'AR', type: 'airport', nlabel: 'almirante marcos a zar trelew chubut argentina'},
  { icon: 'faPlaneDeparture', label: 'RCH Riohacha, Colombia, Almirante Padilla, RCH, almirante padilla riohacha colombia', key:'A_RCH', cityBase: 91, city: 'RCH', country: 'CO', type: 'airport', nlabel: 'almirante padilla riohacha colombia'},
  { icon: 'faPlaneDeparture', label: 'ARD Alor Island, Indonesia, Alor Island, ARD, alor island alor island indonesia', key:'A_ARD', cityBase: 127, city: 'ARD', country: 'ID', type: 'airport', nlabel: 'alor island alor island indonesia'},
  { icon: 'faPlaneDeparture', label: 'APN Alpena, United States, Alpena County Regional, APN, alpena county regional alpena michigan united states', key:'A_APN', cityBase: 103, city: 'APN', country: 'US', type: 'airport', nlabel: 'alpena county regional alpena michigan united states'},
  { icon: 'faPlaneDeparture', label: 'GNB Grenoble, France, Alpes–Isère, GNB, alpes isere grenoble france 1', key:'A_GNB', cityBase: 43, city: 'GNB', country: 'FR', type: 'airport', nlabel: 'alpes isere grenoble france 1'},
  { icon: 'faPlaneDeparture', label: 'ALF Alta, Norway, Alta, ALF, alta alta norway', key:'A_ALF', cityBase: 127, city: 'ALF', country: 'NO', type: 'airport', nlabel: 'alta alta norway'},
  { icon: 'faPlaneDeparture', label: 'AFL Alta Floresta, Brazil, Alta Floresta, AFL, alta floresta alta floresta state of mato grosso brazil', key:'A_AFL', cityBase: 127, city: 'AFL', country: 'BR', type: 'airport', nlabel: 'alta floresta alta floresta state of mato grosso brazil'},
  { icon: 'faPlaneDeparture', label: 'ATM Altamira, Brazil, Altamira, ATM, altamira altamira state of para brazil', key:'A_ATM', cityBase: 113, city: 'ATM', country: 'BR', type: 'airport', nlabel: 'altamira altamira state of para brazil'},
  { icon: 'faPlaneDeparture', label: 'AAT Altay Prefecture, China, Altay, AAT, altay altay prefecture china', key:'A_AAT', cityBase: 69, city: 'AAT', country: 'CN', type: 'airport', nlabel: 'altay altay prefecture china'},
  { icon: 'faPlaneDeparture', label: 'MQX Mek ele, Ethiopia, Alula Aba Nega, MQX, alula aba nega mek ele ethiopia', key:'A_MQX', cityBase: 125, city: 'MQX', country: 'ET', type: 'airport', nlabel: 'alula aba nega mek ele ethiopia'},
  { icon: 'faPlaneDeparture', label: 'NSK Norilsk, Russia, Alykel, NSK, alykel norilsk russia', key:'A_NSK', cityBase: 47, city: 'NSK', country: 'RU', type: 'airport', nlabel: 'alykel norilsk russia'},
  { icon: 'faPlaneDeparture', label: 'ASJ Amami, Japan, Amami, ASJ, amami amami japan', key:'A_ASJ', cityBase: 116, city: 'ASJ', country: 'JP', type: 'airport', nlabel: 'amami amami japan'},
  { icon: 'faPlaneDeparture', label: 'MZH Amasya, Turkey, Amasya Merzifon, MZH, amasya merzifon amasya turkey', key:'A_MZH', cityBase: 25, city: 'MZH', country: 'TR', type: 'airport', nlabel: 'amasya merzifon amasya turkey'},
  { icon: 'faPlaneDeparture', label: 'ABL Ambler, United States, Ambler, ABL, ambler ambler alaska united states', key:'A_ABL', cityBase: 127, city: 'ABL', country: 'US', type: 'airport', nlabel: 'ambler ambler alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MJN Mahajanga, Madagascar, Amborovy, MJN, amborovy mahajanga madagascar', key:'A_MJN', cityBase: 116, city: 'MJN', country: 'MG', type: 'airport', nlabel: 'amborovy mahajanga madagascar'},
  { icon: 'faPlaneDeparture', label: 'ASV Amboseli National Park, Kenya, Amboseli, ASV, amboseli amboseli national park kenya', key:'A_ASV', cityBase: 127, city: 'ASV', country: 'KE', type: 'airport', nlabel: 'amboseli amboseli national park kenya'},
  { icon: 'faPlaneDeparture', label: 'SID Sal, Cape Verde, Amílcar Cabral International, SID, amilcar cabral international sal cape verde', key:'A_SID', cityBase: 42, city: 'SID', country: 'CV', type: 'airport', nlabel: 'amilcar cabral international sal cape verde'},
  { icon: 'faPlaneDeparture', label: 'AMS Amsterdam, Netherlands, Amsterdam Airport Schiphol, AMS, amsterdam airport schiphol amsterdam netherlands', key:'A_AMS', cityBase: 2, city: 'AMS', country: 'NL', type: 'airport', nlabel: 'amsterdam airport schiphol amsterdam netherlands'},
  { icon: 'faPlaneDeparture', label: 'AAA Anaa, French Polynesia, Anaa, AAA, anaa anaa french polynesia', key:'A_AAA', cityBase: 127, city: 'AAA', country: 'PF', type: 'airport', nlabel: 'anaa anaa french polynesia'},
  { icon: 'faPlaneDeparture', label: 'AOE Eskişehir, Turkey, Anadolu, AOE, anadolu eskisehir turkey', key:'A_AOE', cityBase: 127, city: 'ESK', country: 'TR', type: 'airport', nlabel: 'anadolu eskisehir turkey'},
  { icon: 'faPlaneDeparture', label: 'YAA Anahim Lake, Canada, Anahim Lake, YAA, anahim lake anahim lake british columbia canada', key:'A_YAA', cityBase: 127, city: 'YAA', country: 'CA', type: 'airport', nlabel: 'anahim lake anahim lake british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'AKP Anaktuvuk Pass, United States, Anaktuvuk Pass, AKP, anaktuvuk pass anaktuvuk pass alaska united states', key:'A_AKP', cityBase: 127, city: 'AKP', country: 'US', type: 'airport', nlabel: 'anaktuvuk pass anaktuvuk pass alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AAQ Anapa, Russia, Anapa, AAQ, anapa anapa russia', key:'A_AAQ', cityBase: 43, city: 'AAQ', country: 'RU', type: 'airport', nlabel: 'anapa anapa russia'},
  { icon: 'faPlaneDeparture', label: 'AUY Aneityum, Vanuatu, Anatom, AUY, anatom aneityum vanuatu', key:'A_AUY', cityBase: 127, city: 'AUY', country: 'VU', type: 'airport', nlabel: 'anatom aneityum vanuatu'},
  { icon: 'faPlaneDeparture', label: 'ANS Andahuaylas, Peru, Andahuaylas, ANS, andahuaylas andahuaylas peru', key:'A_ANS', cityBase: 127, city: 'ANS', country: 'PE', type: 'airport', nlabel: 'andahuaylas andahuaylas peru'},
  { icon: 'faPlaneDeparture', label: 'AZN Andijan, Uzbekistan, Andizhan, AZN, andizhan andijan uzbekistan', key:'A_AZN', cityBase: 127, city: 'AZN', country: 'UZ', type: 'airport', nlabel: 'andizhan andijan uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'ANX Andenes, Norway, Andøya, ANX, andoya andenes norway', key:'A_ANX', cityBase: 127, city: 'ANX', country: 'NO', type: 'airport', nlabel: 'andoya andenes norway'},
  { icon: 'faPlaneDeparture', label: 'ANF Antofagasta, Chile, Andrés Sabella Gálvez International, ANF, andres sabella galvez international antofagasta chile', key:'A_ANF', cityBase: 92, city: 'ANF', country: 'CL', type: 'airport', nlabel: 'andres sabella galvez international antofagasta chile'},
  { icon: 'faPlaneDeparture', label: 'OUD Oujda, Morocco, Angads, OUD, angads oujda morocco', key:'A_OUD', cityBase: 80, city: 'OUD', country: 'MA', type: 'airport', nlabel: 'angads oujda morocco'},
  { icon: 'faPlaneDeparture', label: 'AGH Ängelholm, Sweden, Ängelholm–Helsingborg, AGH, angelholm helsingborg angelholm sweden', key:'A_AGH', cityBase: 95, city: 'AGH', country: 'SE', type: 'airport', nlabel: 'angelholm helsingborg angelholm sweden'},
  { icon: 'faPlaneDeparture', label: 'ANE Angers, France, Angers – Loire, ANE, angers loire angers france', key:'A_ANE', cityBase: 127, city: 'ANE', country: 'FR', type: 'airport', nlabel: 'angers loire angers france'},
  { icon: 'faPlaneDeparture', label: 'VLY Ynys Môn, United Kingdom, Anglesey, VLY, anglesey ynys mon united kingdom', key:'A_VLY', cityBase: -873, city: 'VLY', country: 'GB', type: 'airport', nlabel: 'anglesey ynys mon united kingdom'},
  { icon: 'faPlaneDeparture', label: 'AGN Angoon, United States, Angoon Seaplane Base, AGN, angoon seaplane base angoon alaska united states', key:'A_AGN', cityBase: 127, city: 'AGN', country: 'US', type: 'airport', nlabel: 'angoon seaplane base angoon alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ANI Aniak, United States, Aniak, ANI, aniak aniak alaska united states', key:'A_ANI', cityBase: 127, city: 'ANI', country: 'US', type: 'airport', nlabel: 'aniak aniak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AMK Durango, United States, Animas Air Park, AMK, animas air park durango colorado united states', key:'A_AMK', cityBase: 127, city: 'DRO', country: 'US', type: 'airport', nlabel: 'animas air park durango colorado united states'},
  { icon: 'faPlaneDeparture', label: 'AWD Aniwa Island, Vanuatu, Aniwa, AWD, aniwa aniwa island vanuatu', key:'A_AWD', cityBase: 127, city: 'AWD', country: 'VU', type: 'airport', nlabel: 'aniwa aniwa island vanuatu'},
  { icon: 'faPlaneDeparture', label: 'VBA Ann, Myanmar (Burma), Ann, VBA, ann ann myanmar (burma)', key:'A_VBA', cityBase: 127, city: 'VBA', country: 'MM', type: 'airport', nlabel: 'ann ann myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'AQG Anqing, China, Anqing Tianzhushan, AQG, anqing tianzhushan anqing china', key:'A_AQG', cityBase: 75, city: 'AQG', country: 'CN', type: 'airport', nlabel: 'anqing tianzhushan anqing china'},
  { icon: 'faPlaneDeparture', label: 'AOG Anshan, China, Anshan Teng ao, AOG, anshan teng ao anshan china', key:'A_AOG', cityBase: 76, city: 'AOG', country: 'CN', type: 'airport', nlabel: 'anshan teng ao anshan china'},
  { icon: 'faPlaneDeparture', label: 'AVA Anshun, China, Anshun Huangguoshu, AVA, anshun huangguoshu anshun china', key:'A_AVA', cityBase: 66, city: 'AVA', country: 'CN', type: 'airport', nlabel: 'anshun huangguoshu anshun china'},
  { icon: 'faPlaneDeparture', label: 'AYT Antalya, Turkey, Antalya, AYT, antalya antalya turkey', key:'A_AYT', cityBase: 6, city: 'AYT', country: 'TR', type: 'airport', nlabel: 'antalya antalya turkey'},
  { icon: 'faPlaneDeparture', label: 'GUM Hagåtña, United States, Antonio B. Won Pat International, GUM, antonio b won pat international hagatna guam united states', key:'A_GUM', cityBase: 34, city: 'GUM', country: 'US', type: 'airport', nlabel: 'antonio b won pat international hagatna guam united states'},
  { icon: 'faPlaneDeparture', label: 'CUM Cumaná, Venezuela, Antonio José de Sucre, CUM, antonio jose de sucre cumana venezuela', key:'A_CUM', cityBase: 110, city: 'CUM', country: 'VE', type: 'airport', nlabel: 'antonio jose de sucre cumana venezuela'},
  { icon: 'faPlaneDeparture', label: 'SCU Santiago de Cuba, Cuba, Antonio Maceo, SCU, antonio maceo santiago de cuba', key:'A_SCU', cityBase: 63, city: 'SCU', country: 'CU', type: 'airport', nlabel: 'antonio maceo santiago de cuba'},
  { icon: 'faPlaneDeparture', label: 'PSO Pasto, Colombia, Antonio Nariño, PSO, antonio narino pasto colombia', key:'A_PSO', cityBase: 93, city: 'PSO', country: 'CO', type: 'airport', nlabel: 'antonio narino pasto colombia'},
  { icon: 'faPlaneDeparture', label: 'VQS Vieques, United States, Antonio Rivera Rodríguez, VQS, antonio rivera rodriguez vieques puerto rico united states', key:'A_VQS', cityBase: 104, city: 'VQS', country: 'US', type: 'airport', nlabel: 'antonio rivera rodriguez vieques puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'APO Apartadó, Colombia, Antonio Roldán Betancourt, APO, antonio roldan betancourt apartado colombia', key:'A_APO', cityBase: 127, city: 'APO', country: 'CO', type: 'airport', nlabel: 'antonio roldan betancourt apartado colombia'},
  { icon: 'faPlaneDeparture', label: 'ANR City of Brussels, Belgium, Antwerp International, ANR, antwerp international antwerp belgium', key:'A_ANR', cityBase: 78, city: 'BRU', country: 'BE', type: 'airport', nlabel: 'antwerp international antwerp belgium'},
  { icon: 'faPlaneDeparture', label: 'ANV Anvik, United States, Anvik, ANV, anvik anvik alaska united states', key:'A_ANV', cityBase: 127, city: 'ANV', country: 'US', type: 'airport', nlabel: 'anvik anvik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AOJ Aomori, Japan, Aomori, AOJ, aomori aomori japan', key:'A_AOJ', cityBase: 112, city: 'AOJ', country: 'JP', type: 'airport', nlabel: 'aomori aomori japan'},
  { icon: 'faPlaneDeparture', label: 'APK Apataki, French Polynesia, Apataki, APK, apataki apataki french polynesia', key:'A_APK', cityBase: 127, city: 'APK', country: 'PF', type: 'airport', nlabel: 'apataki apataki french polynesia'},
  { icon: 'faPlaneDeparture', label: 'ATW Appleton, United States, Appleton International, ATW, appleton international appleton wisconsin united states', key:'A_ATW', cityBase: 33, city: 'ATW', country: 'US', type: 'airport', nlabel: 'appleton international appleton wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'ARU Araçatuba, Brazil, Araçatuba, ARU, aracatuba aracatuba state of sao paulo brazil', key:'A_ARU', cityBase: 127, city: 'ARU', country: 'BR', type: 'airport', nlabel: 'aracatuba aracatuba state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'ARW Arad, Romania, Arad International, ARW, arad international arad romania', key:'A_ARW', cityBase: 127, city: 'ARW', country: 'RO', type: 'airport', nlabel: 'arad international arad romania'},
  { icon: 'faPlaneDeparture', label: 'AUX Araguaína, Brazil, Araguaína, AUX, araguaina araguaina state of tocantins brazil', key:'A_AUX', cityBase: 127, city: 'AUX', country: 'BR', type: 'airport', nlabel: 'araguaina araguaina state of tocantins brazil'},
  { icon: 'faPlaneDeparture', label: 'RAE Arar, Saudi Arabia, Arar Domestic, RAE, arar domestic arar saudi arabia', key:'A_RAE', cityBase: 127, city: 'RAE', country: 'SA', type: 'airport', nlabel: 'arar domestic arar saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'ACR Araracuara, Colombia, Araracuara, ACR, araracuara araracuara colombia', key:'A_ACR', cityBase: 127, city: 'ACR', country: 'CO', type: 'airport', nlabel: 'araracuara araracuara colombia'},
  { icon: 'faPlaneDeparture', label: 'ASS Arathusa Safari Lodge, South Africa, Arathusa Safari Lodge, ASS, arathusa safari lodge arathusa safari lodge south africa', key:'A_ASS', cityBase: 127, city: 'ASS', country: 'ZA', type: 'airport', nlabel: 'arathusa safari lodge arathusa safari lodge south africa'},
  { icon: 'faPlaneDeparture', label: 'RKA Aratika, French Polynesia, Aratika-Nord, RKA, aratika nord aratika french polynesia', key:'A_RKA', cityBase: 127, city: 'RKA', country: 'PF', type: 'airport', nlabel: 'aratika nord aratika french polynesia'},
  { icon: 'faPlaneDeparture', label: 'AAX Araxá, Brazil, Araxá, AAX, araxa araxa state of minas gerais brazil', key:'A_AAX', cityBase: 127, city: 'AAX', country: 'BR', type: 'airport', nlabel: 'araxa araxa state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'GPA Patras, Greece, Araxos, GPA, araxos patras greece', key:'A_GPA', cityBase: 18, city: 'GPA', country: 'GR', type: 'airport', nlabel: 'araxos patras greece'},
  { icon: 'faPlaneDeparture', label: 'AMH Arba Minch, Ethiopia, Arba Minch, AMH, arba minch arba minch ethiopia', key:'A_AMH', cityBase: 127, city: 'AMH', country: 'ET', type: 'airport', nlabel: 'arba minch arba minch ethiopia'},
  { icon: 'faPlaneDeparture', label: 'ACV Arcata, United States, Arcata–Eureka, ACV, arcata eureka arcata california united states', key:'A_ACV', cityBase: 56, city: 'ACV', country: 'US', type: 'airport', nlabel: 'arcata eureka arcata california united states'},
  { icon: 'faPlaneDeparture', label: 'YAB Arctic Bay, Canada, Arctic Bay, YAB, arctic bay arctic bay nunavut canada', key:'A_YAB', cityBase: 127, city: 'YAB', country: 'CA', type: 'airport', nlabel: 'arctic bay arctic bay nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'ARC Arctic Village, United States, Arctic Village, ARC, arctic village arctic village alaska united states', key:'A_ARC', cityBase: 127, city: 'ARC', country: 'US', type: 'airport', nlabel: 'arctic village arctic village alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ADU Ardabil, Iran, Ardabil, ADU, ardabil ardabil iran', key:'A_ADU', cityBase: 127, city: 'ADU', country: 'IR', type: 'airport', nlabel: 'ardabil ardabil iran'},
  { icon: 'faPlaneDeparture', label: 'OSD Östersund, Sweden, Åre Östersund, OSD, are ostersund ostersund sweden', key:'A_OSD', cityBase: 82, city: 'OSD', country: 'SE', type: 'airport', nlabel: 'are ostersund ostersund sweden'},
  { icon: 'faPlaneDeparture', label: 'BVC Boa Vista, Cape Verde, Aristides Pereira International, BVC, aristides pereira international boa vista cape verde', key:'A_BVC', cityBase: 88, city: 'BVC', country: 'CV', type: 'airport', nlabel: 'aristides pereira international boa vista cape verde'},
  { icon: 'faPlaneDeparture', label: 'ARM Armidale, Australia, Armidale, ARM, armidale armidale new south wales australia', key:'A_ARM', cityBase: 114, city: 'ARM', country: 'AU', type: 'airport', nlabel: 'armidale armidale new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'LBE Latrobe, United States, Arnold Palmer Regional, LBE, arnold palmer regional latrobe pennsylvania united states', key:'A_LBE', cityBase: 89, city: 'LBE', country: 'US', type: 'airport', nlabel: 'arnold palmer regional latrobe pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'KIE Kieta, Papua New Guinea, Aropa, KIE, aropa kieta papua new guinea', key:'A_KIE', cityBase: 124, city: 'KIE', country: 'PG', type: 'airport', nlabel: 'aropa kieta papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'DIE Antsiranana, Madagascar, Arrachart, DIE, arrachart antsiranana madagascar', key:'A_DIE', cityBase: 113, city: 'DIE', country: 'MG', type: 'airport', nlabel: 'arrachart antsiranana madagascar'},
  { icon: 'faPlaneDeparture', label: 'TAB Tobago, Trinidad & Tobago, Arthur Napoleon Raymond Robinson International, TAB, arthur napoleon raymond robinson international tobago trinidad and tobago', key:'A_TAB', cityBase: 41, city: 'TAB', country: 'TT', type: 'airport', nlabel: 'arthur napoleon raymond robinson international tobago trinidad and tobago'},
  { icon: 'faPlaneDeparture', label: 'ATC Arthur\'s Town, Bahamas, Arthur\'s Town, ATC, arthur s town arthur s town bahamas', key:'A_ATC', cityBase: 127, city: 'ATC', country: 'BS', type: 'airport', nlabel: 'arthur s town arthur s town bahamas'},
  { icon: 'faPlaneDeparture', label: 'VLN Valencia, Venezuela, Arturo Michelena International, VLN, arturo michelena international valencia venezuela', key:'A_VLN', cityBase: 34, city: 'VLN', country: 'VE', type: 'airport', nlabel: 'arturo michelena international valencia venezuela'},
  { icon: 'faPlaneDeparture', label: 'ARK Arusha, Tanzania, Arusha, ARK, arusha arusha tanzania', key:'A_ARK', cityBase: 94, city: 'ARK', country: 'TZ', type: 'airport', nlabel: 'arusha arusha tanzania'},
  { icon: 'faPlaneDeparture', label: 'AXR Arutua, French Polynesia, Arutua, AXR, arutua arutua french polynesia', key:'A_AXR', cityBase: 127, city: 'AXR', country: 'PF', type: 'airport', nlabel: 'arutua arutua french polynesia'},
  { icon: 'faPlaneDeparture', label: 'YEK Arviat, Canada, Arviat, YEK, arviat arviat nunavut canada', key:'A_YEK', cityBase: 127, city: 'YEK', country: 'CA', type: 'airport', nlabel: 'arviat arviat nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'AJR Arvidsjaur, Sweden, Arvidsjaur, AJR, arvidsjaur arvidsjaur sweden', key:'A_AJR', cityBase: 127, city: 'AJR', country: 'SE', type: 'airport', nlabel: 'arvidsjaur arvidsjaur sweden'},
  { icon: 'faPlaneDeparture', label: 'ABB Asaba, Nigeria, Asaba International, ABB, asaba international asaba nigeria', key:'A_ABB', cityBase: 126, city: 'ABB', country: 'NG', type: 'airport', nlabel: 'asaba international asaba nigeria'},
  { icon: 'faPlaneDeparture', label: 'AKJ Asahikawa, Japan, Asahikawa, AKJ, asahikawa asahikawa japan', key:'A_AKJ', cityBase: 105, city: 'AKJ', country: 'JP', type: 'airport', nlabel: 'asahikawa asahikawa japan'},
  { icon: 'faPlaneDeparture', label: 'AVL Asheville, United States, Asheville Regional, AVL, asheville regional asheville north carolina united states', key:'A_AVL', cityBase: 29, city: 'AVL', country: 'US', type: 'airport', nlabel: 'asheville regional asheville north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'ASB Ashgabat, Turkmenistan, Ashgabat International, ASB, ashgabat international ashgabat turkmenistan', key:'A_ASB', cityBase: 80, city: 'ASB', country: 'TM', type: 'airport', nlabel: 'ashgabat international ashgabat turkmenistan'},
  { icon: 'faPlaneDeparture', label: 'ASM Asmara, Eritrea, Asmara International, ASM, asmara international asmara eritrea', key:'A_ASM', cityBase: 47, city: 'ASM', country: 'ER', type: 'airport', nlabel: 'asmara international asmara eritrea'},
  { icon: 'faPlaneDeparture', label: 'ASO Asosa, Ethiopia, Asosa, ASO, asosa asosa ethiopia', key:'A_ASO', cityBase: 127, city: 'ASO', country: 'ET', type: 'airport', nlabel: 'asosa asosa ethiopia'},
  { icon: 'faPlaneDeparture', label: 'ASE Aspen, United States, Aspen–Pitkin County, ASE, aspen pitkin county aspen colorado united states', key:'A_ASE', cityBase: 32, city: 'ASE', country: 'US', type: 'airport', nlabel: 'aspen pitkin county aspen colorado united states'},
  { icon: 'faPlaneDeparture', label: 'ATZ Asyut, Egypt, Assiut, ATZ, assiut asyut egypt', key:'A_ATZ', cityBase: 35, city: 'ATZ', country: 'EG', type: 'airport', nlabel: 'assiut asyut egypt'},
  { icon: 'faPlaneDeparture', label: 'TSE Astana, Kazakhstan, Astana International, TSE, astana international astana kazakhstan', key:'A_TSE', cityBase: 49, city: 'TSE', country: 'KZ', type: 'airport', nlabel: 'astana international astana kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'MDQ Mar del Plata, Argentina, Astor Piazzolla International, MDQ, astor piazzolla international mar del plata buenos aires argentina', key:'A_MDQ', cityBase: 127, city: 'MDQ', country: 'AR', type: 'airport', nlabel: 'astor piazzolla international mar del plata buenos aires argentina'},
  { icon: 'faPlaneDeparture', label: 'OVD Asturias, Spain, Asturias, OVD, asturias asturias spain', key:'A_OVD', cityBase: 13, city: 'OVD', country: 'ES', type: 'airport', nlabel: 'asturias asturias spain'},
  { icon: 'faPlaneDeparture', label: 'JTY Astypalaia, Greece, Astypalaia Island National, JTY, astypalaia island national astypalaia greece', key:'A_JTY', cityBase: 24, city: 'JTY', country: 'GR', type: 'airport', nlabel: 'astypalaia island national astypalaia greece'},
  { icon: 'faPlaneDeparture', label: 'ASW Aswan, Egypt, Aswan International, ASW, aswan international aswan egypt', key:'A_ASW', cityBase: 80, city: 'ASW', country: 'EG', type: 'airport', nlabel: 'aswan international aswan egypt'},
  { icon: 'faPlaneDeparture', label: 'ATH Athens, Greece, Athens International, ATH, athens international athens greece', key:'A_ATH', cityBase: 2, city: 'ATH', country: 'GR', type: 'airport', nlabel: 'athens international athens greece'},
  { icon: 'faPlaneDeparture', label: 'AKB Atka, United States, Atka, AKB, atka atka alaska united states', key:'A_AKB', cityBase: 127, city: 'AKB', country: 'US', type: 'airport', nlabel: 'atka atka alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ACY Atlantic City, United States, Atlantic City International, ACY, atlantic city international atlantic city new jersey united states', key:'A_ACY', cityBase: 29, city: 'AIY', country: 'US', type: 'airport', nlabel: 'atlantic city international atlantic city new jersey united states'},
  { icon: 'faPlaneDeparture', label: 'ATT Atmautluak, United States, Atmautluak, ATT, atmautluak atmautluak alaska united states', key:'A_ATT', cityBase: 127, city: 'ATT', country: 'US', type: 'airport', nlabel: 'atmautluak atmautluak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ATK Atqasuk, United States, Atqasuk Edward Burnell Sr. Memorial, ATK, atqasuk edward burnell sr memorial atqasuk alaska united states', key:'A_ATK', cityBase: 127, city: 'ATK', country: 'US', type: 'airport', nlabel: 'atqasuk edward burnell sr memorial atqasuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YAT Attawapiskat First Nation, Canada, Attawapiskat, YAT, attawapiskat attawapiskat first nation ontario canada', key:'A_YAT', cityBase: 127, city: 'YAT', country: 'CA', type: 'airport', nlabel: 'attawapiskat attawapiskat first nation ontario canada'},
  { icon: 'faPlaneDeparture', label: 'AUQ Hiva Oa, French Polynesia, Atuona, AUQ, atuona hiva oa french polynesia', key:'A_AUQ', cityBase: 127, city: 'AUQ', country: 'PF', type: 'airport', nlabel: 'atuona hiva oa french polynesia'},
  { icon: 'faPlaneDeparture', label: 'GUW Atyrau, Kazakhstan, Atyrau, GUW, atyrau atyrau kazakhstan', key:'A_GUW', cityBase: 82, city: 'GUW', country: 'KZ', type: 'airport', nlabel: 'atyrau atyrau kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'AKL Auckland, New Zealand, Auckland, AKL, auckland auckland new zealand', key:'A_AKL', cityBase: 18, city: 'AKL', country: 'NZ', type: 'airport', nlabel: 'auckland auckland new zealand'},
  { icon: 'faPlaneDeparture', label: 'AGS Augusta, United States, Augusta Regional, AGS, augusta regional augusta georgia united states', key:'A_AGS', cityBase: 53, city: 'AGS', country: 'US', type: 'airport', nlabel: 'augusta regional augusta georgia united states'},
  { icon: 'faPlaneDeparture', label: 'AUG Augusta, United States, Augusta State, AUG, augusta state augusta maine united states', key:'A_AUG', cityBase: 106, city: 'AUG', country: 'US', type: 'airport', nlabel: 'augusta state augusta maine united states'},
  { icon: 'faPlaneDeparture', label: 'NGD Anegada, British Virgin Islands, Auguste George, NGD, auguste george anegada british virgin islands', key:'A_NGD', cityBase: 127, city: 'NGD', country: 'VG', type: 'airport', nlabel: 'auguste george anegada british virgin islands'},
  { icon: 'faPlaneDeparture', label: 'MGA Managua, Nicaragua, Augusto C. Sandino International, MGA, augusto c sandino international managua nicaragua', key:'A_MGA', cityBase: 30, city: 'MGA', country: 'NI', type: 'airport', nlabel: 'augusto c sandino international managua nicaragua'},
  { icon: 'faPlaneDeparture', label: 'NAT Natal, Brazil, Augusto Severo International, NAT, augusto severo international natal state of rio grande do norte brazil', key:'A_NAT', cityBase: 56, city: 'NAT', country: 'BR', type: 'airport', nlabel: 'augusto severo international natal state of rio grande do norte brazil'},
  { icon: 'faPlaneDeparture', label: 'AKS Auki, Solomon Islands, Auki Gwaunaru u, AKS, auki gwaunaru u auki solomon islands', key:'A_AKS', cityBase: 127, city: 'AKS', country: 'SB', type: 'airport', nlabel: 'auki gwaunaru u auki solomon islands'},
  { icon: 'faPlaneDeparture', label: 'YPJ Aupaluk, Canada, Aupaluk, YPJ, aupaluk aupaluk quebec canada', key:'A_YPJ', cityBase: 127, city: 'YPJ', country: 'CA', type: 'airport', nlabel: 'aupaluk aupaluk quebec canada'},
  { icon: 'faPlaneDeparture', label: 'IXU Aurangabad, India, Aurangabad, IXU, aurangabad aurangabad india', key:'A_IXU', cityBase: 102, city: 'IXU', country: 'IN', type: 'airport', nlabel: 'aurangabad aurangabad india'},
  { icon: 'faPlaneDeparture', label: 'AUR Aurillac, France, Aurillac – Tronquières, AUR, aurillac tronquieres aurillac france', key:'A_AUR', cityBase: 127, city: 'AUR', country: 'FR', type: 'airport', nlabel: 'aurillac tronquieres aurillac france'},
  { icon: 'faPlaneDeparture', label: 'AUS Austin, United States, Austin–Bergstrom International, AUS, austin bergstrom international austin texas united states', key:'A_AUS', cityBase: 2, city: 'AUS', country: 'US', type: 'airport', nlabel: 'austin bergstrom international austin texas united states'},
  { icon: 'faPlaneDeparture', label: 'AVV Melbourne, Australia, Avalon, AVV, avalon melbourne victoria australia', key:'A_AVV', cityBase: 125, city: 'MEL', country: 'AU', type: 'airport', nlabel: 'avalon melbourne victoria australia'},
  { icon: 'faPlaneDeparture', label: 'CPC San Martín de los Andes, Argentina, Aviador Carlos Campos, CPC, aviador carlos campos san martin de los andes neuquen argentina', key:'A_CPC', cityBase: 127, city: 'CPC', country: 'AR', type: 'airport', nlabel: 'aviador carlos campos san martin de los andes neuquen argentina'},
  { icon: 'faPlaneDeparture', label: 'AVN Avignon, France, Avignon – Provence, AVN, avignon provence avignon france', key:'A_AVN', cityBase: 99, city: 'AVN', country: 'FR', type: 'airport', nlabel: 'avignon provence avignon france'},
  { icon: 'faPlaneDeparture', label: 'CBO Cotabato City, Philippines, Awang, CBO, awang cotabato city philippines', key:'A_CBO', cityBase: 101, city: 'CBO', country: 'PH', type: 'airport', nlabel: 'awang cotabato city philippines'},
  { icon: 'faPlaneDeparture', label: 'AWA Awasa, Ethiopia, Awasa, AWA, awasa awasa ethiopia', key:'A_AWA', cityBase: 127, city: 'AWA', country: 'ET', type: 'airport', nlabel: 'awasa awasa ethiopia'},
  { icon: 'faPlaneDeparture', label: 'AXU Axum, Ethiopia, Axum, AXU, axum axum ethiopia', key:'A_AXU', cityBase: 127, city: 'AXU', country: 'ET', type: 'airport', nlabel: 'axum axum ethiopia'},
  { icon: 'faPlaneDeparture', label: 'AYQ Uluru, Australia, Ayers Rock, AYQ, ayers rock uluru northern territory australia', key:'A_AYQ', cityBase: 57, city: 'AYQ', country: 'AU', type: 'airport', nlabel: 'ayers rock uluru northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'BKM Ba kelalan, Malaysia, Ba kelalan, BKM, ba kelalan ba kelalan malaysia', key:'A_BKM', cityBase: 127, city: 'BKM', country: 'MY', type: 'airport', nlabel: 'ba kelalan ba kelalan malaysia'},
  { icon: 'faPlaneDeparture', label: 'TTE Ternate City, Indonesia, Babullah, TTE, babullah ternate city indonesia', key:'A_TTE', cityBase: 107, city: 'TTE', country: 'ID', type: 'airport', nlabel: 'babullah ternate city indonesia'},
  { icon: 'faPlaneDeparture', label: 'PEW Peshawar, Pakistan, Bacha Khan International, PEW, bacha khan international peshawar pakistan', key:'A_PEW', cityBase: 65, city: 'PEW', country: 'PK', type: 'airport', nlabel: 'bacha khan international peshawar pakistan'},
  { icon: 'faPlaneDeparture', label: 'CUL Culiacán, Mexico, Bachigualato Federal International, CUL, bachigualato federal international culiacan mexico', key:'A_CUL', cityBase: 43, city: 'CUL', country: 'MX', type: 'airport', nlabel: 'bachigualato federal international culiacan mexico'},
  { icon: 'faPlaneDeparture', label: 'BCO Jinka, Ethiopia, Baco, BCO, baco jinka ethiopia', key:'A_BCO', cityBase: 127, city: 'BCO', country: 'ET', type: 'airport', nlabel: 'baco jinka ethiopia'},
  { icon: 'faPlaneDeparture', label: 'BCD Bacolod, Philippines, Bacolod–Silay, BCD, bacolod silay bacolod philippines', key:'A_BCD', cityBase: 45, city: 'BCD', country: 'PH', type: 'airport', nlabel: 'bacolod silay bacolod philippines'},
  { icon: 'faPlaneDeparture', label: 'BJZ Badajoz, Spain, Badajoz, BJZ, badajoz badajoz spain', key:'A_BJZ', cityBase: 18, city: 'BJZ', country: 'ES', type: 'airport', nlabel: 'badajoz badajoz spain'},
  { icon: 'faPlaneDeparture', label: 'BFX Bafoussam, Cameroon, Bafoussam, BFX, bafoussam bafoussam cameroon', key:'A_BFX', cityBase: 127, city: 'BFX', country: 'CM', type: 'airport', nlabel: 'bafoussam bafoussam cameroon'},
  { icon: 'faPlaneDeparture', label: 'IXB Bagdogra, India, Bagdogra, IXB, bagdogra bagdogra india', key:'A_IXB', cityBase: 92, city: 'IXB', country: 'IN', type: 'airport', nlabel: 'bagdogra bagdogra india'},
  { icon: 'faPlaneDeparture', label: 'BGW Baghdad, Iraq, Baghdad International, BGW, baghdad international baghdad iraq', key:'A_BGW', cityBase: 37, city: 'BGW', country: 'IQ', type: 'airport', nlabel: 'baghdad international baghdad iraq'},
  { icon: 'faPlaneDeparture', label: 'BHV Bahawalpur, Pakistan, Bahawalpur, BHV, bahawalpur bahawalpur pakistan', key:'A_BHV', cityBase: 127, city: 'BHV', country: 'PK', type: 'airport', nlabel: 'bahawalpur bahawalpur pakistan'},
  { icon: 'faPlaneDeparture', label: 'BFQ Bahia Pinas, Panama, Bahía Piña, BFQ, bahia pina bahia pinas panama', key:'A_BFQ', cityBase: 127, city: 'BFQ', country: 'PA', type: 'airport', nlabel: 'bahia pina bahia pinas panama'},
  { icon: 'faPlaneDeparture', label: 'HUX Huatulco, Mexico, Bahías de Huatulco International, HUX, bahias de huatulco international huatulco mexico', key:'A_HUX', cityBase: 45, city: 'HUX', country: 'MX', type: 'airport', nlabel: 'bahias de huatulco international huatulco mexico'},
  { icon: 'faPlaneDeparture', label: 'BJR Bahir Dar, Ethiopia, Bahir Dar, BJR, bahir dar bahir dar ethiopia', key:'A_BJR', cityBase: 127, city: 'BJR', country: 'ET', type: 'airport', nlabel: 'bahir dar bahir dar ethiopia'},
  { icon: 'faPlaneDeparture', label: 'BAH Bahrain Island, Bahrain, Bahrain International, BAH, bahrain international bahrain island bahrain', key:'A_BAH', cityBase: 31, city: 'BAH', country: 'BH', type: 'airport', nlabel: 'bahrain international bahrain island bahrain'},
  { icon: 'faPlaneDeparture', label: 'YBC Baie-Comeau, Canada, Baie-Comeau, YBC, baie comeau baie comeau quebec canada', key:'A_YBC', cityBase: 127, city: 'YBC', country: 'CA', type: 'airport', nlabel: 'baie comeau baie comeau quebec canada'},
  { icon: 'faPlaneDeparture', label: 'UUD Ulan-Ude, Russia, Baikal International, UUD, baikal international ulan ude russia', key:'A_UUD', cityBase: 47, city: 'UUD', country: 'RU', type: 'airport', nlabel: 'baikal international ulan ude russia'},
  { icon: 'faPlaneDeparture', label: 'AEB Baise, China, Baise Bama, AEB, baise bama baise china', key:'A_AEB', cityBase: 66, city: 'AEB', country: 'CN', type: 'airport', nlabel: 'baise bama baise china'},
  { icon: 'faPlaneDeparture', label: 'YBK Baker Lake, Nunavut, Canada, Baker Lake, YBK, baker lake baker lake nunavut canada', key:'A_YBK', cityBase: 127, city: 'YBK', country: 'CA', type: 'airport', nlabel: 'baker lake baker lake nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'BAS Balalae Island, Solomon Islands, Balalae, BAS, balalae balalae island solomon islands', key:'A_BAS', cityBase: 127, city: 'BAS', country: 'SB', type: 'airport', nlabel: 'balalae balalae island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'EDO Edremit, Turkey, Balıkesir Koca Seyit, EDO, balikesir koca seyit edremit turkey', key:'A_EDO', cityBase: 22, city: 'EDO', country: 'TR', type: 'airport', nlabel: 'balikesir koca seyit edremit turkey'},
  { icon: 'faPlaneDeparture', label: 'BKN Balkanabat, Turkmenistan, Balkanabat, BKN, balkanabat balkanabat turkmenistan', key:'A_BKN', cityBase: 127, city: 'BKN', country: 'TM', type: 'airport', nlabel: 'balkanabat balkanabat turkmenistan'},
  { icon: 'faPlaneDeparture', label: 'BXH Balkhash, Kazakhstan, Balkhash, BXH, balkhash balkhash kazakhstan', key:'A_BXH', cityBase: 127, city: 'BXH', country: 'KZ', type: 'airport', nlabel: 'balkhash balkhash kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'BNK Ballina, Australia, Ballina Byron Gateway, BNK, ballina byron gateway ballina new south wales australia', key:'A_BNK', cityBase: 107, city: 'BNK', country: 'AU', type: 'airport', nlabel: 'ballina byron gateway ballina new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'BBA Balmaceda, Chile, Balmaceda, BBA, balmaceda balmaceda chile', key:'A_BBA', cityBase: 92, city: 'BBA', country: 'CL', type: 'airport', nlabel: 'balmaceda balmaceda chile'},
  { icon: 'faPlaneDeparture', label: 'BWI Washington, D.C., United States, Baltimore–Washington International, BWI, baltimore washington international washington district of columbia united states', key:'A_BWI', cityBase: 2, city: 'WAS', country: 'US', type: 'airport', nlabel: 'baltimore washington international washington district of columbia united states'},
  { icon: 'faPlaneDeparture', label: 'BKO Bamako, Mali, Bamako–Sénou International, BKO, bamako senou international bamako mali', key:'A_BKO', cityBase: 51, city: 'BKO', country: 'ML', type: 'airport', nlabel: 'bamako senou international bamako mali'},
  { icon: 'faPlaneDeparture', label: 'HOE Ban Houayxay, Laos, Ban Huoeisay, HOE, ban huoeisay ban houayxay laos', key:'A_HOE', cityBase: 127, city: 'HOE', country: 'LA', type: 'airport', nlabel: 'ban huoeisay ban houayxay laos'},
  { icon: 'faPlaneDeparture', label: 'BXU Butuan, Philippines, Bancasi, BXU, bancasi butuan philippines', key:'A_BXU', cityBase: 83, city: 'BXU', country: 'PH', type: 'airport', nlabel: 'bancasi butuan philippines'},
  { icon: 'faPlaneDeparture', label: 'BND Bandar Abbas, Iran, Bandar Abbas International, BND, bandar abbas international bandar abbas iran', key:'A_BND', cityBase: 127, city: 'BND', country: 'IR', type: 'airport', nlabel: 'bandar abbas international bandar abbas iran'},
  { icon: 'faPlaneDeparture', label: 'BDH Bandar Lengeh, Iran, Bandar Lengeh, BDH, bandar lengeh bandar lengeh iran', key:'A_BDH', cityBase: 127, city: 'BDH', country: 'IR', type: 'airport', nlabel: 'bandar lengeh bandar lengeh iran'},
  { icon: 'faPlaneDeparture', label: 'CMB Colombo, Sri Lanka, Bandaranaike International, CMB, bandaranaike international colombo sri lanka', key:'A_CMB', cityBase: 27, city: 'CMB', country: 'LK', type: 'airport', nlabel: 'bandaranaike international colombo sri lanka'},
  { icon: 'faPlaneDeparture', label: 'FKI Kisangani, Democratic Republic of the Congo, Bangoka International, FKI, bangoka international kisangani congo kinshasa', key:'A_FKI', cityBase: 127, city: 'FKI', country: 'CD', type: 'airport', nlabel: 'bangoka international kisangani congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'BGR Bangor, United States, Bangor International, BGR, bangor international bangor maine united states', key:'A_BGR', cityBase: 39, city: 'BGR', country: 'US', type: 'airport', nlabel: 'bangor international bangor maine united states'},
  { icon: 'faPlaneDeparture', label: 'BGF Bangui, Central African Republic, Bangui M Poko International, BGF, bangui m poko international bangui central african republic', key:'A_BGF', cityBase: 97, city: 'BGF', country: 'CF', type: 'airport', nlabel: 'bangui m poko international bangui central african republic'},
  { icon: 'faPlaneDeparture', label: 'BNX Banja Luka, Bosnia & Herzegovina, Banja Luka International, BNX, banja luka international banja luka bosnia and herzegovina', key:'A_BNX', cityBase: 111, city: 'BNX', country: 'BA', type: 'airport', nlabel: 'banja luka international banja luka bosnia and herzegovina'},
  { icon: 'faPlaneDeparture', label: 'BJL Banjul, Gambia, Banjul International, BJL, banjul international banjul gambia', key:'A_BJL', cityBase: 35, city: 'BJL', country: 'GM', type: 'airport', nlabel: 'banjul international banjul gambia'},
  { icon: 'faPlaneDeparture', label: 'BSD Baoshan, Yunnan, China, Baoshan Yunrui, BSD, baoshan yunrui baoshan china', key:'A_BSD', cityBase: 69, city: 'BSD', country: 'CN', type: 'airport', nlabel: 'baoshan yunrui baoshan china'},
  { icon: 'faPlaneDeparture', label: 'BAV Baotou, China, Baotou, BAV, baotou baotou china', key:'A_BAV', cityBase: 69, city: 'BAV', country: 'CN', type: 'airport', nlabel: 'baotou baotou china'},
  { icon: 'faPlaneDeparture', label: 'BCI Barcaldine, Australia, Barcaldine, BCI, barcaldine barcaldine queensland australia', key:'A_BCI', cityBase: 127, city: 'BCI', country: 'AU', type: 'airport', nlabel: 'barcaldine barcaldine queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BCN Barcelona, Spain, Barcelona–El Prat, BCN, barcelona el prat barcelona spain', key:'A_BCN', cityBase: 1, city: 'BCN', country: 'ES', type: 'airport', nlabel: 'barcelona el prat barcelona spain'},
  { icon: 'faPlaneDeparture', label: 'BDU Andselv, Norway, Bardufoss, BDU, bardufoss andselv norway', key:'A_BDU', cityBase: 127, city: 'BDU', country: 'NO', type: 'airport', nlabel: 'bardufoss andselv norway'},
  { icon: 'faPlaneDeparture', label: 'BRI Bari, Italy, Bari Karol Wojtyła, BRI, bari karol wojtyla bari italy', key:'A_BRI', cityBase: 10, city: 'BRI', country: 'IT', type: 'airport', nlabel: 'bari karol wojtyla bari italy'},
  { icon: 'faPlaneDeparture', label: 'BYP Barimunya, Australia, Barimunya, BYP, barimunya barimunya western australia australia', key:'A_BYP', cityBase: 127, city: 'BYP', country: 'AU', type: 'airport', nlabel: 'barimunya barimunya western australia australia'},
  { icon: 'faPlaneDeparture', label: 'BNS Barinas, Venezuela, Barinas, BNS, barinas barinas venezuela', key:'A_BNS', cityBase: 92, city: 'BNS', country: 'VE', type: 'airport', nlabel: 'barinas barinas venezuela'},
  { icon: 'faPlaneDeparture', label: 'BBN Bario, Malaysia, Bario, BBN, bario bario malaysia', key:'A_BBN', cityBase: 127, city: 'BBN', country: 'MY', type: 'airport', nlabel: 'bario bario malaysia'},
  { icon: 'faPlaneDeparture', label: 'BZL Barisal, Bangladesh, Barisal, BZL, barisal barisal bangladesh', key:'A_BZL', cityBase: 127, city: 'BZL', country: 'BD', type: 'airport', nlabel: 'barisal barisal bangladesh'},
  { icon: 'faPlaneDeparture', label: 'PAH Paducah, United States, Barkley Regional, PAH, barkley regional paducah kentucky united states', key:'A_PAH', cityBase: 95, city: 'PAH', country: 'US', type: 'airport', nlabel: 'barkley regional paducah kentucky united states'},
  { icon: 'faPlaneDeparture', label: 'BAX Barnaul, Russia, Barnaul, BAX, barnaul barnaul russia', key:'A_BAX', cityBase: 44, city: 'BAX', country: 'RU', type: 'airport', nlabel: 'barnaul barnaul russia'},
  { icon: 'faPlaneDeparture', label: 'HYA Hyannis, United States, Barnstable Municipal, HYA, barnstable municipal hyannis massachusetts united states', key:'A_HYA', cityBase: 42, city: 'HYA', country: 'US', type: 'airport', nlabel: 'barnstable municipal hyannis massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'BRR Barra, United Kingdom, Barra, BRR, barra barra united kingdom', key:'A_BRR', cityBase: -873, city: 'BRR', country: 'GB', type: 'airport', nlabel: 'barra barra united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BPG Barra do Garças, Brazil, Barra do Garças, BPG, barra do garcas barra do garcas state of mato grosso brazil', key:'A_BPG', cityBase: 127, city: 'BPG', country: 'BR', type: 'airport', nlabel: 'barra do garcas barra do garcas state of mato grosso brazil'},
  { icon: 'faPlaneDeparture', label: 'BRA Barreiras, Brazil, Barreiras, BRA, barreiras barreiras state of bahia brazil', key:'A_BRA', cityBase: 124, city: 'BRA', country: 'BR', type: 'airport', nlabel: 'barreiras barreiras state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'BTI Barter Island, United States, Barter Island LRRS, BTI, barter island lrrs barter island alaska united states', key:'A_BTI', cityBase: 127, city: 'BTI', country: 'US', type: 'airport', nlabel: 'barter island lrrs barter island alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CAW Campos dos Goytacazes, Brazil, Bartolomeu Lysandro, CAW, bartolomeu lysandro campos dos goytacazes state of rio de janeiro brazil', key:'A_CAW', cityBase: 127, city: 'CAW', country: 'BR', type: 'airport', nlabel: 'bartolomeu lysandro campos dos goytacazes state of rio de janeiro brazil'},
  { icon: 'faPlaneDeparture', label: 'BSO Basco, Philippines, Basco, BSO, basco basco philippines', key:'A_BSO', cityBase: 94, city: 'BSO', country: 'PH', type: 'airport', nlabel: 'basco basco philippines'},
  { icon: 'faPlaneDeparture', label: 'BSR Basra, Iraq, Basra International, BSR, basra international basra iraq', key:'A_BSR', cityBase: 72, city: 'BSR', country: 'IQ', type: 'airport', nlabel: 'basra international basra iraq'},
  { icon: 'faPlaneDeparture', label: 'BIA Bastia, France, Bastia – Poretta, BIA, bastia poretta bastia france', key:'A_BIA', cityBase: 63, city: 'BIA', country: 'FR', type: 'airport', nlabel: 'bastia poretta bastia france'},
  { icon: 'faPlaneDeparture', label: 'BSG Bata, Equatorial Guinea, Bata, BSG, bata bata equatorial guinea', key:'A_BSG', cityBase: 124, city: 'BSG', country: 'GQ', type: 'airport', nlabel: 'bata bata equatorial guinea'},
  { icon: 'faPlaneDeparture', label: 'BQJ Batagay, Russia, Batagay, BQJ, batagay batagay russia', key:'A_BQJ', cityBase: 127, city: 'BQJ', country: 'RU', type: 'airport', nlabel: 'batagay batagay russia'},
  { icon: 'faPlaneDeparture', label: 'BHS Bathurst, Australia, Bathurst, BHS, bathurst bathurst new south wales australia', key:'A_BHS', cityBase: 127, city: 'BHS', country: 'AU', type: 'airport', nlabel: 'bathurst bathurst new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'ZBF Bathurst, Canada, Bathurst, ZBF, bathurst bathurst new brunswick canada', key:'A_ZBF', cityBase: 127, city: 'ZBF', country: 'CA', type: 'airport', nlabel: 'bathurst bathurst new brunswick canada'},
  { icon: 'faPlaneDeparture', label: 'BAL Batman, Turkey, Batman, BAL, batman batman turkey', key:'A_BAL', cityBase: 20, city: 'BAL', country: 'TR', type: 'airport', nlabel: 'batman batman turkey'},
  { icon: 'faPlaneDeparture', label: 'BTR Baton Rouge, United States, Baton Rouge Metropolitan, BTR, baton rouge metropolitan baton rouge louisiana united states', key:'A_BTR', cityBase: 34, city: 'BTR', country: 'US', type: 'airport', nlabel: 'baton rouge metropolitan baton rouge louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'BJF Båtsfjord, Norway, Båtsfjord, BJF, batsfjord batsfjord norway', key:'A_BJF', cityBase: 127, city: 'BJF', country: 'NO', type: 'airport', nlabel: 'batsfjord batsfjord norway'},
  { icon: 'faPlaneDeparture', label: 'BTC Batticaloa, Sri Lanka, Batticaloa, BTC, batticaloa batticaloa sri lanka', key:'A_BTC', cityBase: 127, city: 'BTC', country: 'LK', type: 'airport', nlabel: 'batticaloa batticaloa sri lanka'},
  { icon: 'faPlaneDeparture', label: 'BTW Batulicin, Indonesia, Batu Licin, BTW, batu licin batulicin indonesia', key:'A_BTW', cityBase: 127, city: 'BTW', country: 'ID', type: 'airport', nlabel: 'batu licin batulicin indonesia'},
  { icon: 'faPlaneDeparture', label: 'BUS Batumi, Georgia, Batumi International, BUS, batumi international batumi georgia', key:'A_BUS', cityBase: 50, city: 'BUS', country: 'GE', type: 'airport', nlabel: 'batumi international batumi georgia'},
  { icon: 'faPlaneDeparture', label: 'BUW Bau-Bau, Indonesia, Baubau, BUW, baubau bau bau indonesia', key:'A_BUW', cityBase: 127, city: 'BUW', country: 'ID', type: 'airport', nlabel: 'baubau bau bau indonesia'},
  { icon: 'faPlaneDeparture', label: 'VLI Port Vila, Vanuatu, Bauerfield International, VLI, bauerfield international port vila vanuatu', key:'A_VLI', cityBase: 82, city: 'VLI', country: 'VU', type: 'airport', nlabel: 'bauerfield international port vila vanuatu'},
  { icon: 'faPlaneDeparture', label: 'JTC Bauru, Brazil, Bauru-Arealva, JTC, bauru arealva bauru state of sao paulo brazil', key:'A_JTC', cityBase: 127, city: 'JTC', country: 'BR', type: 'airport', nlabel: 'bauru arealva bauru state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'RLK Bayannur, China, Bayannur Tianjitai, RLK, bayannur tianjitai bayannur china', key:'A_RLK', cityBase: 69, city: 'RLK', country: 'CN', type: 'airport', nlabel: 'bayannur tianjitai bayannur china'},
  { icon: 'faPlaneDeparture', label: 'XBE Bearskin Lake, Canada, Bearskin Lake, XBE, bearskin lake bearskin lake ontario canada', key:'A_XBE', cityBase: 127, city: 'XBE', country: 'CA', type: 'airport', nlabel: 'bearskin lake bearskin lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'BVA Paris, France, Beauvais–Tillé, BVA, beauvais tille paris france', key:'A_BVA', cityBase: 46, city: 'PAR', country: 'FR', type: 'airport', nlabel: 'beauvais tille paris france'},
  { icon: 'faPlaneDeparture', label: 'WBQ Beaver, United States, Beaver, WBQ, beaver beaver alaska united states', key:'A_WBQ', cityBase: 127, city: 'WBQ', country: 'US', type: 'airport', nlabel: 'beaver beaver alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BEU Bedourie, Australia, Bedourie, BEU, bedourie bedourie queensland australia', key:'A_BEU', cityBase: 127, city: 'BEU', country: 'AU', type: 'airport', nlabel: 'bedourie bedourie queensland australia'},
  { icon: 'faPlaneDeparture', label: 'NBC Nizhnekamsk, Russia, Begishevo, NBC, begishevo nizhnekamsk russia', key:'A_NBC', cityBase: 47, city: 'NBC', country: 'RU', type: 'airport', nlabel: 'begishevo nizhnekamsk russia'},
  { icon: 'faPlaneDeparture', label: 'BHY Beihai, China, Beihai Fucheng, BHY, beihai fucheng beihai china', key:'A_BHY', cityBase: 62, city: 'BHY', country: 'CN', type: 'airport', nlabel: 'beihai fucheng beihai china'},
  { icon: 'faPlaneDeparture', label: 'PEK Beijing, China, Beijing Capital International, PEK, beijing capital international beijing china', key:'A_PEK', cityBase: 23, city: 'BJS', country: 'CN', type: 'airport', nlabel: 'beijing capital international beijing china'},
  { icon: 'faPlaneDeparture', label: 'NAY Beijing, China, Beijing Nanyuan, NAY, beijing nanyuan beijing china', key:'A_NAY', cityBase: 55, city: 'BJS', country: 'CN', type: 'airport', nlabel: 'beijing nanyuan beijing china'},
  { icon: 'faPlaneDeparture', label: 'BEW Beira, Mozambique, Beira, BEW, beira beira mozambique', key:'A_BEW', cityBase: 127, city: 'BEW', country: 'MZ', type: 'airport', nlabel: 'beira beira mozambique'},
  { icon: 'faPlaneDeparture', label: 'BEY Beirut, Lebanon, Beirut–Rafic Hariri International, BEY, beirut rafic hariri international beirut lebanon', key:'A_BEY', cityBase: 17, city: 'BEY', country: 'LB', type: 'airport', nlabel: 'beirut rafic hariri international beirut lebanon'},
  { icon: 'faPlaneDeparture', label: 'BGN Belaya Gora, Sakha Republic, Russia, Belaya Gora, BGN, belaya gora belaya gora russia', key:'A_BGN', cityBase: 127, city: 'BGN', country: 'RU', type: 'airport', nlabel: 'belaya gora belaya gora russia'},
  { icon: 'faPlaneDeparture', label: 'BFS Belfast, United Kingdom, Belfast International, BFS, belfast international belfast united kingdom', key:'A_BFS', cityBase: -989, city: 'BFS', country: 'GB', type: 'airport', nlabel: 'belfast international belfast united kingdom'},
  { icon: 'faPlaneDeparture', label: 'IXG Belgaum, India, Belgaum, IXG, belgaum belgaum india', key:'A_IXG', cityBase: 127, city: 'IXG', country: 'IN', type: 'airport', nlabel: 'belgaum belgaum india'},
  { icon: 'faPlaneDeparture', label: 'EGO Belgorod, Russia, Belgorod International, EGO, belgorod international belgorod russia', key:'A_EGO', cityBase: 48, city: 'EGO', country: 'RU', type: 'airport', nlabel: 'belgorod international belgorod russia'},
  { icon: 'faPlaneDeparture', label: 'BEG Belgrade, Serbia, Belgrade Nikola Tesla, BEG, belgrade nikola tesla belgrade serbia', key:'A_BEG', cityBase: 26, city: 'BEG', country: 'RS', type: 'airport', nlabel: 'belgrade nikola tesla belgrade serbia'},
  { icon: 'faPlaneDeparture', label: 'TZA Belize City, Belize, Belize City Municipal, TZA, belize city municipal belize city belize', key:'A_TZA', cityBase: 83, city: 'BZE', country: 'BZ', type: 'airport', nlabel: 'belize city municipal belize city belize'},
  { icon: 'faPlaneDeparture', label: 'ZEL Bella Bella, Canada, Bella Bella (Campbell Island), ZEL, bella bella (campbell island) bella bella british columbia canada', key:'A_ZEL', cityBase: 127, city: 'ZEL', country: 'CA', type: 'airport', nlabel: 'bella bella (campbell island) bella bella british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'QBC Bella Coola, Canada, Bella Coola, QBC, bella coola bella coola british columbia canada', key:'A_QBC', cityBase: 127, city: 'QBC', country: 'CA', type: 'airport', nlabel: 'bella coola bella coola british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'BLI Bellingham, United States, Bellingham International, BLI, bellingham international bellingham washington united states', key:'A_BLI', cityBase: 52, city: 'BLI', country: 'US', type: 'airport', nlabel: 'bellingham international bellingham washington united states'},
  { icon: 'faPlaneDeparture', label: 'BNY Bellona Island, Solomon Islands, Bellona/Anua, BNY, bellona anua bellona island solomon islands', key:'A_BNY', cityBase: 127, city: 'BNY', country: 'SB', type: 'airport', nlabel: 'bellona anua bellona island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'PLU Belo Horizonte, Brazil, Belo Horizonte/Pampulha – Carlos Drummond de Andrade, PLU, belo horizonte pampulha carlos drummond de andrade belo horizonte state of minas gerais brazil', key:'A_PLU', cityBase: 127, city: 'BHZ', country: 'BR', type: 'airport', nlabel: 'belo horizonte pampulha carlos drummond de andrade belo horizonte state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'EYK Beloyarsky, Russia, Beloyarsk, EYK, beloyarsk beloyarsky russia', key:'A_EYK', cityBase: 58, city: 'EYK', country: 'RU', type: 'airport', nlabel: 'beloyarsk beloyarsky russia'},
  { icon: 'faPlaneDeparture', label: 'BJI Bemidji, United States, Bemidji Regional, BJI, bemidji regional bemidji minnesota united states', key:'A_BJI', cityBase: 92, city: 'BJI', country: 'US', type: 'airport', nlabel: 'bemidji regional bemidji minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'TLV Tel Aviv, Israel, Ben Gurion, TLV, ben gurion tel aviv israel', key:'A_TLV', cityBase: 1, city: 'TLV', country: 'IL', type: 'airport', nlabel: 'ben gurion tel aviv israel'},
  { icon: 'faPlaneDeparture', label: 'BEB Benbecula, United Kingdom, Benbecula, BEB, benbecula benbecula united kingdom', key:'A_BEB', cityBase: -873, city: 'BEB', country: 'GB', type: 'airport', nlabel: 'benbecula benbecula united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BLR Bengaluru, India, Bengaluru International, BLR, bengaluru international bengaluru india', key:'A_BLR', cityBase: 23, city: 'BLR', country: 'IN', type: 'airport', nlabel: 'bengaluru international bengaluru india'},
  { icon: 'faPlaneDeparture', label: 'BNC Beni, Democratic Republic of the Congo, Democratic Republic of the Congo, Beni Mavivi, BNC, beni mavivi beni congo kinshasa', key:'A_BNC', cityBase: 127, city: 'BNC', country: 'CD', type: 'airport', nlabel: 'beni mavivi beni congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'BEM Beni Mellal, Morocco, Beni Mellal, BEM, beni mellal beni mellal morocco', key:'A_BEM', cityBase: 127, city: 'BEM', country: 'MA', type: 'airport', nlabel: 'beni mellal beni mellal morocco'},
  { icon: 'faPlaneDeparture', label: 'BNI Benin City, Nigeria, Benin, BNI, benin benin city nigeria', key:'A_BNI', cityBase: 120, city: 'BNI', country: 'NG', type: 'airport', nlabel: 'benin benin city nigeria'},
  { icon: 'faPlaneDeparture', label: 'BEN Benghazi, Libya, Benina International, BEN, benina international benghazi libya', key:'A_BEN', cityBase: 101, city: 'BEN', country: 'LY', type: 'airport', nlabel: 'benina international benghazi libya'},
  { icon: 'faPlaneDeparture', label: 'NVA Neiva, Huila, Colombia, Benito Salas, NVA, benito salas neiva colombia', key:'A_NVA', cityBase: 119, city: 'NVA', country: 'CO', type: 'airport', nlabel: 'benito salas neiva colombia'},
  { icon: 'faPlaneDeparture', label: 'CPX Culebra, United States, Benjamín Rivera Noriega, CPX, benjamin rivera noriega culebra puerto rico united states', key:'A_CPX', cityBase: 94, city: 'CPX', country: 'US', type: 'airport', nlabel: 'benjamin rivera noriega culebra puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'BJT Bentota, Sri Lanka, Bentota River SPB, BJT, bentota river spb bentota sri lanka', key:'A_BJT', cityBase: 127, city: 'BJT', country: 'LK', type: 'airport', nlabel: 'bentota river spb bentota sri lanka'},
  { icon: 'faPlaneDeparture', label: 'BBO Berbera, Somalia, Berbera Airport, BBO, berbera airport berbera somalia', key:'A_BBO', cityBase: 127, city: 'BBO', country: 'SO', type: 'airport', nlabel: 'berbera airport berbera somalia'},
  { icon: 'faPlaneDeparture', label: 'YBV Berens River, Manitoba, Canada, Berens River, YBV, berens river berens river manitoba canada', key:'A_YBV', cityBase: 127, city: 'YBV', country: 'CA', type: 'airport', nlabel: 'berens river berens river manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'EZV Beryozovo, Khanty-Mansi Autonomous Okrug, Russia, Berezovo, EZV, berezovo beryozovo russia', key:'A_EZV', cityBase: 127, city: 'EZV', country: 'RU', type: 'airport', nlabel: 'berezovo beryozovo russia'},
  { icon: 'faPlaneDeparture', label: 'BGO Bergen, Norway, Bergen Airport, Flesland, BGO, bergen airport bergen norway', key:'A_BGO', cityBase: 41, city: 'BGO', country: 'NO', type: 'airport', nlabel: 'bergen airport bergen norway'},
  { icon: 'faPlaneDeparture', label: 'EGC Bergerac, France, Bergerac Dordogne Périgord, EGC, bergerac dordogne perigord bergerac france', key:'A_EGC', cityBase: 58, city: 'EGC', country: 'FR', type: 'airport', nlabel: 'bergerac dordogne perigord bergerac france'},
  { icon: 'faPlaneDeparture', label: 'BVG Berlevåg, Norway, Berlevåg, BVG, berlevag berlevag norway', key:'A_BVG', cityBase: 127, city: 'BVG', country: 'NO', type: 'airport', nlabel: 'berlevag berlevag norway'},
  { icon: 'faPlaneDeparture', label: 'BER Berlin, Germany, Berlin Brandenburg, BER, berlin brandenburg berlin germany', key:'A_BER', cityBase: 7, city: 'BER', country: 'DE', type: 'airport', nlabel: 'berlin brandenburg berlin germany'},
  { icon: 'faPlaneDeparture', label: 'SXF Berlin, Germany, Berlin Schönefeld, SXF, berlin schonefeld berlin germany', key:'A_SXF', cityBase: 3, city: 'BER', country: 'DE', type: 'airport', nlabel: 'berlin schonefeld berlin germany'},
  { icon: 'faPlaneDeparture', label: 'TXL Berlin, Germany, Berlin Tegel, TXL, berlin tegel berlin germany', key:'A_TXL', cityBase: 2, city: 'BER', country: 'DE', type: 'airport', nlabel: 'berlin tegel berlin germany'},
  { icon: 'faPlaneDeparture', label: 'BRN Bern, Switzerland, Bern, BRN, bern bern switzerland', key:'A_BRN', cityBase: 20, city: 'BRN', country: 'CH', type: 'airport', nlabel: 'bern bern switzerland'},
  { icon: 'faPlaneDeparture', label: 'BTM Butte, United States, Bert Mooney, BTM, bert mooney butte montana united states', key:'A_BTM', cityBase: 98, city: 'BTM', country: 'US', type: 'airport', nlabel: 'bert mooney butte montana united states'},
  { icon: 'faPlaneDeparture', label: 'OGZ Vladikavkaz, Russia, Beslan, OGZ, beslan vladikavkaz russia', key:'A_OGZ', cityBase: 46, city: 'OGZ', country: 'RU', type: 'airport', nlabel: 'beslan vladikavkaz russia'},
  { icon: 'faPlaneDeparture', label: 'BET Bethel, United States, Bethel, BET, bethel bethel alaska united states', key:'A_BET', cityBase: 126, city: 'BET', country: 'US', type: 'airport', nlabel: 'bethel bethel alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BTT Bettles, United States, Bettles, BTT, bettles bettles alaska united states', key:'A_BTT', cityBase: 127, city: 'BTT', country: 'US', type: 'airport', nlabel: 'bettles bettles alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BZR Béziers, France, Béziers Cap d Agde, BZR, beziers cap d agde beziers france', key:'A_BZR', cityBase: 126, city: 'BZR', country: 'FR', type: 'airport', nlabel: 'beziers cap d agde beziers france'},
  { icon: 'faPlaneDeparture', label: 'BDP Bhadrapur, Mechi, Nepal, Bhadrapur, BDP, bhadrapur bhadrapur nepal', key:'A_BDP', cityBase: 127, city: 'BDP', country: 'NP', type: 'airport', nlabel: 'bhadrapur bhadrapur nepal'},
  { icon: 'faPlaneDeparture', label: 'BMO Bhamo, Myanmar (Burma), Bhamo, BMO, bhamo bhamo myanmar (burma)', key:'A_BMO', cityBase: 127, city: 'BMO', country: 'MM', type: 'airport', nlabel: 'bhamo bhamo myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'BHR Bharatpur, Nepal, Bharatpur, BHR, bharatpur bharatpur nepal', key:'A_BHR', cityBase: 127, city: 'BHR', country: 'NP', type: 'airport', nlabel: 'bharatpur bharatpur nepal'},
  { icon: 'faPlaneDeparture', label: 'BHU Bhavnagar, India, Bhavnagar, BHU, bhavnagar bhavnagar india', key:'A_BHU', cityBase: 127, city: 'BHU', country: 'IN', type: 'airport', nlabel: 'bhavnagar bhavnagar india'},
  { icon: 'faPlaneDeparture', label: 'BHJ Bhuj, India, Bhuj, BHJ, bhuj bhuj india', key:'A_BHJ', cityBase: 127, city: 'BHJ', country: 'IN', type: 'airport', nlabel: 'bhuj bhuj india'},
  { icon: 'faPlaneDeparture', label: 'BIQ Biarritz, France, Biarritz Pays Basque, BIQ, biarritz pays basque biarritz france', key:'A_BIQ', cityBase: 50, city: 'BIQ', country: 'FR', type: 'airport', nlabel: 'biarritz pays basque biarritz france'},
  { icon: 'faPlaneDeparture', label: 'BFJ Bijie, China, Bijie Feixiong, BFJ, bijie feixiong bijie china', key:'A_BFJ', cityBase: 70, city: 'BFJ', country: 'CN', type: 'airport', nlabel: 'bijie feixiong bijie china'},
  { icon: 'faPlaneDeparture', label: 'BBI Bhubaneswar, India, Biju Patnaik International, BBI, biju patnaik international bhubaneswar india', key:'A_BBI', cityBase: 66, city: 'BBI', country: 'IN', type: 'airport', nlabel: 'biju patnaik international bhubaneswar india'},
  { icon: 'faPlaneDeparture', label: 'BIO Bilbao, Spain, Bilbao, BIO, bilbao bilbao spain', key:'A_BIO', cityBase: 10, city: 'BIO', country: 'ES', type: 'airport', nlabel: 'bilbao bilbao spain'},
  { icon: 'faPlaneDeparture', label: 'BIL Billings, United States, Billings Logan International, BIL, billings logan international billings montana united states', key:'A_BIL', cityBase: 33, city: 'BIL', country: 'US', type: 'airport', nlabel: 'billings logan international billings montana united states'},
  { icon: 'faPlaneDeparture', label: 'BLL Billund, Denmark, Billund, BLL, billund billund denmark', key:'A_BLL', cityBase: 34, city: 'BLL', country: 'DK', type: 'airport', nlabel: 'billund billund denmark'},
  { icon: 'faPlaneDeparture', label: 'YTZ Toronto, Canada, Billy Bishop Toronto City, YTZ, billy bishop toronto city toronto ontario canada', key:'A_YTZ', cityBase: 35, city: 'YTO', country: 'CA', type: 'airport', nlabel: 'billy bishop toronto city toronto ontario canada'},
  { icon: 'faPlaneDeparture', label: 'GNS Gunungsitoli, Indonesia, Binaka, GNS, binaka gunungsitoli indonesia', key:'A_GNS', cityBase: 118, city: 'GNS', country: 'ID', type: 'airport', nlabel: 'binaka gunungsitoli indonesia'},
  { icon: 'faPlaneDeparture', label: 'BGG Bingöl, Turkey, Bingöl, BGG, bingol bingol turkey', key:'A_BGG', cityBase: 24, city: 'BGG', country: 'TR', type: 'airport', nlabel: 'bingol bingol turkey'},
  { icon: 'faPlaneDeparture', label: 'BTU Bintulu, Malaysia, Bintulu, BTU, bintulu bintulu malaysia', key:'A_BTU', cityBase: 127, city: 'BTU', country: 'MY', type: 'airport', nlabel: 'bintulu bintulu malaysia'},
  { icon: 'faPlaneDeparture', label: 'BIR Biratnagar, Nepal, Biratnagar, BIR, biratnagar biratnagar nepal', key:'A_BIR', cityBase: 127, city: 'BIR', country: 'NP', type: 'airport', nlabel: 'biratnagar biratnagar nepal'},
  { icon: 'faPlaneDeparture', label: 'BVI Birdsville, Australia, Birdsville, BVI, birdsville birdsville queensland australia', key:'A_BVI', cityBase: 127, city: 'BVI', country: 'AU', type: 'airport', nlabel: 'birdsville birdsville queensland australia'},
  { icon: 'faPlaneDeparture', label: 'XBJ Birjand, Iran, Birjand International, XBJ, birjand international birjand iran', key:'A_XBJ', cityBase: 127, city: 'XBJ', country: 'IR', type: 'airport', nlabel: 'birjand international birjand iran'},
  { icon: 'faPlaneDeparture', label: 'BHX Birmingham, United Kingdom, Birmingham, BHX, birmingham birmingham united kingdom', key:'A_BHX', cityBase: -995, city: 'BHX', country: 'GB', type: 'airport', nlabel: 'birmingham birmingham united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BHM Birmingham, United States, Birmingham-Shuttlesworth International, BHM, birmingham shuttlesworth international birmingham alabama united states', key:'A_BHM', cityBase: 12, city: 'BHM', country: 'US', type: 'airport', nlabel: 'birmingham shuttlesworth international birmingham alabama united states'},
  { icon: 'faPlaneDeparture', label: 'IXR Ranchi, India, Birsa Munda, IXR, birsa munda ranchi india', key:'A_IXR', cityBase: 111, city: 'IXR', country: 'IN', type: 'airport', nlabel: 'birsa munda ranchi india'},
  { icon: 'faPlaneDeparture', label: 'BHH Bisha, Saudi Arabia, Bisha Domestic, BHH, bisha domestic bisha saudi arabia', key:'A_BHH', cityBase: 127, city: 'BHH', country: 'SA', type: 'airport', nlabel: 'bisha domestic bisha saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'FNT Flint, United States, Bishop International, FNT, bishop international flint michigan united states', key:'A_FNT', cityBase: 51, city: 'FNT', country: 'US', type: 'airport', nlabel: 'bishop international flint michigan united states'},
  { icon: 'faPlaneDeparture', label: 'BSK Biskra, Algeria, Biskra, BSK, biskra biskra algeria', key:'A_BSK', cityBase: 124, city: 'BSK', country: 'DZ', type: 'airport', nlabel: 'biskra biskra algeria'},
  { icon: 'faPlaneDeparture', label: 'BIS Bismarck, United States, Bismarck Municipal, BIS, bismarck municipal bismarck north dakota united states', key:'A_BIS', cityBase: 49, city: 'BIS', country: 'US', type: 'airport', nlabel: 'bismarck municipal bismarck north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'BKQ Blackall, Australia, Blackall, BKQ, blackall blackall queensland australia', key:'A_BKQ', cityBase: 127, city: 'BKQ', country: 'AU', type: 'airport', nlabel: 'blackall blackall queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BLK Blackpool, United Kingdom, Blackpool, BLK, blackpool blackpool united kingdom', key:'A_BLK', cityBase: -891, city: 'BLK', country: 'GB', type: 'airport', nlabel: 'blackpool blackpool united kingdom'},
  { icon: 'faPlaneDeparture', label: 'DSS Dakar, Senegal, Blaise Diagne International Airport, DSS, blaise diagne international airport dakar senegal', key:'A_DSS', cityBase: 43, city: 'DKR', country: 'SN', type: 'airport', nlabel: 'blaise diagne international airport dakar senegal'},
  { icon: 'faPlaneDeparture', label: 'BWX Banyuwangi, Indonesia, Blimbingsari, BWX, blimbingsari banyuwangi indonesia', key:'A_BWX', cityBase: 127, city: 'BWX', country: 'ID', type: 'airport', nlabel: 'blimbingsari banyuwangi indonesia'},
  { icon: 'faPlaneDeparture', label: 'BFN Bloemfontein, South Africa, Bloemfontein, BFN, bloemfontein bloemfontein south africa', key:'A_BFN', cityBase: 127, city: 'BFN', country: 'ZA', type: 'airport', nlabel: 'bloemfontein bloemfontein south africa'},
  { icon: 'faPlaneDeparture', label: 'LEX Lexington, United States, Blue Grass, LEX, blue grass lexington kentucky united states', key:'A_LEX', cityBase: 23, city: 'LEX', country: 'US', type: 'airport', nlabel: 'blue grass lexington kentucky united states'},
  { icon: 'faPlaneDeparture', label: 'BVB Boa Vista, Brazil, Boa Vista International, BVB, boa vista international boa vista state of roraima brazil', key:'A_BVB', cityBase: 78, city: 'BVB', country: 'BR', type: 'airport', nlabel: 'boa vista international boa vista state of roraima brazil'},
  { icon: 'faPlaneDeparture', label: 'IAN Kiana, United States, Bob Baker Memorial, IAN, bob baker memorial kiana alaska united states', key:'A_IAN', cityBase: 127, city: 'IAN', country: 'US', type: 'airport', nlabel: 'bob baker memorial kiana alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BOY Bobo-Dioulasso, Burkina Faso, Bobo Dioulasso, BOY, bobo dioulasso bobo dioulasso burkina faso', key:'A_BOY', cityBase: 127, city: 'BOY', country: 'BF', type: 'airport', nlabel: 'bobo dioulasso bobo dioulasso burkina faso'},
  { icon: 'faPlaneDeparture', label: 'BOC Bocas Town, Bocas del Toro, Panama, Bocas del Toro  Isla Colón  International, BOC, bocas del toro isla colon international bocas town panama', key:'A_BOC', cityBase: 85, city: 'BOC', country: 'PA', type: 'airport', nlabel: 'bocas del toro isla colon international bocas town panama'},
  { icon: 'faPlaneDeparture', label: 'ODO Bodaybo, Russia, Bodaybo, ODO, bodaybo bodaybo russia', key:'A_ODO', cityBase: 127, city: 'ODO', country: 'RU', type: 'airport', nlabel: 'bodaybo bodaybo russia'},
  { icon: 'faPlaneDeparture', label: 'BOO Bodø, Norway, Bodø, BOO, bodo bodo norway', key:'A_BOO', cityBase: 92, city: 'BOO', country: 'NO', type: 'airport', nlabel: 'bodo bodo norway'},
  { icon: 'faPlaneDeparture', label: 'BFI Seattle, United States, Boeing Field, BFI, boeing field seattle washington united states', key:'A_BFI', cityBase: 100, city: 'SEA', country: 'US', type: 'airport', nlabel: 'boeing field seattle washington united states'},
  { icon: 'faPlaneDeparture', label: 'TOF Tomsk, Russia, Bogashevo, TOF, bogashevo tomsk russia', key:'A_TOF', cityBase: 44, city: 'TOF', country: 'RU', type: 'airport', nlabel: 'bogashevo tomsk russia'},
  { icon: 'faPlaneDeparture', label: 'BOI Boise, United States, Boise, BOI, boise boise idaho united states', key:'A_BOI', cityBase: 12, city: 'BOI', country: 'US', type: 'airport', nlabel: 'boise boise idaho united states'},
  { icon: 'faPlaneDeparture', label: 'BJB Bojnord, Iran, Bojnord, BJB, bojnord bojnord iran', key:'A_BJB', cityBase: 127, city: 'BJB', country: 'IR', type: 'airport', nlabel: 'bojnord bojnord iran'},
  { icon: 'faPlaneDeparture', label: 'VBP Bokpyin, Myanmar (Burma), Bokpyin, VBP, bokpyin bokpyin myanmar (burma)', key:'A_VBP', cityBase: 127, city: 'VBP', country: 'MM', type: 'airport', nlabel: 'bokpyin bokpyin myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'BLQ Bologna, Italy, Bologna Guglielmo Marconi, BLQ, bologna guglielmo marconi bologna italy', key:'A_BLQ', cityBase: 10, city: 'BLQ', country: 'IT', type: 'airport', nlabel: 'bologna guglielmo marconi bologna italy'},
  { icon: 'faPlaneDeparture', label: 'BYO Bonito, Brazil, Bonito, BYO, bonito bonito state of mato grosso do sul brazil', key:'A_BYO', cityBase: 127, city: 'BYO', country: 'BR', type: 'airport', nlabel: 'bonito bonito state of mato grosso do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'TRW Bairiki, Kiribati, Bonriki International, TRW, bonriki international bairiki kiribati', key:'A_TRW', cityBase: 86, city: 'TRW', country: 'KI', type: 'airport', nlabel: 'bonriki international bairiki kiribati'},
  { icon: 'faPlaneDeparture', label: 'HRO Harrison, United States, Boone County, HRO, boone county harrison arkansas united states', key:'A_HRO', cityBase: 127, city: 'HRO', country: 'US', type: 'airport', nlabel: 'boone county harrison arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'BOB Bora Bora, French Polynesia, Bora Bora, BOB, bora bora bora bora french polynesia', key:'A_BOB', cityBase: 38, city: 'BOB', country: 'PF', type: 'airport', nlabel: 'bora bora bora bora french polynesia'},
  { icon: 'faPlaneDeparture', label: 'BOD Bordeaux, France, Bordeaux–Mérignac, BOD, bordeaux merignac bordeaux france', key:'A_BOD', cityBase: 11, city: 'BOD', country: 'FR', type: 'airport', nlabel: 'bordeaux merignac bordeaux france'},
  { icon: 'faPlaneDeparture', label: 'BMW Bordj Badji Mokhtar, Algeria, Bordj Mokhtar, BMW, bordj mokhtar bordj badji mokhtar algeria', key:'A_BMW', cityBase: 127, city: 'BMW', country: 'DZ', type: 'airport', nlabel: 'bordj mokhtar bordj badji mokhtar algeria'},
  { icon: 'faPlaneDeparture', label: 'HBE Alexandria, Egypt, Borg El Arab, HBE, borg el arab alexandria egypt', key:'A_HBE', cityBase: 17, city: 'ALY', country: 'EG', type: 'airport', nlabel: 'borg el arab alexandria egypt'},
  { icon: 'faPlaneDeparture', label: 'RNN Bornholm, Denmark, Bornholm, RNN, bornholm bornholm denmark', key:'A_RNN', cityBase: 107, city: 'RNN', country: 'DK', type: 'airport', nlabel: 'bornholm bornholm denmark'},
  { icon: 'faPlaneDeparture', label: 'KBP Kiev, Ukraine, Boryspil International, KBP, boryspil international kiev ukraine', key:'A_KBP', cityBase: 27, city: 'IEV', country: 'UA', type: 'airport', nlabel: 'boryspil international kiev ukraine'},
  { icon: 'faPlaneDeparture', label: 'BSA Bosaso, Somalia, Bosaso, BSA, bosaso bosaso somalia', key:'A_BSA', cityBase: 89, city: 'BSA', country: 'SO', type: 'airport', nlabel: 'bosaso bosaso somalia'},
  { icon: 'faPlaneDeparture', label: 'BST Lashkargah, Afghanistan, Bost, BST, bost lashkargah afghanistan', key:'A_BST', cityBase: 127, city: 'BST', country: 'AF', type: 'airport', nlabel: 'bost lashkargah afghanistan'},
  { icon: 'faPlaneDeparture', label: 'BYK Bouaké, Côte d’Ivoire, Bouaké, BYK, bouake bouake cote d ivoire', key:'A_BYK', cityBase: 127, city: 'BYK', country: 'CI', type: 'airport', nlabel: 'bouake bouake cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'CBH Béchar, Algeria, Boudghene Ben Ali Lotfi, CBH, boudghene ben ali lotfi bechar algeria', key:'A_CBH', cityBase: 127, city: 'CBH', country: 'DZ', type: 'airport', nlabel: 'boudghene ben ali lotfi bechar algeria'},
  { icon: 'faPlaneDeparture', label: 'BLD Las Vegas, United States, Boulder City Municipal, BLD, boulder city municipal las vegas nevada united states', key:'A_BLD', cityBase: 127, city: 'LAS', country: 'US', type: 'airport', nlabel: 'boulder city municipal las vegas nevada united states'},
  { icon: 'faPlaneDeparture', label: 'BQL Boulia, Australia, Boulia, BQL, boulia boulia queensland australia', key:'A_BQL', cityBase: 127, city: 'BQL', country: 'AU', type: 'airport', nlabel: 'boulia boulia queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BOH Bournemouth, United Kingdom, Bournemouth, BOH, bournemouth bournemouth united kingdom', key:'A_BOH', cityBase: -939, city: 'BOH', country: 'GB', type: 'airport', nlabel: 'bournemouth bournemouth united kingdom'},
  { icon: 'faPlaneDeparture', label: 'OTL Boutilimit, Mauritania, Boutilimit Airport, OTL, boutilimit airport boutilimit mauritania', key:'A_OTL', cityBase: 127, city: 'null', country: 'MR', type: 'airport', nlabel: 'boutilimit airport boutilimit mauritania'},
  { icon: 'faPlaneDeparture', label: 'BZN Bozeman, United States, Bozeman Yellowstone International, BZN, bozeman yellowstone international bozeman montana united states', key:'A_BZN', cityBase: 24, city: 'BZN', country: 'US', type: 'airport', nlabel: 'bozeman yellowstone international bozeman montana united states'},
  { icon: 'faPlaneDeparture', label: 'BWK Brač, Croatia, Brač, BWK, brac brac croatia', key:'A_BWK', cityBase: 33, city: 'BWK', country: 'HR', type: 'airport', nlabel: 'brac brac croatia'},
  { icon: 'faPlaneDeparture', label: 'BDL Hartford, United States, Bradley International, BDL, bradley international hartford connecticut united states', key:'A_BDL', cityBase: 6, city: 'HFD', country: 'US', type: 'airport', nlabel: 'bradley international hartford connecticut united states'},
  { icon: 'faPlaneDeparture', label: 'BRD Brainerd, United States, Brainerd Lakes Regional, BRD, brainerd lakes regional brainerd minnesota united states', key:'A_BRD', cityBase: 126, city: 'BRD', country: 'US', type: 'airport', nlabel: 'brainerd lakes regional brainerd minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'YBR Brandon, Canada, Brandon Municipal, YBR, brandon municipal brandon manitoba canada', key:'A_YBR', cityBase: 127, city: 'YBR', country: 'CA', type: 'airport', nlabel: 'brandon municipal brandon manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'BKG Branson, United States, Branson, BKG, branson branson missouri united states', key:'A_BKG', cityBase: 48, city: 'BKG', country: 'US', type: 'airport', nlabel: 'branson branson missouri united states'},
  { icon: 'faPlaneDeparture', label: 'BSB Brasília, Brazil, Brasília International, BSB, brasilia international brasilia federal district brazil', key:'A_BSB', cityBase: 55, city: 'BSB', country: 'BR', type: 'airport', nlabel: 'brasilia international brasilia federal district brazil'},
  { icon: 'faPlaneDeparture', label: 'BTS Bratislava, Slovakia, Bratislava Airport, BTS, bratislava bratislava slovakia', key:'A_BTS', cityBase: 3, city: 'BTS', country: 'SK', type: 'airport', nlabel: 'bratislava bratislava slovakia'},
  { icon: 'faPlaneDeparture', label: 'BTK Bratsk, Russia, Bratsk, BTK, bratsk bratsk russia', key:'A_BTK', cityBase: 51, city: 'BTK', country: 'RU', type: 'airport', nlabel: 'bratsk bratsk russia'},
  { icon: 'faPlaneDeparture', label: 'BRE Bremen, Germany, Bremen, BRE, bremen bremen germany', key:'A_BRE', cityBase: 5, city: 'BRE', country: 'DE', type: 'airport', nlabel: 'bremen bremen germany'},
  { icon: 'faPlaneDeparture', label: 'VBS Brescia, Italy, Brescia / Montichiari Airport, VBS, brescia montichiari airport brescia italy', key:'A_VBS', cityBase: 127, city: 'BRZ', country: 'IT', type: 'airport', nlabel: 'brescia montichiari airport brescia italy'},
  { icon: 'faPlaneDeparture', label: 'BQT Brest, Belarus, Brest, BQT, brest brest belarus', key:'A_BQT', cityBase: 127, city: 'BQT', country: 'BY', type: 'airport', nlabel: 'brest brest belarus'},
  { icon: 'faPlaneDeparture', label: 'BES Brest, France, Brest Bretagne, BES, brest bretagne brest france', key:'A_BES', cityBase: 63, city: 'BES', country: 'FR', type: 'airport', nlabel: 'brest bretagne brest france'},
  { icon: 'faPlaneDeparture', label: 'KTS Brevig Mission, United States, Brevig Mission, KTS, brevig mission brevig mission alaska united states', key:'A_KTS', cityBase: 127, city: 'KTS', country: 'US', type: 'airport', nlabel: 'brevig mission brevig mission alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LUQ San Luis, Argentina, Brigadier Mayor César Raúl Ojeda, LUQ, brigadier mayor cesar raul ojeda san luis san luis argentina', key:'A_LUQ', cityBase: 127, city: 'LUQ', country: 'AR', type: 'airport', nlabel: 'brigadier mayor cesar raul ojeda san luis san luis argentina'},
  { icon: 'faPlaneDeparture', label: 'BDS Brindisi, Italy, Brindisi, BDS, brindisi brindisi italy', key:'A_BDS', cityBase: 12, city: 'BDS', country: 'IT', type: 'airport', nlabel: 'brindisi brindisi italy'},
  { icon: 'faPlaneDeparture', label: 'BNE Brisbane, Australia, Brisbane, BNE, brisbane brisbane queensland australia', key:'A_BNE', cityBase: 30, city: 'BNE', country: 'AU', type: 'airport', nlabel: 'brisbane brisbane queensland australia'},
  { icon: 'faPlaneDeparture', label: 'WTB Toowoomba, Australia, Brisbane West Wellcamp, WTB, brisbane west wellcamp toowoomba queensland australia', key:'A_WTB', cityBase: 127, city: 'TWB', country: 'AU', type: 'airport', nlabel: 'brisbane west wellcamp toowoomba queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BRS Bristol, United Kingdom, Bristol, BRS, bristol bristol united kingdom', key:'A_BRS', cityBase: -994, city: 'BRS', country: 'GB', type: 'airport', nlabel: 'bristol bristol united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BVE Brive-la-Gaillarde, France, Brive–Souillac Airport, BVE, brive laroche brive la gaillarde france', key:'A_BVE', cityBase: 96, city: 'BVE', country: 'FR', type: 'airport', nlabel: 'brive laroche brive la gaillarde france'},
  { icon: 'faPlaneDeparture', label: 'BRQ Brno, Czechia, Brno–Tuřany, BRQ, brno turany brno czechia', key:'A_BRQ', cityBase: 5, city: 'BRQ', country: 'CZ', type: 'airport', nlabel: 'brno turany brno czechia'},
  { icon: 'faPlaneDeparture', label: 'YBT Brochet, Manitoba, Canada, Brochet, YBT, brochet brochet manitoba canada', key:'A_YBT', cityBase: 127, city: 'YBT', country: 'CA', type: 'airport', nlabel: 'brochet brochet manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'BHQ Broken Hill, Australia, Broken Hill, BHQ, broken hill broken hill new south wales australia', key:'A_BHQ', cityBase: 127, city: 'BHQ', country: 'AU', type: 'airport', nlabel: 'broken hill broken hill new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'BNN Brønnøysund, Norway, Brønnøysund Airport, Brønnøy, BNN, bronnoysund airport bronnoysund norway', key:'A_BNN', cityBase: 127, city: 'BNN', country: 'NO', type: 'airport', nlabel: 'bronnoysund airport bronnoysund norway'},
  { icon: 'faPlaneDeparture', label: 'BME Broome, Australia, Broome International, BME, broome international broome western australia australia', key:'A_BME', cityBase: 107, city: 'BME', country: 'AU', type: 'airport', nlabel: 'broome international broome western australia australia'},
  { icon: 'faPlaneDeparture', label: 'BRO Brownsville, United States, Brownsville/South Padre Island International, BRO, brownsville south padre island international brownsville texas united states', key:'A_BRO', cityBase: 38, city: 'BRO', country: 'US', type: 'airport', nlabel: 'brownsville south padre island international brownsville texas united states'},
  { icon: 'faPlaneDeparture', label: 'BWN Bandar Seri Begawan, Brunei, Brunei International, BWN, brunei international bandar seri begawan brunei', key:'A_BWN', cityBase: 61, city: 'BWN', country: 'BN', type: 'airport', nlabel: 'brunei international bandar seri begawan brunei'},
  { icon: 'faPlaneDeparture', label: 'BQK Brunswick, United States, Brunswick Golden Isles, BQK, brunswick golden isles brunswick georgia united states', key:'A_BQK', cityBase: 87, city: 'SSI', country: 'US', type: 'airport', nlabel: 'brunswick golden isles brunswick georgia united states'},
  { icon: 'faPlaneDeparture', label: 'BRU City of Brussels, Belgium, Brussels, BRU, brussels city of brussels belgium', key:'A_BRU', cityBase: 8, city: 'BRU', country: 'BE', type: 'airport', nlabel: 'brussels city of brussels belgium'},
  { icon: 'faPlaneDeparture', label: 'CRL City of Brussels, Belgium, Brussels South Charleroi, CRL, brussels south charleroi city of brussels belgium', key:'A_CRL', cityBase: 35, city: 'BRU', country: 'BE', type: 'airport', nlabel: 'brussels south charleroi city of brussels belgium'},
  { icon: 'faPlaneDeparture', label: 'BZK Bryansk, Russia, Bryansk International, BZK, bryansk international bryansk russia', key:'A_BZK', cityBase: 60, city: 'BZK', country: 'RU', type: 'airport', nlabel: 'bryansk international bryansk russia'},
  { icon: 'faPlaneDeparture', label: 'LUW Luwuk, Indonesia, Bubung, LUW, bubung luwuk indonesia', key:'A_LUW', cityBase: 127, city: 'LUW', country: 'ID', type: 'airport', nlabel: 'bubung luwuk indonesia'},
  { icon: 'faPlaneDeparture', label: 'CCR Concord, United States, Buchanan Field, CCR, buchanan field concord california united states', key:'A_CCR', cityBase: 87, city: 'CCR', country: 'US', type: 'airport', nlabel: 'buchanan field concord california united states'},
  { icon: 'faPlaneDeparture', label: 'KWA Kwajalein Atoll, Marshall Islands, Bucholz Army Airfield, KWA, bucholz army airfield kwajalein atoll marshall islands', key:'A_KWA', cityBase: 123, city: 'KWA', country: 'MH', type: 'airport', nlabel: 'bucholz army airfield kwajalein atoll marshall islands'},
  { icon: 'faPlaneDeparture', label: 'BKC Buckland, United States, Buckland, BKC, buckland buckland alaska united states', key:'A_BKC', cityBase: 127, city: 'BKC', country: 'US', type: 'airport', nlabel: 'buckland buckland alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BUD Budapest, Hungary, Budapest Ferenc Liszt International, BUD, budapest ferenc liszt international budapest hungary', key:'A_BUD', cityBase: 1, city: 'BUD', country: 'HU', type: 'airport', nlabel: 'budapest ferenc liszt international budapest hungary'},
  { icon: 'faPlaneDeparture', label: 'BUF Buffalo, United States, Buffalo Niagara International, BUF, buffalo niagara international buffalo new york united states', key:'A_BUF', cityBase: 8, city: 'BUF', country: 'US', type: 'airport', nlabel: 'buffalo niagara international buffalo new york united states'},
  { icon: 'faPlaneDeparture', label: 'UUA Bugulma, Russia, Bugulma, UUA, bugulma bugulma russia', key:'A_UUA', cityBase: 52, city: 'UUA', country: 'RU', type: 'airport', nlabel: 'bugulma bugulma russia'},
  { icon: 'faPlaneDeparture', label: 'BJM Bujumbura, Burundi, Bujumbura International, BJM, bujumbura international bujumbura burundi', key:'A_BJM', cityBase: 87, city: 'BJM', country: 'BI', type: 'airport', nlabel: 'bujumbura international bujumbura burundi'},
  { icon: 'faPlaneDeparture', label: 'BUA Buka Island, Papua New Guinea, Buka, BUA, buka buka island papua new guinea', key:'A_BUA', cityBase: 127, city: 'BUA', country: 'PG', type: 'airport', nlabel: 'buka buka island papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'BHK Bukhara, Uzbekistan, Bukhara International, BHK, bukhara international bukhara uzbekistan', key:'A_BHK', cityBase: 63, city: 'BHK', country: 'UZ', type: 'airport', nlabel: 'bukhara international bukhara uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'BKZ Bukoba, Tanzania, Bukoba, BKZ, bukoba bukoba tanzania', key:'A_BKZ', cityBase: 127, city: 'BKZ', country: 'TZ', type: 'airport', nlabel: 'bukoba bukoba tanzania'},
  { icon: 'faPlaneDeparture', label: 'BUL Bulolo, Papua New Guinea, Bulolo Airport, BUL, bulolo airport bulolo papua new guinea', key:'A_BUL', cityBase: 127, city: 'BUL', country: 'PG', type: 'airport', nlabel: 'bulolo airport bulolo papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'BMB Bumba, Democratic Republic of the Congo, Democratic Republic of the Congo, Bumba, BMB, bumba bumba congo kinshasa', key:'A_BMB', cityBase: 127, city: 'BMB', country: 'CD', type: 'airport', nlabel: 'bumba bumba congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'BDB Bundaberg Region, Australia, Bundaberg, BDB, bundaberg bundaberg region queensland australia', key:'A_BDB', cityBase: 127, city: 'BDB', country: 'AU', type: 'airport', nlabel: 'bundaberg bundaberg region queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BUX Bunia, Democratic Republic of the Congo, Bunia, BUX, bunia bunia congo kinshasa', key:'A_BUX', cityBase: 127, city: 'BUX', country: 'CD', type: 'airport', nlabel: 'bunia bunia congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'BMV Buon Ma Thuot, Vietnam, Buon Ma Thuot, BMV, buon ma thuot buon ma thuot vietnam', key:'A_BMV', cityBase: 127, city: 'BMV', country: 'VN', type: 'airport', nlabel: 'buon ma thuot buon ma thuot vietnam'},
  { icon: 'faPlaneDeparture', label: 'BOJ Burgas, Bulgaria, Burgas, BOJ, burgas burgas bulgaria', key:'A_BOJ', cityBase: 10, city: 'BOJ', country: 'BG', type: 'airport', nlabel: 'burgas burgas bulgaria'},
  { icon: 'faPlaneDeparture', label: 'RGS Burgos, Spain, Burgos Airport, RGS, burgos airport burgos spain', key:'A_RGS', cityBase: 111, city: 'RGS', country: 'ES', type: 'airport', nlabel: 'burgos airport burgos spain'},
  { icon: 'faPlaneDeparture', label: 'BFV Buriram Province, Thailand, Buriram, BFV, buriram buriram province thailand', key:'A_BFV', cityBase: 108, city: 'BFV', country: 'TH', type: 'airport', nlabel: 'buriram buriram province thailand'},
  { icon: 'faPlaneDeparture', label: 'BUC Burketown, Australia, Burketown, BUC, burketown burketown queensland australia', key:'A_BUC', cityBase: 127, city: 'BUC', country: 'AU', type: 'airport', nlabel: 'burketown burketown queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BTV Burlington, United States, Burlington International, BTV, burlington international burlington vermont united states', key:'A_BTV', cityBase: 24, city: 'BTV', country: 'US', type: 'airport', nlabel: 'burlington international burlington vermont united states'},
  { icon: 'faPlaneDeparture', label: 'BWT Burnie, Australia, Burnie, BWT, burnie burnie tasmania australia', key:'A_BWT', cityBase: 127, city: 'BWT', country: 'AU', type: 'airport', nlabel: 'burnie burnie tasmania australia'},
  { icon: 'faPlaneDeparture', label: 'BUZ Bushehr, Iran, Bushehr, BUZ, bushehr bushehr iran', key:'A_BUZ', cityBase: 127, city: 'BUZ', country: 'IR', type: 'airport', nlabel: 'bushehr bushehr iran'},
  { icon: 'faPlaneDeparture', label: 'BQB Busselton, Australia, Busselton Regional, BQB, busselton regional busselton western australia australia', key:'A_BQB', cityBase: 124, city: 'BQB', country: 'AU', type: 'airport', nlabel: 'busselton regional busselton western australia australia'},
  { icon: 'faPlaneDeparture', label: 'BZG Bydgoszcz, Poland, Bydgoszcz Ignacy Jan Paderewski, BZG, bydgoszcz ignacy jan paderewski bydgoszcz poland', key:'A_BZG', cityBase: 94, city: 'BZG', country: 'PL', type: 'airport', nlabel: 'bydgoszcz ignacy jan paderewski bydgoszcz poland'},
  { icon: 'faPlaneDeparture', label: 'BKA Moscow, Russia, Bykovo Airport, BKA, bykovo airport moscow russia', key:'A_BKA', cityBase: 127, city: 'MOW', country: 'RU', type: 'airport', nlabel: 'bykovo airport moscow russia'},
  { icon: 'faPlaneDeparture', label: 'CAH Ca Mau Province, Vietnam, Cà Mau, CAH, ca mau ca mau province vietnam', key:'A_CAH', cityBase: 127, city: 'CAH', country: 'VN', type: 'airport', nlabel: 'ca mau ca mau province vietnam'},
  { icon: 'faPlaneDeparture', label: 'CAB Cabinda, Angola, Cabinda, CAB, cabinda cabinda angola', key:'A_CAB', cityBase: 127, city: 'CAB', country: 'AO', type: 'airport', nlabel: 'cabinda cabinda angola'},
  { icon: 'faPlaneDeparture', label: 'CFB Cabo Frio, Brazil, Cabo Frio International, CFB, cabo frio international cabo frio state of rio de janeiro brazil', key:'A_CFB', cityBase: 127, city: 'CFB', country: 'BR', type: 'airport', nlabel: 'cabo frio international cabo frio state of rio de janeiro brazil'},
  { icon: 'faPlaneDeparture', label: 'PYH Puerto Ayacucho, Venezuela, Cacique Aramare, PYH, cacique aramare puerto ayacucho venezuela', key:'A_PYH', cityBase: 126, city: 'PYH', country: 'VE', type: 'airport', nlabel: 'cacique aramare puerto ayacucho venezuela'},
  { icon: 'faPlaneDeparture', label: 'OAL Cacoal, Brazil, Cacoal, OAL, cacoal cacoal state of rondonia brazil', key:'A_OAL', cityBase: 127, city: 'OAL', country: 'BR', type: 'airport', nlabel: 'cacoal cacoal state of rondonia brazil'},
  { icon: 'faPlaneDeparture', label: 'TPP Tarapoto, Peru, Cad. FAP Guillermo del Castillo Paredes, TPP, cad fap guillermo del castillo paredes tarapoto peru', key:'A_TPP', cityBase: 80, city: 'TPP', country: 'PE', type: 'airport', nlabel: 'cad fap guillermo del castillo paredes tarapoto peru'},
  { icon: 'faPlaneDeparture', label: 'COO Cotonou, Benin, Cadjehoun, COO, cadjehoun cotonou benin', key:'A_COO', cityBase: 57, city: 'COO', country: 'BJ', type: 'airport', nlabel: 'cadjehoun cotonou benin'},
  { icon: 'faPlaneDeparture', label: 'CFR Caen, France, Caen – Carpiquet, CFR, caen carpiquet caen france', key:'A_CFR', cityBase: 81, city: 'CFR', country: 'FR', type: 'airport', nlabel: 'caen carpiquet caen france'},
  { icon: 'faPlaneDeparture', label: 'CAG Cagliari, Italy, Cagliari Elmas, CAG, cagliari elmas cagliari italy', key:'A_CAG', cityBase: 11, city: 'CAG', country: 'IT', type: 'airport', nlabel: 'cagliari elmas cagliari italy'},
  { icon: 'faPlaneDeparture', label: 'CNS Cairns, Australia, Cairns, CNS, cairns cairns queensland australia', key:'A_CNS', cityBase: 36, city: 'CNS', country: 'AU', type: 'airport', nlabel: 'cairns cairns queensland australia'},
  { icon: 'faPlaneDeparture', label: 'CAI Cairo, Egypt, Cairo International, CAI, cairo international cairo egypt', key:'A_CAI', cityBase: 5, city: 'CAI', country: 'EG', type: 'airport', nlabel: 'cairo international cairo egypt'},
  { icon: 'faPlaneDeparture', label: 'CYP Calbayog, Philippines, Calbayog, CYP, calbayog calbayog philippines', key:'A_CYP', cityBase: 127, city: 'CYP', country: 'PH', type: 'airport', nlabel: 'calbayog calbayog philippines'},
  { icon: 'faPlaneDeparture', label: 'CLV Caldas Novas, Brazil, Caldas Novas, CLV, caldas novas caldas novas state of goias brazil', key:'A_CLV', cityBase: 127, city: 'CLV', country: 'BR', type: 'airport', nlabel: 'caldas novas caldas novas state of goias brazil'},
  { icon: 'faPlaneDeparture', label: 'YYC Calgary, Canada, Calgary International, YYC, calgary international calgary alberta canada', key:'A_YYC', cityBase: 18, city: 'YYC', country: 'CA', type: 'airport', nlabel: 'calgary international calgary alberta canada'},
  { icon: 'faPlaneDeparture', label: 'CCJ Kozhikode, India, Calicut International, CCJ, calicut international kozhikode india', key:'A_CCJ', cityBase: 44, city: 'CCJ', country: 'IN', type: 'airport', nlabel: 'calicut international kozhikode india'},
  { icon: 'faPlaneDeparture', label: 'CLY Calvi, Haute-Corse, France, Calvi – Sainte-Catherine, CLY, calvi sainte catherine calvi france', key:'A_CLY', cityBase: 92, city: 'CLY', country: 'FR', type: 'airport', nlabel: 'calvi sainte catherine calvi france'},
  { icon: 'faPlaneDeparture', label: 'CXR Nha Trang, Vietnam, Cam Ranh International, CXR, cam ranh international nha trang vietnam', key:'A_CXR', cityBase: 48, city: 'NHA', country: 'VN', type: 'airport', nlabel: 'cam ranh international nha trang vietnam'},
  { icon: 'faPlaneDeparture', label: 'CBG Cambridge, United Kingdom, Cambridge, CBG, cambridge cambridge united kingdom', key:'A_CBG', cityBase: -873, city: 'CBG', country: 'GB', type: 'airport', nlabel: 'cambridge cambridge united kingdom'},
  { icon: 'faPlaneDeparture', label: 'YCB Cambridge Bay, Canada, Cambridge Bay, YCB, cambridge bay cambridge bay nunavut canada', key:'A_YCB', cityBase: 127, city: 'YCB', country: 'CA', type: 'airport', nlabel: 'cambridge bay cambridge bay nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'CGM Camiguin, Philippines, Camiguin, CGM, camiguin camiguin philippines', key:'A_CGM', cityBase: 89, city: 'CGM', country: 'PH', type: 'airport', nlabel: 'camiguin camiguin philippines'},
  { icon: 'faPlaneDeparture', label: 'CUC Cúcuta, Colombia, Camilo Daza International, CUC, camilo daza international cucuta colombia', key:'A_CUC', cityBase: 40, city: 'CUC', country: 'CO', type: 'airport', nlabel: 'camilo daza international cucuta colombia'},
  { icon: 'faPlaneDeparture', label: 'YBL Campbell River, Canada, Campbell River, YBL, campbell river campbell river british columbia canada', key:'A_YBL', cityBase: 125, city: 'YBL', country: 'CA', type: 'airport', nlabel: 'campbell river campbell river british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'CAL Campbeltown, United Kingdom, Campbeltown, CAL, campbeltown campbeltown united kingdom', key:'A_CAL', cityBase: -891, city: 'CAL', country: 'GB', type: 'airport', nlabel: 'campbeltown campbeltown united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CPE Campeche, Mexico, Campeche International, CPE, campeche international campeche mexico', key:'A_CPE', cityBase: 51, city: 'CPE', country: 'MX', type: 'airport', nlabel: 'campeche international campeche mexico'},
  { icon: 'faPlaneDeparture', label: 'CPV Campina Grande, Brazil, Campina Grande, CPV, campina grande campina grande state of paraiba brazil', key:'A_CPV', cityBase: 127, city: 'CPV', country: 'BR', type: 'airport', nlabel: 'campina grande campina grande state of paraiba brazil'},
  { icon: 'faPlaneDeparture', label: 'CXJ Caxias do Sul, Brazil, Campo dos Bugres, CXJ, campo dos bugres caxias do sul state of rio grande do sul brazil', key:'A_CXJ', cityBase: 127, city: 'CXJ', country: 'BR', type: 'airport', nlabel: 'campo dos bugres caxias do sul state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'CGR Campo Grande, Brazil, Campo Grande International, CGR, campo grande international campo grande state of mato grosso do sul brazil', key:'A_CGR', cityBase: 90, city: 'CGR', country: 'BR', type: 'airport', nlabel: 'campo grande international campo grande state of mato grosso do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'VCA Can Tho, Vietnam, Can Tho International, VCA, can tho international can tho vietnam', key:'A_VCA', cityBase: 127, city: 'VCA', country: 'VN', type: 'airport', nlabel: 'can tho international can tho vietnam'},
  { icon: 'faPlaneDeparture', label: 'CKZ Çanakkale, Turkey, Çanakkale, CKZ, canakkale canakkale turkey', key:'A_CKZ', cityBase: 28, city: 'CKZ', country: 'TR', type: 'airport', nlabel: 'canakkale canakkale turkey'},
  { icon: 'faPlaneDeparture', label: 'ZOS Osorno, Chile, Cañal Bajo Carlos Hott Siebert, ZOS, canal bajo carlos hott siebert osorno chile', key:'A_ZOS', cityBase: 127, city: 'ZOS', country: 'CL', type: 'airport', nlabel: 'canal bajo carlos hott siebert osorno chile'},
  { icon: 'faPlaneDeparture', label: 'CBR Canberra, Australia, Canberra, CBR, canberra canberra australian capital territory australia', key:'A_CBR', cityBase: 86, city: 'CBR', country: 'AU', type: 'airport', nlabel: 'canberra canberra australian capital territory australia'},
  { icon: 'faPlaneDeparture', label: 'CUN Cancún, Mexico, Cancún International, CUN, cancun international cancun mexico', key:'A_CUN', cityBase: 2, city: 'CUN', country: 'MX', type: 'airport', nlabel: 'cancun international cancun mexico'},
  { icon: 'faPlaneDeparture', label: 'DCF Saint Joseph Parish, Dominica, Canefield, DCF, canefield saint joseph parish dominica', key:'A_DCF', cityBase: 127, city: 'DOM', country: 'DM', type: 'airport', nlabel: 'canefield saint joseph parish dominica'},
  { icon: 'faPlaneDeparture', label: 'CWJ Cangyuan Va Autonomous County, China, Cangyuan Washan, CWJ, cangyuan washan cangyuan va autonomous county china', key:'A_CWJ', cityBase: 70, city: 'CWJ', country: 'CN', type: 'airport', nlabel: 'cangyuan washan cangyuan va autonomous county china'},
  { icon: 'faPlaneDeparture', label: 'CNY Moab, United States, Canyonlands Field, CNY, canyonlands field moab utah united states', key:'A_CNY', cityBase: 121, city: 'CNY', country: 'US', type: 'airport', nlabel: 'canyonlands field moab utah united states'},
  { icon: 'faPlaneDeparture', label: 'CSK Cap Skiring, Senegal, Cap Skirring, CSK, cap skirring cap skiring senegal', key:'A_CSK', cityBase: 127, city: 'CSK', country: 'SN', type: 'airport', nlabel: 'cap skirring cap skiring senegal'},
  { icon: 'faPlaneDeparture', label: 'CAP Cap-Haïtien, Haiti, Cap-Haïtien International, CAP, cap haitien international cap haitien haiti', key:'A_CAP', cityBase: 70, city: 'CAP', country: 'HT', type: 'airport', nlabel: 'cap haitien international cap haitien haiti'},
  { icon: 'faPlaneDeparture', label: 'TBP Tumbes, Peru, Cap. FAP Pedro Canga Rodríguez, TBP, cap fap pedro canga rodriguez tumbes peru', key:'A_TBP', cityBase: 91, city: 'TBP', country: 'PE', type: 'airport', nlabel: 'cap fap pedro canga rodriguez tumbes peru'},
  { icon: 'faPlaneDeparture', label: 'YTE Cape Dorset, Canada, Cape Dorset, YTE, cape dorset cape dorset nunavut canada', key:'A_YTE', cityBase: 127, city: 'YTE', country: 'CA', type: 'airport', nlabel: 'cape dorset cape dorset nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'CGI Cape Girardeau, United States, Cape Girardeau Regional, CGI, cape girardeau regional cape girardeau missouri united states', key:'A_CGI', cityBase: 77, city: 'CGI', country: 'US', type: 'airport', nlabel: 'cape girardeau regional cape girardeau missouri united states'},
  { icon: 'faPlaneDeparture', label: 'LUR Cape Lisburne, Alaska, United States, Cape Lisburne LRRS, LUR, cape lisburne lrrs cape lisburne alaska united states', key:'A_LUR', cityBase: 127, city: 'LUR', country: 'US', type: 'airport', nlabel: 'cape lisburne lrrs cape lisburne alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CPT Cape Town, South Africa, Cape Town International, CPT, cape town international cape town south africa', key:'A_CPT', cityBase: 22, city: 'CPT', country: 'ZA', type: 'airport', nlabel: 'cape town international cape town south africa'},
  { icon: 'faPlaneDeparture', label: 'LAN Lansing, United States, Capital Region International, LAN, capital region international lansing michigan united states', key:'A_LAN', cityBase: 58, city: 'LAN', country: 'US', type: 'airport', nlabel: 'capital region international lansing michigan united states'},
  { icon: 'faPlaneDeparture', label: 'PSZ Puerto Suarez, Bolivia, Capitan Av. Salvador Ogaya G. airport, PSZ, capitan av salvador ogaya g airport puerto suarez bolivia', key:'A_PSZ', cityBase: 127, city: 'PSZ', country: 'BO', type: 'airport', nlabel: 'capitan av salvador ogaya g airport puerto suarez bolivia'},
  { icon: 'faPlaneDeparture', label: 'PDP Punta del Este, Uruguay, Capitán de Corbeta Carlos A. Curbelo International, PDP, capitan de corbeta carlos a curbelo international punta del este uruguay', key:'A_PDP', cityBase: 127, city: 'PDP', country: 'UY', type: 'airport', nlabel: 'capitan de corbeta carlos a curbelo international punta del este uruguay'},
  { icon: 'faPlaneDeparture', label: 'PIO Pisco, Peru, Capitán FAP Renán Elías Olivera, PIO, capitan fap renan elias olivera pisco peru', key:'A_PIO', cityBase: 127, city: 'PIO', country: 'PE', type: 'airport', nlabel: 'capitan fap renan elias olivera pisco peru'},
  { icon: 'faPlaneDeparture', label: 'TYL Talara, Peru, Capitán FAP Víctor Montes Arias International, TYL, capitan fap victor montes arias international talara peru', key:'A_TYL', cityBase: 127, city: 'TYL', country: 'PE', type: 'airport', nlabel: 'capitan fap victor montes arias international talara peru'},
  { icon: 'faPlaneDeparture', label: 'WPR Porvenir, Chile, Capitan Fuentes Martinez, WPR, capitan fuentes martinez porvenir chile', key:'A_WPR', cityBase: 127, city: 'WPR', country: 'CL', type: 'airport', nlabel: 'capitan fuentes martinez porvenir chile'},
  { icon: 'faPlaneDeparture', label: 'TJA Tarija, Bolivia, Capitan Oriel Lea Plaza, TJA, capitan oriel lea plaza tarija bolivia', key:'A_TJA', cityBase: 107, city: 'TJA', country: 'BO', type: 'airport', nlabel: 'capitan oriel lea plaza tarija bolivia'},
  { icon: 'faPlaneDeparture', label: 'CIJ Cobija, Bolivia, Captain Aníbal Arab, CIJ, captain anibal arab cobija bolivia', key:'A_CIJ', cityBase: 127, city: 'CIJ', country: 'BO', type: 'airport', nlabel: 'captain anibal arab cobija bolivia'},
  { icon: 'faPlaneDeparture', label: 'POI Potosí, Bolivia, Captain Nicolas Rojas, POI, captain nicolas rojas potosi bolivia', key:'A_POI', cityBase: 127, city: 'POI', country: 'BO', type: 'airport', nlabel: 'captain nicolas rojas potosi bolivia'},
  { icon: 'faPlaneDeparture', label: 'CKS Parauapebas, Brazil, Carajás, CKS, carajas parauapebas state of para brazil', key:'A_CKS', cityBase: 127, city: 'CKS', country: 'BR', type: 'airport', nlabel: 'carajas parauapebas state of para brazil'},
  { icon: 'faPlaneDeparture', label: 'CCF Carcassonne, France, Carcassonne, CCF, carcassonne carcassonne france', key:'A_CCF', cityBase: 98, city: 'CCF', country: 'FR', type: 'airport', nlabel: 'carcassonne carcassonne france'},
  { icon: 'faPlaneDeparture', label: 'CWL Cardiff, United Kingdom, Cardiff, CWL, cardiff cardiff united kingdom', key:'A_CWL', cityBase: -989, city: 'CWL', country: 'GB', type: 'airport', nlabel: 'cardiff cardiff united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BYM Bayamo, Cuba, Carlos Manuel de Céspedes, BYM, carlos manuel de cespedes bayamo cuba', key:'A_BYM', cityBase: 127, city: 'BYM', country: 'CU', type: 'airport', nlabel: 'carlos manuel de cespedes bayamo cuba'},
  { icon: 'faPlaneDeparture', label: 'CVQ Carnarvon, Australia, Carnarvon, CVQ, carnarvon carnarvon western australia australia', key:'A_CVQ', cityBase: 127, city: 'CVQ', country: 'AU', type: 'airport', nlabel: 'carnarvon carnarvon western australia australia'},
  { icon: 'faPlaneDeparture', label: 'MVD Montevideo, Uruguay, Carrasco International, MVD, carrasco international montevideo uruguay', key:'A_MVD', cityBase: 28, city: 'MVD', country: 'UY', type: 'airport', nlabel: 'carrasco international montevideo uruguay'},
  { icon: 'faPlaneDeparture', label: 'CCP Concepción, Chile, Carriel Sur International, CCP, carriel sur international concepcion chile', key:'A_CCP', cityBase: 106, city: 'CCP', country: 'CL', type: 'airport', nlabel: 'carriel sur international concepcion chile'},
  { icon: 'faPlaneDeparture', label: 'CAC Cascavel, Brazil, Cascavel, CAC, cascavel cascavel state of parana brazil', key:'A_CAC', cityBase: 127, city: 'CAC', country: 'BR', type: 'airport', nlabel: 'cascavel cascavel state of parana brazil'},
  { icon: 'faPlaneDeparture', label: 'CPR Casper, United States, Casper–Natrona County International, CPR, casper natrona county international casper wyoming united states', key:'A_CPR', cityBase: 94, city: 'CPR', country: 'US', type: 'airport', nlabel: 'casper natrona county international casper wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'CXI Kiritimati, Kiribati, Cassidy International, CXI, cassidy international kiritimati kiribati', key:'A_CXI', cityBase: 127, city: 'CXI', country: 'KI', type: 'airport', nlabel: 'cassidy international kiritimati kiribati'},
  { icon: 'faPlaneDeparture', label: 'CDT Castellón de la Plana, Spain, Castellón–Costa Azahar, CDT, castellon costa azahar castellon de la plana spain', key:'A_CDT', cityBase: 26, city: 'CDT', country: 'ES', type: 'airport', nlabel: 'castellon costa azahar castellon de la plana spain'},
  { icon: 'faPlaneDeparture', label: 'DCM Castres, France, Castres–Mazamet, DCM, castres mazamet castres france', key:'A_DCM', cityBase: 59, city: 'DCM', country: 'FR', type: 'airport', nlabel: 'castres mazamet castres france'},
  { icon: 'faPlaneDeparture', label: 'HPH Haiphong, Vietnam, Cat Bi International, HPH, cat bi international haiphong vietnam', key:'A_HPH', cityBase: 90, city: 'HPH', country: 'VN', type: 'airport', nlabel: 'cat bi international haiphong vietnam'},
  { icon: 'faPlaneDeparture', label: 'YAC Cat Lake, Canada, Cat Lake, YAC, cat lake cat lake ontario canada', key:'A_YAC', cityBase: 127, city: 'YAC', country: 'CA', type: 'airport', nlabel: 'cat lake cat lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'CTA Catania, Italy, Catania–Fontanarossa, CTA, catania fontanarossa catania italy', key:'A_CTA', cityBase: 10, city: 'CTA', country: 'IT', type: 'airport', nlabel: 'catania fontanarossa catania italy'},
  { icon: 'faPlaneDeparture', label: 'IGR Puerto Iguazú, Argentina, Cataratas del Iguazú International, IGR, cataratas del iguazu international puerto iguazu misiones argentina', key:'A_IGR', cityBase: 48, city: 'IGR', country: 'AR', type: 'airport', nlabel: 'cataratas del iguazu international puerto iguazu misiones argentina'},
  { icon: 'faPlaneDeparture', label: 'CRM Catarman, Northern Samar, Philippines, Catarman National, CRM, catarman national catarman philippines', key:'A_CRM', cityBase: 127, city: 'CRM', country: 'PH', type: 'airport', nlabel: 'catarman national catarman philippines'},
  { icon: 'faPlaneDeparture', label: 'CBT Catumbela, Angola, Catumbela, CBT, catumbela catumbela angola', key:'A_CBT', cityBase: 127, city: 'CBT', country: 'AO', type: 'airport', nlabel: 'catumbela catumbela angola'},
  { icon: 'faPlaneDeparture', label: 'CYZ Cauayan, Isabela, Philippines, Cauayan, CYZ, cauayan cauayan philippines', key:'A_CYZ', cityBase: 122, city: 'CYZ', country: 'PH', type: 'airport', nlabel: 'cauayan cauayan philippines'},
  { icon: 'faPlaneDeparture', label: 'LQM Puerto Leguízamo, Colombia, Caucayá, LQM, caucaya puerto leguizamo colombia', key:'A_LQM', cityBase: 127, city: 'LQM', country: 'CO', type: 'airport', nlabel: 'caucaya puerto leguizamo colombia'},
  { icon: 'faPlaneDeparture', label: 'ONQ Zonguldak, Turkey, Caycuma, ONQ, caycuma zonguldak turkey', key:'A_ONQ', cityBase: 27, city: 'ONQ', country: 'TR', type: 'airport', nlabel: 'caycuma zonguldak turkey'},
  { icon: 'faPlaneDeparture', label: 'CUK Caye Caulker, Belize, Caye Caulker, CUK, caye caulker caye caulker belize', key:'A_CUK', cityBase: 127, city: 'CUK', country: 'BZ', type: 'airport', nlabel: 'caye caulker caye caulker belize'},
  { icon: 'faPlaneDeparture', label: 'CAY Cayenne, France, Cayenne – Félix Eboué, CAY, cayenne felix eboue cayenne french guiana france', key:'A_CAY', cityBase: 115, city: 'CAY', country: 'FR', type: 'airport', nlabel: 'cayenne felix eboue cayenne french guiana france'},
  { icon: 'faPlaneDeparture', label: 'CDC Cedar City, United States, Cedar City Regional, CDC, cedar city regional cedar city utah united states', key:'A_CDC', cityBase: 116, city: 'CDC', country: 'US', type: 'airport', nlabel: 'cedar city regional cedar city utah united states'},
  { icon: 'faPlaneDeparture', label: 'CED Ceduna, Australia, Ceduna, CED, ceduna ceduna south australia australia', key:'A_CED', cityBase: 127, city: 'CED', country: 'AU', type: 'airport', nlabel: 'ceduna ceduna south australia australia'},
  { icon: 'faPlaneDeparture', label: 'APA Denver, United States, Centennial Airport, APA, centennial airport denver colorado united states', key:'A_APA', cityBase: 127, city: 'DEN', country: 'US', type: 'airport', nlabel: 'centennial airport denver colorado united states'},
  { icon: 'faPlaneDeparture', label: 'CEM Central, United States, Central, CEM, central central alaska united states', key:'A_CEM', cityBase: 127, city: 'CEM', country: 'US', type: 'airport', nlabel: 'central central alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BMI Bloomington, United States, Central Illinois Regional, BMI, central illinois regional bloomington illinois united states', key:'A_BMI', cityBase: 79, city: 'BMI', country: 'US', type: 'airport', nlabel: 'central illinois regional bloomington illinois united states'},
  { icon: 'faPlaneDeparture', label: 'GRI Grand Island, United States, Central Nebraska Regional, GRI, central nebraska regional grand island nebraska united states', key:'A_GRI', cityBase: 87, city: 'GRI', country: 'US', type: 'airport', nlabel: 'central nebraska regional grand island nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'CWA Wausau, United States, Central Wisconsin, CWA, central wisconsin wausau wisconsin united states', key:'A_CWA', cityBase: 61, city: 'AUW', country: 'US', type: 'airport', nlabel: 'central wisconsin wausau wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'PDA Inírida, Guainía, Colombia, César Gaviria Trujillo, PDA, cesar gaviria trujillo inirida colombia', key:'A_PDA', cityBase: 127, city: 'PDA', country: 'CO', type: 'airport', nlabel: 'cesar gaviria trujillo inirida colombia'},
  { icon: 'faPlaneDeparture', label: 'VXE São Vicente, Cape Verde, Cesária Évora, VXE, cesaria evora sao vicente cape verde', key:'A_VXE', cityBase: 120, city: 'VXE', country: 'CV', type: 'airport', nlabel: 'cesaria evora sao vicente cape verde'},
  { icon: 'faPlaneDeparture', label: 'YBG Saguenay, Canada, CFB Bagotville, YBG, cfb bagotville saguenay quebec canada', key:'A_YBG', cityBase: 124, city: 'YBG', country: 'CA', type: 'airport', nlabel: 'cfb bagotville saguenay quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YQQ Comox, Canada, CFB Comox, YQQ, cfb comox comox british columbia canada', key:'A_YQQ', cityBase: 97, city: 'YQQ', country: 'CA', type: 'airport', nlabel: 'cfb comox comox british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'YYR Happy Valley-Goose Bay, Canada, CFB Goose Bay, YYR, cfb goose bay happy valley goose bay newfoundland and labrador canada', key:'A_YYR', cityBase: 127, city: 'YYR', country: 'CA', type: 'airport', nlabel: 'cfb goose bay happy valley goose bay newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'ARI Arica, Chile, Chacalluta International, ARI, chacalluta international arica chile', key:'A_ARI', cityBase: 90, city: 'ARI', country: 'CL', type: 'airport', nlabel: 'chacalluta international arica chile'},
  { icon: 'faPlaneDeparture', label: 'CDR Chadron, United States, Chadron Municipal, CDR, chadron municipal chadron nebraska united states', key:'A_CDR', cityBase: 108, city: 'CDR', country: 'US', type: 'airport', nlabel: 'chadron municipal chadron nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'CIK Chalkyitsik, United States, Chalkyitsik, CIK, chalkyitsik chalkyitsik alaska united states', key:'A_CIK', cityBase: 127, city: 'CIK', country: 'US', type: 'airport', nlabel: 'chalkyitsik chalkyitsik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'XCR Paris, France, Châlons Vatry, XCR, chalons vatry paris france', key:'A_XCR', cityBase: 51, city: 'PAR', country: 'FR', type: 'airport', nlabel: 'chalons vatry paris france'},
  { icon: 'faPlaneDeparture', label: 'CMF Chambéry, France, Chambéry, CMF, chambery chambery france', key:'A_CMF', cityBase: 96, city: 'CMF', country: 'FR', type: 'airport', nlabel: 'chambery chambery france'},
  { icon: 'faPlaneDeparture', label: 'CPO Copiapó, Chile, Chamonate, CPO, chamonate copiapo chile', key:'A_CPO', cityBase: 127, city: 'CPO', country: 'CL', type: 'airport', nlabel: 'chamonate copiapo chile'},
  { icon: 'faPlaneDeparture', label: 'IXC Chandigarh, India, Chandigarh International, IXC, chandigarh international chandigarh india', key:'A_IXC', cityBase: 75, city: 'IXC', country: 'IN', type: 'airport', nlabel: 'chandigarh international chandigarh india'},
  { icon: 'faPlaneDeparture', label: 'NBS Baishan, China, Changbaishan, NBS, changbaishan baishan china', key:'A_NBS', cityBase: 74, city: 'NBS', country: 'CN', type: 'airport', nlabel: 'changbaishan baishan china'},
  { icon: 'faPlaneDeparture', label: 'CGQ Changchun, China, Changchun Longjia International, CGQ, changchun longjia international changchun china', key:'A_CGQ', cityBase: 56, city: 'CGQ', country: 'CN', type: 'airport', nlabel: 'changchun longjia international changchun china'},
  { icon: 'faPlaneDeparture', label: 'CGD Changde, China, Changde Taohuayuan, CGD, changde taohuayuan changde china', key:'A_CGD', cityBase: 72, city: 'CGD', country: 'CN', type: 'airport', nlabel: 'changde taohuayuan changde china'},
  { icon: 'faPlaneDeparture', label: 'CSX Changsha, China, Changsha Huanghua International, CSX, changsha huanghua international changsha china', key:'A_CSX', cityBase: 30, city: 'CSX', country: 'CN', type: 'airport', nlabel: 'changsha huanghua international changsha china'},
  { icon: 'faPlaneDeparture', label: 'CHX Changuinola, Panama, Changuinola  Capitán Manuel Niño  International, CHX, changuinola capitan manuel nino international changuinola panama', key:'A_CHX', cityBase: 127, city: 'CHX', country: 'PA', type: 'airport', nlabel: 'changuinola capitan manuel nino international changuinola panama'},
  { icon: 'faPlaneDeparture', label: 'CIH Changzhi, China, Changzhi Wangcun, CIH, changzhi wangcun changzhi china', key:'A_CIH', cityBase: 71, city: 'CIH', country: 'CN', type: 'airport', nlabel: 'changzhi wangcun changzhi china'},
  { icon: 'faPlaneDeparture', label: 'CZX Changzhou, China, Changzhou Benniu, CZX, changzhou benniu changzhou china', key:'A_CZX', cityBase: 78, city: 'CZX', country: 'CN', type: 'airport', nlabel: 'changzhou benniu changzhou china'},
  { icon: 'faPlaneDeparture', label: 'CHQ Chania, Greece, Chania International, CHQ, chania international chania greece', key:'A_CHQ', cityBase: 11, city: 'CHQ', country: 'GR', type: 'airport', nlabel: 'chania international chania greece'},
  { icon: 'faPlaneDeparture', label: 'CHG Chaoyang, Liaoning, China, Chaoyang, CHG, chaoyang chaoyang china', key:'A_CHG', cityBase: 71, city: 'CHG', country: 'CN', type: 'airport', nlabel: 'chaoyang chaoyang china'},
  { icon: 'faPlaneDeparture', label: 'XAP Chapecó, Brazil, Chapecó, XAP, chapeco chapeco state of santa catarina brazil', key:'A_XAP', cityBase: 127, city: 'XAP', country: 'BR', type: 'airport', nlabel: 'chapeco chapeco state of santa catarina brazil'},
  { icon: 'faPlaneDeparture', label: 'MKC Kansas City, United States, Charles B. Wheeler Downtown, MKC, charles b wheeler downtown kansas city missouri united states', key:'A_MKC', cityBase: 76, city: 'MKC', country: 'US', type: 'airport', nlabel: 'charles b wheeler downtown kansas city missouri united states'},
  { icon: 'faPlaneDeparture', label: 'CDG Paris, France, Charles de Gaulle Airport, CDG, charles de gaulle paris france', key:'A_CDG', cityBase: 4, city: 'PAR', country: 'FR', type: 'airport', nlabel: 'charles de gaulle paris france'},
  { icon: 'faPlaneDeparture', label: 'CYB Cayman Brac, Cayman Islands, Charles Kirkconnell International, CYB, charles kirkconnell international cayman brac cayman islands', key:'A_CYB', cityBase: 127, city: 'CYB', country: 'KY', type: 'airport', nlabel: 'charles kirkconnell international cayman brac cayman islands'},
  { icon: 'faPlaneDeparture', label: 'STS Santa Rosa, United States, Charles M. Schulz–Sonoma County, STS, charles m schulz sonoma county santa rosa california united states', key:'A_STS', cityBase: 32, city: 'STS', country: 'US', type: 'airport', nlabel: 'charles m schulz sonoma county santa rosa california united states'},
  { icon: 'faPlaneDeparture', label: 'CHS Charleston, United States, Charleston International, CHS, charleston international charleston south carolina united states', key:'A_CHS', cityBase: 8, city: 'CHS', country: 'US', type: 'airport', nlabel: 'charleston international charleston south carolina united states'},
  { icon: 'faPlaneDeparture', label: 'CTL Charleville, Australia, Charleville, CTL, charleville charleville queensland australia', key:'A_CTL', cityBase: 127, city: 'CTL', country: 'AU', type: 'airport', nlabel: 'charleville charleville queensland australia'},
  { icon: 'faPlaneDeparture', label: 'YCL Charlo, Canada, Charlo, YCL, charlo charlo new brunswick canada', key:'A_YCL', cityBase: 127, city: 'YCL', country: 'CA', type: 'airport', nlabel: 'charlo charlo new brunswick canada'},
  { icon: 'faPlaneDeparture', label: 'SPB Saint Thomas, United States, Charlotte Amalie Harbor Seaplane Base, SPB, charlotte amalie harbor seaplane base saint thomas u s virgin islands united states', key:'A_SPB', cityBase: 106, city: 'STT', country: 'US', type: 'airport', nlabel: 'charlotte amalie harbor seaplane base saint thomas u s virgin islands united states'},
  { icon: 'faPlaneDeparture', label: 'CLT Charlotte, United States, Charlotte Douglas International, CLT, charlotte douglas international charlotte north carolina united states', key:'A_CLT', cityBase: 1, city: 'CLT', country: 'US', type: 'airport', nlabel: 'charlotte douglas international charlotte north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'CHO Charlottesville, United States, Charlottesville–Albemarle, CHO, charlottesville albemarle charlottesville virginia united states', key:'A_CHO', cityBase: 31, city: 'CHO', country: 'US', type: 'airport', nlabel: 'charlottesville albemarle charlottesville virginia united states'},
  { icon: 'faPlaneDeparture', label: 'YYG Charlottetown, Canada, Charlottetown, YYG, charlottetown charlottetown prince edward island canada', key:'A_YYG', cityBase: 59, city: 'YYG', country: 'CA', type: 'airport', nlabel: 'charlottetown charlottetown prince edward island canada'},
  { icon: 'faPlaneDeparture', label: 'CHA Chattanooga, United States, Chattanooga Metropolitan, CHA, chattanooga metropolitan chattanooga tennessee united states', key:'A_CHA', cityBase: 39, city: 'CHA', country: 'US', type: 'airport', nlabel: 'chattanooga metropolitan chattanooga tennessee united states'},
  { icon: 'faPlaneDeparture', label: 'LKO Lucknow, India, Chaudhary Charan Singh International, LKO, chaudhary charan singh international lucknow india', key:'A_LKO', cityBase: 64, city: 'LKO', country: 'IN', type: 'airport', nlabel: 'chaudhary charan singh international lucknow india'},
  { icon: 'faPlaneDeparture', label: 'CSY Cheboksary, Russia, Cheboksary, CSY, cheboksary cheboksary russia', key:'A_CSY', cityBase: 50, city: 'CSY', country: 'RU', type: 'airport', nlabel: 'cheboksary cheboksary russia'},
  { icon: 'faPlaneDeparture', label: 'GEO Georgetown, Guyana, Cheddi Jagan International, GEO, cheddi jagan international georgetown guyana', key:'A_GEO', cityBase: 39, city: 'GEO', country: 'GY', type: 'airport', nlabel: 'cheddi jagan international georgetown guyana'},
  { icon: 'faPlaneDeparture', label: 'CYF Chefornak, United States, Chefornak, CYF, chefornak chefornak alaska united states', key:'A_CYF', cityBase: 127, city: 'CYF', country: 'US', type: 'airport', nlabel: 'chefornak chefornak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TEE Tébessa, Algeria, Cheikh Larbi Tebessi, TEE, cheikh larbi tebessi tebessa algeria', key:'A_TEE', cityBase: 127, city: 'TEE', country: 'DZ', type: 'airport', nlabel: 'cheikh larbi tebessi tebessa algeria'},
  { icon: 'faPlaneDeparture', label: 'CEK Chelyabinsk, Russia, Chelyabinsk, CEK, chelyabinsk chelyabinsk russia', key:'A_CEK', cityBase: 43, city: 'CEK', country: 'RU', type: 'airport', nlabel: 'chelyabinsk chelyabinsk russia'},
  { icon: 'faPlaneDeparture', label: 'CDE Chengde, China, Chengde Puning, CDE, chengde puning chengde china', key:'A_CDE', cityBase: 127, city: 'CDE', country: 'CN', type: 'airport', nlabel: 'chengde puning chengde china'},
  { icon: 'faPlaneDeparture', label: 'CTU Chengdu, China, Chengdu Shuangliu International, CTU, chengdu shuangliu international chengdu china', key:'A_CTU', cityBase: 24, city: 'CTU', country: 'CN', type: 'airport', nlabel: 'chengdu shuangliu international chengdu china'},
  { icon: 'faPlaneDeparture', label: 'MAA Chennai, India, Chennai International, MAA, chennai international chennai india', key:'A_MAA', cityBase: 21, city: 'MAA', country: 'IN', type: 'airport', nlabel: 'chennai international chennai india'},
  { icon: 'faPlaneDeparture', label: 'CJJ Cheongju, South Korea, Cheongju International, CJJ, cheongju international cheongju south korea', key:'A_CJJ', cityBase: 100, city: 'CJJ', country: 'KR', type: 'airport', nlabel: 'cheongju international cheongju south korea'},
  { icon: 'faPlaneDeparture', label: 'CEE Cherepovets, Russia, Cherepovets, CEE, cherepovets cherepovets russia', key:'A_CEE', cityBase: 50, city: 'CEE', country: 'RU', type: 'airport', nlabel: 'cherepovets cherepovets russia'},
  { icon: 'faPlaneDeparture', label: 'AHU Al Hoceima, Morocco, Cherif Al Idrissi, AHU, cherif al idrissi al hoceima morocco', key:'A_AHU', cityBase: 103, city: 'AHU', country: 'MA', type: 'airport', nlabel: 'cherif al idrissi al hoceima morocco'},
  { icon: 'faPlaneDeparture', label: 'CWC Chernivtsi, Ukraine, Chernivtsi International, CWC, chernivtsi international chernivtsi ukraine', key:'A_CWC', cityBase: 103, city: 'CWC', country: 'UA', type: 'airport', nlabel: 'chernivtsi international chernivtsi ukraine'},
  { icon: 'faPlaneDeparture', label: 'TVC Traverse City, United States, Cherry Capital, TVC, cherry capital traverse city michigan united states', key:'A_TVC', cityBase: 36, city: 'TVC', country: 'US', type: 'airport', nlabel: 'cherry capital traverse city michigan united states'},
  { icon: 'faPlaneDeparture', label: 'CYX Chersky, Russia, Chersky, CYX, chersky chersky russia', key:'A_CYX', cityBase: 127, city: 'CYX', country: 'RU', type: 'airport', nlabel: 'chersky chersky russia'},
  { icon: 'faPlaneDeparture', label: 'YCS Chesterfield Inlet, Nunavut, Canada, Chesterfield Inlet, YCS, chesterfield inlet chesterfield inlet nunavut canada', key:'A_YCS', cityBase: 127, city: 'YCS', country: 'CA', type: 'airport', nlabel: 'chesterfield inlet chesterfield inlet nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'CTM Chetumal, Mexico, Chetumal International, CTM, chetumal international chetumal mexico', key:'A_CTM', cityBase: 52, city: 'CTM', country: 'MX', type: 'airport', nlabel: 'chetumal international chetumal mexico'},
  { icon: 'faPlaneDeparture', label: 'VAK Chevak, United States, Chevak, VAK, chevak chevak alaska united states', key:'A_VAK', cityBase: 127, city: 'VAK', country: 'US', type: 'airport', nlabel: 'chevak chevak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YHR Chevery, Quebec, Canada, Chevery, YHR, chevery chevery quebec canada', key:'A_YHR', cityBase: 127, city: 'YHR', country: 'CA', type: 'airport', nlabel: 'chevery chevery quebec canada'},
  { icon: 'faPlaneDeparture', label: 'CYS Cheyenne, United States, Cheyenne Regional, CYS, cheyenne regional cheyenne wyoming united states', key:'A_CYS', cityBase: 103, city: 'CYS', country: 'US', type: 'airport', nlabel: 'cheyenne regional cheyenne wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'BOM Mumbai, India, Chhatrapati Shivaji International, BOM, chhatrapati shivaji international mumbai india', key:'A_BOM', cityBase: 8, city: 'BOM', country: 'IN', type: 'airport', nlabel: 'chhatrapati shivaji international mumbai india'},
  { icon: 'faPlaneDeparture', label: 'CNX Chiang Mai, Thailand, Chiang Mai International, CNX, chiang mai international chiang mai thailand', key:'A_CNX', cityBase: 20, city: 'CNX', country: 'TH', type: 'airport', nlabel: 'chiang mai international chiang mai thailand'},
  { icon: 'faPlaneDeparture', label: 'CEI Chiang Rai Province, Thailand, Chiang Rai International, CEI, chiang rai international chiang rai province thailand', key:'A_CEI', cityBase: 62, city: 'CEI', country: 'TH', type: 'airport', nlabel: 'chiang rai international chiang rai province thailand'},
  { icon: 'faPlaneDeparture', label: 'CYI Chiayi, Taiwan, Chiayi, CYI, chiayi chiayi taiwan', key:'A_CYI', cityBase: 127, city: 'CYI', country: 'TW', type: 'airport', nlabel: 'chiayi chiayi taiwan'},
  { icon: 'faPlaneDeparture', label: 'YMT Chibougamau, Canada, Chibougamau/Chapais, YMT, chibougamau chapais chibougamau quebec canada', key:'A_YMT', cityBase: 127, city: 'YMT', country: 'CA', type: 'airport', nlabel: 'chibougamau chapais chibougamau quebec canada'},
  { icon: 'faPlaneDeparture', label: 'MDW Chicago, United States, Chicago Midway International, MDW, chicago midway international chicago illinois united states', key:'A_MDW', cityBase: 5, city: 'CHI', country: 'US', type: 'airport', nlabel: 'chicago midway international chicago illinois united states'},
  { icon: 'faPlaneDeparture', label: 'RFD Chicago, United States, Chicago Rockford International, RFD, chicago rockford international chicago illinois united states', key:'A_RFD', cityBase: 96, city: 'CHI', country: 'US', type: 'airport', nlabel: 'chicago rockford international chicago illinois united states'},
  { icon: 'faPlaneDeparture', label: 'CIF Chifeng, China, Chifeng Yulong, CIF, chifeng yulong chifeng china', key:'A_CIF', cityBase: 69, city: 'CIF', country: 'CN', type: 'airport', nlabel: 'chifeng yulong chifeng china'},
  { icon: 'faPlaneDeparture', label: 'KCG Chignik, United States, Chignik Fisheries, KCG, chignik fisheries chignik alaska united states', key:'A_KCG', cityBase: 127, city: 'KCL', country: 'US', type: 'airport', nlabel: 'chignik fisheries chignik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KCL Chignik, United States, Chignik Lagoon, KCL, chignik lagoon chignik alaska united states', key:'A_KCL', cityBase: 127, city: 'KCL', country: 'US', type: 'airport', nlabel: 'chignik lagoon chignik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KCQ Chignik, United States, Chignik Lake, KCQ, chignik lake chignik alaska united states', key:'A_KCQ', cityBase: 127, city: 'KCL', country: 'US', type: 'airport', nlabel: 'chignik lake chignik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CUU Chihuahua, Mexico, Chihuahua International, CUU, chihuahua international chihuahua mexico', key:'A_CUU', cityBase: 47, city: 'CUU', country: 'MX', type: 'airport', nlabel: 'chihuahua international chihuahua mexico'},
  { icon: 'faPlaneDeparture', label: 'BLZ Blantyre, Malawi, Chileka International, BLZ, chileka international blantyre malawi', key:'A_BLZ', cityBase: 79, city: 'BLZ', country: 'MW', type: 'airport', nlabel: 'chileka international blantyre malawi'},
  { icon: 'faPlaneDeparture', label: 'VPY Chimoio, Mozambique, Chimoio, VPY, chimoio chimoio mozambique', key:'A_VPY', cityBase: 127, city: 'VPY', country: 'MZ', type: 'airport', nlabel: 'chimoio chimoio mozambique'},
  { icon: 'faPlaneDeparture', label: 'CCA Chimoré, Cochabamba, Bolivia, Chimore, CCA, chimore chimore bolivia', key:'A_CCA', cityBase: 127, city: 'CCA', country: 'BO', type: 'airport', nlabel: 'chimore chimore bolivia'},
  { icon: 'faPlaneDeparture', label: 'TRR Trincomalee, Sri Lanka, China Bay, TRR, china bay trincomalee sri lanka', key:'A_TRR', cityBase: 127, city: 'TRR', country: 'LK', type: 'airport', nlabel: 'china bay trincomalee sri lanka'},
  { icon: 'faPlaneDeparture', label: 'ULN Ulaanbaatar, Mongolia, Chinggis Khaan International, ULN, chinggis khaan international ulaanbaatar mongolia', key:'A_ULN', cityBase: 42, city: 'ULN', country: 'MN', type: 'airport', nlabel: 'chinggis khaan international ulaanbaatar mongolia'},
  { icon: 'faPlaneDeparture', label: 'TET Tete, Mozambique, Chingozi, TET, chingozi tete mozambique', key:'A_TET', cityBase: 114, city: 'TET', country: 'MZ', type: 'airport', nlabel: 'chingozi tete mozambique'},
  { icon: 'faPlaneDeparture', label: 'JKH Chios, Greece, Chios Island National, JKH, chios island national chios greece', key:'A_JKH', cityBase: 20, city: 'JKH', country: 'GR', type: 'airport', nlabel: 'chios island national chios greece'},
  { icon: 'faPlaneDeparture', label: 'CIU Sault Sainte Marie, United States, Chippewa County International, CIU, chippewa county international sault sainte marie michigan united states', key:'A_CIU', cityBase: 108, city: 'SSM', country: 'US', type: 'airport', nlabel: 'chippewa county international sault sainte marie michigan united states'},
  { icon: 'faPlaneDeparture', label: 'EAU Eau Claire, United States, Chippewa Valley Regional, EAU, chippewa valley regional eau claire wisconsin united states', key:'A_EAU', cityBase: 89, city: 'EAU', country: 'US', type: 'airport', nlabel: 'chippewa valley regional eau claire wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'YKU Chisasibi, Canada, Chisasibi, YKU, chisasibi chisasibi quebec canada', key:'A_YKU', cityBase: 127, city: 'YKU', country: 'CA', type: 'airport', nlabel: 'chisasibi chisasibi quebec canada'},
  { icon: 'faPlaneDeparture', label: 'KIV Chișinău, Moldova, Chișinău International, KIV, chisinau international chisinau moldova', key:'A_KIV', cityBase: 39, city: 'KIV', country: 'MD', type: 'airport', nlabel: 'chisinau international chisinau moldova'},
  { icon: 'faPlaneDeparture', label: 'CJL Chitral, Pakistan, Chitral, CJL, chitral chitral pakistan', key:'A_CJL', cityBase: 127, city: 'CJL', country: 'PK', type: 'airport', nlabel: 'chitral chitral pakistan'},
  { icon: 'faPlaneDeparture', label: 'CTD Chitré, Panama, Chitre Alonso Valderrama, CTD, chitre alonso valderrama chitre panama', key:'A_CTD', cityBase: 127, city: 'CTD', country: 'PA', type: 'airport', nlabel: 'chitre alonso valderrama chitre panama'},
  { icon: 'faPlaneDeparture', label: 'JUH Chizhou, China, Chizhou Jiuhuashan, JUH, chizhou jiuhuashan chizhou china', key:'A_JUH', cityBase: 74, city: 'JUH', country: 'CN', type: 'airport', nlabel: 'chizhou jiuhuashan chizhou china'},
  { icon: 'faPlaneDeparture', label: 'CFK Chlef, Algeria, Chlef International, CFK, chlef international chlef algeria', key:'A_CFK', cityBase: 127, city: 'CFK', country: 'DZ', type: 'airport', nlabel: 'chlef international chlef algeria'},
  { icon: 'faPlaneDeparture', label: 'COQ Choibalsan, Mongolia, Choibalsan, COQ, choibalsan choibalsan mongolia', key:'A_COQ', cityBase: 127, city: 'COQ', country: 'MN', type: 'airport', nlabel: 'choibalsan choibalsan mongolia'},
  { icon: 'faPlaneDeparture', label: 'CHY Choiseul Bay, Solomon Islands, Choiseul Bay, CHY, choiseul bay choiseul bay solomon islands', key:'A_CHY', cityBase: 127, city: 'CHY', country: 'SB', type: 'airport', nlabel: 'choiseul bay choiseul bay solomon islands'},
  { icon: 'faPlaneDeparture', label: 'CKH Chokurdakh, Russia, Chokurdakh, CKH, chokurdakh chokurdakh russia', key:'A_CKH', cityBase: 127, city: 'CKH', country: 'RU', type: 'airport', nlabel: 'chokurdakh chokurdakh russia'},
  { icon: 'faPlaneDeparture', label: 'CKG Chongqing, China, Chongqing Jiangbei International, CKG, chongqing jiangbei international chongqing china', key:'A_CKG', cityBase: 30, city: 'CKG', country: 'CN', type: 'airport', nlabel: 'chongqing jiangbei international chongqing china'},
  { icon: 'faPlaneDeparture', label: 'CHC Christchurch, New Zealand, Christchurch International, CHC, christchurch international christchurch new zealand', key:'A_CHC', cityBase: 39, city: 'CHC', country: 'NZ', type: 'airport', nlabel: 'christchurch international christchurch new zealand'},
  { icon: 'faPlaneDeparture', label: 'SSB Saint Croix, United States, Christiansted Harbor Seaplane Base, SSB, christiansted harbor seaplane base saint croix u s virgin islands united states', key:'A_SSB', cityBase: 127, city: 'STX', country: 'US', type: 'airport', nlabel: 'christiansted harbor seaplane base saint croix u s virgin islands united states'},
  { icon: 'faPlaneDeparture', label: 'XCH Shire of, Christmas Island, Christmas Island, XCH, christmas island shire of christmas island', key:'A_XCH', cityBase: 127, city: 'XCH', country: 'CX', type: 'airport', nlabel: 'christmas island shire of christmas island'},
  { icon: 'faPlaneDeparture', label: 'VCL Chu Lai, Vietnam, Chu Lai International, VCL, chu lai international chu lai vietnam', key:'A_VCL', cityBase: 127, city: 'VCL', country: 'VN', type: 'airport', nlabel: 'chu lai international chu lai vietnam'},
  { icon: 'faPlaneDeparture', label: 'CHU Chuathbaluk, United States, Chuathbaluk, CHU, chuathbaluk chuathbaluk alaska united states', key:'A_CHU', cityBase: 127, city: 'CHU', country: 'US', type: 'airport', nlabel: 'chuathbaluk chuathbaluk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NGO Nagoya, Japan, Chubu Centrair International, NGO, chubu centrair international nagoya japan', key:'A_NGO', cityBase: 39, city: 'NGO', country: 'JP', type: 'airport', nlabel: 'chubu centrair international nagoya japan'},
  { icon: 'faPlaneDeparture', label: 'NER Neryungri, Russia, Chulman Neryungri Airport, NER, chulman neryungri russia', key:'A_NER', cityBase: 56, city: 'NER', country: 'RU', type: 'airport', nlabel: 'chulman neryungri russia'},
  { icon: 'faPlaneDeparture', label: 'CJM Chumphon Province, Thailand, Chumphon, CJM, chumphon chumphon province thailand', key:'A_CJM', cityBase: 104, city: 'CJM', country: 'TH', type: 'airport', nlabel: 'chumphon chumphon province thailand'},
  { icon: 'faPlaneDeparture', label: 'YYQ Churchill, Canada, Churchill, YYQ, churchill churchill manitoba canada', key:'A_YYQ', cityBase: 127, city: 'YYQ', country: 'CA', type: 'airport', nlabel: 'churchill churchill manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'ZUM Churchill Falls, Canada, Churchill Falls, ZUM, churchill falls churchill falls newfoundland and labrador canada', key:'A_ZUM', cityBase: 127, city: 'ZUM', country: 'CA', type: 'airport', nlabel: 'churchill falls churchill falls newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'TKK Chuuk State, Micronesia, Chuuk International, TKK, chuuk international chuuk state micronesia', key:'A_TKK', cityBase: 85, city: 'TKK', country: 'FM', type: 'airport', nlabel: 'chuuk international chuuk state micronesia'},
  { icon: 'faPlaneDeparture', label: 'CIA Rome, Italy, Ciampino–G. B. Pastine International, CIA, ciampino g b pastine international rome italy', key:'A_CIA', cityBase: 13, city: 'ROM', country: 'IT', type: 'airport', nlabel: 'ciampino g b pastine international rome italy'},
  { icon: 'faPlaneDeparture', label: 'STI Santiago de los Caballeros, Dominican Republic, Cibao International, STI, cibao international santiago de los caballeros dominican republic', key:'A_STI', cityBase: 13, city: 'STI', country: 'DO', type: 'airport', nlabel: 'cibao international santiago de los caballeros dominican republic'},
  { icon: 'faPlaneDeparture', label: 'ICI Cicia, Fiji, Cicia, ICI, cicia cicia fiji', key:'A_ICI', cityBase: 127, city: 'ICI', country: 'FJ', type: 'airport', nlabel: 'cicia cicia fiji'},
  { icon: 'faPlaneDeparture', label: 'LUK Cincinnati, United States, Cincinnati Municipal Lunken, LUK, cincinnati municipal lunken cincinnati ohio united states', key:'A_LUK', cityBase: 41, city: 'CVG', country: 'US', type: 'airport', nlabel: 'cincinnati municipal lunken cincinnati ohio united states'},
  { icon: 'faPlaneDeparture', label: 'CVG Cincinnati, United States, Cincinnati/Northern Kentucky International, CVG, cincinnati northern kentucky international cincinnati ohio united states', key:'A_CVG', cityBase: 4, city: 'CVG', country: 'US', type: 'airport', nlabel: 'cincinnati northern kentucky international cincinnati ohio united states'},
  { icon: 'faPlaneDeparture', label: 'IRC Circle, United States, Circle City, IRC, circle city circle alaska united states', key:'A_IRC', cityBase: 127, city: 'IRC', country: 'US', type: 'airport', nlabel: 'circle city circle alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LDY Derry, United Kingdom, City of Derry, LDY, city of derry derry united kingdom', key:'A_LDY', cityBase: -900, city: 'LDY', country: 'GB', type: 'airport', nlabel: 'city of derry derry united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LOH Loja, Ecuador, Ciudad de Catamayo, LOH, ciudad de catamayo loja ecuador', key:'A_LOH', cityBase: 47, city: 'LOH', country: 'EC', type: 'airport', nlabel: 'ciudad de catamayo loja ecuador'},
  { icon: 'faPlaneDeparture', label: 'CME Ciudad del Carmen, Mexico, Ciudad del Carmen International, CME, ciudad del carmen international ciudad del carmen mexico', key:'A_CME', cityBase: 51, city: 'CME', country: 'MX', type: 'airport', nlabel: 'ciudad del carmen international ciudad del carmen mexico'},
  { icon: 'faPlaneDeparture', label: 'CJS Ciudad Juárez, Mexico, Ciudad Juárez International, CJS, ciudad juarez international ciudad juarez mexico', key:'A_CJS', cityBase: 41, city: 'CJS', country: 'MX', type: 'airport', nlabel: 'ciudad juarez international ciudad juarez mexico'},
  { icon: 'faPlaneDeparture', label: 'CEN Ciudad Obregón, Mexico, Ciudad Obregón International, CEN, ciudad obregon international ciudad obregon mexico', key:'A_CEN', cityBase: 54, city: 'CEN', country: 'MX', type: 'airport', nlabel: 'ciudad obregon international ciudad obregon mexico'},
  { icon: 'faPlaneDeparture', label: 'CVM Ciudad Victoria, Mexico, Ciudad Victoria International, CVM, ciudad victoria international ciudad victoria mexico', key:'A_CVM', cityBase: 127, city: 'CVM', country: 'MX', type: 'airport', nlabel: 'ciudad victoria international ciudad victoria mexico'},
  { icon: 'faPlaneDeparture', label: 'GFN Grafton, Australia, Clarence Valley Regional, GFN, clarence valley regional grafton new south wales australia', key:'A_GFN', cityBase: 127, city: 'GFN', country: 'AU', type: 'airport', nlabel: 'clarence valley regional grafton new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'CRK Angeles, Philippines, Clark International, CRK, clark international angeles philippines', key:'A_CRK', cityBase: 40, city: 'CRK', country: 'PH', type: 'airport', nlabel: 'clark international angeles philippines'},
  { icon: 'faPlaneDeparture', label: 'CLP Clark\'s Point, United States, Clarks Point, CLP, clarks point clark s point alaska united states', key:'A_CLP', cityBase: 127, city: 'CLP', country: 'US', type: 'airport', nlabel: 'clarks point clark s point alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AXA Anguilla, Anguilla, Clayton J. Lloyd International, AXA, clayton j lloyd international anguilla anguilla', key:'A_AXA', cityBase: 95, city: 'AXA', country: 'AI', type: 'airport', nlabel: 'clayton j lloyd international anguilla anguilla'},
  { icon: 'faPlaneDeparture', label: 'CFE Clermont-Ferrand, France, Clermont-Ferrand Auvergne, CFE, clermont ferrand auvergne clermont ferrand france', key:'A_CFE', cityBase: 81, city: 'CFE', country: 'FR', type: 'airport', nlabel: 'clermont ferrand auvergne clermont ferrand france'},
  { icon: 'faPlaneDeparture', label: 'BKL Cleveland, United States, Cleveland Burke Lakefront, BKL, cleveland burke lakefront cleveland ohio united states', key:'A_BKL', cityBase: 55, city: 'CLE', country: 'US', type: 'airport', nlabel: 'cleveland burke lakefront cleveland ohio united states'},
  { icon: 'faPlaneDeparture', label: 'CLE Cleveland, United States, Cleveland Hopkins International, CLE, cleveland hopkins international cleveland ohio united states', key:'A_CLE', cityBase: 3, city: 'CLE', country: 'US', type: 'airport', nlabel: 'cleveland hopkins international cleveland ohio united states'},
  { icon: 'faPlaneDeparture', label: 'LIT Little Rock, United States, Clinton National, LIT, clinton national little rock arkansas united states', key:'A_LIT', cityBase: 24, city: 'LIT', country: 'US', type: 'airport', nlabel: 'clinton national little rock arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'CNJ Cloncurry, Australia, Cloncurry, CNJ, cloncurry cloncurry queensland australia', key:'A_CNJ', cityBase: 127, city: 'CNJ', country: 'AU', type: 'airport', nlabel: 'cloncurry cloncurry queensland australia'},
  { icon: 'faPlaneDeparture', label: 'CLJ Cluj-Napoca, Romania, Cluj International, CLJ, cluj international cluj napoca romania', key:'A_CLJ', cityBase: 19, city: 'CLJ', country: 'RO', type: 'airport', nlabel: 'cluj international cluj napoca romania'},
  { icon: 'faPlaneDeparture', label: 'YCY Clyde River, Nunavut, Canada, Clyde River, YCY, clyde river clyde river nunavut canada', key:'A_YCY', cityBase: 127, city: 'YCY', country: 'CA', type: 'airport', nlabel: 'clyde river clyde river nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'EWN New Bern, United States, Coastal Carolina Regional, EWN, coastal carolina regional new bern north carolina united states', key:'A_EWN', cityBase: 60, city: 'EWN', country: 'US', type: 'airport', nlabel: 'coastal carolina regional new bern north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'CAZ Cobar, Australia, Cobar, CAZ, cobar cobar new south wales australia', key:'A_CAZ', cityBase: 127, city: 'CAZ', country: 'AU', type: 'airport', nlabel: 'cobar cobar new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'COK Kochi, India, Cochin International, COK, cochin international kochi india', key:'A_COK', cityBase: 30, city: 'COK', country: 'IN', type: 'airport', nlabel: 'cochin international kochi india'},
  { icon: 'faPlaneDeparture', label: 'CCK Home Island, Cocos (Keeling) Islands, Cocos (Keeling) Islands, CCK, cocos (keeling) islands home island cocos (keeling) islands', key:'A_CCK', cityBase: 127, city: 'CCK', country: 'CC', type: 'airport', nlabel: 'cocos (keeling) islands home island cocos (keeling) islands'},
  { icon: 'faPlaneDeparture', label: 'KCC Coffman Cove, United States, Coffman Cove Sea Plane Base, KCC, coffman cove sea plane base coffman cove alaska united states', key:'A_KCC', cityBase: 127, city: 'KCC', country: 'US', type: 'airport', nlabel: 'coffman cove sea plane base coffman cove alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CFS Coffs Harbour, Australia, Coffs Harbour, CFS, coffs harbour coffs harbour new south wales australia', key:'A_CFS', cityBase: 108, city: 'CFS', country: 'AU', type: 'airport', nlabel: 'coffs harbour coffs harbour new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'CJB Coimbatore, India, Coimbatore International, CJB, coimbatore international coimbatore india', key:'A_CJB', cityBase: 59, city: 'CJB', country: 'IN', type: 'airport', nlabel: 'coimbatore international coimbatore india'},
  { icon: 'faPlaneDeparture', label: 'CDB Cold Bay, United States, Cold Bay, CDB, cold bay cold bay alaska united states', key:'A_CDB', cityBase: 127, city: 'CDB', country: 'US', type: 'airport', nlabel: 'cold bay cold bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CXF Coldfoot, United States, Coldfoot, CXF, coldfoot coldfoot alaska united states', key:'A_CXF', cityBase: 127, city: 'CXF', country: 'US', type: 'airport', nlabel: 'coldfoot coldfoot alaska united states'},
  { icon: 'faPlaneDeparture', label: 'DET Detroit, United States, Coleman A. Young Municipal Airport, DET, coleman a young municipal airport detroit michigan united states', key:'A_DET', cityBase: 103, city: 'DTT', country: 'US', type: 'airport', nlabel: 'coleman a young municipal airport detroit michigan united states'},
  { icon: 'faPlaneDeparture', label: 'CLQ Colima, Mexico, Colima, CLQ, colima colima mexico', key:'A_CLQ', cityBase: 72, city: 'CLQ', country: 'MX', type: 'airport', nlabel: 'colima colima mexico'},
  { icon: 'faPlaneDeparture', label: 'CGN Cologne, Germany, Cologne Bonn Airport, CGN, cologne bonn cologne germany', key:'A_CGN', cityBase: 3, city: 'CGN', country: 'DE', type: 'airport', nlabel: 'cologne bonn cologne germany'},
  { icon: 'faPlaneDeparture', label: 'ESM Esmeraldas, Ecuador, Colonel Carlos Concha Torres, ESM, colonel carlos concha torres esmeraldas ecuador', key:'A_ESM', cityBase: 101, city: 'ESM', country: 'EC', type: 'airport', nlabel: 'colonel carlos concha torres esmeraldas ecuador'},
  { icon: 'faPlaneDeparture', label: 'CRI Crooked Island, Bahamas, Colonel Hill, CRI, colonel hill crooked island bahamas', key:'A_CRI', cityBase: 127, city: 'CRI', country: 'BS', type: 'airport', nlabel: 'colonel hill crooked island bahamas'},
  { icon: 'faPlaneDeparture', label: 'COS Colorado Springs, United States, Colorado Springs, COS, colorado springs colorado springs colorado united states', key:'A_COS', cityBase: 21, city: 'COS', country: 'US', type: 'airport', nlabel: 'colorado springs colorado springs colorado united states'},
  { icon: 'faPlaneDeparture', label: 'CAE Columbia, United States, Columbia Metropolitan, CAE, columbia metropolitan columbia south carolina united states', key:'A_CAE', cityBase: 29, city: 'CAE', country: 'US', type: 'airport', nlabel: 'columbia metropolitan columbia south carolina united states'},
  { icon: 'faPlaneDeparture', label: 'COU Columbia, United States, Columbia Regional, COU, columbia regional columbia missouri united states', key:'A_COU', cityBase: 64, city: 'COU', country: 'US', type: 'airport', nlabel: 'columbia regional columbia missouri united states'},
  { icon: 'faPlaneDeparture', label: 'CSG Columbus, United States, Columbus Metropolitan, CSG, columbus metropolitan columbus georgia united states', key:'A_CSG', cityBase: 81, city: 'CSG', country: 'US', type: 'airport', nlabel: 'columbus metropolitan columbus georgia united states'},
  { icon: 'faPlaneDeparture', label: 'YCK Colville Lake, Canada, Colville Lake/Tommy Kochon Aerodrome, YCK, colville lake tommy kochon aerodrome colville lake northwest territories canada', key:'A_YCK', cityBase: 127, city: 'YCK', country: 'CA', type: 'airport', nlabel: 'colville lake tommy kochon aerodrome colville lake northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'FTE El Calafate, Argentina, Comandante Armando Tola International, FTE, comandante armando tola international el calafate santa cruz argentina', key:'A_FTE', cityBase: 48, city: 'FTE', country: 'AR', type: 'airport', nlabel: 'comandante armando tola international el calafate santa cruz argentina'},
  { icon: 'faPlaneDeparture', label: 'BHI Bahía Blanca, Argentina, Comandante Espora, BHI, comandante espora bahia blanca buenos aires argentina', key:'A_BHI', cityBase: 127, city: 'BHI', country: 'AR', type: 'airport', nlabel: 'comandante espora bahia blanca buenos aires argentina'},
  { icon: 'faPlaneDeparture', label: 'ATA Huaraz, Peru, Comandante FAP Germán Arias Graziani, ATA, comandante fap german arias graziani huaraz peru', key:'A_ATA', cityBase: 127, city: 'ATA', country: 'PE', type: 'airport', nlabel: 'comandante fap german arias graziani huaraz peru'},
  { icon: 'faPlaneDeparture', label: 'DSE Dessie, Ethiopia, Combolcha, DSE, combolcha dessie ethiopia', key:'A_DSE', cityBase: 127, city: 'DSE', country: 'ET', type: 'airport', nlabel: 'combolcha dessie ethiopia'},
  { icon: 'faPlaneDeparture', label: 'CIY Comiso, Italy, Comiso, CIY, comiso comiso italy', key:'A_CIY', cityBase: 21, city: 'CIY', country: 'IT', type: 'airport', nlabel: 'comiso comiso italy'},
  { icon: 'faPlaneDeparture', label: 'SCL Santiago de Chile, Chile, Comodoro Arturo Merino Benítez International, SCL, comodoro arturo merino benitez international santiago chile', key:'A_SCL', cityBase: 16, city: 'SCL', country: 'CL', type: 'airport', nlabel: 'comodoro arturo merino benitez international santiago chile'},
  { icon: 'faPlaneDeparture', label: 'VCS Côn Sơn Island, Vietnam, Con Dao, VCS, con dao con son island vietnam', key:'A_VCS', cityBase: 127, city: 'VCS', country: 'VN', type: 'airport', nlabel: 'con dao con son island vietnam'},
  { icon: 'faPlaneDeparture', label: 'CKY Conakry, Guinea, Conakry International, CKY, conakry international conakry guinea', key:'A_CKY', cityBase: 41, city: 'CKY', country: 'GN', type: 'airport', nlabel: 'conakry international conakry guinea'},
  { icon: 'faPlaneDeparture', label: 'USA Concord, United States, Concord Regional, USA, concord regional concord north carolina united states', key:'A_USA', cityBase: 84, city: 'USA', country: 'US', type: 'airport', nlabel: 'concord regional concord north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'COG Condoto, Colombia, Condoto Mandinga, COG, condoto mandinga condoto colombia', key:'A_COG', cityBase: 127, city: 'COG', country: 'CO', type: 'airport', nlabel: 'condoto mandinga condoto colombia'},
  { icon: 'faPlaneDeparture', label: 'OTD Contadora Island, Panama, Contadora, OTD, contadora contadora island panama', key:'A_OTD', cityBase: 125, city: 'OTD', country: 'PA', type: 'airport', nlabel: 'contadora contadora island panama'},
  { icon: 'faPlaneDeparture', label: 'CPD Coober Pedy, Australia, Coober Pedy, CPD, coober pedy coober pedy south australia australia', key:'A_CPD', cityBase: 127, city: 'CPD', country: 'AU', type: 'airport', nlabel: 'coober pedy coober pedy south australia australia'},
  { icon: 'faPlaneDeparture', label: 'CTN Cooktown, Australia, Cooktown, CTN, cooktown cooktown queensland australia', key:'A_CTN', cityBase: 127, city: 'CTN', country: 'AU', type: 'airport', nlabel: 'cooktown cooktown queensland australia'},
  { icon: 'faPlaneDeparture', label: 'OOM Cooma, Australia, Cooma–Snowy Mountains, OOM, cooma snowy mountains cooma new south wales australia', key:'A_OOM', cityBase: 127, city: 'OOM', country: 'AU', type: 'airport', nlabel: 'cooma snowy mountains cooma new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'CJF Coondewanna, Australia, Coondewanna, CJF, coondewanna coondewanna western australia australia', key:'A_CJF', cityBase: 127, city: 'CJF', country: 'AU', type: 'airport', nlabel: 'coondewanna coondewanna western australia australia'},
  { icon: 'faPlaneDeparture', label: 'CPH Copenhagen, Denmark, Copenhagen, CPH, copenhagen copenhagen denmark', key:'A_CPH', cityBase: 8, city: 'CPH', country: 'DK', type: 'airport', nlabel: 'copenhagen copenhagen denmark'},
  { icon: 'faPlaneDeparture', label: 'WRO Wrocław, Poland, Copernicus Airport Wrocław, WRO, copernicus airport wroclaw wroclaw poland', key:'A_WRO', cityBase: 4, city: 'WRO', country: 'PL', type: 'airport', nlabel: 'copernicus airport wroclaw wroclaw poland'},
  { icon: 'faPlaneDeparture', label: 'TCD Tarapacá, Colombia, Coposa, TCD, coposa tarapaca colombia', key:'A_TCD', cityBase: 127, city: 'TCD', country: 'CO', type: 'airport', nlabel: 'coposa tarapaca colombia'},
  { icon: 'faPlaneDeparture', label: 'YZS Coral Harbour, Canada, Coral Harbour, YZS, coral harbour coral harbour nunavut canada', key:'A_YZS', cityBase: 127, city: 'YZS', country: 'CA', type: 'airport', nlabel: 'coral harbour coral harbour nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'CFU Corfu, Greece, Corfu International, CFU, corfu international corfu greece', key:'A_CFU', cityBase: 8, city: 'CFU', country: 'GR', type: 'airport', nlabel: 'corfu international corfu greece'},
  { icon: 'faPlaneDeparture', label: 'ORK Cork, Ireland, Cork, ORK, cork cork ireland', key:'A_ORK', cityBase: 49, city: 'ORK', country: 'IE', type: 'airport', nlabel: 'cork cork ireland'},
  { icon: 'faPlaneDeparture', label: 'NQY Newquay, United Kingdom, Cornwall Airport Newquay, NQY, cornwall airport newquay newquay united kingdom', key:'A_NQY', cityBase: -919, city: 'NQY', country: 'GB', type: 'airport', nlabel: 'cornwall airport newquay newquay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'AYP Ayacucho, Peru, Coronel FAP Alfredo Mendívil Duarte, AYP, coronel fap alfredo mendivil duarte ayacucho peru', key:'A_AYP', cityBase: 116, city: 'AYP', country: 'PE', type: 'airport', nlabel: 'coronel fap alfredo mendivil duarte ayacucho peru'},
  { icon: 'faPlaneDeparture', label: 'TCQ Tacna, Peru, Coronel FAP Carlos Ciriani Santa Rosa International, TCQ, coronel fap carlos ciriani santa rosa international tacna peru', key:'A_TCQ', cityBase: 64, city: 'TCQ', country: 'PE', type: 'airport', nlabel: 'coronel fap carlos ciriani santa rosa international tacna peru'},
  { icon: 'faPlaneDeparture', label: 'IQT Iquitos, Peru, Coronel FAP Francisco Secada Vignetta International, IQT, coronel fap francisco secada vignetta international iquitos peru', key:'A_IQT', cityBase: 56, city: 'IQT', country: 'PE', type: 'airport', nlabel: 'coronel fap francisco secada vignetta international iquitos peru'},
  { icon: 'faPlaneDeparture', label: 'CTC San Fernando del Valle de Catamarca, Argentina, Coronel Felipe Varela International, CTC, coronel felipe varela international san fernando del valle de catamarca catamarca argentina', key:'A_CTC', cityBase: 127, city: 'CTC', country: 'AR', type: 'airport', nlabel: 'coronel felipe varela international san fernando del valle de catamarca catamarca argentina'},
  { icon: 'faPlaneDeparture', label: 'CZH Corozal Town, Belize, Corozal, CZH, corozal corozal town belize', key:'A_CZH', cityBase: 127, city: 'CZH', country: 'BZ', type: 'airport', nlabel: 'corozal corozal town belize'},
  { icon: 'faPlaneDeparture', label: 'CRP Corpus Christi, United States, Corpus Christi International, CRP, corpus christi international corpus christi texas united states', key:'A_CRP', cityBase: 34, city: 'CRP', country: 'US', type: 'airport', nlabel: 'corpus christi international corpus christi texas united states'},
  { icon: 'faPlaneDeparture', label: 'CEZ Cortez, United States, Cortez Municipal, CEZ, cortez municipal cortez colorado united states', key:'A_CEZ', cityBase: 118, city: 'CEZ', country: 'US', type: 'airport', nlabel: 'cortez municipal cortez colorado united states'},
  { icon: 'faPlaneDeparture', label: 'CMG Corumbá, Brazil, Corumbá International, CMG, corumba international corumba state of mato grosso do sul brazil', key:'A_CMG', cityBase: 127, city: 'CMG', country: 'BR', type: 'airport', nlabel: 'corumba international corumba state of mato grosso do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'CVU Corvo Island, Portugal, Corvo, CVU, corvo corvo island portugal', key:'A_CVU', cityBase: 127, city: 'CVU', country: 'PT', type: 'airport', nlabel: 'corvo corvo island portugal'},
  { icon: 'faPlaneDeparture', label: 'ECI Rivas, Nicaragua, Costa Esmeralda, ECI, costa esmeralda rivas nicaragua', key:'A_ECI', cityBase: 120, city: 'ECI', country: 'NI', type: 'airport', nlabel: 'costa esmeralda rivas nicaragua'},
  { icon: 'faPlaneDeparture', label: 'LTX Latacunga, Ecuador, Cotopaxi International, LTX, cotopaxi international latacunga ecuador', key:'A_LTX', cityBase: 127, city: 'LTX', country: 'EC', type: 'airport', nlabel: 'cotopaxi international latacunga ecuador'},
  { icon: 'faPlaneDeparture', label: 'CVT Coventry, United Kingdom, Coventry, CVT, coventry coventry united kingdom', key:'A_CVT', cityBase: -873, city: 'CVT', country: 'GB', type: 'airport', nlabel: 'coventry coventry united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CXB Cox\'s Bazar, Bangladesh, Cox\'s Bazar, CXB, cox s bazar cox s bazar bangladesh', key:'A_CXB', cityBase: 83, city: 'CXB', country: 'BD', type: 'airport', nlabel: 'cox s bazar cox s bazar bangladesh'},
  { icon: 'faPlaneDeparture', label: 'CZM Cozumel, Mexico, Cozumel International, CZM, cozumel international cozumel mexico', key:'A_CZM', cityBase: 37, city: 'CZM', country: 'MX', type: 'airport', nlabel: 'cozumel international cozumel mexico'},
  { icon: 'faPlaneDeparture', label: 'CCV Craig Cove, Vanuatu, Craig Cove, CCV, craig cove craig cove vanuatu', key:'A_CCV', cityBase: 127, city: 'CCV', country: 'VU', type: 'airport', nlabel: 'craig cove craig cove vanuatu'},
  { icon: 'faPlaneDeparture', label: 'CGA Craig, United States, Craig Sea Plane Base, CGA, craig sea plane base craig alaska united states', key:'A_CGA', cityBase: 127, city: 'CGA', country: 'US', type: 'airport', nlabel: 'craig sea plane base craig alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CRA Craiova, Romania, Craiova International, CRA, craiova international craiova romania', key:'A_CRA', cityBase: 24, city: 'CRA', country: 'RO', type: 'airport', nlabel: 'craiova international craiova romania'},
  { icon: 'faPlaneDeparture', label: 'YXC Cranbrook, Canada, Cranbrook/Canadian Rockies International, YXC, cranbrook canadian rockies international cranbrook british columbia canada', key:'A_YXC', cityBase: 102, city: 'YXC', country: 'CA', type: 'airport', nlabel: 'cranbrook canadian rockies international cranbrook british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'LMT Klamath Falls, United States, Crater Lake–Klamath Regional, LMT, crater lake klamath regional klamath falls oregon united states', key:'A_LMT', cityBase: 127, city: 'LMT', country: 'US', type: 'airport', nlabel: 'crater lake klamath regional klamath falls oregon united states'},
  { icon: 'faPlaneDeparture', label: 'FNC Madeira, Portugal, Cristiano Ronaldo International, FNC, cristiano ronaldo international madeira portugal', key:'A_FNC', cityBase: 9, city: 'FNC', country: 'PT', type: 'airport', nlabel: 'cristiano ronaldo international madeira portugal'},
  { icon: 'faPlaneDeparture', label: 'CKD Crooked Creek, United States, Crooked Creek, CKD, crooked creek crooked creek alaska united states', key:'A_CKD', cityBase: 127, city: 'CKD', country: 'US', type: 'airport', nlabel: 'crooked creek crooked creek alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YCR Cross Lake, Manitoba, Canada, Cross Lake (Charlie Sinclair Memorial), YCR, cross lake (charlie sinclair memorial) cross lake manitoba canada', key:'A_YCR', cityBase: 127, city: 'YCR', country: 'CA', type: 'airport', nlabel: 'cross lake (charlie sinclair memorial) cross lake manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'CRV Crotone, Italy, Crotone Airport, CRV, crotone airport crotone italy', key:'A_CRV', cityBase: 34, city: 'CRV', country: 'IT', type: 'airport', nlabel: 'crotone airport crotone italy'},
  { icon: 'faPlaneDeparture', label: 'JJD Cruz, Ceará, Brazil, Cruz Jericoacoara, JJD, cruz jericoacoara cruz state of ceara brazil', key:'A_JJD', cityBase: 103, city: 'JJD', country: 'BR', type: 'airport', nlabel: 'cruz jericoacoara cruz state of ceara brazil'},
  { icon: 'faPlaneDeparture', label: 'CZS Cruzeiro do Sul, Brazil, Cruzeiro do Sul International, CZS, cruzeiro do sul international cruzeiro do sul state of acre brazil', key:'A_CZS', cityBase: 98, city: 'CZS', country: 'BR', type: 'airport', nlabel: 'cruzeiro do sul international cruzeiro do sul state of acre brazil'},
  { icon: 'faPlaneDeparture', label: 'CDP Kadapa, India, Cuddapah, CDP, cuddapah kadapa india', key:'A_CDP', cityBase: 127, city: 'CDP', country: 'IN', type: 'airport', nlabel: 'cuddapah kadapa india'},
  { icon: 'faPlaneDeparture', label: 'CUF Cuneo, Italy, Cuneo International, CUF, cuneo international cuneo italy', key:'A_CUF', cityBase: 33, city: 'CUF', country: 'IT', type: 'airport', nlabel: 'cuneo international cuneo italy'},
  { icon: 'faPlaneDeparture', label: 'CMA Cunnamulla, Australia, Cunnamulla, CMA, cunnamulla cunnamulla queensland australia', key:'A_CMA', cityBase: 127, city: 'CMA', country: 'AU', type: 'airport', nlabel: 'cunnamulla cunnamulla queensland australia'},
  { icon: 'faPlaneDeparture', label: 'CUR Willemstad, Curaçao, Curaçao International, CUR, curacao international willemstad curacao', key:'A_CUR', cityBase: 39, city: 'CUR', country: 'CW', type: 'airport', nlabel: 'curacao international willemstad curacao'},
  { icon: 'faPlaneDeparture', label: 'MEQ Meulaboh, Indonesia, Cut Nyak Dhien, MEQ, cut nyak dhien meulaboh indonesia', key:'A_MEQ', cityBase: 127, city: 'MEQ', country: 'ID', type: 'airport', nlabel: 'cut nyak dhien meulaboh indonesia'},
  { icon: 'faPlaneDeparture', label: 'STT Saint Thomas, United States, Cyril E. King, STT, cyril e king saint thomas u s virgin islands united states', key:'A_STT', cityBase: 25, city: 'STT', country: 'US', type: 'airport', nlabel: 'cyril e king saint thomas u s virgin islands united states'},
  { icon: 'faPlaneDeparture', label: 'DAD Da Nang, Vietnam, Da Nang International, DAD, da nang international da nang vietnam', key:'A_DAD', cityBase: 35, city: 'DAD', country: 'VN', type: 'airport', nlabel: 'da nang international da nang vietnam'},
  { icon: 'faPlaneDeparture', label: 'TAE Daegu, South Korea, Daegu International, TAE, daegu international daegu south korea', key:'A_TAE', cityBase: 57, city: 'TAE', country: 'KR', type: 'airport', nlabel: 'daegu international daegu south korea'},
  { icon: 'faPlaneDeparture', label: 'VIL Dakhla, Unknown Region, Dakhla, VIL, dakhla dakhla unknown region', key:'A_VIL', cityBase: 102, city: 'VIL', country: 'ZZ', type: 'airport', nlabel: 'dakhla dakhla unknown region'},
  { icon: 'faPlaneDeparture', label: 'BLE Borlänge, Sweden, Dala, BLE, dala borlange sweden', key:'A_BLE', cityBase: 127, city: 'BLE', country: 'SE', type: 'airport', nlabel: 'dala borlange sweden'},
  { icon: 'faPlaneDeparture', label: 'DLM Dalaman, Turkey, Dalaman, DLM, dalaman dalaman turkey', key:'A_DLM', cityBase: 12, city: 'DLM', country: 'TR', type: 'airport', nlabel: 'dalaman dalaman turkey'},
  { icon: 'faPlaneDeparture', label: 'DLZ Dalanzadgad, Mongolia, Dalanzadgad, DLZ, dalanzadgad dalanzadgad mongolia', key:'A_DLZ', cityBase: 127, city: 'DLZ', country: 'MN', type: 'airport', nlabel: 'dalanzadgad dalanzadgad mongolia'},
  { icon: 'faPlaneDeparture', label: 'DBA Dalbandin, Pakistan, Dalbandin, DBA, dalbandin dalbandin pakistan', key:'A_DBA', cityBase: 127, city: 'DBA', country: 'PK', type: 'airport', nlabel: 'dalbandin dalbandin pakistan'},
  { icon: 'faPlaneDeparture', label: 'DLU Dali, China, Dali, DLU, dali dali china', key:'A_DLU', cityBase: 70, city: 'DLU', country: 'CN', type: 'airport', nlabel: 'dali dali china'},
  { icon: 'faPlaneDeparture', label: 'DLC Dalian, China, Dalian Zhoushuizi International, DLC, dalian zhoushuizi international dalian china', key:'A_DLC', cityBase: 45, city: 'DLC', country: 'CN', type: 'airport', nlabel: 'dalian zhoushuizi international dalian china'},
  { icon: 'faPlaneDeparture', label: 'DAL Dallas, United States, Dallas Love Field, DAL, dallas love field dallas texas united states', key:'A_DAL', cityBase: 7, city: 'DFW', country: 'US', type: 'airport', nlabel: 'dallas love field dallas texas united states'},
  { icon: 'faPlaneDeparture', label: 'DFW Dallas, United States, Dallas/Fort Worth International, DFW, dallas fort worth international dallas texas united states', key:'A_DFW', cityBase: 0, city: 'DFW', country: 'US', type: 'airport', nlabel: 'dallas fort worth international dallas texas united states'},
  { icon: 'faPlaneDeparture', label: 'DAM Damascus, Syria, Damascus International, DAM, damascus international damascus syria', key:'A_DAM', cityBase: 88, city: 'DAM', country: 'SY', type: 'airport', nlabel: 'damascus international damascus syria'},
  { icon: 'faPlaneDeparture', label: 'RSS Roseires, Sudan, Damazin, RSS, damazin roseires sudan', key:'A_RSS', cityBase: 127, city: 'RSS', country: 'SD', type: 'airport', nlabel: 'damazin roseires sudan'},
  { icon: 'faPlaneDeparture', label: 'DDG Dandong, China, Dandong Langtou, DDG, dandong langtou dandong china', key:'A_DDG', cityBase: 68, city: 'DDG', country: 'CN', type: 'airport', nlabel: 'dandong langtou dandong china'},
  { icon: 'faPlaneDeparture', label: 'MSN Madison, United States, Dane County Regional, MSN, dane county regional madison wisconsin united states', key:'A_MSN', cityBase: 18, city: 'MSN', country: 'US', type: 'airport', nlabel: 'dane county regional madison wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'DGA Dangriga, Belize, Dangriga, DGA, dangriga dangriga belize', key:'A_DGA', cityBase: 117, city: 'DGA', country: 'BZ', type: 'airport', nlabel: 'dangriga dangriga belize'},
  { icon: 'faPlaneDeparture', label: 'HNL Honolulu, United States, Daniel K. Inouye International, HNL, daniel k inouye international honolulu hawaii united states', key:'A_HNL', cityBase: 2, city: 'HNL', country: 'US', type: 'airport', nlabel: 'daniel k inouye international honolulu hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'LIR Liberia, Costa Rica, Daniel Oduber Quirós International, LIR, daniel oduber quiros international liberia costa rica', key:'A_LIR', cityBase: 29, city: 'LIR', country: 'CR', type: 'airport', nlabel: 'daniel oduber quiros international liberia costa rica'},
  { icon: 'faPlaneDeparture', label: 'TAC Tacloban, Philippines, Daniel Z. Romualdez, TAC, daniel z romualdez tacloban philippines', key:'A_TAC', cityBase: 53, city: 'TAC', country: 'PH', type: 'airport', nlabel: 'daniel z romualdez tacloban philippines'},
  { icon: 'faPlaneDeparture', label: 'DCY Daocheng County, China, Daocheng Yading, DCY, daocheng yading daocheng county china', key:'A_DCY', cityBase: 71, city: 'DCY', country: 'CN', type: 'airport', nlabel: 'daocheng yading daocheng county china'},
  { icon: 'faPlaneDeparture', label: 'DQA Daqing, China, Daqing Sartu, DQA, daqing sartu daqing china', key:'A_DQA', cityBase: 73, city: 'DQA', country: 'CN', type: 'airport', nlabel: 'daqing sartu daqing china'},
  { icon: 'faPlaneDeparture', label: 'DAU Daru, Papua New Guinea, Daru, DAU, daru daru papua new guinea', key:'A_DAU', cityBase: 127, city: 'DAU', country: 'PG', type: 'airport', nlabel: 'daru daru papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'DRW Darwin, Australia, Darwin International, DRW, darwin international darwin northern territory australia', key:'A_DRW', cityBase: 62, city: 'DRW', country: 'AU', type: 'airport', nlabel: 'darwin international darwin northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'SRY Sari, Iran, Dasht-e Naz, SRY, dasht e naz sari iran', key:'A_SRY', cityBase: 127, city: 'SRY', country: 'IR', type: 'airport', nlabel: 'dasht e naz sari iran'},
  { icon: 'faPlaneDeparture', label: 'TAZ Daşoguz, Turkmenistan, Daşoguz, TAZ, dasoguz dasoguz turkmenistan', key:'A_TAZ', cityBase: 127, city: 'TAZ', country: 'TM', type: 'airport', nlabel: 'dasoguz dasoguz turkmenistan'},
  { icon: 'faPlaneDeparture', label: 'DAT Datong, China, Datong Yungang, DAT, datong yungang datong china', key:'A_DAT', cityBase: 68, city: 'DAT', country: 'CN', type: 'airport', nlabel: 'datong yungang datong china'},
  { icon: 'faPlaneDeparture', label: 'KUT Kutaisi, Georgia, David the Builder Kutaisi International, KUT, david the builder kutaisi international kutaisi georgia', key:'A_KUT', cityBase: 40, city: 'KUT', country: 'GE', type: 'airport', nlabel: 'david the builder kutaisi international kutaisi georgia'},
  { icon: 'faPlaneDeparture', label: 'DWD Dawadmi, Saudi Arabia, Dawadmi Domestic, DWD, dawadmi domestic dawadmi saudi arabia', key:'A_DWD', cityBase: 127, city: 'DWD', country: 'SA', type: 'airport', nlabel: 'dawadmi domestic dawadmi saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'TVY Dawei Township, Myanmar (Burma), Dawei, TVY, dawei dawei township myanmar (burma)', key:'A_TVY', cityBase: 127, city: 'TVY', country: 'MM', type: 'airport', nlabel: 'dawei dawei township myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'YDA Dawson City, Canada, Dawson City, YDA, dawson city dawson city yukon territory canada', key:'A_YDA', cityBase: 127, city: 'YDA', country: 'CA', type: 'airport', nlabel: 'dawson city dawson city yukon territory canada'},
  { icon: 'faPlaneDeparture', label: 'GDV Glendive, United States, Dawson Community, GDV, dawson community glendive montana united states', key:'A_GDV', cityBase: 127, city: 'GDV', country: 'US', type: 'airport', nlabel: 'dawson community glendive montana united states'},
  { icon: 'faPlaneDeparture', label: 'YDQ Dawson Creek, Canada, Dawson Creek, YDQ, dawson creek dawson creek british columbia canada', key:'A_YDQ', cityBase: 127, city: 'YDQ', country: 'CA', type: 'airport', nlabel: 'dawson creek dawson creek british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'GSM Qeshm, Iran, Dayrestan, GSM, dayrestan qeshm iran', key:'A_GSM', cityBase: 127, city: 'GSM', country: 'IR', type: 'airport', nlabel: 'dayrestan qeshm iran'},
  { icon: 'faPlaneDeparture', label: 'DAY Dayton, United States, Dayton International, DAY, dayton international dayton ohio united states', key:'A_DAY', cityBase: 24, city: 'DAY', country: 'US', type: 'airport', nlabel: 'dayton international dayton ohio united states'},
  { icon: 'faPlaneDeparture', label: 'DAB Daytona Beach, United States, Daytona Beach International, DAB, daytona beach international daytona beach florida united states', key:'A_DAB', cityBase: 36, city: 'DAB', country: 'US', type: 'airport', nlabel: 'daytona beach international daytona beach florida united states'},
  { icon: 'faPlaneDeparture', label: 'DAX Dazhou, China, Dazhou Heshi, DAX, dazhou heshi dazhou china', key:'A_DAX', cityBase: 73, city: 'DAX', country: 'CN', type: 'airport', nlabel: 'dazhou heshi dazhou china'},
  { icon: 'faPlaneDeparture', label: 'SCC Prudhoe Bay, United States, Deadhorse, SCC, deadhorse prudhoe bay alaska united states', key:'A_SCC', cityBase: 127, city: 'SCC', country: 'US', type: 'airport', nlabel: 'deadhorse prudhoe bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LGI Deadman\'s Cay Settlement, Bahamas, Deadman\'s Cay, LGI, deadman s cay deadman s cay settlement bahamas', key:'A_LGI', cityBase: 127, city: 'LGI', country: 'BS', type: 'airport', nlabel: 'deadman s cay deadman s cay settlement bahamas'},
  { icon: 'faPlaneDeparture', label: 'DOL Deauville, France, Deauville – Normandie, DOL, deauville normandie deauville france', key:'A_DOL', cityBase: 77, city: 'DOL', country: 'FR', type: 'airport', nlabel: 'deauville normandie deauville france'},
  { icon: 'faPlaneDeparture', label: 'DEB Debrecen, Hungary, Debrecen International, DEB, debrecen international debrecen hungary', key:'A_DEB', cityBase: 79, city: 'DEB', country: 'HU', type: 'airport', nlabel: 'debrecen international debrecen hungary'},
  { icon: 'faPlaneDeparture', label: 'DEC Decatur, United States, Decatur, DEC, decatur decatur illinois united states', key:'A_DEC', cityBase: 125, city: 'DEC', country: 'US', type: 'airport', nlabel: 'decatur decatur illinois united states'},
  { icon: 'faPlaneDeparture', label: 'YVZ Deer Lake, Canada, Deer Lake, YVZ, deer lake deer lake ontario canada', key:'A_YVZ', cityBase: 127, city: 'YVZ', country: 'CA', type: 'airport', nlabel: 'deer lake deer lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'YDF Deer Lake, Canada, Deer Lake Regional, YDF, deer lake regional deer lake newfoundland and labrador canada', key:'A_YDF', cityBase: 88, city: 'YDF', country: 'CA', type: 'airport', nlabel: 'deer lake regional deer lake newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'DRG Deering, United States, Deering, DRG, deering deering alaska united states', key:'A_DRG', cityBase: 127, city: 'DRG', country: 'US', type: 'airport', nlabel: 'deering deering alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LUM Mang City, China, Dehong Mangshi, LUM, dehong mangshi mang city china', key:'A_LUM', cityBase: 74, city: 'LUM', country: 'CN', type: 'airport', nlabel: 'dehong mangshi mang city china'},
  { icon: 'faPlaneDeparture', label: 'DED Dehradun, India, Dehradun, DED, dehradun dehradun india', key:'A_DED', cityBase: 82, city: 'DED', country: 'IN', type: 'airport', nlabel: 'dehradun dehradun india'},
  { icon: 'faPlaneDeparture', label: 'PDK Atlanta, United States, DeKalb–Peachtree, PDK, dekalb peachtree atlanta georgia united states', key:'A_PDK', cityBase: 127, city: 'ATL', country: 'US', type: 'airport', nlabel: 'dekalb peachtree atlanta georgia united states'},
  { icon: 'faPlaneDeparture', label: 'BJX León, Mexico, Del Bajío International, BJX, del bajio international leon mexico', key:'A_BJX', cityBase: 29, city: 'BJX', country: 'MX', type: 'airport', nlabel: 'del bajio international leon mexico'},
  { icon: 'faPlaneDeparture', label: 'CEC Crescent City, United States, Del Norte County, CEC, del norte county crescent city california united states', key:'A_CEC', cityBase: 110, city: 'CEC', country: 'US', type: 'airport', nlabel: 'del norte county crescent city california united states'},
  { icon: 'faPlaneDeparture', label: 'ESC Escanaba, United States, Delta County, ESC, delta county escanaba michigan united states', key:'A_ESC', cityBase: 124, city: 'ESC', country: 'US', type: 'airport', nlabel: 'delta county escanaba michigan united states'},
  { icon: 'faPlaneDeparture', label: 'DEM Dembidolo, Ethiopia, Dembi Dolo, DEM, dembi dolo dembidolo ethiopia', key:'A_DEM', cityBase: 127, city: 'DEM', country: 'ET', type: 'airport', nlabel: 'dembi dolo dembidolo ethiopia'},
  { icon: 'faPlaneDeparture', label: 'DNZ Denizli, Turkey, Denizli Çardak, DNZ, denizli cardak denizli turkey', key:'A_DNZ', cityBase: 19, city: 'DNZ', country: 'TR', type: 'airport', nlabel: 'denizli cardak denizli turkey'},
  { icon: 'faPlaneDeparture', label: 'DEN Denver, United States, Denver International, DEN, denver international denver colorado united states', key:'A_DEN', cityBase: 0, city: 'DEN', country: 'US', type: 'airport', nlabel: 'denver international denver colorado united states'},
  { icon: 'faPlaneDeparture', label: 'PGK Pangkal Pinang, Indonesia, Depati Amir, PGK, depati amir pangkal pinang indonesia', key:'A_PGK', cityBase: 127, city: 'PGK', country: 'ID', type: 'airport', nlabel: 'depati amir pangkal pinang indonesia'},
  { icon: 'faPlaneDeparture', label: 'KRC Kerinci, Indonesia, Depati Parbo, KRC, depati parbo kerinci indonesia', key:'A_KRC', cityBase: 127, city: 'KRC', country: 'ID', type: 'airport', nlabel: 'depati parbo kerinci indonesia'},
  { icon: 'faPlaneDeparture', label: 'SSA Salvador, Brazil, Deputado Luís Eduardo Magalhães International, SSA, deputado luis eduardo magalhaes international salvador state of bahia brazil', key:'A_SSA', cityBase: 56, city: 'SSA', country: 'BR', type: 'airport', nlabel: 'deputado luis eduardo magalhaes international salvador state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'DPT Deputatsky, Sakha Republic, Russia, Deputatsky, DPT, deputatsky deputatsky russia', key:'A_DPT', cityBase: 127, city: 'DPT', country: 'RU', type: 'airport', nlabel: 'deputatsky deputatsky russia'},
  { icon: 'faPlaneDeparture', label: 'DEA Dera Ghazi Khan, Pakistan, Dera Ghazi Khan International, DEA, dera ghazi khan international dera ghazi khan pakistan', key:'A_DEA', cityBase: 105, city: 'DEA', country: 'PK', type: 'airport', nlabel: 'dera ghazi khan international dera ghazi khan pakistan'},
  { icon: 'faPlaneDeparture', label: 'DSM Des Moines, United States, Des Moines International, DSM, des moines international des moines iowa united states', key:'A_DSM', cityBase: 17, city: 'DSM', country: 'US', type: 'airport', nlabel: 'des moines international des moines iowa united states'},
  { icon: 'faPlaneDeparture', label: 'VPS Destin, United States, Destin–Fort Walton Beach, VPS, destin fort walton beach destin florida united states', key:'A_VPS', cityBase: 30, city: 'VPS', country: 'US', type: 'airport', nlabel: 'destin fort walton beach destin florida united states'},
  { icon: 'faPlaneDeparture', label: 'DTW Detroit, United States, Detroit Metropolitan, DTW, detroit metropolitan detroit michigan united states', key:'A_DTW', cityBase: 1, city: 'DTT', country: 'US', type: 'airport', nlabel: 'detroit metropolitan detroit michigan united states'},
  { icon: 'faPlaneDeparture', label: 'IDR Indore, India, Devi Ahilya Bai Holkar, IDR, devi ahilya bai holkar indore india', key:'A_IDR', cityBase: 76, city: 'IDR', country: 'IN', type: 'airport', nlabel: 'devi ahilya bai holkar indore india'},
  { icon: 'faPlaneDeparture', label: 'DVL Devils Lake, United States, Devils Lake Regional, DVL, devils lake regional devils lake north dakota united states', key:'A_DVL', cityBase: 113, city: 'DVL', country: 'US', type: 'airport', nlabel: 'devils lake regional devils lake north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'DPO Devonport, Australia, Devonport, DPO, devonport devonport tasmania australia', key:'A_DPO', cityBase: 127, city: 'DPO', country: 'AU', type: 'airport', nlabel: 'devonport devonport tasmania australia'},
  { icon: 'faPlaneDeparture', label: 'DHI Dhangadhi, Nepal, Dhangadhi, DHI, dhangadhi dhangadhi nepal', key:'A_DHI', cityBase: 127, city: 'DHI', country: 'NP', type: 'airport', nlabel: 'dhangadhi dhangadhi nepal'},
  { icon: 'faPlaneDeparture', label: 'DHM Dharamshala, India, Dharamsala airport, DHM, dharamsala airport dharamshala india', key:'A_DHM', cityBase: 85, city: 'DHM', country: 'IN', type: 'airport', nlabel: 'dharamsala airport dharamshala india'},
  { icon: 'faPlaneDeparture', label: 'DRV Dharavandhoo, Maldives, Dharavandhoo, DRV, dharavandhoo dharavandhoo maldives', key:'A_DRV', cityBase: 127, city: 'DRV', country: 'MV', type: 'airport', nlabel: 'dharavandhoo dharavandhoo maldives'},
  { icon: 'faPlaneDeparture', label: 'DIB Dibrugarh, India, Dibrugarh, DIB, dibrugarh dibrugarh india', key:'A_DIB', cityBase: 100, city: 'DIB', country: 'IN', type: 'airport', nlabel: 'dibrugarh dibrugarh india'},
  { icon: 'faPlaneDeparture', label: 'DIK Dickinson, United States, Dickinson Theodore Roosevelt Regional, DIK, dickinson theodore roosevelt regional dickinson north dakota united states', key:'A_DIK', cityBase: 102, city: 'DIK', country: 'US', type: 'airport', nlabel: 'dickinson theodore roosevelt regional dickinson north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'IQQ Iquique, Chile, Diego Aracena International, IQQ, diego aracena international iquique chile', key:'A_IQQ', cityBase: 98, city: 'IQQ', country: 'CL', type: 'airport', nlabel: 'diego aracena international iquique chile'},
  { icon: 'faPlaneDeparture', label: 'DIN Điện Biên Phủ, Vietnam, Dien Bien Phu, DIN, dien bien phu dien bien phu vietnam', key:'A_DIN', cityBase: 118, city: 'DIN', country: 'VN', type: 'airport', nlabel: 'dien bien phu dien bien phu vietnam'},
  { icon: 'faPlaneDeparture', label: 'DKS Dikson, Russia, Dikson, DKS, dikson dikson russia', key:'A_DKS', cityBase: 127, city: 'DKS', country: 'RU', type: 'airport', nlabel: 'dikson dikson russia'},
  { icon: 'faPlaneDeparture', label: 'DLG Dillingham, United States, Dillingham, DLG, dillingham dillingham alaska united states', key:'A_DLG', cityBase: 127, city: 'DLG', country: 'US', type: 'airport', nlabel: 'dillingham dillingham alaska united states'},
  { icon: 'faPlaneDeparture', label: 'DLY Dillons Bay, Vanuatu, Dillon\'s Bay, DLY, dillon s bay dillons bay vanuatu', key:'A_DLY', cityBase: 127, city: 'DLY', country: 'VU', type: 'airport', nlabel: 'dillon s bay dillons bay vanuatu'},
  { icon: 'faPlaneDeparture', label: 'DMU Dimapur, India, Dimapur, DMU, dimapur dimapur india', key:'A_DMU', cityBase: 114, city: 'DMU', country: 'IN', type: 'airport', nlabel: 'dimapur dimapur india'},
  { icon: 'faPlaneDeparture', label: 'DNR Dinard, France, Dinard–Pleurtuit–Saint-Malo, DNR, dinard pleurtuit saint malo dinard france', key:'A_DNR', cityBase: 126, city: 'DNR', country: 'FR', type: 'airport', nlabel: 'dinard pleurtuit saint malo dinard france'},
  { icon: 'faPlaneDeparture', label: 'CCM Criciúma, Brazil, Diomício Freitas, CCM, diomicio freitas criciuma state of santa catarina brazil', key:'A_CCM', cityBase: 127, city: 'CCM', country: 'BR', type: 'airport', nlabel: 'diomicio freitas criciuma state of santa catarina brazil'},
  { icon: 'faPlaneDeparture', label: 'NIM Niamey, Niger, Diori Hamani International, NIM, diori hamani international niamey niger', key:'A_NIM', cityBase: 62, city: 'NIM', country: 'NE', type: 'airport', nlabel: 'diori hamani international niamey niger'},
  { icon: 'faPlaneDeparture', label: 'DPL Dipolog, Philippines, Dipolog, DPL, dipolog dipolog philippines', key:'A_DPL', cityBase: 96, city: 'DPL', country: 'PH', type: 'airport', nlabel: 'dipolog dipolog philippines'},
  { icon: 'faPlaneDeparture', label: 'DIG Diqing Tibetan Autonomous Prefecture, China, Diqing Shangri-La, DIG, diqing shangri la diqing tibetan autonomous prefecture china', key:'A_DIG', cityBase: 75, city: 'DIG', country: 'CN', type: 'airport', nlabel: 'diqing shangri la diqing tibetan autonomous prefecture china'},
  { icon: 'faPlaneDeparture', label: 'DIU Diu, India, Diu, DIU, diu diu india', key:'A_DIU', cityBase: 127, city: 'DIU', country: 'IN', type: 'airport', nlabel: 'diu diu india'},
  { icon: 'faPlaneDeparture', label: 'DIQ Divinópolis, Brazil, Divinópolis, DIQ, divinopolis divinopolis state of minas gerais brazil', key:'A_DIQ', cityBase: 127, city: 'DIQ', country: 'BR', type: 'airport', nlabel: 'divinopolis divinopolis state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'DIY Diyarbakır, Turkey, Diyarbakır, DIY, diyarbakir diyarbakir turkey', key:'A_DIY', cityBase: 16, city: 'DIY', country: 'TR', type: 'airport', nlabel: 'diyarbakir diyarbakir turkey'},
  { icon: 'faPlaneDeparture', label: 'DJE Djerba, Tunisia, Djerba–Zarzis International, DJE, djerba zarzis international djerba tunisia', key:'A_DJE', cityBase: 94, city: 'DJE', country: 'TN', type: 'airport', nlabel: 'djerba zarzis international djerba tunisia'},
  { icon: 'faPlaneDeparture', label: 'JIB Balbala, Djibouti, Djibouti–Ambouli International, JIB, djibouti ambouli international balbala djibouti', key:'A_JIB', cityBase: 92, city: 'JIB', country: 'DJ', type: 'airport', nlabel: 'djibouti ambouli international balbala djibouti'},
  { icon: 'faPlaneDeparture', label: 'DNK Dnipro, Ukraine, Dnipropetrovsk International, DNK, dnipropetrovsk international dnipro ukraine', key:'A_DNK', cityBase: 82, city: 'DNK', country: 'UA', type: 'airport', nlabel: 'dnipropetrovsk international dnipro ukraine'},
  { icon: 'faPlaneDeparture', label: 'DOB Dobo, Indonesia, Dobo, DOB, dobo dobo indonesia', key:'A_DOB', cityBase: 127, city: 'DOB', country: 'ID', type: 'airport', nlabel: 'dobo dobo indonesia'},
  { icon: 'faPlaneDeparture', label: 'CNQ Corrientes, Argentina, Doctor Fernando Piragine Niveyro International, CNQ, doctor fernando piragine niveyro international corrientes corrientes argentina', key:'A_CNQ', cityBase: 127, city: 'CNQ', country: 'AR', type: 'airport', nlabel: 'doctor fernando piragine niveyro international corrientes corrientes argentina'},
  { icon: 'faPlaneDeparture', label: 'DDC Dodge City, United States, Dodge City Regional, DDC, dodge city regional dodge city kansas united states', key:'A_DDC', cityBase: 127, city: 'DDC', country: 'US', type: 'airport', nlabel: 'dodge city regional dodge city kansas united states'},
  { icon: 'faPlaneDeparture', label: 'DLE Dole, France, Dole–Jura, DLE, dole jura dole france', key:'A_DLE', cityBase: 81, city: 'DLE', country: 'FR', type: 'airport', nlabel: 'dole jura dole france'},
  { icon: 'faPlaneDeparture', label: 'SOQ Sorong, Indonesia, Domine Eduard Osok, SOQ, domine eduard osok sorong indonesia', key:'A_SOQ', cityBase: 100, city: 'SOQ', country: 'ID', type: 'airport', nlabel: 'domine eduard osok sorong indonesia'},
  { icon: 'faPlaneDeparture', label: 'UAQ San Juan, Argentina, Domingo Faustino Sarmiento, UAQ, domingo faustino sarmiento san juan san juan argentina', key:'A_UAQ', cityBase: 127, city: 'UAQ', country: 'AR', type: 'airport', nlabel: 'domingo faustino sarmiento san juan san juan argentina'},
  { icon: 'faPlaneDeparture', label: 'DME Moscow, Russia, Domodedovo International, DME, domodedovo international moscow russia', key:'A_DME', cityBase: 17, city: 'MOW', country: 'RU', type: 'airport', nlabel: 'domodedovo international moscow russia'},
  { icon: 'faPlaneDeparture', label: 'DMK Bangkok, Thailand, Don Mueang International, DMK, don mueang international bangkok thailand', key:'A_DMK', cityBase: 41, city: 'BKK', country: 'TH', type: 'airport', nlabel: 'don mueang international bangkok thailand'},
  { icon: 'faPlaneDeparture', label: 'DSA Doncaster, United Kingdom, Doncaster Sheffield, DSA, doncaster sheffield doncaster united kingdom', key:'A_DSA', cityBase: -946, city: 'DSA', country: 'GB', type: 'airport', nlabel: 'doncaster sheffield doncaster united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CFN Donegal, Ireland, Donegal, CFN, donegal donegal ireland', key:'A_CFN', cityBase: 127, city: 'CFN', country: 'IE', type: 'airport', nlabel: 'donegal donegal ireland'},
  { icon: 'faPlaneDeparture', label: 'VDH Đồng Hới, Vietnam, Dong Hoi, VDH, dong hoi dong hoi vietnam', key:'A_VDH', cityBase: 106, city: 'VDH', country: 'VN', type: 'airport', nlabel: 'dong hoi dong hoi vietnam'},
  { icon: 'faPlaneDeparture', label: 'TBB Tuy Hòa, Vietnam, Dong Tac, TBB, dong tac tuy hoa vietnam', key:'A_TBB', cityBase: 127, city: 'TBB', country: 'VN', type: 'airport', nlabel: 'dong tac tuy hoa vietnam'},
  { icon: 'faPlaneDeparture', label: 'DOY Dongying, China, Dongying Shengli, DOY, dongying shengli dongying china', key:'A_DOY', cityBase: 71, city: 'DOY', country: 'CN', type: 'airport', nlabel: 'dongying shengli dongying china'},
  { icon: 'faPlaneDeparture', label: 'ULZ Uliastai, Mongolia, Donoi, ULZ, donoi uliastai mongolia', key:'A_ULZ', cityBase: 127, city: 'ULZ', country: 'MN', type: 'airport', nlabel: 'donoi uliastai mongolia'},
  { icon: 'faPlaneDeparture', label: 'DMD Doomadgee, Australia, Doomadgee, DMD, doomadgee doomadgee queensland australia', key:'A_DMD', cityBase: 127, city: 'DMD', country: 'AU', type: 'airport', nlabel: 'doomadgee doomadgee queensland australia'},
  { icon: 'faPlaneDeparture', label: 'DTM Dortmund, Germany, Dortmund, DTM, dortmund dortmund germany', key:'A_DTM', cityBase: 7, city: 'DTM', country: 'DE', type: 'airport', nlabel: 'dortmund dortmund germany'},
  { icon: 'faPlaneDeparture', label: 'DHN Dothan, United States, Dothan Regional, DHN, dothan regional dothan alabama united states', key:'A_DHN', cityBase: 95, city: 'DHN', country: 'US', type: 'airport', nlabel: 'dothan regional dothan alabama united states'},
  { icon: 'faPlaneDeparture', label: 'DLA Douala, Cameroon, Douala International, DLA, douala international douala cameroon', key:'A_DLA', cityBase: 62, city: 'DLA', country: 'CM', type: 'airport', nlabel: 'douala international douala cameroon'},
  { icon: 'faPlaneDeparture', label: 'DOM Saint Joseph Parish, Dominica, Douglas–Charles, DOM, douglas charles saint joseph parish dominica', key:'A_DOM', cityBase: 79, city: 'DOM', country: 'DM', type: 'airport', nlabel: 'douglas charles saint joseph parish dominica'},
  { icon: 'faPlaneDeparture', label: 'DOU Dourados, Brazil, Dourados, DOU, dourados dourados state of mato grosso do sul brazil', key:'A_DOU', cityBase: 127, city: 'DOU', country: 'BR', type: 'airport', nlabel: 'dourados dourados state of mato grosso do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'NAG Nagpur, India, Dr. Babasaheb Ambedkar International, NAG, dr babasaheb ambedkar international nagpur india', key:'A_NAG', cityBase: 62, city: 'NAG', country: 'IN', type: 'airport', nlabel: 'dr babasaheb ambedkar international nagpur india'},
  { icon: 'faPlaneDeparture', label: 'DRK Bahía Drake, Costa Rica, Drake Bay, DRK, drake bay bahia drake costa rica', key:'A_DRK', cityBase: 127, city: 'DRK', country: 'CR', type: 'airport', nlabel: 'drake bay bahia drake costa rica'},
  { icon: 'faPlaneDeparture', label: 'DRS Dresden, Germany, Dresden, DRS, dresden dresden germany', key:'A_DRS', cityBase: 5, city: 'DRS', country: 'DE', type: 'airport', nlabel: 'dresden dresden germany'},
  { icon: 'faPlaneDeparture', label: 'YHD Dryden, Canada, Dryden Regional, YHD, dryden regional dryden ontario canada', key:'A_YHD', cityBase: 127, city: 'YHD', country: 'CA', type: 'airport', nlabel: 'dryden regional dryden ontario canada'},
  { icon: 'faPlaneDeparture', label: 'DXB Dubai, United Arab Emirates, Dubai International, DXB, dubai international dubai united arab emirates', key:'A_DXB', cityBase: 5, city: 'DXB', country: 'AE', type: 'airport', nlabel: 'dubai international dubai united arab emirates'},
  { icon: 'faPlaneDeparture', label: 'DBO Dubbo, Australia, Dubbo City Regional, DBO, dubbo city regional dubbo new south wales australia', key:'A_DBO', cityBase: 127, city: 'DBO', country: 'AU', type: 'airport', nlabel: 'dubbo city regional dubbo new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'DUB Dublin, Ireland, Dublin, DUB, dublin dublin ireland', key:'A_DUB', cityBase: 3, city: 'DUB', country: 'IE', type: 'airport', nlabel: 'dublin dublin ireland'},
  { icon: 'faPlaneDeparture', label: 'DUJ DuBois, United States, DuBois Regional, DUJ, dubois regional dubois pennsylvania united states', key:'A_DUJ', cityBase: 95, city: 'DUJ', country: 'US', type: 'airport', nlabel: 'dubois regional dubois pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'DBV Dubrovnik, Croatia, Dubrovnik, DBV, dubrovnik dubrovnik croatia', key:'A_DBV', cityBase: 11, city: 'DBV', country: 'HR', type: 'airport', nlabel: 'dubrovnik dubrovnik croatia'},
  { icon: 'faPlaneDeparture', label: 'DBQ Dubuque, United States, Dubuque Regional, DBQ, dubuque regional dubuque iowa united states', key:'A_DBQ', cityBase: 86, city: 'DBQ', country: 'US', type: 'airport', nlabel: 'dubuque regional dubuque iowa united states'},
  { icon: 'faPlaneDeparture', label: 'DLH Duluth, United States, Duluth International, DLH, duluth international duluth minnesota united states', key:'A_DLH', cityBase: 58, city: 'DLH', country: 'US', type: 'airport', nlabel: 'duluth international duluth minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'DND Dundee, United Kingdom, Dundee, DND, dundee dundee united kingdom', key:'A_DND', cityBase: -898, city: 'DND', country: 'GB', type: 'airport', nlabel: 'dundee dundee united kingdom'},
  { icon: 'faPlaneDeparture', label: 'DUE Dundo, Angola, Dundo, DUE, dundo dundo angola', key:'A_DUE', cityBase: 127, city: 'DUE', country: 'AO', type: 'airport', nlabel: 'dundo dundo angola'},
  { icon: 'faPlaneDeparture', label: 'DUD Dunedin, New Zealand, Dunedin, DUD, dunedin dunedin new zealand', key:'A_DUD', cityBase: 80, city: 'DUD', country: 'NZ', type: 'airport', nlabel: 'dunedin dunedin new zealand'},
  { icon: 'faPlaneDeparture', label: 'DNH Dunhuang, China, Dunhuang, DNH, dunhuang dunhuang china', key:'A_DNH', cityBase: 65, city: 'DNH', country: 'CN', type: 'airport', nlabel: 'dunhuang dunhuang china'},
  { icon: 'faPlaneDeparture', label: 'DQM Duqm, Oman, Duqm Jaaluni, DQM, duqm jaaluni duqm oman', key:'A_DQM', cityBase: 127, city: 'DQM', country: 'OM', type: 'airport', nlabel: 'duqm jaaluni duqm oman'},
  { icon: 'faPlaneDeparture', label: 'DGO Durango, Mexico, Durango International, DGO, durango international durango mexico', key:'A_DGO', cityBase: 56, city: 'DGO', country: 'MX', type: 'airport', nlabel: 'durango international durango mexico'},
  { icon: 'faPlaneDeparture', label: 'DRO Durango, United States, Durango–La Plata County, DRO, durango la plata county durango colorado united states', key:'A_DRO', cityBase: 51, city: 'DRO', country: 'US', type: 'airport', nlabel: 'durango la plata county durango colorado united states'},
  { icon: 'faPlaneDeparture', label: 'DUR Durban, South Africa, Durban International, DUR, durban international durban south africa', key:'A_DUR', cityBase: 53, city: 'DUR', country: 'ZA', type: 'airport', nlabel: 'durban international durban south africa'},
  { icon: 'faPlaneDeparture', label: 'MME Durham, England, United Kingdom, Durham Tees Valley, MME, durham tees valley durham united kingdom', key:'A_MME', cityBase: -902, city: 'MME', country: 'GB', type: 'airport', nlabel: 'durham tees valley durham united kingdom'},
  { icon: 'faPlaneDeparture', label: 'DYU Dushanbe, Tajikistan, Dushanbe International, DYU, dushanbe international dushanbe tajikistan', key:'A_DYU', cityBase: 45, city: 'DYU', country: 'TJ', type: 'airport', nlabel: 'dushanbe international dushanbe tajikistan'},
  { icon: 'faPlaneDeparture', label: 'DUS Düsseldorf, Germany, Düsseldorf International Airport, DUS, dusseldorf dusseldorf germany', key:'A_DUS', cityBase: 2, city: 'DUS', country: 'DE', type: 'airport', nlabel: 'dusseldorf dusseldorf germany'},
  { icon: 'faPlaneDeparture', label: 'DZA Mamoudzou, France, Dzaoudzi–Pamandzi International, DZA, dzaoudzi pamandzi international mamoudzou mayotte france', key:'A_DZA', cityBase: 127, city: 'DZA', country: 'FR', type: 'airport', nlabel: 'dzaoudzi pamandzi international mamoudzou mayotte france'},
  { icon: 'faPlaneDeparture', label: 'SVD Saint Vincent, St. Vincent & Grenadines, E. T. Joshua, SVD, e t joshua saint vincent st vincent and grenadines', key:'A_SVD', cityBase: 50, city: 'SVD', country: 'VC', type: 'airport', nlabel: 'e t joshua saint vincent st vincent and grenadines'},
  { icon: 'faPlaneDeparture', label: 'EGE Vail, United States, Eagle County Regional, EGE, eagle county regional vail colorado united states', key:'A_EGE', cityBase: 37, city: 'EGE', country: 'US', type: 'airport', nlabel: 'eagle county regional vail colorado united states'},
  { icon: 'faPlaneDeparture', label: 'KNX Kununurra, Australia, East Kimberley Regional, KNX, east kimberley regional kununurra western australia australia', key:'A_KNX', cityBase: 127, city: 'KNX', country: 'AU', type: 'airport', nlabel: 'east kimberley regional kununurra western australia australia'},
  { icon: 'faPlaneDeparture', label: 'ELS East London, South Africa, East London, ELS, east london east london south africa', key:'A_ELS', cityBase: 86, city: 'ELS', country: 'ZA', type: 'airport', nlabel: 'east london east london south africa'},
  { icon: 'faPlaneDeparture', label: 'EMA Nottingham, United Kingdom, East Midlands, EMA, east midlands nottingham united kingdom', key:'A_EMA', cityBase: -987, city: 'NQT', country: 'GB', type: 'airport', nlabel: 'east midlands nottingham united kingdom'},
  { icon: 'faPlaneDeparture', label: 'GGG Longview, United States, East Texas Regional, GGG, east texas regional longview texas united states', key:'A_GGG', cityBase: 96, city: 'GGG', country: 'US', type: 'airport', nlabel: 'east texas regional longview texas united states'},
  { icon: 'faPlaneDeparture', label: 'PDT Pendleton, United States, Eastern Oregon Regional, PDT, eastern oregon regional pendleton oregon united states', key:'A_PDT', cityBase: 127, city: 'PDT', country: 'US', type: 'airport', nlabel: 'eastern oregon regional pendleton oregon united states'},
  { icon: 'faPlaneDeparture', label: 'CLL College Station, United States, Easterwood, CLL, easterwood college station texas united states', key:'A_CLL', cityBase: 79, city: 'CLL', country: 'US', type: 'airport', nlabel: 'easterwood college station texas united states'},
  { icon: 'faPlaneDeparture', label: 'ZEM Eastmain, Quebec, Canada, Eastmain River, ZEM, eastmain river eastmain quebec canada', key:'A_ZEM', cityBase: 127, city: 'ZEM', country: 'CA', type: 'airport', nlabel: 'eastmain river eastmain quebec canada'},
  { icon: 'faPlaneDeparture', label: 'EOI Eday, United Kingdom, Eday, EOI, eday eday united kingdom', key:'A_EOI', cityBase: -873, city: 'EOI', country: 'GB', type: 'airport', nlabel: 'eday eday united kingdom'},
  { icon: 'faPlaneDeparture', label: 'EDI Edinburgh, United Kingdom, Edinburgh, EDI, edinburgh edinburgh united kingdom', key:'A_EDI', cityBase: -996, city: 'EDI', country: 'GB', type: 'airport', nlabel: 'edinburgh edinburgh united kingdom'},
  { icon: 'faPlaneDeparture', label: 'YEG Edmonton, Canada, Edmonton International, YEG, edmonton international edmonton alberta canada', key:'A_YEG', cityBase: 50, city: 'YEA', country: 'CA', type: 'airport', nlabel: 'edmonton international edmonton alberta canada'},
  { icon: 'faPlaneDeparture', label: 'EDA Edna Bay, United States, Edna Bay Sea Plane Base, EDA, edna bay sea plane base edna bay alaska united states', key:'A_EDA', cityBase: 127, city: 'EDA', country: 'US', type: 'airport', nlabel: 'edna bay sea plane base edna bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SVI San Vicente Del Caguan, Colombia, Eduardo Falla Solano Airport, SVI, eduardo falla solano airport san vicente del caguan colombia', key:'A_SVI', cityBase: 127, city: 'SVI', country: 'CO', type: 'airport', nlabel: 'eduardo falla solano airport san vicente del caguan colombia'},
  { icon: 'faPlaneDeparture', label: 'MAO Manaus, Brazil, Eduardo Gomes International, MAO, eduardo gomes international manaus state of amazonas brazil', key:'A_MAO', cityBase: 44, city: 'MAO', country: 'BR', type: 'airport', nlabel: 'eduardo gomes international manaus state of amazonas brazil'},
  { icon: 'faPlaneDeparture', label: 'LYB Little Cayman, Cayman Islands, Edward Bodden Airfield, LYB, edward bodden airfield little cayman cayman islands', key:'A_LYB', cityBase: 98, city: 'LYB', country: 'KY', type: 'airport', nlabel: 'edward bodden airfield little cayman cayman islands'},
  { icon: 'faPlaneDeparture', label: 'GAL Galena, United States, Edward G. Pitka Sr., GAL, edward g pitka sr galena alaska united states', key:'A_GAL', cityBase: 127, city: 'GAL', country: 'US', type: 'airport', nlabel: 'edward g pitka sr galena alaska united states'},
  { icon: 'faPlaneDeparture', label: 'EDR Pormpuraaw, Australia, Edward River, EDR, edward river pormpuraaw queensland australia', key:'A_EDR', cityBase: 127, city: 'EDR', country: 'AU', type: 'airport', nlabel: 'edward river pormpuraaw queensland australia'},
  { icon: 'faPlaneDeparture', label: 'EEK Eek, United States, Eek, EEK, eek eek alaska united states', key:'A_EEK', cityBase: 127, city: 'EEK', country: 'US', type: 'airport', nlabel: 'eek eek alaska united states'},
  { icon: 'faPlaneDeparture', label: 'EGX Egegik, United States, Egegik, EGX, egegik egegik alaska united states', key:'A_EGX', cityBase: 127, city: 'EGX', country: 'US', type: 'airport', nlabel: 'egegik egegik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'EGS Egilsstaðir, Iceland, Egilsstaðir, EGS, egilsstadir egilsstadir iceland', key:'A_EGS', cityBase: 67, city: 'EGS', country: 'IS', type: 'airport', nlabel: 'egilsstadir egilsstadir iceland'},
  { icon: 'faPlaneDeparture', label: 'ETH Eilat, Israel, Eilat, ETH, eilat eilat israel', key:'A_ETH', cityBase: 23, city: 'ETH', country: 'IL', type: 'airport', nlabel: 'eilat eilat israel'},
  { icon: 'faPlaneDeparture', label: 'EIN Eindhoven, Netherlands, Eindhoven, EIN, eindhoven eindhoven netherlands', key:'A_EIN', cityBase: 42, city: 'EIN', country: 'NL', type: 'airport', nlabel: 'eindhoven eindhoven netherlands'},
  { icon: 'faPlaneDeparture', label: 'KEK Ekwok, United States, Ekwok, KEK, ekwok ekwok alaska united states', key:'A_KEK', cityBase: 127, city: 'KEK', country: 'US', type: 'airport', nlabel: 'ekwok ekwok alaska united states'},
  { icon: 'faPlaneDeparture', label: 'EYP Yopal, Colombia, El Alcaraván, EYP, el alcaravan yopal colombia', key:'A_EYP', cityBase: 99, city: 'EYP', country: 'CO', type: 'airport', nlabel: 'el alcaravan yopal colombia'},
  { icon: 'faPlaneDeparture', label: 'LPB La Paz, Bolivia, El Alto International, LPB, el alto international la paz bolivia', key:'A_LPB', cityBase: 34, city: 'LPB', country: 'BO', type: 'airport', nlabel: 'el alto international la paz bolivia'},
  { icon: 'faPlaneDeparture', label: 'EBG El Bagre, Colombia, El Bagre, EBG, el bagre el bagre colombia', key:'A_EBG', cityBase: 127, city: 'EBG', country: 'CO', type: 'airport', nlabel: 'el bagre el bagre colombia'},
  { icon: 'faPlaneDeparture', label: 'UIB Quibdó, Colombia, El Caraño, UIB, el carano quibdo colombia', key:'A_UIB', cityBase: 127, city: 'UIB', country: 'CO', type: 'airport', nlabel: 'el carano quibdo colombia'},
  { icon: 'faPlaneDeparture', label: 'BOG Bogotá, Colombia, El Dorado International, BOG, el dorado international bogota colombia', key:'A_BOG', cityBase: 6, city: 'BOG', country: 'CO', type: 'airport', nlabel: 'el dorado international bogota colombia'},
  { icon: 'faPlaneDeparture', label: 'AXM Armenia, Colombia, El Edén International, AXM, el eden international armenia colombia', key:'A_AXM', cityBase: 82, city: 'AXM', country: 'CO', type: 'airport', nlabel: 'el eden international armenia colombia'},
  { icon: 'faPlaneDeparture', label: 'PVA Providencia Island, Colombia, El Embrujo, PVA, el embrujo providencia island colombia', key:'A_PVA', cityBase: 78, city: 'PVA', country: 'CO', type: 'airport', nlabel: 'el embrujo providencia island colombia'},
  { icon: 'faPlaneDeparture', label: 'ELF Al-Fashir, Sudan, El Fasher, ELF, el fasher al fashir sudan', key:'A_ELF', cityBase: 127, city: 'ELF', country: 'SD', type: 'airport', nlabel: 'el fasher al fashir sudan'},
  { icon: 'faPlaneDeparture', label: 'ELG El Goléa, Algeria, El Golea, ELG, el golea el golea algeria', key:'A_ELG', cityBase: 127, city: 'ELG', country: 'DZ', type: 'airport', nlabel: 'el golea el golea algeria'},
  { icon: 'faPlaneDeparture', label: 'VDE Valverde, Spain, El Hierro, VDE, el hierro valverde spain', key:'A_VDE', cityBase: 25, city: 'VDE', country: 'ES', type: 'airport', nlabel: 'el hierro valverde spain'},
  { icon: 'faPlaneDeparture', label: 'JAL Xalapa, Mexico, El Lencero, JAL, el lencero xalapa mexico', key:'A_JAL', cityBase: 127, city: 'JAL', country: 'MX', type: 'airport', nlabel: 'el lencero xalapa mexico'},
  { icon: 'faPlaneDeparture', label: 'CJC Calama, Chile, El Loa, CJC, el loa calama chile', key:'A_CJC', cityBase: 54, city: 'CJC', country: 'CL', type: 'airport', nlabel: 'el loa calama chile'},
  { icon: 'faPlaneDeparture', label: 'EPA Buenos Aires, Argentina, El Palomar, EPA, el palomar el palomar buenos aires argentina', key:'A_EPA', cityBase: 127, city: 'BUE', country: 'AR', type: 'airport', nlabel: 'el palomar el palomar buenos aires argentina'},
  { icon: 'faPlaneDeparture', label: 'ELP El Paso, United States, El Paso International, ELP, el paso international el paso texas united states', key:'A_ELP', cityBase: 16, city: 'ELP', country: 'US', type: 'airport', nlabel: 'el paso international el paso texas united states'},
  { icon: 'faPlaneDeparture', label: 'PAZ Poza Rica, Mexico, El Tajín National, PAZ, el tajin national poza rica mexico', key:'A_PAZ', cityBase: 127, city: 'PAZ', country: 'MX', type: 'airport', nlabel: 'el tajin national poza rica mexico'},
  { icon: 'faPlaneDeparture', label: 'KOE Kupang, Indonesia, El Tari International, KOE, el tari international kupang indonesia', key:'A_KOE', cityBase: 110, city: 'KOE', country: 'ID', type: 'airport', nlabel: 'el tari international kupang indonesia'},
  { icon: 'faPlaneDeparture', label: 'PMY Puerto Madryn, Argentina, El Tehuelche, PMY, el tehuelche puerto madryn chubut argentina', key:'A_PMY', cityBase: 105, city: 'PMY', country: 'AR', type: 'airport', nlabel: 'el tehuelche puerto madryn chubut argentina'},
  { icon: 'faPlaneDeparture', label: 'PMC Puerto Montt, Chile, El Tepual International, PMC, el tepual international puerto montt chile', key:'A_PMC', cityBase: 90, city: 'PMC', country: 'CL', type: 'airport', nlabel: 'el tepual international puerto montt chile'},
  { icon: 'faPlaneDeparture', label: 'EZS Elazığ, Turkey, Elazığ, EZS, elazig elazig turkey', key:'A_EZS', cityBase: 17, city: 'EZS', country: 'TR', type: 'airport', nlabel: 'elazig elazig turkey'},
  { icon: 'faPlaneDeparture', label: 'ELC Elcho Island, Australia, Elcho Island, ELC, elcho island elcho island northern territory australia', key:'A_ELC', cityBase: 127, city: 'ELC', country: 'AU', type: 'airport', nlabel: 'elcho island elcho island northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'EDL Eldoret, Kenya, Eldoret International, EDL, eldoret international eldoret kenya', key:'A_EDL', cityBase: 127, city: 'EDL', country: 'KE', type: 'airport', nlabel: 'eldoret international eldoret kenya'},
  { icon: 'faPlaneDeparture', label: 'ELI Elim, United States, Elim, ELI, elim elim alaska united states', key:'A_ELI', cityBase: 127, city: 'ELI', country: 'US', type: 'airport', nlabel: 'elim elim alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ESL Elista, Russia, Elista, ESL, elista elista russia', key:'A_ESL', cityBase: 59, city: 'ESL', country: 'RU', type: 'airport', nlabel: 'elista elista russia'},
  { icon: 'faPlaneDeparture', label: 'EKO Elko, United States, Elko Regional, EKO, elko regional elko nevada united states', key:'A_EKO', cityBase: 109, city: 'EKO', country: 'US', type: 'airport', nlabel: 'elko regional elko nevada united states'},
  { icon: 'faPlaneDeparture', label: 'ELM Elmira, United States, Elmira/Corning Regional, ELM, elmira corning regional elmira new york united states', key:'A_ELM', cityBase: 74, city: 'ELM', country: 'US', type: 'airport', nlabel: 'elmira corning regional elmira new york united states'},
  { icon: 'faPlaneDeparture', label: 'MEC Manta, Ecuador, Eloy Alfaro International, MEC, eloy alfaro international manta ecuador', key:'A_MEC', cityBase: 63, city: 'MEC', country: 'EC', type: 'airport', nlabel: 'eloy alfaro international manta ecuador'},
  { icon: 'faPlaneDeparture', label: 'EMD Emerald, Australia, Emerald, EMD, emerald emerald queensland australia', key:'A_EMD', cityBase: 127, city: 'EMD', country: 'AU', type: 'airport', nlabel: 'emerald emerald queensland australia'},
  { icon: 'faPlaneDeparture', label: 'EMK Emmonak, United States, Emmonak, EMK, emmonak emmonak alaska united states', key:'A_EMK', cityBase: 127, city: 'EMK', country: 'US', type: 'airport', nlabel: 'emmonak emmonak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NBE Enfidha, Tunisia, Enfidha–Hammamet International, NBE, enfidha hammamet international enfidha tunisia', key:'A_NBE', cityBase: 41, city: 'NBE', country: 'TN', type: 'airport', nlabel: 'enfidha hammamet international enfidha tunisia'},
  { icon: 'faPlaneDeparture', label: 'ENF Enontekio, Finland, Enontekiö, ENF, enontekio enontekio finland', key:'A_ENF', cityBase: 127, city: 'ENF', country: 'FI', type: 'airport', nlabel: 'enontekio enontekio finland'},
  { icon: 'faPlaneDeparture', label: 'DAV David, Chiriquí, Panama, Enrique Malek International, DAV, enrique malek international david panama', key:'A_DAV', cityBase: 100, city: 'DAV', country: 'PA', type: 'airport', nlabel: 'enrique malek international david panama'},
  { icon: 'faPlaneDeparture', label: 'ENH Enshi City, China, Enshi Xujiaping, ENH, enshi xujiaping enshi city china', key:'A_ENH', cityBase: 67, city: 'ENH', country: 'CN', type: 'airport', nlabel: 'enshi xujiaping enshi city china'},
  { icon: 'faPlaneDeparture', label: 'EBB Entebbe, Uganda, Entebbe International, EBB, entebbe international entebbe uganda', key:'A_EBB', cityBase: 33, city: 'EBB', country: 'UG', type: 'airport', nlabel: 'entebbe international entebbe uganda'},
  { icon: 'faPlaneDeparture', label: 'AIU Atiu, Cook Islands, Enua, AIU, enua atiu cook islands', key:'A_AIU', cityBase: 127, city: 'AIU', country: 'CK', type: 'airport', nlabel: 'enua atiu cook islands'},
  { icon: 'faPlaneDeparture', label: 'OMA Omaha, United States, Eppley Airfield, OMA, eppley airfield omaha nebraska united states', key:'A_OMA', cityBase: 8, city: 'OMA', country: 'US', type: 'airport', nlabel: 'eppley airfield omaha nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'EBL Erbil, Iraq, Erbil International, EBL, erbil international erbil iraq', key:'A_EBL', cityBase: 38, city: 'EBL', country: 'IQ', type: 'airport', nlabel: 'erbil international erbil iraq'},
  { icon: 'faPlaneDeparture', label: 'ERG Yerbogachen, Russia, Erbogachen, ERG, erbogachen yerbogachen russia', key:'A_ERG', cityBase: 127, city: 'ERG', country: 'RU', type: 'airport', nlabel: 'erbogachen yerbogachen russia'},
  { icon: 'faPlaneDeparture', label: 'ECN Northern Cyprus, Unknown Region, Ercan International, ECN, ercan international northern cyprus unknown region', key:'A_ECN', cityBase: 15, city: 'ECN', country: 'ZZ', type: 'airport', nlabel: 'ercan international northern cyprus unknown region'},
  { icon: 'faPlaneDeparture', label: 'ERL Erenhot, China, Erenhot Saiwusu International, ERL, erenhot saiwusu international erenhot china', key:'A_ERL', cityBase: 72, city: 'ERL', country: 'CN', type: 'airport', nlabel: 'erenhot saiwusu international erenhot china'},
  { icon: 'faPlaneDeparture', label: 'ERF Erfurt, Germany, Erfurt–Weimar, ERF, erfurt weimar erfurt germany', key:'A_ERF', cityBase: 12, city: 'ERF', country: 'DE', type: 'airport', nlabel: 'erfurt weimar erfurt germany'},
  { icon: 'faPlaneDeparture', label: 'ERI Erie, United States, Erie International, ERI, erie international erie pennsylvania united states', key:'A_ERI', cityBase: 64, city: 'ERI', country: 'US', type: 'airport', nlabel: 'erie international erie pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'YXY Whitehorse, Canada, Erik Nielsen Whitehorse International, YXY, erik nielsen whitehorse international whitehorse yukon territory canada', key:'A_YXY', cityBase: 89, city: 'YXY', country: 'CA', type: 'airport', nlabel: 'erik nielsen whitehorse international whitehorse yukon territory canada'},
  { icon: 'faPlaneDeparture', label: 'ASR Kayseri, Turkey, Erkilet International, ASR, erkilet international kayseri turkey', key:'A_ASR', cityBase: 14, city: 'ASR', country: 'TR', type: 'airport', nlabel: 'erkilet international kayseri turkey'},
  { icon: 'faPlaneDeparture', label: 'PRC Prescott, United States, Ernest A. Love Field, PRC, ernest a love field prescott arizona united states', key:'A_PRC', cityBase: 127, city: 'PRC', country: 'US', type: 'airport', nlabel: 'ernest a love field prescott arizona united states'},
  { icon: 'faPlaneDeparture', label: 'BAQ Barranquilla, Colombia, Ernesto Cortissoz International, BAQ, ernesto cortissoz international barranquilla colombia', key:'A_BAQ', cityBase: 39, city: 'BAQ', country: 'CO', type: 'airport', nlabel: 'ernesto cortissoz international barranquilla colombia'},
  { icon: 'faPlaneDeparture', label: 'ERS Windhoek, Namibia, Eros, ERS, eros windhoek namibia', key:'A_ERS', cityBase: 127, city: 'WDH', country: 'NA', type: 'airport', nlabel: 'eros windhoek namibia'},
  { icon: 'faPlaneDeparture', label: 'ERC Erzincan, Turkey, Erzincan, ERC, erzincan erzincan turkey', key:'A_ERC', cityBase: 23, city: 'ERC', country: 'TR', type: 'airport', nlabel: 'erzincan erzincan turkey'},
  { icon: 'faPlaneDeparture', label: 'ERZ Erzurum, Turkey, Erzurum, ERZ, erzurum erzurum turkey', key:'A_ERZ', cityBase: 20, city: 'ERZ', country: 'TR', type: 'airport', nlabel: 'erzurum erzurum turkey'},
  { icon: 'faPlaneDeparture', label: 'EBJ Esbjerg, Denmark, Esbjerg, EBJ, esbjerg esbjerg denmark', key:'A_EBJ', cityBase: 127, city: 'EBJ', country: 'DK', type: 'airport', nlabel: 'esbjerg esbjerg denmark'},
  { icon: 'faPlaneDeparture', label: 'ESB Ankara, Turkey, Esenboğa International, ESB, esenboga international ankara turkey', key:'A_ESB', cityBase: 12, city: 'ANK', country: 'TR', type: 'airport', nlabel: 'esenboga international ankara turkey'},
  { icon: 'faPlaneDeparture', label: 'EPR Esperance, Australia, Esperance, EPR, esperance esperance western australia australia', key:'A_EPR', cityBase: 127, city: 'EPR', country: 'AU', type: 'airport', nlabel: 'esperance esperance western australia australia'},
  { icon: 'faPlaneDeparture', label: 'EQS Esquel, Argentina, Esquel, EQS, esquel esquel chubut argentina', key:'A_EQS', cityBase: 127, city: 'EQS', country: 'AR', type: 'airport', nlabel: 'esquel esquel chubut argentina'},
  { icon: 'faPlaneDeparture', label: 'ESU Essaouira, Morocco, Essaouira-Mogador, ESU, essaouira mogador essaouira morocco', key:'A_ESU', cityBase: 116, city: 'ESU', country: 'MA', type: 'airport', nlabel: 'essaouira mogador essaouira morocco'},
  { icon: 'faPlaneDeparture', label: 'MEB Melbourne, Australia, Essendon, MEB, essendon melbourne victoria australia', key:'A_MEB', cityBase: 69, city: 'MEL', country: 'AU', type: 'airport', nlabel: 'essendon melbourne victoria australia'},
  { icon: 'faPlaneDeparture', label: 'EUG Eugene, United States, Eugene, EUG, eugene eugene oregon united states', key:'A_EUG', cityBase: 28, city: 'EUG', country: 'US', type: 'airport', nlabel: 'eugene eugene oregon united states'},
  { icon: 'faPlaneDeparture', label: 'MAZ Mayagüez, United States, Eugenio María de Hostos, MAZ, eugenio maria de hostos mayaguez puerto rico united states', key:'A_MAZ', cityBase: 123, city: 'MAZ', country: 'US', type: 'airport', nlabel: 'eugenio maria de hostos mayaguez puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'VIX Vitória, Espírito Santo, Brazil, Eurico de Aguiar Salles, VIX, eurico de aguiar salles vitoria state of espirito santo brazil', key:'A_VIX', cityBase: 77, city: 'VIX', country: 'BR', type: 'airport', nlabel: 'eurico de aguiar salles vitoria state of espirito santo brazil'},
  { icon: 'faPlaneDeparture', label: 'BSL Basel, Switzerland, EuroAirport Basel Mulhouse Freiburg, BSL, euroairport basel mulhouse freiburg basel switzerland', key:'A_BSL', cityBase: 4, city: 'EAP', country: 'CH', type: 'airport', nlabel: 'euroairport basel mulhouse freiburg basel switzerland'},
  { icon: 'faPlaneDeparture', label: 'EVV Evansville, United States, Evansville Regional, EVV, evansville regional evansville indiana united states', key:'A_EVV', cityBase: 60, city: 'EVV', country: 'US', type: 'airport', nlabel: 'evansville regional evansville indiana united states'},
  { icon: 'faPlaneDeparture', label: 'EXI Excursion Inlet, United States, Excursion Inlet Seaplane Base, EXI, excursion inlet seaplane base excursion inlet alaska united states', key:'A_EXI', cityBase: 127, city: 'EXI', country: 'US', type: 'airport', nlabel: 'excursion inlet seaplane base excursion inlet alaska united states'},
  { icon: 'faPlaneDeparture', label: 'EXT Exeter, United Kingdom, Exeter, EXT, exeter exeter united kingdom', key:'A_EXT', cityBase: -983, city: 'EXT', country: 'GB', type: 'airport', nlabel: 'exeter exeter united kingdom'},
  { icon: 'faPlaneDeparture', label: 'GGT George Town, Bahamas, Exuma International, GGT, exuma international george town bahamas', key:'A_GGT', cityBase: 78, city: 'GGT', country: 'BS', type: 'airport', nlabel: 'exuma international george town bahamas'},
  { icon: 'faPlaneDeparture', label: 'EUX Sint Eustatius, Netherlands, F. D. Roosevelt, EUX, f d roosevelt sint eustatius caribbean netherlands netherlands', key:'A_EUX', cityBase: 127, city: 'EUX', country: 'NL', type: 'airport', nlabel: 'f d roosevelt sint eustatius caribbean netherlands netherlands'},
  { icon: 'faPlaneDeparture', label: 'PPT Tahiti, French Polynesia, Fa a ā International, PPT, fa a a international tahiti french polynesia', key:'A_PPT', cityBase: 31, city: 'PPT', country: 'PF', type: 'airport', nlabel: 'fa a a international tahiti french polynesia'},
  { icon: 'faPlaneDeparture', label: 'FAC Faaite, French Polynesia, Faaite, FAC, faaite faaite french polynesia', key:'A_FAC', cityBase: 127, city: 'FAC', country: 'PF', type: 'airport', nlabel: 'faaite faaite french polynesia'},
  { icon: 'faPlaneDeparture', label: 'MVP Mitú, Colombia, Fabio Alberto León Bentley, MVP, fabio alberto leon bentley mitu colombia', key:'A_MVP', cityBase: 127, city: 'MVP', country: 'CO', type: 'airport', nlabel: 'fabio alberto leon bentley mitu colombia'},
  { icon: 'faPlaneDeparture', label: 'FAI Fairbanks, United States, Fairbanks International, FAI, fairbanks international fairbanks alaska united states', key:'A_FAI', cityBase: 40, city: 'FAI', country: 'US', type: 'airport', nlabel: 'fairbanks international fairbanks alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LYP Faisalabad District, Pakistan, Faisalabad International, LYP, faisalabad international faisalabad district pakistan', key:'A_LYP', cityBase: 80, city: 'LYP', country: 'PK', type: 'airport', nlabel: 'faisalabad international faisalabad district pakistan'},
  { icon: 'faPlaneDeparture', label: 'FAV Fakarava, French Polynesia, Fakarava, FAV, fakarava fakarava french polynesia', key:'A_FAV', cityBase: 127, city: 'FAV', country: 'PF', type: 'airport', nlabel: 'fakarava fakarava french polynesia'},
  { icon: 'faPlaneDeparture', label: 'FKQ Fakfak, Indonesia, Fakfak Torea, FKQ, fakfak torea fakfak indonesia', key:'A_FKQ', cityBase: 127, city: 'FKQ', country: 'ID', type: 'airport', nlabel: 'fakfak torea fakfak indonesia'},
  { icon: 'faPlaneDeparture', label: 'PMO Palermo, Italy, Falcone–Borsellino, PMO, falcone borsellino palermo italy', key:'A_PMO', cityBase: 12, city: 'PMO', country: 'IT', type: 'airport', nlabel: 'falcone borsellino palermo italy'},
  { icon: 'faPlaneDeparture', label: 'APW Apia, Samoa, Faleolo International, APW, faleolo international apia samoa', key:'A_APW', cityBase: 64, city: 'APW', country: 'WS', type: 'airport', nlabel: 'faleolo international apia samoa'},
  { icon: 'faPlaneDeparture', label: 'INL International Falls, United States, Falls International, INL, falls international international falls minnesota united states', key:'A_INL', cityBase: 111, city: 'INL', country: 'US', type: 'airport', nlabel: 'falls international international falls minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'KFP False Pass, United States, False Pass, KFP, false pass false pass alaska united states', key:'A_KFP', cityBase: 127, city: 'KFP', country: 'US', type: 'airport', nlabel: 'false pass false pass alaska united states'},
  { icon: 'faPlaneDeparture', label: 'FGU Fangatau, French Polynesia, Fangatau, FGU, fangatau fangatau french polynesia', key:'A_FGU', cityBase: 127, city: 'FGU', country: 'PF', type: 'airport', nlabel: 'fangatau fangatau french polynesia'},
  { icon: 'faPlaneDeparture', label: 'TRU Trujillo, Peru, FAP Captain Carlos Martínez de Pinillos International, TRU, fap captain carlos martinez de pinillos international trujillo peru', key:'A_TRU', cityBase: 84, city: 'TRU', country: 'PE', type: 'airport', nlabel: 'fap captain carlos martinez de pinillos international trujillo peru'},
  { icon: 'faPlaneDeparture', label: 'PCL Pucallpa, Peru, FAP Captain David Abensur Rengifo International, PCL, fap captain david abensur rengifo international pucallpa peru', key:'A_PCL', cityBase: 111, city: 'PCL', country: 'PE', type: 'airport', nlabel: 'fap captain david abensur rengifo international pucallpa peru'},
  { icon: 'faPlaneDeparture', label: 'PIU Piura, Peru, FAP Captain Guillermo Concha Iberico International, PIU, fap captain guillermo concha iberico international piura peru', key:'A_PIU', cityBase: 61, city: 'PIU', country: 'PE', type: 'airport', nlabel: 'fap captain guillermo concha iberico international piura peru'},
  { icon: 'faPlaneDeparture', label: 'CIX Chiclayo, Peru, FAP Captain José Abelardo Quiñones Gonzáles International, CIX, fap captain jose abelardo quinones gonzales international chiclayo peru', key:'A_CIX', cityBase: 90, city: 'CIX', country: 'PE', type: 'airport', nlabel: 'fap captain jose abelardo quinones gonzales international chiclayo peru'},
  { icon: 'faPlaneDeparture', label: 'FAO Faro, Portugal, Faro, FAO, faro faro portugal', key:'A_FAO', cityBase: 6, city: 'FAO', country: 'PT', type: 'airport', nlabel: 'faro faro portugal'},
  { icon: 'faPlaneDeparture', label: 'NOS Nosy Be, Madagascar, Fascene, NOS, fascene nosy be madagascar', key:'A_NOS', cityBase: 94, city: 'NOS', country: 'MG', type: 'airport', nlabel: 'fascene nosy be madagascar'},
  { icon: 'faPlaneDeparture', label: 'BKS Bengkulu, Indonesia, Fatmawati Soekarno, BKS, fatmawati soekarno bengkulu indonesia', key:'A_BKS', cityBase: 127, city: 'BKS', country: 'ID', type: 'airport', nlabel: 'fatmawati soekarno bengkulu indonesia'},
  { icon: 'faPlaneDeparture', label: 'FAY Fayetteville, United States, Fayetteville Regional, FAY, fayetteville regional fayetteville north carolina united states', key:'A_FAY', cityBase: 54, city: 'FAY', country: 'US', type: 'airport', nlabel: 'fayetteville regional fayetteville north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'RMI Rimini, Italy, Federico Fellini International, RMI, federico fellini international rimini italy', key:'A_RMI', cityBase: 21, city: 'RMI', country: 'IT', type: 'airport', nlabel: 'federico fellini international rimini italy'},
  { icon: 'faPlaneDeparture', label: 'GRX Granada, Spain, Federico García Lorca, GRX, federico garcia lorca granada spain', key:'A_GRX', cityBase: 14, city: 'GRX', country: 'ES', type: 'airport', nlabel: 'federico garcia lorca granada spain'},
  { icon: 'faPlaneDeparture', label: 'FEC Feira de Santana, Brazil, Feira de Santana, FEC, feira de santana feira de santana brazil', key:'A_FEC', cityBase: 127, city: 'FEC', country: 'BR', type: 'airport', nlabel: 'feira de santana feira de santana brazil'},
  { icon: 'faPlaneDeparture', label: 'FRE Fera Island, Solomon Islands, Fera, FRE, fera fera island solomon islands', key:'A_FRE', cityBase: 127, city: 'FRE', country: 'SB', type: 'airport', nlabel: 'fera fera island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'FLZ Sibolga, Indonesia, Ferdinand Lumban Tobing, FLZ, ferdinand lumban tobing sibolga indonesia', key:'A_FLZ', cityBase: 127, city: 'FLZ', country: 'ID', type: 'airport', nlabel: 'ferdinand lumban tobing sibolga indonesia'},
  { icon: 'faPlaneDeparture', label: 'FEG Fergana, Uzbekistan, Fergana International, FEG, fergana international fergana uzbekistan', key:'A_FEG', cityBase: 57, city: 'FEG', country: 'UZ', type: 'airport', nlabel: 'fergana international fergana uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'FEN Fernando de Noronha, Brazil, Fernando De Noronha, FEN, fernando de noronha fernando de noronha fernando de noronha brazil', key:'A_FEN', cityBase: 90, city: 'FEN', country: 'BR', type: 'airport', nlabel: 'fernando de noronha fernando de noronha fernando de noronha brazil'},
  { icon: 'faPlaneDeparture', label: 'SIG San Juan, United States, Fernando Luis Ribas Dominicci, SIG, fernando luis ribas dominicci san juan puerto rico united states', key:'A_SIG', cityBase: 95, city: 'SJU', country: 'US', type: 'airport', nlabel: 'fernando luis ribas dominicci san juan puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'FEZ Fes, Morocco, Fès–Saïs, FEZ, fes sais fes morocco', key:'A_FEZ', cityBase: 50, city: 'FEZ', country: 'MA', type: 'airport', nlabel: 'fes sais fes morocco'},
  { icon: 'faPlaneDeparture', label: 'FSC Figari, France, Figari–Sud Corse, FSC, figari sud corse figari france', key:'A_FSC', cityBase: 19, city: 'FSC', country: 'FR', type: 'airport', nlabel: 'figari sud corse figari france'},
  { icon: 'faPlaneDeparture', label: 'FLG Grand Canyon Village, United States, Flagstaff Pulliam, FLG, flagstaff pulliam grand canyon village arizona united states', key:'A_FLG', cityBase: 61, city: 'GCN', country: 'US', type: 'airport', nlabel: 'flagstaff pulliam grand canyon village arizona united states'},
  { icon: 'faPlaneDeparture', label: 'BON Bonaire, Netherlands, Flamingo International, BON, flamingo international bonaire caribbean netherlands netherlands', key:'A_BON', cityBase: 72, city: 'BON', country: 'NL', type: 'airport', nlabel: 'flamingo international bonaire caribbean netherlands netherlands'},
  { icon: 'faPlaneDeparture', label: 'YFO Flin Flon, Canada, Flin Flon, YFO, flin flon flin flon manitoba canada', key:'A_YFO', cityBase: 127, city: 'YFO', country: 'CA', type: 'airport', nlabel: 'flin flon flin flon manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'FLS Flinders Island, Australia, Flinders Island, FLS, flinders island flinders island tasmania australia', key:'A_FLS', cityBase: 127, city: 'FLS', country: 'AU', type: 'airport', nlabel: 'flinders island flinders island tasmania australia'},
  { icon: 'faPlaneDeparture', label: 'FLR Florence, Italy, Florence, FLR, florence florence italy', key:'A_FLR', cityBase: 9, city: 'FLR', country: 'IT', type: 'airport', nlabel: 'florence florence italy'},
  { icon: 'faPlaneDeparture', label: 'FLO Florence, United States, Florence Regional, FLO, florence regional florence south carolina united states', key:'A_FLO', cityBase: 92, city: 'FLO', country: 'US', type: 'airport', nlabel: 'florence regional florence south carolina united states'},
  { icon: 'faPlaneDeparture', label: 'FLW Flores Island, Portugal, Flores, FLW, flores flores island portugal', key:'A_FLW', cityBase: 57, city: 'FLW', country: 'PT', type: 'airport', nlabel: 'flores flores island portugal'},
  { icon: 'faPlaneDeparture', label: 'FRO Florø, Norway, Florø, FRO, floro floro norway', key:'A_FRO', cityBase: 127, city: 'FRO', country: 'NO', type: 'airport', nlabel: 'floro floro norway'},
  { icon: 'faPlaneDeparture', label: 'IMT Iron Mountain, United States, Ford, IMT, ford iron mountain michigan united states', key:'A_IMT', cityBase: 101, city: 'IMT', country: 'US', type: 'airport', nlabel: 'ford iron mountain michigan united states'},
  { icon: 'faPlaneDeparture', label: 'FDE Førde, Norway, Førde Airport, Bringeland, FDE, forde airport forde norway', key:'A_FDE', cityBase: 127, city: 'FDE', country: 'NO', type: 'airport', nlabel: 'forde airport forde norway'},
  { icon: 'faPlaneDeparture', label: 'FMA Formosa, Argentina, Formosa International, FMA, formosa international formosa formosa argentina', key:'A_FMA', cityBase: 127, city: 'FMA', country: 'AR', type: 'airport', nlabel: 'formosa international formosa formosa argentina'},
  { icon: 'faPlaneDeparture', label: 'YFA Fort Albany First Nation, Canada, Fort Albany, YFA, fort albany fort albany first nation ontario canada', key:'A_YFA', cityBase: 127, city: 'YFA', country: 'CA', type: 'airport', nlabel: 'fort albany fort albany first nation ontario canada'},
  { icon: 'faPlaneDeparture', label: 'YPY Fort Chipewyan, Canada, Fort Chipewyan, YPY, fort chipewyan fort chipewyan alberta canada', key:'A_YPY', cityBase: 127, city: 'YPY', country: 'CA', type: 'airport', nlabel: 'fort chipewyan fort chipewyan alberta canada'},
  { icon: 'faPlaneDeparture', label: 'FOD Fort Dodge, United States, Fort Dodge Regional, FOD, fort dodge regional fort dodge iowa united states', key:'A_FOD', cityBase: 127, city: 'FOD', country: 'US', type: 'airport', nlabel: 'fort dodge regional fort dodge iowa united states'},
  { icon: 'faPlaneDeparture', label: 'YAG Fort Frances, Canada, Fort Frances Municipal, YAG, fort frances municipal fort frances ontario canada', key:'A_YAG', cityBase: 127, city: 'YAG', country: 'CA', type: 'airport', nlabel: 'fort frances municipal fort frances ontario canada'},
  { icon: 'faPlaneDeparture', label: 'YGH Fort Good Hope, Canada, Fort Good Hope, YGH, fort good hope fort good hope northwest territories canada', key:'A_YGH', cityBase: 127, city: 'YGH', country: 'CA', type: 'airport', nlabel: 'fort good hope fort good hope northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'FXE Fort Lauderdale, United States, Fort Lauderdale Executive, FXE, fort lauderdale executive fort lauderdale florida united states', key:'A_FXE', cityBase: 95, city: 'FLL', country: 'US', type: 'airport', nlabel: 'fort lauderdale executive fort lauderdale florida united states'},
  { icon: 'faPlaneDeparture', label: 'FLL Fort Lauderdale, United States, Fort Lauderdale–Hollywood International, FLL, fort lauderdale hollywood international fort lauderdale florida united states', key:'A_FLL', cityBase: 0, city: 'FLL', country: 'US', type: 'airport', nlabel: 'fort lauderdale hollywood international fort lauderdale florida united states'},
  { icon: 'faPlaneDeparture', label: 'YMM Fort McMurray, Canada, Fort McMurray International, YMM, fort mcmurray international fort mcmurray alberta canada', key:'A_YMM', cityBase: 116, city: 'YMM', country: 'CA', type: 'airport', nlabel: 'fort mcmurray international fort mcmurray alberta canada'},
  { icon: 'faPlaneDeparture', label: 'ZFM Fort McPherson, Canada, Fort McPherson, ZFM, fort mcpherson fort mcpherson northwest territories canada', key:'A_ZFM', cityBase: 127, city: 'ZFM', country: 'CA', type: 'airport', nlabel: 'fort mcpherson fort mcpherson northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'YYE Fort Nelson, Canada, Fort Nelson, YYE, fort nelson fort nelson british columbia canada', key:'A_YYE', cityBase: 127, city: 'YYE', country: 'CA', type: 'airport', nlabel: 'fort nelson fort nelson british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'YFS Fort Simpson, Canada, Fort Simpson, YFS, fort simpson fort simpson northwest territories canada', key:'A_YFS', cityBase: 127, city: 'YFS', country: 'CA', type: 'airport', nlabel: 'fort simpson fort simpson northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'YSM Fort Smith, Canada, Fort Smith, YSM, fort smith fort smith northwest territories canada', key:'A_YSM', cityBase: 117, city: 'YSM', country: 'CA', type: 'airport', nlabel: 'fort smith fort smith northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'FSM Fort Smith, United States, Fort Smith Regional, FSM, fort smith regional fort smith arkansas united states', key:'A_FSM', cityBase: 66, city: 'FSM', country: 'US', type: 'airport', nlabel: 'fort smith regional fort smith arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'YXJ Fort St. John, Canada, Fort St. John, YXJ, fort st john fort st john british columbia canada', key:'A_YXJ', cityBase: 119, city: 'YXJ', country: 'CA', type: 'airport', nlabel: 'fort st john fort st john british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'FWA Fort Wayne, United States, Fort Wayne International, FWA, fort wayne international fort wayne indiana united states', key:'A_FWA', cityBase: 38, city: 'FWA', country: 'US', type: 'airport', nlabel: 'fort wayne international fort wayne indiana united states'},
  { icon: 'faPlaneDeparture', label: 'FYU Fort Yukon, United States, Fort Yukon, FYU, fort yukon fort yukon alaska united states', key:'A_FYU', cityBase: 127, city: 'FYU', country: 'US', type: 'airport', nlabel: 'fort yukon fort yukon alaska united states'},
  { icon: 'faPlaneDeparture', label: 'FMN Farmington, United States, Four Corners Regional, FMN, four corners regional farmington new mexico united states', key:'A_FMN', cityBase: 127, city: 'FMN', country: 'US', type: 'airport', nlabel: 'four corners regional farmington new mexico united states'},
  { icon: 'faPlaneDeparture', label: 'IGU Foz do Iguaçu, Brazil, Foz do Iguaçu International, IGU, foz do iguacu international foz do iguacu state of parana brazil', key:'A_IGU', cityBase: 51, city: 'IGU', country: 'BR', type: 'airport', nlabel: 'foz do iguacu international foz do iguacu state of parana brazil'},
  { icon: 'faPlaneDeparture', label: 'USU Busuanga, Palawan, Philippines, Francisco B. Reyes, USU, francisco b reyes busuanga philippines', key:'A_USU', cityBase: 52, city: 'USU', country: 'PH', type: 'airport', nlabel: 'francisco b reyes busuanga philippines'},
  { icon: 'faPlaneDeparture', label: 'DVO Davao, Philippines, Francisco Bangoy International, DVO, francisco bangoy international davao philippines', key:'A_DVO', cityBase: 41, city: 'DVO', country: 'PH', type: 'airport', nlabel: 'francisco bangoy international davao philippines'},
  { icon: 'faPlaneDeparture', label: 'JAU Jauja, Peru, Francisco Carle, JAU, francisco carle jauja peru', key:'A_JAU', cityBase: 127, city: 'JAU', country: 'PE', type: 'airport', nlabel: 'francisco carle jauja peru'},
  { icon: 'faPlaneDeparture', label: 'OCC Puerto Francisco de Orellana, Ecuador, Francisco de Orellana, OCC, francisco de orellana puerto francisco de orellana ecuador', key:'A_OCC', cityBase: 127, city: 'OCC', country: 'EC', type: 'airport', nlabel: 'francisco de orellana puerto francisco de orellana ecuador'},
  { icon: 'faPlaneDeparture', label: 'FRW Francistown, Botswana, Francistown, FRW, francistown francistown botswana', key:'A_FRW', cityBase: 123, city: 'FRW', country: 'BW', type: 'airport', nlabel: 'francistown francistown botswana'},
  { icon: 'faPlaneDeparture', label: 'HOG Holguín, Cuba, Frank País, HOG, frank pais holguin cuba', key:'A_HOG', cityBase: 40, city: 'HOG', country: 'CU', type: 'airport', nlabel: 'frank pais holguin cuba'},
  { icon: 'faPlaneDeparture', label: 'FRA Frankfurt, Germany, Frankfurt International Airport, FRA, frankfurt frankfurt germany', key:'A_FRA', cityBase: 2, city: 'FRA', country: 'DE', type: 'airport', nlabel: 'frankfurt frankfurt germany'},
  { icon: 'faPlaneDeparture', label: 'HHN Frankfurt, Germany, Frankfurt–Hahn, HHN, frankfurt hahn frankfurt germany', key:'A_HHN', cityBase: 8, city: 'FRA', country: 'DE', type: 'airport', nlabel: 'frankfurt hahn frankfurt germany'},
  { icon: 'faPlaneDeparture', label: 'BIK Biak, Indonesia, Frans Kaisiepo International, BIK, frans kaisiepo international biak indonesia', key:'A_BIK', cityBase: 127, city: 'BIK', country: 'ID', type: 'airport', nlabel: 'frans kaisiepo international biak indonesia'},
  { icon: 'faPlaneDeparture', label: 'RTG Ruteng, Indonesia, Frans Sales Lega, RTG, frans sales lega ruteng indonesia', key:'A_RTG', cityBase: 127, city: 'RTG', country: 'ID', type: 'airport', nlabel: 'frans sales lega ruteng indonesia'},
  { icon: 'faPlaneDeparture', label: 'MOF Maumere, Indonesia, Frans Xavier Seda, MOF, frans xavier seda maumere indonesia', key:'A_MOF', cityBase: 127, city: 'MOF', country: 'ID', type: 'airport', nlabel: 'frans xavier seda maumere indonesia'},
  { icon: 'faPlaneDeparture', label: 'YFC Fredericton, Canada, Fredericton International, YFC, fredericton international fredericton new brunswick canada', key:'A_YFC', cityBase: 84, city: 'YFC', country: 'CA', type: 'airport', nlabel: 'fredericton international fredericton new brunswick canada'},
  { icon: 'faPlaneDeparture', label: 'FAT Fresno, United States, Fresno Yosemite International, FAT, fresno yosemite international fresno california united states', key:'A_FAT', cityBase: 23, city: 'FAT', country: 'US', type: 'airport', nlabel: 'fresno yosemite international fresno california united states'},
  { icon: 'faPlaneDeparture', label: 'SUN Sun Valley, United States, Friedman Memorial, SUN, friedman memorial sun valley idaho united states', key:'A_SUN', cityBase: 37, city: 'SUN', country: 'US', type: 'airport', nlabel: 'friedman memorial sun valley idaho united states'},
  { icon: 'faPlaneDeparture', label: 'FDH Friedrichshafen, Germany, Friedrichshafen, FDH, friedrichshafen friedrichshafen germany', key:'A_FDH', cityBase: 7, city: 'FDH', country: 'DE', type: 'airport', nlabel: 'friedrichshafen friedrichshafen germany'},
  { icon: 'faPlaneDeparture', label: 'TBU Nukuʻalofa, Tonga, Fuaʻamotu International, TBU, fua`amotu international nuku`alofa tonga', key:'A_TBU', cityBase: 82, city: 'TBU', country: 'TO', type: 'airport', nlabel: 'fua`amotu international nuku`alofa tonga'},
  { icon: 'faPlaneDeparture', label: 'FUE Fuerteventura, Spain, Fuerteventura, FUE, fuerteventura fuerteventura spain', key:'A_FUE', cityBase: 8, city: 'FUE', country: 'ES', type: 'airport', nlabel: 'fuerteventura fuerteventura spain'},
  { icon: 'faPlaneDeparture', label: 'FUJ Fukue Island, Japan, Fukue, FUJ, fukue fukue island japan', key:'A_FUJ', cityBase: 127, city: 'FUJ', country: 'JP', type: 'airport', nlabel: 'fukue fukue island japan'},
  { icon: 'faPlaneDeparture', label: 'FUK Fukuoka, Japan, Fukuoka, FUK, fukuoka fukuoka japan', key:'A_FUK', cityBase: 39, city: 'FUK', country: 'JP', type: 'airport', nlabel: 'fukuoka fukuoka japan'},
  { icon: 'faPlaneDeparture', label: 'FKS Fukushima, Japan, Fukushima, FKS, fukushima fukushima japan', key:'A_FKS', cityBase: 127, city: 'FKS', country: 'JP', type: 'airport', nlabel: 'fukushima fukushima japan'},
  { icon: 'faPlaneDeparture', label: 'FUN Funafuti, Tuvalu, Funafuti International, FUN, funafuti international funafuti tuvalu', key:'A_FUN', cityBase: 127, city: 'FUN', country: 'TV', type: 'airport', nlabel: 'funafuti international funafuti tuvalu'},
  { icon: 'faPlaneDeparture', label: 'FTA Futuna Island, Vanuatu, Futuna, FTA, futuna futuna island vanuatu', key:'A_FTA', cityBase: 127, city: 'FTA', country: 'VU', type: 'airport', nlabel: 'futuna futuna island vanuatu'},
  { icon: 'faPlaneDeparture', label: 'FUG Fuyang, China, Fuyang Xiguan, FUG, fuyang xiguan fuyang china', key:'A_FUG', cityBase: 71, city: 'FUG', country: 'CN', type: 'airport', nlabel: 'fuyang xiguan fuyang china'},
  { icon: 'faPlaneDeparture', label: 'FYJ Fuyuan, Heilongjiang, China, Fuyuan Dongji, FYJ, fuyuan dongji fuyuan china', key:'A_FYJ', cityBase: 73, city: 'FYJ', country: 'CN', type: 'airport', nlabel: 'fuyuan dongji fuyuan china'},
  { icon: 'faPlaneDeparture', label: 'FYN Fuyun County, China, Fuyun Koktokay, FYN, fuyun koktokay fuyun county china', key:'A_FYN', cityBase: 66, city: 'FYN', country: 'CN', type: 'airport', nlabel: 'fuyun koktokay fuyun county china'},
  { icon: 'faPlaneDeparture', label: 'FOC Fuzhou, China, Fuzhou Changle International, FOC, fuzhou changle international fuzhou china', key:'A_FOC', cityBase: 37, city: 'FOC', country: 'CN', type: 'airport', nlabel: 'fuzhou changle international fuzhou china'},
  { icon: 'faPlaneDeparture', label: 'TME Tame, Colombia, Gabriel Vargas Santos, TME, gabriel vargas santos tame colombia', key:'A_TME', cityBase: 127, city: 'TME', country: 'CO', type: 'airport', nlabel: 'gabriel vargas santos tame colombia'},
  { icon: 'faPlaneDeparture', label: 'GNV Gainesville, United States, Gainesville Regional, GNV, gainesville regional gainesville florida united states', key:'A_GNV', cityBase: 55, city: 'GNV', country: 'US', type: 'airport', nlabel: 'gainesville regional gainesville florida united states'},
  { icon: 'faPlaneDeparture', label: 'GLX Galela, Indonesia, Galela Gamarmalamo, GLX, galela gamarmalamo galela indonesia', key:'A_GLX', cityBase: 127, city: 'GLX', country: 'ID', type: 'airport', nlabel: 'galela gamarmalamo galela indonesia'},
  { icon: 'faPlaneDeparture', label: 'GEV Gällivare, Sweden, Gällivare, GEV, gallivare gallivare sweden', key:'A_GEV', cityBase: 114, city: 'GEV', country: 'SE', type: 'airport', nlabel: 'gallivare gallivare sweden'},
  { icon: 'faPlaneDeparture', label: 'GMB Gambela, Ethiopia, Gambela, GMB, gambela gambela ethiopia', key:'A_GMB', cityBase: 127, city: 'GMB', country: 'ET', type: 'airport', nlabel: 'gambela gambela ethiopia'},
  { icon: 'faPlaneDeparture', label: 'GAM Gambell, United States, Gambell, GAM, gambell gambell alaska united states', key:'A_GAM', cityBase: 127, city: 'GAM', country: 'US', type: 'airport', nlabel: 'gambell gambell alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YRA Gameti, Canada, Gamètì/Rae Lakes, YRA, gameti rae lakes gameti northwest territories canada', key:'A_YRA', cityBase: 127, city: 'YRA', country: 'CA', type: 'airport', nlabel: 'gameti rae lakes gameti northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'YQX Gander, Canada, Gander International, YQX, gander international gander newfoundland and labrador canada', key:'A_YQX', cityBase: 109, city: 'YQX', country: 'CA', type: 'airport', nlabel: 'gander international gander newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'ISK Nashik, India, Gandhinagar Airport, ISK, gandhinagar airport nashik india', key:'A_ISK', cityBase: 127, city: 'null', country: 'IN', type: 'airport', nlabel: 'gandhinagar airport nashik india'},
  { icon: 'faPlaneDeparture', label: 'KVD Ganja, Azerbaijan, Ganja International, KVD, ganja international ganja azerbaijan', key:'A_KVD', cityBase: 58, city: 'KVD', country: 'AZ', type: 'airport', nlabel: 'ganja international ganja azerbaijan'},
  { icon: 'faPlaneDeparture', label: 'GXH Xiahe County, China, Gannan Xiahe, GXH, gannan xiahe xiahe county china', key:'A_GXH', cityBase: 127, city: 'GXH', country: 'CN', type: 'airport', nlabel: 'gannan xiahe xiahe county china'},
  { icon: 'faPlaneDeparture', label: 'KOW Ganzhou, China, Ganzhou Huangjin, KOW, ganzhou huangjin ganzhou china', key:'A_KOW', cityBase: 76, city: 'KOW', country: 'CN', type: 'airport', nlabel: 'ganzhou huangjin ganzhou china'},
  { icon: 'faPlaneDeparture', label: 'GCK Garden City, United States, Garden City Regional, GCK, garden city regional garden city kansas united states', key:'A_GCK', cityBase: 97, city: 'GCK', country: 'US', type: 'airport', nlabel: 'garden city regional garden city kansas united states'},
  { icon: 'faPlaneDeparture', label: 'GOU Garoua, Cameroon, Garoua International, GOU, garoua international garoua cameroon', key:'A_GOU', cityBase: 127, city: 'GOU', country: 'CM', type: 'airport', nlabel: 'garoua international garoua cameroon'},
  { icon: 'faPlaneDeparture', label: 'GGR Garoe, Somalia, Garowe, GGR, garowe garoe somalia', key:'A_GGR', cityBase: 127, city: 'GGR', country: 'SO', type: 'airport', nlabel: 'garowe garoe somalia'},
  { icon: 'faPlaneDeparture', label: 'LGW London, United Kingdom, Gatwick, LGW, gatwick london united kingdom', key:'A_LGW', cityBase: -998, city: 'LON', country: 'GB', type: 'airport', nlabel: 'gatwick london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'ZGU Gaua, Vanuatu, Gaua, ZGU, gaua gaua vanuatu', key:'A_ZGU', cityBase: 127, city: 'ZGU', country: 'VU', type: 'airport', nlabel: 'gaua gaua vanuatu'},
  { icon: 'faPlaneDeparture', label: 'BWA Siddharthanagar, Nepal, Gautam Buddha, BWA, gautam buddha siddharthanagar nepal', key:'A_BWA', cityBase: 127, city: 'BWA', country: 'NP', type: 'airport', nlabel: 'gautam buddha siddharthanagar nepal'},
  { icon: 'faPlaneDeparture', label: 'GAY Gaya, India, Gaya, GAY, gaya gaya india', key:'A_GAY', cityBase: 105, city: 'GAY', country: 'IN', type: 'airport', nlabel: 'gaya gaya india'},
  { icon: 'faPlaneDeparture', label: 'GZP Gazipaşa, Turkey, Gazipaşa, GZP, gazipasa gazipasa turkey', key:'A_GZP', cityBase: 18, city: 'GZP', country: 'TR', type: 'airport', nlabel: 'gazipasa gazipasa turkey'},
  { icon: 'faPlaneDeparture', label: 'GDN Gdańsk, Poland, Gdańsk Lech Wałęsa, GDN, gdansk lech walesa gdansk poland', key:'A_GDN', cityBase: 38, city: 'GDN', country: 'PL', type: 'airport', nlabel: 'gdansk lech walesa gdansk poland'},
  { icon: 'faPlaneDeparture', label: 'GDZ Gelendzhik, Russia, Gelendzhik, GDZ, gelendzhik gelendzhik russia', key:'A_GDZ', cityBase: 44, city: 'GDZ', country: 'RU', type: 'airport', nlabel: 'gelendzhik gelendzhik russia'},
  { icon: 'faPlaneDeparture', label: 'GMA Gemena, Democratic Republic of the Congo, Gemena, GMA, gemena gemena democratic republic of the congo', key:'A_GMA', cityBase: 127, city: 'GMA', country: 'CD', type: 'airport', nlabel: 'gemena gemena democratic republic of the congo'},
  { icon: 'faPlaneDeparture', label: 'EGN Geneina, Sudan, Geneina, EGN, geneina geneina sudan', key:'A_EGN', cityBase: 127, city: 'EGN', country: 'SD', type: 'airport', nlabel: 'geneina geneina sudan'},
  { icon: 'faPlaneDeparture', label: 'CRD Comodoro Rivadavia, Argentina, General Enrique Mosconi International, CRD, general enrique mosconi international comodoro rivadavia chubut argentina', key:'A_CRD', cityBase: 127, city: 'CRD', country: 'AR', type: 'airport', nlabel: 'general enrique mosconi international comodoro rivadavia chubut argentina'},
  { icon: 'faPlaneDeparture', label: 'HMO Hermosillo, Mexico, General Ignacio Pesqueira García International, HMO, general ignacio pesqueira garcia international hermosillo mexico', key:'A_HMO', cityBase: 47, city: 'HMO', country: 'MX', type: 'airport', nlabel: 'general ignacio pesqueira garcia international hermosillo mexico'},
  { icon: 'faPlaneDeparture', label: 'BLA Barcelona, Venezuela, General José Antonio Anzoátegui International, BLA, general jose antonio anzoategui international barcelona venezuela', key:'A_BLA', cityBase: 46, city: 'BLA', country: 'VE', type: 'airport', nlabel: 'general jose antonio anzoategui international barcelona venezuela'},
  { icon: 'faPlaneDeparture', label: 'PRA Paraná, Argentina, General Justo José de Urquiza, PRA, general justo jose de urquiza parana entre rios argentina', key:'A_PRA', cityBase: 127, city: 'PRA', country: 'AR', type: 'airport', nlabel: 'general justo jose de urquiza parana entre rios argentina'},
  { icon: 'faPlaneDeparture', label: 'REX Reynosa, Mexico, General Lucio Blanco International, REX, general lucio blanco international reynosa mexico', key:'A_REX', cityBase: 48, city: 'REX', country: 'MX', type: 'airport', nlabel: 'general lucio blanco international reynosa mexico'},
  { icon: 'faPlaneDeparture', label: 'MKE Milwaukee, United States, General Mitchell International, MKE, general mitchell international milwaukee wisconsin united states', key:'A_MKE', cityBase: 6, city: 'MKE', country: 'US', type: 'airport', nlabel: 'general mitchell international milwaukee wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'GES General Santos, Philippines, General Santos International, GES, general santos international general santos philippines', key:'A_GES', cityBase: 79, city: 'GES', country: 'PH', type: 'airport', nlabel: 'general santos international general santos philippines'},
  { icon: 'faPlaneDeparture', label: 'SNC Salinas, Ecuador, General Ulpiano Paez, SNC, general ulpiano paez salinas ecuador', key:'A_SNC', cityBase: 125, city: 'SNC', country: 'EC', type: 'airport', nlabel: 'general ulpiano paez salinas ecuador'},
  { icon: 'faPlaneDeparture', label: 'PIA Peoria, United States, General Wayne A. Downing Peoria International, PIA, general wayne a downing peoria international peoria illinois united states', key:'A_PIA', cityBase: 53, city: 'PIA', country: 'US', type: 'airport', nlabel: 'general wayne a downing peoria international peoria illinois united states'},
  { icon: 'faPlaneDeparture', label: 'GVA Geneva, Switzerland, Geneva, GVA, geneva geneva switzerland', key:'A_GVA', cityBase: 3, city: 'GVA', country: 'CH', type: 'airport', nlabel: 'geneva geneva switzerland'},
  { icon: 'faPlaneDeparture', label: 'GOA Genoa, Italy, Genoa Cristoforo Colombo, GOA, genoa cristoforo colombo genoa italy', key:'A_GOA', cityBase: 13, city: 'GOA', country: 'IT', type: 'airport', nlabel: 'genoa cristoforo colombo genoa italy'},
  { icon: 'faPlaneDeparture', label: 'GRJ George, South Africa, George, GRJ, george george south africa', key:'A_GRJ', cityBase: 84, city: 'GRJ', country: 'ZA', type: 'airport', nlabel: 'george george south africa'},
  { icon: 'faPlaneDeparture', label: 'BHD Belfast, United Kingdom, George Best Belfast City, BHD, george best belfast city belfast united kingdom', key:'A_BHD', cityBase: -926, city: 'BFS', country: 'GB', type: 'airport', nlabel: 'george best belfast city belfast united kingdom'},
  { icon: 'faPlaneDeparture', label: 'IAH Houston, United States, George Bush Intercontinental, IAH, george bush intercontinental houston texas united states', key:'A_IAH', cityBase: 1, city: 'HOU', country: 'US', type: 'airport', nlabel: 'george bush intercontinental houston texas united states'},
  { icon: 'faPlaneDeparture', label: 'BCM Bacău, Romania, George Enescu International, BCM, george enescu international bacau romania', key:'A_BCM', cityBase: 26, city: 'BCM', country: 'RO', type: 'airport', nlabel: 'george enescu international bacau romania'},
  { icon: 'faPlaneDeparture', label: 'SLU Saint Lucia, St. Lucia, George F. L. Charles, SLU, george f l charles saint lucia st lucia', key:'A_SLU', cityBase: 59, city: 'SLU', country: 'LC', type: 'airport', nlabel: 'george f l charles saint lucia st lucia'},
  { icon: 'faPlaneDeparture', label: 'GRR Grand Rapids, United States, Gerald R. Ford International, GRR, gerald r ford international grand rapids michigan united states', key:'A_GRR', cityBase: 13, city: 'GRR', country: 'US', type: 'airport', nlabel: 'gerald r ford international grand rapids michigan united states'},
  { icon: 'faPlaneDeparture', label: 'GET Geraldton, Australia, Geraldton, GET, geraldton geraldton western australia australia', key:'A_GET', cityBase: 127, city: 'GET', country: 'AU', type: 'airport', nlabel: 'geraldton geraldton western australia australia'},
  { icon: 'faPlaneDeparture', label: 'BUN Buenaventura, Colombia, Gerardo Tobar López, BUN, gerardo tobar lopez buenaventura colombia', key:'A_BUN', cityBase: 127, city: 'BUN', country: 'CO', type: 'airport', nlabel: 'gerardo tobar lopez buenaventura colombia'},
  { icon: 'faPlaneDeparture', label: 'PCR Puerto Carreño, Colombia, Germán Olano, PCR, german olano puerto carreno colombia', key:'A_PCR', cityBase: 115, city: 'PCR', country: 'CO', type: 'airport', nlabel: 'german olano puerto carreno colombia'},
  { icon: 'faPlaneDeparture', label: 'LKA Larantuka, Indonesia, Gewayantana, LKA, gewayantana larantuka indonesia', key:'A_LKA', cityBase: 127, city: 'LKA', country: 'ID', type: 'airport', nlabel: 'gewayantana larantuka indonesia'},
  { icon: 'faPlaneDeparture', label: 'GHT Ghat, Libya, Ghat, GHT, ghat ghat libya', key:'A_GHT', cityBase: 127, city: 'GHT', country: 'LY', type: 'airport', nlabel: 'ghat ghat libya'},
  { icon: 'faPlaneDeparture', label: 'GIB Gibraltar, Gibraltar, Gibraltar International, GIB, gibraltar international gibraltar gibraltar', key:'A_GIB', cityBase: 86, city: 'GIB', country: 'GI', type: 'airport', nlabel: 'gibraltar international gibraltar gibraltar'},
  { icon: 'faPlaneDeparture', label: 'GIL Gilgit, Unknown Region, Gilgit, GIL, gilgit gilgit unknown region', key:'A_GIL', cityBase: 87, city: 'GIL', country: 'ZZ', type: 'airport', nlabel: 'gilgit gilgit unknown region'},
  { icon: 'faPlaneDeparture', label: 'YGX Gillam, Canada, Gillam, YGX, gillam gillam manitoba canada', key:'A_YGX', cityBase: 127, city: 'YGX', country: 'CA', type: 'airport', nlabel: 'gillam gillam manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'GCC Gillette, United States, Gillette–Campbell County, GCC, gillette campbell county gillette wyoming united states', key:'A_GCC', cityBase: 99, city: 'GCC', country: 'US', type: 'airport', nlabel: 'gillette campbell county gillette wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'PUS Busan, South Korea, Gimhae International, PUS, gimhae international busan south korea', key:'A_PUS', cityBase: 23, city: 'PUS', country: 'KR', type: 'airport', nlabel: 'gimhae international busan south korea'},
  { icon: 'faPlaneDeparture', label: 'GMP Seoul, South Korea, Gimpo International, GMP, gimpo international seoul south korea', key:'A_GMP', cityBase: 42, city: 'SEL', country: 'KR', type: 'airport', nlabel: 'gimpo international seoul south korea'},
  { icon: 'faPlaneDeparture', label: 'GRO Girona, Spain, Girona–Costa Brava, GRO, girona costa brava girona spain', key:'A_GRO', cityBase: 13, city: 'GRO', country: 'ES', type: 'airport', nlabel: 'girona costa brava girona spain'},
  { icon: 'faPlaneDeparture', label: 'PNP Popondetta, Papua New Guinea, Girua, PNP, girua popondetta papua new guinea', key:'A_PNP', cityBase: 127, city: 'PNP', country: 'PG', type: 'airport', nlabel: 'girua popondetta papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'GIS Gisborne, New Zealand, Gisborne, GIS, gisborne gisborne new zealand', key:'A_GIS', cityBase: 127, city: 'GIS', country: 'NZ', type: 'airport', nlabel: 'gisborne gisborne new zealand'},
  { icon: 'faPlaneDeparture', label: 'YHK Gjoa Haven, Canada, Gjoa Haven, YHK, gjoa haven gjoa haven nunavut canada', key:'A_YHK', cityBase: 127, city: 'YHK', country: 'CA', type: 'airport', nlabel: 'gjoa haven gjoa haven nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'FCA Kalispell, United States, Glacier Park International, FCA, glacier park international kalispell montana united states', key:'A_FCA', cityBase: 31, city: 'FCA', country: 'US', type: 'airport', nlabel: 'glacier park international kalispell montana united states'},
  { icon: 'faPlaneDeparture', label: 'GLT Gladstone, Australia, Gladstone, GLT, gladstone gladstone queensland australia', key:'A_GLT', cityBase: 127, city: 'GLT', country: 'AU', type: 'airport', nlabel: 'gladstone gladstone queensland australia'},
  { icon: 'faPlaneDeparture', label: 'GGW Glasgow, United States, Glasgow, GGW, glasgow glasgow montana united states', key:'A_GGW', cityBase: 80, city: 'GGW', country: 'US', type: 'airport', nlabel: 'glasgow glasgow montana united states'},
  { icon: 'faPlaneDeparture', label: 'GLA Glasgow, United Kingdom, Glasgow, GLA, glasgow glasgow united kingdom', key:'A_GLA', cityBase: -995, city: 'GLA', country: 'GB', type: 'airport', nlabel: 'glasgow glasgow united kingdom'},
  { icon: 'faPlaneDeparture', label: 'PIK Glasgow, United Kingdom, Glasgow Prestwick, PIK, glasgow prestwick glasgow united kingdom', key:'A_PIK', cityBase: -884, city: 'GLA', country: 'GB', type: 'airport', nlabel: 'glasgow prestwick glasgow united kingdom'},
  { icon: 'faPlaneDeparture', label: 'GOI Goa, India, Goa International, GOI, goa international goa india', key:'A_GOI', cityBase: 38, city: 'GOI', country: 'IN', type: 'airport', nlabel: 'goa international goa india'},
  { icon: 'faPlaneDeparture', label: 'VDM Viedma, Argentina, Gobernador Edgardo Castello, VDM, gobernador edgardo castello viedma rio negro argentina', key:'A_VDM', cityBase: 127, city: 'VDM', country: 'AR', type: 'airport', nlabel: 'gobernador edgardo castello viedma rio negro argentina'},
  { icon: 'faPlaneDeparture', label: 'JUJ San Salvador de Jujuy, Argentina, Gobernador Horacio Guzmán International, JUJ, gobernador horacio guzman international san salvador de jujuy jujuy argentina', key:'A_JUJ', cityBase: 124, city: 'JUJ', country: 'AR', type: 'airport', nlabel: 'gobernador horacio guzman international san salvador de jujuy jujuy argentina'},
  { icon: 'faPlaneDeparture', label: 'GDE Gode, Ethiopia, Gode, GDE, gode gode ethiopia', key:'A_GDE', cityBase: 127, city: 'GDE', country: 'ET', type: 'airport', nlabel: 'gode gode ethiopia'},
  { icon: 'faPlaneDeparture', label: 'MPH Caticlan, Philippines, Godofredo P. Ramos, MPH, godofredo p ramos caticlan philippines', key:'A_MPH', cityBase: 67, city: 'MPH', country: 'PH', type: 'airport', nlabel: 'godofredo p ramos caticlan philippines'},
  { icon: 'faPlaneDeparture', label: 'YGO Gods Lake Narrows, Manitoba, Canada, Gods Lake Narrows, YGO, gods lake narrows gods lake narrows manitoba canada', key:'A_YGO', cityBase: 127, city: 'YGO', country: 'CA', type: 'airport', nlabel: 'gods lake narrows gods lake narrows manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'ZGI Manto Sipi Cree Nation, Canada, Gods River, ZGI, gods river manto sipi cree nation manitoba canada', key:'A_ZGI', cityBase: 127, city: 'ZGI', country: 'CA', type: 'airport', nlabel: 'gods river manto sipi cree nation manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'IWD Ironwood, United States, Gogebic–Iron County, IWD, gogebic iron county ironwood michigan united states', key:'A_IWD', cityBase: 104, city: 'IWD', country: 'US', type: 'airport', nlabel: 'gogebic iron county ironwood michigan united states'},
  { icon: 'faPlaneDeparture', label: 'OOL Gold Coast, Australia, Gold Coast, OOL, gold coast gold coast queensland australia', key:'A_OOL', cityBase: 49, city: 'OOL', country: 'AU', type: 'airport', nlabel: 'gold coast gold coast queensland australia'},
  { icon: 'faPlaneDeparture', label: 'GTR Columbus, United States, Golden Triangle Regional, GTR, golden triangle regional columbus mississippi united states', key:'A_GTR', cityBase: 104, city: 'UBS', country: 'US', type: 'airport', nlabel: 'golden triangle regional columbus mississippi united states'},
  { icon: 'faPlaneDeparture', label: 'GLF Golfito, Costa Rica, Golfito, GLF, golfito golfito costa rica', key:'A_GLF', cityBase: 125, city: 'GLF', country: 'CR', type: 'airport', nlabel: 'golfito golfito costa rica'},
  { icon: 'faPlaneDeparture', label: 'TLU Tolú, Colombia, Golfo de Morrosquillo, TLU, golfo de morrosquillo tolu colombia', key:'A_TLU', cityBase: 127, city: 'TLU', country: 'CO', type: 'airport', nlabel: 'golfo de morrosquillo tolu colombia'},
  { icon: 'faPlaneDeparture', label: 'GOQ Golmud, China, Golmud, GOQ, golmud golmud china', key:'A_GOQ', cityBase: 75, city: 'GOQ', country: 'CN', type: 'airport', nlabel: 'golmud golmud china'},
  { icon: 'faPlaneDeparture', label: 'GMQ Golog Tibetan Autonomous Prefecture, China, Golog Maqin, GMQ, golog maqin golog tibetan autonomous prefecture china', key:'A_GMQ', cityBase: 127, city: 'GMQ', country: 'CN', type: 'airport', nlabel: 'golog maqin golog tibetan autonomous prefecture china'},
  { icon: 'faPlaneDeparture', label: 'LCE La Ceiba, Honduras, Golosón International, LCE, goloson international la ceiba honduras', key:'A_LCE', cityBase: 127, city: 'LCE', country: 'HN', type: 'airport', nlabel: 'goloson international la ceiba honduras'},
  { icon: 'faPlaneDeparture', label: 'GLV Golovin, United States, Golovin, GLV, golovin golovin alaska united states', key:'A_GLV', cityBase: 127, city: 'GLV', country: 'US', type: 'airport', nlabel: 'golovin golovin alaska united states'},
  { icon: 'faPlaneDeparture', label: 'GOM Goma, Democratic Republic of the Congo, Goma International, GOM, goma international goma congo kinshasa', key:'A_GOM', cityBase: 127, city: 'GOM', country: 'CD', type: 'airport', nlabel: 'goma international goma congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'GMO Gombe, Nigeria, Gombe Lawanti International, GMO, gombe lawanti international gombe nigeria', key:'A_GMO', cityBase: 127, city: 'GMO', country: 'NG', type: 'airport', nlabel: 'gombe lawanti international gombe nigeria'},
  { icon: 'faPlaneDeparture', label: 'GME Gomel, Belarus, Gomel, GME, gomel gomel belarus', key:'A_GME', cityBase: 127, city: 'GME', country: 'BY', type: 'airport', nlabel: 'gomel gomel belarus'},
  { icon: 'faPlaneDeparture', label: 'GDQ Gondar, Ethiopia, Gondar, GDQ, gondar gondar ethiopia', key:'A_GDQ', cityBase: 127, city: 'GDQ', country: 'ET', type: 'airport', nlabel: 'gondar gondar ethiopia'},
  { icon: 'faPlaneDeparture', label: 'GNU Goodnews Bay, United States, Goodnews, GNU, goodnews goodnews bay alaska united states', key:'A_GNU', cityBase: 127, city: 'GNU', country: 'US', type: 'airport', nlabel: 'goodnews goodnews bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'GBT Gorgan, Iran, Gorgan, GBT, gorgan gorgan iran', key:'A_GBT', cityBase: 127, city: 'GBT', country: 'IR', type: 'airport', nlabel: 'gorgan gorgan iran'},
  { icon: 'faPlaneDeparture', label: 'RGK Gorno-Altaysk, Russia, Gorno-Altaysk, RGK, gorno altaysk gorno altaysk russia', key:'A_RGK', cityBase: 53, city: 'RGK', country: 'RU', type: 'airport', nlabel: 'gorno altaysk gorno altaysk russia'},
  { icon: 'faPlaneDeparture', label: 'GKA Goroka, Papua New Guinea, Goroka, GKA, goroka goroka papua new guinea', key:'A_GKA', cityBase: 127, city: 'GKA', country: 'PG', type: 'airport', nlabel: 'goroka goroka papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'GOT Gothenburg, Sweden, Göteborg Landvetter, GOT, goteborg landvetter gothenburg sweden', key:'A_GOT', cityBase: 36, city: 'GOT', country: 'SE', type: 'airport', nlabel: 'goteborg landvetter gothenburg sweden'},
  { icon: 'faPlaneDeparture', label: 'GSE Gothenburg, Sweden, Gothenburg City Airport, GSE, gothenburg city airport gothenburg sweden', key:'A_GSE', cityBase: 63, city: 'GOT', country: 'SE', type: 'airport', nlabel: 'gothenburg city airport gothenburg sweden'},
  { icon: 'faPlaneDeparture', label: 'GOV Nhulunbuy, Australia, Gove, GOV, gove nhulunbuy northern territory australia', key:'A_GOV', cityBase: 127, city: 'GOV', country: 'AU', type: 'airport', nlabel: 'gove nhulunbuy northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'PVH Porto Velho, Brazil, Governador Jorge Teixeira de Oliveira International, PVH, governador jorge teixeira de oliveira international porto velho state of rondonia brazil', key:'A_PVH', cityBase: 127, city: 'PVH', country: 'BR', type: 'airport', nlabel: 'governador jorge teixeira de oliveira international porto velho state of rondonia brazil'},
  { icon: 'faPlaneDeparture', label: 'GVR Governador Valadares, Brazil, Governador Valadares, GVR, governador valadares governador valadares state of minas gerais brazil', key:'A_GVR', cityBase: 119, city: 'GVR', country: 'BR', type: 'airport', nlabel: 'governador valadares governador valadares state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'MDZ Mendoza, Argentina, Governor Francisco Gabrielli International, MDZ, governor francisco gabrielli international mendoza mendoza argentina', key:'A_MDZ', cityBase: 51, city: 'MDZ', country: 'AR', type: 'airport', nlabel: 'governor francisco gabrielli international mendoza mendoza argentina'},
  { icon: 'faPlaneDeparture', label: 'GHB Governor\'s Harbour, Bahamas, Governor\'s Harbour, GHB, governor s harbour governor s harbour bahamas', key:'A_GHB', cityBase: 101, city: 'GHB', country: 'BS', type: 'airport', nlabel: 'governor s harbour governor s harbour bahamas'},
  { icon: 'faPlaneDeparture', label: 'GRW Graciosa, Portugal, Graciosa, GRW, graciosa graciosa portugal', key:'A_GRW', cityBase: 127, city: 'GRW', country: 'PT', type: 'airport', nlabel: 'graciosa graciosa portugal'},
  { icon: 'faPlaneDeparture', label: 'LPA Las Palmas, Spain, Gran Canaria, LPA, gran canaria las palmas spain', key:'A_LPA', cityBase: 7, city: 'LPA', country: 'ES', type: 'airport', nlabel: 'gran canaria las palmas spain'},
  { icon: 'faPlaneDeparture', label: 'FPO Freeport, Bahamas, Grand Bahama International, FPO, grand bahama international freeport bahamas', key:'A_FPO', cityBase: 37, city: 'FPO', country: 'BS', type: 'airport', nlabel: 'grand bahama international freeport bahamas'},
  { icon: 'faPlaneDeparture', label: 'GCN Grand Canyon Village, United States, Grand Canyon National Park, GCN, grand canyon national park grand canyon village arizona united states', key:'A_GCN', cityBase: 100, city: 'GCN', country: 'US', type: 'airport', nlabel: 'grand canyon national park grand canyon village arizona united states'},
  { icon: 'faPlaneDeparture', label: 'GCW Grand Canyon West, Arizona, United States, Grand Canyon West, GCW, grand canyon west grand canyon west arizona united states', key:'A_GCW', cityBase: 127, city: 'GCW', country: 'US', type: 'airport', nlabel: 'grand canyon west grand canyon west arizona united states'},
  { icon: 'faPlaneDeparture', label: 'SFG Saint Martin (Island), St. Martin, Grand Case-Espérance, SFG, grand case esperance saint martin (island) st martin', key:'A_SFG', cityBase: 127, city: 'SFG', country: 'MF', type: 'airport', nlabel: 'grand case esperance saint martin (island) st martin'},
  { icon: 'faPlaneDeparture', label: 'GFK Grand Forks, United States, Grand Forks International, GFK, grand forks international grand forks north dakota united states', key:'A_GFK', cityBase: 87, city: 'GFK', country: 'US', type: 'airport', nlabel: 'grand forks international grand forks north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'GJT Grand Junction, United States, Grand Junction Regional, GJT, grand junction regional grand junction colorado united states', key:'A_GJT', cityBase: 51, city: 'GJT', country: 'US', type: 'airport', nlabel: 'grand junction regional grand junction colorado united states'},
  { icon: 'faPlaneDeparture', label: 'YQU Grande Prairie, Canada, Grande Prairie, YQU, grande prairie grande prairie alberta canada', key:'A_YQU', cityBase: 127, city: 'YQU', country: 'CA', type: 'airport', nlabel: 'grande prairie grande prairie alberta canada'},
  { icon: 'faPlaneDeparture', label: 'BGI Bridgetown, Barbados, Grantley Adams International, BGI, grantley adams international bridgetown barbados', key:'A_BGI', cityBase: 31, city: 'BGI', country: 'BB', type: 'airport', nlabel: 'grantley adams international bridgetown barbados'},
  { icon: 'faPlaneDeparture', label: 'KGX Grayling, United States, Grayling, KGX, grayling grayling alaska united states', key:'A_KGX', cityBase: 127, city: 'KGX', country: 'US', type: 'airport', nlabel: 'grayling grayling alaska united states'},
  { icon: 'faPlaneDeparture', label: 'GRZ Graz, Austria, Graz, GRZ, graz graz austria', key:'A_GRZ', cityBase: 4, city: 'GRZ', country: 'AT', type: 'airport', nlabel: 'graz graz austria'},
  { icon: 'faPlaneDeparture', label: 'HTI Hamilton Island, Australia, Great Barrier Reef, HTI, great barrier reef hamilton island queensland australia', key:'A_HTI', cityBase: 69, city: 'HTI', country: 'AU', type: 'airport', nlabel: 'great barrier reef hamilton island queensland australia'},
  { icon: 'faPlaneDeparture', label: 'GTF Great Falls, United States, Great Falls International, GTF, great falls international great falls montana united states', key:'A_GTF', cityBase: 61, city: 'GTF', country: 'US', type: 'airport', nlabel: 'great falls international great falls montana united states'},
  { icon: 'faPlaneDeparture', label: 'BGM Binghamton, United States, Greater Binghamton, BGM, greater binghamton binghamton new york united states', key:'A_BGM', cityBase: 93, city: 'BGM', country: 'US', type: 'airport', nlabel: 'greater binghamton binghamton new york united states'},
  { icon: 'faPlaneDeparture', label: 'YQM Moncton, Canada, Greater Moncton Roméo LeBlanc International, YQM, greater moncton romeo leblanc international moncton new brunswick canada', key:'A_YQM', cityBase: 79, city: 'YQM', country: 'CA', type: 'airport', nlabel: 'greater moncton romeo leblanc international moncton new brunswick canada'},
  { icon: 'faPlaneDeparture', label: 'ROC Rochester, United States, Greater Rochester International, ROC, greater rochester international rochester new york united states', key:'A_ROC', cityBase: 15, city: 'ROC', country: 'US', type: 'airport', nlabel: 'greater rochester international rochester new york united states'},
  { icon: 'faPlaneDeparture', label: 'GRB Green Bay, United States, Green Bay–Austin Straubel International, GRB, green bay austin straubel international green bay wisconsin united states', key:'A_GRB', cityBase: 35, city: 'GRB', country: 'US', type: 'airport', nlabel: 'green bay austin straubel international green bay wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'LWB Lewisburg, United States, Greenbrier Valley, LWB, greenbrier valley lewisburg west virginia united states', key:'A_LWB', cityBase: 117, city: 'LWB', country: 'US', type: 'airport', nlabel: 'greenbrier valley lewisburg west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'GSP Greenville, United States, Greenville–Spartanburg International, GSP, greenville spartanburg international greenville south carolina united states', key:'A_GSP', cityBase: 18, city: 'GSP', country: 'US', type: 'airport', nlabel: 'greenville spartanburg international greenville south carolina united states'},
  { icon: 'faPlaneDeparture', label: 'POP Puerto Plata, Dominican Republic, Gregorio Luperón International, POP, gregorio luperon international puerto plata dominican republic', key:'A_POP', cityBase: 33, city: 'POP', country: 'DO', type: 'airport', nlabel: 'gregorio luperon international puerto plata dominican republic'},
  { icon: 'faPlaneDeparture', label: 'GFF Griffith, Australia, Griffith, GFF, griffith griffith new south wales australia', key:'A_GFF', cityBase: 127, city: 'GFF', country: 'AU', type: 'airport', nlabel: 'griffith griffith new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'YGZ Grise Fiord, Canada, Grise Fiord, YGZ, grise fiord grise fiord nunavut canada', key:'A_YGZ', cityBase: 127, city: 'YGZ', country: 'CA', type: 'airport', nlabel: 'grise fiord grise fiord nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'GRQ Groningen, Netherlands, Groningen Airport Eelde, GRQ, groningen airport eelde groningen netherlands', key:'A_GRQ', cityBase: 93, city: 'GRQ', country: 'NL', type: 'airport', nlabel: 'groningen airport eelde groningen netherlands'},
  { icon: 'faPlaneDeparture', label: 'GTE Groote Eylandt, Australia, Groote Eylandt, GTE, groote eylandt groote eylandt northern territory australia', key:'A_GTE', cityBase: 127, city: 'GTE', country: 'AU', type: 'airport', nlabel: 'groote eylandt groote eylandt northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'GRS Grosseto, Italy, Grosseto Air Base, GRS, grosseto air base grosseto italy', key:'A_GRS', cityBase: 127, city: 'GRS', country: 'IT', type: 'airport', nlabel: 'grosseto air base grosseto italy'},
  { icon: 'faPlaneDeparture', label: 'GRV Grozny, Russia, Grozny, GRV, grozny grozny russia', key:'A_GRV', cityBase: 48, city: 'GRV', country: 'RU', type: 'airport', nlabel: 'grozny grozny russia'},
  { icon: 'faPlaneDeparture', label: 'GDL Guadalajara, Mexico, Guadalajara International, GDL, guadalajara international guadalajara mexico', key:'A_GDL', cityBase: 8, city: 'GDL', country: 'MX', type: 'airport', nlabel: 'guadalajara international guadalajara mexico'},
  { icon: 'faPlaneDeparture', label: 'GYS Guangyuan, China, Guangyuan Panlong, GYS, guangyuan panlong guangyuan china', key:'A_GYS', cityBase: 68, city: 'GYS', country: 'CN', type: 'airport', nlabel: 'guangyuan panlong guangyuan china'},
  { icon: 'faPlaneDeparture', label: 'CAN Guangzhou, China, Guangzhou Baiyun International, CAN, guangzhou baiyun international guangzhou china', key:'A_CAN', cityBase: 7, city: 'CAN', country: 'CN', type: 'airport', nlabel: 'guangzhou baiyun international guangzhou china'},
  { icon: 'faPlaneDeparture', label: 'GPI Guapi, Colombia, Guapi, GPI, guapi guapi colombia', key:'A_GPI', cityBase: 127, city: 'GPI', country: 'CO', type: 'airport', nlabel: 'guapi guapi colombia'},
  { icon: 'faPlaneDeparture', label: 'AGT Ciudad del Este, Paraguay, Guaraní International, AGT, guarani international ciudad del este paraguay', key:'A_AGT', cityBase: 127, city: 'AGT', country: 'PY', type: 'airport', nlabel: 'guarani international ciudad del este paraguay'},
  { icon: 'faPlaneDeparture', label: 'WPU Puerto Williams, Chile, Guardiamarina Zañartu, WPU, guardiamarina zanartu puerto williams chile', key:'A_WPU', cityBase: 127, city: 'WPU', country: 'CL', type: 'airport', nlabel: 'guardiamarina zanartu puerto williams chile'},
  { icon: 'faPlaneDeparture', label: 'GYA Guayaramerín, Bolivia, Guayaramerín, GYA, guayaramerin guayaramerin bolivia', key:'A_GYA', cityBase: 127, city: 'GYA', country: 'BO', type: 'airport', nlabel: 'guayaramerin guayaramerin bolivia'},
  { icon: 'faPlaneDeparture', label: 'GLN Guelmim, Morocco, Guelmim, GLN, guelmim guelmim morocco', key:'A_GLN', cityBase: 127, city: 'GLN', country: 'MA', type: 'airport', nlabel: 'guelmim guelmim morocco'},
  { icon: 'faPlaneDeparture', label: 'ELU El Oued, Algeria, Guemar, ELU, guemar el oued algeria', key:'A_ELU', cityBase: 127, city: 'ELU', country: 'DZ', type: 'airport', nlabel: 'guemar el oued algeria'},
  { icon: 'faPlaneDeparture', label: 'GCI Saint Peter Port, Guernsey, Guernsey, GCI, guernsey saint peter port guernsey', key:'A_GCI', cityBase: 47, city: 'GCI', country: 'GG', type: 'airport', nlabel: 'guernsey saint peter port guernsey'},
  { icon: 'faPlaneDeparture', label: 'KWL Guilin, China, Guilin Liangjiang International, KWL, guilin liangjiang international guilin china', key:'A_KWL', cityBase: 52, city: 'KWL', country: 'CN', type: 'airport', nlabel: 'guilin liangjiang international guilin china'},
  { icon: 'faPlaneDeparture', label: 'PPN Popayán, Colombia, Guillermo León Valencia, PPN, guillermo leon valencia popayan colombia', key:'A_PPN', cityBase: 127, city: 'PPN', country: 'CO', type: 'airport', nlabel: 'guillermo leon valencia popayan colombia'},
  { icon: 'faPlaneDeparture', label: 'KWE Guiyang, China, Guiyang Longdongbao International, KWE, guiyang longdongbao international guiyang china', key:'A_KWE', cityBase: 52, city: 'KWE', country: 'CN', type: 'airport', nlabel: 'guiyang longdongbao international guiyang china'},
  { icon: 'faPlaneDeparture', label: 'GPT Gulfport, United States, Gulfport–Biloxi International, GPT, gulfport biloxi international gulfport mississippi united states', key:'A_GPT', cityBase: 35, city: 'GPT', country: 'US', type: 'airport', nlabel: 'gulfport biloxi international gulfport mississippi united states'},
  { icon: 'faPlaneDeparture', label: 'GUC Gunnison, United States, Gunnison–Crested Butte Regional, GUC, gunnison crested butte regional gunnison colorado united states', key:'A_GUC', cityBase: 98, city: 'GUC', country: 'US', type: 'airport', nlabel: 'gunnison crested butte regional gunnison colorado united states'},
  { icon: 'faPlaneDeparture', label: 'KUV Gunsan, South Korea, Gunsan, KUV, gunsan gunsan south korea', key:'A_KUV', cityBase: 127, city: 'KUV', country: 'KR', type: 'airport', nlabel: 'gunsan gunsan south korea'},
  { icon: 'faPlaneDeparture', label: 'URY Qurayyat, Saudi Arabia, Gurayat Domestic, URY, gurayat domestic qurayyat saudi arabia', key:'A_URY', cityBase: 113, city: 'URY', country: 'SA', type: 'airport', nlabel: 'gurayat domestic qurayyat saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'GUO Guri el, Somalia, Guriel, GUO, guriel guri el somalia', key:'A_GUO', cityBase: 127, city: 'GUO', country: 'SO', type: 'airport', nlabel: 'guriel guri el somalia'},
  { icon: 'faPlaneDeparture', label: 'GUR Alotau, Papua New Guinea, Gurney, GUR, gurney alotau papua new guinea', key:'A_GUR', cityBase: 126, city: 'GUR', country: 'PG', type: 'airport', nlabel: 'gurney alotau papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'SBH Saint Barthélemy, St. Barthélemy, Gustaf III, SBH, gustaf iii saint barthelemy st barthelemy', key:'A_SBH', cityBase: 81, city: 'SBH', country: 'BL', type: 'airport', nlabel: 'gustaf iii saint barthelemy st barthelemy'},
  { icon: 'faPlaneDeparture', label: 'FLA Florencia, Colombia, Gustavo Artunduaga Paredes, FLA, gustavo artunduaga paredes florencia colombia', key:'A_FLA', cityBase: 105, city: 'FLA', country: 'CO', type: 'airport', nlabel: 'gustavo artunduaga paredes florencia colombia'},
  { icon: 'faPlaneDeparture', label: 'BCA Baracoa, Cuba, Gustavo Rizo, BCA, gustavo rizo baracoa cuba', key:'A_BCA', cityBase: 127, city: 'BCA', country: 'CU', type: 'airport', nlabel: 'gustavo rizo baracoa cuba'},
  { icon: 'faPlaneDeparture', label: 'ADZ San Andrés, Colombia, Gustavo Rojas Pinilla International, ADZ, gustavo rojas pinilla international san andres colombia', key:'A_ADZ', cityBase: 41, city: 'ADZ', country: 'CO', type: 'airport', nlabel: 'gustavo rojas pinilla international san andres colombia'},
  { icon: 'faPlaneDeparture', label: 'GST Gustavus, United States, Gustavus, GST, gustavus gustavus alaska united states', key:'A_GST', cityBase: 119, city: 'GST', country: 'US', type: 'airport', nlabel: 'gustavus gustavus alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KBU Kotabaru Regency, Indonesia, Gusti Syamsir Alam, KBU, gusti syamsir alam kotabaru regency indonesia', key:'A_KBU', cityBase: 127, city: 'KBU', country: 'ID', type: 'airport', nlabel: 'gusti syamsir alam kotabaru regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'GYU Guyuan County, China, Guyuan Liupanshan, GYU, guyuan liupanshan guyuan county china', key:'A_GYU', cityBase: 70, city: 'GYU', country: 'CN', type: 'airport', nlabel: 'guyuan liupanshan guyuan county china'},
  { icon: 'faPlaneDeparture', label: 'GWD Gwadar, Pakistan, Gwadar International, GWD, gwadar international gwadar pakistan', key:'A_GWD', cityBase: 127, city: 'GWD', country: 'PK', type: 'airport', nlabel: 'gwadar international gwadar pakistan'},
  { icon: 'faPlaneDeparture', label: 'GWL Gwalior, India, Gwalior, GWL, gwalior gwalior india', key:'A_GWL', cityBase: 127, city: 'GWL', country: 'IN', type: 'airport', nlabel: 'gwalior gwalior india'},
  { icon: 'faPlaneDeparture', label: 'KWJ Gwangju, South Korea, Gwangju, KWJ, gwangju gwangju south korea', key:'A_KWJ', cityBase: 112, city: 'KWJ', country: 'KR', type: 'airport', nlabel: 'gwangju gwangju south korea'},
  { icon: 'faPlaneDeparture', label: 'SMQ Sampit, Indonesia, H. Asan, SMQ, h asan sampit indonesia', key:'A_SMQ', cityBase: 127, city: 'SMQ', country: 'ID', type: 'airport', nlabel: 'h asan sampit indonesia'},
  { icon: 'faPlaneDeparture', label: 'ENE Ende, Indonesia, H. Hasan Aroeboesman, ENE, h hasan aroeboesman ende indonesia', key:'A_ENE', cityBase: 127, city: 'ENE', country: 'ID', type: 'airport', nlabel: 'h hasan aroeboesman ende indonesia'},
  { icon: 'faPlaneDeparture', label: 'TJQ Tanjung Pandan, Indonesia, H.A.S. Hanandjoeddin International, TJQ, h a s hanandjoeddin international tanjung pandan indonesia', key:'A_TJQ', cityBase: 113, city: 'TJQ', country: 'ID', type: 'airport', nlabel: 'h a s hanandjoeddin international tanjung pandan indonesia'},
  { icon: 'faPlaneDeparture', label: 'HAS Ha il, Saudi Arabia, Ha il Regional, HAS, ha il regional ha il saudi arabia', key:'A_HAS', cityBase: 88, city: 'HAS', country: 'SA', type: 'airport', nlabel: 'ha il regional ha il saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'HAC Hachijō-jima, Japan, Hachijojima, HAC, hachijojima hachijo jima japan', key:'A_HAC', cityBase: 127, city: 'HAC', country: 'JP', type: 'airport', nlabel: 'hachijojima hachijo jima japan'},
  { icon: 'faPlaneDeparture', label: 'HGR Hagerstown, United States, Hagerstown Regional, HGR, hagerstown regional hagerstown maryland united states', key:'A_HGR', cityBase: 45, city: 'HGR', country: 'US', type: 'airport', nlabel: 'hagerstown regional hagerstown maryland united states'},
  { icon: 'faPlaneDeparture', label: 'HFA Haifa, Israel, Haifa, HFA, haifa haifa israel', key:'A_HFA', cityBase: 127, city: 'HFA', country: 'IL', type: 'airport', nlabel: 'haifa haifa israel'},
  { icon: 'faPlaneDeparture', label: 'HAK Haikou, China, Haikou Meilan International, HAK, haikou meilan international haikou china', key:'A_HAK', cityBase: 43, city: 'HAK', country: 'CN', type: 'airport', nlabel: 'haikou meilan international haikou china'},
  { icon: 'faPlaneDeparture', label: 'HNS Haines, United States, Haines, HNS, haines haines alaska united states', key:'A_HNS', cityBase: 127, city: 'HNS', country: 'US', type: 'airport', nlabel: 'haines haines alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YKO Hakkâri, Turkey, Hakkari–Yüksekova, YKO, hakkari yuksekova hakkari turkey', key:'A_YKO', cityBase: 32, city: 'YKO', country: 'TR', type: 'airport', nlabel: 'hakkari yuksekova hakkari turkey'},
  { icon: 'faPlaneDeparture', label: 'HKD Hakodate, Japan, Hakodate, HKD, hakodate hakodate japan', key:'A_HKD', cityBase: 101, city: 'HKD', country: 'JP', type: 'airport', nlabel: 'hakodate hakodate japan'},
  { icon: 'faPlaneDeparture', label: 'YHZ Halifax, Canada, Halifax Stanfield International, YHZ, halifax stanfield international halifax nova scotia canada', key:'A_YHZ', cityBase: 34, city: 'YHZ', country: 'CA', type: 'airport', nlabel: 'halifax stanfield international halifax nova scotia canada'},
  { icon: 'faPlaneDeparture', label: 'HLP Jakarta, Indonesia, Halim Perdanakusuma International, HLP, halim perdanakusuma international jakarta indonesia', key:'A_HLP', cityBase: 90, city: 'JKT', country: 'ID', type: 'airport', nlabel: 'halim perdanakusuma international jakarta indonesia'},
  { icon: 'faPlaneDeparture', label: 'ABU Atambua, Indonesia, Haliwen, ABU, haliwen atambua indonesia', key:'A_ABU', cityBase: 127, city: 'ABU', country: 'ID', type: 'airport', nlabel: 'haliwen atambua indonesia'},
  { icon: 'faPlaneDeparture', label: 'YUX Hall Beach, Canada, Hall Beach, YUX, hall beach hall beach nunavut canada', key:'A_YUX', cityBase: 127, city: 'YUX', country: 'CA', type: 'airport', nlabel: 'hall beach hall beach nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'HAD Halmstad, Sweden, Halmstad, HAD, halmstad halmstad sweden', key:'A_HAD', cityBase: 127, city: 'HAD', country: 'SE', type: 'airport', nlabel: 'halmstad halmstad sweden'},
  { icon: 'faPlaneDeparture', label: 'KDI Kendari, Indonesia, Haluoleo, KDI, haluoleo kendari indonesia', key:'A_KDI', cityBase: 118, city: 'KDI', country: 'ID', type: 'airport', nlabel: 'haluoleo kendari indonesia'},
  { icon: 'faPlaneDeparture', label: 'DOH Doha, Qatar, Hamad International, DOH, hamad international doha qatar', key:'A_DOH', cityBase: 20, city: 'DOH', country: 'QA', type: 'airport', nlabel: 'hamad international doha qatar'},
  { icon: 'faPlaneDeparture', label: 'HDM Hamadan, Iran, Hamadan International, HDM, hamadan international hamadan iran', key:'A_HDM', cityBase: 127, city: 'HDM', country: 'IR', type: 'airport', nlabel: 'hamadan international hamadan iran'},
  { icon: 'faPlaneDeparture', label: 'HAM Hamburg, Germany, Hamburg, HAM, hamburg hamburg germany', key:'A_HAM', cityBase: 3, city: 'HAM', country: 'DE', type: 'airport', nlabel: 'hamburg hamburg germany'},
  { icon: 'faPlaneDeparture', label: 'HMI Hami, China, Hami, HMI, hami hami china', key:'A_HMI', cityBase: 68, city: 'HMI', country: 'CN', type: 'airport', nlabel: 'hami hami china'},
  { icon: 'faPlaneDeparture', label: 'KBL Kabul, Afghanistan, Hamid Karzai International, KBL, hamid karzai international kabul afghanistan', key:'A_KBL', cityBase: 46, city: 'KBL', country: 'AF', type: 'airport', nlabel: 'hamid karzai international kabul afghanistan'},
  { icon: 'faPlaneDeparture', label: 'HLZ Hamilton, New Zealand, Hamilton, HLZ, hamilton hamilton new zealand', key:'A_HLZ', cityBase: 93, city: 'HLZ', country: 'NZ', type: 'airport', nlabel: 'hamilton hamilton new zealand'},
  { icon: 'faPlaneDeparture', label: 'HFT Hammerfest, Norway, Hammerfest, HFT, hammerfest hammerfest norway', key:'A_HFT', cityBase: 127, city: 'HFT', country: 'NO', type: 'airport', nlabel: 'hammerfest hammerfest norway'},
  { icon: 'faPlaneDeparture', label: 'HNM Hana, United States, Hana, HNM, hana hana hawaii united states', key:'A_HNM', cityBase: 127, city: 'HNM', country: 'US', type: 'airport', nlabel: 'hana hana hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'HNA Hanamaki, Japan, Hanamaki, HNA, hanamaki hanamaki japan', key:'A_HNA', cityBase: 127, city: 'HNA', country: 'JP', type: 'airport', nlabel: 'hanamaki hanamaki japan'},
  { icon: 'faPlaneDeparture', label: 'BHB Bar Harbor, United States, Hancock County-Bar Harbor, BHB, hancock county bar harbor bar harbor maine united states', key:'A_BHB', cityBase: 86, city: 'BHB', country: 'US', type: 'airport', nlabel: 'hancock county bar harbor bar harbor maine united states'},
  { icon: 'faPlaneDeparture', label: 'HDG Handan, China, Handan, HDG, handan handan china', key:'A_HDG', cityBase: 73, city: 'HDG', country: 'CN', type: 'airport', nlabel: 'handan handan china'},
  { icon: 'faPlaneDeparture', label: 'HND Tokyo, Japan, Haneda, HND, haneda tokyo japan', key:'A_HND', cityBase: 28, city: 'TYO', country: 'JP', type: 'airport', nlabel: 'haneda tokyo japan'},
  { icon: 'faPlaneDeparture', label: 'BTH Batam, Indonesia, Hang Nadim, BTH, hang nadim batam indonesia', key:'A_BTH', cityBase: 84, city: 'BTH', country: 'ID', type: 'airport', nlabel: 'hang nadim batam indonesia'},
  { icon: 'faPlaneDeparture', label: 'HGH Hangzhou, China, Hangzhou Xiaoshan International, HGH, hangzhou xiaoshan international hangzhou china', key:'A_HGH', cityBase: 28, city: 'HGH', country: 'CN', type: 'airport', nlabel: 'hangzhou xiaoshan international hangzhou china'},
  { icon: 'faPlaneDeparture', label: 'HAJ Hanover, Germany, Hannover, HAJ, hannover hanover germany', key:'A_HAJ', cityBase: 4, city: 'HAJ', country: 'DE', type: 'airport', nlabel: 'hannover hanover germany'},
  { icon: 'faPlaneDeparture', label: 'HZG Hanzhong, China, Hanzhong Xiguan, HZG, hanzhong xiguan hanzhong china', key:'A_HZG', cityBase: 70, city: 'HZG', country: 'CN', type: 'airport', nlabel: 'hanzhong xiguan hanzhong china'},
  { icon: 'faPlaneDeparture', label: 'HOI Hao, French Polynesia, Hao, HOI, hao hao french polynesia', key:'A_HOI', cityBase: 127, city: 'HOI', country: 'PF', type: 'airport', nlabel: 'hao hao french polynesia'},
  { icon: 'faPlaneDeparture', label: 'HRE Harare, Zimbabwe, Harare International, HRE, harare international harare zimbabwe', key:'A_HRE', cityBase: 48, city: 'HRE', country: 'ZW', type: 'airport', nlabel: 'harare international harare zimbabwe'},
  { icon: 'faPlaneDeparture', label: 'HRB Harbin, China, Harbin Taiping International, HRB, harbin taiping international harbin china', key:'A_HRB', cityBase: 46, city: 'HRB', country: 'CN', type: 'airport', nlabel: 'harbin taiping international harbin china'},
  { icon: 'faPlaneDeparture', label: 'HGA Hargeisa, Somalia, Hargeisa, HGA, hargeisa hargeisa somalia', key:'A_HGA', cityBase: 65, city: 'HGA', country: 'SO', type: 'airport', nlabel: 'hargeisa hargeisa somalia'},
  { icon: 'faPlaneDeparture', label: 'MDT Harrisburg, United States, Harrisburg International, MDT, harrisburg international harrisburg pennsylvania united states', key:'A_MDT', cityBase: 28, city: 'HAR', country: 'US', type: 'airport', nlabel: 'harrisburg international harrisburg pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'LVI Livingstone, Zambia, Harry Mwanga Nkumbula International, LVI, harry mwanga nkumbula international livingstone zambia', key:'A_LVI', cityBase: 66, city: 'LVI', country: 'ZM', type: 'airport', nlabel: 'harry mwanga nkumbula international livingstone zambia'},
  { icon: 'faPlaneDeparture', label: 'EVE Narvik, Norway, Harstad/Narvik Airport, Evenes, EVE, harstad narvik airport narvik norway', key:'A_EVE', cityBase: 99, city: 'EVE', country: 'NO', type: 'airport', nlabel: 'harstad narvik airport narvik norway'},
  { icon: 'faPlaneDeparture', label: 'ATL Atlanta, United States, Hartsfield–Jackson Atlanta International, ATL, hartsfield jackson atlanta international atlanta georgia united states', key:'A_ATL', cityBase: 0, city: 'ATL', country: 'US', type: 'airport', nlabel: 'hartsfield jackson atlanta international atlanta georgia united states'},
  { icon: 'faPlaneDeparture', label: 'EUN Laayoune, Unknown Region, Hassan I, EUN, hassan i laayoune unknown region', key:'A_EUN', cityBase: 89, city: 'EUN', country: 'ZZ', type: 'airport', nlabel: 'hassan i laayoune unknown region'},
  { icon: 'faPlaneDeparture', label: 'HAA Hasvik, Norway, Hasvik, HAA, hasvik hasvik norway', key:'A_HAA', cityBase: 127, city: 'HAA', country: 'NO', type: 'airport', nlabel: 'hasvik hasvik norway'},
  { icon: 'faPlaneDeparture', label: 'HDY Hat Yai, Thailand, Hat Yai International, HDY, hat yai international hat yai thailand', key:'A_HDY', cityBase: 40, city: 'HDY', country: 'TH', type: 'airport', nlabel: 'hat yai international hat yai thailand'},
  { icon: 'faPlaneDeparture', label: 'HTY Hatay Province, Turkey, Hatay, HTY, hatay hatay province turkey', key:'A_HTY', cityBase: 17, city: 'HTY', country: 'TR', type: 'airport', nlabel: 'hatay hatay province turkey'},
  { icon: 'faPlaneDeparture', label: 'PIB Laurel, United States, Hattiesburg–Laurel Regional, PIB, hattiesburg laurel regional laurel mississippi united states', key:'A_PIB', cityBase: 113, city: 'LUL', country: 'US', type: 'airport', nlabel: 'hattiesburg laurel regional laurel mississippi united states'},
  { icon: 'faPlaneDeparture', label: 'NUF Hatton, Sri Lanka, Hatton Castlereigh Resrvr SPB, NUF, hatton castlereigh resrvr spb hatton sri lanka', key:'A_NUF', cityBase: 127, city: 'NUF', country: 'LK', type: 'airport', nlabel: 'hatton castlereigh resrvr spb hatton sri lanka'},
  { icon: 'faPlaneDeparture', label: 'HAU Haugesund, Norway, Haugesund Airport, Karmøy, HAU, haugesund airport haugesund norway', key:'A_HAU', cityBase: 83, city: 'HAU', country: 'NO', type: 'airport', nlabel: 'haugesund airport haugesund norway'},
  { icon: 'faPlaneDeparture', label: 'HVR Havre, United States, Havre City–County, HVR, havre city county havre montana united states', key:'A_HVR', cityBase: 127, city: 'HVR', country: 'US', type: 'airport', nlabel: 'havre city county havre montana united states'},
  { icon: 'faPlaneDeparture', label: 'VIN Vinnytsia, Ukraine, Havryshivka Vinnytsia International, VIN, havryshivka vinnytsia international vinnytsia ukraine', key:'A_VIN', cityBase: 104, city: 'VIN', country: 'UA', type: 'airport', nlabel: 'havryshivka vinnytsia international vinnytsia ukraine'},
  { icon: 'faPlaneDeparture', label: 'NPE Napier, New Zealand, Hawke\'s Bay, NPE, hawke s bay napier new zealand', key:'A_NPE', cityBase: 115, city: 'NPE', country: 'NZ', type: 'airport', nlabel: 'hawke s bay napier new zealand'},
  { icon: 'faPlaneDeparture', label: 'YHY Hay River, Canada, Hay River/Merlyn Carter, YHY, hay river merlyn carter hay river northwest territories canada', key:'A_YHY', cityBase: 127, city: 'YHY', country: 'CA', type: 'airport', nlabel: 'hay river merlyn carter hay river northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'HIS Hayman Island, Australia, Hayman Island Heliport, HIS, hayman island heliport hayman island queensland australia', key:'A_HIS', cityBase: 127, city: 'HIS', country: 'AU', type: 'airport', nlabel: 'hayman island heliport hayman island queensland australia'},
  { icon: 'faPlaneDeparture', label: 'HYS Hays, United States, Hays Regional, HYS, hays regional hays kansas united states', key:'A_HYS', cityBase: 110, city: 'HYS', country: 'US', type: 'airport', nlabel: 'hays regional hays kansas united states'},
  { icon: 'faPlaneDeparture', label: 'HKB Healy Lake, United States, Healy River, HKB, healy river healy lake alaska united states', key:'A_HKB', cityBase: 127, city: 'HKB', country: 'US', type: 'airport', nlabel: 'healy river healy lake alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LHR London, United Kingdom, Heathrow, LHR, heathrow london united kingdom', key:'A_LHR', cityBase: -999, city: 'LON', country: 'GB', type: 'airport', nlabel: 'heathrow london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'FAR Fargo, United States, Hector International, FAR, hector international fargo north dakota united states', key:'A_FAR', cityBase: 42, city: 'FAR', country: 'US', type: 'airport', nlabel: 'hector international fargo north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'BCV Belize City, Belize, Hector Silva Airstrip, BCV, hector silva airstrip belize city belize', key:'A_BCV', cityBase: 127, city: 'BZE', country: 'BZ', type: 'airport', nlabel: 'hector silva airstrip belize city belize'},
  { icon: 'faPlaneDeparture', label: 'HFE Hefei, China, Hefei Xinqiao International, HFE, hefei xinqiao international hefei china', key:'A_HFE', cityBase: 59, city: 'HFE', country: 'CN', type: 'airport', nlabel: 'hefei xinqiao international hefei china'},
  { icon: 'faPlaneDeparture', label: 'HEH Heho, Myanmar (Burma), Heho, HEH, heho heho myanmar (burma)', key:'A_HEH', cityBase: 110, city: 'HEH', country: 'MM', type: 'airport', nlabel: 'heho heho myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'HEK Heihe, China, Heihe, HEK, heihe heihe china', key:'A_HEK', cityBase: 73, city: 'HEK', country: 'CN', type: 'airport', nlabel: 'heihe heihe china'},
  { icon: 'faPlaneDeparture', label: 'HLN Helena, United States, Helena Regional, HLN, helena regional helena montana united states', key:'A_HLN', cityBase: 58, city: 'HLN', country: 'US', type: 'airport', nlabel: 'helena regional helena montana united states'},
  { icon: 'faPlaneDeparture', label: 'HEL Helsinki, Finland, Helsinki, HEL, helsinki helsinki finland', key:'A_HEL', cityBase: 19, city: 'HEL', country: 'FI', type: 'airport', nlabel: 'helsinki helsinki finland'},
  { icon: 'faPlaneDeparture', label: 'HMV Hemavan, Sweden, Hemavan, HMV, hemavan hemavan sweden', key:'A_HMV', cityBase: 127, city: 'HMV', country: 'SE', type: 'airport', nlabel: 'hemavan hemavan sweden'},
  { icon: 'faPlaneDeparture', label: 'PHW Phalaborwa, South Africa, Hendrik Van Eck, PHW, hendrik van eck phalaborwa south africa', key:'A_PHW', cityBase: 127, city: 'PHW', country: 'ZA', type: 'airport', nlabel: 'hendrik van eck phalaborwa south africa'},
  { icon: 'faPlaneDeparture', label: 'HNY Hengyang, China, Hengyang Nanyue, HNY, hengyang nanyue hengyang china', key:'A_HNY', cityBase: 73, city: 'HNY', country: 'CN', type: 'airport', nlabel: 'hengyang nanyue hengyang china'},
  { icon: 'faPlaneDeparture', label: 'OTP Bucharest, Romania, Henri Coandă International, OTP, henri coanda international bucharest romania', key:'A_OTP', cityBase: 13, city: 'BUH', country: 'RO', type: 'airport', nlabel: 'henri coanda international bucharest romania'},
  { icon: 'faPlaneDeparture', label: 'STX Saint Croix, United States, Henry E. Rohlsen, STX, henry e rohlsen saint croix u s virgin islands united states', key:'A_STX', cityBase: 42, city: 'STX', country: 'US', type: 'airport', nlabel: 'henry e rohlsen saint croix u s virgin islands united states'},
  { icon: 'faPlaneDeparture', label: 'HER Heraklion, Greece, Heraklion International, HER, heraklion international heraklion greece', key:'A_HER', cityBase: 7, city: 'HER', country: 'GR', type: 'airport', nlabel: 'heraklion international heraklion greece'},
  { icon: 'faPlaneDeparture', label: 'HEA Herat, Afghanistan, Herat International, HEA, herat international herat afghanistan', key:'A_HEA', cityBase: 127, city: 'HEA', country: 'AF', type: 'airport', nlabel: 'herat international herat afghanistan'},
  { icon: 'faPlaneDeparture', label: 'FLN Florianópolis, Brazil, Hercílio Luz International, FLN, hercilio luz international florianopolis state of santa catarina brazil', key:'A_FLN', cityBase: 52, city: 'FLN', country: 'BR', type: 'airport', nlabel: 'hercilio luz international florianopolis state of santa catarina brazil'},
  { icon: 'faPlaneDeparture', label: 'HDF Heringsdorf, Germany, Heringsdorf, HDF, heringsdorf heringsdorf germany', key:'A_HDF', cityBase: 25, city: 'HDF', country: 'DE', type: 'airport', nlabel: 'heringsdorf heringsdorf germany'},
  { icon: 'faPlaneDeparture', label: 'VTU Las Tunas, Cuba, Hermanos Ameijeiras, VTU, hermanos ameijeiras las tunas cuba', key:'A_VTU', cityBase: 127, city: 'VTU', country: 'CU', type: 'airport', nlabel: 'hermanos ameijeiras las tunas cuba'},
  { icon: 'faPlaneDeparture', label: 'RGA Río Grande, Argentina, Hermes Quijada International, RGA, hermes quijada international rio grande tierra del fuego argentina', key:'A_RGA', cityBase: 127, city: 'RGA', country: 'AR', type: 'airport', nlabel: 'hermes quijada international rio grande tierra del fuego argentina'},
  { icon: 'faPlaneDeparture', label: 'HVB Hervey Bay, Australia, Hervey Bay, HVB, hervey bay hervey bay queensland australia', key:'A_HVB', cityBase: 118, city: 'HVB', country: 'AU', type: 'airport', nlabel: 'hervey bay hervey bay queensland australia'},
  { icon: 'faPlaneDeparture', label: 'SOB Hévíz, Hungary, Hévíz–Balaton, SOB, heviz balaton heviz hungary', key:'A_SOB', cityBase: 127, city: 'SOB', country: 'HU', type: 'airport', nlabel: 'heviz balaton heviz hungary'},
  { icon: 'faPlaneDeparture', label: 'UVF Saint Lucia, St. Lucia, Hewanorra International, UVF, hewanorra international saint lucia st lucia', key:'A_UVF', cityBase: 32, city: 'SLU', country: 'LC', type: 'airport', nlabel: 'hewanorra international saint lucia st lucia'},
  { icon: 'faPlaneDeparture', label: 'GYD Baku, Azerbaijan, Heydar Aliyev International, GYD, heydar aliyev international baku azerbaijan', key:'A_GYD', cityBase: 38, city: 'BAK', country: 'AZ', type: 'airport', nlabel: 'heydar aliyev international baku azerbaijan'},
  { icon: 'faPlaneDeparture', label: 'YOJ High Level, Canada, High Level, YOJ, high level high level alberta canada', key:'A_YOJ', cityBase: 127, city: 'YOJ', country: 'CA', type: 'airport', nlabel: 'high level high level alberta canada'},
  { icon: 'faPlaneDeparture', label: 'WLS Wallis Island, Wallis & Futuna, Hihifo, WLS, hihifo wallis island wallis and futuna', key:'A_WLS', cityBase: 127, city: 'WLS', country: 'WF', type: 'airport', nlabel: 'hihifo wallis island wallis and futuna'},
  { icon: 'faPlaneDeparture', label: 'HHZ Hikueru, French Polynesia, Hikueru, HHZ, hikueru hikueru french polynesia', key:'A_HHZ', cityBase: 127, city: 'HHZ', country: 'PF', type: 'airport', nlabel: 'hikueru hikueru french polynesia'},
  { icon: 'faPlaneDeparture', label: 'ITO Hilo, United States, Hilo International, ITO, hilo international hilo hawaii united states', key:'A_ITO', cityBase: 41, city: 'ITO', country: 'US', type: 'airport', nlabel: 'hilo international hilo hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'HHH Hilton Head Island, United States, Hilton Head, HHH, hilton head hilton head island south carolina united states', key:'A_HHH', cityBase: 62, city: 'HHH', country: 'US', type: 'airport', nlabel: 'hilton head hilton head island south carolina united states'},
  { icon: 'faPlaneDeparture', label: 'HIJ Hiroshima, Japan, Hiroshima, HIJ, hiroshima hiroshima japan', key:'A_HIJ', cityBase: 60, city: 'HIJ', country: 'JP', type: 'airport', nlabel: 'hiroshima hiroshima japan'},
  { icon: 'faPlaneDeparture', label: 'HBA Hobart, Australia, Hobart International, HBA, hobart international hobart tasmania australia', key:'A_HBA', cityBase: 54, city: 'HBA', country: 'AU', type: 'airport', nlabel: 'hobart international hobart tasmania australia'},
  { icon: 'faPlaneDeparture', label: 'HOD Hodeida, Yemen, Hodeidah International Airport, HOD, hodeidah international airport hodeida yemen', key:'A_HOD', cityBase: 127, city: 'HOD', country: 'YE', type: 'airport', nlabel: 'hodeidah international airport hodeida yemen'},
  { icon: 'faPlaneDeparture', label: 'KRF Kramfors Municipality, Sweden, Höga Kusten, KRF, hoga kusten kramfors municipality sweden', key:'A_KRF', cityBase: 127, city: 'KRF', country: 'SE', type: 'airport', nlabel: 'hoga kusten kramfors municipality sweden'},
  { icon: 'faPlaneDeparture', label: 'HET Hohhot, China, Hohhot Baita International, HET, hohhot baita international hohhot china', key:'A_HET', cityBase: 60, city: 'HET', country: 'CN', type: 'airport', nlabel: 'hohhot baita international hohhot china'},
  { icon: 'faPlaneDeparture', label: 'HKK Hokitika, New Zealand, Hokitika, HKK, hokitika hokitika new zealand', key:'A_HKK', cityBase: 127, city: 'HKK', country: 'NZ', type: 'airport', nlabel: 'hokitika hokitika new zealand'},
  { icon: 'faPlaneDeparture', label: 'HYL Hollis, United States, Hollis Seaplane Base, HYL, hollis seaplane base hollis alaska united states', key:'A_HYL', cityBase: 127, city: 'HYL', country: 'US', type: 'airport', nlabel: 'hollis seaplane base hollis alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BUR Burbank, United States, Hollywood Burbank, BUR, hollywood burbank burbank california united states', key:'A_BUR', cityBase: 17, city: 'BUR', country: 'US', type: 'airport', nlabel: 'hollywood burbank burbank california united states'},
  { icon: 'faPlaneDeparture', label: 'HCR Holy Cross, United States, Holy Cross, HCR, holy cross holy cross alaska united states', key:'A_HCR', cityBase: 127, city: 'HCR', country: 'US', type: 'airport', nlabel: 'holy cross holy cross alaska united states'},
  { icon: 'faPlaneDeparture', label: 'HOX Homalin, Myanmar (Burma), Homalin, HOX, homalin homalin myanmar (burma)', key:'A_HOX', cityBase: 127, city: 'HOX', country: 'MM', type: 'airport', nlabel: 'homalin homalin myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'HOM Homer, United States, Homer, HOM, homer homer alaska united states', key:'A_HOM', cityBase: 105, city: 'HOM', country: 'US', type: 'airport', nlabel: 'homer homer alaska united states'},
  { icon: 'faPlaneDeparture', label: 'HKG Hong Kong, China, Hong Kong International, HKG, hong kong international hong kong hong kong', key:'A_HKG', cityBase: 3, city: 'HKG', country: 'CN', type: 'airport', nlabel: 'hong kong international hong kong hong kong'},
  { icon: 'faPlaneDeparture', label: 'HIR Honiara, Solomon Islands, Honiara International, HIR, honiara international honiara solomon islands', key:'A_HIR', cityBase: 95, city: 'HIR', country: 'SB', type: 'airport', nlabel: 'honiara international honiara solomon islands'},
  { icon: 'faPlaneDeparture', label: 'HVG Honningsvåg, Norway, Honningsvåg Airport, Valan, HVG, honningsvag airport honningsvag norway', key:'A_HVG', cityBase: 127, city: 'HVG', country: 'NO', type: 'airport', nlabel: 'honningsvag airport honningsvag norway'},
  { icon: 'faPlaneDeparture', label: 'HNH Hoonah, United States, Hoonah, HNH, hoonah hoonah alaska united states', key:'A_HNH', cityBase: 127, city: 'HNH', country: 'US', type: 'airport', nlabel: 'hoonah hoonah alaska united states'},
  { icon: 'faPlaneDeparture', label: 'HPB Hooper Bay, United States, Hooper Bay, HPB, hooper bay hooper bay alaska united states', key:'A_HPB', cityBase: 127, city: 'HPB', country: 'US', type: 'airport', nlabel: 'hooper bay hooper bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YHO Hopedale, Canada, Hopedale, YHO, hopedale hopedale newfoundland and labrador canada', key:'A_YHO', cityBase: 127, city: 'YHO', country: 'CA', type: 'airport', nlabel: 'hopedale hopedale newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'HID Horn Island, Australia, Horn Island, HID, horn island horn island queensland australia', key:'A_HID', cityBase: 127, city: 'HID', country: 'AU', type: 'airport', nlabel: 'horn island horn island queensland australia'},
  { icon: 'faPlaneDeparture', label: 'HOR Horta, Azores, Portugal, Horta, HOR, horta horta portugal', key:'A_HOR', cityBase: 28, city: 'HOR', country: 'PT', type: 'airport', nlabel: 'horta horta portugal'},
  { icon: 'faPlaneDeparture', label: 'WDH Windhoek, Namibia, Hosea Kutako International, WDH, hosea kutako international windhoek namibia', key:'A_WDH', cityBase: 50, city: 'WDH', country: 'NA', type: 'airport', nlabel: 'hosea kutako international windhoek namibia'},
  { icon: 'faPlaneDeparture', label: 'HKN Hoskins, Papua New Guinea, Hoskins, HKN, hoskins hoskins papua new guinea', key:'A_HKN', cityBase: 127, city: 'HKN', country: 'PG', type: 'airport', nlabel: 'hoskins hoskins papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'HTN Hotan, China, Hotan, HTN, hotan hotan china', key:'A_HTN', cityBase: 72, city: 'HTN', country: 'CN', type: 'airport', nlabel: 'hotan hotan china'},
  { icon: 'faPlaneDeparture', label: 'ALG Algiers, Algeria, Houari Boumediene, ALG, houari boumediene algiers algeria', key:'A_ALG', cityBase: 39, city: 'ALG', country: 'DZ', type: 'airport', nlabel: 'houari boumediene algiers algeria'},
  { icon: 'faPlaneDeparture', label: 'CMX Hancock, United States, Houghton County Memorial, CMX, houghton county memorial hancock michigan united states', key:'A_CMX', cityBase: 91, city: 'CMX', country: 'US', type: 'airport', nlabel: 'houghton county memorial hancock michigan united states'},
  { icon: 'faPlaneDeparture', label: 'BLB Balboa, Panama, Howard Air Force Base, BLB, howard air force base balboa panama', key:'A_BLB', cityBase: 127, city: 'BLB', country: 'PA', type: 'airport', nlabel: 'howard air force base balboa panama'},
  { icon: 'faPlaneDeparture', label: 'GNA Grodno, Belarus, Hrodna, GNA, hrodna grodno belarus', key:'A_GNA', cityBase: 127, city: 'GNA', country: 'BY', type: 'airport', nlabel: 'hrodna grodno belarus'},
  { icon: 'faPlaneDeparture', label: 'HHQ Hua Hin District, Thailand, Hua Hin, HHQ, hua hin hua hin district thailand', key:'A_HHQ', cityBase: 95, city: 'HHQ', country: 'TH', type: 'airport', nlabel: 'hua hin hua hin district thailand'},
  { icon: 'faPlaneDeparture', label: 'HUH Huahine, French Polynesia, Huahine – Fare, HUH, huahine fare huahine french polynesia', key:'A_HUH', cityBase: 83, city: 'HUH', country: 'PF', type: 'airport', nlabel: 'huahine fare huahine french polynesia'},
  { icon: 'faPlaneDeparture', label: 'HIA Huai an, China, Huai an Lianshui, HIA, huai an lianshui huai an china', key:'A_HIA', cityBase: 77, city: 'HIA', country: 'CN', type: 'airport', nlabel: 'huai an lianshui huai an china'},
  { icon: 'faPlaneDeparture', label: 'HJJ Huaihua, China, Huaihua Zhijiang, HJJ, huaihua zhijiang huaihua china', key:'A_HJJ', cityBase: 68, city: 'HJJ', country: 'CN', type: 'airport', nlabel: 'huaihua zhijiang huaihua china'},
  { icon: 'faPlaneDeparture', label: 'HUN Hualien County, Taiwan, Hualien, HUN, hualien hualien county taiwan', key:'A_HUN', cityBase: 122, city: 'HUN', country: 'TW', type: 'airport', nlabel: 'hualien hualien county taiwan'},
  { icon: 'faPlaneDeparture', label: 'TXN Huangshan City, China, Huangshan Tunxi International, TXN, huangshan tunxi international huangshan city china', key:'A_TXN', cityBase: 66, city: 'TXN', country: 'CN', type: 'airport', nlabel: 'huangshan tunxi international huangshan city china'},
  { icon: 'faPlaneDeparture', label: 'HBX Hubli, India, Hubli, HBX, hubli hubli india', key:'A_HBX', cityBase: 127, city: 'HBX', country: 'IN', type: 'airport', nlabel: 'hubli hubli india'},
  { icon: 'faPlaneDeparture', label: 'HGD Hughenden, Australia, Hughenden, HGD, hughenden hughenden queensland australia', key:'A_HGD', cityBase: 127, city: 'HGD', country: 'AU', type: 'airport', nlabel: 'hughenden hughenden queensland australia'},
  { icon: 'faPlaneDeparture', label: 'HUS Hughes, United States, Hughes, HUS, hughes hughes alaska united states', key:'A_HUS', cityBase: 127, city: 'HUS', country: 'US', type: 'airport', nlabel: 'hughes hughes alaska united states'},
  { icon: 'faPlaneDeparture', label: 'HUZ Huizhou, China, Huizhou Pingtan, HUZ, huizhou pingtan huizhou china', key:'A_HUZ', cityBase: 73, city: 'HUZ', country: 'CN', type: 'airport', nlabel: 'huizhou pingtan huizhou china'},
  { icon: 'faPlaneDeparture', label: 'HLD Hailar, China, Hulunbuir Hailar, HLD, hulunbuir hailar hailar china', key:'A_HLD', cityBase: 71, city: 'HLD', country: 'CN', type: 'airport', nlabel: 'hulunbuir hailar hailar china'},
  { icon: 'faPlaneDeparture', label: 'HUY Kirmington, United Kingdom, Humberside, HUY, humberside kirmington united kingdom', key:'A_HUY', cityBase: -900, city: 'HUY', country: 'GB', type: 'airport', nlabel: 'humberside kirmington united kingdom'},
  { icon: 'faPlaneDeparture', label: 'HUE Humera, Ethiopia, Humera, HUE, humera humera ethiopia', key:'A_HUE', cityBase: 127, city: 'HUE', country: 'ET', type: 'airport', nlabel: 'humera humera ethiopia'},
  { icon: 'faPlaneDeparture', label: 'HSV Huntsville, United States, Huntsville International, HSV, huntsville international huntsville alabama united states', key:'A_HSV', cityBase: 28, city: 'HSV', country: 'US', type: 'airport', nlabel: 'huntsville international huntsville alabama united states'},
  { icon: 'faPlaneDeparture', label: 'HRG Hurghada, Egypt, Hurghada International, HRG, hurghada international hurghada egypt', key:'A_HRG', cityBase: 8, city: 'HRG', country: 'EG', type: 'airport', nlabel: 'hurghada international hurghada egypt'},
  { icon: 'faPlaneDeparture', label: 'BDO Bandung, Indonesia, Husein Sastranegara International, BDO, husein sastranegara international bandung indonesia', key:'A_BDO', cityBase: 35, city: 'BDO', country: 'ID', type: 'airport', nlabel: 'husein sastranegara international bandung indonesia'},
  { icon: 'faPlaneDeparture', label: 'HSL Huslia, United States, Huslia, HSL, huslia huslia alaska united states', key:'A_HSL', cityBase: 127, city: 'HSL', country: 'US', type: 'airport', nlabel: 'huslia huslia alaska united states'},
  { icon: 'faPlaneDeparture', label: 'HYG Hydaburg, United States, Hydaburg Seaplane Base, HYG, hydaburg seaplane base hydaburg alaska united states', key:'A_HYG', cityBase: 127, city: 'HYG', country: 'US', type: 'airport', nlabel: 'hydaburg seaplane base hydaburg alaska united states'},
  { icon: 'faPlaneDeparture', label: 'IAS Iași, Romania, Iași International, IAS, iasi international iasi romania', key:'A_IAS', cityBase: 21, city: 'IAS', country: 'RO', type: 'airport', nlabel: 'iasi international iasi romania'},
  { icon: 'faPlaneDeparture', label: 'IBA Ibadan, Nigeria, Ibadan, IBA, ibadan ibadan nigeria', key:'A_IBA', cityBase: 127, city: 'IBA', country: 'NG', type: 'airport', nlabel: 'ibadan ibadan nigeria'},
  { icon: 'faPlaneDeparture', label: 'IBR Ibaraki, Japan, Ibaraki, IBR, ibaraki ibaraki japan', key:'A_IBR', cityBase: 123, city: 'IBR', country: 'JP', type: 'airport', nlabel: 'ibaraki ibaraki japan'},
  { icon: 'faPlaneDeparture', label: 'IBZ Ibiza, Spain, Ibiza, IBZ, ibiza ibiza spain', key:'A_IBZ', cityBase: 6, city: 'IBZ', country: 'ES', type: 'airport', nlabel: 'ibiza ibiza spain'},
  { icon: 'faPlaneDeparture', label: 'IDA Idaho Falls, United States, Idaho Falls Regional, IDA, idaho falls regional idaho falls idaho united states', key:'A_IDA', cityBase: 60, city: 'IDA', country: 'US', type: 'airport', nlabel: 'idaho falls regional idaho falls idaho united states'},
  { icon: 'faPlaneDeparture', label: 'IAA Igarka, Russia, Igarka, IAA, igarka igarka russia', key:'A_IAA', cityBase: 127, city: 'IAA', country: 'RU', type: 'airport', nlabel: 'igarka igarka russia'},
  { icon: 'faPlaneDeparture', label: 'IGD Iğdır, Turkey, Iğdır, IGD, igdir igdir turkey', key:'A_IGD', cityBase: 27, city: 'IGD', country: 'TR', type: 'airport', nlabel: 'igdir igdir turkey'},
  { icon: 'faPlaneDeparture', label: 'IGG Igiugig, United States, Igiugig, IGG, igiugig igiugig alaska united states', key:'A_IGG', cityBase: 127, city: 'IGG', country: 'US', type: 'airport', nlabel: 'igiugig igiugig alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YGT Igloolik, Canada, Igloolik, YGT, igloolik igloolik nunavut canada', key:'A_YGT', cityBase: 127, city: 'YGT', country: 'CA', type: 'airport', nlabel: 'igloolik igloolik nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'CMW Camagüey, Cuba, Ignacio Agramonte International, CMW, ignacio agramonte international camaguey cuba', key:'A_CMW', cityBase: 57, city: 'CMW', country: 'CU', type: 'airport', nlabel: 'ignacio agramonte international camaguey cuba'},
  { icon: 'faPlaneDeparture', label: 'BQS Blagoveshchensk, Russia, Ignatyevo, BQS, ignatyevo blagoveshchensk russia', key:'A_BQS', cityBase: 48, city: 'BQS', country: 'RU', type: 'airport', nlabel: 'ignatyevo blagoveshchensk russia'},
  { icon: 'faPlaneDeparture', label: 'IRM Igrim, Russia, Igrim, IRM, igrim igrim russia', key:'A_IRM', cityBase: 127, city: 'IRM', country: 'RU', type: 'airport', nlabel: 'igrim igrim russia'},
  { icon: 'faPlaneDeparture', label: 'JIK Icaria, Greece, Ikaria Island National, JIK, ikaria island national icaria greece', key:'A_JIK', cityBase: 20, city: 'JIK', country: 'GR', type: 'airport', nlabel: 'ikaria island national icaria greece'},
  { icon: 'faPlaneDeparture', label: 'IKI Iki, Japan, Iki, IKI, iki iki japan', key:'A_IKI', cityBase: 127, city: 'IKI', country: 'JP', type: 'airport', nlabel: 'iki iki japan'},
  { icon: 'faPlaneDeparture', label: 'IIL Ilam, Iran, Ilam, IIL, ilam ilam iran', key:'A_IIL', cityBase: 127, city: 'IIL', country: 'IR', type: 'airport', nlabel: 'ilam ilam iran'},
  { icon: 'faPlaneDeparture', label: 'BMY Art Island, New Caledonia, Île Art – Waala, BMY, ile art waala art island new caledonia', key:'A_BMY', cityBase: 127, city: 'BMY', country: 'NC', type: 'airport', nlabel: 'ile art waala art island new caledonia'},
  { icon: 'faPlaneDeparture', label: 'ILP Isle of Pines, New Caledonia, Île des Pins, ILP, ile des pins isle of pines new caledonia', key:'A_ILP', cityBase: 127, city: 'ILP', country: 'NC', type: 'airport', nlabel: 'ile des pins isle of pines new caledonia'},
  { icon: 'faPlaneDeparture', label: 'YGR Les Îles-de-la-Madeleine, Quebec, Canada, Îles-de-la-Madeleine, YGR, iles de la madeleine les iles de la madeleine quebec canada', key:'A_YGR', cityBase: 127, city: 'YGR', country: 'CA', type: 'airport', nlabel: 'iles de la madeleine les iles de la madeleine quebec canada'},
  { icon: 'faPlaneDeparture', label: 'IOS Ilhéus, Brazil, Ilhéus Jorge Amado, IOS, ilheus jorge amado ilheus state of bahia brazil', key:'A_IOS', cityBase: 101, city: 'IOS', country: 'BR', type: 'airport', nlabel: 'ilheus jorge amado ilheus state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'ILI Iliamna, United States, Iliamna, ILI, iliamna iliamna alaska united states', key:'A_ILI', cityBase: 127, city: 'ILI', country: 'US', type: 'airport', nlabel: 'iliamna iliamna alaska united states'},
  { icon: 'faPlaneDeparture', label: 'WOL City of Wollongong, Australia, Illawarra Regional, WOL, illawarra regional city of wollongong new south wales australia', key:'A_WOL', cityBase: 127, city: 'WOL', country: 'AU', type: 'airport', nlabel: 'illawarra regional city of wollongong new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'ILO Iloilo City, Philippines, Iloilo International, ILO, iloilo international iloilo city philippines', key:'A_ILO', cityBase: 58, city: 'ILO', country: 'PH', type: 'airport', nlabel: 'iloilo international iloilo city philippines'},
  { icon: 'faPlaneDeparture', label: 'ILR Ilorin, Nigeria, Ilorin International, ILR, ilorin international ilorin nigeria', key:'A_ILR', cityBase: 127, city: 'ILR', country: 'NG', type: 'airport', nlabel: 'ilorin international ilorin nigeria'},
  { icon: 'faPlaneDeparture', label: 'JAV Ilulissat, Greenland, Ilulissat, JAV, ilulissat ilulissat greenland', key:'A_JAV', cityBase: 74, city: 'JAV', country: 'GL', type: 'airport', nlabel: 'ilulissat ilulissat greenland'},
  { icon: 'faPlaneDeparture', label: 'IMP Imperatriz, Brazil, Imperatriz, IMP, imperatriz imperatriz state of maranhao brazil', key:'A_IMP', cityBase: 127, city: 'IMP', country: 'BR', type: 'airport', nlabel: 'imperatriz imperatriz state of maranhao brazil'},
  { icon: 'faPlaneDeparture', label: 'IPL El Centro, United States, Imperial County, IPL, imperial county el centro california united states', key:'A_IPL', cityBase: 109, city: 'IPL', country: 'US', type: 'airport', nlabel: 'imperial county el centro california united states'},
  { icon: 'faPlaneDeparture', label: 'IMF Imphal, India, Imphal International, IMF, imphal international imphal india', key:'A_IMF', cityBase: 108, city: 'IMF', country: 'IN', type: 'airport', nlabel: 'imphal international imphal india'},
  { icon: 'faPlaneDeparture', label: 'IAM In Amenas, Algeria, In Amenas, IAM, in amenas in amenas algeria', key:'A_IAM', cityBase: 127, city: 'IAM', country: 'DZ', type: 'airport', nlabel: 'in amenas in amenas algeria'},
  { icon: 'faPlaneDeparture', label: 'INZ In Salah, Algeria, In Salah, INZ, in salah in salah algeria', key:'A_INZ', cityBase: 127, city: 'INZ', country: 'DZ', type: 'airport', nlabel: 'in salah in salah algeria'},
  { icon: 'faPlaneDeparture', label: 'IGA Inagua, Bahamas, Inagua, IGA, inagua inagua bahamas', key:'A_IGA', cityBase: 127, city: 'IGA', country: 'BS', type: 'airport', nlabel: 'inagua inagua bahamas'},
  { icon: 'faPlaneDeparture', label: 'JUL Juliaca, Peru, Inca Manco Cápac International, JUL, inca manco capac international juliaca peru', key:'A_JUL', cityBase: 63, city: 'JUL', country: 'PE', type: 'airport', nlabel: 'inca manco capac international juliaca peru'},
  { icon: 'faPlaneDeparture', label: 'ICN Seoul, South Korea, Incheon International, ICN, incheon international seoul south korea', key:'A_ICN', cityBase: 7, city: 'SEL', country: 'KR', type: 'airport', nlabel: 'incheon international seoul south korea'},
  { icon: 'faPlaneDeparture', label: 'INB Independence and Mango Creek, Belize, Independence, INB, independence independence and mango creek belize', key:'A_INB', cityBase: 127, city: 'INB', country: 'BZ', type: 'airport', nlabel: 'independence independence and mango creek belize'},
  { icon: 'faPlaneDeparture', label: 'UTO Utopia Creek, United States, Indian Mountain Lrrs, UTO, indian mountain lrrs utopia creek alaska united states', key:'A_UTO', cityBase: 127, city: 'UTO', country: 'US', type: 'airport', nlabel: 'indian mountain lrrs utopia creek alaska united states'},
  { icon: 'faPlaneDeparture', label: 'IND Indianapolis, United States, Indianapolis International, IND, indianapolis international indianapolis indiana united states', key:'A_IND', cityBase: 4, city: 'IND', country: 'US', type: 'airport', nlabel: 'indianapolis international indianapolis indiana united states'},
  { icon: 'faPlaneDeparture', label: 'DEL New Delhi, India, Indira Gandhi International, DEL, indira gandhi international new delhi india', key:'A_DEL', cityBase: 7, city: 'DEL', country: 'IN', type: 'airport', nlabel: 'indira gandhi international new delhi india'},
  { icon: 'faPlaneDeparture', label: 'COR Córdoba, Argentina, Ingeniero Aeronáutico Ambrosio L.V. Taravella International, COR, ingeniero aeronautico ambrosio l v taravella international cordoba cordoba argentina', key:'A_COR', cityBase: 49, city: 'COR', country: 'AR', type: 'airport', nlabel: 'ingeniero aeronautico ambrosio l v taravella international cordoba cordoba argentina'},
  { icon: 'faPlaneDeparture', label: 'INH Inhambane, Mozambique, Inhambane, INH, inhambane inhambane mozambique', key:'A_INH', cityBase: 118, city: 'INH', country: 'MZ', type: 'airport', nlabel: 'inhambane inhambane mozambique'},
  { icon: 'faPlaneDeparture', label: 'INN Innsbruck, Austria, Innsbruck, INN, innsbruck innsbruck austria', key:'A_INN', cityBase: 5, city: 'INN', country: 'AT', type: 'airport', nlabel: 'innsbruck innsbruck austria'},
  { icon: 'faPlaneDeparture', label: 'INA Inta, Russia, Inta Airport, INA, inta airport inta russia', key:'A_INA', cityBase: 127, city: 'INA', country: 'RU', type: 'airport', nlabel: 'inta airport inta russia'},
  { icon: 'faPlaneDeparture', label: 'IKT Irkutsk, Russia, International Airport Irkutsk, IKT, international airport irkutsk irkutsk russia', key:'A_IKT', cityBase: 41, city: 'IKT', country: 'RU', type: 'airport', nlabel: 'international airport irkutsk irkutsk russia'},
  { icon: 'faPlaneDeparture', label: 'YPH Inukjuak, Canada, Inukjuak, YPH, inukjuak inukjuak quebec canada', key:'A_YPH', cityBase: 127, city: 'YPH', country: 'CA', type: 'airport', nlabel: 'inukjuak inukjuak quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YEV Inuvik, Canada, Inuvik (Mike Zubko), YEV, inuvik (mike zubko) inuvik northwest territories canada', key:'A_YEV', cityBase: 127, city: 'YEV', country: 'CA', type: 'airport', nlabel: 'inuvik (mike zubko) inuvik northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'IVC Invercargill, New Zealand, Invercargill, IVC, invercargill invercargill new zealand', key:'A_IVC', cityBase: 116, city: 'IVC', country: 'NZ', type: 'airport', nlabel: 'invercargill invercargill new zealand'},
  { icon: 'faPlaneDeparture', label: 'INV Inverness, United Kingdom, Inverness, INV, inverness inverness united kingdom', key:'A_INV', cityBase: -943, city: 'INV', country: 'GB', type: 'airport', nlabel: 'inverness inverness united kingdom'},
  { icon: 'faPlaneDeparture', label: 'IOA Ioannina, Greece, Ioannina National, IOA, ioannina national ioannina greece', key:'A_IOA', cityBase: 26, city: 'IOA', country: 'GR', type: 'airport', nlabel: 'ioannina national ioannina greece'},
  { icon: 'faPlaneDeparture', label: 'IPA Ipota, Vanuatu, Ipota, IPA, ipota ipota vanuatu', key:'A_IPA', cityBase: 127, city: 'IPA', country: 'VU', type: 'airport', nlabel: 'ipota ipota vanuatu'},
  { icon: 'faPlaneDeparture', label: 'YFB Iqaluit, Canada, Iqaluit, YFB, iqaluit iqaluit nunavut canada', key:'A_YFB', cityBase: 127, city: 'YFB', country: 'CA', type: 'airport', nlabel: 'iqaluit iqaluit nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'IHR Iranshahr, Iran, Iranshahr, IHR, iranshahr iranshahr iran', key:'A_IHR', cityBase: 105, city: 'IHR', country: 'IR', type: 'airport', nlabel: 'iranshahr iranshahr iran'},
  { icon: 'faPlaneDeparture', label: 'BXB Babo, Indonesia, Irarutu Babo, BXB, irarutu babo babo indonesia', key:'A_BXB', cityBase: 127, city: 'BXB', country: 'ID', type: 'airport', nlabel: 'irarutu babo babo indonesia'},
  { icon: 'faPlaneDeparture', label: 'NOC Knock, County Mayo, Ireland, Ireland West Airport Knock, NOC, ireland west airport knock knock ireland', key:'A_NOC', cityBase: 52, city: 'NOC', country: 'IE', type: 'airport', nlabel: 'ireland west airport knock knock ireland'},
  { icon: 'faPlaneDeparture', label: 'IRI Nduli, Tanzania, Iringa Airport, IRI, iringa airport nduli tanzania', key:'A_IRI', cityBase: 127, city: 'IRI', country: 'TZ', type: 'airport', nlabel: 'iringa airport nduli tanzania'},
  { icon: 'faPlaneDeparture', label: 'IFJ Ísafjörður, Iceland, Ísafjörður, IFJ, isafjordur isafjordur iceland', key:'A_IFJ', cityBase: 71, city: 'IFJ', country: 'IS', type: 'airport', nlabel: 'isafjordur isafjordur iceland'},
  { icon: 'faPlaneDeparture', label: 'IFN Isfahan, Iran, Isfahan International, IFN, isfahan international isfahan iran', key:'A_IFN', cityBase: 61, city: 'IFN', country: 'IR', type: 'airport', nlabel: 'isfahan international isfahan iran'},
  { icon: 'faPlaneDeparture', label: 'ISG Ishigaki, Okinawa, Japan, Ishigaki, ISG, ishigaki ishigaki japan', key:'A_ISG', cityBase: 78, city: 'ISG', country: 'JP', type: 'airport', nlabel: 'ishigaki ishigaki japan'},
  { icon: 'faPlaneDeparture', label: 'PKN Pangkalan Bun, Indonesia, Iskandar, PKN, iskandar pangkalan bun indonesia', key:'A_PKN', cityBase: 97, city: 'PKN', country: 'ID', type: 'airport', nlabel: 'iskandar pangkalan bun indonesia'},
  { icon: 'faPlaneDeparture', label: 'ISB Islamabad, Pakistan, Islamabad International Airport, ISB, benazir bhutto international islamabad pakistan', key:'A_ISB', cityBase: 31, city: 'ISB', country: 'PK', type: 'airport', nlabel: 'benazir bhutto international islamabad pakistan'},
  { icon: 'faPlaneDeparture', label: 'YIV Island Lake, Manitoba, Canada, Island Lake, YIV, island lake island lake manitoba canada', key:'A_YIV', cityBase: 127, city: 'YIV', country: 'CA', type: 'airport', nlabel: 'island lake island lake manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'ILY Islay, United Kingdom, Islay, ILY, islay islay united kingdom', key:'A_ILY', cityBase: -903, city: 'ILY', country: 'GB', type: 'airport', nlabel: 'islay islay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'IOM Douglas, Isle of Man, Isle of Man, IOM, isle of man douglas isle of man', key:'A_IOM', cityBase: 91, city: 'IOM', country: 'IM', type: 'airport', nlabel: 'isle of man douglas isle of man'},
  { icon: 'faPlaneDeparture', label: 'ISE Isparta, Turkey, Isparta Süleyman Demirel, ISE, isparta suleyman demirel isparta turkey', key:'A_ISE', cityBase: 25, city: 'ISE', country: 'TR', type: 'airport', nlabel: 'isparta suleyman demirel isparta turkey'},
  { icon: 'faPlaneDeparture', label: 'IKU Tamchy, Kyrgyzstan, Issyk-Kul International, IKU, issyk kul international tamchy kyrgyzstan', key:'A_IKU', cityBase: 127, city: 'IKU', country: 'KG', type: 'airport', nlabel: 'issyk kul international tamchy kyrgyzstan'},
  { icon: 'faPlaneDeparture', label: 'IST Istanbul, Turkey, Istanbul Atatürk, IST, istanbul ataturk istanbul turkey', key:'A_IST', cityBase: 3, city: 'IST', country: 'TR', type: 'airport', nlabel: 'istanbul ataturk istanbul turkey'},
  { icon: 'faPlaneDeparture', label: 'ITM Osaka, Japan, Itami, ITM, itami osaka japan', key:'A_ITM', cityBase: 59, city: 'OSA', country: 'JP', type: 'airport', nlabel: 'itami osaka japan'},
  { icon: 'faPlaneDeparture', label: 'ITH Ithaca, United States, Ithaca Tompkins Regional, ITH, ithaca tompkins regional ithaca new york united states', key:'A_ITH', cityBase: 35, city: 'ITH', country: 'US', type: 'airport', nlabel: 'ithaca tompkins regional ithaca new york united states'},
  { icon: 'faPlaneDeparture', label: 'ITU Iturup, Russia, Iturup, ITU, iturup iturup russia', key:'A_ITU', cityBase: 127, city: 'ITU', country: 'RU', type: 'airport', nlabel: 'iturup iturup russia'},
  { icon: 'faPlaneDeparture', label: 'IVL Ivalo, Finland, Ivalo, IVL, ivalo ivalo finland', key:'A_IVL', cityBase: 81, city: 'IVL', country: 'FI', type: 'airport', nlabel: 'ivalo ivalo finland'},
  { icon: 'faPlaneDeparture', label: 'IFO Ivano-Frankivsk, Ukraine, Ivano-Frankivsk International, IFO, ivano frankivsk international ivano frankivsk ukraine', key:'A_IFO', cityBase: 59, city: 'IFO', country: 'UA', type: 'airport', nlabel: 'ivano frankivsk international ivano frankivsk ukraine'},
  { icon: 'faPlaneDeparture', label: 'IWA Ivanovo, Russia, Ivanovo Yuzhny, IWA, ivanovo yuzhny ivanovo russia', key:'A_IWA', cityBase: 55, city: 'IWA', country: 'RU', type: 'airport', nlabel: 'ivanovo yuzhny ivanovo russia'},
  { icon: 'faPlaneDeparture', label: 'TNR Antananarivo, Madagascar, Ivato International, TNR, ivato international antananarivo madagascar', key:'A_TNR', cityBase: 51, city: 'TNR', country: 'MG', type: 'airport', nlabel: 'ivato international antananarivo madagascar'},
  { icon: 'faPlaneDeparture', label: 'YIK Ivujivik, Canada, Ivujivik, YIK, ivujivik ivujivik quebec canada', key:'A_YIK', cityBase: 127, city: 'YIK', country: 'CA', type: 'airport', nlabel: 'ivujivik ivujivik quebec canada'},
  { icon: 'faPlaneDeparture', label: 'IWJ Masuda, Japan, Iwami, IWJ, iwami masuda japan', key:'A_IWJ', cityBase: 127, city: 'IWJ', country: 'JP', type: 'airport', nlabel: 'iwami masuda japan'},
  { icon: 'faPlaneDeparture', label: 'ZIH Ixtapa, Mexico, Ixtapa-Zihuatanejo International, ZIH, ixtapa zihuatanejo international ixtapa mexico', key:'A_ZIH', cityBase: 44, city: 'ZIH', country: 'MX', type: 'airport', nlabel: 'ixtapa zihuatanejo international ixtapa mexico'},
  { icon: 'faPlaneDeparture', label: 'IZT Ixtepec, Oaxaca, Mexico, Ixtepec, IZT, ixtepec ixtepec mexico', key:'A_IZT', cityBase: 127, city: 'IZT', country: 'MX', type: 'airport', nlabel: 'ixtepec ixtepec mexico'},
  { icon: 'faPlaneDeparture', label: 'IJK Izhevsk, Russia, Izhevsk, IJK, izhevsk izhevsk russia', key:'A_IJK', cityBase: 49, city: 'IJK', country: 'RU', type: 'airport', nlabel: 'izhevsk izhevsk russia'},
  { icon: 'faPlaneDeparture', label: 'IZO Izumo, Japan, Izumo, IZO, izumo izumo japan', key:'A_IZO', cityBase: 127, city: 'IZO', country: 'JP', type: 'airport', nlabel: 'izumo izumo japan'},
  { icon: 'faPlaneDeparture', label: 'JLR Jabalpur, India, Jabalpur, JLR, jabalpur jabalpur india', key:'A_JLR', cityBase: 127, city: 'JLR', country: 'IN', type: 'airport', nlabel: 'jabalpur jabalpur india'},
  { icon: 'faPlaneDeparture', label: 'BRM Barquisimeto, Venezuela, Jacinto Lara International, BRM, jacinto lara international barquisimeto venezuela', key:'A_BRM', cityBase: 79, city: 'BRM', country: 'VE', type: 'airport', nlabel: 'jacinto lara international barquisimeto venezuela'},
  { icon: 'faPlaneDeparture', label: 'BPT Beaumont, United States, Jack Brooks Regional, BPT, jack brooks regional beaumont texas united states', key:'A_BPT', cityBase: 107, city: 'BPT', country: 'US', type: 'airport', nlabel: 'jack brooks regional beaumont texas united states'},
  { icon: 'faPlaneDeparture', label: 'JAC Jackson, United States, Jackson Hole, JAC, jackson hole jackson wyoming united states', key:'A_JAC', cityBase: 28, city: 'JAC', country: 'US', type: 'airport', nlabel: 'jackson hole jackson wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'JAN Jackson, United States, Jackson–Evers International, JAN, jackson evers international jackson mississippi united states', key:'A_JAN', cityBase: 31, city: 'JAN', country: 'US', type: 'airport', nlabel: 'jackson evers international jackson mississippi united states'},
  { icon: 'faPlaneDeparture', label: 'POM Port Moresby, Papua New Guinea, Jacksons International, POM, jacksons international port moresby papua new guinea', key:'A_POM', cityBase: 63, city: 'POM', country: 'PG', type: 'airport', nlabel: 'jacksons international port moresby papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'JAX Jacksonville, United States, Jacksonville International, JAX, jacksonville international jacksonville florida united states', key:'A_JAX', cityBase: 7, city: 'JAX', country: 'US', type: 'airport', nlabel: 'jacksonville international jacksonville florida united states'},
  { icon: 'faPlaneDeparture', label: 'JAE Jaén, Peru, Jaén, JAE, jaen jaen peru', key:'A_JAE', cityBase: 127, city: 'JAE', country: 'PE', type: 'airport', nlabel: 'jaen jaen peru'},
  { icon: 'faPlaneDeparture', label: 'GDT Cockburn Town, Turks & Caicos Islands, JAGS McCartney International, GDT, jags mccartney international cockburn town turks and caicos islands', key:'A_GDT', cityBase: 112, city: 'GDT', country: 'TC', type: 'airport', nlabel: 'jags mccartney international cockburn town turks and caicos islands'},
  { icon: 'faPlaneDeparture', label: 'JJG Jaguaruna, Brazil, Jaguaruna Regional, JJG, jaguaruna regional jaguaruna state of santa catarina brazil', key:'A_JJG', cityBase: 127, city: 'JJG', country: 'BR', type: 'airport', nlabel: 'jaguaruna regional jaguaruna state of santa catarina brazil'},
  { icon: 'faPlaneDeparture', label: 'CFG Cienfuegos, Cuba, Jaime González, CFG, jaime gonzalez cienfuegos cuba', key:'A_CFG', cityBase: 127, city: 'CFG', country: 'CU', type: 'airport', nlabel: 'jaime gonzalez cienfuegos cuba'},
  { icon: 'faPlaneDeparture', label: 'JAI Jaipur, India, Jaipur International, JAI, jaipur international jaipur india', key:'A_JAI', cityBase: 56, city: 'JAI', country: 'IN', type: 'airport', nlabel: 'jaipur international jaipur india'},
  { icon: 'faPlaneDeparture', label: 'JSA Jaisalmer, India, Jaisalmer, JSA, jaisalmer jaisalmer india', key:'A_JSA', cityBase: 97, city: 'JSA', country: 'IN', type: 'airport', nlabel: 'jaisalmer jaisalmer india'},
  { icon: 'faPlaneDeparture', label: 'GTO Gorontalo, Indonesia, Jalaluddin, GTO, jalaluddin gorontalo indonesia', key:'A_GTO', cityBase: 43, city: 'GTO', country: 'ID', type: 'airport', nlabel: 'jalaluddin gorontalo indonesia'},
  { icon: 'faPlaneDeparture', label: 'JMS Jamestown, United States, Jamestown Regional, JMS, jamestown regional jamestown north dakota united states', key:'A_JMS', cityBase: 101, city: 'JMS', country: 'US', type: 'airport', nlabel: 'jamestown regional jamestown north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'IXJ Jammu, Unknown Region, Jammu, IXJ, jammu jammu unknown region', key:'A_IXJ', cityBase: 92, city: 'IXJ', country: 'ZZ', type: 'airport', nlabel: 'jammu jammu unknown region'},
  { icon: 'faPlaneDeparture', label: 'JGA Jamnagar, India, Jamnagar, JGA, jamnagar jamnagar india', key:'A_JGA', cityBase: 127, city: 'JGA', country: 'IN', type: 'airport', nlabel: 'jamnagar jamnagar india'},
  { icon: 'faPlaneDeparture', label: 'JKR Janakpur, Nepal, Janakpur, JKR, janakpur janakpur nepal', key:'A_JKR', cityBase: 127, city: 'JKR', country: 'NP', type: 'airport', nlabel: 'janakpur janakpur nepal'},
  { icon: 'faPlaneDeparture', label: 'JQE Jaqué, Panama, Jaque, JQE, jaque jaque panama', key:'A_JQE', cityBase: 127, city: 'JQE', country: 'PA', type: 'airport', nlabel: 'jaque jaque panama'},
  { icon: 'faPlaneDeparture', label: 'CCC Cayo Coco, Cuba, Jardines del Rey, CCC, jardines del rey cayo coco cuba', key:'A_CCC', cityBase: 44, city: 'CCC', country: 'CU', type: 'airport', nlabel: 'jardines del rey cayo coco cuba'},
  { icon: 'faPlaneDeparture', label: 'PAT Patna, India, Jay Prakash Narayan International, PAT, jay prakash narayan international patna india', key:'A_PAT', cityBase: 85, city: 'PAT', country: 'IN', type: 'airport', nlabel: 'jay prakash narayan international patna india'},
  { icon: 'faPlaneDeparture', label: 'GIZ Jizan, Saudi Arabia, Jazan, GIZ, jazan jizan saudi arabia', key:'A_GIZ', cityBase: 88, city: 'GIZ', country: 'SA', type: 'airport', nlabel: 'jazan jizan saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'CJU Jeju City, South Korea, Jeju International, CJU, jeju international jeju city south korea', key:'A_CJU', cityBase: 31, city: 'CJU', country: 'KR', type: 'airport', nlabel: 'jeju international jeju city south korea'},
  { icon: 'faPlaneDeparture', label: 'XRY Jerez de la Frontera, Spain, Jerez, XRY, jerez jerez de la frontera spain', key:'A_XRY', cityBase: 12, city: 'XRY', country: 'ES', type: 'airport', nlabel: 'jerez jerez de la frontera spain'},
  { icon: 'faPlaneDeparture', label: 'JER Saint Helier, Jersey, Jersey, JER, jersey saint helier jersey', key:'A_JER', cityBase: 83, city: 'JER', country: 'JE', type: 'airport', nlabel: 'jersey saint helier jersey'},
  { icon: 'faPlaneDeparture', label: 'JSR Jessore, Bangladesh, Jessore, JSR, jessore jessore bangladesh', key:'A_JSR', cityBase: 125, city: 'JSR', country: 'BD', type: 'airport', nlabel: 'jessore jessore bangladesh'},
  { icon: 'faPlaneDeparture', label: 'JPR Ji-Paraná, Brazil, Ji-Paraná, JPR, ji parana ji parana state of rondonia brazil', key:'A_JPR', cityBase: 127, city: 'JPR', country: 'BR', type: 'airport', nlabel: 'ji parana ji parana state of rondonia brazil'},
  { icon: 'faPlaneDeparture', label: 'JGD Jiagedaqi District, China, Jiagedaqi, JGD, jiagedaqi jiagedaqi district china', key:'A_JGD', cityBase: 84, city: 'JGD', country: 'CN', type: 'airport', nlabel: 'jiagedaqi jiagedaqi district china'},
  { icon: 'faPlaneDeparture', label: 'JMU Jiamusi, China, Jiamusi Dongjiao, JMU, jiamusi dongjiao jiamusi china', key:'A_JMU', cityBase: 68, city: 'JMU', country: 'CN', type: 'airport', nlabel: 'jiamusi dongjiao jiamusi china'},
  { icon: 'faPlaneDeparture', label: 'JGN Jiayuguan City, China, Jiayuguan, JGN, jiayuguan jiayuguan city china', key:'A_JGN', cityBase: 72, city: 'JGN', country: 'CN', type: 'airport', nlabel: 'jiayuguan jiayuguan city china'},
  { icon: 'faPlaneDeparture', label: 'SWA Shantou, China, Jieyang Chaoshan International, SWA, jieyang chaoshan international shantou china', key:'A_SWA', cityBase: 32, city: 'SWA', country: 'CN', type: 'airport', nlabel: 'jieyang chaoshan international shantou china'},
  { icon: 'faPlaneDeparture', label: 'GJL Jijel, Algeria, Jijel Ferhat Abbas, GJL, jijel ferhat abbas jijel algeria', key:'A_GJL', cityBase: 112, city: 'GJL', country: 'DZ', type: 'airport', nlabel: 'jijel ferhat abbas jijel algeria'},
  { icon: 'faPlaneDeparture', label: 'JIJ Jijiga, Ethiopia, Jijiga, JIJ, jijiga jijiga ethiopia', key:'A_JIJ', cityBase: 127, city: 'JIJ', country: 'ET', type: 'airport', nlabel: 'jijiga jijiga ethiopia'},
  { icon: 'faPlaneDeparture', label: 'TNA Jinan, China, Jinan Yaoqiang International, TNA, jinan yaoqiang international jinan china', key:'A_TNA', cityBase: 50, city: 'TNA', country: 'CN', type: 'airport', nlabel: 'jinan yaoqiang international jinan china'},
  { icon: 'faPlaneDeparture', label: 'JIC Jinchang, China, Jinchang Jinchuan, JIC, jinchang jinchuan jinchang china', key:'A_JIC', cityBase: 67, city: 'JIC', country: 'CN', type: 'airport', nlabel: 'jinchang jinchuan jinchang china'},
  { icon: 'faPlaneDeparture', label: 'JDZ Jingdezhen, China, Jingdezhen Luojia, JDZ, jingdezhen luojia jingdezhen china', key:'A_JDZ', cityBase: 75, city: 'JDZ', country: 'CN', type: 'airport', nlabel: 'jingdezhen luojia jingdezhen china'},
  { icon: 'faPlaneDeparture', label: 'JGS Ji an, China, Jinggangshan, JGS, jinggangshan ji an china', key:'A_JGS', cityBase: 77, city: 'JGS', country: 'CN', type: 'airport', nlabel: 'jinggangshan ji an china'},
  { icon: 'faPlaneDeparture', label: 'JNG Jining, China, Jining Qufu, JNG, jining qufu jining china', key:'A_JNG', cityBase: 78, city: 'JNG', country: 'CN', type: 'airport', nlabel: 'jining qufu jining china'},
  { icon: 'faPlaneDeparture', label: 'KHI Karachi, Pakistan, Jinnah International, KHI, jinnah international karachi pakistan', key:'A_KHI', cityBase: 25, city: 'KHI', country: 'PK', type: 'airport', nlabel: 'jinnah international karachi pakistan'},
  { icon: 'faPlaneDeparture', label: 'JNZ Jinzhou, China, Jinzhou Xiaolingzi, JNZ, jinzhou xiaolingzi jinzhou china', key:'A_JNZ', cityBase: 76, city: 'JNZ', country: 'CN', type: 'airport', nlabel: 'jinzhou xiaolingzi jinzhou china'},
  { icon: 'faPlaneDeparture', label: 'JZH Jiuzhaigou County, China, Jiuzhai Huanglong, JZH, jiuzhai huanglong jiuzhaigou county china', key:'A_JZH', cityBase: 67, city: 'JZH', country: 'CN', type: 'airport', nlabel: 'jiuzhai huanglong jiuzhaigou county china'},
  { icon: 'faPlaneDeparture', label: 'JXA Jixi, China, Jixi Xingkaihu, JXA, jixi xingkaihu jixi china', key:'A_JXA', cityBase: 67, city: 'JXA', country: 'CN', type: 'airport', nlabel: 'jixi xingkaihu jixi china'},
  { icon: 'faPlaneDeparture', label: 'PDL Ponta Delgada, Portugal, João Paulo II, PDL, joao paulo ii ponta delgada portugal', key:'A_PDL', cityBase: 15, city: 'PDL', country: 'PT', type: 'airport', nlabel: 'joao paulo ii ponta delgada portugal'},
  { icon: 'faPlaneDeparture', label: 'JDH Jodhpur, India, Jodhpur, JDH, jodhpur jodhpur india', key:'A_JDH', cityBase: 94, city: 'JDH', country: 'IN', type: 'airport', nlabel: 'jodhpur jodhpur india'},
  { icon: 'faPlaneDeparture', label: 'JOE Joensuu, Finland, Joensuu, JOE, joensuu joensuu finland', key:'A_JOE', cityBase: 107, city: 'JOE', country: 'FI', type: 'airport', nlabel: 'joensuu joensuu finland'},
  { icon: 'faPlaneDeparture', label: 'PBM Paramaribo, Suriname, Johan Adolf Pengel International, PBM, johan adolf pengel international paramaribo suriname', key:'A_PBM', cityBase: 54, city: 'PBM', country: 'SR', type: 'airport', nlabel: 'johan adolf pengel international paramaribo suriname'},
  { icon: 'faPlaneDeparture', label: 'MNI Brades, United Kingdom, John A. Osborne, MNI, john a osborne brades montserrat', key:'A_MNI', cityBase: -873, city: 'MNI', country: 'GB', type: 'airport', nlabel: 'john a osborne brades montserrat'},
  { icon: 'faPlaneDeparture', label: 'YHM Hamilton, Canada, John C. Munro Hamilton International, YHM, john c munro hamilton international hamilton ontario canada', key:'A_YHM', cityBase: 63, city: 'YHM', country: 'CA', type: 'airport', nlabel: 'john c munro hamilton international hamilton ontario canada'},
  { icon: 'faPlaneDeparture', label: 'JFK New York City, United States, John F. Kennedy International, JFK, john f kennedy international new york city new york united states', key:'A_JFK', cityBase: 0, city: 'NYC', country: 'US', type: 'airport', nlabel: 'john f kennedy international new york city new york united states'},
  { icon: 'faPlaneDeparture', label: 'CMH Columbus, United States, John Glenn Columbus International, CMH, john glenn columbus international columbus ohio united states', key:'A_CMH', cityBase: 6, city: 'CMH', country: 'US', type: 'airport', nlabel: 'john glenn columbus international columbus ohio united states'},
  { icon: 'faPlaneDeparture', label: 'KRK Kraków, Poland, John Paul II International Airport Kraków–Balice, KRK, john paul ii international airport krakow balice krakow poland', key:'A_KRK', cityBase: 2, city: 'KRK', country: 'PL', type: 'airport', nlabel: 'john paul ii international airport krakow balice krakow poland'},
  { icon: 'faPlaneDeparture', label: 'SNA Santa Ana, United States, John Wayne, SNA, john wayne santa ana california united states', key:'A_SNA', cityBase: 7, city: 'SNA', country: 'US', type: 'airport', nlabel: 'john wayne santa ana california united states'},
  { icon: 'faPlaneDeparture', label: 'JST Johnstown, United States, Johnstown–Cambria County, JST, johnstown cambria county johnstown pennsylvania united states', key:'A_JST', cityBase: 127, city: 'JST', country: 'US', type: 'airport', nlabel: 'johnstown cambria county johnstown pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'JOI Joinville, Brazil, Joinville-Lauro Carneiro de Loyola, JOI, joinville lauro carneiro de loyola joinville state of santa catarina brazil', key:'A_JOI', cityBase: 118, city: 'JOI', country: 'BR', type: 'airport', nlabel: 'joinville lauro carneiro de loyola joinville state of santa catarina brazil'},
  { icon: 'faPlaneDeparture', label: 'NBO Nairobi, Kenya, Jomo Kenyatta International, NBO, jomo kenyatta international nairobi kenya', key:'A_NBO', cityBase: 26, city: 'NBO', country: 'KE', type: 'airport', nlabel: 'jomo kenyatta international nairobi kenya'},
  { icon: 'faPlaneDeparture', label: 'JBR Jonesboro, United States, Jonesboro Municipal, JBR, jonesboro municipal jonesboro arkansas united states', key:'A_JBR', cityBase: 127, city: 'JBR', country: 'US', type: 'airport', nlabel: 'jonesboro municipal jonesboro arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'JKG Jönköping, Sweden, Jönköping, JKG, jonkoping jonkoping sweden', key:'A_JKG', cityBase: 101, city: 'JKG', country: 'SE', type: 'airport', nlabel: 'jonkoping jonkoping sweden'},
  { icon: 'faPlaneDeparture', label: 'JLN Joplin, United States, Joplin Regional, JLN, joplin regional joplin missouri united states', key:'A_JLN', cityBase: 83, city: 'JLN', country: 'US', type: 'airport', nlabel: 'joplin regional joplin missouri united states'},
  { icon: 'faPlaneDeparture', label: 'LIM Lima, Peru, Jorge Chávez International, LIM, jorge chavez international lima peru', key:'A_LIM', cityBase: 7, city: 'LIM', country: 'PE', type: 'airport', nlabel: 'jorge chavez international lima peru'},
  { icon: 'faPlaneDeparture', label: 'SJE San José del Guaviare, Colombia, Jorge Enrique González Torres, SJE, jorge enrique gonzalez torres san jose del guaviare colombia', key:'A_SJE', cityBase: 127, city: 'SJE', country: 'CO', type: 'airport', nlabel: 'jorge enrique gonzalez torres san jose del guaviare colombia'},
  { icon: 'faPlaneDeparture', label: 'CBB Cochabamba, Bolivia, Jorge Wilstermann International, CBB, jorge wilstermann international cochabamba bolivia', key:'A_CBB', cityBase: 55, city: 'CBB', country: 'BO', type: 'airport', nlabel: 'jorge wilstermann international cochabamba bolivia'},
  { icon: 'faPlaneDeparture', label: 'JRH Jorhat, India, Jorhat, JRH, jorhat jorhat india', key:'A_JRH', cityBase: 127, city: 'JRH', country: 'IN', type: 'airport', nlabel: 'jorhat jorhat india'},
  { icon: 'faPlaneDeparture', label: 'NRR Roosevelt Roads Naval Station, United States, José Aponte de la Torre, NRR, jose aponte de la torre roosevelt roads naval station puerto rico united states', key:'A_NRR', cityBase: 120, city: 'NRR', country: 'US', type: 'airport', nlabel: 'jose aponte de la torre roosevelt roads naval station puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'BSC Bahía Solano, Colombia, Jose Celestino Mutis, BSC, jose celestino mutis bahia solano colombia', key:'A_BSC', cityBase: 127, city: 'BSC', country: 'CO', type: 'airport', nlabel: 'jose celestino mutis bahia solano colombia'},
  { icon: 'faPlaneDeparture', label: 'GYE Guayaquil, Ecuador, José Joaquín de Olmedo International, GYE, jose joaquin de olmedo international guayaquil ecuador', key:'A_GYE', cityBase: 19, city: 'GYE', country: 'EC', type: 'airport', nlabel: 'jose joaquin de olmedo international guayaquil ecuador'},
  { icon: 'faPlaneDeparture', label: 'CZE Coro, Venezuela, José Leonardo Chirino, CZE, jose leonardo chirino coro venezuela', key:'A_CZE', cityBase: 127, city: 'CZE', country: 'VE', type: 'airport', nlabel: 'jose leonardo chirino coro venezuela'},
  { icon: 'faPlaneDeparture', label: 'MDE Medellín, Colombia, José María Córdova International, MDE, jose maria cordova international medellin colombia', key:'A_MDE', cityBase: 20, city: 'MDE', country: 'CO', type: 'airport', nlabel: 'jose maria cordova international medellin colombia'},
  { icon: 'faPlaneDeparture', label: 'HAV Havana, Cuba, José Martí International, HAV, jose marti international havana cuba', key:'A_HAV', cityBase: 15, city: 'HAV', country: 'CU', type: 'airport', nlabel: 'jose marti international havana cuba'},
  { icon: 'faPlaneDeparture', label: 'LSP Las Piedras, Venezuela, Josefa Camejo International, LSP, josefa camejo international las piedras venezuela', key:'A_LSP', cityBase: 104, city: 'LSP', country: 'VE', type: 'airport', nlabel: 'josefa camejo international las piedras venezuela'},
  { icon: 'faPlaneDeparture', label: 'BUQ Bulawayo, Zimbabwe, Joshua Mqabuko Nkomo International, BUQ, joshua mqabuko nkomo international bulawayo zimbabwe', key:'A_BUQ', cityBase: 88, city: 'BUQ', country: 'ZW', type: 'airport', nlabel: 'joshua mqabuko nkomo international bulawayo zimbabwe'},
  { icon: 'faPlaneDeparture', label: 'VRA Varadero, Cuba, Juan Gualberto Gómez, VRA, juan gualberto gomez varadero cuba', key:'A_VRA', cityBase: 39, city: 'VRA', country: 'CU', type: 'airport', nlabel: 'juan gualberto gomez varadero cuba'},
  { icon: 'faPlaneDeparture', label: 'CAQ Caucasia, Colombia, Juan H. White, CAQ, juan h white caucasia colombia', key:'A_CAQ', cityBase: 127, city: 'CAQ', country: 'CO', type: 'airport', nlabel: 'juan h white caucasia colombia'},
  { icon: 'faPlaneDeparture', label: 'RTB Coxen Hole, Honduras, Juan Manuel Gálvez International, RTB, juan manuel galvez international coxen hole honduras', key:'A_RTB', cityBase: 52, city: 'RTB', country: 'HN', type: 'airport', nlabel: 'juan manuel galvez international coxen hole honduras'},
  { icon: 'faPlaneDeparture', label: 'ORU Oruro, Bolivia, Juan Mendoza, ORU, juan mendoza oruro bolivia', key:'A_ORU', cityBase: 127, city: 'ORU', country: 'BO', type: 'airport', nlabel: 'juan mendoza oruro bolivia'},
  { icon: 'faPlaneDeparture', label: 'VIG El Vigía, Venezuela, Juan Pablo Pérez Alfonzo, VIG, juan pablo perez alfonzo el vigia venezuela', key:'A_VIG', cityBase: 78, city: 'VIG', country: 'VE', type: 'airport', nlabel: 'juan pablo perez alfonzo el vigia venezuela'},
  { icon: 'faPlaneDeparture', label: 'SJO San José, Costa Rica, Juan Santamaría International, SJO, juan santamaria international san jose costa rica', key:'A_SJO', cityBase: 6, city: 'SJO', country: 'CR', type: 'airport', nlabel: 'juan santamaria international san jose costa rica'},
  { icon: 'faPlaneDeparture', label: 'SAB Saba, Netherlands, Juancho E. Yrausquin, SAB, juancho e yrausquin saba caribbean netherlands netherlands', key:'A_SAB', cityBase: 111, city: 'SAB', country: 'NL', type: 'airport', nlabel: 'juancho e yrausquin saba caribbean netherlands netherlands'},
  { icon: 'faPlaneDeparture', label: 'SUB Surabaya, Indonesia, Juanda International, SUB, juanda international surabaya indonesia', key:'A_SUB', cityBase: 50, city: 'SUB', country: 'ID', type: 'airport', nlabel: 'juanda international surabaya indonesia'},
  { icon: 'faPlaneDeparture', label: 'JDO Juazeiro do Norte, Brazil, Juazeiro do Norte, JDO, juazeiro do norte juazeiro do norte state of ceara brazil', key:'A_JDO', cityBase: 127, city: 'JDO', country: 'BR', type: 'airport', nlabel: 'juazeiro do norte juazeiro do norte state of ceara brazil'},
  { icon: 'faPlaneDeparture', label: 'JUB Juba, South Sudan, Juba International, JUB, juba international juba south sudan', key:'A_JUB', cityBase: 85, city: 'JUB', country: 'SS', type: 'airport', nlabel: 'juba international juba south sudan'},
  { icon: 'faPlaneDeparture', label: 'JCK Julia Creek, Australia, Julia Creek, JCK, julia creek julia creek queensland australia', key:'A_JCK', cityBase: 127, city: 'JCK', country: 'AU', type: 'airport', nlabel: 'julia creek julia creek queensland australia'},
  { icon: 'faPlaneDeparture', label: 'DAR Dar es Salaam, Tanzania, Julius Nyerere International, DAR, julius nyerere international dar es salaam tanzania', key:'A_DAR', cityBase: 42, city: 'DAR', country: 'TZ', type: 'airport', nlabel: 'julius nyerere international dar es salaam tanzania'},
  { icon: 'faPlaneDeparture', label: 'JNU Juneau, United States, Juneau International, JNU, juneau international juneau alaska united states', key:'A_JNU', cityBase: 51, city: 'JNU', country: 'US', type: 'airport', nlabel: 'juneau international juneau alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TRK Tarakan, North Kalimantan, Indonesia, Juwata International, TRK, juwata international tarakan indonesia', key:'A_TRK', cityBase: 127, city: 'TRK', country: 'ID', type: 'airport', nlabel: 'juwata international tarakan indonesia'},
  { icon: 'faPlaneDeparture', label: 'JYV Jyväskylä, Finland, Jyvaskyla, JYV, jyvaskyla jyvaskyla finland', key:'A_JYV', cityBase: 127, city: 'JYV', country: 'FI', type: 'airport', nlabel: 'jyvaskyla jyvaskyla finland'},
  { icon: 'faPlaneDeparture', label: 'ABK Kebri Dahar, Ethiopia, Kabri Dar, ABK, kabri dar kebri dahar ethiopia', key:'A_ABK', cityBase: 127, city: 'ABK', country: 'ET', type: 'airport', nlabel: 'kabri dar kebri dahar ethiopia'},
  { icon: 'faPlaneDeparture', label: 'HTA Chita, Russia, Kadala, HTA, kadala chita russia', key:'A_HTA', cityBase: 46, city: 'HTA', country: 'RU', type: 'airport', nlabel: 'kadala chita russia'},
  { icon: 'faPlaneDeparture', label: 'KAD Kaduna, Nigeria, Kaduna International, KAD, kaduna international kaduna nigeria', key:'A_KAD', cityBase: 112, city: 'KAD', country: 'NG', type: 'airport', nlabel: 'kaduna international kaduna nigeria'},
  { icon: 'faPlaneDeparture', label: 'KGE Kaghau Island, Solomon Islands, Kaghau, KGE, kaghau kaghau island solomon islands', key:'A_KGE', cityBase: 127, city: 'KGE', country: 'SB', type: 'airport', nlabel: 'kaghau kaghau island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'KOJ Kagoshima, Japan, Kagoshima, KOJ, kagoshima kagoshima japan', key:'A_KOJ', cityBase: 91, city: 'KOJ', country: 'JP', type: 'airport', nlabel: 'kagoshima kagoshima japan'},
  { icon: 'faPlaneDeparture', label: 'KCM Kahramanmaraş, Turkey, Kahramanmaraş, KCM, kahramanmaras kahramanmaras turkey', key:'A_KCM', cityBase: 23, city: 'KCM', country: 'TR', type: 'airport', nlabel: 'kahramanmaras kahramanmaras turkey'},
  { icon: 'faPlaneDeparture', label: 'OGG Kahului, United States, Kahului, OGG, kahului kahului hawaii united states', key:'A_OGG', cityBase: 7, city: 'OGG', country: 'US', type: 'airport', nlabel: 'kahului kahului hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'KJH Huangping County, China, Kaili Huangping, KJH, kaili huangping huangping county china', key:'A_KJH', cityBase: 69, city: 'KJH', country: 'CN', type: 'airport', nlabel: 'kaili huangping huangping county china'},
  { icon: 'faPlaneDeparture', label: 'KNG Kaimana, Indonesia, Kaimana, KNG, kaimana kaimana indonesia', key:'A_KNG', cityBase: 127, city: 'KNG', country: 'ID', type: 'airport', nlabel: 'kaimana kaimana indonesia'},
  { icon: 'faPlaneDeparture', label: 'KAJ Kajaani, Finland, Kajaani, KAJ, kajaani kajaani finland', key:'A_KAJ', cityBase: 108, city: 'KAJ', country: 'FI', type: 'airport', nlabel: 'kajaani kajaani finland'},
  { icon: 'faPlaneDeparture', label: 'GGM Kakamega, Kenya, Kakamega, GGM, kakamega kakamega kenya', key:'A_GGM', cityBase: 127, city: 'GGM', country: 'KE', type: 'airport', nlabel: 'kakamega kakamega kenya'},
  { icon: 'faPlaneDeparture', label: 'KAE Kake, United States, Kake Sea Plane Base, KAE, kake sea plane base kake alaska united states', key:'A_KAE', cityBase: 127, city: 'KAE', country: 'US', type: 'airport', nlabel: 'kake sea plane base kake alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KLX Kalamata, Greece, Kalamata International, KLX, kalamata international kalamata greece', key:'A_KLX', cityBase: 17, city: 'KLX', country: 'GR', type: 'airport', nlabel: 'kalamata international kalamata greece'},
  { icon: 'faPlaneDeparture', label: 'AZO Kalamazoo, United States, Kalamazoo/Battle Creek International, AZO, kalamazoo battle creek international kalamazoo michigan united states', key:'A_AZO', cityBase: 66, city: 'AZO', country: 'US', type: 'airport', nlabel: 'kalamazoo battle creek international kalamazoo michigan united states'},
  { icon: 'faPlaneDeparture', label: 'KMV Kalay, Myanmar (Burma), Kalaymyo, KMV, kalaymyo kalay myanmar (burma)', key:'A_KMV', cityBase: 127, city: 'KMV', country: 'MM', type: 'airport', nlabel: 'kalaymyo kalay myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'FMI Kalemie, Democratic Republic of the Congo, Kalemie, FMI, kalemie kalemie democratic republic of the congo', key:'A_FMI', cityBase: 127, city: 'FMI', country: 'CD', type: 'airport', nlabel: 'kalemie kalemie democratic republic of the congo'},
  { icon: 'faPlaneDeparture', label: 'KGI Kalgoorlie, Australia, Kalgoorlie-Boulder, KGI, kalgoorlie boulder kalgoorlie western australia australia', key:'A_KGI', cityBase: 127, city: 'KGI', country: 'AU', type: 'airport', nlabel: 'kalgoorlie boulder kalgoorlie western australia australia'},
  { icon: 'faPlaneDeparture', label: 'KLO Kalibo, Philippines, Kalibo International, KLO, kalibo international kalibo philippines', key:'A_KLO', cityBase: 81, city: 'KLO', country: 'PH', type: 'airport', nlabel: 'kalibo international kalibo philippines'},
  { icon: 'faPlaneDeparture', label: 'BEJ Tanjung Redeb, Indonesia, Kalimarau, BEJ, kalimarau tanjung redeb indonesia', key:'A_BEJ', cityBase: 62, city: 'BEJ', country: 'ID', type: 'airport', nlabel: 'kalimarau tanjung redeb indonesia'},
  { icon: 'faPlaneDeparture', label: 'KLR Kalmar, Sweden, Kalmar, KLR, kalmar kalmar sweden', key:'A_KLR', cityBase: 123, city: 'KLR', country: 'SE', type: 'airport', nlabel: 'kalmar kalmar sweden'},
  { icon: 'faPlaneDeparture', label: 'KLG Upper Kalskag, United States, Kalskag, KLG, kalskag upper kalskag alaska united states', key:'A_KLG', cityBase: 127, city: 'KLG', country: 'US', type: 'airport', nlabel: 'kalskag upper kalskag alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KAL Kaltag, United States, Kaltag, KAL, kaltag kaltag alaska united states', key:'A_KAL', cityBase: 127, city: 'KAL', country: 'US', type: 'airport', nlabel: 'kaltag kaltag alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KLF Kaluga, Russia, Kaluga (Grabtsevo), KLF, kaluga (grabtsevo) kaluga russia', key:'A_KLF', cityBase: 61, city: 'KLF', country: 'RU', type: 'airport', nlabel: 'kaluga (grabtsevo) kaluga russia'},
  { icon: 'faPlaneDeparture', label: 'JKL Kalymnos, Greece, Kalymnos Island National, JKL, kalymnos island national kalymnos greece', key:'A_JKL', cityBase: 26, city: 'JKL', country: 'GR', type: 'airport', nlabel: 'kalymnos island national kalymnos greece'},
  { icon: 'faPlaneDeparture', label: 'KME Cyangugu, Rwanda, Kamembe, KME, kamembe cyangugu rwanda', key:'A_KME', cityBase: 127, city: 'KME', country: 'RW', type: 'airport', nlabel: 'kamembe cyangugu rwanda'},
  { icon: 'faPlaneDeparture', label: 'YKA Kamloops, Canada, Kamloops, YKA, kamloops kamloops british columbia canada', key:'A_YKA', cityBase: 85, city: 'YKA', country: 'CA', type: 'airport', nlabel: 'kamloops kamloops british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'KGA Kananga, Democratic Republic of the Congo, Kananga, KGA, kananga kananga congo kinshasa', key:'A_KGA', cityBase: 127, city: 'KGA', country: 'CD', type: 'airport', nlabel: 'kananga kananga congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'KJI Burqin, China, Kanas, KJI, kanas burqin china', key:'A_KJI', cityBase: 127, city: 'KJI', country: 'CN', type: 'airport', nlabel: 'kanas burqin china'},
  { icon: 'faPlaneDeparture', label: 'KDH Kandahar, Afghanistan, Kandahar International, KDH, kandahar international kandahar afghanistan', key:'A_KDH', cityBase: 127, city: 'KDH', country: 'AF', type: 'airport', nlabel: 'kandahar international kandahar afghanistan'},
  { icon: 'faPlaneDeparture', label: 'IXY Kandla, India, Kandla, IXY, kandla kandla india', key:'A_IXY', cityBase: 127, city: 'IXY', country: 'IN', type: 'airport', nlabel: 'kandla kandla india'},
  { icon: 'faPlaneDeparture', label: 'KDZ Kandy, Sri Lanka, Kandy Polgolla Reservoir SPB, KDZ, kandy polgolla reservoir spb kandy sri lanka', key:'A_KDZ', cityBase: 127, city: 'KDW', country: 'LK', type: 'airport', nlabel: 'kandy polgolla reservoir spb kandy sri lanka'},
  { icon: 'faPlaneDeparture', label: 'KGT Kangding, China, Kangding, KGT, kangding kangding china', key:'A_KGT', cityBase: 75, city: 'KGT', country: 'CN', type: 'airport', nlabel: 'kangding kangding china'},
  { icon: 'faPlaneDeparture', label: 'SFJ Kangerlussuaq, Greenland, Kangerlussuaq, SFJ, kangerlussuaq kangerlussuaq greenland', key:'A_SFJ', cityBase: 117, city: 'SFJ', country: 'GL', type: 'airport', nlabel: 'kangerlussuaq kangerlussuaq greenland'},
  { icon: 'faPlaneDeparture', label: 'XGR Kangiqsualujjuaq, Canada, Kangiqsualujjuaq (Georges River), XGR, kangiqsualujjuaq (georges river) kangiqsualujjuaq quebec canada', key:'A_XGR', cityBase: 127, city: 'XGR', country: 'CA', type: 'airport', nlabel: 'kangiqsualujjuaq (georges river) kangiqsualujjuaq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YWB Kangiqsujuaq, Canada, Kangiqsujuaq (Wakeham Bay), YWB, kangiqsujuaq (wakeham bay) kangiqsujuaq quebec canada', key:'A_YWB', cityBase: 127, city: 'YWB', country: 'CA', type: 'airport', nlabel: 'kangiqsujuaq (wakeham bay) kangiqsujuaq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YKG Kangirsuk, Canada, Kangirsuk, YKG, kangirsuk kangirsuk quebec canada', key:'A_YKG', cityBase: 127, city: 'YKG', country: 'CA', type: 'airport', nlabel: 'kangirsuk kangirsuk quebec canada'},
  { icon: 'faPlaneDeparture', label: 'KNU Kanpur, India, Kanpur, KNU, kanpur kanpur india', key:'A_KNU', cityBase: 114, city: 'KNU', country: 'IN', type: 'airport', nlabel: 'kanpur kanpur india'},
  { icon: 'faPlaneDeparture', label: 'KIX Osaka, Japan, Kansai International, KIX, kansai international osaka japan', key:'A_KIX', cityBase: 30, city: 'OSA', country: 'JP', type: 'airport', nlabel: 'kansai international osaka japan'},
  { icon: 'faPlaneDeparture', label: 'MCI Kansas City, United States, Kansas City International, MCI, kansas city international kansas city missouri united states', key:'A_MCI', cityBase: 4, city: 'MKC', country: 'US', type: 'airport', nlabel: 'kansas city international kansas city missouri united states'},
  { icon: 'faPlaneDeparture', label: 'KHH Kaohsiung, Taiwan, Kaohsiung International, KHH, kaohsiung international kaohsiung taiwan', key:'A_KHH', cityBase: 38, city: 'KHH', country: 'TW', type: 'airport', nlabel: 'kaohsiung international kaohsiung taiwan'},
  { icon: 'faPlaneDeparture', label: 'JHM Kapalua, United States, Kapalua, JHM, kapalua kapalua hawaii united states', key:'A_JHM', cityBase: 41, city: 'JHM', country: 'US', type: 'airport', nlabel: 'kapalua kapalua hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'PPQ Paraparaumu, New Zealand, Kapiti Coast, PPQ, kapiti coast paraparaumu new zealand', key:'A_PPQ', cityBase: 127, city: 'PPQ', country: 'NZ', type: 'airport', nlabel: 'kapiti coast paraparaumu new zealand'},
  { icon: 'faPlaneDeparture', label: 'YYU Kapuskasing, Canada, Kapuskasing, YYU, kapuskasing kapuskasing ontario canada', key:'A_YYU', cityBase: 127, city: 'YYU', country: 'CA', type: 'airport', nlabel: 'kapuskasing kapuskasing ontario canada'},
  { icon: 'faPlaneDeparture', label: 'KRY Karamay, China, Karamay, KRY, karamay karamay china', key:'A_KRY', cityBase: 74, city: 'KRY', country: 'CN', type: 'airport', nlabel: 'karamay karamay china'},
  { icon: 'faPlaneDeparture', label: 'LUV Langgur, Indonesia, Karel Sadsuitubun Airport, LUV, dumatubin langgur indonesia', key:'A_LUV', cityBase: 127, city: 'LUV', country: 'ID', type: 'airport', nlabel: 'dumatubin langgur indonesia'},
  { icon: 'faPlaneDeparture', label: 'KAB Kariba, Zimbabwe, Kariba International Airport, KAB, kariba international airport kariba zimbabwe', key:'A_KAB', cityBase: 127, city: 'KAB', country: 'ZW', type: 'airport', nlabel: 'kariba international airport kariba zimbabwe'},
  { icon: 'faPlaneDeparture', label: 'KLV Karlovy Vary, Czechia, Karlovy Vary, KLV, karlovy vary karlovy vary czechia', key:'A_KLV', cityBase: 60, city: 'KLV', country: 'CZ', type: 'airport', nlabel: 'karlovy vary karlovy vary czechia'},
  { icon: 'faPlaneDeparture', label: 'FKB Karlsruhe, Germany, Karlsruhe/Baden-Baden, FKB, karlsruhe baden baden karlsruhe germany', key:'A_FKB', cityBase: 6, city: 'FKB', country: 'DE', type: 'airport', nlabel: 'karlsruhe baden baden karlsruhe germany'},
  { icon: 'faPlaneDeparture', label: 'KSD Karlstad, Sweden, Karlstad, KSD, karlstad karlstad sweden', key:'A_KSD', cityBase: 104, city: 'KSD', country: 'SE', type: 'airport', nlabel: 'karlstad karlstad sweden'},
  { icon: 'faPlaneDeparture', label: 'AOK Karpathos, Greece, Karpathos Island National, AOK, karpathos island national karpathos greece', key:'A_AOK', cityBase: 17, city: 'AOK', country: 'GR', type: 'airport', nlabel: 'karpathos island national karpathos greece'},
  { icon: 'faPlaneDeparture', label: 'KTA Karratha, Australia, Karratha, KTA, karratha karratha western australia australia', key:'A_KTA', cityBase: 127, city: 'KTA', country: 'AU', type: 'airport', nlabel: 'karratha karratha western australia australia'},
  { icon: 'faPlaneDeparture', label: 'KSY Kars, Turkey, Kars Harakani, KSY, kars harakani kars turkey', key:'A_KSY', cityBase: 25, city: 'KSY', country: 'TR', type: 'airport', nlabel: 'kars harakani kars turkey'},
  { icon: 'faPlaneDeparture', label: 'KSQ Qarshi, Uzbekistan, Karshi, KSQ, karshi qarshi uzbekistan', key:'A_KSQ', cityBase: 80, city: 'KSQ', country: 'UZ', type: 'airport', nlabel: 'karshi qarshi uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'KRB Karumba, Australia, Karumba, KRB, karumba karumba queensland australia', key:'A_KRB', cityBase: 127, city: 'KRB', country: 'AU', type: 'airport', nlabel: 'karumba karumba queensland australia'},
  { icon: 'faPlaneDeparture', label: 'KXA Kasaan, United States, Kasaan, KXA, kasaan kasaan alaska united states', key:'A_KXA', cityBase: 127, city: 'KXA', country: 'US', type: 'airport', nlabel: 'kasaan kasaan alaska united states'},
  { icon: 'faPlaneDeparture', label: 'BBK Kasane, Botswana, Kasane, BBK, kasane kasane botswana', key:'A_BBK', cityBase: 89, city: 'BBK', country: 'BW', type: 'airport', nlabel: 'kasane kasane botswana'},
  { icon: 'faPlaneDeparture', label: 'ZKE Kashechewan, Canada, Kashechewan, ZKE, kashechewan kashechewan ontario canada', key:'A_ZKE', cityBase: 127, city: 'ZKE', country: 'CA', type: 'airport', nlabel: 'kashechewan kashechewan ontario canada'},
  { icon: 'faPlaneDeparture', label: 'KHG Kashgar, China, Kashi, KHG, kashi kashgar china', key:'A_KHG', cityBase: 75, city: 'KHG', country: 'CN', type: 'airport', nlabel: 'kashi kashgar china'},
  { icon: 'faPlaneDeparture', label: 'KUK Kasigluk, United States, Kasigluk, KUK, kasigluk kasigluk alaska united states', key:'A_KUK', cityBase: 127, city: 'KUK', country: 'US', type: 'airport', nlabel: 'kasigluk kasigluk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KSJ Kasos, Greece, Kasos Island Public, KSJ, kasos island public kasos greece', key:'A_KSJ', cityBase: 127, city: 'KSJ', country: 'GR', type: 'airport', nlabel: 'kasos island public kasos greece'},
  { icon: 'faPlaneDeparture', label: 'KSF Kassel, Germany, Kassel, KSF, kassel kassel germany', key:'A_KSF', cityBase: 19, city: 'KSF', country: 'DE', type: 'airport', nlabel: 'kassel kassel germany'},
  { icon: 'faPlaneDeparture', label: 'KFS Kastamonu, Turkey, Kastamonu, KFS, kastamonu kastamonu turkey', key:'A_KFS', cityBase: 26, city: 'KFS', country: 'TR', type: 'airport', nlabel: 'kastamonu kastamonu turkey'},
  { icon: 'faPlaneDeparture', label: 'KZS Kastellorizo, Greece, Kastellorizo, KZS, kastellorizo kastellorizo greece', key:'A_KZS', cityBase: 127, city: 'KZS', country: 'GR', type: 'airport', nlabel: 'kastellorizo kastellorizo greece'},
  { icon: 'faPlaneDeparture', label: 'KSO Kastoria, Greece, Kastoria National, KSO, kastoria national kastoria greece', key:'A_KSO', cityBase: 28, city: 'KSO', country: 'GR', type: 'airport', nlabel: 'kastoria national kastoria greece'},
  { icon: 'faPlaneDeparture', label: 'MPA Katima Mulilo, Namibia, Katima Mulilo, MPA, katima mulilo katima mulilo namibia', key:'A_MPA', cityBase: 127, city: 'MPA', country: 'NA', type: 'airport', nlabel: 'katima mulilo katima mulilo namibia'},
  { icon: 'faPlaneDeparture', label: 'KXU Katiu, French Polynesia, Katiu, KXU, katiu katiu french polynesia', key:'A_KXU', cityBase: 127, city: 'KXU', country: 'PF', type: 'airport', nlabel: 'katiu katiu french polynesia'},
  { icon: 'faPlaneDeparture', label: 'KTW Katowice, Poland, Katowice International, KTW, katowice international katowice poland', key:'A_KTW', cityBase: 4, city: 'KTW', country: 'PL', type: 'airport', nlabel: 'katowice international katowice poland'},
  { icon: 'faPlaneDeparture', label: 'KAZ Kau, Indonesia, Kaubang, KAZ, kaubang kau indonesia', key:'A_KAZ', cityBase: 127, city: 'KAZ', country: 'ID', type: 'airport', nlabel: 'kaubang kau indonesia'},
  { icon: 'faPlaneDeparture', label: 'KHZ Kauehi, French Polynesia, Kauehi, KHZ, kauehi kauehi french polynesia', key:'A_KHZ', cityBase: 127, city: 'KHZ', country: 'PF', type: 'airport', nlabel: 'kauehi kauehi french polynesia'},
  { icon: 'faPlaneDeparture', label: 'KKR Kaukura, French Polynesia, Kaukura, KKR, kaukura kaukura french polynesia', key:'A_KKR', cityBase: 127, city: 'KKR', country: 'PF', type: 'airport', nlabel: 'kaukura kaukura french polynesia'},
  { icon: 'faPlaneDeparture', label: 'KUN Kaunas, Lithuania, Kaunas, KUN, kaunas kaunas lithuania', key:'A_KUN', cityBase: 63, city: 'KUN', country: 'LT', type: 'airport', nlabel: 'kaunas kaunas lithuania'},
  { icon: 'faPlaneDeparture', label: 'KVA Kavala, Greece, Kavala International, KVA, kavala international kavala greece', key:'A_KVA', cityBase: 14, city: 'KVA', country: 'GR', type: 'airport', nlabel: 'kavala international kavala greece'},
  { icon: 'faPlaneDeparture', label: 'KVR Kavalerovo, Russia, Kavalerovo, KVR, kavalerovo kavalerovo russia', key:'A_KVR', cityBase: 127, city: 'KVR', country: 'RU', type: 'airport', nlabel: 'kavalerovo kavalerovo russia'},
  { icon: 'faPlaneDeparture', label: 'KVG Kavieng, Papua New Guinea, Kavieng, KVG, kavieng kavieng papua new guinea', key:'A_KVG', cityBase: 127, city: 'KVG', country: 'PG', type: 'airport', nlabel: 'kavieng kavieng papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'BKY Bukavu, Democratic Republic of the Congo, Kavumu, BKY, kavumu bukavu congo kinshasa', key:'A_BKY', cityBase: 127, city: 'BKY', country: 'CD', type: 'airport', nlabel: 'kavumu bukavu congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'KAW Kawthaung Township, Myanmar (Burma), Kawthaung, KAW, kawthaung kawthaung township myanmar (burma)', key:'A_KAW', cityBase: 127, city: 'KAW', country: 'MM', type: 'airport', nlabel: 'kawthaung kawthaung township myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'KZN Kazan, Russia, Kazan International, KZN, kazan international kazan russia', key:'A_KZN', cityBase: 29, city: 'KZN', country: 'RU', type: 'airport', nlabel: 'kazan international kazan russia'},
  { icon: 'faPlaneDeparture', label: 'RDP Durgapur, India, Kazi Nazrul Islam, RDP, kazi nazrul islam durgapur india', key:'A_RDP', cityBase: 127, city: 'RDP', country: 'IN', type: 'airport', nlabel: 'kazi nazrul islam durgapur india'},
  { icon: 'faPlaneDeparture', label: 'EAR Kearney, United States, Kearney Regional, EAR, kearney regional kearney nebraska united states', key:'A_EAR', cityBase: 121, city: 'EAR', country: 'US', type: 'airport', nlabel: 'kearney regional kearney nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'KEF Reykjavik, Iceland, Keflavík International, KEF, keflavik international reykjavik iceland', key:'A_KEF', cityBase: 6, city: 'REK', country: 'IS', type: 'airport', nlabel: 'keflavik international reykjavik iceland'},
  { icon: 'faPlaneDeparture', label: 'ZKG Kegashka, Quebec, Canada, Kegaska, ZKG, kegaska kegashka quebec canada', key:'A_ZKG', cityBase: 127, city: 'ZKG', country: 'CA', type: 'airport', nlabel: 'kegaska kegashka quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YLW Kelowna, Canada, Kelowna International, YLW, kelowna international kelowna british columbia canada', key:'A_YLW', cityBase: 61, city: 'YLW', country: 'CA', type: 'airport', nlabel: 'kelowna international kelowna british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'KEJ Kemerovo, Russia, Kemerovo International, KEJ, kemerovo international kemerovo russia', key:'A_KEJ', cityBase: 48, city: 'KEJ', country: 'RU', type: 'airport', nlabel: 'kemerovo international kemerovo russia'},
  { icon: 'faPlaneDeparture', label: 'KEM Kemi, Finland, Kemi-Tornio, KEM, kemi tornio kemi finland', key:'A_KEM', cityBase: 106, city: 'KEM', country: 'FI', type: 'airport', nlabel: 'kemi tornio kemi finland'},
  { icon: 'faPlaneDeparture', label: 'ENA Kenai, United States, Kenai Municipal, ENA, kenai municipal kenai alaska united states', key:'A_ENA', cityBase: 115, city: 'ENA', country: 'US', type: 'airport', nlabel: 'kenai municipal kenai alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KET Kengtung, Myanmar (Burma), Kengtung, KET, kengtung kengtung myanmar (burma)', key:'A_KET', cityBase: 127, city: 'KET', country: 'MM', type: 'airport', nlabel: 'kengtung kengtung myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'KEH Kenmore, United States, Kenmore Air Harbor, KEH, kenmore air harbor kenmore washington united states', key:'A_KEH', cityBase: 127, city: 'KEH', country: 'US', type: 'airport', nlabel: 'kenmore air harbor kenmore washington united states'},
  { icon: 'faPlaneDeparture', label: 'LKE Seattle, United States, Kenmore Air Harbor Seaplane Base, LKE, kenmore air harbor seaplane base seattle washington united states', key:'A_LKE', cityBase: 127, city: 'SEA', country: 'US', type: 'airport', nlabel: 'kenmore air harbor seaplane base seattle washington united states'},
  { icon: 'faPlaneDeparture', label: 'LUN Lusaka, Zambia, Kenneth Kaunda International, LUN, kenneth kaunda international lusaka zambia', key:'A_LUN', cityBase: 59, city: 'LUN', country: 'ZM', type: 'airport', nlabel: 'kenneth kaunda international lusaka zambia'},
  { icon: 'faPlaneDeparture', label: 'YQK Kenora, Canada, Kenora, YQK, kenora kenora ontario canada', key:'A_YQK', cityBase: 127, city: 'YQK', country: 'CA', type: 'airport', nlabel: 'kenora kenora ontario canada'},
  { icon: 'faPlaneDeparture', label: 'KPW Keperveyem, Russia, Keperveyem, KPW, keperveyem keperveyem russia', key:'A_KPW', cityBase: 127, city: 'KPW', country: 'RU', type: 'airport', nlabel: 'keperveyem keperveyem russia'},
  { icon: 'faPlaneDeparture', label: 'EFL Cephalonia, Greece, Kephalonia International, EFL, kephalonia international cephalonia greece', key:'A_EFL', cityBase: 16, city: 'EFL', country: 'GR', type: 'airport', nlabel: 'kephalonia international cephalonia greece'},
  { icon: 'faPlaneDeparture', label: 'KKE Kerikeri, New Zealand, Kerikeri, KKE, kerikeri kerikeri new zealand', key:'A_KKE', cityBase: 127, city: 'KKE', country: 'NZ', type: 'airport', nlabel: 'kerikeri kerikeri new zealand'},
  { icon: 'faPlaneDeparture', label: 'KER Kerman, Iran, Kerman, KER, kerman kerman iran', key:'A_KER', cityBase: 127, city: 'KER', country: 'IR', type: 'airport', nlabel: 'kerman kerman iran'},
  { icon: 'faPlaneDeparture', label: 'KSH Kermanshah, Iran, Kermanshah, KSH, kermanshah kermanshah iran', key:'A_KSH', cityBase: 127, city: 'KSH', country: 'IR', type: 'airport', nlabel: 'kermanshah kermanshah iran'},
  { icon: 'faPlaneDeparture', label: 'KIR County Kerry, Ireland, Kerry, KIR, kerry county kerry ireland', key:'A_KIR', cityBase: 84, city: 'KIR', country: 'IE', type: 'airport', nlabel: 'kerry county kerry ireland'},
  { icon: 'faPlaneDeparture', label: 'KTE Kerteh, Malaysia, Kerteh, KTE, kerteh kerteh malaysia', key:'A_KTE', cityBase: 127, city: 'KTE', country: 'MY', type: 'airport', nlabel: 'kerteh kerteh malaysia'},
  { icon: 'faPlaneDeparture', label: 'KTG Ketapang, Indonesia, Ketapang Rahadi Osman, KTG, ketapang rahadi osman ketapang indonesia', key:'A_KTG', cityBase: 127, city: 'KTG', country: 'ID', type: 'airport', nlabel: 'ketapang rahadi osman ketapang indonesia'},
  { icon: 'faPlaneDeparture', label: 'KTN Ketchikan, United States, Ketchikan International, KTN, ketchikan international ketchikan alaska united states', key:'A_KTN', cityBase: 62, city: 'KTN', country: 'US', type: 'airport', nlabel: 'ketchikan international ketchikan alaska united states'},
  { icon: 'faPlaneDeparture', label: 'EYW Key West, United States, Key West International, EYW, key west international key west florida united states', key:'A_EYW', cityBase: 32, city: 'EYW', country: 'US', type: 'airport', nlabel: 'key west international key west florida united states'},
  { icon: 'faPlaneDeparture', label: 'KHV Khabarovsk, Russia, Khabarovsk Novy, KHV, khabarovsk novy khabarovsk russia', key:'A_KHV', cityBase: 43, city: 'KHV', country: 'RU', type: 'airport', nlabel: 'khabarovsk novy khabarovsk russia'},
  { icon: 'faPlaneDeparture', label: 'HJR Khajuraho Group of Monuments, India, Khajuraho, HJR, khajuraho khajuraho group of monuments india', key:'A_HJR', cityBase: 102, city: 'HJR', country: 'IN', type: 'airport', nlabel: 'khajuraho khajuraho group of monuments india'},
  { icon: 'faPlaneDeparture', label: 'KHM Khamti, Myanmar (Burma), Khamti, KHM, khamti khamti myanmar (burma)', key:'A_KHM', cityBase: 127, city: 'KHM', country: 'MM', type: 'airport', nlabel: 'khamti khamti myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'KDY Khandyga, Russia, Khandyga Teply Klyuch, KDY, khandyga teply klyuch khandyga russia', key:'A_KDY', cityBase: 127, city: 'KDY', country: 'RU', type: 'airport', nlabel: 'khandyga teply klyuch khandyga russia'},
  { icon: 'faPlaneDeparture', label: 'HMA Khanty-Mansiysk, Russia, Khanty-Mansiysk, HMA, khanty mansiysk khanty mansiysk russia', key:'A_HMA', cityBase: 47, city: 'HMA', country: 'RU', type: 'airport', nlabel: 'khanty mansiysk khanty mansiysk russia'},
  { icon: 'faPlaneDeparture', label: 'HRK Kharkiv, Ukraine, Kharkiv International, HRK, kharkiv international kharkiv ukraine', key:'A_HRK', cityBase: 34, city: 'HRK', country: 'UA', type: 'airport', nlabel: 'kharkiv international kharkiv ukraine'},
  { icon: 'faPlaneDeparture', label: 'KRT Khartoum, Sudan, Khartoum International, KRT, khartoum international khartoum sudan', key:'A_KRT', cityBase: 42, city: 'KRT', country: 'SD', type: 'airport', nlabel: 'khartoum international khartoum sudan'},
  { icon: 'faPlaneDeparture', label: 'KHS Khasab, Oman, Khasab, KHS, khasab khasab oman', key:'A_KHS', cityBase: 127, city: 'KHS', country: 'OM', type: 'airport', nlabel: 'khasab khasab oman'},
  { icon: 'faPlaneDeparture', label: 'HTG Khatanga, Russia, Khatanga, HTG, khatanga khatanga russia', key:'A_HTG', cityBase: 127, city: 'HTG', country: 'RU', type: 'airport', nlabel: 'khatanga khatanga russia'},
  { icon: 'faPlaneDeparture', label: 'KHE Kherson, Ukraine, Kherson International, KHE, kherson international kherson ukraine', key:'A_KHE', cityBase: 92, city: 'KHE', country: 'UA', type: 'airport', nlabel: 'kherson international kherson ukraine'},
  { icon: 'faPlaneDeparture', label: 'KKC Khon Kaen, Thailand, Khon Kaen, KKC, khon kaen khon kaen thailand', key:'A_KKC', cityBase: 104, city: 'KKC', country: 'TH', type: 'airport', nlabel: 'khon kaen khon kaen thailand'},
  { icon: 'faPlaneDeparture', label: 'KHD Khorramabad, Iran, Khorramabad, KHD, khorramabad khorramabad iran', key:'A_KHD', cityBase: 127, city: 'KHD', country: 'IR', type: 'airport', nlabel: 'khorramabad khorramabad iran'},
  { icon: 'faPlaneDeparture', label: 'HVD Hovd, Mongolia, Khovd, HVD, khovd hovd mongolia', key:'A_HVD', cityBase: 127, city: 'HVD', country: 'MN', type: 'airport', nlabel: 'khovd hovd mongolia'},
  { icon: 'faPlaneDeparture', label: 'KHY Khoy, Iran, Khoy Airport, KHY, khoy airport khoy iran', key:'A_KHY', cityBase: 127, city: 'null', country: 'IR', type: 'airport', nlabel: 'khoy airport khoy iran'},
  { icon: 'faPlaneDeparture', label: 'KGD Kaliningrad, Russia, Khrabrovo, KGD, khrabrovo kaliningrad russia', key:'A_KGD', cityBase: 31, city: 'KGD', country: 'RU', type: 'airport', nlabel: 'khrabrovo kaliningrad russia'},
  { icon: 'faPlaneDeparture', label: 'LBD Khujand, Tajikistan, Khujand, LBD, khujand khujand tajikistan', key:'A_LBD', cityBase: 54, city: 'LBD', country: 'TJ', type: 'airport', nlabel: 'khujand khujand tajikistan'},
  { icon: 'faPlaneDeparture', label: 'KGL Kigali, Rwanda, Kigali International, KGL, kigali international kigali rwanda', key:'A_KGL', cityBase: 40, city: 'KGL', country: 'RW', type: 'airport', nlabel: 'kigali international kigali rwanda'},
  { icon: 'faPlaneDeparture', label: 'TKQ Kigoma, Tanzania, Kigoma, TKQ, kigoma kigoma tanzania', key:'A_TKQ', cityBase: 127, city: 'TKQ', country: 'TZ', type: 'airport', nlabel: 'kigoma kigoma tanzania'},
  { icon: 'faPlaneDeparture', label: 'JRO Mount Kilimanjaro, Tanzania, Kilimanjaro International, JRO, kilimanjaro international mount kilimanjaro tanzania', key:'A_JRO', cityBase: 43, city: 'JRO', country: 'TZ', type: 'airport', nlabel: 'kilimanjaro international mount kilimanjaro tanzania'},
  { icon: 'faPlaneDeparture', label: 'GRK Killeen, United States, Killeen–Fort Hood Regional, GRK, killeen fort hood regional killeen texas united states', key:'A_GRK', cityBase: 37, city: 'ILE', country: 'US', type: 'airport', nlabel: 'killeen fort hood regional killeen texas united states'},
  { icon: 'faPlaneDeparture', label: 'KIY Kilwa Kisiwani, Tanzania, Kilwa Masoko, KIY, kilwa masoko kilwa kisiwani tanzania', key:'A_KIY', cityBase: 127, city: 'KIY', country: 'TZ', type: 'airport', nlabel: 'kilwa masoko kilwa kisiwani tanzania'},
  { icon: 'faPlaneDeparture', label: 'KIM Kimberley, Northern Cape, South Africa, Kimberley, KIM, kimberley kimberley south africa', key:'A_KIM', cityBase: 127, city: 'KIM', country: 'ZA', type: 'airport', nlabel: 'kimberley kimberley south africa'},
  { icon: 'faPlaneDeparture', label: 'YLC Kimmirut, Canada, Kimmirut, YLC, kimmirut kimmirut nunavut canada', key:'A_YLC', cityBase: 127, city: 'YLC', country: 'CA', type: 'airport', nlabel: 'kimmirut kimmirut nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'KND Kindu, Democratic Republic of the Congo, Kindu, KND, kindu kindu democratic republic of the congo', key:'A_KND', cityBase: 127, city: 'KND', country: 'CD', type: 'airport', nlabel: 'kindu kindu democratic republic of the congo'},
  { icon: 'faPlaneDeparture', label: 'JED Jeddah, Saudi Arabia, King Abdulaziz International, JED, king abdulaziz international jeddah saudi arabia', key:'A_JED', cityBase: 23, city: 'JED', country: 'SA', type: 'airport', nlabel: 'king abdulaziz international jeddah saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'KVC King Cove, United States, King Cove, KVC, king cove king cove alaska united states', key:'A_KVC', cityBase: 127, city: 'KVC', country: 'US', type: 'airport', nlabel: 'king cove king cove alaska united states'},
  { icon: 'faPlaneDeparture', label: 'DMM Dammam, Saudi Arabia, King Fahd International, DMM, king fahd international dammam saudi arabia', key:'A_DMM', cityBase: 32, city: 'DMM', country: 'SA', type: 'airport', nlabel: 'king fahd international dammam saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'AQJ Aqaba, Jordan, King Hussein International, AQJ, king hussein international aqaba jordan', key:'A_AQJ', cityBase: 55, city: 'AQJ', country: 'JO', type: 'airport', nlabel: 'king hussein international aqaba jordan'},
  { icon: 'faPlaneDeparture', label: 'KNS King Island, Australia, King Island, KNS, king island king island tasmania australia', key:'A_KNS', cityBase: 127, city: 'KNS', country: 'AU', type: 'airport', nlabel: 'king island king island tasmania australia'},
  { icon: 'faPlaneDeparture', label: 'RUH Riyadh, Saudi Arabia, King Khalid International, RUH, king khalid international riyadh saudi arabia', key:'A_RUH', cityBase: 21, city: 'RUH', country: 'SA', type: 'airport', nlabel: 'king khalid international riyadh saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'KMC King Khalid Military City, Saudi Arabia, King Khalid Military City, KMC, king khalid military city king khalid military city saudi arabia', key:'A_KMC', cityBase: 127, city: 'KMC', country: 'SA', type: 'airport', nlabel: 'king khalid military city king khalid military city saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'SHO Manzini, Swaziland, King Mswati III International, SHO, king mswati iii international manzini swaziland', key:'A_SHO', cityBase: 101, city: 'MTS', country: 'SZ', type: 'airport', nlabel: 'king mswati iii international manzini swaziland'},
  { icon: 'faPlaneDeparture', label: 'AKN King Salmon, United States, King Salmon, AKN, king salmon king salmon alaska united states', key:'A_AKN', cityBase: 98, city: 'AKN', country: 'US', type: 'airport', nlabel: 'king salmon king salmon alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KGC Kingscote, Australia, Kingscote, KGC, kingscote kingscote south australia australia', key:'A_KGC', cityBase: 127, city: 'KGC', country: 'AU', type: 'airport', nlabel: 'kingscote kingscote south australia australia'},
  { icon: 'faPlaneDeparture', label: 'YGK Kingston, Canada, Kingston/Norman Rogers, YGK, kingston norman rogers kingston ontario canada', key:'A_YGK', cityBase: 127, city: 'YGK', country: 'CA', type: 'airport', nlabel: 'kingston norman rogers kingston ontario canada'},
  { icon: 'faPlaneDeparture', label: 'KNH Kinmen, Taiwan, Kinmen, KNH, kinmen kinmen taiwan', key:'A_KNH', cityBase: 120, city: 'KNH', country: 'TW', type: 'airport', nlabel: 'kinmen kinmen taiwan'},
  { icon: 'faPlaneDeparture', label: 'KPN Kipnuk, United States, Kipnuk, KPN, kipnuk kipnuk alaska united states', key:'A_KPN', cityBase: 127, city: 'KPN', country: 'US', type: 'airport', nlabel: 'kipnuk kipnuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'IRA Kirakira, Solomon Islands, Kirakira, IRA, kirakira kirakira solomon islands', key:'A_IRA', cityBase: 127, city: 'IRA', country: 'SB', type: 'airport', nlabel: 'kirakira kirakira solomon islands'},
  { icon: 'faPlaneDeparture', label: 'KCK Kirensk, Russia, Kirensk, KCK, kirensk kirensk russia', key:'A_KCK', cityBase: 123, city: 'KCK', country: 'RU', type: 'airport', nlabel: 'kirensk kirensk russia'},
  { icon: 'faPlaneDeparture', label: 'KKN Kirkenes, Norway, Kirkenes Airport, Høybuktmoen, KKN, kirkenes airport kirkenes norway', key:'A_KKN', cityBase: 103, city: 'KKN', country: 'NO', type: 'airport', nlabel: 'kirkenes airport kirkenes norway'},
  { icon: 'faPlaneDeparture', label: 'IRK Kirksville, United States, Kirksville Regional, IRK, kirksville regional kirksville missouri united states', key:'A_IRK', cityBase: 127, city: 'IRK', country: 'US', type: 'airport', nlabel: 'kirksville regional kirksville missouri united states'},
  { icon: 'faPlaneDeparture', label: 'KOI Kirkwall, United Kingdom, Kirkwall, KOI, kirkwall kirkwall united kingdom', key:'A_KOI', cityBase: -883, city: 'KOI', country: 'GB', type: 'airport', nlabel: 'kirkwall kirkwall united kingdom'},
  { icon: 'faPlaneDeparture', label: 'KVK Kirovsk, Russia, Kirovsk-Apatity, KVK, kirovsk apatity kirovsk russia', key:'A_KVK', cityBase: 55, city: 'KVK', country: 'RU', type: 'airport', nlabel: 'kirovsk apatity kirovsk russia'},
  { icon: 'faPlaneDeparture', label: 'KRN Kiruna, Sweden, Kiruna, KRN, kiruna kiruna sweden', key:'A_KRN', cityBase: 85, city: 'KRN', country: 'SE', type: 'airport', nlabel: 'kiruna kiruna sweden'},
  { icon: 'faPlaneDeparture', label: 'KIH Kish Island, Iran, Kish International, KIH, kish international kish island iran', key:'A_KIH', cityBase: 127, city: 'KIH', country: 'IR', type: 'airport', nlabel: 'kish international kish island iran'},
  { icon: 'faPlaneDeparture', label: 'KMU Kismayo, Somalia, Kisimayu, KMU, kisimayu kismayo somalia', key:'A_KMU', cityBase: 127, city: 'KMU', country: 'SO', type: 'airport', nlabel: 'kisimayu kismayo somalia'},
  { icon: 'faPlaneDeparture', label: 'KIS Kisumu, Kenya, Kisumu International, KIS, kisumu international kisumu kenya', key:'A_KIS', cityBase: 102, city: 'KIS', country: 'KE', type: 'airport', nlabel: 'kisumu international kisumu kenya'},
  { icon: 'faPlaneDeparture', label: 'KKJ Kitakyushu, Japan, Kitakyushu, KKJ, kitakyushu kitakyushu japan', key:'A_KKJ', cityBase: 127, city: 'KKJ', country: 'JP', type: 'airport', nlabel: 'kitakyushu kitakyushu japan'},
  { icon: 'faPlaneDeparture', label: 'KTL Kitale, Kenya, Kitale, KTL, kitale kitale kenya', key:'A_KTL', cityBase: 127, city: 'KTL', country: 'KE', type: 'airport', nlabel: 'kitale kitale kenya'},
  { icon: 'faPlaneDeparture', label: 'KIT Kythira, Greece, Kithira Island National, KIT, kithira island national kythira greece', key:'A_KIT', cityBase: 23, city: 'KIT', country: 'GR', type: 'airport', nlabel: 'kithira island national kythira greece'},
  { icon: 'faPlaneDeparture', label: 'KTT Kittilä, Finland, Kittilä, KTT, kittila kittila finland', key:'A_KTT', cityBase: 98, city: 'KTT', country: 'FI', type: 'airport', nlabel: 'kittila kittila finland'},
  { icon: 'faPlaneDeparture', label: 'UNG Kiunga, Papua New Guinea, Kiunga, UNG, kiunga kiunga papua new guinea', key:'A_UNG', cityBase: 127, city: 'UNG', country: 'PG', type: 'airport', nlabel: 'kiunga kiunga papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'KVL Kivalina, United States, Kivalina, KVL, kivalina kivalina alaska united states', key:'A_KVL', cityBase: 127, city: 'KVL', country: 'US', type: 'airport', nlabel: 'kivalina kivalina alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KLU Klagenfurt, Austria, Klagenfurt, KLU, klagenfurt klagenfurt austria', key:'A_KLU', cityBase: 7, city: 'KLU', country: 'AT', type: 'airport', nlabel: 'klagenfurt klagenfurt austria'},
  { icon: 'faPlaneDeparture', label: 'KLW Klawock, United States, Klawock, KLW, klawock klawock alaska united states', key:'A_KLW', cityBase: 127, city: 'KLW', country: 'US', type: 'airport', nlabel: 'klawock klawock alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RKD Rockland, United States, Knox County Regional, RKD, knox county regional rockland maine united states', key:'A_RKD', cityBase: 97, city: 'RKD', country: 'US', type: 'airport', nlabel: 'knox county regional rockland maine united states'},
  { icon: 'faPlaneDeparture', label: 'UKB Kobe, Japan, Kobe, UKB, kobe kobe japan', key:'A_UKB', cityBase: 99, city: 'UKB', country: 'JP', type: 'airport', nlabel: 'kobe kobe japan'},
  { icon: 'faPlaneDeparture', label: 'ZNV Koblenz, Germany, Koblenz-Winningen Airport, ZNV, koblenz winningen airport koblenz germany', key:'A_ZNV', cityBase: 114, city: 'ZNV', country: 'DE', type: 'airport', nlabel: 'koblenz winningen airport koblenz germany'},
  { icon: 'faPlaneDeparture', label: 'OBU Kobuk, United States, Kobuk, OBU, kobuk kobuk alaska united states', key:'A_OBU', cityBase: 127, city: 'OBU', country: 'US', type: 'airport', nlabel: 'kobuk kobuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KCZ Kochi, Japan, Kōchi, KCZ, kochi kochi japan', key:'A_KCZ', cityBase: 112, city: 'KCZ', country: 'JP', type: 'airport', nlabel: 'kochi kochi japan'},
  { icon: 'faPlaneDeparture', label: 'ADQ Kodiak, United States, Kodiak, ADQ, kodiak kodiak alaska united states', key:'A_ADQ', cityBase: 73, city: 'ADQ', country: 'US', type: 'airport', nlabel: 'kodiak kodiak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KGP Kogalym, Russia, Kogalym International, KGP, kogalym international kogalym russia', key:'A_KGP', cityBase: 60, city: 'KGP', country: 'RU', type: 'airport', nlabel: 'kogalym international kogalym russia'},
  { icon: 'faPlaneDeparture', label: 'KCT Koggala, Sri Lanka, Koggala, KCT, koggala koggala sri lanka', key:'A_KCT', cityBase: 127, city: 'KCT', country: 'LK', type: 'airport', nlabel: 'koggala koggala sri lanka'},
  { icon: 'faPlaneDeparture', label: 'KNK Kakhonak, United States, Kokhanok, KNK, kokhanok kakhonak alaska united states', key:'A_KNK', cityBase: 127, city: 'KNK', country: 'US', type: 'airport', nlabel: 'kokhanok kakhonak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KOK Kokkola, Finland, Kokkola-Pietarsaari, KOK, kokkola pietarsaari kokkola finland', key:'A_KOK', cityBase: 127, city: 'KOK', country: 'FI', type: 'airport', nlabel: 'kokkola pietarsaari kokkola finland'},
  { icon: 'faPlaneDeparture', label: 'KOV Kokshetau, Kazakhstan, Kokshetau, KOV, kokshetau kokshetau kazakhstan', key:'A_KOV', cityBase: 127, city: 'KOV', country: 'KZ', type: 'airport', nlabel: 'kokshetau kokshetau kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'PUM Pomalaa, Indonesia, Kolaka Pomala, PUM, kolaka pomala pomalaa indonesia', key:'A_PUM', cityBase: 117, city: 'PUM', country: 'ID', type: 'airport', nlabel: 'kolaka pomala pomalaa indonesia'},
  { icon: 'faPlaneDeparture', label: 'KGK Koliganek, United States, Koliganek, KGK, koliganek koliganek alaska united states', key:'A_KGK', cityBase: 127, city: 'KGK', country: 'US', type: 'airport', nlabel: 'koliganek koliganek alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SVX Yekaterinburg, Russia, Koltsovo, SVX, koltsovo yekaterinburg russia', key:'A_SVX', cityBase: 24, city: 'SVX', country: 'RU', type: 'airport', nlabel: 'koltsovo yekaterinburg russia'},
  { icon: 'faPlaneDeparture', label: 'KMQ Komatsu, Japan, Komatsu, KMQ, komatsu komatsu japan', key:'A_KMQ', cityBase: 86, city: 'KMQ', country: 'JP', type: 'airport', nlabel: 'komatsu komatsu japan'},
  { icon: 'faPlaneDeparture', label: 'LBJ Labuan Bajo, Indonesia, Komodo, LBJ, komodo labuan bajo indonesia', key:'A_LBJ', cityBase: 82, city: 'LBJ', country: 'ID', type: 'airport', nlabel: 'komodo labuan bajo indonesia'},
  { icon: 'faPlaneDeparture', label: 'KXK Komsomolsk-on-Amur, Russia, Komsomolsk-on-Amur, KXK, komsomolsk on amur komsomolsk on amur russia', key:'A_KXK', cityBase: 127, city: 'KXK', country: 'RU', type: 'airport', nlabel: 'komsomolsk on amur komsomolsk on amur russia'},
  { icon: 'faPlaneDeparture', label: 'KOA Kailua, United States, Kona International, KOA, kona international kailua hawaii united states', key:'A_KOA', cityBase: 15, city: 'KOA', country: 'US', type: 'airport', nlabel: 'kona international kailua hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'ZBR Chabahar, Iran, Konarak, ZBR, konarak chabahar iran', key:'A_ZBR', cityBase: 127, city: 'ZBR', country: 'IR', type: 'airport', nlabel: 'konarak chabahar iran'},
  { icon: 'faPlaneDeparture', label: 'KNQ Kone, New Caledonia, Kone Airport, KNQ, kone airport kone new caledonia', key:'A_KNQ', cityBase: 127, city: 'KNQ', country: 'NC', type: 'airport', nlabel: 'kone airport kone new caledonia'},
  { icon: 'faPlaneDeparture', label: 'KKH Kongiganak, United States, Kongiganak, KKH, kongiganak kongiganak alaska united states', key:'A_KKH', cityBase: 127, city: 'KKH', country: 'US', type: 'airport', nlabel: 'kongiganak kongiganak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KYA Konya, Turkey, Konya, KYA, konya konya turkey', key:'A_KYA', cityBase: 17, city: 'KYA', country: 'TR', type: 'airport', nlabel: 'konya konya turkey'},
  { icon: 'faPlaneDeparture', label: 'HGO Korhogo, Côte d’Ivoire, Korhogo, HGO, korhogo korhogo cote d ivoire', key:'A_HGO', cityBase: 127, city: 'HGO', country: 'CI', type: 'airport', nlabel: 'korhogo korhogo cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'KRL Korla, China, Korla, KRL, korla korla china', key:'A_KRL', cityBase: 77, city: 'KRL', country: 'CN', type: 'airport', nlabel: 'korla korla china'},
  { icon: 'faPlaneDeparture', label: 'KXF Koro Island, Fiji, Koro Island Airport, KXF, koro island airport koro island fiji', key:'A_KXF', cityBase: 127, city: 'KXF', country: 'FJ', type: 'airport', nlabel: 'koro island airport koro island fiji'},
  { icon: 'faPlaneDeparture', label: 'KGS Kos, Greece, Kos Island International, KGS, kos island international kos greece', key:'A_KGS', cityBase: 10, city: 'KGS', country: 'GR', type: 'airport', nlabel: 'kos island international kos greece'},
  { icon: 'faPlaneDeparture', label: 'KSC Košice, Slovakia, Košice International, KSC, kosice international kosice slovakia', key:'A_KSC', cityBase: 32, city: 'KSC', country: 'SK', type: 'airport', nlabel: 'kosice international kosice slovakia'},
  { icon: 'faPlaneDeparture', label: 'KSA Kosrae, Micronesia, Kosrae International, KSA, kosrae international kosrae micronesia', key:'A_KSA', cityBase: 127, city: 'KSA', country: 'FM', type: 'airport', nlabel: 'kosrae international kosrae micronesia'},
  { icon: 'faPlaneDeparture', label: 'KSN Kostanay, Kazakhstan, Kostanay, KSN, kostanay kostanay kazakhstan', key:'A_KSN', cityBase: 100, city: 'KSN', country: 'KZ', type: 'airport', nlabel: 'kostanay kostanay kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'BKI Kota Kinabalu, Malaysia, Kota Kinabalu International, BKI, kota kinabalu international kota kinabalu malaysia', key:'A_BKI', cityBase: 22, city: 'BKI', country: 'MY', type: 'airport', nlabel: 'kota kinabalu international kota kinabalu malaysia'},
  { icon: 'faPlaneDeparture', label: 'KSZ Kotlas, Russia, Kotlas, KSZ, kotlas kotlas russia', key:'A_KSZ', cityBase: 83, city: 'KSZ', country: 'RU', type: 'airport', nlabel: 'kotlas kotlas russia'},
  { icon: 'faPlaneDeparture', label: 'KOT Kotlik, United States, Kotlik, KOT, kotlik kotlik alaska united states', key:'A_KOT', cityBase: 127, city: 'KOT', country: 'US', type: 'airport', nlabel: 'kotlik kotlik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ACC Accra, Ghana, Kotoka International, ACC, kotoka international accra ghana', key:'A_ACC', cityBase: 32, city: 'ACC', country: 'GH', type: 'airport', nlabel: 'kotoka international accra ghana'},
  { icon: 'faPlaneDeparture', label: 'DWO Sri Jayawardenepura Kotte, Sri Lanka, Kotte Diyawanna Oya SPB, DWO, kotte diyawanna oya spb sri jayawardenepura kotte sri lanka', key:'A_DWO', cityBase: 91, city: 'DWO', country: 'LK', type: 'airport', nlabel: 'kotte diyawanna oya spb sri jayawardenepura kotte sri lanka'},
  { icon: 'faPlaneDeparture', label: 'KOC Koumac, New Caledonia, Koumac, KOC, koumac koumac new caledonia', key:'A_KOC', cityBase: 127, city: 'KOC', country: 'NC', type: 'airport', nlabel: 'koumac koumac new caledonia'},
  { icon: 'faPlaneDeparture', label: 'KWM Kowanyama, Australia, Kowanyama, KWM, kowanyama kowanyama queensland australia', key:'A_KWM', cityBase: 127, city: 'KWM', country: 'AU', type: 'airport', nlabel: 'kowanyama kowanyama queensland australia'},
  { icon: 'faPlaneDeparture', label: 'KKA Koyuk, United States, Koyuk Alfred Adams, KKA, koyuk alfred adams koyuk alaska united states', key:'A_KKA', cityBase: 127, city: 'KKA', country: 'US', type: 'airport', nlabel: 'koyuk alfred adams koyuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KYU Koyukuk, United States, Koyukuk, KYU, koyukuk koyukuk alaska united states', key:'A_KYU', cityBase: 127, city: 'KYU', country: 'US', type: 'airport', nlabel: 'koyukuk koyukuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KZI Kozani, Greece, Kozani National, KZI, kozani national kozani greece', key:'A_KZI', cityBase: 28, city: 'KZI', country: 'GR', type: 'airport', nlabel: 'kozani national kozani greece'},
  { icon: 'faPlaneDeparture', label: 'KBV Krabi, Thailand, Krabi, KBV, krabi krabi thailand', key:'A_KBV', cityBase: 29, city: 'KBV', country: 'TH', type: 'airport', nlabel: 'krabi krabi thailand'},
  { icon: 'faPlaneDeparture', label: 'KRR Krasnodar, Russia, Krasnodar International, KRR, krasnodar international krasnodar russia', key:'A_KRR', cityBase: 33, city: 'KRR', country: 'RU', type: 'airport', nlabel: 'krasnodar international krasnodar russia'},
  { icon: 'faPlaneDeparture', label: 'BXY Baikonur, Kazakhstan, Krayniy, BXY, krayniy baikonur kazakhstan', key:'A_BXY', cityBase: 127, city: 'BXY', country: 'KZ', type: 'airport', nlabel: 'krayniy baikonur kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'KRS Kristiansand, Norway, Kristiansand Airport, Kjevik, KRS, kristiansand airport kristiansand norway', key:'A_KRS', cityBase: 76, city: 'KRS', country: 'NO', type: 'airport', nlabel: 'kristiansand airport kristiansand norway'},
  { icon: 'faPlaneDeparture', label: 'KID Kristianstad, Sweden, Kristianstad, KID, kristianstad kristianstad sweden', key:'A_KID', cityBase: 62, city: 'KID', country: 'SE', type: 'airport', nlabel: 'kristianstad kristianstad sweden'},
  { icon: 'faPlaneDeparture', label: 'KSU Kristiansund, Norway, Kristiansund Airport, Kvernberget, KSU, kristiansund airport kristiansund norway', key:'A_KSU', cityBase: 107, city: 'KSU', country: 'NO', type: 'airport', nlabel: 'kristiansund airport kristiansund norway'},
  { icon: 'faPlaneDeparture', label: 'MQP Nelspruit, South Africa, Kruger Mpumalanga International, MQP, kruger mpumalanga international nelspruit south africa', key:'A_MQP', cityBase: 81, city: 'NLP', country: 'ZA', type: 'airport', nlabel: 'kruger mpumalanga international nelspruit south africa'},
  { icon: 'faPlaneDeparture', label: 'KUL Kuala Lumpur, Malaysia, Kuala Lumpur International, KUL, kuala lumpur international kuala lumpur malaysia', key:'A_KUL', cityBase: 5, city: 'KUL', country: 'MY', type: 'airport', nlabel: 'kuala lumpur international kuala lumpur malaysia'},
  { icon: 'faPlaneDeparture', label: 'KNO Medan, Indonesia, Kualanamu International, KNO, kualanamu international medan indonesia', key:'A_KNO', cityBase: 76, city: 'MES', country: 'ID', type: 'airport', nlabel: 'kualanamu international medan indonesia'},
  { icon: 'faPlaneDeparture', label: 'KCH Kuching, Malaysia, Kuching International, KCH, kuching international kuching malaysia', key:'A_KCH', cityBase: 33, city: 'KCH', country: 'MY', type: 'airport', nlabel: 'kuching international kuching malaysia'},
  { icon: 'faPlaneDeparture', label: 'KUD Kudat, Malaysia, Kudat, KUD, kudat kudat malaysia', key:'A_KUD', cityBase: 127, city: 'KUD', country: 'MY', type: 'airport', nlabel: 'kudat kudat malaysia'},
  { icon: 'faPlaneDeparture', label: 'AKF Kufrah, Libya, Kufra, AKF, kufra kufrah libya', key:'A_AKF', cityBase: 127, city: 'AKF', country: 'LY', type: 'airport', nlabel: 'kufra kufrah libya'},
  { icon: 'faPlaneDeparture', label: 'YBB Kugaaruk, Canada, Kugaaruk, YBB, kugaaruk kugaaruk nunavut canada', key:'A_YBB', cityBase: 127, city: 'YBB', country: 'CA', type: 'airport', nlabel: 'kugaaruk kugaaruk nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'YCO Kugluktuk, Canada, Kugluktuk, YCO, kugluktuk kugluktuk nunavut canada', key:'A_YCO', cityBase: 127, city: 'YCO', country: 'CA', type: 'airport', nlabel: 'kugluktuk kugluktuk nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'SVP Kuito, Angola, Kuito, SVP, kuito kuito angola', key:'A_SVP', cityBase: 127, city: 'SVP', country: 'AO', type: 'airport', nlabel: 'kuito kuito angola'},
  { icon: 'faPlaneDeparture', label: 'KUU Kullu, India, Kullu–Manali, KUU, kullu manali kullu india', key:'A_KUU', cityBase: 105, city: 'KUU', country: 'IN', type: 'airport', nlabel: 'kullu manali kullu india'},
  { icon: 'faPlaneDeparture', label: 'TJU Kulob, Tajikistan, Kulob, TJU, kulob kulob tajikistan', key:'A_TJU', cityBase: 60, city: 'TJU', country: 'TJ', type: 'airport', nlabel: 'kulob kulob tajikistan'},
  { icon: 'faPlaneDeparture', label: 'KUS Kulusuk, Greenland, Kulusuk, KUS, kulusuk kulusuk greenland', key:'A_KUS', cityBase: 71, city: 'KUS', country: 'GL', type: 'airport', nlabel: 'kulusuk kulusuk greenland'},
  { icon: 'faPlaneDeparture', label: 'KMJ Kumamoto, Japan, Kumamoto, KMJ, kumamoto kumamoto japan', key:'A_KMJ', cityBase: 127, city: 'KMJ', country: 'JP', type: 'airport', nlabel: 'kumamoto kumamoto japan'},
  { icon: 'faPlaneDeparture', label: 'KMS Kumasi, Ghana, Kumasi, KMS, kumasi kumasi ghana', key:'A_KMS', cityBase: 121, city: 'KMS', country: 'GH', type: 'airport', nlabel: 'kumasi kumasi ghana'},
  { icon: 'faPlaneDeparture', label: 'UEO Kume Island, Japan, Kumejima, UEO, kumejima kume island japan', key:'A_UEO', cityBase: 127, city: 'UEO', country: 'JP', type: 'airport', nlabel: 'kumejima kume island japan'},
  { icon: 'faPlaneDeparture', label: 'KMG Kunming, China, Kunming Changshui International, KMG, kunming changshui international kunming china', key:'A_KMG', cityBase: 25, city: 'KMG', country: 'CN', type: 'airport', nlabel: 'kunming changshui international kunming china'},
  { icon: 'faPlaneDeparture', label: 'KUO Kuopio, Finland, Kuopio, KUO, kuopio kuopio finland', key:'A_KUO', cityBase: 127, city: 'KUO', country: 'FI', type: 'airport', nlabel: 'kuopio kuopio finland'},
  { icon: 'faPlaneDeparture', label: 'KCA Kuqa County, China, Kuqa Qiuci, KCA, kuqa qiuci kuqa county china', key:'A_KCA', cityBase: 73, city: 'KCA', country: 'CN', type: 'airport', nlabel: 'kuqa qiuci kuqa county china'},
  { icon: 'faPlaneDeparture', label: 'KRO Kurgan, Kurgan Oblast, Russia, Kurgan, KRO, kurgan kurgan russia', key:'A_KRO', cityBase: 53, city: 'KRO', country: 'RU', type: 'airport', nlabel: 'kurgan kurgan russia'},
  { icon: 'faPlaneDeparture', label: 'URS Kursk, Russia, Kursk Vostochny, URS, kursk vostochny kursk russia', key:'A_URS', cityBase: 51, city: 'URS', country: 'RU', type: 'airport', nlabel: 'kursk vostochny kursk russia'},
  { icon: 'faPlaneDeparture', label: 'KUF Samara, Russia, Kurumoch International, KUF, kurumoch international samara russia', key:'A_KUF', cityBase: 43, city: 'KUF', country: 'RU', type: 'airport', nlabel: 'kurumoch international samara russia'},
  { icon: 'faPlaneDeparture', label: 'KUH Kushiro, Japan, Kushiro, KUH, kushiro kushiro japan', key:'A_KUH', cityBase: 127, city: 'KUH', country: 'JP', type: 'airport', nlabel: 'kushiro kushiro japan'},
  { icon: 'faPlaneDeparture', label: 'IXL Leh, Unknown Region, Kushok Bakula Rimpochee, IXL, kushok bakula rimpochee leh unknown region', key:'A_IXL', cityBase: 79, city: 'IXL', country: 'ZZ', type: 'airport', nlabel: 'kushok bakula rimpochee leh unknown region'},
  { icon: 'faPlaneDeparture', label: 'YVP Kuujjuaq, Canada, Kuujjuaq, YVP, kuujjuaq kuujjuaq quebec canada', key:'A_YVP', cityBase: 127, city: 'YVP', country: 'CA', type: 'airport', nlabel: 'kuujjuaq kuujjuaq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YGW Kuujjuarapik, Canada, Kuujjuarapik, YGW, kuujjuarapik kuujjuarapik quebec canada', key:'A_YGW', cityBase: 127, city: 'YGW', country: 'CA', type: 'airport', nlabel: 'kuujjuarapik kuujjuarapik quebec canada'},
  { icon: 'faPlaneDeparture', label: 'KAO Kuusamo, Finland, Kuusamo, KAO, kuusamo kuusamo finland', key:'A_KAO', cityBase: 117, city: 'KAO', country: 'FI', type: 'airport', nlabel: 'kuusamo kuusamo finland'},
  { icon: 'faPlaneDeparture', label: 'KWI Kuwait City, Kuwait, Kuwait International, KWI, kuwait international kuwait city kuwait', key:'A_KWI', cityBase: 22, city: 'KWI', country: 'KW', type: 'airport', nlabel: 'kuwait international kuwait city kuwait'},
  { icon: 'faPlaneDeparture', label: 'KWT Kwethluk, United States, Kwethluk, KWT, kwethluk kwethluk alaska united states', key:'A_KWT', cityBase: 127, city: 'KWT', country: 'US', type: 'airport', nlabel: 'kwethluk kwethluk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KWK Kwigillingok, United States, Kwigillingok, KWK, kwigillingok kwigillingok alaska united states', key:'A_KWK', cityBase: 127, city: 'KWK', country: 'US', type: 'airport', nlabel: 'kwigillingok kwigillingok alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KYP Kyaukpyu, Myanmar (Burma), Kyaukpyu, KYP, kyaukpyu kyaukpyu myanmar (burma)', key:'A_KYP', cityBase: 127, city: 'KYP', country: 'MM', type: 'airport', nlabel: 'kyaukpyu kyaukpyu myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'IEV Kiev, Ukraine, Kyiv International Airport (Zhuliany), IEV, kyiv international airport (zhuliany) kiev ukraine', key:'A_IEV', cityBase: 30, city: 'IEV', country: 'UA', type: 'airport', nlabel: 'kyiv international airport (zhuliany) kiev ukraine'},
  { icon: 'faPlaneDeparture', label: 'KYZ Kyzyl, Russia, Kyzyl, KYZ, kyzyl kyzyl russia', key:'A_KYZ', cityBase: 57, city: 'KYZ', country: 'RU', type: 'airport', nlabel: 'kyzyl kyzyl russia'},
  { icon: 'faPlaneDeparture', label: 'KZO Kyzylorda, Kazakhstan, Kyzylorda, KZO, kyzylorda kyzylorda kazakhstan', key:'A_KZO', cityBase: 127, city: 'KZO', country: 'KZ', type: 'airport', nlabel: 'kyzylorda kyzylorda kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'LOO Laghouat, Algeria, L Mekrareg, LOO, l mekrareg laghouat algeria', key:'A_LOO', cityBase: 102, city: 'LOO', country: 'DZ', type: 'airport', nlabel: 'l mekrareg laghouat algeria'},
  { icon: 'faPlaneDeparture', label: 'OLF Wolf Point, United States, L. M. Clayton, OLF, l m clayton wolf point montana united states', key:'A_OLF', cityBase: 127, city: 'OLF', country: 'US', type: 'airport', nlabel: 'l m clayton wolf point montana united states'},
  { icon: 'faPlaneDeparture', label: 'BDA Bermuda, United Kingdom, L.F. Wade International, BDA, l f wade international bermuda bermuda united kingdom', key:'A_BDA', cityBase: -976, city: 'BDA', country: 'GB', type: 'airport', nlabel: 'l f wade international bermuda bermuda united kingdom'},
  { icon: 'faPlaneDeparture', label: 'ZCO Temuco, Chile, La Araucanía International, ZCO, la araucania international temuco chile', key:'A_ZCO', cityBase: 102, city: 'ZCO', country: 'CL', type: 'airport', nlabel: 'la araucania international temuco chile'},
  { icon: 'faPlaneDeparture', label: 'GUA Guatemala City, Guatemala, La Aurora International, GUA, la aurora international guatemala city guatemala', key:'A_GUA', cityBase: 15, city: 'GUA', country: 'GT', type: 'airport', nlabel: 'la aurora international guatemala city guatemala'},
  { icon: 'faPlaneDeparture', label: 'MAR Maracaibo, Venezuela, La Chinita International, MAR, la chinita international maracaibo venezuela', key:'A_MAR', cityBase: 34, city: 'MAR', country: 'VE', type: 'airport', nlabel: 'la chinita international maracaibo venezuela'},
  { icon: 'faPlaneDeparture', label: 'LCR La Chorrera, Colombia, La Chorrera, LCR, la chorrera la chorrera colombia', key:'A_LCR', cityBase: 127, city: 'LCR', country: 'CO', type: 'airport', nlabel: 'la chorrera la chorrera colombia'},
  { icon: 'faPlaneDeparture', label: 'LSE La Crosse, United States, La Crosse Regional, LSE, la crosse regional la crosse wisconsin united states', key:'A_LSE', cityBase: 87, city: 'LSE', country: 'US', type: 'airport', nlabel: 'la crosse regional la crosse wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'LSC La Serena, Chile, La Florida, LSC, la florida la serena chile', key:'A_LSC', cityBase: 105, city: 'LSC', country: 'CL', type: 'airport', nlabel: 'la florida la serena chile'},
  { icon: 'faPlaneDeparture', label: 'TCO Tumaco, Colombia, La Florida, TCO, la florida tumaco colombia', key:'A_TCO', cityBase: 127, city: 'TCO', country: 'CO', type: 'airport', nlabel: 'la florida tumaco colombia'},
  { icon: 'faPlaneDeparture', label: 'LFR La Fría, Venezuela, La Fría, LFR, la fria la fria venezuela', key:'A_LFR', cityBase: 80, city: 'LFR', country: 'VE', type: 'airport', nlabel: 'la fria la fria venezuela'},
  { icon: 'faPlaneDeparture', label: 'GMZ San Sebastián de La Gomera, Spain, La Gomera, GMZ, la gomera san sebastian de la gomera spain', key:'A_GMZ', cityBase: 127, city: 'GMZ', country: 'ES', type: 'airport', nlabel: 'la gomera san sebastian de la gomera spain'},
  { icon: 'faPlaneDeparture', label: 'YGL La Grande River, Canada, La Grande Rivière, YGL, la grande riviere la grande river quebec canada', key:'A_YGL', cityBase: 127, city: 'YGL', country: 'CA', type: 'airport', nlabel: 'la grande riviere la grande river quebec canada'},
  { icon: 'faPlaneDeparture', label: 'JBQ Santo Domingo, Dominican Republic, La Isabela International, JBQ, la isabela international santo domingo dominican republic', key:'A_JBQ', cityBase: 84, city: 'SDQ', country: 'DO', type: 'airport', nlabel: 'la isabela international santo domingo dominican republic'},
  { icon: 'faPlaneDeparture', label: 'GJA Guanaja, Honduras, La Laguna Airport, GJA, la laguna airport guanaja honduras', key:'A_GJA', cityBase: 127, city: 'GJA', country: 'HN', type: 'airport', nlabel: 'la laguna airport guanaja honduras'},
  { icon: 'faPlaneDeparture', label: 'LMC La Macarena, Colombia, La Macarena, LMC, la macarena la macarena colombia', key:'A_LMC', cityBase: 127, city: 'LMC', country: 'CO', type: 'airport', nlabel: 'la macarena la macarena colombia'},
  { icon: 'faPlaneDeparture', label: 'MZL Manizales, Colombia, La Nubia, MZL, la nubia manizales colombia', key:'A_MZL', cityBase: 89, city: 'MZL', country: 'CO', type: 'airport', nlabel: 'la nubia manizales colombia'},
  { icon: 'faPlaneDeparture', label: 'SPC Santa Cruz de La Palma, Spain, La Palma, SPC, la palma santa cruz de la palma spain', key:'A_SPC', cityBase: 16, city: 'SPC', country: 'ES', type: 'airport', nlabel: 'la palma santa cruz de la palma spain'},
  { icon: 'faPlaneDeparture', label: 'LAP La Paz, Mexico, La Paz International, LAP, la paz international la paz mexico', key:'A_LAP', cityBase: 55, city: 'LAP', country: 'MX', type: 'airport', nlabel: 'la paz international la paz mexico'},
  { icon: 'faPlaneDeparture', label: 'LPD La Pedrera, Colombia, La Pedrera, LPD, la pedrera la pedrera colombia', key:'A_LPD', cityBase: 127, city: 'LPD', country: 'CO', type: 'airport', nlabel: 'la pedrera la pedrera colombia'},
  { icon: 'faPlaneDeparture', label: 'IRJ La Rioja, Argentina, La Rioja, IRJ, la rioja la rioja la rioja province argentina', key:'A_IRJ', cityBase: 127, city: 'IRJ', country: 'AR', type: 'airport', nlabel: 'la rioja la rioja la rioja province argentina'},
  { icon: 'faPlaneDeparture', label: 'LRH La Rochelle, France, La Rochelle – Île de Ré, LRH, la rochelle ile de re la rochelle france', key:'A_LRH', cityBase: 65, city: 'LRH', country: 'FR', type: 'airport', nlabel: 'la rochelle ile de re la rochelle france'},
  { icon: 'faPlaneDeparture', label: 'ZGS La Romaine, Quebec, Canada, La Romaine, ZGS, la romaine la romaine quebec canada', key:'A_ZGS', cityBase: 127, city: 'ZGS', country: 'CA', type: 'airport', nlabel: 'la romaine la romaine quebec canada'},
  { icon: 'faPlaneDeparture', label: 'LRM La Romana, Dominican Republic, La Romana International, LRM, la romana international la romana dominican republic', key:'A_LRM', cityBase: 65, city: 'LRM', country: 'DO', type: 'airport', nlabel: 'la romana international la romana dominican republic'},
  { icon: 'faPlaneDeparture', label: 'ZLT La Tabatière, Quebec, Canada, La Tabatière, ZLT, la tabatiere la tabatiere quebec canada', key:'A_ZLT', cityBase: 127, city: 'ZLT', country: 'CA', type: 'airport', nlabel: 'la tabatiere la tabatiere quebec canada'},
  { icon: 'faPlaneDeparture', label: 'NOU Nouméa, New Caledonia, La Tontouta International, NOU, la tontouta international noumea new caledonia', key:'A_NOU', cityBase: 61, city: 'NOU', country: 'NC', type: 'airport', nlabel: 'la tontouta international noumea new caledonia'},
  { icon: 'faPlaneDeparture', label: 'VVC Villavicencio, Colombia, La Vanguardia, VVC, la vanguardia villavicencio colombia', key:'A_VVC', cityBase: 112, city: 'VVC', country: 'CO', type: 'airport', nlabel: 'la vanguardia villavicencio colombia'},
  { icon: 'faPlaneDeparture', label: 'LBS Labasa, Fiji, Labasa, LBS, labasa labasa fiji', key:'A_LBS', cityBase: 96, city: 'LBS', country: 'FJ', type: 'airport', nlabel: 'labasa labasa fiji'},
  { icon: 'faPlaneDeparture', label: 'OZC Ozamiz, Philippines, Labo, OZC, labo ozamiz philippines', key:'A_OZC', cityBase: 111, city: 'OZC', country: 'PH', type: 'airport', nlabel: 'labo ozamiz philippines'},
  { icon: 'faPlaneDeparture', label: 'LBU Labuan, Malaysia, Labuan, LBU, labuan labuan malaysia', key:'A_LBU', cityBase: 127, city: 'LBU', country: 'MY', type: 'airport', nlabel: 'labuan labuan malaysia'},
  { icon: 'faPlaneDeparture', label: 'XLB Lac Brochet, Manitoba, Canada, Lac Brochet, XLB, lac brochet lac brochet manitoba canada', key:'A_XLB', cityBase: 127, city: 'XLB', country: 'CA', type: 'airport', nlabel: 'lac brochet lac brochet manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'ZWH Lac Brochet, Manitoba, Canada, Lac Brochet Airport, ZWH, lac brochet airport lac brochet manitoba canada', key:'A_ZWH', cityBase: 127, city: 'XLB', country: 'CA', type: 'airport', nlabel: 'lac brochet airport lac brochet manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'LAE Lae, Papua New Guinea, Lae Nadzab, LAE, lae nadzab lae papua new guinea', key:'A_LAE', cityBase: 127, city: 'LAE', country: 'PG', type: 'airport', nlabel: 'lae nadzab lae papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'LFT Lafayette, United States, Lafayette Regional, LFT, lafayette regional lafayette louisiana united states', key:'A_LFT', cityBase: 53, city: 'LFT', country: 'US', type: 'airport', nlabel: 'lafayette regional lafayette louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'LAJ Lages, Brazil, Lages, LAJ, lages lages brazil', key:'A_LAJ', cityBase: 127, city: 'LAJ', country: 'BR', type: 'airport', nlabel: 'lages lages brazil'},
  { icon: 'faPlaneDeparture', label: 'LGQ Lago Agrio Canton, Ecuador, Lago Agrio, LGQ, lago agrio lago agrio canton ecuador', key:'A_LGQ', cityBase: 120, city: 'LGQ', country: 'EC', type: 'airport', nlabel: 'lago agrio lago agrio canton ecuador'},
  { icon: 'faPlaneDeparture', label: 'LGA New York City, United States, LaGuardia, LGA, laguardia new york city new york united states', key:'A_LGA', cityBase: 3, city: 'NYC', country: 'US', type: 'airport', nlabel: 'laguardia new york city new york united states'},
  { icon: 'faPlaneDeparture', label: 'LDU Lahad Datu, Malaysia, Lahad Datu, LDU, lahad datu lahad datu malaysia', key:'A_LDU', cityBase: 122, city: 'LDU', country: 'MY', type: 'airport', nlabel: 'lahad datu lahad datu malaysia'},
  { icon: 'faPlaneDeparture', label: 'PXA Lahat Regency, Indonesia, Lahat Pagar Alam, PXA, lahat pagar alam lahat regency indonesia', key:'A_PXA', cityBase: 127, city: 'PXA', country: 'ID', type: 'airport', nlabel: 'lahat pagar alam lahat regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'TER Terceira Island, Portugal, Lajes Field, TER, lajes field terceira island portugal', key:'A_TER', cityBase: 22, city: 'TER', country: 'PT', type: 'airport', nlabel: 'lajes field terceira island portugal'},
  { icon: 'faPlaneDeparture', label: 'LCH Lake Charles, United States, Lake Charles Regional, LCH, lake charles regional lake charles louisiana united states', key:'A_LCH', cityBase: 88, city: 'LCH', country: 'US', type: 'airport', nlabel: 'lake charles regional lake charles louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'LKY Lake Manyara, Tanzania, Lake Manyara, LKY, lake manyara lake manyara tanzania', key:'A_LKY', cityBase: 127, city: 'LKY', country: 'TZ', type: 'airport', nlabel: 'lake manyara lake manyara tanzania'},
  { icon: 'faPlaneDeparture', label: 'LKB Lakeba, Fiji, Lakeba, LKB, lakeba lakeba fiji', key:'A_LKB', cityBase: 127, city: 'LKB', country: 'FJ', type: 'airport', nlabel: 'lakeba lakeba fiji'},
  { icon: 'faPlaneDeparture', label: 'LKL Lakselv, Norway, Lakselv Airport, Banak, LKL, lakselv airport lakselv norway', key:'A_LKL', cityBase: 127, city: 'LKL', country: 'NO', type: 'airport', nlabel: 'lakselv airport lakselv norway'},
  { icon: 'faPlaneDeparture', label: 'VNS Varanasi, India, Lal Bahadur Shastri International, VNS, lal bahadur shastri international varanasi india', key:'A_VNS', cityBase: 73, city: 'VNS', country: 'IN', type: 'airport', nlabel: 'lal bahadur shastri international varanasi india'},
  { icon: 'faPlaneDeparture', label: 'LLI Lalibela, Ethiopia, Lalibela, LLI, lalibela lalibela ethiopia', key:'A_LLI', cityBase: 127, city: 'LLI', country: 'ET', type: 'airport', nlabel: 'lalibela lalibela ethiopia'},
  { icon: 'faPlaneDeparture', label: 'LNB Lamen Bay, Vanuatu, Lamen Bay, LNB, lamen bay lamen bay vanuatu', key:'A_LNB', cityBase: 127, city: 'LNB', country: 'VU', type: 'airport', nlabel: 'lamen bay lamen bay vanuatu'},
  { icon: 'faPlaneDeparture', label: 'LFM Lamerd, Iran, Lamerd, LFM, lamerd lamerd iran', key:'A_LFM', cityBase: 127, city: 'LFM', country: 'IR', type: 'airport', nlabel: 'lamerd lamerd iran'},
  { icon: 'faPlaneDeparture', label: 'SUF Lamezia Terme, Italy, Lamezia Terme International, SUF, lamezia terme international lamezia terme italy', key:'A_SUF', cityBase: 11, city: 'SUF', country: 'IT', type: 'airport', nlabel: 'lamezia terme international lamezia terme italy'},
  { icon: 'faPlaneDeparture', label: 'LPT Lampang Province, Thailand, Lampang, LPT, lampang lampang province thailand', key:'A_LPT', cityBase: 111, city: 'LPT', country: 'TH', type: 'airport', nlabel: 'lampang lampang province thailand'},
  { icon: 'faPlaneDeparture', label: 'LMP Lampedusa, Italy, Lampedusa, LMP, lampedusa lampedusa italy', key:'A_LMP', cityBase: 20, city: 'LMP', country: 'IT', type: 'airport', nlabel: 'lampedusa lampedusa italy'},
  { icon: 'faPlaneDeparture', label: 'LNY Lanai, United States, Lanai, LNY, lanai lanai hawaii united states', key:'A_LNY', cityBase: 110, city: 'LNY', country: 'US', type: 'airport', nlabel: 'lanai lanai hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'LGK Langkawi, Malaysia, Langkawi International, LGK, langkawi international langkawi malaysia', key:'A_LGK', cityBase: 28, city: 'LGK', country: 'MY', type: 'airport', nlabel: 'langkawi international langkawi malaysia'},
  { icon: 'faPlaneDeparture', label: 'LLK Lankaran, Azerbaijan, Lankaran International, LLK, lankaran international lankaran azerbaijan', key:'A_LLK', cityBase: 65, city: 'LLK', country: 'AZ', type: 'airport', nlabel: 'lankaran international lankaran azerbaijan'},
  { icon: 'faPlaneDeparture', label: 'LAI Lannion, France, Lannion – Côte de Granit, LAI, lannion cote de granit lannion france', key:'A_LAI', cityBase: 127, city: 'LAI', country: 'FR', type: 'airport', nlabel: 'lannion cote de granit lannion france'},
  { icon: 'faPlaneDeparture', label: 'HLA Johannesburg, South Africa, Lanseria International, HLA, lanseria international johannesburg south africa', key:'A_HLA', cityBase: 110, city: 'JNB', country: 'ZA', type: 'airport', nlabel: 'lanseria international johannesburg south africa'},
  { icon: 'faPlaneDeparture', label: 'ACE Lanzarote, Spain, Lanzarote, ACE, lanzarote lanzarote spain', key:'A_ACE', cityBase: 6, city: 'ACE', country: 'ES', type: 'airport', nlabel: 'lanzarote lanzarote spain'},
  { icon: 'faPlaneDeparture', label: 'LHW Lanzhou, China, Lanzhou Zhongchuan International, LHW, lanzhou zhongchuan international lanzhou china', key:'A_LHW', cityBase: 64, city: 'LHW', country: 'CN', type: 'airport', nlabel: 'lanzhou zhongchuan international lanzhou china'},
  { icon: 'faPlaneDeparture', label: 'LAO Laoag, Philippines, Laoag International, LAO, laoag international laoag philippines', key:'A_LAO', cityBase: 66, city: 'LAO', country: 'PH', type: 'airport', nlabel: 'laoag international laoag philippines'},
  { icon: 'faPlaneDeparture', label: 'LPP Lappeenranta, Finland, Lappeenranta, LPP, lappeenranta lappeenranta finland', key:'A_LPP', cityBase: 114, city: 'LPP', country: 'FI', type: 'airport', nlabel: 'lappeenranta lappeenranta finland'},
  { icon: 'faPlaneDeparture', label: 'LAR Laramie, United States, Laramie Regional, LAR, laramie regional laramie wyoming united states', key:'A_LAR', cityBase: 120, city: 'LAR', country: 'US', type: 'airport', nlabel: 'laramie regional laramie wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'LRD Laredo, United States, Laredo International, LRD, laredo international laredo texas united states', key:'A_LRD', cityBase: 81, city: 'LRD', country: 'US', type: 'airport', nlabel: 'laredo international laredo texas united states'},
  { icon: 'faPlaneDeparture', label: 'LRR Lar, Iran, Larestan International, LRR, larestan international lar iran', key:'A_LRR', cityBase: 118, city: 'LRR', country: 'IR', type: 'airport', nlabel: 'larestan international lar iran'},
  { icon: 'faPlaneDeparture', label: 'LCA Larnaca, Cyprus, Larnaca International, LCA, larnaca international larnaca cyprus', key:'A_LCA', cityBase: 7, city: 'LCA', country: 'CY', type: 'airport', nlabel: 'larnaca international larnaca cyprus'},
  { icon: 'faPlaneDeparture', label: 'KLN Larsen Bay, United States, Larsen Bay, KLN, larsen bay larsen bay alaska united states', key:'A_KLN', cityBase: 127, city: 'KLN', country: 'US', type: 'airport', nlabel: 'larsen bay larsen bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SDQ Santo Domingo, Dominican Republic, Las Américas International, SDQ, las americas international santo domingo dominican republic', key:'A_SDQ', cityBase: 12, city: 'SDQ', country: 'DO', type: 'airport', nlabel: 'las americas international santo domingo dominican republic'},
  { icon: 'faPlaneDeparture', label: 'CZU Corozal, Sucre, Colombia, Las Brujas, CZU, las brujas corozal colombia', key:'A_CZU', cityBase: 127, city: 'CZU', country: 'CO', type: 'airport', nlabel: 'las brujas corozal colombia'},
  { icon: 'faPlaneDeparture', label: 'SFD San Fernando de Apure, Venezuela, Las Flecheras, SFD, las flecheras san fernando de apure venezuela', key:'A_SFD', cityBase: 121, city: 'SFD', country: 'VE', type: 'airport', nlabel: 'las flecheras san fernando de apure venezuela'},
  { icon: 'faPlaneDeparture', label: 'RCU Río Cuarto, Argentina, Las Higueras, RCU, las higueras rio cuarto cordoba argentina', key:'A_RCU', cityBase: 127, city: 'RCU', country: 'AR', type: 'airport', nlabel: 'las higueras rio cuarto cordoba argentina'},
  { icon: 'faPlaneDeparture', label: 'ZAL Valdivia, Chile, Las Marías, ZAL, las marias valdivia chile', key:'A_ZAL', cityBase: 127, city: 'ZAL', country: 'CL', type: 'airport', nlabel: 'las marias valdivia chile'},
  { icon: 'faPlaneDeparture', label: 'RHD Termas de Río Hondo, Argentina, Las Termas, RHD, las termas termas de rio hondo santiago del estero argentina', key:'A_RHD', cityBase: 127, city: 'RHD', country: 'AR', type: 'airport', nlabel: 'las termas termas de rio hondo santiago del estero argentina'},
  { icon: 'faPlaneDeparture', label: 'LSH Lashio, Myanmar (Burma), Lashio, LSH, lashio lashio myanmar (burma)', key:'A_LSH', cityBase: 127, city: 'LSH', country: 'MM', type: 'airport', nlabel: 'lashio lashio myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'IFP Bullhead City, United States, Laughlin/Bullhead International, IFP, laughlin bullhead international bullhead city arizona united states', key:'A_IFP', cityBase: 127, city: 'IFP', country: 'US', type: 'airport', nlabel: 'laughlin bullhead international bullhead city arizona united states'},
  { icon: 'faPlaneDeparture', label: 'LST City of Launceston, Australia, Launceston, LST, launceston city of launceston tasmania australia', key:'A_LST', cityBase: 91, city: 'LST', country: 'AU', type: 'airport', nlabel: 'launceston city of launceston tasmania australia'},
  { icon: 'faPlaneDeparture', label: 'PFB Passo Fundo, Brazil, Lauro Kurtz, PFB, lauro kurtz passo fundo state of rio grande do sul brazil', key:'A_PFB', cityBase: 127, city: 'PFB', country: 'BR', type: 'airport', nlabel: 'lauro kurtz passo fundo state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'LWY Lawas, Malaysia, Lawas, LWY, lawas lawas malaysia', key:'A_LWY', cityBase: 127, city: 'LWY', country: 'MY', type: 'airport', nlabel: 'lawas lawas malaysia'},
  { icon: 'faPlaneDeparture', label: 'LAW Lawton, United States, Lawton–Fort Sill Regional, LAW, lawton fort sill regional lawton oklahoma united states', key:'A_LAW', cityBase: 98, city: 'LAW', country: 'US', type: 'airport', nlabel: 'lawton fort sill regional lawton oklahoma united states'},
  { icon: 'faPlaneDeparture', label: 'LZC Lázaro Cárdenas, Mexico, Lázaro Cárdenas, LZC, lazaro cardenas lazaro cardenas mexico', key:'A_LZC', cityBase: 127, city: 'LZC', country: 'MX', type: 'airport', nlabel: 'lazaro cardenas lazaro cardenas mexico'},
  { icon: 'faPlaneDeparture', label: 'LPY Le Puy-en-Velay, France, Le Puy – Loudes, LPY, le puy loudes le puy en velay france', key:'A_LPY', cityBase: 45, city: 'LPY', country: 'FR', type: 'airport', nlabel: 'le puy loudes le puy en velay france'},
  { icon: 'faPlaneDeparture', label: 'HOB Hobbs, United States, Lea County Regional, HOB, lea county regional hobbs new mexico united states', key:'A_HOB', cityBase: 126, city: 'HOB', country: 'US', type: 'airport', nlabel: 'lea county regional hobbs new mexico united states'},
  { icon: 'faPlaneDeparture', label: 'LEB Lebanon, United States, Lebanon Municipal, LEB, lebanon municipal lebanon new hampshire united states', key:'A_LEB', cityBase: 96, city: 'LEB', country: 'US', type: 'airport', nlabel: 'lebanon municipal lebanon new hampshire united states'},
  { icon: 'faPlaneDeparture', label: 'LBA Leeds, United Kingdom, Leeds Bradford, LBA, leeds bradford leeds united kingdom', key:'A_LBA', cityBase: -990, city: 'LBA', country: 'GB', type: 'airport', nlabel: 'leeds bradford leeds united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LGP Legazpi, Philippines, Legazpi, LGP, legazpi legazpi philippines', key:'A_LGP', cityBase: 84, city: 'LGP', country: 'PH', type: 'airport', nlabel: 'legazpi legazpi philippines'},
  { icon: 'faPlaneDeparture', label: 'ABE Allentown, United States, Lehigh Valley International, ABE, lehigh valley international allentown pennsylvania united states', key:'A_ABE', cityBase: 47, city: 'ABE', country: 'US', type: 'airport', nlabel: 'lehigh valley international allentown pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'LEJ Leipzig, Germany, Leipzig/Halle Airport, LEJ, leipzig halle leipzig germany', key:'A_LEJ', cityBase: 1, city: 'LEJ', country: 'DE', type: 'airport', nlabel: 'leipzig halle leipzig germany'},
  { icon: 'faPlaneDeparture', label: 'RAO Ribeirão Preto, Brazil, Leite Lopes, RAO, leite lopes ribeirao preto state of sao paulo brazil', key:'A_RAO', cityBase: 127, city: 'RAO', country: 'BR', type: 'airport', nlabel: 'leite lopes ribeirao preto state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'LKN Leknes, Norway, Leknes, LKN, leknes leknes norway', key:'A_LKN', cityBase: 104, city: 'LKN', country: 'NO', type: 'airport', nlabel: 'leknes leknes norway'},
  { icon: 'faPlaneDeparture', label: 'LXS Lemnos, Greece, Lemnos International, LXS, lemnos international lemnos greece', key:'A_LXS', cityBase: 24, city: 'LXS', country: 'GR', type: 'airport', nlabel: 'lemnos international lemnos greece'},
  { icon: 'faPlaneDeparture', label: 'LEC Lençóis, Brazil, Lençóis, LEC, lencois lencois state of bahia brazil', key:'A_LEC', cityBase: 127, city: 'LEC', country: 'BR', type: 'airport', nlabel: 'lencois lencois state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'AJL Aizawl, India, Lengpui, AJL, lengpui aizawl india', key:'A_AJL', cityBase: 121, city: 'AJL', country: 'IN', type: 'airport', nlabel: 'lengpui aizawl india'},
  { icon: 'faPlaneDeparture', label: 'ULK Lensk, Russia, Lensk, ULK, lensk lensk russia', key:'A_ULK', cityBase: 56, city: 'ULK', country: 'RU', type: 'airport', nlabel: 'lensk lensk russia'},
  { icon: 'faPlaneDeparture', label: 'OTI Morotai, Indonesia, Leo Wattimena, OTI, leo wattimena morotai indonesia', key:'A_OTI', cityBase: 127, city: 'OTI', country: 'ID', type: 'airport', nlabel: 'leo wattimena morotai indonesia'},
  { icon: 'faPlaneDeparture', label: 'LEN León, Spain, León, LEN, leon leon spain', key:'A_LEN', cityBase: 19, city: 'LEN', country: 'ES', type: 'airport', nlabel: 'leon leon spain'},
  { icon: 'faPlaneDeparture', label: 'FCO Rome, Italy, Leonardo da Vinci–Fiumicino Airport, FCO, leonardo da vinci fiumicino rome italy', key:'A_FCO', cityBase: 4, city: 'ROM', country: 'IT', type: 'airport', nlabel: 'leonardo da vinci fiumicino rome italy'},
  { icon: 'faPlaneDeparture', label: 'DKR Dakar, Senegal, Léopold Sédar Senghor International, DKR, leopold sedar senghor international dakar senegal', key:'A_DKR', cityBase: 35, city: 'DKR', country: 'SN', type: 'airport', nlabel: 'leopold sedar senghor international dakar senegal'},
  { icon: 'faPlaneDeparture', label: 'OSR Ostrava, Czechia, Leoš Janáček Airport Ostrava, OSR, leos janacek airport ostrava ostrava czechia', key:'A_OSR', cityBase: 3, city: 'OSR', country: 'CZ', type: 'airport', nlabel: 'leos janacek airport ostrava ostrava czechia'},
  { icon: 'faPlaneDeparture', label: 'LRS Leros, Greece, Leros Municipal, LRS, leros municipal leros greece', key:'A_LRS', cityBase: 28, city: 'LRS', country: 'GR', type: 'airport', nlabel: 'leros municipal leros greece'},
  { icon: 'faPlaneDeparture', label: 'GBJ Grand Bourg, France, Les Bases Airport, GBJ, les bases airport grand bourg guadeloupe france 1', key:'A_GBJ', cityBase: 127, city: 'GBJ', country: 'FR', type: 'airport', nlabel: 'les bases airport grand bourg guadeloupe france 1'},
  { icon: 'faPlaneDeparture', label: 'YQL Lethbridge, Canada, Lethbridge, YQL, lethbridge lethbridge alberta canada', key:'A_YQL', cityBase: 107, city: 'YQL', country: 'CA', type: 'airport', nlabel: 'lethbridge lethbridge alberta canada'},
  { icon: 'faPlaneDeparture', label: 'KLL Levelock, United States, Levelock, KLL, levelock levelock alaska united states', key:'A_KLL', cityBase: 127, city: 'KLL', country: 'US', type: 'airport', nlabel: 'levelock levelock alaska united states'},
  { icon: 'faPlaneDeparture', label: 'LWS Lewiston, United States, Lewiston–Nez Perce County, LWS, lewiston nez perce county lewiston idaho united states', key:'A_LWS', cityBase: 90, city: 'LWS', country: 'US', type: 'airport', nlabel: 'lewiston nez perce county lewiston idaho united states'},
  { icon: 'faPlaneDeparture', label: 'LXA Lhasa, China, Lhasa Gonggar, LXA, lhasa gonggar lhasa china', key:'A_LXA', cityBase: 62, city: 'LXA', country: 'CN', type: 'airport', nlabel: 'lhasa gonggar lhasa china'},
  { icon: 'faPlaneDeparture', label: 'LYG Lianyungang, China, Lianyungang Baitabu, LYG, lianyungang baitabu lianyungang china', key:'A_LYG', cityBase: 74, city: 'LYG', country: 'CN', type: 'airport', nlabel: 'lianyungang baitabu lianyungang china'},
  { icon: 'faPlaneDeparture', label: 'LBL Liberal, United States, Liberal Mid-America Regional, LBL, liberal mid america regional liberal kansas united states', key:'A_LBL', cityBase: 127, city: 'LBL', country: 'US', type: 'airport', nlabel: 'liberal mid america regional liberal kansas united states'},
  { icon: 'faPlaneDeparture', label: 'PSS Posadas, Argentina, Libertador General José de San Martín, PSS, libertador general jose de san martin posadas misiones argentina', key:'A_PSS', cityBase: 127, city: 'PSS', country: 'AR', type: 'airport', nlabel: 'libertador general jose de san martin posadas misiones argentina'},
  { icon: 'faPlaneDeparture', label: 'LLB Libo County, China, Libo, LLB, libo libo county china', key:'A_LLB', cityBase: 70, city: 'LLB', country: 'CN', type: 'airport', nlabel: 'libo libo county china'},
  { icon: 'faPlaneDeparture', label: 'LBV Libreville, Gabon, Libreville International, LBV, libreville international libreville gabon', key:'A_LBV', cityBase: 77, city: 'LBV', country: 'GA', type: 'airport', nlabel: 'libreville international libreville gabon'},
  { icon: 'faPlaneDeparture', label: 'PVR Puerto Vallarta, Mexico, Licenciado Gustavo Díaz Ordaz International, PVR, licenciado gustavo diaz ordaz international puerto vallarta mexico', key:'A_PVR', cityBase: 20, city: 'PVR', country: 'MX', type: 'airport', nlabel: 'licenciado gustavo diaz ordaz international puerto vallarta mexico'},
  { icon: 'faPlaneDeparture', label: 'VXC Lichinga, Mozambique, Lichinga, VXC, lichinga lichinga mozambique', key:'A_VXC', cityBase: 127, city: 'VXC', country: 'MZ', type: 'airport', nlabel: 'lichinga lichinga mozambique'},
  { icon: 'faPlaneDeparture', label: 'LGG Liège, Belgium, Liège, LGG, liege liege belgium', key:'A_LGG', cityBase: 127, city: 'LGG', country: 'BE', type: 'airport', nlabel: 'liege liege belgium'},
  { icon: 'faPlaneDeparture', label: 'DLI Da Lat, Vietnam, Lien Khuong, DLI, lien khuong da lat vietnam', key:'A_DLI', cityBase: 87, city: 'DLI', country: 'VN', type: 'airport', nlabel: 'lien khuong da lat vietnam'},
  { icon: 'faPlaneDeparture', label: 'LPX Liepāja, Latvia, Liepāja International, LPX, liepaja international liepaja latvia', key:'A_LPX', cityBase: 127, city: 'LPX', country: 'LV', type: 'airport', nlabel: 'liepaja international liepaja latvia'},
  { icon: 'faPlaneDeparture', label: 'LNV Lihir Group, Papua New Guinea, Lihir Island, LNV, lihir island lihir group papua new guinea', key:'A_LNV', cityBase: 127, city: 'LNV', country: 'PG', type: 'airport', nlabel: 'lihir island lihir group papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'LIH Kauai, United States, Lihue, LIH, lihue kauai hawaii united states', key:'A_LIH', cityBase: 18, city: 'LIH', country: 'US', type: 'airport', nlabel: 'lihue kauai hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'LJG Lijiang, China, Lijiang Sanyi International, LJG, lijiang sanyi international lijiang china', key:'A_LJG', cityBase: 74, city: 'LJG', country: 'CN', type: 'airport', nlabel: 'lijiang sanyi international lijiang china'},
  { icon: 'faPlaneDeparture', label: 'IXI Lakhimpur district, India, Lilabari, IXI, lilabari lakhimpur district india', key:'A_IXI', cityBase: 127, city: 'IXI', country: 'IN', type: 'airport', nlabel: 'lilabari lakhimpur district india'},
  { icon: 'faPlaneDeparture', label: 'LIL Lille, France, Lille, LIL, lille lille france', key:'A_LIL', cityBase: 52, city: 'LIL', country: 'FR', type: 'airport', nlabel: 'lille lille france'},
  { icon: 'faPlaneDeparture', label: 'LLW Lilongwe, Malawi, Lilongwe International, LLW, lilongwe international lilongwe malawi', key:'A_LLW', cityBase: 69, city: 'LLW', country: 'MW', type: 'airport', nlabel: 'lilongwe international lilongwe malawi'},
  { icon: 'faPlaneDeparture', label: 'LMN Limbang, Malaysia, Limbang, LMN, limbang limbang malaysia', key:'A_LMN', cityBase: 127, city: 'LMN', country: 'MY', type: 'airport', nlabel: 'limbang limbang malaysia'},
  { icon: 'faPlaneDeparture', label: 'LIG Limoges, France, Limoges – Bellegarde, LIG, limoges bellegarde limoges france', key:'A_LIG', cityBase: 81, city: 'LIG', country: 'FR', type: 'airport', nlabel: 'limoges bellegarde limoges france'},
  { icon: 'faPlaneDeparture', label: 'LIO Puerto Limón, Costa Rica, Limón International, LIO, limon international puerto limon costa rica', key:'A_LIO', cityBase: 101, city: 'LIO', country: 'CR', type: 'airport', nlabel: 'limon international puerto limon costa rica'},
  { icon: 'faPlaneDeparture', label: 'LIN Milan, Italy, Linate, LIN, linate milan italy', key:'A_LIN', cityBase: 7, city: 'MIL', country: 'IT', type: 'airport', nlabel: 'linate milan italy'},
  { icon: 'faPlaneDeparture', label: 'LNJ Lincang, China, Lincang, LNJ, lincang lincang china', key:'A_LNJ', cityBase: 66, city: 'LNJ', country: 'CN', type: 'airport', nlabel: 'lincang lincang china'},
  { icon: 'faPlaneDeparture', label: 'LNK Lincoln, United States, Lincoln, LNK, lincoln lincoln nebraska united states', key:'A_LNK', cityBase: 63, city: 'LNK', country: 'US', type: 'airport', nlabel: 'lincoln lincoln nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'LFQ Linfen, China, Linfen Qiaoli, LFQ, linfen qiaoli linfen china', key:'A_LFQ', cityBase: 127, city: 'LFQ', country: 'CN', type: 'airport', nlabel: 'linfen qiaoli linfen china'},
  { icon: 'faPlaneDeparture', label: 'LPI Linköping, Sweden, Linköping City, LPI, linkoping city linkoping sweden', key:'A_LPI', cityBase: 92, city: 'LPI', country: 'SE', type: 'airport', nlabel: 'linkoping city linkoping sweden'},
  { icon: 'faPlaneDeparture', label: 'LYI Linyi, China, Linyi Shubuling, LYI, linyi shubuling linyi china', key:'A_LYI', cityBase: 66, city: 'LYI', country: 'CN', type: 'airport', nlabel: 'linyi shubuling linyi china'},
  { icon: 'faPlaneDeparture', label: 'LNZ Linz, Austria, Linz, LNZ, linz linz austria', key:'A_LNZ', cityBase: 2, city: 'LNZ', country: 'AT', type: 'airport', nlabel: 'linz linz austria'},
  { icon: 'faPlaneDeparture', label: 'LPK Lipetsk, Russia, Lipetsk, LPK, lipetsk lipetsk russia', key:'A_LPK', cityBase: 51, city: 'LPK', country: 'RU', type: 'airport', nlabel: 'lipetsk lipetsk russia'},
  { icon: 'faPlaneDeparture', label: 'HZH Liping County, China, Liping, HZH, liping liping county china', key:'A_HZH', cityBase: 72, city: 'HZH', country: 'CN', type: 'airport', nlabel: 'liping liping county china'},
  { icon: 'faPlaneDeparture', label: 'LIQ Lisala, Democratic Republic of the Congo, Lisala, LIQ, lisala lisala congo kinshasa', key:'A_LIQ', cityBase: 127, city: 'LIQ', country: 'CD', type: 'airport', nlabel: 'lisala lisala congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'LIS Lisbon, Portugal, Lisbon Portela, LIS, lisbon portela lisbon portugal', key:'A_LIS', cityBase: 3, city: 'LIS', country: 'PT', type: 'airport', nlabel: 'lisbon portela lisbon portugal'},
  { icon: 'faPlaneDeparture', label: 'LSY Lismore, Australia, Lismore, LSY, lismore lismore new south wales australia', key:'A_LSY', cityBase: 127, city: 'LSY', country: 'AU', type: 'airport', nlabel: 'lismore lismore new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'LPF Liupanshui, China, Liupanshui Yuezhao, LPF, liupanshui yuezhao liupanshui china', key:'A_LPF', cityBase: 68, city: 'LPF', country: 'CN', type: 'airport', nlabel: 'liupanshui yuezhao liupanshui china'},
  { icon: 'faPlaneDeparture', label: 'LZH Liuzhou, China, Liuzhou Bailian, LZH, liuzhou bailian liuzhou china', key:'A_LZH', cityBase: 71, city: 'LZH', country: 'CN', type: 'airport', nlabel: 'liuzhou bailian liuzhou china'},
  { icon: 'faPlaneDeparture', label: 'LPL Liverpool, United Kingdom, Liverpool John Lennon, LPL, liverpool john lennon liverpool united kingdom', key:'A_LPL', cityBase: -987, city: 'LPL', country: 'GB', type: 'airport', nlabel: 'liverpool john lennon liverpool united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LJU Ljubljana, Slovenia, Ljubljana Jože Pučnik, LJU, ljubljana joze pucnik ljubljana slovenia', key:'A_LJU', cityBase: 42, city: 'LJU', country: 'SI', type: 'airport', nlabel: 'ljubljana joze pucnik ljubljana slovenia'},
  { icon: 'faPlaneDeparture', label: 'ILD Lleida, Spain, Lleida–Alguaire, ILD, lleida alguaire lleida spain', key:'A_ILD', cityBase: 18, city: 'ILD', country: 'ES', type: 'airport', nlabel: 'lleida alguaire lleida spain'},
  { icon: 'faPlaneDeparture', label: 'YLL Lloydminster, Canada, Lloydminster, YLL, lloydminster lloydminster saskatchewan canada', key:'A_YLL', cityBase: 127, city: 'YLL', country: 'CA', type: 'airport', nlabel: 'lloydminster lloydminster saskatchewan canada'},
  { icon: 'faPlaneDeparture', label: 'LOK Lodwar, Kenya, Lodwar, LOK, lodwar lodwar kenya', key:'A_LOK', cityBase: 127, city: 'LOK', country: 'KE', type: 'airport', nlabel: 'lodwar lodwar kenya'},
  { icon: 'faPlaneDeparture', label: 'LCJ Łódź, Poland, Łódź Władysław Reymont, LCJ, lodz wladyslaw reymont lodz poland', key:'A_LCJ', cityBase: 108, city: 'LCJ', country: 'PL', type: 'airport', nlabel: 'lodz wladyslaw reymont lodz poland'},
  { icon: 'faPlaneDeparture', label: 'LOE Loei Province, Thailand, Loei, LOE, loei loei province thailand', key:'A_LOE', cityBase: 127, city: 'LOE', country: 'TH', type: 'airport', nlabel: 'loei loei province thailand'},
  { icon: 'faPlaneDeparture', label: 'BOS Boston, United States, Logan International, BOS, logan international boston massachusetts united states', key:'A_BOS', cityBase: 0, city: 'BOS', country: 'US', type: 'airport', nlabel: 'logan international boston massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'RJL Logroño, Spain, Logroño–Agoncillo, RJL, logrono agoncillo logrono spain', key:'A_RJL', cityBase: 22, city: 'RJL', country: 'ES', type: 'airport', nlabel: 'logrono agoncillo logrono spain'},
  { icon: 'faPlaneDeparture', label: 'LIW Loikaw, Myanmar (Burma), Loikaw, LIW, loikaw loikaw myanmar (burma)', key:'A_LIW', cityBase: 127, city: 'LIW', country: 'MM', type: 'airport', nlabel: 'loikaw loikaw myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'GAU Guwahati, India, Lokpriya Gopinath Bordoloi International, GAU, lokpriya gopinath bordoloi international guwahati india', key:'A_GAU', cityBase: 97, city: 'GAU', country: 'IN', type: 'airport', nlabel: 'lokpriya gopinath bordoloi international guwahati india'},
  { icon: 'faPlaneDeparture', label: 'LOP Praya, Lombok, Indonesia, Lombok International, LOP, lombok international praya indonesia', key:'A_LOP', cityBase: 63, city: 'LOP', country: 'ID', type: 'airport', nlabel: 'lombok international praya indonesia'},
  { icon: 'faPlaneDeparture', label: 'LFW Lomé, Togo, Lomé–Tokoin International, LFW, lome tokoin international lome togo', key:'A_LFW', cityBase: 63, city: 'LFW', country: 'TG', type: 'airport', nlabel: 'lome tokoin international lome togo'},
  { icon: 'faPlaneDeparture', label: 'LDZ Londolozi Private Game Reserve, South Africa, Londolozi, LDZ, londolozi londolozi private game reserve south africa', key:'A_LDZ', cityBase: 127, city: 'LDZ', country: 'ZA', type: 'airport', nlabel: 'londolozi londolozi private game reserve south africa'},
  { icon: 'faPlaneDeparture', label: 'LCY London, United Kingdom, London City, LCY, london city london united kingdom', key:'A_LCY', cityBase: -991, city: 'LON', country: 'GB', type: 'airport', nlabel: 'london city london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'YXU London, Canada, London International, YXU, london international london ontario canada', key:'A_YXU', cityBase: 86, city: 'YXU', country: 'CA', type: 'airport', nlabel: 'london international london ontario canada'},
  { icon: 'faPlaneDeparture', label: 'OXF Oxford, United Kingdom, London Oxford Airport, OXF, london oxford airport oxford united kingdom', key:'A_OXF', cityBase: -873, city: 'OXF', country: 'GB', type: 'airport', nlabel: 'london oxford airport oxford united kingdom'},
  { icon: 'faPlaneDeparture', label: 'SEN London, United Kingdom, London Southend, SEN, london southend london united kingdom', key:'A_SEN', cityBase: -984, city: 'LON', country: 'GB', type: 'airport', nlabel: 'london southend london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'STN London, United Kingdom, London Stansted, STN, london stansted london united kingdom', key:'A_STN', cityBase: -994, city: 'LON', country: 'GB', type: 'airport', nlabel: 'london stansted london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LDB Londrina, Brazil, Londrina, LDB, londrina londrina state of parana brazil', key:'A_LDB', cityBase: 105, city: 'LDB', country: 'BR', type: 'airport', nlabel: 'londrina londrina state of parana brazil'},
  { icon: 'faPlaneDeparture', label: 'LKH Long Akah, Malaysia, Long Akah, LKH, long akah long akah malaysia', key:'A_LKH', cityBase: 127, city: 'LKH', country: 'MY', type: 'airport', nlabel: 'long akah long akah malaysia'},
  { icon: 'faPlaneDeparture', label: 'LBP Long Banga, Malaysia, Long Banga, LBP, long banga long banga malaysia', key:'A_LBP', cityBase: 127, city: 'LBP', country: 'MY', type: 'airport', nlabel: 'long banga long banga malaysia'},
  { icon: 'faPlaneDeparture', label: 'LGB Long Beach, United States, Long Beach, LGB, long beach long beach california united states', key:'A_LGB', cityBase: 14, city: 'LGB', country: 'US', type: 'airport', nlabel: 'long beach long beach california united states'},
  { icon: 'faPlaneDeparture', label: 'ISP Islip, United States, Long Island MacArthur, ISP, long island macarthur islip new york united states', key:'A_ISP', cityBase: 31, city: 'ISP', country: 'US', type: 'airport', nlabel: 'long island macarthur islip new york united states'},
  { icon: 'faPlaneDeparture', label: 'LGL Long Lellang, Malaysia, Long Lellang, LGL, long lellang long lellang malaysia', key:'A_LGL', cityBase: 127, city: 'LGL', country: 'MY', type: 'airport', nlabel: 'long lellang long lellang malaysia'},
  { icon: 'faPlaneDeparture', label: 'ODN Long Seridan, Malaysia, Long Seridan, ODN, long seridan long seridan malaysia', key:'A_ODN', cityBase: 127, city: 'ODN', country: 'MY', type: 'airport', nlabel: 'long seridan long seridan malaysia'},
  { icon: 'faPlaneDeparture', label: 'LOD Longana, Vanuatu, Longana, LOD, longana longana vanuatu', key:'A_LOD', cityBase: 127, city: 'LOD', country: 'VU', type: 'airport', nlabel: 'longana longana vanuatu'},
  { icon: 'faPlaneDeparture', label: 'LRE Longreach, Australia, Longreach, LRE, longreach longreach queensland australia', key:'A_LRE', cityBase: 127, city: 'LRE', country: 'AU', type: 'airport', nlabel: 'longreach longreach queensland australia'},
  { icon: 'faPlaneDeparture', label: 'LCX Longyan, China, Longyan Guanzhishan, LCX, longyan guanzhishan longyan china', key:'A_LCX', cityBase: 68, city: 'LCX', country: 'CN', type: 'airport', nlabel: 'longyan guanzhishan longyan china'},
  { icon: 'faPlaneDeparture', label: 'LNE Lonorore, Vanuatu, Lonorore, LNE, lonorore lonorore vanuatu', key:'A_LNE', cityBase: 127, city: 'LNE', country: 'VU', type: 'airport', nlabel: 'lonorore lonorore vanuatu'},
  { icon: 'faPlaneDeparture', label: 'LPS Lopez Island, United States, Lopez Island, LPS, lopez island lopez island washington united states', key:'A_LPS', cityBase: 127, city: 'LPS', country: 'US', type: 'airport', nlabel: 'lopez island lopez island washington united states'},
  { icon: 'faPlaneDeparture', label: 'LDH Lord Howe Island, Australia, Lord Howe Island, LDH, lord howe island lord howe island new south wales australia', key:'A_LDH', cityBase: 99, city: 'LDH', country: 'AU', type: 'airport', nlabel: 'lord howe island lord howe island new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'LTO Loreto, Mexico, Loreto International, LTO, loreto international loreto mexico', key:'A_LTO', cityBase: 79, city: 'LTO', country: 'MX', type: 'airport', nlabel: 'loreto international loreto mexico'},
  { icon: 'faPlaneDeparture', label: 'LRT Lorient, France, Lorient South Brittany, LRT, lorient south brittany lorient france', key:'A_LRT', cityBase: 127, city: 'LRT', country: 'FR', type: 'airport', nlabel: 'lorient south brittany lorient france'},
  { icon: 'faPlaneDeparture', label: 'LAX Los Angeles, United States, Los Angeles International, LAX, los angeles international los angeles california united states', key:'A_LAX', cityBase: 0, city: 'LAX', country: 'US', type: 'airport', nlabel: 'los angeles international los angeles california united states'},
  { icon: 'faPlaneDeparture', label: 'SJD San José del Cabo, Mexico, Los Cabos International, SJD, los cabos international san jose del cabo mexico', key:'A_SJD', cityBase: 16, city: 'SJD', country: 'MX', type: 'airport', nlabel: 'los cabos international san jose del cabo mexico'},
  { icon: 'faPlaneDeparture', label: 'RVE Saravena, Colombia, Los Colonizadores, RVE, los colonizadores saravena colombia', key:'A_RVE', cityBase: 127, city: 'RVE', country: 'CO', type: 'airport', nlabel: 'los colonizadores saravena colombia'},
  { icon: 'faPlaneDeparture', label: 'MTR Montería, Colombia, Los Garzones, MTR, los garzones monteria colombia', key:'A_MTR', cityBase: 86, city: 'MTR', country: 'CO', type: 'airport', nlabel: 'los garzones monteria colombia'},
  { icon: 'faPlaneDeparture', label: 'LMM Los Mochis, Mexico, Los Mochis International, LMM, los mochis international los mochis mexico', key:'A_LMM', cityBase: 102, city: 'LMM', country: 'MX', type: 'airport', nlabel: 'los mochis international los mochis mexico'},
  { icon: 'faPlaneDeparture', label: 'LSA Kiriwina, Papua New Guinea, Losuia, LSA, losuia kiriwina papua new guinea', key:'A_LSA', cityBase: 127, city: 'LSA', country: 'PG', type: 'airport', nlabel: 'losuia kiriwina papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'LXG Luang Namtha, Laos, Louang Namtha, LXG, louang namtha luang namtha laos', key:'A_LXG', cityBase: 127, city: 'LXG', country: 'LA', type: 'airport', nlabel: 'louang namtha luang namtha laos'},
  { icon: 'faPlaneDeparture', label: 'MSY New Orleans, United States, Louis Armstrong New Orleans International, MSY, louis armstrong new orleans international new orleans louisiana united states', key:'A_MSY', cityBase: 2, city: 'MSY', country: 'US', type: 'airport', nlabel: 'louis armstrong new orleans international new orleans louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'SDF Louisville, United States, Louisville International, SDF, louisville international louisville kentucky united states', key:'A_SDF', cityBase: 11, city: 'SDF', country: 'US', type: 'airport', nlabel: 'louisville international louisville kentucky united states'},
  { icon: 'faPlaneDeparture', label: 'YBX Blanc-Sablon, Canada, Lourdes-de-Blanc-Sablon, YBX, lourdes de blanc sablon blanc sablon quebec canada', key:'A_YBX', cityBase: 127, city: 'YBX', country: 'CA', type: 'airport', nlabel: 'lourdes de blanc sablon blanc sablon quebec canada'},
  { icon: 'faPlaneDeparture', label: 'LPQ Luang Prabang, Laos, Luang Prabang International, LPQ, luang prabang international luang prabang laos', key:'A_LPQ', cityBase: 54, city: 'LPQ', country: 'LA', type: 'airport', nlabel: 'luang prabang international luang prabang laos'},
  { icon: 'faPlaneDeparture', label: 'SDD Lubango, Angola, Lubango, SDD, lubango lubango angola', key:'A_SDD', cityBase: 127, city: 'SDD', country: 'AO', type: 'airport', nlabel: 'lubango lubango angola'},
  { icon: 'faPlaneDeparture', label: 'LBB Lubbock, United States, Lubbock Preston Smith International, LBB, lubbock preston smith international lubbock texas united states', key:'A_LBB', cityBase: 56, city: 'LBB', country: 'US', type: 'airport', nlabel: 'lubbock preston smith international lubbock texas united states'},
  { icon: 'faPlaneDeparture', label: 'LBC Lubeck, Germany, Lubeck Blankensee Airport, LBC, lubeck blankensee airport lubeck germany', key:'A_LBC', cityBase: 83, city: 'LBC', country: 'DE', type: 'airport', nlabel: 'lubeck blankensee airport lubeck germany'},
  { icon: 'faPlaneDeparture', label: 'LUZ Lublin, Poland, Lublin, LUZ, lublin lublin poland', key:'A_LUZ', cityBase: 84, city: 'LUZ', country: 'PL', type: 'airport', nlabel: 'lublin lublin poland'},
  { icon: 'faPlaneDeparture', label: 'FBM Lubumbashi, Democratic Republic of the Congo, Lubumbashi International, FBM, lubumbashi international lubumbashi congo kinshasa', key:'A_FBM', cityBase: 91, city: 'FBM', country: 'CD', type: 'airport', nlabel: 'lubumbashi international lubumbashi congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'LUD Lüderitz, Namibia, Lüderitz, LUD, luderitz luderitz namibia', key:'A_LUD', cityBase: 127, city: 'LUD', country: 'NA', type: 'airport', nlabel: 'luderitz luderitz namibia'},
  { icon: 'faPlaneDeparture', label: 'LUO Luena, Angola, Luena, LUO, luena luena angola', key:'A_LUO', cityBase: 127, city: 'LUO', country: 'AO', type: 'airport', nlabel: 'luena luena angola'},
  { icon: 'faPlaneDeparture', label: 'LUG Lugano, Switzerland, Lugano, LUG, lugano lugano switzerland 1', key:'A_LUG', cityBase: 12, city: 'LUG', country: 'CH', type: 'airport', nlabel: 'lugano lugano switzerland 1'},
  { icon: 'faPlaneDeparture', label: 'SJU San Juan, United States, Luis Muñoz Marín International, SJU, luis munoz marin international san juan puerto rico united states', key:'A_SJU', cityBase: 4, city: 'SJU', country: 'US', type: 'airport', nlabel: 'luis munoz marin international san juan puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'LLA Luleå, Sweden, Luleå, LLA, lulea lulea sweden', key:'A_LLA', cityBase: 83, city: 'LLA', country: 'SE', type: 'airport', nlabel: 'lulea lulea sweden'},
  { icon: 'faPlaneDeparture', label: 'LLV Lüliang, China, Lüliang, LLV, luliang luliang china', key:'A_LLV', cityBase: 75, city: 'LLV', country: 'CN', type: 'airport', nlabel: 'luliang luliang china'},
  { icon: 'faPlaneDeparture', label: 'CGY Cagayan de Oro, Philippines, Lumbia Air Base, CGY, lumbia air base cagayan de oro philippines', key:'A_CGY', cityBase: 57, city: 'CGY', country: 'PH', type: 'airport', nlabel: 'lumbia air base cagayan de oro philippines'},
  { icon: 'faPlaneDeparture', label: 'FNA Freetown, Sierra Leone, Lungi International, FNA, lungi international freetown sierra leone', key:'A_FNA', cityBase: 41, city: 'FNA', country: 'SL', type: 'airport', nlabel: 'lungi international freetown sierra leone'},
  { icon: 'faPlaneDeparture', label: 'LYA Luoyang, China, Luoyang Beijiao, LYA, luoyang beijiao luoyang china', key:'A_LYA', cityBase: 67, city: 'LYA', country: 'CN', type: 'airport', nlabel: 'luoyang beijiao luoyang china'},
  { icon: 'faPlaneDeparture', label: 'LTN London, United Kingdom, Luton, LTN, luton london united kingdom', key:'A_LTN', cityBase: -993, city: 'LON', country: 'GB', type: 'airport', nlabel: 'luton london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'YSG Lutselk\'e, Canada, Lutselk\'e, YSG, lutselk e lutselk e northwest territories canada', key:'A_YSG', cityBase: 127, city: 'YSG', country: 'CA', type: 'airport', nlabel: 'lutselk e lutselk e northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'LUX Luxembourg City, Luxembourg, Luxembourg, LUX, luxembourg luxembourg city luxembourg 1', key:'A_LUX', cityBase: 38, city: 'LUX', country: 'LU', type: 'airport', nlabel: 'luxembourg luxembourg city luxembourg 1'},
  { icon: 'faPlaneDeparture', label: 'LXR Luxor, Egypt, Luxor International, LXR, luxor international luxor egypt', key:'A_LXR', cityBase: 37, city: 'LXR', country: 'EG', type: 'airport', nlabel: 'luxor international luxor egypt'},
  { icon: 'faPlaneDeparture', label: 'LZO Luzhou, China, Luzhou Yunlong, LZO, luzhou yunlong luzhou china', key:'A_LZO', cityBase: 68, city: 'LZO', country: 'CN', type: 'airport', nlabel: 'luzhou yunlong luzhou china'},
  { icon: 'faPlaneDeparture', label: 'LWO Lviv, Ukraine, Lviv Danylo Halytskyi International, LWO, lviv danylo halytskyi international lviv ukraine', key:'A_LWO', cityBase: 36, city: 'LWO', country: 'UA', type: 'airport', nlabel: 'lviv danylo halytskyi international lviv ukraine'},
  { icon: 'faPlaneDeparture', label: 'LYC Lycksele, Sweden, Lycksele, LYC, lycksele lycksele sweden', key:'A_LYC', cityBase: 127, city: 'LYC', country: 'SE', type: 'airport', nlabel: 'lycksele lycksele sweden'},
  { icon: 'faPlaneDeparture', label: 'LYH Lynchburg, United States, Lynchburg Regional, LYH, lynchburg regional lynchburg virginia united states', key:'A_LYH', cityBase: 86, city: 'LYH', country: 'US', type: 'airport', nlabel: 'lynchburg regional lynchburg virginia united states'},
  { icon: 'faPlaneDeparture', label: 'NAS Nassau, Bahamas, Lynden Pindling International, NAS, lynden pindling international nassau bahamas', key:'A_NAS', cityBase: 18, city: 'NAS', country: 'BS', type: 'airport', nlabel: 'lynden pindling international nassau bahamas'},
  { icon: 'faPlaneDeparture', label: 'LYS Lyon, France, Lyon–Saint-Exupéry, LYS, lyon saint exupery lyon france', key:'A_LYS', cityBase: 26, city: 'LYS', country: 'FR', type: 'airport', nlabel: 'lyon saint exupery lyon france'},
  { icon: 'faPlaneDeparture', label: 'MST Maastricht, Netherlands, Maastricht Aachen, MST, maastricht aachen maastricht netherlands', key:'A_MST', cityBase: 94, city: 'MST', country: 'NL', type: 'airport', nlabel: 'maastricht aachen maastricht netherlands'},
  { icon: 'faPlaneDeparture', label: 'MCP Macapá, Brazil, Macapá International, MCP, macapa international macapa state of amapa brazil', key:'A_MCP', cityBase: 127, city: 'MCP', country: 'BR', type: 'airport', nlabel: 'macapa international macapa state of amapa brazil'},
  { icon: 'faPlaneDeparture', label: 'MFM Macau, China, Macau International, MFM, macau international macao macau', key:'A_MFM', cityBase: 25, city: 'MFM', country: 'CN', type: 'airport', nlabel: 'macau international macao macau'},
  { icon: 'faPlaneDeparture', label: 'MKY Mackay, Australia, Mackay, MKY, mackay mackay queensland australia', key:'A_MKY', cityBase: 81, city: 'MKY', country: 'AU', type: 'airport', nlabel: 'mackay mackay queensland australia'},
  { icon: 'faPlaneDeparture', label: 'CEB Cebu, Philippines, Mactan–Cebu International, CEB, mactan cebu international cebu philippines', key:'A_CEB', cityBase: 25, city: 'CEB', country: 'PH', type: 'airport', nlabel: 'mactan cebu international cebu philippines'},
  { icon: 'faPlaneDeparture', label: 'MAG Madang, Papua New Guinea, Madang, MAG, madang madang papua new guinea', key:'A_MAG', cityBase: 104, city: 'MAG', country: 'PG', type: 'airport', nlabel: 'madang madang papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'IXM Madurai, India, Madurai, IXM, madurai madurai india', key:'A_IXM', cityBase: 60, city: 'IXM', country: 'IN', type: 'airport', nlabel: 'madurai madurai india'},
  { icon: 'faPlaneDeparture', label: 'HGN Mae Hong Son Province, Thailand, Mae Hong Son, HGN, mae hong son mae hong son province thailand', key:'A_HGN', cityBase: 127, city: 'HGN', country: 'TH', type: 'airport', nlabel: 'mae hong son mae hong son province thailand'},
  { icon: 'faPlaneDeparture', label: 'MAQ Mae Sot District, Thailand, Mae Sot, MAQ, mae sot mae sot district thailand', key:'A_MAQ', cityBase: 127, city: 'MAQ', country: 'TH', type: 'airport', nlabel: 'mae sot mae sot district thailand'},
  { icon: 'faPlaneDeparture', label: 'MWF Maewo, Vanuatu, Maewo-Naone, MWF, maewo naone maewo vanuatu', key:'A_MWF', cityBase: 127, city: 'MWF', country: 'VU', type: 'airport', nlabel: 'maewo naone maewo vanuatu'},
  { icon: 'faPlaneDeparture', label: 'MFA Mafia Island, Tanzania, Mafia, MFA, mafia mafia island tanzania', key:'A_MFA', cityBase: 127, city: 'MFA', country: 'TZ', type: 'airport', nlabel: 'mafia mafia island tanzania'},
  { icon: 'faPlaneDeparture', label: 'GYG Magan, Russia, Magan, GYG, magan magan russia', key:'A_GYG', cityBase: 127, city: 'GYG', country: 'RU', type: 'airport', nlabel: 'magan magan russia'},
  { icon: 'faPlaneDeparture', label: 'TWF Twin Falls, United States, Magic Valley Regional, TWF, magic valley regional twin falls idaho united states', key:'A_TWF', cityBase: 127, city: 'TWF', country: 'US', type: 'airport', nlabel: 'magic valley regional twin falls idaho united states'},
  { icon: 'faPlaneDeparture', label: 'MQF Magnitogorsk, Russia, Magnitogorsk International, MQF, magnitogorsk international magnitogorsk russia', key:'A_MQF', cityBase: 50, city: 'MQF', country: 'RU', type: 'airport', nlabel: 'magnitogorsk international magnitogorsk russia'},
  { icon: 'faPlaneDeparture', label: 'MZG Magong, Taiwan, Magong, MZG, magong magong taiwan', key:'A_MZG', cityBase: 127, city: 'MZG', country: 'TW', type: 'airport', nlabel: 'magong magong taiwan'},
  { icon: 'faPlaneDeparture', label: 'UDR Udaipur, India, Maharana Pratap, UDR, maharana pratap udaipur india', key:'A_UDR', cityBase: 76, city: 'UDR', country: 'IN', type: 'airport', nlabel: 'maharana pratap udaipur india'},
  { icon: 'faPlaneDeparture', label: 'GOP Gorakhpur, India, Mahayogi Gorakhnath, GOP, mahayogi gorakhnath gorakhpur india', key:'A_GOP', cityBase: 121, city: 'GOP', country: 'IN', type: 'airport', nlabel: 'mahayogi gorakhnath gorakhpur india'},
  { icon: 'faPlaneDeparture', label: 'MBD Mmabatho, South Africa, Mahikeng, MBD, mahikeng mmabatho south africa', key:'A_MBD', cityBase: 127, city: 'MBD', country: 'ZA', type: 'airport', nlabel: 'mahikeng mmabatho south africa'},
  { icon: 'faPlaneDeparture', label: 'MIU Maiduguri, Nigeria, Maiduguri International Airport, MIU, maiduguri international airport maiduguri nigeria', key:'A_MIU', cityBase: 127, city: 'null', country: 'NG', type: 'airport', nlabel: 'maiduguri international airport maiduguri nigeria'},
  { icon: 'faPlaneDeparture', label: 'SBG Sabang, Indonesia, Maimun Saleh, SBG, maimun saleh sabang indonesia', key:'A_SBG', cityBase: 123, city: 'SBG', country: 'ID', type: 'airport', nlabel: 'maimun saleh sabang indonesia'},
  { icon: 'faPlaneDeparture', label: 'MMO Maio, Cape Verde, Maio, MMO, maio maio cape verde', key:'A_MMO', cityBase: 127, city: 'MMO', country: 'CV', type: 'airport', nlabel: 'maio maio cape verde'},
  { icon: 'faPlaneDeparture', label: 'VAG Varginha, Brazil, Major Brigadeiro Trompowsky, VAG, major brigadeiro trompowsky varginha state of minas gerais brazil', key:'A_VAG', cityBase: 127, city: 'VAG', country: 'BR', type: 'airport', nlabel: 'major brigadeiro trompowsky varginha state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'MKP Makemo, French Polynesia, Makemo, MKP, makemo makemo french polynesia', key:'A_MKP', cityBase: 127, city: 'MKP', country: 'PF', type: 'airport', nlabel: 'makemo makemo french polynesia'},
  { icon: 'faPlaneDeparture', label: 'YMN Makkovik, Canada, Makkovik, YMN, makkovik makkovik newfoundland and labrador canada', key:'A_YMN', cityBase: 127, city: 'YMN', country: 'CA', type: 'airport', nlabel: 'makkovik makkovik newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'SSG Malabo, Equatorial Guinea, Malabo International, SSG, malabo international malabo equatorial guinea', key:'A_SSG', cityBase: 90, city: 'SSG', country: 'GQ', type: 'airport', nlabel: 'malabo international malabo equatorial guinea'},
  { icon: 'faPlaneDeparture', label: 'MKZ Malacca City, Malaysia, Malacca International, MKZ, malacca international malacca city malaysia', key:'A_MKZ', cityBase: 127, city: 'MKZ', country: 'MY', type: 'airport', nlabel: 'malacca international malacca city malaysia'},
  { icon: 'faPlaneDeparture', label: 'AGP Málaga, Spain, Málaga, AGP, malaga malaga spain', key:'A_AGP', cityBase: 5, city: 'AGP', country: 'ES', type: 'airport', nlabel: 'malaga malaga spain'},
  { icon: 'faPlaneDeparture', label: 'MLX Malatya, Turkey, Malatya Erhaç, MLX, malatya erhac malatya turkey', key:'A_MLX', cityBase: 18, city: 'MLX', country: 'TR', type: 'airport', nlabel: 'malatya erhac malatya turkey'},
  { icon: 'faPlaneDeparture', label: 'LPM Lamap, Vanuatu, Malekoula, LPM, malekoula lamap vanuatu', key:'A_LPM', cityBase: 127, city: 'LPM', country: 'VU', type: 'airport', nlabel: 'malekoula lamap vanuatu'},
  { icon: 'faPlaneDeparture', label: 'LSW Lhokseumawe, Indonesia, Malikus Saleh, LSW, malikus saleh lhokseumawe indonesia', key:'A_LSW', cityBase: 127, city: 'LSW', country: 'ID', type: 'airport', nlabel: 'malikus saleh lhokseumawe indonesia'},
  { icon: 'faPlaneDeparture', label: 'MYD Malindi, Kenya, Malindi, MYD, malindi malindi kenya', key:'A_MYD', cityBase: 127, city: 'MYD', country: 'KE', type: 'airport', nlabel: 'malindi malindi kenya'},
  { icon: 'faPlaneDeparture', label: 'KAN Kano, Nigeria, Mallam Aminu Kano International, KAN, mallam aminu kano international kano nigeria', key:'A_KAN', cityBase: 108, city: 'KAN', country: 'NG', type: 'airport', nlabel: 'mallam aminu kano international kano nigeria'},
  { icon: 'faPlaneDeparture', label: 'MMX Malmö, Sweden, Malmö, MMX, malmo malmo sweden', key:'A_MMX', cityBase: 41, city: 'MMA', country: 'SE', type: 'airport', nlabel: 'malmo malmo sweden'},
  { icon: 'faPlaneDeparture', label: 'MLA Malta, Malta, Malta International, MLA, malta international valleta malta', key:'A_MLA', cityBase: 19, city: 'MLA', country: 'MT', type: 'airport', nlabel: 'malta international valleta malta'},
  { icon: 'faPlaneDeparture', label: 'MMH Mammoth Lakes, United States, Mammoth Yosemite, MMH, mammoth yosemite mammoth lakes california united states', key:'A_MMH', cityBase: 76, city: 'MMH', country: 'US', type: 'airport', nlabel: 'mammoth yosemite mammoth lakes california united states'},
  { icon: 'faPlaneDeparture', label: 'MJC Man, Côte d’Ivoire, Man, MJC, man man cote d ivoire', key:'A_MJC', cityBase: 127, city: 'MJC', country: 'CI', type: 'airport', nlabel: 'man man cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'FRU Bishkek, Kyrgyzstan, Manas International, FRU, manas international bishkek kyrgyzstan', key:'A_FRU', cityBase: 42, city: 'FRU', country: 'KG', type: 'airport', nlabel: 'manas international bishkek kyrgyzstan'},
  { icon: 'faPlaneDeparture', label: 'MAN Manchester, United Kingdom, Manchester, MAN, manchester manchester united kingdom', key:'A_MAN', cityBase: -997, city: 'MAN', country: 'GB', type: 'airport', nlabel: 'manchester manchester united kingdom'},
  { icon: 'faPlaneDeparture', label: 'MHT Manchester, United States, Manchester–Boston Regional, MHT, manchester boston regional manchester new hampshire united states', key:'A_MHT', cityBase: 21, city: 'MHT', country: 'US', type: 'airport', nlabel: 'manchester boston regional manchester new hampshire united states'},
  { icon: 'faPlaneDeparture', label: 'LAU Lamu, Kenya, Manda, LAU, manda lamu kenya', key:'A_LAU', cityBase: 127, city: 'LAU', country: 'KE', type: 'airport', nlabel: 'manda lamu kenya'},
  { icon: 'faPlaneDeparture', label: 'MDL Mandalay, Myanmar (Burma), Mandalay International, MDL, mandalay international mandalay myanmar (burma)', key:'A_MDL', cityBase: 64, city: 'MDL', country: 'MM', type: 'airport', nlabel: 'mandalay international mandalay myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'MGS Mangaia Island, Cook Islands, Mangaia Island Airport, MGS, mangaia island airport mangaia island cook islands', key:'A_MGS', cityBase: 127, city: 'MGS', country: 'CK', type: 'airport', nlabel: 'mangaia island airport mangaia island cook islands'},
  { icon: 'faPlaneDeparture', label: 'IXE Mangalore, India, Mangaluru, IXE, mangaluru mangalore india', key:'A_IXE', cityBase: 59, city: 'IXE', country: 'IN', type: 'airport', nlabel: 'mangaluru mangalore india'},
  { icon: 'faPlaneDeparture', label: 'MHK Manhattan, United States, Manhattan Regional, MHK, manhattan regional manhattan kansas united states', key:'A_MHK', cityBase: 72, city: 'MHK', country: 'US', type: 'airport', nlabel: 'manhattan regional manhattan kansas united states'},
  { icon: 'faPlaneDeparture', label: 'XMH Manihi, French Polynesia, Manihi, XMH, manihi manihi french polynesia', key:'A_XMH', cityBase: 127, city: 'XMH', country: 'PF', type: 'airport', nlabel: 'manihi manihi french polynesia'},
  { icon: 'faPlaneDeparture', label: 'MHX Manihiki Island, Cook Islands, Manihiki Island Airport, MHX, manihiki island airport manihiki island cook islands', key:'A_MHX', cityBase: 127, city: 'null', country: 'CK', type: 'airport', nlabel: 'manihiki island airport manihiki island cook islands'},
  { icon: 'faPlaneDeparture', label: 'JSU Maniitsoq, Greenland, Maniitsoq, JSU, maniitsoq maniitsoq greenland', key:'A_JSU', cityBase: 127, city: 'JSU', country: 'GL', type: 'airport', nlabel: 'maniitsoq maniitsoq greenland'},
  { icon: 'faPlaneDeparture', label: 'MNG Maningrida, Australia, Maningrida, MNG, maningrida maningrida northern territory australia', key:'A_MNG', cityBase: 127, city: 'MNG', country: 'AU', type: 'airport', nlabel: 'maningrida maningrida northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'MBL Manistee, United States, Manistee County Blacker, MBL, manistee county blacker manistee michigan united states', key:'A_MBL', cityBase: 127, city: 'MBL', country: 'US', type: 'airport', nlabel: 'manistee county blacker manistee michigan united states'},
  { icon: 'faPlaneDeparture', label: 'MLY Manley Hot Springs, United States, Manley Hot Springs, MLY, manley hot springs manley hot springs alaska united states', key:'A_MLY', cityBase: 127, city: 'MLY', country: 'US', type: 'airport', nlabel: 'manley hot springs manley hot springs alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AJY Agadez, Niger, Mano Dayak International, AJY, mano dayak international agadez niger', key:'A_AJY', cityBase: 127, city: 'AJY', country: 'NE', type: 'airport', nlabel: 'mano dayak international agadez niger'},
  { icon: 'faPlaneDeparture', label: 'PZO Puerto Ordaz and San Felix, Venezuela, Manuel Carlos Piar Guayana, PZO, manuel carlos piar guayana puerto ordaz and san felix venezuela', key:'A_PZO', cityBase: 62, city: 'PZO', country: 'VE', type: 'airport', nlabel: 'manuel carlos piar guayana puerto ordaz and san felix venezuela'},
  { icon: 'faPlaneDeparture', label: 'NZH Manzhouli, China, Manzhouli Xijiao, NZH, manzhouli xijiao manzhouli china', key:'A_NZH', cityBase: 73, city: 'NZH', country: 'CN', type: 'airport', nlabel: 'manzhouli xijiao manzhouli china'},
  { icon: 'faPlaneDeparture', label: 'MPM Maputo, Mozambique, Maputo International, MPM, maputo international maputo mozambique', key:'A_MPM', cityBase: 59, city: 'MPM', country: 'MZ', type: 'airport', nlabel: 'maputo international maputo mozambique'},
  { icon: 'faPlaneDeparture', label: 'MRE Maasai Mara, Kenya, Mara Serena, MRE, mara serena maasai mara kenya', key:'A_MRE', cityBase: 127, city: 'MRE', country: 'KE', type: 'airport', nlabel: 'mara serena maasai mara kenya'},
  { icon: 'faPlaneDeparture', label: 'MAB Marabá, Pará, Brazil, Marabá, MAB, maraba maraba state of para brazil', key:'A_MAB', cityBase: 127, city: 'MAB', country: 'BR', type: 'airport', nlabel: 'maraba maraba state of para brazil'},
  { icon: 'faPlaneDeparture', label: 'AVW Tucson, United States, Marana Regional Airport, AVW, marana regional airport tucson arizona united states', key:'A_AVW', cityBase: 127, city: 'TUS', country: 'US', type: 'airport', nlabel: 'marana regional airport tucson arizona united states'},
  { icon: 'faPlaneDeparture', label: 'RUS Marau Sound, Solomon Islands, Marau, RUS, marau marau sound solomon islands', key:'A_RUS', cityBase: 127, city: 'RUS', country: 'SB', type: 'airport', nlabel: 'marau marau sound solomon islands'},
  { icon: 'faPlaneDeparture', label: 'AOI Ancona, Italy, Marche Airport, AOI, ancona falconara ancona italy', key:'A_AOI', cityBase: 14, city: 'AOI', country: 'IT', type: 'airport', nlabel: 'ancona falconara ancona italy'},
  { icon: 'faPlaneDeparture', label: 'MQM Mardin, Turkey, Mardin, MQM, mardin mardin turkey', key:'A_MQM', cityBase: 20, city: 'MQM', country: 'TR', type: 'airport', nlabel: 'mardin mardin turkey'},
  { icon: 'faPlaneDeparture', label: 'MEE Mare, New Caledonia, Mare Airport, MEE, mare airport mare new caledonia', key:'A_MEE', cityBase: 127, city: 'MEE', country: 'NC', type: 'airport', nlabel: 'mare airport mare new caledonia'},
  { icon: 'faPlaneDeparture', label: 'SLZ São Luís, Brazil, Marechal Cunha Machado International, SLZ, marechal cunha machado international sao luis state of maranhao brazil', key:'A_SLZ', cityBase: 80, city: 'SLZ', country: 'BR', type: 'airport', nlabel: 'marechal cunha machado international sao luis state of maranhao brazil'},
  { icon: 'faPlaneDeparture', label: 'CGB Cuiabá, Brazil, Marechal Rondon International, CGB, marechal rondon international cuiaba state of mato grosso brazil', key:'A_CGB', cityBase: 93, city: 'CGB', country: 'BR', type: 'airport', nlabel: 'marechal rondon international cuiaba state of mato grosso brazil'},
  { icon: 'faPlaneDeparture', label: 'CBQ Calabar, Nigeria, Margaret Ekpo International, CBQ, margaret ekpo international calabar nigeria', key:'A_CBQ', cityBase: 118, city: 'CBQ', country: 'NG', type: 'airport', nlabel: 'margaret ekpo international calabar nigeria'},
  { icon: 'faPlaneDeparture', label: 'MGH Margate, KwaZulu-Natal, South Africa, Margate, MGH, margate margate south africa', key:'A_MGH', cityBase: 127, city: 'MGH', country: 'ZA', type: 'airport', nlabel: 'margate margate south africa'},
  { icon: 'faPlaneDeparture', label: 'GAO Guantánamo, Cuba, Mariana Grajales, GAO, mariana grajales guantanamo cuba', key:'A_GAO', cityBase: 127, city: 'GAO', country: 'CU', type: 'airport', nlabel: 'mariana grajales guantanamo cuba'},
  { icon: 'faPlaneDeparture', label: 'MBX Maribor, Slovenia, Maribor Edvard Rusjan, MBX, maribor edvard rusjan maribor slovenia', key:'A_MBX', cityBase: 103, city: 'MBX', country: 'SI', type: 'airport', nlabel: 'maribor edvard rusjan maribor slovenia'},
  { icon: 'faPlaneDeparture', label: 'MHQ Mariehamn, Åland Islands, Mariehamn, MHQ, mariehamn mariehamn aland islands', key:'A_MHQ', cityBase: 127, city: 'MHQ', country: 'AX', type: 'airport', nlabel: 'mariehamn mariehamn aland islands'},
  { icon: 'faPlaneDeparture', label: 'MII Marília, Brazil, Marília, MII, marilia marilia state of sao paulo brazil', key:'A_MII', cityBase: 127, city: 'MII', country: 'BR', type: 'airport', nlabel: 'marilia marilia state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'EBA Campo nell Elba, Italy, Marina di Campo, EBA, marina di campo campo nell elba italy', key:'A_EBA', cityBase: 127, city: 'EBA', country: 'IT', type: 'airport', nlabel: 'marina di campo campo nell elba italy'},
  { icon: 'faPlaneDeparture', label: 'MGF Maringá, Brazil, Maringá Regional, MGF, maringa regional maringa state of parana brazil', key:'A_MGF', cityBase: 127, city: 'MGF', country: 'BR', type: 'airport', nlabel: 'maringa regional maringa state of parana brazil'},
  { icon: 'faPlaneDeparture', label: 'CUE Cuenca, Ecuador, Mariscal Lamar International, CUE, mariscal lamar international cuenca ecuador', key:'A_CUE', cityBase: 49, city: 'CUE', country: 'EC', type: 'airport', nlabel: 'mariscal lamar international cuenca ecuador'},
  { icon: 'faPlaneDeparture', label: 'UIO Quito, Ecuador, Mariscal Sucre International, UIO, mariscal sucre international quito ecuador', key:'A_UIO', cityBase: 17, city: 'UIO', country: 'EC', type: 'airport', nlabel: 'mariscal sucre international quito ecuador'},
  { icon: 'faPlaneDeparture', label: 'WMN Maroantsetra, Madagascar, Maroantsetra, WMN, maroantsetra maroantsetra madagascar', key:'A_WMN', cityBase: 127, city: 'WMN', country: 'MG', type: 'airport', nlabel: 'maroantsetra maroantsetra madagascar'},
  { icon: 'faPlaneDeparture', label: 'RAK Marrakesh, Morocco, Marrakesh Menara, RAK, marrakesh menara marrakesh morocco', key:'A_RAK', cityBase: 14, city: 'RAK', country: 'MA', type: 'airport', nlabel: 'marrakesh menara marrakesh morocco'},
  { icon: 'faPlaneDeparture', label: 'RMF Marsa Alam, Egypt, Marsa Alam International, RMF, marsa alam international marsa alam egypt', key:'A_RMF', cityBase: 13, city: 'RMF', country: 'EG', type: 'airport', nlabel: 'marsa alam international marsa alam egypt'},
  { icon: 'faPlaneDeparture', label: 'MRS Marseille, France, Marseille Provence, MRS, marseille provence marseille france', key:'A_MRS', cityBase: 10, city: 'MRS', country: 'FR', type: 'airport', nlabel: 'marseille provence marseille france'},
  { icon: 'faPlaneDeparture', label: 'MHH Marsh Harbour, Bahamas, Marsh Harbour, MHH, marsh harbour marsh harbour bahamas', key:'A_MHH', cityBase: 63, city: 'MHH', country: 'BS', type: 'airport', nlabel: 'marsh harbour marsh harbour bahamas'},
  { icon: 'faPlaneDeparture', label: 'MLL Marshall, United States, Marshall Don Hunter Sr., MLL, marshall don hunter sr marshall alaska united states', key:'A_MLL', cityBase: 127, city: 'MLL', country: 'US', type: 'airport', nlabel: 'marshall don hunter sr marshall alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MAJ Majuro, Marshall Islands, Marshall Islands International, MAJ, marshall islands international majuro marshall islands', key:'A_MAJ', cityBase: 80, city: 'MAJ', country: 'MH', type: 'airport', nlabel: 'marshall islands international majuro marshall islands'},
  { icon: 'faPlaneDeparture', label: 'MVY Martha\'s Vineyard, United States, Martha\'s Vineyard, MVY, martha s vineyard martha s vineyard massachusetts united states', key:'A_MVY', cityBase: 33, city: 'MVY', country: 'US', type: 'airport', nlabel: 'martha s vineyard martha s vineyard massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'SLA Salta, Argentina, Martín Miguel de Güemes International, SLA, martin miguel de guemes international salta salta argentina', key:'A_SLA', cityBase: 57, city: 'SLA', country: 'AR', type: 'airport', nlabel: 'martin miguel de guemes international salta salta argentina'},
  { icon: 'faPlaneDeparture', label: 'FDF Fort-de-France, France, Martinique Aimé Césaire International, FDF, martinique aime cesaire international fort de france martinique france', key:'A_FDF', cityBase: 64, city: 'FDF', country: 'FR', type: 'airport', nlabel: 'martinique aime cesaire international fort de france martinique france'},
  { icon: 'faPlaneDeparture', label: 'MUR Marudi, Malaysia, Marudi, MUR, marudi marudi malaysia', key:'A_MUR', cityBase: 127, city: 'MUR', country: 'MY', type: 'airport', nlabel: 'marudi marudi malaysia'},
  { icon: 'faPlaneDeparture', label: 'MYP Mary, Turkmenistan, Mary International, MYP, mary international mary turkmenistan', key:'A_MYP', cityBase: 127, city: 'MYP', country: 'TM', type: 'airport', nlabel: 'mary international mary turkmenistan'},
  { icon: 'faPlaneDeparture', label: 'MHD Mashhad, Iran, Mashhad International, MHD, mashhad international mashhad iran', key:'A_MHD', cityBase: 55, city: 'MHD', country: 'IR', type: 'airport', nlabel: 'mashhad international mashhad iran'},
  { icon: 'faPlaneDeparture', label: 'MCW Mason City, United States, Mason City Municipal, MCW, mason city municipal mason city iowa united states', key:'A_MCW', cityBase: 93, city: 'MCW', country: 'US', type: 'airport', nlabel: 'mason city municipal mason city iowa united states'},
  { icon: 'faPlaneDeparture', label: 'MSS Massena, United States, Massena International, MSS, massena international massena new york united states', key:'A_MSS', cityBase: 127, city: 'MSS', country: 'US', type: 'airport', nlabel: 'massena international massena new york united states'},
  { icon: 'faPlaneDeparture', label: 'ZMT Masset, Canada, Masset, ZMT, masset masset british columbia canada', key:'A_ZMT', cityBase: 127, city: 'ZMT', country: 'CA', type: 'airport', nlabel: 'masset masset british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'MVT Mataiva, French Polynesia, Mataiva, MVT, mataiva mataiva french polynesia', key:'A_MVT', cityBase: 127, city: 'MVT', country: 'PF', type: 'airport', nlabel: 'mataiva mataiva french polynesia'},
  { icon: 'faPlaneDeparture', label: 'MWK Matak, Indonesia, Matak Tarempa, MWK, matak tarempa matak indonesia', key:'A_MWK', cityBase: 127, city: 'MWK', country: 'ID', type: 'airport', nlabel: 'matak tarempa matak indonesia'},
  { icon: 'faPlaneDeparture', label: 'MAM Matamoros, Mexico, Matamoros International, MAM, matamoros international matamoros mexico', key:'A_MAM', cityBase: 97, city: 'MAM', country: 'MX', type: 'airport', nlabel: 'matamoros international matamoros mexico'},
  { icon: 'faPlaneDeparture', label: 'IRP Isiro, Democratic Republic of the Congo, Matari, IRP, matari isiro democratic republic of the congo', key:'A_IRP', cityBase: 127, city: 'IRP', country: 'CD', type: 'airport', nlabel: 'matari isiro democratic republic of the congo'},
  { icon: 'faPlaneDeparture', label: 'IPC Easter Island, Chile, Mataveri International, IPC, mataveri international easter island chile', key:'A_IPC', cityBase: 43, city: 'IPC', country: 'CL', type: 'airport', nlabel: 'mataveri international easter island chile'},
  { icon: 'faPlaneDeparture', label: 'PEI Pereira, Colombia, Matecaña International, PEI, matecana international pereira colombia', key:'A_PEI', cityBase: 45, city: 'PEI', country: 'CO', type: 'airport', nlabel: 'matecana international pereira colombia'},
  { icon: 'faPlaneDeparture', label: 'TVU Taveuni, Fiji, Matei, TVU, matei taveuni fiji', key:'A_TVU', cityBase: 109, city: 'TVU', country: 'FJ', type: 'airport', nlabel: 'matei taveuni fiji'},
  { icon: 'faPlaneDeparture', label: 'MFK Matsu Islands, Taiwan, Matsu Beigan, MFK, matsu beigan matsu islands taiwan', key:'A_MFK', cityBase: 127, city: 'MFK', country: 'TW', type: 'airport', nlabel: 'matsu beigan matsu islands taiwan'},
  { icon: 'faPlaneDeparture', label: 'LZN Nangan, Lienchiang, Taiwan, Matsu Nangan, LZN, matsu nangan nangan taiwan', key:'A_LZN', cityBase: 127, city: 'LZN', country: 'TW', type: 'airport', nlabel: 'matsu nangan nangan taiwan'},
  { icon: 'faPlaneDeparture', label: 'MMJ Matsumoto, Japan, Matsumoto, MMJ, matsumoto matsumoto japan', key:'A_MMJ', cityBase: 127, city: 'MMJ', country: 'JP', type: 'airport', nlabel: 'matsumoto matsumoto japan'},
  { icon: 'faPlaneDeparture', label: 'MYJ Matsuyama, Japan, Matsuyama, MYJ, matsuyama matsuyama japan', key:'A_MYJ', cityBase: 86, city: 'MYJ', country: 'JP', type: 'airport', nlabel: 'matsuyama matsuyama japan'},
  { icon: 'faPlaneDeparture', label: 'HRI Hambantota, Sri Lanka, Mattala Rajapaksa International, HRI, mattala rajapaksa international hambantota sri lanka', key:'A_HRI', cityBase: 127, city: 'HRI', country: 'LK', type: 'airport', nlabel: 'mattala rajapaksa international hambantota sri lanka'},
  { icon: 'faPlaneDeparture', label: 'MUK Mauke Island, Cook Islands, Mauke Airport, MUK, mauke airport mauke island cook islands', key:'A_MUK', cityBase: 127, city: 'MUK', country: 'CK', type: 'airport', nlabel: 'mauke airport mauke island cook islands'},
  { icon: 'faPlaneDeparture', label: 'MUB Maun, Botswana, Maun, MUB, maun maun botswana', key:'A_MUB', cityBase: 85, city: 'MUB', country: 'BW', type: 'airport', nlabel: 'maun maun botswana'},
  { icon: 'faPlaneDeparture', label: 'MAU Maupiti, French Polynesia, Maupiti, MAU, maupiti maupiti french polynesia', key:'A_MAU', cityBase: 127, city: 'MAU', country: 'PF', type: 'airport', nlabel: 'maupiti maupiti french polynesia'},
  { icon: 'faPlaneDeparture', label: 'GND St George\'s, Grenada, Maurice Bishop International, GND, maurice bishop international st george s grenada', key:'A_GND', cityBase: 38, city: 'GND', country: 'GD', type: 'airport', nlabel: 'maurice bishop international st george s grenada'},
  { icon: 'faPlaneDeparture', label: 'DIW Dickwella, Sri Lanka, Mawella Lagoon, DIW, mawella lagoon dickwella sri lanka', key:'A_DIW', cityBase: 127, city: 'DIW', country: 'LK', type: 'airport', nlabel: 'mawella lagoon dickwella sri lanka'},
  { icon: 'faPlaneDeparture', label: 'MNU Mawlamyine, Myanmar (Burma), Mawlamyine, MNU, mawlamyine mawlamyine myanmar (burma)', key:'A_MNU', cityBase: 127, city: 'MNU', country: 'MM', type: 'airport', nlabel: 'mawlamyine mawlamyine myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'BZV Brazzaville, Republic of the Congo, Maya-Maya, BZV, maya maya brazzaville congo brazzaville', key:'A_BZV', cityBase: 96, city: 'BZV', country: 'CG', type: 'airport', nlabel: 'maya maya brazzaville congo brazzaville'},
  { icon: 'faPlaneDeparture', label: 'MYG Mayaguana, Bahamas, Mayaguana, MYG, mayaguana mayaguana bahamas', key:'A_MYG', cityBase: 127, city: 'MYG', country: 'BS', type: 'airport', nlabel: 'mayaguana mayaguana bahamas'},
  { icon: 'faPlaneDeparture', label: 'STD Santo Domingo, Venezuela, Mayor Buenaventura Vivas, STD, mayor buenaventura vivas santo domingo venezuela', key:'A_STD', cityBase: 83, city: 'STD', country: 'VE', type: 'airport', nlabel: 'mayor buenaventura vivas santo domingo venezuela'},
  { icon: 'faPlaneDeparture', label: 'CJA Cajamarca, Peru, Mayor General FAP Armando Revoredo Iglesias, CJA, mayor general fap armando revoredo iglesias cajamarca peru', key:'A_CJA', cityBase: 107, city: 'CJA', country: 'PE', type: 'airport', nlabel: 'mayor general fap armando revoredo iglesias cajamarca peru'},
  { icon: 'faPlaneDeparture', label: 'MZR Mazar-i-Sharif, Afghanistan, Mazar-i-Sharif International, MZR, mazar i sharif international mazar i sharif afghanistan', key:'A_MZR', cityBase: 106, city: 'MZR', country: 'AF', type: 'airport', nlabel: 'mazar i sharif international mazar i sharif afghanistan'},
  { icon: 'faPlaneDeparture', label: 'MZT Mazatlán, Mexico, Mazatlán International, MZT, mazatlan international mazatlan mexico', key:'A_MZT', cityBase: 37, city: 'MZT', country: 'MX', type: 'airport', nlabel: 'mazatlan international mazatlan mexico'},
  { icon: 'faPlaneDeparture', label: 'MDK Mbandaka, Democratic Republic of the Congo, Mbandaka, MDK, mbandaka mbandaka congo kinshasa', key:'A_MDK', cityBase: 127, city: 'MDK', country: 'CD', type: 'airport', nlabel: 'mbandaka mbandaka congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'MBI Mbeya, Tanzania, Mbeya, MBI, mbeya mbeya tanzania', key:'A_MBI', cityBase: 127, city: 'MBI', country: 'TZ', type: 'airport', nlabel: 'mbeya mbeya tanzania'},
  { icon: 'faPlaneDeparture', label: 'MBS Saginaw, United States, MBS International, MBS, mbs international saginaw michigan united states', key:'A_MBS', cityBase: 88, city: 'MBS', country: 'US', type: 'airport', nlabel: 'mbs international saginaw michigan united states'},
  { icon: 'faPlaneDeparture', label: 'MJM Mbuji-Mayi, Democratic Republic of the Congo, Mbuji Mayi, MJM, mbuji mayi mbuji mayi democratic republic of the congo', key:'A_MJM', cityBase: 127, city: 'MJM', country: 'CD', type: 'airport', nlabel: 'mbuji mayi mbuji mayi democratic republic of the congo'},
  { icon: 'faPlaneDeparture', label: 'MFE McAllen, United States, McAllen Miller International, MFE, mcallen miller international mcallen texas united states', key:'A_MFE', cityBase: 36, city: 'MFE', country: 'US', type: 'airport', nlabel: 'mcallen miller international mcallen texas united states'},
  { icon: 'faPlaneDeparture', label: 'MCV McArthur River zinc mine, Australia, McArthur River Mine, MCV, mcarthur river mine mcarthur river zinc mine northern territory australia', key:'A_MCV', cityBase: 127, city: 'MCV', country: 'AU', type: 'airport', nlabel: 'mcarthur river mine mcarthur river zinc mine northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'LAS Las Vegas, United States, McCarran International, LAS, mccarran international las vegas nevada united states', key:'A_LAS', cityBase: 0, city: 'LAS', country: 'US', type: 'airport', nlabel: 'mccarran international las vegas nevada united states'},
  { icon: 'faPlaneDeparture', label: 'CLD San Diego, United States, McClellan–Palomar, CLD, mcclellan palomar san diego california united states', key:'A_CLD', cityBase: 106, city: 'SAN', country: 'US', type: 'airport', nlabel: 'mcclellan palomar san diego california united states'},
  { icon: 'faPlaneDeparture', label: 'MCK McCook, United States, McCook Ben Nelson Regional, MCK, mccook ben nelson regional mccook nebraska united states', key:'A_MCK', cityBase: 127, city: 'MCK', country: 'US', type: 'airport', nlabel: 'mccook ben nelson regional mccook nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'TYS Knoxville, United States, McGhee Tyson, TYS, mcghee tyson knoxville tennessee united states', key:'A_TYS', cityBase: 16, city: 'TYS', country: 'US', type: 'airport', nlabel: 'mcghee tyson knoxville tennessee united states'},
  { icon: 'faPlaneDeparture', label: 'MCG McGrath, United States, McGrath, MCG, mcgrath mcgrath alaska united states', key:'A_MCG', cityBase: 127, city: 'MCG', country: 'US', type: 'airport', nlabel: 'mcgrath mcgrath alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MKL Jackson, United States, McKellar–Sipes Regional, MKL, mckellar sipes regional jackson tennessee united states', key:'A_MKL', cityBase: 109, city: 'MKL', country: 'US', type: 'airport', nlabel: 'mckellar sipes regional jackson tennessee united states'},
  { icon: 'faPlaneDeparture', label: 'BFL Bakersfield, United States, Meadows Field, BFL, meadows field bakersfield california united states', key:'A_BFL', cityBase: 76, city: 'BFL', country: 'US', type: 'airport', nlabel: 'meadows field bakersfield california united states'},
  { icon: 'faPlaneDeparture', label: 'YXH Medicine Hat, Canada, Medicine Hat, YXH, medicine hat medicine hat alberta canada', key:'A_YXH', cityBase: 127, city: 'YXH', country: 'CA', type: 'airport', nlabel: 'medicine hat medicine hat alberta canada'},
  { icon: 'faPlaneDeparture', label: 'MEH Mehamn, Norway, Mehamn, MEH, mehamn mehamn norway', key:'A_MEH', cityBase: 108, city: 'MEH', country: 'NO', type: 'airport', nlabel: 'mehamn mehamn norway'},
  { icon: 'faPlaneDeparture', label: 'THR Tehran, Iran, Mehrabad International, THR, mehrabad international tehran iran', key:'A_THR', cityBase: 59, city: 'THR', country: 'IR', type: 'airport', nlabel: 'mehrabad international tehran iran'},
  { icon: 'faPlaneDeparture', label: 'MXZ Meizhou, China, Meixian, MXZ, meixian meizhou china', key:'A_MXZ', cityBase: 68, city: 'MXZ', country: 'CN', type: 'airport', nlabel: 'meixian meizhou china'},
  { icon: 'faPlaneDeparture', label: 'MSU Maseru, Lesotho, Mejametalana, MSU, mejametalana maseru lesotho', key:'A_MSU', cityBase: 111, city: 'MSU', country: 'LS', type: 'airport', nlabel: 'mejametalana maseru lesotho'},
  { icon: 'faPlaneDeparture', label: 'MYU Mekoryuk, United States, Mekoryuk, MYU, mekoryuk mekoryuk alaska united states', key:'A_MYU', cityBase: 127, city: 'MYU', country: 'US', type: 'airport', nlabel: 'mekoryuk mekoryuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MNA Melonguane, Indonesia, Melangguane, MNA, melangguane melonguane indonesia', key:'A_MNA', cityBase: 127, city: 'MNA', country: 'ID', type: 'airport', nlabel: 'melangguane melonguane indonesia'},
  { icon: 'faPlaneDeparture', label: 'MEL Melbourne, Australia, Melbourne, MEL, melbourne melbourne victoria australia', key:'A_MEL', cityBase: 21, city: 'MEL', country: 'AU', type: 'airport', nlabel: 'melbourne melbourne victoria australia'},
  { icon: 'faPlaneDeparture', label: 'MLN Melilla, Spain, Melilla, MLN, melilla melilla spain', key:'A_MLN', cityBase: 18, city: 'MLN', country: 'ES', type: 'airport', nlabel: 'melilla melilla spain'},
  { icon: 'faPlaneDeparture', label: 'MMB Memanbetsu, Japan, Memanbetsu, MMB, memanbetsu memanbetsu japan', key:'A_MMB', cityBase: 105, city: 'MMB', country: 'JP', type: 'airport', nlabel: 'memanbetsu memanbetsu japan'},
  { icon: 'faPlaneDeparture', label: 'FMM Memmingen, Germany, Memmingen, FMM, memmingen memmingen germany 1', key:'A_FMM', cityBase: 9, city: 'FMM', country: 'DE', type: 'airport', nlabel: 'memmingen memmingen germany 1'},
  { icon: 'faPlaneDeparture', label: 'HOT Hot Springs, United States, Memorial Field, HOT, memorial field hot springs arkansas united states', key:'A_HOT', cityBase: 122, city: 'HOT', country: 'US', type: 'airport', nlabel: 'memorial field hot springs arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'MEM Memphis, United States, Memphis International, MEM, memphis international memphis tennessee united states', key:'A_MEM', cityBase: 8, city: 'MEM', country: 'US', type: 'airport', nlabel: 'memphis international memphis tennessee united states'},
  { icon: 'faPlaneDeparture', label: 'MDU Mendi, Papua New Guinea, Mendi, MDU, mendi mendi papua new guinea', key:'A_MDU', cityBase: 127, city: 'MDU', country: 'PG', type: 'airport', nlabel: 'mendi mendi papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'SPP Menongue, Angola, Menongue, SPP, menongue menongue angola', key:'A_SPP', cityBase: 127, city: 'SPP', country: 'AO', type: 'airport', nlabel: 'menongue menongue angola'},
  { icon: 'faPlaneDeparture', label: 'MAH Menorca, Spain, Menorca, MAH, menorca menorca spain', key:'A_MAH', cityBase: 9, city: 'MAH', country: 'ES', type: 'airport', nlabel: 'menorca menorca spain'},
  { icon: 'faPlaneDeparture', label: 'PSE Ponce, United States, Mercedita, PSE, mercedita ponce puerto rico united states', key:'A_PSE', cityBase: 49, city: 'PSE', country: 'US', type: 'airport', nlabel: 'mercedita ponce puerto rico united states'},
  { icon: 'faPlaneDeparture', label: 'MID Mérida, Mexico, Mérida International, MID, merida international merida mexico', key:'A_MID', cityBase: 40, city: 'MID', country: 'MX', type: 'airport', nlabel: 'merida international merida mexico'},
  { icon: 'faPlaneDeparture', label: 'MEI Meridian, United States, Meridian Regional, MEI, meridian regional meridian mississippi united states', key:'A_MEI', cityBase: 124, city: 'MEI', country: 'US', type: 'airport', nlabel: 'meridian regional meridian mississippi united states'},
  { icon: 'faPlaneDeparture', label: 'MIM Merimbula, Australia, Merimbula, MIM, merimbula merimbula new south wales australia', key:'A_MIM', cityBase: 127, city: 'MIM', country: 'AU', type: 'airport', nlabel: 'merimbula merimbula new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'CDV Cordova, United States, Merle K. (Mudhole) Smith, CDV, merle k (mudhole) smith cordova alaska united states', key:'A_CDV', cityBase: 123, city: 'CDV', country: 'US', type: 'airport', nlabel: 'merle k (mudhole) smith cordova alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MUH Mersa Matruh, Egypt, Mersa Matruh Airport, MUH, mersa matruh airport mersa matruh egypt', key:'A_MUH', cityBase: 123, city: 'MUH', country: 'EG', type: 'airport', nlabel: 'mersa matruh airport mersa matruh egypt'},
  { icon: 'faPlaneDeparture', label: 'MTM Metlakatla, United States, Metlakatla Sea Plane Base, MTM, metlakatla sea plane base metlakatla alaska united states', key:'A_MTM', cityBase: 127, city: 'MTM', country: 'US', type: 'airport', nlabel: 'metlakatla sea plane base metlakatla alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ETZ Nancy, France, Metz–Nancy–Lorraine, ETZ, metz nancy lorraine nancy france', key:'A_ETZ', cityBase: 88, city: 'ETZ', country: 'FR', type: 'airport', nlabel: 'metz nancy lorraine nancy france'},
  { icon: 'faPlaneDeparture', label: 'MXL Mexicali, Mexico, Mexicali International, MXL, mexicali international mexicali mexico', key:'A_MXL', cityBase: 53, city: 'MXL', country: 'MX', type: 'airport', nlabel: 'mexicali international mexicali mexico'},
  { icon: 'faPlaneDeparture', label: 'MEX Mexico City, Mexico, Mexico City International, MEX, mexico city international mexico city mexico', key:'A_MEX', cityBase: 3, city: 'MEX', country: 'MX', type: 'airport', nlabel: 'mexico city international mexico city mexico'},
  { icon: 'faPlaneDeparture', label: 'WMK Meyers Chuck, United States, Meyers Chuck Sea Plane Base, WMK, meyers chuck sea plane base meyers chuck alaska united states', key:'A_WMK', cityBase: 127, city: 'WMK', country: 'US', type: 'airport', nlabel: 'meyers chuck sea plane base meyers chuck alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MIA Miami, United States, Miami International, MIA, miami international miami florida united states', key:'A_MIA', cityBase: 0, city: 'MIA', country: 'US', type: 'airport', nlabel: 'miami international miami florida united states'},
  { icon: 'faPlaneDeparture', label: 'MIG Mianyang, China, Mianyang Nanjiao, MIG, mianyang nanjiao mianyang china', key:'A_MIG', cityBase: 65, city: 'MIG', country: 'CN', type: 'airport', nlabel: 'mianyang nanjiao mianyang china'},
  { icon: 'faPlaneDeparture', label: 'YGP Gaspé, Canada, Michel-Pouliot Gaspé, YGP, michel pouliot gaspe gaspe quebec canada', key:'A_YGP', cityBase: 101, city: 'YGP', country: 'CA', type: 'airport', nlabel: 'michel pouliot gaspe gaspe quebec canada'},
  { icon: 'faPlaneDeparture', label: 'PKB Parkersburg, United States, Mid-Ohio Valley Regional, PKB, mid ohio valley regional parkersburg west virginia united states', key:'A_PKB', cityBase: 127, city: 'PKB', country: 'US', type: 'airport', nlabel: 'mid ohio valley regional parkersburg west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'BLV Belleville, United States, MidAmerica St. Louis, BLV, midamerica st louis belleville illinois united states', key:'A_BLV', cityBase: 49, city: 'BLV', country: 'US', type: 'airport', nlabel: 'midamerica st louis belleville illinois united states'},
  { icon: 'faPlaneDeparture', label: 'MCN Macon, United States, Middle Georgia Regional, MCN, middle georgia regional macon georgia united states', key:'A_MCN', cityBase: 121, city: 'MCN', country: 'US', type: 'airport', nlabel: 'middle georgia regional macon georgia united states'},
  { icon: 'faPlaneDeparture', label: 'MMM Middlemount, Australia, Middlemount, MMM, middlemount middlemount queensland australia', key:'A_MMM', cityBase: 127, city: 'MMM', country: 'AU', type: 'airport', nlabel: 'middlemount middlemount queensland australia'},
  { icon: 'faPlaneDeparture', label: 'MAF Midland, United States, Midland International Air and Space Port, MAF, midland international air and space port midland texas united states', key:'A_MAF', cityBase: 55, city: 'MAF', country: 'US', type: 'airport', nlabel: 'midland international air and space port midland texas united states'},
  { icon: 'faPlaneDeparture', label: 'KRP Karup, Denmark, Midtjyllands, KRP, midtjyllands karup denmark', key:'A_KRP', cityBase: 113, city: 'KRP', country: 'DK', type: 'airport', nlabel: 'midtjyllands karup denmark'},
  { icon: 'faPlaneDeparture', label: 'CND Constanța, Romania, Mihail Kogălniceanu International, CND, mihail kogalniceanu international constanta romania', key:'A_CND', cityBase: 28, city: 'CND', country: 'RO', type: 'airport', nlabel: 'mihail kogalniceanu international constanta romania'},
  { icon: 'faPlaneDeparture', label: 'YGJ Yonago, Japan, Miho-Yonago, YGJ, miho yonago yonago japan', key:'A_YGJ', cityBase: 127, city: 'YGJ', country: 'JP', type: 'airport', nlabel: 'miho yonago yonago japan'},
  { icon: 'faPlaneDeparture', label: 'BGY Milan, Italy, Milan Bergamo International Airport, BGY, orio al serio international milan italy', key:'A_BGY', cityBase: 6, city: 'MIL', country: 'IT', type: 'airport', nlabel: 'orio al serio international milan italy'},
  { icon: 'faPlaneDeparture', label: 'MXP Milan, Italy, Milan–Malpensa, MXP, milan malpensa milan italy', key:'A_MXP', cityBase: 4, city: 'MIL', country: 'IT', type: 'airport', nlabel: 'milan malpensa milan italy'},
  { icon: 'faPlaneDeparture', label: 'BJV Bodrum, Turkey, Milas–Bodrum, BJV, milas bodrum bodrum turkey', key:'A_BJV', cityBase: 13, city: 'BJV', country: 'TR', type: 'airport', nlabel: 'milas bodrum bodrum turkey'},
  { icon: 'faPlaneDeparture', label: 'MQL Mildura, Australia, Mildura, MQL, mildura mildura victoria australia', key:'A_MQL', cityBase: 127, city: 'MQL', country: 'AU', type: 'airport', nlabel: 'mildura mildura victoria australia'},
  { icon: 'faPlaneDeparture', label: 'MGT Milingimbi Island, Australia, Milingimbi, MGT, milingimbi milingimbi island northern territory australia', key:'A_MGT', cityBase: 127, city: 'MGT', country: 'AU', type: 'airport', nlabel: 'milingimbi milingimbi island northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'MLO Plaka, Milos, Greece, Milos Island National, MLO, milos island national plaka greece', key:'A_MLO', cityBase: 22, city: 'MLO', country: 'GR', type: 'airport', nlabel: 'milos island national plaka greece'},
  { icon: 'faPlaneDeparture', label: 'PDG Padang, Indonesia, Minangkabau International, PDG, minangkabau international padang indonesia', key:'A_PDG', cityBase: 97, city: 'PDG', country: 'ID', type: 'airport', nlabel: 'minangkabau international padang indonesia'},
  { icon: 'faPlaneDeparture', label: 'MTT Minatitlán, Mexico, Minatitlán/Coatzacoalcos International, MTT, minatitlan coatzacoalcos international minatitlan mexico', key:'A_MTT', cityBase: 53, city: 'MTT', country: 'MX', type: 'airport', nlabel: 'minatitlan coatzacoalcos international minatitlan mexico'},
  { icon: 'faPlaneDeparture', label: 'LMA Lake Minchumina, United States, Minchumina, LMA, minchumina lake minchumina alaska united states', key:'A_LMA', cityBase: 127, city: 'LMA', country: 'US', type: 'airport', nlabel: 'minchumina lake minchumina alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MRV Mineralnye Vody, Russia, Mineralnye Vody, MRV, mineralnye vody mineralnye vody russia', key:'A_MRV', cityBase: 43, city: 'MRV', country: 'RU', type: 'airport', nlabel: 'mineralnye vody mineralnye vody russia'},
  { icon: 'faPlaneDeparture', label: 'EZE Buenos Aires, Argentina, Ministro Pistarini International, EZE, ministro pistarini international buenos aires buenos aires argentina', key:'A_EZE', cityBase: 32, city: 'BUE', country: 'AR', type: 'airport', nlabel: 'ministro pistarini international buenos aires buenos aires argentina'},
  { icon: 'faPlaneDeparture', label: 'MSP Minneapolis, United States, Minneapolis–Saint Paul International, MSP, minneapolis saint paul international minneapolis minnesota united states', key:'A_MSP', cityBase: 1, city: 'MSP', country: 'US', type: 'airport', nlabel: 'minneapolis saint paul international minneapolis minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'MOT Minot, United States, Minot International, MOT, minot international minot north dakota united states', key:'A_MOT', cityBase: 82, city: 'MOT', country: 'US', type: 'airport', nlabel: 'minot international minot north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'MSQ Minsk, Belarus, Minsk National, MSQ, minsk national minsk belarus', key:'A_MSQ', cityBase: 40, city: 'MSQ', country: 'BY', type: 'airport', nlabel: 'minsk national minsk belarus'},
  { icon: 'faPlaneDeparture', label: 'MHP Minsk, Belarus, Minsk-1, MHP, minsk 1 minsk belarus', key:'A_MHP', cityBase: 114, city: 'MSQ', country: 'BY', type: 'airport', nlabel: 'minsk 1 minsk belarus'},
  { icon: 'faPlaneDeparture', label: 'MNT Minto, United States, Minto Al Wright, MNT, minto al wright minto alaska united states', key:'A_MNT', cityBase: 127, city: 'MNT', country: 'US', type: 'airport', nlabel: 'minto al wright minto alaska united states'},
  { icon: 'faPlaneDeparture', label: 'MYY Miri, Malaysia, Miri, MYY, miri miri malaysia', key:'A_MYY', cityBase: 76, city: 'MYY', country: 'MY', type: 'airport', nlabel: 'miri miri malaysia'},
  { icon: 'faPlaneDeparture', label: 'MJZ Mirny, Russia, Mirny, MJZ, mirny mirny russia', key:'A_MJZ', cityBase: 49, city: 'MJZ', country: 'RU', type: 'airport', nlabel: 'mirny mirny russia'},
  { icon: 'faPlaneDeparture', label: 'MSJ Misawa, Japan, Misawa, MSJ, misawa misawa japan', key:'A_MSJ', cityBase: 83, city: 'MSJ', country: 'JP', type: 'airport', nlabel: 'misawa misawa japan'},
  { icon: 'faPlaneDeparture', label: 'MIS Misima Island, Papua New Guinea, Misima Island, MIS, misima island misima island papua new guinea', key:'A_MIS', cityBase: 127, city: 'MIS', country: 'PG', type: 'airport', nlabel: 'misima island misima island papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'MRA Misurata, Libya, Misrata, MRA, misrata misurata libya', key:'A_MRA', cityBase: 107, city: 'MRA', country: 'LY', type: 'airport', nlabel: 'misrata misurata libya'},
  { icon: 'faPlaneDeparture', label: 'MSO Missoula, United States, Missoula International, MSO, missoula international missoula montana united states', key:'A_MSO', cityBase: 29, city: 'MSO', country: 'US', type: 'airport', nlabel: 'missoula international missoula montana united states'},
  { icon: 'faPlaneDeparture', label: 'MOI Mitiaro Island, Cook Islands, Mitiaro Island Airport, MOI, mitiaro island airport mitiaro island cook islands', key:'A_MOI', cityBase: 127, city: 'MOI', country: 'CK', type: 'airport', nlabel: 'mitiaro island airport mitiaro island cook islands'},
  { icon: 'faPlaneDeparture', label: 'MJI Tripoli, Libya, Mitiga International, MJI, mitiga international tripoli libya', key:'A_MJI', cityBase: 127, city: 'TIP', country: 'LY', type: 'airport', nlabel: 'mitiga international tripoli libya'},
  { icon: 'faPlaneDeparture', label: 'MMY Miyakojima, Japan, Miyako, MMY, miyako miyakojima japan', key:'A_MMY', cityBase: 127, city: 'MMY', country: 'JP', type: 'airport', nlabel: 'miyako miyakojima japan'},
  { icon: 'faPlaneDeparture', label: 'KMI Miyazaki, Japan, Miyazaki, KMI, miyazaki miyazaki japan', key:'A_KMI', cityBase: 101, city: 'KMI', country: 'JP', type: 'airport', nlabel: 'miyazaki miyazaki japan'},
  { icon: 'faPlaneDeparture', label: 'MQN Mo i Rana, Norway, Mo i Rana Airport, Røssvoll, MQN, mo i rana airport mo i rana norway', key:'A_MQN', cityBase: 127, city: 'MQN', country: 'NO', type: 'airport', nlabel: 'mo i rana airport mo i rana norway'},
  { icon: 'faPlaneDeparture', label: 'MOB Mobile, United States, Mobile Regional, MOB, mobile regional mobile alabama united states', key:'A_MOB', cityBase: 31, city: 'MOB', country: 'US', type: 'airport', nlabel: 'mobile regional mobile alabama united states'},
  { icon: 'faPlaneDeparture', label: 'MHC Castro, Chile, Mocopulli, MHC, mocopulli castro chile', key:'A_MHC', cityBase: 127, city: 'WCA', country: 'CL', type: 'airport', nlabel: 'mocopulli castro chile'},
  { icon: 'faPlaneDeparture', label: 'MJD Mohenjodaro, Pakistan, Moenjodaro, MJD, moenjodaro mohenjodaro pakistan', key:'A_MJD', cityBase: 127, city: 'MJD', country: 'PK', type: 'airport', nlabel: 'moenjodaro mohenjodaro pakistan'},
  { icon: 'faPlaneDeparture', label: 'CZL Constantine, Algeria, Mohamed Boudiaf International, CZL, mohamed boudiaf international constantine algeria', key:'A_CZL', cityBase: 124, city: 'CZL', country: 'DZ', type: 'airport', nlabel: 'mohamed boudiaf international constantine algeria'},
  { icon: 'faPlaneDeparture', label: 'CMN Casablanca, Morocco, Mohammed V International, CMN, mohammed v international casablanca morocco', key:'A_CMN', cityBase: 20, city: 'CAS', country: 'MA', type: 'airport', nlabel: 'mohammed v international casablanca morocco'},
  { icon: 'faPlaneDeparture', label: 'OHE Mohe County, China, Mohe Gulian, OHE, mohe gulian mohe county china', key:'A_OHE', cityBase: 73, city: 'OHE', country: 'CN', type: 'airport', nlabel: 'mohe gulian mohe county china'},
  { icon: 'faPlaneDeparture', label: 'MBA Mombasa, Kenya, Moi International, MBA, moi international mombasa kenya', key:'A_MBA', cityBase: 63, city: 'MBA', country: 'KE', type: 'airport', nlabel: 'moi international mombasa kenya'},
  { icon: 'faPlaneDeparture', label: 'MBT Masbate City, Philippines, Moises R. Espinosa, MBT, moises r espinosa masbate city philippines', key:'A_MBT', cityBase: 127, city: 'MBT', country: 'PH', type: 'airport', nlabel: 'moises r espinosa masbate city philippines'},
  { icon: 'faPlaneDeparture', label: 'MOL Molde, Norway, Molde Airport, Årø, MOL, molde airport molde norway', key:'A_MOL', cityBase: 106, city: 'MOL', country: 'NO', type: 'airport', nlabel: 'molde airport molde norway'},
  { icon: 'faPlaneDeparture', label: 'MKK Hoʻolehua, United States, Molokai, MKK, molokai ho`olehua hawaii united states', key:'A_MKK', cityBase: 83, city: 'MKK', country: 'US', type: 'airport', nlabel: 'molokai ho`olehua hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'MQJ Khonuu, Russia, Moma, MQJ, moma khonuu russia', key:'A_MQJ', cityBase: 127, city: 'MQJ', country: 'RU', type: 'airport', nlabel: 'moma khonuu russia'},
  { icon: 'faPlaneDeparture', label: 'MAS Manus Island, Papua New Guinea, Momote, MAS, momote manus island papua new guinea', key:'A_MAS', cityBase: 127, city: 'MAS', country: 'PG', type: 'airport', nlabel: 'momote manus island papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'MCM Monte Carlo, Monaco, Monaco Heliport, MCM, monaco heliport monte carlo monaco', key:'A_MCM', cityBase: 104, city: 'MCM', country: 'MC', type: 'airport', nlabel: 'monaco heliport monte carlo monaco'},
  { icon: 'faPlaneDeparture', label: 'MIR Monastir, Tunisia, Monastir Habib Bourguiba International, MIR, monastir habib bourguiba international monastir tunisia', key:'A_MIR', cityBase: 60, city: 'MIR', country: 'TN', type: 'airport', nlabel: 'monastir habib bourguiba international monastir tunisia'},
  { icon: 'faPlaneDeparture', label: 'MBE Monbetsu, Japan, Monbetsu, MBE, monbetsu monbetsu japan', key:'A_MBE', cityBase: 127, city: 'MBE', country: 'JP', type: 'airport', nlabel: 'monbetsu monbetsu japan'},
  { icon: 'faPlaneDeparture', label: 'LOV Monclova, Mexico, Monclova International, LOV, monclova international monclova mexico', key:'A_LOV', cityBase: 127, city: 'LOV', country: 'MX', type: 'airport', nlabel: 'monclova international monclova mexico'},
  { icon: 'faPlaneDeparture', label: 'MOG Mong Hsat Township, Myanmar (Burma), Monghsat, MOG, monghsat mong hsat township myanmar (burma)', key:'A_MOG', cityBase: 127, city: 'MOG', country: 'MM', type: 'airport', nlabel: 'monghsat mong hsat township myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'GEM Mongomo, Equatorial Guinea, Mongomo Mongomeyen, GEM, mongomo mongomeyen mongomo equatorial guinea', key:'A_GEM', cityBase: 122, city: 'GEM', country: 'GQ', type: 'airport', nlabel: 'mongomo mongomeyen mongomo equatorial guinea'},
  { icon: 'faPlaneDeparture', label: 'MLU Monroe, United States, Monroe Regional, MLU, monroe regional monroe louisiana united states', key:'A_MLU', cityBase: 84, city: 'MLU', country: 'US', type: 'airport', nlabel: 'monroe regional monroe louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'SAL San Salvador, El Salvador, Monseñor Óscar Arnulfo Romero International, SAL, monsenor oscar arnulfo romero international san salvador el salvador', key:'A_SAL', cityBase: 15, city: 'SAL', country: 'SV', type: 'airport', nlabel: 'monsenor oscar arnulfo romero international san salvador el salvador'},
  { icon: 'faPlaneDeparture', label: 'YTM Mont-Tremblant, Canada, Mont Tremblant International, YTM, mont tremblant international mont tremblant quebec canada', key:'A_YTM', cityBase: 127, city: 'YTM', country: 'CA', type: 'airport', nlabel: 'mont tremblant international mont tremblant quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YYY Mont-Joli, Canada, Mont-Joli, YYY, mont joli mont joli quebec canada', key:'A_YYY', cityBase: 127, city: 'YYY', country: 'CA', type: 'airport', nlabel: 'mont joli mont joli quebec canada'},
  { icon: 'faPlaneDeparture', label: 'MRY Monterey, United States, Monterey Regional, MRY, monterey regional monterey california united states', key:'A_MRY', cityBase: 38, city: 'MRY', country: 'US', type: 'airport', nlabel: 'monterey regional monterey california united states'},
  { icon: 'faPlaneDeparture', label: 'MTY Monterrey, Mexico, Monterrey International, MTY, monterrey international monterrey mexico', key:'A_MTY', cityBase: 31, city: 'MTY', country: 'MX', type: 'airport', nlabel: 'monterrey international monterrey mexico'},
  { icon: 'faPlaneDeparture', label: 'MOC Montes Claros, Brazil, Montes Claros, MOC, montes claros montes claros state of minas gerais brazil', key:'A_MOC', cityBase: 108, city: 'MOC', country: 'BR', type: 'airport', nlabel: 'montes claros montes claros state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'MGM Montgomery, United States, Montgomery Regional, MGM, montgomery regional montgomery alabama united states', key:'A_MGM', cityBase: 74, city: 'MGM', country: 'US', type: 'airport', nlabel: 'montgomery regional montgomery alabama united states'},
  { icon: 'faPlaneDeparture', label: 'MPL Montpellier, France, Montpellier–Méditerranée, MPL, montpellier mediterranee montpellier france', key:'A_MPL', cityBase: 46, city: 'MPL', country: 'FR', type: 'airport', nlabel: 'montpellier mediterranee montpellier france'},
  { icon: 'faPlaneDeparture', label: 'YUL Montreal, Canada, Montréal–Pierre Elliott Trudeau International, YUL, montreal pierre elliott trudeau international montreal quebec canada', key:'A_YUL', cityBase: 16, city: 'YMQ', country: 'CA', type: 'airport', nlabel: 'montreal pierre elliott trudeau international montreal quebec canada'},
  { icon: 'faPlaneDeparture', label: 'MTJ Montrose, United States, Montrose Regional, MTJ, montrose regional montrose colorado united states', key:'A_MTJ', cityBase: 59, city: 'MTJ', country: 'US', type: 'airport', nlabel: 'montrose regional montrose colorado united states'},
  { icon: 'faPlaneDeparture', label: 'NYW Monywa, Myanmar (Burma), Monywa, NYW, monywa monywa myanmar (burma)', key:'A_NYW', cityBase: 127, city: 'NYW', country: 'MM', type: 'airport', nlabel: 'monywa monywa myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'MOZ Mo orea, French Polynesia, Moorea, MOZ, moorea mo orea french polynesia', key:'A_MOZ', cityBase: 92, city: 'MOZ', country: 'PF', type: 'airport', nlabel: 'moorea mo orea french polynesia'},
  { icon: 'faPlaneDeparture', label: 'YMO Moosonee, Canada, Moosonee, YMO, moosonee moosonee ontario canada', key:'A_YMO', cityBase: 127, city: 'YMO', country: 'CA', type: 'airport', nlabel: 'moosonee moosonee ontario canada'},
  { icon: 'faPlaneDeparture', label: 'MKQ Merauke, Indonesia, Mopah International, MKQ, mopah international merauke indonesia', key:'A_MKQ', cityBase: 127, city: 'MKQ', country: 'ID', type: 'airport', nlabel: 'mopah international merauke indonesia'},
  { icon: 'faPlaneDeparture', label: 'MXX Mora, Sweden, Mora–Siljan, MXX, mora siljan mora sweden', key:'A_MXX', cityBase: 127, city: 'MXX', country: 'SE', type: 'airport', nlabel: 'mora siljan mora sweden'},
  { icon: 'faPlaneDeparture', label: 'MOV Moranbah, Australia, Moranbah, MOV, moranbah moranbah queensland australia', key:'A_MOV', cityBase: 127, city: 'MOV', country: 'AU', type: 'airport', nlabel: 'moranbah moranbah queensland australia'},
  { icon: 'faPlaneDeparture', label: 'MRZ Moree, Australia, Moree, MRZ, moree moree new south wales australia', key:'A_MRZ', cityBase: 127, city: 'MRZ', country: 'AU', type: 'airport', nlabel: 'moree moree new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'MLM Morelia, Mexico, Morelia International, MLM, morelia international morelia mexico', key:'A_MLM', cityBase: 36, city: 'MLM', country: 'MX', type: 'airport', nlabel: 'morelia international morelia mexico'},
  { icon: 'faPlaneDeparture', label: 'MGW Morgantown, United States, Morgantown Municipal, MGW, morgantown municipal morgantown west virginia united states', key:'A_MGW', cityBase: 117, city: 'MGW', country: 'US', type: 'airport', nlabel: 'morgantown municipal morgantown west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'ONG Mornington Island, Australia, Mornington Island, ONG, mornington island mornington island queensland australia', key:'A_ONG', cityBase: 127, city: 'ONG', country: 'AU', type: 'airport', nlabel: 'mornington island mornington island queensland australia'},
  { icon: 'faPlaneDeparture', label: 'MXH Iagifu Ridge Camp, Papua New Guinea, Moro, MXH, moro iagifu ridge camp papua new guinea', key:'A_MXH', cityBase: 127, city: 'MXH', country: 'PG', type: 'airport', nlabel: 'moro iagifu ridge camp papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'MXV Moron, Mongolia, Mörön, MXV, moron moron mongolia', key:'A_MXV', cityBase: 127, city: 'MXV', country: 'MN', type: 'airport', nlabel: 'moron moron mongolia'},
  { icon: 'faPlaneDeparture', label: 'MOQ Morondava, Madagascar, Morondava, MOQ, morondava morondava madagascar', key:'A_MOQ', cityBase: 127, city: 'MOQ', country: 'MG', type: 'airport', nlabel: 'morondava morondava madagascar'},
  { icon: 'faPlaneDeparture', label: 'MMU Morristown, United States, Morristown Municipal, MMU, morristown municipal morristown new jersey united states', key:'A_MMU', cityBase: 127, city: 'MMU', country: 'US', type: 'airport', nlabel: 'morristown municipal morristown new jersey united states'},
  { icon: 'faPlaneDeparture', label: 'MVL Stowe, United States, Morrisville–Stowe State, MVL, morrisville stowe state stowe vermont united states', key:'A_MVL', cityBase: 127, city: 'MVL', country: 'US', type: 'airport', nlabel: 'morrisville stowe state stowe vermont united states'},
  { icon: 'faPlaneDeparture', label: 'MYA Moruya, Australia, Moruya, MYA, moruya moruya new south wales australia', key:'A_MYA', cityBase: 127, city: 'MYA', country: 'AU', type: 'airport', nlabel: 'moruya moruya new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'MJF Mosjøen, Norway, Mosjøen Airport, Kjærstad, MJF, mosjoen airport mosjoen norway', key:'A_MJF', cityBase: 127, city: 'MJF', country: 'NO', type: 'airport', nlabel: 'mosjoen airport mosjoen norway'},
  { icon: 'faPlaneDeparture', label: 'RYG Oslo, Norway, Moss Airport, Rygge, RYG, moss airport oslo norway', key:'A_RYG', cityBase: 93, city: 'OSL', country: 'NO', type: 'airport', nlabel: 'moss airport oslo norway'},
  { icon: 'faPlaneDeparture', label: 'OMO Mostar, Bosnia & Herzegovina, Mostar, OMO, mostar mostar bosnia and herzegovina', key:'A_OMO', cityBase: 89, city: 'OMO', country: 'BA', type: 'airport', nlabel: 'mostar mostar bosnia and herzegovina'},
  { icon: 'faPlaneDeparture', label: 'BLJ Batna, Algeria, Mostépha Ben Boulaid, BLJ, mostepha ben boulaid batna algeria', key:'A_BLJ', cityBase: 127, city: 'BLJ', country: 'DZ', type: 'airport', nlabel: 'mostepha ben boulaid batna algeria'},
  { icon: 'faPlaneDeparture', label: 'MTV Mota Lava, Vanuatu, Mota Lava, MTV, mota lava mota lava vanuatu', key:'A_MTV', cityBase: 127, city: 'MTV', country: 'VU', type: 'airport', nlabel: 'mota lava mota lava vanuatu'},
  { icon: 'faPlaneDeparture', label: 'ERH Errachidia, Morocco, Moulay Ali Cherif, ERH, moulay ali cherif errachidia morocco', key:'A_ERH', cityBase: 127, city: 'ERH', country: 'MA', type: 'airport', nlabel: 'moulay ali cherif errachidia morocco'},
  { icon: 'faPlaneDeparture', label: 'MGB Mount Gambier, Australia, Mount Gambier, MGB, mount gambier mount gambier south australia australia', key:'A_MGB', cityBase: 127, city: 'MGB', country: 'AU', type: 'airport', nlabel: 'mount gambier mount gambier south australia australia'},
  { icon: 'faPlaneDeparture', label: 'HGU Mount Hagen, Papua New Guinea, Mount Hagen, HGU, mount hagen mount hagen papua new guinea', key:'A_HGU', cityBase: 127, city: 'HGU', country: 'PG', type: 'airport', nlabel: 'mount hagen mount hagen papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'ISA Mount Isa, Australia, Mount Isa, ISA, mount isa mount isa queensland australia', key:'A_ISA', cityBase: 127, city: 'ISA', country: 'AU', type: 'airport', nlabel: 'mount isa mount isa queensland australia'},
  { icon: 'faPlaneDeparture', label: 'MOU Mountain Village, United States, Mountain Village, MOU, mountain village mountain village alaska united states', key:'A_MOU', cityBase: 127, city: 'MOU', country: 'US', type: 'airport', nlabel: 'mountain village mountain village alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TIM Timika, Indonesia, Mozes Kilangin International, TIM, mozes kilangin international timika indonesia', key:'A_TIM', cityBase: 127, city: 'TIM', country: 'ID', type: 'airport', nlabel: 'mozes kilangin international timika indonesia'},
  { icon: 'faPlaneDeparture', label: 'UTT Mthatha, South Africa, Mthatha, UTT, mthatha mthatha south africa', key:'A_UTT', cityBase: 127, city: 'UTT', country: 'ZA', type: 'airport', nlabel: 'mthatha mthatha south africa'},
  { icon: 'faPlaneDeparture', label: 'MYW Mtwara, Tanzania, Mtwara, MYW, mtwara mtwara tanzania', key:'A_MYW', cityBase: 127, city: 'MYW', country: 'TZ', type: 'airport', nlabel: 'mtwara mtwara tanzania'},
  { icon: 'faPlaneDeparture', label: 'MWX Muan County, South Korea, Muan International, MWX, muan international muan county south korea', key:'A_MWX', cityBase: 127, city: 'MWX', country: 'KR', type: 'airport', nlabel: 'muan international muan county south korea'},
  { icon: 'faPlaneDeparture', label: 'MDG Mudanjiang, China, Mudanjiang Hailang International, MDG, mudanjiang hailang international mudanjiang china', key:'A_MDG', cityBase: 65, city: 'MDG', country: 'CN', type: 'airport', nlabel: 'mudanjiang hailang international mudanjiang china'},
  { icon: 'faPlaneDeparture', label: 'DGE Mudgee, Australia, Mudgee, DGE, mudgee mudgee new south wales australia', key:'A_DGE', cityBase: 127, city: 'DGE', country: 'AU', type: 'airport', nlabel: 'mudgee mudgee new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'MKM Mukah, Malaysia, Mukah, MKM, mukah mukah malaysia', key:'A_MKM', cityBase: 127, city: 'MKM', country: 'MY', type: 'airport', nlabel: 'mukah mukah malaysia'},
  { icon: 'faPlaneDeparture', label: 'RIY Riyan, Yemen, Mukalla International Airport, RIY, mukalla international airport riyan yemen', key:'A_RIY', cityBase: 127, city: 'RIY', country: 'YE', type: 'airport', nlabel: 'mukalla international airport riyan yemen'},
  { icon: 'faPlaneDeparture', label: 'MPP Mulatupo, Panama, Mulatupo, MPP, mulatupo mulatupo panama', key:'A_MPP', cityBase: 127, city: 'MPP', country: 'PA', type: 'airport', nlabel: 'mulatupo mulatupo panama'},
  { icon: 'faPlaneDeparture', label: 'MLH Basel, Switzerland, Mulhouse/Basel EuroAirport French  FR, MLH, mulhouse basel euroairport french fr basel switzerland', key:'A_MLH', cityBase: 25, city: 'EAP', country: 'CH', type: 'airport', nlabel: 'mulhouse basel euroairport french fr basel switzerland'},
  { icon: 'faPlaneDeparture', label: 'MUX Multan, Pakistan, Multan International, MUX, multan international multan pakistan', key:'A_MUX', cityBase: 65, city: 'MUX', country: 'PK', type: 'airport', nlabel: 'multan international multan pakistan'},
  { icon: 'faPlaneDeparture', label: 'MZV Gunung Mulu National Park, Malaysia, Mulu, MZV, mulu gunung mulu national park malaysia', key:'A_MZV', cityBase: 87, city: 'MZV', country: 'MY', type: 'airport', nlabel: 'mulu gunung mulu national park malaysia'},
  { icon: 'faPlaneDeparture', label: 'MGU Manaung, Myanmar (Burma), Munaung, MGU, munaung manaung myanmar (burma)', key:'A_MGU', cityBase: 127, city: 'MGU', country: 'MM', type: 'airport', nlabel: 'munaung manaung myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'MUA Munda, Solomon Islands, Munda, MUA, munda munda solomon islands', key:'A_MUA', cityBase: 127, city: 'MUA', country: 'SB', type: 'airport', nlabel: 'munda munda solomon islands'},
  { icon: 'faPlaneDeparture', label: 'FRS Flores, Guatemala, Mundo Maya International, FRS, mundo maya international flores guatemala', key:'A_FRS', cityBase: 87, city: 'FRS', country: 'GT', type: 'airport', nlabel: 'mundo maya international flores guatemala'},
  { icon: 'faPlaneDeparture', label: 'MUC Munich, Germany, Munich, MUC, munich munich germany', key:'A_MUC', cityBase: 0, city: 'MUC', country: 'DE', type: 'airport', nlabel: 'munich munich germany'},
  { icon: 'faPlaneDeparture', label: 'FMO Münster, Germany, Münster Osnabrück International, FMO, munster osnabruck international munster germany', key:'A_FMO', cityBase: 6, city: 'FMO', country: 'DE', type: 'airport', nlabel: 'munster osnabruck international munster germany'},
  { icon: 'faPlaneDeparture', label: 'MJV Murcia, Spain, Murcia–San Javier, MJV, murcia san javier murcia spain', key:'A_MJV', cityBase: 15, city: 'MJV', country: 'ES', type: 'airport', nlabel: 'murcia san javier murcia spain'},
  { icon: 'faPlaneDeparture', label: 'MMK Murmansk, Russia, Murmansk, MMK, murmansk murmansk russia', key:'A_MMK', cityBase: 44, city: 'MMK', country: 'RU', type: 'airport', nlabel: 'murmansk murmansk russia'},
  { icon: 'faPlaneDeparture', label: 'LOS Lagos, Nigeria, Murtala Muhammed International, LOS, murtala muhammed international lagos nigeria', key:'A_LOS', cityBase: 26, city: 'LOS', country: 'NG', type: 'airport', nlabel: 'murtala muhammed international lagos nigeria'},
  { icon: 'faPlaneDeparture', label: 'MSR Muş, Turkey, Muş, MSR, mus mus turkey', key:'A_MSR', cityBase: 23, city: 'MSR', country: 'TR', type: 'airport', nlabel: 'mus mus turkey'},
  { icon: 'faPlaneDeparture', label: 'MCT Muscat, Oman, Muscat International, MCT, muscat international muscat oman', key:'A_MCT', cityBase: 37, city: 'MCT', country: 'OM', type: 'airport', nlabel: 'muscat international muscat oman'},
  { icon: 'faPlaneDeparture', label: 'MKG Muskegon, United States, Muskegon County, MKG, muskegon county muskegon michigan united states', key:'A_MKG', cityBase: 95, city: 'MKG', country: 'US', type: 'airport', nlabel: 'muskegon county muskegon michigan united states'},
  { icon: 'faPlaneDeparture', label: 'MUZ Musoma, Tanzania, Musoma, MUZ, musoma musoma tanzania', key:'A_MUZ', cityBase: 127, city: 'MUZ', country: 'TZ', type: 'airport', nlabel: 'musoma musoma tanzania'},
  { icon: 'faPlaneDeparture', label: 'PLW Palu, Indonesia, Mutiara SIS Al-Jufrie, PLW, mutiara sis al jufrie palu indonesia', key:'A_PLW', cityBase: 127, city: 'PLW', country: 'ID', type: 'airport', nlabel: 'mutiara sis al jufrie palu indonesia'},
  { icon: 'faPlaneDeparture', label: 'MWZ Mwanza, Tanzania, Mwanza, MWZ, mwanza mwanza tanzania', key:'A_MWZ', cityBase: 127, city: 'MWZ', country: 'TZ', type: 'airport', nlabel: 'mwanza mwanza tanzania'},
  { icon: 'faPlaneDeparture', label: 'MGZ Myeik, Myanmar, Myanmar (Burma), Myeik, MGZ, myeik myeik myanmar (burma)', key:'A_MGZ', cityBase: 127, city: 'MGZ', country: 'MM', type: 'airport', nlabel: 'myeik myeik myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'MYT Myitkyina, Myanmar (Burma), Myitkyina, MYT, myitkyina myitkyina myanmar (burma)', key:'A_MYT', cityBase: 127, city: 'MYT', country: 'MM', type: 'airport', nlabel: 'myitkyina myitkyina myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'JMK Mykonos, Greece, Mykonos Island National, JMK, mykonos island national mykonos greece', key:'A_JMK', cityBase: 10, city: 'JMK', country: 'GR', type: 'airport', nlabel: 'mykonos island national mykonos greece'},
  { icon: 'faPlaneDeparture', label: 'MYR Myrtle Beach, United States, Myrtle Beach International, MYR, myrtle beach international myrtle beach south carolina united states', key:'A_MYR', cityBase: 14, city: 'MYR', country: 'US', type: 'airport', nlabel: 'myrtle beach international myrtle beach south carolina united states'},
  { icon: 'faPlaneDeparture', label: 'MYQ Mysore, India, Mysore, MYQ, mysore mysore india', key:'A_MYQ', cityBase: 118, city: 'MYQ', country: 'IN', type: 'airport', nlabel: 'mysore mysore india'},
  { icon: 'faPlaneDeparture', label: 'MJT Mytilene, Greece, Mytilene International, MJT, mytilene international mytilene greece', key:'A_MJT', cityBase: 18, city: 'MJT', country: 'GR', type: 'airport', nlabel: 'mytilene international mytilene greece'},
  { icon: 'faPlaneDeparture', label: 'NDJ N Djamena, Chad, N Djamena International, NDJ, n djamena international n djamena chad', key:'A_NDJ', cityBase: 106, city: 'NDJ', country: 'TD', type: 'airport', nlabel: 'n djamena international n djamena chad'},
  { icon: 'faPlaneDeparture', label: 'FIH Kinshasa, Democratic Republic of the Congo, N djili, FIH, n djili kinshasa congo kinshasa', key:'A_FIH', cityBase: 38, city: 'FIH', country: 'CD', type: 'airport', nlabel: 'n djili kinshasa congo kinshasa'},
  { icon: 'faPlaneDeparture', label: 'NBX Nabire Regency, Indonesia, Nabire, NBX, nabire nabire regency indonesia', key:'A_NBX', cityBase: 127, city: 'NBX', country: 'ID', type: 'airport', nlabel: 'nabire nabire regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'MNC Nacala, Mozambique, Nacala, MNC, nacala nacala mozambique', key:'A_MNC', cityBase: 127, city: 'MNC', country: 'MZ', type: 'airport', nlabel: 'nacala nacala mozambique'},
  { icon: 'faPlaneDeparture', label: 'NAN Nadi, Fiji, Nadi International, NAN, nadi international nadi fiji', key:'A_NAN', cityBase: 30, city: 'NAN', country: 'FJ', type: 'airport', nlabel: 'nadi international nadi fiji'},
  { icon: 'faPlaneDeparture', label: 'NDR Nador, Morocco, Nador International, NDR, nador international nador morocco', key:'A_NDR', cityBase: 75, city: 'NDR', country: 'MA', type: 'airport', nlabel: 'nador international nador morocco'},
  { icon: 'faPlaneDeparture', label: 'NYM Nadym, Russia, Nadym, NYM, nadym nadym russia', key:'A_NYM', cityBase: 54, city: 'NYM', country: 'RU', type: 'airport', nlabel: 'nadym nadym russia'},
  { icon: 'faPlaneDeparture', label: 'WNP Naga, Camarines Sur, Philippines, Naga, WNP, naga naga philippines', key:'A_WNP', cityBase: 113, city: 'WNP', country: 'PH', type: 'airport', nlabel: 'naga naga philippines'},
  { icon: 'faPlaneDeparture', label: 'NGS Nagasaki, Japan, Nagasaki, NGS, nagasaki nagasaki japan', key:'A_NGS', cityBase: 117, city: 'NGS', country: 'JP', type: 'airport', nlabel: 'nagasaki nagasaki japan'},
  { icon: 'faPlaneDeparture', label: 'NKM Nagoya, Japan, Nagoya Airfield, NKM, nagoya airfield nagoya japan', key:'A_NKM', cityBase: 127, city: 'NGO', country: 'JP', type: 'airport', nlabel: 'nagoya airfield nagoya japan'},
  { icon: 'faPlaneDeparture', label: 'NAH Naha, Indonesia, Naha, NAH, naha naha indonesia', key:'A_NAH', cityBase: 119, city: 'NAH', country: 'ID', type: 'airport', nlabel: 'naha naha indonesia'},
  { icon: 'faPlaneDeparture', label: 'OKA Okinawa Island, Japan, Naha, OKA, naha okinawa island japan', key:'A_OKA', cityBase: 29, city: 'OKA', country: 'JP', type: 'airport', nlabel: 'naha okinawa island japan'},
  { icon: 'faPlaneDeparture', label: 'YDP Nain, Canada, Nain, YDP, nain nain newfoundland and labrador canada', key:'A_YDP', cityBase: 127, city: 'YDP', country: 'CA', type: 'airport', nlabel: 'nain nain newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'SHB Nakashibetsu, Japan, Nakashibetsu, SHB, nakashibetsu nakashibetsu japan', key:'A_SHB', cityBase: 127, city: 'SHB', country: 'JP', type: 'airport', nlabel: 'nakashibetsu nakashibetsu japan'},
  { icon: 'faPlaneDeparture', label: 'NAJ Nakhchivan, Azerbaijan, Nakhchivan International, NAJ, nakhchivan international nakhchivan azerbaijan', key:'A_NAJ', cityBase: 127, city: 'NAJ', country: 'AZ', type: 'airport', nlabel: 'nakhchivan international nakhchivan azerbaijan'},
  { icon: 'faPlaneDeparture', label: 'KOP Nakhon Phanom Province, Thailand, Nakhon Phanom, KOP, nakhon phanom nakhon phanom province thailand', key:'A_KOP', cityBase: 114, city: 'KOP', country: 'TH', type: 'airport', nlabel: 'nakhon phanom nakhon phanom province thailand'},
  { icon: 'faPlaneDeparture', label: 'NST Nakhon Si Thammarat Province, Thailand, Nakhon Si Thammarat, NST, nakhon si thammarat nakhon si thammarat province thailand', key:'A_NST', cityBase: 123, city: 'NST', country: 'TH', type: 'airport', nlabel: 'nakhon si thammarat nakhon si thammarat province thailand'},
  { icon: 'faPlaneDeparture', label: 'BKB Bikaner, India, Nal Airport, BKB, nal airport bikaner india', key:'A_BKB', cityBase: 127, city: 'BKB', country: 'IN', type: 'airport', nlabel: 'nal airport bikaner india'},
  { icon: 'faPlaneDeparture', label: 'NAL Nalchik, Russia, Nalchik, NAL, nalchik nalchik russia', key:'A_NAL', cityBase: 54, city: 'NAL', country: 'RU', type: 'airport', nlabel: 'nalchik nalchik russia'},
  { icon: 'faPlaneDeparture', label: 'NMA Namangan, Uzbekistan, Namangan, NMA, namangan namangan uzbekistan', key:'A_NMA', cityBase: 58, city: 'NMA', country: 'UZ', type: 'airport', nlabel: 'namangan namangan uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'MSZ Namibe, Angola, Namibe, MSZ, namibe namibe angola', key:'A_MSZ', cityBase: 115, city: 'MSZ', country: 'AO', type: 'airport', nlabel: 'namibe namibe angola'},
  { icon: 'faPlaneDeparture', label: 'APL Nampula, Mozambique, Nampula, APL, nampula nampula mozambique', key:'A_APL', cityBase: 127, city: 'APL', country: 'MZ', type: 'airport', nlabel: 'nampula nampula mozambique'},
  { icon: 'faPlaneDeparture', label: 'OSY Namsos, Norway, Namsos Airport, Høknesøra, OSY, namsos airport namsos norway', key:'A_OSY', cityBase: 102, city: 'OSY', country: 'NO', type: 'airport', nlabel: 'namsos airport namsos norway'},
  { icon: 'faPlaneDeparture', label: 'NNT Nan Province, Thailand, Nan Nakhon, NNT, nan nakhon nan province thailand', key:'A_NNT', cityBase: 115, city: 'NNT', country: 'TH', type: 'airport', nlabel: 'nan nakhon nan province thailand'},
  { icon: 'faPlaneDeparture', label: 'YCD Nanaimo, Canada, Nanaimo, YCD, nanaimo nanaimo british columbia canada', key:'A_YCD', cityBase: 94, city: 'YCD', country: 'CA', type: 'airport', nlabel: 'nanaimo nanaimo british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'KHN Nanchang, China, Nanchang Changbei International, KHN, nanchang changbei international nanchang china', key:'A_KHN', cityBase: 44, city: 'KHN', country: 'CN', type: 'airport', nlabel: 'nanchang changbei international nanchang china'},
  { icon: 'faPlaneDeparture', label: 'NAO Nanchong, China, Nanchong Gaoping, NAO, nanchong gaoping nanchong china', key:'A_NAO', cityBase: 69, city: 'NAO', country: 'CN', type: 'airport', nlabel: 'nanchong gaoping nanchong china'},
  { icon: 'faPlaneDeparture', label: 'NKG Nanjing, China, Nanjing Lukou International, NKG, nanjing lukou international nanjing china', key:'A_NKG', cityBase: 33, city: 'NKG', country: 'CN', type: 'airport', nlabel: 'nanjing lukou international nanjing china'},
  { icon: 'faPlaneDeparture', label: 'SHM Shirahama, Japan, Nanki–Shirahama, SHM, nanki shirahama shirahama japan', key:'A_SHM', cityBase: 127, city: 'SHM', country: 'JP', type: 'airport', nlabel: 'nanki shirahama shirahama japan'},
  { icon: 'faPlaneDeparture', label: 'NNG Nanning, China, Nanning Wuxu International, NNG, nanning wuxu international nanning china', key:'A_NNG', cityBase: 43, city: 'NNG', country: 'CN', type: 'airport', nlabel: 'nanning wuxu international nanning china'},
  { icon: 'faPlaneDeparture', label: 'NTE Nantes, France, Nantes Atlantique, NTE, nantes atlantique nantes france', key:'A_NTE', cityBase: 37, city: 'NTE', country: 'FR', type: 'airport', nlabel: 'nantes atlantique nantes france'},
  { icon: 'faPlaneDeparture', label: 'NTG Nantong, China, Nantong Xingdong, NTG, nantong xingdong nantong china', key:'A_NTG', cityBase: 70, city: 'NTG', country: 'CN', type: 'airport', nlabel: 'nantong xingdong nantong china'},
  { icon: 'faPlaneDeparture', label: 'ACK Nantucket, United States, Nantucket Memorial, ACK, nantucket memorial nantucket massachusetts united states', key:'A_ACK', cityBase: 36, city: 'ACK', country: 'US', type: 'airport', nlabel: 'nantucket memorial nantucket massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'KEB Nanwalek, United States, Nanwalek, KEB, nanwalek nanwalek alaska united states', key:'A_KEB', cityBase: 127, city: 'KEB', country: 'US', type: 'airport', nlabel: 'nanwalek nanwalek alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NNY Nanyang, China, Nanyang Jiangying, NNY, nanyang jiangying nanyang china', key:'A_NNY', cityBase: 69, city: 'NNY', country: 'CN', type: 'airport', nlabel: 'nanyang jiangying nanyang china'},
  { icon: 'faPlaneDeparture', label: 'NYK Nanyuki, Kenya, Nanyuki, NYK, nanyuki nanyuki kenya', key:'A_NYK', cityBase: 127, city: 'NYK', country: 'KE', type: 'airport', nlabel: 'nanyuki nanyuki kenya'},
  { icon: 'faPlaneDeparture', label: 'WNA Napakiak, United States, Napakiak, WNA, napakiak napakiak alaska united states', key:'A_WNA', cityBase: 127, city: 'WNA', country: 'US', type: 'airport', nlabel: 'napakiak napakiak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PKA Napaskiak, United States, Napaskiak, PKA, napaskiak napaskiak alaska united states', key:'A_PKA', cityBase: 127, city: 'PKA', country: 'US', type: 'airport', nlabel: 'napaskiak napaskiak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NAP Naples, Italy, Naples International, NAP, naples international naples italy', key:'A_NAP', cityBase: 5, city: 'NAP', country: 'IT', type: 'airport', nlabel: 'naples international naples italy'},
  { icon: 'faPlaneDeparture', label: 'APF Naples, United States, Naples Municipal, APF, naples municipal naples florida united states', key:'A_APF', cityBase: 90, city: 'APF', country: 'US', type: 'airport', nlabel: 'naples municipal naples florida united states'},
  { icon: 'faPlaneDeparture', label: 'NAU Napuka, French Polynesia, Napuka, NAU, napuka napuka french polynesia', key:'A_NAU', cityBase: 127, city: 'NAU', country: 'PF', type: 'airport', nlabel: 'napuka napuka french polynesia'},
  { icon: 'faPlaneDeparture', label: 'NAW Narathiwat Province, Thailand, Narathiwat, NAW, narathiwat narathiwat province thailand', key:'A_NAW', cityBase: 127, city: 'NAW', country: 'TH', type: 'airport', nlabel: 'narathiwat narathiwat province thailand'},
  { icon: 'faPlaneDeparture', label: 'ASF Astrakhan, Russia, Narimanovo, ASF, narimanovo astrakhan russia', key:'A_ASF', cityBase: 43, city: 'ASF', country: 'RU', type: 'airport', nlabel: 'narimanovo astrakhan russia'},
  { icon: 'faPlaneDeparture', label: 'NRT Tokyo, Japan, Narita International, NRT, narita international tokyo japan', key:'A_NRT', cityBase: 12, city: 'TYO', country: 'JP', type: 'airport', nlabel: 'narita international tokyo japan'},
  { icon: 'faPlaneDeparture', label: 'NRA Narrandera, Australia, Narrandera, NRA, narrandera narrandera new south wales australia', key:'A_NRA', cityBase: 127, city: 'NRA', country: 'AU', type: 'airport', nlabel: 'narrandera narrandera new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'UAK Narsarsuaq, Greenland, Narsarsuaq, UAK, narsarsuaq narsarsuaq greenland', key:'A_UAK', cityBase: 68, city: 'UAK', country: 'GL', type: 'airport', nlabel: 'narsarsuaq narsarsuaq greenland'},
  { icon: 'faPlaneDeparture', label: 'NVK Narvik, Norway, Narvik Airport, Framnes, NVK, narvik airport narvik norway', key:'A_NVK', cityBase: 127, city: 'EVE', country: 'NO', type: 'airport', nlabel: 'narvik airport narvik norway'},
  { icon: 'faPlaneDeparture', label: 'NNM Naryan-Mar, Russia, Naryan-Mar, NNM, naryan mar naryan mar russia', key:'A_NNM', cityBase: 58, city: 'NNM', country: 'RU', type: 'airport', nlabel: 'naryan mar naryan mar russia'},
  { icon: 'faPlaneDeparture', label: 'BNA Nashville, United States, Nashville International, BNA, nashville international nashville tennessee united states', key:'A_BNA', cityBase: 3, city: 'BNA', country: 'US', type: 'airport', nlabel: 'nashville international nashville tennessee united states'},
  { icon: 'faPlaneDeparture', label: 'YNA Natashquan, Canada, Natashquan, YNA, natashquan natashquan quebec canada', key:'A_YNA', cityBase: 127, city: 'YNA', country: 'CA', type: 'airport', nlabel: 'natashquan natashquan quebec canada'},
  { icon: 'faPlaneDeparture', label: 'YNP Natuashish, Canada, Natuashish, YNP, natuashish natuashish newfoundland and labrador canada', key:'A_YNP', cityBase: 127, city: 'YNP', country: 'CA', type: 'airport', nlabel: 'natuashish natuashish newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'YUT Naujaat, Canada, Naujaat, YUT, naujaat naujaat nunavut canada', key:'A_YUT', cityBase: 127, city: 'YUT', country: 'CA', type: 'airport', nlabel: 'naujaat naujaat nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'SUV Suva, Fiji, Nausori International, SUV, nausori international suva fiji', key:'A_SUV', cityBase: 72, city: 'SUV', country: 'FJ', type: 'airport', nlabel: 'nausori international suva fiji'},
  { icon: 'faPlaneDeparture', label: 'NVT Navegantes, Brazil, Navegantes, NVT, navegantes navegantes state of santa catarina brazil', key:'A_NVT', cityBase: 76, city: 'NVT', country: 'BR', type: 'airport', nlabel: 'navegantes navegantes state of santa catarina brazil'},
  { icon: 'faPlaneDeparture', label: 'NVI Navoiy, Uzbekistan, Navoi International, NVI, navoi international navoiy uzbekistan', key:'A_NVI', cityBase: 59, city: 'NVI', country: 'UZ', type: 'airport', nlabel: 'navoi international navoiy uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'JNX Naxos, Greece, Naxos Island National, JNX, naxos island national naxos greece', key:'A_JNX', cityBase: 22, city: 'JNX', country: 'GR', type: 'airport', nlabel: 'naxos island national naxos greece'},
  { icon: 'faPlaneDeparture', label: 'NYT Naypyidaw, Myanmar (Burma), Naypyidaw, NYT, naypyidaw naypyidaw myanmar (burma)', key:'A_NYT', cityBase: 103, city: 'NYT', country: 'MM', type: 'airport', nlabel: 'naypyidaw naypyidaw myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'IGT Nazran, Russia, Nazran, IGT, nazran nazran russia', key:'A_IGT', cityBase: 53, city: 'IGT', country: 'RU', type: 'airport', nlabel: 'nazran nazran russia'},
  { icon: 'faPlaneDeparture', label: 'VOL Volos, Greece, Nea Anchialos National, VOL, nea anchialos national volos greece', key:'A_VOL', cityBase: 22, city: 'VOL', country: 'GR', type: 'airport', nlabel: 'nea anchialos national volos greece'},
  { icon: 'faPlaneDeparture', label: 'NSN Nelson, New Zealand, Nelson, NSN, nelson nelson new zealand', key:'A_NSN', cityBase: 127, city: 'NSN', country: 'NZ', type: 'airport', nlabel: 'nelson nelson new zealand'},
  { icon: 'faPlaneDeparture', label: 'NLG Nelson Lagoon, United States, Nelson Lagoon, NLG, nelson lagoon nelson lagoon alaska united states', key:'A_NLG', cityBase: 127, city: 'NLG', country: 'US', type: 'airport', nlabel: 'nelson lagoon nelson lagoon alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RAI Praia, Cape Verde, Nelson Mandela International, RAI, nelson mandela international praia cape verde', key:'A_RAI', cityBase: 47, city: 'RAI', country: 'CV', type: 'airport', nlabel: 'nelson mandela international praia cape verde'},
  { icon: 'faPlaneDeparture', label: 'YNS Nemaska, Canada, Nemiscau, YNS, nemiscau nemaska quebec canada', key:'A_YNS', cityBase: 127, city: 'YNS', country: 'CA', type: 'airport', nlabel: 'nemiscau nemaska quebec canada'},
  { icon: 'faPlaneDeparture', label: 'KEP Nepalgunj, Nepal, Nepalgunj, KEP, nepalgunj nepalgunj nepal', key:'A_KEP', cityBase: 103, city: 'KEP', country: 'NP', type: 'airport', nlabel: 'nepalgunj nepalgunj nepal'},
  { icon: 'faPlaneDeparture', label: 'CCU Kolkata, India, Netaji Subhas Chandra Bose International, CCU, netaji subhas chandra bose international kolkata india', key:'A_CCU', cityBase: 36, city: 'CCU', country: 'IN', type: 'airport', nlabel: 'netaji subhas chandra bose international kolkata india'},
  { icon: 'faPlaneDeparture', label: 'NAV Nevşehir, Turkey, Nevşehir Kapadokya, NAV, nevsehir kapadokya nevsehir turkey', key:'A_NAV', cityBase: 21, city: 'NAV', country: 'TR', type: 'airport', nlabel: 'nevsehir kapadokya nevsehir turkey'},
  { icon: 'faPlaneDeparture', label: 'EWB New Bedford, United States, New Bedford Regional, EWB, new bedford regional new bedford massachusetts united states', key:'A_EWB', cityBase: 127, city: 'EWB', country: 'US', type: 'airport', nlabel: 'new bedford regional new bedford massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'TBI The Bight, Bahamas, New Bight, TBI, new bight the bight bahamas', key:'A_TBI', cityBase: 127, city: 'TBI', country: 'BS', type: 'airport', nlabel: 'new bight the bight bahamas'},
  { icon: 'faPlaneDeparture', label: 'CTS Sapporo, Japan, New Chitose, CTS, new chitose sapporo japan', key:'A_CTS', cityBase: 46, city: 'SPK', country: 'JP', type: 'airport', nlabel: 'new chitose sapporo japan'},
  { icon: 'faPlaneDeparture', label: 'NPL New Plymouth, New Zealand, New Plymouth, NPL, new plymouth new plymouth new zealand', key:'A_NPL', cityBase: 91, city: 'NPL', country: 'NZ', type: 'airport', nlabel: 'new plymouth new plymouth new zealand'},
  { icon: 'faPlaneDeparture', label: 'KNW New Stuyahok, United States, New Stuyahok, KNW, new stuyahok new stuyahok alaska united states', key:'A_KNW', cityBase: 127, city: 'KNW', country: 'US', type: 'airport', nlabel: 'new stuyahok new stuyahok alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TNE Tanegashima, Japan, New Tanegashima, TNE, new tanegashima tanegashima japan', key:'A_TNE', cityBase: 127, city: 'TNE', country: 'JP', type: 'airport', nlabel: 'new tanegashima tanegashima japan'},
  { icon: 'faPlaneDeparture', label: 'EWR New York City, United States, Newark Liberty International, EWR, newark liberty international new york city new york united states', key:'A_EWR', cityBase: 0, city: 'NYC', country: 'US', type: 'airport', nlabel: 'newark liberty international new york city new york united states'},
  { icon: 'faPlaneDeparture', label: 'NCL Newcastle upon Tyne, United Kingdom, Newcastle, NCL, newcastle newcastle upon tyne united kingdom', key:'A_NCL', cityBase: -992, city: 'NCL', country: 'GB', type: 'airport', nlabel: 'newcastle newcastle upon tyne united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NTL City of Newcastle, Australia, Newcastle, NTL, newcastle city of newcastle new south wales australia', key:'A_NTL', cityBase: 118, city: 'NTL', country: 'AU', type: 'airport', nlabel: 'newcastle city of newcastle new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'ZNE Newman, Australia, Newman, ZNE, newman newman western australia australia', key:'A_ZNE', cityBase: 127, city: 'ZNE', country: 'AU', type: 'airport', nlabel: 'newman newman western australia australia'},
  { icon: 'faPlaneDeparture', label: 'PHF Newport News, United States, Newport News/Williamsburg International, PHF, newport news williamsburg international newport news virginia united states', key:'A_PHF', cityBase: 33, city: 'PHF', country: 'US', type: 'airport', nlabel: 'newport news williamsburg international newport news virginia united states'},
  { icon: 'faPlaneDeparture', label: 'WWT Newtok, United States, Newtok, WWT, newtok newtok alaska united states', key:'A_WWT', cityBase: 127, city: 'WWT', country: 'US', type: 'airport', nlabel: 'newtok newtok alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NGL Ngala, South Africa, Ngala Airport, NGL, ngala airport ngala south africa', key:'A_NGL', cityBase: 127, city: 'NGL', country: 'ZA', type: 'airport', nlabel: 'ngala airport ngala south africa'},
  { icon: 'faPlaneDeparture', label: 'NGE Ngaoundéré, Cameroon, Ngaoundéré, NGE, ngaoundere ngaoundere cameroon', key:'A_NGE', cityBase: 127, city: 'NGE', country: 'CM', type: 'airport', nlabel: 'ngaoundere ngaoundere cameroon'},
  { icon: 'faPlaneDeparture', label: 'NGQ Ngari Prefecture, China, Ngari Gunsa, NGQ, ngari gunsa ngari prefecture china', key:'A_NGQ', cityBase: 66, city: 'NGQ', country: 'CN', type: 'airport', nlabel: 'ngari gunsa ngari prefecture china'},
  { icon: 'faPlaneDeparture', label: 'DPS Bali Denpasar, Indonesia, Ngurah Rai International, DPS, ngurah rai international denpasar indonesia', key:'A_DPS', cityBase: 12, city: 'DPS', country: 'ID', type: 'airport', nlabel: 'ngurah rai international denpasar indonesia'},
  { icon: 'faPlaneDeparture', label: 'IAG Niagara Falls, United States, Niagara Falls International, IAG, niagara falls international niagara falls new york united states', key:'A_IAG', cityBase: 39, city: 'IAG', country: 'US', type: 'airport', nlabel: 'niagara falls international niagara falls new york united states'},
  { icon: 'faPlaneDeparture', label: 'NIU Niau, French Polynesia, Niau, NIU, niau niau french polynesia', key:'A_NIU', cityBase: 127, city: 'NIU', country: 'PF', type: 'airport', nlabel: 'niau niau french polynesia'},
  { icon: 'faPlaneDeparture', label: 'NCE Nice, France, Nice Côte d Azur International, NCE, nice cote d azur international nice france', key:'A_NCE', cityBase: 5, city: 'NCE', country: 'FR', type: 'airport', nlabel: 'nice cote d azur international nice france'},
  { icon: 'faPlaneDeparture', label: 'NME Nightmute, United States, Nightmute, NME, nightmute nightmute alaska united states', key:'A_NME', cityBase: 127, city: 'NME', country: 'US', type: 'airport', nlabel: 'nightmute nightmute alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KIJ Niigata, Japan, Niigata, KIJ, niigata niigata japan', key:'A_KIJ', cityBase: 91, city: 'KIJ', country: 'JP', type: 'airport', nlabel: 'niigata niigata japan'},
  { icon: 'faPlaneDeparture', label: 'IKO Nikolski, United States, Nikolski Air Station, IKO, nikolski air station nikolski alaska united states', key:'A_IKO', cityBase: 127, city: 'IKO', country: 'US', type: 'airport', nlabel: 'nikolski air station nikolski alaska united states'},
  { icon: 'faPlaneDeparture', label: 'FNI Nîmes, France, Nîmes–Alès–Camargue–Cévennes, FNI, nimes ales camargue cevennes nimes france', key:'A_FNI', cityBase: 107, city: 'FNI', country: 'FR', type: 'airport', nlabel: 'nimes ales camargue cevennes nimes france'},
  { icon: 'faPlaneDeparture', label: 'NGB Ningbo, China, Ningbo Lishe International, NGB, ningbo lishe international ningbo china', key:'A_NGB', cityBase: 49, city: 'NGB', country: 'CN', type: 'airport', nlabel: 'ningbo lishe international ningbo china'},
  { icon: 'faPlaneDeparture', label: 'NLH Ninglang, China, Ninglang Luguhu, NLH, ninglang luguhu ninglang china', key:'A_NLH', cityBase: 69, city: 'NLH', country: 'CN', type: 'airport', nlabel: 'ninglang luguhu ninglang china'},
  { icon: 'faPlaneDeparture', label: 'MNL Manila, Philippines, Ninoy Aquino International, MNL, ninoy aquino international manila philippines', key:'A_MNL', cityBase: 3, city: 'MNL', country: 'PH', type: 'airport', nlabel: 'ninoy aquino international manila philippines'},
  { icon: 'faPlaneDeparture', label: 'INI Niš, Serbia, Niš Constantine the Great, INI, nis constantine the great nis serbia', key:'A_INI', cityBase: 83, city: 'INI', country: 'RS', type: 'airport', nlabel: 'nis constantine the great nis serbia'},
  { icon: 'faPlaneDeparture', label: 'IUE Alofi, Niue, Niue International, IUE, niue international alofi niue', key:'A_IUE', cityBase: 127, city: 'IUE', country: 'NU', type: 'airport', nlabel: 'niue international alofi niue'},
  { icon: 'faPlaneDeparture', label: 'NJC Nizhnevartovsk, Russia, Nizhnevartovsk, NJC, nizhnevartovsk nizhnevartovsk russia', key:'A_NJC', cityBase: 44, city: 'NJC', country: 'RU', type: 'airport', nlabel: 'nizhnevartovsk nizhnevartovsk russia'},
  { icon: 'faPlaneDeparture', label: 'JOM Njombe, Tanzania, Njombe Airport, JOM, njombe airport njombe tanzania', key:'A_JOM', cityBase: 127, city: 'null', country: 'TZ', type: 'airport', nlabel: 'njombe airport njombe tanzania'},
  { icon: 'faPlaneDeparture', label: 'ABV Abuja, Nigeria, Nnamdi Azikiwe International, ABV, nnamdi azikiwe international abuja nigeria', key:'A_ABV', cityBase: 45, city: 'ABV', country: 'NG', type: 'airport', nlabel: 'nnamdi azikiwe international abuja nigeria'},
  { icon: 'faPlaneDeparture', label: 'WTK Noatak, United States, Noatak, WTK, noatak noatak alaska united states', key:'A_WTK', cityBase: 127, city: 'WTK', country: 'US', type: 'airport', nlabel: 'noatak noatak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NGK Nogliki, Russia, Nogliki, NGK, nogliki nogliki russia', key:'A_NGK', cityBase: 127, city: 'NGK', country: 'RU', type: 'airport', nlabel: 'nogliki nogliki russia'},
  { icon: 'faPlaneDeparture', label: 'HAN Hanoi, Vietnam, Noi Bai International, HAN, noi bai international hanoi vietnam', key:'A_HAN', cityBase: 24, city: 'HAN', country: 'VN', type: 'airport', nlabel: 'noi bai international hanoi vietnam'},
  { icon: 'faPlaneDeparture', label: 'OME Nome, United States, Nome, OME, nome nome alaska united states', key:'A_OME', cityBase: 99, city: 'OME', country: 'US', type: 'airport', nlabel: 'nome nome alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NNL Nondalton, United States, Nondalton, NNL, nondalton nondalton alaska united states', key:'A_NNL', cityBase: 127, city: 'NNL', country: 'US', type: 'airport', nlabel: 'nondalton nondalton alaska united states'},
  { icon: 'faPlaneDeparture', label: 'DEX Yahukimo Regency, Indonesia, Nop Goliat Dekai, DEX, nop goliat dekai yahukimo regency indonesia', key:'A_DEX', cityBase: 127, city: 'DEX', country: 'ID', type: 'airport', nlabel: 'nop goliat dekai yahukimo regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'ORF Norfolk, United States, Norfolk International, ORF, norfolk international norfolk virginia united states', key:'A_ORF', cityBase: 11, city: 'ORF', country: 'US', type: 'airport', nlabel: 'norfolk international norfolk virginia united states'},
  { icon: 'faPlaneDeparture', label: 'NLK Burnt Pine, Norfolk Island, Norfolk Island, NLK, norfolk island burnt pine norfolk island', key:'A_NLK', cityBase: 127, city: 'NLK', country: 'NF', type: 'airport', nlabel: 'norfolk island burnt pine norfolk island'},
  { icon: 'faPlaneDeparture', label: 'KIN Kingston, Jamaica, Norman Manley International, KIN, norman manley international kingston jamaica', key:'A_KIN', cityBase: 22, city: 'KIN', country: 'JM', type: 'airport', nlabel: 'norman manley international kingston jamaica'},
  { icon: 'faPlaneDeparture', label: 'YVQ Norman Wells, Canada, Norman Wells, YVQ, norman wells norman wells northwest territories canada', key:'A_YVQ', cityBase: 127, city: 'YVQ', country: 'CA', type: 'airport', nlabel: 'norman wells norman wells northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'NTN Normanton, Australia, Normanton, NTN, normanton normanton queensland australia', key:'A_NTN', cityBase: 127, city: 'NTN', country: 'AU', type: 'airport', nlabel: 'normanton normanton queensland australia'},
  { icon: 'faPlaneDeparture', label: 'NRK Norrköping, Sweden, Norrköping, NRK, norrkoping norrkoping sweden', key:'A_NRK', cityBase: 127, city: 'NRK', country: 'SE', type: 'airport', nlabel: 'norrkoping norrkoping sweden'},
  { icon: 'faPlaneDeparture', label: 'NUS Norsup, Vanuatu, Norsup, NUS, norsup norsup vanuatu', key:'A_NUS', cityBase: 127, city: 'NUS', country: 'VU', type: 'airport', nlabel: 'norsup norsup vanuatu'},
  { icon: 'faPlaneDeparture', label: 'YYB North Bay, Canada, North Bay/Jack Garland, YYB, north bay jack garland north bay ontario canada', key:'A_YYB', cityBase: 119, city: 'YYB', country: 'CA', type: 'airport', nlabel: 'north bay jack garland north bay ontario canada'},
  { icon: 'faPlaneDeparture', label: 'CKB Clarksburg, United States, North Central West Virginia, CKB, north central west virginia clarksburg west virginia united states', key:'A_CKB', cityBase: 98, city: 'CKB', country: 'US', type: 'airport', nlabel: 'north central west virginia clarksburg west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'ELH North Eleuthera, Bahamas, North Eleuthera, ELH, north eleuthera north eleuthera bahamas', key:'A_ELH', cityBase: 80, city: 'ELH', country: 'BS', type: 'airport', nlabel: 'north eleuthera north eleuthera bahamas'},
  { icon: 'faPlaneDeparture', label: 'LBF North Platte, United States, North Platte Regional, LBF, north platte regional north platte nebraska united states', key:'A_LBF', cityBase: 105, city: 'LBF', country: 'US', type: 'airport', nlabel: 'north platte regional north platte nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'NRL North Ronaldsay, United Kingdom, North Ronaldsay, NRL, north ronaldsay north ronaldsay united kingdom', key:'A_NRL', cityBase: -873, city: 'NRL', country: 'GB', type: 'airport', nlabel: 'north ronaldsay north ronaldsay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'YNO North Spirit Lake, Canada, North Spirit Lake, YNO, north spirit lake north spirit lake ontario canada', key:'A_YNO', cityBase: 127, city: 'YNO', country: 'CA', type: 'airport', nlabel: 'north spirit lake north spirit lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'WWP Whale Pass, United States, North Whale Seaplane Base, WWP, north whale seaplane base whale pass alaska united states', key:'A_WWP', cityBase: 127, city: 'WWP', country: 'US', type: 'airport', nlabel: 'north whale seaplane base whale pass alaska united states'},
  { icon: 'faPlaneDeparture', label: 'UST St. Augustine, United States, Northeast Florida Regional, UST, northeast florida regional st augustine florida united states', key:'A_UST', cityBase: 109, city: 'UST', country: 'US', type: 'airport', nlabel: 'northeast florida regional st augustine florida united states'},
  { icon: 'faPlaneDeparture', label: 'PQI Presque Isle, United States, Northern Maine Regional Airport at Presque Isle, PQI, northern maine regional airport at presque isle presque isle maine united states', key:'A_PQI', cityBase: 102, city: 'PQI', country: 'US', type: 'airport', nlabel: 'northern maine regional airport at presque isle presque isle maine united states'},
  { icon: 'faPlaneDeparture', label: 'ABM Bamaga, Australia, Northern Peninsula, ABM, northern peninsula bamaga queensland australia', key:'A_ABM', cityBase: 127, city: 'ABM', country: 'AU', type: 'airport', nlabel: 'northern peninsula bamaga queensland australia'},
  { icon: 'faPlaneDeparture', label: 'MSL Muscle Shoals, United States, Northwest Alabama Regional, MSL, northwest alabama regional muscle shoals alabama united states', key:'A_MSL', cityBase: 127, city: 'MSL', country: 'US', type: 'airport', nlabel: 'northwest alabama regional muscle shoals alabama united states'},
  { icon: 'faPlaneDeparture', label: 'XNA Fayetteville, United States, Northwest Arkansas Regional, XNA, northwest arkansas regional fayetteville arkansas united states', key:'A_XNA', cityBase: 25, city: 'FYV', country: 'US', type: 'airport', nlabel: 'northwest arkansas regional fayetteville arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'ECP Panama City, United States, Northwest Florida Beaches International, ECP, northwest florida beaches international panama city florida united states', key:'A_ECP', cityBase: 30, city: 'ECP', country: 'US', type: 'airport', nlabel: 'northwest florida beaches international panama city florida united states'},
  { icon: 'faPlaneDeparture', label: 'YXT Terrace, Canada, Northwest Regional Airport Terrace-Kitimat, YXT, northwest regional airport terrace kitimat terrace british columbia canada', key:'A_YXT', cityBase: 127, city: 'YXT', country: 'CA', type: 'airport', nlabel: 'northwest regional airport terrace kitimat terrace british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'YNE Norway House, Canada, Norway House, YNE, norway house norway house manitoba canada', key:'A_YNE', cityBase: 115, city: 'YNE', country: 'CA', type: 'airport', nlabel: 'norway house norway house manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'NWI Norwich, United Kingdom, Norwich, NWI, norwich norwich united kingdom', key:'A_NWI', cityBase: -958, city: 'NWI', country: 'GB', type: 'airport', nlabel: 'norwich norwich united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NOB Nosara, Costa Rica, Nosara, NOB, nosara nosara costa rica', key:'A_NOB', cityBase: 127, city: 'NOB', country: 'CR', type: 'airport', nlabel: 'nosara nosara costa rica'},
  { icon: 'faPlaneDeparture', label: 'NSH Nowshahr, Iran, Noshahr, NSH, noshahr nowshahr iran', key:'A_NSH', cityBase: 127, city: 'NSH', country: 'IR', type: 'airport', nlabel: 'noshahr nowshahr iran'},
  { icon: 'faPlaneDeparture', label: 'NTQ Wajima, Japan, Noto, NTQ, noto wajima japan', key:'A_NTQ', cityBase: 127, city: 'NTQ', country: 'JP', type: 'airport', nlabel: 'noto wajima japan'},
  { icon: 'faPlaneDeparture', label: 'JBB Jember Regency, Indonesia, Notohadinegoro, JBB, notohadinegoro jember regency indonesia', key:'A_JBB', cityBase: 127, city: 'JBB', country: 'ID', type: 'airport', nlabel: 'notohadinegoro jember regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'NDB Nouadhibou, Mauritania, Nouadhibou International, NDB, nouadhibou international nouadhibou mauritania', key:'A_NDB', cityBase: 127, city: 'NDB', country: 'MR', type: 'airport', nlabel: 'nouadhibou international nouadhibou mauritania'},
  { icon: 'faPlaneDeparture', label: 'NKC Nouakchott, Mauritania, Nouakchott International, NKC, nouakchott international nouakchott mauritania', key:'A_NKC', cityBase: 68, city: 'NKC', country: 'MR', type: 'airport', nlabel: 'nouakchott international nouakchott mauritania'},
  { icon: 'faPlaneDeparture', label: 'GEA Nouméa, New Caledonia, Nouméa Magenta, GEA, noumea magenta noumea new caledonia', key:'A_GEA', cityBase: 127, city: 'NOU', country: 'NC', type: 'airport', nlabel: 'noumea magenta noumea new caledonia'},
  { icon: 'faPlaneDeparture', label: 'GHA Ghardaïa, Algeria, Noumérat – Moufdi Zakaria, GHA, noumerat moufdi zakaria ghardaia algeria', key:'A_GHA', cityBase: 127, city: 'GHA', country: 'DZ', type: 'airport', nlabel: 'noumerat moufdi zakaria ghardaia algeria'},
  { icon: 'faPlaneDeparture', label: 'NUX Novy Urengoy, Russia, Novy Urengoy, NUX, novy urengoy novy urengoy russia', key:'A_NUX', cityBase: 46, city: 'NUX', country: 'RU', type: 'airport', nlabel: 'novy urengoy novy urengoy russia'},
  { icon: 'faPlaneDeparture', label: 'NOJ Noyabrsk, Russia, Noyabrsk, NOJ, noyabrsk noyabrsk russia', key:'A_NOJ', cityBase: 48, city: 'NOJ', country: 'RU', type: 'airport', nlabel: 'noyabrsk noyabrsk russia'},
  { icon: 'faPlaneDeparture', label: 'NLD Nuevo Laredo, Mexico, Nuevo Laredo International, NLD, nuevo laredo international nuevo laredo mexico', key:'A_NLD', cityBase: 115, city: 'NLD', country: 'MX', type: 'airport', nlabel: 'nuevo laredo international nuevo laredo mexico'},
  { icon: 'faPlaneDeparture', label: 'NUI Nuiqsut, United States, Nuiqsut, NUI, nuiqsut nuiqsut alaska united states', key:'A_NUI', cityBase: 127, city: 'NUI', country: 'US', type: 'airport', nlabel: 'nuiqsut nuiqsut alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NHV Nuku Hiva, French Polynesia, Nuku Hiva, NHV, nuku hiva nuku hiva french polynesia', key:'A_NHV', cityBase: 127, city: 'NHV', country: 'PF', type: 'airport', nlabel: 'nuku hiva nuku hiva french polynesia'},
  { icon: 'faPlaneDeparture', label: 'NCU Nukus, Uzbekistan, Nukus, NCU, nukus nukus uzbekistan', key:'A_NCU', cityBase: 127, city: 'NCU', country: 'UZ', type: 'airport', nlabel: 'nukus nukus uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'NUL Nulato, United States, Nulato, NUL, nulato nulato alaska united states', key:'A_NUL', cityBase: 127, city: 'NUL', country: 'US', type: 'airport', nlabel: 'nulato nulato alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NUP Nunapitchuk, United States, Nunapitchuk, NUP, nunapitchuk nunapitchuk alaska united states', key:'A_NUP', cityBase: 127, city: 'NUP', country: 'US', type: 'airport', nlabel: 'nunapitchuk nunapitchuk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NNX Nunukan, Indonesia, Nunukan, NNX, nunukan nunukan indonesia', key:'A_NNX', cityBase: 127, city: 'NNX', country: 'ID', type: 'airport', nlabel: 'nunukan nunukan indonesia'},
  { icon: 'faPlaneDeparture', label: 'NUE Nuremberg, Germany, Nuremberg, NUE, nuremberg nuremberg germany', key:'A_NUE', cityBase: 1, city: 'NUE', country: 'DE', type: 'airport', nlabel: 'nuremberg nuremberg germany'},
  { icon: 'faPlaneDeparture', label: 'GZO Gizo, Solomon Islands, Nusatupe, GZO, nusatupe gizo solomon islands', key:'A_GZO', cityBase: 127, city: 'GZO', country: 'SB', type: 'airport', nlabel: 'nusatupe gizo solomon islands'},
  { icon: 'faPlaneDeparture', label: 'GOH Nuuk, Greenland, Nuuk, GOH, nuuk nuuk greenland', key:'A_GOH', cityBase: 62, city: 'GOH', country: 'GL', type: 'airport', nlabel: 'nuuk nuuk greenland'},
  { icon: 'faPlaneDeparture', label: 'NYA Nyagan, Russia, Nyagan, NYA, nyagan nyagan russia', key:'A_NYA', cityBase: 60, city: 'NYA', country: 'RU', type: 'airport', nlabel: 'nyagan nyagan russia'},
  { icon: 'faPlaneDeparture', label: 'UYL Nyala, Sudan, Nyala, UYL, nyala nyala sudan', key:'A_UYL', cityBase: 127, city: 'UYL', country: 'SD', type: 'airport', nlabel: 'nyala nyala sudan'},
  { icon: 'faPlaneDeparture', label: 'NYU Bagan, Myanmar (Burma), Nyaung U, NYU, nyaung u bagan myanmar (burma)', key:'A_NYU', cityBase: 103, city: 'NYU', country: 'MM', type: 'airport', nlabel: 'nyaung u bagan myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'LZY Nyingchi, China, Nyingchi Mainling, LZY, nyingchi mainling nyingchi china', key:'A_LZY', cityBase: 72, city: 'LZY', country: 'CN', type: 'airport', nlabel: 'nyingchi mainling nyingchi china'},
  { icon: 'faPlaneDeparture', label: 'NYR Nyurba, Russia, Nyurba, NYR, nyurba nyurba russia', key:'A_NYR', cityBase: 127, city: 'NYR', country: 'RU', type: 'airport', nlabel: 'nyurba nyurba russia'},
  { icon: 'faPlaneDeparture', label: 'ORD Chicago, United States, O Hare International, ORD, o hare international chicago illinois united states', key:'A_ORD', cityBase: 0, city: 'CHI', country: 'US', type: 'airport', nlabel: 'o hare international chicago illinois united states'},
  { icon: 'faPlaneDeparture', label: 'JNB Johannesburg, South Africa, O. R. Tambo International, JNB, o r tambo international johannesburg south africa', key:'A_JNB', cityBase: 22, city: 'JNB', country: 'ZA', type: 'airport', nlabel: 'o r tambo international johannesburg south africa'},
  { icon: 'faPlaneDeparture', label: 'OAK Oakland, United States, Oakland International, OAK, oakland international oakland california united states', key:'A_OAK', cityBase: 4, city: 'OAK', country: 'US', type: 'airport', nlabel: 'oakland international oakland california united states'},
  { icon: 'faPlaneDeparture', label: 'OAX Oaxaca, Mexico, Oaxaca International, OAX, oaxaca international oaxaca mexico', key:'A_OAX', cityBase: 36, city: 'OAX', country: 'MX', type: 'airport', nlabel: 'oaxaca international oaxaca mexico'},
  { icon: 'faPlaneDeparture', label: 'ONJ Odate, Japan, Odate–Noshiro, ONJ, odate noshiro odate japan', key:'A_ONJ', cityBase: 127, city: 'ONJ', country: 'JP', type: 'airport', nlabel: 'odate noshiro odate japan'},
  { icon: 'faPlaneDeparture', label: 'ODS Odessa, Ukraine, Odessa International, ODS, odessa international odessa ukraine', key:'A_ODS', cityBase: 31, city: 'ODS', country: 'UA', type: 'airport', nlabel: 'odessa international odessa ukraine'},
  { icon: 'faPlaneDeparture', label: 'KEO Odienné, Côte d’Ivoire, Odienne, KEO, odienne odienne cote d ivoire', key:'A_KEO', cityBase: 127, city: 'KEO', country: 'CI', type: 'airport', nlabel: 'odienne odienne cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'LAH Labuha, Indonesia, Oesman Sadik, LAH, oesman sadik labuha indonesia', key:'A_LAH', cityBase: 127, city: 'LAH', country: 'ID', type: 'airport', nlabel: 'oesman sadik labuha indonesia'},
  { icon: 'faPlaneDeparture', label: 'OGD Ogden, United States, Ogden-Hinckley, OGD, ogden hinckley ogden utah united states', key:'A_OGD', cityBase: 127, city: 'OGD', country: 'US', type: 'airport', nlabel: 'ogden hinckley ogden utah united states'},
  { icon: 'faPlaneDeparture', label: 'OGS Ogdensburg, United States, Ogdensburg International, OGS, ogdensburg international ogdensburg new york united states', key:'A_OGS', cityBase: 106, city: 'OGS', country: 'US', type: 'airport', nlabel: 'ogdensburg international ogdensburg new york united states'},
  { icon: 'faPlaneDeparture', label: 'OGL Georgetown, Guyana, Ogle, OGL, ogle georgetown guyana', key:'A_OGL', cityBase: 90, city: 'GEO', country: 'GY', type: 'airport', nlabel: 'ogle georgetown guyana'},
  { icon: 'faPlaneDeparture', label: 'GZT Gaziantep, Turkey, Oğuzeli, GZT, oguzeli gaziantep turkey', key:'A_GZT', cityBase: 14, city: 'GZT', country: 'TR', type: 'airport', nlabel: 'oguzeli gaziantep turkey'},
  { icon: 'faPlaneDeparture', label: 'OHD Ohrid, Macedonia (FYROM), Ohrid  St. Paul the Apostle , OHD, ohrid st paul the apostle ohrid macedonia (fyrom)', key:'A_OHD', cityBase: 24, city: 'OHD', country: 'MK', type: 'airport', nlabel: 'ohrid st paul the apostle ohrid macedonia (fyrom)'},
  { icon: 'faPlaneDeparture', label: 'OIT Oita, Japan, Oita, OIT, oita oita japan', key:'A_OIT', cityBase: 99, city: 'OIT', country: 'JP', type: 'airport', nlabel: 'oita oita japan'},
  { icon: 'faPlaneDeparture', label: 'OKD Sapporo, Japan, Okadama, OKD, okadama sapporo japan', key:'A_OKD', cityBase: 127, city: 'SPK', country: 'JP', type: 'airport', nlabel: 'okadama sapporo japan'},
  { icon: 'faPlaneDeparture', label: 'OKJ Okayama, Japan, Okayama, OKJ, okayama okayama japan', key:'A_OKJ', cityBase: 99, city: 'OKJ', country: 'JP', type: 'airport', nlabel: 'okayama okayama japan'},
  { icon: 'faPlaneDeparture', label: 'OHH Okha, Russia, Okha, OHH, okha okha russia', key:'A_OHH', cityBase: 127, city: 'OHH', country: 'RU', type: 'airport', nlabel: 'okha okha russia'},
  { icon: 'faPlaneDeparture', label: 'OHO Okhotsk, Russia, Okhotsk, OHO, okhotsk okhotsk russia', key:'A_OHO', cityBase: 127, city: 'OHO', country: 'RU', type: 'airport', nlabel: 'okhotsk okhotsk russia'},
  { icon: 'faPlaneDeparture', label: 'OKI Oki Islands, Japan, Oki, OKI, oki oki islands japan', key:'A_OKI', cityBase: 114, city: 'OKI', country: 'JP', type: 'airport', nlabel: 'oki oki islands japan'},
  { icon: 'faPlaneDeparture', label: 'OKL Oksibil, Indonesia, Oksibil, OKL, oksibil oksibil indonesia', key:'A_OKL', cityBase: 127, city: 'OKL', country: 'ID', type: 'airport', nlabel: 'oksibil oksibil indonesia'},
  { icon: 'faPlaneDeparture', label: 'OIR Okushiri, Japan, Okushiri, OIR, okushiri okushiri japan', key:'A_OIR', cityBase: 127, city: 'OIR', country: 'JP', type: 'airport', nlabel: 'okushiri okushiri japan'},
  { icon: 'faPlaneDeparture', label: 'EOH Medellín, Colombia, Olaya Herrera, EOH, olaya herrera medellin colombia', key:'A_EOH', cityBase: 83, city: 'MDE', country: 'CO', type: 'airport', nlabel: 'olaya herrera medellin colombia'},
  { icon: 'faPlaneDeparture', label: 'OLB Olbia, Italy, Olbia Costa Smeralda, OLB, olbia costa smeralda olbia italy', key:'A_OLB', cityBase: 10, city: 'OLB', country: 'IT', type: 'airport', nlabel: 'olbia costa smeralda olbia italy'},
  { icon: 'faPlaneDeparture', label: 'YOC Old Crow, Yukon, Canada, Old Crow, YOC, old crow old crow yukon territory canada', key:'A_YOC', cityBase: 127, city: 'YOC', country: 'CA', type: 'airport', nlabel: 'old crow old crow yukon territory canada'},
  { icon: 'faPlaneDeparture', label: 'OLH Old Harbor, United States, Old Harbor, OLH, old harbor old harbor alaska united states', key:'A_OLH', cityBase: 127, city: 'OLH', country: 'US', type: 'airport', nlabel: 'old harbor old harbor alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ONK Olenyok, Russia, Olenyok, ONK, olenyok olenyok russia', key:'A_ONK', cityBase: 127, city: 'ONK', country: 'RU', type: 'airport', nlabel: 'olenyok olenyok russia'},
  { icon: 'faPlaneDeparture', label: 'ULG Ölgii, Mongolia, Ölgii, ULG, olgii olgii mongolia', key:'A_ULG', cityBase: 127, city: 'ULG', country: 'MN', type: 'airport', nlabel: 'olgii olgii mongolia'},
  { icon: 'faPlaneDeparture', label: 'SZY Szymany, Szczytno County, Poland, Olsztyn-Mazury, SZY, olsztyn mazury szymany poland', key:'A_SZY', cityBase: 127, city: 'SZY', country: 'PL', type: 'airport', nlabel: 'olsztyn mazury szymany poland'},
  { icon: 'faPlaneDeparture', label: 'OLZ Olyokminsk, Russia, Olyokminsk, OLZ, olyokminsk olyokminsk russia', key:'A_OLZ', cityBase: 127, city: 'OLZ', country: 'RU', type: 'airport', nlabel: 'olyokminsk olyokminsk russia'},
  { icon: 'faPlaneDeparture', label: 'OMS Omsk, Russia, Omsk Tsentralny, OMS, omsk tsentralny omsk russia', key:'A_OMS', cityBase: 42, city: 'OMS', country: 'RU', type: 'airport', nlabel: 'omsk tsentralny omsk russia'},
  { icon: 'faPlaneDeparture', label: 'VPE Ondjiva, Angola, Ondjiva Pereira, VPE, ondjiva pereira ondjiva angola', key:'A_VPE', cityBase: 127, city: 'VPE', country: 'AO', type: 'airport', nlabel: 'ondjiva pereira ondjiva angola'},
  { icon: 'faPlaneDeparture', label: 'ONS Onslow, Australia, Onslow, ONS, onslow onslow western australia australia', key:'A_ONS', cityBase: 127, city: 'ONS', country: 'AU', type: 'airport', nlabel: 'onslow onslow western australia australia'},
  { icon: 'faPlaneDeparture', label: 'ONT Ontario, United States, Ontario International, ONT, ontario international ontario california united states', key:'A_ONT', cityBase: 15, city: 'ONT', country: 'US', type: 'airport', nlabel: 'ontario international ontario california united states'},
  { icon: 'faPlaneDeparture', label: 'OMR Oradea, Romania, Oradea International, OMR, oradea international oradea romania', key:'A_OMR', cityBase: 26, city: 'OMR', country: 'RO', type: 'airport', nlabel: 'oradea international oradea romania'},
  { icon: 'faPlaneDeparture', label: 'URA Oral, Kazakhstan, Oral Ak Zhol, URA, oral ak zhol oral kazakhstan', key:'A_URA', cityBase: 112, city: 'URA', country: 'KZ', type: 'airport', nlabel: 'oral ak zhol oral kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'ORN Oran, Algeria, Oran Es Sénia, ORN, oran es senia oran algeria', key:'A_ORN', cityBase: 87, city: 'ORN', country: 'DZ', type: 'airport', nlabel: 'oran es senia oran algeria'},
  { icon: 'faPlaneDeparture', label: 'OAG Orange, Australia, Orange, OAG, orange orange new south wales australia', key:'A_OAG', cityBase: 127, city: 'OAG', country: 'AU', type: 'airport', nlabel: 'orange orange new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'ORZ Orange Walk District, Belize, Orange Walk, ORZ, orange walk orange walk district belize', key:'A_ORZ', cityBase: 127, city: 'ORZ', country: 'BZ', type: 'airport', nlabel: 'orange walk orange walk district belize'},
  { icon: 'faPlaneDeparture', label: 'OMD Oranjemund, Namibia, Oranjemund, OMD, oranjemund oranjemund namibia', key:'A_OMD', cityBase: 127, city: 'OMD', country: 'NA', type: 'airport', nlabel: 'oranjemund oranjemund namibia'},
  { icon: 'faPlaneDeparture', label: 'DSN Ordos City, China, Ordos Ejin Horo, DSN, ordos ejin horo ordos city china', key:'A_DSN', cityBase: 78, city: 'DSN', country: 'CN', type: 'airport', nlabel: 'ordos ejin horo ordos city china'},
  { icon: 'faPlaneDeparture', label: 'OGU Giresun, Turkey, Ordu–Giresun, OGU, ordu giresun giresun turkey', key:'A_OGU', cityBase: 18, city: 'OGU', country: 'TR', type: 'airport', nlabel: 'ordu giresun giresun turkey'},
  { icon: 'faPlaneDeparture', label: 'ORB Örebro, Sweden, Örebro, ORB, orebro orebro sweden', key:'A_ORB', cityBase: 127, city: 'ORB', country: 'SE', type: 'airport', nlabel: 'orebro orebro sweden'},
  { icon: 'faPlaneDeparture', label: 'REN Orenburg, Russia, Orenburg Tsentralny, REN, orenburg tsentralny orenburg russia', key:'A_REN', cityBase: 44, city: 'REN', country: 'RU', type: 'airport', nlabel: 'orenburg tsentralny orenburg russia'},
  { icon: 'faPlaneDeparture', label: 'MOA Moa, Cuba, Orestes Acosta, MOA, orestes acosta moa cuba', key:'A_MOA', cityBase: 127, city: 'MOA', country: 'CU', type: 'airport', nlabel: 'orestes acosta moa cuba'},
  { icon: 'faPlaneDeparture', label: 'OLA Ørland, Norway, Ørland Main Air Station, OLA, orland main air station orland norway', key:'A_OLA', cityBase: 127, city: 'OLA', country: 'NO', type: 'airport', nlabel: 'orland main air station orland norway'},
  { icon: 'faPlaneDeparture', label: 'MCO Orlando, United States, Orlando International, MCO, orlando international orlando florida united states', key:'A_MCO', cityBase: 0, city: 'ORL', country: 'US', type: 'airport', nlabel: 'orlando international orlando florida united states'},
  { icon: 'faPlaneDeparture', label: 'MLB Melbourne, United States, Orlando Melbourne International, MLB, orlando melbourne international melbourne florida united states', key:'A_MLB', cityBase: 58, city: 'MLB', country: 'US', type: 'airport', nlabel: 'orlando melbourne international melbourne florida united states'},
  { icon: 'faPlaneDeparture', label: 'SFB Orlando, United States, Orlando Sanford International, SFB, orlando sanford international orlando florida united states', key:'A_SFB', cityBase: 26, city: 'ORL', country: 'US', type: 'airport', nlabel: 'orlando sanford international orlando florida united states'},
  { icon: 'faPlaneDeparture', label: 'OMC Ormoc, Philippines, Ormoc, OMC, ormoc ormoc philippines', key:'A_OMC', cityBase: 127, city: 'OMC', country: 'PH', type: 'airport', nlabel: 'ormoc ormoc philippines'},
  { icon: 'faPlaneDeparture', label: 'OER Örnsköldsvik, Sweden, Örnsköldsvik, OER, ornskoldsvik ornskoldsvik sweden', key:'A_OER', cityBase: 98, city: 'OER', country: 'SE', type: 'airport', nlabel: 'ornskoldsvik ornskoldsvik sweden'},
  { icon: 'faPlaneDeparture', label: 'OSW Orsk, Russia, Orsk, OSW, orsk orsk russia', key:'A_OSW', cityBase: 52, city: 'OSW', country: 'RU', type: 'airport', nlabel: 'orsk orsk russia'},
  { icon: 'faPlaneDeparture', label: 'HOV Volda, Norway, Ørsta–Volda Airport, Hovden, HOV, orsta volda airport volda norway', key:'A_HOV', cityBase: 127, city: 'HOV', country: 'NO', type: 'airport', nlabel: 'orsta volda airport volda norway'},
  { icon: 'faPlaneDeparture', label: 'OSS Osh, Kyrgyzstan, Osh, OSS, osh osh kyrgyzstan', key:'A_OSS', cityBase: 55, city: 'OSS', country: 'KG', type: 'airport', nlabel: 'osh osh kyrgyzstan'},
  { icon: 'faPlaneDeparture', label: 'OSI Osijek, Croatia, Osijek, OSI, osijek osijek croatia', key:'A_OSI', cityBase: 30, city: 'OSI', country: 'HR', type: 'airport', nlabel: 'osijek osijek croatia'},
  { icon: 'faPlaneDeparture', label: 'UKK Oskemen, Kazakhstan, Oskemen, UKK, oskemen oskemen kazakhstan', key:'A_UKK', cityBase: 127, city: 'UKK', country: 'KZ', type: 'airport', nlabel: 'oskemen oskemen kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'OSL Oslo, Norway, Oslo Airport, Gardermoen, OSL, oslo airport oslo norway', key:'A_OSL', cityBase: 21, city: 'OSL', country: 'NO', type: 'airport', nlabel: 'oslo airport oslo norway'},
  { icon: 'faPlaneDeparture', label: 'ZYL Sylhet, Bangladesh, Osmani International, ZYL, osmani international sylhet bangladesh', key:'A_ZYL', cityBase: 96, city: 'ZYL', country: 'BD', type: 'airport', nlabel: 'osmani international sylhet bangladesh'},
  { icon: 'faPlaneDeparture', label: 'OST Ostend, Belgium, Ostend-Bruges International, OST, ostend bruges international ostend belgium', key:'A_OST', cityBase: 92, city: 'OST', country: 'BE', type: 'airport', nlabel: 'ostend bruges international ostend belgium'},
  { icon: 'faPlaneDeparture', label: 'OXB Bissau, Guinea-Bissau, Osvaldo Vieira International, OXB, osvaldo vieira international bissau guinea bissau', key:'A_OXB', cityBase: 92, city: 'OXB', country: 'GW', type: 'airport', nlabel: 'osvaldo vieira international bissau guinea bissau'},
  { icon: 'faPlaneDeparture', label: 'YOW Ottawa, Canada, Ottawa Macdonald–Cartier International, YOW, ottawa macdonald cartier international ottawa ontario canada', key:'A_YOW', cityBase: 29, city: 'YOW', country: 'CA', type: 'airport', nlabel: 'ottawa macdonald cartier international ottawa ontario canada'},
  { icon: 'faPlaneDeparture', label: 'OUA Ouagadougou, Burkina Faso, Ouagadougou, OUA, ouagadougou ouagadougou burkina faso', key:'A_OUA', cityBase: 59, city: 'OUA', country: 'BF', type: 'airport', nlabel: 'ouagadougou ouagadougou burkina faso'},
  { icon: 'faPlaneDeparture', label: 'LIF Lifou Island, New Caledonia, Ouanaham, LIF, ouanaham lifou island new caledonia', key:'A_LIF', cityBase: 127, city: 'LIF', country: 'NC', type: 'airport', nlabel: 'ouanaham lifou island new caledonia'},
  { icon: 'faPlaneDeparture', label: 'OFI Ouango-Fitini, Côte d’Ivoire, Ouango Fitini, OFI, ouango fitini ouango fitini cote d ivoire', key:'A_OFI', cityBase: 127, city: 'OFI', country: 'CI', type: 'airport', nlabel: 'ouango fitini ouango fitini cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'AJN Anjouan, Comoros, Ouani, AJN, ouani anjouan comoros', key:'A_AJN', cityBase: 127, city: 'AJN', country: 'KM', type: 'airport', nlabel: 'ouani anjouan comoros'},
  { icon: 'faPlaneDeparture', label: 'OZZ Ouarzazate, Morocco, Ouarzazate, OZZ, ouarzazate ouarzazate morocco', key:'A_OZZ', cityBase: 127, city: 'OZZ', country: 'MA', type: 'airport', nlabel: 'ouarzazate ouarzazate morocco'},
  { icon: 'faPlaneDeparture', label: 'ODY Muang Xay, Laos, Oudomsay, ODY, oudomsay muang xay laos', key:'A_ODY', cityBase: 127, city: 'ODY', country: 'LA', type: 'airport', nlabel: 'oudomsay muang xay laos'},
  { icon: 'faPlaneDeparture', label: 'HME Hassi Messaoud, Algeria, Oued Irara–Krim Belkacem, HME, oued irara krim belkacem hassi messaoud algeria', key:'A_HME', cityBase: 127, city: 'HME', country: 'DZ', type: 'airport', nlabel: 'oued irara krim belkacem hassi messaoud algeria'},
  { icon: 'faPlaneDeparture', label: 'OUL Oulu, Finland, Oulu, OUL, oulu oulu finland', key:'A_OUL', cityBase: 89, city: 'OUL', country: 'FI', type: 'airport', nlabel: 'oulu oulu finland'},
  { icon: 'faPlaneDeparture', label: 'UVE Ouvéa Island, New Caledonia, Ouvéa, UVE, ouvea ouvea island new caledonia', key:'A_UVE', cityBase: 127, city: 'UVE', country: 'NC', type: 'airport', nlabel: 'ouvea ouvea island new caledonia'},
  { icon: 'faPlaneDeparture', label: 'KOZ Ouzinkie, United States, Ouzinkie, KOZ, ouzinkie ouzinkie alaska united states', key:'A_KOZ', cityBase: 127, city: 'KOZ', country: 'US', type: 'airport', nlabel: 'ouzinkie ouzinkie alaska united states'},
  { icon: 'faPlaneDeparture', label: 'VDA Ovda, Israel, Ovda, VDA, ovda eilat israel', key:'A_VDA', cityBase: 22, city: 'VDA', country: 'IL', type: 'airport', nlabel: 'ovda eilat israel'},
  { icon: 'faPlaneDeparture', label: 'GCM Grand Cayman, Cayman Islands, Owen Roberts International, GCM, owen roberts international grand cayman cayman islands', key:'A_GCM', cityBase: 27, city: 'GCM', country: 'KY', type: 'airport', nlabel: 'owen roberts international grand cayman cayman islands'},
  { icon: 'faPlaneDeparture', label: 'OWB Owensboro, United States, Owensboro–Daviess County Regional, OWB, owensboro daviess county regional owensboro kentucky united states', key:'A_OWB', cityBase: 123, city: 'OWB', country: 'US', type: 'airport', nlabel: 'owensboro daviess county regional owensboro kentucky united states'},
  { icon: 'faPlaneDeparture', label: 'YOH Oxford House, Manitoba, Canada, Oxford House, YOH, oxford house oxford house manitoba canada', key:'A_YOH', cityBase: 127, city: 'YOH', country: 'CA', type: 'airport', nlabel: 'oxford house oxford house manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'PBJ Paama, Vanuatu, Paama, PBJ, paama paama vanuatu', key:'A_PBJ', cityBase: 127, city: 'PBJ', country: 'VU', type: 'airport', nlabel: 'paama paama vanuatu'},
  { icon: 'faPlaneDeparture', label: 'JFR Paamiut, Greenland, Paamiut, JFR, paamiut paamiut greenland', key:'A_JFR', cityBase: 127, city: 'JFR', country: 'GL', type: 'airport', nlabel: 'paamiut paamiut greenland'},
  { icon: 'faPlaneDeparture', label: 'PAD Paderborn, Germany, Paderborn Lippstadt, PAD, paderborn lippstadt paderborn germany', key:'A_PAD', cityBase: 7, city: 'PAD', country: 'DE', type: 'airport', nlabel: 'paderborn lippstadt paderborn germany'},
  { icon: 'faPlaneDeparture', label: 'PEM Puerto Maldonado, Peru, Padre Aldamiz International, PEM, padre aldamiz international puerto maldonado peru', key:'A_PEM', cityBase: 93, city: 'PEM', country: 'PE', type: 'airport', nlabel: 'padre aldamiz international puerto maldonado peru'},
  { icon: 'faPlaneDeparture', label: 'PAG Pagadian, Philippines, Pagadian, PAG, pagadian pagadian philippines', key:'A_PAG', cityBase: 111, city: 'PAG', country: 'PH', type: 'airport', nlabel: 'pagadian pagadian philippines'},
  { icon: 'faPlaneDeparture', label: 'PGA Page, United States, Page Municipal, PGA, page municipal page arizona united states', key:'A_PGA', cityBase: 127, city: 'PGA', country: 'US', type: 'airport', nlabel: 'page municipal page arizona united states'},
  { icon: 'faPlaneDeparture', label: 'PPG Pago Pago, United States, Pago Pago International, PPG, pago pago international pago pago american samoa united states', key:'A_PPG', cityBase: 74, city: 'PPG', country: 'US', type: 'airport', nlabel: 'pago pago international pago pago american samoa united states'},
  { icon: 'faPlaneDeparture', label: 'PYY Pai, Thailand, Pai, PYY, pai pai thailand', key:'A_PYY', cityBase: 127, city: 'PYY', country: 'TH', type: 'airport', nlabel: 'pai pai thailand'},
  { icon: 'faPlaneDeparture', label: 'PKZ Pakse, Laos, Pakse International, PKZ, pakse international pakse laos', key:'A_PKZ', cityBase: 127, city: 'PKZ', country: 'LA', type: 'airport', nlabel: 'pakse international pakse laos'},
  { icon: 'faPlaneDeparture', label: 'PLQ Palanga, Lithuania, Palanga International, PLQ, palanga international palanga lithuania', key:'A_PLQ', cityBase: 80, city: 'PLQ', country: 'LT', type: 'airport', nlabel: 'palanga international palanga lithuania'},
  { icon: 'faPlaneDeparture', label: 'PQM Palenque, Mexico, Palenque International, PQM, palenque international palenque mexico', key:'A_PQM', cityBase: 57, city: 'PQM', country: 'MX', type: 'airport', nlabel: 'palenque international palenque mexico'},
  { icon: 'faPlaneDeparture', label: 'PBI West Palm Beach, United States, Palm Beach International, PBI, palm beach international west palm beach florida united states', key:'A_PBI', cityBase: 11, city: 'PBI', country: 'US', type: 'airport', nlabel: 'palm beach international west palm beach florida united states'},
  { icon: 'faPlaneDeparture', label: 'PSP Palm Springs, United States, Palm Springs International, PSP, palm springs international palm springs california united states', key:'A_PSP', cityBase: 22, city: 'PSP', country: 'US', type: 'airport', nlabel: 'palm springs international palm springs california united states'},
  { icon: 'faPlaneDeparture', label: 'PMI Palma, Majorca, Spain, Palma de Mallorca, PMI, palma de mallorca palma spain', key:'A_PMI', cityBase: 5, city: 'PMI', country: 'ES', type: 'airport', nlabel: 'palma de mallorca palma spain'},
  { icon: 'faPlaneDeparture', label: 'PMZ Palmar Sur, Costa Rica, Palmar Sur, PMZ, palmar sur palmar sur costa rica', key:'A_PMZ', cityBase: 127, city: 'PMZ', country: 'CR', type: 'airport', nlabel: 'palmar sur palmar sur costa rica'},
  { icon: 'faPlaneDeparture', label: 'PMW Palmas, Brazil, Palmas, PMW, palmas palmas state of tocantins brazil', key:'A_PMW', cityBase: 116, city: 'PMW', country: 'BR', type: 'airport', nlabel: 'palmas palmas state of tocantins brazil'},
  { icon: 'faPlaneDeparture', label: 'PMR Palmerston North, New Zealand, Palmerston North, PMR, palmerston north palmerston north new zealand', key:'A_PMR', cityBase: 114, city: 'PMR', country: 'NZ', type: 'airport', nlabel: 'palmerston north palmerston north new zealand'},
  { icon: 'faPlaneDeparture', label: 'BGA Bucaramanga, Colombia, Palonegro International, BGA, palonegro international bucaramanga colombia', key:'A_BGA', cityBase: 52, city: 'BGA', country: 'CO', type: 'airport', nlabel: 'palonegro international bucaramanga colombia'},
  { icon: 'faPlaneDeparture', label: 'LLO Palopo, Indonesia, Palopo Lagaligo, LLO, palopo lagaligo palopo indonesia', key:'A_LLO', cityBase: 127, city: 'LLO', country: 'ID', type: 'airport', nlabel: 'palopo lagaligo palopo indonesia'},
  { icon: 'faPlaneDeparture', label: 'PNA Pamplona, Spain, Pamplona, PNA, pamplona pamplona spain', key:'A_PNA', cityBase: 16, city: 'PNA', country: 'ES', type: 'airport', nlabel: 'pamplona pamplona spain'},
  { icon: 'faPlaneDeparture', label: 'EAT Wenatchee, United States, Pangborn Memorial, EAT, pangborn memorial wenatchee washington united states', key:'A_EAT', cityBase: 79, city: 'EAT', country: 'US', type: 'airport', nlabel: 'pangborn memorial wenatchee washington united states'},
  { icon: 'faPlaneDeparture', label: 'YXP Pangnirtung, Canada, Pangnirtung, YXP, pangnirtung pangnirtung nunavut canada', key:'A_YXP', cityBase: 127, city: 'YXP', country: 'CA', type: 'airport', nlabel: 'pangnirtung pangnirtung nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'PSU Putussibau, Indonesia, Pangsuma, PSU, pangsuma putussibau indonesia', key:'A_PSU', cityBase: 127, city: 'PSU', country: 'ID', type: 'airport', nlabel: 'pangsuma putussibau indonesia'},
  { icon: 'faPlaneDeparture', label: 'PJG Panjgur, Pakistan, Panjgur Airport, PJG, panjgur airport panjgur pakistan', key:'A_PJG', cityBase: 127, city: 'PJG', country: 'PK', type: 'airport', nlabel: 'panjgur airport panjgur pakistan'},
  { icon: 'faPlaneDeparture', label: 'PNL Pantelleria, Italy, Pantelleria, PNL, pantelleria pantelleria italy', key:'A_PNL', cityBase: 22, city: 'PNL', country: 'IT', type: 'airport', nlabel: 'pantelleria pantelleria italy'},
  { icon: 'faPlaneDeparture', label: 'PGH Pantnagar, India, Pantnagar, PGH, pantnagar pantnagar india', key:'A_PGH', cityBase: 119, city: 'PGH', country: 'IN', type: 'airport', nlabel: 'pantnagar pantnagar india'},
  { icon: 'faPlaneDeparture', label: 'PZI Panzhihua, China, Panzhihua Bao anying, PZI, panzhihua bao anying panzhihua china', key:'A_PZI', cityBase: 71, city: 'PZI', country: 'CN', type: 'airport', nlabel: 'panzhihua bao anying panzhihua china'},
  { icon: 'faPlaneDeparture', label: 'PPW Papa Westray, United Kingdom, Papa Westray, PPW, papa westray papa westray united kingdom', key:'A_PPW', cityBase: -873, city: 'PPW', country: 'GB', type: 'airport', nlabel: 'papa westray papa westray united kingdom'},
  { icon: 'faPlaneDeparture', label: 'PFO Paphos, Cyprus, Paphos International, PFO, paphos international paphos cyprus', key:'A_PFO', cityBase: 10, city: 'PFO', country: 'CY', type: 'airport', nlabel: 'paphos international paphos cyprus'},
  { icon: 'faPlaneDeparture', label: 'PBO Paraburdoo, Australia, Paraburdoo, PBO, paraburdoo paraburdoo western australia australia', key:'A_PBO', cityBase: 127, city: 'PBO', country: 'AU', type: 'airport', nlabel: 'paraburdoo paraburdoo western australia australia'},
  { icon: 'faPlaneDeparture', label: 'PRS Parasi, Solomon Islands, Parasi Airport, PRS, parasi airport parasi solomon islands', key:'A_PRS', cityBase: 127, city: 'null', country: 'SB', type: 'airport', nlabel: 'parasi airport parasi solomon islands'},
  { icon: 'faPlaneDeparture', label: 'PED Pardubice, Czechia, Pardubice, PED, pardubice pardubice czechia', key:'A_PED', cityBase: 30, city: 'PED', country: 'CZ', type: 'airport', nlabel: 'pardubice pardubice czechia'},
  { icon: 'faPlaneDeparture', label: 'PIN Parintins, Brazil, Parintins, PIN, parintins parintins state of amazonas brazil', key:'A_PIN', cityBase: 127, city: 'PIN', country: 'BR', type: 'airport', nlabel: 'parintins parintins state of amazonas brazil'},
  { icon: 'faPlaneDeparture', label: 'ORY Paris, France, Paris Orly, ORY, paris orly paris france', key:'A_ORY', cityBase: 16, city: 'PAR', country: 'FR', type: 'airport', nlabel: 'paris orly paris france'},
  { icon: 'faPlaneDeparture', label: 'LBG Paris, France, Paris–Le Bourget, LBG, paris le bourget paris france', key:'A_LBG', cityBase: 112, city: 'PAR', country: 'FR', type: 'airport', nlabel: 'paris le bourget paris france'},
  { icon: 'faPlaneDeparture', label: 'PKE Parkes, Australia, Parkes, PKE, parkes parkes new south wales australia', key:'A_PKE', cityBase: 127, city: 'PKE', country: 'AU', type: 'airport', nlabel: 'parkes parkes new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'PMF Parma, Italy, Parma, PMF, parma parma italy', key:'A_PMF', cityBase: 33, city: 'PMF', country: 'IT', type: 'airport', nlabel: 'parma parma italy'},
  { icon: 'faPlaneDeparture', label: 'PHB Parnaíba, Brazil, Parnaíba-Prefeito Dr. João Silva Filho International, PHB, parnaiba prefeito dr joao silva filho international parnaiba brazil', key:'A_PHB', cityBase: 127, city: 'PHB', country: 'BR', type: 'airport', nlabel: 'parnaiba prefeito dr joao silva filho international parnaiba brazil'},
  { icon: 'faPlaneDeparture', label: 'PBH Paro, Bhutan, Paro, PBH, paro paro bhutan', key:'A_PBH', cityBase: 105, city: 'PBH', country: 'BT', type: 'airport', nlabel: 'paro paro bhutan'},
  { icon: 'faPlaneDeparture', label: 'PAS Parikia, Greece, Paros National, PAS, paros national parikia greece', key:'A_PAS', cityBase: 21, city: 'PAS', country: 'GR', type: 'airport', nlabel: 'paros national parikia greece'},
  { icon: 'faPlaneDeparture', label: 'PFQ Parsabad, Iran, Parsabade Moghan Airport, PFQ, parsabade moghan airport parsabad iran', key:'A_PFQ', cityBase: 127, city: 'null', country: 'IR', type: 'airport', nlabel: 'parsabade moghan airport parsabad iran'},
  { icon: 'faPlaneDeparture', label: 'IXT Pasighat, India, Pasighat Airport, IXT, pasighat airport pasighat india', key:'A_IXT', cityBase: 127, city: 'null', country: 'IN', type: 'airport', nlabel: 'pasighat airport pasighat india'},
  { icon: 'faPlaneDeparture', label: 'AMQ Ambon, Maluku, Indonesia, Pattimura, AMQ, pattimura ambon indonesia', key:'A_AMQ', cityBase: 112, city: 'AMQ', country: 'ID', type: 'airport', nlabel: 'pattimura ambon indonesia'},
  { icon: 'faPlaneDeparture', label: 'PUF Pau, Pyrénées-Atlantiques, France, Pau Pyrénées, PUF, pau pyrenees pau france', key:'A_PUF', cityBase: 60, city: 'PUF', country: 'FR', type: 'airport', nlabel: 'pau pyrenees pau france'},
  { icon: 'faPlaneDeparture', label: 'PAV Paulo Afonso, Brazil, Paulo Afonso, PAV, paulo afonso paulo afonso state of bahia brazil', key:'A_PAV', cityBase: 127, city: 'PAV', country: 'BR', type: 'airport', nlabel: 'paulo afonso paulo afonso state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'PWQ Pavlodar, Kazakhstan, Pavlodar, PWQ, pavlodar pavlodar kazakhstan', key:'A_PWQ', cityBase: 127, city: 'PWQ', country: 'KZ', type: 'airport', nlabel: 'pavlodar pavlodar kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'YPO Peawanuck, Canada, Peawanuck, YPO, peawanuck peawanuck ontario canada', key:'A_YPO', cityBase: 127, city: 'YPO', country: 'CA', type: 'airport', nlabel: 'peawanuck peawanuck ontario canada'},
  { icon: 'faPlaneDeparture', label: 'PEX Pechora, Russia, Pechora, PEX, pechora pechora russia', key:'A_PEX', cityBase: 127, city: 'PEX', country: 'RU', type: 'airport', nlabel: 'pechora pechora russia'},
  { icon: 'faPlaneDeparture', label: 'PDM Pedasi, Panama, Pedasí, PDM, pedasi pedasi panama', key:'A_PDM', cityBase: 127, city: 'PDM', country: 'PA', type: 'airport', nlabel: 'pedasi pedasi panama'},
  { icon: 'faPlaneDeparture', label: 'PDB Pedro Bay, United States, Pedro Bay, PDB, pedro bay pedro bay alaska united states', key:'A_PDB', cityBase: 127, city: 'PDB', country: 'US', type: 'airport', nlabel: 'pedro bay pedro bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PEC Pelican, United States, Pelican Sea Plane Base, PEC, pelican sea plane base pelican alaska united states', key:'A_PEC', cityBase: 127, city: 'PEC', country: 'US', type: 'airport', nlabel: 'pelican sea plane base pelican alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PLN Pellston, United States, Pellston Regional, PLN, pellston regional pellston michigan united states', key:'A_PLN', cityBase: 92, city: 'PLN', country: 'US', type: 'airport', nlabel: 'pellston regional pellston michigan united states'},
  { icon: 'faPlaneDeparture', label: 'PET Pelotas, Brazil, Pelotas International, PET, pelotas international pelotas state of rio grande do sul brazil', key:'A_PET', cityBase: 127, city: 'PET', country: 'BR', type: 'airport', nlabel: 'pelotas international pelotas state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'PMA Pemba Island, Tanzania, Pemba, PMA, pemba pemba island tanzania', key:'A_PMA', cityBase: 127, city: 'PMA', country: 'TZ', type: 'airport', nlabel: 'pemba pemba island tanzania'},
  { icon: 'faPlaneDeparture', label: 'POL Pemba, Mozambique, Pemba, POL, pemba pemba mozambique', key:'A_POL', cityBase: 127, city: 'POL', country: 'MZ', type: 'airport', nlabel: 'pemba pemba mozambique'},
  { icon: 'faPlaneDeparture', label: 'PEN Penang, Malaysia, Penang International, PEN, penang international penang malaysia', key:'A_PEN', cityBase: 30, city: 'PEN', country: 'MY', type: 'airport', nlabel: 'penang international penang malaysia'},
  { icon: 'faPlaneDeparture', label: 'PNS Pensacola, United States, Pensacola International, PNS, pensacola international pensacola florida united states', key:'A_PNS', cityBase: 17, city: 'PNS', country: 'US', type: 'airport', nlabel: 'pensacola international pensacola florida united states'},
  { icon: 'faPlaneDeparture', label: 'YYF Penticton, Canada, Penticton Regional, YYF, penticton regional penticton british columbia canada', key:'A_YYF', cityBase: 120, city: 'YYF', country: 'CA', type: 'airport', nlabel: 'penticton regional penticton british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'PEZ Penza, Russia, Penza, PEZ, penza penza russia', key:'A_PEZ', cityBase: 47, city: 'PEZ', country: 'RU', type: 'airport', nlabel: 'penza penza russia'},
  { icon: 'faPlaneDeparture', label: 'IBE Ibagué, Colombia, Perales, IBE, perales ibague colombia', key:'A_IBE', cityBase: 127, city: 'IBE', country: 'CO', type: 'airport', nlabel: 'perales ibague colombia'},
  { icon: 'faPlaneDeparture', label: 'PGX Périgueux, France, Périgueux Bassillac, PGX, perigueux bassillac perigueux france', key:'A_PGX', cityBase: 127, city: 'PGX', country: 'FR', type: 'airport', nlabel: 'perigueux bassillac perigueux france'},
  { icon: 'faPlaneDeparture', label: 'PEE Perm, Russia, Perm International, PEE, perm international perm russia', key:'A_PEE', cityBase: 45, city: 'PEE', country: 'RU', type: 'airport', nlabel: 'perm international perm russia'},
  { icon: 'faPlaneDeparture', label: 'PGF Perpignan, France, Perpignan–Rivesaltes, PGF, perpignan rivesaltes perpignan france', key:'A_PGF', cityBase: 62, city: 'PGF', country: 'FR', type: 'airport', nlabel: 'perpignan rivesaltes perpignan france'},
  { icon: 'faPlaneDeparture', label: 'KPV Perryville, United States, Perryville, KPV, perryville perryville alaska united states', key:'A_KPV', cityBase: 127, city: 'KPV', country: 'US', type: 'airport', nlabel: 'perryville perryville alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PGU Asaluyeh, Iran, Persian Gulf, PGU, persian gulf asaluyeh iran', key:'A_PGU', cityBase: 127, city: 'PGU', country: 'IR', type: 'airport', nlabel: 'persian gulf asaluyeh iran'},
  { icon: 'faPlaneDeparture', label: 'PER Perth, Australia, Perth, PER, perth perth western australia australia', key:'A_PER', cityBase: 36, city: 'PER', country: 'AU', type: 'airport', nlabel: 'perth perth western australia australia'},
  { icon: 'faPlaneDeparture', label: 'PSG Petersburg, United States, Petersburg James A. Johnson, PSG, petersburg james a johnson petersburg alaska united states', key:'A_PSG', cityBase: 116, city: 'PSG', country: 'US', type: 'airport', nlabel: 'petersburg james a johnson petersburg alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PNZ Petrolina, Brazil, Petrolina, PNZ, petrolina petrolina state of pernambuco brazil', key:'A_PNZ', cityBase: 127, city: 'PNZ', country: 'BR', type: 'airport', nlabel: 'petrolina petrolina state of pernambuco brazil'},
  { icon: 'faPlaneDeparture', label: 'PPK Petropavl, Kazakhstan, Petropavl, PPK, petropavl petropavl kazakhstan', key:'A_PPK', cityBase: 127, city: 'PPK', country: 'KZ', type: 'airport', nlabel: 'petropavl petropavl kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'PKC Petropavlovsk-Kamchatsky, Russia, Petropavlovsk-Kamchatsky, PKC, petropavlovsk kamchatsky petropavlovsk kamchatsky russia', key:'A_PKC', cityBase: 46, city: 'PKC', country: 'RU', type: 'airport', nlabel: 'petropavlovsk kamchatsky petropavlovsk kamchatsky russia'},
  { icon: 'faPlaneDeparture', label: 'PES Petrozavodsk, Russia, Petrozavodsk, PES, petrozavodsk petrozavodsk russia', key:'A_PES', cityBase: 55, city: 'PES', country: 'RU', type: 'airport', nlabel: 'petrozavodsk petrozavodsk russia'},
  { icon: 'faPlaneDeparture', label: 'PPL Phaplu, Nepal, Phaplu Airport, PPL, phaplu airport phaplu nepal', key:'A_PPL', cityBase: 127, city: 'null', country: 'NP', type: 'airport', nlabel: 'phaplu airport phaplu nepal'},
  { icon: 'faPlaneDeparture', label: 'PHL Philadelphia, United States, Philadelphia International, PHL, philadelphia international philadelphia pennsylvania united states', key:'A_PHL', cityBase: 0, city: 'PHL', country: 'US', type: 'airport', nlabel: 'philadelphia international philadelphia pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'BZE Belize City, Belize, Philip S. W. Goldson International, BZE, philip s w goldson international belize city belize', key:'A_BZE', cityBase: 33, city: 'BZE', country: 'BZ', type: 'airport', nlabel: 'philip s w goldson international belize city belize'},
  { icon: 'faPlaneDeparture', label: 'PZL Phinda Private Game Reserve, South Africa, Phinda Zulu Inyala, PZL, phinda zulu inyala phinda private game reserve south africa', key:'A_PZL', cityBase: 127, city: 'PZL', country: 'ZA', type: 'airport', nlabel: 'phinda zulu inyala phinda private game reserve south africa'},
  { icon: 'faPlaneDeparture', label: 'PHS Phitsanulok, Thailand, Phitsanulok, PHS, phitsanulok phitsanulok thailand', key:'A_PHS', cityBase: 127, city: 'PHS', country: 'TH', type: 'airport', nlabel: 'phitsanulok phitsanulok thailand'},
  { icon: 'faPlaneDeparture', label: 'PNH Phnom Penh, Cambodia, Phnom Penh International, PNH, phnom penh international phnom penh cambodia', key:'A_PNH', cityBase: 33, city: 'PNH', country: 'KH', type: 'airport', nlabel: 'phnom penh international phnom penh cambodia'},
  { icon: 'faPlaneDeparture', label: 'DVT Phoenix, United States, Phoenix Deer Valley Airport, DVT, phoenix deer valley airport phoenix arizona united states', key:'A_DVT', cityBase: 127, city: 'PHX', country: 'US', type: 'airport', nlabel: 'phoenix deer valley airport phoenix arizona united states'},
  { icon: 'faPlaneDeparture', label: 'PHX Phoenix, United States, Phoenix Sky Harbor International, PHX, phoenix sky harbor international phoenix arizona united states', key:'A_PHX', cityBase: 1, city: 'PHX', country: 'US', type: 'airport', nlabel: 'phoenix sky harbor international phoenix arizona united states'},
  { icon: 'faPlaneDeparture', label: 'AZA Phoenix, United States, Phoenix–Mesa Gateway, AZA, phoenix mesa gateway phoenix arizona united states', key:'A_AZA', cityBase: 35, city: 'PHX', country: 'US', type: 'airport', nlabel: 'phoenix mesa gateway phoenix arizona united states'},
  { icon: 'faPlaneDeparture', label: 'PRH Phrae Province, Thailand, Phrae, PRH, phrae phrae province thailand', key:'A_PRH', cityBase: 127, city: 'PRH', country: 'TH', type: 'airport', nlabel: 'phrae phrae province thailand'},
  { icon: 'faPlaneDeparture', label: 'HUI Hue, Vietnam, Phu Bai International, HUI, phu bai international hue vietnam', key:'A_HUI', cityBase: 61, city: 'HUI', country: 'VN', type: 'airport', nlabel: 'phu bai international hue vietnam'},
  { icon: 'faPlaneDeparture', label: 'UIH Qui Nhơn, Vietnam, Phu Cat, UIH, phu cat qui nhon vietnam', key:'A_UIH', cityBase: 125, city: 'UIH', country: 'VN', type: 'airport', nlabel: 'phu cat qui nhon vietnam'},
  { icon: 'faPlaneDeparture', label: 'PQC Phú Quốc, Vietnam, Phu Quoc International Airport, PQC, duong dong phu quoc vietnam', key:'A_PQC', cityBase: 50, city: 'PQC', country: 'VN', type: 'airport', nlabel: 'duong dong phu quoc vietnam'},
  { icon: 'faPlaneDeparture', label: 'HKT Phuket City, Thailand, Phuket International, HKT, phuket international phuket city thailand', key:'A_HKT', cityBase: 9, city: 'HKT', country: 'TH', type: 'airport', nlabel: 'phuket international phuket city thailand'},
  { icon: 'faPlaneDeparture', label: 'POS Port of Spain, Trinidad & Tobago, Piarco International, POS, piarco international port of spain trinidad and tobago', key:'A_POS', cityBase: 25, city: 'POS', country: 'TT', type: 'airport', nlabel: 'piarco international port of spain trinidad and tobago'},
  { icon: 'faPlaneDeparture', label: 'PIX Pico Island, Portugal, Pico, PIX, pico pico island portugal', key:'A_PIX', cityBase: 27, city: 'PIX', country: 'PT', type: 'airport', nlabel: 'pico pico island portugal'},
  { icon: 'faPlaneDeparture', label: 'PCN Picton, New Zealand, Picton Aerodrome, PCN, picton aerodrome picton new zealand', key:'A_PCN', cityBase: 127, city: 'PCN', country: 'NZ', type: 'airport', nlabel: 'picton aerodrome picton new zealand'},
  { icon: 'faPlaneDeparture', label: 'GSO Greensboro, United States, Piedmont Triad International, GSO, piedmont triad international greensboro north carolina united states', key:'A_GSO', cityBase: 25, city: 'GSO', country: 'US', type: 'airport', nlabel: 'piedmont triad international greensboro north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'PDS Piedras Negras, Mexico, Piedras Negras International, PDS, piedras negras international piedras negras mexico', key:'A_PDS', cityBase: 127, city: 'PDS', country: 'MX', type: 'airport', nlabel: 'piedras negras international piedras negras mexico'},
  { icon: 'faPlaneDeparture', label: 'PIR Pierre, United States, Pierre Regional, PIR, pierre regional pierre south dakota united states', key:'A_PIR', cityBase: 103, city: 'PIR', country: 'US', type: 'airport', nlabel: 'pierre regional pierre south dakota united states'},
  { icon: 'faPlaneDeparture', label: 'ZSE Saint-Pierre, France, Pierrefonds, ZSE, pierrefonds saint pierre reunion france', key:'A_ZSE', cityBase: 122, city: 'ZSE', country: 'FR', type: 'airport', nlabel: 'pierrefonds saint pierre reunion france'},
  { icon: 'faPlaneDeparture', label: 'PZB Pietermaritzburg, South Africa, Pietermaritzburg, PZB, pietermaritzburg pietermaritzburg south africa', key:'A_PZB', cityBase: 127, city: 'PZB', country: 'ZA', type: 'airport', nlabel: 'pietermaritzburg pietermaritzburg south africa'},
  { icon: 'faPlaneDeparture', label: 'YPM Pikangikum First Nation, Canada, Pikangikum, YPM, pikangikum pikangikum first nation ontario canada', key:'A_YPM', cityBase: 127, city: 'YPM', country: 'CA', type: 'airport', nlabel: 'pikangikum pikangikum first nation ontario canada'},
  { icon: 'faPlaneDeparture', label: 'NTY Sun City, South Africa, Pilanesberg International, NTY, pilanesberg international sun city south africa', key:'A_NTY', cityBase: 127, city: 'NTY', country: 'ZA', type: 'airport', nlabel: 'pilanesberg international sun city south africa'},
  { icon: 'faPlaneDeparture', label: 'PIP Pilot Point, United States, Pilot Point, PIP, pilot point pilot point alaska united states', key:'A_PIP', cityBase: 127, city: 'PIP', country: 'US', type: 'airport', nlabel: 'pilot point pilot point alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RGL Río Gallegos, Argentina, Piloto Civil Norberto Fernández International, RGL, piloto civil norberto fernandez international rio gallegos santa cruz argentina', key:'A_RGL', cityBase: 127, city: 'RGL', country: 'AR', type: 'airport', nlabel: 'piloto civil norberto fernandez international rio gallegos santa cruz argentina'},
  { icon: 'faPlaneDeparture', label: 'DUM Dumai, Indonesia, Pinang Kampai, DUM, pinang kampai dumai indonesia', key:'A_DUM', cityBase: 127, city: 'DUM', country: 'ID', type: 'airport', nlabel: 'pinang kampai dumai indonesia'},
  { icon: 'faPlaneDeparture', label: 'FOR Fortaleza, Brazil, Pinto Martins – Fortaleza International, FOR, pinto martins fortaleza international fortaleza state of ceara brazil', key:'A_FOR', cityBase: 53, city: 'FOR', country: 'BR', type: 'airport', nlabel: 'pinto martins fortaleza international fortaleza state of ceara brazil'},
  { icon: 'faPlaneDeparture', label: 'PSA Pisa, Italy, Pisa International, PSA, pisa international pisa italy', key:'A_PSA', cityBase: 8, city: 'PSA', country: 'IT', type: 'airport', nlabel: 'pisa international pisa italy'},
  { icon: 'faPlaneDeparture', label: 'PTX Pitalito, Colombia, Pitalito, PTX, pitalito pitalito colombia', key:'A_PTX', cityBase: 127, city: 'PTX', country: 'CO', type: 'airport', nlabel: 'pitalito pitalito colombia'},
  { icon: 'faPlaneDeparture', label: 'PGV Greenville, United States, Pitt–Greenville, PGV, pitt greenville greenville north carolina united states', key:'A_PGV', cityBase: 95, city: 'PGV', country: 'US', type: 'airport', nlabel: 'pitt greenville greenville north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'PIT Pittsburgh, United States, Pittsburgh International, PIT, pittsburgh international pittsburgh pennsylvania united states', key:'A_PIT', cityBase: 4, city: 'PIT', country: 'US', type: 'airport', nlabel: 'pittsburgh international pittsburgh pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'PLJ Placencia, Belize, Placencia, PLJ, placencia placencia belize', key:'A_PLJ', cityBase: 127, city: 'PLJ', country: 'BZ', type: 'airport', nlabel: 'placencia placencia belize'},
  { icon: 'faPlaneDeparture', label: 'SLW Saltillo, Mexico, Plan de Guadalupe International, SLW, plan de guadalupe international saltillo mexico', key:'A_SLW', cityBase: 114, city: 'SLW', country: 'MX', type: 'airport', nlabel: 'plan de guadalupe international saltillo mexico'},
  { icon: 'faPlaneDeparture', label: 'TLY Plastun, Russia, Plastun, TLY, plastun plastun russia', key:'A_TLY', cityBase: 127, city: 'TLY', country: 'RU', type: 'airport', nlabel: 'plastun plastun russia'},
  { icon: 'faPlaneDeparture', label: 'PTU Platinum, United States, Platinum, PTU, platinum platinum alaska united states', key:'A_PTU', cityBase: 127, city: 'PTU', country: 'US', type: 'airport', nlabel: 'platinum platinum alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ROV Rostov-on-Don, Russia, Platov International Airport, ROV, platov international airport rostov on don russia', key:'A_ROV', cityBase: 27, city: 'ROV', country: 'RU', type: 'airport', nlabel: 'platov international airport rostov on don russia'},
  { icon: 'faPlaneDeparture', label: 'PBG Plattsburgh, United States, Plattsburgh International, PBG, plattsburgh international plattsburgh new york united states', key:'A_PBG', cityBase: 78, city: 'PBG', country: 'US', type: 'airport', nlabel: 'plattsburgh international plattsburgh new york united states'},
  { icon: 'faPlaneDeparture', label: 'ZLO Manzanillo, Mexico, Playa de Oro International, ZLO, playa de oro international manzanillo mexico', key:'A_ZLO', cityBase: 74, city: 'ZLO', country: 'MX', type: 'airport', nlabel: 'playa de oro international manzanillo mexico'},
  { icon: 'faPlaneDeparture', label: 'PYC Ukupseni, Panama, Playón Chico, PYC, playon chico ukupseni panama', key:'A_PYC', cityBase: 127, city: 'PYC', country: 'PA', type: 'airport', nlabel: 'playon chico ukupseni panama'},
  { icon: 'faPlaneDeparture', label: 'PXU Pleiku, Vietnam, Pleiku, PXU, pleiku pleiku vietnam', key:'A_PXU', cityBase: 127, city: 'PXU', country: 'VN', type: 'airport', nlabel: 'pleiku pleiku vietnam'},
  { icon: 'faPlaneDeparture', label: 'PBZ Plettenberg Bay, South Africa, Plettenberg Bay, PBZ, plettenberg bay plettenberg bay south africa', key:'A_PBZ', cityBase: 127, city: 'PBZ', country: 'ZA', type: 'airport', nlabel: 'plettenberg bay plettenberg bay south africa'},
  { icon: 'faPlaneDeparture', label: 'PDV Plovdiv, Bulgaria, Plovdiv, PDV, plovdiv plovdiv bulgaria', key:'A_PDV', cityBase: 29, city: 'PDV', country: 'BG', type: 'airport', nlabel: 'plovdiv plovdiv bulgaria'},
  { icon: 'faPlaneDeparture', label: 'PLH Plymouth, United Kingdom, Plymouth City, PLH, plymouth city plymouth united kingdom', key:'A_PLH', cityBase: -873, city: 'PLH', country: 'GB', type: 'airport', nlabel: 'plymouth city plymouth united kingdom'},
  { icon: 'faPlaneDeparture', label: 'KVX Kirov, Russia, Pobedilovo, KVX, pobedilovo kirov russia', key:'A_KVX', cityBase: 49, city: 'KVX', country: 'RU', type: 'airport', nlabel: 'pobedilovo kirov russia'},
  { icon: 'faPlaneDeparture', label: 'PIH Pocatello, United States, Pocatello Regional, PIH, pocatello regional pocatello idaho united states', key:'A_PIH', cityBase: 86, city: 'PIH', country: 'US', type: 'airport', nlabel: 'pocatello regional pocatello idaho united states'},
  { icon: 'faPlaneDeparture', label: 'TGD Podgorica, Montenegro, Podgorica, TGD, podgorica podgorica montenegro', key:'A_TGD', cityBase: 19, city: 'TGD', country: 'ME', type: 'airport', nlabel: 'podgorica podgorica montenegro'},
  { icon: 'faPlaneDeparture', label: 'TGP Podkamennaya Tunguska, Russia, Podkamennaya Tunguska, TGP, podkamennaya tunguska podkamennaya tunguska russia', key:'A_TGP', cityBase: 127, city: 'TGP', country: 'RU', type: 'airport', nlabel: 'podkamennaya tunguska podkamennaya tunguska russia'},
  { icon: 'faPlaneDeparture', label: 'UOL Buol, Indonesia, Pogogul, UOL, pogogul buol indonesia', key:'A_UOL', cityBase: 127, city: 'UOL', country: 'ID', type: 'airport', nlabel: 'pogogul buol indonesia'},
  { icon: 'faPlaneDeparture', label: 'KPO Pohang, South Korea, Pohang, KPO, pohang pohang south korea', key:'A_KPO', cityBase: 127, city: 'KPO', country: 'KR', type: 'airport', nlabel: 'pohang pohang south korea'},
  { icon: 'faPlaneDeparture', label: 'PNI Pohnpei, Micronesia, Pohnpei International, PNI, pohnpei international pohnpei micronesia', key:'A_PNI', cityBase: 93, city: 'PNI', country: 'FM', type: 'airport', nlabel: 'pohnpei international pohnpei micronesia'},
  { icon: 'faPlaneDeparture', label: 'KPB Point Baker, United States, Point Baker Sea Plane Base, KPB, point baker sea plane base point baker alaska united states', key:'A_KPB', cityBase: 127, city: 'KPB', country: 'US', type: 'airport', nlabel: 'point baker sea plane base point baker alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PHO Point Hope, United States, Point Hope, PHO, point hope point hope alaska united states', key:'A_PHO', cityBase: 126, city: 'PHO', country: 'US', type: 'airport', nlabel: 'point hope point hope alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PIZ Point Lay, United States, Point Lay LRRS, PIZ, point lay lrrs point lay alaska united states', key:'A_PIZ', cityBase: 127, city: 'PIZ', country: 'US', type: 'airport', nlabel: 'point lay lrrs point lay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PNR Pointe-Noire, Republic of the Congo, Pointe Noire, PNR, pointe noire pointe noire congo brazzaville', key:'A_PNR', cityBase: 95, city: 'PNR', country: 'CG', type: 'airport', nlabel: 'pointe noire pointe noire congo brazzaville'},
  { icon: 'faPlaneDeparture', label: 'FUT Futuna, Wallis & Futuna, Pointe Vele, FUT, pointe vele futuna wallis and futuna', key:'A_FUT', cityBase: 127, city: 'FUT', country: 'WF', type: 'airport', nlabel: 'pointe vele futuna wallis and futuna'},
  { icon: 'faPlaneDeparture', label: 'PTP Pointe-à-Pitre, France, Pointe-à-Pitre International, PTP, pointe a pitre international pointe a pitre guadeloupe france', key:'A_PTP', cityBase: 62, city: 'PTP', country: 'FR', type: 'airport', nlabel: 'pointe a pitre international pointe a pitre guadeloupe france'},
  { icon: 'faPlaneDeparture', label: 'PIS Poitiers, France, Poitiers–Biard, PIS, poitiers biard poitiers france', key:'A_PIS', cityBase: 100, city: 'PIS', country: 'FR', type: 'airport', nlabel: 'poitiers biard poitiers france'},
  { icon: 'faPlaneDeparture', label: 'PKR Pokhara, Nepal, Pokhara, PKR, pokhara pokhara nepal', key:'A_PKR', cityBase: 98, city: 'PKR', country: 'NP', type: 'airport', nlabel: 'pokhara pokhara nepal'},
  { icon: 'faPlaneDeparture', label: 'PTG Polokwane, Limpopo, South Africa, Polokwane International, PTG, polokwane international polokwane south africa', key:'A_PTG', cityBase: 127, city: 'PTG', country: 'ZA', type: 'airport', nlabel: 'polokwane international polokwane south africa'},
  { icon: 'faPlaneDeparture', label: 'PYJ Udachny, Russia, Polyarny, PYJ, polyarny udachny russia', key:'A_PYJ', cityBase: 57, city: 'PYJ', country: 'RU', type: 'airport', nlabel: 'polyarny udachny russia'},
  { icon: 'faPlaneDeparture', label: 'YIO Pond Inlet, Canada, Pond Inlet, YIO, pond inlet pond inlet nunavut canada', key:'A_YIO', cityBase: 127, city: 'YIO', country: 'CA', type: 'airport', nlabel: 'pond inlet pond inlet nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'PNY Pondicherry, India, Pondicherry, PNY, pondicherry pondicherry india', key:'A_PNY', cityBase: 100, city: 'PNY', country: 'IN', type: 'airport', nlabel: 'pondicherry pondicherry india'},
  { icon: 'faPlaneDeparture', label: 'PGZ Ponta Grossa, Brazil, Ponta Grossa A.Amilton Beraldo, PGZ, ponta grossa a amilton beraldo ponta grossa state of parana brazil', key:'A_PGZ', cityBase: 127, city: 'PGZ', country: 'BR', type: 'airport', nlabel: 'ponta grossa a amilton beraldo ponta grossa state of parana brazil'},
  { icon: 'faPlaneDeparture', label: 'TAT Poprad, Slovakia, Poprad–Tatry, TAT, poprad tatry poprad slovakia', key:'A_TAT', cityBase: 101, city: 'TAT', country: 'SK', type: 'airport', nlabel: 'poprad tatry poprad slovakia'},
  { icon: 'faPlaneDeparture', label: 'PBD Porbandar, India, Porbandar, PBD, porbandar porbandar india', key:'A_PBD', cityBase: 106, city: 'PBD', country: 'IN', type: 'airport', nlabel: 'porbandar porbandar india'},
  { icon: 'faPlaneDeparture', label: 'POR Pori, Finland, Pori, POR, pori pori finland', key:'A_POR', cityBase: 127, city: 'POR', country: 'FI', type: 'airport', nlabel: 'pori pori finland'},
  { icon: 'faPlaneDeparture', label: 'PTA Port Alsworth, United States, Port Alsworth, PTA, port alsworth port alsworth alaska united states', key:'A_PTA', cityBase: 127, city: 'PTA', country: 'US', type: 'airport', nlabel: 'port alsworth port alsworth alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PUG Port Augusta, Australia, Port Augusta, PUG, port augusta port augusta south australia australia', key:'A_PUG', cityBase: 127, city: 'PUG', country: 'AU', type: 'airport', nlabel: 'port augusta port augusta south australia australia'},
  { icon: 'faPlaneDeparture', label: 'ABJ Abidjan, Côte d’Ivoire, Port Bouet, ABJ, port bouet abidjan cote d ivoire', key:'A_ABJ', cityBase: 50, city: 'ABJ', country: 'CI', type: 'airport', nlabel: 'port bouet abidjan cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'PLZ Port Elizabeth, South Africa, Port Elizabeth, PLZ, port elizabeth port elizabeth south africa', key:'A_PLZ', cityBase: 68, city: 'PLZ', country: 'ZA', type: 'airport', nlabel: 'port elizabeth port elizabeth south africa'},
  { icon: 'faPlaneDeparture', label: 'PGM Port Graham, United States, Port Graham, PGM, port graham port graham alaska united states', key:'A_PGM', cityBase: 127, city: 'PGM', country: 'US', type: 'airport', nlabel: 'port graham port graham alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PHC Port Harcourt, Nigeria, Port Harcourt International, PHC, port harcourt international port harcourt nigeria', key:'A_PHC', cityBase: 88, city: 'PHC', country: 'NG', type: 'airport', nlabel: 'port harcourt international port harcourt nigeria'},
  { icon: 'faPlaneDeparture', label: 'YZT Port Hardy, Canada, Port Hardy, YZT, port hardy port hardy british columbia canada', key:'A_YZT', cityBase: 127, city: 'YZT', country: 'CA', type: 'airport', nlabel: 'port hardy port hardy british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'PHE Port Hedland, Australia, Port Hedland International, PHE, port hedland international port hedland western australia australia', key:'A_PHE', cityBase: 127, city: 'PHE', country: 'AU', type: 'airport', nlabel: 'port hedland international port hedland western australia australia'},
  { icon: 'faPlaneDeparture', label: 'PTH Port Heiden, United States, Port Heiden, PTH, port heiden port heiden alaska united states', key:'A_PTH', cityBase: 127, city: 'PTH', country: 'US', type: 'airport', nlabel: 'port heiden port heiden alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PLO Port Lincoln, Australia, Port Lincoln, PLO, port lincoln port lincoln south australia australia', key:'A_PLO', cityBase: 118, city: 'PLO', country: 'AU', type: 'airport', nlabel: 'port lincoln port lincoln south australia australia'},
  { icon: 'faPlaneDeparture', label: 'ORI Port Lions, United States, Port Lions, ORI, port lions port lions alaska united states', key:'A_ORI', cityBase: 127, city: 'ORI', country: 'US', type: 'airport', nlabel: 'port lions port lions alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PQQ Port Macquarie, Australia, Port Macquarie, PQQ, port macquarie port macquarie new south wales australia', key:'A_PQQ', cityBase: 127, city: 'PQQ', country: 'AU', type: 'airport', nlabel: 'port macquarie port macquarie new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'PML Port Moller, United States, Port Moller, PML, port moller port moller alaska united states', key:'A_PML', cityBase: 127, city: 'PML', country: 'US', type: 'airport', nlabel: 'port moller port moller alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RCY Rum Cay District, Bahamas, Port Nelson, RCY, port nelson rum cay district bahamas', key:'A_RCY', cityBase: 127, city: 'RCY', country: 'BS', type: 'airport', nlabel: 'port nelson rum cay district bahamas'},
  { icon: 'faPlaneDeparture', label: 'PPV Port Protection, United States, Port Protection Seaplane Base, PPV, port protection seaplane base port protection alaska united states', key:'A_PPV', cityBase: 127, city: 'PPV', country: 'US', type: 'airport', nlabel: 'port protection seaplane base port protection alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PZU Port, Sudan, Port Sudan New International, PZU, port sudan new international port sudan', key:'A_PZU', cityBase: 127, city: 'PZU', country: 'SD', type: 'airport', nlabel: 'port sudan new international port sudan'},
  { icon: 'faPlaneDeparture', label: 'POG Port-Gentil, Gabon, Port-Gentil International, POG, port gentil international port gentil gabon', key:'A_POG', cityBase: 127, city: 'POG', country: 'GA', type: 'airport', nlabel: 'port gentil international port gentil gabon'},
  { icon: 'faPlaneDeparture', label: 'PTJ Portland, Australia, Portland, PTJ, portland portland victoria australia', key:'A_PTJ', cityBase: 127, city: 'PTJ', country: 'AU', type: 'airport', nlabel: 'portland portland victoria australia'},
  { icon: 'faPlaneDeparture', label: 'PDX Portland, United States, Portland International, PDX, portland international portland oregon united states', key:'A_PDX', cityBase: 1, city: 'PDX', country: 'US', type: 'airport', nlabel: 'portland international portland oregon united states'},
  { icon: 'faPlaneDeparture', label: 'PWM Portland, United States, Portland International Jetport, PWM, portland international jetport portland maine united states', key:'A_PWM', cityBase: 16, city: 'PWM', country: 'US', type: 'airport', nlabel: 'portland international jetport portland maine united states'},
  { icon: 'faPlaneDeparture', label: 'OPO Porto, Portugal, Porto, OPO, porto porto portugal', key:'A_OPO', cityBase: 9, city: 'OPO', country: 'PT', type: 'airport', nlabel: 'porto porto portugal'},
  { icon: 'faPlaneDeparture', label: 'PXO Vila Baleira, Portugal, Porto Santo, PXO, porto santo vila baleira portugal', key:'A_PXO', cityBase: 25, city: 'PXO', country: 'PT', type: 'airport', nlabel: 'porto santo vila baleira portugal'},
  { icon: 'faPlaneDeparture', label: 'BPS Porto Seguro, Brazil, Porto Seguro, BPS, porto seguro porto seguro state of bahia brazil', key:'A_BPS', cityBase: 90, city: 'BPS', country: 'BR', type: 'airport', nlabel: 'porto seguro porto seguro state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'PSM Portsmouth, United States, Portsmouth International Airport at Pease, PSM, portsmouth international airport at pease portsmouth massachusetts united states', key:'A_PSM', cityBase: 127, city: 'PSM', country: 'US', type: 'airport', nlabel: 'portsmouth international airport at pease portsmouth massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'PSJ Poso, Indonesia, Poso Kasinguncu, PSJ, poso kasinguncu poso indonesia', key:'A_PSJ', cityBase: 127, city: 'PSJ', country: 'ID', type: 'airport', nlabel: 'poso kasinguncu poso indonesia'},
  { icon: 'faPlaneDeparture', label: 'YSO Postville, Canada, Postville, YSO, postville postville newfoundland and labrador canada', key:'A_YSO', cityBase: 127, city: 'YSO', country: 'CA', type: 'airport', nlabel: 'postville postville newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'YPW Powell River, British Columbia, Canada, Powell River, YPW, powell river powell river british columbia canada', key:'A_YPW', cityBase: 127, city: 'YPW', country: 'CA', type: 'airport', nlabel: 'powell river powell river british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'POZ Poznań, Poland, Poznań–Ławica, POZ, poznan lawica poznan poland', key:'A_POZ', cityBase: 49, city: 'POZ', country: 'PL', type: 'airport', nlabel: 'poznan lawica poznan poland'},
  { icon: 'faPlaneDeparture', label: 'PRI Praslin, Seychelles, Praslin Island, PRI, praslin island praslin seychelles', key:'A_PRI', cityBase: 116, city: 'PRI', country: 'SC', type: 'airport', nlabel: 'praslin island praslin seychelles'},
  { icon: 'faPlaneDeparture', label: 'SNE São Nicolau, Cape Verde, Preguiça, SNE, preguica sao nicolau cape verde', key:'A_SNE', cityBase: 127, city: 'SNE', country: 'CV', type: 'airport', nlabel: 'preguica sao nicolau cape verde'},
  { icon: 'faPlaneDeparture', label: 'PUQ Punta Arenas, Chile, Presidente Carlos Ibáñez del Campo International, PUQ, presidente carlos ibanez del campo international punta arenas chile', key:'A_PUQ', cityBase: 49, city: 'PUQ', country: 'CL', type: 'airport', nlabel: 'presidente carlos ibanez del campo international punta arenas chile'},
  { icon: 'faPlaneDeparture', label: 'JPA João Pessoa, Paraíba, Brazil, Presidente Castro Pinto International, JPA, presidente castro pinto international joao pessoa state of paraiba brazil', key:'A_JPA', cityBase: 80, city: 'JPA', country: 'BR', type: 'airport', nlabel: 'presidente castro pinto international joao pessoa state of paraiba brazil'},
  { icon: 'faPlaneDeparture', label: 'DIL Dili, Timor-Leste, Presidente Nicolau Lobato International, DIL, presidente nicolau lobato international dili timor leste', key:'A_DIL', cityBase: 85, city: 'DIL', country: 'TL', type: 'airport', nlabel: 'presidente nicolau lobato international dili timor leste'},
  { icon: 'faPlaneDeparture', label: 'NQN Neuquén, Argentina, Presidente Perón International, NQN, presidente peron international neuquen neuquen argentina', key:'A_NQN', cityBase: 89, city: 'NQN', country: 'AR', type: 'airport', nlabel: 'presidente peron international neuquen neuquen argentina'},
  { icon: 'faPlaneDeparture', label: 'PPB Presidente Prudente, São Paulo, Brazil, Presidente Prudente, PPB, presidente prudente presidente prudente state of sao paulo brazil', key:'A_PPB', cityBase: 127, city: 'PPB', country: 'BR', type: 'airport', nlabel: 'presidente prudente presidente prudente state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'ULH Al-`Ula, Saudi Arabia, Prince Abdul Majeed bin Abdulaziz Domestic, ULH, prince abdul majeed bin abdulaziz domestic al `ula saudi arabia', key:'A_ULH', cityBase: 113, city: 'ULH', country: 'SA', type: 'airport', nlabel: 'prince abdul majeed bin abdulaziz domestic al `ula saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'YXS Prince George, Canada, Prince George, YXS, prince george prince george british columbia canada', key:'A_YXS', cityBase: 103, city: 'YXS', country: 'CA', type: 'airport', nlabel: 'prince george prince george british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'ULD Ulundi, South Africa, Prince Mangosuthu Buthelezi Airport, ULD, prince mangosuthu buthelezi airport ulundi south africa', key:'A_ULD', cityBase: 127, city: 'ULD', country: 'ZA', type: 'airport', nlabel: 'prince mangosuthu buthelezi airport ulundi south africa'},
  { icon: 'faPlaneDeparture', label: 'MED Medina, Saudi Arabia, Prince Mohammad bin Abdulaziz, MED, prince mohammad bin abdulaziz medina saudi arabia', key:'A_MED', cityBase: 44, city: 'MED', country: 'SA', type: 'airport', nlabel: 'prince mohammad bin abdulaziz medina saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'ELQ Al-Qassim Region, Saudi Arabia, Prince Nayef bin Abdulaziz Regional, ELQ, prince nayef bin abdulaziz regional al qassim region saudi arabia', key:'A_ELQ', cityBase: 61, city: 'ELQ', country: 'SA', type: 'airport', nlabel: 'prince nayef bin abdulaziz regional al qassim region saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'YPR Prince Rupert, Canada, Prince Rupert, YPR, prince rupert prince rupert british columbia canada', key:'A_YPR', cityBase: 127, city: 'YPR', country: 'CA', type: 'airport', nlabel: 'prince rupert prince rupert british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'HAH Moroni, Comoros, Prince Said Ibrahim International, HAH, prince said ibrahim international moroni comoros', key:'A_HAH', cityBase: 99, city: 'YVA', country: 'KM', type: 'airport', nlabel: 'prince said ibrahim international moroni comoros'},
  { icon: 'faPlaneDeparture', label: 'SXM Lower Prince\'s Quarter, Sint Maarten, Princess Juliana International, SXM, princess juliana international lower prince s quarter sint maarten', key:'A_SXM', cityBase: 32, city: 'SXM', country: 'SX', type: 'airport', nlabel: 'princess juliana international lower prince s quarter sint maarten'},
  { icon: 'faPlaneDeparture', label: 'PRN Pristina, Kosovo, Pristina International, PRN, pristina international pristina kosovo', key:'A_PRN', cityBase: 36, city: 'PRN', country: 'XK', type: 'airport', nlabel: 'pristina international pristina kosovo'},
  { icon: 'faPlaneDeparture', label: 'PLS Providenciales, Turks & Caicos Islands, Providenciales International, PLS, providenciales international providenciales turks and caicos islands', key:'A_PLS', cityBase: 27, city: 'PLS', country: 'TC', type: 'airport', nlabel: 'providenciales international providenciales turks and caicos islands'},
  { icon: 'faPlaneDeparture', label: 'PVC Provincetown, United States, Provincetown Municipal, PVC, provincetown municipal provincetown massachusetts united states', key:'A_PVC', cityBase: 67, city: 'PVC', country: 'US', type: 'airport', nlabel: 'provincetown municipal provincetown massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'PVU Provo, United States, Provo Municipal, PVU, provo municipal provo utah united states', key:'A_PVU', cityBase: 42, city: 'PVU', country: 'US', type: 'airport', nlabel: 'provo municipal provo utah united states'},
  { icon: 'faPlaneDeparture', label: 'PKV Pskov, Russia, Pskov, PKV, pskov pskov russia', key:'A_PKV', cityBase: 127, city: 'PKV', country: 'RU', type: 'airport', nlabel: 'pskov pskov russia'},
  { icon: 'faPlaneDeparture', label: 'SYM Simao District, China, Pu er Simao, SYM, pu er simao simao district china', key:'A_SYM', cityBase: 67, city: 'SYM', country: 'CN', type: 'airport', nlabel: 'pu er simao simao district china'},
  { icon: 'faPlaneDeparture', label: 'PBC Puebla, Mexico, Puebla International, PBC, puebla international puebla mexico', key:'A_PBC', cityBase: 65, city: 'PBC', country: 'MX', type: 'airport', nlabel: 'puebla international puebla mexico'},
  { icon: 'faPlaneDeparture', label: 'PUB Pueblo, United States, Pueblo Memorial, PUB, pueblo memorial pueblo colorado united states', key:'A_PUB', cityBase: 114, city: 'PUB', country: 'US', type: 'airport', nlabel: 'pueblo memorial pueblo colorado united states'},
  { icon: 'faPlaneDeparture', label: 'PXM Puerto Escondido, Oaxaca, Mexico, Puerto Escondido International, PXM, puerto escondido international puerto escondido mexico', key:'A_PXM', cityBase: 48, city: 'PXM', country: 'MX', type: 'airport', nlabel: 'puerto escondido international puerto escondido mexico'},
  { icon: 'faPlaneDeparture', label: 'PJM Puerto Jiménez, Costa Rica, Puerto Jiménez, PJM, puerto jimenez puerto jimenez costa rica', key:'A_PJM', cityBase: 127, city: 'PJM', country: 'CR', type: 'airport', nlabel: 'puerto jimenez puerto jimenez costa rica'},
  { icon: 'faPlaneDeparture', label: 'PEU Puerto Lempira, Honduras, Puerto Lempira, PEU, puerto lempira puerto lempira honduras', key:'A_PEU', cityBase: 127, city: 'PEU', country: 'HN', type: 'airport', nlabel: 'puerto lempira puerto lempira honduras'},
  { icon: 'faPlaneDeparture', label: 'PUE Puerto Obaldía, Panama, Puerto Obaldia, PUE, puerto obaldia puerto obaldia panama', key:'A_PUE', cityBase: 117, city: 'PUE', country: 'PA', type: 'airport', nlabel: 'puerto obaldia puerto obaldia panama'},
  { icon: 'faPlaneDeparture', label: 'PPS Puerto Princesa, Philippines, Puerto Princesa International, PPS, puerto princesa international puerto princesa philippines', key:'A_PPS', cityBase: 40, city: 'PPS', country: 'PH', type: 'airport', nlabel: 'puerto princesa international puerto princesa philippines'},
  { icon: 'faPlaneDeparture', label: 'PUY Pula, Croatia, Pula, PUY, pula pula croatia', key:'A_PUY', cityBase: 19, city: 'PUY', country: 'HR', type: 'airport', nlabel: 'pula pula croatia'},
  { icon: 'faPlaneDeparture', label: 'LED Saint Petersburg, Russia, Pulkovo International, LED, pulkovo international saint petersburg russia', key:'A_LED', cityBase: 17, city: 'LED', country: 'RU', type: 'airport', nlabel: 'pulkovo international saint petersburg russia'},
  { icon: 'faPlaneDeparture', label: 'PUW Pullman, United States, Pullman–Moscow Regional, PUW, pullman moscow regional pullman washington united states', key:'A_PUW', cityBase: 94, city: 'PUW', country: 'US', type: 'airport', nlabel: 'pullman moscow regional pullman washington united states'},
  { icon: 'faPlaneDeparture', label: 'PNQ Pune, India, Pune, PNQ, pune pune india', key:'A_PNQ', cityBase: 47, city: 'PNQ', country: 'IN', type: 'airport', nlabel: 'pune pune india'},
  { icon: 'faPlaneDeparture', label: 'PUJ Punta Cana, Dominican Republic, Punta Cana International, PUJ, punta cana international punta cana dominican republic', key:'A_PUJ', cityBase: 11, city: 'PUJ', country: 'DO', type: 'airport', nlabel: 'punta cana international punta cana dominican republic'},
  { icon: 'faPlaneDeparture', label: 'PGD Punta Gorda, United States, Punta Gorda, PGD, punta gorda punta gorda florida united states', key:'A_PGD', cityBase: 69, city: 'PGD', country: 'US', type: 'airport', nlabel: 'punta gorda punta gorda florida united states'},
  { icon: 'faPlaneDeparture', label: 'PND Punta Gorda, Belize, Punta Gorda, PND, punta gorda punta gorda belize', key:'A_PND', cityBase: 118, city: 'PND', country: 'BZ', type: 'airport', nlabel: 'punta gorda punta gorda belize'},
  { icon: 'faPlaneDeparture', label: 'PBU Putao, Myanmar (Burma), Putao, PBU, putao putao myanmar (burma)', key:'A_PBU', cityBase: 127, city: 'PBU', country: 'MM', type: 'airport', nlabel: 'putao putao myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'YPX Puvirnituq, Canada, Puvirnituq, YPX, puvirnituq puvirnituq quebec canada', key:'A_YPX', cityBase: 127, city: 'YPX', country: 'CA', type: 'airport', nlabel: 'puvirnituq puvirnituq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'FNJ Pyongyang, North Korea, Pyongyang International, FNJ, pyongyang international pyongyang north korea', key:'A_FNJ', cityBase: 127, city: 'FNJ', country: 'KP', type: 'airport', nlabel: 'pyongyang international pyongyang north korea'},
  { icon: 'faPlaneDeparture', label: 'JQA Qaarsut, Greenland, Qaarsut, JQA, qaarsut qaarsut greenland', key:'A_JQA', cityBase: 127, city: 'JQA', country: 'GL', type: 'airport', nlabel: 'qaarsut qaarsut greenland'},
  { icon: 'faPlaneDeparture', label: 'GBB Qabala, Azerbaijan, Qabala International, GBB, qabala international qabala azerbaijan', key:'A_GBB', cityBase: 113, city: 'GBB', country: 'AZ', type: 'airport', nlabel: 'qabala international qabala azerbaijan'},
  { icon: 'faPlaneDeparture', label: 'BPX Chengguan, Chamdo, China, Qamdo Bamda, BPX, qamdo bamda chengguan china', key:'A_BPX', cityBase: 71, city: 'BPX', country: 'CN', type: 'airport', nlabel: 'qamdo bamda chengguan china'},
  { icon: 'faPlaneDeparture', label: 'JIQ Qianjiang District, China, Qianjiang Wulingshan, JIQ, qianjiang wulingshan qianjiang district china', key:'A_JIQ', cityBase: 127, city: 'JIQ', country: 'CN', type: 'airport', nlabel: 'qianjiang wulingshan qianjiang district china'},
  { icon: 'faPlaneDeparture', label: 'IQM Qiemo, China, Qiemo, IQM, qiemo qiemo china', key:'A_IQM', cityBase: 127, city: 'IQM', country: 'CN', type: 'airport', nlabel: 'qiemo qiemo china'},
  { icon: 'faPlaneDeparture', label: 'YVM Qikiqtarjuaq, Canada, Qikiqtarjuaq, YVM, qikiqtarjuaq qikiqtarjuaq nunavut canada', key:'A_YVM', cityBase: 127, city: 'YVM', country: 'CA', type: 'airport', nlabel: 'qikiqtarjuaq qikiqtarjuaq nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'TAO Qingdao, China, Qingdao Liuting International, TAO, qingdao liuting international qingdao china', key:'A_TAO', cityBase: 27, city: 'TAO', country: 'CN', type: 'airport', nlabel: 'qingdao liuting international qingdao china'},
  { icon: 'faPlaneDeparture', label: 'IQN Qingyang, China, Qingyang, IQN, qingyang qingyang china', key:'A_IQN', cityBase: 75, city: 'IQN', country: 'CN', type: 'airport', nlabel: 'qingyang qingyang china'},
  { icon: 'faPlaneDeparture', label: 'BPE Qinhuangdao, China, Qinhuangdao Beidaihe, BPE, qinhuangdao beidaihe qinhuangdao china', key:'A_BPE', cityBase: 73, city: 'BPE', country: 'CN', type: 'airport', nlabel: 'qinhuangdao beidaihe qinhuangdao china'},
  { icon: 'faPlaneDeparture', label: 'BAR Qionghai, China, Qionghai Bo\'ao, BAR, qionghai bo ao qionghai china', key:'A_BAR', cityBase: 67, city: 'BAR', country: 'CN', type: 'airport', nlabel: 'qionghai bo ao qionghai china'},
  { icon: 'faPlaneDeparture', label: 'NDG Qiqihar, China, Qiqihar Sanjiazi, NDG, qiqihar sanjiazi qiqihar china', key:'A_NDG', cityBase: 72, city: 'NDG', country: 'CN', type: 'airport', nlabel: 'qiqihar sanjiazi qiqihar china'},
  { icon: 'faPlaneDeparture', label: 'MLI Moline, United States, Quad City International, MLI, quad city international moline illinois united states', key:'A_MLI', cityBase: 51, city: 'MLI', country: 'US', type: 'airport', nlabel: 'quad city international moline illinois united states'},
  { icon: 'faPlaneDeparture', label: 'JJN Quanzhou, China, Quanzhou Jinjiang International, JJN, quanzhou jinjiang international quanzhou china', key:'A_JJN', cityBase: 53, city: 'JJN', country: 'CN', type: 'airport', nlabel: 'quanzhou jinjiang international quanzhou china'},
  { icon: 'faPlaneDeparture', label: 'YQC Quaqtaq, Canada, Quaqtaq, YQC, quaqtaq quaqtaq quebec canada', key:'A_YQC', cityBase: 127, city: 'YQC', country: 'CA', type: 'airport', nlabel: 'quaqtaq quaqtaq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'LAD Luanda, Angola, Quatro de Fevereiro, LAD, quatro de fevereiro luanda angola', key:'A_LAD', cityBase: 77, city: 'LAD', country: 'AO', type: 'airport', nlabel: 'quatro de fevereiro luanda angola'},
  { icon: 'faPlaneDeparture', label: 'YQB Quebec City, Canada, Québec City Jean Lesage International, YQB, quebec city jean lesage international quebec city quebec canada', key:'A_YQB', cityBase: 35, city: 'YQB', country: 'CA', type: 'airport', nlabel: 'quebec city jean lesage international quebec city quebec canada'},
  { icon: 'faPlaneDeparture', label: 'AMM Amman, Jordan, Queen Alia International, AMM, queen alia international amman jordan', key:'A_AMM', cityBase: 17, city: 'AMM', country: 'JO', type: 'airport', nlabel: 'queen alia international amman jordan'},
  { icon: 'faPlaneDeparture', label: 'AUA Aruba, Aruba, Queen Beatrix International, AUA, queen beatrix international aruba aruba', key:'A_AUA', cityBase: 20, city: 'AUA', country: 'AW', type: 'airport', nlabel: 'queen beatrix international aruba aruba'},
  { icon: 'faPlaneDeparture', label: 'ZQN Queenstown, New Zealand, Queenstown, ZQN, queenstown queenstown new zealand', key:'A_ZQN', cityBase: 40, city: 'ZQN', country: 'NZ', type: 'airport', nlabel: 'queenstown queenstown new zealand'},
  { icon: 'faPlaneDeparture', label: 'UEL Quelimane, Mozambique, Quelimane, UEL, quelimane quelimane mozambique', key:'A_UEL', cityBase: 127, city: 'UEL', country: 'MZ', type: 'airport', nlabel: 'quelimane quelimane mozambique'},
  { icon: 'faPlaneDeparture', label: 'XQP Quepos, Costa Rica, Quepos La Managua, XQP, quepos la managua quepos costa rica', key:'A_XQP', cityBase: 112, city: 'XQP', country: 'CR', type: 'airport', nlabel: 'quepos la managua quepos costa rica'},
  { icon: 'faPlaneDeparture', label: 'QRO Santiago de Querétaro, Mexico, Querétaro International, QRO, queretaro international santiago de queretaro mexico', key:'A_QRO', cityBase: 51, city: 'QRO', country: 'MX', type: 'airport', nlabel: 'queretaro international santiago de queretaro mexico'},
  { icon: 'faPlaneDeparture', label: 'YQZ Quesnel, Canada, Quesnel, YQZ, quesnel quesnel british columbia canada', key:'A_YQZ', cityBase: 127, city: 'YQZ', country: 'CA', type: 'airport', nlabel: 'quesnel quesnel british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'UET Quetta, Pakistan, Quetta International, UET, quetta international quetta pakistan', key:'A_UET', cityBase: 104, city: 'UET', country: 'PK', type: 'airport', nlabel: 'quetta international quetta pakistan'},
  { icon: 'faPlaneDeparture', label: 'ULP Quilpie, Australia, Quilpie, ULP, quilpie quilpie queensland australia', key:'A_ULP', cityBase: 127, city: 'ULP', country: 'AU', type: 'airport', nlabel: 'quilpie quilpie queensland australia'},
  { icon: 'faPlaneDeparture', label: 'UIP Quimper, France, Quimper–Cornouaille, UIP, quimper cornouaille quimper france', key:'A_UIP', cityBase: 97, city: 'UIP', country: 'FR', type: 'airport', nlabel: 'quimper cornouaille quimper france'},
  { icon: 'faPlaneDeparture', label: 'UIN Quincy, United States, Quincy Regional, UIN, quincy regional quincy illinois united states', key:'A_UIN', cityBase: 95, city: 'UIN', country: 'US', type: 'airport', nlabel: 'quincy regional quincy illinois united states'},
  { icon: 'faPlaneDeparture', label: 'KWN Quinhagak, United States, Quinhagak, KWN, quinhagak quinhagak alaska united states', key:'A_KWN', cityBase: 127, city: 'KWN', country: 'US', type: 'airport', nlabel: 'quinhagak quinhagak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KQT Qurghonteppa, Tajikistan, Qurghonteppa International, KQT, qurghonteppa international qurghonteppa tajikistan', key:'A_KQT', cityBase: 127, city: 'KQT', country: 'TJ', type: 'airport', nlabel: 'qurghonteppa international qurghonteppa tajikistan'},
  { icon: 'faPlaneDeparture', label: 'JUZ Quzhou, China, Quzhou, JUZ, quzhou quzhou china', key:'A_JUZ', cityBase: 70, city: 'JUZ', country: 'CN', type: 'airport', nlabel: 'quzhou quzhou china'},
  { icon: 'faPlaneDeparture', label: 'LEA Exmouth, Australia, RAAF Base Learmonth, LEA, raaf base learmonth exmouth western australia australia', key:'A_LEA', cityBase: 127, city: 'LEA', country: 'AU', type: 'airport', nlabel: 'raaf base learmonth exmouth western australia australia'},
  { icon: 'faPlaneDeparture', label: 'KTR Katherine, Australia, RAAF Base Tindal, KTR, raaf base tindal katherine northern territory australia', key:'A_KTR', cityBase: 127, city: 'KTR', country: 'AU', type: 'airport', nlabel: 'raaf base tindal katherine northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'AAE Annaba, Algeria, Rabah Bitat, AAE, rabah bitat annaba algeria', key:'A_AAE', cityBase: 96, city: 'AAE', country: 'DZ', type: 'airport', nlabel: 'rabah bitat annaba algeria'},
  { icon: 'faPlaneDeparture', label: 'RBA Rabat, Morocco, Rabat–Salé, RBA, rabat sale rabat morocco', key:'A_RBA', cityBase: 41, city: 'RBA', country: 'MA', type: 'airport', nlabel: 'rabat sale rabat morocco'},
  { icon: 'faPlaneDeparture', label: 'RAB Rabaul, Papua New Guinea, Rabaul, RAB, rabaul rabaul papua new guinea', key:'A_RAB', cityBase: 127, city: 'RAB', country: 'PG', type: 'airport', nlabel: 'rabaul rabaul papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'VKG Rạch Giá, Vietnam, Rach Gia, VKG, rach gia rach gia vietnam', key:'A_VKG', cityBase: 115, city: 'VKG', country: 'VN', type: 'airport', nlabel: 'rach gia rach gia vietnam'},
  { icon: 'faPlaneDeparture', label: 'TKG Bandar Lampung, Indonesia, Radin Inten II International, TKG, radin inten ii international bandar lampung indonesia', key:'A_TKG', cityBase: 122, city: 'TKG', country: 'ID', type: 'airport', nlabel: 'radin inten ii international bandar lampung indonesia'},
  { icon: 'faPlaneDeparture', label: 'GAN Gan, Maldives, RAF Gan, GAN, raf gan gan maldives', key:'A_GAN', cityBase: 98, city: 'GAN', country: 'MV', type: 'airport', nlabel: 'raf gan gan maldives'},
  { icon: 'faPlaneDeparture', label: 'MPN Mount Pleasant, Falkland Islands (Islas Malvinas), RAF Mount Pleasant, MPN, raf mount pleasant mount pleasant falkland islands (islas malvinas)', key:'A_MPN', cityBase: 127, city: 'MPN', country: 'FK', type: 'airport', nlabel: 'raf mount pleasant mount pleasant falkland islands (islas malvinas)'},
  { icon: 'faPlaneDeparture', label: 'GER Nueva Gerona, Cuba, Rafael Cabrera Mustelier, GER, rafael cabrera mustelier nueva gerona cuba', key:'A_GER', cityBase: 127, city: 'GER', country: 'CU', type: 'airport', nlabel: 'rafael cabrera mustelier nueva gerona cuba'},
  { icon: 'faPlaneDeparture', label: 'CTG Cartagena, Colombia, Rafael Núñez International, CTG, rafael nunez international cartagena colombia', key:'A_CTG', cityBase: 20, city: 'CTG', country: 'CO', type: 'airport', nlabel: 'rafael nunez international cartagena colombia'},
  { icon: 'faPlaneDeparture', label: 'RAH Rafha, Saudi Arabia, Rafha Domestic, RAH, rafha domestic rafha saudi arabia', key:'A_RAH', cityBase: 127, city: 'RAH', country: 'SA', type: 'airport', nlabel: 'rafha domestic rafha saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'RFP Raiatea, French Polynesia, Raiatea, RFP, raiatea raiatea french polynesia', key:'A_RFP', cityBase: 102, city: 'RFP', country: 'PF', type: 'airport', nlabel: 'raiatea raiatea french polynesia'},
  { icon: 'faPlaneDeparture', label: 'RVV Raivavae, French Polynesia, Raivavae, RVV, raivavae raivavae french polynesia', key:'A_RVV', cityBase: 127, city: 'RVV', country: 'PF', type: 'airport', nlabel: 'raivavae raivavae french polynesia'},
  { icon: 'faPlaneDeparture', label: 'BHO Bhopal, India, Raja Bhoj, BHO, raja bhoj bhopal india', key:'A_BHO', cityBase: 91, city: 'BHO', country: 'IN', type: 'airport', nlabel: 'raja bhoj bhopal india'},
  { icon: 'faPlaneDeparture', label: 'TNJ Tanjung Pinang, Indonesia, Raja Haji Fisabilillah International, TNJ, raja haji fisabilillah international tanjung pinang indonesia', key:'A_TNJ', cityBase: 109, city: 'TNJ', country: 'ID', type: 'airport', nlabel: 'raja haji fisabilillah international tanjung pinang indonesia'},
  { icon: 'faPlaneDeparture', label: 'RJA Rajahmundry, India, Rajahmundry, RJA, rajahmundry rajahmundry india', key:'A_RJA', cityBase: 100, city: 'RJA', country: 'IN', type: 'airport', nlabel: 'rajahmundry rajahmundry india'},
  { icon: 'faPlaneDeparture', label: 'HYD Hyderabad, India, Rajiv Gandhi International, HYD, rajiv gandhi international hyderabad india', key:'A_HYD', cityBase: 13, city: 'HYD', country: 'IN', type: 'airport', nlabel: 'rajiv gandhi international hyderabad india'},
  { icon: 'faPlaneDeparture', label: 'RAJ Rajkot, India, Rajkot, RAJ, rajkot rajkot india', key:'A_RAJ', cityBase: 80, city: 'RAJ', country: 'IN', type: 'airport', nlabel: 'rajkot rajkot india'},
  { icon: 'faPlaneDeparture', label: 'BKW Beckley, United States, Raleigh County Memorial, BKW, raleigh county memorial beckley west virginia united states', key:'A_BKW', cityBase: 122, city: 'BKW', country: 'US', type: 'airport', nlabel: 'raleigh county memorial beckley west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'RDU Raleigh, United States, Raleigh–Durham International, RDU, raleigh durham international raleigh north carolina united states', key:'A_RDU', cityBase: 3, city: 'RDU', country: 'US', type: 'airport', nlabel: 'raleigh durham international raleigh north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'TAL Tanana, United States, Ralph M. Calhoun Memorial, TAL, ralph m calhoun memorial tanana alaska united states', key:'A_TAL', cityBase: 127, city: 'TAL', country: 'US', type: 'airport', nlabel: 'ralph m calhoun memorial tanana alaska united states'},
  { icon: 'faPlaneDeparture', label: 'OTZ Kotzebue, United States, Ralph Wien Memorial, OTZ, ralph wien memorial kotzebue alaska united states', key:'A_OTZ', cityBase: 104, city: 'OTZ', country: 'US', type: 'airport', nlabel: 'ralph wien memorial kotzebue alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RBV Ramata Island, Solomon Islands, Ramata, RBV, ramata ramata island solomon islands', key:'A_RBV', cityBase: 127, city: 'RBV', country: 'SB', type: 'airport', nlabel: 'ramata ramata island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'SAP San Pedro Sula, Honduras, Ramón Villeda Morales International, SAP, ramon villeda morales international san pedro sula honduras', key:'A_SAP', cityBase: 29, city: 'SAP', country: 'HN', type: 'airport', nlabel: 'ramon villeda morales international san pedro sula honduras'},
  { icon: 'faPlaneDeparture', label: 'RMP Rampart, United States, Rampart, RMP, rampart rampart alaska united states', key:'A_RMP', cityBase: 127, city: 'RMP', country: 'US', type: 'airport', nlabel: 'rampart rampart alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RZR Ramsar, Mazandaran, Iran, Ramsar, RZR, ramsar ramsar iran', key:'A_RZR', cityBase: 127, city: 'RZR', country: 'IR', type: 'airport', nlabel: 'ramsar ramsar iran'},
  { icon: 'faPlaneDeparture', label: 'NTX Natuna, Indonesia, Ranai, NTX, ranai natuna indonesia', key:'A_NTX', cityBase: 127, city: 'NTX', country: 'ID', type: 'airport', nlabel: 'ranai natuna indonesia'},
  { icon: 'faPlaneDeparture', label: 'HIB Hibbing, United States, Range Regional, HIB, range regional hibbing minnesota united states', key:'A_HIB', cityBase: 111, city: 'HIB', country: 'US', type: 'airport', nlabel: 'range regional hibbing minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'RGI Rangiroa, French Polynesia, Rangiroa, RGI, rangiroa rangiroa french polynesia', key:'A_RGI', cityBase: 127, city: 'RGI', country: 'PF', type: 'airport', nlabel: 'rangiroa rangiroa french polynesia'},
  { icon: 'faPlaneDeparture', label: 'YRT Rankin Inlet, Canada, Rankin Inlet, YRT, rankin inlet rankin inlet nunavut canada', key:'A_YRT', cityBase: 127, city: 'YRT', country: 'CA', type: 'airport', nlabel: 'rankin inlet rankin inlet nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'UNN Ranong Province, Thailand, Ranong, UNN, ranong ranong province thailand', key:'A_UNN', cityBase: 121, city: 'UNN', country: 'TH', type: 'airport', nlabel: 'ranong ranong province thailand'},
  { icon: 'faPlaneDeparture', label: 'RAP Rapid City, United States, Rapid City Regional, RAP, rapid city regional rapid city south dakota united states', key:'A_RAP', cityBase: 33, city: 'RAP', country: 'US', type: 'airport', nlabel: 'rapid city regional rapid city south dakota united states'},
  { icon: 'faPlaneDeparture', label: 'RAR Rarotonga, Cook Islands, Rarotonga International, RAR, rarotonga international rarotonga cook islands', key:'A_RAR', cityBase: 52, city: 'RAR', country: 'CK', type: 'airport', nlabel: 'rarotonga international rarotonga cook islands'},
  { icon: 'faPlaneDeparture', label: 'RKT Ras al-Khaimah, United Arab Emirates, Ras Al Khaimah International, RKT, ras al khaimah international ras al khaimah united arab emirates', key:'A_RKT', cityBase: 127, city: 'RKT', country: 'AE', type: 'airport', nlabel: 'ras al khaimah international ras al khaimah united arab emirates'},
  { icon: 'faPlaneDeparture', label: 'RAS Rasht, Iran, Rasht, RAS, rasht rasht iran', key:'A_RAS', cityBase: 127, city: 'RAS', country: 'IR', type: 'airport', nlabel: 'rasht rasht iran'},
  { icon: 'faPlaneDeparture', label: 'RVT Ravensthorpe, Australia, Ravensthorpe, RVT, ravensthorpe ravensthorpe western australia australia', key:'A_RVT', cityBase: 127, city: 'RVT', country: 'AU', type: 'airport', nlabel: 'ravensthorpe ravensthorpe western australia australia'},
  { icon: 'faPlaneDeparture', label: 'REC Recife, Brazil, Recife/Guararapes–Gilberto Freyre International, REC, recife guararapes gilberto freyre international recife state of pernambuco brazil', key:'A_REC', cityBase: 55, city: 'REC', country: 'BR', type: 'airport', nlabel: 'recife guararapes gilberto freyre international recife state of pernambuco brazil'},
  { icon: 'faPlaneDeparture', label: 'RCQ Reconquista, Argentina, Reconquista, RCQ, reconquista reconquista santa fe argentina', key:'A_RCQ', cityBase: 127, city: 'RCQ', country: 'AR', type: 'airport', nlabel: 'reconquista reconquista santa fe argentina'},
  { icon: 'faPlaneDeparture', label: 'YQF Red Deer, Canada, Red Deer Regional, YQF, red deer regional red deer alberta canada', key:'A_YQF', cityBase: 127, city: 'YQF', country: 'CA', type: 'airport', nlabel: 'red deer regional red deer alberta canada'},
  { icon: 'faPlaneDeparture', label: 'RDB Red Dog, United States, Red Dog, RDB, red dog red dog alaska united states', key:'A_RDB', cityBase: 127, city: 'RDB', country: 'US', type: 'airport', nlabel: 'red dog red dog alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YRL Red Lake, Canada, Red Lake, YRL, red lake red lake ontario canada', key:'A_YRL', cityBase: 127, city: 'YRL', country: 'CA', type: 'airport', nlabel: 'red lake red lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'YRS Red Sucker Lake, Canada, Red Sucker Lake, YRS, red sucker lake red sucker lake manitoba canada', key:'A_YRS', cityBase: 127, city: 'YRS', country: 'CA', type: 'airport', nlabel: 'red sucker lake red sucker lake manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'RDD Redding, United States, Redding Municipal, RDD, redding municipal redding california united states', key:'A_RDD', cityBase: 82, city: 'RDD', country: 'US', type: 'airport', nlabel: 'redding municipal redding california united states'},
  { icon: 'faPlaneDeparture', label: 'REG Reggio Calabria, Italy, Reggio Calabria, REG, reggio calabria reggio calabria italy', key:'A_REG', cityBase: 24, city: 'REG', country: 'IT', type: 'airport', nlabel: 'reggio calabria reggio calabria italy'},
  { icon: 'faPlaneDeparture', label: 'YQR Regina, Canada, Regina International, YQR, regina international regina saskatchewan canada', key:'A_YQR', cityBase: 90, city: 'YQR', country: 'CA', type: 'airport', nlabel: 'regina international regina saskatchewan canada'},
  { icon: 'faPlaneDeparture', label: 'YKF Waterloo, Canada, Region of Waterloo International, YKF, region of waterloo international waterloo ontario canada', key:'A_YKF', cityBase: 114, city: 'YKF', country: 'CA', type: 'airport', nlabel: 'region of waterloo international waterloo ontario canada'},
  { icon: 'faPlaneDeparture', label: 'MKW Manokwari, Indonesia, Rendani, MKW, rendani manokwari indonesia', key:'A_MKW', cityBase: 127, city: 'MKW', country: 'ID', type: 'airport', nlabel: 'rendani manokwari indonesia'},
  { icon: 'faPlaneDeparture', label: 'RNL Rennell Island, Solomon Islands, Rennell/Tingoa, RNL, rennell tingoa rennell island solomon islands', key:'A_RNL', cityBase: 127, city: 'RNL', country: 'SB', type: 'airport', nlabel: 'rennell tingoa rennell island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'RNS Rennes, France, Rennes–Saint-Jacques, RNS, rennes saint jacques rennes france', key:'A_RNS', cityBase: 48, city: 'RNS', country: 'FR', type: 'airport', nlabel: 'rennes saint jacques rennes france'},
  { icon: 'faPlaneDeparture', label: 'RNO Reno, United States, Reno–Tahoe International, RNO, reno tahoe international reno nevada united states', key:'A_RNO', cityBase: 11, city: 'RNO', country: 'US', type: 'airport', nlabel: 'reno tahoe international reno nevada united states'},
  { icon: 'faPlaneDeparture', label: 'RES Resistencia, Argentina, Resistencia International, RES, resistencia international resistencia chaco argentina', key:'A_RES', cityBase: 111, city: 'RES', country: 'AR', type: 'airport', nlabel: 'resistencia international resistencia chaco argentina'},
  { icon: 'faPlaneDeparture', label: 'YRB Resolute, Canada, Resolute Bay, YRB, resolute bay resolute nunavut canada', key:'A_YRB', cityBase: 127, city: 'YRB', country: 'CA', type: 'airport', nlabel: 'resolute bay resolute nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'REU Reus, Spain, Reus, REU, reus reus spain', key:'A_REU', cityBase: 16, city: 'REU', country: 'ES', type: 'airport', nlabel: 'reus reus spain'},
  { icon: 'faPlaneDeparture', label: 'NQU Nuquí, Colombia, Reyes Murillo, NQU, reyes murillo nuqui colombia', key:'A_NQU', cityBase: 127, city: 'NQU', country: 'CO', type: 'airport', nlabel: 'reyes murillo nuqui colombia'},
  { icon: 'faPlaneDeparture', label: 'RKV Reykjavik, Iceland, Reykjavík, RKV, reykjavik reykjavik iceland', key:'A_RKV', cityBase: 60, city: 'REK', country: 'IS', type: 'airport', nlabel: 'reykjavik reykjavik iceland'},
  { icon: 'faPlaneDeparture', label: 'RHI Rhinelander, United States, Rhinelander–Oneida County, RHI, rhinelander oneida county rhinelander wisconsin united states', key:'A_RHI', cityBase: 93, city: 'RHI', country: 'US', type: 'airport', nlabel: 'rhinelander oneida county rhinelander wisconsin united states'},
  { icon: 'faPlaneDeparture', label: 'RHO Rhodes, Greece, Rhodes International, RHO, rhodes international rhodes greece', key:'A_RHO', cityBase: 8, city: 'RHO', country: 'GR', type: 'airport', nlabel: 'rhodes international rhodes greece'},
  { icon: 'faPlaneDeparture', label: 'RIB Riberalta, Bolivia, Riberalta, RIB, riberalta riberalta bolivia', key:'A_RIB', cityBase: 127, city: 'RIB', country: 'BO', type: 'airport', nlabel: 'riberalta riberalta bolivia'},
  { icon: 'faPlaneDeparture', label: 'RCB Richards Bay, South Africa, Richards Bay, RCB, richards bay richards bay south africa', key:'A_RCB', cityBase: 127, city: 'RCB', country: 'ZA', type: 'airport', nlabel: 'richards bay richards bay south africa'},
  { icon: 'faPlaneDeparture', label: 'RCM Richmond, Australia, Richmond, RCM, richmond richmond queensland australia', key:'A_RCM', cityBase: 127, city: 'RCM', country: 'AU', type: 'airport', nlabel: 'richmond richmond queensland australia'},
  { icon: 'faPlaneDeparture', label: 'RIC Richmond, United States, Richmond International, RIC, richmond international richmond virginia united states', key:'A_RIC', cityBase: 9, city: 'RIC', country: 'US', type: 'airport', nlabel: 'richmond international richmond virginia united states'},
  { icon: 'faPlaneDeparture', label: 'AMA Amarillo, United States, Rick Husband Amarillo International, AMA, rick husband amarillo international amarillo texas united states', key:'A_AMA', cityBase: 32, city: 'AMA', country: 'US', type: 'airport', nlabel: 'rick husband amarillo international amarillo texas united states'},
  { icon: 'faPlaneDeparture', label: 'LCK Columbus, United States, Rickenbacker International, LCK, rickenbacker international columbus ohio united states', key:'A_LCK', cityBase: 112, city: 'CMH', country: 'US', type: 'airport', nlabel: 'rickenbacker international columbus ohio united states'},
  { icon: 'faPlaneDeparture', label: 'RIX Riga, Latvia, Riga International, RIX, riga international riga latvia', key:'A_RIX', cityBase: 33, city: 'RIX', country: 'LV', type: 'airport', nlabel: 'riga international riga latvia'},
  { icon: 'faPlaneDeparture', label: 'YRG Rigolet, Canada, Rigolet, YRG, rigolet rigolet newfoundland and labrador canada', key:'A_YRG', cityBase: 127, city: 'YRG', country: 'CA', type: 'airport', nlabel: 'rigolet rigolet newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'RJK Rijeka, Croatia, Rijeka, RJK, rijeka rijeka croatia', key:'A_RJK', cityBase: 22, city: 'RJK', country: 'HR', type: 'airport', nlabel: 'rijeka rijeka croatia'},
  { icon: 'faPlaneDeparture', label: 'RMT Rimatara, French Polynesia, Rimatara, RMT, rimatara rimatara french polynesia', key:'A_RMT', cityBase: 127, city: 'RMT', country: 'PF', type: 'airport', nlabel: 'rimatara rimatara french polynesia'},
  { icon: 'faPlaneDeparture', label: 'RBR Rio Branco, Acre, Brazil, Rio Branco International, RBR, rio branco international rio branco state of acre brazil', key:'A_RBR', cityBase: 109, city: 'RBR', country: 'BR', type: 'airport', nlabel: 'rio branco international rio branco state of acre brazil'},
  { icon: 'faPlaneDeparture', label: 'GIG Rio de Janeiro, Brazil, Rio de Janeiro–Galeão International, GIG, rio de janeiro galeao international rio de janeiro state of rio de janeiro brazil', key:'A_GIG', cityBase: 41, city: 'RIO', country: 'BR', type: 'airport', nlabel: 'rio de janeiro galeao international rio de janeiro state of rio de janeiro brazil'},
  { icon: 'faPlaneDeparture', label: 'RVD Rio Verde, Goiás, Brazil, Rio Verde, RVD, rio verde rio verde state of goias brazil', key:'A_RVD', cityBase: 127, city: 'RVD', country: 'BR', type: 'airport', nlabel: 'rio verde rio verde state of goias brazil'},
  { icon: 'faPlaneDeparture', label: 'RIS Rishiri, Japan, Rishiri, RIS, rishiri rishiri japan', key:'A_RIS', cityBase: 127, city: 'RIS', country: 'JP', type: 'airport', nlabel: 'rishiri rishiri japan'},
  { icon: 'faPlaneDeparture', label: 'RIW Riverton, United States, Riverton Regional, RIW, riverton regional riverton wyoming united states', key:'A_RIW', cityBase: 127, city: 'RIW', country: 'US', type: 'airport', nlabel: 'riverton regional riverton wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'RIZ Rizhao, China, Rizhao Shanzihe, RIZ, rizhao shanzihe rizhao china', key:'A_RIZ', cityBase: 79, city: 'RIZ', country: 'CN', type: 'airport', nlabel: 'rizhao shanzihe rizhao china'},
  { icon: 'faPlaneDeparture', label: 'ROA Roanoke, United States, Roanoke–Blacksburg Regional, ROA, roanoke blacksburg regional roanoke virginia united states', key:'A_ROA', cityBase: 32, city: 'ROA', country: 'US', type: 'airport', nlabel: 'roanoke blacksburg regional roanoke virginia united states'},
  { icon: 'faPlaneDeparture', label: 'GOB Goba, Ethiopia, Robe Airport, GOB, robe airport goba ethiopia', key:'A_GOB', cityBase: 127, city: 'null', country: 'ET', type: 'airport', nlabel: 'robe airport goba ethiopia'},
  { icon: 'faPlaneDeparture', label: 'ORV Noorvik, United States, Robert (Bob) Curtis Memorial, ORV, robert (bob) curtis memorial noorvik alaska united states', key:'A_ORV', cityBase: 127, city: 'ORV', country: 'US', type: 'airport', nlabel: 'robert (bob) curtis memorial noorvik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SKB Saint Kitts, St. Kitts & Nevis, Robert L. Bradshaw International, SKB, robert l bradshaw international saint kitts st kitts and nevis', key:'A_SKB', cityBase: 53, city: 'SKB', country: 'KN', type: 'airport', nlabel: 'robert l bradshaw international saint kitts st kitts and nevis'},
  { icon: 'faPlaneDeparture', label: 'RDM Redmond, United States, Roberts Field, RDM, roberts field redmond oregon united states', key:'A_RDM', cityBase: 30, city: 'RDM', country: 'US', type: 'airport', nlabel: 'roberts field redmond oregon united states'},
  { icon: 'faPlaneDeparture', label: 'ROB Monrovia, Liberia, Roberts International, ROB, roberts international monrovia liberia', key:'A_ROB', cityBase: 61, city: 'MLW', country: 'LR', type: 'airport', nlabel: 'roberts international monrovia liberia'},
  { icon: 'faPlaneDeparture', label: 'RCE Roche Harbor Grocery Store, United States, Roche Harbor, RCE, roche harbor roche harbor grocery store washington united states', key:'A_RCE', cityBase: 127, city: 'RCE', country: 'US', type: 'airport', nlabel: 'roche harbor roche harbor grocery store washington united states'},
  { icon: 'faPlaneDeparture', label: 'RST Rochester, United States, Rochester International, RST, rochester international rochester minnesota united states', key:'A_RST', cityBase: 60, city: 'RST', country: 'US', type: 'airport', nlabel: 'rochester international rochester minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'RSD Rock Sound, Bahamas, Rock Sound International, RSD, rock sound international rock sound bahamas', key:'A_RSD', cityBase: 110, city: 'RSD', country: 'BS', type: 'airport', nlabel: 'rock sound international rock sound bahamas'},
  { icon: 'faPlaneDeparture', label: 'RKS Rock Springs, United States, Rock Springs–Sweetwater County, RKS, rock springs sweetwater county rock springs wyoming united states', key:'A_RKS', cityBase: 91, city: 'RKS', country: 'US', type: 'airport', nlabel: 'rock springs sweetwater county rock springs wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'ROK Rockhampton, Australia, Rockhampton, ROK, rockhampton rockhampton queensland australia', key:'A_ROK', cityBase: 127, city: 'ROK', country: 'AU', type: 'airport', nlabel: 'rockhampton rockhampton queensland australia'},
  { icon: 'faPlaneDeparture', label: 'BJC Denver, United States, Rocky Mountain Metropolitan Airport, BJC, rocky mountain metropolitan airport denver colorado united states', key:'A_BJC', cityBase: 127, city: 'DEN', country: 'US', type: 'airport', nlabel: 'rocky mountain metropolitan airport denver colorado united states'},
  { icon: 'faPlaneDeparture', label: 'RDZ Rodez, France, Rodez–Aveyron, RDZ, rodez aveyron rodez france', key:'A_RDZ', cityBase: 86, city: 'RDZ', country: 'FR', type: 'airport', nlabel: 'rodez aveyron rodez france'},
  { icon: 'faPlaneDeparture', label: 'AQP Arequipa, Peru, Rodríguez Ballón International, AQP, rodriguez ballon international arequipa peru', key:'A_AQP', cityBase: 54, city: 'AQP', country: 'PE', type: 'airport', nlabel: 'rodriguez ballon international arequipa peru'},
  { icon: 'faPlaneDeparture', label: 'MFR Medford, United States, Rogue Valley International–Medford, MFR, rogue valley international medford medford oregon united states', key:'A_MFR', cityBase: 29, city: 'MFR', country: 'US', type: 'airport', nlabel: 'rogue valley international medford medford oregon united states'},
  { icon: 'faPlaneDeparture', label: 'ROI Roi Et Province, Thailand, Roi Et, ROI, roi et roi et province thailand', key:'A_ROI', cityBase: 127, city: 'ROI', country: 'TH', type: 'airport', nlabel: 'roi et roi et province thailand'},
  { icon: 'faPlaneDeparture', label: 'RUN Saint-Denis, France, Roland Garros, RUN, roland garros saint denis reunion france', key:'A_RUN', cityBase: 81, city: 'RUN', country: 'FR', type: 'airport', nlabel: 'roland garros saint denis reunion france'},
  { icon: 'faPlaneDeparture', label: 'RMA Roma, Australia, Roma, RMA, roma roma queensland australia', key:'A_RMA', cityBase: 101, city: 'RMA', country: 'AU', type: 'airport', nlabel: 'roma roma queensland australia'},
  { icon: 'faPlaneDeparture', label: 'ROR Koror, Palau, Roman Tmetuchl International, ROR, roman tmetuchl international koror palau', key:'A_ROR', cityBase: 69, city: 'ROR', country: 'PW', type: 'airport', nlabel: 'roman tmetuchl international koror palau'},
  { icon: 'faPlaneDeparture', label: 'DCA Washington, D.C., United States, Ronald Reagan Washington National, DCA, ronald reagan washington national washington district of columbia united states', key:'A_DCA', cityBase: 2, city: 'WAS', country: 'US', type: 'airport', nlabel: 'ronald reagan washington national washington district of columbia united states'},
  { icon: 'faPlaneDeparture', label: 'ROO Rondonópolis, Brazil, Rondonópolis, ROO, rondonopolis rondonopolis state of mato grosso brazil', key:'A_ROO', cityBase: 127, city: 'ROO', country: 'BR', type: 'airport', nlabel: 'rondonopolis rondonopolis state of mato grosso brazil'},
  { icon: 'faPlaneDeparture', label: 'RNB Ronneby, Sweden, Ronneby, RNB, ronneby ronneby sweden', key:'A_RNB', cityBase: 127, city: 'RNB', country: 'SE', type: 'airport', nlabel: 'ronneby ronneby sweden'},
  { icon: 'faPlaneDeparture', label: 'RRS Røros, Norway, Røros, RRS, roros roros norway', key:'A_RRS', cityBase: 127, city: 'RRS', country: 'NO', type: 'airport', nlabel: 'roros roros norway'},
  { icon: 'faPlaneDeparture', label: 'RVK Rørvik, Norway, Rørvik Airport, Ryum, RVK, rorvik airport rorvik norway', key:'A_RVK', cityBase: 113, city: 'RVK', country: 'NO', type: 'airport', nlabel: 'rorvik airport rorvik norway'},
  { icon: 'faPlaneDeparture', label: 'ROS Rosario, Argentina, Rosario – Islas Malvinas International, ROS, rosario islas malvinas international rosario santa fe argentina', key:'A_ROS', cityBase: 56, city: 'ROS', country: 'AR', type: 'airport', nlabel: 'rosario islas malvinas international rosario santa fe argentina'},
  { icon: 'faPlaneDeparture', label: 'TJM Tyumen, Russia, Roshchino International, TJM, roshchino international tyumen russia', key:'A_TJM', cityBase: 43, city: 'TJM', country: 'RU', type: 'airport', nlabel: 'roshchino international tyumen russia'},
  { icon: 'faPlaneDeparture', label: 'RET Røst, Norway, Røst, RET, rost rost norway', key:'A_RET', cityBase: 127, city: 'RET', country: 'NO', type: 'airport', nlabel: 'rost rost norway'},
  { icon: 'faPlaneDeparture', label: 'RLG Rostock, Germany, Rostock–Laage, RLG, rostock laage rostock germany', key:'A_RLG', cityBase: 9, city: 'RLG', country: 'DE', type: 'airport', nlabel: 'rostock laage rostock germany'},
  { icon: 'faPlaneDeparture', label: 'ROW Roswell, United States, Roswell International Air Center, ROW, roswell international air center roswell new mexico united states', key:'A_ROW', cityBase: 78, city: 'ROW', country: 'US', type: 'airport', nlabel: 'roswell international air center roswell new mexico united states'},
  { icon: 'faPlaneDeparture', label: 'ROP Rota, United States, Rota International, ROP, rota international rota northern mariana islands united states', key:'A_ROP', cityBase: 127, city: 'ROP', country: 'US', type: 'airport', nlabel: 'rota international rota northern mariana islands united states'},
  { icon: 'faPlaneDeparture', label: 'RTI Rote Island, Indonesia, Roti David C. Saudale, RTI, roti david c saudale rote island indonesia', key:'A_RTI', cityBase: 127, city: 'RTI', country: 'ID', type: 'airport', nlabel: 'roti david c saudale rote island indonesia'},
  { icon: 'faPlaneDeparture', label: 'ROT Rotorua, New Zealand, Rotorua Regional, ROT, rotorua regional rotorua new zealand', key:'A_ROT', cityBase: 114, city: 'ROT', country: 'NZ', type: 'airport', nlabel: 'rotorua regional rotorua new zealand'},
  { icon: 'faPlaneDeparture', label: 'RTM Rotterdam, Netherlands, Rotterdam The Hague, RTM, rotterdam the hague rotterdam netherlands', key:'A_RTM', cityBase: 24, city: 'RTM', country: 'NL', type: 'airport', nlabel: 'rotterdam the hague rotterdam netherlands'},
  { icon: 'faPlaneDeparture', label: 'RTA Rotuma, Fiji, Rotuma, RTA, rotuma rotuma fiji', key:'A_RTA', cityBase: 127, city: 'RTA', country: 'FJ', type: 'airport', nlabel: 'rotuma rotuma fiji'},
  { icon: 'faPlaneDeparture', label: 'URO Rouen, France, Rouen, URO, rouen rouen france', key:'A_URO', cityBase: 108, city: 'URO', country: 'FR', type: 'airport', nlabel: 'rouen rouen france'},
  { icon: 'faPlaneDeparture', label: 'ZRJ Weagamow Lake, Canada, Round Lake (Weagamow Lake), ZRJ, round lake (weagamow lake) weagamow lake ontario canada', key:'A_ZRJ', cityBase: 127, city: 'ZRJ', country: 'CA', type: 'airport', nlabel: 'round lake (weagamow lake) weagamow lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'YUY Rouyn-Noranda, Canada, Rouyn-Noranda, YUY, rouyn noranda rouyn noranda quebec canada', key:'A_YUY', cityBase: 127, city: 'YUY', country: 'CA', type: 'airport', nlabel: 'rouyn noranda rouyn noranda quebec canada'},
  { icon: 'faPlaneDeparture', label: 'RVN Rovaniemi, Finland, Rovaniemi, RVN, rovaniemi rovaniemi finland', key:'A_RVN', cityBase: 32, city: 'RVN', country: 'FI', type: 'airport', nlabel: 'rovaniemi rovaniemi finland'},
  { icon: 'faPlaneDeparture', label: 'RXS Roxas, Capiz, Philippines, Roxas, RXS, roxas roxas philippines', key:'A_RXS', cityBase: 110, city: 'RXS', country: 'PH', type: 'airport', nlabel: 'roxas roxas philippines'},
  { icon: 'faPlaneDeparture', label: 'URG Uruguaiana, Brazil, Ruben Berta International, URG, ruben berta international uruguaiana state of rio grande do sul brazil', key:'A_URG', cityBase: 127, city: 'URG', country: 'BR', type: 'airport', nlabel: 'ruben berta international uruguaiana state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'RBY Ruby, United States, Ruby, RBY, ruby ruby alaska united states', key:'A_RBY', cityBase: 127, city: 'RBY', country: 'US', type: 'airport', nlabel: 'ruby ruby alaska united states'},
  { icon: 'faPlaneDeparture', label: 'NDU Rundu, Namibia, Rundu, NDU, rundu rundu namibia', key:'A_NDU', cityBase: 127, city: 'NDU', country: 'NA', type: 'airport', nlabel: 'rundu rundu namibia'},
  { icon: 'faPlaneDeparture', label: 'RBQ Rurrenabaque, Bolivia, Rurrenabaque, RBQ, rurrenabaque rurrenabaque bolivia', key:'A_RBQ', cityBase: 127, city: 'RBQ', country: 'BO', type: 'airport', nlabel: 'rurrenabaque rurrenabaque bolivia'},
  { icon: 'faPlaneDeparture', label: 'RUR Rurutu, French Polynesia, Rurutu, RUR, rurutu rurutu french polynesia', key:'A_RUR', cityBase: 127, city: 'RUR', country: 'PF', type: 'airport', nlabel: 'rurutu rurutu french polynesia'},
  { icon: 'faPlaneDeparture', label: 'RSH Russian Mission, United States, Russian Mission, RSH, russian mission russian mission alaska united states', key:'A_RSH', cityBase: 127, city: 'RSH', country: 'US', type: 'airport', nlabel: 'russian mission russian mission alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RUT Rutland City, United States, Rutland–Southern Vermont Regional, RUT, rutland southern vermont regional rutland city vermont united states', key:'A_RUT', cityBase: 99, city: 'RUT', country: 'US', type: 'airport', nlabel: 'rutland southern vermont regional rutland city vermont united states'},
  { icon: 'faPlaneDeparture', label: 'RZE Rzeszów, Poland, Rzeszów–Jasionka, RZE, rzeszow jasionka rzeszow poland', key:'A_RZE', cityBase: 49, city: 'RZE', country: 'PL', type: 'airport', nlabel: 'rzeszow jasionka rzeszow poland'},
  { icon: 'faPlaneDeparture', label: 'RVH Saint Petersburg, Russia, Rzhevka, RVH, rzhevka saint petersburg russia', key:'A_RVH', cityBase: 127, city: 'LED', country: 'RU', type: 'airport', nlabel: 'rzhevka saint petersburg russia'},
  { icon: 'faPlaneDeparture', label: 'SCN Saarbrücken, Germany, Saarbrücken, SCN, saarbrucken saarbrucken germany', key:'A_SCN', cityBase: 9, city: 'SCN', country: 'DE', type: 'airport', nlabel: 'saarbrucken saarbrucken germany'},
  { icon: 'faPlaneDeparture', label: 'SEB Sabha, Libya, Sabha, SEB, sabha sabha libya', key:'A_SEB', cityBase: 127, city: 'SEB', country: 'LY', type: 'airport', nlabel: 'sabha sabha libya'},
  { icon: 'faPlaneDeparture', label: 'SAW Istanbul, Turkey, Sabiha Gökçen International, SAW, sabiha gokcen international istanbul turkey', key:'A_SAW', cityBase: 10, city: 'IST', country: 'TR', type: 'airport', nlabel: 'sabiha gokcen international istanbul turkey'},
  { icon: 'faPlaneDeparture', label: 'AFZ Sabzevar, Iran, Sabzevar, AFZ, sabzevar sabzevar iran', key:'A_AFZ', cityBase: 127, city: 'AFZ', country: 'IR', type: 'airport', nlabel: 'sabzevar sabzevar iran'},
  { icon: 'faPlaneDeparture', label: 'HIN Jinju, South Korea, Sacheon, HIN, sacheon jinju south korea', key:'A_HIN', cityBase: 127, city: 'HIN', country: 'KR', type: 'airport', nlabel: 'sacheon jinju south korea'},
  { icon: 'faPlaneDeparture', label: 'ZPB Sachigo Lake, Canada, Sachigo Lake, ZPB, sachigo lake sachigo lake ontario canada', key:'A_ZPB', cityBase: 127, city: 'ZPB', country: 'CA', type: 'airport', nlabel: 'sachigo lake sachigo lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'YSY Sachs Harbour, Canada, Sachs Harbour (David Nasogaluak Jr. Saaryuaq), YSY, sachs harbour (david nasogaluak jr saaryuaq) sachs harbour northwest territories canada', key:'A_YSY', cityBase: 127, city: 'YSY', country: 'CA', type: 'airport', nlabel: 'sachs harbour (david nasogaluak jr saaryuaq) sachs harbour northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'SMF Sacramento, United States, Sacramento International, SMF, sacramento international sacramento california united states', key:'A_SMF', cityBase: 5, city: 'SAC', country: 'US', type: 'airport', nlabel: 'sacramento international sacramento california united states'},
  { icon: 'faPlaneDeparture', label: 'SKO Sokoto, Nigeria, Sadiq Abubakar III International, SKO, sadiq abubakar iii international sokoto nigeria', key:'A_SKO', cityBase: 127, city: 'SKO', country: 'NG', type: 'airport', nlabel: 'sadiq abubakar iii international sokoto nigeria'},
  { icon: 'faPlaneDeparture', label: 'HSG Saga, Japan, Saga, HSG, saga saga japan', key:'A_HSG', cityBase: 127, city: 'HSG', country: 'JP', type: 'airport', nlabel: 'saga saga japan'},
  { icon: 'faPlaneDeparture', label: 'LUH Ludhiana, India, Sahnewal, LUH, sahnewal ludhiana india', key:'A_LUH', cityBase: 127, city: 'LUH', country: 'IN', type: 'airport', nlabel: 'sahnewal ludhiana india'},
  { icon: 'faPlaneDeparture', label: 'SPD Saidpur, Bangladesh, Saidpur, SPD, saidpur saidpur bangladesh', key:'A_SPD', cityBase: 127, city: 'SPD', country: 'BD', type: 'airport', nlabel: 'saidpur saidpur bangladesh'},
  { icon: 'faPlaneDeparture', label: 'HLE Jamestown, St. Helena, Saint Helena, HLE, saint helena jamestown st helena', key:'A_HLE', cityBase: 122, city: 'HLE', country: 'SH', type: 'airport', nlabel: 'saint helena jamestown st helena'},
  { icon: 'faPlaneDeparture', label: 'YSJ Saint John, Canada, Saint John, YSJ, saint john saint john new brunswick canada', key:'A_YSJ', cityBase: 91, city: 'YSJ', country: 'CA', type: 'airport', nlabel: 'saint john saint john new brunswick canada'},
  { icon: 'faPlaneDeparture', label: 'YIF Saint-Augustin, Côte-Nord, Quebec, Canada, Saint-Augustin, YIF, saint augustin saint augustin quebec canada', key:'A_YIF', cityBase: 127, city: 'YIF', country: 'CA', type: 'airport', nlabel: 'saint augustin saint augustin quebec canada'},
  { icon: 'faPlaneDeparture', label: 'EBU Saint-Étienne, France, Saint-Étienne–Bouthéon, EBU, saint etienne boutheon saint etienne france', key:'A_EBU', cityBase: 36, city: 'EBU', country: 'FR', type: 'airport', nlabel: 'saint etienne boutheon saint etienne france'},
  { icon: 'faPlaneDeparture', label: 'FSP Saint-Pierre, St. Pierre & Miquelon, Saint-Pierre, FSP, saint pierre saint pierre st pierre and miquelon', key:'A_FSP', cityBase: 127, city: 'FSP', country: 'PM', type: 'airport', nlabel: 'saint pierre saint pierre st pierre and miquelon'},
  { icon: 'faPlaneDeparture', label: 'SMS Île Sainte-Marie, Madagascar, Sainte Marie, SMS, sainte marie ile sainte marie madagascar', key:'A_SMS', cityBase: 127, city: 'SMS', country: 'MG', type: 'airport', nlabel: 'sainte marie ile sainte marie madagascar'},
  { icon: 'faPlaneDeparture', label: 'SPN Saipan, United States, Saipan International, SPN, saipan international saipan northern mariana islands united states', key:'A_SPN', cityBase: 46, city: 'SPN', country: 'US', type: 'airport', nlabel: 'saipan international saipan northern mariana islands united states'},
  { icon: 'faPlaneDeparture', label: 'SNO Sakon Nakhon, Thailand, Sakon Nakhon, SNO, sakon nakhon sakon nakhon thailand', key:'A_SNO', cityBase: 127, city: 'SNO', country: 'TH', type: 'airport', nlabel: 'sakon nakhon sakon nakhon thailand'},
  { icon: 'faPlaneDeparture', label: 'MVR Maroua, Cameroon, Salak, MVR, salak maroua cameroon', key:'A_MVR', cityBase: 127, city: 'MVR', country: 'CM', type: 'airport', nlabel: 'salak maroua cameroon'},
  { icon: 'faPlaneDeparture', label: 'SLL Salalah, Oman, Salalah, SLL, salalah salalah oman', key:'A_SLL', cityBase: 64, city: 'SLL', country: 'OM', type: 'airport', nlabel: 'salalah salalah oman'},
  { icon: 'faPlaneDeparture', label: 'SLM Salamanca, Spain, Salamanca, SLM, salamanca salamanca spain', key:'A_SLM', cityBase: 23, city: 'SLM', country: 'ES', type: 'airport', nlabel: 'salamanca salamanca spain'},
  { icon: 'faPlaneDeparture', label: 'SLY Salekhard, Russia, Salekhard, SLY, salekhard salekhard russia', key:'A_SLY', cityBase: 48, city: 'SLY', country: 'RU', type: 'airport', nlabel: 'salekhard salekhard russia'},
  { icon: 'faPlaneDeparture', label: 'POA Porto Alegre, Brazil, Salgado Filho International, POA, salgado filho international porto alegre state of rio grande do sul brazil', key:'A_POA', cityBase: 52, city: 'POA', country: 'BR', type: 'airport', nlabel: 'salgado filho international porto alegre state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'SLN Salina, United States, Salina Regional, SLN, salina regional salina kansas united states', key:'A_SLN', cityBase: 109, city: 'SLN', country: 'US', type: 'airport', nlabel: 'salina regional salina kansas united states'},
  { icon: 'faPlaneDeparture', label: 'SBY Ocean City, United States, Salisbury–Ocean City–Wicomico Regional, SBY, salisbury ocean city wicomico regional ocean city maryland united states', key:'A_SBY', cityBase: 87, city: 'SBY', country: 'US', type: 'airport', nlabel: 'salisbury ocean city wicomico regional ocean city maryland united states'},
  { icon: 'faPlaneDeparture', label: 'YZG Salluit, Canada, Salluit, YZG, salluit salluit quebec canada', key:'A_YZG', cityBase: 127, city: 'YSW', country: 'CA', type: 'airport', nlabel: 'salluit salluit quebec canada'},
  { icon: 'faPlaneDeparture', label: 'SLX Salt Cay, Turks Islands, Turks & Caicos Islands, Salt Cay, SLX, salt cay salt cay turks and caicos islands', key:'A_SLX', cityBase: 127, city: 'SLX', country: 'TC', type: 'airport', nlabel: 'salt cay salt cay turks and caicos islands'},
  { icon: 'faPlaneDeparture', label: 'SLC Salt Lake City, United States, Salt Lake City International, SLC, salt lake city international salt lake city utah united states', key:'A_SLC', cityBase: 2, city: 'SLC', country: 'US', type: 'airport', nlabel: 'salt lake city international salt lake city utah united states'},
  { icon: 'faPlaneDeparture', label: 'SZG Salzburg, Austria, Salzburg, SZG, salzburg salzburg austria', key:'A_SZG', cityBase: 1, city: 'SZG', country: 'AT', type: 'airport', nlabel: 'salzburg salzburg austria'},
  { icon: 'faPlaneDeparture', label: 'QOW Owerri, Nigeria, Sam Mbakwe, QOW, sam mbakwe owerri nigeria', key:'A_QOW', cityBase: 127, city: 'QOW', country: 'NG', type: 'airport', nlabel: 'sam mbakwe owerri nigeria'},
  { icon: 'faPlaneDeparture', label: 'MDC Manado, Indonesia, Sam Ratulangi International, MDC, sam ratulangi international manado indonesia', key:'A_MDC', cityBase: 89, city: 'MDC', country: 'ID', type: 'airport', nlabel: 'sam ratulangi international manado indonesia'},
  { icon: 'faPlaneDeparture', label: 'AZS Samaná, Dominican Republic, Samaná El Catey International, AZS, samana el catey international samana dominican republic', key:'A_AZS', cityBase: 109, city: 'AZS', country: 'DO', type: 'airport', nlabel: 'samana el catey international samana dominican republic'},
  { icon: 'faPlaneDeparture', label: 'SRI Samarinda, Indonesia, Samarinda Temindung, SRI, samarinda temindung samarinda indonesia', key:'A_SRI', cityBase: 127, city: 'SRI', country: 'ID', type: 'airport', nlabel: 'samarinda temindung samarinda indonesia'},
  { icon: 'faPlaneDeparture', label: 'SKD Samarkand, Uzbekistan, Samarkand International, SKD, samarkand international samarkand uzbekistan', key:'A_SKD', cityBase: 51, city: 'SKD', country: 'UZ', type: 'airport', nlabel: 'samarkand international samarkand uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'SVB Sambava, Madagascar, Sambava, SVB, sambava sambava madagascar', key:'A_SVB', cityBase: 127, city: 'SVB', country: 'MG', type: 'airport', nlabel: 'sambava sambava madagascar'},
  { icon: 'faPlaneDeparture', label: 'UAS Samburu County, Kenya, Samburu, UAS, samburu samburu county kenya', key:'A_UAS', cityBase: 127, city: 'UAS', country: 'KE', type: 'airport', nlabel: 'samburu samburu county kenya'},
  { icon: 'faPlaneDeparture', label: 'SMI Samos, Greece, Samos International, SMI, samos international samos greece', key:'A_SMI', cityBase: 14, city: 'SMI', country: 'GR', type: 'airport', nlabel: 'samos international samos greece'},
  { icon: 'faPlaneDeparture', label: 'SZF Samsun, Turkey, Samsun-Çarşamba, SZF, samsun carsamba samsun turkey', key:'A_SZF', cityBase: 15, city: 'SZF', country: 'TR', type: 'airport', nlabel: 'samsun carsamba samsun turkey'},
  { icon: 'faPlaneDeparture', label: 'USM Ko Samui, Thailand, Samui, USM, samui ko samui thailand', key:'A_USM', cityBase: 37, city: 'USM', country: 'TH', type: 'airport', nlabel: 'samui ko samui thailand'},
  { icon: 'faPlaneDeparture', label: 'SJT San Angelo, United States, San Angelo Regional, SJT, san angelo regional san angelo texas united states', key:'A_SJT', cityBase: 94, city: 'SJT', country: 'US', type: 'airport', nlabel: 'san angelo regional san angelo texas united states'},
  { icon: 'faPlaneDeparture', label: 'SAT San Antonio, United States, San Antonio International, SAT, san antonio international san antonio texas united states', key:'A_SAT', cityBase: 5, city: 'SAT', country: 'US', type: 'airport', nlabel: 'san antonio international san antonio texas united states'},
  { icon: 'faPlaneDeparture', label: 'BRC Bariloche, Argentina, San Carlos de Bariloche, BRC, san carlos de bariloche bariloche rio negro argentina', key:'A_BRC', cityBase: 50, city: 'BRC', country: 'AR', type: 'airport', nlabel: 'san carlos de bariloche bariloche rio negro argentina'},
  { icon: 'faPlaneDeparture', label: 'SCY San Cristóbal Island, Ecuador, San Cristóbal, SCY, san cristobal san cristobal island ecuador', key:'A_SCY', cityBase: 55, city: 'SCY', country: 'EC', type: 'airport', nlabel: 'san cristobal san cristobal island ecuador'},
  { icon: 'faPlaneDeparture', label: 'SAN San Diego, United States, San Diego International, SAN, san diego international san diego california united states', key:'A_SAN', cityBase: 1, city: 'SAN', country: 'US', type: 'airport', nlabel: 'san diego international san diego california united states'},
  { icon: 'faPlaneDeparture', label: 'PEG Perugia, Italy, San Francesco d Assisi, PEG, san francesco d assisi perugia italy', key:'A_PEG', cityBase: 19, city: 'PEG', country: 'IT', type: 'airport', nlabel: 'san francesco d assisi perugia italy'},
  { icon: 'faPlaneDeparture', label: 'SFO San Francisco, United States, San Francisco International, SFO, san francisco international san francisco california united states', key:'A_SFO', cityBase: 0, city: 'SFO', country: 'US', type: 'airport', nlabel: 'san francisco international san francisco california united states'},
  { icon: 'faPlaneDeparture', label: 'SJI San Jose, Occidental Mindoro, Philippines, San Jose, SJI, san jose san jose philippines', key:'A_SJI', cityBase: 113, city: 'SJI', country: 'PH', type: 'airport', nlabel: 'san jose san jose philippines'},
  { icon: 'faPlaneDeparture', label: 'SJC San Jose, United States, San Jose International, SJC, san jose international san jose california united states', key:'A_SJC', cityBase: 4, city: 'SJC', country: 'US', type: 'airport', nlabel: 'san jose international san jose california united states'},
  { icon: 'faPlaneDeparture', label: 'SIC Isla San José, Panama, San Jose Island, SIC, san jose island isla san jose panama', key:'A_SIC', cityBase: 127, city: 'SIC', country: 'PA', type: 'airport', nlabel: 'san jose island isla san jose panama'},
  { icon: 'faPlaneDeparture', label: 'IPI Ipiales, Colombia, San Luis, IPI, san luis ipiales colombia', key:'A_IPI', cityBase: 127, city: 'IPI', country: 'CO', type: 'airport', nlabel: 'san luis ipiales colombia'},
  { icon: 'faPlaneDeparture', label: 'SBP San Luis Obispo, United States, San Luis Obispo County Regional, SBP, san luis obispo county regional san luis obispo california united states', key:'A_SBP', cityBase: 34, city: 'SBP', country: 'US', type: 'airport', nlabel: 'san luis obispo county regional san luis obispo california united states'},
  { icon: 'faPlaneDeparture', label: 'SLP San Luis Potosí, Mexico, San Luis Potosí International, SLP, san luis potosi international san luis potosi mexico', key:'A_SLP', cityBase: 43, city: 'SLP', country: 'MX', type: 'airport', nlabel: 'san luis potosi international san luis potosi mexico'},
  { icon: 'faPlaneDeparture', label: 'ALS Alamosa, United States, San Luis Valley Regional, ALS, san luis valley regional alamosa colorado united states', key:'A_ALS', cityBase: 125, city: 'ALS', country: 'US', type: 'airport', nlabel: 'san luis valley regional alamosa colorado united states'},
  { icon: 'faPlaneDeparture', label: 'SPR San Pedro Town, Belize, San Pedro, SPR, san pedro san pedro town belize', key:'A_SPR', cityBase: 125, city: 'SPR', country: 'BZ', type: 'airport', nlabel: 'san pedro san pedro town belize'},
  { icon: 'faPlaneDeparture', label: 'SPY San-Pédro, Ivory Coast, Côte d’Ivoire, San Pédro, SPY, san pedro san pedro cote d ivoire', key:'A_SPY', cityBase: 127, city: 'SPY', country: 'CI', type: 'airport', nlabel: 'san pedro san pedro cote d ivoire'},
  { icon: 'faPlaneDeparture', label: 'ZSA San Salvador Island, Bahamas, San Salvador, ZSA, san salvador san salvador island bahamas', key:'A_ZSA', cityBase: 91, city: 'ZSA', country: 'BS', type: 'airport', nlabel: 'san salvador san salvador island bahamas'},
  { icon: 'faPlaneDeparture', label: 'EAS Donostia / San Sebastián, Spain, San Sebastián, EAS, san sebastian donostia san sebastian spain', key:'A_EAS', cityBase: 20, city: 'EAS', country: 'ES', type: 'airport', nlabel: 'san sebastian donostia san sebastian spain'},
  { icon: 'faPlaneDeparture', label: 'SOM San Tomé, Venezuela, San Tomé, SOM, san tome san tome venezuela', key:'A_SOM', cityBase: 127, city: 'SOM', country: 'VE', type: 'airport', nlabel: 'san tome san tome venezuela'},
  { icon: 'faPlaneDeparture', label: 'SAH Sana\'a, Yemen, Sana\'a International Airport, SAH, sana a international airport sana a yemen', key:'A_SAH', cityBase: 121, city: 'SAH', country: 'YE', type: 'airport', nlabel: 'sana a international airport sana a yemen'},
  { icon: 'faPlaneDeparture', label: 'SDG Sanandij, Iran, Sanandaj, SDG, sanandaj sanandij iran', key:'A_SDG', cityBase: 122, city: 'SDG', country: 'IR', type: 'airport', nlabel: 'sanandaj sanandij iran'},
  { icon: 'faPlaneDeparture', label: 'SDP Sand Point, United States, Sand Point, SDP, sand point sand point alaska united states', key:'A_SDP', cityBase: 127, city: 'SDP', country: 'US', type: 'airport', nlabel: 'sand point sand point alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SDK Sandakan, Malaysia, Sandakan, SDK, sandakan sandakan malaysia', key:'A_SDK', cityBase: 85, city: 'SDK', country: 'MY', type: 'airport', nlabel: 'sandakan sandakan malaysia'},
  { icon: 'faPlaneDeparture', label: 'SDN Sandane, Norway, Sandane Airport, Anda, SDN, sandane airport sandane norway', key:'A_SDN', cityBase: 127, city: 'SDN', country: 'NO', type: 'airport', nlabel: 'sandane airport sandane norway'},
  { icon: 'faPlaneDeparture', label: 'NDY Sanday, Orkney, United Kingdom, Sanday, NDY, sanday sanday united kingdom', key:'A_NDY', cityBase: -873, city: 'NDY', country: 'GB', type: 'airport', nlabel: 'sanday sanday united kingdom'},
  { icon: 'faPlaneDeparture', label: 'TRF Oslo, Norway, Sandefjord Airport, Torp, TRF, sandefjord airport oslo norway', key:'A_TRF', cityBase: 54, city: 'OSL', country: 'NO', type: 'airport', nlabel: 'sandefjord airport oslo norway'},
  { icon: 'faPlaneDeparture', label: 'SSJ Sandnessjøen, Norway, Sandnessjøen Airport, Stokka, SSJ, sandnessjoen airport sandnessjoen norway', key:'A_SSJ', cityBase: 127, city: 'SSJ', country: 'NO', type: 'airport', nlabel: 'sandnessjoen airport sandnessjoen norway'},
  { icon: 'faPlaneDeparture', label: 'YZP Sandspit, Canada, Sandspit, YZP, sandspit sandspit british columbia canada', key:'A_YZP', cityBase: 127, city: 'YZP', country: 'CA', type: 'airport', nlabel: 'sandspit sandspit british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'ZSJ Sandy Lake, Canada, Sandy Lake, ZSJ, sandy lake sandy lake ontario canada', key:'A_ZSJ', cityBase: 127, city: 'ZSJ', country: 'CA', type: 'airport', nlabel: 'sandy lake sandy lake ontario canada'},
  { icon: 'faPlaneDeparture', label: 'TWT Tawi-Tawi, Philippines, Sanga-Sanga, TWT, sanga sanga tawi tawi philippines', key:'A_TWT', cityBase: 127, city: 'TWT', country: 'PH', type: 'airport', nlabel: 'sanga sanga tawi tawi philippines'},
  { icon: 'faPlaneDeparture', label: 'MBJ Montego Bay, Jamaica, Sangster International, MBJ, sangster international montego bay jamaica', key:'A_MBJ', cityBase: 19, city: 'MBJ', country: 'JM', type: 'airport', nlabel: 'sangster international montego bay jamaica'},
  { icon: 'faPlaneDeparture', label: 'TTU Tétouan, Morocco, Sania Ramel, TTU, sania ramel tetouan morocco', key:'A_TTU', cityBase: 127, city: 'TTU', country: 'MA', type: 'airport', nlabel: 'sania ramel tetouan morocco'},
  { icon: 'faPlaneDeparture', label: 'YSK Sanikiluaq, Canada, Sanikiluaq, YSK, sanikiluaq sanikiluaq nunavut canada', key:'A_YSK', cityBase: 127, city: 'YSK', country: 'CA', type: 'airport', nlabel: 'sanikiluaq sanikiluaq nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'GNY Şanlıurfa, Turkey, Sanliurfa, GNY, sanliurfa sanliurfa turkey', key:'A_GNY', cityBase: 21, city: 'SFQ', country: 'TR', type: 'airport', nlabel: 'sanliurfa sanliurfa turkey'},
  { icon: 'faPlaneDeparture', label: 'SQJ Sanming, China, Sanming Shaxian, SQJ, sanming shaxian sanming china', key:'A_SQJ', cityBase: 71, city: 'SQJ', country: 'CN', type: 'airport', nlabel: 'sanming shaxian sanming china'},
  { icon: 'faPlaneDeparture', label: 'NNB Santa Ana Island, Solomon Islands, Santa Ana, NNB, santa ana santa ana island solomon islands', key:'A_NNB', cityBase: 127, city: 'NNB', country: 'SB', type: 'airport', nlabel: 'santa ana santa ana island solomon islands'},
  { icon: 'faPlaneDeparture', label: 'SBA Santa Barbara, United States, Santa Barbara Municipal, SBA, santa barbara municipal santa barbara california united states', key:'A_SBA', cityBase: 28, city: 'SBA', country: 'US', type: 'airport', nlabel: 'santa barbara municipal santa barbara california united states'},
  { icon: 'faPlaneDeparture', label: 'SCZ Santa Cruz Islands, Solomon Islands, Santa Cruz/Graciosa Bay/Luova, SCZ, santa cruz graciosa bay luova santa cruz islands solomon islands', key:'A_SCZ', cityBase: 127, city: 'SCZ', country: 'SB', type: 'airport', nlabel: 'santa cruz graciosa bay luova santa cruz islands solomon islands'},
  { icon: 'faPlaneDeparture', label: 'SAF Santa Fe, United States, Santa Fe Municipal, SAF, santa fe municipal santa fe new mexico united states', key:'A_SAF', cityBase: 39, city: 'SAF', country: 'US', type: 'airport', nlabel: 'santa fe municipal santa fe new mexico united states'},
  { icon: 'faPlaneDeparture', label: 'GYN Goiânia, Brazil, Santa Genoveva, GYN, santa genoveva goiania state of goias brazil', key:'A_GYN', cityBase: 55, city: 'GYN', country: 'BR', type: 'airport', nlabel: 'santa genoveva goiania state of goias brazil'},
  { icon: 'faPlaneDeparture', label: 'AJU Aracaju, Brazil, Santa Maria, AJU, santa maria aracaju state of sergipe brazil', key:'A_AJU', cityBase: 104, city: 'AJU', country: 'BR', type: 'airport', nlabel: 'santa maria aracaju state of sergipe brazil'},
  { icon: 'faPlaneDeparture', label: 'RIA Santa Maria, Brazil, Santa Maria, RIA, santa maria santa maria state of rio grande do sul brazil', key:'A_RIA', cityBase: 127, city: 'RIA', country: 'BR', type: 'airport', nlabel: 'santa maria santa maria state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'SMA Santa Maria Island, Portugal, Santa Maria, SMA, santa maria santa maria island portugal', key:'A_SMA', cityBase: 32, city: 'SMA', country: 'PT', type: 'airport', nlabel: 'santa maria santa maria island portugal'},
  { icon: 'faPlaneDeparture', label: 'SMG Santa Maria, Peru, Santa Maria, SMG, santa maria santa maria peru', key:'A_SMG', cityBase: 127, city: 'SMG', country: 'PE', type: 'airport', nlabel: 'santa maria santa maria peru'},
  { icon: 'faPlaneDeparture', label: 'SMX Santa Maria, United States, Santa Maria Public, SMX, santa maria public santa maria california united states', key:'A_SMX', cityBase: 89, city: 'SMX', country: 'US', type: 'airport', nlabel: 'santa maria public santa maria california united states'},
  { icon: 'faPlaneDeparture', label: 'RSA Santa Rosa, Argentina, Santa Rosa, RSA, santa rosa santa rosa la pampa argentina', key:'A_RSA', cityBase: 127, city: 'RSA', country: 'AR', type: 'airport', nlabel: 'santa rosa santa rosa la pampa argentina'},
  { icon: 'faPlaneDeparture', label: 'ETR Santa Rosa Canton, Ecuador, Santa Rosa International, ETR, santa rosa international santa rosa canton ecuador', key:'A_ETR', cityBase: 91, city: 'ETR', country: 'EC', type: 'airport', nlabel: 'santa rosa international santa rosa canton ecuador'},
  { icon: 'faPlaneDeparture', label: 'SDR Santander, Spain, Santander, SDR, santander santander spain', key:'A_SDR', cityBase: 16, city: 'SDR', country: 'ES', type: 'airport', nlabel: 'santander santander spain'},
  { icon: 'faPlaneDeparture', label: 'STM Santarém, Brazil, Santarém-Maestro Wilson Fonseca, STM, santarem maestro wilson fonseca santarem state of para brazil', key:'A_STM', cityBase: 121, city: 'STM', country: 'BR', type: 'airport', nlabel: 'santarem maestro wilson fonseca santarem state of para brazil'},
  { icon: 'faPlaneDeparture', label: 'SCQ Santiago de Compostela, Spain, Santiago de Compostela, SCQ, santiago de compostela santiago de compostela spain 1', key:'A_SCQ', cityBase: 12, city: 'SCQ', country: 'ES', type: 'airport', nlabel: 'santiago de compostela santiago de compostela spain 1'},
  { icon: 'faPlaneDeparture', label: 'PMV Porlamar, Venezuela, Santiago Mariño Caribbean International, PMV, santiago marino caribbean international porlamar venezuela', key:'A_PMV', cityBase: 47, city: 'PMV', country: 'VE', type: 'airport', nlabel: 'santiago marino caribbean international porlamar venezuela'},
  { icon: 'faPlaneDeparture', label: 'AUC Arauca, Colombia, Santiago Pérez Quiroz, AUC, santiago perez quiroz arauca colombia', key:'A_AUC', cityBase: 127, city: 'AUC', country: 'CO', type: 'airport', nlabel: 'santiago perez quiroz arauca colombia'},
  { icon: 'faPlaneDeparture', label: 'SON Luganville, Vanuatu, Santo-Pekoa International, SON, santo pekoa international luganville vanuatu', key:'A_SON', cityBase: 127, city: 'SON', country: 'VU', type: 'airport', nlabel: 'santo pekoa international luganville vanuatu'},
  { icon: 'faPlaneDeparture', label: 'JTR Santorini, Greece, Santorini (Thira) National, JTR, santorini (thira) national santorini greece', key:'A_JTR', cityBase: 9, city: 'JTR', country: 'GR', type: 'airport', nlabel: 'santorini (thira) national santorini greece'},
  { icon: 'faPlaneDeparture', label: 'SDU Rio de Janeiro, Brazil, Santos Dumont, SDU, santos dumont rio de janeiro state of rio de janeiro brazil', key:'A_SDU', cityBase: 96, city: 'RIO', country: 'BR', type: 'airport', nlabel: 'santos dumont rio de janeiro state of rio de janeiro brazil'},
  { icon: 'faPlaneDeparture', label: 'SYX Sanya, China, Sanya Phoenix International, SYX, sanya phoenix international sanya china', key:'A_SYX', cityBase: 47, city: 'SYX', country: 'CN', type: 'airport', nlabel: 'sanya phoenix international sanya china'},
  { icon: 'faPlaneDeparture', label: 'SFL São Filipe, Cape Verde, São Filipe, SFL, sao filipe sao filipe cape verde', key:'A_SFL', cityBase: 127, city: 'SFL', country: 'CV', type: 'airport', nlabel: 'sao filipe sao filipe cape verde'},
  { icon: 'faPlaneDeparture', label: 'SJZ São Jorge Island, Portugal, Sao Jorge, SJZ, sao jorge sao jorge island portugal', key:'A_SJZ', cityBase: 127, city: 'SJZ', country: 'PT', type: 'airport', nlabel: 'sao jorge sao jorge island portugal'},
  { icon: 'faPlaneDeparture', label: 'SJP São José do Rio Preto, Brazil, São José do Rio Preto, SJP, sao jose do rio preto sao jose do rio preto state of sao paulo brazil', key:'A_SJP', cityBase: 125, city: 'SJP', country: 'BR', type: 'airport', nlabel: 'sao jose do rio preto sao jose do rio preto state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'CGH São Paulo, Brazil, São Paulo–Congonhas, CGH, sao paulo congonhas sao paulo state of sao paulo brazil', key:'A_CGH', cityBase: 79, city: 'SAO', country: 'BR', type: 'airport', nlabel: 'sao paulo congonhas sao paulo state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'GRU São Paulo, Brazil, São Paulo–Guarulhos International, GRU, sao paulo guarulhos international sao paulo state of sao paulo brazil', key:'A_GRU', cityBase: 31, city: 'SAO', country: 'BR', type: 'airport', nlabel: 'sao paulo guarulhos international sao paulo state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'TMS São Tomé, São Tomé & Príncipe, São Tomé International, TMS, sao tome international sao tome sao tome and principe', key:'A_TMS', cityBase: 85, city: 'TMS', country: 'ST', type: 'airport', nlabel: 'sao tome international sao tome sao tome and principe'},
  { icon: 'faPlaneDeparture', label: 'SSR Sara, Vanuatu, Sara, SSR, sara sara vanuatu', key:'A_SSR', cityBase: 127, city: 'SSR', country: 'VU', type: 'airport', nlabel: 'sara sara vanuatu'},
  { icon: 'faPlaneDeparture', label: 'SJJ Sarajevo, Bosnia & Herzegovina, Sarajevo International, SJJ, sarajevo international sarajevo bosnia and herzegovina', key:'A_SJJ', cityBase: 39, city: 'SJJ', country: 'BA', type: 'airport', nlabel: 'sarajevo international sarajevo bosnia and herzegovina'},
  { icon: 'faPlaneDeparture', label: 'SKX Saransk, Russia, Saransk, SKX, saransk saransk russia', key:'A_SKX', cityBase: 54, city: 'SKX', country: 'RU', type: 'airport', nlabel: 'saransk saransk russia'},
  { icon: 'faPlaneDeparture', label: 'SRQ Sarasota, United States, Sarasota–Bradenton International, SRQ, sarasota bradenton international sarasota florida united states', key:'A_SRQ', cityBase: 27, city: 'SRQ', country: 'US', type: 'airport', nlabel: 'sarasota bradenton international sarasota florida united states'},
  { icon: 'faPlaneDeparture', label: 'RTW Saratov, Russia, Saratov Tsentralny, RTW, saratov tsentralny saratov russia', key:'A_RTW', cityBase: 45, city: 'RTW', country: 'RU', type: 'airport', nlabel: 'saratov tsentralny saratov russia'},
  { icon: 'faPlaneDeparture', label: 'AMD Ahmedabad, India, Sardar Vallabhbhai Patel International, AMD, sardar vallabhbhai patel international ahmedabad india', key:'A_AMD', cityBase: 24, city: 'AMD', country: 'IN', type: 'airport', nlabel: 'sardar vallabhbhai patel international ahmedabad india'},
  { icon: 'faPlaneDeparture', label: 'YZR Sarnia, Canada, Sarnia Chris Hadfield, YZR, sarnia chris hadfield sarnia ontario canada', key:'A_YZR', cityBase: 103, city: 'YZR', country: 'CA', type: 'airport', nlabel: 'sarnia chris hadfield sarnia ontario canada'},
  { icon: 'faPlaneDeparture', label: 'KGF Karaganda, Kazakhstan, Sary-Arka, KGF, sary arka karaganda kazakhstan', key:'A_KGF', cityBase: 78, city: 'KGF', country: 'KZ', type: 'airport', nlabel: 'sary arka karaganda kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'YXE Saskatoon, Canada, Saskatoon John G. Diefenbaker International, YXE, saskatoon john g diefenbaker international saskatoon saskatchewan canada', key:'A_YXE', cityBase: 57, city: 'YXE', country: 'CA', type: 'airport', nlabel: 'saskatoon john g diefenbaker international saskatoon saskatchewan canada'},
  { icon: 'faPlaneDeparture', label: 'SYS Saskylakh, Russia, Saskylakh, SYS, saskylakh saskylakh russia', key:'A_SYS', cityBase: 127, city: 'SYS', country: 'RU', type: 'airport', nlabel: 'saskylakh saskylakh russia'},
  { icon: 'faPlaneDeparture', label: 'SUJ Satu Mare, Romania, Satu Mare International, SUJ, satu mare international satu mare romania', key:'A_SUJ', cityBase: 30, city: 'SUJ', country: 'RO', type: 'airport', nlabel: 'satu mare international satu mare romania'},
  { icon: 'faPlaneDeparture', label: 'SFN Santa Fe, Argentina, Sauce Viejo, SFN, sauce viejo santa fe santa fe argentina', key:'A_SFN', cityBase: 127, city: 'SFN', country: 'AR', type: 'airport', nlabel: 'sauce viejo santa fe santa fe argentina'},
  { icon: 'faPlaneDeparture', label: 'YAM Sault Ste. Marie, Canada, Sault Ste. Marie, YAM, sault ste marie sault ste marie ontario canada', key:'A_YAM', cityBase: 106, city: 'YAM', country: 'CA', type: 'airport', nlabel: 'sault ste marie sault ste marie ontario canada'},
  { icon: 'faPlaneDeparture', label: 'SXK Saumlaki, Indonesia, Saumlaki Olilit, SXK, saumlaki olilit saumlaki indonesia', key:'A_SXK', cityBase: 127, city: 'SXK', country: 'ID', type: 'airport', nlabel: 'saumlaki olilit saumlaki indonesia'},
  { icon: 'faPlaneDeparture', label: 'VHC Saurimo, Angola, Saurimo, VHC, saurimo saurimo angola', key:'A_VHC', cityBase: 127, city: 'VHC', country: 'AO', type: 'airport', nlabel: 'saurimo saurimo angola'},
  { icon: 'faPlaneDeparture', label: 'SAV Savannah, United States, Savannah/Hilton Head International, SAV, savannah hilton head international savannah georgia united states', key:'A_SAV', cityBase: 15, city: 'SAV', country: 'US', type: 'airport', nlabel: 'savannah hilton head international savannah georgia united states'},
  { icon: 'faPlaneDeparture', label: 'ZVK Savannakhet, Laos, Savannakhet, ZVK, savannakhet savannakhet laos', key:'A_ZVK', cityBase: 127, city: 'ZVK', country: 'LA', type: 'airport', nlabel: 'savannakhet savannakhet laos'},
  { icon: 'faPlaneDeparture', label: 'SVL Savonlinna, Finland, Savonlinna, SVL, savonlinna savonlinna finland', key:'A_SVL', cityBase: 127, city: 'SVL', country: 'FI', type: 'airport', nlabel: 'savonlinna savonlinna finland'},
  { icon: 'faPlaneDeparture', label: 'SVA Savoonga, United States, Savoonga, SVA, savoonga savoonga alaska united states', key:'A_SVA', cityBase: 127, city: 'SVA', country: 'US', type: 'airport', nlabel: 'savoonga savoonga alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SVU Savusavu, Fiji, Savusavu, SVU, savusavu savusavu fiji', key:'A_SVU', cityBase: 118, city: 'SVU', country: 'FJ', type: 'airport', nlabel: 'savusavu savusavu fiji'},
  { icon: 'faPlaneDeparture', label: 'MQT Marquette, United States, Sawyer International, MQT, sawyer international marquette michigan united states', key:'A_MQT', cityBase: 77, city: 'MQT', country: 'US', type: 'airport', nlabel: 'sawyer international marquette michigan united states'},
  { icon: 'faPlaneDeparture', label: 'IAO Del Carmen, Philippines, Sayak, IAO, sayak del carmen philippines', key:'A_IAO', cityBase: 77, city: 'IAO', country: 'PH', type: 'airport', nlabel: 'sayak del carmen philippines'},
  { icon: 'faPlaneDeparture', label: 'GXF Seiyun, Yemen, Sayun, GXF, sayun seiyun yemen', key:'A_GXF', cityBase: 127, city: 'GXF', country: 'YE', type: 'airport', nlabel: 'sayun seiyun yemen'},
  { icon: 'faPlaneDeparture', label: 'SCM Scammon Bay, United States, Scammon Bay, SCM, scammon bay scammon bay alaska united states', key:'A_SCM', cityBase: 127, city: 'SCM', country: 'US', type: 'airport', nlabel: 'scammon bay scammon bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YKL Schefferville, Canada, Schefferville, YKL, schefferville schefferville quebec canada', key:'A_YKL', cityBase: 127, city: 'YKL', country: 'CA', type: 'airport', nlabel: 'schefferville schefferville quebec canada'},
  { icon: 'faPlaneDeparture', label: 'SDV Tel Aviv, Israel, Sde Dov, SDV, sde dov tel aviv israel', key:'A_SDV', cityBase: 82, city: 'TLV', country: 'IL', type: 'airport', nlabel: 'sde dov tel aviv israel'},
  { icon: 'faPlaneDeparture', label: 'SEA Seattle, United States, Seattle–Tacoma International, SEA, seattle tacoma international seattle washington united states', key:'A_SEA', cityBase: 0, city: 'SEA', country: 'US', type: 'airport', nlabel: 'seattle tacoma international seattle washington united states'},
  { icon: 'faPlaneDeparture', label: 'EGM Seghe, Solomon Islands, Seghe, EGM, seghe seghe solomon islands', key:'A_EGM', cityBase: 127, city: 'EGM', country: 'SB', type: 'airport', nlabel: 'seghe seghe solomon islands'},
  { icon: 'faPlaneDeparture', label: 'WLK Selawik, United States, Selawik, WLK, selawik selawik alaska united states', key:'A_WLK', cityBase: 127, city: 'WLK', country: 'US', type: 'airport', nlabel: 'selawik selawik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SZE Semera, Ethiopia, Semera, SZE, semera semera ethiopia', key:'A_SZE', cityBase: 127, city: 'SZE', country: 'ET', type: 'airport', nlabel: 'semera semera ethiopia'},
  { icon: 'faPlaneDeparture', label: 'PLX Semey, Kazakhstan, Semey, PLX, semey semey kazakhstan', key:'A_PLX', cityBase: 127, city: 'PLX', country: 'KZ', type: 'airport', nlabel: 'semey semey kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'THE Teresina, Brazil, Senador Petronio Portella, THE, senador petronio portella teresina state of piaui brazil', key:'A_THE', cityBase: 103, city: 'THE', country: 'BR', type: 'airport', nlabel: 'senador petronio portella teresina state of piaui brazil'},
  { icon: 'faPlaneDeparture', label: 'JHB Johor Bahru, Malaysia, Senai International, JHB, senai international johor bahru malaysia', key:'A_JHB', cityBase: 27, city: 'JHB', country: 'MY', type: 'airport', nlabel: 'senai international johor bahru malaysia'},
  { icon: 'faPlaneDeparture', label: 'SDJ Sendai, Japan, Sendai, SDJ, sendai sendai japan', key:'A_SDJ', cityBase: 64, city: 'SDJ', country: 'JP', type: 'airport', nlabel: 'sendai sendai japan'},
  { icon: 'faPlaneDeparture', label: 'DJJ Jayapura, Indonesia, Sentani International, DJJ, sentani international jayapura indonesia', key:'A_DJJ', cityBase: 96, city: 'DJJ', country: 'ID', type: 'airport', nlabel: 'sentani international jayapura indonesia'},
  { icon: 'faPlaneDeparture', label: 'GEL Santo Ângelo, Brazil, Sepé Tiaraju, GEL, sepe tiaraju santo angelo state of rio grande do sul brazil', key:'A_GEL', cityBase: 127, city: 'GEL', country: 'BR', type: 'airport', nlabel: 'sepe tiaraju santo angelo state of rio grande do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'YZV Sept-Îles, Canada, Sept-Îles, YZV, sept iles sept iles quebec canada', key:'A_YZV', cityBase: 127, city: 'YZV', country: 'CA', type: 'airport', nlabel: 'sept iles sept iles quebec canada'},
  { icon: 'faPlaneDeparture', label: 'SEU Seronera, Tanzania, Seronera Airstrip, SEU, seronera airstrip seronera tanzania', key:'A_SEU', cityBase: 127, city: 'SEU', country: 'TZ', type: 'airport', nlabel: 'seronera airstrip seronera tanzania'},
  { icon: 'faPlaneDeparture', label: 'SWV Evensk, Russia, Severo-Evensk, SWV, severo evensk evensk russia', key:'A_SWV', cityBase: 127, city: 'SWV', country: 'RU', type: 'airport', nlabel: 'severo evensk evensk russia'},
  { icon: 'faPlaneDeparture', label: 'SVQ Seville, Spain, Seville, SVQ, seville seville spain', key:'A_SVQ', cityBase: 10, city: 'SVQ', country: 'ES', type: 'airport', nlabel: 'seville seville spain'},
  { icon: 'faPlaneDeparture', label: 'SEZ Mahé, Seychelles, Seychelles International, SEZ, seychelles international mahe seychelles', key:'A_SEZ', cityBase: 42, city: 'SEZ', country: 'SC', type: 'airport', nlabel: 'seychelles international mahe seychelles'},
  { icon: 'faPlaneDeparture', label: 'GPS Baltra Island, Ecuador, Seymour, GPS, seymour baltra island ecuador', key:'A_GPS', cityBase: 39, city: 'GPS', country: 'EC', type: 'airport', nlabel: 'seymour baltra island ecuador'},
  { icon: 'faPlaneDeparture', label: 'SFA Sfax, Tunisia, Sfax–Thyna International, SFA, sfax thyna international sfax tunisia', key:'A_SFA', cityBase: 127, city: 'SFA', country: 'TN', type: 'airport', nlabel: 'sfax thyna international sfax tunisia'},
  { icon: 'faPlaneDeparture', label: 'SHX Shageluk, United States, Shageluk, SHX, shageluk shageluk alaska united states', key:'A_SHX', cityBase: 127, city: 'SHX', country: 'US', type: 'airport', nlabel: 'shageluk shageluk alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CGP Chittagong, Bangladesh, Shah Amanat International, CGP, shah amanat international chittagong bangladesh', key:'A_CGP', cityBase: 90, city: 'CGP', country: 'BD', type: 'airport', nlabel: 'shah amanat international chittagong bangladesh'},
  { icon: 'faPlaneDeparture', label: 'RJH Rajshahi, Bangladesh, Shah Makhdum, RJH, shah makhdum rajshahi bangladesh', key:'A_RJH', cityBase: 107, city: 'RJH', country: 'BD', type: 'airport', nlabel: 'shah makhdum rajshahi bangladesh'},
  { icon: 'faPlaneDeparture', label: 'AZD Yazd, Iran, Shahid Sadooghi, AZD, shahid sadooghi yazd iran', key:'A_AZD', cityBase: 96, city: 'AZD', country: 'IR', type: 'airport', nlabel: 'shahid sadooghi yazd iran'},
  { icon: 'faPlaneDeparture', label: 'DAC Dhaka, Bangladesh, Shahjalal International, DAC, shahjalal international dhaka bangladesh', key:'A_DAC', cityBase: 26, city: 'DAC', country: 'BD', type: 'airport', nlabel: 'shahjalal international dhaka bangladesh'},
  { icon: 'faPlaneDeparture', label: 'CQD Shahrekord, Iran, Shahrekord Airport, CQD, shahrekord airport shahrekord iran', key:'A_CQD', cityBase: 127, city: 'CQD', country: 'IR', type: 'airport', nlabel: 'shahrekord airport shahrekord iran'},
  { icon: 'faPlaneDeparture', label: 'RYK Rahim Yar Khan, Pakistan, Shaikh Zayed International, RYK, shaikh zayed international rahim yar khan pakistan', key:'A_RYK', cityBase: 127, city: 'RYK', country: 'PK', type: 'airport', nlabel: 'shaikh zayed international rahim yar khan pakistan'},
  { icon: 'faPlaneDeparture', label: 'EKS Shakhtyorsk, Russia, Shakhtersk, EKS, shakhtersk shakhtyorsk russia', key:'A_EKS', cityBase: 127, city: 'EKS', country: 'RU', type: 'airport', nlabel: 'shakhtersk shakhtyorsk russia'},
  { icon: 'faPlaneDeparture', label: 'SKK Shaktoolik, United States, Shaktoolik, SKK, shaktoolik shaktoolik alaska united states', key:'A_SKK', cityBase: 127, city: 'SKK', country: 'US', type: 'airport', nlabel: 'shaktoolik shaktoolik alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ZTM Shamattawa First Nation, Canada, Shamattawa, ZTM, shamattawa shamattawa first nation manitoba canada', key:'A_ZTM', cityBase: 127, city: 'ZTM', country: 'CA', type: 'airport', nlabel: 'shamattawa shamattawa first nation manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'SHA Shanghai, China, Shanghai Hongqiao International, SHA, shanghai hongqiao international shanghai china', key:'A_SHA', cityBase: 9, city: 'SHA', country: 'CN', type: 'airport', nlabel: 'shanghai hongqiao international shanghai china'},
  { icon: 'faPlaneDeparture', label: 'PVG Shanghai, China, Shanghai Pudong International, PVG, shanghai pudong international shanghai china', key:'A_PVG', cityBase: 18, city: 'SHA', country: 'CN', type: 'airport', nlabel: 'shanghai pudong international shanghai china'},
  { icon: 'faPlaneDeparture', label: 'SQD Shangrao, China, Shangrao Sanqingshan, SQD, shangrao sanqingshan shangrao china', key:'A_SQD', cityBase: 127, city: 'SQD', country: 'CN', type: 'airport', nlabel: 'shangrao sanqingshan shangrao china'},
  { icon: 'faPlaneDeparture', label: 'SNN Shannon, County Clare, Ireland, Shannon, SNN, shannon shannon ireland', key:'A_SNN', cityBase: 38, city: 'SNN', country: 'IE', type: 'airport', nlabel: 'shannon shannon ireland'},
  { icon: 'faPlaneDeparture', label: 'WGN Shaoyang, China, Shaoyang Wugang, WGN, shaoyang wugang shaoyang china', key:'A_WGN', cityBase: 127, city: 'WGN', country: 'CN', type: 'airport', nlabel: 'shaoyang wugang shaoyang china'},
  { icon: 'faPlaneDeparture', label: 'SHJ Dubai, United Arab Emirates, Sharjah International, SHJ, sharjah international sharjah united arab emirates', key:'A_SHJ', cityBase: 56, city: 'DXB', country: 'AE', type: 'airport', nlabel: 'sharjah international sharjah united arab emirates'},
  { icon: 'faPlaneDeparture', label: 'MJK Monkey Mia, Australia, Shark Bay, MJK, shark bay monkey mia western australia australia', key:'A_MJK', cityBase: 127, city: 'MJK', country: 'AU', type: 'airport', nlabel: 'shark bay monkey mia western australia australia'},
  { icon: 'faPlaneDeparture', label: 'SSH Sharm El Sheikh, Egypt, Sharm el-Sheikh International, SSH, sharm el sheikh international sharm el sheikh egypt', key:'A_SSH', cityBase: 15, city: 'SSH', country: 'EG', type: 'airport', nlabel: 'sharm el sheikh international sharm el sheikh egypt'},
  { icon: 'faPlaneDeparture', label: 'SHW Sharurah, Saudi Arabia, Sharurah Domestic, SHW, sharurah domestic sharurah saudi arabia', key:'A_SHW', cityBase: 127, city: 'SHW', country: 'SA', type: 'airport', nlabel: 'sharurah domestic sharurah saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'SXP Nunam Iqua, United States, Sheldon Point, SXP, sheldon point nunam iqua alaska united states', key:'A_SXP', cityBase: 44, city: 'SXP', country: 'US', type: 'airport', nlabel: 'sheldon point nunam iqua alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SHD Staunton, Virginia, United States, Shenandoah Valley Regional, SHD, shenandoah valley regional staunton virginia united states', key:'A_SHD', cityBase: 105, city: 'SHD', country: 'US', type: 'airport', nlabel: 'shenandoah valley regional staunton virginia united states'},
  { icon: 'faPlaneDeparture', label: 'HPG Shennongjia, China, Shennongjia Hongping, HPG, shennongjia hongping shennongjia china', key:'A_HPG', cityBase: 70, city: 'HPG', country: 'CN', type: 'airport', nlabel: 'shennongjia hongping shennongjia china'},
  { icon: 'faPlaneDeparture', label: 'SHE Shenyang, China, Shenyang Taoxian International, SHE, shenyang taoxian international shenyang china', key:'A_SHE', cityBase: 38, city: 'SHE', country: 'CN', type: 'airport', nlabel: 'shenyang taoxian international shenyang china'},
  { icon: 'faPlaneDeparture', label: 'SZX Shenzhen, China, Shenzhen Bao an International, SZX, shenzhen bao an international shenzhen china', key:'A_SZX', cityBase: 16, city: 'SZX', country: 'CN', type: 'airport', nlabel: 'shenzhen bao an international shenzhen china'},
  { icon: 'faPlaneDeparture', label: 'SVO Moscow, Russia, Sheremetyevo International, SVO, sheremetyevo international moscow russia', key:'A_SVO', cityBase: 14, city: 'MOW', country: 'RU', type: 'airport', nlabel: 'sheremetyevo international moscow russia'},
  { icon: 'faPlaneDeparture', label: 'RKZ Shigatse, China, Shigatse Peace, RKZ, shigatse peace shigatse china', key:'A_RKZ', cityBase: 70, city: 'RKZ', country: 'CN', type: 'airport', nlabel: 'shigatse peace shigatse china'},
  { icon: 'faPlaneDeparture', label: 'SHF Shihezi, China, Shihezi Huayuan, SHF, shihezi huayuan shihezi china', key:'A_SHF', cityBase: 67, city: 'SHF', country: 'CN', type: 'airport', nlabel: 'shihezi huayuan shihezi china'},
  { icon: 'faPlaneDeparture', label: 'SJW Shijiazhuang, China, Shijiazhuang Zhengding International, SJW, shijiazhuang zhengding international shijiazhuang china', key:'A_SJW', cityBase: 53, city: 'SJW', country: 'CN', type: 'airport', nlabel: 'shijiazhuang zhengding international shijiazhuang china'},
  { icon: 'faPlaneDeparture', label: 'SHL Shillong, India, Shillong, SHL, shillong shillong india', key:'A_SHL', cityBase: 127, city: 'SHL', country: 'IN', type: 'airport', nlabel: 'shillong shillong india'},
  { icon: 'faPlaneDeparture', label: 'LWN Gyumri, Armenia, Shirak, LWN, shirak gyumri armenia', key:'A_LWN', cityBase: 62, city: 'LWN', country: 'AM', type: 'airport', nlabel: 'shirak gyumri armenia'},
  { icon: 'faPlaneDeparture', label: 'SYZ Shiraz, Iran, Shiraz International, SYZ, shiraz international shiraz iran', key:'A_SYZ', cityBase: 67, city: 'SYZ', country: 'IR', type: 'airport', nlabel: 'shiraz international shiraz iran'},
  { icon: 'faPlaneDeparture', label: 'SHC Shire, Ethiopia, Shire, SHC, shire shire ethiopia', key:'A_SHC', cityBase: 127, city: 'SHC', country: 'ET', type: 'airport', nlabel: 'shire shire ethiopia'},
  { icon: 'faPlaneDeparture', label: 'SHH Shishmaref, United States, Shishmaref, SHH, shishmaref shishmaref alaska united states', key:'A_SHH', cityBase: 127, city: 'SHH', country: 'US', type: 'airport', nlabel: 'shishmaref shishmaref alaska united states'},
  { icon: 'faPlaneDeparture', label: 'WDS Shiyan, China, Shiyan Wudangshan, WDS, shiyan wudangshan shiyan china', key:'A_WDS', cityBase: 71, city: 'WDS', country: 'CN', type: 'airport', nlabel: 'shiyan wudangshan shiyan china'},
  { icon: 'faPlaneDeparture', label: 'FSZ Shizuoka, Japan, Shizuoka, FSZ, shizuoka shizuoka japan', key:'A_FSZ', cityBase: 127, city: 'FSZ', country: 'JP', type: 'airport', nlabel: 'shizuoka shizuoka japan'},
  { icon: 'faPlaneDeparture', label: 'SYO Shonai, Japan, Shonai, SYO, shonai shonai japan', key:'A_SYO', cityBase: 127, city: 'SYO', country: 'JP', type: 'airport', nlabel: 'shonai shonai japan'},
  { icon: 'faPlaneDeparture', label: 'SHV Shreveport, United States, Shreveport Regional, SHV, shreveport regional shreveport louisiana united states', key:'A_SHV', cityBase: 48, city: 'SHV', country: 'US', type: 'airport', nlabel: 'shreveport regional shreveport louisiana united states'},
  { icon: 'faPlaneDeparture', label: 'NDC Nanded, India, Shri Guru Gobind Singh Ji, NDC, shri guru gobind singh ji nanded india', key:'A_NDC', cityBase: 120, city: 'NDC', country: 'IN', type: 'airport', nlabel: 'shri guru gobind singh ji nanded india'},
  { icon: 'faPlaneDeparture', label: 'SHG Shungnak, United States, Shungnak, SHG, shungnak shungnak alaska united states', key:'A_SHG', cityBase: 127, city: 'SHG', country: 'US', type: 'airport', nlabel: 'shungnak shungnak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CIT Shymkent, Kazakhstan, Shymkent International, CIT, shymkent international shymkent kazakhstan', key:'A_CIT', cityBase: 127, city: 'CIT', country: 'KZ', type: 'airport', nlabel: 'shymkent international shymkent kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'SKT Sialkot, Pakistan, Sialkot International, SKT, sialkot international sialkot pakistan', key:'A_SKT', cityBase: 73, city: 'SKT', country: 'PK', type: 'airport', nlabel: 'sialkot international sialkot pakistan'},
  { icon: 'faPlaneDeparture', label: 'SBZ Sibiu, Romania, Sibiu International, SBZ, sibiu international sibiu romania', key:'A_SBZ', cityBase: 20, city: 'SBZ', country: 'RO', type: 'airport', nlabel: 'sibiu international sibiu romania'},
  { icon: 'faPlaneDeparture', label: 'SBW Sibu, Malaysia, Sibu, SBW, sibu sibu malaysia', key:'A_SBW', cityBase: 113, city: 'SBW', country: 'MY', type: 'airport', nlabel: 'sibu sibu malaysia'},
  { icon: 'faPlaneDeparture', label: 'DGT Dumaguete, Philippines, Sibulan, DGT, sibulan dumaguete philippines', key:'A_DGT', cityBase: 78, city: 'DGT', country: 'PH', type: 'airport', nlabel: 'sibulan dumaguete philippines'},
  { icon: 'faPlaneDeparture', label: 'TGR Touggourt, Algeria, Sidi Mahdi, TGR, sidi mahdi touggourt algeria', key:'A_TGR', cityBase: 127, city: 'TGR', country: 'DZ', type: 'airport', nlabel: 'sidi mahdi touggourt algeria'},
  { icon: 'faPlaneDeparture', label: 'SDY Sidney, United States, Sidney–Richland Municipal, SDY, sidney richland municipal sidney montana united states', key:'A_SDY', cityBase: 104, city: 'SDY', country: 'US', type: 'airport', nlabel: 'sidney richland municipal sidney montana united states'},
  { icon: 'faPlaneDeparture', label: 'REP Siem Reap, Cambodia, Siem Reap International, REP, siem reap international siem reap cambodia', key:'A_REP', cityBase: 33, city: 'REP', country: 'KH', type: 'airport', nlabel: 'siem reap international siem reap cambodia'},
  { icon: 'faPlaneDeparture', label: 'MZO Manzanillo, Cuba, Sierra Maestra, MZO, sierra maestra manzanillo cuba', key:'A_MZO', cityBase: 127, city: 'MZO', country: 'CU', type: 'airport', nlabel: 'sierra maestra manzanillo cuba'},
  { icon: 'faPlaneDeparture', label: 'GIU Sigiriya, Sri Lanka, Sigiriya, GIU, sigiriya sigiriya sri lanka', key:'A_GIU', cityBase: 127, city: 'GIU', country: 'LK', type: 'airport', nlabel: 'sigiriya sigiriya sri lanka'},
  { icon: 'faPlaneDeparture', label: 'KOS Sihanoukville Province, Cambodia, Sihanoukville International, KOS, sihanoukville international sihanoukville province cambodia', key:'A_KOS', cityBase: 43, city: 'KOS', country: 'KH', type: 'airport', nlabel: 'sihanoukville international sihanoukville province cambodia'},
  { icon: 'faPlaneDeparture', label: 'SXZ Siirt, Turkey, Siirt, SXZ, siirt siirt turkey', key:'A_SXZ', cityBase: 127, city: 'SXZ', country: 'TR', type: 'airport', nlabel: 'siirt siirt turkey'},
  { icon: 'faPlaneDeparture', label: 'DTB Siborong-Borong, Indonesia, Silangit International, DTB, silangit international siborong borong indonesia', key:'A_DTB', cityBase: 127, city: 'DTB', country: 'ID', type: 'airport', nlabel: 'silangit international siborong borong indonesia'},
  { icon: 'faPlaneDeparture', label: 'IXS Silchar, India, Silchar, IXS, silchar silchar india', key:'A_IXS', cityBase: 127, city: 'IXS', country: 'IN', type: 'airport', nlabel: 'silchar silchar india'},
  { icon: 'faPlaneDeparture', label: 'ASU Asunción, Paraguay, Silvio Pettirossi International, ASU, silvio pettirossi international asuncion paraguay', key:'A_ASU', cityBase: 37, city: 'ASU', country: 'PY', type: 'airport', nlabel: 'silvio pettirossi international asuncion paraguay'},
  { icon: 'faPlaneDeparture', label: 'SIF Simara, Bara, Nepal, Simara, SIF, simara simara nepal', key:'A_SIF', cityBase: 127, city: 'SIF', country: 'NP', type: 'airport', nlabel: 'simara simara nepal'},
  { icon: 'faPlaneDeparture', label: 'SIP Simferopol, Unknown Region, Simferopol International, SIP, simferopol international simferopol unknown region', key:'A_SIP', cityBase: 27, city: 'SIP', country: 'ZZ', type: 'airport', nlabel: 'simferopol international simferopol unknown region'},
  { icon: 'faPlaneDeparture', label: 'CCS Caracas, Venezuela, Simón Bolívar International, CCS, simon bolivar international caracas venezuela', key:'A_CCS', cityBase: 16, city: 'CCS', country: 'VE', type: 'airport', nlabel: 'simon bolivar international caracas venezuela'},
  { icon: 'faPlaneDeparture', label: 'SMR Santa Marta, Colombia, Simón Bolívar International, SMR, simon bolivar international santa marta colombia', key:'A_SMR', cityBase: 40, city: 'SMR', country: 'CO', type: 'airport', nlabel: 'simon bolivar international santa marta colombia'},
  { icon: 'faPlaneDeparture', label: 'NLA Ndola, Zambia, Simon Mwansa Kapwepwe International, NLA, simon mwansa kapwepwe international ndola zambia', key:'A_NLA', cityBase: 97, city: 'NLA', country: 'ZM', type: 'airport', nlabel: 'simon mwansa kapwepwe international ndola zambia'},
  { icon: 'faPlaneDeparture', label: 'SIN Singapore, Singapore, Singapore Changi, SIN, singapore changi singapore singapore', key:'A_SIN', cityBase: 6, city: 'SIN', country: 'SG', type: 'airport', nlabel: 'singapore changi singapore singapore'},
  { icon: 'faPlaneDeparture', label: 'NOP Sinop, Turkey, Sinop, NOP, sinop sinop turkey', key:'A_NOP', cityBase: 127, city: 'NOP', country: 'TR', type: 'airport', nlabel: 'sinop sinop turkey'},
  { icon: 'faPlaneDeparture', label: 'OPS Sinop, Mato Grosso, Brazil, Sinop, OPS, sinop sinop state of mato grosso brazil', key:'A_OPS', cityBase: 127, city: 'OPS', country: 'BR', type: 'airport', nlabel: 'sinop sinop state of mato grosso brazil'},
  { icon: 'faPlaneDeparture', label: 'SQG Sintang Regency, Indonesia, Sintang, SQG, sintang sintang regency indonesia', key:'A_SQG', cityBase: 127, city: 'SQG', country: 'ID', type: 'airport', nlabel: 'sintang sintang regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'SIR Sion, Switzerland, Sion, SIR, sion sion switzerland', key:'A_SIR', cityBase: 30, city: 'SIR', country: 'CH', type: 'airport', nlabel: 'sion sion switzerland'},
  { icon: 'faPlaneDeparture', label: 'FSD Sioux Falls, United States, Sioux Falls Regional, FSD, sioux falls regional sioux falls south dakota united states', key:'A_FSD', cityBase: 41, city: 'FSD', country: 'US', type: 'airport', nlabel: 'sioux falls regional sioux falls south dakota united states'},
  { icon: 'faPlaneDeparture', label: 'SUX Sioux City, United States, Sioux Gateway, SUX, sioux gateway sioux city iowa united states', key:'A_SUX', cityBase: 92, city: 'SUX', country: 'US', type: 'airport', nlabel: 'sioux gateway sioux city iowa united states'},
  { icon: 'faPlaneDeparture', label: 'YXL Sioux Lookout, Canada, Sioux Lookout, YXL, sioux lookout sioux lookout ontario canada', key:'A_YXL', cityBase: 127, city: 'YXL', country: 'CA', type: 'airport', nlabel: 'sioux lookout sioux lookout ontario canada'},
  { icon: 'faPlaneDeparture', label: 'RRG Rodrigues, Mauritius, Sir Gaëtan Duval, RRG, sir gaetan duval rodrigues mauritius', key:'A_RRG', cityBase: 127, city: 'RRG', country: 'MU', type: 'airport', nlabel: 'sir gaetan duval rodrigues mauritius'},
  { icon: 'faPlaneDeparture', label: 'MRU Mauritius Island, Mauritius, Sir Seewoosagur Ramgoolam International, MRU, sir seewoosagur ramgoolam international mauritius island mauritius', key:'A_MRU', cityBase: 37, city: 'MRU', country: 'MU', type: 'airport', nlabel: 'sir seewoosagur ramgoolam international mauritius island mauritius'},
  { icon: 'faPlaneDeparture', label: 'GBE Gaborone, Botswana, Sir Seretse Khama International, GBE, sir seretse khama international gaborone botswana', key:'A_GBE', cityBase: 63, city: 'GBE', country: 'BW', type: 'airport', nlabel: 'sir seretse khama international gaborone botswana'},
  { icon: 'faPlaneDeparture', label: 'SIS Dingleton, South Africa, Sishen, SIS, sishen dingleton south africa', key:'A_SIS', cityBase: 127, city: 'SIS', country: 'ZA', type: 'airport', nlabel: 'sishen dingleton south africa'},
  { icon: 'faPlaneDeparture', label: 'JHS Sisimiut, Greenland, Sisimiut, JHS, sisimiut sisimiut greenland', key:'A_JHS', cityBase: 127, city: 'JHS', country: 'GL', type: 'airport', nlabel: 'sisimiut sisimiut greenland'},
  { icon: 'faPlaneDeparture', label: 'JSH Sitia, Greece, Sitia Public, JSH, sitia public sitia greece', key:'A_JSH', cityBase: 21, city: 'JSH', country: 'GR', type: 'airport', nlabel: 'sitia public sitia greece'},
  { icon: 'faPlaneDeparture', label: 'SIT Sitka, United States, Sitka Rocky Gutierrez, SIT, sitka rocky gutierrez sitka alaska united states', key:'A_SIT', cityBase: 89, city: 'SIT', country: 'US', type: 'airport', nlabel: 'sitka rocky gutierrez sitka alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AKY Sittwe, Myanmar (Burma), Sittwe, AKY, sittwe sittwe myanmar (burma)', key:'A_AKY', cityBase: 127, city: 'AKY', country: 'MM', type: 'airport', nlabel: 'sittwe sittwe myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'VAS Sivas, Turkey, Sivas, VAS, sivas sivas turkey', key:'A_VAS', cityBase: 20, city: 'VAS', country: 'TR', type: 'airport', nlabel: 'sivas sivas turkey'},
  { icon: 'faPlaneDeparture', label: 'EAE Emae, Vanuatu, Siwo, EAE, siwo emae vanuatu', key:'A_EAE', cityBase: 127, city: 'EAE', country: 'VU', type: 'airport', nlabel: 'siwo emae vanuatu'},
  { icon: 'faPlaneDeparture', label: 'NKT Şırnak, Turkey, Şırnak, NKT, sirnak sirnak turkey', key:'A_NKT', cityBase: 26, city: 'NKT', country: 'TR', type: 'airport', nlabel: 'sirnak sirnak turkey'},
  { icon: 'faPlaneDeparture', label: 'SGY Skagway, United States, Skagway, SGY, skagway skagway alaska united states', key:'A_SGY', cityBase: 120, city: 'SGY', country: 'US', type: 'airport', nlabel: 'skagway skagway alaska united states'},
  { icon: 'faPlaneDeparture', label: 'KDU Skardu, Unknown Region, Skardu, KDU, skardu skardu unknown region', key:'A_KDU', cityBase: 93, city: 'KDU', country: 'ZZ', type: 'airport', nlabel: 'skardu skardu unknown region'},
  { icon: 'faPlaneDeparture', label: 'SFT Skellefteå, Sweden, Skellefteå, SFT, skelleftea skelleftea sweden', key:'A_SFT', cityBase: 121, city: 'SFT', country: 'SE', type: 'airport', nlabel: 'skelleftea skelleftea sweden'},
  { icon: 'faPlaneDeparture', label: 'JSI Skiathos, Greece, Skiathos Island National, JSI, skiathos island national skiathos greece', key:'A_JSI', cityBase: 15, city: 'JSI', country: 'GR', type: 'airport', nlabel: 'skiathos island national skiathos greece'},
  { icon: 'faPlaneDeparture', label: 'SKP Skopje, Macedonia (FYROM), Skopje  Alexander the Great , SKP, skopje alexander the great skopje macedonia (fyrom)', key:'A_SKP', cityBase: 32, city: 'SKP', country: 'MK', type: 'airport', nlabel: 'skopje alexander the great skopje macedonia (fyrom)'},
  { icon: 'faPlaneDeparture', label: 'SZK Skukuza, South Africa, Skukuza, SZK, skukuza skukuza south africa', key:'A_SZK', cityBase: 104, city: 'SZK', country: 'ZA', type: 'airport', nlabel: 'skukuza skukuza south africa'},
  { icon: 'faPlaneDeparture', label: 'SKU Skyros, Greece, Skyros Island National, SKU, skyros island national skyros greece', key:'A_SKU', cityBase: 29, city: 'SKU', country: 'GR', type: 'airport', nlabel: 'skyros island national skyros greece'},
  { icon: 'faPlaneDeparture', label: 'SLQ Sleetmute, United States, Sleetmute, SLQ, sleetmute sleetmute alaska united states', key:'A_SLQ', cityBase: 127, city: 'SLQ', country: 'US', type: 'airport', nlabel: 'sleetmute sleetmute alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ISN Williston, United States, Sloulin Field International, ISN, sloulin field international williston north dakota united states', key:'A_ISN', cityBase: 88, city: 'ISN', country: 'US', type: 'airport', nlabel: 'sloulin field international williston north dakota united states'},
  { icon: 'faPlaneDeparture', label: 'INT Winston-Salem, United States, Smith Reynolds, INT, smith reynolds winston salem north carolina united states', key:'A_INT', cityBase: 104, city: 'INT', country: 'US', type: 'airport', nlabel: 'smith reynolds winston salem north carolina united states'},
  { icon: 'faPlaneDeparture', label: 'YYD Smithers, Canada, Smithers, YYD, smithers smithers british columbia canada', key:'A_YYD', cityBase: 127, city: 'YYD', country: 'CA', type: 'airport', nlabel: 'smithers smithers british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'AER Sochi, Russia, Sochi International, AER, sochi international sochi russia', key:'A_AER', cityBase: 26, city: 'AER', country: 'RU', type: 'airport', nlabel: 'sochi international sochi russia'},
  { icon: 'faPlaneDeparture', label: 'SCT Socotra, Yemen, Socotra, SCT, socotra socotra yemen', key:'A_SCT', cityBase: 127, city: 'SCT', country: 'YE', type: 'airport', nlabel: 'socotra socotra yemen'},
  { icon: 'faPlaneDeparture', label: 'CGK Jakarta, Indonesia, Soekarno–Hatta International, CGK, soekarno hatta international jakarta indonesia', key:'A_CGK', cityBase: 30, city: 'JKT', country: 'ID', type: 'airport', nlabel: 'soekarno hatta international jakarta indonesia'},
  { icon: 'faPlaneDeparture', label: 'SOF Sofia, Bulgaria, Sofia, SOF, sofia sofia bulgaria', key:'A_SOF', cityBase: 9, city: 'SOF', country: 'BG', type: 'airport', nlabel: 'sofia sofia bulgaria'},
  { icon: 'faPlaneDeparture', label: 'SOG Sogndal, Norway, Sogndal Airport, Haukåsen, SOG, sogndal airport sogndal norway', key:'A_SOG', cityBase: 127, city: 'SOG', country: 'NO', type: 'airport', nlabel: 'sogndal airport sogndal norway'},
  { icon: 'faPlaneDeparture', label: 'HMB Sohag, Egypt, Sohag International, HMB, sohag international sohag egypt', key:'A_HMB', cityBase: 36, city: 'HMB', country: 'EG', type: 'airport', nlabel: 'sohag international sohag egypt'},
  { icon: 'faPlaneDeparture', label: 'OHS Sohar, Oman, Sohar, OHS, sohar sohar oman', key:'A_OHS', cityBase: 92, city: 'OHS', country: 'OM', type: 'airport', nlabel: 'sohar sohar oman'},
  { icon: 'faPlaneDeparture', label: 'GDX Magadan, Russia, Sokol, GDX, sokol magadan russia', key:'A_GDX', cityBase: 52, city: 'GDX', country: 'RU', type: 'airport', nlabel: 'sokol magadan russia'},
  { icon: 'faPlaneDeparture', label: 'SZZ Szczecin, Poland, Solidarity Szczecin–Goleniów, SZZ, solidarity szczecin goleniow szczecin poland', key:'A_SZZ', cityBase: 97, city: 'SZZ', country: 'PL', type: 'airport', nlabel: 'solidarity szczecin goleniow szczecin poland'},
  { icon: 'faPlaneDeparture', label: 'CSH Solovetsky, Arkhangelsk Oblast, Russia, Solovki, CSH, solovki solovetsky russia', key:'A_CSH', cityBase: 83, city: 'CSH', country: 'RU', type: 'airport', nlabel: 'solovki solovetsky russia'},
  { icon: 'faPlaneDeparture', label: 'SLI Solwezi, Zambia, Solwezi, SLI, solwezi solwezi zambia', key:'A_SLI', cityBase: 127, city: 'SLI', country: 'ZM', type: 'airport', nlabel: 'solwezi solwezi zambia'},
  { icon: 'faPlaneDeparture', label: 'SGD Sønderborg, Denmark, Sønderborg, SGD, sonderborg sonderborg denmark', key:'A_SGD', cityBase: 127, city: 'SGD', country: 'DK', type: 'airport', nlabel: 'sonderborg sonderborg denmark'},
  { icon: 'faPlaneDeparture', label: 'YSQ Songyuan, China, Songyuan Chaganhu, YSQ, songyuan chaganhu songyuan china', key:'A_YSQ', cityBase: 127, city: 'YSQ', country: 'CN', type: 'airport', nlabel: 'songyuan chaganhu songyuan china'},
  { icon: 'faPlaneDeparture', label: 'SOJ Sørkjosen, Norway, Sørkjosen, SOJ, sorkjosen sorkjosen norway', key:'A_SOJ', cityBase: 127, city: 'SOJ', country: 'NO', type: 'airport', nlabel: 'sorkjosen sorkjosen norway'},
  { icon: 'faPlaneDeparture', label: 'SMT Sorriso, Brazil, Sorriso, SMT, sorriso sorriso brazil', key:'A_SMT', cityBase: 127, city: 'SMT', country: 'BR', type: 'airport', nlabel: 'sorriso sorriso brazil'},
  { icon: 'faPlaneDeparture', label: 'ELD El Dorado, United States, South Arkansas Regional Airport at Goodwin Field, ELD, south arkansas regional airport at goodwin field el dorado arkansas united states', key:'A_ELD', cityBase: 127, city: 'ELD', country: 'US', type: 'airport', nlabel: 'south arkansas regional airport at goodwin field el dorado arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'SBN South Bend, United States, South Bend International, SBN, south bend international south bend indiana united states', key:'A_SBN', cityBase: 31, city: 'SBN', country: 'US', type: 'airport', nlabel: 'south bend international south bend indiana united states'},
  { icon: 'faPlaneDeparture', label: 'BIM Bimini, Bahamas, South Bimini, BIM, south bimini bimini bahamas', key:'A_BIM', cityBase: 99, city: 'BIM', country: 'BS', type: 'airport', nlabel: 'south bimini bimini bahamas'},
  { icon: 'faPlaneDeparture', label: 'XSC South Caicos, Turks & Caicos Islands, South Caicos, XSC, south caicos south caicos turks and caicos islands', key:'A_XSC', cityBase: 127, city: 'XSC', country: 'TC', type: 'airport', nlabel: 'south caicos south caicos turks and caicos islands'},
  { icon: 'faPlaneDeparture', label: 'XSI South Indian Lake, Canada, South Indian Lake, XSI, south indian lake south indian lake manitoba canada', key:'A_XSI', cityBase: 127, city: 'XSI', country: 'CA', type: 'airport', nlabel: 'south indian lake south indian lake manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'WSN South Naknek, United States, South Naknek, WSN, south naknek south naknek alaska united states', key:'A_WSN', cityBase: 127, city: 'WSN', country: 'US', type: 'airport', nlabel: 'south naknek south naknek alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SWJ South West Bay, Vanuatu, South West Bay, SWJ, south west bay south west bay vanuatu', key:'A_SWJ', cityBase: 127, city: 'SWJ', country: 'VU', type: 'airport', nlabel: 'south west bay south west bay vanuatu'},
  { icon: 'faPlaneDeparture', label: 'SOU Southampton, United Kingdom, Southampton, SOU, southampton southampton united kingdom', key:'A_SOU', cityBase: -985, city: 'SOU', country: 'GB', type: 'airport', nlabel: 'southampton southampton united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BRL Burlington, United States, Southeast Iowa Regional, BRL, southeast iowa regional burlington iowa united states', key:'A_BRL', cityBase: 83, city: 'BRL', country: 'US', type: 'airport', nlabel: 'southeast iowa regional burlington iowa united states'},
  { icon: 'faPlaneDeparture', label: 'RSW Fort Myers, United States, Southwest Florida International, RSW, southwest florida international fort myers florida united states', key:'A_RSW', cityBase: 5, city: 'FMY', country: 'US', type: 'airport', nlabel: 'southwest florida international fort myers florida united states'},
  { icon: 'faPlaneDeparture', label: 'ABY Albany, United States, Southwest Georgia Regional, ABY, southwest georgia regional albany georgia united states', key:'A_ABY', cityBase: 124, city: 'ABY', country: 'US', type: 'airport', nlabel: 'southwest georgia regional albany georgia united states'},
  { icon: 'faPlaneDeparture', label: 'OTH North Bend, United States, Southwest Oregon Regional, OTH, southwest oregon regional north bend oregon united states', key:'A_OTH', cityBase: 93, city: 'OTH', country: 'US', type: 'airport', nlabel: 'southwest oregon regional north bend oregon united states'},
  { icon: 'faPlaneDeparture', label: 'OVS Sovetsky, Russia, Sovetsky, OVS, sovetsky sovetsky russia', key:'A_OVS', cityBase: 56, city: 'OVS', country: 'RU', type: 'airport', nlabel: 'sovetsky sovetsky russia'},
  { icon: 'faPlaneDeparture', label: 'SZA Soyo, Angola, Soyo, SZA, soyo soyo angola', key:'A_SZA', cityBase: 127, city: 'SZA', country: 'AO', type: 'airport', nlabel: 'soyo soyo angola'},
  { icon: 'faPlaneDeparture', label: 'NOZ Novokuznetsk, Russia, Spichenkovo, NOZ, spichenkovo novokuznetsk russia', key:'A_NOZ', cityBase: 47, city: 'NOZ', country: 'RU', type: 'airport', nlabel: 'spichenkovo novokuznetsk russia'},
  { icon: 'faPlaneDeparture', label: 'SPU Split, Croatia, Split, SPU, split split croatia', key:'A_SPU', cityBase: 10, city: 'SPU', country: 'HR', type: 'airport', nlabel: 'split split croatia'},
  { icon: 'faPlaneDeparture', label: 'GEG Spokane, United States, Spokane International, GEG, spokane international spokane washington united states', key:'A_GEG', cityBase: 15, city: 'GEG', country: 'US', type: 'airport', nlabel: 'spokane international spokane washington united states'},
  { icon: 'faPlaneDeparture', label: 'MLW Monrovia, Liberia, Spriggs Payne Airport, MLW, spriggs payne airport monrovia liberia', key:'A_MLW', cityBase: 37, city: 'MLW', country: 'LR', type: 'airport', nlabel: 'spriggs payne airport monrovia liberia'},
  { icon: 'faPlaneDeparture', label: 'AXP Spring Point, Bahamas, Spring Point, AXP, spring point spring point bahamas', key:'A_AXP', cityBase: 127, city: 'AXP', country: 'BS', type: 'airport', nlabel: 'spring point spring point bahamas'},
  { icon: 'faPlaneDeparture', label: 'SGF Springfield, United States, Springfield-Branson National, SGF, springfield branson national springfield missouri united states', key:'A_SGF', cityBase: 31, city: 'SGF', country: 'US', type: 'airport', nlabel: 'springfield branson national springfield missouri united states'},
  { icon: 'faPlaneDeparture', label: 'SEK Srednekolymsk, Russia, Srednekolymsk, SEK, srednekolymsk srednekolymsk russia', key:'A_SEK', cityBase: 127, city: 'SEK', country: 'RU', type: 'airport', nlabel: 'srednekolymsk srednekolymsk russia'},
  { icon: 'faPlaneDeparture', label: 'ATQ Amritsar, India, Sri Guru Ram Dass Jee International, ATQ, sri guru ram dass jee international amritsar india', key:'A_ATQ', cityBase: 50, city: 'ATQ', country: 'IN', type: 'airport', nlabel: 'sri guru ram dass jee international amritsar india'},
  { icon: 'faPlaneDeparture', label: 'SXR Srinagar, Unknown Region, Srinagar International, SXR, srinagar international srinagar unknown region', key:'A_SXR', cityBase: 91, city: 'SXR', country: 'ZZ', type: 'airport', nlabel: 'srinagar international srinagar unknown region'},
  { icon: 'faPlaneDeparture', label: 'SGO St George, Australia, St George, SGO, st george st george queensland australia', key:'A_SGO', cityBase: 127, city: 'SGO', country: 'AU', type: 'airport', nlabel: 'st george st george queensland australia'},
  { icon: 'faPlaneDeparture', label: 'SNR Saint Nazaire, France, St-Nazaire Montoir, SNR, st nazaire montoir saint nazaire france', key:'A_SNR', cityBase: 127, city: 'SNR', country: 'FR', type: 'airport', nlabel: 'st nazaire montoir saint nazaire france'},
  { icon: 'faPlaneDeparture', label: 'YAY St. Anthony, Canada, St. Anthony, YAY, st anthony st anthony newfoundland and labrador canada', key:'A_YAY', cityBase: 127, city: 'YAY', country: 'CA', type: 'airport', nlabel: 'st anthony st anthony newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'STC St. Cloud, United States, St. Cloud Regional, STC, st cloud regional st cloud minnesota united states', key:'A_STC', cityBase: 127, city: 'STC', country: 'US', type: 'airport', nlabel: 'st cloud regional st cloud minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'ACH Thal, Switzerland, St. Gallen–Altenrhein, ACH, st gallen altenrhein thal switzerland', key:'A_ACH', cityBase: 14, city: 'ACH', country: 'CH', type: 'airport', nlabel: 'st gallen altenrhein thal switzerland'},
  { icon: 'faPlaneDeparture', label: 'STG St. George, United States, St. George, STG, st george st george alaska united states', key:'A_STG', cityBase: 127, city: 'STG', country: 'US', type: 'airport', nlabel: 'st george st george alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SGU St. George, United States, St. George Regional, SGU, st george regional st george utah united states', key:'A_SGU', cityBase: 35, city: 'SGU', country: 'US', type: 'airport', nlabel: 'st george regional st george utah united states'},
  { icon: 'faPlaneDeparture', label: 'YYT St. John s, Canada, St. John\'s International, YYT, st john s international st john s newfoundland and labrador canada', key:'A_YYT', cityBase: 55, city: 'YYT', country: 'CA', type: 'airport', nlabel: 'st john s international st john s newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'STL St. Louis, United States, St. Louis Lambert International, STL, st louis lambert international st louis missouri united states', key:'A_STL', cityBase: 4, city: 'STL', country: 'US', type: 'airport', nlabel: 'st louis lambert international st louis missouri united states'},
  { icon: 'faPlaneDeparture', label: 'KSM St. Mary s, United States, St. Mary s, KSM, st mary s st mary s alaska united states', key:'A_KSM', cityBase: 127, city: 'KSM', country: 'US', type: 'airport', nlabel: 'st mary s st mary s alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SMK St. Michael, United States, St. Michael, SMK, st michael st michael alaska united states', key:'A_SMK', cityBase: 127, city: 'SMK', country: 'US', type: 'airport', nlabel: 'st michael st michael alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SNP St. Paul, United States, St. Paul Island, SNP, st paul island st paul alaska united states', key:'A_SNP', cityBase: 127, city: 'SNP', country: 'US', type: 'airport', nlabel: 'st paul island st paul alaska united states'},
  { icon: 'faPlaneDeparture', label: 'PIE Tampa, United States, St. Pete–Clearwater International, PIE, st pete clearwater international tampa florida united states', key:'A_PIE', cityBase: 29, city: 'TPA', country: 'US', type: 'airport', nlabel: 'st pete clearwater international tampa florida united states'},
  { icon: 'faPlaneDeparture', label: 'YST St. Theresa Point First Nation, Canada, St. Theresa Point, YST, st theresa point st theresa point first nation manitoba canada', key:'A_YST', cityBase: 127, city: 'YST', country: 'CA', type: 'airport', nlabel: 'st theresa point st theresa point first nation manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'SVG Stavanger, Norway, Stavanger Airport, Sola, SVG, stavanger airport stavanger norway', key:'A_SVG', cityBase: 56, city: 'SVG', country: 'NO', type: 'airport', nlabel: 'stavanger airport stavanger norway'},
  { icon: 'faPlaneDeparture', label: 'STW Stavropol, Russia, Stavropol Shpakovskoye, STW, stavropol shpakovskoye stavropol russia', key:'A_STW', cityBase: 45, city: 'STW', country: 'RU', type: 'airport', nlabel: 'stavropol shpakovskoye stavropol russia'},
  { icon: 'faPlaneDeparture', label: 'WBB Stebbins, United States, Stebbins, WBB, stebbins stebbins alaska united states', key:'A_WBB', cityBase: 127, city: 'WBB', country: 'US', type: 'airport', nlabel: 'stebbins stebbins alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SML Stella Maris, Bahamas, Stella Maris, SML, stella maris stella maris bahamas', key:'A_SML', cityBase: 127, city: 'SML', country: 'BS', type: 'airport', nlabel: 'stella maris stella maris bahamas'},
  { icon: 'faPlaneDeparture', label: 'YJT Stephenville, Canada, Stephenville International, YJT, stephenville international stephenville newfoundland and labrador canada', key:'A_YJT', cityBase: 127, city: 'YJT', country: 'CA', type: 'airport', nlabel: 'stephenville international stephenville newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'SWF New York City, United States, Stewart International, SWF, stewart international newburgh new york united states', key:'A_SWF', cityBase: 20, city: 'NYC', country: 'US', type: 'airport', nlabel: 'stewart international newburgh new york united states'},
  { icon: 'faPlaneDeparture', label: 'SWO Stillwater, United States, Stillwater Regional, SWO, stillwater regional stillwater oklahoma united states', key:'A_SWO', cityBase: 90, city: 'SWO', country: 'US', type: 'airport', nlabel: 'stillwater regional stillwater oklahoma united states'},
  { icon: 'faPlaneDeparture', label: 'ARN Stockholm, Sweden, Stockholm Arlanda, ARN, stockholm arlanda stockholm sweden', key:'A_ARN', cityBase: 12, city: 'STO', country: 'SE', type: 'airport', nlabel: 'stockholm arlanda stockholm sweden'},
  { icon: 'faPlaneDeparture', label: 'BMA Stockholm, Sweden, Stockholm Bromma, BMA, stockholm bromma stockholm sweden', key:'A_BMA', cityBase: 57, city: 'STO', country: 'SE', type: 'airport', nlabel: 'stockholm bromma stockholm sweden'},
  { icon: 'faPlaneDeparture', label: 'NYO Stockholm, Sweden, Stockholm Skavsta, NYO, stockholm skavsta stockholm sweden', key:'A_NYO', cityBase: 53, city: 'STO', country: 'SE', type: 'airport', nlabel: 'stockholm skavsta stockholm sweden'},
  { icon: 'faPlaneDeparture', label: 'VST Stockholm, Sweden, Stockholm Västerås, VST, stockholm vasteras stockholm sweden', key:'A_VST', cityBase: 56, city: 'STO', country: 'SE', type: 'airport', nlabel: 'stockholm vasteras stockholm sweden'},
  { icon: 'faPlaneDeparture', label: 'SCK Stockton, United States, Stockton Metropolitan, SCK, stockton metropolitan stockton california united states', key:'A_SCK', cityBase: 60, city: 'SCK', country: 'US', type: 'airport', nlabel: 'stockton metropolitan stockton california united states'},
  { icon: 'faPlaneDeparture', label: 'SKN Stokmarknes, Norway, Stokmarknes Airport, Skagen, SKN, stokmarknes airport stokmarknes norway', key:'A_SKN', cityBase: 127, city: 'SKN', country: 'NO', type: 'airport', nlabel: 'stokmarknes airport stokmarknes norway'},
  { icon: 'faPlaneDeparture', label: 'SRV Stony River, United States, Stony River, SRV, stony river stony river alaska united states', key:'A_SRV', cityBase: 127, city: 'SRV', country: 'US', type: 'airport', nlabel: 'stony river stony river alaska united states'},
  { icon: 'faPlaneDeparture', label: 'SRP Stord, Norway, Stord Airport, Sørstokken, SRP, stord airport stord norway', key:'A_SRP', cityBase: 127, city: 'SRP', country: 'NO', type: 'airport', nlabel: 'stord airport stord norway'},
  { icon: 'faPlaneDeparture', label: 'SYY Stornoway, United Kingdom, Stornoway, SYY, stornoway stornoway united kingdom', key:'A_SYY', cityBase: -889, city: 'SYY', country: 'GB', type: 'airport', nlabel: 'stornoway stornoway united kingdom'},
  { icon: 'faPlaneDeparture', label: 'SXB Strasbourg, France, Strasbourg, SXB, strasbourg strasbourg france', key:'A_SXB', cityBase: 36, city: 'SXB', country: 'FR', type: 'airport', nlabel: 'strasbourg strasbourg france'},
  { icon: 'faPlaneDeparture', label: 'SWT Strzhewoi, Russia, Strezhevoy, SWT, strezhevoy strzhewoi russia', key:'A_SWT', cityBase: 127, city: 'SWT', country: 'RU', type: 'airport', nlabel: 'strezhevoy strzhewoi russia'},
  { icon: 'faPlaneDeparture', label: 'GOJ Nizhny Novgorod, Russia, Strigino International, GOJ, strigino international nizhny novgorod russia', key:'A_GOJ', cityBase: 45, city: 'GOJ', country: 'RU', type: 'airport', nlabel: 'strigino international nizhny novgorod russia'},
  { icon: 'faPlaneDeparture', label: 'SOY Stronsay, United Kingdom, Stronsay, SOY, stronsay stronsay united kingdom', key:'A_SOY', cityBase: -873, city: 'SOY', country: 'GB', type: 'airport', nlabel: 'stronsay stronsay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'STR Stuttgart, Germany, Stuttgart, STR, stuttgart stuttgart germany', key:'A_STR', cityBase: 3, city: 'STR', country: 'DE', type: 'airport', nlabel: 'stuttgart stuttgart germany'},
  { icon: 'faPlaneDeparture', label: 'VAO Suavanao, Solomon Islands, Suavanao, VAO, suavanao suavanao solomon islands', key:'A_VAO', cityBase: 127, city: 'VAO', country: 'SB', type: 'airport', nlabel: 'suavanao suavanao solomon islands'},
  { icon: 'faPlaneDeparture', label: 'SCV Suceava, Romania, Suceava International, SCV, suceava international suceava romania', key:'A_SCV', cityBase: 28, city: 'SCV', country: 'RO', type: 'airport', nlabel: 'suceava international suceava romania'},
  { icon: 'faPlaneDeparture', label: 'YSB Greater Sudbury, Canada, Sudbury, YSB, sudbury greater sudbury ontario canada', key:'A_YSB', cityBase: 100, city: 'YSB', country: 'CA', type: 'airport', nlabel: 'sudbury greater sudbury ontario canada'},
  { icon: 'faPlaneDeparture', label: 'RAQ Raha, Indonesia, Sugimanuru, RAQ, sugimanuru raha indonesia', key:'A_RAQ', cityBase: 127, city: 'RAQ', country: 'ID', type: 'airport', nlabel: 'sugimanuru raha indonesia'},
  { icon: 'faPlaneDeparture', label: 'THS Sukhothai Province, Thailand, Sukhothai, THS, sukhothai sukhothai province thailand', key:'A_THS', cityBase: 127, city: 'THS', country: 'TH', type: 'airport', nlabel: 'sukhothai sukhothai province thailand'},
  { icon: 'faPlaneDeparture', label: 'SKZ Sukkur, Pakistan, Sukkur, SKZ, sukkur sukkur pakistan', key:'A_SKZ', cityBase: 109, city: 'SKZ', country: 'PK', type: 'airport', nlabel: 'sukkur sukkur pakistan'},
  { icon: 'faPlaneDeparture', label: 'ISU Sulaymaniyah, Iraq, Sulaimaniyah International, ISU, sulaimaniyah international sulaymaniyah iraq', key:'A_ISU', cityBase: 84, city: 'ISU', country: 'IQ', type: 'airport', nlabel: 'sulaimaniyah international sulaymaniyah iraq'},
  { icon: 'faPlaneDeparture', label: 'SZB Kuala Lumpur, Malaysia, Sultan Abdul Aziz Shah, SZB, sultan abdul aziz shah kuala lumpur malaysia', key:'A_SZB', cityBase: 94, city: 'KUL', country: 'MY', type: 'airport', nlabel: 'sultan abdul aziz shah kuala lumpur malaysia'},
  { icon: 'faPlaneDeparture', label: 'AOR Alor Setar, Malaysia, Sultan Abdul Halim, AOR, sultan abdul halim alor setar malaysia', key:'A_AOR', cityBase: 110, city: 'AOR', country: 'MY', type: 'airport', nlabel: 'sultan abdul halim alor setar malaysia'},
  { icon: 'faPlaneDeparture', label: 'BPN Balikpapan, Indonesia, Sultan Aji Muhammad Sulaiman, BPN, sultan aji muhammad sulaiman balikpapan indonesia', key:'A_BPN', cityBase: 65, city: 'BPN', country: 'ID', type: 'airport', nlabel: 'sultan aji muhammad sulaiman balikpapan indonesia'},
  { icon: 'faPlaneDeparture', label: 'IPH Ipoh, Malaysia, Sultan Azlan Shah, IPH, sultan azlan shah ipoh malaysia', key:'A_IPH', cityBase: 82, city: 'IPH', country: 'MY', type: 'airport', nlabel: 'sultan azlan shah ipoh malaysia'},
  { icon: 'faPlaneDeparture', label: 'TLI Tolitoli, Indonesia, Sultan Bantilan, TLI, sultan bantilan tolitoli indonesia', key:'A_TLI', cityBase: 127, city: 'TLI', country: 'ID', type: 'airport', nlabel: 'sultan bantilan tolitoli indonesia'},
  { icon: 'faPlaneDeparture', label: 'KUA Kuantan, Malaysia, Sultan Haji Ahmad Shah, KUA, sultan haji ahmad shah kuantan malaysia', key:'A_KUA', cityBase: 114, city: 'KUA', country: 'MY', type: 'airport', nlabel: 'sultan haji ahmad shah kuantan malaysia'},
  { icon: 'faPlaneDeparture', label: 'UPG Makassar, Indonesia, Sultan Hasanuddin International, UPG, sultan hasanuddin international makassar indonesia', key:'A_UPG', cityBase: 93, city: 'UPG', country: 'ID', type: 'airport', nlabel: 'sultan hasanuddin international makassar indonesia'},
  { icon: 'faPlaneDeparture', label: 'BTJ Banda Aceh, Indonesia, Sultan Iskandar Muda International, BTJ, sultan iskandar muda international banda aceh indonesia', key:'A_BTJ', cityBase: 106, city: 'BTJ', country: 'ID', type: 'airport', nlabel: 'sultan iskandar muda international banda aceh indonesia'},
  { icon: 'faPlaneDeparture', label: 'KBR Kota Bharu, Malaysia, Sultan Ismail Petra, KBR, sultan ismail petra kota bharu malaysia', key:'A_KBR', cityBase: 67, city: 'KBR', country: 'MY', type: 'airport', nlabel: 'sultan ismail petra kota bharu malaysia'},
  { icon: 'faPlaneDeparture', label: 'TGG Kuala Terengganu, Malaysia, Sultan Mahmud, TGG, sultan mahmud kuala terengganu malaysia', key:'A_TGG', cityBase: 65, city: 'TGG', country: 'MY', type: 'airport', nlabel: 'sultan mahmud kuala terengganu malaysia'},
  { icon: 'faPlaneDeparture', label: 'PLM Palembang, Indonesia, Sultan Mahmud Badaruddin II International, PLM, sultan mahmud badaruddin ii international palembang indonesia', key:'A_PLM', cityBase: 91, city: 'PLM', country: 'ID', type: 'airport', nlabel: 'sultan mahmud badaruddin ii international palembang indonesia'},
  { icon: 'faPlaneDeparture', label: 'SWQ Sumbawa Besar, Indonesia, Sultan Muhammad Kaharuddin III, SWQ, sultan muhammad kaharuddin iii sumbawa besar indonesia', key:'A_SWQ', cityBase: 127, city: 'SWQ', country: 'ID', type: 'airport', nlabel: 'sultan muhammad kaharuddin iii sumbawa besar indonesia'},
  { icon: 'faPlaneDeparture', label: 'BMU Bima, Indonesia, Sultan Muhammad Salahudin, BMU, sultan muhammad salahudin bima indonesia', key:'A_BMU', cityBase: 119, city: 'BMU', country: 'ID', type: 'airport', nlabel: 'sultan muhammad salahudin bima indonesia'},
  { icon: 'faPlaneDeparture', label: 'PKU Pekanbaru, Indonesia, Sultan Syarif Kasim II International, PKU, sultan syarif kasim ii international pekanbaru indonesia', key:'A_PKU', cityBase: 109, city: 'PKU', country: 'ID', type: 'airport', nlabel: 'sultan syarif kasim ii international pekanbaru indonesia'},
  { icon: 'faPlaneDeparture', label: 'DJB Jambi City, Indonesia, Sultan Thaha, DJB, sultan thaha jambi city indonesia', key:'A_DJB', cityBase: 127, city: 'DJB', country: 'ID', type: 'airport', nlabel: 'sultan thaha jambi city indonesia'},
  { icon: 'faPlaneDeparture', label: 'LSI Shetland Islands, United Kingdom, Sumburgh, LSI, sumburgh shetland islands united kingdom', key:'A_LSI', cityBase: -891, city: 'SDZ', country: 'GB', type: 'airport', nlabel: 'sumburgh shetland islands united kingdom'},
  { icon: 'faPlaneDeparture', label: 'WUX Wuxi, China, Sunan Shuofang International, WUX, sunan shuofang international wuxi china', key:'A_WUX', cityBase: 58, city: 'WUX', country: 'CN', type: 'airport', nlabel: 'sunan shuofang international wuxi china'},
  { icon: 'faPlaneDeparture', label: 'SDL Sundsvall, Sweden, Sundsvall–Timrå, SDL, sundsvall timra sundsvall sweden', key:'A_SDL', cityBase: 127, city: 'SDL', country: 'SE', type: 'airport', nlabel: 'sundsvall timra sundsvall sweden'},
  { icon: 'faPlaneDeparture', label: 'MCY Sunshine Coast Region, Australia, Sunshine Coast, MCY, sunshine coast sunshine coast region queensland australia', key:'A_MCY', cityBase: 84, city: 'MCY', country: 'AU', type: 'airport', nlabel: 'sunshine coast sunshine coast region queensland australia'},
  { icon: 'faPlaneDeparture', label: 'SUY Suntar, Russia, Suntar, SUY, suntar suntar russia', key:'A_SUY', cityBase: 127, city: 'SUY', country: 'RU', type: 'airport', nlabel: 'suntar suntar russia'},
  { icon: 'faPlaneDeparture', label: 'PNK Pontianak, Indonesia, Supadio International, PNK, supadio international pontianak indonesia', key:'A_PNK', cityBase: 93, city: 'PNK', country: 'ID', type: 'airport', nlabel: 'supadio international pontianak indonesia'},
  { icon: 'faPlaneDeparture', label: 'STV Surat, India, Surat, STV, surat surat india', key:'A_STV', cityBase: 71, city: 'STV', country: 'IN', type: 'airport', nlabel: 'surat surat india'},
  { icon: 'faPlaneDeparture', label: 'URT Surat Thani Province, Thailand, Surat Thani International, URT, surat thani international surat thani province thailand', key:'A_URT', cityBase: 77, city: 'URT', country: 'TH', type: 'airport', nlabel: 'surat thani international surat thani province thailand'},
  { icon: 'faPlaneDeparture', label: 'SGC Surgut, Russia, Surgut International, SGC, surgut international surgut russia', key:'A_SGC', cityBase: 45, city: 'SGC', country: 'RU', type: 'airport', nlabel: 'surgut international surgut russia'},
  { icon: 'faPlaneDeparture', label: 'SUG Surigao City, Philippines, Surigao, SUG, surigao surigao city philippines', key:'A_SUG', cityBase: 127, city: 'SUG', country: 'PH', type: 'airport', nlabel: 'surigao surigao city philippines'},
  { icon: 'faPlaneDeparture', label: 'SKH Surkhet, Nepal, Surkhet Airport, SKH, surkhet airport surkhet nepal', key:'A_SKH', cityBase: 127, city: 'SKH', country: 'NP', type: 'airport', nlabel: 'surkhet airport surkhet nepal'},
  { icon: 'faPlaneDeparture', label: 'BKK Bangkok, Thailand, Suvarnabhumi, BKK, suvarnabhumi bangkok thailand', key:'A_BKK', cityBase: 2, city: 'BKK', country: 'TH', type: 'airport', nlabel: 'suvarnabhumi bangkok thailand'},
  { icon: 'faPlaneDeparture', label: 'LYR Longyearbyen, Svalbard & Jan Mayen, Svalbard Airport, Longyear, LYR, svalbard airport longyearbyen svalbard and jan mayen', key:'A_LYR', cityBase: 80, city: 'LYR', country: 'SJ', type: 'airport', nlabel: 'svalbard airport longyearbyen svalbard and jan mayen'},
  { icon: 'faPlaneDeparture', label: 'EVG Sveg, Sweden, Sveg, EVG, sveg sveg sweden', key:'A_EVG', cityBase: 127, city: 'EVG', country: 'SE', type: 'airport', nlabel: 'sveg sveg sweden'},
  { icon: 'faPlaneDeparture', label: 'SVJ Svolvær, Norway, Svolvær Airport, Helle, SVJ, svolvaer airport svolvaer norway', key:'A_SVJ', cityBase: 123, city: 'SVJ', country: 'NO', type: 'airport', nlabel: 'svolvaer airport svolvaer norway'},
  { icon: 'faPlaneDeparture', label: 'RPR Raipur, India, Swami Vivekananda, RPR, swami vivekananda raipur india', key:'A_RPR', cityBase: 100, city: 'RPR', country: 'IN', type: 'airport', nlabel: 'swami vivekananda raipur india'},
  { icon: 'faPlaneDeparture', label: 'BDJ Banjarmasin, Indonesia, Syamsudin Noor International, BDJ, syamsudin noor international banjarmasin indonesia', key:'A_BDJ', cityBase: 114, city: 'BDJ', country: 'ID', type: 'airport', nlabel: 'syamsudin noor international banjarmasin indonesia'},
  { icon: 'faPlaneDeparture', label: 'SYD Sydney, Australia, Sydney, SYD, sydney sydney new south wales australia', key:'A_SYD', cityBase: 11, city: 'SYD', country: 'AU', type: 'airport', nlabel: 'sydney sydney new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'YQY Sydney, Canada, Sydney/J.A. Douglas McCurdy, YQY, sydney j a douglas mccurdy sydney nova scotia canada', key:'A_YQY', cityBase: 100, city: 'YQY', country: 'CA', type: 'airport', nlabel: 'sydney j a douglas mccurdy sydney nova scotia canada'},
  { icon: 'faPlaneDeparture', label: 'SCW Syktyvkar, Russia, Syktyvkar, SCW, syktyvkar syktyvkar russia', key:'A_SCW', cityBase: 46, city: 'SCW', country: 'RU', type: 'airport', nlabel: 'syktyvkar syktyvkar russia'},
  { icon: 'faPlaneDeparture', label: 'GWT Westerland, Germany, Sylt, GWT, sylt westerland germany', key:'A_GWT', cityBase: 11, city: 'GWT', country: 'DE', type: 'airport', nlabel: 'sylt westerland germany'},
  { icon: 'faPlaneDeparture', label: 'SYR Syracuse, United States, Syracuse Hancock International, SYR, syracuse hancock international syracuse new york united states', key:'A_SYR', cityBase: 15, city: 'SYR', country: 'US', type: 'airport', nlabel: 'syracuse hancock international syracuse new york united states'},
  { icon: 'faPlaneDeparture', label: 'JSY Syros, Greece, Syros Island National, JSY, syros island national syros greece', key:'A_JSY', cityBase: 23, city: 'JSY', country: 'GR', type: 'airport', nlabel: 'syros island national syros greece'},
  { icon: 'faPlaneDeparture', label: 'PVD Providence, United States, T. F. Green, PVD, t f green providence rhode island united states', key:'A_PVD', cityBase: 14, city: 'PVD', country: 'US', type: 'airport', nlabel: 't f green providence rhode island united states'},
  { icon: 'faPlaneDeparture', label: 'TIF Ta if, Saudi Arabia, Ta if Regional, TIF, ta if regional ta if saudi arabia', key:'A_TIF', cityBase: 91, city: 'TIF', country: 'SA', type: 'airport', nlabel: 'ta if regional ta if saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'TCX Tabas, Iran, Tabas Airport, TCX, tabas airport tabas iran', key:'A_TCX', cityBase: 127, city: 'TCX', country: 'IR', type: 'airport', nlabel: 'tabas airport tabas iran'},
  { icon: 'faPlaneDeparture', label: 'TBT Tabatinga, Brazil, Tabatinga International, TBT, tabatinga international tabatinga state of amazonas brazil', key:'A_TBT', cityBase: 127, city: 'TBT', country: 'BR', type: 'airport', nlabel: 'tabatinga international tabatinga state of amazonas brazil'},
  { icon: 'faPlaneDeparture', label: 'TBO Tabora, Tanzania, Tabora, TBO, tabora tabora tanzania', key:'A_TBO', cityBase: 127, city: 'TBO', country: 'TZ', type: 'airport', nlabel: 'tabora tabora tanzania'},
  { icon: 'faPlaneDeparture', label: 'TBZ Tabriz, Iran, Tabriz International, TBZ, tabriz international tabriz iran', key:'A_TBZ', cityBase: 85, city: 'TBZ', country: 'IR', type: 'airport', nlabel: 'tabriz international tabriz iran'},
  { icon: 'faPlaneDeparture', label: 'TBG Tabubil, Papua New Guinea, Tabubil, TBG, tabubil tabubil papua new guinea', key:'A_TBG', cityBase: 127, city: 'TBG', country: 'PG', type: 'airport', nlabel: 'tabubil tabubil papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'TUU Tabuk, Saudi Arabia, Tabuk Regional, TUU, tabuk regional tabuk saudi arabia', key:'A_TUU', cityBase: 82, city: 'TUU', country: 'SA', type: 'airport', nlabel: 'tabuk regional tabuk saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'TCG Tacheng Prefecture, China, Tacheng, TCG, tacheng tacheng prefecture china', key:'A_TCG', cityBase: 74, city: 'TCG', country: 'CN', type: 'airport', nlabel: 'tacheng tacheng prefecture china'},
  { icon: 'faPlaneDeparture', label: 'THL Tachileik, Myanmar (Burma), Tachilek, THL, tachilek tachileik myanmar (burma)', key:'A_THL', cityBase: 127, city: 'THL', country: 'MM', type: 'airport', nlabel: 'tachilek tachileik myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'XTL Tadoule Lake, Canada, Tadoule Lake, XTL, tadoule lake tadoule lake manitoba canada', key:'A_XTL', cityBase: 127, city: 'XTL', country: 'CA', type: 'airport', nlabel: 'tadoule lake tadoule lake manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'TAG Tagbilaran, Philippines, Tagbilaran, TAG, tagbilaran tagbilaran philippines', key:'A_TAG', cityBase: 58, city: 'TAG', country: 'PH', type: 'airport', nlabel: 'tagbilaran tagbilaran philippines'},
  { icon: 'faPlaneDeparture', label: 'RMQ Taichung, Taiwan, Taichung, RMQ, taichung taichung taiwan', key:'A_RMQ', cityBase: 64, city: 'RMQ', country: 'TW', type: 'airport', nlabel: 'taichung taichung taiwan'},
  { icon: 'faPlaneDeparture', label: 'TNN Tainan, Taiwan, Tainan, TNN, tainan tainan taiwan', key:'A_TNN', cityBase: 127, city: 'TNN', country: 'TW', type: 'airport', nlabel: 'tainan tainan taiwan'},
  { icon: 'faPlaneDeparture', label: 'TSA Taipei, Taiwan, Taipei Songshan, TSA, taipei songshan taipei taiwan', key:'A_TSA', cityBase: 77, city: 'TPE', country: 'TW', type: 'airport', nlabel: 'taipei songshan taipei taiwan'},
  { icon: 'faPlaneDeparture', label: 'TTT Taitung County, Taiwan, Taitung, TTT, taitung taitung county taiwan', key:'A_TTT', cityBase: 125, city: 'TTT', country: 'TW', type: 'airport', nlabel: 'taitung taitung county taiwan'},
  { icon: 'faPlaneDeparture', label: 'TPE Taipei, Taiwan, Taiwan Taoyuan International, TPE, taiwan taoyuan international taipei taiwan', key:'A_TPE', cityBase: 6, city: 'TPE', country: 'TW', type: 'airport', nlabel: 'taiwan taoyuan international taipei taiwan'},
  { icon: 'faPlaneDeparture', label: 'TYN Taiyuan, China, Taiyuan Wusu International, TYN, taiyuan wusu international taiyuan china', key:'A_TYN', cityBase: 56, city: 'TYN', country: 'CN', type: 'airport', nlabel: 'taiyuan wusu international taiyuan china'},
  { icon: 'faPlaneDeparture', label: 'TAI Taiz Governorate, Yemen, Taiz International, TAI, taiz international taiz governorate yemen', key:'A_TAI', cityBase: 127, city: 'TAI', country: 'YE', type: 'airport', nlabel: 'taiz international taiz governorate yemen'},
  { icon: 'faPlaneDeparture', label: 'HYN Taizhou, China, Taizhou Luqiao, HYN, taizhou luqiao taizhou china', key:'A_HYN', cityBase: 69, city: 'HYN', country: 'CN', type: 'airport', nlabel: 'taizhou luqiao taizhou china'},
  { icon: 'faPlaneDeparture', label: 'TAK Takamatsu, Japan, Takamatsu, TAK, takamatsu takamatsu japan', key:'A_TAK', cityBase: 108, city: 'TAK', country: 'JP', type: 'airport', nlabel: 'takamatsu takamatsu japan'},
  { icon: 'faPlaneDeparture', label: 'TKP Takapoto, French Polynesia, Takapoto, TKP, takapoto takapoto french polynesia', key:'A_TKP', cityBase: 127, city: 'TKP', country: 'PF', type: 'airport', nlabel: 'takapoto takapoto french polynesia'},
  { icon: 'faPlaneDeparture', label: 'TKX Takaroa, French Polynesia, Takaroa, TKX, takaroa takaroa french polynesia', key:'A_TKX', cityBase: 127, city: 'TKX', country: 'PF', type: 'airport', nlabel: 'takaroa takaroa french polynesia'},
  { icon: 'faPlaneDeparture', label: 'TXE Takengon, Indonesia, Takengon Rembele, TXE, takengon rembele takengon indonesia', key:'A_TXE', cityBase: 127, city: 'TXE', country: 'ID', type: 'airport', nlabel: 'takengon rembele takengon indonesia'},
  { icon: 'faPlaneDeparture', label: 'VVZ Illizi, Algeria, Takhamalt, VVZ, takhamalt illizi algeria', key:'A_VVZ', cityBase: 127, city: 'VVZ', country: 'DZ', type: 'airport', nlabel: 'takhamalt illizi algeria'},
  { icon: 'faPlaneDeparture', label: 'TKD Sekondi-Takoradi, Ghana, Takoradi, TKD, takoradi sekondi takoradi ghana', key:'A_TKD', cityBase: 127, city: 'TKD', country: 'GH', type: 'airport', nlabel: 'takoradi sekondi takoradi ghana'},
  { icon: 'faPlaneDeparture', label: 'ARH Arkhangelsk, Russia, Talagi, ARH, talagi arkhangelsk russia', key:'A_ARH', cityBase: 45, city: 'ARH', country: 'RU', type: 'airport', nlabel: 'talagi arkhangelsk russia'},
  { icon: 'faPlaneDeparture', label: 'TLK Vitim, Sakha Republic, Russia, Talakan, TLK, talakan vitim russia', key:'A_TLK', cityBase: 127, city: 'TLK', country: 'RU', type: 'airport', nlabel: 'talakan vitim russia'},
  { icon: 'faPlaneDeparture', label: 'TDK Taldykorgan, Kazakhstan, Taldykorgan, TDK, taldykorgan taldykorgan kazakhstan', key:'A_TDK', cityBase: 127, city: 'TDK', country: 'KZ', type: 'airport', nlabel: 'taldykorgan taldykorgan kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'TLH Tallahassee, United States, Tallahassee International, TLH, tallahassee international tallahassee florida united states', key:'A_TLH', cityBase: 31, city: 'TLH', country: 'US', type: 'airport', nlabel: 'tallahassee international tallahassee florida united states'},
  { icon: 'faPlaneDeparture', label: 'TLL Tallinn, Estonia, Tallinn, TLL, tallinn tallinn estonia', key:'A_TLL', cityBase: 35, city: 'TLL', country: 'EE', type: 'airport', nlabel: 'tallinn tallinn estonia'},
  { icon: 'faPlaneDeparture', label: 'YYH Taloyoak, Canada, Taloyoak, YYH, taloyoak taloyoak nunavut canada', key:'A_YYH', cityBase: 127, city: 'YYH', country: 'CA', type: 'airport', nlabel: 'taloyoak taloyoak nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'TML Tamale, Ghana, Tamale, TML, tamale tamale ghana', key:'A_TML', cityBase: 99, city: 'TML', country: 'GH', type: 'airport', nlabel: 'tamale tamale ghana'},
  { icon: 'faPlaneDeparture', label: 'TNO Tamarindo, Costa Rica, Tamarindo, TNO, tamarindo tamarindo costa rica', key:'A_TNO', cityBase: 127, city: 'TNO', country: 'CR', type: 'airport', nlabel: 'tamarindo tamarindo costa rica'},
  { icon: 'faPlaneDeparture', label: 'TMC Tambolaka, Indonesia, Tambolaka, TMC, tambolaka tambolaka indonesia', key:'A_TMC', cityBase: 118, city: 'TMC', country: 'ID', type: 'airport', nlabel: 'tambolaka tambolaka indonesia'},
  { icon: 'faPlaneDeparture', label: 'TMU Tambor, Costa Rica, Tambor, TMU, tambor tambor costa rica', key:'A_TMU', cityBase: 127, city: 'TMU', country: 'CR', type: 'airport', nlabel: 'tambor tambor costa rica'},
  { icon: 'faPlaneDeparture', label: 'TBW Tambov, Russia, Tambov Donskoye, TBW, tambov donskoye tambov russia', key:'A_TBW', cityBase: 53, city: 'TBW', country: 'RU', type: 'airport', nlabel: 'tambov donskoye tambov russia'},
  { icon: 'faPlaneDeparture', label: 'TPA Tampa, United States, Tampa International, TPA, tampa international tampa florida united states', key:'A_TPA', cityBase: 1, city: 'TPA', country: 'US', type: 'airport', nlabel: 'tampa international tampa florida united states'},
  { icon: 'faPlaneDeparture', label: 'MJU Mamuju Regency, Indonesia, Tampa Padang, MJU, tampa padang mamuju regency indonesia', key:'A_MJU', cityBase: 127, city: 'MJU', country: 'ID', type: 'airport', nlabel: 'tampa padang mamuju regency indonesia'},
  { icon: 'faPlaneDeparture', label: 'TMP Tampere, Finland, Tampere–Pirkkala, TMP, tampere pirkkala tampere finland', key:'A_TMP', cityBase: 111, city: 'TMP', country: 'FI', type: 'airport', nlabel: 'tampere pirkkala tampere finland'},
  { icon: 'faPlaneDeparture', label: 'TAM Tampico, Mexico, Tampico International, TAM, tampico international tampico mexico', key:'A_TAM', cityBase: 50, city: 'TAM', country: 'MX', type: 'airport', nlabel: 'tampico international tampico mexico'},
  { icon: 'faPlaneDeparture', label: 'TMW Tamworth, Australia, Tamworth, TMW, tamworth tamworth new south wales australia', key:'A_TMW', cityBase: 127, city: 'TMW', country: 'AU', type: 'airport', nlabel: 'tamworth tamworth new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'SGN Ho Chi Minh City, Vietnam, Tan Son Nhat International, SGN, tan son nhat international ho chi minh city vietnam', key:'A_SGN', cityBase: 12, city: 'SGN', country: 'VN', type: 'airport', nlabel: 'tan son nhat international ho chi minh city vietnam'},
  { icon: 'faPlaneDeparture', label: 'TTA Tan-Tan, Morocco, Tan Tan, TTA, tan tan tan tan morocco', key:'A_TTA', cityBase: 127, city: 'TTA', country: 'MA', type: 'airport', nlabel: 'tan tan tan tan morocco'},
  { icon: 'faPlaneDeparture', label: 'CNF Belo Horizonte, Brazil, Tancredo Neves International, CNF, tancredo neves international belo horizonte state of minas gerais brazil', key:'A_CNF', cityBase: 68, city: 'BHZ', country: 'BR', type: 'airport', nlabel: 'tancredo neves international belo horizonte state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'TDG Tandag, Philippines, Tandag, TDG, tandag tandag philippines', key:'A_TDG', cityBase: 127, city: 'TDG', country: 'PH', type: 'airport', nlabel: 'tandag tandag philippines'},
  { icon: 'faPlaneDeparture', label: 'TGT Tanga, Tanzania, Tanga, TGT, tanga tanga tanzania', key:'A_TGT', cityBase: 127, city: 'TGT', country: 'TZ', type: 'airport', nlabel: 'tanga tanga tanzania'},
  { icon: 'faPlaneDeparture', label: 'TNG Tangier, Morocco, Tangier Ibn Battouta, TNG, tangier ibn battouta tangier morocco', key:'A_TNG', cityBase: 44, city: 'TNG', country: 'MA', type: 'airport', nlabel: 'tangier ibn battouta tangier morocco'},
  { icon: 'faPlaneDeparture', label: 'TVS Tangshan, China, Tangshan Sannühe, TVS, tangshan sannuhe tangshan china', key:'A_TVS', cityBase: 75, city: 'TVS', country: 'CN', type: 'airport', nlabel: 'tangshan sannuhe tangshan china'},
  { icon: 'faPlaneDeparture', label: 'TJS Tanjung Selor, Indonesia, Tanjung Harapan, TJS, tanjung harapan tanjung selor indonesia', key:'A_TJS', cityBase: 127, city: 'TJS', country: 'ID', type: 'airport', nlabel: 'tanjung harapan tanjung selor indonesia'},
  { icon: 'faPlaneDeparture', label: 'TGC Tanjung Manis, Malaysia, Tanjung Manis, TGC, tanjung manis tanjung manis malaysia', key:'A_TGC', cityBase: 127, city: 'TGC', country: 'MY', type: 'airport', nlabel: 'tanjung manis tanjung manis malaysia'},
  { icon: 'faPlaneDeparture', label: 'TAP Tapachula, Mexico, Tapachula International, TAP, tapachula international tapachula mexico', key:'A_TAP', cityBase: 86, city: 'TAP', country: 'MX', type: 'airport', nlabel: 'tapachula international tapachula mexico'},
  { icon: 'faPlaneDeparture', label: 'DMB Taraz, Kazakhstan, Taraz, DMB, taraz taraz kazakhstan', key:'A_DMB', cityBase: 127, city: 'DMB', country: 'KZ', type: 'airport', nlabel: 'taraz taraz kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'LDE Lourdes, France, Tarbes–Lourdes–Pyrénées, LDE, tarbes lourdes pyrenees lourdes france', key:'A_LDE', cityBase: 61, city: 'LDE', country: 'FR', type: 'airport', nlabel: 'tarbes lourdes pyrenees lourdes france'},
  { icon: 'faPlaneDeparture', label: 'TRO Taree, Australia, Taree, TRO, taree taree new south wales australia', key:'A_TRO', cityBase: 127, city: 'TRO', country: 'AU', type: 'airport', nlabel: 'taree taree new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'TGM Târgu Mureș, Romania, Târgu Mureș International, TGM, targu mures international targu mures romania', key:'A_TGM', cityBase: 127, city: 'TGM', country: 'RO', type: 'airport', nlabel: 'targu mures international targu mures romania'},
  { icon: 'faPlaneDeparture', label: 'TIZ Tari, Papua New Guinea, Tari, TIZ, tari tari papua new guinea', key:'A_TIZ', cityBase: 127, city: 'TIZ', country: 'PG', type: 'airport', nlabel: 'tari tari papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'TAY Tartu, Estonia, Tartu, TAY, tartu tartu estonia', key:'A_TAY', cityBase: 127, city: 'TAY', country: 'EE', type: 'airport', nlabel: 'tartu tartu estonia'},
  { icon: 'faPlaneDeparture', label: 'TAS Tashkent, Uzbekistan, Tashkent International, TAS, tashkent international tashkent uzbekistan', key:'A_TAS', cityBase: 39, city: 'TAS', country: 'UZ', type: 'airport', nlabel: 'tashkent international tashkent uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'TSY Tasikmalaya, Indonesia, Tasikmalaya Wiriadinata, TSY, tasikmalaya wiriadinata tasikmalaya indonesia', key:'A_TSY', cityBase: 127, city: 'TSY', country: 'ID', type: 'airport', nlabel: 'tasikmalaya wiriadinata tasikmalaya indonesia'},
  { icon: 'faPlaneDeparture', label: 'YTQ Tasiujaq, Canada, Tasiujaq, YTQ, tasiujaq tasiujaq quebec canada', key:'A_YTQ', cityBase: 127, city: 'YTQ', country: 'CA', type: 'airport', nlabel: 'tasiujaq tasiujaq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'TKV Tatakoto, French Polynesia, Tatakoto, TKV, tatakoto tatakoto french polynesia', key:'A_TKV', cityBase: 127, city: 'TKV', country: 'PF', type: 'airport', nlabel: 'tatakoto tatakoto french polynesia'},
  { icon: 'faPlaneDeparture', label: 'TUO Taupo, New Zealand, Taupo, TUO, taupo taupo new zealand', key:'A_TUO', cityBase: 127, city: 'TUO', country: 'NZ', type: 'airport', nlabel: 'taupo taupo new zealand'},
  { icon: 'faPlaneDeparture', label: 'TRG Tauranga, New Zealand, Tauranga, TRG, tauranga tauranga new zealand', key:'A_TRG', cityBase: 97, city: 'TRG', country: 'NZ', type: 'airport', nlabel: 'tauranga tauranga new zealand'},
  { icon: 'faPlaneDeparture', label: 'TWU Tawau, Malaysia, Tawau, TWU, tawau tawau malaysia', key:'A_TWU', cityBase: 33, city: 'TWU', country: 'MY', type: 'airport', nlabel: 'tawau tawau malaysia'},
  { icon: 'faPlaneDeparture', label: 'OUZ Zouérat, Mauritania, Tazadit, OUZ, tazadit zouerat mauritania', key:'A_OUZ', cityBase: 127, city: 'OUZ', country: 'MR', type: 'airport', nlabel: 'tazadit zouerat mauritania'},
  { icon: 'faPlaneDeparture', label: 'TBS Tbilisi, Georgia, Tbilisi International, TBS, tbilisi international tbilisi georgia', key:'A_TBS', cityBase: 26, city: 'TBS', country: 'GE', type: 'airport', nlabel: 'tbilisi international tbilisi georgia'},
  { icon: 'faPlaneDeparture', label: 'ANC Anchorage, United States, Ted Stevens Anchorage International, ANC, ted stevens anchorage international anchorage alaska united states', key:'A_ANC', cityBase: 12, city: 'ANC', country: 'US', type: 'airport', nlabel: 'ted stevens anchorage international anchorage alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TFF Tefé, Brazil, Tefé, TFF, tefe tefe state of amazonas brazil', key:'A_TFF', cityBase: 107, city: 'TFF', country: 'BR', type: 'airport', nlabel: 'tefe tefe state of amazonas brazil'},
  { icon: 'faPlaneDeparture', label: 'IKA Tehran, Iran, Tehran Imam Khomeini International, IKA, tehran imam khomeini international tehran iran', key:'A_IKA', cityBase: 25, city: 'THR', country: 'IR', type: 'airport', nlabel: 'tehran imam khomeini international tehran iran'},
  { icon: 'faPlaneDeparture', label: 'TXF Teixeira de Freitas, Brazil, Teixeira de Freitas, TXF, teixeira de freitas teixeira de freitas state of bahia brazil', key:'A_TXF', cityBase: 127, city: 'TXF', country: 'BR', type: 'airport', nlabel: 'teixeira de freitas teixeira de freitas state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'TEQ Tekirdağ, Turkey, Tekirdağ Çorlu, TEQ, tekirdag corlu tekirdag turkey', key:'A_TEQ', cityBase: 34, city: 'TEQ', country: 'TR', type: 'airport', nlabel: 'tekirdag corlu tekirdag turkey'},
  { icon: 'faPlaneDeparture', label: 'TLA Teller, United States, Teller, TLA, teller teller alaska united states', key:'A_TLA', cityBase: 127, city: 'TLA', country: 'US', type: 'airport', nlabel: 'teller teller alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TEX Telluride, United States, Telluride Regional, TEX, telluride regional telluride colorado united states', key:'A_TEX', cityBase: 106, city: 'TEX', country: 'US', type: 'airport', nlabel: 'telluride regional telluride colorado united states'},
  { icon: 'faPlaneDeparture', label: 'TKE Tenakee Springs, United States, Tenakee Seaplane Base, TKE, tenakee seaplane base tenakee springs alaska united states', key:'A_TKE', cityBase: 127, city: 'TKE', country: 'US', type: 'airport', nlabel: 'tenakee seaplane base tenakee springs alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TFN Tenerife, Spain, Tenerife-North, TFN, tenerife north tenerife spain', key:'A_TFN', cityBase: 15, city: 'TCI', country: 'ES', type: 'airport', nlabel: 'tenerife north tenerife spain'},
  { icon: 'faPlaneDeparture', label: 'TFS Tenerife, Spain, Tenerife–South, TFS, tenerife south tenerife spain', key:'A_TFS', cityBase: 8, city: 'TCI', country: 'ES', type: 'airport', nlabel: 'tenerife south tenerife spain'},
  { icon: 'faPlaneDeparture', label: 'TCZ Tengchong, China, Tengchong Tuofeng, TCZ, tengchong tuofeng tengchong china', key:'A_TCZ', cityBase: 74, city: 'TCZ', country: 'CN', type: 'airport', nlabel: 'tengchong tuofeng tengchong china'},
  { icon: 'faPlaneDeparture', label: 'TUC San Miguel de Tucumán, Argentina, Teniente General Benjamín Matienzo International, TUC, teniente general benjamin matienzo international san miguel de tucuman tucuman argentina', key:'A_TUC', cityBase: 95, city: 'TUC', country: 'AR', type: 'airport', nlabel: 'teniente general benjamin matienzo international san miguel de tucuman tucuman argentina'},
  { icon: 'faPlaneDeparture', label: 'TDD Trinidad, Bolivia, Teniente Jorge Henrich Arauz, TDD, teniente jorge henrich arauz trinidad bolivia', key:'A_TDD', cityBase: 123, city: 'TDD', country: 'BO', type: 'airport', nlabel: 'teniente jorge henrich arauz trinidad bolivia'},
  { icon: 'faPlaneDeparture', label: 'PNT Puerto Natales, Chile, Teniente Julio Gallardo, PNT, teniente julio gallardo puerto natales chile', key:'A_PNT', cityBase: 90, city: 'PNT', country: 'CL', type: 'airport', nlabel: 'teniente julio gallardo puerto natales chile'},
  { icon: 'faPlaneDeparture', label: 'TCA Tennant Creek, Australia, Tennant Creek, TCA, tennant creek tennant creek northern territory australia', key:'A_TCA', cityBase: 127, city: 'TCA', country: 'AU', type: 'airport', nlabel: 'tennant creek tennant creek northern territory australia'},
  { icon: 'faPlaneDeparture', label: 'LUA Lukla, Nepal, Tenzing–Hillary, LUA, tenzing hillary lukla nepal', key:'A_LUA', cityBase: 127, city: 'LUA', country: 'NP', type: 'airport', nlabel: 'tenzing hillary lukla nepal'},
  { icon: 'faPlaneDeparture', label: 'TPQ Tepic, Mexico, Tepic International, TPQ, tepic international tepic mexico', key:'A_TPQ', cityBase: 88, city: 'TPQ', country: 'MX', type: 'airport', nlabel: 'tepic international tepic mexico'},
  { icon: 'faPlaneDeparture', label: 'TMJ Termez, Uzbekistan, Termez, TMJ, termez termez uzbekistan', key:'A_TMJ', cityBase: 119, city: 'TMJ', country: 'UZ', type: 'airport', nlabel: 'termez termez uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'EIS Tortola, British Virgin Islands, Terrance B. Lettsome International, EIS, terrance b lettsome international tortola british virgin islands', key:'A_EIS', cityBase: 70, city: 'TOV', country: 'VG', type: 'airport', nlabel: 'terrance b lettsome international tortola british virgin islands'},
  { icon: 'faPlaneDeparture', label: 'LSS Les Saintes, France, Terre-de-Haut Airport, LSS, terre de haut airport les saintes guadeloupe france 1', key:'A_LSS', cityBase: 127, city: 'LSS', country: 'FR', type: 'airport', nlabel: 'terre de haut airport les saintes guadeloupe france 1'},
  { icon: 'faPlaneDeparture', label: 'ZTB Tête-à-la-Baleine, Quebec, Canada, Tête-à-la-Baleine, ZTB, tete a la baleine tete a la baleine quebec canada', key:'A_ZTB', cityBase: 127, city: 'ZTB', country: 'CA', type: 'airport', nlabel: 'tete a la baleine tete a la baleine quebec canada'},
  { icon: 'faPlaneDeparture', label: 'TXK Texarkana, United States, Texarkana Regional, TXK, texarkana regional texarkana arkansas united states', key:'A_TXK', cityBase: 97, city: 'TXK', country: 'US', type: 'airport', nlabel: 'texarkana regional texarkana arkansas united states'},
  { icon: 'faPlaneDeparture', label: 'TEZ Tezpur, India, Tezpur, TEZ, tezpur tezpur india', key:'A_TEZ', cityBase: 127, city: 'TEZ', country: 'IN', type: 'airport', nlabel: 'tezpur tezpur india'},
  { icon: 'faPlaneDeparture', label: 'SNW Thandwe, Myanmar (Burma), Thandwe, SNW, thandwe thandwe myanmar (burma)', key:'A_SNW', cityBase: 127, city: 'SNW', country: 'MM', type: 'airport', nlabel: 'thandwe thandwe myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'ZBL Biloela, Australia, Thangool, ZBL, thangool biloela queensland australia', key:'A_ZBL', cityBase: 127, city: 'ZBL', country: 'AU', type: 'airport', nlabel: 'thangool biloela queensland australia'},
  { icon: 'faPlaneDeparture', label: 'THD Thanh Hoa Province, Vietnam, Thanh Hoa Tho Xuan, THD, thanh hoa tho xuan thanh hoa province vietnam', key:'A_THD', cityBase: 127, city: 'THD', country: 'VN', type: 'airport', nlabel: 'thanh hoa tho xuan thanh hoa province vietnam'},
  { icon: 'faPlaneDeparture', label: 'XTG Thargomindah, Australia, Thargomindah, XTG, thargomindah thargomindah queensland australia', key:'A_XTG', cityBase: 127, city: 'XTG', country: 'AU', type: 'airport', nlabel: 'thargomindah thargomindah queensland australia'},
  { icon: 'faPlaneDeparture', label: 'CID Cedar Rapids, United States, The Eastern Iowa, CID, the eastern iowa cedar rapids iowa united states', key:'A_CID', cityBase: 37, city: 'CID', country: 'US', type: 'airport', nlabel: 'the eastern iowa cedar rapids iowa united states'},
  { icon: 'faPlaneDeparture', label: 'YQD The Pas, Canada, The Pas, YQD, the pas the pas manitoba canada', key:'A_YQD', cityBase: 127, city: 'YQD', country: 'CA', type: 'airport', nlabel: 'the pas the pas manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'SKG Thessaloniki, Greece, Thessaloniki International Airport  Macedonia , SKG, thessaloniki international airport macedonia thessaloniki greece', key:'A_SKG', cityBase: 9, city: 'SKG', country: 'GR', type: 'airport', nlabel: 'thessaloniki international airport macedonia thessaloniki greece'},
  { icon: 'faPlaneDeparture', label: 'TVF Thief River Falls, United States, Thief River Falls Regional, TVF, thief river falls regional thief river falls minnesota united states', key:'A_TVF', cityBase: 126, city: 'TVF', country: 'US', type: 'airport', nlabel: 'thief river falls regional thief river falls minnesota united states'},
  { icon: 'faPlaneDeparture', label: 'YTH Thompson, Canada, Thompson, YTH, thompson thompson manitoba canada', key:'A_YTH', cityBase: 127, city: 'YTH', country: 'CA', type: 'airport', nlabel: 'thompson thompson manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'KTB Thorne Bay, United States, Thorne Bay Seaplane Base, KTB, thorne bay seaplane base thorne bay alaska united states', key:'A_KTB', cityBase: 127, city: 'KTB', country: 'US', type: 'airport', nlabel: 'thorne bay seaplane base thorne bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YQT Thunder Bay, Canada, Thunder Bay International, YQT, thunder bay international thunder bay ontario canada', key:'A_YQT', cityBase: 122, city: 'YQT', country: 'CA', type: 'airport', nlabel: 'thunder bay international thunder bay ontario canada'},
  { icon: 'faPlaneDeparture', label: 'TSN Tianjin, China, Tianjin Binhai International, TSN, tianjin binhai international tianjin china', key:'A_TSN', cityBase: 34, city: 'TSN', country: 'CN', type: 'airport', nlabel: 'tianjin binhai international tianjin china'},
  { icon: 'faPlaneDeparture', label: 'TGJ Tiga Island, New Caledonia, Tiga, TGJ, tiga tiga island new caledonia', key:'A_TGJ', cityBase: 127, city: 'TGJ', country: 'NC', type: 'airport', nlabel: 'tiga tiga island new caledonia'},
  { icon: 'faPlaneDeparture', label: 'TIJ Tijuana, Mexico, Tijuana International, TIJ, tijuana international tijuana mexico', key:'A_TIJ', cityBase: 21, city: 'TIJ', country: 'MX', type: 'airport', nlabel: 'tijuana international tijuana mexico'},
  { icon: 'faPlaneDeparture', label: 'TIH Tikehau, French Polynesia, Tikehau, TIH, tikehau tikehau french polynesia', key:'A_TIH', cityBase: 127, city: 'TIH', country: 'PF', type: 'airport', nlabel: 'tikehau tikehau french polynesia'},
  { icon: 'faPlaneDeparture', label: 'IKS Tiksi, Russia, Tiksi, IKS, tiksi tiksi russia', key:'A_IKS', cityBase: 127, city: 'IKS', country: 'RU', type: 'airport', nlabel: 'tiksi tiksi russia'},
  { icon: 'faPlaneDeparture', label: 'TIU Timaru, New Zealand, Timaru, TIU, timaru timaru new zealand', key:'A_TIU', cityBase: 106, city: 'TIU', country: 'NZ', type: 'airport', nlabel: 'timaru timaru new zealand'},
  { icon: 'faPlaneDeparture', label: 'TMX Timimoun, Algeria, Timimoun, TMX, timimoun timimoun algeria', key:'A_TMX', cityBase: 121, city: 'TMX', country: 'DZ', type: 'airport', nlabel: 'timimoun timimoun algeria'},
  { icon: 'faPlaneDeparture', label: 'TSR Timișoara, Romania, Timișoara Traian Vuia International, TSR, timisoara traian vuia international timisoara romania', key:'A_TSR', cityBase: 22, city: 'TSR', country: 'RO', type: 'airport', nlabel: 'timisoara traian vuia international timisoara romania'},
  { icon: 'faPlaneDeparture', label: 'YTS Timmins, Canada, Timmins/Victor M. Power, YTS, timmins victor m power timmins ontario canada', key:'A_YTS', cityBase: 127, city: 'YTS', country: 'CA', type: 'airport', nlabel: 'timmins victor m power timmins ontario canada'},
  { icon: 'faPlaneDeparture', label: 'TNC Tin City, United States, Tin City LRRS, TNC, tin city lrrs tin city alaska united states', key:'A_TNC', cityBase: 127, city: 'TNC', country: 'US', type: 'airport', nlabel: 'tin city lrrs tin city alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TIN Tindouf, Algeria, Tindouf, TIN, tindouf tindouf algeria', key:'A_TIN', cityBase: 127, city: 'TIN', country: 'DZ', type: 'airport', nlabel: 'tindouf tindouf algeria'},
  { icon: 'faPlaneDeparture', label: 'TGI Tingo María, Peru, Tingo María, TGI, tingo maria tingo maria peru', key:'A_TGI', cityBase: 127, city: 'TGI', country: 'PE', type: 'airport', nlabel: 'tingo maria tingo maria peru'},
  { icon: 'faPlaneDeparture', label: 'TIA Tirana, Albania, Tirana International Airport Nënë Tereza, TIA, tirana international airport nene tereza tirana albania', key:'A_TIA', cityBase: 28, city: 'TIA', country: 'AL', type: 'airport', nlabel: 'tirana international airport nene tereza tirana albania'},
  { icon: 'faPlaneDeparture', label: 'TRE Tiree, United Kingdom, Tiree, TRE, tiree tiree united kingdom', key:'A_TRE', cityBase: -873, city: 'TRE', country: 'GB', type: 'airport', nlabel: 'tiree tiree united kingdom'},
  { icon: 'faPlaneDeparture', label: 'TRZ Tiruchirappalli, India, Tiruchirappalli International, TRZ, tiruchirappalli international tiruchirappalli india', key:'A_TRZ', cityBase: 54, city: 'TRZ', country: 'IN', type: 'airport', nlabel: 'tiruchirappalli international tiruchirappalli india'},
  { icon: 'faPlaneDeparture', label: 'TIR Tirupati, India, Tirupati, TIR, tirupati tirupati india', key:'A_TIR', cityBase: 87, city: 'TIR', country: 'IN', type: 'airport', nlabel: 'tirupati tirupati india'},
  { icon: 'faPlaneDeparture', label: 'DJG Djanet, Algeria, Tiska, DJG, tiska djanet algeria', key:'A_DJG', cityBase: 127, city: 'DJG', country: 'DZ', type: 'airport', nlabel: 'tiska djanet algeria'},
  { icon: 'faPlaneDeparture', label: 'TIV Tivat, Montenegro, Tivat, TIV, tivat tivat montenegro', key:'A_TIV', cityBase: 23, city: 'TIV', country: 'ME', type: 'airport', nlabel: 'tivat tivat montenegro'},
  { icon: 'faPlaneDeparture', label: 'PKY Palangka Raya, Indonesia, Tjilik Riwut, PKY, tjilik riwut palangka raya indonesia', key:'A_PKY', cityBase: 127, city: 'PKY', country: 'ID', type: 'airport', nlabel: 'tjilik riwut palangka raya indonesia'},
  { icon: 'faPlaneDeparture', label: 'TMM Toamasina, Madagascar, Toamasina, TMM, toamasina toamasina madagascar', key:'A_TMM', cityBase: 127, city: 'TMM', country: 'MG', type: 'airport', nlabel: 'toamasina toamasina madagascar'},
  { icon: 'faPlaneDeparture', label: 'TOB Tobruk, Libya, Tobruk, TOB, tobruk tobruk libya', key:'A_TOB', cityBase: 127, city: 'TOB', country: 'LY', type: 'airport', nlabel: 'tobruk tobruk libya'},
  { icon: 'faPlaneDeparture', label: 'PTY Panama City, Panama, Tocumen International, PTY, tocumen international panama city panama', key:'A_PTY', cityBase: 13, city: 'PTY', country: 'PA', type: 'airport', nlabel: 'tocumen international panama city panama'},
  { icon: 'faPlaneDeparture', label: 'YAZ Tofino, Canada, Tofino/Long Beach, YAZ, tofino long beach tofino british columbia canada', key:'A_YAZ', cityBase: 60, city: 'YAZ', country: 'CA', type: 'airport', nlabel: 'tofino long beach tofino british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'TOG Togiak, United States, Togiak, TOG, togiak togiak alaska united states', key:'A_TOG', cityBase: 127, city: 'TOG', country: 'US', type: 'airport', nlabel: 'togiak togiak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TKJ Tok, United States, Tok, TKJ, tok tok alaska united states', key:'A_TKJ', cityBase: 127, city: 'TKJ', country: 'US', type: 'airport', nlabel: 'tok tok alaska united states'},
  { icon: 'faPlaneDeparture', label: 'OBO Obihiro, Japan, Tokachi–Obihiro, OBO, tokachi obihiro obihiro japan', key:'A_OBO', cityBase: 108, city: 'OBO', country: 'JP', type: 'airport', nlabel: 'tokachi obihiro obihiro japan'},
  { icon: 'faPlaneDeparture', label: 'TJK Tokat, Turkey, Tokat, TJK, tokat tokat turkey', key:'A_TJK', cityBase: 127, city: 'TJK', country: 'TR', type: 'airport', nlabel: 'tokat tokat turkey'},
  { icon: 'faPlaneDeparture', label: 'OOK Toksook Bay, United States, Toksook Bay, OOK, toksook bay toksook bay alaska united states', key:'A_OOK', cityBase: 127, city: 'OOK', country: 'US', type: 'airport', nlabel: 'toksook bay toksook bay alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TKN Tokunoshima, Japan, Tokunoshima, TKN, tokunoshima tokunoshima japan', key:'A_TKN', cityBase: 127, city: 'TKN', country: 'JP', type: 'airport', nlabel: 'tokunoshima tokunoshima japan'},
  { icon: 'faPlaneDeparture', label: 'TKS Tokushima, Japan, Tokushima, TKS, tokushima tokushima japan', key:'A_TKS', cityBase: 117, city: 'TKS', country: 'JP', type: 'airport', nlabel: 'tokushima tokushima japan'},
  { icon: 'faPlaneDeparture', label: 'FTU Tôlanaro, Madagascar, Tôlanaro, FTU, tolanaro tolanaro madagascar', key:'A_FTU', cityBase: 127, city: 'FTU', country: 'MG', type: 'airport', nlabel: 'tolanaro tolanaro madagascar'},
  { icon: 'faPlaneDeparture', label: 'TOL Toledo, United States, Toledo Express, TOL, toledo express toledo ohio united states', key:'A_TOL', cityBase: 87, city: 'TOL', country: 'US', type: 'airport', nlabel: 'toledo express toledo ohio united states'},
  { icon: 'faPlaneDeparture', label: 'TLE Toliara, Madagascar, Toliara, TLE, toliara toliara madagascar', key:'A_TLE', cityBase: 127, city: 'TLE', country: 'MG', type: 'airport', nlabel: 'toliara toliara madagascar'},
  { icon: 'faPlaneDeparture', label: 'OVB Novosibirsk, Russia, Tolmachevo, OVB, tolmachevo novosibirsk russia', key:'A_OVB', cityBase: 25, city: 'OVB', country: 'RU', type: 'airport', nlabel: 'tolmachevo novosibirsk russia'},
  { icon: 'faPlaneDeparture', label: 'TLC Toluca, Mexico, Toluca International, TLC, toluca international toluca mexico', key:'A_TLC', cityBase: 57, city: 'TLC', country: 'MX', type: 'airport', nlabel: 'toluca international toluca mexico'},
  { icon: 'faPlaneDeparture', label: 'TGU Tegucigalpa, Honduras, Toncontín International, TGU, toncontin international tegucigalpa honduras', key:'A_TGU', cityBase: 34, city: 'TGU', country: 'HN', type: 'airport', nlabel: 'toncontin international tegucigalpa honduras'},
  { icon: 'faPlaneDeparture', label: 'TNH Tonghua, China, Tonghua Sanyuanpu, TNH, tonghua sanyuanpu tonghua china', key:'A_TNH', cityBase: 72, city: 'TNH', country: 'CN', type: 'airport', nlabel: 'tonghua sanyuanpu tonghua china'},
  { icon: 'faPlaneDeparture', label: 'TGO Tongliao, China, Tongliao, TGO, tongliao tongliao china', key:'A_TGO', cityBase: 75, city: 'TGO', country: 'CN', type: 'airport', nlabel: 'tongliao tongliao china'},
  { icon: 'faPlaneDeparture', label: 'TGH Tongoa, Vanuatu, Tongoa, TGH, tongoa tongoa vanuatu', key:'A_TGH', cityBase: 127, city: 'TGH', country: 'VU', type: 'airport', nlabel: 'tongoa tongoa vanuatu'},
  { icon: 'faPlaneDeparture', label: 'TEN Tongren, China, Tongren Fenghuang, TEN, tongren fenghuang tongren china', key:'A_TEN', cityBase: 66, city: 'TEN', country: 'CN', type: 'airport', nlabel: 'tongren fenghuang tongren china'},
  { icon: 'faPlaneDeparture', label: 'YYZ Toronto, Canada, Toronto Pearson International, YYZ, toronto pearson international toronto ontario canada', key:'A_YYZ', cityBase: 5, city: 'YTO', country: 'CA', type: 'airport', nlabel: 'toronto pearson international toronto ontario canada'},
  { icon: 'faPlaneDeparture', label: 'TRC Torreón, Mexico, Torreón International, TRC, torreon international torreon mexico', key:'A_TRC', cityBase: 49, city: 'TRC', country: 'MX', type: 'airport', nlabel: 'torreon international torreon mexico'},
  { icon: 'faPlaneDeparture', label: 'TOH Torres Islands, Vanuatu, Torres, TOH, torres torres islands vanuatu', key:'A_TOH', cityBase: 127, city: 'TOH', country: 'VU', type: 'airport', nlabel: 'torres torres islands vanuatu'},
  { icon: 'faPlaneDeparture', label: 'TTQ Tortuguero, Costa Rica, Tortuguero, TTQ, tortuguero tortuguero costa rica', key:'A_TTQ', cityBase: 127, city: 'TTQ', country: 'CR', type: 'airport', nlabel: 'tortuguero tortuguero costa rica'},
  { icon: 'faPlaneDeparture', label: 'GMR Totegegie, French Polynesia, Totegegie, GMR, totegegie totegegie french polynesia', key:'A_GMR', cityBase: 127, city: 'GMR', country: 'PF', type: 'airport', nlabel: 'totegegie totegegie french polynesia'},
  { icon: 'faPlaneDeparture', label: 'TTJ Tottori, Japan, Tottori, TTJ, tottori tottori japan', key:'A_TTJ', cityBase: 127, city: 'TTJ', country: 'JP', type: 'airport', nlabel: 'tottori tottori japan'},
  { icon: 'faPlaneDeparture', label: 'AZR Adrar, Algeria, Touat-Cheikh Sidi Mohamed Belkebir, AZR, touat cheikh sidi mohamed belkebir adrar algeria', key:'A_AZR', cityBase: 127, city: 'AZR', country: 'DZ', type: 'airport', nlabel: 'touat cheikh sidi mohamed belkebir adrar algeria'},
  { icon: 'faPlaneDeparture', label: 'TOU Touho, New Caledonia, Touho, TOU, touho touho new caledonia', key:'A_TOU', cityBase: 127, city: 'TOU', country: 'NC', type: 'airport', nlabel: 'touho touho new caledonia'},
  { icon: 'faPlaneDeparture', label: 'TLN Toulon, France, Toulon–Hyères, TLN, toulon hyeres toulon france', key:'A_TLN', cityBase: 78, city: 'TLN', country: 'FR', type: 'airport', nlabel: 'toulon hyeres toulon france'},
  { icon: 'faPlaneDeparture', label: 'TLS Toulouse, France, Toulouse–Blagnac, TLS, toulouse blagnac toulouse france', key:'A_TLS', cityBase: 11, city: 'TLS', country: 'FR', type: 'airport', nlabel: 'toulouse blagnac toulouse france'},
  { icon: 'faPlaneDeparture', label: 'TUF Tours, France, Tours Val de Loire, TUF, tours val de loire tours france', key:'A_TUF', cityBase: 89, city: 'TUF', country: 'FR', type: 'airport', nlabel: 'tours val de loire tours france'},
  { icon: 'faPlaneDeparture', label: 'PAP Port-au-Prince, Haiti, Toussaint Louverture International, PAP, toussaint louverture international port au prince haiti', key:'A_PAP', cityBase: 27, city: 'PAP', country: 'HT', type: 'airport', nlabel: 'toussaint louverture international port au prince haiti'},
  { icon: 'faPlaneDeparture', label: 'TSV Townsville, Australia, Townsville, TSV, townsville townsville queensland australia', key:'A_TSV', cityBase: 90, city: 'TSV', country: 'AU', type: 'airport', nlabel: 'townsville townsville queensland australia'},
  { icon: 'faPlaneDeparture', label: 'TOY Toyama, Japan, Toyama, TOY, toyama toyama japan', key:'A_TOY', cityBase: 102, city: 'TOY', country: 'JP', type: 'airport', nlabel: 'toyama toyama japan'},
  { icon: 'faPlaneDeparture', label: 'TOE Tozeur, Tunisia, Tozeur–Nefta International, TOE, tozeur nefta international tozeur tunisia', key:'A_TOE', cityBase: 127, city: 'TOE', country: 'TN', type: 'airport', nlabel: 'tozeur nefta international tozeur tunisia'},
  { icon: 'faPlaneDeparture', label: 'TZX Trabzon, Turkey, Trabzon, TZX, trabzon trabzon turkey', key:'A_TZX', cityBase: 14, city: 'TZX', country: 'TR', type: 'airport', nlabel: 'trabzon trabzon turkey'},
  { icon: 'faPlaneDeparture', label: 'YZZ Trail, Canada, Trail, YZZ, trail trail canada', key:'A_YZZ', cityBase: 127, city: 'YZZ', country: 'CA', type: 'airport', nlabel: 'trail trail canada'},
  { icon: 'faPlaneDeparture', label: 'TST Trang, Thailand, Trang, TST, trang trang thailand', key:'A_TST', cityBase: 127, city: 'TST', country: 'TH', type: 'airport', nlabel: 'trang trang thailand'},
  { icon: 'faPlaneDeparture', label: 'TDX Trat Province, Thailand, Trat, TDX, trat trat province thailand', key:'A_TDX', cityBase: 97, city: 'TDX', country: 'TH', type: 'airport', nlabel: 'trat trat province thailand'},
  { icon: 'faPlaneDeparture', label: 'TCB Treasure Cay, Bahamas, Treasure Cay, TCB, treasure cay treasure cay bahamas', key:'A_TCB', cityBase: 127, city: 'TCB', country: 'BS', type: 'airport', nlabel: 'treasure cay treasure cay bahamas'},
  { icon: 'faPlaneDeparture', label: 'TTN Trenton, United States, Trenton–Mercer, TTN, trenton mercer trenton new jersey united states', key:'A_TTN', cityBase: 27, city: 'TTN', country: 'US', type: 'airport', nlabel: 'trenton mercer trenton new jersey united states'},
  { icon: 'faPlaneDeparture', label: 'PUU Puerto Asís, Colombia, Tres de Mayo, PUU, tres de mayo puerto asis colombia', key:'A_PUU', cityBase: 127, city: 'PUU', country: 'CO', type: 'airport', nlabel: 'tres de mayo puerto asis colombia'},
  { icon: 'faPlaneDeparture', label: 'TJL Três Lagoas, Brazil, Três Lagoas, TJL, tres lagoas tres lagoas state of mato grosso do sul brazil', key:'A_TJL', cityBase: 127, city: 'TJL', country: 'BR', type: 'airport', nlabel: 'tres lagoas tres lagoas state of mato grosso do sul brazil'},
  { icon: 'faPlaneDeparture', label: 'TSF Venice, Italy, Treviso, TSF, treviso venice italy', key:'A_TSF', cityBase: 14, city: 'VCE', country: 'IT', type: 'airport', nlabel: 'treviso venice italy'},
  { icon: 'faPlaneDeparture', label: 'PSC Pasco, United States, Tri-Cities, PSC, tri cities pasco washington united states', key:'A_PSC', cityBase: 42, city: 'PSC', country: 'US', type: 'airport', nlabel: 'tri cities pasco washington united states'},
  { icon: 'faPlaneDeparture', label: 'TRI Bristol, United States, Tri-Cities Regional, TRI, tri cities regional bristol tennessee united states', key:'A_TRI', cityBase: 58, city: 'TRI', country: 'US', type: 'airport', nlabel: 'tri cities regional bristol tennessee united states'},
  { icon: 'faPlaneDeparture', label: 'HTS Huntington, United States, Tri-State, HTS, tri state huntington west virginia united states', key:'A_HTS', cityBase: 82, city: 'HTS', country: 'US', type: 'airport', nlabel: 'tri state huntington west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'KTM Kathmandu, Nepal, Tribhuvan International, KTM, tribhuvan international kathmandu nepal', key:'A_KTM', cityBase: 30, city: 'KTM', country: 'NP', type: 'airport', nlabel: 'tribhuvan international kathmandu nepal'},
  { icon: 'faPlaneDeparture', label: 'TRS Trieste, Italy, Trieste – Friuli Venezia Giulia, TRS, trieste friuli venezia giulia trieste italy', key:'A_TRS', cityBase: 16, city: 'TRS', country: 'IT', type: 'airport', nlabel: 'trieste friuli venezia giulia trieste italy'},
  { icon: 'faPlaneDeparture', label: 'TIP Tripoli, Libya, Tripoli International, TIP, tripoli international tripoli libya', key:'A_TIP', cityBase: 101, city: 'TIP', country: 'LY', type: 'airport', nlabel: 'tripoli international tripoli libya'},
  { icon: 'faPlaneDeparture', label: 'TRV Thiruvananthapuram, India, Trivandrum International, TRV, trivandrum international thiruvananthapuram india', key:'A_TRV', cityBase: 40, city: 'TRV', country: 'IN', type: 'airport', nlabel: 'trivandrum international thiruvananthapuram india'},
  { icon: 'faPlaneDeparture', label: 'THN Trollhättan, Sweden, Trollhättan–Vänersborg, THN, trollhattan vanersborg trollhattan sweden', key:'A_THN', cityBase: 127, city: 'THN', country: 'SE', type: 'airport', nlabel: 'trollhattan vanersborg trollhattan sweden'},
  { icon: 'faPlaneDeparture', label: 'TOS Tromsø, Norway, Tromsø Airport, Langnes, TOS, tromso airport tromso norway', key:'A_TOS', cityBase: 51, city: 'TOS', country: 'NO', type: 'airport', nlabel: 'tromso airport tromso norway'},
  { icon: 'faPlaneDeparture', label: 'TRD Trondheim, Norway, Trondheim Airport, Værnes, TRD, trondheim airport trondheim norway', key:'A_TRD', cityBase: 49, city: 'TRD', country: 'NO', type: 'airport', nlabel: 'trondheim airport trondheim norway'},
  { icon: 'faPlaneDeparture', label: 'TSJ Tsushima, Japan, Tsushima, TSJ, tsushima tsushima japan', key:'A_TSJ', cityBase: 127, city: 'TSJ', country: 'JP', type: 'airport', nlabel: 'tsushima tsushima japan'},
  { icon: 'faPlaneDeparture', label: 'TUB Tubuai, French Polynesia, Tubuai – Mataura, TUB, tubuai mataura tubuai french polynesia', key:'A_TUB', cityBase: 127, city: 'TUB', country: 'PF', type: 'airport', nlabel: 'tubuai mataura tubuai french polynesia'},
  { icon: 'faPlaneDeparture', label: 'TUS Tucson, United States, Tucson International, TUS, tucson international tucson arizona united states', key:'A_TUS', cityBase: 13, city: 'TUS', country: 'US', type: 'airport', nlabel: 'tucson international tucson arizona united states'},
  { icon: 'faPlaneDeparture', label: 'TFI Tufi, Papua New Guinea, Tufi, TFI, tufi tufi papua new guinea', key:'A_TFI', cityBase: 127, city: 'TFI', country: 'PG', type: 'airport', nlabel: 'tufi tufi papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'TBH Tablas Island, Philippines, Tugdan, TBH, tugdan tablas island philippines', key:'A_TBH', cityBase: 127, city: 'TBH', country: 'PH', type: 'airport', nlabel: 'tugdan tablas island philippines'},
  { icon: 'faPlaneDeparture', label: 'TUG Tuguegarao, Philippines, Tuguegarao, TUG, tuguegarao tuguegarao philippines', key:'A_TUG', cityBase: 111, city: 'TUG', country: 'PH', type: 'airport', nlabel: 'tuguegarao tuguegarao philippines'},
  { icon: 'faPlaneDeparture', label: 'YUB Tuktoyaktuk, Canada, Tuktoyaktuk/James Gruben, YUB, tuktoyaktuk james gruben tuktoyaktuk northwest territories canada', key:'A_YUB', cityBase: 127, city: 'YUB', country: 'CA', type: 'airport', nlabel: 'tuktoyaktuk james gruben tuktoyaktuk northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'ZFN Tulita, Canada, Tulita, ZFN, tulita tulita northwest territories canada', key:'A_ZFN', cityBase: 127, city: 'ZFN', country: 'CA', type: 'airport', nlabel: 'tulita tulita northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'TUL Tulsa, United States, Tulsa International, TUL, tulsa international tulsa oklahoma united states', key:'A_TUL', cityBase: 19, city: 'TUL', country: 'US', type: 'airport', nlabel: 'tulsa international tulsa oklahoma united states'},
  { icon: 'faPlaneDeparture', label: 'TLT Tuluksak, United States, Tuluksak, TLT, tuluksak tuluksak alaska united states', key:'A_TLT', cityBase: 127, city: 'TLT', country: 'US', type: 'airport', nlabel: 'tuluksak tuluksak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TMI Tumlingtar, Nepal, Tumlingtar, TMI, tumlingtar tumlingtar nepal', key:'A_TMI', cityBase: 127, city: 'TMI', country: 'NP', type: 'airport', nlabel: 'tumlingtar tumlingtar nepal'},
  { icon: 'faPlaneDeparture', label: 'TUN Tunis, Tunisia, Tunis–Carthage International, TUN, tunis carthage international tunis tunisia', key:'A_TUN', cityBase: 31, city: 'TUN', country: 'TN', type: 'airport', nlabel: 'tunis carthage international tunis tunisia'},
  { icon: 'faPlaneDeparture', label: 'IAR Yaroslavl, Russia, Tunoshna, IAR, tunoshna yaroslavl russia', key:'A_IAR', cityBase: 60, city: 'IAR', country: 'RU', type: 'airport', nlabel: 'tunoshna yaroslavl russia'},
  { icon: 'faPlaneDeparture', label: 'WTL Tuntutuliak, United States, Tuntutuliak, WTL, tuntutuliak tuntutuliak alaska united states', key:'A_WTL', cityBase: 127, city: 'WTL', country: 'US', type: 'airport', nlabel: 'tuntutuliak tuntutuliak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TNK Tununak, United States, Tununak, TNK, tununak tununak alaska united states', key:'A_TNK', cityBase: 127, city: 'TNK', country: 'US', type: 'airport', nlabel: 'tununak tununak alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TUP Tupelo, United States, Tupelo Regional, TUP, tupelo regional tupelo mississippi united states', key:'A_TUP', cityBase: 127, city: 'TUP', country: 'US', type: 'airport', nlabel: 'tupelo regional tupelo mississippi united states'},
  { icon: 'faPlaneDeparture', label: 'TUI Turaif, Saudi Arabia, Turaif Domestic, TUI, turaif domestic turaif saudi arabia', key:'A_TUI', cityBase: 127, city: 'TUI', country: 'SA', type: 'airport', nlabel: 'turaif domestic turaif saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'TUK Turbat, Pakistan, Turbat International, TUK, turbat international turbat pakistan', key:'A_TUK', cityBase: 127, city: 'TUK', country: 'PK', type: 'airport', nlabel: 'turbat international turbat pakistan'},
  { icon: 'faPlaneDeparture', label: 'BJW Bajawa, Indonesia, Turelelo Soa, BJW, turelelo soa bajawa indonesia', key:'A_BJW', cityBase: 127, city: 'BJW', country: 'ID', type: 'airport', nlabel: 'turelelo soa bajawa indonesia'},
  { icon: 'faPlaneDeparture', label: 'TRN Turin, Italy, Turin, TRN, turin turin italy', key:'A_TRN', cityBase: 12, city: 'TRN', country: 'IT', type: 'airport', nlabel: 'turin turin italy'},
  { icon: 'faPlaneDeparture', label: 'CRZ Türkmenabat, Turkmenistan, Turkmenabat, CRZ, turkmenabat turkmenabat turkmenistan', key:'A_CRZ', cityBase: 127, city: 'CRZ', country: 'TM', type: 'airport', nlabel: 'turkmenabat turkmenabat turkmenistan'},
  { icon: 'faPlaneDeparture', label: 'KRW Türkmenbaşy, Turkmenistan, Turkmenbashi, KRW, turkmenbashi turkmenbasy turkmenistan', key:'A_KRW', cityBase: 127, city: 'KRW', country: 'TM', type: 'airport', nlabel: 'turkmenbashi turkmenbasy turkmenistan'},
  { icon: 'faPlaneDeparture', label: 'TKU Turku, Finland, Turku, TKU, turku turku finland', key:'A_TKU', cityBase: 93, city: 'TKU', country: 'FI', type: 'airport', nlabel: 'turku turku finland'},
  { icon: 'faPlaneDeparture', label: 'TLQ Turpan, China, Turpan Jiaohe, TLQ, turpan jiaohe turpan china', key:'A_TLQ', cityBase: 71, city: 'TLQ', country: 'CN', type: 'airport', nlabel: 'turpan jiaohe turpan china'},
  { icon: 'faPlaneDeparture', label: 'THX Turukhansk, Russia, Turukhansk, THX, turukhansk turukhansk russia', key:'A_THX', cityBase: 127, city: 'THX', country: 'RU', type: 'airport', nlabel: 'turukhansk turukhansk russia'},
  { icon: 'faPlaneDeparture', label: 'TCR Thoothukudi, India, Tuticorin, TCR, tuticorin thoothukudi india', key:'A_TCR', cityBase: 127, city: 'TCR', country: 'IN', type: 'airport', nlabel: 'tuticorin thoothukudi india'},
  { icon: 'faPlaneDeparture', label: 'TGZ Tuxtla Gutiérrez, Mexico, Tuxtla Gutiérrez International, TGZ, tuxtla gutierrez international tuxtla gutierrez mexico', key:'A_TGZ', cityBase: 46, city: 'TGZ', country: 'MX', type: 'airport', nlabel: 'tuxtla gutierrez international tuxtla gutierrez mexico'},
  { icon: 'faPlaneDeparture', label: 'TZL Tuzla, Bosnia & Herzegovina, Tuzla International, TZL, tuzla international tuzla bosnia and herzegovina', key:'A_TZL', cityBase: 85, city: 'TZL', country: 'BA', type: 'airport', nlabel: 'tuzla international tuzla bosnia and herzegovina'},
  { icon: 'faPlaneDeparture', label: 'HVN New Haven, United States, Tweed New Haven, HVN, tweed new haven new haven connecticut united states', key:'A_HVN', cityBase: 81, city: 'HVN', country: 'US', type: 'airport', nlabel: 'tweed new haven new haven connecticut united states'},
  { icon: 'faPlaneDeparture', label: 'TYR Tyler, United States, Tyler Pounds Regional, TYR, tyler pounds regional tyler texas united states', key:'A_TYR', cityBase: 86, city: 'TYR', country: 'US', type: 'airport', nlabel: 'tyler pounds regional tyler texas united states'},
  { icon: 'faPlaneDeparture', label: 'TYD Tynda, Russia, Tynda, TYD, tynda tynda russia', key:'A_TYD', cityBase: 127, city: 'TYD', country: 'RU', type: 'airport', nlabel: 'tynda tynda russia'},
  { icon: 'faPlaneDeparture', label: 'UTP Pattaya, Thailand, U-Tapao International, UTP, u tapao international pattaya thailand', key:'A_UTP', cityBase: 72, city: 'UTP', country: 'TH', type: 'airport', nlabel: 'u tapao international pattaya thailand'},
  { icon: 'faPlaneDeparture', label: 'UAH Ua Huka, French Polynesia, Ua Huka, UAH, ua huka ua huka french polynesia', key:'A_UAH', cityBase: 127, city: 'UAH', country: 'PF', type: 'airport', nlabel: 'ua huka ua huka french polynesia'},
  { icon: 'faPlaneDeparture', label: 'UAP Ua Pou, French Polynesia, Ua Pou, UAP, ua pou ua pou french polynesia', key:'A_UAP', cityBase: 127, city: 'UAP', country: 'PF', type: 'airport', nlabel: 'ua pou ua pou french polynesia'},
  { icon: 'faPlaneDeparture', label: 'UBA Uberaba, Brazil, Uberaba, UBA, uberaba uberaba state of minas gerais brazil', key:'A_UBA', cityBase: 127, city: 'UBA', country: 'BR', type: 'airport', nlabel: 'uberaba uberaba state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'UDI Uberlândia, Brazil, Uberlandia, UDI, uberlandia uberlandia state of minas gerais brazil', key:'A_UDI', cityBase: 94, city: 'UDI', country: 'BR', type: 'airport', nlabel: 'uberlandia uberlandia state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'UBP Ubon Ratchathani Province, Thailand, Ubon Ratchathani, UBP, ubon ratchathani ubon ratchathani province thailand', key:'A_UBP', cityBase: 106, city: 'UBP', country: 'TH', type: 'airport', nlabel: 'ubon ratchathani ubon ratchathani province thailand'},
  { icon: 'faPlaneDeparture', label: 'UTH Udon Thani, Thailand, Udon Thani International, UTH, udon thani international udon thani thailand', key:'A_UTH', cityBase: 81, city: 'UTH', country: 'TH', type: 'airport', nlabel: 'udon thani international udon thani thailand'},
  { icon: 'faPlaneDeparture', label: 'UFA Ufa, Russia, Ufa International, UFA, ufa international ufa russia', key:'A_UFA', cityBase: 44, city: 'UFA', country: 'RU', type: 'airport', nlabel: 'ufa international ufa russia'},
  { icon: 'faPlaneDeparture', label: 'DYR Anadyr, Russia, Ugolny, DYR, ugolny anadyr russia', key:'A_DYR', cityBase: 61, city: 'DYR', country: 'RU', type: 'airport', nlabel: 'ugolny anadyr russia'},
  { icon: 'faPlaneDeparture', label: 'UCT Ukhta, Russia, Ukhta, UCT, ukhta ukhta russia', key:'A_UCT', cityBase: 54, city: 'UCT', country: 'RU', type: 'airport', nlabel: 'ukhta ukhta russia'},
  { icon: 'faPlaneDeparture', label: 'UKA Ukunda, Kenya, Ukunda, UKA, ukunda ukunda kenya', key:'A_UKA', cityBase: 127, city: 'UKA', country: 'KE', type: 'airport', nlabel: 'ukunda ukunda kenya'},
  { icon: 'faPlaneDeparture', label: 'ULO Ulaangom, Mongolia, Ulaangom, ULO, ulaangom ulaangom mongolia', key:'A_ULO', cityBase: 127, city: 'ULO', country: 'MN', type: 'airport', nlabel: 'ulaangom ulaangom mongolia'},
  { icon: 'faPlaneDeparture', label: 'HLH Ulan Hot, China, Ulanhot, HLH, ulanhot ulan hot china', key:'A_HLH', cityBase: 77, city: 'HLH', country: 'CN', type: 'airport', nlabel: 'ulanhot ulan hot china'},
  { icon: 'faPlaneDeparture', label: 'UCB Ulanqab, China, Ulanqab Jining, UCB, ulanqab jining ulanqab china', key:'A_UCB', cityBase: 127, city: 'UCB', country: 'CN', type: 'airport', nlabel: 'ulanqab jining ulanqab china'},
  { icon: 'faPlaneDeparture', label: 'RNA Arona, Solomon Islands, Ulawa, RNA, ulawa arona solomon islands', key:'A_RNA', cityBase: 127, city: 'RNA', country: 'SB', type: 'airport', nlabel: 'ulawa arona solomon islands'},
  { icon: 'faPlaneDeparture', label: 'ULB Toak, Vanuatu, Ulei, ULB, ulei toak vanuatu', key:'A_ULB', cityBase: 127, city: 'ULB', country: 'VU', type: 'airport', nlabel: 'ulei toak vanuatu'},
  { icon: 'faPlaneDeparture', label: 'USN Ulsan, South Korea, Ulsan, USN, ulsan ulsan south korea', key:'A_USN', cityBase: 127, city: 'USN', country: 'KR', type: 'airport', nlabel: 'ulsan ulsan south korea'},
  { icon: 'faPlaneDeparture', label: 'YHI Ulukhaktok, Canada, Ulukhaktok/Holman, YHI, ulukhaktok holman ulukhaktok northwest territories canada', key:'A_YHI', cityBase: 127, city: 'YHI', country: 'CA', type: 'airport', nlabel: 'ulukhaktok holman ulukhaktok northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'ULX Sabi Sand Game Reserve, South Africa, Ulusaba, ULX, ulusaba sabi sand game reserve south africa', key:'A_ULX', cityBase: 127, city: 'ULX', country: 'ZA', type: 'airport', nlabel: 'ulusaba sabi sand game reserve south africa'},
  { icon: 'faPlaneDeparture', label: 'ULV Ulyanovsk, Russia, Ulyanovsk Baratayevka, ULV, ulyanovsk baratayevka ulyanovsk russia', key:'A_ULV', cityBase: 127, city: 'ULY', country: 'RU', type: 'airport', nlabel: 'ulyanovsk baratayevka ulyanovsk russia'},
  { icon: 'faPlaneDeparture', label: 'ULY Ulyanovsk, Russia, Ulyanovsk Vostochny, ULY, ulyanovsk vostochny ulyanovsk russia', key:'A_ULY', cityBase: 47, city: 'ULY', country: 'RU', type: 'airport', nlabel: 'ulyanovsk vostochny ulyanovsk russia'},
  { icon: 'faPlaneDeparture', label: 'WGP Waingapu, Indonesia, Umbu Mehang Kunda, WGP, umbu mehang kunda waingapu indonesia', key:'A_WGP', cityBase: 127, city: 'WGP', country: 'ID', type: 'airport', nlabel: 'umbu mehang kunda waingapu indonesia'},
  { icon: 'faPlaneDeparture', label: 'UME Umeå, Sweden, Umeå, UME, umea umea sweden', key:'A_UME', cityBase: 88, city: 'UME', country: 'SE', type: 'airport', nlabel: 'umea umea sweden'},
  { icon: 'faPlaneDeparture', label: 'YUD Umiujaq, Canada, Umiujaq, YUD, umiujaq umiujaq quebec canada', key:'A_YUD', cityBase: 127, city: 'YUD', country: 'CA', type: 'airport', nlabel: 'umiujaq umiujaq quebec canada'},
  { icon: 'faPlaneDeparture', label: 'UNK Unalakleet, United States, Unalakleet, UNK, unalakleet unalakleet alaska united states', key:'A_UNK', cityBase: 127, city: 'UNK', country: 'US', type: 'airport', nlabel: 'unalakleet unalakleet alaska united states'},
  { icon: 'faPlaneDeparture', label: 'DUT Dutch Harbor, United States, Unalaska, DUT, unalaska dutch harbor alaska united states', key:'A_DUT', cityBase: 127, city: 'DUT', country: 'US', type: 'airport', nlabel: 'unalaska dutch harbor alaska united states'},
  { icon: 'faPlaneDeparture', label: 'CMI Champaign, United States, University of Illinois Willard, CMI, university of illinois willard champaign illinois united states', key:'A_CMI', cityBase: 75, city: 'CMI', country: 'US', type: 'airport', nlabel: 'university of illinois willard champaign illinois united states'},
  { icon: 'faPlaneDeparture', label: 'SCE State College, United States, University Park, SCE, university park state college pennsylvania united states', key:'A_SCE', cityBase: 64, city: 'SCE', country: 'US', type: 'airport', nlabel: 'university park state college pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'JUV Upernavik, Greenland, Upernavik, JUV, upernavik upernavik greenland', key:'A_JUV', cityBase: 127, city: 'JUV', country: 'GL', type: 'airport', nlabel: 'upernavik upernavik greenland'},
  { icon: 'faPlaneDeparture', label: 'UTN Upington, South Africa, Upington, UTN, upington upington south africa', key:'A_UTN', cityBase: 127, city: 'UTN', country: 'ZA', type: 'airport', nlabel: 'upington upington south africa'},
  { icon: 'faPlaneDeparture', label: 'URJ Uray, Russia, Uray, URJ, uray uray russia', key:'A_URJ', cityBase: 57, city: 'URJ', country: 'RU', type: 'airport', nlabel: 'uray uray russia'},
  { icon: 'faPlaneDeparture', label: 'UZR Urzhar, Kazakhstan, Urdzhar, UZR, urdzhar urzhar kazakhstan', key:'A_UZR', cityBase: 127, city: 'UZR', country: 'KZ', type: 'airport', nlabel: 'urdzhar urzhar kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'UGC Urgench, Uzbekistan, Urgench International, UGC, urgench international urgench uzbekistan', key:'A_UGC', cityBase: 56, city: 'UGC', country: 'UZ', type: 'airport', nlabel: 'urgench international urgench uzbekistan'},
  { icon: 'faPlaneDeparture', label: 'OMH Urmia, Iran, Urmia, OMH, urmia urmia iran', key:'A_OMH', cityBase: 121, city: 'OMH', country: 'IR', type: 'airport', nlabel: 'urmia urmia iran'},
  { icon: 'faPlaneDeparture', label: 'ATD Atoifi, Solomon Islands, Uru Harbour, ATD, uru harbour atoifi solomon islands', key:'A_ATD', cityBase: 127, city: 'ATD', country: 'SB', type: 'airport', nlabel: 'uru harbour atoifi solomon islands'},
  { icon: 'faPlaneDeparture', label: 'UPN Uruapan, Mexico, Uruapan International, UPN, uruapan international uruapan mexico', key:'A_UPN', cityBase: 80, city: 'UPN', country: 'MX', type: 'airport', nlabel: 'uruapan international uruapan mexico'},
  { icon: 'faPlaneDeparture', label: 'URC Ürümqi, China, Ürümqi Diwopu International, URC, urumqi diwopu international urumqi china', key:'A_URC', cityBase: 52, city: 'URC', country: 'CN', type: 'airport', nlabel: 'urumqi diwopu international urumqi china'},
  { icon: 'faPlaneDeparture', label: 'USQ Usak, Turkey, Uşak, USQ, usak usak turkey', key:'A_USQ', cityBase: 29, city: 'USQ', country: 'TR', type: 'airport', nlabel: 'usak usak turkey'},
  { icon: 'faPlaneDeparture', label: 'USH Ushuaia, Argentina, Ushuaia – Malvinas Argentinas International, USH, ushuaia malvinas argentinas international ushuaia tierra del fuego argentina', key:'A_USH', cityBase: 52, city: 'USH', country: 'AR', type: 'airport', nlabel: 'ushuaia malvinas argentinas international ushuaia tierra del fuego argentina'},
  { icon: 'faPlaneDeparture', label: 'IPN Ipatinga, Brazil, Usiminas, IPN, usiminas ipatinga state of minas gerais brazil', key:'A_IPN', cityBase: 102, city: 'IPN', country: 'BR', type: 'airport', nlabel: 'usiminas ipatinga state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'USK Usinsk, Russia, Usinsk, USK, usinsk usinsk russia', key:'A_USK', cityBase: 55, city: 'USK', country: 'RU', type: 'airport', nlabel: 'usinsk usinsk russia'},
  { icon: 'faPlaneDeparture', label: 'UKX Ust-Kut, Russia, Ust-Kut, UKX, ust kut ust kut russia', key:'A_UKX', cityBase: 127, city: 'UKX', country: 'RU', type: 'airport', nlabel: 'ust kut ust kut russia'},
  { icon: 'faPlaneDeparture', label: 'UKG Ust-Kuyga, Russia, Ust-Kuyga, UKG, ust kuyga ust kuyga russia', key:'A_UKG', cityBase: 127, city: 'UKG', country: 'RU', type: 'airport', nlabel: 'ust kuyga ust kuyga russia'},
  { icon: 'faPlaneDeparture', label: 'UMS Ust-Maya, Russia, Ust-Maya, UMS, ust maya ust maya russia', key:'A_UMS', cityBase: 127, city: 'UMS', country: 'RU', type: 'airport', nlabel: 'ust maya ust maya russia'},
  { icon: 'faPlaneDeparture', label: 'USR Ust-Nera, Russia, Ust-Nera, USR, ust nera ust nera russia', key:'A_USR', cityBase: 127, city: 'USR', country: 'RU', type: 'airport', nlabel: 'ust nera ust nera russia'},
  { icon: 'faPlaneDeparture', label: 'UTS Ust-Tsilma, Russia, Ust-Tsilma, UTS, ust tsilma ust tsilma russia', key:'A_UTS', cityBase: 127, city: 'UTS', country: 'RU', type: 'airport', nlabel: 'ust tsilma ust tsilma russia'},
  { icon: 'faPlaneDeparture', label: 'OGM Ustupo, Panama, Ustupu-Ogobsucum, OGM, ustupu ogobsucum ustupo panama', key:'A_OGM', cityBase: 127, city: 'OGM', country: 'PA', type: 'airport', nlabel: 'ustupu ogobsucum ustupo panama'},
  { icon: 'faPlaneDeparture', label: 'UII Útila, Honduras, Útila, UII, utila utila honduras', key:'A_UII', cityBase: 115, city: 'UII', country: 'HN', type: 'airport', nlabel: 'utila utila honduras'},
  { icon: 'faPlaneDeparture', label: 'MCX Makhachkala, Russia, Uytash, MCX, uytash makhachkala russia', key:'A_MCX', cityBase: 45, city: 'MCX', country: 'RU', type: 'airport', nlabel: 'uytash makhachkala russia'},
  { icon: 'faPlaneDeparture', label: 'UYU Uyuni, Bolivia, Uyuni, UYU, uyuni uyuni bolivia', key:'A_UYU', cityBase: 71, city: 'UYU', country: 'BO', type: 'airport', nlabel: 'uyuni uyuni bolivia'},
  { icon: 'faPlaneDeparture', label: 'ANU Antigua, Antigua & Barbuda, V. C. Bird International, ANU, v c bird international antigua antigua and barbuda', key:'A_ANU', cityBase: 36, city: 'ANU', country: 'AG', type: 'airport', nlabel: 'v c bird international antigua antigua and barbuda'},
  { icon: 'faPlaneDeparture', label: 'VAA Vaasa, Finland, Vaasa, VAA, vaasa vaasa finland', key:'A_VAA', cityBase: 101, city: 'VAA', country: 'FI', type: 'airport', nlabel: 'vaasa vaasa finland'},
  { icon: 'faPlaneDeparture', label: 'PRG Prague, Czechia, Václav Havel Airport Prague, PRG, vaclav havel airport prague prague czechia', key:'A_PRG', cityBase: 0, city: 'PRG', country: 'CZ', type: 'airport', nlabel: 'vaclav havel airport prague prague czechia'},
  { icon: 'faPlaneDeparture', label: 'BDQ Vadodara, India, Vadodara, BDQ, vadodara vadodara india', key:'A_BDQ', cityBase: 58, city: 'BDQ', country: 'IN', type: 'airport', nlabel: 'vadodara vadodara india'},
  { icon: 'faPlaneDeparture', label: 'VDS Vadsø, Norway, Vadsø, VDS, vadso vadso norway', key:'A_VDS', cityBase: 127, city: 'VDS', country: 'NO', type: 'airport', nlabel: 'vadso vadso norway'},
  { icon: 'faPlaneDeparture', label: 'FAE Sørvágur, Faroe Islands, Vágar, FAE, vagar s\xc3\xb8rv\xc3\xa1gur faroe islands', key:'A_FAE', cityBase: 53, city: 'FAE', country: 'FO', type: 'airport', nlabel: 'vagar s\xc3\xb8rv\xc3\xa1gur faroe islands'},
  { icon: 'faPlaneDeparture', label: 'BEL Belém, Brazil, Val de Cans International, BEL, val de cans international belem state of para brazil', key:'A_BEL', cityBase: 70, city: 'BEL', country: 'BR', type: 'airport', nlabel: 'val de cans international belem state of para brazil'},
  { icon: 'faPlaneDeparture', label: 'YVO Val-d Or, Canada, Val-d Or, YVO, val d or val d or quebec canada', key:'A_YVO', cityBase: 127, city: 'YVO', country: 'CA', type: 'airport', nlabel: 'val d or val d or quebec canada'},
  { icon: 'faPlaneDeparture', label: 'VDZ Valdez, United States, Valdez, VDZ, valdez valdez alaska united states', key:'A_VDZ', cityBase: 127, city: 'VDZ', country: 'US', type: 'airport', nlabel: 'valdez valdez alaska united states'},
  { icon: 'faPlaneDeparture', label: 'VLD Valdosta, United States, Valdosta Regional, VLD, valdosta regional valdosta georgia united states', key:'A_VLD', cityBase: 86, city: 'VLD', country: 'US', type: 'airport', nlabel: 'valdosta regional valdosta georgia united states'},
  { icon: 'faPlaneDeparture', label: 'VAL Valença, Bahia, Brazil, Valença, VAL, valenca valenca state of bahia brazil', key:'A_VAL', cityBase: 127, city: 'VAL', country: 'BR', type: 'airport', nlabel: 'valenca valenca state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'VLC Valencia, Spain, Valencia, VLC, valencia valencia spain', key:'A_VLC', cityBase: 10, city: 'VLC', country: 'ES', type: 'airport', nlabel: 'valencia valencia spain'},
  { icon: 'faPlaneDeparture', label: 'VLS Valesdir, Vanuatu, Valesdir, VLS, valesdir valesdir vanuatu', key:'A_VLS', cityBase: 127, city: 'VLS', country: 'VU', type: 'airport', nlabel: 'valesdir valesdir vanuatu'},
  { icon: 'faPlaneDeparture', label: 'VLL Valladolid, Spain, Valladolid, VLL, valladolid valladolid spain', key:'A_VLL', cityBase: 19, city: 'VLL', country: 'ES', type: 'airport', nlabel: 'valladolid valladolid spain'},
  { icon: 'faPlaneDeparture', label: 'HRL Harlingen, United States, Valley International, HRL, valley international harlingen texas united states', key:'A_HRL', cityBase: 37, city: 'HRL', country: 'US', type: 'airport', nlabel: 'valley international harlingen texas united states'},
  { icon: 'faPlaneDeparture', label: 'VAN Van, Turkey, Van Ferit Melen, VAN, van ferit melen van turkey', key:'A_VAN', cityBase: 18, city: 'VAN', country: 'TR', type: 'airport', nlabel: 'van ferit melen van turkey'},
  { icon: 'faPlaneDeparture', label: 'NEV Nevis, St. Kitts & Nevis, Vance W. Amory International, NEV, vance w amory international nevis st kitts and nevis', key:'A_NEV', cityBase: 106, city: 'NEV', country: 'KN', type: 'airport', nlabel: 'vance w amory international nevis st kitts and nevis'},
  { icon: 'faPlaneDeparture', label: 'CXH Vancouver, Canada, Vancouver Harbour Flight Centre, CXH, vancouver harbour flight centre vancouver british columbia canada', key:'A_CXH', cityBase: 103, city: 'YVR', country: 'CA', type: 'airport', nlabel: 'vancouver harbour flight centre vancouver british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'YVR Vancouver, Canada, Vancouver International, YVR, vancouver international vancouver british columbia canada', key:'A_YVR', cityBase: 8, city: 'YVR', country: 'CA', type: 'airport', nlabel: 'vancouver international vancouver british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'VAI Vanimo, Papua New Guinea, Vanimo, VAI, vanimo vanimo papua new guinea', key:'A_VAI', cityBase: 127, city: 'VAI', country: 'PG', type: 'airport', nlabel: 'vanimo vanimo papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'SLH Sola, Vanuatu, Vanua Lava, SLH, vanua lava sola vanuatu', key:'A_SLH', cityBase: 127, city: 'SLH', country: 'VU', type: 'airport', nlabel: 'vanua lava sola vanuatu'},
  { icon: 'faPlaneDeparture', label: 'VBV Vanua Balavu, Fiji, Vanuabalavu, VBV, vanuabalavu vanua balavu fiji', key:'A_VBV', cityBase: 127, city: 'VBV', country: 'FJ', type: 'airport', nlabel: 'vanuabalavu vanua balavu fiji'},
  { icon: 'faPlaneDeparture', label: 'VAW Vardø, Norway, Vardø Airport, Svartnes, VAW, vardo airport vardo norway', key:'A_VAW', cityBase: 127, city: 'VAW', country: 'NO', type: 'airport', nlabel: 'vardo airport vardo norway'},
  { icon: 'faPlaneDeparture', label: 'VAR Varna, Bulgaria, Varna, VAR, varna varna bulgaria', key:'A_VAR', cityBase: 11, city: 'VAR', country: 'BG', type: 'airport', nlabel: 'varna varna bulgaria'},
  { icon: 'faPlaneDeparture', label: 'VAV Vavaʻu, Tonga, Vavaʻu International, VAV, vava`u international vava`u tonga', key:'A_VAV', cityBase: 119, city: 'VAV', country: 'TO', type: 'airport', nlabel: 'vava`u international vava`u tonga'},
  { icon: 'faPlaneDeparture', label: 'VXO Växjö, Sweden, Växjö Småland, VXO, vaxjo smaland vaxjo sweden', key:'A_VXO', cityBase: 98, city: 'VXO', country: 'SE', type: 'airport', nlabel: 'vaxjo smaland vaxjo sweden'},
  { icon: 'faPlaneDeparture', label: 'IXZ Port Blair, India, Veer Savarkar International, IXZ, veer savarkar international port blair india', key:'A_IXZ', cityBase: 88, city: 'IXZ', country: 'IN', type: 'airport', nlabel: 'veer savarkar international port blair india'},
  { icon: 'faPlaneDeparture', label: 'MLE Malé, Maldives, Velana International, MLE, velana international male maldives', key:'A_MLE', cityBase: 25, city: 'MLE', country: 'MV', type: 'airport', nlabel: 'velana international male maldives'},
  { icon: 'faPlaneDeparture', label: 'VUS Veliky Ustyug, Russia, Veliky Ustyug, VUS, veliky ustyug veliky ustyug russia', key:'A_VUS', cityBase: 127, city: 'VUS', country: 'RU', type: 'airport', nlabel: 'veliky ustyug veliky ustyug russia'},
  { icon: 'faPlaneDeparture', label: 'VEE Venetie, United States, Venetie, VEE, venetie venetie alaska united states', key:'A_VEE', cityBase: 127, city: 'VEE', country: 'US', type: 'airport', nlabel: 'venetie venetie alaska united states'},
  { icon: 'faPlaneDeparture', label: 'VCE Venice, Italy, Venice Marco Polo, VCE, venice marco polo venice italy', key:'A_VCE', cityBase: 4, city: 'VCE', country: 'IT', type: 'airport', nlabel: 'venice marco polo venice italy'},
  { icon: 'faPlaneDeparture', label: 'VER Veracruz, Mexico, Veracruz International, VER, veracruz international veracruz mexico', key:'A_VER', cityBase: 45, city: 'VER', country: 'MX', type: 'airport', nlabel: 'veracruz international veracruz mexico'},
  { icon: 'faPlaneDeparture', label: 'VHV Verkhnevilyuysk, Russia, Verkhnevilyuysk, VHV, verkhnevilyuysk verkhnevilyuysk russia', key:'A_VHV', cityBase: 127, city: 'VHV', country: 'RU', type: 'airport', nlabel: 'verkhnevilyuysk verkhnevilyuysk russia'},
  { icon: 'faPlaneDeparture', label: 'VEL Vernal, United States, Vernal Regional, VEL, vernal regional vernal utah united states', key:'A_VEL', cityBase: 127, city: 'VEL', country: 'US', type: 'airport', nlabel: 'vernal regional vernal utah united states'},
  { icon: 'faPlaneDeparture', label: 'VRB Vero Beach, United States, Vero Beach Regional, VRB, vero beach regional vero beach florida united states', key:'A_VRB', cityBase: 127, city: 'VRB', country: 'US', type: 'airport', nlabel: 'vero beach regional vero beach florida united states'},
  { icon: 'faPlaneDeparture', label: 'VRN Verona, Italy, Verona Villafranca, VRN, verona villafranca verona italy', key:'A_VRN', cityBase: 10, city: 'VRN', country: 'IT', type: 'airport', nlabel: 'verona villafranca verona italy'},
  { icon: 'faPlaneDeparture', label: 'SDE Santiago del Estero, Argentina, Vicecomodoro Ángel de la Paz Aragonés, SDE, vicecomodoro angel de la paz aragones santiago del estero santiago del estero argentina', key:'A_SDE', cityBase: 127, city: 'SDE', country: 'AR', type: 'airport', nlabel: 'vicecomodoro angel de la paz aragones santiago del estero santiago del estero argentina'},
  { icon: 'faPlaneDeparture', label: 'VFA Victoria Falls, Zimbabwe, Victoria Falls International, VFA, victoria falls international victoria falls zimbabwe', key:'A_VFA', cityBase: 48, city: 'VFA', country: 'ZW', type: 'airport', nlabel: 'victoria falls international victoria falls zimbabwe'},
  { icon: 'faPlaneDeparture', label: 'YWH Victoria, Canada, Victoria Inner Harbour SPB, YWH, victoria inner harbour spb victoria british columbia canada', key:'A_YWH', cityBase: 127, city: 'YYJ', country: 'CA', type: 'airport', nlabel: 'victoria inner harbour spb victoria british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'YYJ Victoria, Canada, Victoria International, YYJ, victoria international victoria british columbia canada', key:'A_YYJ', cityBase: 38, city: 'YYJ', country: 'CA', type: 'airport', nlabel: 'victoria international victoria british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'VCT Victoria, United States, Victoria Regional, VCT, victoria regional victoria texas united states', key:'A_VCT', cityBase: 127, city: 'VCT', country: 'US', type: 'airport', nlabel: 'victoria regional victoria texas united states'},
  { icon: 'faPlaneDeparture', label: 'VIE Vienna, Austria, Vienna International, VIE, vienna international vienna austria', key:'A_VIE', cityBase: 0, city: 'VIE', country: 'AT', type: 'airport', nlabel: 'vienna international vienna austria'},
  { icon: 'faPlaneDeparture', label: 'VGO Vigo, Spain, Vigo–Peinador, VGO, vigo peinador vigo spain', key:'A_VGO', cityBase: 19, city: 'VGO', country: 'ES', type: 'airport', nlabel: 'vigo peinador vigo spain'},
  { icon: 'faPlaneDeparture', label: 'VGA Vijayawada, India, Vijayawada, VGA, vijayawada vijayawada india', key:'A_VGA', cityBase: 54, city: 'VGA', country: 'IN', type: 'airport', nlabel: 'vijayawada vijayawada india'},
  { icon: 'faPlaneDeparture', label: 'VNX Vilankulo, Mozambique, Vilankulo, VNX, vilankulo vilankulo mozambique', key:'A_VNX', cityBase: 99, city: 'VNX', country: 'MZ', type: 'airport', nlabel: 'vilankulo vilankulo mozambique'},
  { icon: 'faPlaneDeparture', label: 'VHM Vilhelmina, Sweden, Vilhelmina, VHM, vilhelmina vilhelmina sweden', key:'A_VHM', cityBase: 127, city: 'VHM', country: 'SE', type: 'airport', nlabel: 'vilhelmina vilhelmina sweden'},
  { icon: 'faPlaneDeparture', label: 'BVH Vilhena, Brazil, Vilhena, BVH, vilhena vilhena state of rondonia brazil', key:'A_BVH', cityBase: 127, city: 'BVH', country: 'BR', type: 'airport', nlabel: 'vilhena vilhena state of rondonia brazil'},
  { icon: 'faPlaneDeparture', label: 'VGZ Villagarzón, Colombia, Villa Garzón, VGZ, villa garzon villagarzon colombia', key:'A_VGZ', cityBase: 127, city: 'VGZ', country: 'CO', type: 'airport', nlabel: 'villa garzon villagarzon colombia'},
  { icon: 'faPlaneDeparture', label: 'VAM Maamigili, Maldives, Villa International, VAM, villa international maamigili maldives', key:'A_VAM', cityBase: 127, city: 'VAM', country: 'MV', type: 'airport', nlabel: 'villa international maamigili maldives'},
  { icon: 'faPlaneDeparture', label: 'VSA Villahermosa, Mexico, Villahermosa International, VSA, villahermosa international villahermosa mexico', key:'A_VSA', cityBase: 48, city: 'VSA', country: 'MX', type: 'airport', nlabel: 'villahermosa international villahermosa mexico'},
  { icon: 'faPlaneDeparture', label: 'VNO Vilnius, Lithuania, Vilnius, VNO, vilnius vilnius lithuania', key:'A_VNO', cityBase: 32, city: 'VNO', country: 'LT', type: 'airport', nlabel: 'vilnius vilnius lithuania'},
  { icon: 'faPlaneDeparture', label: 'CYO Cayo Largo del Sur, Cuba, Vilo Acuña, CYO, vilo acuna cayo largo del sur cuba', key:'A_CYO', cityBase: 127, city: 'CYO', country: 'CU', type: 'airport', nlabel: 'vilo acuna cayo largo del sur cuba'},
  { icon: 'faPlaneDeparture', label: 'VYI Vilyuysk, Russia, Vilyuysk, VYI, vilyuysk vilyuysk russia', key:'A_VYI', cityBase: 127, city: 'VYI', country: 'RU', type: 'airport', nlabel: 'vilyuysk vilyuysk russia'},
  { icon: 'faPlaneDeparture', label: 'TPS Trapani, Italy, Vincenzo Florio Airport Trapani–Birgi, TPS, vincenzo florio airport trapani birgi trapani italy', key:'A_TPS', cityBase: 21, city: 'TPS', country: 'IT', type: 'airport', nlabel: 'vincenzo florio airport trapani birgi trapani italy'},
  { icon: 'faPlaneDeparture', label: 'VII Vinh, Vietnam, Vinh International, VII, vinh international vinh vietnam', key:'A_VII', cityBase: 115, city: 'VII', country: 'VN', type: 'airport', nlabel: 'vinh international vinh vietnam'},
  { icon: 'faPlaneDeparture', label: 'VRC Virac, Catanduanes, Philippines, Virac, VRC, virac virac philippines', key:'A_VRC', cityBase: 127, city: 'VRC', country: 'PH', type: 'airport', nlabel: 'virac virac philippines'},
  { icon: 'faPlaneDeparture', label: 'VCP São Paulo, Brazil, Viracopos International, VCP, viracopos international sao paulo state of sao paulo brazil', key:'A_VCP', cityBase: 84, city: 'SAO', country: 'BR', type: 'airport', nlabel: 'viracopos international sao paulo state of sao paulo brazil'},
  { icon: 'faPlaneDeparture', label: 'VIJ Virgin Gorda, British Virgin Islands, Virgin Gorda, VIJ, virgin gorda virgin gorda british virgin islands', key:'A_VIJ', cityBase: 127, city: 'VIJ', country: 'VG', type: 'airport', nlabel: 'virgin gorda virgin gorda british virgin islands'},
  { icon: 'faPlaneDeparture', label: 'VIR Durban, South Africa, Virginia Airport, VIR, virginia airport durban south africa', key:'A_VIR', cityBase: 127, city: 'DUR', country: 'ZA', type: 'airport', nlabel: 'virginia airport durban south africa'},
  { icon: 'faPlaneDeparture', label: 'VVI Santa Cruz de la Sierra, Bolivia, Viru Viru International, VVI, viru viru international santa cruz de la sierra bolivia', key:'A_VVI', cityBase: 36, city: 'SRZ', country: 'BO', type: 'airport', nlabel: 'viru viru international santa cruz de la sierra bolivia'},
  { icon: 'faPlaneDeparture', label: 'VTZ Visakhapatnam, India, Visakhapatnam, VTZ, visakhapatnam visakhapatnam india', key:'A_VTZ', cityBase: 58, city: 'VTZ', country: 'IN', type: 'airport', nlabel: 'visakhapatnam visakhapatnam india'},
  { icon: 'faPlaneDeparture', label: 'VBY Visby, Sweden, Visby, VBY, visby visby sweden', key:'A_VBY', cityBase: 110, city: 'VBY', country: 'SE', type: 'airport', nlabel: 'visby visby sweden'},
  { icon: 'faPlaneDeparture', label: 'VTB Vitebsk, Belarus, Vitebsk Vostochny, VTB, vitebsk vostochny vitebsk belarus', key:'A_VTB', cityBase: 127, city: 'VTB', country: 'BY', type: 'airport', nlabel: 'vitebsk vostochny vitebsk belarus'},
  { icon: 'faPlaneDeparture', label: 'VIT Vitoria-Gasteiz, Spain, Vitoria, VIT, vitoria vitoria gasteiz spain', key:'A_VIT', cityBase: 23, city: 'VIT', country: 'ES', type: 'airport', nlabel: 'vitoria vitoria gasteiz spain'},
  { icon: 'faPlaneDeparture', label: 'VDC Vitória da Conquista, Brazil, Vitória da Conquista, VDC, vitoria da conquista vitoria da conquista state of bahia brazil', key:'A_VDC', cityBase: 127, city: 'VDC', country: 'BR', type: 'airport', nlabel: 'vitoria da conquista vitoria da conquista state of bahia brazil'},
  { icon: 'faPlaneDeparture', label: 'VVO Vladivostok, Russia, Vladivostok International, VVO, vladivostok international vladivostok russia', key:'A_VVO', cityBase: 40, city: 'VVO', country: 'RU', type: 'airport', nlabel: 'vladivostok international vladivostok russia'},
  { icon: 'faPlaneDeparture', label: 'VKO Moscow, Russia, Vnukovo International, VKO, vnukovo international moscow russia', key:'A_VKO', cityBase: 18, city: 'MOW', country: 'RU', type: 'airport', nlabel: 'vnukovo international moscow russia'},
  { icon: 'faPlaneDeparture', label: 'VOG Volgograd, Russia, Volgograd International, VOG, volgograd international volgograd russia', key:'A_VOG', cityBase: 44, city: 'VOG', country: 'RU', type: 'airport', nlabel: 'volgograd international volgograd russia'},
  { icon: 'faPlaneDeparture', label: 'VKT Vorkuta, Russia, Vorkuta, VKT, vorkuta vorkuta russia', key:'A_VKT', cityBase: 61, city: 'VKT', country: 'RU', type: 'airport', nlabel: 'vorkuta vorkuta russia'},
  { icon: 'faPlaneDeparture', label: 'VOZ Voronezh, Russia, Voronezh International, VOZ, voronezh international voronezh russia', key:'A_VOZ', cityBase: 46, city: 'VOZ', country: 'RU', type: 'airport', nlabel: 'voronezh international voronezh russia'},
  { icon: 'faPlaneDeparture', label: 'KDV Kadavu Island, Fiji, Vunisea, KDV, vunisea kadavu island fiji', key:'A_KDV', cityBase: 127, city: 'KDV', country: 'FJ', type: 'airport', nlabel: 'vunisea kadavu island fiji'},
  { icon: 'faPlaneDeparture', label: 'YWK Wabush, Canada, Wabush, YWK, wabush wabush newfoundland and labrador canada', key:'A_YWK', cityBase: 127, city: 'YWK', country: 'CA', type: 'airport', nlabel: 'wabush wabush newfoundland and labrador canada'},
  { icon: 'faPlaneDeparture', label: 'ACT Waco, United States, Waco Regional, ACT, waco regional waco texas united states', key:'A_ACT', cityBase: 74, city: 'ACT', country: 'US', type: 'airport', nlabel: 'waco regional waco texas united states'},
  { icon: 'faPlaneDeparture', label: 'WAE Wadi ad-Dawasir, Saudi Arabia, Wadi al-Dawasir Domestic, WAE, wadi al dawasir domestic wadi ad dawasir saudi arabia', key:'A_WAE', cityBase: 120, city: 'WAE', country: 'SA', type: 'airport', nlabel: 'wadi al dawasir domestic wadi ad dawasir saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'WGA Wagga Wagga, Australia, Wagga Wagga, WGA, wagga wagga wagga wagga new south wales australia', key:'A_WGA', cityBase: 127, city: 'WGA', country: 'AU', type: 'airport', nlabel: 'wagga wagga wagga wagga new south wales australia'},
  { icon: 'faPlaneDeparture', label: 'MUE Waimea, United States, Waimea-Kohala, MUE, waimea kohala waimea hawaii united states', key:'A_MUE', cityBase: 127, city: 'MUE', country: 'US', type: 'airport', nlabel: 'waimea kohala waimea hawaii united states'},
  { icon: 'faPlaneDeparture', label: 'AIN Wainwright, United States, Wainwright, AIN, wainwright wainwright alaska united states', key:'A_AIN', cityBase: 127, city: 'AIN', country: 'US', type: 'airport', nlabel: 'wainwright wainwright alaska united states'},
  { icon: 'faPlaneDeparture', label: 'RJM Waisai, Indonesia, Waisai Marinda, RJM, waisai marinda waisai indonesia', key:'A_RJM', cityBase: 127, city: 'RJM', country: 'ID', type: 'airport', nlabel: 'waisai marinda waisai indonesia'},
  { icon: 'faPlaneDeparture', label: 'WJR Wajir, Kenya, Wajir, WJR, wajir wajir kenya', key:'A_WJR', cityBase: 127, city: 'WJR', country: 'KE', type: 'airport', nlabel: 'wajir wajir kenya'},
  { icon: 'faPlaneDeparture', label: 'WKJ Wakkanai, Japan, Wakkanai, WKJ, wakkanai wakkanai japan', key:'A_WKJ', cityBase: 127, city: 'WKJ', country: 'JP', type: 'airport', nlabel: 'wakkanai wakkanai japan'},
  { icon: 'faPlaneDeparture', label: 'WLH Walaha, Vanuatu, Walaha, WLH, walaha walaha vanuatu', key:'A_WLH', cityBase: 127, city: 'WLH', country: 'VU', type: 'airport', nlabel: 'walaha walaha vanuatu'},
  { icon: 'faPlaneDeparture', label: 'WAA Wales, United States, Wales, WAA, wales wales alaska united states', key:'A_WAA', cityBase: 117, city: 'WAA', country: 'US', type: 'airport', nlabel: 'wales wales alaska united states'},
  { icon: 'faPlaneDeparture', label: 'ALW Walla Walla, United States, Walla Walla Regional, ALW, walla walla regional walla walla washington united states', key:'A_ALW', cityBase: 95, city: 'ALW', country: 'US', type: 'airport', nlabel: 'walla walla regional walla walla washington united states'},
  { icon: 'faPlaneDeparture', label: 'WVB Walvis Bay, Namibia, Walvis Bay, WVB, walvis bay walvis bay namibia', key:'A_WVB', cityBase: 102, city: 'WVB', country: 'NA', type: 'airport', nlabel: 'walvis bay walvis bay namibia'},
  { icon: 'faPlaneDeparture', label: 'WMX Wamena, Indonesia, Wamena, WMX, wamena wamena indonesia', key:'A_WMX', cityBase: 112, city: 'WMX', country: 'ID', type: 'airport', nlabel: 'wamena wamena indonesia'},
  { icon: 'faPlaneDeparture', label: 'WXN Wanzhou District, China, Wanzhou Wuqiao, WXN, wanzhou wuqiao wanzhou district china', key:'A_WXN', cityBase: 127, city: 'WXN', country: 'CN', type: 'airport', nlabel: 'wanzhou wuqiao wanzhou district china'},
  { icon: 'faPlaneDeparture', label: 'WBM Wapenamanda District, Papua New Guinea, Wapenamanda, WBM, wapenamanda wapenamanda district papua new guinea', key:'A_WBM', cityBase: 127, city: 'WBM', country: 'PG', type: 'airport', nlabel: 'wapenamanda wapenamanda district papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'QRW Warri, Nigeria, Warri, QRW, warri warri nigeria', key:'A_QRW', cityBase: 122, city: 'QRW', country: 'NG', type: 'airport', nlabel: 'warri warri nigeria'},
  { icon: 'faPlaneDeparture', label: 'WMB Warrnambool, Australia, Warrnambool, WMB, warrnambool warrnambool victoria australia', key:'A_WMB', cityBase: 127, city: 'WMB', country: 'AU', type: 'airport', nlabel: 'warrnambool warrnambool victoria australia'},
  { icon: 'faPlaneDeparture', label: 'WAW Warsaw, Poland, Warsaw Chopin, WAW, warsaw chopin warsaw poland', key:'A_WAW', cityBase: 21, city: 'WAW', country: 'PL', type: 'airport', nlabel: 'warsaw chopin warsaw poland'},
  { icon: 'faPlaneDeparture', label: 'WMI Warsaw, Poland, Warsaw Modlin, WMI, warsaw modlin warsaw poland', key:'A_WMI', cityBase: 46, city: 'WAW', country: 'PL', type: 'airport', nlabel: 'warsaw modlin warsaw poland'},
  { icon: 'faPlaneDeparture', label: 'IAD Washington, D.C., United States, Washington Dulles International, IAD, washington dulles international washington district of columbia united states', key:'A_IAD', cityBase: 2, city: 'WAS', country: 'US', type: 'airport', nlabel: 'washington dulles international washington district of columbia united states'},
  { icon: 'faPlaneDeparture', label: 'YKQ Waskaganish, Canada, Waskaganish, YKQ, waskaganish waskaganish quebec canada', key:'A_YKQ', cityBase: 127, city: 'YKQ', country: 'CA', type: 'airport', nlabel: 'waskaganish waskaganish quebec canada'},
  { icon: 'faPlaneDeparture', label: 'KWF Waterfall, Alaska, United States, Waterfall Seaplane Base, KWF, waterfall seaplane base waterfall alaska united states', key:'A_KWF', cityBase: 127, city: 'KWF', country: 'US', type: 'airport', nlabel: 'waterfall seaplane base waterfall alaska united states'},
  { icon: 'faPlaneDeparture', label: 'WAT Waterford, Ireland, Waterford Airport, WAT, waterford airport waterford ireland', key:'A_WAT', cityBase: 127, city: 'WAT', country: 'IE', type: 'airport', nlabel: 'waterford airport waterford ireland'},
  { icon: 'faPlaneDeparture', label: 'ALO Waterloo, United States, Waterloo Regional, ALO, waterloo regional waterloo iowa united states', key:'A_ALO', cityBase: 96, city: 'ALO', country: 'US', type: 'airport', nlabel: 'waterloo regional waterloo iowa united states'},
  { icon: 'faPlaneDeparture', label: 'ART Watertown, United States, Watertown International, ART, watertown international watertown new york united states', key:'A_ART', cityBase: 72, city: 'ART', country: 'US', type: 'airport', nlabel: 'watertown international watertown new york united states'},
  { icon: 'faPlaneDeparture', label: 'ATY Watertown, United States, Watertown Regional, ATY, watertown regional watertown south dakota united states', key:'A_ATY', cityBase: 127, city: 'ATY', country: 'US', type: 'airport', nlabel: 'watertown regional watertown south dakota united states'},
  { icon: 'faPlaneDeparture', label: 'VTE Vientiane, Laos, Wattay International, VTE, wattay international vientiane laos', key:'A_VTE', cityBase: 52, city: 'VTE', country: 'LA', type: 'airport', nlabel: 'wattay international vientiane laos'},
  { icon: 'faPlaneDeparture', label: 'TBN Fort Leonard Wood, United States, Waynesville-St. Robert Regional, TBN, waynesville st robert regional fort leonard wood missouri united states', key:'A_TBN', cityBase: 124, city: 'TBN', country: 'US', type: 'airport', nlabel: 'waynesville st robert regional fort leonard wood missouri united states'},
  { icon: 'faPlaneDeparture', label: 'WRZ Weerawila, Sri Lanka, Weerawila, WRZ, weerawila weerawila sri lanka', key:'A_WRZ', cityBase: 127, city: 'WRZ', country: 'LK', type: 'airport', nlabel: 'weerawila weerawila sri lanka'},
  { icon: 'faPlaneDeparture', label: 'NRN Düsseldorf, Germany, Weeze, NRN, weeze dusseldorf germany', key:'A_NRN', cityBase: 8, city: 'DUS', country: 'DE', type: 'airport', nlabel: 'weeze dusseldorf germany'},
  { icon: 'faPlaneDeparture', label: 'WEF Weifang, China, Weifang, WEF, weifang weifang china', key:'A_WEF', cityBase: 85, city: 'WEF', country: 'CN', type: 'airport', nlabel: 'weifang weifang china'},
  { icon: 'faPlaneDeparture', label: 'WEH Weihai, China, Weihai Dashuibo, WEH, weihai dashuibo weihai china', key:'A_WEH', cityBase: 39, city: 'WEH', country: 'CN', type: 'airport', nlabel: 'weihai dashuibo weihai china'},
  { icon: 'faPlaneDeparture', label: 'WEI Weipa, Australia, Weipa, WEI, weipa weipa queensland australia', key:'A_WEI', cityBase: 127, city: 'WEI', country: 'AU', type: 'airport', nlabel: 'weipa weipa queensland australia'},
  { icon: 'faPlaneDeparture', label: 'WLG Wellington, New Zealand, Wellington International, WLG, wellington international wellington new zealand', key:'A_WLG', cityBase: 50, city: 'WLG', country: 'NZ', type: 'airport', nlabel: 'wellington international wellington new zealand'},
  { icon: 'faPlaneDeparture', label: 'YNC Wemindji, Quebec, Canada, Wemindji, YNC, wemindji wemindji quebec canada', key:'A_YNC', cityBase: 127, city: 'YNC', country: 'CA', type: 'airport', nlabel: 'wemindji wemindji quebec canada'},
  { icon: 'faPlaneDeparture', label: 'WNH Wenshan City, China, Wenshan Puzhehei, WNH, wenshan puzhehei wenshan city china', key:'A_WNH', cityBase: 75, city: 'WNH', country: 'CN', type: 'airport', nlabel: 'wenshan puzhehei wenshan city china'},
  { icon: 'faPlaneDeparture', label: 'WNZ Wenzhou, China, Wenzhou Longwan International, WNZ, wenzhou longwan international wenzhou china', key:'A_WNZ', cityBase: 57, city: 'WNZ', country: 'CN', type: 'airport', nlabel: 'wenzhou longwan international wenzhou china'},
  { icon: 'faPlaneDeparture', label: 'YCG Castlegar, Canada, West Kootenay Regional, YCG, west kootenay regional castlegar british columbia canada', key:'A_YCG', cityBase: 112, city: 'YCG', country: 'CA', type: 'airport', nlabel: 'west kootenay regional castlegar british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'HPN Westchester County, New York, United States, Westchester County, HPN, westchester county westchester county new york united states', key:'A_HPN', cityBase: 24, city: 'HPN', country: 'US', type: 'airport', nlabel: 'westchester county westchester county new york united states'},
  { icon: 'faPlaneDeparture', label: 'BFF Scottsbluff, United States, Western Nebraska Regional, BFF, western nebraska regional scottsbluff nebraska united states', key:'A_BFF', cityBase: 117, city: 'BFF', country: 'US', type: 'airport', nlabel: 'western nebraska regional scottsbluff nebraska united states'},
  { icon: 'faPlaneDeparture', label: 'WSZ Westport, New Zealand, Westport, WSZ, westport westport new zealand', key:'A_WSZ', cityBase: 127, city: 'WSZ', country: 'NZ', type: 'airport', nlabel: 'westport westport new zealand'},
  { icon: 'faPlaneDeparture', label: 'WRY Westray, United Kingdom, Westray, WRY, westray westray united kingdom', key:'A_WRY', cityBase: -873, city: 'WRY', country: 'GB', type: 'airport', nlabel: 'westray westray united kingdom'},
  { icon: 'faPlaneDeparture', label: 'WSX West Sound, Washington, United States, Westsound/WSX Seaplane Base, WSX, westsound wsx seaplane base west sound washington united states', key:'A_WSX', cityBase: 127, city: 'WSX', country: 'US', type: 'airport', nlabel: 'westsound wsx seaplane base west sound washington united states'},
  { icon: 'faPlaneDeparture', label: 'WWK Wewak, Papua New Guinea, Wewak, WWK, wewak wewak papua new guinea', key:'A_WWK', cityBase: 108, city: 'WWK', country: 'PG', type: 'airport', nlabel: 'wewak wewak papua new guinea'},
  { icon: 'faPlaneDeparture', label: 'YXN Whale Cove, Nunavut, Canada, Whale Cove, YXN, whale cove whale cove nunavut canada', key:'A_YXN', cityBase: 127, city: 'YXN', country: 'CA', type: 'airport', nlabel: 'whale cove whale cove nunavut canada'},
  { icon: 'faPlaneDeparture', label: 'WAG Whanganui, New Zealand, Whanganui Airport, WAG, whanganui airport whanganui new zealand', key:'A_WAG', cityBase: 127, city: 'WAG', country: 'NZ', type: 'airport', nlabel: 'whanganui airport whanganui new zealand'},
  { icon: 'faPlaneDeparture', label: 'WRE Whangarei, New Zealand, Whangarei, WRE, whangarei whangarei new zealand', key:'A_WRE', cityBase: 127, city: 'WRE', country: 'NZ', type: 'airport', nlabel: 'whangarei whangarei new zealand'},
  { icon: 'faPlaneDeparture', label: 'WMO White Mountain, United States, White Mountain, WMO, white mountain white mountain alaska united states', key:'A_WMO', cityBase: 127, city: 'WMO', country: 'US', type: 'airport', nlabel: 'white mountain white mountain alaska united states'},
  { icon: 'faPlaneDeparture', label: 'TAH Tanna, Vanuatu, Whitegrass, TAH, whitegrass tanna vanuatu', key:'A_TAH', cityBase: 127, city: 'TAH', country: 'VU', type: 'airport', nlabel: 'whitegrass tanna vanuatu'},
  { icon: 'faPlaneDeparture', label: 'PPP Proserpine, Australia, Whitsunday Coast, PPP, whitsunday coast proserpine queensland australia', key:'A_PPP', cityBase: 66, city: 'PPP', country: 'AU', type: 'airport', nlabel: 'whitsunday coast proserpine queensland australia'},
  { icon: 'faPlaneDeparture', label: 'WYA Whyalla, Australia, Whyalla, WYA, whyalla whyalla south australia australia', key:'A_WYA', cityBase: 127, city: 'WYA', country: 'AU', type: 'airport', nlabel: 'whyalla whyalla south australia australia'},
  { icon: 'faPlaneDeparture', label: 'ICT Wichita, United States, Wichita Dwight D. Eisenhower National, ICT, wichita dwight d eisenhower national wichita kansas united states', key:'A_ICT', cityBase: 21, city: 'ICT', country: 'US', type: 'airport', nlabel: 'wichita dwight d eisenhower national wichita kansas united states'},
  { icon: 'faPlaneDeparture', label: 'SPS Wichita Falls, United States, Wichita Falls Municipal, SPS, wichita falls municipal wichita falls texas united states', key:'A_SPS', cityBase: 126, city: 'SPS', country: 'US', type: 'airport', nlabel: 'wichita falls municipal wichita falls texas united states'},
  { icon: 'faPlaneDeparture', label: 'WIC Wick, United Kingdom, Wick, WIC, wick wick united kingdom', key:'A_WIC', cityBase: -878, city: 'WIC', country: 'GB', type: 'airport', nlabel: 'wick wick united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BRW Barrow, United States, Wiley Post–Will Rogers Memorial, BRW, wiley post will rogers memorial barrow alaska united states', key:'A_BRW', cityBase: 110, city: 'BRW', country: 'US', type: 'airport', nlabel: 'wiley post will rogers memorial barrow alaska united states'},
  { icon: 'faPlaneDeparture', label: 'AVP Wilkes-Barre, United States, Wilkes-Barre/Scranton International, AVP, wilkes barre scranton international wilkes barre pennsylvania united states', key:'A_AVP', cityBase: 46, city: 'AVP', country: 'US', type: 'airport', nlabel: 'wilkes barre scranton international wilkes barre pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'OKC Oklahoma City, United States, Will Rogers World, OKC, will rogers world oklahoma city oklahoma united states', key:'A_OKC', cityBase: 9, city: 'OKC', country: 'US', type: 'airport', nlabel: 'will rogers world oklahoma city oklahoma united states'},
  { icon: 'faPlaneDeparture', label: 'HOU Houston, United States, William P. Hobby, HOU, william p hobby houston texas united states', key:'A_HOU', cityBase: 3, city: 'HOU', country: 'US', type: 'airport', nlabel: 'william p hobby houston texas united states'},
  { icon: 'faPlaneDeparture', label: 'YWL Williams Lake, Canada, Williams Lake, YWL, williams lake williams lake british columbia canada', key:'A_YWL', cityBase: 127, city: 'YWL', country: 'CA', type: 'airport', nlabel: 'williams lake williams lake british columbia canada'},
  { icon: 'faPlaneDeparture', label: 'MWA Marion, United States, Williamson County Regional, MWA, williamson county regional marion illinois united states', key:'A_MWA', cityBase: 90, city: 'MWA', country: 'US', type: 'airport', nlabel: 'williamson county regional marion illinois united states'},
  { icon: 'faPlaneDeparture', label: 'IPT Williamsport, United States, Williamsport Regional, IPT, williamsport regional williamsport pennsylvania united states', key:'A_IPT', cityBase: 85, city: 'IPT', country: 'US', type: 'airport', nlabel: 'williamsport regional williamsport pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'YIP Detroit, United States, Willow Run Airport, YIP, willow run airport detroit michigan united states', key:'A_YIP', cityBase: 127, city: 'DTT', country: 'US', type: 'airport', nlabel: 'willow run airport detroit michigan united states'},
  { icon: 'faPlaneDeparture', label: 'ILM Wilmington, United States, Wilmington International, ILM, wilmington international wilmington delaware united states', key:'A_ILM', cityBase: 29, city: 'ILM', country: 'US', type: 'airport', nlabel: 'wilmington international wilmington delaware united states'},
  { icon: 'faPlaneDeparture', label: 'WIL Nairobi, Kenya, Wilson, WIL, wilson nairobi kenya', key:'A_WIL', cityBase: 127, city: 'NBO', country: 'KE', type: 'airport', nlabel: 'wilson nairobi kenya'},
  { icon: 'faPlaneDeparture', label: 'WNR Windorah, Australia, Windorah, WNR, windorah windorah queensland australia', key:'A_WNR', cityBase: 127, city: 'WNR', country: 'AU', type: 'airport', nlabel: 'windorah windorah queensland australia'},
  { icon: 'faPlaneDeparture', label: 'YQG Windsor, Canada, Windsor International, YQG, windsor international windsor ontario canada', key:'A_YQG', cityBase: 81, city: 'YQG', country: 'CA', type: 'airport', nlabel: 'windsor international windsor ontario canada'},
  { icon: 'faPlaneDeparture', label: 'BBX Philadelphia, United States, Wings Field, BBX, wings field philadelphia pennsylvania united states', key:'A_BBX', cityBase: 127, city: 'PHL', country: 'US', type: 'airport', nlabel: 'wings field philadelphia pennsylvania united states'},
  { icon: 'faPlaneDeparture', label: 'YWG Winnipeg, Canada, Winnipeg James Armstrong Richardson International, YWG, winnipeg james armstrong richardson international winnipeg manitoba canada', key:'A_YWG', cityBase: 35, city: 'YWG', country: 'CA', type: 'airport', nlabel: 'winnipeg james armstrong richardson international winnipeg manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'WIN Winton, Australia, Winton, WIN, winton winton queensland australia', key:'A_WIN', cityBase: 127, city: 'WIN', country: 'AU', type: 'airport', nlabel: 'winton winton queensland australia'},
  { icon: 'faPlaneDeparture', label: 'PRY Pretoria, South Africa, Wonderboom, PRY, wonderboom pretoria south africa', key:'A_PRY', cityBase: 120, city: 'PRY', country: 'ZA', type: 'airport', nlabel: 'wonderboom pretoria south africa'},
  { icon: 'faPlaneDeparture', label: 'WJU Wonju, South Korea, Wonju, WJU, wonju wonju south korea', key:'A_WJU', cityBase: 113, city: 'WJU', country: 'KR', type: 'airport', nlabel: 'wonju wonju south korea'},
  { icon: 'faPlaneDeparture', label: 'LWE Lewoleba, Indonesia, Wonopito, LWE, wonopito lewoleba indonesia', key:'A_LWE', cityBase: 123, city: 'LWE', country: 'ID', type: 'airport', nlabel: 'wonopito lewoleba indonesia'},
  { icon: 'faPlaneDeparture', label: 'BHE Blenheim, New Zealand, Woodbourne, BHE, woodbourne blenheim new zealand', key:'A_BHE', cityBase: 127, city: 'BHE', country: 'NZ', type: 'airport', nlabel: 'woodbourne blenheim new zealand'},
  { icon: 'faPlaneDeparture', label: 'ORH Worcester, United States, Worcester Regional, ORH, worcester regional worcester massachusetts united states', key:'A_ORH', cityBase: 88, city: 'ORH', country: 'US', type: 'airport', nlabel: 'worcester regional worcester massachusetts united states'},
  { icon: 'faPlaneDeparture', label: 'WRG Wrangell, United States, Wrangell, WRG, wrangell wrangell alaska united states', key:'A_WRG', cityBase: 117, city: 'WRG', country: 'US', type: 'airport', nlabel: 'wrangell wrangell alaska united states'},
  { icon: 'faPlaneDeparture', label: 'WUA Wuhai, China, Wuhai, WUA, wuhai wuhai china', key:'A_WUA', cityBase: 73, city: 'WUA', country: 'CN', type: 'airport', nlabel: 'wuhai wuhai china'},
  { icon: 'faPlaneDeparture', label: 'WUH Wuhan, China, Wuhan Tianhe International, WUH, wuhan tianhe international wuhan china', key:'A_WUH', cityBase: 27, city: 'WUH', country: 'CN', type: 'airport', nlabel: 'wuhan tianhe international wuhan china'},
  { icon: 'faPlaneDeparture', label: 'WUS Wuyishan, Fujian, China, Wuyishan, WUS, wuyishan wuyishan china', key:'A_WUS', cityBase: 75, city: 'WUS', country: 'CN', type: 'airport', nlabel: 'wuyishan wuyishan china'},
  { icon: 'faPlaneDeparture', label: 'XIY Xi An, China, Xi an Xianyang International, XIY, xi an xianyang international xi an china', key:'A_XIY', cityBase: 43, city: 'SIA', country: 'CN', type: 'airport', nlabel: 'xi an xianyang international xi an china'},
  { icon: 'faPlaneDeparture', label: 'XMN Xiamen, China, Xiamen Gaoqi International, XMN, xiamen gaoqi international xiamen china', key:'A_XMN', cityBase: 39, city: 'XMN', country: 'CN', type: 'airport', nlabel: 'xiamen gaoqi international xiamen china'},
  { icon: 'faPlaneDeparture', label: 'XFN Xiangyang, China, Xiangyang Liuji, XFN, xiangyang liuji xiangyang china', key:'A_XFN', cityBase: 77, city: 'XFN', country: 'CN', type: 'airport', nlabel: 'xiangyang liuji xiangyang china'},
  { icon: 'faPlaneDeparture', label: 'XIC Xichang, China, Xichang Qingshan, XIC, xichang qingshan xichang china', key:'A_XIC', cityBase: 73, city: 'XIC', country: 'CN', type: 'airport', nlabel: 'xichang qingshan xichang china'},
  { icon: 'faPlaneDeparture', label: 'XKH Phonsavan, Laos, Xieng Khouang, XKH, xieng khouang phonsavan laos', key:'A_XKH', cityBase: 127, city: 'XKH', country: 'LA', type: 'airport', nlabel: 'xieng khouang phonsavan laos'},
  { icon: 'faPlaneDeparture', label: 'XIL Xilinhot, China, Xilinhot, XIL, xilinhot xilinhot china', key:'A_XIL', cityBase: 73, city: 'XIL', country: 'CN', type: 'airport', nlabel: 'xilinhot xilinhot china'},
  { icon: 'faPlaneDeparture', label: 'ACX Xingyi, Guizhou, China, Xingyi Wanfenglin, ACX, xingyi wanfenglin xingyi china', key:'A_ACX', cityBase: 76, city: 'ACX', country: 'CN', type: 'airport', nlabel: 'xingyi wanfenglin xingyi china'},
  { icon: 'faPlaneDeparture', label: 'XNN Xining, China, Xining Caojiabao International, XNN, xining caojiabao international xining china', key:'A_XNN', cityBase: 64, city: 'XNN', country: 'CN', type: 'airport', nlabel: 'xining caojiabao international xining china'},
  { icon: 'faPlaneDeparture', label: 'NLT Xinyuan County, China, Xinyuan Nalati, NLT, xinyuan nalati xinyuan county china', key:'A_NLT', cityBase: 127, city: 'NLT', country: 'CN', type: 'airport', nlabel: 'xinyuan nalati xinyuan county china'},
  { icon: 'faPlaneDeparture', label: 'WUT Xinzhou, China, Xinzhou Wutaishan, WUT, xinzhou wutaishan xinzhou china', key:'A_WUT', cityBase: 58, city: 'WUT', country: 'CN', type: 'airport', nlabel: 'xinzhou wutaishan xinzhou china'},
  { icon: 'faPlaneDeparture', label: 'JHG Jinghong, China, Xishuangbanna Gasa, JHG, xishuangbanna gasa jinghong china', key:'A_JHG', cityBase: 67, city: 'JHG', country: 'CN', type: 'airport', nlabel: 'xishuangbanna gasa jinghong china'},
  { icon: 'faPlaneDeparture', label: 'XUZ Xuzhou, China, Xuzhou Guanyin International, XUZ, xuzhou guanyin international xuzhou china', key:'A_XUZ', cityBase: 70, city: 'XUZ', country: 'CN', type: 'airport', nlabel: 'xuzhou guanyin international xuzhou china'},
  { icon: 'faPlaneDeparture', label: 'BYC Yacuíba, Bolivia, Yacuiba, BYC, yacuiba yacuiba bolivia', key:'A_BYC', cityBase: 127, city: 'BYC', country: 'BO', type: 'airport', nlabel: 'yacuiba yacuiba bolivia'},
  { icon: 'faPlaneDeparture', label: 'YKM Yakima, United States, Yakima Air Terminal, YKM, yakima air terminal yakima washington united states', key:'A_YKM', cityBase: 82, city: 'YKM', country: 'US', type: 'airport', nlabel: 'yakima air terminal yakima washington united states'},
  { icon: 'faPlaneDeparture', label: 'JOS Jos, Nigeria, Yakubu Gowon, JOS, yakubu gowon jos nigeria', key:'A_JOS', cityBase: 127, city: 'JOS', country: 'NG', type: 'airport', nlabel: 'yakubu gowon jos nigeria'},
  { icon: 'faPlaneDeparture', label: 'YAK Yakutat, United States, Yakutat, YAK, yakutat yakutat alaska united states', key:'A_YAK', cityBase: 127, city: 'YAK', country: 'US', type: 'airport', nlabel: 'yakutat yakutat alaska united states'},
  { icon: 'faPlaneDeparture', label: 'YKS Yakutsk, Russia, Yakutsk, YKS, yakutsk yakutsk russia', key:'A_YKS', cityBase: 46, city: 'YKS', country: 'RU', type: 'airport', nlabel: 'yakutsk yakutsk russia'},
  { icon: 'faPlaneDeparture', label: 'GAJ Yamagata, Japan, Yamagata, GAJ, yamagata yamagata japan', key:'A_GAJ', cityBase: 127, city: 'GAJ', country: 'JP', type: 'airport', nlabel: 'yamagata yamagata japan'},
  { icon: 'faPlaneDeparture', label: 'UBJ Ube, Japan, Yamaguchi Ube, UBJ, yamaguchi ube ube japan', key:'A_UBJ', cityBase: 127, city: 'UBJ', country: 'JP', type: 'airport', nlabel: 'yamaguchi ube ube japan'},
  { icon: 'faPlaneDeparture', label: 'HDN Hayden, United States, Yampa Valley, HDN, yampa valley hayden colorado united states', key:'A_HDN', cityBase: 80, city: 'HDN', country: 'US', type: 'airport', nlabel: 'yampa valley hayden colorado united states'},
  { icon: 'faPlaneDeparture', label: 'ENY Yan an, China, Yan an Ershilipu, ENY, yan an ershilipu yan an china', key:'A_ENY', cityBase: 75, city: 'ENY', country: 'CN', type: 'airport', nlabel: 'yan an ershilipu yan an china'},
  { icon: 'faPlaneDeparture', label: 'YNB Yanbu, Saudi Arabia, Yanbu, YNB, yanbu yanbu saudi arabia', key:'A_YNB', cityBase: 84, city: 'YNB', country: 'SA', type: 'airport', nlabel: 'yanbu yanbu saudi arabia'},
  { icon: 'faPlaneDeparture', label: 'YNZ Yancheng, China, Yancheng Nanyang International, YNZ, yancheng nanyang international yancheng china', key:'A_YNZ', cityBase: 69, city: 'YNZ', country: 'CN', type: 'airport', nlabel: 'yancheng nanyang international yancheng china'},
  { icon: 'faPlaneDeparture', label: 'RGN Yangon, Myanmar (Burma), Yangon International, RGN, yangon international yangon myanmar (burma)', key:'A_RGN', cityBase: 37, city: 'RGN', country: 'MM', type: 'airport', nlabel: 'yangon international yangon myanmar (burma)'},
  { icon: 'faPlaneDeparture', label: 'YNY Yangyang County, South Korea, Yangyang International, YNY, yangyang international yangyang county south korea', key:'A_YNY', cityBase: 127, city: 'YNY', country: 'KR', type: 'airport', nlabel: 'yangyang international yangyang county south korea'},
  { icon: 'faPlaneDeparture', label: 'YTY Yangzhou, China, Yangzhou Taizhou International, YTY, yangzhou taizhou international yangzhou china', key:'A_YTY', cityBase: 73, city: 'YTY', country: 'CN', type: 'airport', nlabel: 'yangzhou taizhou international yangzhou china'},
  { icon: 'faPlaneDeparture', label: 'YNJ Yanji, China, Yanji Chaoyangchuan International, YNJ, yanji chaoyangchuan international yanji china', key:'A_YNJ', cityBase: 36, city: 'YNJ', country: 'CN', type: 'airport', nlabel: 'yanji chaoyangchuan international yanji china'},
  { icon: 'faPlaneDeparture', label: 'YNT Yantai, China, Yantai Laishan, YNT, yantai laishan yantai china', key:'A_YNT', cityBase: 40, city: 'YNT', country: 'CN', type: 'airport', nlabel: 'yantai laishan yantai china'},
  { icon: 'faPlaneDeparture', label: 'NSI Yaoundé, Cameroon, Yaoundé Nsimalen International, NSI, yaounde nsimalen international yaounde cameroon', key:'A_NSI', cityBase: 64, city: 'YAO', country: 'CM', type: 'airport', nlabel: 'yaounde nsimalen international yaounde cameroon'},
  { icon: 'faPlaneDeparture', label: 'YAP Yap, Micronesia, Yap International, YAP, yap international yap micronesia', key:'A_YAP', cityBase: 107, city: 'YAP', country: 'FM', type: 'airport', nlabel: 'yap international yap micronesia'},
  { icon: 'faPlaneDeparture', label: 'EJA Barrancabermeja, Colombia, Yariguíes, EJA, yariguies barrancabermeja colombia', key:'A_EJA', cityBase: 127, city: 'EJA', country: 'CO', type: 'airport', nlabel: 'yariguies barrancabermeja colombia'},
  { icon: 'faPlaneDeparture', label: 'CRW Charleston, United States, Yeager, CRW, yeager charleston west virginia united states', key:'A_CRW', cityBase: 58, city: 'CRW', country: 'US', type: 'airport', nlabel: 'yeager charleston west virginia united states'},
  { icon: 'faPlaneDeparture', label: 'YZF Yellowknife, Canada, Yellowknife, YZF, yellowknife yellowknife northwest territories canada', key:'A_YZF', cityBase: 77, city: 'YZF', country: 'CA', type: 'airport', nlabel: 'yellowknife yellowknife northwest territories canada'},
  { icon: 'faPlaneDeparture', label: 'WYS West Yellowstone, United States, Yellowstone, WYS, yellowstone west yellowstone montana united states', key:'A_WYS', cityBase: 98, city: 'WYS', country: 'US', type: 'airport', nlabel: 'yellowstone west yellowstone montana united states'},
  { icon: 'faPlaneDeparture', label: 'COD Cody, United States, Yellowstone Regional, COD, yellowstone regional cody wyoming united states', key:'A_COD', cityBase: 77, city: 'COD', country: 'US', type: 'airport', nlabel: 'yellowstone regional cody wyoming united states'},
  { icon: 'faPlaneDeparture', label: 'KJA Krasnoyarsk, Russia, Yemelyanovo International, KJA, yemelyanovo international krasnoyarsk russia', key:'A_KJA', cityBase: 44, city: 'KJA', country: 'RU', type: 'airport', nlabel: 'yemelyanovo international krasnoyarsk russia'},
  { icon: 'faPlaneDeparture', label: 'YEI Bursa, Turkey, Yenişehir, YEI, yenisehir bursa turkey', key:'A_YEI', cityBase: 59, city: 'YEI', country: 'TR', type: 'airport', nlabel: 'yenisehir bursa turkey'},
  { icon: 'faPlaneDeparture', label: 'RSU Yeosu, South Korea, Yeosu, RSU, yeosu yeosu south korea', key:'A_RSU', cityBase: 110, city: 'RSU', country: 'KR', type: 'airport', nlabel: 'yeosu yeosu south korea'},
  { icon: 'faPlaneDeparture', label: 'YBP Yibin, China, Yibin Caiba, YBP, yibin caiba yibin china', key:'A_YBP', cityBase: 77, city: 'YBP', country: 'CN', type: 'airport', nlabel: 'yibin caiba yibin china'},
  { icon: 'faPlaneDeparture', label: 'YIH Yichang, China, Yichang Sanxia, YIH, yichang sanxia yichang china', key:'A_YIH', cityBase: 66, city: 'YIH', country: 'CN', type: 'airport', nlabel: 'yichang sanxia yichang china'},
  { icon: 'faPlaneDeparture', label: 'LDS Yichun, Heilongjiang, China, Yichun Lindu, LDS, yichun lindu yichun china', key:'A_LDS', cityBase: 76, city: 'LDS', country: 'CN', type: 'airport', nlabel: 'yichun lindu yichun china'},
  { icon: 'faPlaneDeparture', label: 'YIC Yichun, Jiangxi, China, Yichun Mingyueshan, YIC, yichun mingyueshan yichun china', key:'A_YIC', cityBase: 70, city: 'YIC', country: 'CN', type: 'airport', nlabel: 'yichun mingyueshan yichun china'},
  { icon: 'faPlaneDeparture', label: 'INC Yinchuan, China, Yinchuan Hedong International, INC, yinchuan hedong international yinchuan china', key:'A_INC', cityBase: 63, city: 'INC', country: 'CN', type: 'airport', nlabel: 'yinchuan hedong international yinchuan china'},
  { icon: 'faPlaneDeparture', label: 'YKH Yingkou, China, Yingkou Lanqi, YKH, yingkou lanqi yingkou china', key:'A_YKH', cityBase: 68, city: 'YKH', country: 'CN', type: 'airport', nlabel: 'yingkou lanqi yingkou china'},
  { icon: 'faPlaneDeparture', label: 'YIN Ghulja, China, Yining, YIN, yining ghulja china', key:'A_YIN', cityBase: 76, city: 'YIN', country: 'CN', type: 'airport', nlabel: 'yining ghulja china'},
  { icon: 'faPlaneDeparture', label: 'YIW Yiwu, China, Yiwu, YIW, yiwu yiwu china', key:'A_YIW', cityBase: 70, city: 'YIW', country: 'CN', type: 'airport', nlabel: 'yiwu yiwu china'},
  { icon: 'faPlaneDeparture', label: 'YOL Yola, Nigeria, Yola, YOL, yola yola nigeria', key:'A_YOL', cityBase: 127, city: 'YOL', country: 'NG', type: 'airport', nlabel: 'yola yola nigeria'},
  { icon: 'faPlaneDeparture', label: 'LLF Yongzhou, China, Yongzhou Lingling, LLF, yongzhou lingling yongzhou china', key:'A_LLF', cityBase: 70, city: 'LLF', country: 'CN', type: 'airport', nlabel: 'yongzhou lingling yongzhou china'},
  { icon: 'faPlaneDeparture', label: 'ZAC York Factory First Nation, Canada, York Landing, ZAC, york landing york factory first nation manitoba canada', key:'A_ZAC', cityBase: 127, city: 'ZAC', country: 'CA', type: 'airport', nlabel: 'york landing york factory first nation manitoba canada'},
  { icon: 'faPlaneDeparture', label: 'JOK Yoshkar-Ola, Russia, Yoshkar-Ola, JOK, yoshkar ola yoshkar ola russia', key:'A_JOK', cityBase: 127, city: 'JOK', country: 'RU', type: 'airport', nlabel: 'yoshkar ola yoshkar ola russia'},
  { icon: 'faPlaneDeparture', label: 'YNG Youngstown, United States, Youngstown–Warren Regional, YNG, youngstown warren regional youngstown ohio united states', key:'A_YNG', cityBase: 112, city: 'YNG', country: 'US', type: 'airport', nlabel: 'youngstown warren regional youngstown ohio united states'},
  { icon: 'faPlaneDeparture', label: 'UYN Yulin, China, Yulin Yuyang, UYN, yulin yuyang yulin china', key:'A_UYN', cityBase: 71, city: 'UYN', country: 'CN', type: 'airport', nlabel: 'yulin yuyang yulin china'},
  { icon: 'faPlaneDeparture', label: 'YUM Yuma, United States, Yuma International, YUM, yuma international yuma arizona united states', key:'A_YUM', cityBase: 65, city: 'YUM', country: 'US', type: 'airport', nlabel: 'yuma international yuma arizona united states'},
  { icon: 'faPlaneDeparture', label: 'YCU Yuncheng, China, Yuncheng Guangong, YCU, yuncheng guangong yuncheng china', key:'A_YCU', cityBase: 76, city: 'YCU', country: 'CN', type: 'airport', nlabel: 'yuncheng guangong yuncheng china'},
  { icon: 'faPlaneDeparture', label: 'YUS Yushu City, Qinghai, China, Yushu Batang, YUS, yushu batang yushu city china', key:'A_YUS', cityBase: 72, city: 'YUS', country: 'CN', type: 'airport', nlabel: 'yushu batang yushu city china'},
  { icon: 'faPlaneDeparture', label: 'DEE Yuzhno-Kurilsk, Unknown Region, Yuzhno-Kurilsk Mendeleyevo, DEE, yuzhno kurilsk mendeleyevo yuzhno kurilsk unknown region', key:'A_DEE', cityBase: 127, city: 'DEE', country: 'ZZ', type: 'airport', nlabel: 'yuzhno kurilsk mendeleyevo yuzhno kurilsk unknown region'},
  { icon: 'faPlaneDeparture', label: 'UUS Yuzhno-Sakhalinsk, Russia, Yuzhno-Sakhalinsk, UUS, yuzhno sakhalinsk yuzhno sakhalinsk russia', key:'A_UUS', cityBase: 46, city: 'UUS', country: 'RU', type: 'airport', nlabel: 'yuzhno sakhalinsk yuzhno sakhalinsk russia'},
  { icon: 'faPlaneDeparture', label: 'ACZ Zabol, Iran, Zabol, ACZ, zabol zabol iran', key:'A_ACZ', cityBase: 127, city: 'ACZ', country: 'IR', type: 'airport', nlabel: 'zabol zabol iran'},
  { icon: 'faPlaneDeparture', label: 'ZCL Zacatecas, Mexico, Zacatecas International, ZCL, zacatecas international zacatecas mexico', key:'A_ZCL', cityBase: 42, city: 'ZCL', country: 'MX', type: 'airport', nlabel: 'zacatecas international zacatecas mexico'},
  { icon: 'faPlaneDeparture', label: 'ZAD Zadar, Croatia, Zadar, ZAD, zadar zadar croatia', key:'A_ZAD', cityBase: 19, city: 'ZAD', country: 'HR', type: 'airport', nlabel: 'zadar zadar croatia'},
  { icon: 'faPlaneDeparture', label: 'KZR Kütahya, Turkey, Zafer, KZR, zafer kutahya turkey', key:'A_KZR', cityBase: 21, city: 'KZR', country: 'TR', type: 'airport', nlabel: 'zafer kutahya turkey'},
  { icon: 'faPlaneDeparture', label: 'OZG Zagora, Morocco, Zagora, OZG, zagora zagora morocco', key:'A_OZG', cityBase: 115, city: 'OZG', country: 'MA', type: 'airport', nlabel: 'zagora zagora morocco'},
  { icon: 'faPlaneDeparture', label: 'ZAG Zagreb, Croatia, Zagreb, ZAG, zagreb zagreb croatia', key:'A_ZAG', cityBase: 14, city: 'ZAG', country: 'HR', type: 'airport', nlabel: 'zagreb zagreb croatia'},
  { icon: 'faPlaneDeparture', label: 'ZAH Zahedan, Iran, Zahedan, ZAH, zahedan zahedan iran', key:'A_ZAH', cityBase: 127, city: 'ZAH', country: 'IR', type: 'airport', nlabel: 'zahedan zahedan iran'},
  { icon: 'faPlaneDeparture', label: 'ZTH Zakynthos Island, Greece, Zakynthos International, ZTH, zakynthos international zakynthos island greece', key:'A_ZTH', cityBase: 10, city: 'ZTH', country: 'GR', type: 'airport', nlabel: 'zakynthos international zakynthos island greece'},
  { icon: 'faPlaneDeparture', label: 'ZAM Zamboanga City, Philippines, Zamboanga International, ZAM, zamboanga international zamboanga city philippines', key:'A_ZAM', cityBase: 93, city: 'ZAM', country: 'PH', type: 'airport', nlabel: 'zamboanga international zamboanga city philippines'},
  { icon: 'faPlaneDeparture', label: 'OZH Zaporizhia, Ukraine, Zaporizhia International, OZH, zaporizhia international zaporizhia ukraine', key:'A_OZH', cityBase: 85, city: 'OZH', country: 'UA', type: 'airport', nlabel: 'zaporizhia international zaporizhia ukraine'},
  { icon: 'faPlaneDeparture', label: 'ZAZ Zaragoza, Spain, Zaragoza, ZAZ, zaragoza zaragoza spain', key:'A_ZAZ', cityBase: 15, city: 'ZAZ', country: 'ES', type: 'airport', nlabel: 'zaragoza zaragoza spain'},
  { icon: 'faPlaneDeparture', label: 'ZAJ Zaranj, Afghanistan, Zaranj, ZAJ, zaranj zaranj afghanistan', key:'A_ZAJ', cityBase: 127, city: 'ZAJ', country: 'AF', type: 'airport', nlabel: 'zaranj zaranj afghanistan'},
  { icon: 'faPlaneDeparture', label: 'SZI Zaysan, Kazakhstan, Zaysan, SZI, zaysan zaysan kazakhstan', key:'A_SZI', cityBase: 127, city: 'SZI', country: 'KZ', type: 'airport', nlabel: 'zaysan zaysan kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'TLM Tlemcen, Algeria, Zenata – Messali El Hadj, TLM, zenata messali el hadj tlemcen algeria', key:'A_TLM', cityBase: 125, city: 'TLM', country: 'DZ', type: 'airport', nlabel: 'zenata messali el hadj tlemcen algeria'},
  { icon: 'faPlaneDeparture', label: 'DYG Zhangjiajie, China, Zhangjiajie Hehua International, DYG, zhangjiajie hehua international zhangjiajie china', key:'A_DYG', cityBase: 62, city: 'DYG', country: 'CN', type: 'airport', nlabel: 'zhangjiajie hehua international zhangjiajie china'},
  { icon: 'faPlaneDeparture', label: 'ZQZ Zhangjiakou, China, Zhangjiakou Ningyuan, ZQZ, zhangjiakou ningyuan zhangjiakou china', key:'A_ZQZ', cityBase: 68, city: 'ZQZ', country: 'CN', type: 'airport', nlabel: 'zhangjiakou ningyuan zhangjiakou china'},
  { icon: 'faPlaneDeparture', label: 'YZY Zhangye, China, Zhangye Ganzhou, YZY, zhangye ganzhou zhangye china', key:'A_YZY', cityBase: 127, city: 'YZY', country: 'CN', type: 'airport', nlabel: 'zhangye ganzhou zhangye china'},
  { icon: 'faPlaneDeparture', label: 'ZHA Zhanjiang, China, Zhanjiang, ZHA, zhanjiang zhanjiang china', key:'A_ZHA', cityBase: 65, city: 'ZHA', country: 'CN', type: 'airport', nlabel: 'zhanjiang zhanjiang china'},
  { icon: 'faPlaneDeparture', label: 'ZAT Zhaotong, China, Zhaotong, ZAT, zhaotong zhaotong china', key:'A_ZAT', cityBase: 76, city: 'ZAT', country: 'CN', type: 'airport', nlabel: 'zhaotong zhaotong china'},
  { icon: 'faPlaneDeparture', label: 'CGO Zhengzhou, China, Zhengzhou Xinzheng International, CGO, zhengzhou xinzheng international zhengzhou china', key:'A_CGO', cityBase: 40, city: 'CGO', country: 'CN', type: 'airport', nlabel: 'zhengzhou xinzheng international zhengzhou china'},
  { icon: 'faPlaneDeparture', label: 'DZN Jezkazgan, Kazakhstan, Zhezkazgan, DZN, zhezkazgan jezkazgan kazakhstan', key:'A_DZN', cityBase: 127, city: 'DZN', country: 'KZ', type: 'airport', nlabel: 'zhezkazgan jezkazgan kazakhstan'},
  { icon: 'faPlaneDeparture', label: 'ZIX Zhigansk, Russia, Zhigansk, ZIX, zhigansk zhigansk russia', key:'A_ZIX', cityBase: 127, city: 'ZIX', country: 'RU', type: 'airport', nlabel: 'zhigansk zhigansk russia'},
  { icon: 'faPlaneDeparture', label: 'PZH Zhob, Pakistan, Zhob, PZH, zhob zhob pakistan', key:'A_PZH', cityBase: 127, city: 'PZH', country: 'PK', type: 'airport', nlabel: 'zhob zhob pakistan'},
  { icon: 'faPlaneDeparture', label: 'ZHY Zhongwei, China, Zhongwei Shapotou, ZHY, zhongwei shapotou zhongwei china', key:'A_ZHY', cityBase: 69, city: 'ZHY', country: 'CN', type: 'airport', nlabel: 'zhongwei shapotou zhongwei china'},
  { icon: 'faPlaneDeparture', label: 'HSN Zhoushan, China, Zhoushan Putuoshan, HSN, zhoushan putuoshan zhoushan china', key:'A_HSN', cityBase: 72, city: 'HSN', country: 'CN', type: 'airport', nlabel: 'zhoushan putuoshan zhoushan china'},
  { icon: 'faPlaneDeparture', label: 'ZUH Zhuhai, China, Zhuhai Jinwan, ZUH, zhuhai jinwan zhuhai china', key:'A_ZUH', cityBase: 64, city: 'ZUH', country: 'CN', type: 'airport', nlabel: 'zhuhai jinwan zhuhai china'},
  { icon: 'faPlaneDeparture', label: 'ZIA Moscow, Russia, Zhukovsky International, ZIA, zhukovsky international moscow russia', key:'A_ZIA', cityBase: 127, city: 'MOW', country: 'RU', type: 'airport', nlabel: 'zhukovsky international moscow russia'},
  { icon: 'faPlaneDeparture', label: 'IEG Zielona Góra, Poland, Zielona Góra, IEG, zielona gora zielona gora poland', key:'A_IEG', cityBase: 124, city: 'IEG', country: 'PL', type: 'airport', nlabel: 'zielona gora zielona gora poland'},
  { icon: 'faPlaneDeparture', label: 'ZIG Ziguinchor, Senegal, Ziguinchor, ZIG, ziguinchor ziguinchor senegal', key:'A_ZIG', cityBase: 127, city: 'ZIG', country: 'SN', type: 'airport', nlabel: 'ziguinchor ziguinchor senegal'},
  { icon: 'faPlaneDeparture', label: 'ZND Zinder, Niger, Zinder, ZND, zinder zinder niger', key:'A_ZND', cityBase: 127, city: 'ZND', country: 'NE', type: 'airport', nlabel: 'zinder zinder niger'},
  { icon: 'faPlaneDeparture', label: 'ZIS Zintan, Libya, Zintan, ZIS, zintan zintan libya', key:'A_ZIS', cityBase: 127, city: 'ZIS', country: 'LY', type: 'airport', nlabel: 'zintan zintan libya'},
  { icon: 'faPlaneDeparture', label: 'IZA Juiz de Fora, Brazil, Zona da Mata Regional, IZA, zona da mata regional juiz de fora state of minas gerais brazil', key:'A_IZA', cityBase: 127, city: 'JDF', country: 'BR', type: 'airport', nlabel: 'zona da mata regional juiz de fora state of minas gerais brazil'},
  { icon: 'faPlaneDeparture', label: 'MCZ Maceió, Brazil, Zumbi dos Palmares International, MCZ, zumbi dos palmares international maceio state of alagoas brazil', key:'A_MCZ', cityBase: 78, city: 'MCZ', country: 'BR', type: 'airport', nlabel: 'zumbi dos palmares international maceio state of alagoas brazil'},
  { icon: 'faPlaneDeparture', label: 'ZYI Zunyi, China, Zunyi Xinzhou, ZYI, zunyi xinzhou zunyi china', key:'A_ZYI', cityBase: 67, city: 'ZYI', country: 'CN', type: 'airport', nlabel: 'zunyi xinzhou zunyi china'},
  { icon: 'faPlaneDeparture', label: 'ZRH Zürich, Switzerland, Zürich Airport, ZRH, zurich zurich switzerland', key:'A_ZRH', cityBase: 2, city: 'ZRH', country: 'CH', type: 'airport', nlabel: 'zurich zurich switzerland'},
  { icon: 'faPlaneDeparture', label: 'EVN Yerevan, Armenia, Zvartnots International, EVN, zvartnots international yerevan armenia', key:'A_EVN', cityBase: 34, city: 'EVN', country: 'AM', type: 'airport', nlabel: 'zvartnots international yerevan armenia'},
  { icon: 'faPlaneDeparture', label: 'ZKP Zyryanka, Sakha Republic, Russia, Zyryanka West, ZKP, zyryanka west zyryanka russia', key:'A_ZKP', cityBase: 127, city: 'ZKP', country: 'RU', type: 'airport', nlabel: 'zyryanka west zyryanka russia'},
];
export { listAirports, listCities };
