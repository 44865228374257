const titleType = ['Mr', 'Ms', 'Mrs', 'Miss', 'Dr'];
const employmentType = [
  { code: 100, name: 'Other' },
  { code: 101, name: 'Full time employed' },
  { code: 102, name: 'Part time employed' },
  { code: 103, name: 'Self employed' },
  { code: 104, name: 'Not employed' },
  { code: 105, name: 'Student' },
  { code: 106, name: 'Homemaker' },
  { code: 107, name: 'Retired' },
];
const residentialType = [
  { code: 100, name: 'Other tenant' },
  { code: 101, name: 'Owner' },
  { code: 102, name: 'Tenant unfurnished' },
  { code: 103, name: 'Council tenant' },
  { code: 104, name: 'House share' },
  { code: 105, name: 'Living with parents' },
  { code: 106, name: 'Tenant furnished' },
];
const employmentNames = employmentType.map(t => t.name);
const residentialNames = residentialType.map(t => t.name);

class FnplDictionaries {
  findInNames = ({ name }) => {
    return name.toLowerCase() === this.toLowerCase();
  };

  residential = residentialStr => {
    let residentialCode = residentialType.find(
      t => t.name.toLowerCase() === residentialStr.toLowerCase()
    );
    if (!residentialStr) {
      console.error('Employment code not valid: ', residentialStr);
    }
    return residentialCode.code || 100;
  };

  employment = employmentStr => {
    let employmentCode = employmentType.find(
      t => t.name.toLowerCase() === employmentStr.toLowerCase()
    );
    if (!employmentCode) {
      console.error('Employment code not valid: ', employmentStr);
    }
    return employmentCode.code || 100;
  };

  gender = title => {
    let genderCode = 0;
    switch (title) {
      case 'Mr':
      case 'Dr': // @todo to be confirmed
        genderCode = 102; // male
        break;
      case 'Ms':
      case 'Mrs':
      case 'Miss':
      default:
        genderCode = 101; // female;
    }
    return genderCode;
  };
}

const fnplDictionary = new FnplDictionaries();

export default FnplDictionaries;
export {
  fnplDictionary,
  titleType,
  employmentType,
  employmentNames,
  residentialType,
  residentialNames,
};
