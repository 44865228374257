import React from 'react';
import styled from 'react-emotion/macro';
import { useTranslation } from 'react-i18next';

import intercom from './intercom.png';

const flymbleBlue = '#4860ff';
const backgroundColor = '#f7f7f7';
const buttonWhite = '#f3f3f5';
const almostBlack = '#1a1a1a';

const CustomerServiceWrapper = styled.div`
  color: black;
  background-color: ${backgroundColor};
  padding-top: 50px;
  padding-bottom: 10px;
  text-align: center;

  @media (max-width: 1000px) {
    padding-bottom: 30px;
  }

  .title {
    font-size: 24px;
    color: black;
    font-weight: 700;
    padding-bottom: 20px;
  }

  .hereForYouMobile {
    padding-bottom: 32px;
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .hereForYouDesktop {
    border-bottom: 1px rgb(219, 219, 221) solid;
    padding-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    @media (max-width: 1000px) {
      display: none;
    }

    .grid {
      grid-column: 2/4;
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: left;

      @media (min-width: 1000px) {
        padding-bottom: 20px;
        /* padding-top: 40px; */
      }

      .secondaryGridA {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;

        p {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 14px;
        }

        .personalAssistant {
          color: #0079ff;
          font-size: 14px;
          margin-top: 6px;
          cursor: pointer;

          img,
          p {
            display: inline-block;
            vertical-align: middle;
          }

          img {
            padding-right: 10px;
            margin-bottom: 25px;
          }
        }

        .available {
          grid-column: 1/3;
          grid-row: 1/2;

          p:nth-child(2) {
            font-weight: bold;
          }
        }

        .emailUs {
          display: none;
          cursor: pointer;
          p {
            display: block;
          }

          p:nth-child(2) {
            color: #0079ff;
          }
        }
      }

      .secondaryGridB {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5px;

        p {
          display: inline-block;
          font-size: 14px;
          margin-top: 0px;
          margin-bottom: 16px;
        }

        a {
          text-decoration: none;
          color: black;
          cursor: pointer;
        }
      }

      h2 {
        font-size: 20px;
      }
    }
  }

  .travelCtas {
    @media (min-width: 1000px) {
      display: inline-block;
      padding-top: 15px;
    }

    .mobileOnly {
      @media (min-width: 1000px) {
        display: none;
      }
    }

    .desktopOnly {
      @media (max-width: 1000px) {
        display: none;
      }
    }
    hr {
      width: 75%;
      opacity: 0.3;
      @media (min-width: 1000px) {
        display: none;
      }
    }

    .travelSmartButtonWrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      @media (min-width: 1000px) {
        display: inline-block;
      }
    }

    h3 {
      display: block;
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 20px;
      padding-top: 30px;

      @media (min-width: 1000px) {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        margin-right: 19px;
        vertical-align: middle;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
`;

const InfoGroup = styled.div`
  margin-top: 14px;
  margin-top: 14px;
  font-size: 14px;
  line-height: 1.4;

  p {
    margin-bottom: 0px;
    margin-top: 0px;
    color: black;
  }

  p:first-child {
    color: black;
    font-weight: 500;
  }

  .phoneNumber {
    color: ${flymbleBlue};
    font-size: 32px;
    letter-spacing: -0.3px;
    margin-top: 15px;
    font-weight: 400;

    a {
      text-decoration: none;
      color: ${flymbleBlue};
    }
  }
`;

const Button = styled('a')`
  background: ${props => (props.white ? '#fff' : '#337ab7')};
  color: ${props => (props.white ? '#337ab7' : '#fff')};
  outline: none;
  border-radius: 3px;
  padding: 12px 30px;
  text-decoration: none;
  height: 35px;
  font-weight: 600;
  cursor: pointer;
`;

const ButtonPrimary = styled(Button)`
  background: ${flymbleBlue};
  margin-right: 20px;
  max-width: 120px;
  font-size: 16px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.15);
  padding: 12px 36px;
  @media (max-width: 380px) {
    padding: 12px 29px;
  }
  @media (max-width: 320px) {
    padding: 12px 20px;
  }
`;

const ButtonWhite = styled(Button)`
  background: ${buttonWhite};
  color: ${almostBlack};
  max-width: 120px;
  font-size: 16px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.15);
  padding: 12px 29px;
  @media (max-width: 380px) {
    padding: 12px 22px;
  }
  @media (max-width: 320px) {
    padding: 12px 16px;
  }
`;

const CustomerService = () => {
  const { t } = useTranslation();
  return (
    <CustomerServiceWrapper>
      <div className="hereForYouDesktop">
        <div className="grid">
          <div>
            <h2>{t("CustomerService..We're here for you")}</h2>
            <div className="secondaryGridA">
              <div
                className="personalAssistant"
                onClick={() =>
                  typeof window !== 'undefined' && window.Intercom('show')
                }
              >
                <img src={intercom} alt="/" />
                <p>
                  {t('CustomerService..Chat with a personal travel assistant')}
                </p>
              </div>
              <div
                className="emailUs"
                onClick={() =>
                  typeof window !== 'undefined' && window.Intercom('show')
                }
              >
                <p>{t('CustomerService..Chat with us')}</p>
                <p>
                  <u>
                    {t(
                      'CustomerService..Personal travel experts ready to help'
                    )}
                  </u>
                </p>
              </div>
              <div className="available">
                {/* <p>{t("CustomerService..We're available")}</p> */}
                <p>{t('CustomerService..Monday to Sunday 9am-6pm')}</p>
              </div>
            </div>
          </div>
          <div>
            <h2>{t('CustomerService..Top 5 FAQs')}</h2>
            <div className="secondaryGridB">
              <p>
                <a href="/help">
                  {t('CustomerService..Do you support one way flights?')}
                </a>
              </p>
              <p>
                <a href="/help">{t('CustomerService..How does it work?')}</a>
              </p>
              <p>
                <a href="/help">
                  {t(
                    'CustomerService..My passport expires soon, how can I book?'
                  )}
                </a>
              </p>
              <p>
                <a href="/help">
                  {t('CustomerService..When will I get my flight ticket?')}
                </a>
              </p>

              <p>
                <a href="/help">
                  {t(
                    'CustomerService..Is it possible to pay instalments on flights?'
                  )}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="hereForYouMobile">
        <h2 className="title">{t("CustomerService..We're happy to help")}</h2>
        <InfoGroup>
          <p>{t('CustomerService..Call now to book')}</p>
          <p className="phoneNumber">
            <a href={t('Main..Phone Number Link')}>
              {t('Main..Phone Number Text')}
            </a>
          </p>
          {/* <p>Open 8am-10pm</p> */}
          {/* <p>Personal travel experts ready to help</p> */}
        </InfoGroup>
        <InfoGroup>
          <p>{t('CustomerService..Chat with us')}</p>
          <p>{t('CustomerService..Personal travel experts ready to help')}</p>
        </InfoGroup>
        <InfoGroup>
          <p>We're available</p>
          <p>Monday to Sunday 10am-5pm</p>
        </InfoGroup>
      </div>

      <div className="travelCtas">
        <hr />
        <h3>{t('CustomerService..Travel the smart way')}</h3>
        <div className="travelSmartButtonWrapper">
          <ButtonPrimary
            className="desktopOnly"
            onClick={() => {
              window.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }}
            alt="get started"
          >
            {t('CustomerService..Get started')}
          </ButtonPrimary>

          <ButtonPrimary
            className="mobileOnly"
            onClick={() => {
              window.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }}
            alt="get started"
          >
            {t('CustomerService..Get started')}
          </ButtonPrimary>

          <ButtonWhite href="/about-us" alt="discover why">
            {t('CustomerService..Discover why')}
          </ButtonWhite>
        </div>
      </div>
    </CustomerServiceWrapper>
  );
};

export default CustomerService;

export { ButtonPrimary };
