import React from 'react';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import { CircleStop, LineStopVertical } from './TravelDisplay';

//#region
const DetailedRouteWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 60px 40px 4fr;
  grid-column-gap: 5px;

  @media (max-width: 1000px) {
    align-items: center;
  }

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
  }

  .visualStopsDetail {
    text-align: center;
    grid-row: 1/3;
    grid-column: 1/2;
  }

  .departureTime {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .departureAirport {
    grid-column: 3/4;
    grid-row: 1/2;
  }

  .departureCity {
    grid-column: 4/5;
    grid-row: 1/2;
  }

  .arrivalTime {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .totalDurationVisual {
    grid-column: 2/5;
    padding-top: 12px;
    padding-bottom: 15px;
  }
`;
//#endregion

class DetailedRoute extends React.Component {
  render() {
    return (
      <DetailedRouteWrapper>
        <div className="visualStopsDetail">
          <div>
            <CircleStop />
          </div>
          <div>
            <LineStopVertical />
          </div>
          <div>
            <CircleStop />
          </div>
        </div>
        <p className="departureTime">{this.props.departureTime}</p>
        <p className="arrivalTime">{this.props.arrivalTime}</p>
        <p className="departureAirport">{this.props.departureAirport}</p>
        <p className="arrivalAirport">{this.props.arrivalAirport}</p>
        <p className="departureCity">{this.props.departureCity}</p>
        <p className="arrivalCity">{this.props.arrivalCity}</p>
        <div className="totalDurationVisual">
          <FontAwesomeIcon icon={faClock} />
          <span>
            {' '}
            <b>{this.props.totalRouteDuration}</b>
          </span>
        </div>
      </DetailedRouteWrapper>
    );
  }
}

export { DetailedRoute };
