import React from 'react';
import { ActiveWrapper, BaggageElementWrapper } from "../styles";
import { BaggageTypeRow } from "./BaggageTypeRow";
import { cx } from 'emotion';
import { DimensionsInterface } from "../../../../interfaces";
import { IconProp } from '@fortawesome/fontawesome-svg-core';


export const BaggageElement = ({
    isActive,
    dimensions,
    price, 
    icon,
    handleOnClick,
    title,
}: {
    isActive: boolean,
    dimensions: DimensionsInterface,
    price: number,
    icon: IconProp,
    handleOnClick: Function,
    title: string
}) => {

    return (
        <ActiveWrapper>
            <div
                className={cx(
                    { borderWrapper: true },
                    { borderWrapperActive: isActive }
                )}
                onClick={() => handleOnClick()}
            >
                <BaggageElementWrapper>
                    <BaggageTypeRow
                        dimensions={dimensions}
                        title={title}
                        price={price}
                        icon={icon}
                    />
                </BaggageElementWrapper>
            </div>
        </ActiveWrapper>
    );
};