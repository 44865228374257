import React from 'react';
import styled from 'react-emotion/macro';
import { Modal, Button } from 'antd';
import i18n from 'Utils/i18n.js';
import Clock from './assets/clock.png';
import PropTypes from 'prop-types';

class CheckFlightsModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // redirectToReferrer: false,
    };
  }

  handleClick = () => {
    this.setState({
      redirectToReferrer: true,
    });
  };

  render() {
    console.log('modal content propsAA', this.props);
    if (this.props.isFlightUnavailable) {
      return (
        <CheckFlightsModalWrapper>
          <img src={Clock} alt="clockIcon" />
          <h2>
            {i18n.t(
              'CheckFlightsModal..Sorry, this trip has just been sold out.'
            )}
          </h2>
          <p>
            {i18n.t(
              'CheckFlightsModal..As the departure date approaches, the few remaining seats get sold out quickly. We recommend to choose an alternative flight from the results.'
            )}
          </p>
          <Button className="goBackButton" onClick={this.props.onOk}>
            {i18n.t('CheckFlightsModal..Back to results.')}
          </Button>
        </CheckFlightsModalWrapper>
      );
    } else if (this.props.isPriceChanged) {
      return (
        <CheckFlightsModalWrapper>
          <img src={Clock} alt="clockIcon" />
          <h2>
            {i18n.t('CheckFlightsModal..Sorry, the price has changed to £')}
            {`${this.props.updatedPricePerson.toFixed(2)}`}
          </h2>
          <p>
            {i18n.t(
              'CheckFlightsModal..It can take up to 5 minutes to receive the latest itinerary prices. We apologize for any inconvenience this may have caused.'
            )}
          </p>
          <Button className="goBackButton" onClick={this.props.onBack}>
            Back to results
          </Button>
          <Button
            className="goBackButton"
            onClick={this.props.onPriceChangeAccept}
          >
            {i18n.t('CheckFlightsModal..Agree to price change')}
          </Button>
        </CheckFlightsModalWrapper>
      );
    }
  }
}

CheckFlightsModalContent.propTypes = {
  isFlightUnavailable: PropTypes.bool,
  isPriceChanged: PropTypes.bool,
  onPriceChangeAccept: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  updatedPricePerson: PropTypes.number,
};

class CheckFlightsModal extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          {i18n.t('CheckFlightsModal..Open Modal')}
        </Button>
        <Modal
          closable={false}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={() => {}}
          footer={null}
        >
          <div>
            {i18n.t(
              'CheckFlightsModal..Something went wrong, please try again.'
            )}
            <CheckFlightsModalContent
              onAgreeToPriceChange={() => {}}
              otherProp={123}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default CheckFlightsModal;

export { CheckFlightsModalContent };

const CheckFlightsModalWrapper = styled.div`
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: black !important;
    margin: 0px;
  }

  img {
    width: 92px !important;
    height: 92px;
  }

  p {
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 5px;
  }

  .goBackButton {
    display: block;
    width: 100%;
    height: 46px;

    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 15px;
    margin-bottom: 10px;

    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;

    color: black;
    background-color: #d4d7e08c;

    border: 0px solid transparent !important;

    @media (min-width: 1000px) {
      margin-top: 0px;
    }
    @media (max-width: 325px) {
      font-size: 16px;
    }
  }
`;
