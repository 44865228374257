import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import paymentLogos from './payment_logos.png';

export const RowPaymentLogos = () => {
  return (
    <RowPaymentLogosWrapper>
      <VisaLogo />
      <MasterCardLogo />
    </RowPaymentLogosWrapper>
  );
};

RowPaymentLogos.propTypes = {
  //   isVisible: PropTypes.bool.isRequired,
};

const RowPaymentLogosWrapper = styled.div`
  float: right;

  @media (min-width: 1000px) {
    float: none;
    display: inline-block;
  }
`;

const VisaLogo = styled.div`
  margin-left: 0px;
  display: inline-block;
  background-image: url(${paymentLogos});
  background-position-x: 0px;
  background-position-y: -511px;
  width: 50px;
  height: 17px;
`;

const MasterCardLogo = styled.div`
  margin-left: 5px;
  display: inline-block;
  background-image: url(${paymentLogos});
  background-position-x: 0px;
  background-position-y: -834px;
  width: 38px;
  height: 22px;
`;
