import React from 'react';
import styled from 'react-emotion/macro';
import PropTypes from 'prop-types';
import { AppConsumer } from '../Context/AppContext';
import { fnplCalc, parsedTicketData } from '../Utils/fnplCalc';
import FlightTicket from './components/FlightTicket';

const searchPageBg = '#f9f9fa';

const MappedFlightTicketsWrapper = styled.div`
  @media (min-width: 1000px) {
    padding-top: 25px;
    margin: 0 auto;
  }
  padding-top: 10px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
  background-color: ${searchPageBg};
`;

// The Flight ticket function puts data of a single flight result into the right props.
// It can be used in a mapping function on a larger array of search results.
function FlightTicketFunction(flightObject, instalments, index) {
  // Here comes the parsed ticket data that parses the flight object data into single paramaters.
  const parsedData = parsedTicketData(flightObject);
  const { airlines } = flightObject;

  return (
    <AppConsumer key={index}>
      {searchContext => {
        const {
          lastSearch: { noAdults, noChildren, noInfants },
        } = searchContext.get();
        const noPassengers = noAdults + noChildren + noInfants;
        const costs = fnplCalc(
          flightObject.conversion[process.env.REACT_APP_VARIANT_CURRENCY] /
            noPassengers,
          instalments,
          airlines
        );
        const { instalment: pricePerMonth, upfront: priceUpfront } = costs;
        return (
          <FlightTicket
            // These values are not parsed from the flight ticket data.
            key={index}
            viewDetails={false}
            // ----------> End of not parsed data

            // Costs and pricing parameters
            costs={costs}
            pricePerMonth={pricePerMonth.toFixed(2)}
            priceUpfront={priceUpfront.toFixed(2)}
            nrInstalments={instalments}
            // ----------> End of  Costs and pricing parameters.

            // General route parameter(s)
            theFlightTicket={flightObject}
            typeFlight={parsedData.typeFlight}
            route={parsedData.route}
            // ----------> End of General parameter(s)

            // Airline codes. [all airline codes, outboundCodes, returnCodes]
            airlineCodes={parsedData.airlineCodes}
            outboundCodes={parsedData.outboundCodes}
            returnCodes={parsedData.returnCodes}
            airlineName={parsedData.airlineName}
            // ----------> End of Airline codes.

            // Airport codes in travel display view.
            departureAirportOutbound={parsedData.departureAirportOutbound}
            arrivalAirportOutbound={parsedData.arrivalAirportOutbound}
            departureAirportReturn={parsedData.departureAirportReturn}
            arrivalAirportReturn={parsedData.arrivalAirportReturn}
            // ----------> End of Airport codes.

            // City names. Unique per route in travel display.
            departureCityOutbound={parsedData.departureCityOutbound}
            arrivalCityOutbound={parsedData.arrivalCityOutbound}
            departureCityReturn={parsedData.departureCityReturn}
            arrivalCityReturn={parsedData.arrivalCityReturn}
            // ----------> End of City names.

            // Departure and Arrival times in travel display view.
            departureTimeOutbound={parsedData.departureTimeOutbound}
            arrivalTimeOutbound={parsedData.arrivalTimeOutbound}
            departureTimeInbound={parsedData.departureTimeInbound}
            arrivalTimeInbound={parsedData.arrivalTimeInbound}
            // ----------> End of Departure and Arrival times.

            // Flight duration in travel display view (duration, nr of stops, total duration)
            flightDurationOutbound={parsedData.flightDurationOutbound}
            flightDurationInbound={parsedData.flightDurationInbound}
            routeStopsOutbound={parsedData.routeStopsOutbound}
            routeStopsInbound={parsedData.routeStopsInbound}
            outboundTotalDuration={parsedData.outboundTotalDuration}
            returnTotalDuration={parsedData.returnTotalDuration}
            // ----------> End of flight duration parameters.

            // Time and dates.
            outboundTextDateDeparture={parsedData.outboundTextDateDeparture}
            outboundTextDateArrival={parsedData.outboundTextDateArrival}
            returnTextDateDeparture={parsedData.returnTextDateDeparture}
            returnTextDateArrival={parsedData.returnTextDateArrival}
            // ----------> End of Time and dates.

            // @Todo: Seats left function needs to be thought out.
            seatsText="seats left"
            nrSeatsLeft={5 + Math.floor(Math.random() * Math.floor(2))}
          />
        );
      }}
    </AppConsumer>
  );
}

// This function has the response data (an array with flight search results) as input. It needs to be called on the search results page.
function MapFlightTickets(responseResults, instalments) {
  return responseResults.map((result, index) => (
    <React.Fragment key={index}>
      {/* {index !== 0 && index % 2 === 0 && <FlightResultsBanner />} */}
      {FlightTicketFunction(result, instalments, index)}
    </React.Fragment>
  ));
}

const MappedFlightTickets = ({ searchResult, noMonths }) => {
  return searchResult ? (
    <MappedFlightTicketsWrapper>
      {MapFlightTickets(searchResult, noMonths)}
    </MappedFlightTicketsWrapper>
  ) : (
    <MappedFlightTicketsWrapper>
      {/* {MapFlightTickets(responseSample, 10)} */}
    </MappedFlightTicketsWrapper>
  );
};

MappedFlightTickets.propTypes = {
  searchResult: PropTypes.array.isRequired,
  noMonths: PropTypes.number.isRequired,
};

export default MappedFlightTickets;

// console.log(responseSample);
// console.log('Airport code legs.');
// console.log(allFlightLegs(responseSample[0]));

export { MapFlightTickets };
export { FlightTicketFunction };
