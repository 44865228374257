import __ from 'ramda/src/__';
import sum from 'ramda/src/sum';
import set from 'ramda/src/set';
import pipe from 'ramda/src/pipe';
import prop from 'ramda/src/prop';
import { AxiosResponse } from 'axios';
import { fold } from 'fp-ts/lib/Either';
import identity from 'ramda/src/identity';
import { TaskEither } from 'fp-ts/lib/TaskEither';

import { KIWI } from '../../constants';
import { VARIANT_LOCALE, VARIANT_CURRENCY } from 'constants/env';

import { configAxios, IHTTPRequest } from 'helpers/configAxios';

import * as L from './lenses'

const getCheckFlightParams = ({ key, noAdults, noChildren, noInfants }) => {
  const handleBasicParams = pipe(
    set(L.urlLens, KIWI.CF_URL),
    set(L.methodLens, 'GET'),
  );

  const handlePassengersParams = pipe(
    set(L.adultsLens, noAdults),
    set(L.infantsLens, noInfants),
    set(L.childrenLens, noChildren),
  )

  const params = pipe(
    handleBasicParams,
    handlePassengersParams,
    set(L.vLens, 2),
    set(L.bnumLens, 0),
    set(L.bookingTokenLens, key),
    set(L.localeLens, VARIANT_LOCALE),
    set(L.currencyLens, VARIANT_CURRENCY),
    set(L.affilyLens, `flymble_${VARIANT_LOCALE}`),
    set(L.pnumLens, sum([noAdults, noChildren, noInfants])),
  )({});

  return params as IHTTPRequest
}

// TODO: refactor
const handleError = (error) => {
  if (error.response) {
    console.error('Kiwi Er resp: ', error.response);
  } else if (error.request) {
    console.error('Kiwi Er req:', error.request);
  } else {
    console.log('Kiwi Error', error.message);
  }
  console.log(error.config);
}

const handleRequest = (fn: TaskEither<Error, AxiosResponse>) => fn()
  .then(
    fold(
      handleError,
      identity,
    )
  )
  .then(prop("data") as any) // TODO: remove any

// TODO: set args types
export const checkFlight = (key, noAdults, noChildren, noInfants) =>
  pipe(
    getCheckFlightParams,
    configAxios,
    handleRequest,
  )({ key, noAdults, noChildren, noInfants })
