import React from 'react';
import PropTypes from 'prop-types';
// import { cx } from 'react-emotion';
// import { css } from 'emotion/macro';
import { css, cx } from 'emotion';

const wrapperStyle = css`
  display: grid;
  grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;

  & > div {
    grid-column: 2/4;
  }
`;

class MainWrapper extends React.Component {
  render() {
    const { children, className } = this.props;
    return (
      <div className={cx(wrapperStyle, className)}>
        <div>{children}</div>
      </div>
    );
  }
}

MainWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bgColor: PropTypes.string,
  mobileBgColor: PropTypes.string,
  className: PropTypes.string,
};

MainWrapper.defaultProps = {
  children: null,
  bgColor: 'inherit',
  mobileBgColor: 'inherit',
  className: '',
};

export default MainWrapper;
