import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import FormLabel from '../../components/FormLabel';

const formInputSize = 'large';
const FormItem = Form.Item;

export const EmailField = ({ getFieldDecorator }) => {
  return (
    <div>
      <FormLabel>E-mail address</FormLabel>
      <FormItem>
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message:
                'Enter a valid email to receive your booking confirmation.',
              type: 'email',
            },
          ],
        })(
          <Input
            placeholder="your@email.com"
            size={formInputSize}
            style={{ width: '100%' }}
          />
        )}
      </FormItem>
    </div>
  );
};

EmailField.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
};
