import React, { useContext } from 'react';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChild, faBaby } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import AppContext from 'Context/AppContext';
import { PackagesContext } from 'Context/PackagesContext';

export const PassengersPopup = ({ isVisible, handleClose, isFlights }) => {
  // Set last search context
  const app_context = useContext(AppContext);
  const packages_context = useContext(PackagesContext);

  const context = isFlights ? app_context : packages_context;

  const { setLastSearch, lastSearch } = context;
  const { noAdults, noChildren, noInfants } = lastSearch;

  // Hanle change.
  const handleChange = (value, id) => {
    let b = {
      ...lastSearch,
      [id]: value,
    };
    setLastSearch(b);
    packages_context.setLastSearch(b);
  };

  const handleCloseButton = () => {
    handleClose();
  };
  return (
    isVisible && (
      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'fixed',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(37, 42, 49, 0.6)',
          zIndex: '2999999997',
        }}
        onClick={() => handleCloseButton()}
      >
        <PassengersPopupWrapper onClick={e => e.stopPropagation()}>
          <div className="innerWrapper">
            <PassengerTypeRow
              icon={faUser}
              passengerType="Adults"
              subTitle="Over 11"
              passengerAmount={noAdults}
              updateFunc={value => handleChange(value, 'noAdults')}
            />{' '}
            <PassengerTypeRow
              icon={faChild}
              passengerType="Children"
              subTitle="2 - 11"
              passengerAmount={noChildren}
              updateFunc={value => handleChange(value, 'noChildren')}
            />{' '}
            <PassengerTypeRow
              icon={faBaby}
              passengerType="Infants"
              subTitle="Under 2"
              passengerAmount={noInfants}
              updateFunc={value => handleChange(value, 'noInfants')}
            />
            <br />
            <Button
              style={{ width: '100%' }}
              onClick={() => handleCloseButton()}
            >
              Close
            </Button>
          </div>
        </PassengersPopupWrapper>
      </div>
    )
  );
};

PassengersPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isFlights: PropTypes.bool.isRequired,
};

const PassengerTypeRow = ({
  icon,
  passengerType,
  subTitle,
  passengerAmount,
  updateFunc,
}) => {
  const handleIncrement = () => {
    updateFunc(passengerAmount + 1);
  };

  const handleDecrement = () => {
    passengerAmount > 0 && updateFunc(passengerAmount - 1);
  };
  return (
    <div className="passengerTypeRow">
      <FontAwesomeIcon icon={icon} className="faIcon" />
      <div>
        {passengerType}
        <div className="subText">{subTitle}</div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        <Button
          icon="minus"
          onClick={() => handleDecrement()}
          disabled={passengerAmount < 1}
        />
        <div style={{ margin: 'auto', paddingRight: '2px' }}>
          {passengerAmount}
        </div>
        <Button icon="plus" onClick={() => handleIncrement()} />
      </div>
    </div>
  );
};

PassengerTypeRow.propTypes = {
  passengerType: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired,
  passengerAmount: PropTypes.number.isRequired,
  icon: PropTypes.object.isRequired,
  subTitle: PropTypes.string.isRequired,
};

const PassengersPopupWrapper = styled.div`
  position: fixed;
  bottom: 0px;
    width: 100%;

  .innerWrapper {
    left: 0px;
    padding: 20px;
    background-color: white;
    color: black;
    z-index: 3000000000;
    width: 100%;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    box-shadow: rgba(37, 42, 49, 0.16) 0px -4px 8px 0px,
      rgba(37, 42, 49, 0.24) 0px -8px 24px 0px;

    transition: opacity 0.15s ease-in-out 0s, transform 0.15s ease-in-out 0s;

    .passengerTypeRow {
      display: grid;
      grid-template-columns: 1fr 8fr 6fr;
      margin-bottom: 10px;

      .subText {
        color: rgb(95, 115, 140);
      }
    }

    .faIcon {
      /* color: rgb(95,115,140); */
      color: black;
      margin: auto;
      margin-right: 9px;
      font-size: 15px;
    }
  }

  @media (min-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    .innerWrapper {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 40px;
    }
  }
`;
