import React from 'react';
import i18n from 'Utils/i18n';

const variantFlightResultsBanner = {
  en: React.lazy(() => import('./FlightResultsBannerUK')),
  pl: React.lazy(() => import('./FlightResultsBannerPL')),
};

const FlightResultsBanner = variantFlightResultsBanner[i18n.language];

export default FlightResultsBanner;
