import { Modal } from 'antd';
const { confirm } = Modal;

const showMaintenanceModal = callFunction => {
  confirm({
    title: 'Klarna and direct payments will be available in 7 days.',
    content:
      'We are currently introducing Klarna and direct payment systems. Do you want to spread the cost using Fly Now Pay Later instead?',
    okCancel: false,
    onOk() {
      callFunction();
    },
    onCancel() {},
  });
};

const showKlarnaPriceLimitModal = callFunction => {
  confirm({
    title: 'Checkout with Fly Now Pay Later',
    content:
      'Klarna is available for all flights up to £800 that depart in at least 30 days. For all other flights you will be redirected to our partner Fly Now Pay Later.',
    okCancel: true,
    onOk() {
      callFunction();
    },
    onCancel() {},
  });
};

export { showMaintenanceModal, showKlarnaPriceLimitModal};
