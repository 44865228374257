import React from 'react';
import { css } from 'emotion/macro';
import styled from 'react-emotion/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Header, Modal } from 'semantic-ui-react';

const RegulatoryTextLineFNPLWrapper = styled('div')`
  grid-column: 2/4;
  color: black;
  text-align: left;
  padding: 20px;
  padding-top: 40px;
  .disclosureText {
    display: inline-block;
    /* text-decoration: underline; */
    cursor: pointer;
    text-align: left;
  }

  @media (min-width: 1000px) {
    text-align: left;
    padding-top: 30px;
  }
`;

export const RegulatoryTextLineFNPL = () => {
  return (
    <RegulatoryTextLineFNPLWrapper>
      <Modal
        trigger={
          <div>
            <h2>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className={css`
                  margin-right: 10px;
                  vertical-align: center;
                  margin-top: 5px;
                  cursor: pointer;
                `}
              />
              Flymble acts as a credit broker and not as a lender
            </h2>
            <p className="disclosureText">
              Flymble Travel Ltd, trading as Flymble, is an Appointed
              Representative of Fly Now Pay Later Limited, who are authorised
              and regulated by the Financial Conduct Authority FRN: 726937.
              Flymble acts as a Credit Broker, not a Lender, and is authorised
              and regulated by the Financial Conduct Authority FRN: 778540.
              Flymble will introduce you to Fly Now Pay Later Limited. Fly Now
              Pay Later act as a Credit Broker, not a Lender and works
              exclusively with one lender, Travelfund Limited who are authorised
              and regulated by the Financial Conduct Authority FRN:672306.
              <br />
              <br />
              Representative example: Borrowing £600 over 9 months, a one off
              transaction fee of 14.99% is payable (fixed), you will repay 9
              monthly payments of £66.66 per month. Total amount payable
              £689.94. Representative %APR 49%.
              <br />
              <br />
              Registered office address: Kemp House, 152 -160 City Road London,
              United Kingdom, EC1V 2NX
            </p>

            {false && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                className={css`
                  margin-left: 10px;
                  vertical-align: center;
                  margin-top: 5px;
                  cursor: pointer;
                `}
              />
            )}
          </div>
        }
        basic
        size="small"
        closeIcon
      >
        <Header
          icon="question circle"
          content="Flymble acts as a credit broker and not a lender"
        />
        <Modal.Content>
          <p>
            Flymble Travel Ltd, trading as Flymble, is an Appointed
            Representative of Fly Now Pay Later Limited, who are authorised and
            regulated by the Financial Conduct Authority FRN: 726937. Flymble
            acts as a Credit Broker, not a Lender, and is authorised and
            regulated by the Financial Conduct Authority FRN: 778540. Flymble
            will introduce you to Fly Now Pay Later Limited. Fly Now Pay Later
            act as a Credit Broker, not a Lender and works exclusively with one
            lender, Travelfund Limited who are authorised and regulated by the
            Financial Conduct Authority FRN:672306.
            <br />
            <br />
            Please don't hesitate to contact us should you have any questions:
            <br />
            <br />
            support@flymble.com
          </p>
        </Modal.Content>
      </Modal>
    </RegulatoryTextLineFNPLWrapper>
  );
};
