import React from 'react';
import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import { cx } from 'react-emotion';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import pages from 'pages';
import TrustPilot from './assets/sub-header-trustpilot-new-pl.png';
import LogoBlack from 'assets/images/logo_black.svg';
import LogoPurple from './assets/Flymble_logo_purple.png';
import LogoBlueWhite from 'assets/images/flymble_logo_blue_white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faSearch,
  faPlane,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import PartnerComponent from './PartnerComponent';

const MENU_WIDTH = '100vw';

const almostBlack = '#1a1a1a';
// const navBackground = '#337290d9';

const faStyle = css`
  font-size: 22px;
`;

const NavStyled = styled('div')`
  z-index: 3000;
  display: grid;
  position: fixed;
  width: 100vw;
  grid-template-columns: 150px 470px 160px 1fr;
  align-items: center;
  grid-gap: 10px;
  grid-auto-rows: 65px;
  height: 65px;
  @media (min-width: 600px) {
    grid-auto-rows: 50px;
    height: 50px;
    box-shadow: 0 2px 8px 0 rgba(88, 89, 91, 0.34);
  }

  background-color: white !important;

  .search_button {
    @media (min-width: 600px) {
      display: none;
    }
    justify-self: center;
    width: 100%;

    button {
      /* background-color: #e7e7e7; */
      background-color: #f7f7f7;
      text-align: left;
      width: 100%;
    }

    svg {
      color: #999;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      color: #e7e7e7;
      border-color: #e7e7e7;
    }
    span {
      text-align: center;
      width: 50%;
      display: inline-block;
      color: ${almostBlack};
      font-weight: 400;
    }
  }

  .logo_white,
  .logo_black {
    width: 100%;
    height: 100%;

    img {
      margin-left: 13px;
      margin-top: 12px;
    }
  }
  .logo_white {
    display: inline-block;

    img {
      height: 38px;
      margin-top: 6px;
    }
  }
  .logo_black {
    display: none;
    img {
      margin-left: 17px;
      margin-top: 20px;
    }
  }

  .credibilityValues {
    span {
      font-size: 14px;
      :first-child {
        /* padding-right: 15px; */
      }

      :nth-child(2) {
        padding-left: 15px;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  .menuitems {
    /* display: grid;
    grid-template-columns: repeat(6, 100px); */
    justify-content: end;
    text-align: right;
    z-index: 1000;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      grid-column: span 18;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 3px;
    }

    li {
      display: inline-block;
      padding: 0 20px 0 0;
    }
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    @media (min-width: 1000px) {
      font-weight: 500;
      color: black;
    }
    :hover {
      text-decoration: none;
    }
  }

  .active {
  }

  .menubutton {
    display: none;
    margin-right: 20px;
    justify-self: end;
  }

  .menuitem.menu_button--close {
    display: none;
  }

  .menuitem.header,
  .menuitem.social {
    display: none;
  }

  @media (max-width: 1000px) {
    display: none;
    background-color: white;
    color: black;

    .logo_white {
      display: none;
    }
    .logo_black {
      display: inline-block;
    }
    .menuitem {
      width: 100%;
      text-align: center;
      display: inline-block;
    }

    .menuitem.social {
      display: block;
    }
    .menuitem.header {
      display: block;
      padding: 20px 20px;
    }
    .menu_button--close {
      color: white;
      font-size: 18px;
    }
    .menuitem.menu_button--close,
    .menuitem.menu_button--logo {
      display: inline-block;
      cursor: pointer;
      align-self: start;
    }
    .menu_button--close {
      float: right;
    }
    .menu_button--logo {
      float: left;
    }

    .menuitem div.social {
      display: inline-block;
      padding: 0 15px;
    }

    #nav.open {
      transform: translate3d(-${MENU_WIDTH}, 0, 1px);
    }

    .menuitems {
      transform: translateZ(1px);
      z-index: 1000;
      transition: transform 0.2s ease-in-out;
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 100vw;
      width: ${MENU_WIDTH};
      background: black;
      ul {
        width: 100vw;
        margin-top: 0;
        margin-bottom: 0;
        display: grid;
        grid-template-rows: 1fr repeat(5, 50px) 2fr;
        height: calc(100% - 140px);
        align-items: center;
        justify-items: center;
      }

      a {
        font-size: 20px;
        font-weight: 600;
        color: white;
      }

      li {
        cursor: pointer;
        padding: 0;
      }

      .logo-brand {
        height: 50px;
        display: inline-block;
      }
      .button-close {
        display: inline-block;
      }
    }

    .menubutton {
      display: block;
    }
  } /* END OF @media (max-width: 599px) */

  .toggle {
    transition: transform 0.3 linear;
    color: black;
  }

  .toggle.open {
    z-index: 100;
  }

  .trustPilotLog {
    height: 31px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 2vw;
    @media (max-width: 1240px) {
      display: none;
    }
  }
`;

const CredibilityValues = () => {
  return (
    <div className="credibilityValues">
      {/* <span>{i18n.t('CredibilityValues..Over 2500 happy customers')}</span> */}
      &nbsp; &nbsp;
      <FontAwesomeIcon
        icon={faPlane}
        size="1x"
        className={css`
          color: #13c29b;
        `}
      />
      &nbsp; &nbsp;
      <span>
        {/* {i18n.t('CredibilityValues..14 million km traveled and counting')} */}
      </span>
    </div>
  );
};

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  openMenu = event => {
    event.preventDefault();
    document.ontouchmove = e => {
      e.preventDefault();
    };
    this.setState({
      open: true,
    });
  };

  closeMenu = event => {
    document.ontouchmove = e => {
      return true;
    };
    this.setState({
      open: false,
    });
  };

  render() {
    var toggleClasses = cx({
      toggle: true,
      open: this.state.open,
    });
    return (
      <NavStyled>
        <div className="logo_black">
          <Link to="/">
            <img src={LogoPurple} alt="logo" />
          </Link>
        </div>
        <div className="logo_white">
          <Link to="/">
            {/* <img src={LogoBlueWhite} alt="logo" /> */}

            <img src={LogoBlueWhite} alt="LogoBlueWhite" />
          </Link>
        </div>
        {false && <CredibilityValues />}
        <div />

        <PartnerComponent />
        <div
          className={cx({ menuitems: true, open: this.state.open })}
          id="nav"
          role="navigation"
        >
          <div className="menuitem header">
            <Link to="/">
              <img
                className="menu_button--logo"
                src={LogoBlack}
                alt="Flymble Logo"
                onClick={this.closeMenu}
              />
            </Link>
            <FontAwesomeIcon
              className="menu_button--close"
              icon={faTimes}
              size="1x"
              onClick={this.closeMenu}
            />
          </div>
          {/* <li className="menuitem">
            <div
              onClick={() => {
                const scrollToTop = () => {
                  const c =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                  if (c > 0) {
                    window.requestAnimationFrame(scrollToTop);
                    window.scrollTo(0, c - c / 8);
                  }
                };
                scrollToTop();
              }}
            >
              SCROLL TOP
            </div>
          </li> */}
          <li className="menuitem">
            <a href="https://www.trustpilot.com/review/flymble.com">
              <img
                src={TrustPilot}
                alt="trust pilot logo"
                className="trustPilotLog"
                target="_blank"
              />
            </a>
          </li>
          <ul>
            {this.props.menuItems.map(item => (
              <li
                className="menuitem"
                key={item.title}
                onClick={this.closeMenu}
              >
                <Link to={item.link} alt="/">
                  {item.title}
                </Link>
              </li>
            ))}
            <li className="menuitem"> </li>
          </ul>
          <div className="menuitem social">
            <div className="social">
              <a
                href="https://www.facebook.com/Flymble/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} className={faStyle} />
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.linkedin.com/company/flymble/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className={faStyle} />
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.instagram.com/flymbletravel/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className={faStyle} />
              </a>
            </div>
          </div>
        </div>
        <div className="search_button">
          <Link to={pages.flightResult} alt="search">
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className={css`
                height: 46px;
              `}
            >
              <FontAwesomeIcon icon={faSearch} size="1x" />
              Search flights
            </Button>
          </Link>
        </div>
        <div className="menubutton">
          <a className={toggleClasses} href="/" onClick={this.openMenu}>
            <FontAwesomeIcon
              icon={faBars}
              id="btnMenu"
              className={css`
                font-size: 18px;
              `}
            />
          </a>
        </div>
      </NavStyled>
    );
  }
}

Nav.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Nav;
