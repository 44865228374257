import React from 'react';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const blueGray = '#f2f3f6';
const flightRed = '#d0021b';

const ConnectionTimeWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 5px;

  color: ${flightRed};
  background-color: ${blueGray};

  margin: 10px 28px;
  margin-bottom: 20px;

  padding-left: 22px;
  padding-top: 12px;
  padding-bottom: 10px;
  border-radius: 3px;

  font-size: 16px;

  p {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 5px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 3fr 5fr 3fr;
    text-align: center;
    margin-right: 10px;
    padding-right: 23px;

    p {
      padding-left: 5px;
    }

    .longWait {
      padding-left: 0px;
    }

    .connectionAmount {
      grid-row: 1/2;
    }
  }
`;

const ConnectionTime = ({ isLongWait, connectionDuration }) => {
  const { t } = useTranslation();
  return (
    <ConnectionTimeWrapper>
      <p>{t('ConnectionTime..Connect in airport')}</p>
      {isLongWait && (
        <p className="longWait">
          <b>{t('ConnectionTime..Long wait')}</b>
        </p>
      )}

      <div className="connectionAmount">
        <FontAwesomeIcon icon={faClock} />
        <span> {connectionDuration}</span>
      </div>
    </ConnectionTimeWrapper>
  );
};

ConnectionTime.propTypes = {
  isLongWait: PropTypes.bool.isRequired,
  connectionDuration: PropTypes.string.isRequired,
};

export { ConnectionTime };
