import React from 'react';
import { css } from 'emotion/macro';
import { message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PerMonth } from './styles';

const InformationCircle = () => {
  return (
    <PerMonth>
      &nbsp;
      <FontAwesomeIcon
        icon={faInfoCircle}
        className={css`
          margin-left: 10px;
          vertical-align: center;
          margin-top: 5px;
          cursor: pointer;
        `}
        onClick={() => {
          message.info(
            'Pricing is determined by our credit partner FlyNowPayLater in the next step. Upfront credit fee might be required.',
            10
          );
        }}
      />
    </PerMonth>
  );
};

export default InformationCircle;
