export const generateMonthlyPriceArray = selectedPackage => {
  const {
    january_price,
    february_price,
    March_price: march_price,
    April_price: april_price,
    May_price: may_price,
    June_price: june_price,
    July_price: july_price,
    August_price: august_price,
    September_price: september_price,
    October_price: october_price,
    November_price: november_price,
    December_price: december_price,
  } = selectedPackage;
  return [
    {
      month: 'January',
      price: january_price,
      days: 31,
      monthId: 1,
    },
    {
      month: 'February',
      price: february_price,
      days: 28,
      monthId: 2,
    },
    {
      month: 'March',
      price: march_price,
      days: 31,
      monthId: 3,
    },
    {
      month: 'April',
      price: april_price,
      days: 30,
      monthId: 4,
    },
    {
      month: 'May',
      price: may_price,
      days: 31,
      monthId: 5,
    },
    {
      month: 'June',
      price: june_price,
      days: 30,
      monthId: 6,
    },
    {
      month: 'July',
      price: july_price,
      days: 31,
      monthId: 7,
    },
    {
      month: 'August',
      price: august_price,
      days: 31,
      monthId: 8,
    },
    {
      month: 'September',
      price: september_price,
      days: 30,
      monthId: 9,
    },
    {
      month: 'October',
      price: october_price,
      days: 31,
      monthId: 10,
    },
    {
      month: 'November',
      price: november_price,
      days: 30,
      monthId: 11,
    },
    {
      month: 'December',
      price: december_price,
      days: 31,
      monthId: 12,
    },
  ];
};
