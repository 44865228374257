import React from 'react';
import { Form, Input, AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import i18n from 'Utils/i18n.js';

import countriesPL from './CountriesPL.json';
import countriesUK from './CountriesUK.json';
// import { listCountries } from './Countries';

const FormItem = Form.Item;

const listCountries =
  i18n.language === 'pl' ? countriesPL.countries : countriesUK.countries;
const topCountries = i18n.language === 'pl' ? ['Polska'] : ['United Kingdom'];

class CountryInput extends React.Component {
  static propTypes = {
    getFieldDecorator: PropTypes.func,
    prefix: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataSource: topCountries,
      //   dataSource: listCountries,
    };
  }

  handleSearch = value => {
    // console.log('handleSearch', value);
    var filteredCountries = listCountries.filter(v => {
      return v.toUpperCase().indexOf(value.toUpperCase()) >= 0;
    });
    this.setState({
      // dataSource: (!value || value.lenght <3) ? topCountries : filteredCountries,
      dataSource: !value ? topCountries : filteredCountries.slice(0, 8),
    });
  };

  render() {
    const { dataSource } = this.state;
    const { getFieldDecorator, prefix } = this.props;
    return (
      <FormItem>
        {getFieldDecorator(`${prefix}nationality`, {
          rules: [
            {
              required: true,
              message: 'Is required.',
            },
          ],
        })(
          <AutoComplete
            size="large"
            onChange={this.props.onChange}
            className={this.props.className}
            // style={{ width: 200 }}
            onSearch={this.handleSearch}
            dataSource={dataSource}
            backfill={true}
            filterOption={(inputValue, option) => {
              return (
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) >= 0
              );
            }}
          >
            <Input placeholder={this.props.placeholder} size="large" />
          </AutoComplete>
        )}
      </FormItem>
    );
  }
}

CountryInput.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CountryInput;
