import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { DatePickerPackages } from './DatePickerPackages';
import { PackageInputFieldsWrapper } from './styles';
import { InputLabelComp } from '../InputLabelComp';
import { PropTypes } from 'prop-types';
import { DateDropDowns } from './DateDropDowns';
import { PassengersDropDown } from 'components/PassengersDropDown';
import { ContinueButton } from 'components/ContinueButton';

const InputFields = ({
  form,
  handleSelectNights,
  handleSelectAirport,
  handleSelectDepartureDate,
  monthlyPriceArray,
  handleContinue,
}) => {
  const [nightsState, setNightsState] = useState(7);
  const [isButtonLoadingState, setIsButtonLoadingState] = useState(false);

  const handleContinueLocal = () => {
    setIsButtonLoadingState(true);
    setTimeout(() => {
      setIsButtonLoadingState(false);
      handleContinue();
    }, 2000);
  };

  return (
    <PackageInputFieldsWrapper>
      <Form>
        <div className="airportInputWrapper">
          <p
            style={{
              marginBottom: '5px',
              color: 'black',
              fontSize: '15px',
              fontWeight: '400',
            }}
          >
            Departure Airport
          </p>
          <Select
            onChange={value => handleSelectAirport(value)}
            showArrow={true}
            placeholder="London Gatwick (LGW)"
            style={{ width: '100%' }}
            size="large"
            defaultValue="London"
          >
            {[
              'London Gatwick (LGW)',
              'London Stansted (STN)',
              'Manchester (MAN)',
              'Birmingham (BHX)',
              'Edinburgh (EDI)',
            ].map(opt => (
              <Select.Option value={opt} key={opt}>
                <span>{opt}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
        <br />
        <DateDropDowns
          monthlyPriceArray={monthlyPriceArray}
          handleSelectDepartureDate={handleSelectDepartureDate}
        />

        {false && (
          <DatePickerPackages
            formController={form}
            isOnlyOne={true}
            onChange={value => handleSelectDepartureDate(value)}
            initializeDepartureDate={handleSelectDepartureDate}
          />
        )}

        <InputLabelComp text={`${nightsState} nights`} />
        <Select
          showArrow={true}
          onChange={value => {
            handleSelectNights(value);
            setNightsState(value);
          }}
          size="large"
          defaultValue={7}
        >
          {[7, 10, 11, 14].map(opt => (
            <Select.Option value={opt} key={opt}>
              <span>{opt} Nights</span>
            </Select.Option>
          ))}
        </Select>
        <InputLabelComp text="Passengers" />
        <PassengersDropDown travelCategory="Packages" />
      </Form>
      <ContinueButton
        handleContinue={() => handleContinueLocal()}
        isButtonLoading={isButtonLoadingState}
        isVisible={false}
      />
    </PackageInputFieldsWrapper>
  );
};

InputFields.propTypes = {
  form: PropTypes.object,
  handleSelectNights: PropTypes.func.isRequired,
  handleSelectDepartureDate: PropTypes.func.isRequired,
  handleSelectAirport: PropTypes.func.isRequired,
  monthlyPriceArray: PropTypes.array.isRequired,
  handleContinue: PropTypes.func.isRequired,
};

const PackageInputFieldsProxy = Form.create({})(InputFields);

export const PackageInputFields = ({
  handleSelectNights,
  handleSelectDepartureDate,
  handleSelectAirport,
  monthlyPriceArray,
  handleContinue,
}) => (
  <PackageInputFieldsProxy
    handleSelectNights={handleSelectNights}
    handleSelectDepartureDate={handleSelectDepartureDate}
    handleSelectAirport={handleSelectAirport}
    monthlyPriceArray={monthlyPriceArray}
    handleContinue={handleContinue}
  />
);

PackageInputFields.propTypes = {
  form: PropTypes.object,
  handleSelectNights: PropTypes.func.isRequired,
  handleSelectDepartureDate: PropTypes.func.isRequired,
  handleSelectAirport: PropTypes.func.isRequired,
  monthlyPriceArray: PropTypes.array.isRequired,
  handleContinue: PropTypes.func.isRequired,
};
