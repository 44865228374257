import styled from 'react-emotion/macro';

const flymbleBlue = '#4860ff';

export const OfferSummaryFormWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  font-size: 15px;
  border-radius: 2px;
  .selectOfferCardWrapper {
    padding: 18px 15px;
    background: #ffffff;
    border: 1px solid #ccc;
    position: relative;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

    .title {
      color: #000000;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .roomCapacity {
      /* margin-bottom: 10px; */
      margin-bottom: 0px;
      margin-top: 15px;
    }

    .roomsForm {
      margin-bottom: 15px;
    }

    .selectOfferButton {
      background-color: ${flymbleBlue};
      width: 100%;
      color: white;

      @media (min-width: 1000px) {
        max-width: 50% !important;
      }
    }
  }

  @media (min-width: 1000px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const TellMeMoreWrapper = styled.div`
  .tellTitle {
    float: right;
    color: ${flymbleBlue} !important;
  }

  .content {
    display: block;
    font-size: 14px; 
    color: black;
    margin-top: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
`;
