import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import moment from 'moment';
import { Modal, Button } from 'antd';
import i18n from 'Utils/i18n.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AppConsumer } from './../../../Context/AppContext';
import { ShareTicket } from './ShareTicket';
import { trackSelectFlightTicket } from 'Utils';

const flymbleBlue = '#4860ff';
const flymbleGreen = '#13c29b';
const REACT_APP_TICKET_DATE_LIMIT_DEPARTURE =
  parseInt(process.env.REACT_APP_TICKET_DATE_LIMIT_DEPARTURE, 10) || 0;

class BottomRowComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isToggleOn: true,
      modalVisible: false,
    };
  }

  showLimitModal = () => {
    this.setState({ modalVisible: true });
  };

  handleModalClose = () => {
    this.setState({ modalVisible: false, mobileModalVisible: false });
  };

  selectTicket = ticket => {
    return () => {
      const { setSelectedFlightTicket } = this.context;
      setSelectedFlightTicket(ticket);
      trackSelectFlightTicket();
      window.localStorage.setItem(
        'SelectedFlightResult',
        JSON.stringify(ticket)
      );

      this.props.history.push({
        state: { theFlightTicket: ticket },
        pathname: '/payment-plan',
      });

      // this.props.history.push({
      //   state: { theFlightTicket: ticket },
      //   pathname: '/checkout',
      // });
    };
  };

  render() {
    const { modalVisible, mobileModalVisible } = this.state;
    const {
      costs: { instalment, flymblePrice, noInstalments },
      theFlightTicket,
      toggleDetails,
      viewDetails,
    } = this.props;

    return (
      <BottomRowWrapper className="bottomRowWrapperDisplay">
        <div
          className="viewDetailsMobileButton"
          onClick={() => toggleDetails()}
        >
          <FontAwesomeIcon
            className="viewDetails"
            icon={viewDetails ? faChevronUp : faChevronDown}
          />
        </div>

        <AppConsumer>
          {searchContext => {
            this.context = searchContext;
            const {
              lastSearch: { noAdults, noChildren, noInfants, dateFrom },
            } = searchContext.get();
            const currentBookingValue =
              parseFloat(flymblePrice) * (noAdults + noChildren + noInfants);
            const priceLimitCondition =
              currentBookingValue < process.env.REACT_APP_TICKET_LOWER_LIMIT ||
              currentBookingValue > process.env.REACT_APP_TICKET_HIGHER_LIMIT;
            const startDateLimitCondition = dateFrom.isBefore(
              moment().add(REACT_APP_TICKET_DATE_LIMIT_DEPARTURE, 'day')
            );

            const priceLimitContent = (
              <span>
                {i18n.t(
                  'BottomRowComponent..We currently only accept online bookings that range between £ {{lowLimit}} and £ {{highLimit}}.',
                  {
                    lowLimit: process.env.REACT_APP_TICKET_LOWER_LIMIT,
                    highLimit: process.env.REACT_APP_TICKET_HIGHER_LIMIT,
                  }
                )}
                <br />
                <br />
                {i18n.t(
                  'BottomRowComponent..The total price of your booking is: £{{value}}.',
                  {
                    value: currentBookingValue.toFixed(2),
                  }
                )}
                <br />
                <br />
              </span>
            );
            const startDateLimitContent = (
              <span>
                {i18n.t(
                  'BottomRowComponent..We are able to offer flight tickets with a minimum period of 7 days in advance. Please call '
                )}
                <b>{i18n.t('Main..Phone Number Text')}</b>.
                <br />
                <br />
                <span className={phoneNumber}>
                  <a href={i18n.t('Main..Phone Number Link')}>
                    {i18n.t('Main..Phone Number Text')}
                  </a>
                </span>
              </span>
            );
            const mobileModalContent = (
              <span>
                We are currently updating our mobile booking process. Please
                complete your booking on desktop.
              </span>
            );
            let modalContent;
            let modalTitle;
            let modalFooter = [
              <Button
                key="0"
                onClick={this.handleModalClose}
                size="large"
                className={css`
                  color: #fafafa;
                  background-color: #800080;
                  font-weight: 600;
                `}
              >
                {i18n.t('BottomRowComponent..Ok')}
              </Button>,
            ];
            if (priceLimitCondition) {
              modalContent = priceLimitContent;
              modalTitle = i18n.t(
                'BottomRowComponent..You have exceeded our current credit limit'
              );
            }
            if (startDateLimitCondition) {
              modalContent = startDateLimitContent;
              modalTitle = (
                <span
                  className={css`
                    color: #4860ff;
                  `}
                >
                  {i18n.t(
                    'BottomRowComponent..Please change your travel dates'
                  )}
                </span>
              );
            }

            return (
              <React.Fragment>
                <div className="priceTag">
                  <div className="bigprice">
                    {i18n.t('BottomRowComponent..Total Price: {{monthly}}', {
                      monthly: (currentBookingValue / noInstalments).toFixed(2),
                    })}
                  </div>
                  <div className="smallprice">
                    {i18n.t('BottomRowComponent..Ticket price')}
                    {i18n.t('BottomRowComponent..Total Price: {{total}}', {
                      total: currentBookingValue.toFixed(2),
                    })}
                  </div>
                </div>
                <div className="selectFlightTicket">
                  <Modal
                    visible={modalVisible}
                    title={modalTitle}
                    onOk={this.handleModalClose}
                    onCancel={this.handleModalClose}
                    footer={modalFooter}
                  >
                    {modalContent}
                  </Modal>
                  <Modal
                    visible={mobileModalVisible}
                    title={'Dear customer'}
                    onOk={this.handleModalClose}
                    onCancel={this.handleModalClose}
                    footer={modalFooter}
                  >
                    {mobileModalContent}
                  </Modal>

                  {/* {priceLimitCondition && (
                    <span onClick={this.showLimitModal}>
                      {i18n.t('BottomRowComponent..Select')}
                    </span>
                  )}
                  {startDateLimitCondition && (
                    <span onClick={this.showLimitModal}>
                      {i18n.t('BottomRowComponent..Select')}
                    </span>
                  )} */}
                  {/* no modals */}
                  {/* {startDateLimitCondition || ( */}



                  <span
                    onClick={this.selectTicket(theFlightTicket, flymblePrice)}
                    id="selectFlightTicketButton"
                  >
                    Select
                  </span>
                </div>
                <div className="desktopOnly">
                  <ShareTicket
                    viewDetails={this.props.viewDetails}
                    departureCity={this.props.departureCity}
                    arrivalCity={this.props.arrivalCity}
                    instalment={instalment}
                  />
                </div>
              </React.Fragment>
            );
          }}
        </AppConsumer>
      </BottomRowWrapper>
    );
  }
}

BottomRowComponent.propTypes = {
  theFlightTicket: PropTypes.object,
  costs: PropTypes.shape({
    instalment: PropTypes.number.isRequired,
    upfront: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    noInstalments: PropTypes.number.isRequired,
    flymblePrice: PropTypes.number.isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  viewDetails: PropTypes.bool.isRequired,
  departureCity: PropTypes.string,
  arrivalCity: PropTypes.string,
};

//#region

const phoneNumber = css`
  a {
    color: ${flymbleBlue};
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    margin-top: 25px;
  }
`;

const BottomRowWrapper = styled.div`
  grid-column: 1/3;
  display: grid;

  @media (max-width: 1000px) {
    grid-template-columns: 7fr 16fr 8fr;
  }

  @media (min-width: 1000px) {
    grid-row: 1/3;
    grid-column: 3/4;
    align-items: center;
    padding-bottom: 0px;
    /* border-left: 2px solid #e7e7e7 */
    border-left: dashed 1px #d0d0d0;
  }
  .viewDetailsMobileButton {
    padding-left: 15px;

    @media (min-width: 1000px) {
      display: none;
      text-align: center;
    }

    .viewDetails {
      margin-top: 5px;
      padding: 9px;
      width: 36px;
      height: 36px;
      background-color: #f0f0f0;
      border-radius: 2px;
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  .priceTag {
    text-align: center;
    @media (min-width: 1000px) {
      & > .bigprice {
        /* display: none; */
        font-size: 24px;
      }
      & > .smallprice {
        font-size: 20px;
        font-weight: 500;
        padding-bottom: 5px;
        padding-top: 4px;
        line-height: 2;
      }
      & > .totalprice {
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 0px;
        padding-top: 0px;
      }
    }
    @media (max-width: 1000px) {
      color: #31393c;
      padding-right: 3px;
      & > .bigprice {
        /* font-size: 19px;
        line-height: 26px; */
        font-size: 18px;
        line-height: 45px;
        font-weight: bold;
        margin-top: -3px;
      }
      & > .smallprice {
        font-size: 16px;
        line-height: 8px;
        font-weight: normal;
      }
    }
  }

  /* now obsolete, previous formatting with nasty grid */
  .priceDisplay {
    background-color: orange;
    @media (min-width: 1000px) {
      display: grid;
      text-align: center;

      .upfrontText {
        display: inline-block;
        grid-row: 2/3;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 6px;
        padding-top: 8px;
        text-align: center;
      }

      .priceUpfront {
        text-align: center;
        font-size: 36px;
        font-weight: normal;
        display: inline-block;

        grid-row: 1/2;

        .plusSign {
          display: none;
        }
      }
    }

    @media (max-width: 1000px) {
      text-align: center;
      color: #31393c;
      padding-right: 3px;
      span {
        font-size: 15px;
        font-weight: bold;
        :first-child {
          font-weight: bold;
          font-size: 19px;

          @media (max-width: 325px) {
            font-size: 15px;
          }
        }
      }

      .priceUpfront {
        text-align: center;
        font-size: 15px;
        font-weight: normal;
        display: inline-block;

        grid-row: 1/2;
      }

      .upfrontText {
        font-weight: normal;
        padding-left: 5px;
      }

      .plusSign {
        font-weight: normal !important;
      }
    }

    @media (max-width: 325px) {
      padding-top: 5px;
    }

    @media (min-width: 1000px) {
      span {
        :nth-child(2) {
          display: none;
        }
      }
    }
  }
  .selectFlightTicket {
    padding-top: 10px;
    text-align: right;
    display: block;
    cursor: pointer;

    @media (max-width: 1000px) {
      padding-top: 2px;
    }

    @media (min-width: 1000px) {
      text-align: center;
      padding-bottom: 8px;    
      padding-top: 0px;  
    }

    a {
      text-decoration: none;
    }

    span {
      padding: 10px 8px;
      background-color: ${flymbleGreen};
      color: white;
      font-size: 18px;
      font-weight: 500;
      display: block;
      text-align: center;
      border-radius: 2px;

      @media (min-width: 1000px) {
        /* background-color: ${flymbleBlue}; */        
        padding: 12px 55px;
        padding-bottom: 18px;
        font-size: 20px;
        /* box-shadow: inset 0 -5px 0 0 #0b1872, -1px 0 0 0 rgba(0, 0, 0, 0.1); */
        box-shadow: inset 0 -5px 0 0 rgba(0, 80, 53, 0.91), -1px 0 0 0 rgba(0,0,0,0.1);
        border-radius: 5px;
        max-width: 175px;
        display: block;
        margin: 0 auto;
      }
    }

    .mobileOnly {
      @media (min-width: 1000px) {
        display: none;
      }
    }
    .desktopOnly {
      @media (max-width: 1000px) {
        display: none;
      }
    }

  }

  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .priceDisplay {
    .desktopOnly {
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      text-align: center;
      padding-bottom: 10px;
      @media (max-width: 1000px) {
        display: none;
      }
    }
    @media (max-width: 1000px) {
      text-align: right;
      padding-right: 14px;
    }

    @media (max-width: 325px) {
      padding-right: 5px;
      font-size: 14px !important;
      text-align: center;
    }
  }

  .nrInstalments {
    font-size: 15px !important;
  }

  .desktopOnly {
    @media(max-width: 1000px) {
      display: none;
    }
  }
`;
//#endregion

const BottomRow = withRouter(BottomRowComponent);

export { BottomRow };
