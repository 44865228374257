import React from 'react';
import { cx } from 'react-emotion';
import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import LogoFlymble from 'assets/images/logo_flymble.svg';
import LogoBlueWhite from 'assets/images/flymble_logo_blue_white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import i18n from 'Utils/i18n.js';

const flymbleBlue = '#4860ff';
const almostBlack = '#1a1a1a';

const faStyle = css`
  font-size: 22px;
`;

const MobileMenuStyled = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: ${almostBlack};
  color: white;
  z-index: 1000;
  display: grid;
  grid-template-rows: 3fr repeat(5, 1fr) 4fr;
  grid-row-gap: 20px;
  left: ${props => (props.open ? '0' : '100vw')};
  transition: all 0.4s ease-in-out;
  padding: 20px 20px;
  text-align: center;

  & div.l1,
  & div.footer {
    a {
      max-width: 70vw;
      color: white;
    }
    span {
      margin: 0 20px;
    }
  }
  & div.footer {
    margin-top: auto;
    margin-bottom: 30%;
  }

  & div.header {
    display: grid;
    grid-template-columns: 32px 1fr 32px;
    grid-column-gap: 20px;
  }
  & .menuitem {
    & a {
      font-size: 20px;
      font-weight: 600;
      color: white;
      text-decoration: none;
    }
  }
`;

class MobileMenu extends React.Component {
  state = {
    open: false,
  };

  socials = [
    {
      href: 'https://www.facebook.com/Flymble/',
      icon: faFacebookF,
    },
    {
      href: 'https://www.instagram.com/flymbletravel/?hl=en',
      icon: faInstagram,
    },
    {
      href: 'https://www.linkedin.com/company/flymble/',
      icon: faLinkedinIn,
    },
  ];

  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }

  render() {
    const { menuItems, onToggle } = this.props;
    return (
      <MobileMenuStyled onClick={onToggle} open={this.state.open}>
        <div className="header">
          <div>
            <Link to="/">
              <img src={LogoFlymble} alt="Flymble logo" />
            </Link>
          </div>
          <div />
          <div>
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </div>
        </div>
        {menuItems.map(item => (
          <div
            className="menuitem"
            key={item.title}
            onClick={this.handleToggle}
          >
            <Link to={item.link} alt="/">
              {item.title}
            </Link>
          </div>
        ))}
        <div className="footer">
          <div className="social">
            {this.socials.map(({ href, icon }) => (
              <span className="social" key={icon.iconName}>
                <a href={href} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={icon} className={faStyle} />
                </a>
              </span>
            ))}
          </div>
        </div>
      </MobileMenuStyled>
    );
  }
}

class NavMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOnToggle = event => {
    if (this.state.open) {
      document.ontouchmove = e => {
        return true;
      };
      window.scrollTo(0, 0);
    } else {
      document.ontouchmove = e => {
        e.preventDefault();
      };
    }
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  openMenu = event => {
    event.preventDefault();
    document.ontouchmove = e => {
      e.preventDefault();
    };
    this.setState({
      open: true,
    });
  };

  closeMenu = event => {
    document.ontouchmove = e => {
      return true;
    };
    this.setState({
      open: false,
    });
  };

  NavStyled2 = styled.div`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    background-color: white;
    display: grid;
    grid-template-columns: 5fr 6fr 62px;
    align-content: center;
    position: relative;
    height: 57px;
    & > div.menu-logo,
    & > div.menu-open,
    & > div.menu-language {
      align-items: center;
      display: flex;
      & > span {
        margin-left: 10px;
      }
    }
    img.flymble-logo {
      color: ${flymbleBlue};
      width: 96px;
      margin-left: 20px;
      padding-top: 2px;
    }
    & > div.menu-open {
      padding-left: 18px;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      & > a {
        font-size: 26px;
        color: black;
      }
    }
    & > div.menu-language {
      justify-content: flex-end;
      padding: 0 10px;
      & > img {
        height: 22px;
        border-radius: 50%;
      }
      & > span {
        font-weight: 500;
        font-size: 14px;
      }
    }
    @media (min-width: 1000px) {
      display: none;
    }

    .menuPhone {
      color: #161616;
      align-items: center;
      display: flex;
      padding-right: 10px;
      justify-content: flex-end;
      font-weight: 500;
      font-size: 16px;

      span {
        margin-left: 10px;
      }

      a {
        text-decoration: none;
        /* color: #13c29b; */
        color: #161616;
      }

      /* temporary disable phone on top bar */
      .phoneIcon {
        /* display: none; */
      }
      /* END: temporary disable phone on top bar */

      @media (max-width: 321px) {
        .phoneIcon {
          display: none;
        }

        padding-right: 8px;

        span {
          margin-left: 0px;
        }
      }

      @media (min-width: 410px) {
        padding-right: 20px;
        font-size: 19px;
      }
    }
  `;

  render() {
    var toggleClasses = cx({
      toggle: true,
      open: this.state.open,
    });
    const NavStyled2 = this.NavStyled2;
    return (
      <NavStyled2>
        <div className="menu-logo">
          <Link to="/">
            <img
              className="flymble-logo"
              src={LogoBlueWhite}
              alt="Flymble logo"
            />
          </Link>
        </div>
        {/* <div className="menu-language">
          <img height="30" src={svgGBP} alt="GBP flag" />
          <span>GBP</span>
        </div> */}
        <div className="menuPhone">
          <FontAwesomeIcon
            icon={faPhone}
            className="phoneIcon"
            transform="flip-h"
          />
          <span>
            <a href={i18n.t('Main..Phone Number Link')}>
              {i18n.t('Main..Phone Number Text')}
            </a>
          </span>
        </div>
        <div className="menu-open">
          <a className={toggleClasses} href="/" onClick={this.openMenu}>
            <FontAwesomeIcon icon={faBars} id="btnMenu" />
          </a>
        </div>
        <MobileMenu
          menuItems={this.props.menuItems}
          open={this.state.open}
          onToggle={this.handleOnToggle}
        />
      </NavStyled2>
    );
  }
}

NavMobile.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape, {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

MobileMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape, {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

// export default NavMobile;
export default NavMobile;
