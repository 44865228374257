import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import { SearchMobileStyle } from './../Search/SearchMobileLP';

const flymbleBlue = '#4860ff';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
  /* padding-top: 40px; */

  @media(max-width: 1000px) {
    .desktopOnly {
      display: none;
    }
  }
`;

export const PageMain = styled.div`
  grid-column: 2/4;
  padding-left: 10px;
  padding-right: 10px;
`;

export const searchInputSummary = css`
  @media (min-width: 600px) {
    display: none;
  }

  /* This code specifies the new input summary section */
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 15px;
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 2px solid #cccedb;
    background-color: white;

    .inputWrapper {
      padding-left: 10px;
      padding-right: 10px;

      display: grid;
      grid-template-columns: 7fr 1fr;

      .summaryRows {
        display: grid;
        grid-template-rows: 1fr 1fr;

        .fromToRow {
          padding-left: 10px;
          span {
            color: #272727;	
            font-size: 16px;	
            font-weight: bold;	
            line-height: 19px;
            padding-bottom: 0px;
            padding-left: 0px;
          }

          .arrowIcon {
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        .datesRow {
          color: #9B9B9B;
          padding-left: 10px;

          
          span {
            padding-top: 0px;
            font-size: 14px;
            padding-left: 0px;
            font-weight: normal;
            padding-bottom: 0px;
          }
        }
      }

      .editIcon {
        font-size: 20px;
      }

      .editText {
        /* margin-bottom: 0px; */
      }
    }


  
  span {
    padding: 10px;
    padding-right: 0px;
    /* border: solid 1px ${flymbleBlue}; */
    /* margin-right: 10px; */
    font-size: 12px;
    /* color: ${flymbleBlue}; */
    /* border-radius: 3px; */
    white-space: nowrap;
    @media (max-width: 325px) {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .editButton {
    font-size: 14px;
    color: ${flymbleBlue};
    letter-spacing: 0.5px;
    font-weight: 500;

    @media (max-width: 600px) {
      display: block;
      cursor: pointer;

    }
  }
`;

export const PaybackMonths = styled('h3')`
  padding-top: 30px;
  font-size: 20px;
  font-weight: 500;
`;

export const ShowingPrice = styled('div')`
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  @media (min-windth: 1000px) {
    padding-top: 15px;
    padding-bottom: 35px;
  }
`;

export const SearchBarWrapper = styled.div`
  ${SearchMobileStyle};
  @media (max-width: 1000px) {
    display: none;
  }

  /* background-color: #0000008f; */
  margin-top: 35px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-bottom: 16px;
  border: #4860ff solid 2px;
`;
