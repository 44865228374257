import React, { useContext, useEffect, useState } from 'react';
import AppContext from 'Context/AppContext';
import { checkFlight } from 'Services/Kiwi';
import { CheckFlightsModalContent } from './CheckFlightsModal';
import { Modal } from 'antd';
import { generateLuggageObject } from 'Utils/generateLuggageObject';
import { FLYMBLE_PRICE_MARGIN } from '../../constants';

const CheckFlightsContainer = ({ children }) => {
  const context = useContext(AppContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkFlightsPriceState, setCheckFlightsPriceState] = useState(0);

  const {
    lastSearch: { noAdults, noChildren, noInfants },
    selectedFlightTicket,
    setSelectedFlightTicket,
    setCheckFlightsBaggageContext,
  } = context;

  const token = selectedFlightTicket.booking_token;
  // const tokenWowAir =
  //   'AMtK0WxJ02zPCZPVfz9qGJX1-4WNXHoGWDxlTzi5nJYAKPKGipvs3iKjmvNhWujTxqQL_pROAN3FnmyjEzK2EMC2N4iea0HDq1LtuWHH8EPQDNpFOqLjFDlUbwgvSqdMrk0P0XruIbz4_-hbocjiOoAcHckb1Fc2YVc8UCNGmOlJrOZhEM3BZo1hFPlVJYait75wI_w8UeQTtgWBGecZLEl-wpWCkYMdqr3i24K4JJ7W_oTk8yQKQbjH5MpKQ0uVPBX5pEvjbCQH5VEZim6Ow7nsfAY4zSbddDrxHDMRAvYjeFjPYNz7qGZccqA90yn9tU55VxxVa5DxT9hYaD5rzrbQqB_USFoLUxK5C2_2SVL3PP_a2ozvTk_INNnEADYPlWcllvLRnnBLwGMwD2Okyzv0Jf1ezFQe-kWLKia7Mtk7Xq5qQAXFy7jTj_iVa2ZK5iyCDadCka76y24hmQ3wWUPLht60mpwO3_H_SVIhsIiyanl7tmzvpforl3kT_kARhX5crvWq9JXZnrbnrAs2awEafD_CH7xlBDKRQp50wn-eqtejizafnq0M0_gWdfK0zXKVQjDyHNz4AuGKTpSDkl_NGktmR9BLro_nLr9kBPOk=';
  // const tokenRyanAir =
  //   'A5iNAS53D4iNYYVhNn6S65b675oPMhvM8N3-KSLnuN5SWcJmtfoLRiO9nLXmCHMMW8LZ36MBJ2AkkP6RT8koLvDgSiYeDfHQWV8fNXqhO6gj7_ZRGgtgHzfYCpHa8N-ex0rCd9hoTjt0DZIlmhUW64bKqcIiPVVCT6LIK1JaJM3FyVcZT_J_SP5TvGduqE-932hRVvTsiKqnshwjA8KXkTt6UEufNf-o4qWUE1A47AOCK9EY-BDwdG-TWdFd3tQrG7ajMQbeyacXdUxZcC4B9b2E8XVBf92D4R4SO1fDeTukKBGRpAQU3L2LE5Oe7JwBV0XKtG8LQPqqPsEjGZrqlXUilsDNnIGWE4R8qY3_sMSKTcnMYBQ4ed_2D03p3_QnRLr9lPu_6p683JbsuHWEh_qfafVQ8eDh0N-Fw4Yg72QIxmXqTYzbOLo47A39h3ai0NfmyqBXEFBfHmfxfnQ7mP_-YFMBTjcc9xKeV7turp8ZpVFnrSXFSrWZ_jPRGVhkW8G641pbZ65uRszCK49xCwXVo2cVPpm30qlYC6ZxBCxGj7xOP4BhxAy6kFNslbqT-5V3EXAZF7Z7DB3pYNey0Pw==';
  // const tokenVirgin = "AT8Bk_UpFAyayeFSSt3vEZgFlskV6Uj0puyW6UUpzofvu3IMAC9eXD-qNIfgwAHejr3F5vteBydCEzSaubq4KtQK4PxD5cTe5emIIEwU5EUYz3Ytod0MUPUEu1hbx3MHBtvosyxRnHZ8fuVxLIvleSJ2B_omaHvoIZ3Z0ciJfQMn9907l0_JL0WSXiij3E-O1pk_3kUR6tzOulLFMBiqSjkpH-ClWyk5EmdV6kbtx31cdMISWiJYP57yA8wYZwDwMRELWTvoMSk0auDLNrNachNsalzcg5T-fnCqvmSJ6N4yeuPOlwlDvidwSb7jqNnFvaY8zmnf9nRx3YrCK-xuEiiFj6E4Uzn87jiRdpykpNvavKrK3d2Vkey9_P_bZ3SPzHJFurZ2iPItrVhkVGzAfJmn2--Ny9PVmvlIp5zCkhywNgvJ0104YpMm2uWzZJN_4hTD4vVv3GV-84RUI01VlmdJA1lDy1GXKOG1Uh_E0B9MxU87GDDjAsPfWu-d3ru2PhYEcya9MjG5R1CXTqjUS1ICdFXnKVsQM6aeDN691Xfhom5YRX9njsXk5LUrxdBALWTnVY19RWNQmDVwV1qem0RzODVJn-lv-Yg5cQxxow7n23R4A8M1cMAt3b1Fe9Y4ZRqklZaTfoXul7dsW0Q5gj_WpkMKad-xP8mLQyZw0I18=";
  // const tokenUnitedAirlines = '';
  // const wrongToken =
  //   'AlwjBL9EiBhG6W2uGFjduVI9rVO49IlFORLwPNv7p3JBoxEuXRRahU4NE9_McnfijByuxGDB5Z8FLe39c0-TwzDexq1Iigj49th4w9xuHiuzsOObf8UP_ljnESw4qimLGm1hLkrj0-Pclw5KD0kAY1RiFGeYM-bd0O45nnZ5HgPuqx3zjiias8wG7QHlueNpTKt7jtIAeyO69PmPTctIyCD5CmhL4akJVuEQimg4jYjL21sfTmNARV7XEqt6bH4f0J8PdL_36ZbHhdNA524gtBBOKMaNSe6CV3OKMHPfELArMx7bwQyT8Ls0zIavABx96ByCpqJDstq2r64QQU93ktwoXO_pjuEmDzJGy7IZzn-SmzmXHZeWluJD01zASZq-mU--995zSJTI3U3ncobrmSF3b16DB0unC82iQd4nGjvbFQl1wGnFhadDFujv7gDtAOIaNFtL0ng5LYpO8ZgzY2rWJNX3mJroeWtoQD7-C1vAgLPO-VxQPnHcBq7EZNDd9WVrUl7yLeqy1XcjGMvypvZqiIP065yagv6-9VPzL6BulQgtVz6Rbgu2RELfxbhA7pgkr8wabckKQEdCWinbYepJTrUnovyslTKk8r5rIN7dFOfnVQJiJ2_Oerpr4Z8PvbvIv_cKVpiRiiTP5OLLDIjGHF73DPoH3Ekhpth1N2hvNrIBttq5zPyKSPtvD7NYjA0bgde8rgW-nHRi046cFnX8YY0y9zYgKGdLwc0Niyis=';
  // const token = wrongToken;

  const doCheckFlight = () => {
    checkFlight(token, noAdults, noChildren, noInfants)
      .then(response => {
        console.log('the check flight response is....', response);
        setCheckFlightsBaggageContext(generateLuggageObject(response));
        const ticketPriceSearch = selectedFlightTicket.conversion['GBP'];
        const checkFlightsPrice = response.conversion.amount;

        let ratio = (
          checkFlightsPrice /
          (ticketPriceSearch / FLYMBLE_PRICE_MARGIN)
        ).toFixed(2);

        if (ratio > 1.08) {
          console.log('Flymble manipulated price', ticketPriceSearch);
          console.log('The new check flights price', checkFlightsPrice);
          console.log('The change ratio is', ratio);

          setIsModalVisible(true);
          setCheckFlightsPriceState(checkFlightsPrice);
        }
      });
  };

  useEffect(() => {
    console.log('The flight ticket deep link:', selectedFlightTicket.deep_link);
    doCheckFlight();
  }, []);

  return (
    <>
      {children && children}

      <Modal
        closable={false}
        visible={isModalVisible}
        onOk={() => { }}
        onCancel={() => { }}
        footer={null}
      >
        <CheckFlightsModalContent
          isFlightUnavailable={false}
          isPriceChanged={true}
          updatedPricePerson={
            checkFlightsPriceState
            // / (noAdults + noChildren + noInfants)
          }
          onBack={e => {
            e.preventDefault();
            let ticket = selectedFlightTicket;
            ticket.conversion['GBP'] = checkFlightsPriceState;
            setSelectedFlightTicket(ticket);
            setIsModalVisible(false);
            window.history.back();
          }}
          onPriceChangeAccept={() => {
            let ticket = selectedFlightTicket;
            ticket.conversion['GBP'] = checkFlightsPriceState;
            setSelectedFlightTicket(ticket);
            setIsModalVisible(false);
          }}
        />
      </Modal>
    </>
  );
};

export { CheckFlightsContainer };
