import React from 'react';
import PropTypes from 'prop-types';

const SideCollumnDesktop = ({children}) => (
  <div className="sideColumnWrapper">
    <div className="stickyWrapper">
      {children}
    </div>
  </div>
);

SideCollumnDesktop.propTypes = {
    children: PropTypes.object.isRequired,
  };

export default SideCollumnDesktop;
