import lensPath from 'ramda/src/lensPath';
import lensProp from 'ramda/src/lensProp';

import { VARIANT_CURRENCY } from 'constants/env'

export const urlLens = lensProp('url');
export const methodLens = lensProp('method');
export const paramsLens = lensProp('params');
export const vLens = lensPath(['params', 'v']);
export const toLens = lensPath(['params', 'to']);
export const sortLens = lensPath(['params', 'sort']);
export const currLens = lensPath(['params', 'curr']);
export const pnumLens = lensPath(['params', 'pnum']);
export const bnumLens = lensPath(['params', 'bnum']);
export const limitLens = lensPath(['params', 'limit']);
export const affilyLens = lensPath(['params', 'affily']);
export const dateToLens = lensPath(['params', 'dateTo']);
export const adultsLens = lensPath(['params', 'adults']);
export const localeLens = lensPath(['params', 'locale']);
export const dtimetoLens = lensPath(['params', 'dtimeto']);
export const partnerLens = lensPath(['params', 'partner']);
export const infantsLens = lensPath(['params', 'infants']);
export const flyFromLens = lensPath(['params', 'flyFrom']);
export const currencyLens = lensPath(['params', 'currency']);
export const dateFromLens = lensPath(['params', 'dateFrom']);
export const returnToLens = lensPath(['params', 'returnTo']);
export const childrenLens = lensPath(['params', 'children']);
export const dtimefromLens = lensPath(['params', 'dtimefrom']);
export const selectAirlinesLens = lensProp('select_airlines');
export const returndtimefromLens = lensProp('returndtimefrom');
export const responseDataDataLens = lensPath(['data', 'data']);
export const returnFromLens = lensPath(['params', 'returnFrom']);
export const flightTypeLens = lensPath(['params', 'flight_type']);
export const conversionOriginalLens = lensProp('conversionOriginal');
export const bookingTokenLens = lensPath(['params', 'booking_token']);
export const maxStopoversLens = lensPath(['params', 'max_stopovers']);
export const returndtimetoLens = lensPath(['params', 'returndtimeto']);
export const selectAirlinesExcludeLens = lensProp('select_airlines_exclude');
export const conversionByCurrencyLens = lensPath(['conversion', VARIANT_CURRENCY]);
