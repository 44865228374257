import React from 'react';
import { css, cx } from 'emotion/macro';
import { paymentPlanOptionsPartnersWithKlarna } from './paymentPlanOptionsPartnersWithKlarna';
import ButtonCardPartners from './ButtonCardPartners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';

const CardsPartners = ({
  totalBookingPrice,
  setContextPaymentPlanPartner,
  paymentPlanPartnerSelected,
  isVisible,
  activeMonths,
  setContextAmountMonths,
}) => {
  // const mobileOptions = paymentPlanOptionsPartnersWithKlarna(totalBookingPrice)
  //   .slice(0, 1)
  //   .concat(paymentPlanOptionsPartnersWithKlarna(totalBookingPrice).slice(2));

  const desktopOptions = paymentPlanOptionsPartnersWithKlarna(
    totalBookingPrice
  );
  // const options =
  //   isMobileDevice() && isShowingSignUpTest ? mobileOptions : desktopOptions;

  const options = desktopOptions;

  const sliceNumber = 4;

  const fourColGrid = css`
    @media (min-width: 1000px) {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  `;

  return (
    isVisible && (
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className={cx('paymentPlanButtons', fourColGrid)}>
          {options.slice(0, sliceNumber).map((plan, index) => {
            return (
              <ButtonCardPartners
                key={index}
                option={plan}
                setContextPaymentPlanPartner={setContextPaymentPlanPartner}
                paymentPlanPartnerSelected={paymentPlanPartnerSelected}
                activeMonths={activeMonths}
                setContextAmountMonths={setContextAmountMonths}
              />
            );
          })}
        </div>
      </ReactCSSTransitionGroup>
    )
  );
};

export default CardsPartners;

CardsPartners.propTypes = {
  totalBookingPrice: PropTypes.number.isRequired,
  setContextPaymentPlanPartner: PropTypes.func.isRequired,
  paymentPlanPartnerSelected: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  activeMonths: PropTypes.number.isRequired,
  setContextAmountMonths: PropTypes.func.isRequired,
};
