import moment from 'moment';
import { generateMonthlyPriceArray } from 'Utils';

// Constants.
const BASIS_NIGHTS = 7;
const AMOUNT_PASSENGERS_BASE_PRICE = 2;
const AIRFARE_PART = 0.4;
const HOTEL_PART = 1 - AIRFARE_PART;

export function calculatePrice(
  selectedPackage,
  amountRoomsContext,
  amountNightsContext,
  departureDateContext,
  amountPassengers
) {
  console.log('[calculatePrice function] start calling with selectedPackage:  ', selectedPackage);

  // Retrieve monthly price array
  const monthlyPriceArray = generateMonthlyPriceArray(selectedPackage);
  const discountMultiple = selectedPackage.old_price / selectedPackage.price;

  console.log('[monthlyPriceArray] ', monthlyPriceArray);

  // Unpack price of the selected package (at departure, based on two passengers).
  const basePriceDepartureMonth = getPriceAtDepartureDate(
    departureDateContext,
    monthlyPriceArray,
    selectedPackage
  );


  console.log('[basePriceDepartureMonth] ', basePriceDepartureMonth);

  // Calculate flight prices. [Based on Passengers]
  const flights_price_fraction = AIRFARE_PART * basePriceDepartureMonth;
  const per_passenger_flight_price =
    flights_price_fraction / AMOUNT_PASSENGERS_BASE_PRICE;
  const flights_price_total = per_passenger_flight_price * amountPassengers;

  // Calculate hotel room prices. [Based on amount Rooms]
  const hotel_price_fraction_per_day =
    (HOTEL_PART * basePriceDepartureMonth) / BASIS_NIGHTS;
  const hotel_price_total =
    hotel_price_fraction_per_day * amountNightsContext * amountRoomsContext;

  // Total price
  const dealPriceAllPassengers = flights_price_total + hotel_price_total;
  const oldDealPriceAllPassengers = discountMultiple * dealPriceAllPassengers;


  const oldDealPriceAllPassengers_proxy = Number.parseFloat(oldDealPriceAllPassengers.toFixed(2));
  const dealPriceAllPassengers_proxy = Number.parseFloat(dealPriceAllPassengers.toFixed(2));


  return {
    oldDealPriceAllPassengers: oldDealPriceAllPassengers_proxy,
    dealPriceAllPassengers: dealPriceAllPassengers_proxy,
  };
}

const getPriceAtDepartureDate = (departureDateContext, monthlyPriceArray) => {
  const momentObject = moment(departureDateContext, 'YYYY-MM-DD');
  const departureMonth = parseInt(momentObject.format('M'));
  const priceInDepartureMonth = monthlyPriceArray[departureMonth - 1].price;

  return priceInDepartureMonth;
};
