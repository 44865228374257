import map from 'ramda/src/map';
import over from 'ramda/src/over';
import pipe from 'ramda/src/pipe';
import curry from 'ramda/src/curry';
import { AxiosResponse } from 'axios';
import { fold } from 'fp-ts/lib/Either';
import identity from 'ramda/src/identity';
import { TaskEither } from 'fp-ts/lib/TaskEither';

import { configAxios } from 'helpers/configAxios';
import { handleError } from 'helpers/handleError';

import { prepareParams } from './prepareParams';
import { addAdditionalFees } from './addAdditionalFees';

import { responseDataDataLens } from './lenses'

const handleRequest = (fn: TaskEither<Error, AxiosResponse>) => fn()
  .then(
    fold(
      handleError,
      identity,
    )
  )
  .then(over(responseDataDataLens, map(addAdditionalFees)))

export const searchFlightApiCall = curry(pipe(
  prepareParams,
  configAxios,
  handleRequest,
))
