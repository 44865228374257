import React from 'react';
import i18n from 'Utils/i18n';

const faqListEN = [
  {
    question:
      'When will I receive my flight tickets if I book Fly Now Pay Later flights?',
    answer: (
      <p>
        You will get your flight ticket instantly after completing the booking
        process and you can choose to pay off the balance before or after your
        departure date. Get ready to start packing!
      </p>
    ),
  },
  {
    question: 'Can somebody else pay for my booking?',
    answer: (
      <p>
        Yes. Please fill in the personal details of this person below the
        passenger form. Please don't hesitate to contact us via chat should you
        have any questions.
      </p>
    ),
  },
  {
    question: 'Is there a credit check?',
    answer: (
      <p>
        Yes, when you book your first flight with Flymble a credit check is
        performed to determine your eligibility for financing.
        {/* You will be notified on the credit decision via email. */}
      </p>
    ),
  },
  {
    question: 'Are there any fees or charges if my application is declined?',
    answer: (
      <React.Fragment>
        <p>
          No, you will not be charged if we are unable to offer you a Fly Now
          Pay Later payment plan. We will immediately notify you and confirm the
          reason via email.
        </p>
      </React.Fragment>
    ),
  },
  {
    question:
      'Is it possible to depart from one city and return to another? Can I book multi-city trips?',
    answer: (
      <React.Fragment>
        <p>
          {/* It is possible if you book via the website chat or telephone.  */}
          It is currently not possible to search multi city trips on our
          website, but we expect to have this functionality within the coming
          months.
        </p>
      </React.Fragment>
    ),
  },
];

const faqListPL = [
  {
    question: 'Kiedy otrzymam bilety?',
    answer: (
      <p>
        Otrzymasz bilety zaraz po zakończeniu procesu rezerwacji. Potwierdzenie
        oraz bilety znajdziesz w ciągu 24 godzin na mailu wskazanym podczas
        procesu rezerwacji.
      </p>
    ),
  },
  {
    question: 'Czy będzie sprawdzana moja zdolność kredytowa?',
    answer: (
      <p>
        Tak. Gdy rezerwujesz bilety z Flymble, zostanie sprawdzona Twoja
        zdolność kredytowa.
      </p>
    ),
  },
  {
    question:
      'Czy istnieją opłaty dodatkowe lub prowizje w przypadku odrzucenia zgłoszenia?',
    answer: (
      <p>
        Nie. Żadne opłaty nie zostaną naliczone, jeśli nie będziemy w stanie
        zaoferować Ci planu płatniczego. Natychmiast Cię powiadomimy i podamy
        powód drogą e-mailową.
      </p>
    ),
  },
  // {
  //   question: 'Are there any fees or charges if my application is declined?',
  //   answer: (
  //     <React.Fragment>
  //       <p>
  //         Nie. Żadne opłaty nie zostaną naliczone, jeśli nie będziemy w stanie
  //         zaoferować ci planu płatniczego Leć Teraz płać Później. Natychmiast
  //         powiadomimy cię i podamy powód drogą e-mailową.
  //       </p>
  //     </React.Fragment>
  //   ),
  // },
  {
    question:
      'Czy jest możliwość wylecieć z jednego miasta a wrócić do innego? Czy mogę zarezerwować podróż wielomiastową?',
    answer: (
      <React.Fragment>
        <p>
          Tak, jest taka możliwość poprzez dokonanie rezerwacji przez czat lub
          telefonicznie. Na tę chwilę wyszukanie podróży wielomiastowych na
          naszej stronie jest niemożliwe, ale zamierzamy dodać tę funkcję w
          ciągu kilku następnych miesięcy.
        </p>
      </React.Fragment>
    ),
  },
];

const faqList = i18n.language === 'pl' ? faqListPL : faqListEN;

export default faqList;
