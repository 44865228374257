import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { RedirectElement } from '../../../components/RedirectElement';

export const ShowFlightTimes = ({ dealId }) => {
  const [isAllowedRedirect, setIsAllowedRedirect] = useState(false);
  return (
    <ShowFlightDetailsWrapper>
      <p className="title" onClick={() => setIsAllowedRedirect(true)}>
        <a
          href={`https://flymble.com/packages-deal-summary-flight-details/${dealId}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          See flight times
        </a>
      </p>
      <RedirectElement
        isAllowedRedirect={isAllowedRedirect}
        destination={window.location.pathname}
      />
    </ShowFlightDetailsWrapper>
  );
};

ShowFlightTimes.propTypes = {
  dealId: PropTypes.number.isRequired,
};

const ShowFlightDetailsWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  .title {
    font-weight: 500;
    color: blue;
    font-size: 14px;
  }
`;
