import React, { useContext, useState, memo } from 'react';
import { Form } from 'antd';
import AppContext from '../../../Context/AppContext';
import { LoadingBaggageView } from './components/LoadingBaggageView';
import { BaggageViewController } from './BaggageViewController';
import { BaggageObjectInterface } from "../../../interfaces";

const FormItem = Form.Item;

function select() {
  const {
    checkFlightsBaggageContext,
    setPurchasedBaggage,
    removePurchasedBaggage,
  } = useContext(AppContext);
  return {
    baggageObject: checkFlightsBaggageContext,
    setPurchasedBaggage: setPurchasedBaggage,
    removePurchasedBaggage: removePurchasedBaggage,
  };
}

// eslint-disable-next-line react/display-name
let PassengerBagsContainerBeta = memo(
  (
    {
      baggageObject, setPurchasedBaggage, prefix, removePurchasedBaggage
    }:
      {
        removePurchasedBaggage: Function
        baggageObject: BaggageObjectInterface,
        setPurchasedBaggage: Function,
        prefix: string,
      }) => (
      <FormItem>
        <LoadingBaggageView isVisible={!baggageObject} />
        {baggageObject && (
          <>
            <BaggageSection
              baggageObjectState={baggageObject && baggageObject}
              prefix={prefix}
              setPurchasedBaggage={setPurchasedBaggage}
              removePurchasedBaggage={removePurchasedBaggage}
            />
          </>
        )}
      </FormItem>
    )
);


function connect(WrappedComponent: any, select: Function) {
  return function (props: any) {
    const selectors = select();
    return <WrappedComponent {...selectors} {...props} />;
  };
}

let PassengerBagsContainer = connect(
  PassengerBagsContainerBeta,
  select
);


const BaggageSection = (
  {
    baggageObjectState,
    setPurchasedBaggage,
    removePurchasedBaggage,
    prefix,
  }: {
    removePurchasedBaggage: Function
    baggageObjectState: BaggageObjectInterface,
    setPurchasedBaggage: Function,
    prefix: string,
  }) => {


  return (
    <BaggageViewController
      baggageObjectState={baggageObjectState}
      setPurchasedBaggage={setPurchasedBaggage}
      removePurchasedBaggage={removePurchasedBaggage}
      prefix={prefix}
    />)
};

export { PassengerBagsContainer };
