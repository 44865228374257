import { faIdCard, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'react-emotion/macro';
import i18n from 'Utils/i18n.js';
import { ContrastStyle } from './styles';

const dateText = 'MMM Do, YYYY';

const PersonSectionWrapper = styled.div`
  margin-bottom: 15px;
`;

const genderTitle = gender => {
  return gender === 'male' ? 'Mr.' : 'Mrs.';
};

const PersonSection = ({ passengerData }) => {
  return (
    <PersonSectionWrapper>
      <div>{passengerData.title}</div>
      <div>
        <span className="confirmationIcon">
          <FontAwesomeIcon icon={faUser} />
        </span>
        {`${genderTitle(passengerData.gender)} ${passengerData.firstname} ${
          passengerData.lastname
        }`}
        <ContrastStyle>
          {'| '}
          {passengerData.dob.utc().format(dateText)}
        </ContrastStyle>
      </div>
      <div className="documentDetails">
        <span className="confirmationIcon">
          <FontAwesomeIcon icon={faIdCard} />
        </span>
        {passengerData.idNumber && passengerData.idNumber}
        {passengerData.idNumber && passengerData.idNoExp ? (
          <React.Fragment>
            <ContrastStyle>
              {'| '}
              {i18n.t('PersonSection..No expiration date')}
              {' |'}
            </ContrastStyle>
            {passengerData.nationality}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ContrastStyle>{`| ${passengerData.idExp
              .utc()
              .format(dateText)} |`}</ContrastStyle>
            {`${passengerData.nationality}`}
          </React.Fragment>
        )}
      </div>
    </PersonSectionWrapper>
  );
};

PersonSection.propTypes = {
  passengerData: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.object,
    data: PropTypes.object,
    gender: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    idNumber: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    idNoExp: PropTypes.bool,
    idExp: PropTypes.object,
    dob: PropTypes.object.isRequired,
  }),
};

export default PersonSection;
