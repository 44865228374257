import { faSuitcaseRolling } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'Utils/i18n.js';

const BaggagePassengersSection = ({ passengerData }) => {
  const { firstname, additionalBaggage, lastname = '' } = passengerData;
  return (
    <div>
      {additionalBaggage && (
        <div>
          <span className="confirmationIcon">
            <FontAwesomeIcon icon={faSuitcaseRolling} />
          </span>{' '}
          1x {i18n.t('BaggagePassengersSection..Checked Baggage - ')}
          {`${firstname} ${lastname}`}
        </div>
      )}
    </div>
  );
};

BaggagePassengersSection.propTypes = {
  passengerData: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string,
    additionalBaggage: PropTypes.bool,
  }),
};

export default BaggagePassengersSection;
