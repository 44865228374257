import React, { useEffect, useContext, useState } from 'react';
import { DetailsWrapper, TextContentWrapper, ParagraphTitle } from './styles';
import { TopHeaderDetails } from '../Headers/TopHeaderDetails';
import PropTypes from 'prop-types';
import { PackagesContext } from '../../../../Context/PackagesContext';
import { Placeholder } from 'semantic-ui-react';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';

const hotelDescription =
  'With a history dating back to 1928 and a sophisticated style that has evolved with the ages, the recently renovated Hotel expertly combines modern style with old-world allure for a truly inviting stay. Situated in the beating heart of the city center, you can enjoy directaccess to the city’s dizzying mix of sights, from the iconic Theater Shows to trendy restaurants and roof-top bars.';

const hotelAmenities =
  'fitness centre, café and bar, Wi-Fi, cash machine, air conditioning, wheelchair access';

const HotelInformationRoute = () => {
  const [selectedPackageState, setSelectedPackageState] = useState(null);
  const context = useContext(PackagesContext);
  const { packagesArray } = context;

  useEffect(() => {
    if (packagesArray) {
      setSelectedPackageState(true);
    }

    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: 'visit-deal-info-page',
    });
  }, [packagesArray]);
  if (selectedPackageState) {
    const selectedPackage = packagesArray.find(
      ({ id }) => id === parseInt(window.location.pathname.split('/').pop())
    );
    console.log(' the selected package is....', selectedPackage);

    let { destination: dealSubtitle, id, tripAdvisorUrl } = selectedPackage;

    return (
      <DetailsWrapper>
        <TopHeaderDetails title="Hotel Details" name={id} />
        <TextContentWrapper>
          <ParagraphTitle>{dealSubtitle}</ParagraphTitle>
          <p>{hotelDescription}</p>
          <p>
            <i>Amenities: {hotelAmenities}</i>
          </p>
          <HighlightsParagraph />
          <SecondOpinionParagraph
            tripAdvisorLink={tripAdvisorUrl}
            hotelName={dealSubtitle}
          />
        </TextContentWrapper>
      </DetailsWrapper>
    );
  } else
    return (
      <Placeholder>
        <Placeholder.Image square />
      </Placeholder>
    );
};

const WrappedDealInformationRoute = () => (
  <ErrorBoundary>
    <HotelInformationRoute />
  </ErrorBoundary>
);

export { WrappedDealInformationRoute as HotelDetails };

// HotelDetailsRoute.propTypes = {
//   dealSubtitle: PropTypes.string.isRequired,
//   hotelDescription: PropTypes.string.isRequired,
//   hotelAmenities: PropTypes.string.isRequired,
//   tripAdvisorLink: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
// };

const HighlightsParagraph = () => {
  return (
    <>
      <ParagraphTitle>Highlights</ParagraphTitle>
      <p>
        Immersing yourself in the vibrant city center, staying mere minutes from
        iconic landmarks
      </p>
      <p>
        Hopping between eight swimming pools and the spa at a five-star
        all-inclusive beach resort
      </p>
      <p>
        Trying your hand a selection of adreneline-pumping water sports and
        swimming in the pool.
      </p>
    </>
  );
};

const SecondOpinionParagraph = ({ tripAdvisorLink, hotelName }) => {
  return (
    <>
      <ParagraphTitle>Second opinion</ParagraphTitle>
      <p>
        To find out more about {hotelName}, please visit their{' '}
        <a href="https://www.nycparamount.com/">website</a>, or read guest
        reviews on <a href={tripAdvisorLink}>TripAdvisor</a>
      </p>
      <p>
        Compare our rates with those available elsewhere online, starting with
        the supplier`s own{' '}
        <a href="https://www.viva-holidays.co.uk/holidays/deal/viva-inspired/usa-2/dazzling-new-york-all-inclusive-cancun-city-to-beach-break/1104470">
          website
        </a>
      </p>
    </>
  );
};

SecondOpinionParagraph.propTypes = {
  tripAdvisorLink: PropTypes.string.isRequired,
  hotelName: PropTypes.string.isRequired,
};
