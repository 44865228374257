import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form } from 'antd';
import { InputLabelComp } from './InputLabelComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { signUpPackageUserToMailchimp } from 'Utils';
import { RedirectElement } from '../../../../components/RedirectElement';

class EmailSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonLoadingState: false,
      showThankYouMessage: false,
    };
  }

  handleContinueSignUp = e => {
    e.preventDefault();
    this.setState({ isButtonLoadingState: true });
    this.props.form.validateFields((err, values) => {
      setTimeout(() => {
        clearTimeout();
        if (!err) {
          console.log('Received values of form: ', values);
          const email = values.email;
          localStorage.setItem('hasEmailSignedUp', email);
          signUpPackageUserToMailchimp(
            values.email,
            this.props.hotelName,
            this.props.dealPriceAllPassengers
          );
          this.setState({ showThankYouMessage: true });
        }

        this.setState({ isButtonLoadingState: false });
      }, 1500);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return this.state.showThankYouMessage ? (
      <RedirectElement
        destination="/packages-checkout"
        isAllowedRedirect={true}
      />
    ) : (
      this.props.isVisible &&
      <>
        <div className="title">{this.props.roomType}</div>
        <Form onSubmit={this.handleContinueSignUp}>
          <Form.Item>
            <InputLabelComp text="Provide your email to continue" />
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your Email!' }],
            })(
              <Input placeholder="your@email.com" size="large" type="email" />
            )}
          </Form.Item>
          <div className="selectOfferButtonWrapper">
            <Button
              size="large"
              className="selectOfferButton"
              loading={this.state.isButtonLoadingState}
              htmlType="submit"
            >
              <FontAwesomeIcon icon={faArrowRight} />
              &nbsp; Continue
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

EmailSignUp.propTypes = {
  hotelName: PropTypes.string.isRequired,
  roomType: PropTypes.string.isRequired,
  dealPriceAllPassengers: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldsError: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
    isFieldTouched: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
};

const WrappedEmailSignUpForm = Form.create({ name: 'horizontal_login' })(
  EmailSignUp
);

export { WrappedEmailSignUpForm as SignUpForm };
