import axios from 'axios';
import firebase from 'config/fbConfig.js';
import { trackThankYouPage } from 'Utils/facebookFunctions';
import { packagesBookingMailchimp } from 'Utils';
import {
  test_OrderSummaryObjectContext,
  test_packages_deep_link
} from './testValues';
// Firebase functions.
const newBooking = firebase.functions().httpsCallable('addMessage');
const newDispersal = firebase.functions().httpsCallable('addMessage');


// Create package application. 
const FIREBASE_CREATE_APPLICATION_URL =
'https://us-central1-project-423459023132745232.cloudfunctions.net/createPackagesApplication';

// Mark the application as accepted.
const FIREBASE_MAKE_ACCEPTED_URL =
'https://us-central1-project-423459023132745232.cloudfunctions.net/makeAcceptedPackagesApplication';



// STEP 1: ADD THE APPLICATION TO THE DATABASE, BACK OFFICE APPLICATION. 
export const packagesApplicationBackend = (
  firstCheckoutPageFormValuesContext,
  departureAirportContext,
  departureDateContext,
  selectedPackageContext,
  lastSearchPackagesContext,
  amountRoomsContext,
  amountNightsContext,
  checkoutPackagesPriceContext,
  discountCode,
  extract
) => {
  // General constants.      
  const { code: Discount_Code, value: Discount_Amount } = discountCode;
  const flymbleUserId = localStorage.getItem('flymbleUserId') || '';

  // Amount passengers.
  const { noAdults, noChildren, noInfants } = lastSearchPackagesContext;
  const totalPassengers = noAdults + noChildren + noInfants;

  // Packages offer form
  const packages_offer_form = {
    departureDate: departureDateContext,
    departureAirport: departureAirportContext,
    amountNights: amountNightsContext,
    amountPassengers: totalPassengers,
    passengersObject: lastSearchPackagesContext,
    amountRooms: amountRoomsContext,
    checkoutPackagesPrice: checkoutPackagesPriceContext,
    firstVisit: localStorage.getItem('firstVisit') || 'n.a.',
    isUserDesktop: localStorage.getItem('isDesktop')
    ? localStorage.getItem('isDesktop')
    : 'not assigned',
    The_Discount_Amount: Discount_Amount,
    The_Discount_Code: Discount_Code,
  };

  const packageOrderObject = {
    booking: firstCheckoutPageFormValuesContext,
    selectedPackage: selectedPackageContext,
    packages_deepLink: test_packages_deep_link,
    packages_offer_form: packages_offer_form,
    //***********************************************
    // source has to be TRY!!! Do not change it here
    //***********************************************
    source: 'TRY-booking-by-FNPL',
    flymbleUserId: flymbleUserId,
  };
  
  console.log('The packages offer form is', packageOrderObject);

  // Add application to the firebase databse. 
  axios
    .post(FIREBASE_CREATE_APPLICATION_URL, packageOrderObject)
    .then(response => {
      console.log('[packagesApplicationBackend:Create application in fireabse] The response is:', response);
      const application_id_backend = JSON.parse(response.data.body).applicationId; 
      console.log('[packagesApplicationBackend:Create application in fireabse] application_id_backend:', application_id_backend);
      window.localStorage.setItem('packagesApplicationId', application_id_backend);
    })
    .catch(error => {
      return console.error(error);
    });

  // Initiate tryBooking of Canaveral App. 
  newBooking({
    booking: firstCheckoutPageFormValuesContext,
    tripExtract: extract,
    deepLink: test_packages_deep_link,
    orderSummaryObject: test_OrderSummaryObjectContext,
    //***********************************************
    // source has to be TRY!!! Do not change it here
    //***********************************************
    source: 'TRY-booking-by-FNPL',
    reference: flymbleUserId,
  })
    .then(result => {
      console.debug(
        'Try NewBooking Successful, in newBooking API call',
        result
      );
      this.setState({ orderId: result.data.bookingId });
    })
    .catch(error => {
      console.debug('Try not passed correctly: ', error);
    });

};

// STEP 2: MARK THE TRANSACTION AS COMPLETED. 
export const packagesCompleteTransaction = (
  dispersalToken,
  selectedPackageContext,
  firstCheckoutPageFormValuesContext,
  extract,
  callBack,
  departureAirportContext,
  departureDateContext,
) => {
  const flymbleUserId = localStorage.getItem('flymbleUserId') || '';
  const packagesApplicationId = window.localStorage.getItem('packagesApplicationId');
  console.log('[makeAcceptedPackagesApplication] start function call', packagesApplicationId);
  trackThankYouPage();
    
  // 2.1 Notify operations of a new booking by sending an email through mailchimp. @Todo ---> Bring this function to the new back-end. 
  packagesBookingMailchimp(
    firstCheckoutPageFormValuesContext,
    departureAirportContext,
    departureDateContext,
    selectedPackageContext
  );

  // 2.2 Make the application accepted in the back-end. 
  axios
    .post(FIREBASE_MAKE_ACCEPTED_URL, { packagesApplicationId })
    .then(response => {
      console.log(
        '[makeAcceptedPackagesApplication] The response is:',
        response
      );
    })
    .catch(error => {
      return console.error(error);
    });

  // 2.3 Process the application as a dispersal [Canaveral Application]
  newDispersal({
    booking: firstCheckoutPageFormValuesContext,
    tripExtract: extract,
    deepLink: test_packages_deep_link,
    orderSummaryObject: test_OrderSummaryObjectContext,
    source: process.env.REACT_APP_BOOKING_SOURCE,
    token: dispersalToken,
    reference: flymbleUserId,
  })
    .then(result => {
      console.debug('[packagesCompleteTransaction] NewDispersal Successful', result);
      // CALL BACK AND DIRECT USER TO THE THANK YOU PAGE. 
      callBack();
    })
    .catch(error => {
      console.debug('[packagesCompleteTransaction] NewDispersal Error', error);
    });

};
