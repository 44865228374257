import React from 'react';
import { TripAdvisorWrapper } from './styles';
import PropTypes from 'prop-types';
import tripAdvisorLogo from './tripadvisor-logo.png';
import tripAdvisorStars from './tripadvisor-stars.png';

export const TripAdvisorRating = ({
  tripAdvisorAmountReviews,
  tripAdvisorLink,
}) => (
  <TripAdvisorWrapper>
    <a href={tripAdvisorLink} target="_blank" rel="noopener noreferrer">
      <div className="contentBox">
        <div>
          <img className="logo" src={tripAdvisorLogo} alt="a-rating-amount" />
          <img className="stars" src={tripAdvisorStars} alt="the-star-rating" />
        </div>
        <p className="reviews">{tripAdvisorAmountReviews} reviews</p>
      </div>
    </a>
  </TripAdvisorWrapper>
);

TripAdvisorRating.propTypes = {
  tripAdvisorAmountReviews: PropTypes.number.isRequired,
  tripAdvisorLink: PropTypes.string.isRequired,
};
