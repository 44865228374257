import React from 'react';
import { Spacer, Title } from '../StylingComponents';
import i18n from 'Utils/i18n.js';
import styled from 'react-emotion/macro';
// import TrustPilotWidget from './TrustPilotWidget';
import trustpilotLogo from 'assets/images/trustpilot-vector-logo.svg';

const LogoWrapper = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  margin-bottom: 5px;
  @media (min-width: 1000px) {
    display: none;
  }
`;

const TrustPilotUrl =
  'https://uk.trustpilot.com/review/flymble.com?utm_medium=Trustbox&utm_source=MicroCombo';

const TrustPilotSectionUK = () => {
  return (
    <React.Fragment>
      <Spacer height={10} bgWhite mobileOnly />
      {/* <TrustPilotWidget /> */}
      <a
        href={TrustPilotUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <LogoWrapper src={trustpilotLogo} mobileOnly />
        <Title paddingTop={1} bgWhite mobileOnly>
          TRUSTSCORE 9.5
        </Title>
      </a>
    </React.Fragment>
  );
};

const TrustPilotSectionPL = () => {
  return <React.Fragment>{null}</React.Fragment>;
};

const TrustPilotSection =
  i18n.language === 'pl' ? TrustPilotSectionPL : TrustPilotSectionUK;

export default TrustPilotSection;
