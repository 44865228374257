import React, { useState, useEffect } from 'react';
import { Button, Header, Image, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { trackLowerPriceLimit, trackHigherPriceLimit } from 'Utils';

const noResultsImage = require('../../../../assets/images/noResults.png');

export const PriceLimitModal = ({ totalBookingPrice }) => {
  const [isOpenState, setIsOpenState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (totalBookingPrice < 100) {
        setIsOpenState(true);
        trackLowerPriceLimit();
      }

      if (totalBookingPrice > 3000) {
        setIsOpenState(true);
        trackHigherPriceLimit();
      }
    }, 1500);
  }, []);

  const handleBackToSearch = () => {
    window.history.back();
  };

  return (
    <div>
      <Modal dimmer="blurring" open={isOpenState}>
        <Modal.Content image>
          <Image wrapped size="medium" src={noResultsImage} />
          <Modal.Description>
            <Header>You have exceeded the credit limit</Header>
            <p>
              We currently only accept bookings that range between £100 and
              £3000.
            </p>
            <p>
              The total price of your booking is: £
              {totalBookingPrice.toFixed(2)}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="checkmark"
            labelPosition="left"
            content="Back to search"
            onClick={handleBackToSearch}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

PriceLimitModal.propTypes = {
  //   isOpen: PropTypes.bool.isRequired,
  totalBookingPrice: PropTypes.number.isRequired,
};
