import React from 'react';
import PropTypes from 'prop-types';
import { TextContentWrapper, ParagraphTitle } from './styles';
import { DetailsWrapper } from './styles';
import { TopHeaderDetails } from '../Headers/TopHeaderDetails';
import { travelPackages } from '../../travelPackages';
import { Route } from 'react-router-dom';
import { NotFound } from 'routes/NotFound';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';

export const FlightDetails = ({ match: { url } }) => {
  return (
    <>
      <Route
        path={`${url}/:dealName`}
        render={props => {
          // The find() method returns the value of the first element in the array that satisfies the provided testing function.
          // In this case this means that the smoothieVarId(parameter)in the url,
          // needs to equal the smoothieId in the smoothies array / product list.
          // const packageDeal = travelPackages.find(({ name }) => {
          //   return name === props.match.params.dealName;
          // });

          const packageDeal = travelPackages[0];

          console.log('THE PACKAGE DEAL IS....', packageDeal);

          // If such smoothie doesn't exists, then return page not found.
          if (!packageDeal) {
            return <NotFound />;
          }

          return (
            <FlightDetailsRoute {...packageDeal} packageDeal={packageDeal} />
          );

          // return <DealInformationRoute {...smoothie} smoothie={smoothie} />;
        }}
      />
    </>
  );
};

FlightDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export const FlightDetailsRoute = ({ name: urlName }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ErrorBoundary>
      <DetailsWrapper>
        {/* <TopHeaderDetails title="Flight Details" name={urlName} /> */}
        <TopHeaderDetails
          title="Flight Details"
          name={window.location.pathname.split('/').pop()}
        />
        <FlightDetailsText />
      </DetailsWrapper>
    </ErrorBoundary>
  );
};

const FlightDetailsText = () => {
  return (
    <TextContentWrapper>
      <p>
        Flights are included from Birmignham, Bristol, Edinburgh London Gatwick,
        Manchester and London Stansted.
        {/* to Heraklion, Greece. */}
      </p>
      <p>
        A 5kg hand luggage allowance per person is included, as well as shared
        airport transfers.
      </p>
      <p>
        Please note hold luggage is not included, but can be added from £50 per
        20kg bag. This can be arranged directly with our supplier, Fleetway,
        after booking.
      </p>
      <p>
        Please note that baggage weight and size restrictions may vary between
        airlines - please check the airlines' terms and conditions.
      </p>
      <ParagraphTitle>From London Gatwick, with easyJet or TUI:</ParagraphTitle>
      <p>
        Outbound: departing between 07.00 and 16.40, arriving between 13.00 and
        22.40
      </p>
      <p>
        Inbound: departing between 13.55 and 23.40, arriving between 16.00 and
        23.55
      </p>
      <ParagraphTitle>From Birmingham, with Jet2 or TUI:</ParagraphTitle>
      <p>
        Outbound: departing between 06.00 and 16.45, arriving between 12.05 and
        22.45
      </p>
      <p>
        Inbound: departing between 12.50 and 23.45, arriving between 15.15 and
        01.55 (the following day)
      </p>
      <ParagraphTitle>From Bristol, with easyJet or TUI:</ParagraphTitle>
      <p>
        Outbound: departing between 06.50 and 15.30, arriving between 12.55 and
        21.35
      </p>
      <p>
        Inbound: departing between 13.40 and 22.20, arriving between 15.50 and
        00.30 (the following day)
      </p>
      <ParagraphTitle>From Edinburgh, with easyJet or Jet2:</ParagraphTitle>
      <p>
        Outbound: departing between 14.10 and 15.35, arriving between 20.35 and
        22.00
      </p>
      <p>
        Inbound: departing between 21.20 and 22.55, arriving between 23.55 and
        01.35 (the following day)
      </p>
      <ParagraphTitle>
        From Manchester, with easyJet, Jet2 or TUI:
      </ParagraphTitle>
      <p>
        Outbound: departing between 07.45 and 16.20, arriving between 13.55 and
        22.40
      </p>
      <p>
        Inbound: departing between 14.40 and 02.05 (the following day), arriving
        between 17.00 and 04.20 (the following day)
      </p>
      <ParagraphTitle>From London Stansted, with Jet2 or TUI:</ParagraphTitle>
      <p>
        Outbound: departing between 05.55 and 16.55, arriving between 12.00 and
        23.00
      </p>
      <p>
        Inbound: departing between 12.50 and 23.59, arriving between 15.00 and
        02.10 (the following day)
      </p>
      <p>
        Within seven days of confirming your booking with us, you'll receive an
        email with your final booking confirmation detailing the exact flight
        times confirmed for your specific reservation.
      </p>
      <p>
        The flight time will be within the time parameters and possible airlines
        detailed in this offer.
      </p>
    </TextContentWrapper>
  );
};
