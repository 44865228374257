import axios from 'axios';
import firebase from 'config/fbConfig.js';
import { trackThankYouPage } from 'Utils/facebookFunctions';
import { BOOKING_TYPES } from '../constants';
// Firebase functions.
const newBooking = firebase.functions().httpsCallable('addMessage');
const newDispersal = firebase.functions().httpsCallable('addMessage');

// Test card stripe. 
// 4242424242424242


// Firebase URLs
const FIREBASE_ADD_APPLICATION_URL =
  'https://us-central1-project-423459023132745232.cloudfunctions.net/createApplication';


export const processBooking = (
  dispersalToken,
  selectedFlightTicket,
  firstCheckoutPageFormValuesContext,
  partnerBookingReferenceContext,
  extract,
  callBack,
  orderSummaryObject,
  orderSummary,
  // typeBookingContext
) => {
  var checkoutEventName = process.env.REACT_APP_BOOKING_SOURCE;

  // AWS
  axios
    .post('https://flymble.com/rest/book', {
      payload: {
        deepLink: selectedFlightTicket.deep_link,
        orderSummary,
        ...firstCheckoutPageFormValuesContext,
        tripExtract: extract,
        orderSummaryObject: orderSummaryObject,
      },
      source: checkoutEventName,
    })
    .then(_response => {
      // for now 2019-03-12 response do not return correct bookingId
    });

  // Update firebase and make accepted application:
  const flymbleUserId = localStorage.getItem('flymbleUserId') || '';
  const flymbleApplicationId =
    window.localStorage.getItem('applicationId') || '';
  console.log('[processBooking] Just before make accepted application');
  makeAcceptedApplication(flymbleApplicationId, flymbleUserId);
  trackThankYouPage(orderSummaryObject.Flymble_Price);
  window.mixpanel.people.track_charge(orderSummaryObject.Flymble_Price);
  console.log('[processBooking] Just after make accepted application');

  // Function that calls the addMessage function on the back-end resulting in new database entry in the OLD (Canaveral) firebase.
  newDispersal({
    booking: firstCheckoutPageFormValuesContext,
    tripExtract: extract,
    deepLink: selectedFlightTicket.deep_link,
    orderSummaryObject: orderSummaryObject,
    source: checkoutEventName,
    token: dispersalToken,
    reference: partnerBookingReferenceContext,
  })
    .then(result => {
      console.debug('[processBooking] NewBooking Successful', result);
    })
    .catch(error => {
      console.debug('[processBooking] NewBooking Error', error);
    });

  callBack();
};

export const tryProcessBooking = (
  selectedFlightTicket,
  firstCheckoutPageFormValuesContext,
  partnerBookingReferenceContext,
  extract,
  callBack,
  orderSummaryObject,
  typeBookingContext
) => {
  let booking_values = firstCheckoutPageFormValuesContext;
  if (typeBookingContext === BOOKING_TYPES.STRIPE_Flights) {
    const { P0_firstname, P0_lastname } = firstCheckoutPageFormValuesContext;

    booking_values.paying_firstname = P0_firstname;
    booking_values.paying_lastname = P0_lastname;

    console.log('[tryProcessBooking] booking_values, STRIPE', booking_values);
  }

  const flymbleUserId = localStorage.getItem('flymbleUserId') || '';
  const tryBookingValues = {
    booking: booking_values,
    tripExtract: extract,
    deepLink: selectedFlightTicket.deep_link,
    orderSummaryObject: orderSummaryObject,
    typeBooking: typeBookingContext || BOOKING_TYPES.FNPL_Flights,
    //***********************************************
    // source has to be TRY!!! Do not change it here
    //***********************************************
    source: 'TRY-booking-by-FNPL',
    reference: partnerBookingReferenceContext,
    flymbleUserId: flymbleUserId,
  };
  addApplicationToFireBase(tryBookingValues);
  addUserToMixpanel(tryBookingValues);

  newBooking({
    booking: booking_values,
    tripExtract: extract,
    deepLink: selectedFlightTicket.deep_link,
    orderSummaryObject: orderSummaryObject,
    //***********************************************
    // source has to be TRY!!! Do not change it here
    //***********************************************
    source: 'TRY-booking-by-FNPL',
    reference: partnerBookingReferenceContext,
  })
    .then(result => {
      console.debug(
        'Try NewBooking Successful, in newBooking API call',
        result
      );
      this.setState({ orderId: result.data.bookingId });
    })
    .catch(error => {
      console.debug('Try not passed correctly: ', error);
    });

  if (callBack) {
    callBack();
  }
};

const addApplicationToFireBase = async tryBookingValues => {
  console.log('Calling firebase to create customer...');
  let response;
  let body = JSON.stringify({
    ...tryBookingValues,
    source: `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`,
  });

  try {
    // response = await axios.post(LOCAL_TEST_URL, { body: body });
    response = await axios.post(FIREBASE_ADD_APPLICATION_URL, { body: body });
    const responseBody = JSON.parse(response.data.body);
    console.log('[ADD APPLICATION TO FIREBASE] The response is:', responseBody);

    console.log(responseBody.applicationId, '<- the application id ');

    responseBody.applicationId &&
      window.localStorage.setItem('applicationId', responseBody.applicationId);
  } catch (error) {
    console.error(error);
  }
};

const makeAcceptedApplication = async (applicationId, userId) => {
  console.log(
    'start make accepted application function',
    applicationId,
    userId
  );
  return await axios
    .post(
      'https://us-central1-project-423459023132745232.cloudfunctions.net/makeAcceptedApplication',
      {
        applicationId: applicationId,
        userId: userId,
        source: `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`,
      }
    )
    .then(response => {
      const responseBody = JSON.parse(response.data.body);
      console.log('RECEIVED RESPONSE MAKE APPLICATION', responseBody);
      return responseBody;
    })
    .catch(error => console.error(error));
};

const addUserToMixpanel = tryBookingValues => {
  const { booking, tripExtract, orderSummaryObject } = tryBookingValues;

  const {
    Flymble_Price,
    Nr_Of_Passengers,
    Price_Variant,
    Purchased_Baggage_Total_Cost,
    Purchased_Baggage_Total_Nr_Added,
    The_Discount_Amount,
    The_Discount_Code,
    Virtual_Interlining,
  } = orderSummaryObject;

  const {
    paying_firstname,
    paying_lastname,
    email,
    mobileNr,
    paying_dobMonth,
    paying_dobDay,
    paying_dobYear,
    postcode,
    city,
    county,
    paying_nationality,
    salary,
    residentialstatus,
    employment,
    gender,
  } = booking;

  const {
    airlineName,
    departureAirportOutbound,
    arrivalAirportOutbound,
    departureAirportReturn,
    arrivalAirportReturn,
    departureCityOutbound,
    arrivalCityOutbound,
    departureCityReturn,
    arrivalCityReturn,
    departureTimeOutbound,
    arrivalTimeOutbound,
    departureTimeInbound,
    arrivalTimeInbound,
    flightDurationOutbound,
    flightDurationInbound,
    routeStopsOutbound,
    routeStopsInbound,
    outboundTotalDuration,
    returnTotalDuration,
  } = tripExtract;

  const mixPanelValues = {
    $email: email, // only special properties need the $
    $created: new Date(),
    gender: gender, // feel free to define your own properties,
    // Paying values
    paying_firstname,
    paying_lastname,
    email,
    mobileNr,
    paying_dobMonth,
    paying_dobDay,
    paying_dobYear,
    postcode,
    city,
    county,
    paying_nationality,
    salary,
    residentialstatus,
    employment,
    // Flight ticket values
    airlineName,
    departureAirportOutbound,
    arrivalAirportOutbound,
    departureAirportReturn,
    arrivalAirportReturn,
    departureCityOutbound,
    arrivalCityOutbound,
    departureCityReturn,
    arrivalCityReturn,
    departureTimeOutbound,
    arrivalTimeOutbound,
    departureTimeInbound,
    arrivalTimeInbound,
    flightDurationOutbound,
    flightDurationInbound,
    routeStopsOutbound,
    routeStopsInbound,
    outboundTotalDuration,
    returnTotalDuration,
    // Sales type valyues
    price: Flymble_Price,
    nr_of_passengers: Nr_Of_Passengers,
    price_variant: Price_Variant,
    purchased_baggage_total_cost: Purchased_Baggage_Total_Cost,
    purchased_baggage_total_nr_added: Purchased_Baggage_Total_Nr_Added,
    discount_amount: The_Discount_Amount,
    discount_code: The_Discount_Code,
    is_virtual_interlining: Virtual_Interlining,
  };

  window.mixpanel.people.set(mixPanelValues);
  console.log('mix panel values are....', tryBookingValues);
};
