import styled from 'react-emotion/macro';

const PaymentPlanSectionWrapper = styled.div`
  background-color: #f9f9fa;
  padding-bottom: 42px;

  .example-appear {
    opacity: 0.01;
  }

  .example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 0.7s ease-in;
  }

  .paymentPlanButtons {
    display: grid;
    grid-gap: 25px;

    @media(max-width: 1000px) {
      grid-gap: 18px;
    }
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 530px) minmax(auto, 530px) 1fr;
    padding-bottom: 60px;
    padding-top: 50px;

    .desktopGrid {
      grid-column: 2/4;
    }
  }

  .oneWayErrorMessage {
    padding: 20px;
    margin: auto;
  }
`;

const DesktopGrid = styled.div`
    padding-left: 15px;
    padding-right: 15px;
  @media (min-width: 1000px) {
    grid-column: 2/4;
    padding-left: 0px;
    padding-right: 0px;

  }
`;

const HideDesktopWrapper = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export { PaymentPlanSectionWrapper, DesktopGrid, HideDesktopWrapper };
