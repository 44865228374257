const dealImage2 =
  'https://d2g6qre7voj6kv.cloudfront.net/inHousePackages/823/2201fb3c_da7e_441d_b479_f538cb0cd372.jpg?w=330&h=230&fit=crop&crop=entropy&auto=format,compress';

const dealImage5 =
  'https://d2g6qre7voj6kv.cloudfront.net/sales/49811/9e3d6a8e_8aa7_4be3_91f9_2c923ad4b8c9.jpg?w=535&h=372&fit=crop&crop=entropy&auto=format,compress';

const dealImage6 =
  'https://d2g6qre7voj6kv.cloudfront.net/sales/102864/797ea2c5_ca59_47a6_88b5_418cbd45daba.jpg?w=330&h=230&fit=crop&crop=entropy&auto=format,compress';

const dealImage8 =
  'https://media-cdn.tripadvisor.com/media/photo-o/11/e7/79/e5/the-alantara.jpg';

const priceBasisDescription = 'Based on 2 people for 7 nights';

const hotelDescription =
  'With a history dating back to 1928 and a sophisticated style that has evolved with the ages, the recently renovated Hotel expertly combines modern style with old-world allure for a truly inviting stay. Situated in the beating heart of the city center, you can enjoy directaccess to the city’s dizzying mix of sights, from the iconic Theater Shows to trendy restaurants and roof-top bars.';

const hotelAmenities =
  'fitness centre, café and bar, Wi-Fi, cash machine, air conditioning, wheelchair access';

export const travelPackages = [
  {
    name: 'the-gates-hotel-south-beach',
    dealTitle: 'The Gates Hotel South Beach',
    dealSubtitle: 'Miami, Florida',
    dealPrice: 1248,
    oldDealPrice: 1692,
    priceBasisDescription: priceBasisDescription,
    mainImage: dealImage5,
    offerSummaryDescription:
      'A clean and sleek new Miami base just north of colorful South Beach, boasting retro-modern interiors',
    mapDetailsUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.127124398937!2d-80.12990398454346!3d25.799379813335527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b49d8a39a5d9%3A0x4c4748926b6a60a0!2sThe%20Gates%20Hotel%20South%20Beach%20-%20a%20DoubleTree%20by%20Hilton!5e0!3m2!1spl!2spl!4v1571751106472!5m2!1spl!2spl',
    tripAdvisorAmountReviews: 10835,
    tripAdvisorLink:
      'https://www.tripadvisor.co.uk/Hotel_Review-g34439-d7940209-Reviews-The_Gates_Hotel_South_Beach_a_DoubleTree_by_Hilton-Miami_Beach_Florida.html',
    closestDestinationAirport: 'Miami International Airport',
    roomType: 'One King Bed room',
    hotelDescription: hotelDescription,
    hotelAmenities: hotelAmenities,
  },
  {
    name: 'banyan-tree-phuket',
    dealTitle: 'Banyan Tree Phuket',
    dealSubtitle: 'Phuket, Thailand',
    dealPrice: 2737 / 2,
    oldDealPrice: 3695 / 2,
    priceBasisDescription: priceBasisDescription,
    mainImage: dealImage6,
    offerSummaryDescription:
      'Stay in a luxurious pool villa at a luxury resort on Phuket’s Andaman coast, including daily breakfast',
    mapDetailsUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.851901919601!2d98.29314551532737!3d8.014204306260522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3050383187c6dd45%3A0x9a7ac0ab1e97ffce!2sBanyan%20Tree%20Phuket!5e0!3m2!1spl!2spl!4v1571753922806!5m2!1spl!2spl',
    tripAdvisorAmountReviews: 10835,
    tripAdvisorLink:
      'https://www.tripadvisor.co.uk/Hotel_Review-g297937-d302772-Reviews-Banyan_Tree_Phuket-Thalang_District_Phuket.html',
    closestDestinationAirport: 'Phuket International Airport, Thailand',
    roomType: 'Banyan Pool villa',
    hotelDescription: hotelDescription,
    hotelAmenities: hotelAmenities,
  },
  {
    name: '5-stars-all-inclusive-crete-escape-with-sea-views-for-summer-2020',
    dealTitle: '5* all-inclusive Crete escape with sea views for summer 2020',
    dealSubtitle: 'CHC Athina Palace Resort Spa, Crete',
    dealPrice: 247,
    oldDealPrice: 482,
    priceBasisDescription: priceBasisDescription,
    mainImage: dealImage2,
    offerSummaryDescription:
      'Enjoy a stylish suite stay at an adults-only Lanzarote hotel on the fringes of Playa Blanca, with all-inclusive board, wellness perks and more.',
    mapDetailsUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1113028363347!2d-73.98927738434351!3d40.75957664264011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258543f40379b%3A0xae966f416e2d9099!2sParamount%20Hotel!5e0!3m2!1sen!2sus!4v1571570152935!5m2!1sen!2sus',
    tripAdvisorAmountReviews: 10835,
    tripAdvisorLink:
      'https://www.tripadvisor.co.uk/Hotel_Review-g150812-d811329-Reviews-Grand_Sunset_Princess_All_Suites_Resort-Playa_del_Carmen_Yucatan_Peninsula.html',
    closestDestinationAirport: 'Heraklion, Greece',
    roomType: 'Standard Sea View room five nights (all-inclusive)',
    hotelDescription: hotelDescription,
    hotelAmenities: hotelAmenities,
  },
  //
  {
    name: 'beautiful-bali-at-your-own-pace',
    dealTitle: 'Beautiful Bali at your own pace',
    dealSubtitle: 'Bali, Indonesia',
    dealPrice: 1390,
    oldDealPrice: 1877,
    priceBasisDescription: priceBasisDescription,
    mainImage: dealImage8,
    offerSummaryDescription:
      'Discover the paradise of Bali from a luxury hotel in Sanur incl. breakfast, 90-min massage, excursions, transfers, and return flight!',
    mapDetailsUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.885359098275!2d115.25390981539059!3d-8.702435791082905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd241b933a75087%3A0x9cc3348deed9d936!2sThe%20Alantara%20Sanur%20by%20Pramana!5e0!3m2!1spl!2spl!4v1571755657292!5m2!1spl!2spl',
    tripAdvisorAmountReviews: 10835,
    tripAdvisorLink:
      'https://www.tripadvisor.co.uk/Hotel_Review-g297700-d12951138-Reviews-The_Alantara-Sanur_Denpasar_Bali.html',
    closestDestinationAirport: 'Bali Denpasar International Airport',
    roomType: 'Deluxe Room / Suite Room',
    hotelDescription: hotelDescription,
    hotelAmenities: hotelAmenities,
  },
  // Also done.
  {
    name: '5-star-all-inclusive-tunisia-beach-escape-at-a-blissful-spa-hotel',
    dealTitle: '5* all-inclusive Tunisia beach escape at a blissful spa hotel',
    dealSubtitle: 'Steigenberger Marhaba Thalasso, Hammamet',
    dealPrice: 499,
    oldDealPrice: 924,
    priceBasisDescription: priceBasisDescription,
    mainImage:
      'https://d2g6qre7voj6kv.cloudfront.net/inHousePackages/478/26ffa80d_336b_47e9_84dc_e4a1f4c55882.jpg?w=330&h=230&fit=crop&crop=entropy&auto=format,compress',
    offerSummaryDescription:
      'A relaxing all-inclusive holiday on the golden shores of Tunisia`s Hammamet, with a thalasso spa - includes all travel and a spacious sea or pool view bungalow',
    mapDetailsUrl:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1113028363347!2d-73.98927738434351!3d40.75957664264011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258543f40379b%3A0xae966f416e2d9099!2sParamount%20Hotel!5e0!3m2!1sen!2sus!4v1571570152935!5m2!1sen!2sus',
    tripAdvisorAmountReviews: 2371,
    tripAdvisorLink:
      'https://www.tripadvisor.co.uk/Hotel_Review-g297943-d1517484-Reviews-Steigenberger_Marhaba_Thalasso-Hammamet_Nabeul_Governorate.html',
    closestDestinationAirport: 'Tunis, Tunesia',
    roomType: 'Sea or Pool View Bungalow, seven nights (all-inclusive)',
    hotelDescription:
      'Positioned on a splendid stretch of sandy beach in Hammamet, Steigenberger Marhaba Thalasso is an impressive high-end member of the region`s burgeoning hotel scene. With a nod to traditional Arab architecture, its contemporary style buildings boast arched windows and a splendid soaring atrium lobby with babbling fountain. ',
    hotelAmenities: hotelAmenities,
  },
];
