import React from 'react';
import styled from 'react-emotion/macro';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const flymbleBlue = '#4860ff';

// const flymbleGray = '#d4d7e08c';
const flymbleGray = '#cacccd';

const GoBackButtonWrapper = styled.div`
  grid-column: 1/5;
  /* margin-top: 20px; */
  margin: ${props =>
    props.OneWay ? '0px 0px 0px 0px' : '20px 0px 20px 0px'} !important;

  .goBackToSearchButton {
    grid-column: 2/3;

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 47px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${props => (props.Gray ? flymbleGray : flymbleBlue)};
    color: white;
    border-color: ${props =>
      props.Gray ? flymbleGray : flymbleBlue} !important;

    .faIconProceed {
      margin-right: 20px;
      font-size: 20px;
    }

    @media (max-width: 400px) {
      .faIconProceed {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      grid-column: 1/3;

      span {
        margin-top: 0px;
      }
    }

    @media (min-width: 1000px) {
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      /* display: inline; */
    }
  }

  @media (min-width: 1000px) {
    /* display: inline; */
  }
`;

export default class GoBackButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
    };
  }

  //
  handleOnClick = () => {
    this.setState({ loading: true });
    this.timeoutPlanSelection = setTimeout(() => {
      this.setState({ loading: false });
      this.props.handleGoBackToSearch();
    }, 1500);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timeoutPlanSelection);
  };
  render() {
    const { text, displayGoBackButton, Gray, buttonId } = this.props;
    return (
      displayGoBackButton && (
        <GoBackButtonWrapper Gray={Gray}>
          <Button
            className="goBackToSearchButton"
            id={buttonId}
            onClick={this.handleOnClick}
            loading={this.state.loading}
          >
            {text}
          </Button>
        </GoBackButtonWrapper>
      )
    );
  }
}

GoBackButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleGoBackToSearch: PropTypes.func.isRequired,
  displayGoBackButton: PropTypes.bool.isRequired,
  Gray: PropTypes.bool,
  buttonId: PropTypes.string.isRequired,
};
