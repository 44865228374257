import React from 'react';
import { ReactComponent as PayULogo } from 'PayU/assets/payu_logo_gradient.svg';
import { css } from 'emotion/macro';

const PartnerComponentPL = () => {
  return (
    <React.Fragment>
      <div
        className={css`
          position: relative;
          top: -4px;
        `}
      >
        Partner
        <span
          className={css`
            display: inline-block;
            width: 65px;
            height: 25px;
            margin-left: 20px;
            vertical-align: -8px;
            margin-right: 20px;
            @media (max-width: 1000px) {
              display: none;
            }
          `}
        >
          <PayULogo />
        </span>
      </div>
    </React.Fragment>
  );
};

export default PartnerComponentPL;
