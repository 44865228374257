import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export const ShowOfferDetails = ({
  amountNights,
  amountRooms,
  amountPassengers,
  isVisible,
}) => {
  const OfferDetailsString = () =>
    `Offer details (${amountNights} nights, ${amountRooms} ${
      amountRooms === 1 ? 'room' : 'rooms'
    }, ${amountPassengers} passengers)`;
  // old string: Offer details (7 Nights, 1 Room, 2 Adults)
  return (
    <ShowOfferDetailsWrapper>
      <p className="title" onClick={() => null}>
        <OfferDetailsString />
      </p>
      {isVisible && (
        <div className="offerDetails">
          <p>
            <FontAwesomeIcon icon={faArrowRight} />
            &nbsp;Accomodation in a Standard Double room
          </p>
          <p>
            <FontAwesomeIcon icon={faArrowRight} />
            &nbsp;Breakfast included
          </p>
          <p>
            <FontAwesomeIcon icon={faArrowRight} />
            &nbsp;Daily one hour use of the Jacuzzi
          </p>
        </div>
      )}
    </ShowOfferDetailsWrapper>
  );
};

ShowOfferDetails.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  amountNights: PropTypes.number.isRequired,
  amountRooms: PropTypes.number.isRequired,
  amountPassengers: PropTypes.number.isRequired,
};

const ShowOfferDetailsWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  .title {
    font-weight: 500;
    color: blue;
    font-size: 14px;
  }

  p {
    margin-bottom: 5px;
  }

  .offerDetails {
    padding-bottom: 10px;
  }
`;
