import styled from 'react-emotion/macro';
import React from 'react';
import PropTypes from 'prop-types';

import BusinessInsider from './assets/BI.png';
import Times from './assets/Times.png';
import Metro from './assets/MT-logo.png';

import { Title } from '..';
const grayBgColor = '#f7f7f7';

const PressBarWrapper = styled.div`
  /* background-color: white; */
  @media (max-width: 1000px) {
    background-color: ${props => (props.bgWhite ? 'white' : '#f7f7f7')};
  }
  padding-left: 1.3em;
  padding-right: 1.3em;
  padding-top: 25px;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  .leftSection {
    text-align: right;
  }
  .centerSection {
    text-align: center;
  }
  .rightSection {
    /* text-align: left; */
  }
  .pressLogo1 {
    height: 32px;
  }
  .pressLogo2 {
    height: 30px;
    /* margin-top: 4px; */
  }
  .pressLogo3 {
    height: 41px;
  }
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 80px;
    .pressLogo1 {
      height: 27px;
    }
    .pressLogo2 {
      height: 26px;
    }
    .pressLogo3 {
      height: 25px;
    }
  }

  @media (max-width: 1000px) {
    img {
      max-height: 19px;
      max-width: 125px;
    }
  }
`;

const BgWrapper = styled.div`
  @media (min-width: 1000px) {
    background-color: ${grayBgColor};
  }
`;

class PressBarUK extends React.Component {
  render() {
    const { title, bgWhite } = this.props;
    return (
      <BgWrapper>
        {title && (
          <Title mobileOnly bgWhite={bgWhite}>
            {title}
          </Title>
        )}
        <PressBarWrapper bgWhite={bgWhite}>
          <div className="leftSection">
            <a
              href="http://www.businessinsider.com/you-can-now-book-a-flight-and-pay-for-it-later-2017-12?IR=T"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pressLogo1"
                src={BusinessInsider}
                alt="Business Insider about Flymble"
              />
            </a>
          </div>
          <div className="centerSection">
            <a
              href="https://www.thetimes.co.uk/article/aman-hotel-opens-in-shanghai-t5vrgp7s6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pressLogo2"
                src={Times}
                alt="The Times about Flymble"
              />
            </a>
          </div>
          <div className="rightSection">
            <a
              href="https://www.metro.news/reimagining-the-travel-agent/897574/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pressLogo3"
                src={Metro}
                alt="Metro about Flymble"
              />
            </a>
          </div>
        </PressBarWrapper>
      </BgWrapper>
    );
  }
}

PressBarUK.propTypes = {
  title: PropTypes.string,
  bgWhite: PropTypes.bool,
};

export default PressBarUK;
