
// Test values 
export const test_packages_deep_link =
  'https://www.travelrepublic.co.uk/holidays/51bc1bc1-8fda-4572-9fcb-b7ad19452e7e/2910619?rr=8.5,10';

export const test_FirstCheckoutPageValues = {
  P0_firstname: 'Kale',
  P0_lastname: 'TEST Emard',
  P0_gender: 'male',
  P0_dobMonth: '6',
  P0_dobDay: '4',
  P0_dobYear: '1978',
  P0_idNumber: 'IDNUM000005',
  P0_idExpMonth: '6',
  P0_idExpDay: '0',
  P0_idExpYear: '2026',
  P0_idNoExp: true,
  P0_nationality: 'United Kingdom',
  paying_title: 'Mr',
  paying_firstname: 'Chet',
  paying_lastname: 'TEST Kuphal',
  paying_dobMonth: '6',
  paying_dobDay: '4',
  paying_dobYear: '1978',
  email: 'NONMarco_Dibbert@example.net',
  mobileNr: '07995245570',
  moreThanYear: 'More than a year',
  postcode: 'W1T 1PZ',
  street: 'Newman Street',
  houseNr: '34',
  apartmentNr: '4',
  floorNr: '1',
  city: 'London',
  county: 'Greater London',
  paying_nationality: 'United Kingdom',
  salary: '11691',
  residentialstatus: 'Owner',
  employment: 'Full time employed',
  acceptance: true,
};

export const test_OrderSummaryObjectContext = {
  Nr_Of_Passengers: 1,
  Nr_Of_Infants: 0,
  Flymble_Price: '777.51',
  FNPL_Price: '777.51',
  Nr_Of_Instalments: 12,
  The_Discount_Amount: 0,
  The_Discount_Code: null,
  Purchased_Baggage_Total_Cost: 0,
  Purchased_Baggage_Total_Nr_Added: 0,
};

export const test_selectedFlightTicket = {
  id:
    '0f64075647794788f5d6f89b_0|0f64075647794788f5d6f89b_1|0f64075647794788f5d6f89b_2|0f64075647794788f5d6f89b_3',
  bags_price: {
    '1': 0,
  },
  baglimit: {
    hold_width: 52,
    hold_height: 78,
    hold_length: 28,
    hold_dimensions_sum: 158,
    hold_weight: 23,
    hand_width: 40,
    hand_height: 55,
    hand_length: 20,
    hand_weight: 5,
  },
  p1: 1,
  p2: 1,
  p3: 1,
  price: 407,
  route: [
    {
      id: '0f64075647794788f5d6f89b_0',
      combination_id: '0f64075647794788f5d6f89b',
      return: 0,
      original_return: 0,
      source: 'deprecated',
      found_on: 'deprecated',
      price: 1,
      aTime: 1580970600,
      dTime: 1580904300,
      aTimeUTC: 1580941800,
      dTimeUTC: 1580904300,
      mapIdfrom: 'london_gb',
      mapIdto: 'beijing_cn',
      cityTo: 'Beijing',
      cityFrom: 'London',
      cityCodeFrom: 'LON',
      cityCodeTo: 'BJS',
      flyTo: 'PEK',
      airline: 'CA',
      operating_carrier: 'CA',
      equipment: '330',
      flyFrom: 'LHR',
      latFrom: 51.4775,
      lngFrom: -0.461389,
      latTo: 40.08,
      lngTo: 116.584444,
      flight_no: 788,
      vehicle_type: 'aircraft',
      refresh_timestamp: 0,
      bags_recheck_required: false,
      guarantee: false,
      fare_classes: 'L',
      fare_basis: 'LNCGB6',
      fare_family: '',
      fare_category: 'M',
      last_seen: 1573225082,
      operating_flight_no: '788',
    },
    {
      id: '0f64075647794788f5d6f89b_1',
      combination_id: '0f64075647794788f5d6f89b',
      return: 0,
      original_return: 0,
      source: 'deprecated',
      found_on: 'deprecated',
      price: 1,
      aTime: 1581012300,
      dTime: 1580996400,
      aTimeUTC: 1580987100,
      dTimeUTC: 1580967600,
      mapIdfrom: 'beijing_cn',
      mapIdto: 'bangkok_th',
      cityTo: 'Bangkok',
      cityFrom: 'Beijing',
      cityCodeFrom: 'BJS',
      cityCodeTo: 'BKK',
      flyTo: 'BKK',
      airline: 'CA',
      operating_carrier: 'CA',
      equipment: '333',
      flyFrom: 'PEK',
      latFrom: 40.08,
      lngFrom: 116.584444,
      latTo: 13.685833,
      lngTo: 100.748889,
      flight_no: 959,
      vehicle_type: 'aircraft',
      refresh_timestamp: 0,
      bags_recheck_required: false,
      guarantee: false,
      fare_classes: 'L',
      fare_basis: 'LNCGB6',
      fare_family: '',
      fare_category: 'M',
      last_seen: 1573225082,
      operating_flight_no: '959',
    },
    {
      id: '0f64075647794788f5d6f89b_2',
      combination_id: '0f64075647794788f5d6f89b',
      return: 1,
      original_return: 1,
      source: 'deprecated',
      found_on: 'deprecated',
      price: 1,
      aTime: 1582180200,
      dTime: 1582165500,
      aTimeUTC: 1582151400,
      dTimeUTC: 1582140300,
      mapIdfrom: 'bangkok_th',
      mapIdto: 'shenzhen_cn',
      cityTo: 'Shenzhen',
      cityFrom: 'Bangkok',
      cityCodeFrom: 'BKK',
      cityCodeTo: 'SZX',
      flyTo: 'SZX',
      airline: 'ZH',
      operating_carrier: 'ZH',
      equipment: '738',
      flyFrom: 'BKK',
      latFrom: 13.685833,
      lngFrom: 100.748889,
      latTo: 22.639167,
      lngTo: 113.810556,
      flight_no: 9020,
      vehicle_type: 'aircraft',
      refresh_timestamp: 0,
      bags_recheck_required: false,
      guarantee: false,
      fare_classes: 'E',
      fare_basis: 'PNN3GB6',
      fare_family: '',
      fare_category: 'M',
      last_seen: 1573225082,
      operating_flight_no: '9020',
    },
    {
      id: '0f64075647794788f5d6f89b_3',
      combination_id: '0f64075647794788f5d6f89b',
      return: 1,
      original_return: 1,
      source: 'deprecated',
      found_on: 'deprecated',
      price: 1,
      aTime: 1582227600,
      dTime: 1582207800,
      aTimeUTC: 1582227600,
      dTimeUTC: 1582179000,
      mapIdfrom: 'shenzhen_cn',
      mapIdto: 'london_gb',
      cityTo: 'London',
      cityFrom: 'Shenzhen',
      cityCodeFrom: 'SZX',
      cityCodeTo: 'LON',
      flyTo: 'LHR',
      airline: 'ZH',
      operating_carrier: 'ZH',
      equipment: '330',
      flyFrom: 'SZX',
      latFrom: 22.639167,
      lngFrom: 113.810556,
      latTo: 51.4775,
      lngTo: -0.461389,
      flight_no: 9067,
      vehicle_type: 'aircraft',
      refresh_timestamp: 0,
      bags_recheck_required: false,
      guarantee: false,
      fare_classes: 'P',
      fare_basis: 'PNN3GB6',
      fare_family: '',
      fare_category: 'M',
      last_seen: 1573225082,
      operating_flight_no: '9067',
    },
  ],
  airlines: ['ZH', 'CA'],
  pnr_count: 1,
  transfers: [],
  has_airport_change: false,
  technical_stops: 0,
  availability: {
    seats: 1,
  },
  dTime: 1580904300,
  dTimeUTC: 1580904300,
  aTime: 1581012300,
  aTimeUTC: 1580987100,
  nightsInDest: 14,
  flyFrom: 'LHR',
  flyTo: 'BKK',
  cityFrom: 'London',
  cityTo: 'Bangkok',
  cityCodeFrom: 'LON',
  cityCodeTo: 'BKK',
  countryFrom: {
    code: 'GB',
    name: 'United Kingdom',
  },
  countryTo: {
    code: 'TH',
    name: 'Thailand',
  },
  mapIdfrom: 'london_gb',
  mapIdto: 'bangkok_th',
  distance: 9587.95,
  routes: [['LHR', 'BKK'], ['BKK', 'LHR']],
  virtual_interlining: false,
  fly_duration: '23h 0m',
  duration: {
    departure: 82800,
    return: 87300,
    total: 170100,
  },
  return_duration: '24h 15m',
  facilitated_booking_available: true,
  type_flights: ['GDS'],
  found_on: ['deprecated'],
  conversion: {
    GBP: 378.51000000000005,
    EUR: 473,
  },
  booking_token:
    'A7ccIZ-LyaCKwIIv8L4RdxbrpnhR8VVAUDM3sZbsU1H988SG37O1ZfEAvMkSr2jF_md_3UmDQiK0gX07GaEjAJVEC0g1OQ79EYu3AZ-nzrfs7UanFQPrLZx-1KGDqfGb-PFwtPVzEikfJBRIx6Igad8clc583TssB1ynanf4RDmhNiYYA2mFpWj7CJdd48U8wo1aHDaQTLc8a6ZBr2Fd8Vz4SWz4n5NA8x7BUiH7FpYteCZIFOzB-g6faX7M_bQG8yrXb62Z2WOjKEduPt6D5s96SXRh3jMpOx4VE54bm8XfB1QoaDZ2fz7p7SBddvCkNuBuOzvKBE-nBKYDrDr2TRmkk9JN0YqEeP_G0wHCS3XynqvHyzcDZZEgohetGp2koLikh3Q_8W3eCZd8SdoLLTLnuetMuPVEYU0nz11yJffEt6MI1p3zJosY8ShbY3I5GRFF03patkjCW4SiqVZMwUeKkJh8PCExe0lqrN_54YTFogHbpLPatLwqkUAu42OffzetiVP2_Xdg9W1ZSsJK0lsQk-DNXodfY-BPm3EVzONrW264IraHy4UTSenAFvKMLuqgsjeTUWRJFkBwBeCMKeWXdiz0ni3Rtm0Y9-xj4v9w=',
  quality: 874.998995,
  deep_link:
    'https://www.kiwi.com/deep?from=LHR&to=BKK&departure=05-02-2020&return=20-02-2020&flightsId=0f64075647794788f5d6f89b_0%7C0f64075647794788f5d6f89b_1%7C0f64075647794788f5d6f89b_2%7C0f64075647794788f5d6f89b_3&price=473&passengers=1&affilid=flymble&lang=en&currency=GBP&booking_token=A7ccIZ-LyaCKwIIv8L4RdxbrpnhR8VVAUDM3sZbsU1H988SG37O1ZfEAvMkSr2jF_md_3UmDQiK0gX07GaEjAJVEC0g1OQ79EYu3AZ-nzrfs7UanFQPrLZx-1KGDqfGb-PFwtPVzEikfJBRIx6Igad8clc583TssB1ynanf4RDmhNiYYA2mFpWj7CJdd48U8wo1aHDaQTLc8a6ZBr2Fd8Vz4SWz4n5NA8x7BUiH7FpYteCZIFOzB-g6faX7M_bQG8yrXb62Z2WOjKEduPt6D5s96SXRh3jMpOx4VE54bm8XfB1QoaDZ2fz7p7SBddvCkNuBuOzvKBE-nBKYDrDr2TRmkk9JN0YqEeP_G0wHCS3XynqvHyzcDZZEgohetGp2koLikh3Q_8W3eCZd8SdoLLTLnuetMuPVEYU0nz11yJffEt6MI1p3zJosY8ShbY3I5GRFF03patkjCW4SiqVZMwUeKkJh8PCExe0lqrN_54YTFogHbpLPatLwqkUAu42OffzetiVP2_Xdg9W1ZSsJK0lsQk-DNXodfY-BPm3EVzONrW264IraHy4UTSenAFvKMLuqgsjeTUWRJFkBwBeCMKeWXdiz0ni3Rtm0Y9-xj4v9w=',
  conversionOriginal: {
    GBP: 378.51000000000005,
    EUR: 473,
  },
};
