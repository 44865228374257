import styled from 'react-emotion/macro';

const flymbleBlue = '#4860ff';

export const PriceInfoWrapper = styled.div`
  padding-top: 8px;

  .pricePromotion {
    color: #000000;

    .priceMain {
      display: block;

      .priceFrom {
        /* font-size: 0.875rem; */
        font-size: 14px;
        line-height: 1.5;
        margin-right: 5px;
      }

      .priceValue {
        color: ${flymbleBlue};
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 1;
        font-weight: bold;

        .priceCurrency {
          font-size: 18px;
        }
      }

      .priceStrikeThrough {
        /* font-size: 1.125rem; */
        font-size: 16px;
        line-height: 1.25;
        text-decoration: line-through;
        margin-left: 5px;
        margin-right: 7px;
        color: #666;
      }

      .pricePerPerson {
        /* font-size: 0.875rem; */
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  .priceLabel {
    font-size: 0.75rem;
    line-height: 1.5;
    margin-top: 4px;
    color: black;

    .faInfoCircle {
      font-size: 14px !important;
    }
  }
`;
