import axios from 'axios';
import { parsedTicketData } from 'Utils';

// Constants
const mailchimpUrl =
  'https://us-central1-mailchimp-2.cloudfunctions.net/subscribeUser';

const packageSignUpUrl =
  'https://us-central1-mailchimp-2.cloudfunctions.net/signUpPackageUser';

// const FIREBASE_SENDGIRD_URL =
//   'http://localhost:5000/mailchimp-2/us-central1/sendGridPackages';

const FIREBASE_SENDGIRD_URL =
  'https://us-central1-mailchimp-2.cloudfunctions.net/sendGridPackages';

// Mailchimp function.
export const subscribeUserToMailchimp = (
  checkoutValues,
  orderSummaryObject,
  theFlightTicket,
  paymentPlanPartnerSelected
) => {
  // Parsing the data.
  let combinedObject = {
    ...checkoutValues,
    ...orderSummaryObject,
    ...theFlightTicket,
  };

  // Contact information.
  const {
    paying_firstname,
    paying_lastname,
    email, //
    mobileNr,
    postcode,
  } = combinedObject;

  // Travel information
  const {
    returnTextDateDeparture,
    outboundTextDateDeparture,
    departureCityOutbound,
    departureCityReturn,
  } = parsedTicketData(theFlightTicket);

  // Pricing information
  const totalOrderValue = combinedObject.Flymble_Price;
  const Monthly_Price_All_Passengers =
    combinedObject.Monthly_Price_All_Passengers;
  const nrMonths = combinedObject.Nr_Of_Instalments;

  // Object to be send.
  const userToSubscribe = {
    firstName: paying_firstname,
    lastName: paying_lastname,
    emailAddress: email,
    phoneNumber: mobileNr,
    livingAddress: postcode,
    totalOrderValue: totalOrderValue,
    monthlyPriceBooking: Monthly_Price_All_Passengers,
    nrMonths: nrMonths,
    paymentProviderSelected: paymentPlanPartnerSelected,
    fromAirport: departureCityOutbound,
    toAirport: departureCityReturn,
    outboundDepartureDate: outboundTextDateDeparture,
    returnDepartureDate: returnTextDateDeparture,
  };

  if (email.includes('@example')) {
    console.log(
      'WARNING!!!!!!',
      'Mailchimp only works with real emails such as @gmail',
      'It does not work with @example',
      'The email you used is: ',
      email
    );
  }

  axios
    .post(mailchimpUrl, userToSubscribe)
    .then(response => {
      response.data === 'Email subscribed!' &&
        console.log(
          'Mailchimp api works and the email is subscribed succesfully.'
        );
    })
    .catch(error => {
      console.log(error);
    });
};

export const signUpPackageUserToMailchimp = (email, hotelName, orderValue) => {
  console.log(
    'The parameters from the package sign up function are...',
    email,
    hotelName,
    orderValue
  );

  let params = { email, hotelName, orderValue };

  axios
    .post(packageSignUpUrl, params)
    .then(response => {
      response.data === 'Email subscribed!' &&
        console.log(
          'Mailchimp api works andl the [Package / Hotel] email is subscribed succesfully.'
        );
    })
    .catch(error => {
      console.log(error);
    });
};

export const packagesBookingMailchimp = (
  checkoutFormValues,
  departureAirport,
  departureDate,
  selectedPackage
) => {
  if (checkoutFormValues) {
    console.log('The checkout form values are....', checkoutFormValues);

    const packageOrderObject = {
      departureAirport,
      departureDate,
      selectedPackage,
      checkoutFormValues,
    };

    console.log(
      '[FUNC Packages Booking Mailchimp] The package order object is...',
      packageOrderObject
    );

    return axios
      .post(FIREBASE_SENDGIRD_URL, packageOrderObject)
      .then(response => {
        console.log('The response is:', response);
      })
      .catch(error => {
        return console.error(error);
      });
  } else
    return console.log(
      'There has been an error with the packages booking function for mailchimp'
    );
};
