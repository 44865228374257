import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContext from 'Context/AppContext';
import { trackPaymentPlanPage, createTripTextConstants } from 'Utils';
import ViewComponent from './View';
import { CheckFlightsContainer } from 'Checkout/CheckFlightsContainer/index.js';
import { LoadingScreen } from '../../components/LoadingScreen';

const PaymentPlanPage = ({ history }) => {
  const context = useContext(AppContext);
  const {
    setAmount,
    setPaymentPlanPartner,
    isOneWayFlight,
    paymentPlanPartnerSelected,
    selectedFlightTicket: selectedFlightTicketContext,
    selectedFlightTicket: theFlightTicket,
    lastSearch,
    lastSearch: { noMonths },
    changeTypeBookingContext
  } = context;

  const initializeFlightTicket = () => {
    const { localStorage } = window;
    const localStorageFlightTicket = JSON.parse(
      localStorage.getItem('SelectedFlightResult')
    );

    if (selectedFlightTicketContext !== null) {
      localStorage.setItem(
        'SelectedFlightResult',
        JSON.stringify(selectedFlightTicketContext)
      );
      return selectedFlightTicketContext;
    } else if (localStorageFlightTicket) {
      context.setSelectedFlightTicket(localStorageFlightTicket);
      return localStorageFlightTicket;
    }
  };

  useEffect(() => {
    initializeFlightTicket();
  }, []);

  function goToCheckout() {
    // Construct url and push.
    // Be carefull with removing the history state object, legacy code relies / relied on it.
    history.push({
      pathname: `/checkout/${theFlightTicket.flyTo}`,
      state: { theFlightTicket },
    });
  }

  function goBackToSearch() {
    history.goBack();
  }

  if (theFlightTicket) {
    const tripTextConstants = createTripTextConstants(
      lastSearch,
      theFlightTicket
    );

    trackPaymentPlanPage(
      theFlightTicket.conversion[process.env.REACT_APP_VARIANT_CURRENCY]
    );
    return (
      <CheckFlightsContainer>
        <ViewComponent
          activeMonths={noMonths}
          setContextAmountMonths={setAmount}
          setContextPaymentPlanPartner={setPaymentPlanPartner}
          paymentPlanPartnerSelected={paymentPlanPartnerSelected}
          isOneWayFlight={isOneWayFlight()}
          totalBookingPrice={
            theFlightTicket.conversion[process.env.REACT_APP_VARIANT_CURRENCY]
          }
          lastSearch={lastSearch}
          theFlightTicket={theFlightTicket}
          goToCheckout={goToCheckout}
          goBackToSearch={goBackToSearch}
          tripTextConstants={tripTextConstants}
          changeTypeBookingContext={changeTypeBookingContext}
        />
      </CheckFlightsContainer>
    );
  } else return <LoadingScreen />;
};

PaymentPlanPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const RouteredPaymentPlanPage = withRouter(PaymentPlanPage);
export default RouteredPaymentPlanPage;
