import moment from 'moment';

export const generate_itinerary = (
  selectedPackage,
  checkoutPackagesPrice,
  firstCheckoutPageFormValuesContext,
  selected_departure_date,
  days_of_stay,
  package_rooms
) => {
  const countryCodeFrom = 'GB';
  const { title: package_name, countryCodeTo } = selectedPackage;
  const { departureDate, returnDate } = get_itinerary_dates(
    selected_departure_date,
    days_of_stay
  );

  // Create a list of passengers that we provide to FNPL.
  const passengers_array = parsePassengersData(
    firstCheckoutPageFormValuesContext
  );



  // Itinierary object definitions.
  let itinerary_outbound = {
    passengers: passengers_array,
    from: {
      date: {
        day: parseInt(departureDate.split('-')[2]),
        month: parseInt(departureDate.split('-')[1]),
        year: parseInt(departureDate.split('-')[0]),
      },
      country: countryCodeFrom,
    },
    to: {
      date: {
        day: parseInt(departureDate.split('-')[2]),
        month: parseInt(departureDate.split('-')[1]),
        year: parseInt(departureDate.split('-')[0]),
      },
      country: countryCodeTo,
    },
  };

  let itinerary_return = {
    passengers: passengers_array,
    from: {
      date: {
        day: parseInt(returnDate.split('-')[2]),
        month: parseInt(returnDate.split('-')[1]),
        year: parseInt(returnDate.split('-')[0]),
      },
      country: countryCodeTo,
    },
    to: {
      date: {
        day: parseInt(returnDate.split('-')[2]),
        month: parseInt(returnDate.split('-')[1]),
        year: parseInt(returnDate.split('-')[0]),
      },
      country: countryCodeFrom,
    },
  };

  let hotel_itinerary = {
    name: package_name,
    country: countryCodeTo,
    persons: passengers_array,
    rooms: package_rooms,
    checking: {
      in: {
        day: parseInt(departureDate.split('-')[2]),
        month: parseInt(departureDate.split('-')[1]),
        year: parseInt(departureDate.split('-')[0]),
      },
      out: {
        day: parseInt(returnDate.split('-')[2]),
        month: parseInt(returnDate.split('-')[1]),
        year: parseInt(returnDate.split('-')[0]),
      },
    },
  };

  let checkout_packages_price_proxy = Number.parseInt(checkoutPackagesPrice.toFixed(0));
  return {
    itinerary_outbound,
    itinerary_return,
    hotel_itinerary,
    checkout_packages_price: checkout_packages_price_proxy,
  };
};

// Parse the package object.
function get_itinerary_dates(selected_departure_date, daysOfStay) {
  const date_format = 'YYYY-MM-DD';
  let packageDate = moment(selected_departure_date, date_format);
  let departureDate = packageDate.format(date_format);
  let returnDate = packageDate.add(daysOfStay, 'day').format(date_format);

  return {
    departureDate,
    returnDate,
  };
}

// Create a list of passengers that we provide to FNPL.
const parsePassengersData = checkoutFormValues => {
  let next = 0;
  let list = [];
  while (
    typeof checkoutFormValues[`P${next}_firstname`] !== 'undefined' &&
    next < 10
  ) {
    list.push({
      name:
        checkoutFormValues[`P${next}_firstname`] +
        ' ' +
        checkoutFormValues[`P${next}_lastname`],
      dob: {
        day: parseInt(checkoutFormValues[`P${next}_dobDay`], 10),
        month: parseInt(checkoutFormValues[`P${next}_dobMonth`], 10),
        year: parseInt(checkoutFormValues[`P${next}_dobYear`], 10),
      },
    });
    next += 1;
  }
  return list;
};
