import React from 'react';
import { ActiveWrapper, BaggageElementWrapper } from "../styles";
import { cx } from 'emotion';
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




export const NoCheckedBaggageOptionElement = ({ isActive, handleOnClick }: { isActive: boolean, handleOnClick: Function }) => {
    return (
        <ActiveWrapper>
            <div
                className={cx(
                    { borderWrapper: true },
                    { borderWrapperActive: isActive }
                )}
                onClick={() => handleOnClick()}
            >
                <BaggageElementWrapper>
                    <div className="noCheckedBaggageElement">
                        <FontAwesomeIcon icon={faTimes} className="faIcon" />

                        <p className="typeBaggage">
                            <b>No checked baggage</b>
                        </p>

                        <p className="unitPrice" />
                    </div>
                </BaggageElementWrapper>
            </div>
        </ActiveWrapper>
    );
};