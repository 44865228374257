import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TellMeMoreWrapper } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChild } from '@fortawesome/free-solid-svg-icons';
import { ROOM_CAPACITY } from '../../../../constants';

// Destructuring room Types
const { ADULTS_ONLY, ONE_CHILD, INFANT_AND_CHILD } = ROOM_CAPACITY;

export const DisplayRoomsCapacity = ({ roomCapacity }) => {
  const [isTellMeMoreContentVisible, setIsTellMeMoreContentVisible] = useState(
    false
  );

  const RoomCapacityIcons = ({ roomCapacity }) => {
    switch (roomCapacity) {
      case ADULTS_ONLY:
        return (
          <>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            <FontAwesomeIcon icon={faUser} />
          </>
        );

      case ONE_CHILD:
        return (
          <>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            <FontAwesomeIcon icon={faUser} />
            &nbsp; + &nbsp;
            <FontAwesomeIcon icon={faChild} />
          </>
        );

      case INFANT_AND_CHILD:
        return (
          <>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            <FontAwesomeIcon icon={faUser} />
            &nbsp; + &nbsp;
            <FontAwesomeIcon icon={faChild} />
            &nbsp;
            <FontAwesomeIcon icon={faChild} />
          </>
        );

      default:
        return (
          <>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            <FontAwesomeIcon icon={faUser} />
          </>
        );
    }
  };
  return (
    <div className="roomCapacity">
      Sleeps up to &nbsp;
      <RoomCapacityIcons roomCapacity={roomCapacity} />
      <div
        className="tellTitle"
        style={{ float: 'right', color: '#4860ff' }}
        onClick={() =>
          setIsTellMeMoreContentVisible(!isTellMeMoreContentVisible)
        }
      >
        Tell me more
      </div>
      <TellMeMoreContent
        roomCapacity={roomCapacity}
        isContentVisible={isTellMeMoreContentVisible}
      />
      <br />
      {/* <RoomsAmountDisplay /> */}
    </div>
  );
};

const TellMeMoreContent = ({ roomCapacity, isContentVisible }) => {
  const AdultsOnly = () => (
    <p>
      This offer can sleep up to two guests. At least one occupant in each room
      must be over the age of 18 years old. <br />
      <br />
      If you wish to bring a child, it is possible, but you must not exceed the
      maximum number of guests per room. You may need to book multiple rooms to
      accommodate your family.
    </p>
  );

  const OneChild = () => (
    <p>
      This room type can sleep up to two adults and one child aged 2-11 years
      old. Children will sleep in existing bedding.
    </p>
  );

  const InfantAndChild = () => (
    <p>
      This suite type can sleep up to two adults and two children aged 2-14
      years old. Children will sleep in existing bedding.
      <br />
      <br />
      If you’re booking multiple suites for your family, at least one occupant
      in each suite must be over the age of 18.
    </p>
  );

  const RoomCapacityDetails = ({ roomCapacity }) => {
    switch (roomCapacity) {
      case ADULTS_ONLY:
        return <AdultsOnly />;

      case ONE_CHILD:
        return <OneChild />;

      case INFANT_AND_CHILD:
        return <InfantAndChild />;
      default:
        return <AdultsOnly />;
    }
  };
  return (
    <>
      <TellMeMoreWrapper>
        {isContentVisible && (
          <div className="content">
            <RoomCapacityDetails roomCapacity={roomCapacity} />
          </div>
        )}
      </TellMeMoreWrapper>
    </>
  );
};

TellMeMoreContent.propTypes = {
  roomCapacity: PropTypes.string.isRequired,
  isContentVisible: PropTypes.bool.isRequired,
};

DisplayRoomsCapacity.propTypes = {
  roomCapacity: PropTypes.string.isRequired,
};
