import React from 'react';
import styled from 'react-emotion/macro';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const flymbleBlue = '#4860ff';

// All units are vw/vh
const CARD_WIDTH = 75;
const MARGIN_WIDTH = 10;

const TravelDealsCardWrapper = styled.div`
  display: inline-block;
  width: ${CARD_WIDTH}vw;

  margin-right: ${MARGIN_WIDTH / 2}vw;
  margin-left: ${MARGIN_WIDTH / 2}vw;
  box-shadow: 3px 3px 25px 0px rgba(0, 0, 0, 0.15);

  @media (min-width: 1000px) {
    &:nth-child(n + 4) {
      display: none;
    }
  }

  .destinationImage {
    background-image: linear-gradient(
        rgba(20, 20, 20, 0.2),
        rgba(20, 20, 20, 0.3)
      ),
      url('CanaryIslands.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    /* height: 180px; */
    height: 165px;

    padding: 2vh 3vw;
    color: white;
    text-align: center;

    h3 {
      margin-top: 6px;
      margin-bottom: 0px;
      font-size: 24px;
      font-weight: 400;
      line-height: 1.2em;
      color: white;
    }
    .customerPhotos {
      text-align: center;

      img {
        border-radius: 50%;
        height: 6vh;
        width: 6vh;
        border: 1px solid white;
        object-fit: scale-down;
      }
      @media (max-width: 599px) {
        img:nth-child(1) {
          transform: translateX(5px);
        }
        img:nth-child(2) {
          transform: translateX(-5px);
        }
      }
    }

    .customerNames {
      font-size: 12px;
      margin-top: 2px;
      margin-bottom: 2px;
      font-weight: 400;
    }

    .daysAgo {
      float: right;
      background: #0000007a;
      border: 1px solid white;
      padding: 8px 9px;
      border-radius: 4px;
      font-size: 12px;
      margin-top: 22px;
      @media (max-width: 321px) {
        padding: 8px 5px;
      }
    }

    .lowRow {
      text-align: left;
      margin-top: 5px;

      p {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.33;
      }

      .bookedFor {
        padding-top: 10px;
        font-size: 15px;
      }

      .poundsUpfront {
        font-weight: bold;
        font-size: 17px;

        @media (max-width: 325px) {
          font-size: 15px;
        }
      }
    }
  }

  .paymentPlanSection {
    background-color: white;
    padding: 16px 16px;

    h5 {
      margin-bottom: 2px;
      margin-top: 0px;
    }

    p {
      margin: 0px;

      span {
        font-size: 13px;
        line-height: 1.5;
      }

      span:nth-child(2) {
        float: right;
        font-weight: bold;
      }
    }

    .searchFlightsButton {
      /* margin-top: 6px; */
      /* padding-top: 12px; */
      width: 100%;
      text-align: center;
      /* border-top: 1px #1d1d1d1c solid; */
      cursor: pointer;

      a {
        text-decoration: none;

        p {
          padding: 12px 30px;
          background-color: ${flymbleBlue};
          text-decoration: none;
          color: white;
          border-radius: 3px;
        }
      }
    }
  }

  @media (min-width: 1000px) {
    margin-left: 25px;
    margin-right: 25px;
    width: initial;
    height: 255px;
    /* height: 355px; */

    .destinationImage {
      /* height: 210px; */
      height: 180px;
      padding: 20px 16px 5px;
    }
  }
`;

const TravelDealsCardUK = ({
  destination,
  costs,
  familyName,
  dealImageBg,
  imgMan,
  imgLady,
  daysAgo,
  noInstalments,
  noInstalmentsText,
  linkDeal,
}) => {
  const { t } = useTranslation();
  return (
    <TravelDealsCardWrapper key={destination} className={dealImageBg}>
      <div className="destinationImage">
        <div className="customerPhotos">
          <img src={imgMan} alt="man" />
          <img src={imgLady} alt="lady" />
        </div>
        <h3>{destination}</h3>
        <p className="customerNames">{familyName}</p>
        <div className="lowRow">
          {/* <p className="bookedFor">from</p>
          <p className="poundsUpfront">£{Math.round(costs.total / 12)}</p> */}
        </div>
      </div>
      <div className="paymentPlanSection">
        {/* <h5>{t('TravelDealsCardX..Payment plan')}</h5>
        <p>
          <span>{t('TravelDealsCardX..Price of tickets')}</span>
          <span>
            {t('TravelDealsCardX..£ {{price}}', {
              price: costs.total.toFixed(2),
            })}
          </span>
        </p>
        <p>
          <span>
            {noInstalmentsText}{' '}
            {t('TravelDealsCardX..monthly payments of', {
              count: costs.noInstalments,
            })}
          </span>
          <span>
            {t('TravelDealsCardX..£{{monthlyPrice}}', {
              monthlyPrice: Math.round(costs.total / 12),
            })}
          </span>
        </p> */}
        {/* <RepresentativeExampleModal /> */}

        <div className="searchFlightsButton">
          <Link
            to={linkDeal}
            id={`carddeal_${destination
              .toLowerCase()
              .split(' ')
              .join('_')}`}
          >
            <p>{t('TravelDealsCardX..Get Started')}</p>
          </Link>
        </div>
      </div>
    </TravelDealsCardWrapper>
  );
};

TravelDealsCardUK.propTypes = {
  destination: PropTypes.string.isRequired,
  costs: PropTypes.object.isRequired,
  familyName: PropTypes.string.isRequired,
  dealImageBg: PropTypes.string.isRequired,
  imgMan: PropTypes.string.isRequired,
  imgLady: PropTypes.string.isRequired,
  daysAgo: PropTypes.string.isRequired,
  noInstalments: PropTypes.number.isRequired,
  noInstalmentsText: PropTypes.string.isRequired,
  linkDeal: PropTypes.string.isRequired,
};

export default TravelDealsCardUK;
