import styled from 'react-emotion/macro';
import { colors } from 'themeFlymble';
import { MobileOnly, DesktopOnly } from 'routes/LandingPage/components';

export const PerMonth = styled.span``;
export const Installments = styled.div``;
export const Details = styled.div``;
export const FinalDetail = styled.div`
  .payInFullPrice {
    font-weight: 700;
    font-size: 22px !important;
    color: #171b1e;
  }
`;
export const Position = styled.div``;
export const Price = styled.div``;
export const Title = styled.h2``;

export const PaymentInformationWrapper = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  background: white;
  padding: 0 15px;
  padding-bottom: 15px;
  font-size: 14px;
  font-family: Roboto, Source Sans Pro, sans-serif;
  line-height: 24px;

  @media (max-width: 1000px) {
    padding: 0 25px;
    margin: 0 -15px;
    padding-bottom: 25px;
  }

  ${Title} {
    display: none;
  }

  ${Installments} {
    margin: 20px 0;
    font-size: 26px;
    font-weight: 600;
    color: ${colors.flymbleBlue};
    padding: 10px 0;
    border-bottom: ${props => (props.isBorder ? '1px solid #c2c3c6' : null)};
    /* padding-top: ${props => (props.isBorder ? '6px' : null)}; */

    margin-bottom: 10px;
    @media(max-width: 1000px) {

    }
  }

  .payuWidgetRaty {
    font-weight: 600 !important;
    color: ${colors.flymbleBlue} !important;
  }

  ${PerMonth} {
    font-size: 18px;
    font-weight: 600;
  }

  ${Details} {
    display: grid;
    grid-template-columns: minmax(200px, auto) 81px;
    grid-row-gap: 5px;
    color: #484848;
    font-weight: 400;

    ${Price} {
      text-align: right;
    }
  }

  ${FinalDetail} {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: minmax(200px, auto) 81px;
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid #c2c3c6;
  }

  @media (min-width: 1000px) {
    background: none;
    ${Title} {
      display: block;
    }
  }

  .upfrontPrice {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 1000px) {
      margin-top: 6px;
      font-size: 18px;
    }
  }
`;

// export const TotalPayInFullWrapper = styled.div`

// `;
