import React from 'react';
import styled from 'react-emotion/macro';
import { css } from 'emotion/macro';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FlymbleLogo from './assets/flymble_logo_white.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import paymentsImage from './assets/paymentsList.png';
import partnersImage from './assets/partnersList.png';
// import klarnaLogoPink from './assets/klarnaLogoPink.svg';

const faStyle = css`
  font-size: 22px;
`;

const FooterWrapper = styled.div`
  background-color: #1d1d1d;
  color: white;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 1.3em;
  padding-right: 1.3em;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 35px 135px 60px 245px;

  .socialList {
    grid-column: 2/3;
  }
  .linkList1 {
    grid-column: 1/2;
  }
  .linkList2 {
    grid-column: 2/3;
  }

  .legalLinks {
    grid-column: 1/3;
  }
  .legalText {
    grid-column: 1/3;
  }
  .partnerLogos {
  }

  @media (min-width: 1000px) {
    grid-template-rows: 70px 60px 135px;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    .linkList1,
    .linkList2 {
      grid-row: span 2;
    }
    .socialList {
      grid-column: 1/2;
      grid-row: 2/3;
      text-align: left !important;
    }
    .linkList1 {
      grid-column: 2/3;
      padding: 0px !important;
      margin: 0px;
      font-weight: 500;
    }
    .linkList2 {
      grid-column: 3/4;
      padding: 0px !important;
      margin: 0px;
      font-weight: 500;
    }

    .legalLinks {
      grid-column: 4/5;
      grid-row: 1/2;
      padding-top: 5px;
      p {
        display: none;
      }
    }
    .legalText {
      grid-column: 1/6;
      grid-row: 3/4;
    }
    .partnerLogos {
      grid-row: 5/6;
      grid-column: 1/2;
    }
    .paymentLogos {
      grid-row: 5/6;
      grid-column: 2/4;
    }
    .addressDesktop {
      grid-row: 5/6;
      color: white;
      grid-column: 4/5;
    }
  }

  .logoWrap {
    @media (min-width: 1000px) {
      img {
        height: 50px;
      }
    }
  }

  .socialList {
    margin: 0px;
    list-style: none;
    padding-left: 0px;
    text-align: right;

    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 20px;

        @media (max-width: 325px) {
          margin-right: 10px;
        }
      }

      a {
        color: white;
      }
    }
  }

  .linkList1 {
    list-style: none;
    text-align: left;

    padding-left: 28px;

    li a {
      color: white;
      text-decoration: none;
      line-height: 2.5;
    }
  }

  .linkList2 {
    list-style: none;
    text-align: left;

    li a {
      color: white;
      text-decoration: none;
      line-height: 2.5;
    }
  }

  .legalLinks {
    list-style: none;
    color: white;
    text-align: left;
    padding-left: 0px;
    font-size: 10px;
    color: #ffffff;

    p {
      font-size: 12px;
    }

    @media (min-width: 1000px) {
      font-size: 12px;
    }

    li {
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .legalText {
    p {
      font-size: 12px;
      color: white;

      @media (min-width: 1000px) {
        font-size: 12px;
      }
    }
  }
  .partnerLogos,
  .paymentLogos {
    span {
      /* opacity: 0.5; */
      font-size: 12px;
    }
    img {
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      padding-left: 6px;
      @media (max-width: 1000px) {
        margin-top: 15px;
      }
    }
  }

  .addressDesktop {
    display: none;
    @media (min-width: 1000px) {
      display: block;
    }

    p {
      margin: 0px;
      font-size: 12px;
      color: white;
    }
  }

  .klarnaLogoText {
    @media (min-width: 1000px) {
      display: inline-block;
      vertical-align: text-top;
    }

    @media (max-width: 1000px) {
      padding-top: 3px;
      display: block;
      padding-bottom: 10px;
    }
  }

  .klarnaLogoFooter {
    display: block;
    margin-top: 0 !important;
    @media (min-width: 1000px) {
      display: inline-block !important;
      vertical-align: middle;
    }
    @media (max-width: 1000px) {
      display: block !important;
      margin-bottom: 8px !important;
    }
  }

  padding-bottom: 50px;
`;

const Footer = () => {
  const { t } = useTranslation();
  const ADDRESS_LONDON = t(
    'Footer..Kemp House 152 - 160, City Road, London EC1V 2NX'
  );
  return (
    <div className="mainGrid footer">
      <div className="wrapper">
        <FooterWrapper>
          <div className="logoWrap">
            <img src={FlymbleLogo} alt="Flymble logo" />
          </div>
          <ul className="socialList">
            <li>
              <a
                href="https://www.facebook.com/Flymble/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} className={faStyle} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/flymble/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className={faStyle} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/flymbletravel/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className={faStyle} />
              </a>
            </li>
          </ul>
          <ul className="linkList1">
            <li>
              <Link to="/about-us" alt="/">
                {t('Footer..About Us')}
              </Link>
            </li>
            <li>
              <Link to="/join-our-team">{t('Footer..Careers')}</Link>
            </li>
            <li>
              <Link to="/transparency">{t('Footer..Transparency')}</Link>
            </li>
          </ul>
          <ul className="linkList2">
            <li>
              <Link to="/help" alt="/">
                {t('Footer..FAQs')}
              </Link>
            </li>
            {/* <li>
              <Link to="/guides" alt="/">
                {t('Footer..Guides')}
              </Link>
            </li> */}
            <li>
              <Link to="/sitemap" alt="/">
                {t('Footer..Sitemap')}
              </Link>
            </li>
          </ul>
          {/* <div className="newsLetter">
            <p>Get 10% off your next booking with Flymble</p>
          <input placeholder="Enter your email address" type="text" />
          </div> */}
          <ul className="legalLinks">
            <li>
              <a style={{ marginBottom: '10px' }} href="/terms-conditions">
                <u>
                  {t(
                    'Footer..Terms & Conditions, Cookie Policy and Privacy Policy'
                  )}
                </u>{' '}
              </a>
            </li>
            <li>
              <a href="https://drive.google.com/a/flymble.com/file/d/1SbPk4pT_SlgdVsVhZJgXNXkQAtReT7rp/view?usp=sharing">
                <u>Complaints Policy</u>
              </a>
            </li>
          </ul>
          <div className="legalText">
            <p>
              Flymble Travel Ltd, trading as Flymble, is an Appointed
              Representative of Fly Now Pay Later Limited, who are authorised
              and regulated by the Financial Conduct Authority FRN: 726937.
              Flymble acts as a Credit Broker, not a Lender, and is authorised
              and regulated by the Financial Conduct Authority FRN: 778540.
              Flymble will introduce you to Fly Now Pay Later Limited. Fly Now
              Pay Later act as a Credit Broker, not a Lender and works
              exclusively with one lender, Travelfund Limited who are authorised
              and regulated by the Financial Conduct Authority FRN:672306.
              <br />
              <br />
              <p>Registered office address: Kemp House, 152 -160 City Road</p>
              <p>Post adress: {ADDRESS_LONDON}</p>
              <br />
            </p>
          </div>
          <div className="partnerLogos">
            <span>{t('Footer..Partnered with:')}</span>
            <img src={partnersImage} alt="partners logos" />
          </div>
          <div className="paymentLogos">
            <span>{t('Footer..Payments accepted:')}</span>
            <img src={paymentsImage} alt="accepted payments logos" />
          </div>
          {/* <div className="partnerLogos" style={{ gridRow: '6/7' }}>
            <span className="klarnaLogoText">Pay later in 3 with:</span>
            <img
              className="klarnaLogoFooter"
              src={klarnaLogoPink}
              alt="partner logo Klarna"
              style={{ height: '22px', marginBottom: '7px' }}
            />
          </div> */}

          <div className="addressDesktop">
            <p>
              Flymble Travel Ltd, trading as Flymble, 10749735
              <br />
              <br />
              {/* Registered office address: Kemp House, 152 -160 City Road, London,
              EC1V 2NX */}
            </p>
            {/* <p>Post adress: {ADDRESS_LONDON}</p> */}
          </div>
         
        </FooterWrapper>
      </div>
    </div>
  );
};

export default Footer;
