import { colors } from 'themeFlymble';
import styled from 'react-emotion/macro';
import { theme } from 'Page/common.js';
import { MobileOnly, DesktopOnly } from 'routes/LandingPage/components';

const searchPageBg = theme.almostWhite;

export const CheckoutPageWrapper = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 0px;

  background-color: ${searchPageBg};

  h2 {
    font-size: 18px;
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 620px)) 1fr;
    padding-top: 70px;
    padding-bottom: 80px;
    min-height: 1200px;

    h2 {
      font-size: 26px;
    }

    .checkoutWrapperGrid {
      grid-column: 2/4;
      display: grid;
      /* grid-template-columns: 12fr 5fr; */
      /* grid-column-gap: 100px; */
      grid-template-columns: 28fr 13fr;
      grid-column-gap: 80px;

      .paymentDatesSection {
        grid-column: 2/3;
      }

      .checkoutBody {
        /* grid-row: 3/4; */
        grid-column: 1/2;
      }
    }

    .sideColumnWrapper {
      grid-column: 2 / 3;
      grid-row: 3 / 5;
    }

    .stickyWrapper {
      position: sticky;
      top: 80px;
    }
  }

  @media (max-width: 1000px) {
    padding-left: 0px;
    padding-right: 0px;

    .checkoutBody {
      /* grid-row: 3/4; */
    }

    .stickyWrapper {
      display: none;
    }
  }
`;

export const BookingSteps = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  grid-column: 1/3;
  & h2 {
    padding-top: 32px;
    padding-bottom: 34px;
    font-size: 20px;
    margin: 0;
  }

  @media (min-width: 1000px) {
    padding-bottom: 30px;
    & svg {
      font-size: 32px;
    }
    & h2 {
      font-size: 26px;
      text-align: left;
      padding-top: 0px;
    }
  }

  & .ant-steps {
    font-family: Roboto, Source Sans Pro, sans-serif;
  }

  & .ant-steps-vertical .ant-steps-item-content {
    min-height: 40px;
  }

  & .ant-steps-small .ant-steps-item-icon,
  & .ant-steps-item-icon {
    font-weight: 600;
    font-size: 14px;
  }

  /* step icon connector */
  & .ant-steps-item-finish > .ant-steps-item-tail:after {
    background-color: ${colors.flymbleBlue};
  }
  & .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
    padding: 24px 0 0;
  }
  & .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail:after {
    width: 2px;
  }
  & .ant-steps-item-finish > .ant-steps-item-tail:after {
    background-color: ${colors.flymbleBlue};
  }

  /* done step icon */
  & .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${colors.flymbleBlue};
    background-color: #fff;
  }
  & .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${colors.flymbleBlue};
  }

  /* active circle */
  & .ant-steps-item-process .ant-steps-item-icon {
    background: ${colors.flymbleBlue};
    border-color: ${colors.flymbleBlue};
    margin-left: 1px;
  }
`;

export const CheckoutFormsUKWrapper = styled.div`
  padding-top: 45px;
  @media (max-width: 1000px) {
    padding-top: 0px;
  }

  .description {
    font-size: 13px;
    display: block;
    color: black;
    margin-top: 0px;

    @media (min-width: 1000px) {
      /* padding: 16px; */
      padding-bottom: 0px;
      grid-column: 1/3;
      margin-top: 0px;
    }
  }

  h2 {
    font-size: 22px;
    margin-bottom: 0px;
    /* vertical-align: middle; */
    color: black;

    @media (min-width: 1000px) {
      margin-bottom: 20px !important;
    }
  }

  .faIcon {
    font-size: 20px;
    /* vertical-align: middle; */
  }

  @media (min-width: 1000px) {
    padding-top: 0px;

    .description {
      /* display: none; */
    }

    .faIcon {
      font-size: 28px;
    }
  }

  .desktopOnly {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .proceedWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .authorizeButton {
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }

  .proceedButton {
    margin-top: 50px;
    margin-bottom: 50px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
    }

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }
`;

export const AddPassengerButtonWrapper = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;

  width: 100%;

  span {
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: bold;

    width: 220px;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: ${colors.flymbleBlue};
    border-radius: 3px;
  }

  .faIcon {
    padding-right: 3px;
    font-size: 16px;
  }
`;

export const ConfirmationPaymentSection = styled.div`
  padding-bottom: 50px;
  .authorizeButton {
    margin-top: 50px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
      width: 100%;
    }

    line-height: 0 !important;

    display: block;
    width: 50%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }
`;
