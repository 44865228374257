import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion/macro';
import i18n from 'Utils/i18n.js';
import { Link } from 'react-router-dom';
import { Form, Checkbox } from 'antd';

const FormItem = Form.Item;
const paymentPlanBorder = '1px solid #E8E8E8';

function customCheckboxValidator(rule, value, callback) {
  if (value === true) {
    callback();
  }
  callback(rule);
}

const AcceptTermsCheckbox = ({
  getFieldDecorator,
  setFieldsValue,
  getFieldValue,
}) => {
  return (
    <AcceptTermsCheckboxWrapper>
      <div className="tcGrid">
        <FormItem
          onClick={() => {
            setFieldsValue({ acceptance: !getFieldValue('acceptance') });
          }}
        >
          {getFieldDecorator('acceptance', {
            rules: [
              {
                required: true,
                message: 'Required',
                validator: customCheckboxValidator,
              },
            ],
            valuePropName: 'checked',
          })(<Checkbox />)}
        </FormItem>
        <span>
          {i18n.t('BookPaymentSection..I accept')}
          <Link to="/terms-conditions" target="_blank">
            {i18n.t(
              'BookPaymentSection.. the Terms and Conditions of Flymble.com '
            )}
          </Link>
          {i18n.t(
            'BookPaymentSection..and I confirm that the information provided is up to date and accurate.'
          )}
        </span>
      </div>
    </AcceptTermsCheckboxWrapper>
  );
};

AcceptTermsCheckbox.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

const AcceptTermsCheckboxWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 10px;

  p {
    padding: 15px;
    border: ${paymentPlanBorder};

    span {
      font-size: 12px;
    }

    input {
      width: 30px;
      height: 30px;
    }
  }

  a {
    text-decoration: none;
  }

  .tcGrid {
    display: grid;
    grid-template-columns: 1fr 7fr;
    align-items: center;

    padding: 15px;
    font-size: 13px;
    border: ${paymentPlanBorder};
    border-radius: 2px;
    margin-bottom: 15px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    color: black;

    > div.ant-form-item :first-child {
      margin-bottom: 0px;
      text-align: center;

      @media (min-width: 1000px) {
        > div > div > span {
          padding-right: 10px;
        }
      }
    }
    .ant-form-explain {
      padding-right: 6px !important;
    }
  }

  @media (min-width: 1000px) {
    padding-bottom: 20px;
    padding-top: 0px;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export { AcceptTermsCheckbox };
