import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

class RedirectElementProxy extends Component {
  state = {
    redirect: false,
  };

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), this.props.timeOutDuration);
  }

  componentWillUnmount() {
    clearTimeout(this.id);
    this.setState({ redirect: false });
  }

  render() {
    return this.state.redirect && <Redirect to={this.props.destination} />;
  }
}

const RedirectElement = ({ destination, isAllowedRedirect, timeOutDuration = 1000 }) => {
  return (
    isAllowedRedirect && <RedirectElementProxy destination={destination} timeOutDuration={timeOutDuration} />
  );
};

export { RedirectElement };

RedirectElement.propTypes = {
  destination: PropTypes.string.isRequired,
  isAllowedRedirect: PropTypes.bool.isRequired,
};

RedirectElementProxy.propTypes = {
  destination: PropTypes.string.isRequired,
  timeOutDuration: PropTypes.number.isRequired
};
