import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'Utils/i18n';
import { css } from 'emotion/macro';
import { Form, Select, Input } from 'antd';

import FormLabel from '../../components/FormLabel';

const Option = Select.Option;
const formInputSize = 'large';
const FormItem = Form.Item;

export const GenderFirstName = ({ getFieldDecorator, isGenderVisible }) => {
  const genderEN = [
    { title: 'Mr', value: 'Mr' },
    { title: 'Ms', value: 'Ms' },
    { title: 'Mrs', value: 'Mrs' },
    { title: 'Miss', value: 'Miss' },
    { title: 'Dr', value: 'Dr' },
  ];

  return (
    <div>
      <FormLabel>Given names</FormLabel>
      <div
        className={
          isGenderVisible
            ? css`
                display: grid;
                grid-template-columns: minmax(80px, 1fr) 6fr;
                grid-column-gap: 10px;
              `
            : ''
        }
      >
        {isGenderVisible && (
          <FormItem>
            {getFieldDecorator('paying_title', {
              rules: [
                {
                  required: true,
                  message: 'Please choose your title.',
                },
              ],
            })(
              <Select size={formInputSize} placeholder="title">
                {genderEN.map(gender => (
                  <Option key={gender.value} value={gender.value}>
                    {gender.title}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        )}

        <FormItem>
          {getFieldDecorator('paying_firstname', {
            rules: [
              {
                required: true,
                message: 'Please enter a first name using letters only.',
                type: 'string',
                pattern: /([^0-9]*)$/,
                whitespace: true,
              },
            ],
          })(
            <Input
              size={formInputSize}
              placeholder={i18n.t(
                'ContactDetails..placeholder..e.g. Oliver James'
              )}
            />
          )}
        </FormItem>
      </div>
    </div>
  );
};

GenderFirstName.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  isGenderVisible: PropTypes.bool.isRequired,
};

export const SurnameField = ({ getFieldDecorator }) => {
  return (
    <div>
      <FormLabel>{i18n.t('ContactDetails..label..Surname(s)')}</FormLabel>
      <FormItem>
        {getFieldDecorator('paying_lastname', {
          rules: [
            {
              required: true,
              message: i18n.t(
                'ContactDetails..validator..Please enter a surname using letters only.'
              ),
              type: 'string',
              pattern: /([^0-9]*)$/,
              whitespace: true,
            },
          ],
        })(
          <Input
            size={formInputSize}
            placeholder={i18n.t('ContactDetails..placeholder..e.g. Brown')}
          />
        )}
      </FormItem>
    </div>
  );
};

SurnameField.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
};

export const DobFields = ({ getFieldDecorator }) => {
  return (
    <div>
      <FormLabel>{i18n.t('ContactDetails..label..Date of birth')}</FormLabel>
      <div className="dobGrid">
        <FormItem>
          {getFieldDecorator('paying_dobMonth', {
            rules: [
              {
                required: true,
                message: i18n.t(
                  'ContactDetails..validator..Please provide your month of birth.'
                ),
              },
            ],
          })(
            <Select
              size={formInputSize}
              placeholder={i18n.t('ContactDetails..placeholder..Month')}
              style={{ width: '100%' }}
            >
              <Option value="01">
                {i18n.t('ContactDetails..label..January')}
              </Option>
              <Option value="02">
                {i18n.t('ContactDetails..label..February')}
              </Option>
              <Option value="03">
                {i18n.t('ContactDetails..label..March')}
              </Option>
              <Option value="04">
                {i18n.t('ContactDetails..label..April')}
              </Option>
              <Option value="05">{i18n.t('ContactDetails..label..May')}</Option>
              <Option value="06">
                {i18n.t('ContactDetails..label..June')}
              </Option>
              <Option value="07">
                {i18n.t('ContactDetails..label..July')}
              </Option>
              <Option value="08">
                {i18n.t('ContactDetails..label..August')}
              </Option>
              <Option value="09">
                {i18n.t('ContactDetails..label..September')}
              </Option>
              <Option value="10">
                {i18n.t('ContactDetails..label..October')}
              </Option>
              <Option value="11">
                {i18n.t('ContactDetails..label..November')}
              </Option>
              <Option value="12">
                {i18n.t('ContactDetails..label..December')}
              </Option>
            </Select>
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('paying_dobDay', {
            rules: [
              {
                required: true,
                message: i18n.t(
                  'ContactDetails..validator..Please provide your day of birth in a 2 digit format.'
                ),
                type: 'string',
                max: 2,
                // specifies up untill 31 and only two digits.
                pattern: /^(?:[0]?[0-9]|[12][0-9]|3[01])$/g,
              },
            ],
          })(
            <Input
              placeholder={i18n.t('ContactDetails..placeholder..DD')}
              size={formInputSize}
              style={{ width: '100%' }}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('paying_dobYear', {
            rules: [
              {
                required: true,
                message: i18n.t(
                  'ContactDetails..validator..Please provide your year of birth in 4 digits.'
                ),
                type: 'string',
                max: 4,
                // specifies up untill 2018 and only digits.
                pattern: /^19[1-9]\d|20[0-1]\d|2018$/g,
              },
            ],
          })(
            <Input
              placeholder={i18n.t('ContactDetails..placeholder..YYYY')}
              size={formInputSize}
              style={{ width: '100%' }}
            />
          )}
        </FormItem>
      </div>
    </div>
  );
};

DobFields.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
};
