import styled from 'react-emotion/macro';

export const Wrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  padding: 12px 0;
  border-top: 1px solid black;

  .ant-btn-primary {
    color: white;
    background-color: rgb(72, 96, 255);
    border-color: rgb(72, 96, 255);
    box-shadow: rgb(11, 24, 114) 0px -5px 0px 0px inset,
      rgba(0, 0, 0, 0.1) -1px 0px 0px 0px;

    @media (min-width: 1000px) {
      border: none;
      background-color: #13c29b;
      box-shadow: none;
      padding-top: 2px;
    }
  }

  @media (min-width: 1000px) {
    border-top: 0;
    padding: 35px 38px;
    padding-bottom: 15px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const FormLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 7px;
  color: #2e353b;
`;

export const InputWrapper = styled.div`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
`;
